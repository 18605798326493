import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { CreditNote } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('CreditNoteApi', downgradeInjectable(CreditNoteApiService))


@Injectable()
@AngularCopy()
export class CreditNoteApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account: string|number, @AngularParams() params) {
    return this.http.get<{ data: CreditNote[] }>(`${apiUrl}/credit-notes/by-account/${account}`, { params }).toPromise()
  }

  @HaveAngularParams()
  listByDeal(dealId: string, @AngularParams() params) {
    return this.http.get<{ data: CreditNote[] }>(`${apiUrl}/credit-notes/by-deal/${dealId}`, { params }).toPromise()
  }

  listByDeals(deal_ids: string[]) {
    return this.http.post<{ data: CreditNote[] }>(`${apiUrl}/credit-notes/by-deals`, { deal_ids }).toPromise()
  }

  create(data: Partial<CreditNote>) {
    return this.http.post<{ data: CreditNote }>(`${apiUrl}/credit-notes`, data).toPromise()
  }

  update(dealId: string, id: string, data: Partial<CreditNote>) {
    return this.http.put<{ data: CreditNote }>(`${apiUrl}/credit-notes/${dealId}/${id}`, data).toPromise()
  }

  get(dealId: string, id: string) {
    return this.http.get<{ data: CreditNote }>(`${apiUrl}/credit-notes/${dealId}/${id}`).toPromise()
  }

  void(id: string) {
    return this.http.put<{ data: CreditNote }>(`${apiUrl}/credit-notes/void/${id}`, {}).toPromise()
  }
}
