<tc-select-search placeholder="Brokerage Customer" gdArea="customer"
    [ctrl]="detailsForm.controls.brokerageCustomer"
    [items]="accounts$ | async" bindValue="account" bindLabel="name" bindHint="type"
    (change)="customerChanged($event)"
    ></tc-select-search>
<mat-form-field gdArea="estimated">
  <mat-label>Est. Amount</mat-label>
  <input matInput type="number" step="0.01"
      [formControl]="detailsForm.controls.brokerageEstAmount"
      (change)="estAmountChanged()">
</mat-form-field>
<tc-select-search placeholder="Currency" gdArea="currency"
    [ctrl]="detailsForm.controls.brokerageCurrency"
    [items]="currencies$ | async"
    (change)="change.next()"
    ></tc-select-search>
<mat-form-field gdArea="actual">
  <mat-label>Actual Amount</mat-label>
  <input matInput type="number" step="0.01"
      [formControl]="detailsForm.controls.brokerageActAmount"
      (change)="change.next()">
</mat-form-field>
<div class="checkboxes" gdArea="checkboxes">
  <mat-checkbox color="primary" [formControl]="detailsForm.controls.brokerageSendSupplierConfirmation">
    Send Supplier Confirmation
  </mat-checkbox>
  <mat-checkbox color="primary" [formControl]="detailsForm.controls.brokerageSendBuyerConfirmation">
    Send Buyer Confirmation
  </mat-checkbox>
</div>
<tc-select-search placeholder="Trader" gdArea="trader"
    [ctrl]="detailsForm.controls.brokerageTraderId"
    [items]="traders$ | async" bindValue="id" bindLabel="name"
    ></tc-select-search>
<tc-select-search placeholder="Contacts" gdArea="contacts"
    [multiple]="true"
    [ctrl]="detailsForm.controls.brokerageContactUserIds"
    [items]="brokerageUsers$ | async" bindValue="user_id" bindLabel="fullname" bindHint="primaryemail"
    ></tc-select-search>
<h2 gdArea="due-date-header">Due Date</h2>
<mat-form-field gdArea="due-date-days">
  <mat-label>No. of days</mat-label>
  <input matInput type="number" step="1" min="0"
      [formControl]="termsForm.controls.days"
      (change)="termsChanged()">
</mat-form-field>
<tc-select-search placeholder="From this date" gdArea="due-date-from"
    [ctrl]="termsForm.controls.from_date"
    [items]="PaymentTermsDates" bindValue="id" bindLabel="name"
    (change)="termsChanged()"
    ></tc-select-search>
<tc-select-search placeholder="Payment Reference" gdArea="pref-from"
    [ctrl]="termsForm.controls.reference_id"
    [items]="paymentReferences$ | async" bindValue="payment_reference_id" bindLabel="reference"
    [showTooltips]="true"
    (change)="termsChanged()"
    ></tc-select-search>
