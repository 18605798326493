import { SortDirection } from '@angular/material/sort'
import { TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as dayjs from 'dayjs'
import { Observable } from 'rxjs'
import { TypedFormGroup } from 'src/shared/utils/typed-forms'

export interface FiltersFormGroup<T, M = any> extends TypedFormGroup<T> {
  tableKey: TableKey
  defaultValues: DeepReadonly<T>
  mapStoredValues?: (x: M) => M
  filtersActive$: Observable<number>
}

export interface DateFilter {
  rangeName?: string
  startDate?: dayjs.Dayjs
  endDate?: dayjs.Dayjs
}

export interface TableFilters {
  table_view_id?: string
  columns: string[]
  skip?: number
  limit: number
  sort: {
    id: string;
    start: SortDirection;
  }
}
export const TABLE_FILTERS_FIELDS = ['limit', 'sort', 'skip', 'columns']
