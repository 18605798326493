import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit } from '@angular/core'
import { MatTooltip } from '@angular/material/tooltip'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { combineLatest } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { DealDetailsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'

@Component({
  selector: 'tc-finance-issues',
  template: `🐞`,
  styles: [`:host:not(.visible) { display: none }`],
  providers: [MatTooltip],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceIssuesComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly tooltip: MatTooltip,
    private readonly AuthApi: AuthApiService,
  ) { super() }

  @Input() detailsForm: DealDetailsFormGroup

  @HostBinding('class.visible') issues: string;

  @HostListener('mouseenter') onMouseEnter(): void {
    this.tooltip.message = this.issues;
    this.tooltip.tooltipClass = 'pre-tooltip'
    this.tooltip.show();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.tooltip.hide();
  }

  ngOnInit() {
    combineLatest([this.detailsForm.financeIssues$, this.AuthApi.currentUser$])
    .pipe(untilComponentDestroyed(this)).subscribe(([issues, currentUser]) => {
      this.issues = ['manager', 'administrator', 'superuser'].includes(currentUser.role) ? issues : ''
    })
  }
}
