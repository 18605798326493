<form (submit)="submit()" [formGroup]="detailsForm">
  <div mat-dialog-title>
    <h2>{{dealId}} Trading Details</h2>
    <tc-locks [resourceId]="dealId" resourceType="deal"></tc-locks>
    <tc-finance-issues [detailsForm]="detailsForm"></tc-finance-issues>
    <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
    <span fxFlex></span>
    <button mat-button type="button" (click)="cancel()">
      Cancel
    </button>
    <button mat-raised-button type="submit" color="primary"
        [matTooltip]="incomplete.incomplete" matTooltipClass="pre-tooltip"
        [disabled]="!!incomplete.incomplete">
      <i class="fa fa-fw"
          [class.fa-save]="!(inProgress$ | async)"
          [class.fa-spin]="inProgress$ | async"
          [class.fa-spinner]="inProgress$ | async"
          ></i> Save
    </button>
  </div>
  <div mat-dialog-content gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="0 15px" gdAlignColumns="start start">
    <div class="tc-bought-from" gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
      <h2 class="title">Bought from</h2>
      <mat-form-field>
        <mat-label>Buying trader</mat-label>
        <input readonly matInput [value]="buyerTraderFullname$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Supplier ref. number</mat-label>
        <input matInput [readonly]="isReadonly" formControlName="supplierRef">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bought from</mat-label>
        <input readonly matInput [value]="supplierName$ | async">
        <!-- <a href="#"><img src="../../../../../../../assets/images/icons/search-icon.png" alt="icon"/></a> -->
      </mat-form-field>
      <mat-form-field>
        <mat-label>Supplier Contact(s)</mat-label>
        <input readonly matInput [value]="supplierContacts$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Pickup Location</mat-label>
        <input readonly matInput [value]="originLocationName$ | async">
      </mat-form-field>
      <ng-container [ngSwitch]="isReadonly">
        <mat-checkbox *ngSwitchCase="true" [checked]="detailsForm.controls.prepaymentMade.value">Prepaid</mat-checkbox>
        <mat-checkbox *ngSwitchDefault formControlName="prepaymentMade">Prepaid</mat-checkbox>
      </ng-container>
      <mat-form-field>
        <mat-label>Pricing terms</mat-label>
        <input readonly matInput [value]="supplierPricingTerms$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Payment terms</mat-label>
        <input readonly matInput [value]="detailsForm.supplierPaymentTerms$ | async">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Invoice number</mat-label>
        <input readonly matInput [value]="supplierInvoiceId$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice date</mat-label>
        <input readonly matInput [value]="supplierInvoiceDate$ | async | epoch:'l'">
      </mat-form-field>
      <!-- TODO: handle changes - calculate supplier_anticipated_liability -->
      <tc-epoch-field placeholder="Liab. term date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="supplierTermDate"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>Ant. liab date</mat-label>
        <input readonly matInput [value]="suplierAntLiabilitySnapshot$ | async | epoch:'l'">
      </mat-form-field>
      <!-- SER-149, WA-1724: check this field mapping. we override invoice.due? -->
      <tc-epoch-field placeholder="Actual liab date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="antLiabilityDate"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>FX ASK rate</mat-label>
        <input readonly matInput [value]="detailsForm.askRate$ | async"
            [matTooltip]="detailsForm.usingAskRates$ | async" matTooltipPlacement="top" [matTooltipShowDelay]="3000" matTooltipClass="nya-bs-option-tooltip">
      </mat-form-field>
      <tc-epoch-field placeholder="Scheduled pickup date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="scheduledPickupDate"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>Supplier Pickup Range</mat-label>
        <input readonly matInput [value]="shipmentDatesRange$ | async">
      </mat-form-field>
      <tc-epoch-field placeholder="Date confirmed" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="supplierConfirmedAt"
          ></tc-epoch-field>
      <div gdColumn="span 2">
        <button mat-button type="button" (click)="addSupplierInstructions()" *ngIf="!isReadonly">
          <i class="fa fa-20 fa-file-plus" alt="icon"></i>&nbsp;
          {{(hasSupplierInstructions$ | async) ? 'EDIT' : 'ADD'}} SUPPLIER SPECIAL INSTRUCTIONS
        </button>
      </div>
    </div>

    <div class="tc-sold-to" gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
      <h2 class="title">Sold to</h2>
      <mat-form-field>
        <mat-label>Selling trader</mat-label>
        <input readonly matInput [value]="supplierTraderFullname$ | async">
      </mat-form-field>
      <mat-form-field *ngIf="!isReadonly">
        <mat-label>Buyer ref. number</mat-label>
        <input matInput [readonly]="isReadonly" formControlName="buyerRef">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sold to</mat-label>
        <input readonly matInput [value]="buyerName$ | async">
        <!-- <a href="#"><img src="../../../../../../../assets/images/icons/search-icon.png" alt="icon"/></a> -->
      </mat-form-field>
      <mat-form-field>
        <mat-label>Buyer Contact(s)</mat-label>
        <input readonly matInput [value]="buyerContacts$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Delivered Location</mat-label>
        <input readonly matInput [value]="destLocationName$ | async">
      </mat-form-field>
      <ng-container [ngSwitch]="isReadonly">
        <mat-checkbox *ngSwitchCase="true" [checked]="detailsForm.controls.prepaymentReceived.value">Prepaid</mat-checkbox>
        <mat-checkbox *ngSwitchDefault formControlName="prepaymentReceived">Prepaid</mat-checkbox>
      </ng-container>
      <mat-form-field>
        <mat-label>Pricing terms</mat-label>
        <input readonly matInput [value]="buyerPricingTerms$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Payment terms</mat-label>
        <input readonly matInput [value]="detailsForm.buyerPaymentTerms$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice number</mat-label>
        <input readonly matInput [value]="buyerInvoiceId$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice date</mat-label>
        <input readonly matInput [value]="buyerInvoiceDate$ | async | epoch:'l'">
      </mat-form-field>

      <tc-epoch-field placeholder="Actual invoice date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="actualInvoiceDate"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>Ant. invoice date</mat-label>
        <input readonly matInput [value]="invoiceDateSnapshot$ | async | epoch:'l'">
      </mat-form-field>
      <tc-epoch-field placeholder="Act. pmt. term date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="buyerTermDate"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>Ant. pmt. term date</mat-label>
        <input readonly matInput [value]="buyerTermDateSnapshot$ | async | epoch:'l':'utc'">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ant. due date</mat-label>
        <input readonly matInput [value]="detailsForm.antDueDate$ | async | epoch:'l':'utc'">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Average Days to Due Date</mat-label>
        <input readonly matInput [value]="buyerAvgDaysToDueDate$ | async">
      </mat-form-field>
      <tc-epoch-field placeholder="Actual Col. date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="collectionDate"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>Ant. Col. date</mat-label>
        <input readonly matInput [value]="collectionDateSnapshot$ | async | epoch:'l'">
      </mat-form-field>
      <tc-epoch-field placeholder="Scheduled del. date" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="scheduledDeliveryDate"
          ></tc-epoch-field>
      <tc-epoch-range-field placeholder="Buyer Delivery Range" [readonly]="isReadonly"
          [useUtc]="true"
          [useTemplates]="true"
          [min]="detailsForm.controls.shipmentDatesFrom.value || TODAY"
          [formGroup]="detailsForm"
          startCtrlName="deliveryDatesFrom"
          endCtrlName="deliveryDatesTo"
          tbdCtrlName="deliveryDatesTbd"
          comparisonStartCtrlName="shipmentDatesFrom"
          comparisonEndCtrlName="shipmentDatesTo"
          ></tc-epoch-range-field>
      <tc-epoch-field placeholder="Date confirmed" [readonly]="isReadonly"
          [group]="detailsForm" ctrlName="buyerConfirmedAt"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>FX BID rate</mat-label>
        <input readonly matInput [value]="detailsForm.bidRate$ | async"
            [matTooltip]="detailsForm.usingBidRates$ | async" matTooltipPlacement="top" [matTooltipShowDelay]="3000" matTooltipClass="nya-bs-option-tooltip">
      </mat-form-field>
      <div gdColumn="span 2">
        <button mat-button type="button" (click)="addBuyerInstructions()" *ngIf="!isReadonly">
          <i class="fa fa-20 fa-file-plus" alt="icon"></i>&nbsp;
          {{(hasBuyerInstructions$ | async) ? 'EDIT' : 'ADD'}} BUYER SPECIAL INSTRUCTIONS
        </button>
      </div>
    </div>
  </div>
</form>
