import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatDialogModule } from "@angular/material/dialog"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { ReactiveAsteriskModule } from "src/components/reactive-asterisk/reactive-asterisk.module"
import { SelectSearchModule } from "src/components/select-search/select-search.module"
import { ModalModule } from "src/shared/modal"
import { PipesModule } from "src/shared/pipes/pipes.module"
import { ItemTypeFormComponent } from "./item-type-form.component"
import { ItemTypeFormService } from "./item-type-form.service"

@NgModule({
  declarations: [ItemTypeFormComponent],
  providers: [ItemTypeFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    ModalModule,
    SelectSearchModule,
    FlexLayoutModule,
    PipesModule,
    CdkDropList,
    CdkDrag,
  ]
})
export class ItemTypeFormModule {}
