import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { AesResponseStatus, Segment } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'
import { AesCommodityExportReport } from '@tradecafe/types/core'
import {ReportsRes} from '../app/store/aes'

const { apiUrl } = environment

export default module =>
  module.service('AESApi', downgradeInjectable(AESApiService))


@Injectable()
@AngularCopy()
export class AESApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  ping(@AngularParams() params) {
    return this.http.get<{ data: boolean }>(`${apiUrl}/ping`, { }).toPromise()
  }

  create(params) {
    return this.http.post<AesCommodityExportReport>(`${apiUrl}/aes/commodity-reports`, {deal_id: params}).toPromise()
  }


  createInBatch(params) {
    return this.http.post<ReportsRes>(`${apiUrl}/aes/commodity-reports/batch`, {deal_ids: params}).toPromise()
  }

  get(id: string) {
    return this.http.get<Segment>(`${apiUrl}/aes/commodity-reports/${id}`).toPromise()
  }

  search(id: string, params) {
    return this.http.post<boolean>(`${apiUrl}/aes/commodity-reports/search?limit=10&offset=0`, params).toPromise()
  }

  setResponseStatus(id: string, status: AesResponseStatus) {
    return this.http.post<{ status: AesResponseStatus }>(`${apiUrl}/aes/commodity-reports/${id}/response-status`, { status }).toPromise()
  }

  cancel(id: string) {
    return this.http.post<AesCommodityExportReport>(`${apiUrl}/aes/commodity-reports/${id}/cancel`, {}).toPromise()
  }
}
