import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { AccountObject, Country, GeneralAddress } from '@tradecafe/types/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { mapValues, sortBy } from 'lodash-es'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { selectAllDepartments } from 'src/app/store/departments'
import { loadDepartments } from 'src/app/store/departments/department.actions'
import { GeoService } from 'src/pages/admin/settings/locations/location-form/geo.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'


export interface AddressFormOptions {
  address?: GeneralAddress
  company?: AccountObject
}

@Component({
  selector: 'tc-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private dialog: MatDialogRef<AddressFormComponent, GeneralAddress>,
    private store: Store,
    private toaster: ToasterService,
    private Geo: GeoService,
    private fb: UntypedFormBuilder,
    private AuthApi: AuthApiService,
    @Inject(MAT_DIALOG_DATA) public options: AddressFormOptions,
  ) {
    super()
  }

  isNew = !this.options.address
  isTradecafe = this.options.company.account === this.AuthApi.currentUser.account


  addressForm = this.fb.group({
    name: [, Validators.required],
    primary: [], // boolean<=>0|1
    consignee: [], // boolean<=>0|1
    def_delivery: [], // boolean<=>0|1
    inalfresco: [], // boolean<=>0|1
    aes_address: [], // boolean<=>0|1
    department: [],
    establishment: [],
    street1: [],
    street2: [],
    city: [, Validators.required],
    state: [],
    state_code: [],
    country: [], // hidden
    cc: [, Validators.required],
    postal: [],
    telephone1: [],
    telephone1_ext: [],
    email: [],
    telephone2: [],
    telephone2_ext: [],
    fax: [],
    fax_ext: [],
    tax_id: [],
    note: [],
    montshipCode: [],
    contact_name: [],
  })

  countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty(), map(c => sortBy(c, 'name')))
  departments$ = this.store.pipe(select(selectAllDepartments), waitNotEmpty(), map(x => sortBy(x, 'name')))
  states$ = replayForm(this.addressForm.controls.cc).pipe(map(cc => this.Geo.getStates(cc)), map(x => sortBy(x, 'name')))
  cities$ = replayForm(this.addressForm.controls.cc).pipe(map(cc => this.Geo.getCities(cc)), map(x => sortBy(x, 'name')))


  ngOnInit(): void {
    if (this.options.address) {
      this.addressForm.patchValue({ ...this.options.address, montshipCode: this.options.address?.integration_codes?.montship })
    }
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadDepartments())
  }

  onStateChange() {
    const { cc, state } = this.addressForm.getRawValue()
    this.addressForm.controls.state_code.setValue(this.Geo.getStateCode(cc, state))
  }

  onCountryChange(country: Country) {
    this.addressForm.controls.country.setValue(country.name)
    this.addressForm.controls.city.setValue(undefined)
    this.addressForm.controls.state.setValue(undefined)
    this.addressForm.controls.state_code.setValue(undefined)
  }

  onInalfrescoChanged(event: MatCheckboxChange) {
    if (!event.checked) {
      return
    }

    const address = this.options.company?.addresses?.find(addr =>
      addr.inalfresco && addr.name !== this.addressForm.controls.name.value)
    if (address) {
      this.toaster.warning('There is already an address checked as INALFRESCO.')
      this.addressForm.controls.inalfresco.setValue(false)
    }
  }

  onAESDesignationChanged(event: MatCheckboxChange) {
    if (!event.checked) {
      return
    }

    const address = this.options.company?.addresses?.find(addr =>
      addr.aes_address && addr.name !== this.addressForm.controls.name.value)
    if (address) {
      this.toaster.warning('There is already an address designated for AES use.')
      this.addressForm.controls.aes_address.setValue(false)
    }
  }

  submit() {
    this.addressForm.markAllAsTouched()
    this.addressForm.updateValueAndValidity()
    if (!this.addressForm.valid) return
    const { montshipCode: montship, ...address } = this.addressForm.value as GeneralAddress & { montshipCode?: string }
    address.primary = address.primary ? 1 : 0
    address.consignee = address.consignee ? 1 : 0
    address.def_delivery = address.def_delivery ? 1 : 0
    address.inalfresco = address.inalfresco ? 1 : 0
    address.aes_address = address.aes_address ? 1 : 0
    if (montship) address.integration_codes = { montship }
    // NOTE: backend doesn't allow empty strings
    this.dialog.close(mapValues(address, v => v || undefined) as GeneralAddress)
  }

  cancel() {
    this.dialog.close()
  }
}
