import { createAction, props } from '@ngrx/store'
import { ProductWrapping, TableKey } from '@tradecafe/types/core'


export const loadWrappingTypes = createAction(
  '[WrappingType/API] Load Wrapping Types',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadWrappingTypesSuccess = createAction(
  '[WrappingType/API] Load Wrapping Types Success',
  props<{
    tableKey?: TableKey,
    wrappingTypes: ProductWrapping[],
  }>(),
)
export const loadWrappingTypesFailure = createAction(
  '[WrappingType/API] Load Wrapping Types Failure',
  props<{ error: Error }>(),
)
