import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, AccountType, Cost, DealPartyE, DeliveryPreference, GeneralAddress, LocationObject, Note, OFFER_EXPIRED, OFFER_LIMIT_ORDER, Offer, OfferStatus, SPECIAL_INSTRUCTIONS, SupplierOffer, User, VENDORS } from '@tradecafe/types/core'
import { DeepReadonly, printPaymentTerms } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import * as dayjs from 'dayjs'
import { cloneDeep, compact, concat, defaults, filter, find, first, get, isNil, keyBy, minBy, omit, pick, round, set, sortBy, split, sumBy } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, mergeMap, share, take, tap, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { routesByName } from 'src/app/routes'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { loadCurrencies, selectAllCurrencies } from 'src/app/store/currencies'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadLocations, selectAllLocations } from 'src/app/store/locations'
import { loadMeasures, selectAllMeasures } from 'src/app/store/measures'
import { loadPackageTypes, selectAllPackageTypes } from 'src/app/store/package-types'
import { loadPricingTerms, selectAllPricingTerms } from 'src/app/store/pricing-terms'
import { loadProductCategories, selectProteinCategories } from 'src/app/store/product-categories'
import { loadProductTypes } from 'src/app/store/product-types'
import { loadProducts, selectProductEntities } from 'src/app/store/products'
import { loadUsers, selectAllUsers } from 'src/app/store/users'
import { loadWeightTypes, selectAllWeightTypes } from 'src/app/store/weight-types'
import { loadWrappingTypes, selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { AddressFieldPickerOptions } from 'src/components/address-field/address-field.component'
import { environment } from 'src/environments/environment'
import { BuyersGroup, BuyersGroupApiService } from 'src/pages/admin/settings/buyers-groups/buyers-groups-item/buyers-groups.service'
import { AccountsService, sortForHaveTestAccountsAfterReal } from 'src/services/data/accounts.service'
import { CreditPoolService } from 'src/services/data/credit-pool.service'
import { OffersService, isOfferExpired, isOfferFinalized } from 'src/services/data/offers.service'
import { SupplierOffersService } from 'src/services/data/supplier-offers.service'
import { getBwiManagers, getDefaultContacts } from 'src/services/data/users.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { disableIf } from 'src/shared/utils/disable-if'
import { replayForm } from 'src/shared/utils/replay-form'
import { CostFormService } from '../../trading/deal-form/costs-form/costs-form.service'
import { SupplierOfferCloneFormService } from './supplier-offer-clone-form/supplier-offer-clone-form.service'
import { SupplierOfferNoteFormService } from './supplier-offer-note-form/supplier-offer-note-form.service'

export interface SupplierOfferOverlayOptions {
  title?: string
  cloneOffer?: boolean
  isCreation?: boolean
  addMOs?: boolean
  redirect?: string
  excludedBuyerIds?: number[]
}

export interface SupplierOfferOverlayData {
  offer: Offer,
  options: SupplierOfferOverlayOptions,
}

interface SupplierOfferRequest extends Partial<SupplierOffer> {
  countries?: string[],
  locations?: string[],
  expirationTime?: number,
}

@Component({
  selector: 'tc-supplier-offer-overlay',
  templateUrl: './supplier-offer-overlay.component.html',
  styleUrls: ['./supplier-offer-overlay.component.scss'],
})
export class SupplierOfferOverlayComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private AuthApi: AuthApiService,
    private store: Store,
    private SupplierOfferCloneForm: SupplierOfferCloneFormService,
    private CostForm: CostFormService,
    private Accounts: AccountsService,
    private CreditPool: CreditPoolService,
    private Offers: OffersService,
    private SupplierOfferNoteForm: SupplierOfferNoteFormService,
    private SupplierOffers: SupplierOffersService,
    private toaster: ToasterService,
    private router: Router,
    private buyersGroupService: BuyersGroupApiService,
    private dialogRef: MatDialogRef<SupplierOfferOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData: SupplierOfferOverlayData,
  ) {
    super()

    this.enableRouteService = environment.enableRouteService ?? false;

    this.offer = dialogData.offer
    this.auditLogFilter = { offer_id: this.offer?.offer_id }
    this.options = dialogData.options || {}
    this.canEditOffer = this.options.addMOs || !this.offer?.offer_id
    this.excludedBuyerIds = this.options.excludedBuyerIds?.map(x => parseFloat(x as any))

    this.dialogRef?.beforeClosed().subscribe(({ $value: offer } = {}) => this.cacheOffer(offer))
  }

  protected get status() {
    return this.offer && this.Offers.isExpired(this.offer) ? OFFER_EXPIRED : this.offer.status
  }

  protected get costsCadTotal() {
    return sumBy(this.offerForm.controls.costs.value, 'amount.total_cad')
  }

  enableRouteService: boolean = false;

  private excludedBuyerIds?: number[]
  private loadedProduct = false
  private loadedSupplier = false
  private readonly CACHE_KEY = `supplier-offer-cache-${this.AuthApi.currentUser.user_id}`
  private readonly CACHED_FIELDS = [
    'account',
    'attributes.additional_specs',
    'attributes.brand',
    'attributes.incoterm_location',
    'attributes.item_type_id',
    'attributes.product_code',
    'attributes.supplier_user_ids',
    'attributes.trader_user_id_supplier',
    'attributes.weight_type_id',
    'attributes.delivery_preference',
    'country',
    'currency',
    'gross_margin',
    'incoterm',
    'packing.package_id',
    'packing.package_measure_id',
    'packing.package_size',
    'packing.packages_count',
    'pickup',
    'product',
    'weight.amount',
    'weight.unit',
    'wrapping',
    'expire',
    'created',
    'costs',
  ]

  protected readonly expirationTimes = [
    dayjs.duration(5, 'minutes'),
    dayjs.duration(10, 'minutes'),
    dayjs.duration(15, 'minutes'),
    dayjs.duration(30, 'minutes'),
    dayjs.duration(1, 'hour'),
    dayjs.duration(2, 'hours'),
    dayjs.duration(3, 'hours'),
  ].map(value => ({ name: value.humanize(), value: value.asSeconds() }))

  protected readonly minDate = dayjs().startOf('day').unix()
  protected readonly costTableDisplayedColumns = ['service', 'provider', 'est_amount', 'est_amount_cad', 'action_menu']
  protected readonly OfferStatus = OfferStatus

  protected offerForm = new FormGroup({
    created: new FormControl<number>(undefined),
    countries: new FormControl<string[]>(undefined),
    locations: new FormControl<string[]>(undefined),
    buyers: new FormControl<string[]>(undefined),
    loads: new FormControl<number>(undefined),
    expire: new FormControl<number>(undefined),
    expirationTime: new FormControl<number>(this.expirationTimes[0].value, Validators.required),
    biddable: new FormControl<boolean>(true),
    account: new FormControl<number>(undefined, Validators.required),
    country: new FormControl<string>(undefined, Validators.required),
    product: new FormControl<string>(undefined, Validators.required),
    weight: new FormGroup({
      unit: new FormControl<string>(undefined, Validators.required),
      amount: new FormControl<number>(undefined, [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]),
    }),
    wo_export_docs: new FormControl<boolean>(undefined),
    notes: new FormControl<Partial<Note[]>>(undefined),
    price: new FormControl<number>(undefined, Validators.required),
    currency: new FormControl<string>(undefined, Validators.required),
    grossMarginPercents: new FormControl<number>(undefined),
    gross_margin: new FormControl<number>(undefined),
    pickup: new FormControl<string>(undefined, Validators.required),
    incoterm: new FormControl<string>(undefined, Validators.required),
    ship_start: new FormControl<number>(undefined, Validators.required),
    ship_end: new FormControl<number>(undefined, Validators.required),
    costs: new FormControl<Cost[]>(undefined),
    packing: new FormGroup({
      packages_count: new FormControl<number>(undefined),
      package_id: new FormControl<string>(undefined, Validators.required),
      package_size: new FormControl<number>(undefined),
      package_measure_id: new FormControl<string>(undefined),
    }),
    quantity: new FormControl<number>(undefined),
    wrapping: new FormControl<string>(undefined, Validators.required),
    attributes: new FormGroup({
      supplier_ref: new FormControl<string>(undefined),
      incoterm_location: new FormControl<string>(undefined),
      supplier_user_ids: new FormControl<string[]>(undefined, Validators.required),
      trader_user_id_supplier: new FormControl<string>(undefined, Validators.required),
      establishments: new FormControl<GeneralAddress[]>(undefined),
      weight_type_id: new FormControl<string>(undefined),
      brand: new FormControl<string>(undefined),
      product_code: new FormControl<string>(undefined),
      additional_specs: new FormControl<string>(undefined),
      item_type_id: new FormControl<string>(undefined, Validators.required),
      delivery_preference: new FormControl<DeliveryPreference>(undefined, Validators.required),
    }),
  })

  protected options: SupplierOfferOverlayOptions
  protected offer: Offer = null

  protected auditLogFilter
  protected buyersDictionary: Dictionary<DeepReadonly<AccountObject>>
  protected buyersGroupsDictionary: Dictionary<DeepReadonly<BuyersGroup>>
  protected locationsDictionary: Dictionary<DeepReadonly<LocationObject>>
  protected accountsDictionary: Dictionary<DeepReadonly<AccountObject>>
  protected inProgress$ = new BehaviorSubject<string>(undefined)
  protected canAttachMatchOffers$ = combineLatest([this.inProgress$, replayForm(this.offerForm.controls.buyers)]).pipe(map(([inProgress, buyers]) => !!buyers?.length && inProgress))
  protected canEditOffer: boolean

  // reference values
  protected countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty(), map(c => sortBy(c, 'name')), untilComponentDestroyed(this))
  protected currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty(), untilComponentDestroyed(this))
  protected itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty(), untilComponentDestroyed(this))
  protected locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty(), map(c => sortBy(c, 'name')), untilComponentDestroyed(this))
  private locationsDictionary$ = this.locations$.pipe(map(locations => keyBy(locations, 'location_id')), untilComponentDestroyed(this))
  protected measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty(), map((measures) => measures?.filter(m => m.code === 'KG' || m.code === 'LB')), take(1))
  protected packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty(), untilComponentDestroyed(this))
  protected pricingTerms$ = this.store.pipe(select(selectAllPricingTerms), waitNotEmpty(), untilComponentDestroyed(this))
  private productCategories$ = this.store.pipe(select(selectProteinCategories()), waitNotEmpty(), untilComponentDestroyed(this))
  private productsEntities$ = this.store.pipe(select(selectProductEntities), waitNotEmpty())
  protected users$ = this.store.pipe(select(selectAllUsers), waitNotEmpty(), untilComponentDestroyed(this), share())
  private usersDictionary$ = this.users$.pipe(map((users) => keyBy(users, 'user_id')), untilComponentDestroyed(this))
  private bwiManagers$ = this.users$.pipe(map(users => getBwiManagers(users)), untilComponentDestroyed(this))
  private accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(),
    map((accounts) => accounts.filter(ac => !ac.archived && ac.status === ACCOUNT_ACTIVE)),
    map((accounts) => sortBy(accounts, ac => ac.name?.toLowerCase())),
    take(1),
    untilComponentDestroyed(this),
  )
  protected accountsDictionary$ = this.accounts$.pipe(map(accounts => keyBy(accounts, 'account')), untilComponentDestroyed(this))
  protected weightTypes$ = this.store.pipe(select(selectAllWeightTypes), waitNotEmpty(), untilComponentDestroyed(this))
  protected wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty(), untilComponentDestroyed(this))
  private buyers$ = this.accounts$.pipe(map(account => account.filter((ac) => ac.type === AccountType.BUYER && this.isManagedAccount(ac))), untilComponentDestroyed(this))
  private activeBuyers$ = this.buyers$.pipe(map(buyers => buyers?.filter(b => b.status === ACCOUNT_ACTIVE)), untilComponentDestroyed(this))
  protected suppliers$ = this.accounts$.pipe(
    map(accounts => accounts.filter((ac) => ac.type === AccountType.SUPPLIER)),
    map(accounts => this.AuthApi.currentUser?.role === 'trader'
      ? accounts.filter((account) => account.manager === this.AuthApi.currentUser?.user_id || account.managers?.includes(this.AuthApi.currentUser?.user_id))
      : accounts),
    untilComponentDestroyed(this),
  )
  private suppliersDictionary$ = this.suppliers$.pipe(map((suppliers) => keyBy(suppliers, 'account')), take(1))
  private supplierUsers$ = combineLatest([this.users$, replayForm(this.offerForm.controls.account).pipe(distinctUntilChanged())]).pipe(
    untilComponentDestroyed(this),
    map(([users, account]) => {
      return users.filter(user => user.account === parseFloat(account as any) &&
        (user.role === 'administrator' || user.attributes.designated === '1'))
    }),
    share(),
    tap(async () => await this.setNotesSupplierCompany()),
  )

  private selectedSupplier$ = combineLatest([this.suppliersDictionary$, replayForm(this.offerForm.controls.account).pipe(distinctUntilChanged())]).pipe(
    untilComponentDestroyed(this),
    map(([suppliers, account]) => suppliers[account]),
  )

  protected supplierPaymentTerms$ = replayForm(this.offerForm.controls.account).pipe(
    distinctUntilChanged(),
    mergeMap(async account => {
      const paymentTerms = await this.CreditPool.getPaymentTerms(account, DealPartyE.supplier)
      return printPaymentTerms(paymentTerms)
    }))

  protected selectableBuyers$ = combineLatest([
    this.activeBuyers$,
    replayForm(this.offerForm.controls.product).pipe(distinctUntilChanged()),
    replayForm(this.offerForm.controls.countries).pipe(distinctUntilChanged()),
    replayForm(this.offerForm.controls.locations).pipe(distinctUntilChanged()),
    replayForm(this.offerForm.controls.attributes.controls.item_type_id).pipe(distinctUntilChanged()),
    this.locationsDictionary$,
  ]).pipe(
    untilComponentDestroyed(this),
    map(([buyers, product, countries, selectedLocations, itemTypeId, allLocations]): DeepReadonly<AccountObject>[] => {
      this.buyersDictionary = keyBy(buyers, 'account')
      this.locationsDictionary = allLocations
      // Based on the combination of selections in the form dropdowns (products, countries, locations, item types)
      // filter all the buyer accounts into only those eligible
      return this.getEligibleBuyers(buyers, product, itemTypeId, countries, allLocations, selectedLocations)
    }),
    share(),
    tap((buyers) => this.unselectIneligibleBuyers(buyers)),
    mergeMap(async buyers => {
      let buyerGroups = []
      try {
        buyerGroups = await this.buyersGroupService.list()
        this.buyersGroupsDictionary = keyBy(buyerGroups, 'group_id')
      } catch (err) {
        console.error(`Unable to load buyers group`, err)
      }

      return [
        ...buyerGroups.map((group) => ({ account: group.group_id, ...group })),
        ...sortForHaveTestAccountsAfterReal(buyers),
      ]
    }),
  )

  protected supplierContacts$ = combineLatest([this.bwiManagers$, this.supplierUsers$.pipe(withLatestFrom(this.selectedSupplier$))]).pipe(
    untilComponentDestroyed(this),
    map(([bwiManagers, [supplierUsers, supplier]]): DeepReadonly<User>[] => {
      const isBwiInventory = this.Accounts.isBwiInventory(supplier)
      return isBwiInventory ? bwiManagers : supplierUsers
    }),
    share(),
  )

  protected buyingTraders$ = combineLatest([this.usersDictionary$, this.selectedSupplier$]).pipe(
    untilComponentDestroyed(this),
    map(([usersDict, supplier]): DeepReadonly<User>[] => {
      if (!supplier) return [] // If we don't have a supplier, we don't have buying traders, do we?

      // Pick out all the users that are in the supplier's managers list, and sort the array of them by their full names
      const managers = compact(concat(supplier.manager, supplier.managers));
      return sortBy(pick(usersDict, managers), 'fullname')
    }),
  )

  protected establishmentAddressOptions$: Observable<AddressFieldPickerOptions> = combineLatest([
    replayForm(this.offerForm.controls.account).pipe(distinctUntilChanged()),
    replayForm(this.offerForm.controls.attributes.controls.establishments).pipe(distinctUntilChanged()),
  ]).pipe(
    untilComponentDestroyed(this),
    map(([account, establishments]) => {
      return ({
        accountId: account,
        addresses: establishments,
        title: 'Select Establishment',
        showEstablishment: true,
        allowMultiple: true,
        limit: 3,
      })
    }),
  )

  protected readonly isExpired = isOfferExpired
  protected readonly isFinalized = isOfferFinalized

  ngOnInit(): void {
    // Trigger loading a bunch of data from storage via Observables
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadMeasures({}))
    this.store.dispatch(loadPackageTypes({}))
    this.store.dispatch(loadPricingTerms({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({ archived: 0 }))
    this.store.dispatch(loadWeightTypes({}))
    this.store.dispatch(loadWrappingTypes({}))

    // Get the most recent offer that's been cached locally (or a default if there isn't one)
    const offer = defaults(this.offer || this.readCachedOffer(), {
      type: OFFER_LIMIT_ORDER,
      attributes: { delivery_preference: DeliveryPreference.cost },
      created: 0,
    })

    if (isNil(offer.attributes.delivery_preference)) {
      offer.attributes.delivery_preference = DeliveryPreference.cost;
    }

    // Account is _supposed_ to be a number here, so we'll try and parse it out in case it isn't
    offer.account = parseFloat(offer.account as any)

    // Make a clone of the offer, filling in required fields as needed
    this.offer = defaults(cloneDeep(offer), { weight: {}, packing: {}, attributes: {}, costs: [] })

    // Patch the offer object into the form
    this.offerForm.patchValue(this.offer)

    // These checkboxes/radio-groups should not be enabled if the SO exists or is not editable
    disableIf(this.offerForm.controls.wo_export_docs, !!this.offer?.offer_id);
    disableIf(this.offerForm.controls.biddable, !!this.offer?.offer_id);
    disableIf(
      this.offerForm.controls.attributes.controls.delivery_preference,
      !this.canEditOffer && environment.enableRouteService
    );

    replayForm(this.offerForm.controls.packing.controls.packages_count)
      .subscribe(count => this.offerForm.controls.quantity.setValue(count || 0))

    // Set up a Observable trigger to prefill/refill the supplier fields if any of the Observables listed changes
    combineLatest([
      this.supplierContacts$.pipe(withLatestFrom(this.selectedSupplier$)),
      this.users$,
      this.accountsDictionary$,
    ]).pipe(untilComponentDestroyed(this)).subscribe(([[supplierContacts, selectedSupplier], users, accountsDictionary]): void => {
      // If it's the first time, it's handled by the readCachedOffer stuff above
      // Otherwise, we should attempt to prefill some fields for the supplier
      if (this.loadedSupplier && this.options.isCreation) {
         this.prefillSupplierFields(supplierContacts, selectedSupplier, users)
      }

      this.loadedSupplier = true
      this.accountsDictionary = accountsDictionary
    })

    replayForm(this.offerForm.controls.product).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(() => {
      // If it's the first time, it's handled by the readCachedOffer stuff above
      // Otherwise, clear out some of the other product related fields automatically
      if (this.loadedProduct && this.options.isCreation) {
        this.removeProductSpecs()
      }
      this.loadedProduct = true
    })

    if (offer?.gross_margin) {
      this.offerForm.controls.grossMarginPercents.setValue(round(offer.gross_margin * 100, 4))
    }

    // If this is a new offer (no offer id yet) but it has an expiry (probably from the cached offer)
    // use it to pre-set the expiry field accordingly
    if (!offer?.offer_id && offer.expire) {
      this.offerForm.controls.expirationTime.setValue((offer.expire - offer.created) || 0)
      const expirationTime: {
        name: string,
        value: number
      } = minBy(this.expirationTimes, ({ value }) => Math.abs(this.offerForm.controls.expirationTime.value - value))
      this.offerForm.controls.expirationTime.setValue(expirationTime.value)
    }
  }

  protected dismiss() {
    this.dialogRef.close()
  }

  protected async saveOffer(offer: Offer) {
    if (this.inProgress$.value) return

    this.offerForm.markAllAsTouched()
    this.offerForm.updateValueAndValidity()
    this.areProductSpecificationsValid()

    if (!this.offerForm.valid) return

    if (!offer.offer_id) {
      this.offerForm.controls.expire.setValue(dayjs().unix() + this.offerForm.controls.expirationTime.value)
      if (this.offerForm.controls.grossMarginPercents.value) {
        this.offerForm.controls.gross_margin.setValue(round(this.offerForm.controls.grossMarginPercents.value / 100, 6))
      }
    }

    this.handleBuyerGroup()

    const request: SupplierOfferRequest = omit(this.offerForm.getRawValue(), ['expirationTime', 'grossMarginPercents'])
    request.quantity = request.quantity || 0
    request.type = OFFER_LIMIT_ORDER

    if (request.notes?.length > 0) {
      request.notes = [
        pick(first(request.notes), [
          'attributes.category',
          'attributes.company',
          'visibility',
          'body',
          'account',
          'user_id',
        ]),
      ] as Note[]
    }

    this.inProgress$.next('save')
    try {
      offer = offer.offer_id ? await this.Offers.update(request) : await this.SupplierOffers.create(request)
      this.toaster.success('Offer saved')
      Object.assign(this.offer, offer)
      this.dialogRef.close({ $value: offer })

      if (this.options?.redirect) {
        const redirectUrl = first(split(routesByName[this.options.redirect]?.url || '', '?'))
        this.router.navigateByUrl(redirectUrl)
      }
    } catch (err) {
      console.error(`Unable to save Offer ${offer.offer_id || ''}`, err)
      this.toaster.error(`Unable to save Offer ${offer.offer_id || ''}`, err)
      throw err
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected async cloneOffer() {
    if (this.inProgress$.value) return

    this.offerForm.markAllAsTouched()
    this.offerForm.updateValueAndValidity()
    this.areProductSpecificationsValid()

    if (!this.offerForm.valid) return

    this.offerForm.controls.expire.setValue(dayjs().unix() + this.offerForm.controls.expirationTime.value)
    const request: SupplierOfferRequest = omit(this.offerForm.getRawValue(), ['expirationTime', 'grossMarginPercents'])
    request.offer_id = this.offer.parent_offer_id
    const { shipmentPeriods: shipment_periods, prioritizeMatchedOffer } = await this.SupplierOfferCloneForm.show(this.offer)
    request.prioritize_mo = prioritizeMatchedOffer
    this.offerForm.controls.expire.setValue(dayjs().unix() + this.offerForm.controls.expirationTime.value)

    this.inProgress$.next('save')
    try {
      const payload = { ...{ offer: { ...this.offer, ...request } }, ...{ shipment_periods } }
      await this.SupplierOffers.clone(payload)
      this.toaster.success('Offer cloned')
      this.dialogRef.close()
    } catch (err) {
      console.error(`Unable to clone Offer ${this.offer.offer_id || ''}`, err)
      this.toaster.error(`Unable to clone Offer ${this.offer.offer_id || ''}`, err)
      throw err
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected async attachMatchOffers() {
    if (this.inProgress$.value) return
    this.inProgress$.next('save')

    try {
      const payload = { ...this.offer, ...omit(this.offerForm.getRawValue(), ['expirationTime', 'grossMarginPercents']) }
      await this.SupplierOffers.attachMO(payload)
      this.toaster.success('Matched offers are being attached')
      this.dialogRef.close()
    } catch (err) {
      console.error(`Unable to attach Matched offer(s) ${this.offer.offer_id || ''} `, err)
      this.toaster.error(`Unable to attach Matched offer(s) ${this.offer.offer_id || ''} `, err)
      throw err
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected async addTertiaryCost() {
    const cost = await this.CostForm.create({
      supplier_id: this.offerForm.controls.account.value?.toString(),
    })
    const costs = this.offerForm.controls.costs.value || []
    this.offerForm.controls.costs.setValue([...costs, cost])
  }

  protected deleteTertiaryCost(cost: Cost) {
    const costs = this.offerForm.controls.costs.value || []
    const index = costs.indexOf(cost)
    costs.splice(index, 1)
    this.offerForm.controls.costs.setValue([...costs])
  }

  protected hasSupplierInstructions() {
    return (this.offerForm.getRawValue()).notes?.find(note =>
      note.body &&
      note.attributes?.company &&
      note.attributes.category === SPECIAL_INSTRUCTIONS &&
      note.visibility === VENDORS,
    )
  }

  protected async addSupplierInstructions() {
    const existing = this.hasSupplierInstructions()
    const note = existing
      ? await this.SupplierOfferNoteForm.updateSupplierOfferNote(first(this.offerForm.get('notes').value))
      : await this.SupplierOfferNoteForm.createSupplierOfferNote({
        attributes: {
          company: [this.offerForm.controls.account.value],
          category: SPECIAL_INSTRUCTIONS,
        },
        visibility: VENDORS,
      })

    this.offerForm.controls['notes'].setValue(note ? [note] : [])
    return note
  }

  /**
   * Retrieves buyer accounts eligible for selection based on the other selected supplier offer properties
   *
   * @private
   * @param {AccountObject[]} buyers
   * @param {string} product
   * @param {string} itemTypeId
   * @param {string[]} countries
   * @param {string[]} allLocations
   * @param {string[]} selectedLocations
   * @returns {AccountObject[]}
   */
  private getEligibleBuyers(buyers: DeepReadonly<AccountObject>[], product: string, itemTypeId: string, countries: string[], allLocations: Dictionary<DeepReadonly<LocationObject>>, selectedLocations: string[]): DeepReadonly<AccountObject>[] {
    return buyers?.filter((buyer) => {
      if (this.excludedBuyerIds && this.excludedBuyerIds.includes(buyer.account)) {
        return false
      }

      let productMatch = buyer.products && buyer.products.includes(product)
      if (buyer.products_spec && itemTypeId) {
        productMatch &&= !!buyer.products_spec.find(x => x.item_type_id ? x.item_type_id === itemTypeId && x.product_id === product : x.product_id === product)
      }
      const addressMatch = buyer.addresses && buyer.addresses.some(addr => countries?.includes(addr.cc))
      const locationMatch = countries?.includes(allLocations[buyer.attributes.default_location]?.country)
      const locationsMatch = buyer.attributes.locations && buyer.attributes.locations.some(x => selectedLocations?.includes(x) || countries?.includes(allLocations[x]?.country))
      return productMatch && (!countries?.length || addressMatch || locationMatch || locationsMatch) && (!selectedLocations?.length || locationsMatch)
    })
  }

  /**
   * Unchecks all selected buyers that aren't allowed for the offer configuration
   *
   * @private
   * @param {DeepReadonly<AccountObject>[]} buyers
   */
  private unselectIneligibleBuyers(buyers: DeepReadonly<AccountObject>[]) {
    let selectedBuyers = this.offerForm.controls.buyers?.value

    if (!this.offer.offer_id && selectedBuyers) {
      // update previous value - remove unavailable buyers
      const buyersDictionary = keyBy(buyers, 'account')
      const newSelectedBuyers = selectedBuyers.filter(buyer => buyersDictionary[buyer] || this.buyersGroupsDictionary[buyer])
      const removedBuyers = selectedBuyers
        .filter(buyer => !newSelectedBuyers.includes(buyer))
      .map(buyer=> this.buyersDictionary[buyer].name)
      if (removedBuyers.length) {
        this.toaster.warning("Mismatched Buyers", removedBuyers.join(","))
      }
      this.offerForm.controls.buyers.setValue(newSelectedBuyers)
    }
  }

  /**
 * Prefill deal fields
 *
 * @private
 * @param {User[]} supplierContacts // The contacts attached to the supplier account
 * (either supplier users _or_ BWI Managers if supplier is BWI Inventory)
 * @param {?AccountObject} supplierAccount // The currently selected supplier account (may be undefined)
 * @param {User[]} users // The current list of all active users
 */
  private prefillSupplierFields(supplierContacts: DeepReadonly<User[]>, supplierAccount: DeepReadonly<AccountObject> | null, users: DeepReadonly<User[]>) {
    const { bwiManager, contact } = getDefaultContacts(supplierContacts, users)
    const isBwiInventory = this.Accounts.isBwiInventory(supplierAccount)

    // Update supplier contacts
    const supplier_user_id = isBwiInventory ? get(bwiManager, 'user_id') : get(contact, 'user_id')
    this.offerForm.controls.attributes.controls.supplier_user_ids.setValue(compact([supplier_user_id]))

    // Update trader and payment terms
    if (this.AuthApi.currentUser.role === 'trader') {
      this.offerForm.controls.attributes.controls.trader_user_id_supplier.setValue(this.AuthApi.currentUser.user_id)
    } else {
      const defaultTraderUser = supplierAccount?.manager ? users.find(u => u.user_id === supplierAccount.manager) : null
      this.offerForm.controls.attributes.controls.trader_user_id_supplier.setValue(defaultTraderUser?.user_id)
    }

    // Update origin geolocation
    const { country, location } = this.getDefaultGeo(supplierAccount)
    this.offerForm.controls.country.setValue(country)
    this.offerForm.controls.pickup.setValue(location)

    // Update a few pricing/measure options that are part of the account's attributes
    const { measure_id, currency, incoterm, tax_location } = get(supplierAccount, 'attributes.pricing') || {}
    this.offerForm.controls.weight.controls.unit.setValue(measure_id)
    this.offerForm.controls.currency.setValue(currency)
    this.offerForm.controls.incoterm.setValue(incoterm)
    this.offerForm.controls.attributes.controls.incoterm_location.setValue(tax_location || this.offerForm.controls.pickup.value)

    // Set the establisment based on the supplier's primary address
    this.offerForm.controls.attributes.controls.establishments.setValue(filter(supplierAccount?.addresses, 'primary'))

    // Set the product to be the supplier's first product
    combineLatest([this.productsEntities$, this.productCategories$]).pipe(take(1)).subscribe(([products, categories]) => {
      const productId = supplierAccount?.products.find((id) => categories.find((c) => c.category_id === products[id].category_id)) ?? null
      this.offerForm.controls.product.setValue(productId)
    })

    // Remove previously saved product specs (since the product is likely changing)
      if (this.options.isCreation) {
          this.removeProductSpecs()
      }
  }

  private getDefaultGeo(supplier: DeepReadonly<AccountObject>) {
    const companyAddress = find(supplier?.addresses, 'primary') || first(supplier?.addresses) // prefer primary

    return {
      country: (companyAddress || {}).cc,
      location: first(get(supplier, 'attributes.locations', [])) as string,
    }
  }

  private removeProductSpecs() {
    this.offerForm.patchValue({
      packing: {
        package_id: null,
        packages_count: null,
        package_size: null,
        package_measure_id: null,
      },
      attributes: {
        item_type_id: null,
        weight_type_id: null,
        brand: null,
        product_code: null,
        additional_specs: null,
      },
      wrapping: null,
    })
  }

  private async setNotesSupplierCompany() {
    const notes = this.offerForm.controls.notes.value
    if (this.hasSupplierInstructions()) {
      set(first(notes), 'attributes.company', [this.offerForm.controls.account.value])
    }
  }

  private readCachedOffer(): Offer {
    const json = localStorage.getItem(this.CACHE_KEY)
    return json ? pick(JSON.parse(json), this.CACHED_FIELDS) as Offer : undefined
  }

  private cacheOffer(offer) {
    // If offer is provided (as it is via saveOffer), just use that object directly, no modification required
    let offerDataToCache

    if (offer) {
      offerDataToCache = offer
    } else {
      // If offer isn't provided directly, the expiry data in the form data is a little
      // strange, so we need to adjust it so it is correct when it's loaded again next time
      offerDataToCache = this.offerForm.getRawValue()

      const created = dayjs().unix()
      const expire = offerDataToCache.expirationTime
      offerDataToCache.created = created
      offerDataToCache.expire = created + expire
    }

    const cachedValue = JSON.stringify(pick(offerDataToCache, this.CACHED_FIELDS))

    localStorage.setItem(this.CACHE_KEY, cachedValue)
  }

  private areProductSpecificationsValid() {
    if (!(this.offerForm.controls.wrapping.valid && this.offerForm.controls.packing.controls.package_id.valid)) {
      this.toaster.error('You need to specify the Wrapping and Type of Package on the Product Specs tab in order to create the offer.')
    }
  }

  private isManagedAccount(account: DeepReadonly<AccountObject>): boolean {
    const managers: string[] = [account.manager, ...(account.managers || [])]
    return this.AuthApi.currentUser.role !== 'trader' || managers.includes(this.AuthApi.currentUser.user_id)
  }

  private handleBuyerGroup() {
    const buyers = this.offerForm.controls.buyers.value
    if (buyers?.length) {
      const selectedBuyers = buyers.filter(buyer => this.buyersDictionary[buyer])
      const selectedGroups = buyers.filter(buyer => this.buyersGroupsDictionary[buyer])
        .map(group => this.buyersGroupsDictionary[group])
      for (let group of selectedGroups) {
        const noAccessBuyers = group.buyers.map(buyer=> this.accountsDictionary[String(buyer)]).filter(buyer => !this.isManagedAccount(buyer))
          .map(buyer => buyer.name)
        if (noAccessBuyers.length) {
          const errorMessage = `Please remove the ${noAccessBuyers.join(',')} from the list ${group.name}. You could update it through the Buyer grouping page in the Settings`
          this.toaster.error(errorMessage)
          throw new Error(errorMessage)
        }
      }
      const groupsBuyers = selectedGroups
        .reduce((acc, cur) => {
          acc.push(...cur.buyers)
          return acc
        }, [])
      const eligibleBuyers = this.getEligibleBuyers(
        groupsBuyers.map(buyer => this.buyersDictionary[buyer]),
        this.offerForm.controls.product.value,
        this.offerForm.controls.attributes.controls.item_type_id.value,
        this.offerForm.controls.countries.value, this.locationsDictionary,
        this.offerForm.controls.locations.value)
        .map(buyer => buyer.account)
      if(!eligibleBuyers.length && selectedGroups.length) {
        const errorMessage = `There are not eligible buyers to create supplier offers. Please update the product, country, location, and item type to get the eligible buyers`
        this.toaster.error(errorMessage)
        throw new Error(errorMessage)
      }
      this.offerForm.controls.buyers.setValue([...new Set([...selectedBuyers, ...eligibleBuyers].map(String))])
    }
  }
}
