<h2 mat-dialog-title>
  {{ dealId }} AES export report request
</h2>
<div class="aes-request-modal-content" mat-dialog-content *ngrxLet="reportRequests$ as reportRequest">
  <div class="request-info">
    <div><span class="request-info-label">Requested by:</span>{{sender | fullname}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Status:</span> <span class="request-info-name"> {{requestStatusDisplayName(reportRequest.active)}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Request sent:</span> {{reportRequest.created_at | epoch:'LL'}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Destination country:</span> <span class="request-info-name">{{toCountry?.name}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">US state of origin:</span> {{originState}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Port of exportation code:</span> <span class="request-info-name">{{reportRequest.port_of_exportation_code}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Port of exportation:</span> {{port?.name}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Port of unlading code:</span> <span class="request-info-name">{{reportRequest.port_of_unlading_code}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Port of unlading:</span> {{portUnlading?.name}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Scac code:</span> <span class="request-info-name">{{reportRequest.scac_code}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Carrier:</span> {{carrier?.name}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Seal number:</span> <span class="request-info-name">{{reportRequest.seal_number}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Container number:</span> {{reportRequest.container_number}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Vessel name:</span> <span class="request-info-name">{{reportRequest.vessel_name}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Batch number:</span> {{reportRequest.batch_number}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Departure date:</span> <span class="request-info-name"> {{reportRequest.departure_date | epoch:'LL':'utc'}}</span></div>
  </div>
  <div class="request-info">
    <div><span class="request-info-label">Bill of lading:</span> {{reportRequest.bill_of_lading_no}}</div>
    <div class="request-status-wrapper"><span class="request-info-label">Arrival date:</span> <span class="request-info-name"> {{reportRequest.arrival_date | epoch:'LL':'utc'}}</span></div>
  </div>
  <div *ngIf="reportRequest?.validation_errors[0]" class="aes-errors">
    <div class="aes-errors-title">The request wasn't submitted to AES because of the following issues</div>
    <div class="aes-errors-item" *ngFor="let error of reportRequest.validation_errors">
        {{error.message}}
    </div>
  </div>
  <div class="aes-conditions-container" *ngIf="reportRequest.pickup_date || reportRequest.pickup_location">
    <table class="table modal-table">
      <tr>
        <th class="col-head bd-bottom">Pickup Date</th>
        <th class="col-head bd-bottom">Name</th>
        <th class="col-head bd-bottom">Address</th>
      </tr>
      <tr>
        <td class="bd-bottom">{{reportRequest.pickup_date | epoch:'LL':'utc'}}</td>
        <td class="bd-bottom">{{reportRequest.pickup_location.name}}</td>
        <td class="bd-bottom">{{formatLocation(reportRequest.pickup_location)}}</td>
      </tr>
    </table>
  </div>
  <div class="aes-conditions-container">
    <table  class="table modal-table">
      <tr>
        <th class="col-head bd-bottom">Product</th>
        <th class="col-head bd-bottom">Code</th>
        <th class="col-head bd-bottom">Selling Weight</th>
        <th class="col-head bd-bottom">Gross Weight</th>
        <th class="col-head bd-bottom">Value</th>
        <th class="col-head bd-bottom">Description</th>
      </tr>
      <tr *ngFor="let item of reportRequest.line_items">
        <td class="bd-bottom product-name" [title]="(products$ | async)?.[item.product_id]?.name">{{(products$ | async)?.[item.product_id]?.name}}</td>
        <td class="bd-bottom">{{item.schedule_b_code}}</td>
        <td class="bd-bottom">{{item.selling_weight_kg}} KG</td>
        <td class="bd-bottom">{{item.gross_weight_kg}} KG</td>
        <td class="bd-bottom">{{item.value_usd}} USD</td>
        <td class="bd-bottom">{{item.commodity_description}}</td>
      </tr>
    </table>
  </div>
  <div class="aes-conditions-container">
    <table  class="table modal-table">
      <tr>
        <th class="col-head bd-bottom">Type</th>
        <th class="col-head bd-bottom">Name</th>
        <th class="col-head bd-bottom">Contact Name</th>
        <th class="col-head bd-bottom">Contact Phone</th>
        <th class="col-head bd-bottom">Address</th>
      </tr>
      <tr>
        <td class="bd-bottom">{{reportRequest.usppi.type}}</td>
        <td class="bd-bottom">{{reportRequest.usppi.name}}</td>
        <td class="bd-bottom">{{formatPartyContactName(reportRequest.usppi)}}</td>
        <td class="bd-bottom">{{reportRequest.usppi.contact_phone}}</td>
        <td class="bd-bottom">{{formatPartyAddress(reportRequest.usppi)}}</td>
      </tr>
      <tr *ngIf="reportRequest?.consignee?.type">
        <td class="bd-bottom">{{reportRequest.consignee.type}}</td>
        <td class="bd-bottom">{{reportRequest.consignee.name}}</td>
        <td class="bd-bottom"></td>
        <td class="bd-bottom">{{reportRequest.consignee.contact_phone}}</td>
        <td class="bd-bottom">{{formatPartyAddress(reportRequest.consignee)}}</td>
      </tr>
      <tr *ngIf="reportRequest?.forwarder?.type">
        <td class="bd-bottom">{{reportRequest.forwarder.type}}</td>
        <td class="bd-bottom">{{reportRequest.forwarder.name}}</td>
        <td class="bd-bottom"></td>
        <td class="bd-bottom">{{reportRequest.forwarder.contact_phone}}</td>
        <td class="bd-bottom">{{formatPartyAddress(reportRequest.forwarder)}}</td>
      </tr>
    </table>
  </div>
  <div class="aes-request-scrolling-line">
    <button [disabled]="(isOldestRequest$ | async) || (inProgress$ | async)" mat-button (click)="seeOlderRequest()">See older request</button>
    <button [disabled]="(isMostRecentRequest$ | async) || (inProgress$ | async)" mat-button (click)="seeNewerRequest()">See newer request</button>
  </div>
</div>

<div mat-dialog-actions align="end" *ngrxLet="reportRequests$ as reportRequest">
  <button mat-button (click)="cancel()">Cancel</button>
</div>
