import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { DistributionListObject, Segment, User } from '@tradecafe/types/core';
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed';
import { concat, flatten, intersection, keyBy } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts';
import { loadCarriers, selectAllCarriers } from 'src/app/store/carriers';
import { loadUsers, selectAllUsers } from 'src/app/store/users';
import { environment } from 'src/environments/environment';
import { DealsService } from 'src/services/data/deals.service';
import { uuid } from 'src/services/data/utils';
import { replayForm } from 'src/shared/utils/replay-form';

const { tradecafeAccount } = environment;

export interface DeringerRecipientsDialogOptions {
  dealId: string;
}

type TmpSegment = Segment & { carrierName: string };
type TmpDistributionList = DistributionListObject & { tmpId: string };

@Component({
  selector: 'tc-deringer-recipients-dialog',
  templateUrl: './deringer-recipients-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeringerRecipientsDialogComponent extends OnDestroyMixin {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private dialogData: DeringerRecipientsDialogOptions,
    private dialogRef: MatDialogRef<
      DeringerRecipientsDialogComponent,
      {
        carrierRecipients: User[];
        notificationRecipients: string[];
      }
    >,
    private store: Store,
    private Deals: DealsService
  ) {
    super();
  }

  protected readonly recipientsForm = new FormGroup({
    segments: new FormControl([]),
    selectedDistributionLists: new FormControl([]),
    selectedCarrierContacts: new FormControl<string[]>([]),
    selectedTradeCafeUsers: new FormControl<string[]>([]),
  });

  protected dealview$ = this.Deals.getDealView(this.dialogData.dealId, [
    'deal',
    'offers',
    'segments',
  ]);
  protected dealSegments: TmpSegment[] = [];
  protected allAccounts$ = this.store.pipe(select(selectAllAccounts));
  protected dlistDictionary = null;
  protected allUsers$ = this.store.pipe(select(selectAllUsers));
  private allUsersDictionary = null;
  protected allCarriers$ = this.store.pipe(select(selectAllCarriers));
  protected allAvailableCarrierContacts = [];
  protected allAvailableDistributionLists = [];
  protected allAvailableTradeCafeUsers = [];

  ngOnInit(): void {
    this.store.dispatch(loadCarriers({}));
    this.store.dispatch(loadAccounts({}));
    this.store.dispatch(loadUsers({}));

    combineLatest([this.allCarriers$, this.dealview$])
      .pipe(untilComponentDestroyed(this))
      .subscribe(([allCarriers, dealView]) => {
        const cDict = keyBy(allCarriers, 'carrier_id');
        this.dealSegments = dealView.segments?.map((sm) => {
          return { ...sm, carrierName: cDict[sm.carrier_id].name };
        });
      });

    combineLatest([
      this.allAccounts$,
      this.allUsers$,
      this.allCarriers$,
      this.dealview$,
      replayForm(this.recipientsForm.controls.segments),
    ])
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        ([
          allAccounts,
          allUsers,
          allCarriers,
          dealView,
          selectedSegmentIds,
        ]) => {
          // common
          const selectedSegments =
            dealView.segments?.filter((sm) => {
              return selectedSegmentIds.includes(sm.segment_id);
            }) || [];
          const selectedCarriers = allCarriers.filter((c) => {
            return selectedSegments
              .map((s) => s.carrier_id)
              .filter((cid) => !!cid)
              .includes(c.carrier_id);
          });
          this.allUsersDictionary = keyBy(allUsers, 'user_id');

          // for carrier-contacts
          this.allAvailableDistributionLists =
            this.allAvailableCarrierContacts = allUsers.filter((u) => {
              return selectedCarriers
                .map((c) => c.account)
                .includes(u.account.toString());
            });

          // for tradecafe users
          this.allAvailableTradeCafeUsers = allUsers.filter(
            (u) => u.account === tradecafeAccount
          );

          // for distribution list
          const selectedAccounts = allAccounts.filter((acc) => {
            return selectedCarriers
              .map((c) => c.account)
              .includes(acc.account.toString());
          });
          const dlist = flatten(
            selectedAccounts
              .map((acc) => acc.distribution_lists)
              .filter((dl) => !!dl)
          )
            .filter((dl) => {
              if (
                dl.filters.locations.length > 0 &&
                !dl.filters.locations.includes(dealView.deal.origin_location) &&
                !dl.filters.locations.includes(dealView.deal.dest_location)
              ) {
                return false;
              }
              if (
                dl.filters.suppliers.length > 0 &&
                intersection(dl.filters.suppliers, [
                  dealView.deal.supplier_id.toString(),
                ]).length === 0
              ) {
                return false;
              }
              if (
                dl.filters.products.length > 0 &&
                intersection(
                  dl.filters.products,
                  dealView.offers.map((o) => o.product)
                ).length === 0
              ) {
                return false;
              }
              return true;
            })
            .map((dlist) => ({ ...dlist, tmpId: uuid() }));
          this.dlistDictionary = keyBy(dlist, 'tmpId');
          this.allAvailableDistributionLists = dlist;
        }
      );
  }

  save() {
    let contactIdsFromDlists = [];
    this.recipientsForm.value.selectedDistributionLists.forEach((dlid) => {
      const dl: TmpDistributionList = this.dlistDictionary[dlid];
      contactIdsFromDlists = contactIdsFromDlists.concat(
        dl.contacts?.buyer,
        dl.contacts?.service_provider
      );
    });

    this.dialogRef.close({
      carrierRecipients: this.recipientsForm.value.selectedCarrierContacts.map(
        (id) => this.allUsersDictionary[id]
      ),
      notificationRecipients: concat(contactIdsFromDlists, this.recipientsForm.value.selectedTradeCafeUsers)
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
