import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDatepicker, MatDatepickerControl } from '@angular/material/datepicker'
import { MatFormField } from '@angular/material/form-field'
import { MatSelect } from '@angular/material/select'
import { MtxDatetimepicker } from '@ng-matero/extensions/datetimepicker'
import { IEpochRange, ItemType } from '@tradecafe/types/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { intersection, isEqual } from 'lodash-es'
import { Subject } from 'rxjs'
import { EpochFieldComponent } from '../epoch/epoch-field/epoch-field.component'
import { EpochRangeFieldComponent } from '../epoch/epoch-range-field/epoch-range-field.component'
import { SelectSearchComponent } from '../select-search/select-search.component'

export interface InlineDateRangeOptions extends Partial<IEpochRange> {
  from2?: number
  to2?: number
  min?: number
  max?: number
  useUtc?: boolean
}

export interface InlineDateOptions {
  date?: number
  min?: number
  max?: number
  useUtc?: boolean
  showTimePicker?: boolean
}

@Component({
  selector: 'tc-inline-editor',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditorComponent extends OnDestroyMixin {
  constructor() { super()}

  // internal state
  activeInline: { getter: () => string | number | string[] | number[] |InlineDateOptions|InlineDateRangeOptions, reject: (err: any) => void, resolve: (result: any) => void }

  dropdown$ = new Subject<{ items: any[], bindLabel?: string, bindValue?: string, multiple?: true }>()
  dateOpts$ = new Subject<{ min?: number, max?: number, useUtc: boolean, showTimePicker?: boolean }>()

  inlineForm = new FormGroup({
    selected: new FormControl<string | number | string[] | number[]>(undefined),
    text: new FormControl<string>(undefined),
    number: new FormControl<number>(undefined),
    date: new FormControl<number>(undefined),
    from: new FormControl<number>(undefined),
    to: new FormControl<number>(undefined),
    tbd: new FormControl<string>(undefined),
    from2: new FormControl<number>(undefined),
    to2: new FormControl<number>(undefined),
  })

  // @ViewChild('inlineDateRangePicker')
  // inlineDateRangePicker: MatDateRangePicker<Date>

  // @ViewChild('inlineDateRangePicker')
  // inlineDateRangePicker: EpochRangeFieldComponent

  @ViewChild('inlineDateRange')
  inlineDateRange: EpochRangeFieldComponent

  @ViewChild('inlineText')
  inlineText: MatFormField

  @ViewChild('inlineNumber')
  inlineNumber: MatFormField

  @ViewChild('inlineDate')
  inlineDate: EpochFieldComponent

  @ViewChild('inlineSelect')
  inlineSelect: SelectSearchComponent<ItemType>

  pickDate(el: HTMLElement, getter: () => InlineDateOptions) {
    this.activeInline?.reject('canceled inline editor')
    return new Promise<number>((resolve, reject) => {
      this.activeInline = { getter, resolve, reject }
      const { date, min, max, useUtc, showTimePicker = true } = getter()
      this.dateOpts$.next({ min, max, useUtc, showTimePicker })
      this.inlineForm.reset({ date })
      this.showPicker(el, this.inlineDate, this.inlineDate.picker)
    })
  }

  pickDateRange(el: HTMLElement, getter: () => InlineDateRangeOptions) {
    this.activeInline?.reject('canceled inline editor')
    return new Promise<IEpochRange>((resolve, reject) => {
      this.activeInline = { getter, resolve, reject }
      const { from, to, tbd, from2, to2, min, max, useUtc } = getter()
      this.dateOpts$.next({ min, max, useUtc })
      this.inlineForm.reset({ from, to, tbd, from2, to2 })
      this.showPicker(el, this.inlineDateRange, this.inlineDateRange.picker)
    })
  }

  editText(getter: () => string, cellEl: HTMLElement) {
    this.activeInline?.reject('canceled inline editor')
    return new Promise<string>((resolve, reject) => {
      this.activeInline = { getter, resolve, reject }
      this.inlineForm.reset({ text: getter() })
      this.showPicker(cellEl, this.inlineText)
    })
  }

  editNumber(getter: () => number, cellEl: HTMLElement) {
    this.activeInline?.reject('canceled inline editor')
    return new Promise<number>((resolve, reject) => {
      this.activeInline = { getter, resolve, reject }
      this.inlineForm.reset({ number: getter() })
      this.showPicker(cellEl, this.inlineNumber)
    })
  }

  selectOption(
    opts: { items: any[], bindLabel?: string, bindValue?: string, multiple?: true },
    getter: () => string|number|string[]|number[],
    cellEl: HTMLElement,
  ) {
    this.activeInline?.reject('canceled inline editor')
    return new Promise((resolve, reject) => {
      this.activeInline = { getter, resolve, reject }
      this.dropdown$.next(opts)
      this.inlineForm.reset({ selected: getter() })
      this.showPicker(cellEl, this.inlineSelect, this.inlineSelect.matSelect)
    })
  }


  onDateChanged() {
    if (this.activeInline && this.inlineForm.dirty) {
      const editedValue: number = this.inlineForm.value.date
      if (!isEqual(editedValue, this.activeInline.getter())) {
        this.activeInline.resolve(editedValue)
        delete this.activeInline
      }
    }
    this.hidePicker(this.inlineDate)
  }

  onDateRangeChanged() {
    if (this.activeInline && this.inlineForm.dirty) {
      const originalValue = this.activeInline.getter() as IEpochRange
      const editedValue: IEpochRange = {
        from: this.inlineForm.value.from,
        to: this.inlineForm.value.to,
        tbd: this.inlineForm.value.tbd,
      }
      if (editedValue?.from !== originalValue?.from || editedValue?.to !== originalValue?.to) {
        this.activeInline.resolve(editedValue)
        delete this.activeInline
      }
    }
    this.hidePicker(this.inlineText)
    this.hidePicker(this.inlineDateRange)
  }

  onTextChanged() {
    if (this.activeInline && this.inlineForm.dirty) {
      const editedValue = this.inlineForm.value.text
      if (!isEqual(editedValue, this.activeInline.getter())) {
        this.activeInline.resolve(editedValue)
        delete this.activeInline
      }
    }
    this.hidePicker(this.inlineText)
  }

  onNumberChanged() {
    if (this.activeInline && this.inlineForm.dirty) {
      const editedValue = this.inlineForm.value.number
      if (!isEqual(editedValue, this.activeInline.getter())) {
        this.activeInline.resolve(editedValue)
        delete this.activeInline
      }
    }
    this.hidePicker(this.inlineNumber)
  }

  onInlineSelectChanged() {
    if (this.activeInline && this.inlineForm.dirty) {
      const editedValue: string|number|string[]|number[] = this.inlineForm.value.selected
      if (!isEqual(editedValue, this.activeInline.getter())) {
        this.activeInline.resolve(editedValue)
        delete this.activeInline
      }
    }
    this.hidePicker(this.inlineSelect)
  }


  private showPicker<C extends MatDatepickerControl<D>, S, D>(
    cellEl: HTMLElement,
    formField: EpochRangeFieldComponent | EpochFieldComponent | MatFormField | SelectSearchComponent<ItemType>,
    picker?: MtxDatetimepicker<unknown> | MatDatepicker<unknown> | MatSelect,
  ) {
    const editor = formField._elementRef.nativeElement as HTMLElement

    // tslint:disable-next-line: prefer-const
    let { left, top, width } = cellEl.getBoundingClientRect()

    const cellParents: HTMLElement[] = []
    for (let p = cellEl.parentElement; p && p !== document.documentElement; p = p.parentElement) cellParents.push(p)
    const editorParents: HTMLElement[] = []
    for (let p = editor.parentElement; p && p !== document.documentElement; p = p.parentElement) editorParents.push(p)

    // calculate offset using shared parents
    for (const parent of intersection(cellParents, editorParents)) {
      const position = getComputedStyle(parent).position
      if (position === 'fixed' || position === 'absolute') {
        const r = parent.getBoundingClientRect()
        left -= r.left
        top -= r.top
      }
    }

    Object.assign(editor.style, {
      position: 'absolute',
      display: 'block',
      top: `${top + window.scrollY}px`,
      left: `${left}px`,
      width: `${width}px`,
    })

    if (picker) {
      picker.open()
    } else {
      const input = editor.querySelector('input')
      input.focus()
      input.select()
    }
  }

  private hidePicker(formField: EpochRangeFieldComponent | EpochFieldComponent | MatFormField | SelectSearchComponent<ItemType>) {
    const editor = formField._elementRef.nativeElement as HTMLElement
    Object.assign(editor.style, {
      display: '',
      position: '',
    })
  }
}
