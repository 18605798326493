import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { BehaviorSubject } from 'rxjs'
import { TableDataStatus } from './table-data-status'

export interface IStatefulDataSource<T> extends MatTableDataSource<T> {
  state$: BehaviorSubject<TableDataStatus>
  setPaginator(paginator: MatPaginator): void
  setSort(sort: MatSort): void
  refresh(): void
}

export abstract class StatefulDataSource<T> extends MatTableDataSource<T> {
  state$ = new BehaviorSubject<TableDataStatus>('loading')
  // data$ = new ReplaySubject<T[]>(1)

  abstract setPaginator(paginator: MatPaginator): void
  abstract setSort(sort: MatSort): void
  // TODO: deprecate refresh in favor of ngxr actions and selectors
  abstract refresh(): void
}
