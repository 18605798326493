import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Store, select } from '@ngrx/store'
import { Product, ProductCategory, ProductType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { filter as _filter, identity, pickBy, uniq } from 'lodash-es'
import { Observable, combineLatest } from 'rxjs'
import { delay, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { loadProductCategories, selectAllProductCategories, selectProteinCategories } from 'src/app/store/product-categories'
import { loadProductTypes, selectAllProductTypes } from 'src/app/store/product-types'
import { loadProducts, selectAllProducts, selectProductEntities } from 'src/app/store/products'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm, replayFormStatus } from 'src/shared/utils/replay-form'

@Component({
  selector: 'tc-product-field',
  templateUrl: './product-field.component.html',
  styleUrls: ['./product-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFieldComponent extends OnDestroyMixin implements OnInit {

  @Input() group: UntypedFormGroup
  @Input() ctrlName: string
  @Input() ctrl: UntypedFormControl

  @Input() readonly: boolean
  @Input() protein: boolean

  categoryCtrl = new UntypedFormControl
  typeCtrl = new UntypedFormControl

  filteredCategories$: Observable<DeepReadonly<ProductCategory[]>>
  filteredTypes$: Observable<DeepReadonly<ProductType[]>>
  filteredProducts$: Observable<DeepReadonly<Product[]>>

  @Output() change = new EventEmitter<Product>()

  constructor(private store: Store) { super() }

  ngOnInit() {
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProducts({}))

    const products$ = this.store.pipe(select(selectAllProducts), waitNotEmpty())
    const categories$ = this.store.pipe(select(this.protein ? selectProteinCategories() : selectAllProductCategories), waitNotEmpty())
    const types$ = this.store.pipe(select(selectAllProductTypes), waitNotEmpty())
    const categoryIds$ = products$.pipe(map(products => uniq(products.map(p => p.category_id))))
    const typeIds$ = products$.pipe(map(products => uniq(products.map(p => p.type_id))))
    this.filteredCategories$ = combineLatest([categories$, categoryIds$]).pipe(
      map(([categories, categoryIds]) =>
        categories.filter(category => categoryIds.includes(category.category_id))),
      distinctUntilChanged())
    this.filteredTypes$ = combineLatest([types$, typeIds$, replayForm<string>(this.categoryCtrl)]).pipe(
      map(([types, typeIds, category_id]) =>
        _filter<DeepReadonly<ProductType>>(types, pickBy({ category_id }, identity))
          .filter(type => typeIds.includes(type.type_id))),
      distinctUntilChanged())
    this.filteredProducts$ = combineLatest([
      products$,
      replayForm<string>(this.categoryCtrl),
      replayForm<string>(this.typeCtrl),
    ]).pipe(
      map(([products, category_id, type_id]) =>
        _filter<DeepReadonly<Product>>(products, pickBy({ category_id, type_id }, identity) as { category_id: string, type_id: string })
            .filter(product => !product.archived)),
      distinctUntilChanged())

    // sync category & type field values
    const productId$: Observable<string> = this.ctrl
      ? replayForm<string>(this.ctrl)
      : replayForm(this.group).pipe(map(form => form[this.ctrlName]), distinctUntilChanged())
    combineLatest([this.store.pipe(select(selectProductEntities), waitNotEmpty()), productId$]).pipe(
      map(([products, productId]) => products[productId]),
      filter(identity),
      untilComponentDestroyed(this),
    ).subscribe(product => {
      this.categoryCtrl.setValue(product.category_id)
      this.typeCtrl.setValue(product.type_id)
    })

    // sync category & type fields status
    const ctrl = this.ctrl ? this.ctrl : this.group.controls[this.ctrlName]
    replayFormStatus(ctrl).pipe(delay(0), untilComponentDestroyed(this)).subscribe(() => {
      if (ctrl.disabled) {
        this.typeCtrl.disable()
        this.categoryCtrl.disable()
      } else if (ctrl.enabled) {
        this.typeCtrl.enable()
        this.categoryCtrl.enable()
      }
    })
  }
}
