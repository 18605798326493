import { Injectable } from '@angular/core'
import { AesResponseStatus } from '@tradecafe/types/core'
import { AESApiService } from 'src/api/aes'
import { AES_CANCELLATION_REASON, NotesService } from 'src/services/data/notes.service'
import { ModalProxyService } from '../../shared/modal'
import {ToasterService} from '../../shared/toaster/toaster.service'


/**
 * AES service
 */
@Injectable()
export class AESService {
  constructor(
    private AESApi: AESApiService,
    private NotesService: NotesService,
    private modalHelper: ModalProxyService,
    private toaster: ToasterService,
  ) {}

  /**
   * Create AES
   */
  async create(deal_id: string, aesNumberAlreadyAssigned: boolean = false) {
    if(aesNumberAlreadyAssigned) {
      await this.modalHelper.showConfirm({
        title: 'Warning',
        description: 'The deal already has an AES number assigned. Are you sure you want to proceed?',
        cancelButtonText: 'Close',
      })
    }

    await this.modalHelper.showConfirm({
      title: 'Warning',
      description: 'Please confirm that the USPPI or foreign ultimate consignee reported on this deal does NOT own 10% or more of each other’s voting securities. If so, please send the request manually.',
      cancelButtonText: 'Close',
    })
    const data = await this.AESApi.create(deal_id)
    this.toaster.success('Deal is sent successfully to AES')
    return data
  }

  async createInBatch(deal_ids: string[], aesNumberAlreadyAssigned: boolean = false) {
    if(aesNumberAlreadyAssigned) {
      await this.modalHelper.showConfirm({
        title: 'Warning',
        description: 'At least one of the selected deals already has an AES number assigned. Are you sure you want to proceed?',
        cancelButtonText: 'Close',
    })
    }
    await this.modalHelper.showConfirm({
      title: 'Warning',
      description: 'Please confirm that, for any of the deals selected, the USPPI or foreign ultimate consignee reported does NOT own 10% or more of each other’s voting securities. If so, please remove the particular deal(s) from this request.',
      cancelButtonText: 'Close',
    })
    const data = await this.AESApi.createInBatch(deal_ids)
    this.toaster.success('Deals are sent successfully to AES')
    return data
  }

  async setResponseStatus(reportId: string, status: AesResponseStatus) {
    const { status: newStatus } = await this.AESApi.setResponseStatus(reportId, status)
    return { reportId, status: newStatus }
  }

  
  /**
   * Cancel AES
   */
  async cancel(reportId: string, dealId: string, reason?: string) {
    await this.modalHelper.showConfirm({
      title: 'Warning',
      description: 'Are you sure you want to cancel this commodity export report from AES? Once canceled, an export report cannot be submitted with the same Deal ID.',
      cancelButtonText: 'Close',
    })

    if(reason) {
      await this.NotesService.createNote({
        visibility: 1,
        body: reason,
        deal_id: dealId,
        attributes: {
          category: AES_CANCELLATION_REASON,
        },
      })
    }

    const data = await this.AESApi.cancel(reportId)
    this.toaster.success('AES commodity export report cancellation requested')
    return data
  }
}
