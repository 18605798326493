<mat-form-field [formGroup]="group" class="chips-input">
  <mat-label>{{placeholder}}</mat-label>
  <mat-chip-grid
    [formControlName]="ctrlName"
    [required]="isRequired" #chipList>
    <mat-chip-row *ngFor="let item of ctrl.value" (removed)="remove(item)">
      {{item}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="{{realPlaceholder || placeholder}}"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
</mat-form-field>
