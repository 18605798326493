<div mat-dialog-title>
  <h2>{{distributionList?.name ? 'Edit' : 'Add New'}} Distribution List</h2>
  <span fxFlex></span>
  <button mat-raised-button type="submit" color="primary" (click)="save()">
    <i class="fa fa-fw" [class.fa-save]="true"></i> Save
  </button>
  <button mat-button type="button" (click)="cancel()">
    Cancel
  </button>
</div>
<div mat-dialog-content>
  <tc-distribution-list #tcDistributionList [company]="company" [distributionList]="distributionList">
  </tc-distribution-list>
</div>
