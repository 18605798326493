import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import {
    createAESExportReport,
    createAESExportReportSuccess,
    createAESExportReportFailure,
    createAESExportRequestsInBatch,
    createAESInBatchSuccess,
    createAESInBatchFailure, setAesResponseStatus, setAesResponseStatusSuccess, setAesResponseStatusFailure, cancelAESExportReport, cancelAESExportReportSuccess, cancelAESExportReportFailure } from './aes.actions'
import { AESService} from 'src/services/data/aes.service'
import { throwError } from 'rxjs'

@Injectable()
export class AesEffects {
  constructor(
    private actions$: Actions,
    private aes: AESService,
    private toaster: ToasterService,
  ) {}

  createAESExportReport$ = createEffect(() => this.actions$.pipe(
    ofType(createAESExportReport),
    switchMap(action =>
      from(this.aes.create(action.dealId, action.aesNumberAlreadyAssigned)).pipe(
        map(aes => createAESExportReportSuccess({
            aes,
        })),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to create AES export report', error)
          this.toaster.error('Unable to create AES export report', error)
          return of(createAESExportReportFailure({ error }))
        })))))

  cancelAESExportReport$ = createEffect(() => this.actions$.pipe(
    ofType(cancelAESExportReport),
    switchMap(action =>
      from(this.aes.cancel(action.reportId, action.dealId, action.reason)).pipe(
        map(aes => cancelAESExportReportSuccess({
            aes,
        })),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to cancel AES export report', error)
          this.toaster.error('Unable to cancel AES export report', error)
          return of(cancelAESExportReportFailure({ error }))
        })))))

    createAESExportRequestsInBatch$ = createEffect(() => this.actions$.pipe(
        ofType(createAESExportRequestsInBatch),
        switchMap(action =>
            from(this.aes.createInBatch(action.dealIds, action.aesNumberAlreadyAssigned)).pipe(
                map(items => createAESInBatchSuccess({
                    items: items.created_reports,
                })),
                catchError(error => {
                    if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
                    console.error('Unable to create AES export reports', error)
                    this.toaster.error('Unable to create AES export reports', error)
                    return of(createAESInBatchFailure({ error }))
                })))))

    setAesResponseStatus$ = createEffect(() => this.actions$.pipe(
      ofType(setAesResponseStatus),
      tap(({ status }) => this.toaster.progress(`Setting request status to ... ${status}`)),
      switchMap(action =>
          from(this.aes.setResponseStatus(action.reportId, action.status)).pipe(
              map(report => setAesResponseStatusSuccess(report)),
              tap(() => this.toaster.success('Updated response status successfully')),
              catchError(error => {
              console.error('Unable to set response status', error)
              if (!error.toasted) this.toaster.error('Unable to set response status', error.error || error )
              return of(setAesResponseStatusFailure({ error }))
  })))))
}
