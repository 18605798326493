import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { StateService, TransitionService, UrlService } from '@uirouter/angularjs'
import { merge, of, Subject } from 'rxjs'
import { delay, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators'
import { isAngularJSUrl } from '../app-routing.module'

@Injectable({
  providedIn: 'root',
})
export class HybridRouter {
  private _navigatedTo$ = new Subject<{ url: string, ng1: boolean }>()
  urlChanged$ = this._navigatedTo$/* .pipe(distinctUntilKeyChanged('url')) */
  angularChanged$ = this._navigatedTo$.pipe(map(x => x.ng1), distinctUntilChanged())

  private $state: StateService
  private $urlService: UrlService

  private uiRouterTransition$ = new Subject<true>()

  constructor(private router: Router) {
    const navigatedTo$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((e: NavigationEnd) => ({ url: e.url, ng1: !!isAngularJSUrl('')(e.url) })))

    navigatedTo$.pipe(switchMap(({ url, ng1 }) => {
      if (!ng1 || !this.$state) return of({ url, ng1 })
      // NOTE: sometimes ui-router doesn't start transition. code below should wait for 100ms and force ng1 transition if it wan't fired
      return merge(this.uiRouterTransition$, of(false).pipe(delay(100))).pipe(take(1), tap(uiRouterFired => {
        if (!uiRouterFired) {
          const m = this.$urlService.match({ path: url })
          this.$state.go(m.rule['state'].name, m.match)
        }
      }), map(() => ({ url, ng1 })))
    }))
    .subscribe(this._navigatedTo$)
  }

  registerUiRouter($state: StateService, $transitions: TransitionService, $urlService: UrlService) {
    this.$state = $state
    this.$urlService = $urlService
    $transitions.onStart({}, () => this.uiRouterTransition$.next(true))
    $transitions.onSuccess({}, () => {
      const url = $state.href($state.current.name, $state.current.params)
      this._navigatedTo$.next({ url, ng1: !!isAngularJSUrl('')(url) })
    })
  }
}


registerHybridRouter.$inject = ['$state', '$transitions', 'HybridRouter', '$urlService']
export function registerHybridRouter($state: StateService, $transitions: TransitionService, hybrid: HybridRouter, $urlService: UrlService) {
  hybrid.registerUiRouter($state, $transitions, $urlService)
}
