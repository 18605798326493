import { Injectable } from '@angular/core'
import { Cost, DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawOffers, SupplierOffer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CreateOfferFormComponent, CreateOfferFormOptions } from './create-offer-form.component'

@Injectable()
export class CreateOfferFormService {
  constructor(private modal: ModalService, private toaster: ToasterService) {}

  async showCreateOffer(
    dv: DeepReadonly<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawInvoices>,
    { defaultBuyers, costs }: { defaultBuyers?: string[], costs?: DeepReadonly<Cost[]> } = {}
  ) {
    // We want to be sure a few fields are populated before
    // we allow an offer to be created or not
    const missingFields = dv.offers.find(o => !o.wrapping || !o.packing.package_id) || dv.bids.find(b => !b.wrapping || !b.packing.type)

    // If either of these fields are missing, show the toaster instead
    if (missingFields) {
      return this.toaster.error(
        'Invalid Product Specifications',
        'You need to specify the Wrapping and Type of Package on the Product Specs tab in order to create the offer for this deal.'
      );
    }

    return this.modal.openDialog<CreateOfferFormComponent, CreateOfferFormOptions, SupplierOffer>(CreateOfferFormComponent, {
      dv,
      defaultBuyers: defaultBuyers?.map(acc => parseFloat(acc)) || [],
      extraCosts: costs || [],
    }, {
      panelClass: ['tc-dialog', 'modal-xlg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
      disableClose: false,
    }).toPromise();
  }
}
