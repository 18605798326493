<h2 mat-dialog-title>
  <i class="fa fa-gift" aria-hidden="true"></i>&nbsp;Create Supplier Offer from {{ dealId }}
</h2>

<form [formGroup]="soForm" (submit)="create()">
  <mat-dialog-content>
    <section gdColumns="minmax(0, 5fr) minmax(0, 5fr) minmax(0, 5fr) minmax(0, 4fr)" gdGap="15px">
      <tc-select-search placeholder="Dest. Country" [multiple]="true"
          [ctrl]="soForm.controls.buyersCountry"
          [items]="countries$ | async" bindValue="code" bindLabel="name"
          ></tc-select-search>
      <tc-select-search placeholder="Dest. Location" [multiple]="true"
          [ctrl]="soForm.controls.buyersLocation"
          [items]="locations$ | async" bindValue="location_id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search placeholder="Buyers" [multiple]="true"
          [ctrl]="soForm.controls.selectedBuyers"
          [items]="buyers$ | async" bindLabel="name" bindValue="account"
          tc-options-sort-by="ranking"
          ></tc-select-search>
      <tc-select-search placeholder="Valid For"
          [ctrl]="soForm.controls.expirationTime"
          [items]="expirationTimes" bindLabel="name" bindValue="value"
          ></tc-select-search>
    </section>

    <p class="form-group">{{ costsForm.length
        ? 'Please select any tertiary cost that needs to be removed from the calculation of the matched offers.'
        : 'There are not tertiary costs on this deal that can be removed.' }}</p>

    <tc-costs-list
        [readonly]="true"
        [units]="units"
        [costsForm]="costsForm"
        [fxRates]="fxRates"
        fxRatesRange="spot"
        [displayColumns]="['select', 'service', 'provider', 'createdBy', 'estAmt', 'fxRate', 'estAmtCAD', 'costUnit']"
        [footer]="false"
        ></tc-costs-list>

    <section gdColumns="repeat(2, minmax(0, 1fr))">
      <div gdColumns="1fr">
        <mat-checkbox [formControl]="soForm.controls.skip_cost_matching">
          Do not add any new logistics or freight rate costs
        </mat-checkbox>
        <mat-checkbox [formControl]="soForm.controls.skip_delivery_dates_calculation">
          Do not recalculate delivery dates
        </mat-checkbox>
        <mat-checkbox [formControl]="soForm.controls.biddable">
          Allow bidding
        </mat-checkbox>
        <mat-checkbox [formControl]="soForm.controls.copy_all_si" (change)="onCopyAllSpecialInstructiosnChange()">
          Copy Special Instructions to all Buyers
        </mat-checkbox>
      </div>
      <div>
        <tc-select-search placeholder="Select Buyers to copy Special Instructions"
            [multiple]="true"
            [ctrl]="soForm.controls.selectedBuyersCopySI"
            [items]="selectedBuyers$ | async" bindLabel="name" bindValue="account"
            ></tc-select-search>
      </div>
    </section>

    <tc-invoices-list *ngIf="invoicesDataSource.data.length"
        [readonly]="true"
        [dataSource]="invoicesDataSource"
        [tableIdentity]="TableKey.ShippingDetailsInvoices"
        [displayColumns]="[
          'rowType',
          'company',
          'vendor_invoice_id',
          'invoice_id',
          'actl_amount',
          'est_amount',
          'net_payable',
          'status',
          'act_liability_date',
          'ant_liability_date',
          'sched_pmt_date',
          'actl_pmt_date',
        ]"
        ></tc-invoices-list>
  </mat-dialog-content>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Close</button>
    <button mat-raised-button type="submit" color="primary">
      <mat-icon class="fa fa-fw" aria-hidden="true"
          [class.fa-check]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></mat-icon>
      Create
    </button>
  </mat-dialog-actions>
</form>
