import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { selectWarehouseCarriers } from 'src/app/store/carriers'
import { selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { AddressFieldPickerOptions } from 'src/components/address-field/address-field.component'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { SegmentFormGroup, SegmentsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'
import { SegmentFormBaseComponent } from '../segment-form-base.component'


@Component({
  selector: 'tc-segment-form-warehouse',
  templateUrl: './segment-form-warehouse.component.html',
  styleUrls: ['./segment-form-warehouse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentFormWarehouseComponent extends SegmentFormBaseComponent implements OnInit {
  constructor(
    NotesOverlay: NotesOverlayService,
    protected store: Store,
  ) {
    super(NotesOverlay, store)
  }

  @Input() dealId: string
  @Input() segmentIndex: number
  @Input() segmentForm: SegmentFormGroup
  @Input() segmentsForm: SegmentsFormGroup
  @Input() readonly = false
  @Output() removeSegment = new EventEmitter()

  warehouseName$: Observable<string>
  warehouseAddressOptions$: Observable<AddressFieldPickerOptions>
  canRemoveSegment: boolean

  // ref data
  locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  carriers$ = this.store.pipe(select(selectWarehouseCarriers), waitNotEmpty())

  ngOnInit(): void {
    super.ngOnInit()

    this.canRemoveSegment = !this.readonly

    this.warehouseName$ = combineLatest([
      replayForm(this.segmentForm),
      this.store.pipe(select(selectLocationEntities), waitNotEmpty()),
    ]).pipe(
      map(([segment, locations]) => {
        const warehouses = this.segmentsForm.controls.filter(s => s.value.type === 'warehouse')
        const warehouseIndex = warehouses.indexOf(this.segmentForm)
        return segment.originId ? `in ${locations[segment.originId].city}` : `${warehouseIndex + 1}`
      }),
      distinctUntilChanged())

    this.warehouseAddressOptions$ = exactWarehouseAddressOptions(this.segmentForm)
  }

  onLocationChange() {
    this.segmentForm.patchValue({
      destinationId: this.segmentForm.value.originId,
    })
    this.segmentForm.controls.destinationId.markAsDirty()
  }

  onAddressChange() {
    this.segmentForm.patchValue({
      exactDropoffAddress: this.segmentForm.controls.exactLoadingAddress.value,
      exactDropoffAccount: this.segmentForm.controls.exactLoadingAccount.value,
    })
    this.segmentForm.controls.exactDropoffAddress.markAsDirty()
    this.segmentForm.controls.exactDropoffAccount.markAsDirty()
    this.segmentForm.controls.exactDropoffAddress.markAsTouched()
    this.segmentForm.controls.exactDropoffAccount.markAsTouched()
  }
}


function exactWarehouseAddressOptions(segmentForm: SegmentFormGroup): Observable<AddressFieldPickerOptions> {
  return replayForm(segmentForm).pipe(map(segment => ({
    title: 'Select Exact Warehouse Address',
    accountId: segment.carrierAccountId,
    address: segment.exactLoadingAddress,
  })))
}
