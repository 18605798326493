import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DocumentOverride } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment

@Injectable()
export class DocumentOverrideApiService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<{ data: DocumentOverride[] }>(`${apiUrl}/document-override`).toPromise()
  }

  create(data: DeepReadonly<Partial<DocumentOverride>>) {
    return this.http.post<{ data: DocumentOverride }>(`${apiUrl}/document-override`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: DocumentOverride }>(`${apiUrl}/document-override/${id}`).toPromise()
  }

  update(id: string, data: DeepReadonly<Partial<DocumentOverride>>) {
    return this.http.put<{ data: DocumentOverride }>(`${apiUrl}/document-override/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: DocumentOverride }>(`${apiUrl}/document-override/${id}`).toPromise()
  }
}
