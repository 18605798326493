import { tableViewsResolver } from 'src/services/data/table-views.service'
import { TableIdentities } from '../constants/table-identities'
import { buyersResolver, currenciesResolver, measuresResolver, productsResolver, suppliersResolver, usersResolver } from '../services/data/deal.resolvers'

export const routes = [
  {
    name: 'app.trading',
    // parent: 'app',
    url: '/trading',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['trader', 'administrator', 'junior-administrator', 'manager', 'superuser'],
      title: 'Trading',
    },
  }, {
    name: 'app.trading.default',
    url: '',
    redirectTo: 'app.trading.deals',
  }, {
    name: 'app.trading.new-deal',
    url: '/deals/new?defaultsId',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'New Deal',
    },
  }, {
    name: 'app.trading.deals',
    url: '/deals',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Deal List',
    },
  },
  {
    name: 'app.trading.shipping-log',
    url: '/traders/shipping-log',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Shipping Log',
    },
  },
  {
    name: 'app.trading.edit-deal',
    url: '/deals/:deal_id?back',
    component: 'tcMockUpgradedPage',
    params: {
      deal_id: null,
      back: '/trading/deals',
    },
    data: {
      roles: ['logistics', 'trader', 'administrator', 'manager', 'superuser', 'junior-administrator'],
      title: 'Edit Deal',
    },
  }, {
    name: 'app.trading.estimates',
    url: '/estimates',
    component: 'adminTradingEstimateList',
    data: {
      title: 'Estimates',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.tradingEstimateList,
      tableViews: tableViewsResolver,
      buyers: buyersResolver,
      suppliers: suppliersResolver,
      products: productsResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.trading.estimate',
    url: '/estimate?id',
    component: 'adminTradingEstimateForm',
    data: {
      title: 'Estimate',
    },
    resolve: {
      measures: measuresResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.trading.vendor-invoices',
    url: '/vendor-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Vendor Invoices',
    },
  }, {
    name: 'app.trading.buyer-invoices',
    url: '/buyer-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Buyer Invoices',
    },
  }, {
    name: 'app.trading.credit-notes',
    // parent: 'app.trading',
    url: '/credit-notes',
    component: 'adminTradingCreditNoteList',
    data: {
      title: 'Credit Notes',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.tradingCreditNoteList,
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.trading.fx-rates',
    // parent: 'app.financial',
    url: '/fx-rates',
    component: 'adminFinancialFxRatesList',
    data: {
      title: 'FX Rates',
    },
  }, {
    name: 'app.trading.freight_rates',
    // parent: 'app.trading',
    url: '/freight-rates',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Freight Rates',
    },
  }, {
    name: 'app.trading.vendor-credits',
    // parent: 'app.trading',
    url: '/vendor-credits',
    component: 'adminTradingVendorCreditList',
    data: {
      title: 'Vendor Credits',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.tradingVendorCreditList,
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  },
  {
    name: 'app.trading.ar-report',
    url: '/ar-report',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'AR Report',
    },
  },
]

tableViewsLoader.$inject = ['tableViewManager', 'tableIdentity']
function tableViewsLoader(tableViewManager, tableIdentity) {
  'ngInject'

  return tableViewManager.loadFor(tableIdentity)
}

dealIdResolver.$inject = ['$stateParams']
export function dealIdResolver($stateParams) {
  'ngInject'

  return $stateParams.deal_id
}
