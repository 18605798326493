import { createAction, props } from '@ngrx/store'
import { Carrier, TableKey } from '@tradecafe/types/core'


export const loadCarriers = createAction(
  '[Carrier/API] Load Carriers',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadCarriersSuccess = createAction(
  '[Carrier/API] Load Carriers Success',
  props<{
    tableKey?: TableKey,
    carriers: Carrier[],
  }>(),
)
export const loadCarriersFailure = createAction(
  '[Carrier/API] Load Carriers Failure',
  props<{ error: Error }>(),
)
