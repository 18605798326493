<form [formGroup]="batchChange" (submit)="submit()">
  <h2 mat-dialog-title>Batch Changes</h2>
  <mat-dialog-content>
    <mat-radio-group formControlName="sign" aria-label="Select change direction">
      <mat-radio-button [value]="-1">Decrease</mat-radio-button>
      <mat-radio-button [value]="+1">Increase</mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="mat-block">
      <mat-label>Amount</mat-label>
      <span matPrefix>{{ batchChange.controls.sign.value < 0 ? '—' : '+' }}</span>
      <input matInput formControlName="amount" type="number" step="0.01"
          #input (focus)="input.select()">
      <span matSuffix>{{ currencyCode }}</span>
    </mat-form-field>
    <tc-epoch-field placeholder="Date Quoted"
        [group]="batchChange" ctrlName="dateQuoted" format="l"
        ></tc-epoch-field>
    <tc-epoch-field placeholder="Valid Until"
        [group]="batchChange" ctrlName="validUntil" format="l"
        ></tc-epoch-field>
    <mat-checkbox formControlName="retireNow">Retire selected rates immediately.</mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-raised-button color="primary" type="close">Change</button>
  </mat-dialog-actions>
</form>
