import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Cost, GeneralAddress, Segment, SegmentType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { CostFormGroup, CostFormValue, SegmentFormGroup, SegmentFormValue } from './deal-form.schema'

export function buildDealCostForm(value?: DeepReadonly<CostFormValue>) {
  const groupConfig: Record<keyof CostFormValue, any[2]> = {
    cost: new UntypedFormControl(undefined, Validators.required),
    type: new UntypedFormControl(undefined, Validators.required),
    status: new UntypedFormControl(undefined, Validators.required),
    providerId: new UntypedFormControl(),
    amount: new UntypedFormControl(undefined, Validators.required),
    currencyCode: new UntypedFormControl(undefined, Validators.required),
    productId: new UntypedFormControl(),
    serviceName: new UntypedFormControl(undefined, Validators.required),
  }
  const costForm = new UntypedFormGroup(groupConfig) as CostFormGroup
  if (value) costForm.patchValue(value)
  return costForm
}

export function prepareDealCostPatch(cost: DeepReadonly<Partial<Cost>>): CostFormValue {
  return {
    cost,
    type: cost.type,
    status: cost.status,
    providerId: cost.provider ? cost.provider : undefined,
    amount: cost.amount.total,
    currencyCode: cost.amount.currency,
    productId: cost.product_id,
    serviceName: cost.service,
  }
}

export function buildSegmentForm(value?: DeepReadonly<SegmentFormValue>) {
  const group = new FormGroup({
    segment: new FormControl<Partial<DeepReadonly<Segment>>>(undefined),
    actualDeliveryDate: new FormControl<number>(undefined),
    actualDeliveryDateTime: new FormControl<boolean>(undefined),
    actualPickupDate: new FormControl<number>(undefined),
    actualPickupDateTime: new FormControl<boolean>(undefined),
    amount: new FormControl<number>(undefined),
    billOfLadingNo: new FormControl<string>(undefined),
    bookingId: new FormControl<string>(undefined),
    carrierAccountId: new FormControl<string>(undefined, [Validators.required]),
    carrierId: new FormControl<string>(undefined, [Validators.required]),
    containerNumber: new FormControl<string>(undefined),
    currencyCode: new FormControl<string>(undefined, [Validators.required]),
    cutoffDatetime: new FormControl<number>(undefined),
    deliveryRefNo: new FormControl<string>(undefined),
    destinationId: new FormControl<string>(undefined),
    etaDate: new FormControl<number>(undefined),
    etaDateTime: new FormControl<boolean>(undefined),
    etdDate: new FormControl<number>(undefined),
    etdDateTime: new FormControl<boolean>(undefined),
    exactDropoffAccount: new FormControl<number>(undefined),
    exactDropoffAddress: new FormControl<GeneralAddress>(undefined),
    exactLoadingAccount: new FormControl<number>(undefined),
    exactLoadingAddress: new FormControl<GeneralAddress>(undefined),
    facilityHadClaims: new FormControl<string>(undefined),
    flightNo: new FormControl<string>(undefined),
    freightForwarderId: new FormControl<string>(undefined),
    importPermit: new FormControl<string>(undefined),
    loadingRamp: new FormControl<string>(undefined),
    order: new FormControl<number>(undefined, [Validators.required]),
    originId: new FormControl<string>(undefined),
    pickupCompany: new FormControl<string>(undefined),
    pickupRefNo: new FormControl<string>(undefined),
    plateNo: new FormControl<string>(undefined),
    referNo: new FormControl<string>(undefined),
    sealNumber: new FormControl<string>(undefined),
    trackingNo: new FormControl<string>(undefined),
    train: new FormControl<string>(undefined),
    type: new FormControl<SegmentType>(undefined, [Validators.required]),
    vessel: new FormControl<string>(undefined),
    voyageNo: new FormControl<string>(undefined),
  }) as SegmentFormGroup
  if (value) group.patchValue(value)
  return group
}

export function prepareDealSegmentPatch(segment: Partial<DeepReadonly<Segment>>, index: number): SegmentFormValue {
  return {
    segment,
    type: segment.type,
    actualDeliveryDate: segment.attributes.actual_delivery_date,
    actualDeliveryDateTime: segment.attributes.actual_delivery_date_time,
    actualPickupDate: segment.attributes.actual_pickup_date,
    actualPickupDateTime: segment.attributes.actual_pickup_date_time,
    amount: segment.attributes.amount,
    billOfLadingNo: segment.attributes.bill_of_lading_no,
    containerNumber: segment.attributes.container_number,
    currencyCode: segment.attributes.currency,
    cutoffDatetime: segment.attributes.cutoff_datetime,
    deliveryRefNo: segment.attributes.delivery_ref_no,
    destinationId: segment.attributes.destination_id,
    etaDate: segment.attributes.eta_date,
    etaDateTime: segment.attributes.eta_date_time,
    etdDate: segment.attributes.etd_date,
    etdDateTime: segment.attributes.etd_date_time,
    facilityHadClaims: segment.attributes.facility_had_claims,
    flightNo: segment.attributes.flight_no,
    freightForwarderId: segment.attributes.freight_forwarder,
    importPermit: segment.attributes.import_permit,
    loadingRamp: segment.attributes.loading_ramp,
    originId: segment.attributes.origin_id,
    order: segment.order || index,
    pickupRefNo: segment.attributes.pickup_ref_no,
    plateNo: segment.attributes.plate_no,
    referNo: segment.attributes.refer_no,
    sealNumber: segment.attributes.seal_number,
    trackingNo: segment.attributes.tracking_no,
    train: segment.attributes.train,
    vessel: segment.attributes.vessel,
    voyageNo: segment.attributes.voyage_no,
    bookingId: segment.booking_id,
    carrierId: segment.carrier_id,
    carrierAccountId: segment.attributes.carrier_account,
    exactLoadingAddress: segment.attributes.exact_loading?.address,
    exactLoadingAccount: segment.attributes.exact_loading?.account ? parseFloat(segment.attributes.exact_loading.account) : undefined,
    exactDropoffAddress: segment.attributes.exact_dropoff?.address,
    exactDropoffAccount: segment.attributes.exact_dropoff?.account ? parseFloat(segment.attributes.exact_dropoff.account) : undefined,
    pickupCompany: segment.attributes.pickup_company,
  }
}
