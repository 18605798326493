<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;Freight Rate Logs
</h2>
<mat-dialog-content>
  <tc-audit-log
    [filter]="auditLogFilter"
    [tableIdentity]="tableIdentity"
  ></tc-audit-log>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button type="button" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
