<form name="form" (ngSubmit)="save()">
  <h2 mat-dialog-title><i class="fa fa-truck" aria-hidden="true"></i>&nbsp; {{ modalTitle }}</h2>
  <div mat-dialog-content gdColumns="repeat(6, minmax(0, 1fr))" gdGap="0 15px">
    <tc-select-search placeholder="Carrier"
                      gdColumn="span 3"
                      [group]="form"
                      ctrlName="carrier_id"
                      [items]="carriers$ | async"
                      bindValue="carrier_id"
                      bindLabel="name"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>
    <tc-epoch-field placeholder="Valid until"
                    gdColumn="span 3"
                    format="L"
                    [ctrl]="form.controls.until"
                    [readonly]="isReadOnly"></tc-epoch-field>
    <tc-select-search placeholder="Type"
                      gdColumn="span 3"
                      [group]="form"
                      ctrlName="type"
                      [items]="types"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>
    <tc-select-search placeholder="Commodity"
                      gdColumn="span 3"
                      [group]="form"
                      ctrlName="commodity"
                      [items]="FreightCategories"
                      bindValue="id"
                      bindLabel="name"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>

    <tc-select-search placeholder="Origin"
                      gdColumn="span 3"
                      [group]="form"
                      ctrlName="origin_id"
                      [items]="locations$ |async"
                      bindValue="location_id"
                      bindLabel="name"
                      bindHint="fullname"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>

    <tc-select-search placeholder="Destination"
                      gdColumn="span 3"
                      [group]="form"
                      ctrlName="destination_id"
                      [items]="locations$ | async"
                      bindValue="location_id"
                      bindLabel="name"
                      bindHint="fullname"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>
    <mat-form-field gdColumn="span 3">
      <mat-label>Port Loading</mat-label>
      <input matInput type="text" autocomplete="off" [readonly]="isReadOnly" [formControl]="form.controls.port_loading">
    </mat-form-field>
    <mat-form-field gdColumn="span 3">
      <mat-label>Port Discharge</mat-label>
      <input matInput type="text" autocomplete="off" [readonly]="isReadOnly" [formControl]="form.controls.port_discharge">
    </mat-form-field>
    <mat-form-field gdColumn="span 3">
      <mat-label>All-in rate</mat-label>
      <input matInput type="number" autocomplete="off" min="0" step="0.01" [readonly]="isReadOnly" [formControl]="form.controls.rate.controls.amount">
    </mat-form-field>
    <tc-select-search placeholder="Currency"
                      gdColumn="span 3"
                      [group]="form.controls.rate"
                      ctrlName="currency"
                      [items]="currencies$ | async"
                      bindValue="code"
                      bindLabel="code"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>
    <mat-form-field gdColumn="span 2">
      <mat-label>Container size</mat-label>
      <input matInput type="number" autocomplete="off" min="0" step="0.0001" [readonly]="isReadOnly" [formControl]="form.controls.container_size">
    </mat-form-field>

    <mat-form-field gdColumn="span 2">
      <mat-label>Maximum weight</mat-label>
      <input matInput type="number" autocomplete="off" min="0" step="0.0001" [readonly]="isReadOnly" [formControl]="form.controls.weight.controls.max">
    </mat-form-field>

    <tc-select-search placeholder="Unit"
                      gdColumn="span 2"
                      [group]="form.controls.weight"
                      ctrlName="metric"
                      [items]="measures$ | async"
                      bindValue="code"
                      bindLabel="name"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>

    <tc-select-search placeholder="Status"
                      gdColumn="span 2"
                      [group]="form"
                      ctrlName="status"
                      [items]="Statuses"
                      bindValue="id"
                      bindLabel="label"
                      [readonly]="isReadOnly"
                      clearable="true"></tc-select-search>

    <tc-epoch-field placeholder="Date Quoted"
                    gdColumn="span 2"
                    format="L"
                    [ctrl]="form.controls.attributes.controls.date_quoted"
                    [readonly]="isReadOnly"></tc-epoch-field>

    <mat-form-field gdColumn="span 2">
      <mat-label>Transit Time (in days)</mat-label>
      <input matInput type="number" autocomplete="off" min="0" step="1" [readonly]="isReadOnly" [formControl]="form.controls.attributes.controls.transit_time">
    </mat-form-field>
    <mat-form-field gdColumn="span 6">
      <mat-label>Description</mat-label>
      <textarea matInput [formControl]="form.controls.description" [readonly]="isReadOnly" rows="3"></textarea>
    </mat-form-field>
      <mat-checkbox gdColumn="span 6"
                    *ngIf="dialogData?.dealId || dialogData?.matchedOfferId"
                    [formControl]="form.controls.availableForOtherDeals"
                    [disabled]="!!dialogData?.freightRate?.rate_id"
      >Show in Freight Rates database</mat-checkbox>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <div mat-dialog-actions align="end">
    <button *ngIf="frTmp && frTmp.rate_id" mat-button type="button" [matTooltip]="getProblemHistory()"
      matTooltipClass="pre-tooltip huge-tooltip" (click)="flagFreightRate()">
      <span *ngIf="isFlagged()"><i class="fa fa-flag" style="color: red;"></i></span>
      <span *ngIf="!isFlagged()"><i class="fa-regular fa-flag" style="color: red;"></i></span>
    </button>
    <button mat-button type="button" (click)="cancel()">
      <span *ngIf="isReadOnly">Close</span>
      <span *ngIf="!isReadOnly">Cancel</span>
    </button>
    <button mat-raised-button type="submit" color="primary"
            [disabled]="inProgress$ | async"
            *ngIf="!isReadOnly">
      <span><i class="fa fa-save"></i>&nbsp; {{ dialogData?.mode === 'update' ? 'Update' : 'Create' }}</span>
    </button>
  </div>
</form>
