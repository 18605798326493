import { Injectable } from '@angular/core'
import { Bid } from '@tradecafe/types/core'
import { groupBy, memoize, pick } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { BidApiService } from 'src/api/bid'
const ALLOWED_FIELDS = ['account', 'country', 'deal_id', 'offer', 'product',
  'price', 'currency', 'quantity', 'containers', 'ship_start', 'ship_end',
  'type', 'note', 'delivery', 'expire', 'status', 'wrapping', 'description',
  'weight', 'incoterm', 'packing', 'attributes']

/**
 * Bids service
 *
 * @see https://docs.google.com/document/d/1-IABBOsN0h-ptSJ7LPQWCeNjrs3d7JPbi5WVtndFVGg/edit
 *
 * @export
 * @returns
 */
@Injectable()
export class BidsService {
  constructor(
    private AuthApi: AuthApiService,
    private BidApi: BidApiService,
  ) {}

  private readonly getByDealIdsCached = memoize(this.getByDealIds)


  /**
   * Get by bid_id
   *
   * @param {any} bid_id
   * @returns
   */
  async getById(bid_id: string) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.BidApi.get(account, bid_id)
    return data
  }

  /**
   * Get by deal_id
   *
   * @param {any} deal_id
   * @returns
   */
  async getByDealId(dealId: string) {
    const data = await this.getByDealIds([dealId])
    return data[dealId] || []
  }

  /**
   * Get by deal_ids
   *
   * @param {any} deal_ids
   * @returns
   */
  async getByDealIds(dealIds: string[]) {
    if (!dealIds.length) return {}
    const { account } = this.AuthApi.currentUser
    const data = await this.BidApi.byDeals(account, dealIds).then(r => r.data)
    return groupBy(data, 'deal_id')
  }

  // function getLastPricesFor(buyer_id, productIds) {
  //   return Promise.all(map(productIds, async productId => ({
  //     [productId]: await getLastPrice(buyer_id, productId)
  //   }))).then(r => r.reduce(Object.assign, {}))
  // }

  // /**
  //  * Get last price
  //  *    TODO: SER-236: this should be moved to the back-end
  //  *
  //  * @param {any} account
  //  * @param {any} product
  //  * @returns
  //  */
  // async function getLastPrice(account, product) {
  //   if (!account || !product) return undefined
  //   const allBids = await getByIds()
  //   // TODO: SER-236: this should be moved to the back-end
  //   const all = filter(allBids, {
  //     account,
  //     product,
  //     status: BID_ACCEPTED,
  //   })
  //   const bid = orderBy(all, ['created'], ['desc'])[0]
  //   return bid && {
  //     price: bid.price,
  //     currency_code: bid.currency,
  //     weight: bid.weight.amount,
  //     measure_id: bid.weight.unit,
  //   }
  // }

  /**
   * Update bid
   *
   * @param {any} bid
   * @returns
   */
  async update(bid: Partial<Bid>) {
    const payload = pick(bid, ALLOWED_FIELDS)
    const { data } = await this.BidApi.update(bid.bid_id, payload)
    .then(recognizeError(`Unable to update bid ${bid.bid_id}`))
    if (bid.deal_id) this.getByDealIdsCached.cache.delete(bid.deal_id)
    else this.getByDealIdsCached.cache.clear()
    return data
  }
}


/**
 * API can return 200 with { error: ... } in response body.
 * NOTE: this is more of a workaround for the backend issue
 *
 * @private
 * @param {*} msg
 * @returns
 */
function recognizeError(msg) {
  return res => {
    if (res.error) {
      console.error(msg, res)
      throw res.error
    }
    return res
  }
}
