import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import * as Sentry from '@sentry/angular-ivy'
import { User } from '@tradecafe/types/core'
import { compact } from 'lodash-es'
import * as LogRocket from 'logrocket'
import { BehaviorSubject, of, ReplaySubject } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { environment } from '../environments/environment'

const { apiUrl } = environment

export function registerNg1 (module: ng.IModule) {
  module.service('AuthApi', downgradeInjectable(AuthApiService))
}


@Injectable()
@AngularCopy()
export class AuthApiService {
  currentUser: User
  currentUser$ = new ReplaySubject<User>(1)
  realUser$ = new ReplaySubject<User>(1)
  token$ = new BehaviorSubject<string>(undefined)

  constructor(private http: HttpClient) {
    this.loadTokenFromSession()
  }

  setRealUser() {
    this.currentUser$.pipe(switchMap((currentUser) =>
      this.checkToken(this.token$.value).pipe(tap((x) => {
        this.realUser$.next(x ? currentUser : undefined)
      })),
    )).subscribe()
  }

  isAuthlessUrl(url: string) {
    return [
      '/check-token',
    ].some(x => url.includes(x))
  }

  checkToken(token?: string) {
    return this.http.post<{ data: boolean }>(`${apiUrl}/check-token`, {token}).pipe(
      map(({data}) => data),
      catchError(() => of(false)),
    )
  }

  autologin(user_id: string) {
    return this.http.post<any>(`${apiUrl}/autologin/user/${user_id}`, {}).toPromise()
  }

  async login(credentials: { email: string, password: string }) {
    const { data } = await this.http.post<any>(`${apiUrl}/login`, credentials).toPromise()
    if (!data.token) {
      throw new Error('Login failed, please try again later')
    }
    // NOTE: we ignore `data.account`
    this.saveTokenForSession(data.token, data.user)
    return data
  }

  async forgotPassword(email: string) {
    const { data } = await this.http.post<any>(`${ apiUrl }/forgot`, { email }).toPromise()
    return data
  }

  async resetPassword(payload: { password: string, email: string, token: string }) {
    const { data } = await this.http.post<any>(`${ apiUrl }/reset-password`, payload).toPromise()
    return data
  }

  async logout() {
    try {
      await this.http.get(`${apiUrl}/logout`).toPromise()
    } catch (error) {
      console.warn('Unable to logout:', error)
    } finally {
      this.cleanTokenFromSession()
    }
  }

  private loadTokenFromSession() {
    const user: User = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    this.currentUser = user
    this.token$.next(token)
    this.currentUser$.next(user)
    if (environment.sentryDsn && user) {
      Sentry.setUser({
        email: user.primaryemail,
        id: user.user_id,
        username: compact([user.firstname, user.lastname]).join(' '),
      })
    }
    if (environment.logRocketAppId && user) {
      LogRocket.identify(user.user_id, {
        name: compact([user.firstname, user.lastname]).join(' '),
        email: user.primaryemail,
      })
    }
  }

  private saveTokenForSession(token: string, user: User) {
    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
    this.currentUser = user
    this.token$.next(token)
    this.currentUser$.next(user)
    this.realUser$.next(user)
  }

  private cleanTokenFromSession() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    this.currentUser = undefined
    this.token$.next(undefined)
    this.currentUser$.next(undefined)
    this.realUser$.next(undefined)
    if (environment.sentryDsn) {
      Sentry.configureScope(scope => scope.setUser(null))
    }
  }
}
