import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'

export type CostsListColumn =
  | 'select'
  | 'ellipsisMenu'
  | 'type'
  | 'created'
  | 'service'
  | 'provider'
  | 'createdBy'
  | 'actAmt'
  | 'estAmt'
  | 'fxRate'
  | 'actAmtCAD'
  | 'estAmtCAD'
  | 'partialAmtCAD'
  | 'costUnit'
  | 'invoiceStatus'
  | 'origin'
  | 'destination'
  | 'portLoading'
  | 'portDischarge'
  | 'dealSource'
  | 'matchedOfferSource'

export const COST_COLUMNS: ColumnDef<CostsListColumn>[] = [
  // internal
  { field: 'select',             displayName: '' },
  { field: 'ellipsisMenu',       displayName: '', internal: true, index: 1001 },

  { field: 'type',               displayName: 'Type' },
  { field: 'created',            displayName: 'Created' },
  { field: 'service',            displayName: 'Service' },
  { field: 'provider',           displayName: 'Provider' },
  { field: 'createdBy',          displayName: 'Created By' },
  { field: 'actAmt',             displayName: 'Act Amt' },
  { field: 'estAmt',             displayName: 'Est Amt' },
  { field: 'fxRate',             displayName: 'FX Rate' },
  { field: 'actAmtCAD',          displayName: 'Act Amt CAD' },
  { field: 'estAmtCAD',          displayName: 'Est Amt CAD' },
  { field: 'partialAmtCAD',      displayName: 'Partial Amt' },
  { field: 'costUnit',           displayName: 'Cost/Unit' },
  // displays as id + status
  { field: 'invoiceStatus',      displayName: 'Invoice' },
  { field: 'origin',             displayName: 'Origin' },
  { field: 'destination',        displayName: 'Destination' },
  { field: 'portLoading',        displayName: 'Port Loading' },
  { field: 'portDischarge',      displayName: 'Port Discharge' },
  { field: 'dealSource',         displayName: 'Previous Deal' },
  { field: 'matchedOfferSource', displayName: 'Previous Matched Offer #' },
]

export const COST_COLUMN_NAMES = mapValues(keyBy(COST_COLUMNS, 'field'), 'displayName')

export const DEFAULT_COLUMNS: Record<string, CostsListColumn[]> = {
  ['default']: [
    'type',
    'service',
    'provider',
    'createdBy',
    'actAmt',
    'estAmt',
    'fxRate',
    'actAmtCAD',
    'estAmtCAD',
    'partialAmtCAD',
    'costUnit',
    'invoiceStatus',
    'ellipsisMenu',
  ]
}
