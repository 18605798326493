<h2 mat-dialog-title>
  <i class="fa fa-file-lines" aria-hidden="true"></i>&nbsp;{{title}}
</h2>


<mat-dialog-content [formGroup]="invoiceForm">
  <div *ngIf="vendorInvoiceIdExist" class="alert alert-warning row" layout="row" layout-align="space-between center">
    <strong flex>Vendor invoice {{invoiceForm?.controls?.vendor_invoice_id.value}} already exists, press agree to use it</strong>
    <button type="button" class="btn btn-warning pull-right" (click)=agreeToUseExistingVendorInvoice()>Agree</button>
  </div>
  <div gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="0 15px">
    <tc-select-search placeholder="Deal #"
        [group]="invoiceForm" ctrlName="deal_id"
        [items]="dealIds$ | async"
        ></tc-select-search>
    <tc-select-search placeholder="Company"
        [group]="invoiceForm" ctrlName="account"
        [items]="vendors$ | async" bindValue="account" bindLabel="name" bindHint="hint"
        ></tc-select-search>
    <mat-form-field>
      <mat-label>Partial Margin (CAD)</mat-label>
      <input readonly matInput [value]="partialMargin$ | async | currency:'CAD'">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Vendor Invoice #</mat-label>
      <input matInput formControlName="vendor_invoice_id">
    </mat-form-field>
    <tc-epoch-field placeholder="Invoice date" [group]="invoiceForm" ctrlName="issued"></tc-epoch-field>
    <tc-epoch-field placeholder="Due date"
                    [min]="invoiceForm.controls.issued.value"
                    [group]="invoiceForm" ctrlName="due"></tc-epoch-field>
  </div>
  <div gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Vendor Weight</mat-label>
      <input readonly matInput
          xx-class.ng-invalid="$ctrl.wrongUnits()"
          xx-class.ng-dirty="$ctrl.wrongUnits()"
          [value]="vendorWeight$ | async | measure:(invoiceUnits$ | async)">
    </mat-form-field>
    <mat-form-field *ngIf="!this.costsForm.length">
      <mat-label>Total invoice Amt</mat-label>
      <input matInput type="number" min="0" step="0.01" formControlName="total">
    </mat-form-field>
    <mat-form-field *ngIf="this.costsForm.length">
      <mat-label>Total invoice Amt</mat-label>
      <input readonly matInput [value]="invoiceForm.controls.total.value | currency:invoiceForm.controls.currency.value">
    </mat-form-field>
    <tc-select-search placeholder="Currency"
        [group]="invoiceForm" ctrlName="currency"
        [items]="currencies$ | async"
        ></tc-select-search>
  </div>
  <hr/>
  <div *ngFor="let costForm of costsForm.controls; let $index = index" [formGroup]="costForm"
      [gdColumns]="(isPrimary$(costForm) | async)
          ? 'minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 35px'
          : 'minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 35px'"
      gdGap="15px">
    <tc-select-search placeholder="Related cost"
        [group]="costForm" ctrlName="cost_id"
        [items]="vendorDealCosts$ | async" bindValue="cost_id" bindLabel="service"
        (change)="onCostChanged($event, costForm)"
        ></tc-select-search>
    <mat-form-field *ngIf="isPrimary$(costForm) | async">
      <mat-label>TC Weight</mat-label>
      <input readonly matInput
          xx-class.ng-invalid="$ctrl.wrongUnits()"
          xx-class.ng-dirty="$ctrl.wrongUnits()"
          [value]="costWeight$(costForm.value.cost_id) | async">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Part. Amt ({{costForm.value.currency}})</mat-label>
      <input readonly matInput [value]="(costForm.value.partial || costForm.value.estimated) | currency:costForm.value.currency">
    </mat-form-field>
    <mat-form-field *ngIf="!(isPrimary$(costForm) | async)">
      <mat-label>Invoice Amt. ({{costForm.value.currency}})</mat-label>
      <input matInput type="number" min="0" step="0.01" formControlName="actual">
    </mat-form-field>
    <mat-form-field *ngIf="isPrimary$(costForm) | async">
      <mat-label>Invoice Amt. ({{costForm.value.currency}})</mat-label>
      <input readonly matInput [value]="costForm.value.actual | currency:costForm.value.currency">
    </mat-form-field>
    <button mat-icon-button (click)="removeCost($index)" color="warn">
      <mat-icon class="fa fa-minus-circle"></mat-icon>
    </button>
  </div>
  <div class="text-right">
    <button mat-button type="button" (click)="addCost()">
      <i class="fa fa-plus"></i> ASSOCIATE COST
    </button>
  </div>
  <hr>
  <div *ngFor="let creditForm of creditsForm.controls; let $index = index" [formGroup]="creditForm"
      gdColumns="minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 35px" gdGap="15px">
    <tc-select-search placeholder="Vendor Credit"
        [group]="creditForm" ctrlName="credit_note_id"
        [items]="vendorCredits$|call:$index | async" bindValue="credit_note_id" bindLabel="name" bindHint="hint"
        (change)="onVendorCreditChanged($event, creditForm)"
    >
      <span *tcSelectSearchOption="let item = item">
        <div>{{item?.name || ''}}</div>
        <small class="textPosition text-gray">{{item?.hint || ''}}</small>
      </span>
    </tc-select-search>
    <mat-form-field>
      <mat-label>Total Amount ({{(creditCurrency$(creditForm) | async)}})</mat-label>
      <input readonly matInput [value]="(creditForm.controls.amount.value) | currency:(creditCurrency$(creditForm) | async)">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Apply Amount ({{(creditCurrency$(creditForm) | async)}})</mat-label>
      <input readonly matInput [value]="(creditForm.controls.applyAmount.value) | currency:(creditCurrency$(creditForm) | async)">
    </mat-form-field>
    <button mat-icon-button (click)="removeVendorCredit($index)" color="warn">
      <mat-icon class="fa fa-minus-circle"></mat-icon>
    </button>
  </div>
  <div class="text-right">
    <button mat-button type="button" (click)="addVendorCredit()">
      <i class="fa fa-plus"></i> ASSOCIATE VENDOR CREDIT
    </button>
  </div>
  <div class="text-right" *ngIf="prepaymentsAvailableAmount$ | async as prepaymentsAmount">
    <mat-checkbox [checked]="true" disabled tabindex="-1">
      Apply prepayment ({{prepaymentsAmount | currency:invoiceForm.controls.currency.value}})
    </mat-checkbox>
  </div>
  <hr>
  <ng-container *ngIf="invoiceFiles?.length">
    <div *ngFor="let file of invoiceFiles">
      <a [href]="file.link" download>{{file.name}}</a>
    </div>
  </ng-container>
  <ng-container *ngIf="!invoiceFiles?.length">
    <div class="file-uploader" [class.text-right]="!inputFile.files.length">
      <button *ngIf="!inputFile.files.length" mat-button type="button" (click)="inputFile.click()">
        <i class="fa fa-cloud-arrow-up"></i> Upload Document
      </button>
      <input #inputFile type="file" style="display:none" (change)="cd.markForCheck()">
      <div *ngIf="inputFile.files.length">
        {{ inputFile.files[0].name }}
        <button mat-icon-button type="button" (click)="inputFile.value = ''; cd.markForCheck()" aria-label="Remove file">
          <mat-icon class="fa fa-xmark"></mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button type="button"
      [disabled]="inProgress$ | async"
      (click)="cancel()">Cancel</button>
  <button *ngIf="mode === 'create'" mat-raised-button color="primary" type="button"
      [disabled]="vendorInvoiceIdExist || (inProgress$ | async)"
      (click)="create()">
    <i class="fa fa-save"></i>&nbsp; Save
  </button>
  <button *ngIf="mode === 'actualize' || costsForm.length" mat-raised-button color="primary" type="button"
      [disabled]="vendorInvoiceIdExist || !costsForm.length || (inProgress$ | async)" (click)="actualize()">
    <i class="fa fa-check"></i>&nbsp; Actualize
  </button>
</mat-dialog-actions>
