import { Injectable } from '@angular/core'
import { Invoice, INVOICE_IN_REVIEW } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, defaultsDeep } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { dayjs } from 'src/services/dayjs'
import { ModalService } from 'src/shared/modal/modal.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { MarkAsPaidFormService } from '../mark-as-paid-form/mark-as-paid-form.service'
import { InvoiceFormComponent, InvoiceFormOptions, InvoiceFormResult } from './invoice-form.component'


const dialogConfig = {
  panelClass: ['tc-dialog', 'modal-md'],
  width: null,
  maxWidth: null,
  autoFocus: false,
}

@Injectable()
export class InvoiceFormService {
  constructor(
      private modal: ModalService,
      private toaster: ToasterService,
      private Invoices: InvoicesService,
      private AuthApi: AuthApiService,
      private ConfirmModal: ConfirmModalService,
      private MarkAsPaidForm: MarkAsPaidFormService,
  ) { }

  actualizeInvoice(invoice: DeepReadonly<Invoice>) {
    return this.modal.openDialog<InvoiceFormComponent, InvoiceFormOptions, InvoiceFormResult>(InvoiceFormComponent, {
      title: 'Actualize Invoice',
      mode: 'actualize',
      invoice: {
        // clone original invoice, add necessary attributes if missing
        ...defaultsDeep(cloneDeep(invoice), { attributes: { vendor_credits: [], costs: [] } }),
        // round total
        total: Math.round(invoice.total * 100) / 100,
      },
    }, dialogConfig).toPromise()
  }

  async updateInvoiceStatus(
      invoice: DeepReadonly<Invoice>,
      status: Invoice['status'],
      dateKey: string,
  ) {

    const paid = await this.MarkAsPaidForm.showMarkAsPaid().toPromise()

    if (!paid) return null

    const isAlreadyPaid = invoice.attributes.paid
    let isOk = true
    if (isAlreadyPaid) {
      isOk = await this.ConfirmModal.show({
        title: 'Warning',
        description: 'Selected invoice has already been marked as Paid. Are you sure you want to update the Actual pmt date?',
      })
    }

    if (!isOk) return null

    const updated = await this.Invoices.patchImmutableInvoice(invoice, {
      status,
      attributes: {
        [dateKey]: paid || dayjs.utc().unix(),
        [`${dateKey}_user`]: this.AuthApi.currentUser.user_id,
      },
    }).catch((err) => {
      console.error('Unable to update invoice status.', err)
      this.toaster.error('Unable to update invoice status.', err)
      throw err
    })

    this.toaster.success('Invoice status updated successfully.')

    return updated
  }

  showCreateInvoice(deal_id?: string): Promise<void> {
    return this.modal.openDialog<InvoiceFormComponent, InvoiceFormOptions, InvoiceFormResult>(InvoiceFormComponent, {
      title: 'Add New Invoice',
      mode: 'create',
      invoice: {
        status: INVOICE_IN_REVIEW,
        type: 'payable',
        line_items: [],
        deal_id,
        attributes: {
          costs: [],
          vendor_credits: [],
        },
      },
    }, dialogConfig).toPromise().then(() => {})
  }
}
