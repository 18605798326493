<form [formGroup]="consigneeForm" (submit)="submit()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    {{isNew ? 'Add New Consignee' : 'Update Consignee'}}
  </h2>
  <div mat-dialog-content gdColumns="repeat(4, minmax(0, 1fr))" gdGap="10px">
    <mat-form-field gdColumn="span 2">
      <mat-label>Address name</mat-label>
      <input matInput autocomplete="off" formControlName="name">
    </mat-form-field>
    <tc-select-search
                      *ngIf="this.options.isSettingsPage"
                      gdColumn="span 2"
                      placeholder="Companies"
                      [group]="consigneeForm"
                      ctrlName="companies"
                      [items]="this.accounts$ | async"
                      bindValue="account"
                      bindLabel="name"
                      [multiple]="true"
    ></tc-select-search>
    <mat-checkbox color="primary" formControlName="inalfresco">
      INALFRESCO
    </mat-checkbox>
    <tc-select-search gdColumn="span 2" placeholder="Department #"
        [group]="consigneeForm" ctrlName="department"
        [items]="departments$ | async" bindValue="department_id" bindLabel="name"
        clearable="true"
        ></tc-select-search>
    <tc-select-search placeholder="Carrier"
                      [group]="consigneeForm" ctrlName="carrier_id"
                      [items]="carriers$ | async"
                      (change)="onCarrierChange()"
                      bindValue="carrier_id"
                      bindLabel="name"
    ></tc-select-search>
    <mat-form-field gdColumn="span 2">
      <mat-label>Code</mat-label>
      <input matInput autocomplete="off" formControlName="code">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Establishment #</mat-label>
      <input matInput autocomplete="off" formControlName="establishment">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Street address 1</mat-label>
      <input matInput autocomplete="off" formControlName="street1">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Street address 2</mat-label>
      <input matInput autocomplete="off" formControlName="street2">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>City</mat-label>
      <input matInput autocomplete="off" formControlName="city"
          [matAutocomplete]="cities">
      <mat-autocomplete autoActiveFirstOption #cities="matAutocomplete">
        <mat-option *ngFor="let city of cities$ | async" [value]="city.name">{{city.name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <tc-select-search gdColumn="span 2" placeholder="State/Province"
        [group]="consigneeForm" ctrlName="state"
        (change)="onStateChange()"
        [items]="states$ | async" bindValue="name" bindLabel="name"
        clearable="true"
        ></tc-select-search>
    <tc-select-search gdColumn="span 2" placeholder="Country"
        [group]="consigneeForm" ctrlName="cc"
        (change)="onCountryChange($event)"
        [items]="countries$ | async" bindValue="code" bindLabel="name"
        clearable="true"
        ></tc-select-search>
    <mat-form-field gdColumn="span 2">
      <mat-label>Zip code</mat-label>
      <input matInput autocomplete="off" formControlName="postal">
    </mat-form-field>
    <div gdColumn="span 2" gdColumns="minmax(0, 3fr) minmax(0, 1fr)" gdGap="10px">
      <mat-form-field>
        <mat-label>Telephone # 1</mat-label>
        <input matInput autocomplete="off" formControlName="telephone1">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ext</mat-label>
        <input matInput autocomplete="off" formControlName="telephone1_ext">
      </mat-form-field>
    </div>
    <mat-form-field gdColumn="span 2">
      <mat-label>Email</mat-label>
      <input matInput type="email" tc-email-pattern autocomplete="off" formControlName="email">
    </mat-form-field>
    <div gdColumn="span 2" gdColumns="minmax(0, 3fr) minmax(0, 1fr)">
      <mat-form-field>
        <mat-label>Telephone # 2</mat-label>
        <input matInput autocomplete="off" formControlName="telephone2">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ext</mat-label>
        <input matInput autocomplete="off" formControlName="telephone2_ext">
      </mat-form-field>
    </div>
    <div gdColumn="span 2" gdColumns="minmax(0, 3fr) minmax(0, 1fr)">
      <mat-form-field>
        <mat-label>Fax</mat-label>
        <input matInput autocomplete="off" formControlName="fax">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ext</mat-label>
        <input matInput autocomplete="off" formControlName="fax_ext">
      </mat-form-field>
    </div>
    <mat-form-field gdColumn="span 2">
      <mat-label>Tax ID #</mat-label>
      <input matInput autocomplete="off" formControlName="tax_id">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Note</mat-label>
      <textarea matInput class="form-control" formControlName="note" rows="1"></textarea>
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Montship code</mat-label>
      <input matInput autocomplete="off" formControlName="montshipCode">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Contact name</mat-label>
      <input matInput autocomplete="off" formControlName="contact_name">
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">Save</button>
  </div>
</form>
