<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;{{title}}
</h2>
<form [formGroup]="cnForm" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
    <tc-select-search placeholder="Deal #"
        [ctrl]="cnForm.controls.deal_id"
        [readonly]="isReadonly || !isNew || !!predefinedDealId"
        [items]="dealIds$ | async"
        ></tc-select-search>
    <mat-form-field>
      <mat-label>Company</mat-label>
      <input readonly matInput [value]="companyName$ | async">
    </mat-form-field>
    <tc-select-search placeholder="Cost"
        [ctrl]="cnForm.controls.cost_id"
        (change)="onCostChange($event)"
        [readonly]="isReadonly || !cnForm.controls.deal_id.value"
        [items]="costs$ | async" bindValue="cost_id" bindLabel="service"
        ></tc-select-search>
    <mat-form-field>
      <mat-label>Amount ({{currency$ | async}})</mat-label>
      <input matInput type="number" step="0.01" min="0" [disabled]="isReadonly" [formControl]="cnForm.controls.amount">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Description</mat-label>
      <textarea matInput [formControl]="cnForm.controls.description" [disabled]="isReadonly"></textarea>
    </mat-form-field>
    <div gdColumn="span 2" *ngIf="isNew && cnForm.controls.description.value">
      <mat-checkbox color="primary" [formControl]="cnForm.controls.createNote" [disabled]="isReadonly">Create internal note</mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">{{isReadonly ? 'Close' : 'Cancel'}}</button>
    <button mat-raised-button type="submit" color="primary" *ngIf="!isReadonly" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
