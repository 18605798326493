import { Deal, DealViewBase, DocumentTemplate, ShipmentRate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { BusinessTypeApiService } from 'src/api/business-type'
import { LogisticNoteApiService } from 'src/api/logistic-note'
import { ProductCategoryApiService } from 'src/api/product/category'
import { ProductSpecificationApiService } from 'src/api/product/specification'
import { ProductTypeApiService } from 'src/api/product/type'
import { ReceiptApiService } from 'src/api/receipt'
import { ShipmentRateApiService } from 'src/api/shipment-routing/shipment-rate'
import { CountriesService } from 'src/pages/admin/settings/admin-setting-geographic/countries.service'
import { CurrenciesService } from 'src/pages/admin/settings/admin-setting-payments/currency/currencies.service'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { ItemTypesService } from 'src/pages/admin/settings/product-specifications/item-types/item-types.service'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { WeightTypesService } from 'src/pages/admin/settings/product-specifications/weight-types/weight-types.service'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { TrackingProvidersService } from 'src/pages/admin/settings/tracking-providers/tracking-providers.service'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { AccountsService } from './accounts.service'
import { UsersService } from './users.service'

const QUERY_ALL = { limit: Number.MAX_SAFE_INTEGER }


productsResolver.$inject = ['Products']
export function productsResolver(
  Products: ProductsService,
) {
  'ngInject'

  return Products.getAll()
}

proteinCategoriesResolver.$inject = ['ProductCategoryApi']
export function proteinCategoriesResolver(
  ProductCategoryApi: ProductCategoryApiService,
) {
  'ngInject'

  // TODO: move this query to Categories service
  return ProductCategoryApi.list({limit: Number.MAX_SAFE_INTEGER})
                           .then(res => res.data.filter(category => category.type === 'protein'))
}

productCategoriesResolver.$inject = ['ProductCategoryApi']
export function productCategoriesResolver(
  ProductCategoryApi: ProductCategoryApiService,
) {
  'ngInject'

  return ProductCategoryApi.list({limit: Number.MAX_SAFE_INTEGER})
                           .then(res => res.data)
}

productTypesResolver.$inject = ['ProductTypeApi']
export function productTypesResolver(
  ProductTypeApi: ProductTypeApiService,
) {
  'ngInject'

  return ProductTypeApi.list({limit: Number.MAX_SAFE_INTEGER}).then(res => res.data)
}

productSpecificationsResolver.$inject = ['ProductSpecificationApi']
export function productSpecificationsResolver(
  ProductSpecificationApi: ProductSpecificationApiService,
) {
  'ngInject'

  return ProductSpecificationApi.list({limit: Number.MAX_SAFE_INTEGER}).then(res => res.data)
}

pricingTermsResolver.$inject = ['PricingTerms']
export function pricingTermsResolver(
  PricingTerms,
) {
  'ngInject'

  return PricingTerms.getPricingTerms()
}

currenciesResolver.$inject = ['Currencies']
export function currenciesResolver(
  Currencies: CurrenciesService,
) {
  'ngInject'

  return Currencies.getCurrenciesByIds()
}

countriesResolver.$inject = ['Countries']
export function countriesResolver(
  Countries: CountriesService,
) {
  'ngInject'

  return Countries.getCountries().then(res => res.data)
}

usersResolver.$inject = ['AuthApi', 'Users']
export function usersResolver(
  AuthApi: AuthApiService,
  Users: UsersService,
) {
  'ngInject'

  return Users.getUsers(AuthApi.currentUser.account).then(res => res.data)
}

locationsResolver.$inject = ['Locations']
export function locationsResolver(
  Locations: LocationsService,
) {
  'ngInject'

  return Locations.getLocations().then(res => res.data)
}

accountsResolver.$inject = ['Accounts']
export function accountsResolver(
  Accounts: AccountsService,
) {
  'ngInject'

  return Accounts.getAccounts().then(res => res.data)
}

export function generateSimpleAccountsResolver(fields: string[]) {
  function resolver(
    Accounts: AccountsService,
  ) {
    'ngInject'

    return Accounts.getSimpleAccounts(fields).then(res => res.data)
  }
  resolver.$inject = ['Accounts']

  return resolver
}


suppliersResolver.$inject = ['Accounts']
export function suppliersResolver(
  Accounts: AccountsService,
) {
  'ngInject'

  return Accounts.getSuppliers()
}

buyersResolver.$inject = ['Accounts']
export function buyersResolver(
  Accounts: AccountsService,
) {
  'ngInject'

  return Accounts.getBuyers()
}

serviceProvidersResolver.$inject = ['Accounts']
export function serviceProvidersResolver(
  Accounts: AccountsService,
) {
  'ngInject'

  return Accounts.getServiceProviders()
}

banksResolver.$inject = ['Banks']
export function banksResolver(
  Banks,
) {
  'ngInject'

  return Banks.getBanks()
}

measuresResolver.$inject = ['Measures']
export function measuresResolver(
  Measures: MeasuresService,
) {
  'ngInject'

  return Measures.getMeasuresByIds().then(r => cloneDeep(r))
}

weightTypesResolver.$inject = ['WeightTypes']
export function weightTypesResolver(
  WeightTypes: WeightTypesService,
) {
  'ngInject'

  return WeightTypes.getWeightTypes().then(res => res.data)
}

itemTypesResolver.$inject = ['ItemTypes']
export function itemTypesResolver(
  ItemTypes: ItemTypesService,
) {
  'ngInject'

  return ItemTypes.getItemTypes().then(res => res.data)
}

wrappingsResolver.$inject = ['WrappingTypes']
export function wrappingsResolver(
  WrappingTypes,
) {
  'ngInject'

  return WrappingTypes.getWrappingTypes().then(res => res.data)
}

packagingsResolver.$inject = ['PackageTypes']
export function packagingsResolver(
  PackageTypes,
) {
  'ngInject'

  return PackageTypes.getPackageTypes().then(res => res.data)
}

carriersResolver.$inject = ['Carriers']
export function carriersResolver(
  Carriers: CarriersService,
) {
  'ngInject'

  return Carriers.getCarriers()
}

trackingProvidersResolver.$inject = ['TrackingProviders']
export function trackingProvidersResolver(
  TrackingProviders: TrackingProvidersService,
) {
  'ngInject'

  return TrackingProviders.getTrackingProviders()
}


export function generateShipmentRatesResolver(dealId?: string | string[], matchedOfferId?: string | string[]) {
  function resolver(
    ShipmentRateApi: ShipmentRateApiService,
  ) {
    'ngInject'

    const isPublicShipmentRate = (rate: ShipmentRate) => (!rate.visible_to_matched_offer_id && !rate.visible_to_deal_id);

    return ShipmentRateApi.list({ ...QUERY_ALL }).then(r => {
      let rates = r.data;
      if(dealId) {
        return rates?.filter(rate => isPublicShipmentRate(rate) || rate.visible_to_deal_id === dealId);
      } else if (matchedOfferId) {
        return rates?.filter(rate => isPublicShipmentRate(rate) || rate.visible_to_matched_offer_id === matchedOfferId);
      } else {
        return rates?.filter(isPublicShipmentRate);
      }
    })
  }
  resolver.$inject = ['ShipmentRateApi']

  return resolver
}

businessTypesResolver.$inject = ['BusinessTypeApi']
export function businessTypesResolver(
  BusinessTypeApi: BusinessTypeApiService,
) {
  'ngInject'

  return BusinessTypeApi.list(QUERY_ALL).then(r => r.data)
}

statusNotesResolver.$inject = ['LogisticNoteApi']
export function statusNotesResolver(
  LogisticNoteApi: LogisticNoteApiService,
) {
  'ngInject'

  return LogisticNoteApi.list(QUERY_ALL).then(r => r.data)
}

export function documentSetResolver(
  deal: DeepReadonly<Deal | DealViewBase>,
  defaultValue?: DocumentTemplate[],
) {

  documentSetResolverDI.$inject = ['DealDocuments']
  return documentSetResolverDI


  function documentSetResolverDI(DealDocuments: DealDocumentsService) {
    return DealDocuments.getDocSetForDeal(deal).toPromise()
    .catch((err) => {
      console.error('Unable to get documents set', err)
      if (!defaultValue) {
        throw err
      }
      return [] as DocumentTemplate[]
    })
  }
}

unappliedReceiptsResolver.$inject = ['ReceiptApi', 'AuthApi']
export function unappliedReceiptsResolver(
  ReceiptApi: ReceiptApiService,
  AuthApi: AuthApiService,
) {
  'ngInject'

  return ReceiptApi.list(AuthApi.currentUser.account, { ...QUERY_ALL, filters: { status: [1], unassigned: true } })
  .then(({ data }) => data)
}

export function childOffersResolver(
  offerId,
) {

  childOffersResolverDI.$inject = ['OfferApi', 'AuthApi']
  return childOffersResolverDI


  async function childOffersResolverDI(OfferApi, AuthApi) {
    return await OfferApi.filter(AuthApi.currentUser.account, {filters: {load_parent: offerId}})
  }
}
