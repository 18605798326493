import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AllInvoiceStatuses, Invoice, TableKey } from '@tradecafe/types/core'
import { DeepReadonly, findBuyerInvoice, findSupplierInvoice } from '@tradecafe/types/utils'
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, compact } from 'lodash-es'
import { combineLatest, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'

import { actualizeCostToZeroSuccess, autosaveDealCostSuccess, patchDealProductSuccess, saveDealFormSuccess, unactualizeCostToZeroSuccess } from 'src/app/store/deal-view.actions'
import { loadMeasures } from 'src/app/store/measures'
import { loadProducts } from 'src/app/store/products'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { InvoiceViewFormService } from 'src/components/invoices/invoice-view-form/invoice-view-form.service'
import { UploaderDialogService } from 'src/components/uploader-dialog/uploader-dialog.service'
import { environment } from 'src/environments/environment'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { CostFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/cost-form/cost-form.service'
import { DealFormBaseController } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form-base.component'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { VendorCreditsService } from 'src/services/data/vendor-credits.service'
import { DealInvoicesDataSource } from 'src/services/table-utils/data-sources/deal-invoices-data-source'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { DealDocumentsService } from '../../../deal-documents/deal-documents.service'


export interface DealFinanceOverlayOptions {
  dealId: string
}

@Component({
  selector: 'tc-deal-finance-overlay',
  templateUrl: './deal-finance-overlay.component.html',
  styleUrls: ['./deal-finance-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealFinanceOverlayComponent extends DealFormBaseController implements OnInit {
  constructor(
    DealForm: DealFormService,
    private InvoiceViewForm: InvoiceViewFormService,
    private FxRates: FxRatesService,
    protected store: Store,
    private VendorCredits: VendorCreditsService,
    private Invoices: InvoicesService,
    private actions$: Actions,
    protected UploaderDialog: UploaderDialogService,
    protected DealDocuments: DealDocumentsService,
    CostForm: CostFormService,
    protected toaster: ToasterService,
    @Inject(MAT_DIALOG_DATA) dialogData: DealFinanceOverlayOptions,
  ) {
    super(of(dialogData.dealId), DealForm, store, UploaderDialog, DealDocuments, toaster, CostForm)
  }

  supplierInvoice$ = this.dealViewRaw$.pipe(map(dv => selectPartyInvoice(findSupplierInvoice(dv.invoices, dv.deal.supplier_id))))
  buyerInvoice$ = this.dealViewRaw$.pipe(map(dv => selectPartyInvoice(findBuyerInvoice(dv.invoices, dv.deal.buyer_id))))

  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  invoicesDataSource = new DealInvoicesDataSource(this.dealViewRaw$, this.accounts$, this.VendorCredits, this.Invoices)
  protected readonly invoicesTableKey = TableKey.ShippingDetailsInvoices
  protected readonly enableBrokerageDeals = environment.enableBrokerageDeals
  protected readonly isBrokerageDeal$ = environment.enableBrokerageDeals
    ? this.dealViewRaw$.pipe(map(dv => dv.deal.deal_type === 'brokerage'), distinctUntilChanged())
    : of(false)

  buyer$ = combineLatest([this.dealRaw$, this.store.select(selectAccountEntities)])
    .pipe(map(([deal, accounts]) => accounts[deal.buyer_id]?.name))
  brokerageCustomer$ = combineLatest([this.dealRaw$, this.store.select(selectAccountEntities)])
    .pipe(map(([deal, accounts]) => accounts[deal.brokerage?.customer]?.name))
  buyerUser$ = combineLatest([this.dealRaw$, this.store.select(selectUserEntities)])
    .pipe(map(([deal, users]) => compact(deal.attributes.buyer_user_ids?.map(id => users[id]?.fullname))[0]))
  logisticsUser$ = combineLatest([this.dealRaw$, this.store.select(selectUserEntities)])
    .pipe(map(([deal, users]) => users[deal.logistics_user_id]?.fullname))
  supplier$ = combineLatest([this.dealRaw$, this.store.select(selectAccountEntities)])
    .pipe(map(([deal, accounts]) => accounts[deal.supplier_id]?.name))
  supplierTrader$ = combineLatest([this.dealRaw$, this.store.select(selectUserEntities)])
    .pipe(map(([deal, accounts]) => accounts[deal.trader_user_id_supplier]?.fullname))
  supplierUser$ = combineLatest([this.dealRaw$, this.store.select(selectUserEntities)])
    .pipe(map(([deal, users]) => compact(deal.attributes.supplier_user_ids?.map(id => users[id]?.fullname))[0]))
  buyerTrader$ = combineLatest([this.dealRaw$, this.store.select(selectUserEntities)])
    .pipe(map(([deal, users]) => users[deal.trader_user_id]?.fullname))
  brokerageTrader$ = combineLatest([this.dealRaw$, this.store.select(selectUserEntities)])
    .pipe(map(([deal, users]) => users[deal.brokerage?.traderId]?.fullname))

  askRate$ = this.dealRaw$.pipe(map(deal =>
    deal.attributes.estimated.buy_currency === 'CAD'
      ? 1
      : deal.attributes.fx_rates.rates[deal.attributes.estimated.buy_currency][deal.attributes.fx_rates_ask_range]?.ask))
  bidRate$ = this.dealRaw$.pipe(map(deal =>
    deal.attributes.estimated.sell_currency === 'CAD'
      ? 1
      : deal.attributes.fx_rates.rates[deal.attributes.estimated.sell_currency][deal.attributes.fx_rates_bid_range]?.bid))

  partialBuyPriceCAD$ = this.dealRaw$.pipe(map(deal => this.FxRates.toCADSync(
    deal.attributes.actual.partial_buy_price,
    deal.attributes.estimated.buy_currency, 'ask',
    deal.attributes.fx_rates.rates,
    deal.attributes.fx_rates_ask_range)))

  partialSellPriceCAD$ = this.dealRaw$.pipe(map(deal => this.FxRates.toCADSync(
    deal.attributes.actual.partial_sell_price,
    deal.attributes.estimated.sell_currency, 'bid',
    deal.attributes.fx_rates.rates,
    deal.attributes.fx_rates_bid_range)))

  ngOnInit() {
    this.actions$.pipe(
      ofType(
        actualizeCostToZeroSuccess,
        autosaveDealCostSuccess,
        saveDealFormSuccess,
        patchDealProductSuccess,
        unactualizeCostToZeroSuccess,
      ),
      switchMap(() => this.fetchDeal()),
      untilComponentDestroyed(this),
    ).subscribe()

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadMeasures({}))
    this.fetchDeal().subscribe()
  }

  showInvoice(invoice: DeepReadonly<Invoice>) {
    return this.InvoiceViewForm.showInvoice(cloneDeep(invoice) as Invoice)
  }
}

function selectPartyInvoice(invoice: DeepReadonly<Invoice>) {
  return {
    invoice,
    invoiceId: invoice?.invoice_id,
    statusColor: AllInvoiceStatuses[invoice?.status]?.color,
    statusName: AllInvoiceStatuses[invoice?.status]?.value,
  }
}
