import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { DealViewRawDeal, DealViewRawInvoices, DealViewRawStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { pickBy } from 'lodash-es'
import { BehaviorSubject, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { patchDealProducts, patchDealProductsFailure, patchDealProductsSuccess } from 'src/app/store/deal-view.actions'
import { DealFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'


export interface DealProductsOverlayOptions {
  dealForm: DealFormGroup
  dealViewRaw: DeepReadonly<DealViewRawDeal & DealViewRawInvoices & DealViewRawStatus>,
}

@Component({
  selector: 'tc-deal-products-overlay',
  templateUrl: './deal-products-overlay.component.html',
  styleUrls: ['./deal-products-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealProductsOverlayComponent {

  constructor(
    private actions$: Actions,
    private store: Store,
    private dialogRef: MatDialogRef<DealProductsOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: DealProductsOverlayOptions,
  ) {}

  protected readonly invoices$ = of(this.data.dealViewRaw.invoices)
  protected readonly dealViewRaw$ = of(this.data.dealViewRaw)
  protected readonly inProgress$ = new BehaviorSubject(false)
  protected readonly dealId = this.data.dealForm.value.details.deal.deal_id

  async cancel() {
    this.dialogRef.close()
  }

  submit() {
    if (this.inProgress$.value) return
    const productsForm = this.data.dealForm.controls.products
    productsForm.markAllAsTouched()
    productsForm.updateValueAndValidity()
    if (!productsForm.valid) return

    this.inProgress$.next(true)
    const dealForm = this.data.dealForm.serialize()
    const patches = productsForm.controls.map(productForm =>
      pickBy(productForm.serialize(), (_value, key) =>
        productForm.controls[key]?.dirty))
    this.store.dispatch(patchDealProducts({ dv: this.data.dealViewRaw, dealForm, patches }))
    this.actions$.pipe(ofType(patchDealProductsSuccess, patchDealProductsFailure), take(1)).subscribe((action) => {
      if (action.type === patchDealProductsSuccess.type) this.dialogRef.close()
      this.inProgress$.next(false)
    })
  }
}
