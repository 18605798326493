import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { FinanceIssuesModule } from 'src/shared/finance-issues/finance-issues.module'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ModalModule } from 'src/shared/modal'
import { DealProductFormModule } from '../deal-product-form/deal-product-form.module'
import { DealProductsOverlayComponent } from './deal-products-overlay.component'
import { DealProductsOverlayService } from './deal-products-overlay.service'

@NgModule({
  declarations: [DealProductsOverlayComponent],
  providers: [DealProductsOverlayService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    FinanceIssuesModule,
    FlexLayoutModule,
    ModalModule,
    DealProductFormModule,
    LocksModule,
    DealViewIncompleteComponent,
  ],
})
export class DealProductsOverlayModule {}
