import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AesConditionSeverity, AesReportStatus, AesResponseStatus, DealViewRawExportReports } from '@tradecafe/types/core'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject, combineLatest, merge, ReplaySubject } from 'rxjs'
import { distinctUntilChanged, map, mapTo, take } from 'rxjs/operators'
import { setAesResponseStatus, setAesResponseStatusFailure } from 'src/app/store/aes/aes.actions'
import { loadLocations } from 'src/app/store/locations'
import { DealsService } from 'src/services/data/deals.service'
import { DeepReadonly } from '@tradecafe/types/utils'

export interface AesResponseReviewDialogOptions {
  dealId: string,
  deal: DeepReadonly<DealViewRawExportReports>
}

@Component({
  selector: 'tc-aes-response-review-dialog',
  templateUrl: './aes-response-review-dialog.component.html',
  styleUrls: ['./aes-response-review-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AesResponseReviewDialogComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private store: Store,
    private actions$: Actions,
    private Deals: DealsService,
    private dialogRef: MatDialogRef<AesResponseReviewDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private dialogData: AesResponseReviewDialogOptions,
  ) { super() }

  protected dealId = this.dialogData.dealId
  private dealViewRaw$ = new ReplaySubject<DealViewRawExportReports>(1)
  protected exportReport$ = this.dealViewRaw$.pipe(map((dv: any) => dv.export_reports[0]))
  protected reportStatusName$ = this.exportReport$.pipe(map((report: any) => this.reportStatusDisplayName(report?.status)))

  private responseIndex$ = new BehaviorSubject<number>(0)
  private indexedReport$ = combineLatest([
    this.exportReport$,
    this.responseIndex$,
  ])

  protected isMostRecentResponse$ = this.responseIndex$.pipe(map(i => i === 0))
  protected isOldestResponse$ = this.indexedReport$.pipe(map(([report, index]) => index >= report?.responses?.length - 1))

  protected reportResponse$ = this.indexedReport$.pipe(
    map(([report, index]) => {
      if (!report?.responses?.length) {
        return null
      }
      const startIndex = report?.responses?.length - index - 1
      if (startIndex < 0) {
        return null
      }
      return report.responses[startIndex]
    }),
  )

  protected responseStatus$ = this.reportResponse$.pipe(map((response) => response?.status))
  inProgress$ = new BehaviorSubject<'loading'|'set-status'|undefined>('loading')

  ngOnInit(): void {
    this.store.dispatch(loadLocations({}))
    // @ts-ignore
    this.dealViewRaw$.next(this.dialogData.deal)
    this.inProgress$.next(undefined)

    this.actions$.pipe(ofType(setAesResponseStatus), untilComponentDestroyed(this)).subscribe(() => {
      this.dialogRef.close()
    })

    merge(
      this.actions$.pipe(ofType(setAesResponseStatus), mapTo('set-status' as const)),
      this.actions$.pipe(ofType(setAesResponseStatusFailure), mapTo(undefined as 'set-status')),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(inProgress => this.inProgress$.next(inProgress))
  }

  protected cancel() {
    this.dialogRef.close()
  }

  protected setAesResponseStatus(status: AesResponseStatus) {
    console.log('setting status ' + status)
    this.exportReport$.pipe(take(1)).subscribe(report =>
      this.store.dispatch(setAesResponseStatus({ reportId: report.export_report_id, status })))
  }

  protected seeOlderResponse(): void {
    const current = this.responseIndex$.getValue()
    this.responseIndex$.next(current + 1)
  }

  protected seeNewerResponse(): void {
    const current = this.responseIndex$.getValue()
    this.responseIndex$.next(current - 1)
  }


  protected severityDisplayName(severity: AesConditionSeverity): string {
    if (severity === 'verification_requested') {
      return 'verification requested'
    } else if (severity === 'compliance_alert') {
      return 'compliance alert'
    } else {
      return severity
    }
  }

  protected reportStatusDisplayName(status: AesReportStatus): string {
    if (status === 'accepted-conditionally') {
      return 'accepted conditionally'
    } else {
      return status
    }
  }

  protected responseStatusDisplayName(status: AesResponseStatus): string {
    if (status === 'not-read') {
      return 'not read'
    } else {
      return status
    }
  }
}
