import { createAction, props } from '@ngrx/store'
import { ProductType, TableKey } from '@tradecafe/types/core'


export const loadProductTypes = createAction(
  '[ProductType/API] Load Product Types',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadProductTypesSuccess = createAction(
  '[ProductType/API] Load Product Types Success',
  props<{
    tableKey?: TableKey,
    productTypes: ProductType[],
  }>(),
)
export const loadProductTypesFailure = createAction(
  '[ProductType/API] Load Product Types Failure',
  props<{ error: Error }>(),
)
