import { Injectable } from '@angular/core'
import { AllInvoiceStatuses, Cost, DealViewRaw, INVOICE_APPROVED, INVOICE_PAID, INVOICE_SCHEDULED, VendorCredit, VENDOR_CREDIT_APPROVED } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, find, identity } from 'lodash-es'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { VendorCreditFormComponent, VendorCreditFormOptions } from 'src/components/credits/vendor-credit/vendor-credit-form.component'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ModalService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { VendorCreditsService } from '../../../services/data/vendor-credits.service'
import { dayjs } from '../../../services/dayjs'


export interface VendorCreditWithDealCosts extends VendorCredit {
  deal?: {
    costs: Cost[]
    deal_id: string
    supplier_id: string | number
  }
}

@Injectable()
export class VendorCreditFormService {
  constructor(
    private toaster: ToasterService,
    private modal: ModalService,
    private ConfirmModal: ConfirmModalService,
    private AuthApi: AuthApiService,
    private VendorCredits: VendorCreditsService,
    private Invoices: InvoicesService,
  ) {}

  dealVendorCreditsChange$ = new Subject<void>()

  showAddItem(dealViewRaw?: DeepReadonly<DealViewRaw>) {
    return this.modal.openDialog<VendorCreditFormComponent, VendorCreditFormOptions, VendorCredit>(VendorCreditFormComponent, {
      dealViewRaw,
      vendorCredit: {
        // approved automatically
        status: VENDOR_CREDIT_APPROVED,
        approval: {
          user_id: this.AuthApi.currentUser.user_id,
          date: dayjs.utc().unix(),
        },
      },
      title: 'New Vendor Credit',
      isNew: true,
    }).pipe(filter(identity)).toPromise()
  }

  showUpdateItemImmutable(item: DeepReadonly<VendorCredit>, readonly?: unknown) {
    return this.showUpdateItem(cloneDeep(item) as VendorCredit, readonly)
  }

  showUpdateItem(vendorCredit: VendorCredit, readonly?: unknown) {
    return this.modal.openDialog<VendorCreditFormComponent, VendorCreditFormOptions, VendorCredit>(VendorCreditFormComponent, {
      vendorCredit,
      title: `${readonly ? 'Vendor Credit' : 'Update Vendor Credit'} #${vendorCredit.credit_note_id}`,
      isReadonly: !!readonly,
    }).pipe(filter(identity)).toPromise()
      .then(stored => Object.assign(vendorCredit, stored))
  }

  async showVoidVendorCreditImmutable(vendorCredit: DeepReadonly<VendorCreditWithDealCosts>) {
    return this.showVoidVendorCredit(cloneDeep(vendorCredit) as VendorCreditWithDealCosts)
  }

  async showVoidVendorCredit(vendorCredit: VendorCreditWithDealCosts) {
    if (vendorCredit.invoice_id) {
      const invoice = await this.Invoices.getById(vendorCredit.invoice_id)
      if ([INVOICE_SCHEDULED, INVOICE_APPROVED, INVOICE_PAID].includes(invoice?.status)) {
        this.toaster.warning(`VC cannot be voided if it is applied to an invoice that is in Scheduled, Approved, or Paid status. Invoice ${invoice.invoice_id} is in ${AllInvoiceStatuses[invoice.status].value} status`)
        return undefined
      }
    }

    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to void this vendor credit?',
    })

    try {
      const stored = await this.VendorCredits.voidVendorCredit(vendorCredit, {
        cost: find(vendorCredit.deal?.costs, {cost_id: vendorCredit.cost_id}),
      })
      this.toaster.success('Credit note voided successfully.')

      return stored
    } catch (err) {
      console.error('Unable to void credit note.', err)
      this.toaster.error('Unable to void credit note.', err)
      throw err
    }
  }

  async showPaidVendorCredit(item: VendorCredit) {
    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to change this vendor credit status to Paid?',
    })

    try {
      const vendorCredit = await this.VendorCredits.savePaidVendorCredit(item)
      this.toaster.success('Vendor credit status changed successfully.')

      return vendorCredit
    } catch (err) {
      console.error('Unable to change vendor credit status.', err)
      this.toaster.error('Unable to change vendor credit status.', err)
      throw err
    }
  }

  async showRefundVendorCredit(item: VendorCredit) {
    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to change this vendor credit status to Refunded?',
    })

    try {
      const vendorCredit = await this.VendorCredits.saveRefundedVendorCredit(item)
      this.toaster.success('Vendor credit status changed successfully.')

      return vendorCredit
    } catch (err) {
      console.error('Unable to change vendor credit status.', err)
      this.toaster.error('Unable to change vendor credit status.', err)
      throw err
    }
  }

  // async showVoidVendorCreditOf(deal: DealView, vendorCredit: VendorCreditWithDealCosts) {
  //   vendorCredit.deal = deal
  //   // TODO: ensure deal has costs loaded
  //   await this.showVoidVendorCredit(vendorCredit)
  //   const cost = find(deal.costs, {cost_id: vendorCredit.cost_id})
  //   await this.DealViewSaver.updateCost(deal, cost)
  //   this.dealVendorCreditsChange$.next()
  // }
}
