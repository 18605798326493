import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { CostFormModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/cost-form/cost-form.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { TypeSafeMatCellDefModule } from '../type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { CostsListComponent } from './costs-list.component'
import { MatCheckboxModule } from '@angular/material/checkbox'

@NgModule({
  declarations: [CostsListComponent],
  exports: [CostsListComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatCheckboxModule,
    ScrollingModule,
    PipesModule,
    CostFormModule,
    TypeSafeMatCellDefModule,
  ],
})
export class CostsListModule { }
