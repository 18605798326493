import { Injectable } from '@angular/core'
import { Invoice } from '@tradecafe/types/core'
import { accountsResolver } from 'src/services/data/deal.resolvers'
import { ModalProxyService } from 'src/shared/modal'


@Injectable()
export class InvoiceViewFormService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}


  showInvoice = (invoice: Invoice, statusActionTest?, actions = {}) => {
    return this.modalHelper.showOverlay('tcInvoiceViewForm', {
      backdrop: true,
      resolve: {
        invoice: () => invoice,
        statusActionTest: () => statusActionTest,
        actions: () => actions,
        accounts: accountsResolver,
      },
    }).catch((err) => {
      if (err === 'backdrop click' || err === 'cancel' || err === 'escape key press') {
        return invoice
      } else throw err
    })
  }
}
