import { Injectable } from '@angular/core'
import { MATCHED_OFFER_CANCELED, MATCHED_OFFER_EXPIRED, MATCHED_OFFER_PENDING, MATCHED_OFFER_REJECTED, MATCHED_OFFER_SENT, MatchedOffer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { filter, find, get, isArray, isPlainObject, map, round } from 'lodash-es'
import { MatchedOfferApiService } from 'src/api/matched-offer'
import { dayjs } from 'src/services/dayjs'

@Injectable()
export class MatchedOffersService {
  constructor (private MatchedOfferApi: MatchedOfferApiService) {}

  /** @deprecated use isMatchedOfferFinalized instead */
  isFinalized = isMatchedOfferFinalized
  /** @deprecated use isMatchedOfferExpired instead */
  isExpired = isMatchedOfferExpired
  /** @deprecated use isMatchedOfferBiddable instead */
  isBiddable = isMatchedOfferBiddable

  async getById(id: string) {
    const { data: matchedOffer } = await this.MatchedOfferApi.get(id)
    this.validateMatchedOffer(matchedOffer)
    return matchedOffer
  }

  async getAll() {
    let { data: matchedOffers } = await this.MatchedOfferApi.list({limit: Number.MAX_SAFE_INTEGER})
    matchedOffers = matchedOffers.filter(this.validateMatchedOffer)
    return matchedOffers
  }

  async queryOffers(query) {
    let { data: matchedOffers } = await this.MatchedOfferApi.filter({
      limit: Number.MAX_SAFE_INTEGER,
      ...query,
    })
    matchedOffers = matchedOffers.filter(this.validateMatchedOffer)
    matchedOffers.forEach(this.fixMatchedOffer)
    return matchedOffers
  }

  async reloadOffers(matchedOffers) {
    return await Promise.all([
      Promise.all(map(filter(matchedOffers, (mo) => get(mo, 'offer.bwi_inventory') && [MATCHED_OFFER_EXPIRED, MATCHED_OFFER_CANCELED].includes(mo.status)),
        (mo) => this.MatchedOfferApi.reopen(mo.matched_offer_id))),
      await Promise.all(map(filter(matchedOffers, (mo) => mo.status === MATCHED_OFFER_REJECTED),
        (mo) => this.MatchedOfferApi.generateOfferNumber(mo.matched_offer_id))),
    ])
  }

  async sendOffers(matchedOffers) {
    await this.reloadOffers(matchedOffers)
    const res = await this.MatchedOfferApi.sendMessages(map(matchedOffers, 'matched_offer_id'))
    const stored = await Promise.all<any>(matchedOffers.map(mo =>
        this.MatchedOfferApi.get(mo.matched_offer_id).then(r => r.data)))
    stored.forEach(mo =>
      Object.assign(find(matchedOffers, { matched_offer_id: mo.matched_offer_id }), mo))
    return res
  }

  async cancelOffers(matchedOffers: MatchedOffer[]) {
    return this.MatchedOfferApi.cancel(map(matchedOffers, 'matched_offer_id'))
  }

  async updateOfferImmutable(matchedOffer: DeepReadonly<MatchedOffer>) {
    const { data } = await this.MatchedOfferApi.update(matchedOffer.matched_offer_id, matchedOffer)
    return data
  }

  async updateOffer(matchedOffer: MatchedOffer) {
    const data = await this.updateOfferImmutable(matchedOffer)
    Object.assign(matchedOffer, data)
    return data
  }

  async calculateOfferImmutable(matchedOffer: DeepReadonly<MatchedOffer>) {
    const { data } = await this.MatchedOfferApi.calculate(matchedOffer)
    return data
  }

  async calculateOffer(matchedOffer: MatchedOffer) {
    const data = await this.calculateOfferImmutable(matchedOffer)
    Object.assign(matchedOffer, data)
    return data
  }

  async confirmOffer(matchedOffer: DeepReadonly<MatchedOffer>) {
    const { data } = await this.MatchedOfferApi.accept(matchedOffer.matched_offer_id)
    // Object.assign(matchedOffer, data)
    return data
  }

  private validateMatchedOffer(mo: MatchedOffer) {
    return isArray(mo.costs) && isPlainObject(mo.fx_rate)
  }

  private fixMatchedOffer(mo: MatchedOffer) {
    mo.bid.price = round(mo.bid.price, 4)
    if (!mo.attributes) {
      // console.warn(`Matched Offer (id="${mo.matched_offer_id}") has no attributes`, mo)
      mo.attributes = {}
    }
    mo.offer.attributes = mo.offer.attributes || {}
    mo.bid.attributes = mo.bid.attributes || {}
    if (!mo.costs.every(cost => cost.amount && cost.attributes)) {
      const invalid = mo.costs.filter(cost => !cost.amount || !cost.attributes)
      console.warn(`Matched Offer (id="${mo.matched_offer_id}") has invalid cost(s)`, invalid, mo)
      mo.costs = mo.costs.filter(cost => cost.amount && cost.attributes)
    }
    mo.costs.forEach((cost) => {
      if (!cost.type) {
        console.warn(`Matched Offer (id="${mo.matched_offer_id}") has cost(s) with undefined "type"`, mo)
        cost.type = cost.attributes?.default_cost ? 'secondary' : 'tertiary'
      }
      if (!cost.creator_id) {
        // console.warn(`Matched Offer ${mo.matched_offer_id} has cost(s) with no creator_id`, mo)
        cost.creator_id = mo.bid.attributes.trader_user_id
      }
    })
  }

  async getCountryCodes() {
    const { data } = await this.MatchedOfferApi.getCountryCodes()
    return data
  }

  async win(matched_offer_id) {
    return this.MatchedOfferApi.win(matched_offer_id)
  }
}

export function isMatchedOfferFinalized(mo: DeepReadonly<MatchedOffer>) {
  return mo.status === MATCHED_OFFER_CANCELED || mo.status >= MATCHED_OFFER_SENT &&  ![ MATCHED_OFFER_REJECTED, MATCHED_OFFER_PENDING ].includes(mo.status)
}

export function isMatchedOfferExpired(mo: DeepReadonly<MatchedOffer>) {
  return !isMatchedOfferFinalized(mo) && mo.offer.expire < dayjs().unix()
}

export function isMatchedOfferBiddable(mo: DeepReadonly<MatchedOffer>) {
  return (mo.status === MATCHED_OFFER_PENDING || mo.status === MATCHED_OFFER_SENT) && mo.biddable
}
