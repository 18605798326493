<div mat-dialog-content>
    <form [formGroup]="distributionListForm">
        <div fxLayout="row" fxLayoutGap="7.5px">
            <mat-form-field fxFlex="50">
                <mat-label>Name</mat-label>
                <input matInput [formControl]="distributionListForm.controls.name">
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <h2>Filter Criteria</h2>
        </div>
        <div fxLayout="row" fxLayoutGap="7.5px" fxLayoutAlign="space-between center">
            <div fxFlex="33">
                <tc-select-search placeholder="Locations" [ctrl]="distributionListForm.controls.filter_locationIds"
                    [multiple]="true" [items]="locations$ | async" bindValue="location_id" bindLabel="name">
                </tc-select-search>
            </div>
            <div fxFlex="33">
                <tc-select-search placeholder="Suppliers" [ctrl]="distributionListForm.controls.filter_supplierIds"
                    [multiple]="true" [items]="suppliers$ | async" bindValue="account" bindLabel="name">
                </tc-select-search>
            </div>
            <div fxFlex="33">
                <tc-select-search placeholder="Service Providers"
                    [ctrl]="distributionListForm.controls.filter_serviceProviderIds" [multiple]="true"
                    [items]="serviceProviders$ | async" bindValue="account" bindLabel="name">
                </tc-select-search>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="7.5px" fxLayoutAlign="space-between center">
            <div fxFlex="33">
                <tc-select-search placeholder="Product Categories"
                    [ctrl]="distributionListForm.controls.filter_productCategoryIds" [multiple]="true"
                    [items]="filteredProductCategories" bindValue="category_id" bindLabel="name"
                    (change)="onProductCategoriesChanged()" (openedChange)="onProductCategoriesChanged()">
                </tc-select-search>
            </div>
            <div fxFlex="33">
                <tc-select-search placeholder="Product Types"
                    [ctrl]="distributionListForm.controls.filter_productTypeIds" [multiple]="true"
                    [items]="filteredProductTypes" bindValue="type_id" bindLabel="name"
                    (change)="onProductTypesChanged()" (openedChange)="onProductTypesChanged()">
                </tc-select-search>
            </div>
            <div fxFlex="33">
                <tc-select-search placeholder="Products" [ctrl]="distributionListForm.controls.filter_productIds"
                    [multiple]="true" [items]="filteredProducts" bindValue="product_id" bindLabel="name">
                </tc-select-search>
            </div>
        </div>
        <div fxLayout="row">
            <h2>Emails</h2>
        </div>
        <div fxLayout="row" fxLayoutGap="7.5px" fxLayoutAlign="start center">
            <div fxFlex="33">
                <tc-select-search placeholder="Buyer Contacts" [ctrl]="distributionListForm.controls.buyer_contacts"
                    [multiple]="true" [items]="filteredBuyerContacts" bindValue="user_id" bindLabel="fullname"
                    bindHint="primaryemail">
                </tc-select-search>
            </div>
            <div fxFlex="33">
                <tc-select-search placeholder="Service Provider"
                    [ctrl]="distributionListForm.controls.service_providers" [multiple]="true"
                    [items]="serviceProviders$ | async" bindValue="account" bindLabel="name"
                    (change)="onServiceProviderChanged()" (openedChange)="onServiceProviderChanged()">
                </tc-select-search>
            </div>
            <div fxFlex="33">
                <tc-select-search placeholder="Service Provider Contacts"
                    [ctrl]="distributionListForm.controls.service_provider_contacts" [multiple]="true"
                    [items]="filteredServiceProviderContacts" bindValue="user_id" bindLabel="fullname"
                    bindHint="primaryemail">
                </tc-select-search>
            </div>
        </div>
        <div fxLayout="row">
            <tc-chips-input fxFill [group]="distributionListForm" placeholder="Emails" realPlaceholder="Add email..."
                ctrlName="emails" (change)="populateEmails($event)">
            </tc-chips-input>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFill>
                <mat-label>Notes</mat-label>
                <input matInput [formControl]="distributionListForm.controls.notes">
            </mat-form-field>
        </div>
    </form>
</div>