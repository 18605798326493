import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AccountObject, BUYER, DealViewRawCosts, DealViewRawDeal, DealViewRawSegments, FileObject, SERVICE_PROVIDER, SUPPLIER } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { map as _map, difference, flatten, groupBy, keyBy, uniqBy } from 'lodash-es'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, from, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { FileUploaderService } from 'src/api/file'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { DealDocumentsService, DocumentTypeOption } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { getDealParties } from 'src/services/data/deal-view.service'
import { DealsService } from 'src/services/data/deals.service'
import { compareBy } from 'src/services/table-utils/compare'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'

export interface UploaderDialogOptions {
  title?: string
  limit?: number
  dv?: DeepReadonly<DealViewRawDeal & DealViewRawCosts & DealViewRawSegments>
}

@Component({
  selector: 'tc-uploader-dialog',
  templateUrl: './uploader-dialog.component.html',
  styleUrl: './uploader-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploaderDialogComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly DealDocuments: DealDocumentsService,
    private readonly Deals: DealsService,
    private readonly FileUploader: FileUploaderService,
    private readonly store: Store,
    private readonly toaster: ToasterService,
    private readonly dialogRef: MatDialogRef<UploaderDialogComponent, FileObject[]>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: UploaderDialogOptions,
  ) { super() }

  protected readonly title = this.dialogData.title || 'Upload files'
  protected readonly limit = this.dialogData.limit || 10
  protected readonly dv = this.dialogData.dv

  // form state
  protected readonly filesQueue$ = new BehaviorSubject<ReturnType<typeof this.newFileForm>[]>([])
  protected readonly inProgress$ = new BehaviorSubject<'loading' | 'save' | undefined>('loading')

  // ref data
  protected readonly accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  protected readonly carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())

  // donwloaded data
  protected readonly dealIds$ = new BehaviorSubject(this.dv ? [this.dv.deal.deal_id] : [])
  protected readonly parties$: Observable<DeepReadonly<Pick<AccountObject, 'name'|'account'|'type'>[]>> =
    combineLatest([this.accounts$, this.carriers$])
      .pipe(map(([accounts, carriers]) =>
        this.dv
          ? getDealParties(this.dv, accounts, carriers)
          : _map(accounts).filter(a => !a.archived).sort(compareBy('name'))))
  // cache
  private deals: DeepReadonly<Dictionary<DealViewRawDeal & DealViewRawCosts & DealViewRawSegments>> = this.dv
    ? { [this.dv.deal.deal_id]: this.dv }
    : {}
  private docTypesByDealId: Dictionary<DocumentTypeOption[]> = {}

  ngOnInit() {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCarriers({}))

    // NOTE: input deal view must have costs and segments loaded. or better just parties
    combineLatest([
      this.accounts$,
      this.carriers$,
      from(this.fetchDealIds())])
    .pipe(take(1)).subscribe(() => this.inProgress$.next(undefined))
  }

  private async fetchDealIds() {
    if (!this.dv) this.dealIds$.next(await this.Deals.getDealIds())
  }

  protected onFilesChange(files: File[]) {
    this.filesQueue$.next([
      ...this.filesQueue$.value,
      ...files.map(file => this.newFileForm(file)),
    ])
  }

  protected removeFile(index: number) {
    this.filesQueue$.next(this.filesQueue$.value.filter((_x, i) => i !== index))
  }

  /**
   * Save button clicked - upload all docs and close modal dialog
   */
  async save() {
    try {
      this.inProgress$.next('save')
      const files = await this.uploadFiles()
      this.dialogRef.close(files)
      this.toaster.success('Files uploaded successfully.')
    } catch (err) {
      console.error('Unable to upload files.', err)
      this.toaster.error('Unable to upload files.', err)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  /**
   * Upload multiple files
   */
  private async uploadFiles() {
    const fileItems = this.filesQueue$.value
    const pairs: { file: File, fileObj: Partial<FileObject>, fileItem: typeof fileItems[number] }[] = fileItems.flatMap(fileItem => {
      const { dealIds, dealParty: deal_party, documentType: document_type } = fileItem.form.getRawValue()
      if (dealIds.length) {
        return dealIds.map(deal_id => ({
          file: fileItem.file,
          fileItem,
          fileObj: { deal_id, attributes: { deal_party, uploaded: true, ...(document_type ? { document_type } : {}) }},
        }))
      }
      return { file: fileItem.file, fileItem, fileObj: undefined }
    })
    const newFiles: FileObject[] = []
    await combineLatest(pairs.map(({ file, fileObj, fileItem }) =>
      this.FileUploader.uploadFile(file, fileObj, true).pipe(
        tap(e => {
          fileItem.progress$.next(e.progress)
          if (e.event === 'done' && e.file) newFiles.push(e.file)
        }),
        catchError(err => {
          console.error(`Unable to upload file ${file.name}`, err)
          this.toaster.error(`Unable to upload file ${file.name}`, err)
          return of(undefined)
        }),
    ))).toPromise()
    const newFilesByIds = groupBy(newFiles, 'deal_id')
    await Promise.all(_map(newFilesByIds, (newDealFiles, dealId) =>
      this.DealDocuments.keepDealFilesUnique(dealId, newDealFiles)))
    return newFiles
  }


  /**
   * Generate file item + FormGroup for an input file
   */
  private newFileForm(file: File) {
    const initialDealIds = this.dv ? [this.dv.deal.deal_id] : this.parseDealIds(file.name)
    const form = new FormGroup({
      dealIds: new FormControl<string[]>(initialDealIds),
      dealParty: new FormControl<number>(undefined),
      documentType: new FormControl<string>(undefined),
    })
    return {
      file,
      status: 'queue' as 'queue' | 'done' | 'fail' | 'cancel',
      progress$: new ReplaySubject<number>(1),
      form,
      documenTypes$: replayForm(form).pipe(
        switchMap(f => {
          const missing = difference(f.dealIds, Object.keys(this.deals))
          if (missing.length) {
            return this.Deals.getDealViews(missing, ['deal', 'costs', 'segments']).pipe(
              tap(deals => {
                this.deals = { ...this.deals, ...keyBy(deals, 'deal.deal_id') }
              }),
              catchError(err => {
                console.error(`Unable to get deal views ${f.dealIds}`, err)
                this.toaster.error(`Unable to get deal views ${f.dealIds}`, err)
                return of(undefined)
              }),
              map(() => f)
            )
          }
          return of(f)
        }),
        switchMap(f => {
          const missing = difference(f.dealIds, Object.keys(this.docTypesByDealId))
          if (missing.length) {
            return combineLatest(missing.map(dealId =>
              this.DealDocuments.getDocTypesForDeal(this.deals[dealId].deal).pipe(
                tap(documentTypes => {
                  this.docTypesByDealId = { ...this.docTypesByDealId, [dealId]: documentTypes }
                }),
                catchError(err => {
                  console.error(`Unable to get documents set for ${this.deals[dealId].deal.deal_id} / ${this.deals[dealId].deal.attributes.docs_country}`, err)
                  this.toaster.error(`Unable to get documents set for ${this.deals[dealId].deal.deal_id} / ${this.deals[dealId].deal.attributes.docs_country}`, err)
                  return of(undefined)
                })
              ))
            ).pipe(map(() => f))
          }
          return of(f)
        }),
        map(f => this.getDocumentTypesFor(f.dealIds, f.dealParty))
      )
    }
  }

  /**
   * Parse file name, return deal ids
   */
  private parseDealIds(fileName: string) {
    const dealIds = []
    for (let m, rx = /((BWI)?\d+TCI?)/gi; m = rx.exec(fileName); ) dealIds.push(m[1])
    // If the deal selected has a TCI version, put the document in the TCI version
    return dealIds.map(dealId =>
      this.dealIds$.value.includes(`${dealId}I`) ? `${dealId}I` : dealId)
  }

  /**
   * Get document types available for the given account
   */
  private getDocumentTypesFor(dealIds: string[], accountId: number | string) {
    return uniqBy(flatten(dealIds.map(dealId => {
      const dv = this.deals[dealId]
      if (!dv) return []
      const documentTypes = this.docTypesByDealId[dv.deal.deal_id]
      if (!documentTypes) return []
      // tslint:disable-next-line: triple-equals
      if (accountId == dv.deal.buyer_id) {
        return this.DealDocuments.filterFor(documentTypes, [BUYER, 'other'])
      }
      // tslint:disable-next-line: triple-equals
      if (accountId == dv.deal.supplier_id) {
        return this.DealDocuments.filterFor(documentTypes, [SUPPLIER, 'other'])
      }
      if (dv.costs?.some(cost => cost.provider && cost.provider == accountId)) {
        return this.DealDocuments.filterFor(documentTypes, [SERVICE_PROVIDER, 'other'])
      }
      return documentTypes
    })), 'id')
  }
}
