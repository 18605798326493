import { EventEmitter } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export class TableSelection<T, K = string> {
  constructor(private rowId: string/* TODO: keyof T */) {}

  selectedIds: Map<K, boolean> = new Map()
  readonly selectedIds$ = new BehaviorSubject<K[]>([])

  canSelect = (row: T) => !!row
  isSelected = (row: T) => this.selectedIds.has(row[this.rowId])

  readonly changed = new EventEmitter<K[]>()

  toggleRow(row: T) {
    this.selectedIds.has(row[this.rowId])
      ? this.selectedIds.delete(row[this.rowId])
      : this.selectedIds.set(row[this.rowId], true)
    const rowIds = [...this.selectedIds.keys()]
    this.selectedIds$.next(rowIds)
    this.changed.next(rowIds)
  }

  toggleRows(selected: boolean, rows?: T[]) {
    rows = rows?.filter(j => !!j)
    this.selectedIds.clear()
    if (selected) {
      for (const row of rows) {
        // if (this.canSelect(row)) {
        this.selectedIds.set(row[this.rowId], true)
        // }
      }
    }
    const rowIds = [...this.selectedIds.keys()]
    this.selectedIds$.next(rowIds)
    this.changed.next(rowIds)
  }
}
