import { createSelector } from '@ngrx/store'
import { Carrier } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, carriersFeatureKey } from './carrier.reducer'

export const selectState = (state: State) => state[carriersFeatureKey]

export const { selectEntities: selectCarrierEntities, selectAll: selectAllCarriers } = adapter.getSelectors(selectState)

export const selectCarrier = createSelector(
  selectCarrierEntities,
  (carriers: Dictionary<Carrier>, carrier: string | number) =>
    carriers[carrier])

export const selectCarriersByIds = createSelector(
  selectCarrierEntities,
  (carriers: Dictionary<Carrier>, carrierIds: string[]) =>
    carrierIds.map(carrierId => carriers[carrierId]))

export const selectWarehouseCarriers = createSelector(
  selectAllCarriers,
  carriers => carriers.filter(carrier => carrier.type.includes('warehouse')))
