import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { TradingSummaryComponent } from './trading-summary.component'

@NgModule({
  declarations: [TradingSummaryComponent],
  exports: [TradingSummaryComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    FlexLayoutModule,
    PipesModule,
    EpochFieldModule,
  ],
})
export class TradingSummaryModule {}
