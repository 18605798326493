import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module';
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module';
import { PipesModule } from 'src/shared/pipes/pipes.module';
import { BrokerageDatesFormComponent } from './brokerage-dates-form.component';

@NgModule({
  declarations: [BrokerageDatesFormComponent],
  exports: [BrokerageDatesFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    EpochFieldModule,
    FlexLayoutModule,
    ReactiveAsteriskModule,
    PipesModule,
  ],
})
export class BrokerageDatesFormModule { }
