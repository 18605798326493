import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { AccountObject, GeneralAddress } from '@tradecafe/types/core'
import { PickerSelection } from 'src/services/table-utils/selection/picker-selection'



interface GeneralAddressItem extends GeneralAddress {
  carrierName?: string
  companies?: AccountObject[]
  companiesTableField?: string
}

@Component({
  selector: 'tc-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AddressListComponent<T extends GeneralAddressItem> implements OnInit {

  @Input() dataSource: MatTableDataSource<T>
  @Input() selected?: T[]
  @Input() multiple?: boolean
  @Input() showEstablishment?: boolean
  @Output() rowClick = new EventEmitter<T>()
  @Input() displayColumns: string[]
  selection: PickerSelection<T>

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.sort = sort }

  ngOnInit(): void {
    this.selection = new PickerSelection<T>(this.selected || [], this.multiple)
    if (!this.displayColumns) {
      this.displayColumns = this.showEstablishment ? ['select', 'name', 'establishment', 'address'] : ['select', 'name', 'address']
    }
  }

}
