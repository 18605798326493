<cdk-virtual-scroll-viewport tvsItemSize="56" headerHeight="56" [itemSize]="56" style="--item-size: 56px" bufferMultiplier="3" (scrolledIndexChange)="onScroll($event)">
  <table mat-table [trackBy]="getRowId"
      fixedLayout [dataSource]="dataSource"
      matSort matSortStart="desc" matSortDisableClear
      class="with-footer">

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <!-- NOTE: column filters are disabled. There're issues which will be addressed in 1.19+
      <tr mat-header-row *matHeaderRowDef="filterColumns" class="tc-filters-row"></tr>
    -->
    <tr mat-row *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row" [class.clickable]="!!row && isClickable"></tr>

    <ng-container *ngIf="footer">
      <tr mat-footer-row *matFooterRowDef="totalColumns; sticky: true" [class.tc-show-footer]="!!(totalsRows$ | async)"></tr>

      <ng-container *ngFor="let colName of displayColumns" [matColumnDef]="colName + '_footer'" [ngSwitch]="colName">
        <ng-container *ngSwitchCase="'currency'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).currency">{{ cell }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'actl_amount_cad'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).actl_amount_cad">{{ cell | number:'1.2-2'}}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'partial_margin'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).partial_margin">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'actl_amount'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).actl_amount">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'est_amount'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).est_amount">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'net_payable'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).net_payable">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'rmng_amount'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).rmng_amount">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'sum_pmts_applied'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).sum_pmts_applied">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'variance'">
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef>
            <div class="footer-row" *ngFor="let cell of (totalsRows$ | async).variance">{{ cell | number:'1.2-2' }}</div>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <td mat-footer-cell [ngClass]="'mat-column-' + colName" *matFooterCellDef></td>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let x of availableColumnFilters" [matColumnDef]="x.colName + '-filter'">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell-filter">
        <ng-container *ngIf="filtersData && filtersData[x.filterName] as filterData">
          <tc-select-search *ngIf="filterData.type === 'select'" [multiple]="true"
              [realPlaceholder]="columnNames[x.colName]"
              [group]="filtersForm" [ctrlName]="x.filterName"
              (openedChange)="!$event && applyFilters.next()"
              [items]="filterData.options" bindValue="id" bindLabel="name"
              ></tc-select-search>
          <tc-epoch-range-field *ngIf="filterData.type === 'epoch-range'" placeholder=""
              [formGroup]="filtersForm" [startCtrlName]="x.colName + '_from'" [endCtrlName]="x.colName + '_to'"
              (closed)="applyFilters.next()" [useUtc]="!!INVOICES_FILTER_SETTINGS[x.colName].useUtc"
              ></tc-epoch-range-field>
        </ng-container>
      </th>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox *ngIf="selectAll"
            matTooltip="Select All"
            (change)="selection.toggleRows($event.checked, dataSource.data)"
            ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <mat-checkbox *ngIf="selection.canSelect(invoice)"
              [checked]="selection.isSelected(invoice)"
              (click)="$event.stopPropagation()"
              (change)="selection.toggleRow(invoice)"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyerInvoicesMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <button mat-icon-button
              [matMenuTriggerFor]="buyerInvoicesMenu"
              [matMenuTriggerData]="{invoice: invoice}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vendorInvoicesMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <button mat-icon-button
              [matMenuTriggerFor]="vendorInvoicesMenu"
              [matMenuTriggerData]="{invoice: invoice}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dealInvoiceMenu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="!readonly && !row">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="!readonly && row">
          <button mat-icon-button type="button"
              [matMenuTriggerFor]="dealInvoiceMenu"
              [matMenuTriggerData]="{row: row}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="invoice_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.invoice_id">{{columnNames.invoice_id}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.invoice_id" (click)="!invoice.viewEx?.rowType?.includes('Credit') && canViewInvoice(invoice) && showInvoiceItem(invoice)">{{invoice.invoice_id}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deal_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deal_id">{{columnNames.deal_id}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice && invoice.view.status">
          <a class="text-ellipsis" [title]="invoice.deal_id"
              [routerLink]="getDealUrl | call:invoice.deal_id"
              (click)="$event.stopPropagation();$event.preventDefault();openDealForm(invoice)">
            {{invoice.deal_id}}
          </a>
          <!-- <tc-deal-status [status]="invoice.view.status" [types]="invoice.view.deal_type"></tc-deal-status> -->
        </ng-container>
        <ng-container *ngIf="invoice && !invoice.view.status">
          <span matTooltip="Deal not found in TradeCafé" [title]="invoice.deal_id">{{invoice.deal_id}}⚠️</span>
          <tc-deal-status status="NOT FOUND"></tc-deal-status>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="due_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.due_date">{{columnNames.due_date}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: invoice.due, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="act_liability_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.act_liability_date">{{columnNames.act_liability_date}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: invoice.viewEx.act_liability_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="ant_liability_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.ant_liability_date">{{columnNames.ant_liability_date}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: invoice.attributes.ant_liability_date }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="issued">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.issued">{{columnNames.issued}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: invoice.issued, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.status">{{columnNames.status}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [ngSwitch]="invoice.viewEx.rowType === 'Credit Note' || invoice.viewEx.rowType === 'Vendor Credit' || invoice.viewEx.rowType === 'Prepayment Credit'">
            <span *ngSwitchCase="true" class="text-uppercase label label-{{CreditNoteStatus[invoice.status]?.color || 'danger'}}">{{CreditNoteStatus[invoice.status]?.label || 'Unknown=' + invoice.status}}</span>
            <span *ngSwitchDefault class="text-uppercase label label-{{InvoiceStatus[invoice.viewEx.status.status]?.color || 'danger'}}">{{invoice.viewEx.status.name || 'Unknown=' + invoice.status}}</span>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="rowType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.rowType">{{columnNames.rowType}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.viewEx.rowType">{{invoice.viewEx.rowType}}</div>
          <small *ngIf="invoice.type" class="text-ellipsis text-gray" [title]="invoice.type">{{invoice.type}}</small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.created">{{columnNames.created}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: invoice.created, useUtc: false }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deal_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deal_status">{{columnNames.deal_status}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view.status">
          <div class="text-ellipsis">
            <span class="label text-uppercase {{DealStatus[invoice.view.status]?.labelClass || 'label-danger'}}" [title]="DealStatus[invoice.view.status]?.name || invoice.view.status">
              {{DealStatus[invoice.view.status]?.name || invoice.view.status}}
            </span>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="selling_trader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.selling_trader">{{columnNames.selling_trader}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.selling_trader">
          <div class="text-ellipsis" [title]="invoice.view.selling_trader.fullname">{{invoice.view.selling_trader.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buying_trader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buying_trader">{{columnNames.buying_trader}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.buying_trader">
          <div class="text-ellipsis" [title]="invoice.view.buying_trader.fullname">{{invoice.view.buying_trader.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="brokerage_trader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.brokerage_trader">{{columnNames.brokerage_trader}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.brokerage_trader">
          <div class="text-ellipsis" [title]="invoice.view.brokerage_trader.fullname">{{invoice.view.brokerage_trader.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="coordinator">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.coordinator">{{columnNames.coordinator}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view.coordinator">
          <div class="text-ellipsis" [title]="invoice.view.coordinator.fullname">{{invoice.view.coordinator.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.company">{{columnNames.company}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.company">
          <div class="text-ellipsis" [title]="invoice.view.company.name">{{invoice.view.company.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="company_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.company_type">{{columnNames.company_type}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.company">
          <div class="text-ellipsis" [title]="invoice.viewEx.companyType">{{invoice.viewEx.companyType}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="note_warning">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.note_warning">{{columnNames.note_warning}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="isBuyerInvoicesPage && invoice?.view?.note_warning">
          <button mat-icon-button title="Notes" (click)="showInvoiceNotes(invoice)"
              [matTooltip]="invoice.view.notes?.body ? (invoice.view.notes.created | epoch) + ': ' + invoice.view.notes.body : ''">
            <mat-icon class="fa fa-note-sticky"></mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!isBuyerInvoicesPage && invoice">
          <button mat-icon-button title="Notes" (click)="showInvoiceNotes(invoice)"
              [matTooltip]="invoice.view.notes?.body ? (invoice.view.notes.created | epoch) + ': ' + invoice.view.notes.body : ''">
            <mat-icon class="fa"
                [class.fa-note-sticky]="!invoice.view.note_warning"
                [class.fa-note]="invoice.view.note_warning"
                [class.text-info]="invoice.view.note_warning"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer">{{columnNames.buyer}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.buyer">
          <div class="text-ellipsis" [title]="invoice.view.buyer.name">{{invoice.view.buyer.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_acc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_acc">{{columnNames.buyer_acc}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.company">
          <div class="text-ellipsis" [title]="invoice.view.company.account">{{invoice.view.company.account}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier">{{columnNames.supplier}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.supplier">
          <div class="text-ellipsis" [title]="invoice.view.supplier.name">{{invoice.view.supplier.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="variance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.variance">{{columnNames.variance}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
          <div *ngIf="displayCurrency" class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.variance, currency: invoice.currency }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="est_amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.est_amount">{{columnNames.est_amount}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.est_amount, currency: invoice.currency }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="partial_margin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.partial_margin">{{columnNames.partial_margin}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.partial_margin">
          <div class="text-ellipsis" [title]="invoice.view.partial_margin | number:'1.2' | zeroIfBwi:invoice.viewEx.inventoryDeal"
              [class.text-red]="(invoice.view.partial_margin|zeroIfBwi:invoice.viewEx.inventoryDeal) < 0">
            {{invoice.view.partial_margin | number:'1.2' | zeroIfBwi:invoice.viewEx.inventoryDeal }}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="partial_margin_p">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.partial_margin_p">{{columnNames.partial_margin_p}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.partial_margin_p">
          <div class="text-ellipsis" [title]="invoice.view.partial_margin_p*100 | number:'1.2-2' | zeroIfBwi:invoice.viewEx.inventoryDeal + '%'"
              [class.text-red]="(invoice.view.partial_margin_p|zeroIfBwi:invoice.viewEx.inventoryDeal) < 0">
            {{invoice.view.partial_margin_p*100 | number:'1.2-2' | zeroIfBwi:invoice.viewEx.inventoryDeal }}%
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="rmng_amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.rmng_amount">{{columnNames.rmng_amount}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.rmng_amount, currency: invoice.currency }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="net_payable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.net_payable">{{columnNames.net_payable}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.net_payable, currency: invoice.currency }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="cost_names">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.cost_names">{{columnNames.cost_names}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view">
          {{invoice.view.cost_names}}
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vendor_invoice_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.vendor_invoice_id">{{columnNames.vendor_invoice_id}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.vendor_invoice_id">{{invoice.vendor_invoice_id}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.user">{{columnNames.user}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.user">
          <div class="text-ellipsis"
              [title]="invoice.view.user.fullname"
              [style.fontWeight]="invoice.view.user.account === tradecafeAccount ? 'bold' : 'normal'"
              >{{invoice.view.user.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actl_amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actl_amount">{{columnNames.actl_amount}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.actl_amount, currency: invoice.currency }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actl_amount_cad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actl_amount_cad">{{columnNames.actl_amount_cad}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.actl_amount_cad, currency: 'CAD' }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="product_category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.product_category">{{columnNames.product_category}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.product?.category">
          <div class="text-ellipsis" [title]="invoice.view.product?.category.name">{{invoice.view.product?.category.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="product_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.product_type">{{columnNames.product_type}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.view?.product?.product_type">
          <div class="text-ellipsis" [title]="invoice.view.product?.product_type.name">{{invoice.view.product?.product_type.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actl_pmt_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actl_pmt_date">{{columnNames.actl_pmt_date}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource" #td
          [class.tc-cell-editable]="canEditPaid(invoice)"
          (click)="editInvoicePaid(invoice, td)">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.attributes">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: invoice.attributes.paid, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="sched_pmt_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.sched_pmt_date">{{columnNames.sched_pmt_date}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.attributes">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: invoice.attributes.scheduled_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="sum_pmts_applied">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.sum_pmts_applied">{{columnNames.sum_pmts_applied}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <ng-container *ngTemplateOutlet="cellAmount; context: { amount: invoice.view.sum_pmts_applied, currency: invoice.currency }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="payment_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.payment_type">{{columnNames.payment_type}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource" #td
          [class.tc-cell-editable]="canEditPaymentType(invoice)"
          (click)="editInvoicePaymentType(invoice, td)">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.viewEx.paymentType">
          <div class="text-ellipsis" [title]="invoice.viewEx.paymentType">{{invoice.viewEx.paymentType}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="batch_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.batch_id">{{columnNames.batch_id}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.batch_id">{{invoice.batch_id}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.product">{{columnNames.product}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.view.product?.name">{{invoice.view.product?.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.currency">{{columnNames.currency}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.currency">{{invoice.currency}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actualized">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actualized">{{columnNames.actualized}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.attributes">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: invoice.attributes.actualized, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actualized_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actualized_by">{{columnNames.actualized_by}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.actualized_by_name">
          {{invoice.actualized_by_name}}
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="escalated_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.escalated_date">{{columnNames.escalated_date}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource"
          (click)="invoice && rowClick.emit({invoice: invoice})">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice?.attributes">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: invoice.attributes.escalated, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="inv">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.inv">{{columnNames.inv}}</th>
      <td mat-cell *matCellDef="let invoice; dataSource:dataSource" #td
          [class.tc-cell-editable]="canEditInv(invoice)"
          (click)="editInvoiceInv(invoice, td)">
        <ng-container *ngIf="!invoice">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="invoice">
          <div class="text-ellipsis" [title]="invoice.inv">{{invoice.inv}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>
<mat-paginator #paginator></mat-paginator>

<mat-menu #vendorInvoicesMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-invoice="invoice">
    <button mat-menu-item (click)="showInvoiceItem(invoice)">
      <i class="fa fa-fw fa-file-lines"></i> View
    </button>
    <button mat-menu-item (click)="showInvoiceNotes(invoice)">
      <i class="fa fa-fw fa-note-sticky"></i> Note
    </button>
    <ng-container *ngIf="tableIdentity === TableKey.TradingVendorInvoices">
      <mat-divider *ngIf="canChangeToReview | call:invoice"></mat-divider>
      <button mat-menu-item (click)="showAddInfoToItem(invoice)" *ngIf="canChangeToReview | call:invoice">
        <i class="fa fa-fw fa-edit"></i> Add Info
      </button>
    </ng-container>
    <ng-container *ngIf="tableIdentity === TableKey.LogisticsVendorInvoices">
      <mat-divider *ngIf="
          (canActualize | call:invoice) ||
          (canReject | call:invoice) ||
          (canEscalate | call:invoice) ||
          (canVoid | call:invoice)
      "></mat-divider>
      <button mat-menu-item (click)="showActualizeInvoice(invoice)" *ngIf="canActualize | call:invoice">
        <i class="fa fa-fw fa-check"></i> Actualize
      </button>
      <button mat-menu-item (click)="showRejectInvoice(invoice)" *ngIf="canReject | call:invoice">
        <i class="fa fa-fw fa-circle-xmark"></i> Reject
      </button>
      <button mat-menu-item (click)="showEscalateInvoice(invoice)" *ngIf="canEscalate | call:invoice">
        <i class="fa fa-fw fa-circle-question"></i> Escalate
      </button>
      <button mat-menu-item (click)="showUpdateVoid(invoice)" *ngIf="canVoid | call:invoice">
        <i class="fa fa-fw fa-trash-can"></i> Void
      </button>
    </ng-container>
    <ng-container *ngIf="tableIdentity === TableKey.FinancialPayables">
      <mat-divider *ngIf="
          (canPaid | call:invoice) ||
          (canSchedule | call:invoice) ||
          (canUnschedule | call:invoice) ||
          (canEscalate | call:invoice)
      "></mat-divider>
      <button mat-menu-item (click)="showPaid(invoice)" *ngIf="canPaid | call:invoice">
        <i class="fa fa-fw fa-money-bill-1"></i> Paid
      </button>
      <button mat-menu-item (click)="showScheduleItem(invoice)" *ngIf="canSchedule | call:invoice">
        <i class="fa fa-fw fa-calendar"></i> {{invoice.status === 4 ? 'Re-schedule' : 'Schedule'}}
      </button>
      <button mat-menu-item (click)="showUnscheduleItem(invoice)" *ngIf="canUnschedule | call:invoice">
        <i class="fa fa-fw fa-calendar"></i> Unschedule
      </button>
      <button mat-menu-item (click)="showEscalateInvoice(invoice)" *ngIf="canEscalate | call:invoice">
        <i class="fa fa-fw fa-circle-question"></i> Escalate
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="showDealDetails(invoice)">
        <i class="fa fa-fw fa-chevron-circle-right"></i> Deal Details
      </button>
      <button mat-menu-item (click)="showShipmentDetails(invoice)">
        <i class="fa fa-fw fa-truck"></i> Shipment Details
      </button>
      <button mat-menu-item (click)="showFinanceDetails(invoice)">
        <i class="fa fa-fw fa-money-bill-1"></i> Finance Details
      </button>
      <button mat-menu-item (click)="showCompanyCredit(invoice)">
        <i class="fa fa-fw fa-money-bill-1"></i> Check Credit
      </button>
    </ng-container>
    <ng-container *ngIf="tableIdentity === TableKey.ManagementVendorInvoices">
      <mat-divider *ngIf="
          (canApprove | call:invoice) ||
          (canUnapprove | call:invoice) ||
          (canEscalate | call:invoice)
      "></mat-divider>
      <button mat-menu-item (click)="showApproveInvoice(invoice)" *ngIf="canApprove | call:invoice">
        <i class="fa fa-fw fa-check"></i> Approve
      </button>
      <button mat-menu-item (click)="showUnapproveInvoice(invoice)" *ngIf="canUnapprove | call:invoice">
        <i class="fa fa-fw fa-undo"></i> Unapprove
      </button>
      <button mat-menu-item (click)="showEscalateInvoice(invoice)" *ngIf="canEscalate | call:invoice">
        <i class="fa fa-fw fa-circle-question"></i> Escalate
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="showDealDetails(invoice)">
        <i class="fa fa-fw fa-chevron-circle-right"></i> Deal Details
      </button>
      <button mat-menu-item (click)="showShipmentDetails(invoice)">
        <i class="fa fa-fw fa-truck"></i> Shipment Details
      </button>
      <button mat-menu-item (click)="showFinanceDetails(invoice)">
        <i class="fa fa-fw fa-money-bill-1"></i> Finance Details
      </button>
      <button mat-menu-item (click)="showCompanyCredit(invoice)">
        <i class="fa fa-fw fa-money-bill-1"></i> Check Credit
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #buyerInvoicesMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-invoice="invoice">
    <button mat-menu-item (click)="showInvoiceItem(invoice)" *ngIf="isInvoiceManager(invoice)">
      <i class="fa fa-fw fa-file-lines"></i> View
    </button>
    <button mat-menu-item (click)="showInvoiceNotes(invoice)">
      <i class="fa fa-fw fa-note-sticky"></i> Note
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item *ngIf="canCreateBankCharge | call:invoice"
        (click)="showCreateBankCharge(invoice)">
      <i class="fa fa-fw fa-money-bill-1"></i> Bank Charge
    </button>
    <button mat-menu-item *ngIf="invoice.attributes?.bankCharge?.cost_id && isInvoiceManager(invoice)"
        (click)="showUndoBankCharge(invoice)">
      <i class="fa fa-fw fa-money-bill-1"></i> Undo Bank Charge
    </button>
    <button mat-menu-item (click)="showDealDetails(invoice)" *ngIf="isInvoiceManager(invoice)">
      <i class="fa fa-fw fa-chevron-circle-right"></i> Deal Details</button>
    <button mat-menu-item (click)="showShipmentDetails(invoice)" *ngIf="isInvoiceManager(invoice)">
      <i class="fa fa-fw fa-truck"></i> Shipment Details</button>
    <button mat-menu-item (click)="showFinanceDetails(invoice)" *ngIf="isInvoiceManager(invoice)">
      <i class="fa fa-fw fa-money-bill-1"></i> Finance Details</button>
    <button mat-menu-item (click)="showCompanyCredit(invoice)">
      <i class="fa fa-fw fa-money-bill-1"></i> Check Credit</button>
    <button mat-menu-item (click)="showReceipts(invoice)">
      <i class="fa fa-fw fa-money-bill-1"></i> Applied Receipts</button>
    <mat-divider *ngIf="isInvoiceManager(invoice)"></mat-divider>
    <button mat-menu-item [disabled]="!canCloseDeal(invoice)" *ngIf="isInvoiceManager(invoice)"
        (click)="closeDeals([invoice])">
      <i class="fa fa-fw fa-times"></i> Close Deal</button>
  </ng-template>
</mat-menu>

<mat-menu #dealInvoiceMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-row="row">
    <ng-container *ngIf="row.viewEx.rowType === 'Credit Note'">
      <button mat-menu-item [disabled]="row.status === 0 || row.status === 3" (click)="showVoidCreditNote(row)">
        <i class="fa fa-fw fa-trash-can"></i> Void
      </button>
      <button mat-menu-item (click)="showCreditNoteNotes(row.viewEx.creditNote)">
        <i class="fa fa-fw fa-note-sticky"></i> Notes
      </button>
    </ng-container>
    <ng-container *ngIf="row.viewEx.rowType === 'Vendor Credit'">
      <button *ngIf="row.status === 1" mat-menu-item (click)="showVendorCreditItem(row)">
        <i class="fa fa-fw fa-edit"></i> Edit
      </button>
      <button *ngIf="row.status !== 0" mat-menu-item (click)="showVoidVendorCredit(row)">
        <i class="fa fa-fw fa-trash-can"></i> Void
      </button>
      <button mat-menu-item (click)="showVendorCreditNotes(row.viewEx.vendorCredit)">
        <i class="fa fa-fw fa-note-sticky"></i> Notes
      </button>
    </ng-container>
    <ng-container *ngIf="row.viewEx.rowType === 'Invoice' || row.viewEx.rowType === 'Prepayment'">
      <button mat-menu-item (click)="showInvoiceItem(row)">
        <i class="fa fa-fw fa-edit"></i> View
      </button>
      <button *ngIf="!row.viewEx.fileUrl" mat-menu-item (click)="uploadAttachment.next(row)">
        <i class="fa fa-fw fa-edit"></i> Upload Attachment
      </button>
      <a *ngIf="row.viewEx.fileUrl" mat-menu-item target="_blank" [href]="row.viewEx.fileUrl">
        <i class="fa fa-fw fa-edit"></i> View Attachment
      </a>
      <button *ngIf="row.viewEx.fileUrl" mat-menu-item (click)="uploadAttachment.next(row)">
        <i class="fa fa-fw fa-edit"></i> Replace/Add Attachment
      </button>
      <button mat-menu-item *ngIf="row.type === 'receivable'" (click)="showReceipts(row)">
        <i class="fa fa-fw fa-money-bill-1"></i> Applied Receipts
      </button>
      <button mat-menu-item (click)="showInvoiceNotes(row)">
        <i class="fa fa-fw fa-pencil"></i> Notes
      </button>
      <mat-divider *ngIf="
          (canActualize | call:row) ||
          (canReject | call:row) ||
          (canEscalate | call:row) ||
          (canVoid | call:row)
      "></mat-divider>
      <button mat-menu-item (click)="showActualizeInvoice(row)" *ngIf="canActualize | call:row">
        <i class="fa fa-fw fa-check"></i> Actualize
      </button>
      <button mat-menu-item (click)="showRejectInvoice(row)" *ngIf="canReject | call:row">
        <i class="fa fa-fw fa-circle-xmark"></i> Reject
      </button>
      <button mat-menu-item (click)="showEscalateInvoice(row)" *ngIf="canEscalate | call:row">
        <i class="fa fa-fw fa-circle-question"></i> Escalate
      </button>
      <button mat-menu-item (click)="showUpdateVoid(row)" *ngIf="canVoid | call:row">
        <i class="fa fa-fw fa-trash-can"></i> Void
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<ng-template #cellAmount let-amount="amount" let-currency="currency">
  <ng-container *ngIf="amount || amount === 0">
    <div *ngIf="formatCurrency" class="text-ellipsis" [title]="amount | currency:currency">{{amount | currency:currency}}</div>
    <div *ngIf="!formatCurrency" class="text-ellipsis" [title]="amount | number:'1.2'">{{amount | number:'1.2'}}</div>
    <small *ngIf="displayCurrency" class="text-ellipsis text-gray">{{currency}}</small>
  </ng-container>
</ng-template>

<ng-template #cellEpochDate let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
</ng-template>

<ng-template #cellEpoch let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp">
    <div class="text-ellipsis" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
    <small class="text-ellipsis text-gray" [title]="timestamp | epoch:'LT':useUtc">{{timestamp | epoch:'LT':useUtc}}</small>
  </div>
</ng-template>

<ng-template #cellWeight let-weight="weight">
  <ng-container *ngIf="weight?.amount || weight?.amount === 0">
    <div class="text-ellipsis" [title]="weight.amount | number">{{weight.amount | number}}</div>
    <small class="text-gray">{{weight.amount | measure:weight.measure_id}}</small>
  </ng-container>
</ng-template>

<tc-inline-editor #inlineEditor></tc-inline-editor>
