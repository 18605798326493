<ng-container [formGroup]="productForm">
  <tc-product-field gdColumn="span 12" gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr)" gdGap="0 10px"
      [group]="productForm" ctrlName="productId"
      [protein]="true"
      ></tc-product-field>

  <tc-select-search placeholder="Item specification" gdColumn="span 3"
      [group]="productForm" ctrlName="itemTypeId"
      [items]="itemTypes$ | async" bindLabel="name" bindValue="item_type_id"
      ></tc-select-search>
  <mat-form-field gdColumn="span 3">
    <mat-label>HS Code</mat-label>
    <input matInput readonly [value]="hsCode$ | async">
  </mat-form-field>
  <mat-form-field gdColumn="span 6">
    <mat-label>Product translation</mat-label>
    <input matInput readonly [value]="productTranslation$ | async">
  </mat-form-field>

  <tc-select-search placeholder="Wrapping" gdColumn="span 6"
      [group]="productForm" ctrlName="wrappingId"
      [items]="wrappings$ | async" bindLabel="name" bindValue="wrapping_id"
      ></tc-select-search>
  <mat-form-field gdColumn="span 6">
    <mat-label>Additional description</mat-label>
    <input matInput formControlName="description">
  </mat-form-field>

  <tc-address-field placeholder="Establishment #" [multiple]="true" gdColumn="span 2"
      [group]="productForm" ctrlName="establishments"
      [pickerOptions]="establishmentAddressOptions$ | async">
    <div *tcAddressFieldValue="let address = address">
      <div>{{ address.establishment}}<span *ngIf="address.establishment"> – </span>{{ address.name }}</div>
      <div class="text-gray">{{ address | address:['street1', 'city']}}</div>
    </div>
  </tc-address-field>
  <tc-select-search placeholder="Supplier Incoterm" gdColumn="span 2"
      [group]="productForm" ctrlName="supplierIncotermId"
      [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
      ></tc-select-search>
  <tc-select-search placeholder="Supplier Incoterm Location" gdColumn="span 2"
      [group]="productForm" ctrlName="supplierIncotermLocationId"
      [items]="locations$ | async" bindValue="location_id" bindLabel="name"
      ></tc-select-search>
  <tc-address-field placeholder="Invoice Address" gdColumn="span 2"
      [group]="productForm" ctrlName="invoiceAddress"
      [pickerOptions]="invoiceAddressOptions$ | async"
      ></tc-address-field>
  <tc-select-search placeholder="Buyer Incoterm" gdColumn="span 2"
      [group]="productForm" ctrlName="buyerIncotermId"
      [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
      ></tc-select-search>
  <tc-select-search placeholder="Buyer Incoterm Location" gdColumn="span 2"
      [group]="productForm" ctrlName="buyerIncotermLocationId"
      [items]="locations$ | async" bindValue="location_id" bindLabel="name"
      ></tc-select-search>

  <tc-select-search placeholder="Weight type" gdColumn="span 3"
      [group]="productForm" ctrlName="weightTypeId"
      [items]="weightTypes$ | async" bindLabel="name" bindValue="type_id"
      ></tc-select-search>
  <mat-form-field gdColumn="span 3">
    <mat-label>Brand</mat-label>
    <input matInput formControlName="brand">
  </mat-form-field>
  <mat-form-field gdColumn="span 3">
    <mat-label>Product Code</mat-label>
    <input matInput formControlName="productCode">
  </mat-form-field>
  <mat-form-field gdColumn="span 3">
    <mat-label>Shipping Marks</mat-label>
    <input matInput formControlName="shippingMarks">
  </mat-form-field>

  <mat-form-field gdColumn="span 2">
    <mat-label>No of packages</mat-label>
    <input matInput type="number" min="0" formControlName="packagesCount">
  </mat-form-field>

  <tc-select-search placeholder="Type of packages" gdColumn="span 4"
      [group]="productForm" ctrlName="packageId"
      [items]="packageTypes$ | async" bindLabel="name" bindValue="package_id"
      ></tc-select-search>
  <mat-form-field gdColumn="span 2">
    <mat-label>Weight of one package</mat-label>
    <input matInput type="number" min="0"
        formControlName="packageSize"
        [matAutocomplete]="packageSize">
    <mat-autocomplete autoActiveFirstOption #packageSize="matAutocomplete">
      <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <tc-select-search placeholder="Unit of weight" gdColumn="span 4"
      [group]="productForm" ctrlName="packageMeasureId"
      [items]="measures$ | async" bindLabel="name" bindValue="measure_id"
      ></tc-select-search>

  <hr>
  <h6 gdColumn="span 12">Batches</h6>
  <ng-container *ngFor="let batchForm of productForm.controls.batches.controls; let index = index" [formGroup]="batchForm">
    <mat-form-field gdColumn="span 2">
      <mat-label>Cases</mat-label>
      <input matInput formControlName="cases">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Lot #</mat-label>
      <input matInput formControlName="lotNo">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Pallets</mat-label>
      <input matInput formControlName="pallets">
    </mat-form-field>
    <tc-epoch-range-field gdColumn="span 2" placeholder="Production date"
        xx-useUtc="true"
        [formGroup]="batchForm"
        startCtrlName="productionDateRangeFrom"
        endCtrlName="productionDateRangeTo"
        tbdCtrlName="productionDateRangeTbd"
        ></tc-epoch-range-field>
    <div gdColumn="span 4" fxLayout="row" fxLayoutAlign="space-between start">
      <div fxFlex>
        <tc-epoch-field placeholder="Expiry date" format="DD MMM YYYY"
            [group]="batchForm" ctrlName="expiryDate"
            [min]="batchForm.controls.productionDateRangeTo.value"
            ></tc-epoch-field>
        <mat-radio-group formControlName="expiryIn">
          <mat-radio-button value="12 months from Production date"
              [checked]="!batchForm.value.expiryDate && batchForm.value.expiryIn === '12 months from Production date'">
            12 months from Production date
          </mat-radio-button>
          <mat-radio-button value="24 months from Production date"
              [checked]="!batchForm.value.expiryDate && batchForm.value.expiryIn === '24 months from Production date'">
            24 months from Production date
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <button type="button" mat-icon-button color="warn" (click)="removeBatch(index)">
        <mat-icon class="fa fa-minus-circle"></mat-icon>
      </button>
    </div>
  </ng-container>
  <div gdColumn="span 12" style="text-align: right">
    <button mat-button type="button" (click)="addBatch()">
      + add new batch
    </button>
  </div>
  <hr>

  <mat-form-field gdColumn="span 10">
    <mat-label>Additional Specifications</mat-label>
    <input matInput formControlName="additionalSpecs" autocomplete="off">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Gross Weight</mat-label>
    <input matInput formControlName="buyerGrossWeight" type="number" min="0" autocomplete="off">
  </mat-form-field>

  <mat-form-field gdColumn="span 2">
    <mat-label>Actual Buy Weight</mat-label>
    <input matInput type="number" min="0" autocomplete="off"
        formControlName="supplierActualWeight"
        [matAutocomplete]="supplierActualWeight">
    <mat-autocomplete autoActiveFirstOption #supplierActualWeight="matAutocomplete">
      <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Est. Buy Weight</mat-label>
    <input matInput type="number" min="0.01" step="0.01" autocomplete="off"
        formControlName="supplierEstWeight"
        (change)="onSupplierWeightChange()"
        [matAutocomplete]="supplierEstWeight">
    <mat-autocomplete autoActiveFirstOption #supplierEstWeight="matAutocomplete" (optionSelected)="onSupplierWeightChange()">
      <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Buy Weight Error</mat-label>
    <input matInput readonly [value]="(productForm.getRawValue().supplierActualWeight||0) - (productForm.getRawValue().supplierEstWeight||0) | measure:productForm.getRawValue().supplierMeasureId">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Actual Sell Weight</mat-label>
    <input matInput type="number" min="0" autocomplete="off"
        formControlName="buyerActualWeight"
        [matAutocomplete]="buyerActualWeight">
    <mat-autocomplete autoActiveFirstOption #buyerActualWeight="matAutocomplete">
      <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Est. Sell Weight</mat-label>
    <input matInput type="number" min="0.01" step="0.01" autocomplete="off"
        formControlName="buyerEstWeight"
        (change)="onBuyerWeightChange()"
        [matAutocomplete]="buyerEstWeight">
    <mat-autocomplete autoActiveFirstOption #buyerEstWeight="matAutocomplete" (optionSelected)="onBuyerWeightChange()">
      <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Sell Weight Error</mat-label>
    <input matInput readonly [value]="(productForm.getRawValue().buyerActualWeight||0) - (productForm.getRawValue().buyerEstWeight||0) | measure:productForm.getRawValue().buyerMeasureId">
  </mat-form-field>

  <mat-form-field gdColumn="span 2">
    <mat-label>Actual Buy Price</mat-label>
    <input matInput type="number" step="0.0001" autocomplete="off"
        formControlName="supplierActualPrice">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Est. Buy Price</mat-label>
    <input matInput type="number" step="0.0001" autocomplete="off"
        formControlName="supplierEstPrice"
        (change)="onSupplierPriceChange()">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Buy Price Error</mat-label>
    <input matInput readonly [value]="(productForm.getRawValue().supplierActualPrice||0) - (productForm.getRawValue().supplierEstPrice||0) | currency:detailsForm.getRawValue().supplierCurrencyCode">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Actual Sell Price</mat-label>
    <input matInput type="number" step="0.0001" autocomplete="off"
        formControlName="buyerActualPrice">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Est. Sell Price</mat-label>
    <input matInput type="number" step="0.0001" autocomplete="off"
        formControlName="buyerEstPrice"
        (change)="onBuyerPriceChange()">
  </mat-form-field>
  <mat-form-field gdColumn="span 2">
    <mat-label>Sell Price Error</mat-label>
    <input matInput readonly [value]="(productForm.getRawValue().buyerActualPrice||0) - (productForm.getRawValue().buyerEstPrice||0) | currency:detailsForm.getRawValue().buyerCurrencyCode">
  </mat-form-field>
  <mat-form-field gdColumn="span 12">
    <mat-label>Comments</mat-label>
    <input matInput formControlName="comments" autocomplete="off">
  </mat-form-field>
</ng-container>
