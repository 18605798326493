import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { AddressFormModule } from '../address-form/address-form.module'
import { TypeSafeMatCellDefModule } from '../type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { AddressListComponent } from './address-list.component'



@NgModule({
  declarations: [AddressListComponent],
  exports: [AddressListComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    PipesModule,
    AddressFormModule,
    TypeSafeMatCellDefModule,
  ],
})
export class AddressListModule { }
