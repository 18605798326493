import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { DealViewRaw, Note, NoteVisibilities } from '@tradecafe/types/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { compact, intersection, keyBy, uniq } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { getCategoryOptions2, getRecipientOptions2 } from 'src/services/data/notes.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { SimpleDataSource } from 'src/services/table-utils/data-sources/simple-data-source'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { TypedFormGroup } from 'src/shared/utils/typed-forms'
import { NoteFormService } from '../note-form/note-form.service'
import { NoteRow, NotesListFilters } from '../notes-list/notes-list.component'

export interface NotesOverlayOptions {
  dealViewRaw$: Observable<DealViewRaw>
  title: string
  general: boolean
  filters: NotesListFilters
  newFilters: Partial<Note>
}

@Component({
  selector: 'tc-notes-overlay',
  templateUrl: './notes-overlay.component.html',
  styleUrls: ['./notes-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesOverlayComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public dialogData: NotesOverlayOptions,
    private dialogRef: MatDialogRef<NotesOverlayComponent, void>,
    private NoteForm: NoteFormService,
    private toaster: ToasterService,
  ) { super() }

  readonly NoteVisibilities = NoteVisibilities

  categories$ = this.dialogData.dealViewRaw$.pipe(map(dv => getCategoryOptions2(dv)))
  recipients$ = combineLatest([
    this.dialogData.dealViewRaw$,
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    this.store.pipe(select(selectCarrierEntities), waitNotEmpty()),
  ]).pipe(map(([dv, accounts, carriers]) =>
    getRecipientOptions2(dv, { accounts, carriers })))
  noteUsers$ = combineLatest([
    this.dialogData.dealViewRaw$,
    this.store.pipe(select(selectUserEntities), waitNotEmpty()),
  ]).pipe(map(([dv, users]) =>
    uniq(compact(dv.notes?.map(note => users[note.user_id])))))

  filtersForm: TypedFormGroup<NotesListFilters>
  dataSource: SimpleDataSource<NoteRow, NotesListFilters>
  displayColumns = this.dialogData.general
    ? ['created', 'visibility', 'user', 'body', 'ellipsisMenu']
    : ['created', 'category', 'relatesTo', 'visibility', 'user', 'body', 'ellipsisMenu']

  ngOnInit(): void {
    this.filtersForm = new FormGroup({
      search: new FormControl(this.dialogData.filters?.search || ''),
      visibility: new FormControl(this.dialogData.filters?.visibility),
      user_id: new FormControl(this.dialogData.filters?.user_id || []),
      company: new FormControl(this.dialogData.filters?.company || []),
      category: new FormControl(this.dialogData.filters?.category || []),
      sort: new FormControl({ id: 'created', start: 'desc' }),
    }) as any

    const notes$: Observable<NoteRow[]> = combineLatest([
      this.dialogData.dealViewRaw$,
      this.noteUsers$.pipe(map(list => keyBy(list, 'user_id'))),
      this.recipients$.pipe(map(list => keyBy(list, 'id'))),
      this.categories$.pipe(map(list => keyBy(list, 'id'))),
    ]).pipe(map(([dv, users, recipients, categories]) => dv.notes?.map(note => ({
      note,
      created: note.created,
      category: categories[note.attributes.category]?.name,
      relatesTo: note.attributes.category === 'emergency'
        ? note.attributes.document_type
        : compact(note.attributes.company?.map(id => recipients[id]?.name)).join(', '),
      visibility: NoteVisibilities[note.visibility]?.name || `${note.visibility}`,
      user: users[note.user_id]?.fullname,
      body: note.body,
    })) || []))

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadUsers({}))

    this.dataSource = new SimpleDataSource(notes$, this.filtersForm, (rows, filters) => {
      const hasRows = !!rows.length
      if (typeof filters.visibility === 'number') rows = rows.filter(r => r.note.visibility === filters.visibility)
      if (filters.user_id?.length) rows = rows.filter(r => filters.user_id.includes(r.note.user_id))
      if (filters.category?.length) rows = rows.filter(r => filters.category.includes(r.note.attributes.category))
      if (filters.company?.length) rows = rows.filter(r => intersection(filters.company, r.note.attributes.company).length)
      if (filters.search) rows = rows.filter(r => JSON.stringify(r).toLowerCase().includes(filters.search.toLowerCase()))
      if (hasRows && !rows.length) this.toaster.warning('No matched records fround!')
      return rows
    })
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
      `${data.note.attributes.ignored}-${data[sortHeaderId]}`
  }

  async showAddNew() {
    const filters = this.filtersForm.getRawValue()
    const note: Partial<Note> = {
      attributes: {
        category: filters.category[0],
        company: filters.company,
      },
      visibility: 1,
    }

    if (this.dialogData.newFilters) {
      Object.assign(note, this.dialogData.newFilters)
    }

    if (this.dialogData.general) {
      await this.NoteForm.createGeneralNote(note)
    } else {
      const dv = await this.dialogData.dealViewRaw$.pipe(take(1)).toPromise()
      let results = await this.NoteForm.createDealNote(dv, note)
      this.filtersForm.controls.category.patchValue(
        [...this.filtersForm.getRawValue().category, ...results.map(r => r.attributes.category)]
      )
    }
  }
}
