<table mat-table [trackBy]="getRowId"
    fixedLayout [dataSource]="dataSource"
    matSort matSortStart="desc" matSortDisableClear>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns"
      [class.tc-loading-row]="!row"
      [class.clickable]="row?.type !== 'secondary'"></tr>
  <ng-container *ngIf="footer">
    <tr mat-footer-row *matFooterRowDef="displayColumns" class="tc-show-footer"></tr>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox matTooltip="Select All"
        (change)="selection.toggleRows($event.checked, dataSource.data)"></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource">
      <mat-checkbox [checked]="selection.isSelected(row)" (click)="$event.stopPropagation()"
        (change)="selection.toggleRow(row)"></mat-checkbox>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.type">{{columnNames.type}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.type">{{cost.type}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.created">{{columnNames.actual_weight_sell}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: cost.created }"></ng-container>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="service">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.service">{{columnNames.service}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.service">{{cost.service}}</div>
        <small *ngIf="cost.type === 'primary' || cost.type === 'tertiary'" class="text-gray">{{cost.originalCost.status}}</small>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="provider">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.provider">{{columnNames.provider}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.provider">{{cost.provider}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.createdBy">{{columnNames.createdBy}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.createdBy">{{cost.createdBy}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="actAmt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actAmt">{{columnNames.actAmt}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost?.actAmt">
        <div class="text-ellipsis">{{cost.actAmt | currency:cost.actCurrency}}</div>
        <small class="text-gray">{{cost.actCurrency}}</small>
      </ng-container>
      <ng-container *ngIf="cost && !cost.actAmt">
        <div class="text-ellipsis">NIL</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="estAmt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.estAmt">{{columnNames.estAmt}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <ng-container *ngIf="cost.secondaryType === 'percent' && cost.secondaryValue">
          <div class="text-ellipsis amount" [ngClass]="cost.type">{{cost.secondaryValue | number:'1.2' }}%</div>
        </ng-container>
        <ng-container *ngIf="cost.secondaryType !== 'percent' && cost.estAmt">
          <div class="text-ellipsis amount" [ngClass]="cost.type">{{cost.estAmt | currency:cost.estCurrency:'symbol-narrow'}}</div>
          <small class="text-gray">{{cost.estCurrency}}</small>
        </ng-container>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="fxRate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.fxRate">{{columnNames.fxRate}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost?.fxRate">
        <div class="text-ellipsis">{{cost.fxRate.rate | number:'1.4'}}</div>
        <small class="text-gray">{{cost.fxRate.code}}</small>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="actAmtCAD">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actAmtCAD">{{columnNames.actAmtCAD}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost?.actAmtCAD || cost?.actAmtCAD === 0">
        <div class="text-ellipsis">{{cost.actAmtCAD | currency:'CAD':'symbol-narrow'}}</div>
        <small class="text-gray">CAD</small>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis">{{totalActAmtCAD$ | async | currency:'CAD':'symbol-narrow'}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="estAmtCAD">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.estAmtCAD">{{columnNames.estAmtCAD}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost?.estAmtCAD || cost?.estAmtCAD === 0">
        <div class="text-ellipsis">{{cost.estAmtCAD | currency:'CAD':'symbol-narrow'}}</div>
        <small class="text-gray">CAD</small>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis">{{totalEstAmtCAD$ | async | currency:'CAD':'symbol-narrow'}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="partialAmtCAD">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.partialAmtCAD">{{columnNames.partialAmtCAD}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost?.partialAmtCAD || cost?.partialAmtCAD === 0">
        <div class="text-ellipsis">{{cost.partialAmtCAD | currency:'CAD':'symbol-narrow'}}</div>
        <small class="text-gray">CAD</small>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis">{{totalPartialAmtCAD$ | async | currency:'CAD':'symbol-narrow'}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="costUnit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.costUnit">{{columnNames.costUnit}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost && (cost.costUnit || cost.costUnit === 0) && units">
        <div class="text-ellipsis">{{cost.costUnit | currency:cost.estCurrency:'symbol-narrow':'1.4' | measure:units}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis">{{totalUnitCost$ | async | currency:'CAD':'symbol-narrow':'1.4' | measure:units}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="invoiceStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.invoiceStatus">{{columnNames.invoiceStatus}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="cost && cost.invoiceId">
        <div class="text-ellipsis" [title]="cost.invoiceId">
          <button class="btn-link" type="button"
              (click)="cost.type.includes('Credit') ? showUpdateItem(cost) : showInvoice(cost.invoice)"
              >{{cost.invoiceId}}</button>
        </div>
        <small class="text-gray" *ngIf="(cost.invoiceStatus || cost.invoiceStatus === 0)">
          <span class="label text-uppercase"
              [ngClass]="InvoiceStatus[cost.invoiceStatus].color ? 'label-' + InvoiceStatus[cost.invoiceStatus].color : 'label-danger'"
              [innerText]="InvoiceStatus[cost.invoiceStatus].value || cost.invoiceStatus"></span>
        </small>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="origin">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.origin">{{columnNames.origin}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.origin">{{cost.origin}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="destination">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.destination">{{columnNames.destination}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.destination">{{cost.destination}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="portLoading">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.portLoading">{{columnNames.portLoading}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.portLoading">{{cost.portLoading}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="portDischarge">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.portDischarge">{{columnNames.portDischarge}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.portDischarge">{{cost.portDischarge}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="dealSource">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.dealSource">{{columnNames.dealSource}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <div class="text-ellipsis" [title]="cost.dealSource">{{cost.dealSource}}</div>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="matchedOfferSource">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.matchedOfferSource">{{columnNames.matchedOfferSource}}</th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource"
        (click)="cost && rowClick.emit({cost: cost})">
      <ng-container *ngIf="!cost">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="cost">
        <a class="text-ellipsis" [title]="cost.matchedOfferSource | fakeId:'OFFER-'"
            (click)="$event.stopPropagation();$event.preventDefault();showEditOffer(cost.matchedOfferSource)">
          {{cost.matchedOfferSource | fakeId:'OFFER-'}}
        </a>
        <!-- <tc-matched-offer-status mo="row.entity"></tc-matched-offer-status> -->
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="ellipsisMenu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let cost; dataSource:dataSource">
      <ng-container *ngIf="!readonly && !cost">
        <div class="skeleton-loader circle"></div>
      </ng-container>
      <ng-container *ngIf="!readonly && cost && cost.type !== 'secondary'">
        <button mat-icon-button type="button"
            [matMenuTriggerFor]="ellipsisMenu"
            [matMenuTriggerData]="{cost: cost}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
</table>

<mat-paginator #paginator></mat-paginator>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-cost="cost">
    <button mat-menu-item *ngIf="cost.canUpdate" (click)="showUpdateItem(cost)">
      <i class="fa fa-fw fa-edit"></i> Edit
    </button>
    <button mat-menu-item *ngIf="cost.canActualize" (click)="showActualizeToZero(cost)">
      <i class="fa fa-fw fa-money-bill-1" aria-hidden="true"></i> Actualize to $0
    </button>
    <button mat-menu-item *ngIf="cost.canUnactualize" (click)="showUnactualizeToZero(cost)">
      <i class="fa fa-fw fa-undo" aria-hidden="true"></i> Unactualize cost
    </button>
    <button mat-menu-item [disabled]="!cost.canDelete" (click)="showDeleteItem(cost)">
      <i class="fa fa-fw fa-trash-can"></i> {{ cost.type === 'Credit Note' ? 'Void' : 'Delete' }}
    </button>
    <button mat-menu-item *ngIf="cost.hasFreightRate" (click)="showFreightRate(cost)">
      <i class="fa fa-fw fa-truck"></i> Show Freight Rate
    </button>
  </ng-template>
</mat-menu>

<ng-template #cellEpoch let-timestamp="timestamp">
  <div class="text-ellipsis" *ngIf="timestamp">
    <div class="text-ellipsis" [title]="timestamp | epoch:'L'">{{timestamp | epoch:'L'}}</div>
    <small class="text-ellipsis text-gray" [title]="timestamp | epoch:'LT'">{{timestamp | epoch:'LT'}}</small>
  </div>
</ng-template>
