import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import * as dayjs from 'dayjs'
import { Subject, combineLatest } from 'rxjs'
import { debounceTime, switchMap, take, tap } from 'rxjs/operators'
import { loadAccounts, selectAccount } from 'src/app/store/accounts'
import { selectCarrier } from 'src/app/store/carriers'
import { loadConsignees } from 'src/app/store/consignees'
import { DealFormGroup, SegmentFormGroup, SegmentsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { getSegmentFormPopulatedValues } from 'src/services/data/segments.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { TypedFormControl } from 'src/shared/utils/typed-forms'
import { NotesOverlayService } from '../notes/notes-overlay/notes-overlay.service'

@Component({ template: '' })
export abstract class SegmentFormBaseComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private NotesOverlay: NotesOverlayService,
    protected store: Store,
  ) {
    super()
  }

  isNew: boolean // TODO: convert to observable?
  abstract dealId: string
  abstract segmentForm: SegmentFormGroup
  abstract segmentsForm: SegmentsFormGroup
  dealForm: DealFormGroup

  protected readonly internalForm = new FormGroup({
    actualPickupTime: new FormControl<number>(undefined),
    actualDeliveryTime: new FormControl<number>(undefined),
    etdTime: new FormControl<number>(undefined),
    etaTime: new FormControl<number>(undefined),
  })

  protected readonly changedSegmentDate$ = new Subject<'actualPickupDate' | 'etdDate' | 'actualDeliveryDate' | 'etaDate' | 'shipmentBolDate'>()

  ngOnInit() {
    this.isNew = !this.segmentForm.value.segment?.segment_id

    if (this.isNew && this.segmentsForm.value.length) {
      const lastSegment = this.segmentsForm.value[this.segmentsForm.value.length - 1]
      const patch = getSegmentFormPopulatedValues(lastSegment)
      this.segmentForm.patchValue(patch)
      Object.keys(patch).forEach(ctrlName => this.segmentForm.controls[ctrlName].markAsDirty())
    }

    this.store.dispatch(loadConsignees())
    this.store.dispatch(loadAccounts({}))

    this.autopopulateEstimatedDates()
    this.handleTimeControls()
  }

  showSegmentNotes() {
    this.NotesOverlay.showSegmentNotes(this.dealId, {
      carrier_id: this.segmentForm.value.carrierId,
      segment_id: this.segmentForm.value.segment?.segment_id,
    }).subscribe()
  }

  async onCarrierChange() {
    const { carrierId } = this.segmentForm.value
    this.store.select(selectCarrier, carrierId).pipe(
      switchMap(carrier => this.store.select(selectAccount(carrier.account))),
      take(1))
      .subscribe(account => {
        this.segmentForm.patchValue({
          carrierAccountId: account.account.toString(),
          currencyCode: account.attributes.pricing?.currency,
        })
        this.segmentForm.controls.carrierAccountId.markAsDirty()
      })
  }
  private autopopulateEstimatedDates() {
    if (!this.segmentForm.value.actualPickupDate) {
      this.segmentForm.controls.etdDate.valueChanges.pipe(debounceTime(10), untilComponentDestroyed(this)).subscribe(etdDate => {
        const { actualPickupDate, actualPickupDateTime } = this.segmentForm.controls
        if (!actualPickupDate.pristine || !actualPickupDateTime.pristine) return
        this.segmentForm.patchValue({
          actualPickupDate: etdDate,
          actualPickupDateTime: this.segmentForm.controls.etdDateTime.value,
        })
        if (this.dealForm && this.segmentForm.controls.type.value === 'sea') {
          const shipmentBolDate = this.dealForm.controls.details.controls.shipmentBolDate
          shipmentBolDate.setValue(etdDate)
          shipmentBolDate.markAsDirty()
          this.changedSegmentDate$.next('shipmentBolDate');
        }
      })
    }
    if (!this.segmentForm.value.actualDeliveryDate) {
      this.segmentForm.controls.etaDate.valueChanges.pipe(debounceTime(10), untilComponentDestroyed(this)).subscribe(etaDate => {
        const { actualDeliveryDate, actualDeliveryDateTime } = this.segmentForm.controls
        if (!actualDeliveryDate.pristine || !actualDeliveryDateTime.pristine) return
        this.segmentForm.patchValue({
          actualDeliveryDate: etaDate,
          actualDeliveryDateTime: this.segmentForm.controls.etaDateTime.value,
        })
      })
    }
  }

  private handleTimeControls() {
    const {
      etaDateTime, etaDate, etdDateTime, etdDate, actualDeliveryDateTime, actualDeliveryDate, actualPickupDateTime, actualPickupDate,
    } = this.segmentForm.controls
    const { etaTime, etdTime, actualDeliveryTime, actualPickupTime } = this.internalForm.controls

    const syncFlagToTime = (dateTime: TypedFormControl<boolean>, time: TypedFormControl<number>, date: TypedFormControl<number>) =>
      replayForm(dateTime).pipe(tap(timeEnabled => {
        const timeValue = timeEnabled ? date.value : undefined
        if (time.value !== timeValue) time.setValue(timeValue)
      }))
    const syncDateToTime = (dateTime: TypedFormControl<boolean>, time: TypedFormControl<number>, date: TypedFormControl<number>) =>
      replayForm(date).pipe(tap(dateValue => {
        if (dateTime.value) {
          const timeValue = time.value
            - dayjs.utc(1000 * time.value).startOf('day').unix()
            + dayjs.utc(1000 * dateValue).startOf('day').unix()
          if (time.value !== timeValue) time.setValue(timeValue)
        } else {
          if (time.value) time.reset()
        }
      }))
    const syncTimeToDate = (dateTime: TypedFormControl<boolean>, time: TypedFormControl<number>, date: TypedFormControl<number>) =>
      replayForm(time).pipe(tap(timeValue => {
        if (!!timeValue && !date.value) date.setValue(timeValue)
        if (!!timeValue && date.value){
          const dateValue =
            dayjs.utc(1000 * date.value)
            .set('hour', dayjs.utc(1000 * timeValue).get('hour'))
            .set('minute', dayjs.utc(1000 * timeValue).get('minute')).unix()
          date.setValue(dateValue)
        }
        if (dateTime.value !== !!timeValue) dateTime.setValue(!!timeValue)
      }))

    combineLatest([
      syncFlagToTime(etaDateTime, etaTime, etaDate),
      syncFlagToTime(etdDateTime, etdTime, etdDate),
      syncFlagToTime(actualDeliveryDateTime, actualDeliveryTime, actualDeliveryDate),
      syncFlagToTime(actualPickupDateTime, actualPickupTime, actualPickupDate),

      syncDateToTime(etaDateTime, etaTime, etaDate),
      syncDateToTime(etdDateTime, etdTime, etdDate),
      syncDateToTime(actualDeliveryDateTime, actualDeliveryTime, actualDeliveryDate),
      syncDateToTime(actualPickupDateTime, actualPickupTime, actualPickupDate),

      syncTimeToDate(etaDateTime, etaTime, etaDate),
      syncTimeToDate(etdDateTime, etdTime, etdDate),
      syncTimeToDate(actualDeliveryDateTime, actualDeliveryTime, actualDeliveryDate),
      syncTimeToDate(actualPickupDateTime, actualPickupTime, actualPickupDate),
    ]).pipe(untilComponentDestroyed(this)).subscribe()
  }
}
