import { AfterContentChecked, Directive, Optional } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { MatDateRangeInput } from '@angular/material/datepicker'
import { MatFormField } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatSelect } from '@angular/material/select'

// NOTE: check https://github.com/angular/components/issues/2574
@Directive({
  selector: 'mat-form-field:has(input:not([required])), mat-form-field:has(textarea:not([required])), mat-form-field:has(mat-select:not([required]))',
})
export class ReactiveAsteriskDirective implements AfterContentChecked {
  constructor(@Optional() private matFormField: MatFormField) {}

  ngAfterContentChecked() {
    const ctrl = this.matFormField?._control
    if (ctrl instanceof MatInput || ctrl instanceof MatSelect) {
      ctrl.required = ctrl.ngControl?.control?.validator?.({} as AbstractControl)?.required
    } else if (ctrl instanceof MatDateRangeInput) {
      ctrl.required = ctrl._startInput?.ngControl?.control?.validator?.({} as AbstractControl)?.required
    }
  }
}
