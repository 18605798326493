import { Pipe, PipeTransform } from '@angular/core'
import { IEpochRange } from '@tradecafe/types/core'
import { printUnixRange } from '@tradecafe/types/utils'

@Pipe({
  name: 'epochRange',
  pure: true,
})
export class EpochRangePipe implements PipeTransform {

  transform(value: IEpochRange, utc?: 'utc'|true): string {
    return printUnixRange(value, {useUtc: !!utc})
  }

}
