import { Cost } from "@tradecafe/types/core";
import { getCostByProduct } from "@tradecafe/types/utils";
import { DealFormGroup, DealProductFormGroup } from "src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema";

export function getCostByProductForm(dealForm: DealFormGroup, productForm: DealProductFormGroup) {
  const products = dealForm.value.products.map(p => ({
    offer_id: p.offer?.offer_id,
    bid_id: p.bid?.bid_id,
    product_id: p.productId,
  }))
  const costs = dealForm.controls.costs.getRawValue().map(cf => cf.cost) as Cost[] // NOTE: not readonly
  const product = products[dealForm.controls.products.controls.indexOf(productForm)]
  return getCostByProduct({costs , products }, product)
}
