import { Injectable } from '@angular/core'
import { TableKey } from '@tradecafe/types/core'
import { AuthApiService } from 'src/api/auth'
import { UsersService } from 'src/services/data/users.service'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class ShareViewFormService {
  constructor(
    private modalHelper: ModalProxyService,
    private AuthApi: AuthApiService,
    private Users: UsersService,
  ) {}

  async shareTableView(tableIdentity: TableKey, view: {}) {
    // TODO: get roles - who can see current page
    const roles = [] // get($state.current, 'data.roles', [])
    const {account, user_id} = this.AuthApi.currentUser
    let { data: users } = await this.Users.getUsers(account)
    users = users.filter((user) =>
      user.user_id !== user_id && (!roles.length || roles.includes(user.role)))

    return this.modalHelper.showModal({
      component: 'shareViewModal',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        users: () => users,
        view: () => view,
        table: () => tableIdentity,
      },
    })
  }
}
