import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { Store, select } from '@ngrx/store'
import { Cost, ProductType, ShipmentRate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { filter as _filter, identity, pickBy, uniq } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { selectProviderOptions } from 'src/app/store/accounts'
import { selectAllCurrencies } from 'src/app/store/currencies'
import { loadProductCategories, selectAllProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes, selectAllProductTypes } from 'src/app/store/product-types'
import { selectNonProteinProducts, selectProductEntities } from 'src/app/store/products/product.selectors'
import { FreightRatesService } from 'src/pages/admin/logistics/freight-rates/freight-rates.service'
import { ProblematicFreightRatesService } from 'src/pages/admin/logistics/freight-rates/problematic-freight-rates/problematic-freight-rates.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealFormService } from '../../deal-form.service'
import { buildDealCostForm, prepareDealCostPatch } from '../../deal-form.service-factory'


export interface CostFormOptions {
  // ui config
  title: string
  canDuplicate?: boolean,
  canEditAmount?: boolean
  canEditCurrency?: boolean
  isActualizedToZero?: {
    username: string
    timestamp: number
  }
  // cost to edit
  cost?: Partial<DeepReadonly<Cost>>

  // hints
  supplierIds: DeepReadonly<Array<number | string>>
  buyerIds: DeepReadonly<Array<number | string>>
}

@Component({
  selector: 'tc-cost-form-v2',
  templateUrl: './cost-form.component.html',
  styleUrls: ['./cost-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CostFormComponent, Partial<Cost>[]>,
    private DealForm: DealFormService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private dialogData: CostFormOptions,
    private ProblematicFreightRates: ProblematicFreightRatesService,
    private FreightRates: FreightRatesService
  ) { super() }

  // raw cost
  private cost: Partial<DeepReadonly<Cost>> = this.dialogData.cost ||
    { type: 'tertiary', status: 'pending', attributes: {}, amount: { total: 0, currency: '' } }

  // ui config
  protected canDuplicate = this.dialogData.canDuplicate
  protected title = this.dialogData.title
  protected isActualizedToZero = this.dialogData.isActualizedToZero
  protected readonly canEditServiceName = this.cost.service && !this.cost.product_id
  protected readonly canEditAmount = this.dialogData.canEditAmount
  protected readonly canEditCurrency = this.dialogData.canEditCurrency

  // forms
  protected costForm = buildDealCostForm(prepareDealCostPatch(this.cost))
  protected categoryCtrl = new FormControl<string>(undefined)
  protected typeCtrl = new FormControl<string>(undefined)
  protected dupForm = new FormGroup({
    enabled: new FormControl(false),
    duplicates: new FormControl(1),
  })

  // ref data
  private products$ = this.store.pipe(select(selectNonProteinProducts), waitNotEmpty())
  private productsById$ = this.store.pipe(select(selectProductEntities), waitNotEmpty())
  protected currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty())
  protected providers$ = this.store.pipe(select(selectProviderOptions({
    supplierIds: this.dialogData.supplierIds,
    buyerIds: this.dialogData.buyerIds,
    providerId: this.cost?.provider,
  })), waitNotEmpty())

  // internal state
  protected inProgress$ = new BehaviorSubject(false)
  protected freightRate: ShipmentRate

  // reactive options
  protected filteredCategories$ = combineLatest([
    this.products$.pipe(map(products => uniq(products.map(p => p.category_id)))),
    this.store.pipe(select(selectAllProductCategories), waitNotEmpty()),
  ]).pipe(
    map(([categoryIds, categories]) =>
      categories.filter(category => categoryIds.includes(category.category_id))),
    distinctUntilChanged())

  protected filteredTypes$ = combineLatest([
    this.products$.pipe(map(products => uniq(products.map(p => p.type_id)))),
    this.store.pipe(select(selectAllProductTypes), waitNotEmpty()),
    replayForm<string>(this.categoryCtrl),
  ]).pipe(
    map(([typeIds, types, category_id]) =>
      _filter<ProductType>(types, pickBy({category_id}, identity))
      .filter(type => typeIds.includes(type.type_id))),
    distinctUntilChanged())

  protected filteredProducts$ = combineLatest([
    this.products$,
    replayForm<string>(this.categoryCtrl),
    replayForm<string>(this.typeCtrl),
  ]).pipe(
    map(([products, category_id, type_id]) => _filter(products, pickBy({category_id, type_id}, identity))),
    distinctUntilChanged())


  ngOnInit() {
    this.costForm.controls.productId.setValidators(this.canEditServiceName ? [] : [Validators.required])
    this.dupForm.controls.duplicates.disable()

    // sync product type and categories
    combineLatest([
      replayForm(this.costForm.controls.productId),
      this.productsById$,
    ]).pipe(
      map(([productId, products]) => products[productId]),
      distinctUntilChanged(),
      untilComponentDestroyed(this),
    ).subscribe(product => {
      if (product) {
        this.typeCtrl.setValue(product.type_id)
        this.categoryCtrl.setValue(product.category_id)
      }
    })

    // keep service name in sync with product_id
    combineLatest([
      replayForm(this.costForm.controls.productId),
      this.productsById$,
    ]).pipe(
      map(([productId, products]) => products[productId]),
      distinctUntilChanged(),
      untilComponentDestroyed(this),
    ).subscribe(product => {
      const serviceName = product?.name || ''
      if (product && this.costForm.value.serviceName !== serviceName) {
        this.costForm.patchValue({ serviceName })
      }
    })

    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadProductCategories({}))

    this.FreightRates.get(this.cost.attributes.rate_id).then(({ data }) => {
      this.freightRate = data
    })
  }

  protected save() {
    this.costForm.markAllAsTouched()
    if (!this.costForm.valid) return

    const dup = this.dupForm.getRawValue()
    const cost = this.DealForm.readCostForm(this.costForm.value)
    const result = [cost]
    if (dup.enabled) while (--dup.duplicates) result.push(cost)
    this.dialogRef.close(result)
  }

  protected cancel() {
    this.dialogRef.close()
  }

  protected dupEnabled(e?: MatSlideToggleChange) {
    if (e.checked) this.dupForm.controls.duplicates.enable()
    else this.dupForm.controls.duplicates.disable()
  }

  async flagFreightRate() {
    let stored = await this.ProblematicFreightRates.showProblematicFreightRates(this.freightRate)
    this.freightRate = stored
  }

  getProblemHistory() {
    return this.freightRate?.problem?.history.join('\n')
  }

  isFlagged() {
    return this.freightRate?.problem?.isFlagged
  }
}
