<form *ngIf="ready$ | async" [formGroup]="dealForm" novalidate (submit)="saveDeal()">
  <div class="tc-table-page-header" tc-sticky-header fxLayoutAlign="space-between center">
    <div fxFlex>
      <div class="mat-headline-6">
        TradeCafé - {{title$ | async}}
        <tc-locks [resourceId]="dealId$" resourceType="deal"></tc-locks>
        <tc-finance-issues [detailsForm]="detailsForm"></tc-finance-issues>
        <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
      </div>
      <small *ngIf="dealId$ | async">
        {{dealId$ | async}}
        <ng-container *ngIf="isClone$ | async" [ngSwitch]="isPrimaryClone$ | async">
          <ng-container *ngSwitchCase="true">
            This is the parent deal of a clone series
          </ng-container>
          <ng-container *ngSwitchCase="false">
            This is a clone deal from parent deal
            <a *ngIf="cloneFrom$ | async as clonedFrom" [routerLink]="'/trading/deals/' + clonedFrom" (click)="goToDealId(clonedFrom)">
              {{clonedFrom}}
            </a>
          </ng-container>
        </ng-container>
        <div *ngIf="isCopy$ | async">
          This deal was copied from
          <a *ngIf="copyFrom$ | async as copyFrom" [routerLink]="'/trading/deals/' + copyFrom" (click)="goToDealId(copyFrom)">
            {{copyFrom}}
          </a>
        </div>
      </small>
    </div>
    <ng-container *ngIf="!modalDisplay">
    <button type="button" mat-button color="link" (click)="goBack()">
      <i class="fa fa-times"></i> Back
    </button>
    <a *ngIf="dealId$ | async" mat-button color="link"
        href="/logistics/shipping-log/{{dealId$ | async}}"
        (click)="$event.stopPropagation();$event.preventDefault(); goToShippingView()"
        [disabled]="inProgress$ | async">
      <i class="fa-brands fa-wpforms"></i> Logistics View
    </a>
    </ng-container>
    <button type="submit" mat-button color="link"
        [class.tc-dirty-badge]="isDirtyAndValid$ | async"
        [class.tc-invalid-badge]="isInvalid$ | async"
        [matTooltip]="(fieldsReport$ | async) || incomplete.incomplete"
        matTooltipClass="pre-tooltip"
        matTooltipShowDelay="10000"
        [disabled]="(inProgress$ | async) || !!incomplete.incomplete">
      <i class="fa fa-fw"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      {{(isSubmitted$ | async) ? 'Save Deal' : 'Save Draft'}}
    </button>

    <mat-divider [vertical]="true" style="height: 50px; margin: 0 30px 0 15px"></mat-divider>
    <button type="button" mat-raised-button color="action"  [matMenuTriggerFor]="actionsMenu">
      <i class="fa fa-square-caret-down"></i> Action
    </button>
    <button type="button" mat-raised-button color="add"
        *ngIf="!(isSubmitted$ | async)"
        [matTooltip]="incomplete.incomplete"
        matTooltipClass="pre-tooltip"
        [disabled]="(inProgress$ | async) || !!incomplete.incomplete"
        (click)="submitDeal()">
      <i class="fa fa-fw"
          [class.fa-paper-plane]="(inProgress$ | async) !== 'submit'"
          [class.fa-spin]="(inProgress$ | async) === 'submit'"
          [class.fa-spinner]="(inProgress$ | async) === 'submit'"
          ></i>
      Confirm
    </button>
    <button type="button" mat-raised-button color="add"
        *ngIf="!(isLocked$ | async) && (isSubmitted$ | async)"
        [disabled]="inProgress$ | async"
        (click)="lockDeal(true)">
      <i class="fa fa-fw"
          [class.fa-lock]="(inProgress$ | async) !== 'lock'"
          [class.fa-spin]="(inProgress$ | async) === 'lock'"
          [class.fa-spinner]="(inProgress$ | async) === 'lock'"
          ></i>
      Lock
    </button>
  </div>
  <div class="main-form">
    <section class="top-section">
      <tc-epoch-field format="L" placeholder="Date"
          [group]="detailsForm" ctrlName="date"
          ></tc-epoch-field>
      <mat-form-field>
        <mat-label>Deal number</mat-label>
        <form (submit)="$event.stopPropagation(); $event.preventDefault()">
          <input matInput [formControl]="dealIdForm" autocomplete="off">
          <button type="submit" style="display: none"></button>
        </form>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <input readonly matInput [value]="dealStatus$ | async">
        <i matSuffix class="fa fa-2x"
            [class.fa-lock]="!!(isLocked$ | async)"
            [class.fa-unlock]="!(isLocked$ | async)"
            ></i>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Partial Margin (%)</mat-label>
        <input readonly matInput [value]="detailsForm.partialMarginP$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Partial Margin (CAD)</mat-label>
        <input readonly matInput [value]="detailsForm.partialMarginCad$ | async">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Partial Revenue (CAD)</mat-label>
        <input readonly matInput [value]="detailsForm.partialRevenueCad$ | async">
      </mat-form-field>
    </section>

    <mat-tab-group
        [selectedIndex]="activeTabIndex$ | async"
        (selectedIndexChange)="setActiveTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span [class.tc-invalid]="detailsTabInvalid$ | async">Deal Details</span>
          <!-- <tc-badge-invalid form="detailsTabInvalid$ | async"></tc-badge-invalid> -->
        </ng-template>
        <!-- <ng-template matTabContent> -->
          <tc-deal-details-v2
              [dealId]="dealId$ | async"
              [dealForm]="dealForm"
              [dealViewRaw$]="dealViewRaw$"
              [oldDealView$]="oldDealView$"
              [costs$]="costs$"
              (updateCost)="updateCost($event)"
              (removeCost)="removeCost($event)"
              ></tc-deal-details-v2>
        <!-- </ng-template> -->
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span [class.tc-invalid]="specsTabInvalid$ | async">Product Specifications</span>
          <!-- <tc-badge-invalid form="specsTabInvalid$ | async"></tc-badge-invalid> -->
        </ng-template>
        <!-- <ng-template matTabContent> -->
          <tc-deal-specs [productsForm]="productsForm"></tc-deal-specs>
        <!-- </ng-template> -->
      </mat-tab>

      <mat-tab label="Logistics Costs">
        <!-- <ng-template matTabContent> -->
          <header class="tc-logistics-costs-header" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
            <span flex></span>
            <button mat-raised-button color="primary" type="button" (click)="showAddCost()">
              <i class="fa fa-plus-square"></i> Add New
            </button>
            <button mat-raised-button color="primary" type="button" (click)="showAddFreight()">
              <i class="fa fa-plus-square"></i> Add Freight
            </button>
            <button mat-raised-button color="primary" type="button" (click)="showAddCredit()" *ngIf="!(isNew$ | async)">
              <i class="fa fa-plus-square"></i> Add Credit
            </button>
          </header>
          <tc-costs-list [types]="['tertiary']"
              [units]="productsForm.controls[0].controls.supplierMeasureId.value"
              [dealViewRaw]="dealViewRaw$ | async"
              [oldDealView]="oldDealView$ | async"
              [costsForm]="costsForm"
              [fxRates]="fxRates$ | async"
              [fxRatesRange]="fxRatesAskRange$ | async"
              [vendorCredits]="vendorCredits$ | async"
              [creditNotes]="creditNotes$ | async"
              (updateCost)="updateCost($event)"
              (removeCost)="removeCost($event)"
              (creditNotesChanged)="creditNotesChanged($event)"
              (vendorCreditsChanged)="vendorCreditsChanged($event)"
              ></tc-costs-list>
        <!-- </ng-template> -->
      </mat-tab>

      <mat-tab label="Shipping">
        <!-- <ng-template matTabContent> -->
          <header class="tc-segments-header" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
            <span flex></span>
            <button type="button" class="btn btn-primary pull-right" (click)="showAddSegment()">
              <i class="fa fa-plus-square" aria-hidden="true"></i> Add Segment
            </button>
          </header>
          <tc-segments-list-v2
              [dealViewRaw$]="dealViewRaw$"
              [segmentsForm]="segmentsForm"
              [fxRates]="fxRates$ | async"
              (updateSegment)="showUpdateSegment($event)"
              (removeSegment)="showRemoveSegment($event)"
              ></tc-segments-list-v2>
        <!-- </ng-template> -->
      </mat-tab>

      <mat-tab label="Default Costs">
        <!-- <ng-template matTabContent> -->
          <tc-costs-list [types]="['secondary']"
              [units]="productsForm.controls[0].controls.supplierMeasureId.value"
              [dealViewRaw]="dealViewRaw$ | async"
              [oldDealView]="oldDealView$ | async"
              [costsForm]="costsForm"
              [fxRates]="fxRates$ | async"
              [fxRatesRange]="fxRatesAskRange$ | async"
              [vendorCredits]="vendorCredits$ | async"
              [creditNotes]="creditNotes$ | async"
              [displayColumns]="['type','service','actAmt','estAmt','fxRate','actAmtCAD','estAmtCAD','partialAmtCAD','costUnit','invoiceStatus']"
              (creditNotesChanged)="creditNotesChanged($event)"
              (vendorCreditsChanged)="vendorCreditsChanged($event)"
              ></tc-costs-list>
        <!-- </ng-template> -->
      </mat-tab>

      <mat-tab label="Clones" [disabled]="isNew$ | async">
        <!-- <ng-template matTabContent> -->
          <tc-deal-clones-v2 #dealClones [dealViewRaw$]="dealViewRaw$"></tc-deal-clones-v2>
        <!-- </ng-template> -->
      </mat-tab>

    </mat-tab-group>
  </div>
</form>

<mat-menu #actionsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="previewDocuments(DealPartyE.supplier)"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-file-pdf"></i> Preview Supplier Documents</button>
    <button mat-menu-item (click)="previewDocuments(DealPartyE.buyer)"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-file-pdf"></i> Preview Buyer Documents</button>
    <button mat-menu-item (click)="previewDocuments(DealPartyE.brokerage_customer)"
        *ngIf="isBrokerageDeal$ | async"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-file-pdf"></i> Preview Brokerage Documents</button>
    <button mat-menu-item (click)="sendConfirmation(DealPartyE.supplier)"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-paper-plane"></i> {{sendConfirmationLabel(DealPartyE.supplier) | async}}</button>
    <button mat-menu-item (click)="sendConfirmation(DealPartyE.brokerage_customer)"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-paper-plane"></i> {{sendConfirmationLabel(DealPartyE.brokerage_customer) | async}}</button>
    <button mat-menu-item (click)="sendConfirmation(DealPartyE.buyer)"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-paper-plane"></i> {{sendConfirmationLabel(DealPartyE.buyer) | async}}</button>
    <button mat-menu-item (click)="showCreateOffer()"
        *ngIf="canCreateSupplierOffer$ | async"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-gift"></i> Create Supplier Offer</button>
    <button mat-menu-item (click)="showSupplierOffer()"
        *ngIf="hasActiveSupplierOffer(supplierOffer)"
        [disabled]="isConfirmationDisabled$ | async">
      <i class="fa fa-fw fa-gift"></i> View Supplier Offer</button>
    <button mat-menu-item (click)="showMatchedOffers()"
        *ngIf="supplierOffer">
      <i class="fa fa-fw fa-gift"></i> View Matched Offers</button>
    <mat-divider *ngIf="isClonesTabSelected$ | async"></mat-divider>
    <button mat-menu-item (click)="showChangeParty(DealPartyE.supplier)"
        *ngIf="isClonesTabSelected$ | async"
        [disabled]="noSelectedClones$ | async">
      <i class="fa fa-fw fa-users"></i> Change Supplier</button>
    <button mat-menu-item (click)="showChangeParty(DealPartyE.buyer)"
        *ngIf="isClonesTabSelected$ | async"
        [disabled]="noSelectedClones$ | async">
      <i class="fa fa-fw fa-users"></i> Change Buyer</button>
    <button mat-menu-item (click)="showChangeBrokerageParty()"
        *ngIf="(isBrokerageDeal$ | async) && (isClonesTabSelected$ | async)"
        [disabled]="noSelectedClones$ | async">
      <i class="fa fa-fw fa-users"></i> Change Brokerage Customer</button>
    <button mat-menu-item (click)="setSpecialInstructions(DealPartyE.supplier)"
        *ngIf="isClonesTabSelected$ | async"
        [disabled]="noSelectedClones$ | async">
      <i class="fa fa-fw fa-users"></i> Change Supplier Special Instructions</button>
    <button mat-menu-item (click)="setSpecialInstructions(DealPartyE.buyer)"
        *ngIf="isClonesTabSelected$ | async"
        [disabled]="noSelectedClones$ | async">
      <i class="fa fa-fw fa-users"></i> Change Buyer Special Instructions</button>
    <button mat-menu-item (click)="showChangeCloneDetails()"
      *ngIf="isClonesTabSelected$ | async"
      [disabled]="noSelectedClones$ | async">
    <i class="fa fa-fw fa-pen-to-square"></i> Change Clone Details</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="showExportGrid()"
        *ngIf="isClonesTabSelected$ | async">
      <i class="fa fa-fw fa-cloud-arrow-down"></i> Download to CSV</button>
    <button mat-menu-item (click)="showCloneDeal()" [disabled]="canNotClone$ | async">
      <i class="fa fa-fw fa-copy"></i> Clone Deal</button>
    <button mat-menu-item (click)="copyDeal()">
      <i class="fa fa-fw fa-clipboard"></i> Copy Deal</button>
    <button mat-menu-item (click)="lockDeal(true)"
        [disabled]="inProgress$ | async"
        *ngIf="!(isLocked$ | async) && (isSubmitted$ | async)">
      <i class="fa fa-fw"
          [class.fa-lock]="(inProgress$ | async) !== 'lock'"
          [class.fa-spin]="(inProgress$ | async) === 'lock'"
          [class.fa-spinner]="(inProgress$ | async) === 'lock'"
          ></i> Lock Deal</button>
    <button mat-menu-item (click)="lockDeal(false)"
        *ngIf="isAdmin && (isLocked$ | async)"
        [disabled]="!(isSubmitted$ | async) || (inProgress$ | async)">
      <i class="fa fa-fw"
          [class.fa-unlock]="(inProgress$ | async) !== 'lock'"
          [class.fa-spin]="(inProgress$ | async) === 'lock'"
          [class.fa-spinner]="(inProgress$ | async) === 'lock'"
          ></i> Unlock Deal</button>
    <button mat-menu-item disabled="true">
      <i class="fa fa-fw fa-trash-can"></i> Delete Deal</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="showMessagesOf()" [disabled]="isNew$ | async">
      <i class="fa fa-fw fa-comments"></i> Access Messages</button>
    <button mat-menu-item (click)="showDealNotes()" [disabled]="isNew$ | async">
      <i class="fa fa-fw fa-pencil"></i> Add/See Notes</button>
    <button mat-menu-item (click)="showDealDocuments()" [disabled]="isNew$ | async">
      <i class="fa fa-fw fa-copy"></i> Add/See Documents</button>
    <button mat-menu-item (click)="showAddCredit()" [disabled]="isNew$ | async">
      <i class="fa fa-fw fa-copy"></i> Add Credit</button>
    <button mat-menu-item (click)="showRequestPrepayment()" [disabled]="isNew$ | async"
        *ngIf="canCreatePrepayment$ | async">
      <i class="fa fa-fw fa-square-check"></i> Request Prepayment</button>
  </ng-template>
</mat-menu>
