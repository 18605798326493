<div mat-dialog-title>
  <h2><i class="fa fa-floppy-disk" aria-hidden="true"></i>&nbsp;{{title}}</h2>
  <tc-locks [resourceId]="dealForm.value.details.deal.deal_id" resourceType="deal"></tc-locks>
  <!-- <span fxFlex></span> -->
</div>
<form [formGroup]="dealForm" (submit)="save()" class="tc-squeeze-grid">
  <mat-dialog-content [ngSwitch]="isWarehouse$ | async">
    <tc-segment-form-regular *ngSwitchCase="false"
        [dealViewRaw$]="dealViewRaw$"
        [dealForm]="dealForm"
        [segmentForm]="segmentForm"
        [segmentIndex]="segmentIndex"
        [readonly]="readonly"
        (removeSegment)="removeSegment()"
        ></tc-segment-form-regular>
    <tc-segment-form-warehouse *ngSwitchCase="true"
        [dealId]="dealForm.value.details.deal.deal_id"
        [segmentIndex]="segmentIndex"
        [segmentForm]="segmentForm"
        [segmentsForm]="segmentsForm"
        [readonly]="readonly"
        (removeSegment)="removeSegment()"
        ></tc-segment-form-warehouse>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="segmentForm.invalid">
      <i class="fa fa-save"></i>&nbsp; Save
    </button>
  </mat-dialog-actions>
</form>
