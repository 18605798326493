import { TableKey } from '@tradecafe/types/core'
import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'


export type NoteColumnName = 'created' | 'category' | 'relatesTo' | 'visibility' | 'user' | 'body'

const NOTE_COLUMNS: Array<ColumnDef & { field: NoteColumnName }> = [
  { field: 'created',    displayName: 'Date',       exportPipe: 'epoch:L LT' },
  { field: 'category',   displayName: 'Category' },
  { field: 'relatesTo',  displayName: 'Relates To' },
  { field: 'visibility', displayName: 'Visibility' },
  { field: 'user',       displayName: 'User' },
  { field: 'body',       displayName: 'Note' },
]

const NOTE_COLUMN_NAMES = mapValues(keyBy(NOTE_COLUMNS, 'field'), 'displayName') as Record<NoteColumnName, string>

export function columnNames(_tableKey?: TableKey) {
  return {
    ...NOTE_COLUMN_NAMES,
    // ...NOTE_COLUMN_OVERRIDES[tableKey],
  }
}
