import { Injectable } from '@angular/core'
import { ShipmentRate } from '@tradecafe/types/core'
import { DeepPartial } from '@tradecafe/types/utils'
import { pick } from 'lodash-es'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { ShipmentRateApiService } from 'src/api/shipment-routing/shipment-rate'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { ModalService } from 'src/shared/modal'
import { FreightRateFormComponent, FreightRatesFormOptions } from './form/freight-rate-form.component'

const ALLOWED_FIELDS = ['until', 'status', 'commodity', 'weight', 'type', 'carrier_id', 'origin_id', 'destination_id',
  'container_size', 'rate', 'description', 'attributes', 'port_loading', 'port_discharge', 'archived',
  'visible_to_deal_id', 'visible_to_matched_offer_id', 'user_id', 'problem']

const QUERY_ALL = { limit: Number.MAX_SAFE_INTEGER }

export const FreightCategories = [
  // TODO: update whenever we'll need this. categories are used only by dropdowns now
  { id: 'Cheese', name: 'Cheese' },
  { id: 'Frozen Meat', name: 'Frozen Meat' },
  { id: 'Powder', name: 'Powder' },
]

@Injectable()
export class FreightRatesService {
  constructor(
    private AuthApi: AuthApiService,
    private ShipmentRateApi: ShipmentRateApiService,
    private NotesOverlay: NotesOverlayService,
    private modal: ModalService,
  ) { }

  public create(rate: Partial<ShipmentRate>) {
    const { user_id } = this.AuthApi.currentUser
    const payload = pick(rate, [...ALLOWED_FIELDS, 'single'])
    payload.user_id = user_id
    return this.ShipmentRateApi.create(payload).then(({ data }) => data)
  }

  public update(rate: Partial<ShipmentRate>) {
    const { user_id } = this.AuthApi.currentUser
    const payload = pick(rate, ALLOWED_FIELDS)
    payload.user_id = user_id
    return this.ShipmentRateApi.update(rate.rate_id, payload).then(({ data }) => data)
  }

  public get(rate_id: string) {
    return this.ShipmentRateApi.get(rate_id)
  }

  async listAll() {
    const allRates = []
    for (let i = 0; true; i++) {
      const { data: rates } = await this.ShipmentRateApi.list({ limit: 10000, skip: i * 10000 })
      if (!rates.length) break
      allRates.push(...rates)
    }
    return { data: allRates }
  }

  listVisibleTo(matchedOfferId: string) {
    const isPublicShipmentRate = (rate: ShipmentRate) => (!rate.visible_to_matched_offer_id && !rate.visible_to_deal_id);

    return this.ShipmentRateApi.listObs({ ...QUERY_ALL }).pipe(map(({ data: rates }) => {
      /* if  (dealId) {
        return rates.filter(rate => isPublicShipmentRate(rate) || rate.visible_to_deal_id === dealId);
      } else */ if (matchedOfferId) {
        return rates.filter(rate => isPublicShipmentRate(rate) || rate.visible_to_matched_offer_id === matchedOfferId);
      } else {
        return rates.filter(isPublicShipmentRate);
      }
    }))
  }

  public showNotes(freightRate: ShipmentRate) {
    return this.NotesOverlay.showFreightRateNotes(freightRate)
  }

  public showFormModal(freightRate: DeepPartial<ShipmentRate>, options: FreightRatesFormOptions = {}) {
    return this.modal.openDialog<FreightRateFormComponent, FreightRatesFormOptions, ShipmentRate>(
      FreightRateFormComponent,
      { freightRate, ...options },
      {
        panelClass: ['tc-dialog', 'modal-md'],
        width: null,
        maxWidth: null,
        autoFocus: false,
      }).toPromise()
  }
}
