import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal'
import { AesCancellationDialogComponent, AesCancellationDialogOptions } from './aes-cancellation-dialog.component'

@Injectable()
export class AesCancellationDialogService {

  constructor(private modal: ModalService) { }

  showAesCancellationDialog(opts: AesCancellationDialogOptions) {
    return this.modal.openDialog<AesCancellationDialogComponent, AesCancellationDialogOptions, boolean>(AesCancellationDialogComponent, opts, {
      panelClass: ['tc-dialog', 'modal-md'],
      width: null,
      maxWidth: null,
    })
  }
}
