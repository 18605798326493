import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { select, Store } from '@ngrx/store'
import { AccountObject, DistributionListObject, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, filter, first, get, uniq } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, map, take } from 'rxjs/operators'
import { selectAccountEntities } from 'src/app/store/accounts'
import { selectCarrierEntities } from 'src/app/store/carriers'
import { selectAllLocations } from 'src/app/store/locations'
import { selectAllUsers } from 'src/app/store/users'
import { AddressPipe } from 'src/filters/address.pipe'
import { EpochPipe } from 'src/filters/epoch.pipe'
import { getDealParties } from 'src/services/data/deal-view.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { requiredIf } from 'src/shared/utils/required-if'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { AuthApiService } from '../../../api/auth'
import { MessageApiService, SegmentNotification, SegmentNotificationType } from '../../../api/message'
import { selectAllProducts } from '../../../app/store/products'
import {
  DistributionListOverlayService
} from '../../../pages/admin/settings/companies/companies/actions/distribution-list-overlay/distribution-list-overlay.service'
import {
  createDistList
} from '../../../pages/admin/settings/companies/companies/actions/distribution-list-overlay/distribution-list/distribution-list.component'
import { AccountsService } from '../../../services/data/accounts.service'
import { dayjs } from '../../../services/dayjs'
import { SegmentTypes } from '../segment-form.component'
import { SegmentNotificationFormService, SegmentNotificationModalOptions } from './segment-notification-form.service'


const defaultFields = [
  {field: 'Carrier', id: 'carrier'},
  {field: 'Drop-off Location', id: 'dropoff_location'},
]

const fieldsMap = {
  rail: [
    ...defaultFields,
    {field: 'Act. drop-off date', id: 'actual_delivery_date'},
    {field: 'Container #', id: 'container_number'},
    {field: 'Booking #', id: 'booking_id'},
  ],
  air: [
    ...defaultFields,
    {field: 'Act. drop-off date', id: 'actual_delivery_date'},
    { field: 'Act. load date', id: 'actual_pickup_date' },
    { field: 'Flight Number #', id: 'flight_no' },
    { field: 'AWB# Tracking#', id: 'awb_tracking_no' },
  ],
  land: [
    ...defaultFields,
    {field: 'Act. drop-off date', id: 'actual_delivery_date'},
    { field: 'Exact Drop-off Address', id: 'exact_dropoff_address' },
  ],
  sea: [
    ...defaultFields,
    {field: 'Act. drop-off date', id: 'actual_delivery_date'},
    { field: 'Act. load date', id: 'actual_pickup_date' },
    { field: 'Container #', id: 'container_number' },
    { field: 'Booking #', id: 'booking_id' },
    { field: 'Name of Vessel', id: 'vessel' },
  ],
}


interface TableFields {
  show: boolean
  highlight: boolean
  fieldName: string
  field: string
  value: string
  data: string
}

@Component({
  selector: 'tc-segment-notification-form',
  templateUrl: './segment-notification-form.component.html',
  styleUrls: ['./segment-notification-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SegmentNotificationFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SegmentNotificationFormComponent, void>,
    private store: Store,
    private toaster: ToasterService,
    private fb: UntypedFormBuilder,
    private epochPipe: EpochPipe,
    private addressPipe: AddressPipe,
    private messageApiService: MessageApiService,
    private SegmentNotificationForm: SegmentNotificationFormService,
    private DistributionListOverlay: DistributionListOverlayService,
    private Accounts: AccountsService,
    private AuthApi: AuthApiService,
    @Inject(MAT_DIALOG_DATA) private dialogData: SegmentNotificationModalOptions,
  ) {
    super()
  }

  buyers$: Observable<DeepReadonly<User[]>>

  protected usersAll$ = this.store.pipe(select(selectAllUsers), waitNotEmpty())
  protected allUsers = []
  protected filteredBuyerContacts = []
  suppliers$: Observable<DeepReadonly<User[]>>
  serviceProviders$: Observable<DeepReadonly<User[]>>
  private products$ = this.store.pipe(select(selectAllProducts), waitNotEmpty())

  private serviceProviders: AccountObject[]

  segmentTypeOptions
  currentSegment
  typeOptions = Object.keys(SegmentNotificationType).map((k) =>
    ({name: k, id: SegmentNotificationType[k] as string})) as DeepReadonly<{name: string, id: SegmentNotificationType}[]>
  dataSource = new MatTableDataSource<TableFields>()
  displayColumns = ['show', 'highlight', 'fieldName', 'value']

  notificationForm = this.fb.group({
    type: [],
    segment_type: [],
    emails: [[], Validators.required],
    buyers: [[]],
    suppliers: [[]],
    serviceProviders: [[]],
    docs_available: [false],
    notes: [],
    emailsList: [],
  })

  distribution_lists: DistributionListObject[]

  private carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())
  private locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())

  private dataTransformers = {
    carrier: () =>  {
      let data
      this.carriers$.pipe(take(1)).subscribe((v) => {
        data = Object.values(v).find((c) => c.account === this.currentSegment.attributes['carrier_account'])?.name
      })
      return data
    },
    awb_tracking_no: () =>  this.currentSegment.attributes['tracking_no'],
    actual_delivery_date: () => this.epochPipe.transform(this.currentSegment.attributes['actual_delivery_date'], 'dddd MMMM DD, YYYY'),
    actual_pickup_date: () => this.epochPipe.transform(this.currentSegment.attributes['actual_pickup_date'], 'dddd MMMM DD, YYYY'),
    booking_id: () => this.currentSegment['booking_id'],
    exact_dropoff_address:  () => this.addressPipe.transform(this.currentSegment.attributes['exact_dropoff']?.address, ['name', 'street1', 'street2', 'city', 'state_code', 'country', 'postal']),
    dropoff_location: () => {
      let data
      this.locations$.pipe(take(1)).subscribe((v) => {
        data = v.find((c) => c.location_id === this.currentSegment.attributes['destination_id'])?.name
      })
      return data
    },
  }

  ngOnInit(): void {
    this.segmentTypeOptions = this.dialogData.segments.map((s) => ({id: s.segment_id, name: SegmentTypes[s.type], type: s.type}))

    this.updateForm(this.dialogData.currentSegmentId)

    this.notificationForm.controls['segment_type']
      .setValue(this.dialogData.currentSegmentId)

    this.notificationForm.controls['segment_type'].valueChanges.subscribe((segmentId) => {
      this.updateForm(segmentId)
    })


    this.notificationForm.controls.serviceProviders.valueChanges.pipe(distinctUntilChanged()).subscribe((selectedServiceProviders) => {
      requiredIf(this.notificationForm.controls.emails, !selectedServiceProviders?.length
          && !this.notificationForm.controls.suppliers.value[0]
          && !this.notificationForm.controls.buyers.value[0])
      this.notificationForm.controls.emails.updateValueAndValidity()
    })

    this.notificationForm.controls.suppliers.valueChanges.pipe(distinctUntilChanged()).subscribe((selectedSupliers) => {
      requiredIf(this.notificationForm.controls.emails, !selectedSupliers?.length
          && !this.notificationForm.controls.buyers.value[0]
          && !this.notificationForm.controls.serviceProviders.value[0])
      this.notificationForm.controls.emails.updateValueAndValidity()
    })

    this.notificationForm.controls.buyers.valueChanges.pipe(distinctUntilChanged()).subscribe((selectedBuyers) => {
      requiredIf(this.notificationForm.controls.emails, !selectedBuyers?.length
          && !this.notificationForm.controls.serviceProviders.value[0]
          && !this.notificationForm.controls.suppliers.value[0])
      this.notificationForm.controls.emails.updateValueAndValidity()
    })

    this.notificationForm.controls.emailsList.valueChanges.pipe(distinctUntilChanged()).subscribe((emailsList) => {
      if (!emailsList) return

      const list = this.distribution_lists.find((l) => l.name === emailsList)

      if (list.notes) {
        this.notificationForm.controls['notes'].setValue(list.notes)
      }

      const emails = list.recipients.filter((i) => Object.keys(i)[0] === 'email').map((i) => i.email)

      if (emails[0]) {
       this.notificationForm.controls['emails'].setValue(emails)
      }

      if (list?.contacts?.buyer) {
        this.notificationForm.controls['buyers'].setValue(list.contacts.buyer)
      }

      if (list?.contacts?.service_provider) {
        this.notificationForm.controls['serviceProviders'].setValue(list?.contacts?.service_provider)
      }
    })


    this.buyers$ = combineLatest([this.accounts$, this.usersAll$, this.dialogData.deal$, this.products$]).pipe(
        map(([accounts, users, dv, allProducts]) => {
              this.distribution_lists = accounts[dv.deal.buyer_id]?.distribution_lists as DistributionListObject[]
              if (this.distribution_lists[0] && !this.notificationForm.controls.emailsList.value) {
                const filteredItem = this.distribution_lists.
                find((i) => this.SegmentNotificationForm.checkFilters(i, dv, allProducts, this.currentSegment.attributes.carrier_account))
                if (filteredItem) {
                  this.notificationForm.controls.emailsList.setValue(filteredItem.name)
                }
              }

              return users.filter((u) => u.account === Number(dv.deal.buyer_id))
            },
      distinctUntilChanged()))

    this.suppliers$ = combineLatest([this.carriers$, this.accounts$, this.usersAll$, this.dialogData.deal$]).pipe(
      map(([carriers, accounts, users, dv]) => {
        const suppliers = filter(getDealParties(dv, accounts, carriers), {type: 'supplier'}) as any

       return users.filter((u) => suppliers.find((s) => s.account === u.account))
      },
      distinctUntilChanged()))

    this.serviceProviders$ = combineLatest([this.carriers$, this.accounts$, this.usersAll$, this.dialogData.deal$]).pipe(
        map(([carriers, accounts, users, dv]) => {
          this.serviceProviders = filter(getDealParties(dv, accounts, carriers), {type: 'service_provider'}) as any

          return users.filter((u) => this.serviceProviders.find((sp) => sp.account === u.account))
        },
        distinctUntilChanged()))
  }

  private updateForm = (segmentId) => {
    this.currentSegment = this.dialogData.segments.find((s) => s.segment_id === segmentId)

    this.notificationForm.controls['type']
      .setValue(this.currentSegment.created === this.currentSegment.updated
        ?  SegmentNotificationType.Created : SegmentNotificationType.Updated)

    this.dataSource.data = fieldsMap[this.currentSegment.type].map(({field, id}) => ({
      show: true,
      highlight: false,
      fieldName: field,
      field: id,
      value: this.dataTransformers[id] && this.dataTransformers[id]() || this.currentSegment.attributes[id],
    }))
  }

  toggleTableCheckbox (row, field) {
    row[field] = !row[field]
  }

  async submit  () {
    this.notificationForm.markAllAsTouched()
    this.notificationForm.updateValueAndValidity()

    if (!this.notificationForm.valid) return

    const notificationForm = this.notificationForm.getRawValue()

    const fields = [...this.dataSource.data]
      .filter((i) => i.show && i.value)
      .map((i) => { delete i.show; delete i.fieldName; return i})

    const segment_type = this.segmentTypeOptions.find((i) => i.id === notificationForm.segment_type).type

    const users = uniq([
      ...get(notificationForm, 'buyers', []),
      ...get(notificationForm, 'suppliers', []),
      ...get(notificationForm, 'serviceProviders', []),
    ])

    const notification: SegmentNotification = {
      deal_id: first(get(this.currentSegment, 'deals')),
      docs_available: notificationForm.docs_available ? 1 : 0,
      emails: notificationForm.emails,
      fields,
      notes: notificationForm.notes,
      segment_type,
      type: notificationForm.type,
      users,
    }

    try {
      await this.messageApiService.sendSegmentNotification(notification)
    } catch (err) {
      console.error('Unable to send notification', err)
      this.toaster.error('Unable to send notification', err)
      return
    }
    this.dialogRef.close()
    this.toaster.success('Notification has been sent')
  }

  saveDistributionList (): void {
    combineLatest([this.accounts$, this.dialogData.deal$]).pipe(take(1)).subscribe(([accounts, dv]) => {
      const activeDistListName = this.notificationForm.controls.emailsList.value
      const dList = createDistList()
      let activeListIndex

      if (activeDistListName) {
        activeListIndex = this.distribution_lists.findIndex((i) => i.name === activeDistListName)
        const activeDlist = this.distribution_lists[activeListIndex]
        dList.filters = activeDlist.filters
        dList.name = activeDlist.name
      }

      dList.recipients = this.notificationForm.controls.emails.value.map((i) => ({email: i}))
      dList.notes = this.notificationForm.controls.notes.value


      dList.service_providers = this.serviceProviders.map((i) => i.account)

      dList.contacts = {
        buyer: this.notificationForm.controls.buyers.value,
        service_provider: this.notificationForm.controls.serviceProviders.value,
      }

      const buyerCompany = cloneDeep(accounts[dv.deal.buyer_id]) as AccountObject


      this.DistributionListOverlay.showUpdateDistributionList(buyerCompany, dList).subscribe(
          (res) => {

            if (!res) return

            const newDistributionLists = cloneDeep(this.distribution_lists)

            res.updated_at = dayjs.utc().unix()
            res.updated_by = this.AuthApi.currentUser.user_id

            if (activeDistListName) {
              if (activeListIndex) {
                newDistributionLists[activeListIndex] = res
              } else {
                newDistributionLists.push(res)
              }
            } else {
              newDistributionLists.push(res)
            }

            buyerCompany.distribution_lists = newDistributionLists

            this.Accounts.saveAccount(buyerCompany).then(() => {
              this.distribution_lists = newDistributionLists
              this.notificationForm.controls.emailsList.setValue(res.name)
              this.toaster.success(`Distribution List ${res.name} has been ${activeDistListName ? 'updated' : 'created'}`)
            })
          },
      )


    })
  }

  cancel (): void {
    this.dialogRef.close()
  }

}
