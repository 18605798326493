import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { ProductFieldModule } from 'src/components/product-field/product-field.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ChangeCloneFormComponent } from './change-clone-form.component'
import { ChangeCloneFormService } from './change-clone-form.service'

@NgModule({
  providers: [ChangeCloneFormService],
  declarations: [ChangeCloneFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    ProductFieldModule,
    FlexLayoutModule,
  ],
})
export class ChangeCloneFormModule {}
