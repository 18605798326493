import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  FreightRatesLogOverlayComponent,
  FreightRatesLogOverlayOptions,
} from './freight-rates-log-overlay.component';

@Injectable()
export class FreightRatesLogOverlayService {
  constructor(private modal: ModalService) {}

  showLogs(rate_id: string) {
    return this.modal.openDialog<
      FreightRatesLogOverlayComponent,
      FreightRatesLogOverlayOptions,
      void
    >(
      FreightRatesLogOverlayComponent,
      {
        title: `Log`,
        rate_id,
      },
      {
        panelClass: ['tc-dialog', 'modal-xlg'],
        width: null,
        maxWidth: null,
      }
    );
  }
}
