import { Injectable } from '@angular/core'
import { Cost, DealParty, DealPartyE, DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawSegments } from '@tradecafe/types/core'
import { DeepReadonly, calculateTermDate, findBuyerInvoice } from '@tradecafe/types/utils'
import { cloneDeep, compact, isEqual, uniq } from 'lodash-es'
import { Observable, from } from 'rxjs'
import { map } from 'rxjs/operators'
import { DealDetailsFormValue } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { CustomCostsService } from './custom-costs.service'


/**
 * Deal View Calculator service.
 *
 * @export
 * @returns
 */
@Injectable()
export class DealViewCalculatorService {
  constructor(
    private CustomCosts: CustomCostsService,
  ) { }


  /**
   * Calculate term dates (no conditions as in SegmentForm or Trading Summary)
   *
   * @param {DealViewRaw} dv
   * @param {DealParty} party = undefined|'supplier'|'buyer'
   */
  // tslint:disable-next-line: cyclomatic-complexity
  calculateTermDates(dv: DeepReadonly<DealViewRawDeal & DealViewRawCosts & DealViewRawSegments & DealViewRawInvoices>, party?: DealPartyE) {
    const epochDay = 86400
    const details: Partial<DealDetailsFormValue> = {}
    const buyerInvoice = findBuyerInvoice(dv.invoices, dv.deal.buyer_id)
    let deal = dv.deal

    if (party !== DealPartyE.buyer) {
      const days = dv.deal.attributes.supplier_payment_terms.days
      const from_date = dv.deal.attributes.supplier_payment_terms.from_date
      const dealView = { ...dv.deal, segments: dv.segments, buyer: { invoice: buyerInvoice } }
      const termDate = calculateTermDate(dealView, from_date)
      details.supplierTermDate = termDate || undefined
      details.antLiabilityDate = termDate + days * epochDay || undefined
      deal = {
        ...deal,
        supplier_anticipated_liability: details.antLiabilityDate,
        attributes: {
          ...deal.attributes,
          supplier_term_date: details.supplierTermDate,
        },
      }
    }

    if (party !== DealPartyE.supplier) {
      const days = dv.deal.attributes.buyer_payment_terms.days
      const from_date = dv.deal.attributes.buyer_payment_terms.from_date
      const dealView = { ...dv.deal, segments: dv.segments, buyer: { invoice: buyerInvoice } }
      const termDate = calculateTermDate(dealView, from_date)
      details.buyerTermDate = termDate || undefined
      details.collectionDate = termDate + days * epochDay || undefined
      deal = {
        ...deal,
        collection_date: details.collectionDate,
        attributes: {
          ...deal.attributes,
          buyer_term_date: details.buyerTermDate,
        },
      }
    }

    return { details, deal }
  }

  /**
   * Refresh secondary costs. Return new secondary costs. Doesn't write to BE.
   *
   * @param {(DealViewRawDeal & DealViewRawBids & DealViewRawCosts)} dv
   * @return {Observable<Partial<Cost>[]>}
   * @memberof DealViewCalculatorService
   */
  refreshSecondaryCosts(dv: DeepReadonly<DealViewRawDeal & DealViewRawBids & DealViewRawCosts>): Observable<Partial<Cost>[]> {
    return from(this.CustomCosts.querySecondaryCosts({
      dealType: dv.deal.deal_type,
      providerIds: compact(uniq(dv.costs.map(cost => cost.provider))),
      supplierId: parseFloat(dv.deal.supplier_id) || undefined,
      buyerId: parseFloat(dv.deal.buyer_id) || undefined,
      originId: dv.deal.origin_location,
      destinationId: dv.deal.dest_location,
      productIds: compact(dv.bids.map(x => x.product)),
    })).pipe(map(secondaryCosts => {
      const existing = dv.costs.filter(isProperSecondaryCost)
      const toAdd = secondaryCosts.filter(c1 => !existing.find(c2 => isEqualSecondaryCost(c1, c2)))

      // keep updated costs; keep estimated values; update attributes.default_cost reference
      const toUpdate = existing
        .filter(c1 => secondaryCosts.find(c2 => isEqualSecondaryCost(c1, c2)))
        .map((oldCost): Partial<Cost> => {
          const newCost = secondaryCosts.find(c2 => isEqualSecondaryCost(oldCost, c2))
          return {
            // keep estimated values
            ...cloneDeep(oldCost) as Cost,
            attributes: {
              ...cloneDeep(oldCost.attributes) as Cost['attributes'],
              default_cost: newCost.attributes.default_cost,
              // NOTE: actual values will be recalculated in deal financials
            }
          }
        })
      return [...toUpdate, ...toAdd]
    }))

    function isProperSecondaryCost(cost: DeepReadonly<Partial<Cost>>) {
      return cost.type === 'secondary' && cost.attributes?.default_cost?.cost_type_id
    }

    function isEqualSecondaryCost(c1: DeepReadonly<Partial<Cost>>, c2: DeepReadonly<Partial<Cost>>) {
      return isEqual(c1.attributes.default_cost.cost_type_id, c2.attributes.default_cost.cost_type_id)
    }
  }
}

