import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { DealRow, SUBMITTED_STATUSES, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { identity } from 'lodash-es'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { loadDealViews } from 'src/app/store/deal-view.actions'
import { selectDealsTable } from 'src/app/store/deal-view.reducer'
import { columnNames, DealsListComponent } from 'src/components/deals-list'
import { DEFAULT_DEALS_FILTERS } from 'src/components/deals-list/deals-list.filters'
import { FiltersService } from 'src/services/table-utils'
import { ApiTableDataSource } from 'src/services/table-utils/data-sources/api-table-data-source'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealDetailsFormValue } from '../deal-form.schema'
import { DealFormSnapshotsService } from '../deal-form.service-snapshots'

export interface DealAutocopyFormOptions {
  buyerId: string
  supplierId: string
  dealDetails: DeepReadonly<DealDetailsFormValue>
}

@Component({
  selector: 'tc-deal-autocopy',
  templateUrl: './deal-autocopy-form.component.html',
  styleUrls: ['./deal-autocopy-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealAutocopyFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private Filters: FiltersService,
    private DealFormSnapshots: DealFormSnapshotsService,
    private store: Store,
    private dialogRef: MatDialogRef<DealAutocopyFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DealAutocopyFormOptions,
  ) { super() }

  tableIdentity = TableKey.DealAutocopy
  displayColumns = ['select', 'deal_date', 'deal_id', 'product', 'buy_price', 'sell_price', 'status', 'buyer', 'dest_location', 'supplier', 'origin_location']
  columnNames = columnNames(this.tableIdentity)

  @ViewChild(DealsListComponent) dealsList: DealsListComponent

  dataSource: ApiTableDataSource<DeepReadonly<DealRow>, any>
  filtersForm = this.Filters.buildForm(this, {
    key: this.tableIdentity,
    defaultValues: {
      ...DEFAULT_DEALS_FILTERS,
      columns: this.displayColumns,
      buyer: [this.data.buyerId],
      supplier: [this.data.supplierId],
      status: SUBMITTED_STATUSES,
    },
  })

  ngOnInit(): void {
    const deals$ = this.store.select(selectDealsTable(this.tableIdentity))

    const trigger$ = new Subject()

    // setup data source
    this.dataSource = new ApiTableDataSource<DeepReadonly<DealRow>, any>(
      deals$.pipe(filter(identity)),
      this.filtersForm,
      trigger$)

    // listen for fetch commands - fetch data
    this.dataSource.fetch$.pipe(untilComponentDestroyed(this)).subscribe(({ filters, page }) => {
      this.store.dispatch(loadDealViews({
        tableKey: this.tableIdentity,
        page,
        filters,
        calculate: false,
      }))
    })

    replayForm(this.filtersForm).pipe(untilComponentDestroyed(this)).subscribe(() =>
      trigger$.next())
  }

  async copyDeal() {
    const [dealId] = this.dealsList.selection.selectedIds.keys()
    const navigated = await this.DealFormSnapshots.copyDealId(dealId, this.data.dealDetails)
    if (navigated) this.dialogRef.close()
  }

  cancel() {
    this.dialogRef.close()
  }
}
