import { createAction, props } from '@ngrx/store'
import { CreditNote, TableKey, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { InvoiceRow, InvoiceTotalsDto } from 'src/api/invoice'
import { ElasticSearchFilters, ElasticSearchPayload } from 'src/services/elastic-search'


export const loadInvoiceViews = createAction(
  '[InvoiceView/API] Load InvoiceViews',
  props<{
    tableKey: TableKey,
    page: number,
    filters: any,
    calculate: boolean,
  }>(),
)
export const loadInvoiceViewsSuccess = createAction(
  '[InvoiceView/API] Load InvoiceViews Success',
  props<{
    tableKey: TableKey,
    total: number,
    totals: InvoiceTotalsDto,
    invoiceViews: InvoiceRow[],
    payload: ElasticSearchPayload
  }>(),
)
export const loadInvoiceViewsFailure = createAction(
  '[InvoiceView/API] Load InvoiceViews Failure',
  props<{ error: Error }>(),
)

export const loadInvoiceFilters = createAction(
  '[InvoiceView/API] Load InvoiceFilters',
  props<{
    tableKey: TableKey,
    filters: any,
  }>(),
)
export const loadInvoiceFiltersSuccess = createAction(
  '[InvoiceView/API] Load InvoiceFilters Success',
  props<{
    tableKey: TableKey,
    result: ElasticSearchFilters,
  }>(),
)
export const loadInvoiceFiltersFailure = createAction(
  '[InvoiceView/API] Load InvoiceFilters Failure',
  props<{ error: Error }>(),
)

export const setInvoiceInv = createAction(
  '[InvoiceView/API] Set Invoice Inv',
  props<{
    invoice: InvoiceRow
    inv: string
  }>(),
)
export const setInvoiceInvSuccess = createAction(
  '[InvoiceView/API] Set Invoice Inv Success')
export const setInvoiceInvFailure = createAction(
  '[InvoiceView/API] Set Invoice Inv Failure', props<{ error: Error }>())

  export const setInvoicePaymentType = createAction(
  '[InvoiceView/API] Set Invoice PaymentType',
  props<{
    invoice: InvoiceRow
    paymentType: string
  }>(),
)
export const setInvoicePaymentTypeSuccess = createAction(
  '[InvoiceView/API] Set Invoice PaymentType Success')
export const setInvoicePaymentTypeFailure = createAction(
  '[InvoiceView/API] Set Invoice PaymentType Failure', props<{ error: Error }>())

export const setInvoicePaidDate = createAction(
  '[InvoiceView/API] Set Invoice PaidDate',
  props<{
    invoice: InvoiceRow
    date: number
  }>(),
)
export const setInvoicePaidDateSuccess = createAction(
  '[InvoiceView/API] Set Invoice PaidDate Success')
export const setInvoicePaidDateFailure = createAction(
  '[InvoiceView/API] Set Invoice PaidDate Failure', props<{ error: Error }>())


export const voidCreditNote = createAction('[CreditNote/API] Void Credit Note', props<{ credit: DeepReadonly<CreditNote> }>())
export const voidCreditNoteSuccess = createAction('[CreditNote/API] Void Credit Note Success')
export const voidCreditNoteFailure = createAction('[CreditNote/API] Void Credit Note Failure', props<{ error: Error }>())

export const voidVendorCredit = createAction('[VendorCredit/API] Void Vendor Credit', props<{ credit: DeepReadonly<VendorCredit> }>())
export const voidVendorCreditSuccess = createAction('[VendorCredit/API] Void Vendor Credit Success')
export const voidVendorCreditFailure = createAction('[VendorCredit/API] Void Vendor Credit Failure', props<{ error: Error }>())
