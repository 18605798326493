import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Offer } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('OfferApi', downgradeInjectable(OfferApiService))

@Injectable()
@AngularCopy()
export class OfferApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account: number, @AngularParams() params) {
    return this.http.get<{ data: Offer[] }>(`${apiUrl}/${account}/offers`, { params }).toPromise()
  }

  filter(account: number, params) {
    return this.http.post<{ data: Offer[] }>(`${apiUrl}/${account}/offers/filter`, params).toPromise()
  }

  byDealIds(account: number, deal_ids: string[]) {
    return this.http.post<{ data: Offer[] }>(`${apiUrl}/${account}/offers/by_deals`, { deal_ids }).toPromise()
  }

  create(account: number, data: Partial<Offer>) {
    return this.http.post<{ data?: Offer, error?: object }>(`${apiUrl}/${account}/offers`, data).toPromise()
  }

  get(account: number, id: string) {
    return this.http.get<{ data: Offer }>(`${apiUrl}/${account}/offers/${id}`).toPromise()
  }

  update(account: number, id: string, data: Partial<Offer>) {
    return this.http.put<{ data?: Offer, error?: object }>(`${apiUrl}/${account}/offers/${id}`, data).toPromise()
  }

  delete(account: number, id: string) {
    return this.http.delete<{ data: Offer }>(`${apiUrl}/${account}/offers/${id}`).toPromise()
  }

  // deleteBatch(account: number, ids: string[]) {
  //   return this.http.operation<{ data: Offer }>(`${apiUrl}/${account}/offers/remove/_batch`, {ids}).toPromise()
  // }

  // addNote(account: number, id: string, noteText) {
  //   return this.http.post<{ data: Offer }>(`${apiUrl}/${account}/offers/${id}/notes`, {text: noteText}).toPromise()
  // }

  cancel(offer_ids: string[]) {
    return this.http.post<{ modified: number, errors: { message: string }[] }>(`${apiUrl}/offers/cancel`, {offer_ids}).toPromise()
  }

  getCountryCodes() {
    return this.http.get<{ data: string[] }>(`${apiUrl}/offers/countries`).toPromise()
  }
}
