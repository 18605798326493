import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DealViewRaw, SegmentType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map, take } from 'rxjs/operators'
import { DealFormGroup, SegmentFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { replayForm } from 'src/shared/utils/replay-form'
import { SegmentFormRemoveService } from './segment-form-remove.service'

export interface SegmentFormData {
  dealForm: DealFormGroup
  dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
  segmentIndex: number
  segmentForm: SegmentFormGroup

  title?: string // TODO: move header out of segment form. create separate container for mat-dialog
  readonly: boolean
}

export enum SegmentTypes {
  land = 'Truck',
  sea = 'Vessel',
  air = 'Airplane',
  rail = 'Train',
}

@Component({
  selector: 'tc-segment-form-v2',
  templateUrl: './segment-form.component.html',
  styleUrls: ['./segment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentFormComponent implements OnInit, SegmentFormData {
  constructor(
    private dialog: MatDialogRef<SegmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) data: SegmentFormData,
    private SegmentFormRemove: SegmentFormRemoveService,
  ) {
    Object.assign(this, data)
  }

  // all @Input fields are mandatory
  @Input() dealForm: DealFormGroup
  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
  get segmentsForm() { return this.dealForm.controls.segments }
  @Input() segmentIndex: number
  @Input() segmentForm: SegmentFormGroup

  @Input() title: string // TODO: move header out of segment form. create separate container for mat-dialog
  @Input() readonly = false

  isWarehouse$: Observable<boolean>

  ngOnInit() {
    const isNew = !this.segmentForm.value.segment?.segment_id
    this.title = isNew ? 'New Segment' : 'Edit Segment'
    this.isWarehouse$ = replayForm(this.segmentForm).pipe(map(s => s.type === 'warehouse'), distinctUntilChanged())
  }

  setSegmentType(type: SegmentType) {
    this.segmentForm.patchValue({ type })
  }

  save() {
    this.segmentForm.markAllAsTouched()
    if (!this.segmentForm.valid) return
    this.dialog.close(this.segmentForm.value)
  }

  cancel() {
    this.dialog.close()
  }

  removeSegment() {
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.SegmentFormRemove.removeSegmentForm(dv, this.dealForm, this.segmentForm)
    })
  }
}
