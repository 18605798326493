import { Injectable } from '@angular/core'
import { Invoice } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep } from 'lodash-es'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class InvoiceBatchFormService {
  constructor(private modalHelper: ModalProxyService) {}

  showBatchFormImmutable(invoices: DeepReadonly<Invoice[]>) {
    return this.showBatchForm(cloneDeep(invoices) as Invoice[])
  }

  showBatchForm(invoices: Invoice[]) {
    return this.modalHelper.showModal({
      component: 'tcInvoiceBatchForm',
      windowClass: 'modalclone',
      resolve: {
        invoices: () => invoices,
      },
    })
  }
}
