<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;Send T&E Request
</h2>

<form [formGroup]="recipientsForm" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(1, minmax(0, 1fr))" gdGap="10px" gdColumns.sm="repeat(2, minmax(0, 2fr))"
    gdColumns.md="repeat(3, minmax(0, 1fr))" gdColumns.gt-md="repeat(4, minmax(0, 1fr))" gdGap="0 15px">
    <tc-select-search placeholder="Segments" [group]="recipientsForm" ctrlName="segments" [items]="dealSegments"
      bindValue="segment_id" bindLabel="carrierName" bindHint="type" clearable="true"
      [multiple]="true"></tc-select-search>
    <tc-select-search placeholder="Distribution Lists" [group]="recipientsForm" ctrlName="selectedDistributionLists"
      [items]="allAvailableDistributionLists" bindValue="tmpId" bindLabel="name" clearable="true"
      [multiple]="true"></tc-select-search>
    <tc-select-search placeholder="Carrier Contact" [group]="recipientsForm" ctrlName="selectedCarrierContacts"
      [items]="allAvailableCarrierContacts" bindValue="user_id" bindLabel="fullname" bindHint="primaryemail"
      clearable="true" [multiple]="true"></tc-select-search>
    <tc-select-search placeholder="TradeCafe Staff" [group]="recipientsForm" ctrlName="selectedTradeCafeUsers"
      [items]="allAvailableTradeCafeUsers" bindValue="user_id" bindLabel="fullname" bindHint="fullname" clearable="true"
      [multiple]="true"></tc-select-search>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw" [class.fa-save]="true" aria-hidden="true"></i>
      Send
    </button>
  </mat-dialog-actions>
</form>