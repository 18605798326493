import { Injectable } from '@angular/core'
import { Cost, DealView, Measure, Product } from '@tradecafe/types/core'
import { getCostByProduct, partial } from '@tradecafe/types/utils'
import { find, forEach, get, map, merge, omit, pick, remove } from 'lodash-es'
import { DealApiService } from 'src/api/deal'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { dayjs } from '../dayjs'
import { DealFinancialsListenerService } from '../deal-financials-listener'
import { uuid } from './utils'

export const dealInputFields = [
  'deal_id', // optional. we've been using it for logging
  'status', // we use deal status to decide if we need to consider prepayments when calculating interest
  'deal_type', // we use deal type to decide if we need to calculate brokerage financials
  'brokerage',
  'supplier_id',
  'buyer_id',
  'collection_date',
  'supplier_anticipated_liability',
  'attributes.supplier_payment_terms',
  'attributes.buyer_payment_terms',
  'attributes.fx_rates',
  'attributes.fx_rates_timestamp',
]

export const productsInputFields = [
  'product_id',
  'supplier.price',
  'supplier.currency_code',
  'supplier.weight',
  'supplier.measure_id',
  'supplier.measure',
  'supplier.actual_price',
  'supplier.actual_weight',
  'buyer.price',
  'buyer.currency_code',
  'buyer.weight',
  'buyer.measure_id',
  'buyer.measure',
  'buyer.actual_price',
  'buyer.actual_weight',
]

const costInputFields = [
  // cost_id might be undefined, for cost identification we rely on costs order
  // output costs order MUST be the same as input costs order
  'cost_id',
  'type',
  'provider',
  'status',
  'service',
  'product_id',
  'amount.total',
  'amount.cost_unit',
  'amount.total_cad',
  'amount.currency',
  'attributes',
]

/**
 * Deal Calculator service
 *
 *    * prepare input for /deals/calculate-margin
 *    * parsing output
 *    * update Deal
 *
 * @export
 * @returns
 */
@Injectable()
export class DealCalculatorService {
  constructor (
    private DealApi: DealApiService,
    private FxRates: FxRatesService,
    private DealFinancialsListener: DealFinancialsListenerService,
  ) { }

  // state
  readonly running: Dictionary<Promise<any>> = {} // promise
  readonly scheduled = {}// input data for scheduled calculation


  /**
   * Calculate Deal
   *
   * @param {any} deal - dealView from UI
   * @param {any} { measures, products } - objects from Products API (hash by id)
   */
  async doCalculations(deal: DealView, { measures, products }: { measures: Dictionary<Measure>, products: Dictionary<Product> }) {
    // use fresh fx_rates deal is not yet confirmed
    await this.updateFxRates(deal)
    // make sure deal.products are in sync with primary costs
    await this.syncPrimaryCosts(deal, { products })

    forEach(deal.products, ({supplier = {}, buyer = {}}: any) => {
      supplier.measure = pick(measures[supplier.measure_id], ['measure_id', 'conversions'])
      buyer.measure = pick(measures[buyer.measure_id], ['measure_id', 'conversions'])
    })

    // NOTE: in order to match input & output costs we we introduce temporary ID.
    //       it should not be stored
    const productsSnapshot = [...deal.products]
    deal.costs.forEach((cost) => {
      cost.ID = cost.ID || uuid()
    })
    const { data: res } = await this.scheduleCalculations({
      // the deal itself
      deal: pick(deal, dealInputFields),
      // aggregated offers (supplier) and bids (buyer)
      bids_and_offers: map(deal.products, product => pick(product, productsInputFields)),
      // array with all costs associated with the deal (we rely on costs order)
      costs: map(deal.costs, cost => pick(cost, ['ID', ...costInputFields])),
      invoices: map(deal.invoices),
    })

    // compare deals to determine if deal financials are changed
    this.DealFinancialsListener.tryToRecordChange(deal, res.deal)

    // merge deal updates back into `deal` view
    merge(deal, omit(res.deal, dealInputFields))
    // we expect no new or missing offers/bids in response. we rely on array elements order
    merge(productsSnapshot, map(res.bids_and_offers, bo => omit(bo, productsInputFields)))
    // we expect no new or missing costs in response. we rely on array elements order
    // NOTE: can't use omit because of attributes and "amount.total", which might be changed on BE
    deal.costs.forEach((cost) => {
      const resCost = find(res.costs, { ID: cost.ID })
      if (!resCost) console.warn(`can't find output cost for ${cost.ID}/${cost.cost_id}`)
      merge(cost, resCost)
    })

    // NOTE: lodash.merge doesn't override array fields
    //      lodash.merge({a: [0]}, {a: []}) ==>> {a:[0]}
    if (deal.attributes.actual) {
      deal.attributes.actual.unweighted = get(res.deal, 'attributes.actual.unweighted')
      deal.attributes.actual.partial_unweighted = get(res.deal, 'attributes.actual.partial_unweighted')
    }
    if (deal.attributes.estimated) {
      deal.attributes.estimated.unweighted = get(res.deal, 'attributes.estimated.unweighted')
    }
  }

  /**
   * Use fresh fx_rates deal is not yet confirmed
   *
   * @private
   * @param {any} deal
   */
  async updateFxRates(deal: DealView) {
    if (!deal.attributes.fx_rates || !deal.isSubmitted()) {
      deal.attributes.fx_rates = await this.FxRates.getFxRates()
      deal.attributes.fx_rates_timestamp = dayjs().unix()
    }
  }

  /**
   * Create new, update existing, remove irrelevant primary costs
   *
   * @private
   * @param {any} deal
   */
  private syncPrimaryCosts(deal: DealView, { products }: { products: Dictionary<Product>}) {
    // handle primary costs
    deal.products = deal.products || []
    // delete irrelevant costs
    remove(deal.costs, ({type, product_id}) =>
      type === 'primary' && !find(deal.products, { product_id }))

    // create or update other primary costs
    // tslint:disable-next-line: cyclomatic-complexity
    deal.products.forEach((dealProduct, product_index) => {
      const {product_id, supplier, buyer, bid_id, offer_id} = dealProduct
      // ignore invalid product entries
      if (!product_id || !supplier || !buyer) return
      // find existing primary cost
      let primaryCost = getCostByProduct(deal, dealProduct)
      if (!primaryCost) { // create new if there is no primary cost
        primaryCost = {
          type: 'primary',
          status: 'pending',
          attributes: { product_index, bid_id, offer_id }, // this field is supposed to be constant
        } as Cost
        deal.costs.push(primaryCost)
      }
      // NOTE: copy actual numbers to deal.products[] (this is UI only operation)
      supplier.actual_weight = partial(supplier.actual_weight, primaryCost.attributes.actual_buy_weight)
      buyer.actual_weight = partial(buyer.actual_weight, primaryCost.attributes.actual_sell_weight)
      // NOTE: by default actual price = estimated price (this might update primary cost)
      supplier.actual_price = supplier.actual_price ||
                              primaryCost.attributes.actual_buy_price ||
                              supplier.price
      buyer.actual_price = buyer.actual_price ||
                           primaryCost.attributes.actual_sell_price ||
                           buyer.price

      // update primary costs
      merge(primaryCost, {
        service: products[product_id].name,
        product_id,
        provider: deal.supplier_id,
        amount: {
          total: supplier.price * supplier.weight,
          currency: supplier.currency_code,
        },
        attributes: {
          buy_weight: supplier.weight,
          buy_price: supplier.price,
          actual_buy_weight: supplier.actual_weight,
          actual_buy_price: supplier.actual_price,
          sell_weight: buyer.weight,
          sell_price: buyer.price,
          actual_sell_weight: buyer.actual_weight,
          actual_sell_price: buyer.actual_price,
          actual_date: dayjs().unix(), // UNUSED
        },
      })
    })
  }

  /**
   * Schedule calculations API request. Don't allow parallel calculations
   *
   * @private kinda. used in dealformcalculator service
   * @param {any} input
   * @returns {Promise}
   */
  scheduleCalculations(input) {
    if (this.running[input.deal.deal_id]) {
      this.scheduled[input.deal.deal_id] = input
    } else {
      this.scheduled[input.deal.deal_id] = false
      this.running[input.deal.deal_id] = this.DealApi.calculateMargin(input)
      .catch((err) => {
        this.running[input.deal.deal_id] = undefined
        if (!this.scheduled[input.deal.deal_id]) throw err // EXIT
        return this.scheduleCalculations(input)
      })
      .then((res) => {
        this.running[input.deal.deal_id] = undefined
        if (!this.scheduled[input.deal.deal_id]) return res // EXIT
        return this.scheduleCalculations(this.scheduled[input.deal.deal_id])
      })
    }

    return this.running[input.deal.deal_id]
  }
}
