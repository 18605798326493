import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Carrier } from '@tradecafe/types/core'
import { Subject } from 'rxjs'

export interface CarrierPickerOptions {
  carriers: Pick<Carrier, 'carrier_id'|'name'|'account'>[]
}

@Component({
  selector: 'tc-carrier-picker',
  templateUrl: './carrier-picker.component.html',
  styleUrls: ['./carrier-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierPickerDialogComponent {
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CarrierPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: CarrierPickerOptions,
  ) {}

  readonly inProgress$ = new Subject<number>()
  readonly carriers = this.dialogData.carriers

  readonly form = this.fb.group({
    carrierIds: [[], Validators.required],
  })

  async save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return
    const carrierIds: string[] = this.form.value.carrierIds
    this.dialogRef.close(carrierIds)
  }

  close() {
    this.dialogRef.close()
  }
}
