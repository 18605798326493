import { Injectable } from '@angular/core'
import { AuthApiService } from 'src/api/auth'

/**
 * Deal View Permissions service defines who can edit which deal fields
 *
 * @export
 * @returns
 */
@Injectable()
export class MatchedOfferPermissionsService {
  constructor(
    private AuthApi: AuthApiService,
  ) {}

  canEdit(fieldName: string) {
    const { role } = this.AuthApi.currentUser

    switch (fieldName) {
      // shared bid & offer fields
      case 'offer.product':
      case 'bid.product':
      case 'offer.wrapping':
      case 'bid.wrapping':
      case 'offer.packing.package_id':
      case 'bid.packing.type':
      case 'offer.quantity':
      case 'bid.quantity':
      case 'offer.packing.packages_count': // = quantity
      case 'bid.packing.unit': // = quantity
      case 'offer.packing.package_size':
      case 'bid.attributes.package_size':
      case 'offer.packing.package_measure_id':
      case 'bid.attributes.package_measure_id':
      case 'offer.attributes.item_type_id':
      case 'bid.attributes.item_type_id':
      case 'offer.attributes.weight_type_id':
      case 'bid.attributes.weight_type_id':
      case 'offer.attributes.additional_specs':
      case 'bid.attributes.additional_specs':
      case 'offer.attributes.hs_code': // generated = fn(buyer, product)
      case 'bid.attributes.hs_code': // generated = fn(buyer, product)
      case 'offer.attributes.brand':
      case 'bid.attributes.brand':
      case 'offer.attributes.product_code':
      case 'bid.attributes.product_code':
      case 'bid.attributes.incoterm_location':
        return role !== 'trader' // traders can not edit supplier side

      // offer uniq fields
      case 'offer.attributes.supplier_ref':
      case 'offer.country':
      case 'offer.attributes.supplier_user_ids':
      case 'offer.attributes.trader_user_id_supplier':
      case 'offer.ship_start':
      case 'offer.attributes.establishments':
      case 'offer.incoterm':
      case 'offer.attributes.incoterm_location':
      case 'offer.weight.amount':
      case 'offer.weight.unit':
      case 'offer.price':
      case 'offer.currency':
        return role !== 'trader' // traders can not edit supplier side

      // bid uniq fields
      case 'bid.attributes.buyer_ref':
      case 'bid.attributes.docs_country':
      case 'bid.attributes.logistics_user_id':
      case 'bid.attributes.buyer_user_ids':
      case 'bid.attributes.trader_user_id':
      case 'bid.ship_start':
      case 'bid.attributes.invoice_address':
      case 'bid.incoterm':
      case 'bid.weight.amount':
      case 'bid.weight.unit':
      case 'bid.price':
      case 'bid.currency':
        return true

      default:
        console.warn(`UNKNOWN fieldName=${fieldName}`)
        throw new Error('Not implemented yet.')
    }
  }
}
