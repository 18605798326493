import { Injectable } from '@angular/core'
import { ACCOUNT_ACTIVE, AccountObject, BUYER, LocationAddress, SERVICE_PROVIDER, SUPPLIER, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, compact, filter, find, flatten, get, keyBy, pick, pickBy } from 'lodash-es'
import { from } from 'rxjs'
import { AccountApiService } from 'src/api/account'
import { AuthApiService } from 'src/api/auth'
import { environment } from 'src/environments/environment'
import { GeoService } from 'src/pages/admin/settings/locations/location-form/geo.service'
import { shortCode } from 'src/pages/admin/settings/templates/form/locales'
import { getBwiManagers } from './users.service'
import { mergeDeep } from './utils'

const { tradecafeAccount } = environment

export const ALLOWED_FIELDS = ['name', 'since', 'parent', 'primaryemail', 'primaryphone', 'addresses', 'website', 'type',
  'archived', 'attributes', 'products', 'currency', 'language', 'manager', 'managers', 'coordinator', 'coordinators', 'banking', 'status',
  'products_spec', 'creditinfo', 'preferred_carriers', 'autocomplete_2fa', 'consignees', 'distribution_lists', 'coordinator_reassignments', 'permissions']

export function isBwiInventory(companyOrAccountId: DeepReadonly<AccountObject> | string | number) {
  const id = parseFloat(get(companyOrAccountId, 'account', companyOrAccountId) as string)
  return environment.bwiInventoryAccounts.includes(id)
}

export function isCompanyVisible(company: DeepReadonly<AccountObject>) {
  return company.status === ACCOUNT_ACTIVE && !company.archived
}

export function getPartyUsers(users: Dictionary<DeepReadonly<User>>, account: string | number): DeepReadonly<User[]> {
  return isBwiInventory(account)
      ? getBwiManagers(users)
      : filter(users, { account: parseFloat(account as string) })
}

export function getPartyUsersMO(users: Dictionary<DeepReadonly<User>>, account: string | number): DeepReadonly<User[]> {
  return isBwiInventory(account)
      ? getBwiManagers(users)
      : filter(users, { account: parseFloat(account as string) }).filter(user =>
          user.role === 'administrator' || user.attributes.designated === '1')
}

export const sortForHaveTestAccountsAfterReal = (accounts: DeepReadonly<AccountObject>[]) => {
  const KEYWORD = 'test'

  return accounts.sort(
    (a, b) => {
      const nameA = a.name.toLocaleLowerCase()
      const nameB = b.name.toLocaleLowerCase()

      if (nameA.includes(KEYWORD) && nameB.includes(KEYWORD)) {
        return nameA > nameB ? 1 : -1
      } else if (!nameA.includes(KEYWORD) && !nameB.includes(KEYWORD)) {
        return nameA > nameB ? 1 : -1
      } else {
        return nameA.includes(KEYWORD) ? 1 : -1
      }
    },
)}

const CACHE_MAX_AGE = 3600 * 1000 // 1hr

export interface getAccountsOptions {
  includeArchived?: boolean
  force?: boolean
}

/**
 * Accounts service
 *
 * Provides creation, retrieval, and updating of Accounts data via Accounts API
 *
 * @export
 * @returns
 */
@Injectable()
export class AccountsService {
  constructor(
    private AuthApi: AuthApiService,
    private AccountApi: AccountApiService,
    private Geo: GeoService,
  ) {}

  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] as AccountObject[] })

  isBwiInventory = isBwiInventory
  /** @deprecated use isCompanyVisible */
  isCompanyVisible = isCompanyVisible

  /**
   * Get accounts by ids
   * Retrieve account data in an object keyed by account IDs, filtered by provided list of
   * values. Returns all accounts if no ids are specified.
   *
   * @param {Array<string|number> | null} ids The list of account IDs to retrieve account data for
   * @param {getAccountsOptions} options A set of options used when retrieving data
   * @returns {{[accountID: number|string]: AccountObject}} An object with account data, keyed by account ID
   */
  async getAccountsByIds(ids?: Array<string|number> | null, options : getAccountsOptions = { includeArchived: true }) {
    const { data } = await this.getAccounts(options)
    const index = keyBy(data, 'account')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get account by ID
   *
   * @param {number|string} accountId The ID of the account to retrieve
   * @param {getAccountsOptions} options A set of options used when retrieving data
   * @returns {AccountObject}
   */
  async getAccountById(accountId: string | number, options : getAccountsOptions = { includeArchived: true } ) {
    const res = await this.getAccountsByIds([accountId], options)
    return res[accountId]
  }

  /**
   * Retrieve (and possibly cache) all account data from Accounts API
   *
   * @private
   * @param {Boolean} force Whether to force a cache refresh (if true) or allow the existing cache
   * to handle the request if not expired (if false). Default: false
   * @returns {Object} { total_rows:number, data: []}
   */
  private async retrieveAccountData(force = false) {
    if (force || !this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE
      // NOTE: we fetch and store everything, then filter after that (so future queries against cached data are more accurate)
      this.cache = this.AccountApi.listAll({ ...{limit: Number.MAX_SAFE_INTEGER, filters: { archived: 1 }}}).then((r) => {
        this.fixAddresses(r.data)
        fixLanguage(r.data)
        return r
      })
    }
    const { data } = await this.cache
    return { data }
  }

  async getSimpleAccounts(fields: string[]) {
    const { data } = await this.AccountApi.listAll({ limit: Number.MAX_SAFE_INTEGER, fields })
    return { data }
  }

  /**
   * Get all available account data. May include archived account information, if specified
   *
   * @param {getAccountsOptions} options An object containing 2 possible values:
   * * force: Bypasses the cache and forces a refresh of account data from the Accounts API, default: false
   * * includeArchived: Whether the resulting list of accounts should include archived account data, default: false
   * @returns {{data: AccountObject[]}}
   */
  async getAccounts(options : getAccountsOptions = { includeArchived: true }) {
    const { data: accountData } = await this.retrieveAccountData(options?.force || false);

    if (options?.includeArchived) {
      return { data: accountData };
    }

    const filteredData = filter( accountData, (item) => !item.archived);
    return { data: filteredData };
  }

  async getBuyers(includeArchived = true) {
    // const { data } = AccountApi.listBuyers({ limit: Number.MAX_SAFE_INTEGER })
    const { data } = await this.getAccounts({ includeArchived })
    return filter(data, { type: BUYER })
  }

  async getSuppliers(includeArchived = true) {
    // const { data } = AccountApi.listSuppliers({ limit: Number.MAX_SAFE_INTEGER })
    const { data } = await this.getAccounts({ includeArchived })
    return filter(data, { type: SUPPLIER })
  }

  async getServiceProviders(includeArchived = true) {
    const { data } = await this.getAccounts({ includeArchived })
    return filter(data, { type: SERVICE_PROVIDER })
  }

  /**
   * Send company to the back end, update input object with new account id if needed
   *
   * @private
   * @param {any} id
   * @param {any} payload
   * @returns
   */
  async saveAccount(company) {
    const payload = pickBy(company, (v, k) => {
      return (ALLOWED_FIELDS.indexOf(k) > -1 && v !== null && v !== undefined)
    })

    // modified by
    const {user_id} = this.AuthApi.currentUser
    const {attributes = {}} = payload
    attributes.user_id = user_id

    const stored = await (company.account
      ? this.AccountApi.update(company.account, payload).then(res => res.data)
      : this.AccountApi.create(payload).then(res => res.data))
    company.account = company.account || stored.account // NOTE: we rely on this in Company Form
    this.cache = null // invalidate cache
    return stored
  }

  async patchAccountImmutable(company, payload) {
    return this.patchAccount(cloneDeep(company), payload)
  }

  async patchAccount(company, payload) {
    payload = pickBy(payload, (v, k) => {
      return (ALLOWED_FIELDS.indexOf(k) > -1 && v !== null && v !== undefined)
    })
    mergeDeep(company, payload)
    if (payload.products) company.products = payload.products // fix merge
    if (payload.products_spec) company.products_spec = payload.products_spec // fix merge
    return this.AccountApi.update(company.account, payload).then((res) => {
      this.cache = null // invalidate cache
      return res.data
    })
  }

  async getAccountProducts(accountId) {
    const [data] = await this.getAccountsProducts([accountId])
    return data
  }

  async getAccountsProducts(accountIds) {
    const data = await this.AccountApi.getByIds(accountIds)
    fixProducts(data)
    fixLanguage(data)
    return data
  }

  async getAccountsByProduct(product) {
    const { data } = await this.AccountApi.listAll({ product, limit: Number.MAX_SAFE_INTEGER })
    fixProducts(data)
    fixLanguage(data)
    return data
  }

  async updateAccountProducts(company, products_spec) {
    const { data } = await this.AccountApi.updateAccountProducts(company.account, products_spec)
    return data
  }

  reassignAccounts(
    accounts: DeepReadonly<Dictionary<AccountObject>>,
    coordinator_reassignments: DeepReadonly<AccountObject['coordinator_reassignments']>,
  ) {
    const tradecafe = accounts[tradecafeAccount]
    const unassigned = tradecafe.coordinator_reassignments?.filter(r =>
      !coordinator_reassignments.some(r2 => r.account === r2.account)) || []
    const assigned = coordinator_reassignments.filter(r =>
      !tradecafe.coordinator_reassignments?.some(r2 => r.account === r2.account))
    const changedAssignee = compact(flatten(tradecafe.coordinator_reassignments?.map(r1 =>
      coordinator_reassignments.map(r2 =>
        r1.account === r2.account && r1.prev === r2.prev && r1.next !== r2.next && [r1, r2]))))

    return from(Promise.all([
      ...unassigned.map(r => this.patchAccountImmutable(accounts[r.account], r.primary
        ? { coordinator: r.prev }
        : { coordinators: accounts[r.account].coordinators.map(c => c === r.next ? r.prev : c)})),
      ...assigned.map(r => this.patchAccountImmutable(accounts[r.account], r.primary
        ? { coordinator: r.next }
        : { coordinators: accounts[r.account].coordinators.map(c => c === r.prev ? r.next : c) })),
      ...changedAssignee.map(([r1, r2]) => this.patchAccountImmutable(accounts[r1.account], r1.primary
        ? { coordinator: r2.next }
        : { coordinators: accounts[r1.account].coordinators.map(c => c === r1.next ? r2.next : c) })),
      ]).then(accounts =>
        this.patchAccountImmutable(tradecafe, { coordinator_reassignments }).then(tc => [tc, ...accounts])))
  }

  private fixAddresses(accounts) {
    accounts.forEach(account => account.addresses.forEach((address) => {
      this.fixAddress(address)
      this.warnAddress(account, address)
    }))
  }

  private fixAddress(address) {
    const country = this.getCountry(address)
    if (country) { // fix available
      if (address.country !== country.name || address.cc !== country.code) {
        // console.warn(`${address.country} (${address.cc}) => ${country.name} (${country.code})`)
        address.country = country.name
        address.cc = country.code
      }
    }
    const state = this.getState(address)
    if (state) { // fix available
      if (address.state !== state.name || address.state_code !== state.shortCode) {
        // console.warn(`${address.state} (${address.state_code}) => ${state.name} (${state.shortCode})`)
        address.state = state.name
        address.state_code = state.shortCode
      }
    }
  }

  private warnAddress(account, address) {
    const states = this.Geo.getStates(address.cc)
    if (!address.state || !states.length) return
    if (address.state === states[0].name) {
      // console.warn(`WA-2130: account ${account.name} address ${address.name} state is set to ${states[0].name}`)
    }
  }

  /**
   * Find a country (@see `Geo` service).
   *
   * @param {*} address
   * @returns {Object} registered country { name, code } | undefined
   */
  private getCountry(address: LocationAddress) {
    if (!address.country && !address.cc) {
      return undefined // legit. country might be undefined
    }                  // otherwise both country && cc should be defined

    const knownCountries = this.Geo.getCountries() // TODO: use countries API

    const byCode = knownCountries[address.cc]
    if (byCode) {
      return byCode // this is the only legit way to get country.
                    // everything below is a hack
    }

    // lookup by name (case insensitive)
    const byName = filter(knownCountries, known =>
      known.name.toLowerCase() === (address.country || '').toLowerCase())
    const [country] = byName

    if (byName.length === 1) {
      return country
    }

    if (!country) {
      if (knownCountries.US && address.country === 'United States of America') {
        return knownCountries.US
      }
      // console.warn(`WA-2130: unknown country ${address.country}(${address.cc})`)
      // console.debug(`WA-2130: unknown country ${address.country}(${address.cc})`, address)
    } else {
      // console.warn(`WA-2130: conflicting country ${address.country}(${address.cc})`)
      // console.debug(`WA-2130: conflicting country ${address.country}(${address.cc})`, address, byName)
    }
    return undefined
  }

  /**
   * Find a state (@see `Geo` service).
   *
   * @param {*} address
   * @returns {Object} registered country { name, code } | undefined
   */
  // tslint:disable-next-line: cyclomatic-complexity
  private getState(address) {
    if (!address.state && !address.state_code) {
      return undefined// nothing to worry about
    }

    const knownStates = this.Geo.getStates(address.cc)
    if (!knownStates.length && !address.state_code) {
      // console.warn(`WA-2130: unregistered state ${address.state}(${address.state_code}) in ${address.country}(${address.cc})`)
      return undefined
    }

    if (address.state_code) {
      const byCode = find(knownStates, {shortCode: address.state_code})
      if (byCode) {
        return byCode // everything is fine
      }
      // console.warn(`WA-2130: unknown state_code ${address.state_code} in ${address.country}(${address.cc})`)
      return undefined
    }

    // if (address.state) {  - always true here
    const byNameOrCode = filter(knownStates, known =>
      address.state.toLowerCase() === (known.shortCode || '').toLowerCase() ||
      address.state.toLowerCase() === known.name.toLowerCase())

    if (byNameOrCode.length === 1) {
      const [state] = byNameOrCode
      // if (state.shortCode) {
      //   console.info(`fix state ${address.state}(${address.state_code}) => ${state.name}(${state.shortCode})`)
      // }
      return state
    }
    if (!byNameOrCode.length) {
      if (address.state === 'San Pedro Sul') return undefined // ??
      const CAT = find(knownStates, {name: 'Cat'})
      if (CAT && address.state === 'Catano') return undefined // ??

      const FED = find(knownStates, {name: 'Distrito Federal'})
      if (FED || address.state === 'Distrito Federal') return undefined // ??

      const DC = find(knownStates, {name: 'District of Columbia'})
      if (DC || address.state === 'District Columbia') return DC

      const MEX = find(knownStates, {name: 'Estado de México'})
      if (MEX && address.state === 'Edo. De Mexico') return MEX
      const YUC = find(knownStates, {name: 'Yucatán'})
      if (YUC && address.state === 'Yucatan') return YUC
      const COA = find(knownStates, {name: 'Coahuila de Zaragoza'}) ||
                  find(knownStates, {name: 'Coahuila'})
      if (COA) COA.name = 'Coahuila'
      if (COA && address.state === 'Coahuila') return COA
      if (COA && address.state === 'Coahuilla') return COA
      const NLE = find(knownStates, {name: 'Nuevo León'})
      if (NLE && address.state === 'Nuevo Leon') return undefined // ??

      // console.warn(`WA-2130: unknown state ${address.state}(${address.state_code}) in ${address.country}(${address.cc})`)
    } else {
      // console.warn(`WA-2130: conflicting state ${address.state}(${address.state_code}) in ${address.country}(${address.cc})`)
    }
    return undefined
  }
}

function fixProducts(accounts: AccountObject[]) {
  accounts.forEach((account) => { account.products_spec = account.products_spec || [] })
}

function fixLanguage(accounts: AccountObject[]) {
  accounts.forEach((account) => { account.language = account.language ? shortCode(account.language) : account.language })
}
