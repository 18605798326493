import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Country } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment

export default module =>
  module.service('CountryApi', downgradeInjectable(CountryApiService))


@Injectable()
@AngularCopy()
export class CountryApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: Country[] }>(`${apiUrl}/countries`, { params }).toPromise()
  }

  create(data: Partial<Country>) {
    return this.http.post<{ data: Country }>(`${apiUrl}/countries`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: Country }>(`${apiUrl}/countries/${id}`).toPromise()
  }

  update(id: string, data: Partial<Country>) {
    return this.http.put<{ data: Country }>(`${apiUrl}/countries/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: Country }>(`${apiUrl}/countries/${id}`).toPromise()
  }
}
