import { Injectable } from '@angular/core'
import { WeightType } from '@tradecafe/types/core'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { WeightTypeApiService } from 'src/api/product/weight-type'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['name']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr

@Injectable()
export class WeightTypesService {
  constructor(
    private AuthApi: AuthApiService,
    private WeightTypeApi: WeightTypeApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) {}

  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  weightTypeChanged$ = new Subject<void>()

  /**
   * Get all available weightTypes
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getWeightTypes(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.WeightTypeApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (weightTypes) => {
        const { account } = this.AuthApi.currentUser
        const userIds = uniq(compact(map(weightTypes.data, 'user_id')))

        const users = await this.Users.getUsersByIds(account, userIds)

        weightTypes.data.forEach((weightType: any) => {
          weightType.user = users[weightType.user_id]
        })
        return weightTypes
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get weightTypes by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=weightType)
   */
  async getWeightTypesByIds(ids?: string[]) {
    const { data } = await this.getWeightTypes()
    const index = keyBy(data, 'type_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get possible values for given weightType field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName) {
    const { data } = await this.getWeightTypes()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new weightType
   *
   * @param {any} weightType
   * @returns
   */
  async create(weightType: Partial<WeightType>) {
    const payload = pick(weightType, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.WeightTypeApi.create(payload)
    this.invalidateCache()
    this.weightTypeChanged$.next()
    return data
  }

  /**
   * Update weightType
   *
   * @param {any} weightType
   * @returns
   */
  async update(weightType: Partial<WeightType>) {
    const { type_id } = weightType
    const payload = pick(weightType, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.WeightTypeApi.update(type_id, payload)
    this.invalidateCache()
    this.weightTypeChanged$.next()
    return data
  }

  /**
   * Update weightType
   *
   * @param {any} weightType
   * @returns
   */
  async remove(weightType: WeightType) {
    const { data } = await this.WeightTypeApi.delete(weightType.type_id)
    this.invalidateCache()
    this.weightTypeChanged$.next()
    return data
  }


  /**
   * Invalidate weightTypes in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }
}
