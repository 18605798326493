import { createAction, props } from '@ngrx/store'
import { TableKey, User } from '@tradecafe/types/core'


export const loadUsers = createAction(
  '[User/API] Load Users',
  props<{
    tableKey?: TableKey,
    account?: string | number,
    archived?: number,
  }>(),
)
export const loadUsersSuccess = createAction(
  '[User/API] Load Users Success',
  props<{
    tableKey?: TableKey,
    users: User[],
  }>(),
)
export const loadUsersFailure = createAction(
  '[User/API] Load Users Failure',
  props<{ error: Error }>(),
)
