import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { ProductPackaging } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as PackageTypeActions from './package-type.actions'

export const packageTypesFeatureKey = 'packageTypes'
export interface PackageTypesState extends EntityState<DeepReadonly<ProductPackaging>> {
}

export const selectId = (packageType: ProductPackaging) => packageType.package_id
export const adapter = createEntityAdapter<DeepReadonly<ProductPackaging>>({ selectId })

const initialState: PackageTypesState = adapter.getInitialState({
})

export const packageTypesReducer = createReducer(
  initialState,
  on(PackageTypeActions.loadPackageTypesSuccess,
    (state, action) =>
      adapter.upsertMany(action.packageTypes, state)),
)

