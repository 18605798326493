import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { DealViewRawDeal, DealViewRawStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { autosaveDealSegment } from 'src/app/store/deal-view.actions'
import { DealFormGroup, SegmentFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'


@Injectable()
export class SegmentFormRemoveService {
  constructor(private store: Store) { }

  async removeSegmentForm(
    dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>,
    dealForm: DealFormGroup,
    segmentForm: SegmentFormGroup,
  ) {
    const segmentsForm = dealForm.controls.segments
    const index = segmentsForm.controls.findIndex(s => s === segmentForm)
    const { segment } = segmentsForm.at(index).value
    segmentsForm.removeAt(index)
    if (segment?.segment_id) {
      this.store.dispatch(autosaveDealSegment({ dv, dealForm: dealForm.serialize() }))
    }
  }
}
