import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { DealDetailsFormGroup } from '../../deal-form.schema'

@Component({
  selector: 'tc-deal-totals-v2',
  templateUrl: './deal-totals.component.html',
  styleUrls: ['./deal-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealTotalsComponent {
  @Input() mode?: 'finance' | 'products' // we hide some content, when mode is specified
  @Input() dealDetails: DealDetailsFormGroup
}
