import { Injectable } from '@angular/core'
import { Cost, DEAL_DRAFT, Deal, DealPaymentTerms, MatchedOffer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, find, merge, omit, pick } from 'lodash-es'
import { DealApiService } from 'src/api/deal'
import { dealInputFields, productsInputFields } from 'src/services/data/deal-calculator.service'
import { mergeDeep, uuid } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { MeasuresService } from '../../settings/product-specifications/measures/measures.service'
import { ProductsService } from '../../settings/products-services/products.service'
import { CostsFormGroup } from '../../trading/deal-form/deal-form-page/deal-form.schema'
import { readCostForm } from '../../trading/deal-form/deal-form-page/deal-form.service'
import { buildDealCostForm, prepareDealCostPatch } from '../../trading/deal-form/deal-form-page/deal-form.service-factory'
import { MatchedOfferFormGroup, readMatchedOfferForm } from './matched-offer-form.service'


@Injectable()
export class FinanceService {
  constructor(
    private Measures: MeasuresService,
    private DealApi: DealApiService,
    private Products: ProductsService,
  ) {}

  /**
   * Calculate Term Date
   *
   * @param {number} created - deal date, offer created date, etc.
   * @param {number} shipmentDate - end date from the shipping dates range
   * @param {number} deliveryDate - end date from the delivery dates range
   * @param {object} paymentTerms - party payment terms `{days: number, from_date: enum-string}`
   * @returns epoch date or NaN if input date is undefined
   * @throws {Error} when `paymentTerms.from_date` is unknown
   */
  getTermDate({ created, shipmentDate, deliveryDate, paymentTerms }) {
    const epochDay = 86400
    switch (paymentTerms.from_date) {
      case 'bill_of_landing_date':
      // Billing of Lading Date == 5 days + the last date of Shipment date on Deal page
        return shipmentDate + 5 * epochDay // will be NaN if `.to` is undefined
      case 'days_from_pickup_date':
        // Days from Pickup Date == the last date of Shipment date on Deal page
        return shipmentDate
      case 'deal_date_date':
        // Deal Date == the date of deal creation.
        return created
      case 'delivery_date':
        // Delivery Date == the last date in the Delivery date range on Deal page
        return deliveryDate
      case 'ship_date':
          // Shipment Date == the last date in the Shipment date range on Deal page
          return shipmentDate
      case 'invoice_date':
        // Invoice Date == the last date of Shipment date on Deal page
        return shipmentDate
      case 'vessel_arrival_date':
        // Vessel Arrival Date == the last date in Delivery date range on Deal page
        return deliveryDate
      default:
        throw new Error(`Unknown payment_term.from_date value "${paymentTerms.from_date}"`)
    }
  }

  /**
   * Calculate liability(?) date, terms date + number of days (from payment terms)
   *
   * @param {*} termDate
   * @param {*} paymentTerms
   * @returns
   */
  getEndDate(termDate, paymentTerms) {
    const epochDay = 86400
    const {days} = paymentTerms
    return termDate + days * epochDay
  }

  /**
   * Calculate term dates
   *
   * @param {*} args - @see getTermDate arguments
   * @returns {termDate, endDate} term dates pair (term date + liability date)
   */
  getTermDates(args) {
    const epochDay = 86400
    const {days} = args.paymentTerms
    const termDate = this.getTermDate(args)
    return {
      termDate,
      endDate: termDate + days * epochDay,
    }
  }

  /**
   * Check if given deal field can update given payment term date
   *
   * @param {any} dealView
   * @param {string} dealField deal field name
   * @param {string} from_date paymentTerms.from_date
   * @returns
   */
  isFinanceDate(dateName: string, paymentTerms: DealPaymentTerms) {
    if (!paymentTerms.from_date) return false

    switch (dateName) {
      case 'created':
        return paymentTerms.from_date === 'deal_date_date'
      case 'shipmentDate':
        return ['bill_of_landing_date', 'days_from_pickup_date', 'ship_date', 'invoice_date'].includes(paymentTerms.from_date)
      case 'deliveryDate':
        return ['delivery_date', 'vessel_arrival_date'].includes(paymentTerms.from_date)
      default:
        throw new Error(`Unknown deal field name "${dateName}"`)
    }
  }

  async calculateMatchedOfferImmutable(matchedOffer: DeepReadonly<MatchedOffer>) {
    // NOTE: fx_rates must be in place
    // NOTE: emulate primary costs?
    let valid = true
    const required = (value) => { valid = !!value; return value }
    const [measures, products] = await Promise.all([
      this.Measures.getMeasuresByIds(),
      this.Products.getByIds(),
    ])

    const costs = cloneDeep(matchedOffer.costs) as Partial<Cost>[]
    if (!find(costs, { type: 'primary' })) {
      const primaryCost: Partial<Cost> = {
        type: 'primary',
        status: 'pending',
        service: products[matchedOffer.offer.product].name,
        product_id: matchedOffer.offer.product,
        provider: matchedOffer.offer.account?.toString(),
        amount: {
          total: matchedOffer.offer.price * matchedOffer.offer.weight.amount,
          currency: matchedOffer.offer.currency,
        },
        attributes: {
          product_index: 0,
          buy_weight: matchedOffer.offer.weight.amount,
          buy_price: matchedOffer.offer.price,
          sell_weight: matchedOffer.bid.weight.amount,
          sell_price: matchedOffer.bid.price,
        },
      }

      costs.push(primaryCost)
    }

    const input = {
      deal: {
        supplier_id: required(matchedOffer.offer.account),
        buyer_id: required(matchedOffer.bid.account),
        collection_date: required(matchedOffer.attributes.collection_date),
        supplier_anticipated_liability: required(matchedOffer.attributes.supplier_liability_date),
        attributes: {
          fx_rates: { rates: required(matchedOffer.fx_rate) },
          fx_rates_timestamp: required(dayjs().unix()),
        },
        status: DEAL_DRAFT,
      },
      bids_and_offers: [{
        product_id: required(matchedOffer.offer.product),
        supplier: {
          price: required(matchedOffer.offer.price),
          currency_code: required(matchedOffer.offer.currency),
          weight: required(matchedOffer.offer.weight.amount),
          measure_id: required(matchedOffer.offer.weight.unit),
          measure: required(pick(measures[matchedOffer.offer.weight.unit], ['measure_id', 'conversions'])),
          // NOTE: required(we skip actual_price and actual_weigh)t
        },
        buyer: {
          price: required(matchedOffer.bid.price),
          currency_code: required(matchedOffer.bid.currency),
          weight: required(matchedOffer.bid.weight.amount),
          measure_id: required(matchedOffer.bid.weight.unit),
          measure: required(pick(measures[matchedOffer.bid.weight.unit], ['measure_id', 'conversions'])),
          // NOTE: we skip actual_price and actual_weight
        },
      }],
      costs: costs.map((cost) => {
        cost.ID = cost.ID || uuid() // temporary ID
        return {
          ID: cost.ID,
          type: required(cost.type),
          provider: required(cost.provider),
          service: required(cost.service),
          product_id: required(cost.product_id),
          amount: {
            total: required(cost.amount.total),
            currency: required(cost.amount.currency),
          },
          attributes: required(cost.attributes),
          // cost.attributes.actual_amount
          // cost.attributes.actual_buy_price
          // cost.attributes.actual_buy_weight
          // cost.attributes.actual_currency
          // cost.attributes.associated.amount
          // cost.attributes.associated.currency
          // cost.attributes.default_cost
          // cost.attributes.default_cost.type
          // cost.attributes.total_actual_cad
          // cost.attributes.total_cad
        }
      }),
    }

    if (!valid) {
      console.warn('dealfinancials INPUT IS INVALID!!!')
    }

    const { data: res } = await this.DealApi.calculateMargin(input)
    const output = {
      deal: omit(res.deal, dealInputFields) as Partial<Deal>,
      bids_and_offers: res.bids_and_offers.map(bo => omit(bo, productsInputFields)),
      // we expect no new or missing costs in response. we rely on array elements order
      // NOTE: can't `omit` because of "attributes" and "amount.total", which might be changed on BE
      costs: costs.map((cost) => {
        const resCost = find(res.costs, { ID: cost.ID })
        if (!resCost) console.warn(`can't find output cost for ${cost.ID}/${cost.cost_id}`)
        return merge(cost, resCost)
      }),
    }

    return output
  }

  async calculateMatchedOfferForm(
    matchedOffer: DeepReadonly<MatchedOffer>,
    moForm: MatchedOfferFormGroup,
    costsForm: CostsFormGroup,
  ) {
    // read form
    const mo = {
      ...mergeDeep(cloneDeep(matchedOffer), readMatchedOfferForm(moForm)),
      costs: costsForm.getRawValue().map(cf => readCostForm(cf))
    }
    // calculate financials
    const output = await this.calculateMatchedOfferImmutable(mo)
    // patch form
    moForm.patchValue({
      financeTerm: output.deal.attributes.finance_term,
      marginP: output.deal.attributes.estimated.margin_p,
      marginCad: output.deal.attributes.estimated.margin,
      revenueCad: output.deal.attributes.estimated.revenue,
    })
    costsForm.clear()
    output.costs.forEach(cost =>
      costsForm.push(buildDealCostForm(prepareDealCostPatch(cost))))
    return output
  }

  async calculateMatchedOffer(matchedOffer: MatchedOffer) {
    const output = await this.calculateMatchedOfferImmutable(matchedOffer)

    matchedOffer.costs = output.costs
    matchedOffer.margin = output.deal.attributes.estimated.margin_p
    matchedOffer.attributes.finance_term = output.deal.attributes.finance_term
    // matchedOffer.attributes.est_finance_term = output.deal.attributes.est_finance_term
    matchedOffer.attributes.margin_cad = output.deal.attributes.estimated.margin
    matchedOffer.attributes.revenue_cad = output.deal.attributes.estimated.revenue

    return output
  }
}
