<form [formGroup]="form" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    Select Carrier
  </h2>
  <div mat-dialog-content>
    <p>Choose which carrier you would like the email/document to be sent to?</p>
    <tc-select-search placeholder="Carrier" [multiple]="true"
        [group]="form" ctrlName="carrierIds"
        [items]="carriers"
        bindValue="carrier_id"
        bindLabel="name"
        ></tc-select-search>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">Send</button>
  </div>
</form>
