import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TransitionService } from '@uirouter/angularjs'
import * as $ from 'jquery'
import { pull } from 'lodash-es'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { moveToBackground } from './modal.service'

@Injectable({ providedIn: 'root' })
export class ModalStackService {
  private stack = []

  isEmpty() {
    return !this.stack.length
  }

  isAlreadyOpen(component) {
    return !!this.stack.find(s => s.component === component)
  }

  push(modal) {
    this.stack.push(modal)
    modal.closed.then(
      () => {
        this.stack = this.stack.filter((s) => s.component !== modal.component)
      }
    )
  }

  dismiss(reason) {
    this.stack.forEach(modal => modal.dismiss({$value: reason}))
  }
}

@Injectable({ providedIn: 'root' })
export class ModalProxyService implements IModalHelper {
  modalHelper: ModalHelper
  showModal(opts: any, ignoreStack = false) {
    return this.modalHelper.showModal(opts, ignoreStack)
  }
  hideLast() { return this.modalHelper.hideLast() }
  showConfirm(opts: any) { return this.modalHelper.showConfirm(opts) }
  showOverlay(component: any, opts: any) { return this.modalHelper.showOverlay(component, opts) }
}

export interface IModalHelper {
  showModal(opts: any)
  hideLast()
  showConfirm(opts: any)
  showOverlay(component: any, opts: any)
}
export class ModalHelper implements IModalHelper {
  static $inject = ['$uibModal', 'ConfirmModal', 'ModalStack', 'MatDialog', '$document']
  constructor(
    private $uibModal,
    private ConfirmModal: ConfirmModalService,
    private modalStack: ModalStackService,
    private matDialog: MatDialog,
    private $document: ng.IDocumentService,
  ) { }

  showModal(options, ignoreStack = false) {
    if (!this.modalStack.isAlreadyOpen(options.component) || ignoreStack) {
      const appendTo = this.$document.find('.cdk-overlay-container').eq(0)
      const modal = this.$uibModal.open(
        {
          appendTo: appendTo.length ? appendTo : undefined,
          ...options,
        }
      )
      this.modalStack.push(
        {
          ...modal,
          component: options.component
        }
      )
      return moveToBackground(
        this.matDialog,
        modal.result as Promise<any>,
        options?.resolve?.cssClass && options.resolve.cssClass()
      )
    } else {
      return null
    }
  }

  // TODO: deprecate "showConfirm" method; start using ConfirmModal service directly
  showConfirm(options, ignoreStack = false) {
    return this.ConfirmModal.show(options, ignoreStack)
  }

  showOverlay(component, options) {
    return this.showModal({
      component,
      windowTemplateUrl: 'closable-overlay-window.template.html',
      openedClass: 'tc-overlay-open modal-open',
      windowClass: 'right tc-modal-overlay',
      backdrop: 'static',
      ...options,
    })
  }

  // TODO: remove & fix WA-4045 using CSS
  // next method doesn't seem like a proper solution for the WA-4045.
  hideLast() {
    if ($('[uib-modal-window]').length < 2) {
      $('[uib-modal-window]').hide()
      $('[uib-modal-backdrop]').hide()
    } else {
      $('[uib-modal-backdrop]').css({'zIndex': parseInt($('[uib-modal-backdrop]').css('zIndex'), 10) - 10 })
      $('[uib-modal-window]').each(function( i, item ) {
        if (i === 0) {
          $(item).hide()
        }
      })
    }
  }
}

registerModalHook.$inject = ['$transitions', 'ModalStack']
export function registerModalHook(
  $transitions: TransitionService,
  ModalStack: ModalStackService,
) {
  'ngInject'

  $transitions.onStart({}, (transition) => {
    if (ModalStack.isEmpty()) {
      return Promise.resolve()
    }
    if (transition.to().name === 'auth.login') {
      ModalStack.dismiss('logout')
      return Promise.resolve()
    }

    ModalStack.dismiss('navigate-out')
    return Promise.resolve()

    // return ConfirmModal.show({
    //   title: 'You are you sure you want to leave this page?',
    //   titleIcon: 'fa-circle-question',
    //   // description: 'Form has unsaved data. Are you sure you want to leave this page?',
    //   confirmButtonText: 'Leave Page',
    //   confirmButtonClass: 'btn-danger',
    //   cancelButtonText: 'Stay on Page',
    //   cancelButtonClass: 'btn-link',
    // }).then(() => {
    //   modalStack.dismiss('navigate-out')
    // })
  })
}

initModalHelperProxy.$inject = ['ModalProxy', 'modalHelper']
export function initModalHelperProxy(modalProxy: ModalProxyService, modalHelper: ModalHelper) {
  modalProxy.modalHelper = modalHelper
}
