import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { AccountObject } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const {apiUrl} = environment

export function registerNg1(module) {
  module.service('AccountApi', downgradeInjectable(AccountApiService))
}


@Injectable()
@AngularCopy()
export class AccountApiService {
  constructor(private http: HttpClient) { }

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: AccountObject[] }>(`${apiUrl}/accounts`, { params }).toPromise()
  }

  @HaveAngularParams()
  listAll(@AngularParams() params) {
    return this.http.get<{ data: AccountObject[] }>(`${apiUrl}/accounts/all`, { params }).toPromise()
  }

  @HaveAngularParams()
  listBuyers(@AngularParams() params) {
    return this.http.get<{ data: AccountObject[] }>(`${apiUrl}/accounts/buyers`, { params }).toPromise()
  }

  @HaveAngularParams()
  listSuppliers(@AngularParams() params) {
    return this.http.get<{ data: AccountObject[] }>(`${apiUrl}/accounts/suppliers`, { params }).toPromise()
  }

  @HaveAngularParams()
  listProviders(@AngularParams() params) {
    return this.http.get<{ data: AccountObject[] }>(`${apiUrl}/accounts/providers`, { params }).toPromise()
  }

  create(data) {
    return this.http.post<{ data: AccountObject }>(`${apiUrl}/accounts`, data).toPromise()
  }

  update(id, data) {
    return this.http.put<{ data: AccountObject }>(`${apiUrl}/accounts/${id}`, data).toPromise()
  }

  updateAccountProducts(id, products_spec) {
    return this.http.put<{ data: any }>(`${apiUrl}/accounts/${id}/products`, products_spec).toPromise()
  }

  get(id) {
    return this.http.get<{ data: AccountObject }>(`${apiUrl}/accounts/${id}`).toPromise()
  }

  delete(id) {
    return this.http.delete<{ data: any }>(`${apiUrl}/accounts/${id}`).toPromise()
  }

  // isActive(id) {
  //   return this.http.get<{ data: any }>(`${apiUrl}/accounts/${id}/is-active`).toPromise()
  // }

  // TODO: deprecate after WA-5591 passed QA at prod
  getByIds(ids) {
    return this.http.post<AccountObject[]>(`${apiUrl}/accounts/by-ids`, {ids}).toPromise()
  }
}
