import { Deal, DealViewRawDeal, DealViewRawInvoices, DealViewRawNotes, Note, User } from '@tradecafe/types/core'
import { DeepReadonly, isDealPortalVisible } from '@tradecafe/types/utils'
import { first, orderBy } from 'lodash-es'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { canEditDealFormField } from 'src/services/data/deal-view-permissions.service'
import { SPECIAL_INSTRUCTIONS } from 'src/services/data/notes.service'
import { DealProductsFormGroup } from '../deal-form.schema'


export function canAddProductSel(dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices>>, currentUser: DeepReadonly<User>) {
  return dealViewRaw$.pipe(
    map(dv => canEditDealFormField(dv, 'productId', currentUser)),
    distinctUntilChanged())
}
export function canRemoveProductSel(dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices>>, productsForm: DealProductsFormGroup, currentUser: DeepReadonly<User>) {
  return dealViewRaw$.pipe(
    map(dv => canEditDealFormField(dv, 'productId', currentUser) && productsForm.length > 1),
    distinctUntilChanged())
}
export function isSupplierPortalVisibleSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(
    map(deal => isDealPortalVisible(deal, deal.supplier_id)),
    distinctUntilChanged())
}
export function isBuyerPortalVisibleSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(
    map(deal => isDealPortalVisible(deal, deal.buyer_id)),
    distinctUntilChanged())
}

export function hasSupplierInstructionsSel(
  dealView$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawNotes>>,
  category = SPECIAL_INSTRUCTIONS,
) {
  return dealView$.pipe(map(dto =>
    hasPartyInstructions(dto.notes, dto.deal.supplier_id, category)))
}

export function hasBuyerInstructionsSel(
  dealView$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawNotes>>,
  category = SPECIAL_INSTRUCTIONS,
) {
  return dealView$.pipe(map(dto =>
    hasPartyInstructions(dto.notes, dto.deal.buyer_id, category)))
}

function hasPartyInstructions(notes: DeepReadonly<Note[]>, partyId: string, category: string): DeepReadonly<Note> {
  const partyNotes = notes?.filter(note =>
    note.attributes &&
    !note.attributes.ignored &&
    note.attributes.company?.includes(partyId) &&
    note.attributes.category === category)
  return first(orderBy(partyNotes, ['created'], ['desc']))
}
