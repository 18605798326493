import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { ItemType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as ItemTypeActions from './item-type.actions'

export const itemTypesFeatureKey = 'itemTypes'
export interface ItemTypesState extends EntityState<DeepReadonly<ItemType>> {
}

export const selectId = (itemType: ItemType) => itemType.item_type_id
export const adapter = createEntityAdapter<DeepReadonly<ItemType>>({ selectId })

const initialState: ItemTypesState = adapter.getInitialState({
})

export const itemTypesReducer = createReducer(
  initialState,
  on(ItemTypeActions.loadItemTypesSuccess,
    (state, action) =>
      adapter.upsertMany(action.itemTypes, state)),
  on(ItemTypeActions.createItemTypeSuccess,
    (state, action) =>
      adapter.addOne(action.itemType, state)),
  on(ItemTypeActions.updateItemTypeSuccess,
    (state, action) =>
      adapter.updateOne({ id: action.itemType.item_type_id, changes: action.itemType }, state)),
  on(ItemTypeActions.deleteItemTypeSuccess,
    (state, action) =>
      adapter.removeOne(action.id, state)),
)
