import { Injectable } from '@angular/core'
import { CREDIT_NOTE_VOIDED, CreditNote, Deal, DealBase, DealView, DealViewBase, DealViewField, DealViewRaw, IEpochRange, VENDOR_CREDIT_VOIDED, VendorCredit, isCreditNoteApproved, isVendorCreditApproved } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, lookupSegments } from '@tradecafe/types/utils'
import { cloneDeep, get, keyBy, omit, pick, sortBy } from 'lodash-es'
import { Subject, from } from 'rxjs'
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { DealApiService } from 'src/api/deal'
import { environment } from 'src/environments/environment'
import { DealFormDto } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFinancialsListenerService } from '../deal-financials-listener'
import { compareBy } from '../table-utils/compare'
import { mergeDeep } from './utils'

const {bwiInventoryAccounts} = environment

const ALLOWED_FIELDS = ['deal_group', 'deal_type', 'supplier_id', 'buyer_id',
  'supplier_user_id', 'buyer_user_id', 'trader_user_id', 'trader_user_id_supplier',
  'logistics_user_id', 'documentation_type', 'seller_terms', 'buyer_terms',
  'buyer_anticipated_liability', 'supplier_anticipated_liability', 'collection_date', 'buyer_confirmed',
  'seller_confirmed', 'origin_location', 'dest_location', 'seller_instructions',
  'buyer_instructions', 'status', 'locked', 'attributes', 'updated', 'inv']
const QUERY_ALL = {limit: Number.MAX_SAFE_INTEGER}

/**
 * Deals service
 *
 * @export
 */
@Injectable()
export class DealsService {
  constructor(
    private AuthApi: AuthApiService,
    private DealApi: DealApiService,
    private DealFinancialsListener: DealFinancialsListenerService,
  ) {}

  dealCostsChanged$ = new Subject<void>()

  /**
   * Get deal by deal_id
   *
   * @param {any} dealId
   * @returns
   */
  async getById(deal_id: string) {
    const {data} = await this.DealApi.get(deal_id)
    return data
  }

  /**
   * Search deals by deal_id, deal_ids or some other fields
   *
   * @param {any} filters
   * @returns
   */
  async searchByDealId(filters) {
    const {data} = await this.DealApi.searchByDealId(filters)
    return data
  }

  /**
   * Get all available deals
   */
  async getDeals(query = {}) {
    const { data } = await this.DealApi.list({...QUERY_ALL, ...query})
    return data
  }

  async getDealIds(params = {}) {
    const { data } = await this.DealApi.getDealIds({ limit: Number.MAX_SAFE_INTEGER, ...params })
    return data.sort(compareBy(dealId => dealId.replace(/^BWI/, ''))).reverse()
  }

  async getDealsByIds(dealIds: string[] = []) {
    if (!dealIds.length) return {}
    const { data } = await this.DealApi.byDealIds(dealIds)
    return keyBy(data, 'deal_id')
  }

  /**
   * Get clones of a deal
   *
   * @param {*} deal
   * @returns
   */
  async getClonesForDeal(deal: Deal) {
    if (!deal.deal_id) return []
    const cloneIds = get(deal, 'attributes.clone_ids', [])
    if (!cloneIds.length) return []
    const { data } = await this.DealApi.byDealIds(cloneIds)
    return data
  }

  /**
   * Check if saving a deal might cause data conflicts
   *
   * @param {*} deal
   */
  async hasDataConflicts<T extends DeepReadonly<Pick<DealBase, 'deal_id'|'updated'>>>(
    deal: T,
    // freshDeal?: DeepReadonly<DealBase>,
  ): Promise<Deal|false> {
    const freshDeal = await this.getById(deal.deal_id)
    // check data conflicts
    return freshDeal.updated > deal.updated ? freshDeal : false
  }

  /**
   * Patch a deal. This method will not override attributes
   * so you can patchDeal(deal, { attributes: { flag: true }})`
   * and this will not destroy all other deal attributes
   *
   * @param {*} deal
   * @param {*} payload
   * @returns
   */
  async patchDeal(deal: Deal|DealViewBase, changes: DeepPartial<DealBase|Deal|DealView>, params?) {
    const oldDeal = deal
    const freshDeal = await this.hasDataConflicts(deal)
    if (freshDeal) deal = freshDeal
    changes = pick(changes, ALLOWED_FIELDS)
    mergeDeep(deal, changes)
    // TODO: do DealView => Deal conversions
    const payload = pick(deal as Deal, Object.keys(changes)) // keep other attributes in play
    const { data } = await this.DealApi.update(deal.deal_id, payload, params)
    oldDeal.updated = data.updated
    oldDeal.attributes = data.attributes
    return data
  }

  /**
   * Patch a deal. This method will not override attributes
   * so you can patchDeal(deal, { attributes: { flag: true }})`
   * and this will not destroy all other deal attributes
   *
   * @param {*} deal
   * @param {*} payload
   * @returns
   */
  async patchImmutableDeal(
    deal: DeepReadonly<DealBase>,
    changes: DeepReadonly<DeepPartial<DealBase>>,
    params?: { skip_calculations: 1 },
  ) {
    const freshDeal = await this.hasDataConflicts(deal)
    if (freshDeal) deal = freshDeal
    changes = pick(changes, ALLOWED_FIELDS)
    deal = mergeDeep(cloneDeep(deal), changes)
    const payload = pick(deal, Object.keys(changes)) // keep other attributes in play
    const { data } = await this.DealApi.update(deal.deal_id, payload, params)
    return data
  }

  /**
   * Update raw deal, return stored version
   *
   * @param {*} deal
   * @returns
   */
  async updateDeal(deal: Partial<Deal | DealBase>, opts?) {
    const {data} = await this.DealApi.update(deal.deal_id, pick(deal, ALLOWED_FIELDS), opts)
    return data
  }

  /**
   * Lock deal
   *
   * @param {any} dealId
   * @returns
   */
  async lockDealMutable(deal: Deal|DealView) {
    const {data: stored} = await this.DealApi.lock(deal.deal_id).toPromise()
    deal.locked = stored.locked
    deal.updated = stored.updated
  }

  unlockDeal(deal: DeepReadonly<DealBase>) {
    return this.DealApi.unlock(deal.deal_id).pipe(map(r => r.data))
  }

  lockDeal(deal: DeepReadonly<DealBase>) {
    return this.DealApi.lock(deal.deal_id).pipe(map(r => r.data))
  }

  /**
   * Unwind deal
   *
   * @param {any} dealId
   * @returns
   */
  unwindDeal(dealId: string) {
    return this.DealApi.unwind(dealId)
  }

  async storeShippingDates(dealView: DealView, { store = false } = {}) {
    const patch = this.calculateShippingDates(dealView)

    if (patch && store) {
      mergeDeep(dealView, patch)
      await this.patchDeal(dealView, patch)
    }
  }

  /**
   * Create segment dates snapshot (min, max + ids)
   */
  calculateShippingDates(dealView: DeepReadonly<DealView>) {
    const { earliest, latest } = lookupSegments(dealView.segments)

    const actual_pickup_date = earliest?.attributes.actual_pickup_date
    const actual_delivery_date = latest?.attributes.actual_delivery_date
    const changed = dealView.attributes.shipment.actual_pickup_date !== actual_pickup_date
                 || dealView.attributes.shipment.actual_delivery_date !== actual_delivery_date
    if (changed) {
      return { attributes: { shipment: {
        actual_pickup_date,
        actual_pickup_segment_id: earliest?.segment_id,
        actual_delivery_date,
        actual_delivery_segment_id: latest?.segment_id,
      }}} as DeepPartial<DealBase>
    } else return false
  }

  async getPrimaryClonesIds() {
    // TODO: do not download all deals. use elastic search
    // return this.elastic.fetchDeals({
    //   query: { type: ['primary-clone'] },
    //   columns: ['deal_id'],
    //   skip: 0,
    //   limit: Number.MAX_SAFE_INTEGER,
    // }).then(r => r.data.map(x => x.deal_id))

    const deals = await this.getDeals()
    const primaryDeals = deals.filter(deal => deal.attributes?.clone_sequence === 0)
    return primaryDeals.map(d => d.deal_id)
  }

  getDealView(
    deal_id: string,
    fields: DealViewField[] = ['deal', 'bids', 'offers', 'costs', 'segments', 'invoices', 'notes', 'credit_notes', 'vendor_credits', 'files', 'bookings', 'export_reports', 'macropoint_order'],
    options: { keepVoidedCredits?: boolean } = {},
  ) {
    return this.getDealViews([deal_id], fields, options).pipe(map(([dealView]) => dealView))
  }

  getDealViews(
    deal_ids: string[],
    fields: DealViewField[] = ['deal', 'bids', 'offers', 'costs', 'segments', 'invoices', 'notes', 'credit_notes', 'vendor_credits', 'files', 'bookings', 'export_reports', 'macropoint_order'],
    { keepVoidedCredits }: { keepVoidedCredits?: boolean } = {}, // options
  ) {
    // TODO: move entire mapping function to the backend
    return this.DealApi.getDealView({ filter: { deal_ids }, fields: [...fields, 'can_create_prepayment'] }).pipe(map(dealViews => dealViews.map(dealView => {
      // compatibility code. remove after SER-1389
      if (dealView.deal) {
        dealView.deal.attributes.deal_date = dealView.deal.attributes.deal_date || dealView.deal.created
      }
      // hide voided credits
      if (!keepVoidedCredits) {
        dealView.credit_notes = dealView.credit_notes?.filter(cn => cn.status !== CREDIT_NOTE_VOIDED)
        dealView.vendor_credits = dealView.vendor_credits?.filter(cn => cn.status !== VENDOR_CREDIT_VOIDED)
      }
      // NOTE: WA-7910 - support segments order (should be moved to the backend)
      if (dealView.segments?.every(s => typeof s.order === 'number')) {
        dealView.segments = sortBy(dealView.segments, 'order')
      }
      // NOTE: data integrity fix.
      // NOTE: sometimes *_ex fields are out of sync.
      dealView.costs?.forEach(cost => {
        const costCredit = (credit: DeepReadonly<CreditNote | VendorCredit>) => ({
          credit_note_id: credit.credit_note_id,
          amount: credit.amount,
          currency: credit.currency,
          date: credit.created,
        })
        cost.attributes.credit_notes_ex = dealView.credit_notes
          ?.filter(c => c.cost_id === cost.cost_id && isCreditNoteApproved(c))
          ?.map(costCredit)
        cost.attributes.vendor_credits_ex = dealView.vendor_credits
          ?.filter(vc => vc.cost_id === cost.cost_id && isVendorCreditApproved(vc) && !vc.attributes?.prepayment)
          ?.map(costCredit)
      })
      return dealView
    })))
  }

  refetchDealParts(dealId: string, parts: DealViewField[], dealViewRaw$: Subject<DeepReadonly<DealViewRaw>>) {
    const nonListFields = ['deal', 'macropoint_order'] as const;
    type NonListField = typeof nonListFields[number];
    return this.getDealView(dealId, parts).pipe(
      withLatestFrom(dealViewRaw$),
      tap(([patch, dv]) => {
        const patch2 = pick(patch, parts)
        parts.forEach(key => {
          if (!(nonListFields).includes(key as NonListField) && !patch2[key]) {
            patch2[key as Exclude<DealViewField, NonListField>] = []
          }
        })
        dealViewRaw$.next({ ...dv, ...patch2 })
      }))
  }

  cloneDeals(deal_id: string, clones: { shipment_dates: IEpochRange, delivery_dates: IEpochRange }[]) {
    return this.DealApi.cloneDeals(deal_id, clones).pipe(switchMap(({ data: deals }) =>
      from(this.DealApi.get(deal_id)).pipe(map(({ data: primaryDeal }) =>
        [primaryDeal, ...deals]))))
  }

  storeDeal(deal: DeepReadonly<Pick<DealBase, 'deal_id' | 'attributes'>>, dealForm: DeepReadonly<DeepPartial<DealFormDto>>) {
    const costs = dealForm.costs?.map(c => omit(c, 'ID'))
    return this.DealApi.storeDeal({ ...dealForm, costs }).pipe(tap(stored => {
      if (dealForm.details.deal_id) {
        this.DealFinancialsListener.tryToRecordChange(deal, stored)
        this.DealApi.dealUpdated$.next(dealForm.details.deal_id)
      }
    }))
  }
}
