import { Injectable } from '@angular/core'
import { AccountObject, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, filter, find, first, flatten, keyBy, map, pick, without } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { UserApiService } from 'src/api/user'
import { environment } from 'src/environments/environment'


const CACHE_MAX_AGE = 3600 * 1000 // 1hr

const ALLOWED_FIELDS = ['attributes', 'address', 'firstname', 'lastname', 'role', 'primaryemail', 'primaryphone', 'whatsapp', 'fax', 'login_allowed', 'allowLoginToAutomatedTrading', 'managedTraders', 'managedLogisticCoordinators', 'language']

/**
 * Users service
 *
 * @export
 * @returns
 */
@Injectable()
export class UsersService {
  constructor(
    private AuthApi: AuthApiService,
    private UserApi: UserApiService,
  ) {}


  private cacheExpiration = {}
  private cache: Dictionary<Promise<{ data: User[] }>> = {}

  /** @deprecated use `export function getBwiManagers` */
  getBwiManagers = getBwiManagers
  /** @deprecated use `export function getDesignatedContacts` */
  getDesignatedContacts = getDesignatedContacts
  /** @deprecated use `export function getDefaultContacts` */
  getDefaultContacts = getDefaultContacts


  /**
   * Get users by ids
   *
   * @param {string} accountId
   * @param {Array} ids
   * @returns hash (key=id, value=user)
   */
  async getUsersByIds(accountId = this.AuthApi.currentUser.account, ids?) {
    const {data} = await this.getUsers(accountId)
    const index = keyBy(data, 'user_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get all available users
   *
   * @param {accountId} accountId
   * @returns {Object} { total_rows:number, data: []}
   */
  async getUsers(accountId: string | number = this.AuthApi.currentUser.account, archived = 1) {
    const cacheKey = `${accountId}_${archived}`
    if (!(this.cache[cacheKey] && this.cacheExpiration[cacheKey] > Date.now())) {
      this.cacheExpiration[cacheKey] = Date.now() + CACHE_MAX_AGE
      // NOTE: we fetch everything
      this.cache[cacheKey] = this.UserApi.list(accountId, {limit: this.UserApi.MaxRequestSize}, archived).then(({data: users}) => {
        // use created as updated if no
        return {
          data: map(users, (user) => {
            user.lastupdate = user.lastupdate || user.created
            return user
          }),
        }
      })
    }
    const {data} = await this.cache[cacheKey]
    return {data}
  }


  /**
   * Create user within given account id
   *
   * @param {any} account
   * @param {any} user
   * @returns
   */
  createFor(account: number, user: User) {
    return this.UserApi.create(account, user).then((res) => {
      this.cacheExpiration[account] = 0
      this.cacheExpiration[this.AuthApi.currentUser.account] = 0
      return res.data
    })
  }

  /**
   * Update user
   *
   * @param {any} user
   * @returns
   */
  update(user: User) {
    const {account, user_id} = user
    const payload = pick(user, ALLOWED_FIELDS)
    return this.UserApi.update(account, user_id, payload).then((res) => {
      this.cacheExpiration[account] = 0
      this.cacheExpiration[this.AuthApi.currentUser.account] = 0
      return res.data
    })
  }

  async getDesignatedContactsByAccountIds(accounts: Array<string|number>) {
    const users = flatten(await Promise.all(accounts.map(account =>
      this.getUsers(account).then(r => r.data))))
    return getDesignatedContacts(users)
  }
}

export function findNick(allUsers: DeepReadonly<User[]> | DeepReadonly<Dictionary<User>>): User { // nick = CEO
  const account = environment.tradecafeAccount
  return find(allUsers, { account, primaryemail: environment.bwiInventoryEmail }) as User ||
    find(allUsers, { account, fullname: environment.bwiInventoryUsername }) as User
}

export function getBwiManagers(allUsers: DeepReadonly<User[]> | DeepReadonly<Dictionary<User>>) {
  const nick = findNick(allUsers)
  const bwiManagers = filter(allUsers, { account: environment.tradecafeAccount, role: 'manager' }) as DeepReadonly<User[]>
  return compact([nick, ...without(bwiManagers, nick)])
}

export function getDesignatedContacts(partyUsers: DeepReadonly<User[]>) {
  return filter(partyUsers, {attributes: {designated: '1'}})
}

export function getDefaultGeo(partyAcc: DeepReadonly<AccountObject>): { country: string, location: string } {
  const companyAddr = find(partyAcc.addresses, 'primary') || // first primary
    first(partyAcc.addresses) // first or nothing
  return {
    country: companyAddr?.cc,
    location: first(partyAcc.attributes?.locations || []),
  }
}

export function getDefaultContacts(partyUsers: DeepReadonly<User[]>, allUsers: DeepReadonly<User[]> | DeepReadonly<Dictionary<User>>) {
  const bwiManagers = getBwiManagers(allUsers)

  return {
    // TODO: '0' = false until after SER-369
    contact: find(partyUsers, {attributes: {designated: '1'}}) ||
      find(partyUsers, {role: 'trader'}) ||
      partyUsers[0],
    bwiManager: findNick(allUsers) || first(bwiManagers),
  }
}

/**
 * Format error message
 *
 * TODO: think about integrating with global err handling (see run.js)
 *
 * @private
 * @param {any} error error field received from server
 * @returns
 */
export function formatErrorMessage(errResponse) {
  const error = errResponse.data?.error || errResponse.error

  // attempt to read Joy validation error
  const details =
    // from users endpoint
    error?.details?.details ||
    // from accounts endpoint
    error?.details?.error?.details

  if (!details) return undefined
  const {path, code, message} = details
  const messagePairs = {
    'primaryphone': 'Invalid phone number.',
    'role': err => err.message,
    'PHONE_IS_IN_USE': err => err.message || message, // 1 - users, 2 - accounts
    'EMAIL_IS_IN_USE': err => err.message || message, // 1 - users, 2 - accounts
  }

  return typeof (messagePairs[path || code]) === 'function'
    ? messagePairs[path || code](errResponse)
    : messagePairs[path || code]
}
