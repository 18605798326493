import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { AccountObject, AesLocation, AesParty, DealRow, LocationObject, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject, combineLatest, merge, ReplaySubject } from 'rxjs'
import { distinctUntilChanged, map, mapTo, take } from 'rxjs/operators'
import { setAesResponseStatus, setAesResponseStatusFailure } from 'src/app/store/aes/aes.actions'
import { loadLocations, selectLocationEntities } from 'src/app/store/locations'
import { GeoService } from 'src/pages/admin/settings/locations/location-form/geo.service'
import { loadAccounts, selectAccountEntities } from '../../app/store/accounts'
import { loadProducts, selectProductEntities } from '../../app/store/products'
import { waitNotEmpty } from '../../shared/utils/wait-not-empty'
import { compact } from 'lodash-es'
import { loadUsers, selectUserEntities } from 'src/app/store/users'

export interface AesResponseReviewDialogOptions {
  dealId: string
  deal: DeepReadonly<DealRow>
}

@Component({
  selector: 'tc-aes-request-review-dialog',
  templateUrl: './aes-request-review-dialog.component.html',
  styleUrls: ['./aes-request-review-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AesRequestReviewDialogComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private actions$: Actions,
    private Geo: GeoService,
    private dialogRef: MatDialogRef<AesRequestReviewDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private dialogData: AesResponseReviewDialogOptions,
  ) { super() }

  readonly dealId = this.dialogData.dealId
  public originState: string
  public toCountry: DeepReadonly<LocationObject>
  public port: DeepReadonly<LocationObject>
  public portUnlading: DeepReadonly<LocationObject>
  public sender: DeepReadonly<User>
  public carrier: DeepReadonly<AccountObject>
  private dealViewRaw$ = new ReplaySubject<DeepReadonly<DealRow>>(1)
  protected exportReport$ = this.dealViewRaw$.pipe(map((dv) => ({...dv.export_reports[0]})))
  protected products$ = this.store.pipe(select(selectProductEntities), waitNotEmpty())

  private requestIndex$ = new BehaviorSubject<number>(0)
  private indexedRequest$ = combineLatest([
    this.exportReport$,
    this.requestIndex$,
  ])
  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
  public isMostRecentRequest$ = this.requestIndex$.pipe(map(i => i === 0))
  public isOldestRequest$ = this.indexedRequest$.pipe(map(([report, index]) => index >= report?.requests?.length - 1))

  public reportRequests$ = this.indexedRequest$.pipe(
    map(([report, index]) => {
      if (!report?.requests?.length) {
        return null
      }
      const startIndex = report?.requests?.length - index - 1
      if (startIndex < 0) {
        return null
      }
      return report.requests?.[startIndex]
    }),
  )

  inProgress$ = new BehaviorSubject<'loading'|'set-status'|undefined>('loading')
  locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())

  ngOnInit(): void {
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({}))

    this.dealViewRaw$.next(this.dialogData.deal)
    this.inProgress$.next(undefined)

    this.actions$.pipe(ofType(setAesResponseStatus), untilComponentDestroyed(this)).subscribe(() => {
      this.dialogRef.close()
    })

    this.reportRequests$.pipe().subscribe((r) => {
      combineLatest([this.locations$, this.accounts$, this.users$]).pipe(take(1)).subscribe(([locations, accounts, users]) => {
        this.originState = r.transportation_origin_country_code === 'US' ? this.Geo.getStateName('US', r.transportation_origin_state_code) : null
        this.toCountry = locations[r.destination_country_location_id]
        this.port = locations[r.port_of_exportation_location_id]
        this.portUnlading = locations[r.port_of_unlading_location_id]
        this.sender = users[r.created_by]
        console.log('this.dialogData.deal', this.dialogData.deal)
        this.carrier = accounts[r.carrier_account]
        this.cd.detectChanges()
      })
    })

    merge(
      this.actions$.pipe(ofType(setAesResponseStatus), mapTo('set-status' as const)),
      this.actions$.pipe(ofType(setAesResponseStatusFailure), mapTo(undefined as 'set-status')),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(inProgress => this.inProgress$.next(inProgress))
  }

  protected cancel() {
    this.dialogRef.close()
  }

  protected seeOlderRequest(): void {
    const current = this.requestIndex$.getValue()
    this.requestIndex$.next(current + 1)
  }

  protected seeNewerRequest(): void {
    const current = this.requestIndex$.getValue()
    this.requestIndex$.next(current - 1)
  }

  protected requestStatusDisplayName(status): string {
    if (status) {
      return 'Active'
    } else {
      return 'Not active'
    }
  }

  protected formatPartyAddress(party: AesParty): string {
    const addressSegments = [party.address, party.secondary_address, party.city, party.state_code, party.postal];
    if(party.country_code !== 'US') {
      addressSegments.push(party.country_code);
    }

    return compact(addressSegments).join(', ');
  }

  protected formatLocation(location: AesLocation): string {
    const stateName = this.Geo.getStateName(location.country_code, location.state_code)
    const countryName = this.Geo.getCountries()[location.country_code]?.name;
    const addressSegments = [location.street1, location.street2, location.city, stateName, location.postal, countryName];
    return compact(addressSegments).join(', ');
  }

  protected formatPartyContactName(party: AesParty): string {
    return compact([party.contact_firstname, party.contact_lastname]).join(', ');
  }
}
