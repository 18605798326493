import { Injectable } from '@angular/core'
import { Deal, DealViewBase, DealViewCosts, Carrier, DealViewRaw, DealRow } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { AuthApiService } from 'src/api/auth'
import { ModalProxyService } from '../../shared/modal/modal-helper'
import { accountsResolver, trackingProvidersResolver, usersResolver } from '../data/deal.resolvers'

@Injectable()
export class DealOverlaysService {
  constructor(
    private modalHelper: ModalProxyService,
    private AuthApi: AuthApiService,
  ) { }


  showMessagesOf(deal: DeepReadonly<(Deal | DealViewBase) & DealViewCosts>) {
    return this.modalHelper.showOverlay('tcDealMessages', {
      backdrop: true,
      resolve: {
        deal: () => deal,
        users: usersResolver,
      },
    })
  }

  openDocumentsTracking(deal: DeepReadonly<DealViewRaw | DealRow>,
                        carriers: DeepReadonly<Dictionary<Carrier>>) {
    const readonly = this.AuthApi.currentUser.role === 'trader'
    return this.modalHelper.showOverlay('tcPackageTracking', {
      backdrop: true,
      resolve: {
        deal: () => deal,
        carriers: () => carriers,
        trackingProviders: trackingProvidersResolver,
        accounts: accountsResolver,
        readonly,
      },
    })
  }

  showShipmentTracking(containerNumber: string) {
    return this.modalHelper.showOverlay('tcShipmentTracking', {
      backdrop: true,
      resolve: {
        containerNumber: () => containerNumber,
      },
    })
  }
}
