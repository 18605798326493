import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Consignee } from '@tradecafe/types/core'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


@Injectable()
export class ConsigneesApiService {
  constructor(private http: HttpClient) {}

  list(params) {
    return this.http.get<{ data: Consignee[] }>(`${apiUrl}/consignees`, { params })
  }

  create(data: Partial<Consignee>) {
    return this.http.post<{ data: Consignee }>(`${apiUrl}/consignees`, data)
  }

  get(id: string) {
    return this.http.get<{ data: Consignee }>(`${apiUrl}/consignees/${id}`)
  }

  update(id: string, data: Partial<Consignee>) {
    return this.http.put<{ data: Consignee }>(`${apiUrl}/consignees/${id}`, data)
  }

  delete(id: string) {
    return this.http.delete<{ data: Consignee }>(`${apiUrl}/consignees/${id}`)
  }
}
