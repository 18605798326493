import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module';
import { SelectSearchModule } from 'src/components/select-search/select-search.module';
import { BrokerageInfoFormComponent } from './brokerage-info-form.component';

@NgModule({
  declarations: [BrokerageInfoFormComponent],
  exports: [BrokerageInfoFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    SelectSearchModule,
    ReactiveAsteriskModule,
  ],
})
export class BrokerageInfoFormModule { }
