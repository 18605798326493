import { Injectable } from '@angular/core'
import { LocationObject } from '@tradecafe/types/core'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { LocationApiService } from 'src/api/shipment-routing/location'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['name', 'country', 'city', 'type', 'state', 'attributes', 'schedule_d_port_code', 'schedule_k_port_code', 'unlocode', 'timezone']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr

/**
 * Locations service
 *
 * @export
 * @returns
 */
@Injectable()
export class LocationsService {
  constructor(
    private AuthApi: AuthApiService,
    private LocationApi: LocationApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) {}

  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] as LocationObject[] })

  locationChanged$ = new Subject<void>()

  /**
   * Get all available locations
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getLocations(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.LocationApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (locations) => {
        const { account } = this.AuthApi.currentUser
        const userIds = uniq(compact(map(locations.data, 'user_id')))

        const users = await this.Users.getUsersByIds(account, userIds)

        locations.data.forEach((location: any) => {
          location.user = users[location.user_id]
          location.created = location.created || location.createdAt
          location.updated = location.updatedAt || location.updated || location.created
          location.fullname = compact([location.city, location.state, location.country]).join(', ')
        })
        return locations
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get locations by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=location)
   */
  async getLocationsByIds(ids?: string[]) {
    const { data } = await this.getLocations()
    const index = keyBy(data, 'location_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get possible values for given location field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const { data } = await this.getLocations()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new location
   *
   * @param {any} location
   * @returns
   */
  async create(location: LocationObject) {
    const payload = pick(location, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id
    payload.type = 'unknown' // TODO: remove once this field will become NOT MANDATORY
    const { data } = await this.LocationApi.create(payload)
    this.invalidateCache()
    this.locationChanged$.next()
    return data
  }

  /**
   * Update location
   *
   * @param {any} location
   * @returns
   */
  async update(location: Partial<LocationObject>) {
    const { location_id } = location
    const payload = pick(location, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.LocationApi.update(location_id, payload)
    this.invalidateCache()
    // NOTE: in theory we should not do that, as we update objects in memory
    this.locationChanged$.next()
    return data
  }

  /**
   * Update location
   *
   * @param {any} location
   * @returns
   */
  async remove(location: LocationObject) {
    const { data } = await this.LocationApi.delete(location.location_id)
    this.invalidateCache()
    this.locationChanged$.next()
    return data
  }


  /**
   * Invalidate locations in-memory cache
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }
}
