import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Note } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('NoteApi', downgradeInjectable(NoteApiService))

@Injectable()
@AngularCopy()
export class NoteApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account: number | string, @AngularParams() params) {
    return this.http.get<{ data: Note[] }>(`${apiUrl}/notes/${account}`, { params }).toPromise()
  }

  byDealIdsObs(account: number | string, deal_ids: string[]) {
    return this.http.post<{ data: Note[] }>(`${apiUrl}/notes/${account}/by_deals`, { deal_ids })
  }

  byDealIds(account: number | string, deal_ids: string[]) {
    return this.http.post<{ data: Note[] }>(`${apiUrl}/notes/${account}/by_deals`, { deal_ids }).toPromise()
  }

  create(account: number | string, data: DeepReadonly<Partial<Note>>) {
    return this.http.post<{ data: Note }>(`${apiUrl}/notes/${account}`, data).toPromise()
  }

  get(account: number | string, id: string) {
    return this.http.get<{ data: Note }>(`${apiUrl}/notes/${account}/${id}`).toPromise()
  }

  update(account: number | string, id: string, data: DeepReadonly<Partial<Note>>) {
    return this.http.put<{ data: Note }>(`${apiUrl}/notes/${account}/${id}`, data).toPromise()
  }

  delete(account: number | string, id: string) {
    return this.http.delete<{ data: Note }>(`${apiUrl}/notes/${account}/${id}`).toPromise()
  }

  getByReceiptIds(account: number | string, receiptIds: string[]) {
    return this.http.post<{ data: Note[] }>(`${apiUrl}/notes/${account}/by_receipt_ids`, {receipt_ids: receiptIds}).toPromise()
  }
}
