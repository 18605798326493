import { createAction, props } from '@ngrx/store'
import { PricingTerm, TableKey } from '@tradecafe/types/core'


export const loadPricingTerms = createAction(
  '[PricingTerm/API] Load Pricing Terms',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadPricingTermsSuccess = createAction(
  '[PricingTerm/API] Load Pricing Terms Success',
  props<{
    tableKey?: TableKey,
    pricingTerms: PricingTerm[],
  }>(),
)
export const loadPricingTermsFailure = createAction(
  '[PricingTerm/API] Load Pricing Terms Failure',
  props<{ error: Error }>(),
)
