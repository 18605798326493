import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DeepReadonly } from '@tradecafe/types/utils'
import { difference } from 'lodash-es'
import { from, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { DocumentApiService } from 'src/api/document-lib/document'
import { DocumentSetApiService } from 'src/api/document-lib/document-set'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { createDocumentTemplate, createDocumentTemplateFailure, createDocumentTemplateSuccess, deleteDocumentTemplate, deleteDocumentTemplateFailure, deleteDocumentTemplateSuccess, loadDocumentTemplates, loadDocumentTemplatesFailure, loadDocumentTemplatesSuccess, updateDocumentTemplate, updateDocumentTemplateFailure, updateDocumentTemplateSuccess } from './document-template.actions'

@Injectable()
export class DocumentTemplateEffects {
  constructor(
    private actions$: Actions,
    private DocumentApi: DocumentApiService,
    private DocumentSetApi: DocumentSetApiService,
    private toaster: ToasterService,
  ) {}

  loadDocumentTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(loadDocumentTemplates),
    switchMap(action =>
      from(this.DocumentApi.list()).pipe(
        map(r => loadDocumentTemplatesSuccess({ tableKey: action.tableKey, templates: r.data })),
        catchError(error => {
          console.error('Unable to fetch document templates', error)
          this.toaster.error('Unable to fetch document templates', error)
          return of(loadDocumentTemplatesFailure({ error }))
        })))))

  createDocumentTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(createDocumentTemplate),
    switchMap(action =>
      from(this.DocumentApi.create(action.template).then(({ data }) =>
        this.updateDocumentSetsForDocument(data.document_id, action.template.doc_set, []).then(() => data))
      ).pipe(
        map(r => createDocumentTemplateSuccess({ template: r })),
        tap(() => this.toaster.success('Document created successfully.')),
        catchError(error => {
          console.error('Unable to create document template', error)
          this.toaster.error('Unable to create document template', error)
          return of(createDocumentTemplateFailure({ error }))
        })))))

  updateDocumentTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(updateDocumentTemplate),
    switchMap(action =>
      from(Promise.all([
        this.DocumentApi.update(action.id, action.template),
        this.updateDocumentSetsForDocument(action.id, action.template.doc_set, action.original.doc_set),
      ])).pipe(
        map(([r]) => updateDocumentTemplateSuccess({ template: r.data })),
        tap(() => this.toaster.success('Document updated successfully.')),
        catchError(error => {
          console.error('Unable to update document template', error)
          this.toaster.error('Unable to update document template', error)
          return of(updateDocumentTemplateFailure({ error }))
        })))))

  deleteDocumentTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(deleteDocumentTemplate),
    switchMap(action =>
      from(Promise.all([
        this.DocumentApi.delete(action.template.document_id),
        ...action.template.doc_set.map(setId => this.DocumentSetApi.removeDoc(setId, action.template.document_id))
      ])).pipe(
        map(() => deleteDocumentTemplateSuccess({ id: action.id })),
        tap(() => this.toaster.success('Document deleted successfully.')),
        catchError(error => {
          console.error('Unable to delete document template', error)
          this.toaster.error('Unable to delete document template', error)
          return of(deleteDocumentTemplateFailure({ error }))
        })))))

  private updateDocumentSetsForDocument(documentId: string, newSets: DeepReadonly<string[]> = [], oldSets: DeepReadonly<string[]> = []) {
    const added = difference(newSets, oldSets)
    const removed = difference(oldSets, newSets)

    return Promise.all([
      ...added.map(setId => this.DocumentSetApi.addDoc(setId, documentId)),
      ...removed.map(setId => this.DocumentSetApi.removeDoc(setId, documentId)),
    ])
  }
}
