import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { PricingTermsService } from 'src/pages/admin/settings/admin-setting-payments/pricing-terms/pricing-terms.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadPricingTerms, loadPricingTermsFailure, loadPricingTermsSuccess } from './pricing-term.actions'

@Injectable()
export class PricingTermEffects {
  constructor(
    private actions$: Actions,
    private PricingTerms: PricingTermsService,
    private toaster: ToasterService,
  ) {}

  loadPricingTerms$ = createEffect(() => this.actions$.pipe(
    ofType(loadPricingTerms),
    switchMap(action =>
      from(this.PricingTerms.getPricingTerms()).pipe(
        map(pricingTerms => loadPricingTermsSuccess({
          tableKey: action.tableKey,
          pricingTerms: pricingTerms,
        })),
        catchError(error => {
          console.error('Unable to fetch pricingTerms', error)
          this.toaster.error('Unable to fetch pricingTerms', error)
          return of(loadPricingTermsFailure({ error }))
        })))))
}
