import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { ACCOUNT_ACTIVE, Carrier, LocationObject, SegmentType, ShipmentRate, ShipmentRateCommodity, ShipmentRateStatuses } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { isEqual, omit, orderBy } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { map, skip, take } from 'rxjs/operators'
import { loadCarriers, selectAllCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { loadCurrencies, selectAllCurrencies } from 'src/app/store/currencies'
import { loadLocations, selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadMeasures, selectAllMeasures } from 'src/app/store/measures'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { FreightCategories, FreightRatesService } from '../freight-rates.service'
import { ProblematicFreightRatesService } from '../problematic-freight-rates/problematic-freight-rates.service'

export interface FreightRatesFormOptions {
  freightRate?: DeepPartial<ShipmentRate>,
  title?: string,
  mode?: string,
  isCopy?: boolean,
  dealId?: string,
  matchedOfferId?: string,
  single?: boolean,
}
@Component({
  selector: 'tc-freight-rate-form',
  styleUrls: ['./freight-rate-form.component.scss'],
  templateUrl: './freight-rate-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightRateFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private toaster: ToasterService,
    private FreightRates: FreightRatesService,
    private store: Store,
    private dialogRef: MatDialogRef<FreightRatesFormOptions, ShipmentRate>,
    @Inject(MAT_DIALOG_DATA) protected dialogData: FreightRatesFormOptions,
    private ProblematicFreightRates: ProblematicFreightRatesService
  ) {
    super()
  }

  protected FreightCategories = FreightCategories
  protected modalTitle: string
  protected isReadOnly: boolean

  protected form = new FormGroup({
    rate_id: new FormControl<string>(null),
    carrier_id: new FormControl<string>('', Validators.required),
    until: new FormControl<number>(null, Validators.required),
    type: new FormControl<SegmentType>(null, Validators.required),
    commodity: new FormControl<ShipmentRateCommodity>(undefined, Validators.required),
    origin_id: new FormControl<string>('', Validators.required),
    destination_id: new FormControl<string>('', Validators.required),
    port_loading: new FormControl<string>('', Validators.required),
    port_discharge: new FormControl<string>('', Validators.required),
    rate: new FormGroup({
      amount: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
      currency: new FormControl<string>('', Validators.required)
    }),
    container_size: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
    weight: new FormGroup({
      max: new FormControl<number>(null, [Validators.required, Validators.min(0)]),
      metric: new FormControl<string>('', Validators.required)
    }),
    status: new FormControl<number>(null),
    attributes: new FormGroup({
      date_quoted: new FormControl<number>(null, Validators.required),
      transit_time: new FormControl<number>(null, [Validators.min(0)])
    }),
    description: new FormControl<string>(''),
    availableForOtherDeals: new FormControl<boolean>(false)
  });


  protected currencies$ = this.store.select(selectAllCurrencies).pipe(waitNotEmpty(), map((currencies) => orderBy(currencies, 'code')))
  protected locations$ = this.store.select(selectAllLocations).pipe(waitNotEmpty(), map((locations) => orderBy(locations, 'name')))
  protected measures$ = this.store.select(selectAllMeasures).pipe(waitNotEmpty())
  protected carriers$ = this.store.select(selectAllCarriers).pipe(waitNotEmpty(),
    map((carriers: Array<Carrier & { status?: number }>) => {
      const activeCarriers = carriers.filter(acc => acc.status === ACCOUNT_ACTIVE);
      return orderBy(activeCarriers, (acc) => acc.name?.toLowerCase())
    }))

  protected locationsDictionary$ = this.store.select(selectLocationEntities).pipe(waitNotEmpty())
  protected carriersDictionary$ = this.store.select(selectCarrierEntities).pipe(waitNotEmpty())

  protected inProgress$ = new BehaviorSubject<string>(undefined);

  protected types: DeepReadonly<SegmentType[]> = [];
  protected Statuses = orderBy(Object.keys(ShipmentRateStatuses).map(i => ShipmentRateStatuses[i]), 'label');

  protected frTmp: ShipmentRate;

  ngOnInit(): void {
    this.inProgress$.next('loading')

    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadMeasures({}))
    this.store.dispatch(loadCarriers({}))

    this.modalTitle = this.dialogData.title || 'Freight rate'
    this.frTmp = this.dialogData.freightRate as ShipmentRate
    this.form.patchValue(this.frTmp)

    const availableForOtherDeals = !!this.frTmp?.rate_id &&
      !this.frTmp?.visible_to_deal_id &&
      !this.frTmp?.visible_to_matched_offer_id;

    this.form.controls.availableForOtherDeals.setValue(availableForOtherDeals);
    this.isReadOnly = this.dialogData.mode === 'read';

    combineLatest([this.currencies$, this.measures$, this.carriersDictionary$, this.locationsDictionary$]).pipe(take(1)).subscribe(([
      _currencies, _measures, carriers, locations]) => {
        this.onCarrierChanged(this.form.controls.carrier_id.value, carriers)
        this.form.controls.carrier_id.valueChanges.pipe(skip(1)).subscribe((carrierId) => { this.onCarrierChanged(carrierId, carriers, true);})
        this.form.controls.origin_id.valueChanges.pipe(skip(1)).subscribe((originId) => {this.onOriginChanged(originId, locations);})
        this.form.controls.destination_id.valueChanges.pipe(skip(1)).subscribe((destinationId) => {this.onDestinationChanged(destinationId, locations);})
        this.inProgress$.next(undefined);
    })
  }

  protected cancel() {
    this.dialogRef.close();
  }


  protected onCarrierChanged(carrierId: string, carriers: Dictionary<DeepReadonly<Carrier>>, autoType: boolean = false) {
    const carrier = carriers[carrierId];
    if(!carrier) return
    this.types = carrier.type;
    if (autoType) {
      this.form.controls.type.setValue(this.types?.length === 1 ? this.types[0] : null)
    }
  }

  protected onOriginChanged(id: string, locations: Dictionary<DeepReadonly<LocationObject>>) {
    const origin = locations[id]
    this.form.controls.port_loading.setValue(origin?.attributes?.port)
  }

   protected onDestinationChanged(id: string, locations: Dictionary<DeepReadonly<LocationObject>>) {
    const destination = locations[id]
    this.form.controls.port_discharge.setValue(destination?.attributes?.port)
  }

  protected async save() {
    if (this.isReadOnly || this.inProgress$.value) return
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()
    if (!this.form.valid) return

    const payload: Partial<ShipmentRate & { single?: boolean }> = omit(this.form.getRawValue(), 'availableForOtherDeals');
    payload.single = this.dialogData.single;
    const { dealId, matchedOfferId } = this.dialogData;

    if(!this.form.controls.availableForOtherDeals.value && (dealId || matchedOfferId)) {
      payload.visible_to_deal_id = dealId;
      payload.visible_to_matched_offer_id = matchedOfferId;
    }

    if (this.dialogData.isCopy && isEqual(payload, this.frTmp)) {
      this.toaster.error('Unable to copy the freight as there is no change.')
      return
    }
    try {
      this.inProgress$.next('saving')
      const stored = payload.rate_id
        ? await this.FreightRates.update(payload)
        : await this.FreightRates.create(payload)
      this.toaster.success(`Freight rate is ${payload.single || payload.rate_id ? '' : 'being'} saved.`)
      this.dialogRef.close(stored)
    } catch (err) {
      console.error('Unable to save freight rate', err)
      this.toaster.error('Unable to save freight rate', err)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  async flagFreightRate() {
    if (this.frTmp?.rate_id && this.frTmp?.rate_id !== '') {
      const stored = await this.ProblematicFreightRates.showProblematicFreightRates(this.frTmp)
      this.frTmp = stored || this.frTmp
      this.form.patchValue(this.frTmp)

    } else {
      this.toaster.warning('Freight Rate Id is invalid')
    }
  }

  getProblemHistory() {
    return this.frTmp?.problem?.history.join('\n')
  }

  isFlagged() {
    return this.frTmp?.problem?.isFlagged
  }
}

