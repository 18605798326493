import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { DealViewRaw, Note, NoteVisibility } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { filter } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { NotesService } from 'src/services/data/notes.service'
import { StatefulDataSource } from 'src/services/table-utils/data-sources/stateful-data-source'
import { NoteFormService } from '../note-form/note-form.service'
import { columnNames } from './notes-list.columns'


export interface NoteRow {
  note: Note // raw
  created: number
  category: string // name
  relatesTo: string // name
  visibility: string // name
  user: string // name
  body: string // name
}

export interface NotesListFilters {
  search?: string
  visibility?: NoteVisibility
  user_id?: string[]
  company: string[] // company ids filter. match note.attributes.company: string[]
  category: string[] // category filter. match note.attributes.category: string
}


@Component({
  selector: 'tc-notes-list-v2',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesListComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private AuthApi: AuthApiService,
    private Notes: NotesService,
    private NoteForm: NoteFormService,
  ) { super() }

  readonly columnNames = columnNames()


  @Input() dealViewRaw: DeepReadonly<DealViewRaw>
  @Input() dataSource: StatefulDataSource<NoteRow>
  @Input() displayColumns: string[]
  @Output() rowClick = new EventEmitter<{ row: NoteRow, column?: string, tabName?: string }>()

  @Input() class = ''

  @HostBinding('class')
  get hostClasses() {
    return `${this.class} tc-notes-list tc-data-${this.dataSource.state$.value}`
  }

  @HostBinding('style.--notes-list-header-height')
  readonly headerRowHeight = '58px'

  @HostBinding('style.--notes-list-rows-height')
  readonly visibleRowsHeight = '55px'

  @HostBinding('style.--notes-list-rows-visible')
  get visibleRowsCount() {
    return Math.min(this.dataSource.paginator?.pageSize || Number.POSITIVE_INFINITY, this.dataSource.filteredData?.length || 0)
  }

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator) }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.setSort(sort) }


  ngOnInit(): void {
    if (!this.rowClick.length) {
      this.rowClick.pipe(
        filter(({ row }) => this.canEdit(row.note)),
        untilComponentDestroyed(this),
      ).subscribe(({ row }) => {
        if (this.canEdit(row.note)) {
          this.showUpdateItem(row)
        }
      })
    }
  }

  getRowId(_index: number, note: NoteRow) {
    return note.note.note_id
  }

  canEdit(note: Note) {
    if (!note) return false
    const { role, user_id } = this.AuthApi.currentUser
    return role === 'administrator' || note.user_id === user_id
  }

  showUpdateItem(row: NoteRow) {
    this.NoteForm.updateDealNote(this.dealViewRaw, row.note).then(() => {
      this.dataSource.refresh()
    })
  }

  ignoreNote(row: NoteRow) {
    this.Notes.ignoreImmutable(row.note, this.dealViewRaw?.deal)
  }

  unignoreNote(row: NoteRow) {
    this.Notes.unignoreImmutable(row.note)
  }
}
