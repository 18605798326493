import { createSelector } from '@ngrx/store'
import { AccountObject, BUYER, SERVICE_PROVIDER, SUPPLIER } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { filter, uniqBy } from 'lodash-es'
import { isCompanyVisible } from 'src/services/data/accounts.service'
import { compareBy } from 'src/services/table-utils/compare'
import { State } from '../reducers'
import { accountsFeatureKey, adapter } from './account.reducer'


export interface GenericOption<T = string> {
  id: T
  name: string
  hint?: string
}

export const selectState = (state: State) => state[accountsFeatureKey]

export const { selectEntities: selectAccountEntities, selectAll: selectAllAccounts } = adapter.getSelectors(selectState)

export const selectAccount = (account: string|number) => createSelector(
  selectAccountEntities,
  (accounts: Dictionary<AccountObject>) => accounts[account])

// export const selectAccountsByIds = (dealIds: string[]) => createSelector(
//   selectAccountEntities,
//   (accounts: Dictionary<AccountObject>) =>
//     dealIds.map(dealId => accounts[dealId]))

export const selectAccountsOptions = (selected?: string | string[]) => createSelector(
  selectAllAccounts,
  (accounts: DeepReadonly<AccountObject>[]) => accounts
    .filter(acc => isCompanyVisible(acc) || selected && (Array.isArray(selected) ? selected.includes(acc.account.toString()) : acc.account.toString() === selected))
    .map((acc): GenericOption => ({ id: acc.account.toString(), name: acc.name, hint: acc.type }))
    .sort(compareBy('name')))

export const selectBrokerageCustomerOptions = (selected?: string | string[]) => createSelector(
  selectAllAccounts,
  (accounts: DeepReadonly<AccountObject>[]) => accounts
    .filter(acc => isCompanyVisible(acc) || selected && (Array.isArray(selected) ? selected.includes(acc.account.toString()) : acc.account.toString() === selected))
    .sort(compareBy('name')))

export const selectBuyersOptions = (selected?: string) => createSelector(
  selectAllAccounts,
  (accounts: DeepReadonly<AccountObject>[]) => accounts
    .filter(acc => acc.type === BUYER && isCompanyVisible(acc) || acc.account.toString() === selected)
    .map((acc): GenericOption => ({ id: acc.account.toString(), name: acc.name }))
    .sort(compareBy('name')))

export const selectProvidersOptions = (selected?: string) => createSelector(
    selectAllAccounts,
    (accounts: DeepReadonly<AccountObject>[]) => accounts
        .filter(acc => acc.type === SERVICE_PROVIDER && isCompanyVisible(acc) || acc.account.toString() === selected)
        .map((acc): GenericOption => ({ id: acc.account.toString(), name: acc.name }))
        .sort(compareBy('name')))

export const selectSuppliersOptions = (selected?: string) => createSelector(
  selectAllAccounts,
  (accounts: DeepReadonly<AccountObject>[]) => accounts
    .filter(acc => acc.type === SUPPLIER && isCompanyVisible(acc) || acc.account.toString() === selected)
    .map((acc): GenericOption => ({ id: acc.account.toString(), name: acc.name }))
    .sort(compareBy('name')))

export const selectBuyers = (selected?: string) => createSelector(
  selectAllAccounts,
  (accounts: DeepReadonly<AccountObject>[]) => accounts
    .filter(acc => acc.type === BUYER && isCompanyVisible(acc) || acc.account.toString() === selected)
    .sort(compareBy('name')))

export const selectSuppliers = (selected?: string) => createSelector(
  selectAllAccounts,
  (accounts: DeepReadonly<AccountObject>[]) => accounts
    .filter(acc => acc.type === SUPPLIER && isCompanyVisible(acc) || acc.account.toString() === selected)
    .sort(compareBy('name')))

export const selectProviderOptions = (opts: {
  supplierIds: DeepReadonly<Array<number | string>>,
  buyerIds: DeepReadonly<Array<number | string>>,
  providerId: string,
}) => createSelector(
  selectAccountEntities,
  (accounts: Dictionary<DeepReadonly<AccountObject>>) =>
    filter(uniqBy([
      ...opts.supplierIds.map(supplierId => ({ hint: 'supplier', ...accounts[supplierId] })),
      ...opts.buyerIds.map(buyerId => ({ hint: 'buyer', ...accounts[buyerId] })),
      ...[
        ...filter(accounts, {type: SERVICE_PROVIDER}),
        ...filter(accounts, {type: SUPPLIER}),
      ].filter(acc => isCompanyVisible(acc) || acc.account.toString() === opts.providerId)
        .sort(compareBy('name'))
        .map(acc => ({ ...acc, hint: '' })),
    ], 'account'), 'account')
    .map((acc): GenericOption => ({ id: acc.account.toString(), name: acc.name, hint: acc.hint })))
