import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { ModalModule } from 'src/shared/modal'
import { FreightRateFormComponent } from './freight-rate-form.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FreightRatesService } from '../freight-rates.service'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { ProblematicFreightRatesModule } from '../problematic-freight-rates/problematic-freight-rates.module'
import { MatTooltipModule } from '@angular/material/tooltip'


@NgModule({
  declarations: [
    FreightRateFormComponent,
  ],
  providers: [FreightRatesService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    ModalModule,
    SelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    EpochFieldModule,
    ProblematicFreightRatesModule
  ],
})
export class FreightRateFormModule { }
