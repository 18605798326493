import { Injectable } from '@angular/core'
import { Receipt } from '@tradecafe/types/core'
import { get } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { OperationsApiService } from 'src/api/operations'
import { ReceiptApiService } from 'src/api/receipt'


export const RECEIPT_VOIDED = 0

export const receiptSources = {
  credit: 'Credit',
  wire: 'Wire',
  bank_fee: 'Bank Fee',
}

@Injectable()
export class ReceiptsService {
  constructor(
    private AuthApi: AuthApiService,
    private ReceiptApi: ReceiptApiService,
    private OperationsApi: OperationsApiService,
  ) {}

  // return {
  //   // sync
  //   sources,
  //   getSource,

  //   // async
  //   getById,
  //   voidReceipt,
  //   voidReceipts,
  // }


  async getById(id: string) {
    const {account} = this.AuthApi.currentUser
    const {data} = await this.ReceiptApi.get(account, id)

    return data
  }


  /**
   * void receipt
   *
   * @param {any} receipt
   * @returns
   */
  voidReceipt(receipt: Receipt) {
    return this.OperationsApi.voidReceipt(receipt.receipt_id).toPromise()
  }

  async voidReceipts(receipts: Receipt[]) {
    for (const receipt of receipts) {
      await this.voidReceipt(receipt)
    }
  }


  getSource(receipt: Receipt) {
    if (get(receipt, 'attributes.credit_note_id', false)) return 'credit'
    if (get(receipt, 'attributes.is_bank_fees', false)) return 'bank_fee'
    return 'wire'
  }

}
