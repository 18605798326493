import { Carrier, Country, LocationObject, ShipmentRate, ShipmentRateStatuses, User } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { cloneDeep, filter, get, set } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dayjs } from 'src/services/dayjs';
import { FiltersFormGroup } from 'src/services/table-utils';
import { SimpleDataSource } from 'src/services/table-utils/data-sources/simple-data-source';
import { GeoService } from '../../settings/locations/location-form/geo.service';
import { FreightRateRow } from './freight-rates-list/freight-rates-list.component';
import { FreightRatesFilter, RATE_EXPIRED, RATE_VALID } from './freight-rates-list/freight-rates-list.filters';

export class FreightRatesDataSource extends SimpleDataSource<FreightRateRow> {
  public constructor(
    freightRates$: Observable<ShipmentRate[]>,
    filtersForm: FiltersFormGroup<FreightRatesFilter>,
    locationsDictionary$: Observable<Dictionary<DeepReadonly<LocationObject>>>,
    carriersDictionary$: Observable<Dictionary<DeepReadonly<Carrier>>>,
    countriesDictionary$: Observable<Dictionary<DeepReadonly<Country>>>,
    usersDictionary$: Observable<Dictionary<DeepReadonly<User>>>,
    private Geo: GeoService
  ) {
    super(
      combineLatest([freightRates$, locationsDictionary$, carriersDictionary$, countriesDictionary$, usersDictionary$])
        .pipe(map(([rates, locations, carriers, countries, users]) => rates.map((rate) => this.populate(rate, locations, carriers, countries, users)))),
      filtersForm,
      applyFilters,
    );

    this.sortingDataAccessor = this.getSortingValue;
  }

  /**
   * Maps a Shipment Rate into format appropriate for the grid display
   *
   * @param {Partial<ShipmentRate>} freightRate
   * @param {Dictionary<DeepReadonly<LocationObject>>} locations
   * @param {Dictionary<DeepReadonly<Carrier>>} carriers
   * @param {Dictionary<DeepReadonly<Country>>} countries
   * @param {Dictionary<DeepReadonly<User>>} users
   * @returns {Promise<FreightRateRow>}
   */
  private populate(freightRate: ShipmentRate,
    locations: Dictionary<DeepReadonly<LocationObject>>,
    carriers: Dictionary<DeepReadonly<Carrier>>,
    countries: Dictionary<DeepReadonly<Country>>,
    users: Dictionary<DeepReadonly<User>>
  ): FreightRateRow {
    const row: FreightRateRow = cloneDeep(freightRate);
    row.carrier = carriers[row.carrier_id];
    row.origin = locations[row.origin_id];
    row.originCountry = countries[row.origin?.country];
    row.originStateName = this.Geo.getStateName(row.origin?.country, row.origin?.state);
    row.destination = locations[row.destination_id];
    row.destinationCountry = countries[row.destination?.country];
    row.destinationStateName = this.Geo.getStateName(row.destination?.country, row.destination?.state);
    row.statusLabel = ShipmentRateStatuses[row.status]?.label;
    row.creator = users[row.user_id];
    set(row, 'attributes.date_quoted', row?.attributes?.date_quoted || row.created);

    return row;
  }

  /**
   * Maps a sorting column key into the property path
   *
   * @param {FreightRateRow} item
   * @param {string} column
   */
  private getSortingValue = (item: FreightRateRow, column: string) => {
    const columnPropertyMapping: Dictionary = {
      'origin_city': 'origin.city',
      'destination_city': 'destination.city',
      'max_weight': 'weight.max',
      'all_in_rate': 'rate.amount',
      'rate_currency': 'rate.currency',
      'transit_time': 'attributes.transit_time',
      'date_quoted': 'attributes.date_quoted',
      'carrier_name': 'carrier.name',
      'status': 'statusLabel',
      'orgCountry': 'originCountry.name',
      'dstCountry': 'destinationCountry.name',
      'orgState': 'originStateName',
      'dstState': 'destinationStateName',
      'creator': 'creator.fullname',
    };

    const propertyPath = get(columnPropertyMapping, column, column);
    const value = get(item, propertyPath);
    if (typeof value === 'string') {
      // make sure string cells are sorted case-insensitive
      return value.toLocaleLowerCase();
    }
    return value;
  };
}

/**
 * Applies the filter form on a dataset
 *
 * @param {FreightRateRow[]} freightRates
 * @param {FreightRatesFilter} filters
 * @returns {*}
 */
function applyFilters(freightRates: FreightRateRow[], filters: FreightRatesFilter) {
  const containsOrEmpty = <T>(arr: T[], value: T) => !arr || arr.length === 0 || arr.includes(value);
  const withinLimits = (from: number, to: number, value: number) => !!value && (!from || from <= value) && (!to || value <= to);

  let filteredRates = freightRates?.filter((row: FreightRateRow) => {
    return containsOrEmpty(filters.carrier, row.carrier_id) &&
      containsOrEmpty(filters.type, row.type) &&
      containsOrEmpty(filters.commodity, row.commodity) &&
      containsOrEmpty(filters.containerSize, row.container_size) &&
      containsOrEmpty(filters.orgCountry, row.origin?.country) &&
      containsOrEmpty(filters.orgState, row.origin?.state) &&
      containsOrEmpty(filters.orgCity, row.origin?.city) &&
      containsOrEmpty(filters.dstCountry, row.destination?.country) &&
      containsOrEmpty(filters.dstState, row.destination?.state) &&
      containsOrEmpty(filters.dstCity, row.destination?.city) &&
      containsOrEmpty(filters.status, row.status) &&
      containsOrEmpty(filters.maxWeight, row.weight?.max) &&
      containsOrEmpty(filters.creator, row.user_id) &&
      containsOrEmpty(filters.carrier, row.carrier_id) &&
      withinLimits(filters.valid_until_from, filters.valid_until_to, row.until) &&
      withinLimits(filters.created_from, filters.created_to, row.created)
  });

  const textSearch = filters.text?.trim()?.toLowerCase();
  if (textSearch) {
    filteredRates = filter(filteredRates, (row) => JSON.stringify(row)?.toLowerCase().includes(textSearch));
  }

  if(filters.validity === RATE_EXPIRED) {
    filteredRates = filter(filteredRates, (row) => isRateExpired(row))
  } else if (filters.validity === RATE_VALID) {
    filteredRates = filter(filteredRates, (row) => !isRateExpired(row))
  }

  return filteredRates;
}

export const isRateExpired = (rate: FreightRateRow) => rate.until < dayjs.utc().unix()
