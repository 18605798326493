import { FormGroup, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { map as _map, compact, flattenDeep, keyBy, mapValues, pickBy } from "lodash-es"
import { distinctUntilChanged, map } from "rxjs/operators"

export function fieldsReport(fg: FormGroup) {
  return fg.statusChanges.pipe(
    map(() => {
      const controls = mapValues(keyBy(deepControls(fg), 'key'), 'control')
      const dirty = _map(pickBy(controls, 'dirty'), (_c, key) => key).join('\n')
      const invalid = _map(pickBy(controls, 'invalid'), (_c, key) => key).join('\n')
      return compact([
        dirty ? `--[ DIRTY ]-------\n${dirty}` : '',
        invalid ? `--[ INVALID ]-----\n${invalid}` : '',
      ]).join('\n\n')
    }),
    distinctUntilChanged())
}

function deepControls(fg: UntypedFormGroup, parent = ''): {key: string, control: UntypedFormControl}[] {
  return flattenDeep(_map(fg.controls, (control: UntypedFormControl, key) => {
    if (parent) key = `${parent}.${key}`
    return control['controls']
      ? deepControls(control as any, key) // recursion
      : { control, key }
  }))
}
