<form [formGroup]="form" (submit)="save()">
  <h2 mat-dialog-title style="text-transform: capitalize">
    <mat-icon class="fa"
        [class.fa-users]="editableParty"
        [class.fa-calculator]="!editableParty"
        ></mat-icon>
    Change {{party}} {{editableParty ? '' : ' Formula'}}
  </h2>
  <div mat-dialog-content>
    <tc-select-search placeholder="Deals {{party}}" *ngIf="editableParty"
        [group]="form" ctrlName="account"
        [items]="accounts$ | async"
        bindValue="account"
        bindLabel="name"
        (change)="onAccountChange($event)"
        ></tc-select-search>

    <mat-radio-group formControlName="overridePrice">
      <mat-radio-button *ngIf="editableParty">Do not change price</mat-radio-button>
      <mat-radio-button value="flat" [class.tc-active-radio-button]="isFlat$ | async" [ngSwitch]="isFlat$ | async">
        <ng-container *ngSwitchCase="false">Set Flat price</ng-container>
        <mat-form-field *ngSwitchCase="true">
          <mat-label>Flat Price{{currency ? ' (' + currency + ')' : ''}}</mat-label>
          <input matInput formControlName="price" type="number" autocomplete="off" step="0.0001" min="0" >
        </mat-form-field>
      </mat-radio-button>
      <mat-radio-button value="formula" [class.tc-active-radio-button]="isFlat$ | async" [class.tc-active-formula]="isFormula$ | async" [ngSwitch]="isFormula$ | async">
        <ng-container *ngSwitchCase="false">Set Formula</ng-container>
        <ng-container *ngSwitchCase="true">
          <div class="formula-form">
            <mat-form-field>
              <mat-label>USDA Product Name</mat-label>
              <input matInput formControlName="usdaProductName" autocomplete="off" >
            </mat-form-field>
            <span style="text-align: center">weighted average USDA FOB plant, afternoon closing price</span>
            <mat-form-field>
              <mat-label>Days</mat-label>
              <input matInput formControlName="daysBeforePickup" type="number" autocomplete="off" min="0" >
            </mat-form-field>
            <span>prior to the pick up date.</span>
          </div>
          <div *ngFor="let lineForm of linesForm.controls; let index = index" class="line-form" [formGroup]="lineForm">
            <span style="text-align: right">- Sheet +</span>
            <mat-form-field>
              <mat-label>Price</mat-label>
              <input matInput autocomplete="off" formControlName="price">
            </mat-form-field>
            <span>{{priceSuffix$ | async}}</span>
            <div>
              <tc-select-search placeholder="Location"
                  [group]="lineForm" ctrlName="location"
                  [items]="filteredLocations$(index) | async" bindValue="location_id" bindLabel="name"
                  ></tc-select-search>
              <button mat-button type="button" (click)="toggleLocations(index)">
                <small>{{ (allVisible$ | async)[index] ? 'Show Party Locations' : 'Show All Locations' }}</small>
              </button>
            </div>
            <mat-form-field>
              <mat-label>Conditions</mat-label>
              <input matInput autocomplete="off" formControlName="conditions">
            </mat-form-field>
            <button type="button" mat-icon-button color="warn" (click)="removeLine(index)" *ngIf="linesForm.length > 1">
              <mat-icon class="fa fa-minus-circle"></mat-icon>
            </button>
          </div>
          <div class="text-right">
            <button mat-button type="button" (click)="addNewLine()">
              <i class="fa fa-plus"></i> ADD FORMULA LINE</button>
          </div>
        </ng-container>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-check"></i> Update
    </button>
  </div>
</form>
