import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { ContactsListComponent } from './contacts-list.component'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { TypeSafeMatCellDefModule } from '../../../../../components/type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { MatButtonModule } from '@angular/material/button'
import { CompanyContactFormService } from '../../../settings/companies/companies/form/sub-forms/company-contact-form.service'


@NgModule({
  declarations: [ContactsListComponent],
  providers: [CompanyContactFormService],
  exports: [ContactsListComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    PipesModule,
    MatMenuModule,
    MatIconModule,
    TypeSafeMatCellDefModule,
    MatButtonModule,
  ],
})
export class ContactsListModule { }
