import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TableKey, TableView } from '@tradecafe/types/core'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { filter, find, isEqual, map as _map, omit, reject, sortBy } from 'lodash-es'
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { TableViewsService } from 'src/services/data/table-views.service'
import { ColumnDef, FiltersFormGroup, showInternalColumns } from 'src/services/table-utils'
import { TableViewFilter } from 'src/services/table-utils/filters.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { ConfigureColumnsService } from '../configure-columns/configure-columns.service'
import { SaveViewFormService } from '../save-view/save-view.service'
import { ShareViewFormService } from '../share-view/share-view.service'
import { tableViewsUpgrade, TableViewUpdater } from './table-views-upgrade'


/**
 * Views Menu - dropdown with table views
 */
@Component({
  selector: 'tc-table-views-menu',
  templateUrl: './table-views-menu.component.html',
  styleUrls: ['./table-views-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableViewsMenuComponent<T, F extends TableViewFilter> extends OnDestroyMixin implements OnInit {

  constructor(
    private TableViews: TableViewsService,
    private SaveViewForm: SaveViewFormService,
    private ShareViewForm: ShareViewFormService,
    private ConfirmModal: ConfirmModalService,
    private ConfigureColumns: ConfigureColumnsService,
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
  ) { super() }

  @Input()
  tableIdentity: TableKey

  @Input()
  updater?: TableViewUpdater

  @Input()
  filters: FiltersFormGroup<F>

  @Input()
  availableColumns: string[]

  @Input()
  columnDefs: Dictionary<ColumnDef>

  @Output()
  tableView = new EventEmitter()

  private tableViews$ = new BehaviorSubject<TableView[]>(undefined)
  myTableViews$ = this.tableViews$.pipe(map(views =>
    filter(views, { user_id: this.AuthApi.currentUser.user_id })))
  sharedTableViews$ = this.tableViews$.pipe(map(views => {
    const {user_id} = this.AuthApi.currentUser
    views = reject(views, { user_id })
    return views.filter(view => view.is_public || view.shared?.includes(user_id))
  }))
  hasSharedTableViews$ = this.sharedTableViews$.pipe(
    map(views => !!views.length),
    distinctUntilChanged())

  activeView$: Observable<string>

  private reloadTableViews$ = new Subject<void>()

  ngOnInit() {
    this.activeView$ = combineLatest([
      this.tableViews$,
      this.filters.valueChanges.pipe(startWith(this.filters.value)),
    ]).pipe(map(([tableViews, filters]) =>
      find(tableViews, { table_view_id: filters.table_view_id })?.attributes.name || 'Default View'))

    this.reloadTableViews$.pipe(
      switchMap(() => from(this.TableViews.getFor(this.tableIdentity))),
      untilComponentDestroyed(this))
    .subscribe(tableViews => {
      tableViews = sortBy(tableViews, 'attributes.name')
      if (this.updater) tableViews = tableViewsUpgrade(tableViews as any, this.updater)
      this.tableViews$.next(tableViews)
    })

    this.reloadTableViews$.next()
  }

  async saveNewTableView() {
    const filters = omit(this.filters.value, 'table_view_id')
    const view = await this.SaveViewForm.saveTableView({
      filters,
      table: this.tableIdentity,
      attributes: { version: 3 },
    })
    this.restoreTableView(view)
    this.reloadTableViews$.next()
  }

  async updateTableView() {
    const filters = omit(this.filters.value, 'table_view_id')
    const view = await this.SaveViewForm.updateTableView({ ...this.currentView(), filters })
    this.restoreTableView(view)
    this.reloadTableViews$.next()
  }

  shareTableView() {
    return this.ShareViewForm.shareTableView(this.tableIdentity, this.currentView())
  }

  async deleteTableView() {
    const view = this.currentView()
    await this.ConfirmModal.show({
      title: `Delete "${view.attributes.name || 'this'}" view`,
      description: `Are you sure you want to delete "${view.attributes.name || 'this'}" view? Deleted views cannot be recovered and are permanently removed from the system.`,
    })

    try {
      await this.TableViews.remove(view)
      this.toaster.success(`View ${view.attributes.name || ''} deleted successfully.`)
    } catch (err) {
      console.error(`Unable to delete "${view.attributes.name || 'this'}" view.`, err)
      this.toaster.error(`Unable to delete "${view.attributes.name || 'this'}" view.`, err)
    }
    this.reloadTableViews$.next()
  }

  async configureColumns() {
    const availableColumns = this.availableColumns || _map(this.columnDefs, 'field')
    const available = filter(this.columnDefs, c => availableColumns.includes(c.field))
    const {selected, custom} = await this.ConfigureColumns.showColumnsConfig({
      available: available.filter(c => !c.internal),
      selected: this.filters.value.columns as string[],
    })
    const columns = showInternalColumns(available, selected)
    this.filters.patchValue({ columns/* , custom */ } as Partial<F>)
  }

  restoreTableView(view: TableView) {
    this.tableView.emit(view)
  }

  isModified = (original: TableView) => {
    const originalFilters = original?.filters || omit(this.filters.defaultValues, 'table_view_id')
    const currentFilters = omit(this.filters.value, 'table_view_id')
    return !isEqual(originalFilters, currentFilters)
  }

  sharedOrNotSelected() {
    const view = this.currentView()
    if (!view) return true // not selected
    const isShared = view.is_public &&
      view.user_id !== this.AuthApi.currentUser.user_id &&
      view.shared?.includes(this.AuthApi.currentUser.user_id)
    return !!isShared
  }

  isSelected(view: TableView) {
    return (!view && !this.currentView()?.table_view_id) ||       // null == default view
        view?.table_view_id === this.currentView()?.table_view_id // is selected
  }

  getItemId(_i, view: TableView) {
    return view.table_view_id
  }

  private currentView() {
    const table_view_id = this.filters.value.table_view_id
    return find(this.tableViews$.value, { table_view_id })
  }
}
