import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableKey } from '@tradecafe/types/core';

export interface CompanyLogOverlayOptions {
  title: string;
  account: string;
}

@Component({
  selector: 'tc-company-log-overlay',
  templateUrl: './company-log-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyLogOverlayComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: CompanyLogOverlayOptions,
    private dialogRef: MatDialogRef<CompanyLogOverlayComponent, void>
  ) {}

  tableIdentity = TableKey.LogisticsFreightRatesAudit;
  title = this.dialogData.title;
  auditLogFilter = {
    action: 'ACCOUNT_STATUS_UPDATED',
    account: this.dialogData.account,
  };

  cancel() {
    this.dialogRef.close();
  }
}
