import { Injectable } from '@angular/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as dayOfYearPlugin from 'dayjs/plugin/dayOfYear'
import { chunk, find, head, keyBy, lowerCase, map, sumBy, uniq } from 'lodash-es'
import { combineLatest, from, Observable } from 'rxjs'
import { filter, map as _map } from 'rxjs/operators'
import { BusinessTypeApiService } from 'src/api/business-type'
import { InvoiceRow } from 'src/api/invoice'
import { CsvService } from 'src/components/csv-exporter/csv.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { dayjs } from '../dayjs'
import { AccountsService } from './accounts.service'
import { InvoiceElasticSearchService } from './invoice-elastic.service'

dayjs.extend(dayOfYearPlugin)


/**
 *
 * @see https://tradecafe.atlassian.net/browse/WA-3441
 *
 */
@Injectable()
export class InvoicesHSBCExportService {
  constructor(
    private Csv: CsvService,
    private Accounts: AccountsService,
    private toaster: ToasterService,
    private BusinessTypeApi: BusinessTypeApiService,
    private InvoiceElastic: InvoiceElasticSearchService,
  ) {}

  exportEFTPayments(tableFilters: any) {
    this.fetchInvoices(tableFilters).subscribe(invoices => {
      const { batch_id, currency } = invoices[0]
      if (currency === 'CAD') {
        this.exportEFTLayout(invoices, 'CAD', batch_id, '0024967475', '001610002', '496747001')
      } else if (currency === 'USD') {
        this.exportEFTLayout(invoices, 'USD', batch_id, '0024967476', '001610002', '496747070')
      } else {
        this.toaster.warning('Currency should be CAD or USD.')
      }
    })
  }

  private checkInvoices(invoices: DeepReadonly<InvoiceRow[]>) {
    if (!invoices || !invoices.length) {
      this.toaster.warning('No vendor invoice to export.')
      return false
    }

    if (uniq(map(invoices, 'batch_id')).length > 1) {
      this.toaster.warning('All invoices should have the same batch id.')
      return false
    }

    if (uniq(map(invoices, 'currency')).length > 1) {
      this.toaster.warning('More than one currency is in this batch.')
      return false
    }

    const bankInfoMissingCompany = invoices.map(i => i.viewEx.company).find(c => !c.banking?.payment_type)
    if (bankInfoMissingCompany) {
      this.toaster.warning(`bank information missing for company ${bankInfoMissingCompany.name}(${bankInfoMissingCompany.account})`)
      return false
    }

    if (uniq(invoices.map(i => i.viewEx.company.banking?.payment_type)).length > 1) {
      this.toaster.warning('All companies should have the same payment type in this batch.')
      return false
    }

    const scheduledDates = invoices.map(invoice => {
      const date = invoice.attributes.scheduled_date
      return date ? dayjs.utc(date * 1000).format('YYMMDD') : ''
    })
    if (uniq(scheduledDates).length > 1) {
      this.toaster.warning('All invoice should have the same scheduled date.')
      return false
    }

    return true
  }

  private fetchInvoices(tableFilters: any): Observable<InvoiceRow[]> {
    return combineLatest([
      this.InvoiceElastic.fetchAllInvoices({ ...tableFilters, columns: ['net_payable'], sort: { id: 'created', start: 'asc' }}),
      // TODO: rewrite to ngrx actions and selectors
      from(this.Accounts.getAccounts().then(r => keyBy(r.data, 'account'))),
      from(this.BusinessTypeApi.list({ limit: Number.MAX_SAFE_INTEGER }).then(({ data }) => keyBy(data, 'business_type_id'))),
    ]).pipe(
      _map(([invoices, accounts, businessTypes]) => invoices.map(invoice => ({
        ...invoice,
        viewEx: {
          ...invoice.viewEx,
          company: accounts[invoice.account],
          businessType: businessTypes[accounts[invoice.account]?.attributes?.business_type_id],
        },
      } as InvoiceRow))),
      filter(invoices => this.checkInvoices(invoices)),
    )
  }

  private exportEFTLayout(
    invoices: DeepReadonly<InvoiceRow[]>,
    currency: string,
    batchId: string,
    eftOriginatorId: string,
    transactionReturnFI: string,
    transactionReturnAccount: string,
  ) {
    const header = this.getEFTLayoutHeader({index: 1, batchId, currency, eftOriginatorId})
    const records = map(chunk(invoices, 6), (segments, index) => {
      return this.mapInvoiceToEFTLayout(segments, {
        index: index + 2,
        batchId,
        eftOriginatorId,
        transactionReturnFI,
        transactionReturnAccount,
      })
    })
    const trailer = this.getEFTLayoutTrailer({
      index: 2 + records.length,
      eftOriginatorId,
      batchId,
      creditNum: invoices.length,
      creditTotal: sumBy(invoices, i => i.view.net_payable),
      debitNum: 0,
      debitTotal: 0,
    })

    const content = [header, ...records, trailer].join('\n')
    this.Csv.downloadTextAs(content, `Electronic Funds Transfer – Canadian Domestic ${currency} EFT Payments - ${eftOriginatorId} - ${batchId}.txt`)
  }

  private getEFTLayoutHeader({index, batchId, currency, eftOriginatorId}) {
    const now = dayjs()
    const field1 = 'A'
    const field2 = padLeft(index, 9, '0')
    const field3 = padLeft(eftOriginatorId, 10, ' ').slice(-10)
    const field4 = padLeft(batchId, 4, '0').slice(-4)
    const field5 = `0${now.format('YY')}${padLeft(now.dayOfYear(), 3, '0')}`
    const field6 = '01600'
    const field7 = padLeft('', 20, ' ')
    const field8 = currency
    const field9 = padLeft(' ', 1406, ' ')
    return `${field1}${field2}${field3}${field4}${field5}${field6}${field7}${field8}${field9}`
  }

  private mapInvoiceToEFTLayout(
    segments: DeepReadonly<InvoiceRow[]>,
    {index, batchId, eftOriginatorId, transactionReturnFI, transactionReturnAccount},
  ) {
    const field1 = 'C'
    const field2 = padLeft(index, 9, '0')
    const field3 = padLeft(eftOriginatorId, 10, ' ').slice(-10)
    const field4 = padLeft(batchId, 4, '0').slice(-4)

    const segmentsData = segments.map(invoice => {
      const currency = invoice.currency
      const scheduledDate = dayjs.utc((invoice.attributes.scheduled_date || 0) * 1000)
      const company = invoice.viewEx.company

      const field5 = '460'
      const field6 = padLeft(((invoice.view.net_payable || 0) * 100).toFixed(0), 10, '0')
      const field7 = '0'
      const field8 = scheduledDate.format('YY')
      const field9 = padLeft(scheduledDate.dayOfYear(), 3, '0')

      const field10 = padLeft(company.banking?.bank_code || '', 4, '0').slice(-4)
      const field11 = padLeft(company.banking?.transit || '', 5, '0').slice(-5)
      const field12 = padRight(company.banking?.[`account_${lowerCase(currency)}`] || '', 12, ' ')
      const field13 = padLeft('', 22, '0')
      const field14 = padLeft('', 3, '0')
      const field15 = padRight('BWI', 15, ' ')
      const field16 = padRight(`${company.name} ${invoice.viewEx.businessType?.name}`, 30, ' ').slice(0, 30)
      const field17 = padRight('Bassett & Walker International Inc.', 30, ' ').slice(0, 30)
      const field18 = padLeft(eftOriginatorId, 10, ' ').slice(-10)
      const field19 = padLeft(invoice.invoice_id, 19, ' ').slice(-19)

      const field20 = padRight(transactionReturnFI, 9, ' ')
      const field21 = padRight(transactionReturnAccount, 12, ' ')
      const field22 = padLeft('', 15, ' ')
      const field23 = padLeft('', 22, ' ')
      const field24 = padLeft('', 2, ' ')
      const field25 = padLeft('', 11, '0')

      return `${field5}${field6}${field7}${field8}${field9}${field10}${field11}${field12}${field13}${field14}${field15}${field16}${field17}${field18}${field19}${field20}${field21}${field22}${field23}${field24}${field25}`
    })
    const segmentLength = 240
    const padding = padLeft('', segmentLength * (6 - segments.length), ' ')

    return `${field1}${field2}${field3}${field4}${segmentsData.join('')}${padding}`
  }

  private getEFTLayoutTrailer({index, batchId, eftOriginatorId, debitNum, debitTotal, creditNum, creditTotal}) {
    const field1 = 'Z'
    const field2 = padLeft(index, 9, '0')
    const field3 = padLeft(eftOriginatorId, 10, ' ').slice(-10)
    const field4 = padLeft(batchId, 4, '0').slice(-4)
    const field5 = padLeft((debitTotal * 100).toFixed(0), 14, '0')
    const field6 = padLeft(debitNum, 8, '0')
    const field7 = padLeft((creditTotal * 100).toFixed(0), 14, '0')
    const field8 = padLeft(creditNum, 8, '0')
    const field9 = padLeft('', 14, '0')
    const field10 = padLeft('', 8, '0')
    const field11 = padLeft('', 14, '0')
    const field12 = padLeft('', 8, '0')
    const field13 = padLeft(' ', 1352, ' ')

    return `${field1}${field2}${field3}${field4}${field5}${field6}${field7}${field8}${field9}${field10}${field11}${field12}${field13}`
  }


  async exportUSCrossBorderPayments(tableFilters: any) {
    return this.fetchInvoices(tableFilters).subscribe(invoices => {
      const emitterConnectId = 'ABC66560001'

      const now = dayjs()
      const firstInvoice = head(invoices)
      const batchId = firstInvoice.batch_id
      const scheduledDateUTC = firstInvoice.attributes.scheduled_date
      const scheduledDate = scheduledDateUTC > 0 ? dayjs.utc(scheduledDateUTC * 1000) : now

      const hsbcHeader = ['1', 'CSVGD', 'V', `${batchId}`, emitterConnectId, now.format('YYYYMMDD'), '' + invoices.length]
      const tcHeader = ['2', `USACH${batchId}`, 'GD', scheduledDate.format('YYYYMMDD'), '', '002496747070', 'CA', '']
      const records = invoices.map(invoice => this.mapInvoiceToXBorderPayment(invoice))

      const content = [hsbcHeader, tcHeader, ...records]
      this.Csv.downloadCsvAs(content, `US Cross border Payments - ${batchId}`)
    })
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private mapInvoiceToXBorderPayment(invoice: InvoiceRow): string[] {
    const company = invoice.viewEx.company
    const companyPrimaryAddress = find(company.addresses || [], 'primary')

    const street1 = companyPrimaryAddress?.street1?.trim() || ''
    const [buildingNo, ...streetNames] = street1.split(' ')
    return [
      '3',
      `C${invoice.invoice_id}`.replace('-', ''), // Customer payment reference
      'USD',
      invoice.view.net_payable?.toFixed(2), // Payment amount
      'E', // Amount type, Must always be “E” for Equivalent Amount
      invoice.vendor_invoice_id, // Payment details, Sender to receiver information – free format text
      '', '', '', '', // 7 ~ 10
      escapeComma(company.name).slice(0, 35), // Beneficiary name
      '', '', '', '', // 12 ~ 15,
      'US', // Beneficiary country of residence
      'C', // Beneficiary account type C = Checking and S = Savings
      '', // 18
      company.banking?.account_usd || '', // Beneficiary account number
      escapeComma(company.name).slice(0, 35), // Beneficiary account name
      '', // 21
      company.banking?.aba || '',
      '', '', // 23 ~ 24
      escapeComma(company.banking?.name || '').slice(0, 35),
      escapeComma(company.banking?.street1 || '').slice(0, 35),
      escapeComma(`${company.banking?.city || ''} ${company.banking?.state || ''}`).slice(0, 35),
      company.banking?.postal || '',
      '', '', '', // 29 ~ 31
      'TRAD', // Purpose code
      '', '', '', '', '', '', '', '', // 33 ~ 40
      escapeComma(buildingNo || ''), // Beneficiary Building Number
      escapeComma(streetNames.join(' ')).slice(0, 35),
      escapeComma(companyPrimaryAddress?.city || '').slice(0, 35),
      escapeComma(companyPrimaryAddress?.state || '').slice(0, 35),
      (companyPrimaryAddress?.postal || '').slice(0, 16),
      '', '', '', // 46 ~ 48
      'US', // Beneficiary Country
      'O', // Beneficiary Customer Type, Indicate whether the Beneficiary is a Private Individual (P) or Corporate Entity (O)
      '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', // 51 ~ 73
      'US',  // Beneficiary Bank Country Code
    ]
  }
}

function escapeComma(text) {
  return (text + '').replace(/,/g, '?,')
}

// https://github.com/thekemkid/string-padder/blob/master/padder.js
function createPadString(padLength, padString) {
  let str = ''

  while (str.length < padLength) {
    let i = 0
    while (i < padString.length && str.length < padLength) {
      str += padString.charAt(i)
      i += 1
    }
  }

  return str
}

function padLeft(str, padLength, padStr) {
  let moddedStr = str || ''
  const pL = padLength || 0
  let pS = padStr || ' '

  // convert the input to a string if it is not a string
  if (!(typeof moddedStr === 'string')) {
    moddedStr = moddedStr.toString()
  }

  // convert to pad string if it is not a string
  if (!(typeof pS === 'string')) {
    pS = pS.toString()
  }

  const padStrLength = pL - moddedStr.length

  if (padLength > 0) {
    moddedStr = createPadString(padStrLength, pS) + moddedStr
  }

  return moddedStr
}

function padRight(str, padLength, padStr) {
  let moddedStr = str || ''
  const pL = padLength || 0
  let pS = padStr || ' '

  // convert the input to a string if it is not a string
  if (!(typeof moddedStr === 'string')) {
    moddedStr = moddedStr.toString()
  }

  // convert to pad string if it is not a string
  if (!(typeof pS === 'string')) {
    pS = pS.toString()
  }


  const padStrLength = pL - moddedStr.length

  if (padStrLength > 0) {
    moddedStr += createPadString(padStrLength, pS)
  }

  return moddedStr
}
