import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import {
    createBooking,
    createBookingSuccess,
    createBookingFailure,
    approveBookingFailure,
    approveBookingSuccess,
    approveBooking,
    rejectBookingSuccess,
    rejectBookingFailure,
    rejectBooking,
    putBookingOnHold,
    putBookingOnHoldSuccess,
    putBookingOnHoldFailure,
} from './booking.actions'
import { throwError } from 'rxjs'
import { BookingsApiService } from 'src/api/booking'

@Injectable()
export class BookingEffects {
  constructor(
    private actions$: Actions,
    private bookings: BookingsApiService,
    private toaster: ToasterService,
  ) {}

  createBooking$ = createEffect(() => this.actions$.pipe(
    ofType(createBooking),
    switchMap(action =>
      from(this.bookings.sendRequest(action)).pipe(
        map(response => createBookingSuccess({ booking: response.data })),
        tap(() => this.toaster.success('Booking request sent successfully')),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to send booking request', error)
          this.toaster.error('Unable to send booking request', error)
          return of(createBookingFailure({ error }))
  })))))

  approveBooking$ = createEffect(() => this.actions$.pipe(
    ofType(approveBooking),
    switchMap(action =>
      from(this.bookings.approve(action.bookingId)).pipe(
        map(response => approveBookingSuccess({ booking: response })),
        tap(() => this.toaster.success('Booking approved successfully')),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to approve booking', error)
          this.toaster.error('Unable to approve booking', error)
          return of(approveBookingFailure({ error }))
  })))))

  rejectBooking$ = createEffect(() => this.actions$.pipe(
    ofType(rejectBooking),
    switchMap(action =>
      from(this.bookings.reject(action.bookingId)).pipe(
        map(response => rejectBookingSuccess({ booking: response })),
        tap(() => this.toaster.success('Booking rejected successfully')),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to reject booking', error)
          this.toaster.error('Unable to reject booking', error)
          return of(rejectBookingFailure({ error }))
  })))))

  putBookingOnHold$ = createEffect(() => this.actions$.pipe(
    ofType(putBookingOnHold),
    switchMap(action =>
      from(this.bookings.putOnHold(action.bookingId)).pipe(
        map(response => putBookingOnHoldSuccess({ booking: response })),
        tap(() => this.toaster.success('Booking put on hold successfully')),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to put booking on hold', error)
          this.toaster.error('Unable to put booking on hold', error)
          return of(putBookingOnHoldFailure({ error }))
  })))))
}
