import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { combineLatest, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { loadAccounts } from 'src/app/store/accounts'
import { loadCarriers } from 'src/app/store/carriers'
import { loadCurrencies } from 'src/app/store/currencies'
import { saveDealFormFailure, saveDealFormSuccess } from 'src/app/store/deal-view.actions'
import { loadLocations } from 'src/app/store/locations'
import { loadMeasures } from 'src/app/store/measures'
import { loadProducts } from 'src/app/store/products'
import { UploaderDialogService } from 'src/components/uploader-dialog/uploader-dialog.service'
import { CostFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/cost-form/cost-form.service'
import { DealFormBaseController } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form-base.component'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { DealDocumentsService } from '../../../deal-documents/deal-documents.service'

export interface DealDetailsOverlayOptions {
  dealId: string
}

@Component({
  selector: 'tc-deal-details-overlay',
  templateUrl: './deal-details-overlay.component.html',
  styleUrls: ['./deal-details-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealDetailsOverlayComponent extends DealFormBaseController {

  constructor(
    DealForm: DealFormService,
    store: Store,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) dialogData: DealDetailsOverlayOptions,
    private dialogRef: MatDialogRef<DealDetailsOverlayOptions>,
    protected UploaderDialog: UploaderDialogService,
    protected DealDocuments: DealDocumentsService,
    protected toaster: ToasterService,
    CostForm: CostFormService,
  ) {
    super(of(dialogData.dealId), DealForm, store, UploaderDialog, DealDocuments, toaster, CostForm)

    combineLatest([
      this.actions$.pipe(ofType(saveDealFormSuccess), tap(() => this.dialogRef.close())),
      this.actions$.pipe(ofType(saveDealFormSuccess, saveDealFormFailure), tap(() => this.inProgress$.next(undefined))),
    ]).pipe(untilComponentDestroyed(this)).subscribe()

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadMeasures({}))
    this.fetchDeal().subscribe()
  }

  @ViewChild(DealViewIncompleteComponent) incomplete: DealViewIncompleteComponent

  cancel() {
    this.dialogRef.close()
  }
}
