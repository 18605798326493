import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableKey } from '@tradecafe/types/core';

export interface FreightRatesLogOverlayOptions {
  title: string;
  rate_id: string;
}

@Component({
  selector: 'tc-freight-rates-log-overlay',
  templateUrl: './freight-rates-log-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightRatesLogOverlayComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: FreightRatesLogOverlayOptions,
    private dialogRef: MatDialogRef<FreightRatesLogOverlayComponent, void>
  ) {}

  tableIdentity = TableKey.LogisticsFreightRatesAudit;
  title = this.dialogData.title;
  auditLogFilter = { rate_id: this.dialogData.rate_id };

  cancel() {
    this.dialogRef.close();
  }
}
