import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, isEqual } from 'lodash-es'
import { Subject } from 'rxjs'
import { ColumnDef } from 'src/services/table-utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CsvFormatterService } from './csv-formatter.service'
import { CsvService } from './csv.service'
import { environment } from 'src/environments/environment'


export interface CsvExporterOptions<T> {
  // source type
  sourceType?: string
  // data source
  dataSource: MatTableDataSource<T>
  // selected rows
  selected: T[]
  // desired csv filename (w/o extension)
  fileName: string
  // visible column names
  visible: string[]
  // available column names
  available: string[]
  // columns definitions
  columnDefs: Dictionary<ColumnDef>

  // load all rows
  loadAll?: (columns: string[]) => Promise<T[]>
  // load more data for selected rows
  loadByIds?: (rows: T[], columns: string[]) => Promise<T[]>

  loadCsvData?: (rows: T[], colunmIds: string[], columns: ColumnDef[]) => Promise<Blob>

  // invoice-only option. too minor to create separate component or mess with TemplateReff
  overrideBatch?: (rows: T[], batchId: string) => Promise<T[]>
}

@Component({
  selector: 'tc-csv-exporter',
  templateUrl: './csv-exporter.component.html',
  styleUrls: ['./csv-exporter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsvExporterDialogComponent<T> extends OnDestroyMixin implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private csv: CsvService,
    private toaster: ToasterService,
    private csvFormatter: CsvFormatterService,
    private dialogRef: MatDialogRef<CsvExporterDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) private dialogData: CsvExporterOptions<T>,
  ) { super() }

  readonly inProgress$ = new Subject<number>()

  readonly selected = this.dialogData.selected.length
  readonly overrideBatch = !!this.dialogData.overrideBatch
  readonly canSelectColumns = !isEqual(this.dialogData.available, this.dialogData.visible)
  // Feature flags
  readonly enableExportService = environment.enableExportService

  readonly form = this.fb.group({
    rowType: [this.selected ? 'selected' : 'all', Validators.required],
    columnType: [this.canSelectColumns ? 'all' : 'visible', Validators.required],
    overrideBatch: [],
    batchId: [],
  })

  @ViewChild('batchIdInput')
  batchIdInput: ElementRef<HTMLInputElement>

  ngOnInit() {
    const { overrideBatch, batchId } = this.form.controls
    overrideBatch.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(checked => {
      batchId.setValidators(checked ? Validators.required : [])
      batchId.updateValueAndValidity()
      if (checked) this.batchIdInput.nativeElement.focus()
      else batchId.reset()
    })
  }

  // tslint:disable-next-line: cyclomatic-complexity
  async save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) return

    const { rowType, columnType, overrideBatch, batchId } = this.form.value
    if (overrideBatch && !batchId) return // invalid form

    const { sourceType } = this.enableExportService ? this.dialogData : { sourceType: '' }

    try {
      switch (sourceType) {
        case 'export-service':
          await this.downloadAndSave()
          break
        default:
          await this.prepareAndSave()
      }
    } catch (err) {
      this.toaster.error('Unable to export csv', err?.message)
    }
    this.close()
  }

  async downloadAndSave() {
    const { columnType, rowType } = this.form.value;
    const { selected, dataSource, fileName, visible, available, columnDefs } = this.dialogData
    const columnIds = columnType === 'all' ? available : visible
    const columns = compact(columnIds.map(col => columnDefs[col]).filter(c => !c?.internal))

    const resolveRows = () => {
      switch (rowType) {
        case 'all':
          return [];
        case 'selected':
          return selected
        default:
          return this.getVisibleData(dataSource)
      }
    }

    const visibleRows = resolveRows();

    const data = await this.downloadCSVData(visibleRows, columnIds, columns);
    this.csv.downloadBlobAs(data, `${fileName}.csv`)
  }

  async prepareAndSave() {
    const { rowType, columnType } = this.form.value;
    const { selected, dataSource, fileName, visible, available, columnDefs, sourceType } = this.dialogData
    const columnIds = columnType === 'all' ? available : visible
    const columns = compact(columnIds.map(col => columnDefs[col]).filter(c => !c?.internal))

    // select data for rows
    let rows: T[]
    if (rowType === 'all') {
      rows = await this.loadAll(columnIds)
    } else if (rowType === 'visible') {
      const visibleRows = this.getVisibleData(dataSource)
      rows = columnType === 'visible' ? visibleRows : await this.loadByIds(visibleRows, columnIds)
    } else if (rowType === 'selected') {
      rows = columnType === 'visible' ? selected : await this.loadByIds(selected, columnIds)
    }

    // override batch id
    if (this.dialogData.overrideBatch && this.form.value.overrideBatch && this.form.value.batchId) {
      rows = await this.dialogData.overrideBatch(rows, this.form.value.batchId)
    }

    // export
    this.exportToCsv(fileName, columns, rows)
  }

  close(value?: { rowType: 'all' | 'visible' | 'selected', columnType: 'all' | 'visible' }) {
    this.dialogRef.close(value)
  }

  private exportToCsv(fileName: string, columns: ColumnDef[], data: T[]) {
    if (!data?.length) return

    const headers = columns.map(col => col.displayName)
    const rows = data.map(row => columns.map(col => this.csvFormatter.getCellValue(row, col)))

    this.csv.downloadCsvAs([headers, ...rows], fileName)
  }

  private getVisibleData(dataSource: MatTableDataSource<T>) {
    const { pageIndex, pageSize } = this.dialogData.dataSource.paginator || this.dialogData.dataSource['paginatorrr']
    const startIndex = pageIndex * pageSize
    const endIndex = startIndex + pageSize
    return this.getSortedFilteredData(dataSource).slice(startIndex, endIndex)
  }

  private async loadAll(columns: string[]) {
    const { dataSource, loadAll } = this.dialogData
    return loadAll ? await loadAll(columns) : this.getSortedFilteredData(dataSource)
  }

  private async loadByIds(rows: T[], columns: string[]) {
    const { dataSource, loadByIds } = this.dialogData
    return loadByIds ? await loadByIds(rows, columns) : this.getSortedFilteredData(dataSource)
  }

  private getSortedFilteredData(dataSource: MatTableDataSource<T>) {
    return dataSource.sort ? dataSource.sortData([...dataSource.filteredData], dataSource.sort) : dataSource.filteredData
  }

  private async downloadCSVData(rows: T[], columnIds: string[], columns: ColumnDef[]): Promise<Blob> {
    const { dataSource, loadCsvData } = this.dialogData;
    try {
      return await loadCsvData(rows, columnIds, columns)
    } catch (err) {
      const errorText = await err.error.text();
      const error = JSON.parse(errorText);
      throw new Error(error.message)
    }
  }
}
