import { ComponentType } from '@angular/cdk/portal'
import { Injectable, Injector } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'

export interface MatDialogConfigEx extends MatDialogConfig {
  injector?: Injector
  /**
   * Custom class for the overlay pane. Supported options:
   *    'tc-dialog' can be combined with 'modal-xlg' or 'modal-lg' or 'modal-md'
   *    'tc-overlay' must be combined with 'right' or 'left'
   */
  panelClass?: string | string[]
  componentName?: string
}

@Injectable()
export class ModalService {

  constructor(private dialog: MatDialog) { }

  private modalNameStack = new Map()

  openDialog<T, D = any, R = any>(component: ComponentType<T>, data?: D, config?: MatDialogConfigEx) {
    const dialogRef = this.open<T, D, R>(
      component,
      {
        width: '600px',
        height: 'auto',
        panelClass: ['tc-dialog'],
        ...config,
        data,
      }
    )

    dialogRef
      .beforeClosed()
      .pipe(
        take(1)
      ).subscribe(
        () => {
          this.modalNameStack.delete(config?.componentName || component.name)
        }
      )

    return moveToForeground(
      this.dialog,
      dialogRef
        .afterClosed()
        .pipe(tap(() => {
          this.modalNameStack.delete(config?.componentName || component.name)
        }))
    )
  }

  openLargeSide<T, D = any, R = any>(component: ComponentType<T>, data?: D, config?: MatDialogConfigEx) {
    const dialogRef = this.open<T, D, R>(component, {
      width: '90%',
      height: '100%',
      minWidth: '947px', // 1024 - close button - close button margins
      // maxWidth: '90%',
      position: { right: '0px', bottom: '0px' },
      panelClass: ['tc-overlay', 'right'],
      autoFocus: false,
      ...config,
      data,
    })

    dialogRef
      .beforeClosed()
      .pipe(
        take(1)
      )
      .subscribe(
        () => {
          this.modalNameStack.delete(config?.componentName || component.name)
          dialogRef['_containerInstance']['_elementRef'].nativeElement.classList.add('side-closing')
        }
      )

    return moveToForeground(
      this.dialog,
      dialogRef
        .afterClosed()
        .pipe(tap(() => {
          this.modalNameStack.delete(config?.componentName || component.name)
        })),
    )
  }

  private open<T, D, R>(component: ComponentType<T>, config?: MatDialogConfigEx) {
    let dialogRef: MatDialogRef<T, R> = this.modalNameStack.get(config?.componentName || component.name)

    if (!dialogRef) {
      dialogRef = this.dialog.open<T, D, R>(component, config)
      this.modalNameStack.set(config?.componentName || component.name, dialogRef)
    }

    dialogRef['_ref'].overlayRef.overlayElement.parentElement.className += ' tc-modal-overlay'

    return dialogRef
  }
}


/**
 * Move Angular dialogs to background while ng1 dialog is opened (`promise`)
 *
 * @export
 * @template T
 * @param {MatDialog} dialog MatDialog service
 * @param {Promise<T>} promise
 * @return {Promise<T>} promise
 */
export async function moveToBackground<T>(dialog: MatDialog, promise: Promise<T>, cssClass): Promise<T> {
  // NOTE: this function will become unused after we upgrade all dialogs and overlays
  const overlayContainer = document.querySelector('.cdk-overlay-container') as HTMLElement
  if (!overlayContainer) return promise

  if (cssClass) {
    overlayContainer.classList.add(cssClass)
  }

  const contains = overlayContainer?.classList.contains('ng1-to-foreground')
  if (!contains) {
    overlayContainer.classList.add('ng1-to-foreground')
    overlayContainer.classList.remove('ng1-to-background')
  }

  const prevClose = dialog.openDialogs.map(d => d.disableClose)
  dialog.openDialogs.forEach(d => d.disableClose = true)

  try {
    const res = await promise
    return res
  } finally {
    if (!contains) {
      overlayContainer.classList.add('ng1-to-background')
      overlayContainer.classList.remove('ng1-to-foreground')
    }
    dialog.openDialogs.forEach((d, i) => d.disableClose = prevClose[i] || false)
  }
}

/**
 * Move Angular dialogs to foreground while they're open (`afterClosed$`)
 *
 * @template T
 * @param {MatDialog} dialog MatDialog service
 * @param {Observable<T>} afterClosed$
 * @return {Observable<T>} afterClosed$
 */
function moveToForeground<T>(dialog: MatDialog, afterClosed$: Observable<T>): Observable<T> {
  // NOTE: this function will become unused after we upgrade all dialogs and overlays
  const overlayContainer = document.querySelector('.cdk-overlay-container') as HTMLElement
  if (!overlayContainer) return afterClosed$

  const contains = overlayContainer?.classList.contains('ng1-to-background')
  if (contains) return afterClosed$

  overlayContainer.classList.add('ng1-to-background')
  overlayContainer.classList.remove('ng1-to-foreground')
  return afterClosed$.pipe(tap(() => {
    overlayContainer.classList.remove('ng1-to-background')
    overlayContainer.classList.add('ng1-to-foreground')
  }))
}
