import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { DealsListModule } from 'src/components/deals-list'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { CloneFormModule } from '../../deal-clones/clone-form/clone-form.module'
import { DealClonesComponent } from './deal-clones.component'


@NgModule({
  declarations: [DealClonesComponent],
  exports: [DealClonesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    PipesModule,
    SelectSearchModule,
    DealsListModule,
    CloneFormModule,
  ],
})
export class DealClonesModule { }
