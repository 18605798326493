import { createSelector } from '@ngrx/store'
import { ProductCategory } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, productCategoriesFeatureKey } from './product-category.reducer'

export const selectState = (state: State) => state[productCategoriesFeatureKey]

export const { selectEntities: selectProductCategoryEntities, selectAll: selectAllProductCategories } = adapter.getSelectors(selectState)

export const selectProteinCategories = (selectedCategories?: string | string[]) =>
  createSelector(
  selectAllProductCategories,
  productCategories => productCategories.filter(c =>
    c.type === 'protein' || c.category_id === selectedCategories || selectedCategories?.includes(c.category_id)),
)

export const selectProductCategory = createSelector(
  selectProductCategoryEntities,
  (productCategories: Dictionary<ProductCategory>, productCategory: string | number) =>
    productCategories[productCategory])

export const selectProductCategoriesById = createSelector(
  selectProductCategoryEntities,
  (productCategories: Dictionary<ProductCategory>, productCategoryId: string) =>
    productCategories[productCategoryId])

export const selectProductCategoriesByIds = createSelector(
  selectProductCategoryEntities,
  (productCategories: Dictionary<ProductCategory>, productCategoryIds: string[]) =>
    productCategoryIds.map(productCategoryId => productCategories[productCategoryId]))
