import { concat, isDate, isEmpty, isNumber, pick, pickBy } from 'lodash-es'

export function prepareInvoicesPayload(filters: any, page: number) {
  filters = prepareBackendFilters(filters)

  // tslint:disable-next-line: prefer-const
  let { limit, sort: sort_, table_view_id, columns, ...query } = filters
  // filter out test data
  if (!query.internal || !query.internal.includes('test')) {
    let tmpText = query.text || []
    tmpText = concat(tmpText, {
      $not: '#TEST'
    })
    query = {
      ...query,
      text: tmpText
    }
  } else {
    query.internal = query.internal.filter((i) => i !== 'test')
  }
  // if deal_id filter is set - ignore other filters except invoice type
  if (query.deal_id) query = { deal_id: query.deal_id, type: query.type || undefined }
  const skip = limit * page
  const sort = [{ [sort_.id]: sort_.start }]
  return { sort, limit, skip, query, columns }
}

export function prepareFiltersPayload(filters: any, topLevelFilters: string[]) {
  filters = prepareBackendFilters(filters)
  let { columns, ...query } = pick(filters, [...topLevelFilters, 'columns'])
  columns = columns.map(col => col === 'deal_id' ? 'deal_ids' : col)
  // if deal_id filter is set - ignore other filters
  if (query.deal_id) query = { deal_id: query.deal_id, type: query.type || undefined }
  return { columns, query }
}

/**
 * Prepare FilterForm value for backend
 *
 * @private
 * @param {*} filters
 * @returns
 */
function prepareBackendFilters(filters: any) {
  filters = { ...filters } // make mutable

  // delete empty values
  filters = pickBy(filters, v => isDate(v) || isNumber(v) || !isEmpty(v))

  // convert _to + _from to {to,from}
  Object.keys(filters).filter(k => k.endsWith('_to')).forEach(toKey => {
    const key = toKey.replace(/_to$/gi, '')
    const from = filters[`${key}_from`]
    const to = filters[`${key}_to`]
    delete filters[`${key}_from`]
    delete filters[`${key}_to`]
    if (filters.hasOwnProperty(key)) throw new Error(`Can not override existing filter ${key}`)
    filters[key] = { from, to }
  })

  return filters
}
