<form (submit)="saveDeal()" [formGroup]="dealForm">
  <div mat-dialog-title>
    <h2>{{dealId$ | async}} Shipping Details</h2>
    <tc-locks [resourceId]="dealId$" resourceType="deal"></tc-locks>
    <tc-finance-issues [detailsForm]="dealForm.controls.details"></tc-finance-issues>
    <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
    <span fxFlex></span>
    <button mat-button type="button" (click)="cancel()">
      Cancel
    </button>
    <button mat-raised-button type="submit" color="primary"
        [matTooltip]="incomplete.incomplete" matTooltipClass="pre-tooltip"
        [disabled]="!!incomplete.incomplete">
      <i class="fa fa-fw"
          [class.fa-save]="!(inProgress$ | async)"
          [class.fa-spin]="inProgress$ | async"
          [class.fa-spinner]="inProgress$ | async"
          ></i> Save
    </button>
  </div>
  <div mat-dialog-content>
    <tc-shipping-details-form
        [dealForm]="dealForm"
        [dealId$]="dealId$"
        [dealViewRaw$]="dealViewRaw$"
        [isReadonly]="isReadonly || !!incomplete.incomplete"
        ></tc-shipping-details-form>
  </div>
</form>
