import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadLocations, loadLocationsFailure, loadLocationsSuccess } from './location.actions'

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private locations: LocationsService,
    private toaster: ToasterService,
  ) {}

  loadLocations$ = createEffect(() => this.actions$.pipe(
    ofType(loadLocations),
    switchMap(action =>
      from(this.locations.getLocations()).pipe(
        map(r => loadLocationsSuccess({
          tableKey: action.tableKey,
          locations: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch locations', error)
          this.toaster.error('Unable to fetch locations', error)
          return of(loadLocationsFailure({ error }))
        })))))
}
