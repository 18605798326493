import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll'
import { ApiModule } from 'src/api/api.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { DealStatusModule } from '../../deal-status/deal-status.module'
import { EpochRangeFieldModule } from '../../epoch/epoch-range-field/epoch-range-field.module'
import { InlineEditorModule } from '../../inline-editor/inline-editor.module'
import { NoteFormModule } from '../../notes/note-form/note-form.module'
import { SelectSearchModule } from '../../select-search/select-search.module'
import { TypeSafeMatCellDefModule } from '../../type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { InvoicesListComponent } from './invoices-list.component'

@NgModule({
  declarations: [InvoicesListComponent],
  exports: [InvoicesListComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    DealStatusModule,
    SelectSearchModule,
    ScrollingModule,
    PipesModule,
    InlineEditorModule,
    TypeSafeMatCellDefModule,
    EpochRangeFieldModule,
    TableVirtualScrollModule,
    NoteFormModule,
    ApiModule
  ],
})
export class InvoicesListModule { }
