import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { FeedsBellModule } from './feeds-bell/feeds-bell.module'
import { HeaderComponent } from './header.component'
import { LogoModule } from './logo/logo.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    FlexLayoutModule,
    FeedsBellModule,
    LogoModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
