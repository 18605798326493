import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { HybridRouter } from './angularjs/hybrid-router.service'
import { serializeUrl } from './app-routing.module'

@Component({
  selector: 'tc-root',
  template: `
    <ngx-loading-bar></ngx-loading-bar>
    <tc-header></tc-header>
    <router-outlet class="tc-angular-page"></router-outlet>
    <div ui-view ng-cloak class="ng1-router-outlet"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [`
    body:not(.tc-login-layout) tc-login,
    .tc-login-layout tc-header,
    .ng1-route-inactive .ng1-router-outlet,
    .ng1-route-active > .ng-star-inserted {
      display: none !important;
    }
  `],
})
export class AppComponent extends OnDestroyMixin {
  @HostBinding('class.ng1-route-active')
  ngRouteActive = false

  @HostBinding('class.ng1-route-inactive')
  ngRouteInactive = false

  loginLayout$ = this.router.urlChanged$.pipe(
    map(x => serializeUrl(x.url) === 'login' || serializeUrl(x.url) === 'forgot-password' || serializeUrl(x.url) === 'reset-password'),
    distinctUntilChanged())

  constructor(private router: HybridRouter, private authService: AuthApiService) {
    super()
    this.authService.setRealUser()
    this.loginLayout$.pipe(untilComponentDestroyed(this)).subscribe(loginLayout => {
      if (loginLayout) document.body.classList.add('tc-login-layout')
      else document.body.classList.remove('tc-login-layout')
    })
    this.router.angularChanged$.pipe(untilComponentDestroyed(this)).subscribe(ng1 => {
      this.ngRouteActive = ng1
      this.ngRouteInactive = !ng1
    })
  }
}
