import { Injectable } from '@angular/core'
import * as FileSaver from 'file-saver'


@Injectable()
export class CsvService {

  stringify(data: string[][]) {
    // escape csv special characters
    data = data.map(row => row.map(value => {
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""')
        value = value.search(/("|[^?],|\n)/g) >= 0 ? `"${value}"` : value
      }
      return value
    }))

    return data.map(row => row.join(',')).join('\n')
  }

  downloadCsvAs(data: string[][], fileName: string) {
    return this.downloadTextAs(this.stringify(data), `${fileName}.csv`)
  }

  downloadTextAs(data: string, fileName: string) {
    const utf8encoding = new Uint8Array([0xEF, 0xBB, 0xBF])
    const blob = new Blob([utf8encoding, data], { type: 'text/plain;charset=utf-8' })
    this.downloadBlobAs(blob, fileName)
  }

  downloadBlobAs(data: Blob, fileName: string) {
    FileSaver.saveAs(data, fileName)

  }
}
