import { HttpErrorResponse } from '@angular/common/http'
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, Booking, Carrier, Consignee, DealViewRawBids, DealViewRawDeal, DealViewRawSegments, GeneralAddress, ItemType, LocationObject, MontshipBookingRequest, Product, Segment, ShipmentType, ShipmentTypes, Unlocode } from '@tradecafe/types/core'
import { DeepReadonly, lookupSegments, matchItemTypeTemperature } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, last, uniq } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, merge, of } from 'rxjs'
import { catchError, concatMap, defaultIfEmpty, distinctUntilChanged, filter, map, mapTo, switchMap, take } from 'rxjs/operators'
import { BookingsApiService } from 'src/api/booking'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { createBooking, createBookingFailure, createBookingSuccess } from 'src/app/store/booking'
import { loadCarriers, selectAllCarriers, selectCarrier, selectCarrierEntities } from 'src/app/store/carriers'
import { loadConsignees } from 'src/app/store/consignees'
import { loadItemTypes, selectItemTypeEntities } from 'src/app/store/item-types'
import { loadLocations, selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadProducts, selectProductEntities } from 'src/app/store/products'
import { environment } from 'src/environments/environment'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { compare } from 'src/services/table-utils/compare'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { AddressFieldPickerOptions } from '../address-field/address-field.component'

const { tradecafeAccount, montshipAccount } = environment

export interface BookingFormOptions {
  dealViewRaw: DeepReadonly<DealViewRawDeal & DealViewRawBids & DealViewRawSegments>
  booking?: Booking
}

export function montshipSegments<T extends Segment>(segments: DeepReadonly<T[]>) {
  const all = segments?.filter(s => s.attributes.carrier_account === montshipAccount.toString())
  return { all, ...lookupSegments(all) }
}

function canadianBwiAddress(accounts: DeepReadonly<Dictionary<AccountObject>>) {
  const addresses = accounts[tradecafeAccount].addresses.filter(x => x.cc === 'CA');
  return addresses.find(a => a.primary) || addresses?.[0]
}

function primaryOrFirst(acc: DeepReadonly<AccountObject>) {
  return acc?.addresses?.find(a => a.primary) || acc?.addresses?.[0]
}

@Component({
  selector: 'tc-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private store: Store,
    private actions$: Actions,
    private BookingsApi: BookingsApiService,
    private Locations: LocationsService,
    private toaster: ToasterService,
    private dialogRef: MatDialogRef<BookingFormComponent, Partial<Booking>>,
    @Inject(MAT_DIALOG_DATA) private dialogData: BookingFormOptions,
  ) { super() }

  readonly ShipmentTypes = ShipmentTypes
  protected inProgress$ = new BehaviorSubject<'saving'|undefined>(undefined)

  protected carriers$ = combineLatest([
    this.store.pipe(select(selectAllCarriers), waitNotEmpty()),
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
  ]).pipe(map(([carriers, accounts]) => carriers
    .filter(carrier => accounts[carrier.account].status === ACCOUNT_ACTIVE)
    .sort((a, b) => compare(a.name, b.name))))

  protected locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  protected locationsDictionary$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  protected carrier$ = this.store.pipe(select(selectCarrier, environment.montshipCarrierId), waitNotEmpty(),
    map((carrier) => carrier?.name  || 'NOT FOUND'))
  private itemTypes$ = this.store.pipe(select(selectItemTypeEntities), waitNotEmpty(), map(itemTypes =>
    compact(this.dialogData.dealViewRaw.bids.map(o => itemTypes[o.attributes.item_type_id]))))
  protected productSpecs$ = this.itemTypes$.pipe(map(itemTypes => uniq(itemTypes.map(i => i.name)).join(', ')))

  protected bookingForm = new FormGroup({
    buyer_address: new FormControl<GeneralAddress>(undefined,
      { validators: [Validators.required, partyValidator] }),
    bwi_address: new FormControl<GeneralAddress>(undefined,
      { validators: [Validators.required, partyValidator] }),
    bwi_address_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.minLength(5), Validators.maxLength(5)] }),
    consignee: new FormControl<GeneralAddress|Consignee>(undefined,
      { validators: [Validators.required, partyValidator] }),
    consignee_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.minLength(5), Validators.maxLength(5)] }),
    notify: new FormControl<GeneralAddress>(undefined,
      { validators: [partyValidator] }),
    notify_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.minLength(5), Validators.maxLength(5)] }),
    etd_date: new FormControl<number>(undefined,
      { validators: [Validators.required] }),
    freight_forwarder_id: new FormControl<string>(undefined),
    freight_forwarder_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.minLength(5), Validators.maxLength(5)] }),
    freight_forwarder: new FormControl<GeneralAddress>(undefined,
      { validators: [partyValidator] }),
    minimum_temperature_celsius: new FormControl<number>(undefined,
      { validators: [Validators.required] }),
    notes: new FormControl<string>(undefined),
    pickup_date: new FormControl<number>(undefined),
    pickup_ref_no: new FormControl<string>(undefined),
    port_of_discharge_id: new FormControl<string>(undefined,
      { validators: [Validators.required] }),
    port_of_discharge_name: new FormControl<string>(undefined,
      { validators: [Validators.required, Validators.minLength(2), Validators.maxLength(24)] }),
    port_of_discharge_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] }),
    port_of_loading_id: new FormControl<string>(undefined,
      { validators: [Validators.required] }),
    port_of_loading_name: new FormControl<string>(undefined,
      { validators: [Validators.required, Validators.minLength(2), Validators.maxLength(24)] }),
    port_of_loading_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)] }),
    shipment_type: new FormControl<ShipmentType>(undefined,
      { validators: [Validators.required] }),
    shipper: new FormControl<GeneralAddress>(undefined,
      { validators: [Validators.required, partyValidator] }),
    shipper_unlocode: new FormControl<string>(undefined,
      { validators: [Validators.minLength(5), Validators.maxLength(5)] }),
    shipping_link: new FormControl<GeneralAddress>(undefined,
      { validators: [addressValidator] }),
    vessel_name: new FormControl<string>(undefined,
      { validators: [Validators.minLength(2), Validators.maxLength(28)] }),
    warehouse: new FormControl<GeneralAddress>(undefined,
      { validators: [Validators.required, partyValidator] }),
    warehouse_unlocode: new FormControl<string>(undefined,
    { validators: [Validators.minLength(5), Validators.maxLength(5)] }),
    deal_loading_location_name: new FormControl<string>(undefined),
    deal_discharge_location_name: new FormControl<string>(undefined),
    deal_final_destination_name: new FormControl<string>(undefined)
  })

  protected buyerAddressOptions: AddressFieldPickerOptions
  protected bwiAddressOptions: AddressFieldPickerOptions
  protected consigneeOptions: AddressFieldPickerOptions
  protected notifyOptions: AddressFieldPickerOptions
  protected shipperOptions: AddressFieldPickerOptions
  protected shippingLinkOptions: AddressFieldPickerOptions
  protected warehouseOptions: AddressFieldPickerOptions

  protected readonly errorsList = (ctrl: AbstractControl) => {
    if (!ctrl?.touched || !ctrl?.errors) return []
    return Object.keys(ctrl.errors).map(error => Object.keys(ctrl.errors[error])[0])
  }

  ngOnInit(): void {
    const dv = this.dialogData.dealViewRaw
    const { earliest } = montshipSegments(dv.segments)

    this.shippingLinkOptions = {
      title: 'Select Shipping Link Address',
      accountId: dv.deal.supplier_id,
      accountIds: uniq(compact([tradecafeAccount, dv.deal.supplier_id])),
    }
    this.buyerAddressOptions = {
      title: 'Select Buyer Address',
      accountId: dv.deal.buyer_id,
    }
    this.consigneeOptions = {
      title: 'Select Consignee Address',
      accountId: dv.deal.buyer_id,
      accountIds: uniq(compact([tradecafeAccount, dv.deal.buyer_id])),
    }
    this.notifyOptions = {
      title: 'Select Notify Address',
      accountId: dv.deal.buyer_id,
      accountIds: uniq(compact([tradecafeAccount, dv.deal.buyer_id])),
    }
    this.shipperOptions = {
      title: 'Select Shipper Address',
      accountId: dv.deal.supplier_id,
      accountIds: uniq(compact([tradecafeAccount, dv.deal.supplier_id])),
    }
    this.bwiAddressOptions = {
      title: 'Select BWI Address',
      accountId: tradecafeAccount,
    }
    this.warehouseOptions = {
      title: 'Select Pickup Address',
      accountId: earliest?.attributes.exact_loading?.account,
      accountIds: uniq(compact([
        dv.deal.buyer_id,
        dv.deal.supplier_id,
        earliest?.attributes.carrier_account,
        earliest?.attributes.freight_forwarder, // TODO: this field is carrier_id, not account_id
      ])),
    }

    if (this.dialogData.booking?.request?.length) {
      this.bookingForm.reset(this.dialogData.booking.request[this.dialogData.booking.request.length-1])
    } else {
      this.store.dispatch(loadAccounts({}))
      this.store.dispatch(loadLocations({}))
      combineLatest([
        this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
        this.store.pipe(select(selectCarrierEntities), waitNotEmpty()),
        this.store.pipe(select(selectItemTypeEntities), waitNotEmpty()),
        this.locationsDictionary$,
        this.store.pipe(select(selectProductEntities), waitNotEmpty()),
      ]).pipe(
        untilComponentDestroyed(this),
        take(1),
        switchMap(([accounts, carriers, itemTypes, locations, products]) => this.newBookingRequest(dv, accounts, carriers, itemTypes, locations, products)),
      ).subscribe(bookingRequest => {
        this.bookingForm.reset(bookingRequest);
      })

      combineLatest([this.locationsDictionary$, replayForm(this.bookingForm.controls.port_of_discharge_id)]).subscribe(([locations, portOfDischargeId]) => this.setDestinationMetadata(locations, portOfDischargeId))
      combineLatest([this.locationsDictionary$, replayForm(this.bookingForm.controls.port_of_loading_id)]).subscribe(([locations, portOfLoadingId]) => this.setOriginMetadata(locations, portOfLoadingId))
    }

    this.store.dispatch(loadConsignees())
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadProducts({}))

    this.actions$.pipe(ofType(createBookingSuccess), untilComponentDestroyed(this)).subscribe(() => {
      this.dialogRef.close()
    })

    merge(
      this.actions$.pipe(ofType(createBooking), mapTo('saving' as const)),
      this.actions$.pipe(ofType(createBookingFailure), mapTo(undefined)),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(inProgress => this.inProgress$.next(inProgress))
  }

  protected readonly saveUnlocode = async (evt: MouseEvent,
    locationIdField: FormControl<string>,
    unlocodeField: FormControl<string>) => {
      evt.stopPropagation();
      evt.preventDefault();

      if(!locationIdField.valid || !unlocodeField.valid) {
        return;
      }

      this.inProgress$.next('saving')
      try {
        await this.Locations.update({
          location_id: locationIdField?.value,
          unlocode: unlocodeField.value,
        })
        this.toaster.success('Saved location unlocode')
        this.store.dispatch(loadLocations({}))
      } catch(e) {
        this.toaster.error('Failed saving location unlocode')
      }
      this.inProgress$.next(undefined)
  }

  private setDestinationMetadata(locations, portOfDischargeId: string) {
    const destination = locations[portOfDischargeId]
    const destinationUnlocode$ = this.getLocationUnlocode(destination)
    const destinationName = destination?.name;
    destinationUnlocode$.subscribe(unlocode => {
      this.bookingForm.patchValue({
        port_of_discharge_unlocode: unlocode,
        port_of_discharge_name:  this.parseLocationName(destinationName)?.city
      })
    })
  }

  private setOriginMetadata(locations, portOfLoadingId: string) {
    const origin = locations[portOfLoadingId]
    const originUnlocode$ = this.getLocationUnlocode(origin)
    const originName = origin?.name
    originUnlocode$.subscribe(unlocode => {
      this.bookingForm.patchValue({
        port_of_loading_unlocode: unlocode,
        port_of_loading_name: this.parseLocationName(originName)?.city
      })
    })
  }

  private getOriginId(dv: DeepReadonly<DealViewRawSegments>) {
    const { earliest: earliestMontship } = montshipSegments(dv.segments)
    return earliestMontship?.attributes?.origin_id;
  }

  private getDestinationId(dv: DeepReadonly<DealViewRawDeal & DealViewRawSegments> ) {
    const { latest: latestMontship } = montshipSegments(dv.segments)
    const { latest } = lookupSegments(dv.segments);
    return latest?.attributes?.carrier_account === montshipAccount.toString() ? dv.deal.dest_location : latestMontship.attributes.destination_id
  }

  save() {
    if (this.inProgress$.value) return

    this.bookingForm.markAllAsTouched()
    this.bookingForm.controls.shipping_link.updateValueAndValidity()
    this.bookingForm.controls.consignee.updateValueAndValidity()
    this.bookingForm.controls.shipper.updateValueAndValidity()

    if (!this.bookingForm.valid) return

    let request = this.bookingForm.getRawValue()
    request = {
      ...request,
      freight_forwarder_id: request.freight_forwarder_id || undefined,
      notes: request.notes || undefined,
      vessel_name: request.vessel_name || undefined,
      warehouse: request.warehouse || undefined,
      warehouse_unlocode: request.warehouse_unlocode || undefined,
    }
    this.inProgress$.next('saving')
    this.store.dispatch(createBooking({
      deal_id: this.dialogData.dealViewRaw.deal.deal_id,
      request,
    }))
  }

  close() {
    this.dialogRef.close()
  }

  onFreightForwarderChanged(carrier: DeepReadonly<Carrier>) {
    this.store.pipe(select(selectAccountEntities), waitNotEmpty(), take(1)).subscribe(accounts => {
      const freight_forwarder = primaryOrFirst(accounts[carrier.account])
      this.bookingForm.controls.freight_forwarder.setValue(freight_forwarder)
      this.getAddressUnlocode(freight_forwarder).subscribe(freight_forwarder_unlocode =>
        this.bookingForm.controls.freight_forwarder_unlocode.setValue(freight_forwarder_unlocode))
    })
  }

  onShipperChanged() {
    const shipper = this.bookingForm.controls.shipper.value;
    this.getAddressUnlocode(shipper).subscribe(shipper_unlocode =>
      this.bookingForm.controls.shipper_unlocode.setValue(shipper_unlocode))
  }

  onWarehouseChanged() {
    const warehouse = this.bookingForm.controls.warehouse.value;
    this.getAddressUnlocode(warehouse).subscribe(warehouse_unlocode =>
      this.bookingForm.controls.warehouse_unlocode.setValue(warehouse_unlocode))
  }

  onBwiAddressChanged() {
    const bwiAddress = this.bookingForm.controls.bwi_address.value;
    this.getAddressUnlocode(bwiAddress).subscribe(bwi_address_unlocode =>
      this.bookingForm.controls.bwi_address_unlocode.setValue(bwi_address_unlocode))
  }

  onConsigneeChanged() {
    const consignee = this.bookingForm.controls.consignee.value;
    this.getAddressUnlocode(consignee).subscribe(consignee_unlocode =>
      this.bookingForm.controls.consignee_unlocode.setValue(consignee_unlocode))
  }

  onNotifyChanged() {
    const notify = this.bookingForm.controls.notify.value;
    this.getAddressUnlocode(notify).subscribe(notify_unlocode =>
      this.bookingForm.controls.notify_unlocode.setValue(notify_unlocode))
  }

  private newBookingRequest(
    dv: DeepReadonly<DealViewRawDeal & DealViewRawBids & DealViewRawSegments>,
    accounts: DeepReadonly<Dictionary<AccountObject>>,
    carriers: DeepReadonly<Dictionary<Carrier>>,
    itemTypes: DeepReadonly<Dictionary<ItemType>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    products: DeepReadonly<Dictionary<Product>>,
  ): Observable<DeepReadonly<Partial<MontshipBookingRequest>>> {
    const { earliest, earliestVessel, latest } = montshipSegments(dv.segments)
    const bwi_address = canadianBwiAddress(accounts)
    const buyer_address = primaryOrFirst(accounts[dv.deal.buyer_id])
    const freight_forwarder_id = earliest?.attributes.freight_forwarder
    const freight_forwarder = primaryOrFirst(accounts[carriers[freight_forwarder_id]?.account])
    const shipper = bwi_address
    const warehouse = earliest?.attributes?.exact_loading?.address
    const minimum_temperature_celsius = Math.min(...compact(dv.bids.map(bid => {
      const itemType = itemTypes[bid?.attributes.item_type_id]
      if (!itemType) return undefined
      const product = products[bid.product]
      const T = matchItemTypeTemperature(itemType, {
        buyer_id: bid.account,
        product_id: product.product_id,
        category_id: product.category_id,
        type_id: product.type_id,
        wrapping_id: bid.wrapping,
        package_id: bid.packing.type
      })
      return T.C
    })))
    const shipment_type = ShipmentType.door_to_door;

    return combineLatest([
      this.getAddressUnlocode(bwi_address),
      this.getAddressUnlocode(buyer_address),
      this.getAddressUnlocode(freight_forwarder),
      this.getAddressUnlocode(shipper),
      this.getAddressUnlocode(warehouse),
    ]).pipe(map(([
      bwi_address_unlocode,
      buyer_address_unlocode,
      freight_forwarder_unlocode,
      shipper_unlocode,
      warehouse_unlocode,
    ]) => ({
      buyer_address: primaryOrFirst(accounts[dv.deal.buyer_id]),
      bwi_address,
      bwi_address_unlocode,
      consignee: buyer_address,
      consignee_unlocode: buyer_address_unlocode,
      notify: buyer_address,
      notify_unlocode: buyer_address_unlocode,
      etd_date: earliestVessel?.attributes.actual_pickup_date || earliestVessel?.attributes.etd_date,
      freight_forwarder,
      freight_forwarder_id,
      freight_forwarder_unlocode,
      notes: '',
      pickup_date: earliest?.attributes.actual_pickup_date,
      pickup_ref_no: earliest?.attributes.refer_no,
      shipment_type,
      shipper,
      shipper_unlocode,
      shipping_link: dv.deal.attributes.shipment?.shipper,
      minimum_temperature_celsius,
      vessel_name: '',
      warehouse,
      warehouse_unlocode,
      port_of_loading_id: this.getOriginId(dv),
      port_of_discharge_id: this.getDestinationId(dv),
      deal_loading_location_name: locations[earliest?.attributes?.origin_id]?.name,
      deal_discharge_location_name: locations[latest?.attributes?.destination_id]?.name,
      deal_final_destination_name: locations[dv.deal.dest_location]?.name,
    })))
  }


  private getLocationUnlocode(location: DeepReadonly<LocationObject>): Observable<string> {
    if(!location) return of(null);
    const countryCode = location?.country_code ?? location?.country;
    const { stateCode, city } = this.parseLocationName(location?.name) ?? {};

    const locationSettingsUnlocode$ = this.getUnlocode(countryCode, location.state, location.city);
    const locationNameUnlocode$ = (location.state !== stateCode || location.city !== city) ? this.getUnlocode(countryCode, location.state, location.city) : of(null);
    const locationFallbackUnlocode$ = of(location.unlocode);

    return of(locationSettingsUnlocode$, locationNameUnlocode$, locationFallbackUnlocode$).pipe(
      concatMap(source$ =>
        source$.pipe(
          filter(value => value),
          take(1)
        ),
      ),
      take(1), // Take the first non-falsy value from any source observable
      defaultIfEmpty(''),
    );
  }

  private getAddressUnlocode(address: DeepReadonly<GeneralAddress>): Observable<string> {
    if(!address) return of(null);
    return this.getUnlocode(address?.cc, address?.state_code, address?.city);
  }

  private getUnlocode(countryCode: string, stateCode: string, cityName: string, prioritizePort = false): Observable<string> {
    if(!countryCode || !cityName) {
      return of(null);
    }

    return this.BookingsApi.getUnlocode(countryCode, stateCode, cityName).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) return of(undefined)
        throw err
      }),
      map((unlocodes: Unlocode[]) => {
        const unlocode = prioritizePort ? (unlocodes?.find(x => x.function.port) || unlocodes?.[0]) : unlocodes?.[0];
        if(!unlocode) {
          return null;
        }
        return `${unlocode.country}${unlocode.location}`;
      })
    )
  }

  private parseLocationName(locationName: string): { city: string, stateCode?: string, countryName: string } {
    const nameParts = locationName?.split(',');
    if(!nameParts?.length) {
      return null;
    }

    return {
      city: nameParts[0]?.trim(),
      stateCode: nameParts.length > 2 ? nameParts[1]?.trim() : null,
      countryName: nameParts.length > 1 ? last(nameParts)?.trim() : null,
    }
  }
}

function getFormattedError(err) {
  if(!err) {
    return err;
  }

  let formattedError = {...err};

  if (err.maxLength) formattedError.message = `${err.field} is too long. ${err.maxLength} characters maximum.`
  else if (err.minLength) formattedError.message = `${err.field} is too short. ${err.minLength} characters minimum.`
  else if (err.required) formattedError.message = `${err.field} is required.`
  else formattedError = {[formattedError.message]: formattedError};

  return {[formattedError.message]: err};
}

// tslint:disable-next-line: cyclomatic-complexity
function addressValidator(ctrl: FormControl<DeepReadonly<GeneralAddress>>) {
  const a = ctrl.value
  if (!a) return null

  let errors = []

  if (!a.name) errors.push({ required: true, field: 'Name' })
  else if (a.name.length > 150) errors.push({ maxLength: 150, field: 'Name' })

  if (a?.email?.length > 80) errors.push({ maxLength: 80, field: 'Email' })

  if (!a.street1) errors.push({ required: true, field: 'Street 1' })

  if (!a.city) errors.push({ required: true, field: 'City' })
  else if (a.city.length > 60) errors.push({ maxLength: 60, field: 'City' })
  else if (a.city.length < 2) errors.push({ minLength: 2, field: 'City' })

  if (a.cc === 'US' || a.cc === 'CA') {
    if (!a.state_code) errors.push({ required: true, field: 'State Code' })
    else if (a.state_code.length > 2) errors.push({ maxLength: 2, field: 'State Code' })
    else if (a.state_code.length < 2) errors.push({ minLength: 2, field: 'State Code' })

    if (!a.postal) errors.push({ required: true, field: 'Postal Code' })
  }

  if(a.postal) {
    if (a.postal.length > 15) errors.push({ maxLength: 15, field: 'Postal Code' })
    else if (a.postal.length < 3) errors.push({ minLength: 3, field: 'Postal Code' })
  }

  if (!a.cc) errors.push({ required: true, field: 'Country Code' })
  else if (a.cc.length > 3) errors.push({ maxLength: 3, field: 'Country Code' })
  else if (a.cc.length < 2) errors.push({ minLength: 2, field: 'Country Code' })

  if (a?.contact_name?.length > 60) errors.push({ maxLength: 60, field: 'Contact Name' })

  errors = errors.map(getFormattedError);

  return errors.length ? errors : null
}

function partyValidator(ctrl: FormControl<DeepReadonly<GeneralAddress>>) {
  if (!ctrl.value) return null

  const addressErrors = addressValidator(ctrl) ?? [];
  let errors = [];

  if (!ctrl?.value?.integration_codes?.montship) errors.push({ required: true, field: 'Montship Code' })
  errors = addressErrors.concat(errors.map(getFormattedError));

  return errors.length ? errors : null
}
