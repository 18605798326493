<mat-form-field #inlineText class="tc-inline-text">
  <form [formGroup]="inlineForm" (submit)="onTextChanged()">
    <button type="submit" style="display: none"></button>
    <input matInput formControlName="text" (blur)="onTextChanged()">
  </form>
</mat-form-field>

<mat-form-field #inlineNumber class="tc-inline-number">
  <form [formGroup]="inlineForm" (submit)="onNumberChanged()">
    <button type="submit" style="display: none"></button>
    <input matInput formControlName="number" type="number" (blur)="onNumberChanged()">
  </form>
</mat-form-field>

<tc-epoch-field #inlineDate class="tc-inline-date"
    format="L LT"
    [type]="(dateOpts$ | async)?.showTimePicker ? 'datetime' : 'date'"
    [useUtc]="(dateOpts$ | async)?.useUtc"
    [group]="inlineForm" ctrlName="date"
    (changed)="onDateChanged()"
    ></tc-epoch-field>

<tc-epoch-range-field #inlineDateRange class="tc-inline-date-range"
    [useUtc]="(dateOpts$ | async)?.useUtc"
    [useTemplates]="true"
    [min]="(dateOpts$ | async)?.min"
    [max]="(dateOpts$ | async)?.max"
    [formGroup]="inlineForm"
    startCtrlName="from"
    endCtrlName="to"
    tbdCtrlName="tbd"
    comparisonStartCtrlName="from2"
    comparisonEndCtrlName="to2"
    (closed)="onDateRangeChanged()"
    ></tc-epoch-range-field>

<tc-select-search #inlineSelect class="tc-inline-select"
    [group]="inlineForm" ctrlName="selected"
    (openedChange)="!$event && onInlineSelectChanged()"
    [items]="(dropdown$ | async)?.items"
    [bindLabel]="(dropdown$ | async)?.bindLabel"
    [bindValue]="(dropdown$ | async)?.bindValue"
    [multiple]="(dropdown$ | async)?.multiple || false"
    ></tc-select-search>
