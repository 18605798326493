import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import { head, identity, split } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { HybridRouter } from 'src/app/angularjs/hybrid-router.service'
import { routesByName } from 'src/app/routes'
import { environment } from 'src/environments/environment'
import { MatchedOfferNotifierService } from 'src/services/matched-offer-notifier'
import { ModalProxyService } from 'src/shared/modal'


@Component({
  selector: 'tc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit, OnInit, OnDestroy {
  readonly version = environment.version
  readonly env = environment.env
  readonly enableChartFeature = environment.enableChartFeature
  readonly enableMultipleBids = environment.enableMultipleBids

  @Input()
  theme: 'dark' | 'light'

  newMatchedOffers: number
  username: string
  isNavCollapsed: boolean

  @ViewChildren(MatMenu)
  menus: QueryList<MatMenu>

  sentryReplay$ = new BehaviorSubject('')


  constructor(
    private modalHelper: ModalProxyService,
    private AuthApi: AuthApiService,
    private MatchedOfferNotifier: MatchedOfferNotifierService,
    hybrid: HybridRouter,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    this.getCurrentStatePath()
    combineLatest([
      AuthApi.currentUser$.pipe(map(user => user?.role), distinctUntilChanged()),
      hybrid.urlChanged$,
    ]).subscribe(() => {
      this.getCurrentStatePath()
    })
  }


  ngOnInit() {
    // const sub = DealFinancialsListener.dealFinancialsChanged$.subscribe(data =>
    //   console.log('deal Finacials changed', data))
    // $scope.$on('$destroy', () => sub.unsubscribe)

    this.AuthApi.realUser$.pipe(filter(identity)).subscribe(() => this.MatchedOfferNotifier.start())

    this.MatchedOfferNotifier.onChange((amount) => {
      this.newMatchedOffers = amount
      this.cd.markForCheck()
    })
  }

  ngOnDestroy() {
    this.MatchedOfferNotifier.stop()
  }

  ngAfterViewInit() {
    this.menus.forEach(menu => menu.overlayPanelClass = 'theme-nav-menu')
  }

  // getThemeClass() {
  //   if (this.theme === 'dark') return ['bg-dark', 'navbar-dark']
  //   if (this.theme === 'light') return ['bg-light', 'navbar-light']
  //   this.theme = 'dark' // default
  //   return this.getThemeClass()
  // }

  private getCurrentStatePath() {
    const currentUser = this.AuthApi.currentUser
    if (!currentUser) return
    this.username = currentUser.firstname + ' ' + currentUser.lastname
    this.cd.markForCheck()
  }

  changePassword() {
    const currentUser = this.AuthApi.currentUser
    this.modalHelper.showModal({
      component: 'tcSetPasswordForm',
      windowClass: 'modalShipping',
      backdrop: 'static',
      resolve: {
        userId: () => currentUser.user_id,
      },
    })
  }

  async logout() {
    await this.AuthApi.logout()
    this.router.navigateByUrl('/login')
  }

  canAccess(stateName: string) {
    if (!this.AuthApi.currentUser) return false
    const { role } = this.AuthApi.currentUser
    return routesByName[stateName]?.roles?.includes(role)
  }

  routeUrl(stateName: string) {
    return head(split(routesByName[stateName]?.url || '', '?'))
  }

  async getReplayId() {
    if (this.sentryReplay$.value) return
    const replay = Sentry.getCurrentHub().getClient()?.getIntegration(Sentry.Replay)
    if (!replay) return
    await replay.flush()
    this.sentryReplay$.next(`https://codegence-inc.sentry.io/replays/${replay.getReplayId()}/`)
  }
}
