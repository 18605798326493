<h2 mat-dialog-title>
  <i class="fa fa-ship" aria-hidden="true"></i>&nbsp;Booking Request
</h2>
<form [formGroup]="bookingForm" (submit)="save()">
  <mat-dialog-content gdColumns="1.1fr 1fr 1fr" gdGap="15px">
    <mat-form-field gdColumn="span 2">
      <mat-label>Carrier</mat-label>
      <input readonly matInput [value]="carrier$ | async">
    </mat-form-field>
    <tc-select-search placeholder="Transit Type" [multiple]="false"
      [ctrl]="bookingForm.controls.shipment_type"
      [items]="ShipmentTypes" bindValue="id" bindLabel="name"
    ></tc-select-search>
    <div gdColumns="minmax(0, 1fr) 125px" gdGap="0 15px">
      <tc-select-search placeholder="Freight Forwarder"
          [ctrl]="bookingForm.controls.freight_forwarder_id"
          [items]="carriers$ | async" bindValue="carrier_id" bindLabel="name"
          (change)="onFreightForwarderChanged($event)">
      </tc-select-search>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.freight_forwarder_unlocode">
      </mat-form-field>
      <mat-error gdColumn="span 2" *ngFor="let err of errorsList(bookingForm.controls.freight_forwarder)">{{err}}</mat-error>
    </div>
    <tc-address-field placeholder="Shipping Link Address"
        [ctrl]="bookingForm.controls.shipping_link"
        [pickerOptions]="shippingLinkOptions">
      <mat-error *ngFor="let err of errorsList(bookingForm.controls.shipping_link)">{{err}}</mat-error>
    </tc-address-field>
    <div gdColumns="minmax(0, 1fr) 100px" gdGap="0 15px">
      <tc-address-field placeholder="Shipper"
          [ctrl]="bookingForm.controls.shipper"
          [pickerOptions]="shipperOptions"
          (addressChanged)="onShipperChanged()"
          >
        <mat-error *ngFor="let err of errorsList(bookingForm.controls.shipper)">{{err}}</mat-error>
      </tc-address-field>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.shipper_unlocode">
      </mat-form-field>
    </div>
    <div gdColumns="minmax(0, 1fr) 125px" gdGap="0 15px">
      <tc-address-field placeholder="Warehouse"
          [ctrl]="bookingForm.controls.warehouse"
          [pickerOptions]="warehouseOptions"
          (addressChanged)="onWarehouseChanged()"
          ></tc-address-field>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.warehouse_unlocode">
      </mat-form-field>
      <mat-error gdColumn="span 2" *ngFor="let err of errorsList(bookingForm.controls.warehouse)">{{err}}</mat-error>
    </div>
    <tc-epoch-field format="L" placeholder="Pickup Date"
        [ctrl]="bookingForm.controls.pickup_date"
    ></tc-epoch-field>
    <mat-form-field>
      <mat-label>Supplier Release #</mat-label>
      <input matInput [formControl]="bookingForm.controls.pickup_ref_no">
    </mat-form-field>
    <div gdColumns="minmax(0, 1fr) 125px" gdGap="0 15px">
      <tc-select-search placeholder="Loading"
          [ctrl]="bookingForm.controls.port_of_loading_id"
          [items]="locations$ | async" bindValue="location_id" bindLabel="name" bindHint="attributes.port"
          ></tc-select-search>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.port_of_loading_unlocode">
        <button type="button" matSuffix mat-icon-button
          title="Store unlocode for this location"
          (click)="saveUnlocode($event, bookingForm.controls.port_of_loading_id, bookingForm.controls.port_of_loading_unlocode)">
          <mat-icon class="fa-regular fa-floppy-disk unlocode-save-icon"></mat-icon>
        </button>
      </mat-form-field>
      <mat-error gdColumn="span 2" *ngIf="bookingForm.controls.port_of_loading_id.touched && bookingForm.controls.port_of_loading_name.errors?.required">Port name is required field</mat-error>
      <mat-error gdColumn="span 2" *ngIf="bookingForm.controls.port_of_loading_id.touched && bookingForm.controls.port_of_loading_name.errors?.minLength">Port name is too short. Min length is {{bookingForm.controls.port_of_loading_name.errors?.minLength}}</mat-error>
      <mat-error gdColumn="span 2" *ngIf="bookingForm.controls.port_of_loading_id.touched && bookingForm.controls.port_of_loading_name.errors?.maxLength">Port name is too long. Max length is {{bookingForm.controls.port_of_loading_name.errors?.maxLength}}</mat-error>
    </div>
    <tc-epoch-field format="L" placeholder="ETD"
        [ctrl]="bookingForm.controls.etd_date"
    ></tc-epoch-field>
    <div gdColumns="minmax(0, 1fr) 125px" gdGap="0 15px" style="grid-column-start: 1">
      <tc-select-search placeholder="Discharge"
          [ctrl]="bookingForm.controls.port_of_discharge_id"
          [items]="locations$ | async" bindValue="location_id" bindLabel="name" bindHint="attributes.port"
          ></tc-select-search>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.port_of_discharge_unlocode">
        <button type="button" matSuffix mat-icon-button
          (click)="saveUnlocode($event, bookingForm.controls.port_of_discharge_id, bookingForm.controls.port_of_discharge_unlocode)">
          <mat-icon class="fa-regular fa-floppy-disk unlocode-save-icon"></mat-icon>
        </button>
      </mat-form-field>
      <mat-error gdColumn="span 2" *ngIf="bookingForm.controls.port_of_discharge_id.touched && bookingForm.controls.port_of_discharge_name.errors?.required">Port name is required field</mat-error>
      <mat-error gdColumn="span 2" *ngIf="bookingForm.controls.port_of_discharge_id.touched && bookingForm.controls.port_of_discharge_name.errors?.minLength">Port name is too short. Min length is {{bookingForm.controls.port_of_discharge_name.errors?.minLength}}</mat-error>
      <mat-error gdColumn="span 2" *ngIf="bookingForm.controls.port_of_discharge_id.touched && bookingForm.controls.port_of_discharge_name.errors?.maxLength">Port name is too long. Max length is {{bookingForm.controls.port_of_discharge_name.errors?.maxLength}}</mat-error>
    </div>
    <tc-address-field placeholder="Buyer address"
        [ctrl]="bookingForm.controls.buyer_address"
        [pickerOptions]="buyerAddressOptions"
        gdColumn="span 2"
        [addressFormat]="['name', 'street1', 'street2', 'city', 'state_code', 'country', 'postal']"
        >
      <mat-error *ngFor="let err of errorsList(bookingForm.controls.buyer_address)">{{err}}</mat-error>
    </tc-address-field>
    <div gdColumns="minmax(0, 1fr) 125px" gdGap="0 15px">
      <tc-address-field placeholder="BWI address"
          [ctrl]="bookingForm.controls.bwi_address"
          [pickerOptions]="bwiAddressOptions"
          (addressChanged)="onBwiAddressChanged()"
          >
        <mat-error *ngFor="let err of errorsList(bookingForm.controls.bwi_address)">{{err}}</mat-error>
      </tc-address-field>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.bwi_address_unlocode">
      </mat-form-field>
    </div>
    <div gdColumns="minmax(0, 1fr) 100px" gdGap="0 15px">
      <tc-address-field placeholder="Notify"
          [ctrl]="bookingForm.controls.notify"
          [pickerOptions]="notifyOptions"
          (addressChanged)="onNotifyChanged()"
          ></tc-address-field>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.notify_unlocode">
      </mat-form-field>
      <mat-error gdColumn="span 2" *ngFor="let err of errorsList(bookingForm.controls.notify)">{{err}}</mat-error>
    </div>
    <div gdColumns="minmax(0, 1fr) 100px" gdGap="0 15px">
      <tc-address-field placeholder="Consignee"
          [ctrl]="bookingForm.controls.consignee"
          [pickerOptions]="consigneeOptions"
          (addressChanged)="onConsigneeChanged()"
          ></tc-address-field>
      <mat-form-field class="unlocode">
        <mat-label>Unlocode</mat-label>
        <input matInput [formControl]="bookingForm.controls.consignee_unlocode">
      </mat-form-field>
      <mat-error gdColumn="span 2" *ngFor="let err of errorsList(bookingForm.controls.consignee)">{{err}}</mat-error>
    </div>
    <mat-form-field>
      <mat-label>Temperature</mat-label>
      <input readonly matInput [value]="'' + bookingForm.controls.minimum_temperature_celsius.value + '° C'">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Item Spec</mat-label>
      <input readonly matInput [value]="productSpecs$ | async">
    </mat-form-field>
  </mat-dialog-content>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="close()">Close</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="inProgress$ | async">
      <i class="fa fa-paper-plane"></i>&nbsp; Book
    </button>
  </mat-dialog-actions>
</form>
