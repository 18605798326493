import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Cost, Deal, DealViewRaw, DealViewStatus, INVOICE_APPROVED, INVOICE_PAID, INVOICE_SCHEDULED, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, compact, identity, uniq } from 'lodash-es'
import { BehaviorSubject, ReplaySubject, combineLatest, from } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { DealsService } from 'src/services/data/deals.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { NotesService } from 'src/services/data/notes.service'
import { VendorCreditsService } from 'src/services/data/vendor-credits.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


export interface VendorCreditFormOptions {
  title: string
  vendorCredit: DeepReadonly<Partial<VendorCredit>>
  dealViewRaw?: DeepReadonly<DealViewRaw>
  isNew?: boolean
  isReadonly?: boolean
}

@Component({
  selector: 'tc-vendor-credit-form',
  templateUrl: './vendor-credit-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorCreditFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private VendorCredits: VendorCreditsService,
    private Deals: DealsService,
    private Notes: NotesService,
    private Invoices: InvoicesService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private dialogData: VendorCreditFormOptions,
    private dialogRef: MatDialogRef<VendorCreditFormComponent, VendorCredit>
  ) { super() }

  vcForm = new FormGroup({
    account: new FormControl(this.dialogData.vendorCredit.account, Validators.required),
    cost_id: new FormControl(this.dialogData.vendorCredit.cost_id, Validators.required),
    deal_id: new FormControl(this.dialogData.vendorCredit.deal_id, Validators.required),
    amount: new FormControl(this.dialogData.vendorCredit.amount, [Validators.required, Validators.min(0)]),
    currency: new FormControl(this.dialogData.vendorCredit.currency, Validators.required),
    vendor_number: new FormControl(this.dialogData.vendorCredit.vendor_number),
    description: new FormControl(this.dialogData.vendorCredit.attributes?.description),
    createNote: new FormControl(false),
  })

  title = this.dialogData.title
  isNew = this.dialogData.isNew
  predefinedDealId = this.dialogData.vendorCredit.deal_id

  dealIds$ = new ReplaySubject<string[]>(1)
  dealViewRaw$ = new ReplaySubject<DeepReadonly<DealViewRaw>>(1)

  private vendor$ = combineLatest([
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    replayForm(this.vcForm.controls.account),
  ]).pipe(map(([accounts, account]) => accounts[account]))

  vendors$ = combineLatest([
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    this.dealViewRaw$,
    this.vendor$,
  ]).pipe(map(([accounts, dv, vendor]) =>
    uniq(compact([
      dv.deal.supplier_id,
      vendor?.account.toString(),
      ...dv.costs.map(cost => cost.provider)
    ])).map(account => accounts[account])
  ))

  private cost$ = combineLatest([
    replayForm(this.vcForm.controls.cost_id),
    this.dealViewRaw$,
  ]).pipe(map(([cost_id, dv]) => dv.costs?.find(c => c.cost_id === cost_id)))

  costs$ = combineLatest([
    this.dealViewRaw$,
    this.vendor$,
    this.cost$,
  ]).pipe(map(([dv, vendor, selectedCost]) =>
    dv.costs?.filter(cost =>
      cost.cost_id === selectedCost?.cost_id ||
      cost.account === vendor?.account.toString() ||
      cost.type === 'tertiary' ||
      vendor?.type === 'supplier' && cost.type === 'primary'
    )), waitNotEmpty())

  inProgress$ = new BehaviorSubject<'save'|'loading'>(undefined)

  isReadonly$ = new BehaviorSubject(!this.isNew)

  ngOnInit() {
    this.store.dispatch(loadAccounts({}))

    if (!this.dialogData.isReadonly) {
      if (!this.dialogData.vendorCredit.invoice_id) {
        this.isReadonly$.next(false)
      } else {
        this.Invoices.getById(this.dialogData.vendorCredit.invoice_id)
        .catch(err => {
          console.error('Unable to load associated invoice.', err)
          this.toaster.error('Unable to load associated invoice.', err)
          throw err
        })
        .then(invoice => {
          if (![INVOICE_SCHEDULED, INVOICE_APPROVED, INVOICE_PAID].includes(invoice.status)) {
            this.isReadonly$.next(false)
          }
        })
      }
    }

    if (this.predefinedDealId) {
      this.dealIds$.next([this.predefinedDealId])
    } else {
      this.inProgress$.next('loading')
      const {role, user_id} = this.AuthApi.currentUser
      from(this.Deals.getDealIds(role === 'trader' ? { trader_user_id: user_id } : {}))
      .pipe(untilComponentDestroyed(this), catchError(err => {
        console.error('Unable to load deal ids.', err)
        this.toaster.error('Unable to load deal ids.', err)
        throw err
      })).subscribe((dealIds) => {
        this.dealIds$.next(dealIds)
      })
    }

    if (this.dialogData.dealViewRaw) {
      this.dealViewRaw$.next(this.dialogData.dealViewRaw)
    } else  {
      this.inProgress$.next('loading')
      const dealId$ = replayForm(this.vcForm.controls.deal_id).pipe(filter(identity), untilComponentDestroyed(this), distinctUntilChanged())
      dealId$.pipe(switchMap(dealId => this.Deals.getDealView(dealId, ['deal', 'costs']))).subscribe(dv => {
        if (dv.status === DealViewStatus.invalid) {
          this.toaster.warning(`Deal ${dv.deal.deal_id} is invalid`)
        }
        this.dealViewRaw$.next(dv)
      })
      if (this.isNew && !this.predefinedDealId) {
        dealId$.subscribe(() => {
          this.vcForm.controls.cost_id.reset()
        })
      }
    }

    combineLatest(this.predefinedDealId ? [this.dealIds$, this.dealViewRaw$] : [this.dealIds$]).pipe(take(1), untilComponentDestroyed(this)).subscribe(() => {
      this.inProgress$.next(undefined)
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  onCostChange(cost: DeepReadonly<Cost>) {
    if (!cost) return
    this.vcForm.controls.amount.setValue(cost.amount.total)
    this.vcForm.controls.amount.markAsDirty()
    this.vcForm.controls.amount.markAsTouched()
    this.vcForm.controls.currency.setValue(cost.amount.currency)
    this.vcForm.controls.currency.markAsDirty()
    this.vcForm.controls.currency.markAsTouched()
  }

  async save() {
    if (this.isReadonly$.value || this.inProgress$.value) return
    this.vcForm.markAllAsTouched()
    this.vcForm.updateValueAndValidity()
    if (!this.vcForm.valid) return

    const creditForm = this.vcForm.value
    let vendorCredit = cloneDeep(this.dialogData.vendorCredit) as VendorCredit

    try {
      this.inProgress$.next('save')
      this.dealViewRaw$.pipe(take(1)).subscribe(async dv => {
        if (this.isNew) {
          vendorCredit.account = creditForm.account.toString()
          vendorCredit.deal_id = creditForm.deal_id
        }
        if (creditForm.cost_id) vendorCredit.cost_id = creditForm.cost_id
        if (creditForm.amount !== undefined) vendorCredit.amount = creditForm.amount
        if (creditForm.currency !== undefined) vendorCredit.currency = creditForm.currency
        if (creditForm.vendor_number !== undefined) vendorCredit.vendor_number = creditForm.vendor_number
        if (creditForm.description !== undefined) {
          vendorCredit.attributes = vendorCredit.attributes || { description: creditForm.description }
          vendorCredit.attributes.description = creditForm.description
        }

        const stored = await this.VendorCredits.saveVendorCredit(vendorCredit, {
          deal: cloneDeep(dv.deal) as Deal,
          cost: cloneDeep(dv.costs.find(c => c.cost_id === vendorCredit.cost_id)) as Cost,
        })

        if (creditForm.createNote && creditForm.description) {
          this.Notes.createNote({
            visibility: 1,
            body: creditForm.description,
            attributes: {
              credit_note_id: stored.credit_note_id,
            },
          })
        }

        this.dialogRef.close(stored)
      })

    } finally {
      this.inProgress$.next(undefined)
    }
  }
}
