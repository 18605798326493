import { NgModule } from '@angular/core';
import { ModalModule } from 'src/shared/modal';
import { SupplierOfferCloneFormService } from './supplier-offer-clone-form.service';

@NgModule({
  declarations: [],
  providers: [SupplierOfferCloneFormService],
  imports: [
    ModalModule
  ]
})
export class SupplierOfferCloneFormModule { }
