import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Cost, GeneralAddress, MatchedOffer, Note } from "@tradecafe/types/core";
import { DeepReadonly } from "@tradecafe/types/utils";
import { CustomCostsService } from "src/services/data/custom-costs.service";
import { MatchedOfferPermissionsService } from "src/services/data/matched-offer-permissions.service";
import { disableIf } from "src/shared/utils/disable-if";
import { CostsFormGroup } from "../../trading/deal-form/deal-form-page/deal-form.schema";
import { buildDealCostForm, prepareDealCostPatch } from "../../trading/deal-form/deal-form-page/deal-form.service-factory";
import { round } from "lodash-es";

export type MatchedOfferFormGroup = FormGroup<{
  proformaNeeded: FormControl<boolean> // matchedOffer.proforma_needed
  supplierRef: FormControl<string> // matchedOffer.offer.attributes.supplier_ref
  originCountryCode: FormControl<string> // matchedOffer.offer.country
  originLocationId: FormControl<string> // matchedOffer.offer.pickup
  supplierUserIds: FormControl<DeepReadonly<string[]>> // matchedOffer.offer.attributes.supplier_user_ids
  supplierTraderId: FormControl<string> // matchedOffer.offer.attributes.trader_user_id_supplier
  shipmentDatesFrom: FormControl<number> // matchedOffer.offer.ship_start
  shipmentDatesTo: FormControl<number> // matchedOffer.offer.ship_end
  shipmentDatesTbd: FormControl<string>
  buyerRef: FormControl<string> // matchedOffer.bid.attributes.buyer_ref
  docsCountryCode: FormControl<string> // matchedOffer.bid.attributes.docs_country
  destLocationId: FormControl<string> // matchedOffer.bid.delivery
  logisticsUserId: FormControl<string> // matchedOffer.bid.attributes.logistics_user_id
  buyerUserIds: FormControl<DeepReadonly<string[]>> // matchedOffer.bid.attributes.buyer_user_ids
  buyerTraderId: FormControl<string> // matchedOffer.bid.attributes.trader_user_id
  deliveryDatesFrom: FormControl<number> // matchedOffer.bid.ship_start
  deliveryDatesTo: FormControl<number> // matchedOffer.bid.ship_end
  deliveryDatesTbd: FormControl<string>
  productId: FormControl<string> // matchedOffer.offer.product = matchedOffer.bid.product
  itemTypeId: FormControl<string> // matchedOffer.offer.attributes.item_type_id = matchedOffer.bid.attributes.item_type_id
  establishments: FormControl<DeepReadonly<GeneralAddress[]>> // matchedOffer.offer.attributes.establishments
  supplierIncotermId: FormControl<string> // matchedOffer.offer.incoterm
  supplierIncotermLocationId: FormControl<string> // matchedOffer.offer.attributes.incoterm_location
  supplierEstWeight: FormControl<number> // matchedOffer.offer.weight.amount
  supplierMeasureId: FormControl<string> // matchedOffer.offer.weight.unit
  supplierEstPrice: FormControl<number> // matchedOffer.offer.price
  supplierCurrencyCode: FormControl<string> // matchedOffer.offer.currency
  invoiceAddress: FormControl<DeepReadonly<GeneralAddress>> // matchedOffer.bid.attributes.invoice_address
  buyerIncotermId: FormControl<string> // matchedOffer.bid.incoterm
  buyerIncotermLocationId: FormControl<string> // matchedOffer.bid.attributes.incoterm_location
  buyerEstWeight: FormControl<number> // matchedOffer.bid.weight.amount
  buyerMeasureId: FormControl<string> // matchedOffer.bid.weight.unit
  buyerEstPrice: FormControl<number> // matchedOffer.bid.price
  buyerCurrencyCode: FormControl<string> // matchedOffer.bid.currency
  supplierInstructions: FormControl<DeepReadonly<Note[]>>
  buyerInstructions: FormControl<DeepReadonly<Note[]>>
  supplierTermDate: FormControl<number> // matchedOffer.attributes.supplier_term_date
  supplierLiabilityDate: FormControl<number> // matchedOffer.attributes.supplier_liability_date
  buyerTermDate: FormControl<number> // matchedOffer.attributes.buyer_term_date
  collectionDate: FormControl<number> // matchedOffer.attributes.collection_date

  wrappingId: FormControl<string> // matchedOffer.offer.wrapping = matchedOffer.bid.wrapping
  weightTypeId: FormControl<string> // matchedOffer.offer.attributes.weight_type_id = matchedOffer.bid.attributes.weight_type_id
  quantity: FormControl<number> // matchedOffer.offer.quantity = matchedOffer.bid.quantity = matchedOffer.offer.packing.packages_count = matchedOffer.bid.packing.unit
  packageId: FormControl<string> // matchedOffer.offer.packing.package_id = matchedOffer.bid.packing.type
  packageSize: FormControl<number> // matchedOffer.offer.packing.package_size = matchedOffer.bid.attributes.package_size
  packageMeasureId: FormControl<string> // matchedOffer.offer.packing.package_measure_id = matchedOffer.bid.attributes.package_measure_id
  brand: FormControl<string> // matchedOffer.offer.attributes.brand = matchedOffer.bid.attributes.brand
  productCode: FormControl<string> // matchedOffer.offer.attributes.product_code = matchedOffer.bid.attributes.product_code
  additionalSpecs: FormControl<string> // matchedOffer.offer.attributes.additional_specs = matchedOffer.bid.attributes.additional_specs

  financeTerm: FormControl<number> // matchedOffer.attributes.finance_term
  marginP: FormControl<number> // matchedOffer.margin
  marginCad: FormControl<number> // matchedOffer.attributes.margin_cad
  revenueCad: FormControl<number> // matchedOffer.attributes.revenue_cad
}>

export type MatchedOfferFormValue = MatchedOfferFormGroup['value']

@Injectable()
export class MatchedOfferFormService {
  constructor(
    private readonly MatchedOfferPermissions: MatchedOfferPermissionsService,
    private readonly CustomCosts: CustomCostsService,
  ){}

  enableDisableMatchedOfferForm(moForm: MatchedOfferFormGroup) {
    disableIf(moForm.controls.supplierRef, !this.MatchedOfferPermissions.canEdit('offer.attributes.supplier_ref'))
    disableIf(moForm.controls.originCountryCode, !this.MatchedOfferPermissions.canEdit('offer.country'))
    disableIf(moForm.controls.supplierUserIds, !this.MatchedOfferPermissions.canEdit('offer.attributes.supplier_user_ids'))
    disableIf(moForm.controls.supplierTraderId, !this.MatchedOfferPermissions.canEdit('offer.attributes.trader_user_id_supplier'))
    disableIf(moForm.controls.shipmentDatesFrom, !this.MatchedOfferPermissions.canEdit('offer.ship_start'))
    disableIf(moForm.controls.shipmentDatesTo, !this.MatchedOfferPermissions.canEdit('offer.ship_start'))
    disableIf(moForm.controls.shipmentDatesTbd, !this.MatchedOfferPermissions.canEdit('offer.ship_start'))
    disableIf(moForm.controls.buyerRef, !this.MatchedOfferPermissions.canEdit('bid.attributes.buyer_ref'))
    disableIf(moForm.controls.docsCountryCode, !this.MatchedOfferPermissions.canEdit('bid.attributes.docs_country'))
    disableIf(moForm.controls.logisticsUserId, !this.MatchedOfferPermissions.canEdit('bid.attributes.logistics_user_id'))
    disableIf(moForm.controls.buyerUserIds, !this.MatchedOfferPermissions.canEdit('bid.attributes.buyer_user_ids'))
    disableIf(moForm.controls.buyerTraderId, !this.MatchedOfferPermissions.canEdit('bid.attributes.trader_user_id'))
    disableIf(moForm.controls.deliveryDatesFrom, !this.MatchedOfferPermissions.canEdit('bid.ship_start'))
    disableIf(moForm.controls.deliveryDatesTo, !this.MatchedOfferPermissions.canEdit('bid.ship_start'))
    disableIf(moForm.controls.deliveryDatesTbd, !this.MatchedOfferPermissions.canEdit('bid.ship_start'))
    disableIf(moForm.controls.productId, !this.MatchedOfferPermissions.canEdit('offer.prodct'))
    disableIf(moForm.controls.itemTypeId, !this.MatchedOfferPermissions.canEdit('offer.attributes.item_type_id'))
    disableIf(moForm.controls.establishments, !this.MatchedOfferPermissions.canEdit('offer.attributes.establishments'))
    disableIf(moForm.controls.supplierIncotermId, !this.MatchedOfferPermissions.canEdit('offer.incoterm'))
    disableIf(moForm.controls.supplierIncotermLocationId, !this.MatchedOfferPermissions.canEdit('offer.attributes.incoterm_location'))
    disableIf(moForm.controls.supplierEstWeight, !this.MatchedOfferPermissions.canEdit('offer.weight.amount'))
    disableIf(moForm.controls.supplierMeasureId, !this.MatchedOfferPermissions.canEdit('offer.weight.unit'))
    disableIf(moForm.controls.supplierEstPrice, !this.MatchedOfferPermissions.canEdit('offer.price'))
    disableIf(moForm.controls.supplierCurrencyCode, !this.MatchedOfferPermissions.canEdit('offer.currency'))
    disableIf(moForm.controls.invoiceAddress, !this.MatchedOfferPermissions.canEdit('bid.attributes.invoice_address'))
    disableIf(moForm.controls.buyerIncotermId, !this.MatchedOfferPermissions.canEdit('bid.incoterm'))
    disableIf(moForm.controls.buyerIncotermLocationId, !this.MatchedOfferPermissions.canEdit('bid.attributes.incoterm_location'))
    disableIf(moForm.controls.buyerEstWeight, !this.MatchedOfferPermissions.canEdit('bid.weight.amount'))
    disableIf(moForm.controls.buyerMeasureId, !this.MatchedOfferPermissions.canEdit('bid.weight.unit'))
    disableIf(moForm.controls.buyerEstPrice, !this.MatchedOfferPermissions.canEdit('bid.price'))
    disableIf(moForm.controls.buyerCurrencyCode, !this.MatchedOfferPermissions.canEdit('bid.currency'))

    disableIf(moForm.controls.wrappingId, !this.MatchedOfferPermissions.canEdit('offer.wrapping'))
    disableIf(moForm.controls.weightTypeId, !this.MatchedOfferPermissions.canEdit('offer.attributes.weight_type_id'))
    disableIf(moForm.controls.quantity, !this.MatchedOfferPermissions.canEdit('offer.quantity'))
    disableIf(moForm.controls.packageId, !this.MatchedOfferPermissions.canEdit('offer.packing.package_id'))
    disableIf(moForm.controls.packageSize, !this.MatchedOfferPermissions.canEdit('offer.packing.package_size'))
    disableIf(moForm.controls.packageMeasureId, !this.MatchedOfferPermissions.canEdit('offer.packing.package_measure_id'))
    disableIf(moForm.controls.brand, !this.MatchedOfferPermissions.canEdit('offer.attributes.brand'))
    disableIf(moForm.controls.productCode, !this.MatchedOfferPermissions.canEdit('offer.attributes.product_code'))
    disableIf(moForm.controls.additionalSpecs, !this.MatchedOfferPermissions.canEdit('offer.attributes.additional_specs'))
  }

  buildMatchedOfferForm = buildMatchedOfferForm

  patchMatchedOfferForm(moForm: MatchedOfferFormGroup, mo: MatchedOffer) {
    moForm.setValue(buildMatchedOfferForm(mo).getRawValue())
  }

  readMatchedOfferForm(moForm: MatchedOfferFormGroup): DeepReadonly<Partial<MatchedOffer>> {
    return readMatchedOfferForm(moForm)
  }

  /**
   * Refresh secondary costs, when some of the "key" fields changed
   */
  async refreshSecondaryCosts(
    supplierId: number | string,
    buyerId: number | string,
    moForm: MatchedOfferFormGroup,
    costsForm: CostsFormGroup,
  ) {
    const mo = moForm.getRawValue()
    const secondaryCosts = await this.CustomCosts.querySecondaryCosts({
      supplierId: parseFloat(supplierId as string) || undefined,
      buyerId: parseFloat(buyerId as string) || undefined,
      originId: mo.originLocationId,
      destinationId: mo.destLocationId,
      productIds: [mo.productId],
    })
    costsForm.controls.forEach(cf => {
      if (!isProperSecondaryCost(cf.value.cost)) return
      costsForm.removeAt(costsForm.controls.indexOf(cf))
    })
    secondaryCosts.forEach(cost =>
      costsForm.push(buildDealCostForm(prepareDealCostPatch(cost))))

    function isProperSecondaryCost(cost: DeepReadonly<Partial<Cost>>) {
      return cost.type === 'secondary' && cost.attributes?.default_cost?.cost_type_id
    }
  }

}

export function buildMatchedOfferForm(mo: DeepReadonly<MatchedOffer>): MatchedOfferFormGroup {
  return new FormGroup({
    proformaNeeded: new FormControl<boolean>(mo.proforma_needed),
    supplierRef: new FormControl<string>(mo.offer.attributes.supplier_ref),
    originCountryCode: new FormControl<string>(mo.offer.country),
    originLocationId: new FormControl<string>(mo.offer.pickup, [Validators.required]),
    supplierUserIds: new FormControl<DeepReadonly<string[]>>(mo.offer.attributes.supplier_user_ids, [Validators.required]),
    supplierTraderId: new FormControl<string>(mo.offer.attributes.trader_user_id_supplier, [Validators.required]),
    shipmentDatesFrom: new FormControl<number>(mo.offer.ship_start, [Validators.required]),
    shipmentDatesTo: new FormControl<number>(mo.offer.ship_end, [Validators.required]),
    shipmentDatesTbd: new FormControl<string>(undefined/* , [Validators.required] */),
    buyerRef: new FormControl<string>(mo.bid.attributes.buyer_ref),
    docsCountryCode: new FormControl<string>(mo.bid.attributes.docs_country),
    destLocationId: new FormControl<string>(mo.bid.delivery, [Validators.required]),
    logisticsUserId: new FormControl<string>(mo.bid.attributes.logistics_user_id),
    buyerUserIds: new FormControl<DeepReadonly<string[]>>(mo.bid.attributes.buyer_user_ids, [Validators.required]),
    buyerTraderId: new FormControl<string>(mo.bid.attributes.trader_user_id, [Validators.required]),
    deliveryDatesFrom: new FormControl<number>(mo.bid.ship_start, [Validators.required]),
    deliveryDatesTo: new FormControl<number>(mo.bid.ship_end, [Validators.required]),
    deliveryDatesTbd: new FormControl<string>(undefined/* , [Validators.required] */),
    productId: new FormControl<string>(mo.offer.product/*  = mo.bid.product */, [Validators.required]),
    itemTypeId: new FormControl<string>(mo.offer.attributes.item_type_id/*  = mo.bid.attributes.item_type_id */, [Validators.required]),
    establishments: new FormControl<DeepReadonly<GeneralAddress[]>>(mo.offer.attributes.establishments/* , [Validators.required] */),
    supplierIncotermId: new FormControl<string>(mo.offer.incoterm, [Validators.required]),
    supplierIncotermLocationId: new FormControl<string>(mo.offer.attributes.incoterm_location),
    supplierEstWeight: new FormControl<number>(round(mo.offer.weight.amount, 2), [Validators.required]),
    supplierMeasureId: new FormControl<string>(mo.offer.weight.unit, [Validators.required]),
    supplierEstPrice: new FormControl<number>(mo.offer.price, [Validators.required]),
    supplierCurrencyCode: new FormControl<string>(mo.offer.currency, [Validators.required]),
    invoiceAddress: new FormControl<DeepReadonly<GeneralAddress>>(mo.bid.attributes.invoice_address/* , [Validators.required] */),
    buyerIncotermId: new FormControl<string>(mo.bid.incoterm, [Validators.required]),
    buyerIncotermLocationId: new FormControl<string>(mo.bid.attributes.incoterm_location),
    buyerEstWeight: new FormControl<number>(round(mo.bid.weight.amount, 2), [Validators.required]),
    buyerMeasureId: new FormControl<string>(mo.bid.weight.unit, [Validators.required]),
    buyerEstPrice: new FormControl<number>(mo.bid.price, [Validators.required]),
    buyerCurrencyCode: new FormControl<string>(mo.bid.currency, [Validators.required]),
    supplierInstructions: new FormControl<DeepReadonly<Note[]>>(mo.offer.notes),
    buyerInstructions: new FormControl<DeepReadonly<Note[]>>(mo.bid.notes),
    supplierTermDate: new FormControl<number>(mo.attributes.supplier_term_date),
    supplierLiabilityDate: new FormControl<number>(mo.attributes.supplier_liability_date, [Validators.required]),
    buyerTermDate: new FormControl<number>(mo.attributes.buyer_term_date, [Validators.required]),
    collectionDate: new FormControl<number>(mo.attributes.collection_date, [Validators.required]),

    wrappingId: new FormControl<string>(mo.offer.wrapping),
    weightTypeId: new FormControl<string>(mo.offer.attributes.weight_type_id),
    quantity: new FormControl<number>(mo.offer.quantity),
    packageId: new FormControl<string>(mo.offer.packing?.package_id),
    packageSize: new FormControl<number>(mo.offer.packing?.package_size),
    packageMeasureId: new FormControl<string>(mo.offer.packing?.package_measure_id),
    brand: new FormControl<string>(mo.offer.attributes.brand),
    productCode: new FormControl<string>(mo.offer.attributes.product_code),
    additionalSpecs: new FormControl<string>(mo.offer.attributes.additional_specs),

    financeTerm: new FormControl<number>(mo.attributes.finance_term),
    marginP: new FormControl<number>(mo.margin),
    marginCad: new FormControl<number>(mo.attributes.margin_cad),
    revenueCad: new FormControl<number>(mo.attributes.revenue_cad),
  })
}

export function readMatchedOfferForm(moForm: MatchedOfferFormGroup): DeepReadonly<Partial<MatchedOffer>> {
  const mo = moForm.getRawValue()

  return {
    attributes: {
      buyer_term_date: mo.buyerTermDate,
      collection_date: mo.collectionDate,
      finance_term: mo.financeTerm,
      margin_cad: mo.marginCad,
      revenue_cad: mo.revenueCad,
      supplier_liability_date: mo.supplierLiabilityDate,
      supplier_term_date: mo.supplierTermDate,
    },
    bid: {
      attributes: {
        additional_specs: mo.additionalSpecs,
        brand: mo.brand,
        buyer_ref: mo.buyerRef,
        buyer_user_ids: mo.buyerUserIds,
        docs_country: mo.docsCountryCode,
        incoterm_location: mo.buyerIncotermLocationId,
        invoice_address: mo.invoiceAddress,
        item_type_id: mo.itemTypeId,
        logistics_user_id: mo.logisticsUserId,
        package_measure_id: mo.packageMeasureId,
        package_size: mo.packageSize,
        product_code: mo.productCode,
        trader_user_id: mo.buyerTraderId,
        weight_type_id: mo.weightTypeId,
      },
      currency: mo.buyerCurrencyCode,
      delivery: mo.destLocationId,
      incoterm: mo.buyerIncotermId,
      notes: mo.buyerInstructions,
      packing:{
        type: mo.packageId,
        unit: mo.quantity,
      },
      price: mo.buyerEstPrice,
      product: mo.productId,
      quantity: mo.quantity,
      ship_end: mo.deliveryDatesTo,
      ship_start: mo.deliveryDatesFrom,
      weight: {
        amount: mo.buyerEstWeight,
        unit: mo.buyerMeasureId,
      },
      wrapping: mo.wrappingId,
    },
    margin: mo.marginP,
    offer: {
      attributes: {
        additional_specs: mo.additionalSpecs,
        brand: mo.brand,
        establishments: mo.establishments,
        incoterm_location: mo.supplierIncotermLocationId,
        item_type_id: mo.itemTypeId,
        product_code: mo.productCode,
        supplier_ref: mo.supplierRef,
        supplier_user_ids: mo.supplierUserIds,
        trader_user_id_supplier: mo.supplierTraderId,
        weight_type_id: mo.weightTypeId,
      },
      country: mo.originCountryCode,
      currency: mo.supplierCurrencyCode,
      incoterm: mo.supplierIncotermId,
      notes: mo.supplierInstructions,
      packing: {
        package_id: mo.packageId,
        package_measure_id: mo.packageMeasureId,
        package_size: mo.packageSize,
        packages_count: mo.quantity,
      },
      pickup: mo.originLocationId,
      price: mo.supplierEstPrice,
      product: mo.productId,
      quantity: mo.quantity,
      ship_end: mo.shipmentDatesTo,
      ship_start: mo.shipmentDatesFrom,
      weight:{
        amount: mo.supplierEstWeight,
        unit: mo.supplierMeasureId,
      },
      wrapping: mo.wrappingId,
    },
    proforma_needed: mo.proformaNeeded,
  }
}
