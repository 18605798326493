import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { ReactiveAsteriskModule } from '../reactive-asterisk/reactive-asterisk.module'
import { FieldOrPathPipe } from './field-or-path.pipe'
import { SelectSearchOptionDirective } from './select-search-option.directive'
import { SelectSearchComponent } from './select-search.component'

@NgModule({
  declarations: [SelectSearchComponent, FieldOrPathPipe, SelectSearchOptionDirective],
  exports: [SelectSearchComponent, SelectSearchOptionDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    ScrollingModule,
    ReactiveAsteriskModule,
  ],
})
export class SelectSearchModule { }
