<h2 mat-dialog-title>
  {{ dealId }} AES export report
  <span class="report-status status-name">{{ reportStatusName$ | async }}</span>
</h2>
<div mat-dialog-content *ngrxLet="reportResponse$ as reportResponse">
  <div class="subtitle-status">
    <div><span class="response-info-label">Response sent:</span> {{reportResponse.date_sent | epoch:'LL':'utc'}}</div>
    <div class="response-status-wrapper"><span class="response-info-label">Response status:</span> <span class="status-name"> {{responseStatusDisplayName(reportResponse.status)}}</span></div>
  </div>
  <div *ngIf="reportResponse?.final_condition?.description"><span class="response-info-label">General message:</span> {{reportResponse?.final_condition?.description}}</div>
  <div *ngIf="reportResponse?.final_condition?.severity"><span class="response-info-label">Highest severity:</span> <span class="status-name">{{severityDisplayName(reportResponse?.final_condition?.severity)}}</span></div>
  <div class="aes-conditions-container">
    <table *ngIf="reportResponse?.data_conditions?.length > 0" class="table modal-table">
      <tr>
        <th class="col-head bd-bottom">Severity</th>
        <th class="col-head bd-bottom">Description</th>
      </tr>
      <tr *ngFor="let condition of reportResponse.data_conditions">
        <td class="bd-bottom condition-severity status-name">{{severityDisplayName(condition.severity)}}</td>
        <td class="bd-bottom">{{condition.description}}</td>
      </tr>
    </table>
    <p class="empty-conditions" *ngIf="!reportResponse?.data_conditions?.length">No data conditions have been returned.</p>
  </div>
  <div class="aes-response-scrolling-line">
    <button [disabled]="(isOldestResponse$ | async) || (inProgress$ | async)" mat-button (click)="seeOlderResponse()">See older response</button>
    <button [disabled]="(isMostRecentResponse$ | async) || (inProgress$ | async)" mat-button (click)="seeNewerResponse()">See newer response</button>
  </div>
</div>
<mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
<div mat-dialog-actions align="end" *ngrxLet="reportResponse$ as reportResponse">
  <button mat-button (click)="cancel()">Cancel</button>
  <button *ngIf="reportResponse.status !== 'not-read'" [disabled]="!(isMostRecentResponse$ | async) || (inProgress$ | async)" mat-raised-button (click)="setAesResponseStatus('not-read')" color="primary">Mark as not read</button>
  <button *ngIf="reportResponse.status !== 'read'" [disabled]="!(isMostRecentResponse$ | async) || (inProgress$ | async)" mat-raised-button (click)="setAesResponseStatus('read')" color="primary">Mark as read</button>
  <button *ngIf="reportResponse.status !== 'handled'" [disabled]="!(isMostRecentResponse$ | async) || (inProgress$ | async)" mat-raised-button (click)="setAesResponseStatus('handled')" color="primary">Mark as handled</button>
</div>
