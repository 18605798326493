import { Injectable } from '@angular/core'
import { MatchedOffer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalService } from 'src/shared/modal'
import { FxRatesService } from '../../financial/fx-rates/fx-rates.service'
import { MatchedOfferOverlayComponent, MatchedOfferOverlayOptions } from './matched-offer-overlay.component'


@Injectable()
export class MatchedOfferOverlayService {
  constructor(
    private readonly modal: ModalService,
    private readonly FxRates: FxRatesService,
  ) { }

  async showEditOffer(matchedOffer: DeepReadonly<MatchedOffer>) {
    const fxRates = await this.FxRates.getFxRates()
    return this.modal.openLargeSide<
      MatchedOfferOverlayComponent,
      MatchedOfferOverlayOptions,
      MatchedOffer
    >(MatchedOfferOverlayComponent, { matchedOffer, fxRates }).toPromise()
  }
}
