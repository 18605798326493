import { createSelector } from '@ngrx/store'
import { Department } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, departmentsFeatureKey } from './department.reducer'


export const selectState = (state: State) => state[departmentsFeatureKey]

export const { selectEntities: selectDepartmentEntities, selectAll: selectAllDepartments } = adapter.getSelectors(selectState)

export const selectDepartment = createSelector(
  selectDepartmentEntities,
  (departments: Dictionary<Department>, department: string | number) =>
    departments[department])

export const selectDepartmentsByIds = createSelector(
  selectDepartmentEntities,
  (departments: Dictionary<Department>, depIds: string[]) =>
    depIds.map(depId => departments[depId]))
