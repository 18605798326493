import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { Booking, DealViewRawBookings, DealViewRawDeal, DealViewRawSegments, GeneralAddress, LocationObject, MontshipBookingRequest, MontshipBookingResponse, MontshipBookingStatus } from '@tradecafe/types/core'
import { DeepReadonly, eta, etd, lookupSegments } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, orderBy } from 'lodash-es'
import { BehaviorSubject, combineLatest, merge, ReplaySubject } from 'rxjs'
import { distinctUntilChanged, map, mapTo, startWith, take } from 'rxjs/operators'
import { approveBooking, approveBookingFailure, approveBookingSuccess, putBookingOnHold, putBookingOnHoldFailure, putBookingOnHoldSuccess, rejectBookingSuccess } from 'src/app/store/booking'
import { loadLocations, selectLocationEntities } from 'src/app/store/locations'
import { environment } from 'src/environments/environment'
import { DealsService } from 'src/services/data/deals.service'
import { MONTSHIP_BOOKING_REJECTION_REASON } from 'src/services/data/notes.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { montshipSegments } from '../booking-form/booking-form.component'
import { BookingRejectionDialogService } from '../booking-rejection-dialog/booking-rejection-dialog.service'
import { NotesOverlayService } from '../notes/notes-overlay/notes-overlay.service'


const { montshipAccount } = environment

type DealView = DealViewRawDeal & DealViewRawSegments & DealViewRawBookings

export interface BookingReviewDialogOptions {
  dealId: string
}

interface IDataRow {
  pickupDate: number,
  pickupAddress: string,
  pickupLocation: string,
  etd: number
  eta: number
  origin: string,
  destination: string
  portOfLoading: string,
  portOfDischarge: string,
  vesselName: string
  bookingNumber: string,
  finalDestination: string,
}

interface IMessagePair {
  request: MontshipBookingRequest;
  response?: MontshipBookingResponse;
}

@Component({
  selector: 'tc-booking-review-dialog',
  templateUrl: './booking-review-dialog.component.html',
  styleUrls: ['./booking-review-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingReviewDialogComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private rejectionDialog: BookingRejectionDialogService,
    private store: Store,
    private actions$: Actions,
    private Deals: DealsService,
    private NotesOverlay: NotesOverlayService,
    private dialogRef: MatDialogRef<BookingReviewDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private dialogData: BookingReviewDialogOptions,
  ) { super() }

  protected dealId = this.dialogData.dealId
  private dealViewRaw$ = new ReplaySubject<DealView>(1)

  protected booking$ = this.dealViewRaw$.pipe(map(((dv) => orderBy(dv.bookings, b => b.created_at, 'desc')[0])))
  protected bookingStatus$ = this.booking$.pipe(map(b => b.status))
  protected bookingStatusDisplayName$ = this.bookingStatus$.pipe(map(s => this.bookingStatusDisplayName(s)))
  protected cannotApproveReject$ = this.booking$.pipe(map(b => b.status !== 'confirmed' &&  b.status !== 'on-hold'))
  protected cannotPutOnHold$ = this.booking$.pipe(map(b => b.status !== 'confirmed'))
  private locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())

  protected currentIndex$ = new BehaviorSubject<number>(0)

  protected currentDealData$ = combineLatest([ this.dealViewRaw$, this.locations$]).pipe(
    map((([dv, locations]) => {
      return this.formatCurrentData(dv, locations)
    })),
    startWith(undefined))

  protected diffs$ = combineLatest([ this.currentDealData$, this.booking$]).pipe(
    map((([currentDealData, booking]) => {
      if(!booking) {
        return [];
      }
      const pairs = this.matchRequestsAndResponses(booking);
      return pairs.map(({ request, response }) => this.formatDifferential(currentDealData, request, response))?.reverse()
    })),
    startWith(undefined))
  inProgress$ = new BehaviorSubject<'loading'|'updating'|undefined>('loading')

  protected displayedDiff$ = combineLatest([this.diffs$, this.currentIndex$]).pipe(
    map(([diff, currentIndex]) => diff?.[currentIndex])
  )

  protected isOldestResponse$ = combineLatest([this.diffs$, this.currentIndex$]).pipe(
    map(([diff, currentIndex]) => !diff?.length || currentIndex === diff?.length - 1)
  )
  protected isMostRecentResponse$ = this.currentIndex$.pipe(map((index) => index === 0))

  ngOnInit(): void {
    this.store.dispatch(loadLocations({}))
    combineLatest([
      this.Deals.getDealView(this.dialogData.dealId, ['deal', 'segments', 'bookings']),
      this.store.pipe(select(selectLocationEntities), waitNotEmpty(), take(1)),
    ]).pipe(untilComponentDestroyed(this))
    .subscribe(([dealViewRaw]) => {
      this.dealViewRaw$.next(dealViewRaw)
      this.inProgress$.next(undefined)
    })

    this.actions$.pipe(ofType(approveBookingSuccess, rejectBookingSuccess, putBookingOnHoldSuccess), untilComponentDestroyed(this)).subscribe(() => {
      this.dialogRef.close()
    })

    merge(
      this.actions$.pipe(ofType(approveBooking, putBookingOnHold), mapTo('updating' as const)),
      this.actions$.pipe(ofType(approveBookingFailure, putBookingOnHoldFailure), mapTo(undefined)),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(inProgress => this.inProgress$.next(inProgress))
  }

  protected close() {
    this.dialogRef.close()
  }

  protected approve() {
    this.booking$.pipe(take(1)).subscribe(booking =>
      this.store.dispatch(approveBooking({ bookingId: booking.booking_id })))
  }

  protected reject() {
    this.booking$.pipe(take(1)).subscribe(booking =>
      this.rejectionDialog.showBookingRejectionDialog({ bookingId: booking.booking_id, dealId: this.dialogData.dealId }))
  }

  protected putOnHold() {
    this.booking$.pipe(take(1)).subscribe(booking =>
      this.store.dispatch(putBookingOnHold({ bookingId: booking.booking_id })))
  }

  protected showMontshipRejectionNotes() {
    this.NotesOverlay.showDealNotes(this.dialogData.dealId, [MONTSHIP_BOOKING_REJECTION_REASON]).subscribe()
  }

  protected seeOlder(): void {
    const currentIndex = this.currentIndex$.getValue()
    this.currentIndex$.next(currentIndex + 1);
  }

  protected seeNewer(): void {
    const currentIndex = this.currentIndex$.getValue()
    this.currentIndex$.next(currentIndex - 1);
  }


  private formatCurrentData(dv: DealView, locations: DeepReadonly<Dictionary<LocationObject>>): IDataRow {
    const { earliest: earliestMontship, earliestVessel: earliestMontshipVessel, latestVessel: latestMontshipVessel, latest: latestMontship } = montshipSegments(dv?.segments)
    const { latest } = lookupSegments(dv?.segments);

    return {
      pickupDate: earliestMontship?.attributes.actual_pickup_date,
      pickupLocation: earliestMontship?.attributes?.exact_loading?.address?.name,
      pickupAddress: this.formatLocationAddress(earliestMontship?.attributes?.exact_loading?.address),
      etd: etd(earliestMontshipVessel),
      eta: eta(latestMontshipVessel),
      origin: locations[earliestMontship?.attributes?.origin_id]?.name,
      destination: latest?.attributes?.carrier_account === montshipAccount.toString() ? locations[dv.deal.dest_location]?.name : locations?.[latestMontship?.attributes?.destination_id]?.name,
      portOfLoading: locations[earliestMontshipVessel?.attributes?.origin_id]?.name,
      portOfDischarge: locations[latestMontshipVessel?.attributes?.destination_id]?.name,
      vesselName: earliestMontshipVessel?.attributes?.vessel,
      bookingNumber: earliestMontshipVessel?.booking_id,
      finalDestination: locations[dv?.deal?.dest_location]?.name,
    }
  }

  private formatDifferential(current: IDataRow, request: MontshipBookingRequest, response: MontshipBookingResponse): {
    request: IDataRow
    current: IDataRow
    response: IDataRow
  } {
    return {
      // Per Montship's request, in the request from our side Port fields are actually used to send origin/destination,
      // and their system internally looks up actual ports.
      request: {
        pickupDate: request?.pickup_date,
        pickupAddress: this.formatLocationAddress(request?.warehouse, request?.warehouse_unlocode),
        pickupLocation: request?.warehouse?.name,
        etd: request?.etd_date,
        eta: request?.pickup_date,
        origin: `${request?.port_of_loading_name} (${request?.port_of_loading_unlocode})`,
        destination: `${request?.port_of_discharge_name} (${request?.port_of_discharge_unlocode})`,
        portOfLoading: request?.deal_loading_location_name,
        portOfDischarge: request?.deal_discharge_location_name,
        vesselName: undefined,
        bookingNumber: undefined,
        finalDestination: request?.deal_final_destination_name,
      },
      current,
      response: {
        pickupDate: response?.container_pickup_availability_date,
        pickupLocation: response?.place_of_delivery?.name,
        pickupAddress: undefined,
        etd: response?.port_of_loading.date,
        eta: response?.port_of_discharge.date,
        origin: response?.place_of_delivery?.name,
        destination: response?.place_of_receipt?.name,
        portOfLoading: response?.port_of_loading?.name,
        portOfDischarge: response?.port_of_discharge?.name,
        vesselName: response?.vessel_name,
        bookingNumber: response?.booking_number,
        finalDestination: undefined,
      },
    }
  }

  private matchRequestsAndResponses(booking: Booking): IMessagePair[] {
    if(!booking) {
      return [];
    }

    const requests = booking.request || [];
    const responses = booking.response || [];

    const sortedRequests = [...requests].sort((a, b) => {
        if (!a.created_at) return -1;
        return a.created_at - b.created_at;
    });

    const sortedResponses = [...responses].sort((a, b) => {
        if (!a.received_at) return -1;
        return a.received_at - b.received_at;
    });

    // Old request and response objects do not have their timestamps independent of the booking object
    if (sortedRequests.length > 0) {
        sortedRequests[0].created_at = sortedRequests[0].created_at || booking.created_at;
    }

    if (sortedResponses.length > 0 && !sortedResponses[0].received_at) {
        sortedResponses[0].received_at = sortedRequests.length > 0 ? sortedRequests[0].created_at + 1 : booking.created_at + 1;
    }

    const messagePairs: IMessagePair[] = [];

    let responseIndex = sortedResponses.length - 1;
    let lastProcessedRequestIndex = sortedRequests.length;

    while (responseIndex >= 0) {
        for (let currentRequestIndex = lastProcessedRequestIndex - 1; currentRequestIndex >= 0; currentRequestIndex--) {
            if (sortedResponses[responseIndex].received_at > sortedRequests[currentRequestIndex].created_at) {
                messagePairs.push({
                    request: sortedRequests[currentRequestIndex],
                    response: sortedResponses[responseIndex]
                });
                lastProcessedRequestIndex = currentRequestIndex;
                break;
            } else {
              messagePairs.push({
                request: sortedRequests[lastProcessedRequestIndex - 1],
                response: null,
              });
              lastProcessedRequestIndex = currentRequestIndex;
            }
        }
        responseIndex--;
    }

    // process any remaining unmatched requests
    for (let i = 0; i < lastProcessedRequestIndex; i++) {
        messagePairs.push({
            request: sortedRequests[i],
            response: null,
        });
    }

    messagePairs.sort((a, b) => {
        if (!a.request.created_at || !b.request.created_at) return 0;
        return a.request.created_at - b.request.created_at;
    });

    return messagePairs;
  }

  private bookingStatusDisplayName(status: MontshipBookingStatus) {
    if(status === 'on-hold') {
      return 'on hold';
    }

    return status;
  }

  private formatLocationAddress(address: GeneralAddress, unlocode?: string): string {
    const formatAddressSegments = (segments: string[], separator = ", ") => compact(segments).join(separator)?.trim();

    const streetLine = formatAddressSegments([address?.street1, address?.street2]);
    let cityLine = formatAddressSegments([address?.city, address?.state, address?.country])
    if(unlocode) {
      cityLine += ` (${unlocode})`;
    }

    return formatAddressSegments([streetLine, cityLine], "\n");
  }
}
