import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ProductTypesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/product-types.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadProductTypes, loadProductTypesFailure, loadProductTypesSuccess } from './product-type.actions'

@Injectable()
export class ProductTypeEffects {
  constructor(
    private actions$: Actions,
    private ProductTypes: ProductTypesService,
    private toaster: ToasterService,
  ) {}

  loadProductTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadProductTypes),
    switchMap(action =>
      from(this.ProductTypes.getProductTypes()).pipe(
        map(productTypes => loadProductTypesSuccess({
          tableKey: action.tableKey,
          productTypes: productTypes.data,
        })),
        catchError(error => {
          console.error('Unable to fetch productTypes', error)
          this.toaster.error('Unable to fetch productTypes', error)
          return of(loadProductTypesFailure({ error }))
        })))))
}
