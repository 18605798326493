import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  DeringerRecipientsDialogComponent,
  DeringerRecipientsDialogOptions,
} from './deringer-recipients-dialog.component';
import { User } from '@tradecafe/types/core';

@Injectable()
export class DeringerRecipientsDialogService {
  constructor(private modal: ModalService) { }

  showDeringerRecipients(dealId: string) {
    return this.modal
      .openDialog<
        DeringerRecipientsDialogComponent,
        DeringerRecipientsDialogOptions,
        {
          carrierRecipients: User[];
          notificationRecipients: string[];
        }
      >(
        DeringerRecipientsDialogComponent,
        { dealId },
        {
          panelClass: ['tc-dialog', 'modal-lg'],
          width: null,
          maxWidth: null,
          autoFocus: false,
        }
      )
      .toPromise();
  }
}
