import { TableIdentities } from '../constants/table-identities'
import { banksResolver, currenciesResolver, generateSimpleAccountsResolver } from '../services/data/deal.resolvers'
import { tableViewsResolver } from '../services/data/table-views.service'

export const routes = [
  {
    name: 'app.financial',
    // parent: 'app',
    url: '/financial',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['accounting', 'manager', 'administrator', 'superuser'],
      title: 'Financial',
    },
  }, {
    name: 'app.financial.default',
    // parent: 'app.financial',
    url: '',
    redirectTo: 'app.financial.receipts',
  }, {
    name: 'app.financial.receipts',
    // parent: 'app.financial',
    url: '/receipts?ref',
    component: 'adminFinancialReceiptList',
    data: {
      title: 'Receipts',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.financialReceiptList,
      tableViews: tableViewsResolver,
      companies: generateSimpleAccountsResolver(['account', 'type', 'name']),
      banks: banksResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.financial.receipts-importer',
    // parent: 'app.financial',
    url: '/receipts-importer',
    component: 'tcReceiptsImporterForm',
    data: {
      title: 'Receipts CSV Importer',
    },
  }, {
    name: 'app.financial.vendor-credits-importer',
    // parent: 'app.financial',
    url: '/vendor-credits-importer',
    component: 'tcVendorCreditsImporterForm',
    data: {
      title: 'Vendor Credits CSV Importer',
    },
    resolve: {
      back: () => 'app.financial.vendor-credits'
    },
  }, {
    name: 'app.financial.fx-rates',
    // parent: 'app.financial',
    url: '/fx-rates',
    component: 'adminFinancialFxRatesList',
    data: {
      title: 'FX Rates',
    },
  }, {
    name: 'app.financial.payables',
    url: '/payables',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Payables',
    },
  }, {
    name: 'app.financial.vendor-credits',
    url: '/vendor-credits',
    component: 'adminLogisticsVendorCreditList',
    data: {
      title: 'Vendor Credits',
      readonly: true,
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.logisticsVendorCreditList,
      importCsvRoute: () => 'app.financial.vendor-credits-importer',
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.financial.buyer-invoices',
    url: '/buyer-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Buyer Invoices',
    },
  }, {
    name: 'app.financial.credit-notes',
    // parent: 'app.financial',
    url: '/credit-notes',
    component: 'adminFinancialCreditNoteList',
    data: {
      title: 'Credit Notes',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.financialCreditNoteList,
      tableViews: tableViewsResolver,
    },
  },
  {
    name: 'app.financial.ar-report',
    url: '/ar-report',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'AR Report',
    },
  },
]
