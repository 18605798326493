import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { BUYER, DealParty, DealViewRaw, Note, SUPPLIER, VENDORS } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, flatten, map } from 'lodash-es'
import { saveNoteSuccess, specialInstructionsSuccess } from 'src/app/store/deal-view.actions'
import { NotesService, SPECIAL_INSTRUCTIONS } from 'src/services/data/notes.service'
import { ModalProxyService, ModalService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { NoteFormComponent, NoteFormOptionsDealSpecific, NoteFormOptionsGeneral } from './note-form.component'

const dialogConfig = {
  panelClass: ['tc-dialog', 'modal-lg'],
  width: null,
  maxWidth: null,
  componentName: 'NoteFormComponent'
}

@Injectable()
export class NoteFormService {
  constructor(
    private modal: ModalService,
    private modalHelper: ModalProxyService,
    private toaster: ToasterService,
    private Notes: NotesService,
    private store: Store,
  ) { }

  async createDealNote(dealViewRaw: DeepReadonly<Partial<DealViewRaw>>, note: DeepReadonly<DeepPartial<Note>>) {
    const stored = await this.showDealNote({ type: 'deal', dealViewRaw, note, title: 'Add Note' })
    stored?.forEach((s) => {
      this.store.dispatch(saveNoteSuccess({ note: s }))
    })
    return stored
  }

  async updateDealNote(dealViewRaw: DeepReadonly<Partial<DealViewRaw>>, note: DeepReadonly<Note>) {
    const stored = await this.showDealNote({ type: 'deal', dealViewRaw, note, title: 'Update Note' })
    if (stored) this.store.dispatch(saveNoteSuccess({ note: stored }))
    return stored
  }

  async createGeneralNote(note: Partial<Note>) {
    let notes = await this.showGeneralNote({ type: 'general', note, title: 'Add Note' })
    let stored
    if (Array.isArray(stored) && stored.length === 1) {
      stored = notes[0]
    }
    if (stored) this.store.dispatch(saveNoteSuccess({ note: stored }))
  }

  async addNoteWithSingleCategory(
    dv: DeepReadonly<DealViewRaw>,
    note: Partial<Note> = {},
    options: Partial<NoteFormOptionsGeneral> & { party?: DealParty } = {},
  ) {
    const newNote = cloneDeep(note)
    newNote.deal_id = dv.deal.deal_id
    // process general recipients
    const a = newNote.attributes
    if (options.party === BUYER) a.company = [dv.deal.buyer_id]
    if (options.party === SUPPLIER) a.company = [dv.deal.supplier_id]
    let storedNote = this.Notes.createNote(newNote)
    return storedNote
  }

  async addMultipleNotes(
    deals: DeepReadonly<DealViewRaw[]>,
    note: Partial<Note> = {},
    options: Partial<NoteFormOptionsGeneral> & { party?: DealParty } = {},
  ) {
    if (!deals.length) return false
    const notesWithMultipleCategories = await this.showGeneralNote({
      title: 'Add Note',
      note: defaultNote2(note),
      type: 'general',
      deals,
      onlyPayload: true,
      ...options,
    })
    if (!notesWithMultipleCategories) return false
    // API calls
    try {
      for (let i = 0; i < deals.length; i++) {
        for (let j = 0; j < notesWithMultipleCategories.length; j++) {
          await this.addNoteWithSingleCategory(deals[i], notesWithMultipleCategories[j], options)
        }
      }
      this.toaster.success('Notes added with multiple categories successfully.')
      return true
    } catch (error) {
      console.error('Unable to add notes with multiple categories', error)
      this.toaster.error('Unable to add note', error)
      return false
    }
    //
  }

  async setSpecialInstructions(deals: DeepReadonly<DealViewRaw[]>, party: DealParty) {
    const notesToIgnore = flatten(map(deals, dv =>
      (dv.notes || []).filter(note =>
        !note.attributes.ignored &&
        note.attributes.category === SPECIAL_INSTRUCTIONS &&
        note.attributes.company?.includes(dv.deal[`${party}_id`]))))

    const partyCaption = party.charAt(0).toUpperCase() + party.slice(1)
    const added = await this.addMultipleNotes(deals, {
      attributes: {
        category: SPECIAL_INSTRUCTIONS,
      },
      visibility: VENDORS,
    }, {
      party,
      hide: {
        company: true,
        category: true,
        visibility: true,
      },
      title: `Add/Update ${partyCaption} Special Instructions`,
    })
    if (added) await Promise.all(notesToIgnore.map(note => this.Notes.ignoreImmutable(note)))
    return added
  }

  /**
   * Edit special instructions
   *
   * @param {DeepReadonly<DealViewRaw>} deal
   * @param {DeepReadonly<Note>} specialInstructions
   * @param {(string | number)} partyId
   * @param {string} [category]
   * @memberof NoteFormService
   */
  async editSpecialInstructions(
    deal: DeepReadonly<DealViewRaw>,
    specialInstructions: DeepReadonly<Note>,
    partyId: string | number,
    category = SPECIAL_INSTRUCTIONS,
  ) {
    const note = specialInstructions
      ? await this.updateDealNote(deal, specialInstructions)
      : await this.createDealNote(deal, {
          attributes: {
            company: [partyId.toString()],
            category,
          },
          visibility: VENDORS,
        })
    if (note) this.store.dispatch(specialInstructionsSuccess({ note }))
  }

  private showDealNote(data: NoteFormOptionsDealSpecific) {
    return this.modal.openDialog(NoteFormComponent, data, dialogConfig).toPromise()
  }

  private showGeneralNote(data: NoteFormOptionsGeneral) {
    return this.modal.openDialog<NoteFormComponent, NoteFormOptionsGeneral, Note[]>(NoteFormComponent, data, dialogConfig).toPromise()
  }

  showOldNoteForm(note: Partial<DeepReadonly<Note>> = {}, { title, categories }: { title: string, categories?: string[]}) {
    return this.modalHelper.showModal({
      component: 'tcNoteForm',
      size: 'lg',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        modalSetting: () => {
          return { title }
        },
        note: () => angular.copy(note),
        categories: () => categories,
      },
    })
  }
}

function defaultNote2(note: Partial<Note>): Partial<Note> {
  return {
    visibility: 0,
    attributes: {},
    ...note,
  }
}
