import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { prepareInstructions } from '@tradecafe/documentlibrary-tags'
import { AccountObject, BUYER, Deal, DealBase, DealPartyE, DealView, DealViewBase, DealViewFiles, DealViewProducts, DealViewRow, DocumentTemplate, FileObject, Product, SERVICE_PROVIDER, SUPPLIER } from '@tradecafe/types/core'
import { DeepReadonly, getType, isDealConfirmedBy, isDealFormulaDeal, isDealSubmitted, isFileVisibleTo, isProforma, isPurchaseOrder, isSalesConfirmation } from '@tradecafe/types/utils'
import { compact, filter, find, flatten, get, includes, isEqual, map, merge, remove, set, uniq, without } from 'lodash-es'
import { Observable, combineLatest, from, of } from 'rxjs'
import { map as _map, catchError, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators'
import { DocumentSetApiService } from 'src/api/document-lib/document-set'
import { DealValidationService } from 'src/services/actions/deal-validation.service'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ToastedError } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealFormGroup, DealFormValue } from '../../deal-form/deal-form-page/deal-form.schema'
import { DealFormService } from '../../deal-form/deal-form-page/deal-form.service'
import { DocumentsGeneratorService } from '../create-document/documents-generator.service'
import { canBeVisible, isUnknownType } from './documents-acl.util'
import { FilesService } from './files.service'
import { FileNameFormService } from './form/file-name-form.service'


export interface DocumentTypeOption {
  id: string
  type: string
  name: string
}


/**
 * Deal Documents Service
 *
 * @export
 */
@Injectable()
export class DealDocumentsService {
  constructor(
    private DocumentSetApi: DocumentSetApiService,
    private FileNameForm: FileNameFormService,
    private Files: FilesService,
    private Invoices: InvoicesService,
    private DealViewLoader: DealViewLoaderService,
    private DocumentsGenerator: DocumentsGeneratorService,
    private toaster: ToasterService,
    private DealValidation: DealValidationService,
    private DealForm: DealFormService,
  ) { }

  /**
   * Remove documents, cleanup invoice objects
   *
   * @param {FileObject[]} files
   */
  async removeDocuments(files: DeepReadonly<FileObject[]>) {
    // before we remove a document - we've got to cleanup `invoice.attributes.files`
    const invoiceIds = uniq(compact(map(files, 'attributes.invoice_id')))
    const invoices = map(await this.Invoices.getByIds(invoiceIds))
    invoices.forEach(invoice => {
      const invoiceFiles = get(invoice, 'attributes.files', [])
      remove(invoiceFiles, file_id => !!find(files, { file_id }))
    })
    await Promise.all([
      ...invoices.map(invoice => this.Invoices.updateInvoice(invoice)),
      ...files.map(file => this.Files.removeFile(file)),
    ])
  }

  /**
   * Filter documents (file templates) according to docs ACL
   *
   * @see documents-acl.service.js
   *
   * @param {*} items
   * @param {*} allowedTypes
   * @returns
   */
  filterFor<T extends { type: string }>(items: T[], allowedTypes: string[]) {
    return items.filter(({type}) => {
      if (!allowedTypes.length) return true // show all if no types selected
      if (isUnknownType(type)) {
        return allowedTypes.includes('other')
      }
      if (allowedTypes.includes(SUPPLIER) &&
        canBeVisible(type, SUPPLIER)) return true
      if (allowedTypes.includes(BUYER) &&
        canBeVisible(type, BUYER)) return true
      if (allowedTypes.includes(SERVICE_PROVIDER) &&
        canBeVisible(type, SERVICE_PROVIDER)) return true
      return false
    })
  }

  /**
   * Fetch document set for the given deal
   *      - traders should not see anything but PO, SC and Proforma documents
   *      - don't let users create invoice documents while deal is in DRAFT state
   *      - apply "by product category" filters on client side (BE filters by country)
   *
   * @param {*} deal
   * @returns
   */
  getDocSetForDeal(deal: DeepReadonly<(Deal | DealViewBase) & Partial<DealViewProducts> & Partial<DealViewRow>>) {
    const product_category_id: string[] = []
    if (deal.products?.length) product_category_id.push(...deal.products.map(p => p.category_id))
    else if (deal.product?.['category_id']) product_category_id.push(deal.product?.['category_id'])
    return this.DocumentSetApi.getDocSet({
      buyer_id: deal.buyer_id.toString(),
      country_code: deal.attributes.docs_country,
      supplier_formula: !!deal.attributes.supplier_formula,
      buyer_formula: !!deal.attributes.buyer_formula,
      product_category_id,
    }).pipe(_map(r => r.data), tap(docs => {
      if (!docs.length) {
        this.toaster.error(`Unable to get documents set for ${deal.deal_id} / ${deal.attributes.docs_country}`)
        throw new ToastedError(`Unable to get documents set for ${deal.deal_id} / ${deal.attributes.docs_country}`)
      }
    }))
  }

  getDocTypesForDeal(deal: DeepReadonly<Deal | DealViewBase>): Observable<DocumentTypeOption[]> {
    return this.DocumentSetApi.getDocTypesForDeal({
      buyer_id: deal.buyer_id.toString(),
      country_code: deal.attributes.docs_country,
    }).pipe(_map(types => types.map(type => ({ id: type, name: type, type }))))
  }

  async checkDocumentsIssues(deals: DeepReadonly<Array<Deal | DealViewBase>>) {
    const warning = await combineLatest(deals.map(deal =>
      this.DealForm.load(deal.deal_id, undefined, ['deal', 'bids', 'offers']).pipe(
        switchMap(({ dealForm, products }) =>
          this.hasDocsCountryIssues$(dealForm, of(products)).pipe(
          _map(issues => issues ? `${deal.deal_id} ${issues}` : '')))),
    )).pipe(_map(issues => compact(issues).join('\n\n')), take(1)).toPromise()
    if (warning) {
      this.toaster.error(warning)
      throw new Error(warning)
    }
  }

  hasDocsCountryIssues$(dealForm: DealFormGroup, products$: Observable<DeepReadonly<Dictionary<Product>>>) {
    return combineLatest([replayForm<DealFormValue>(dealForm), products$]).pipe(
      _map(([df, products]) => ({
        buyer_id: df.details.buyerId,
        country_code: df.details.docsCountryCode,
        supplier_formula: !!df.details.supplierFormula,
        buyer_formula: !!df.details.buyerFormula,
        product_category_id: compact(df.products.map(({ productId }) => products[productId]?.category_id)),
      })),
      distinctUntilChanged(isEqual),
      switchMap(query =>
        this.DocumentSetApi.getDocSet(query).pipe(
          _map(({ data: docs }) => {
            const issues = []
            if (!docs.some(doc => isPurchaseOrder(doc, query.supplier_formula))) {
              issues.push('Purchase Order document template not found')
            }
            if (!docs.some(doc => isSalesConfirmation(doc, query.buyer_formula))) {
              issues.push('Sales Confirmation document template not found')
            }
            if (query.cc === 'CO' && !docs.some(doc => isProforma(doc))) {
              issues.push('Proforma document template not found')
            }
            return issues.join('\n')
          }),
          catchError((err: HttpErrorResponse) =>
            // BE can reply with 400 {"error":{"message":"Can not get document set by country code: <CC>"}}
            of(err.error?.error?.message || err.message)),
          distinctUntilChanged(),
        )))
  }

  /**
   * Remove all documents having similar document types as in provides files list
   *
   * @param {*} deal
   * @param {*} files replacement
   */
  async keepDealFilesUnique(dealId: string, files: DeepReadonly<FileObject[]>): Promise<void> {
    if (!files || !files.length) return

    const dealFiles = await this.Files.getByDealId(dealId)
    const idsToKeep = map(files, 'file_id')
    const typesToRemove = compact(map(files, 'attributes.document_type'))
    const filesToRemove = dealFiles.filter(existing =>
      includes(typesToRemove, get(existing, 'attributes.document_type')) &&
      !includes(idsToKeep, existing.file_id))
    await this.removeDocuments(filesToRemove)
  }

  /**
   * Attach files to the invoice
   *
   * @param {*} deal
   * @param {*} invoice
   * @param {*} files
   */
  async attachInvoiceDocs(dealId: string, invoice/* : DeepReadonly<Invoice> */, files: FileObject[]): Promise<void> {
    const dealFiles = await this.Files.getByDealId(dealId)
    // NOTE: by this moment we expect deal to have only unique document types
    //       including these passed in `files` arguments. this means some of the
    //       invoice attachments were deleted during `keepDealFilesUnique`

    // some if invoice attachments were probably deleted. we should only keep existing
    const existing = get(invoice, 'attributes.files', []).filter(file_id =>
      find(dealFiles, {file_id}))

    // update invoice fields
    invoice = {
      ...invoice,
      template: files[0].file_id, // TODO: old code, which doesn't make any sense imo
      attributes: {
        ...invoice.attributes,
        files: [
          ...existing, // keep existing files
          ...map(files, 'file_id'), // attach new files
        ]
      }
    }
    // update file fields
    files.forEach(file => set(file, 'attributes.invoice_id', invoice.invoice_id))

    await Promise.all<unknown>([
      this.Invoices.updateInvoice(invoice),
      ...files.map(file => this.Files.patchFileImmutable(file, {attributes: file.attributes})),
    ])
  }

  /**
   * Merge PDF files into one
   *
   * @param {*} deal_id
   * @param {*} files
   * @param {string} [name='']
   * @returns file object
   */
  async mergePdf(deal_id: string, files: DeepReadonly<FileObject[]>, name = ''): Promise<FileObject> {
    name = name || await this.FileNameForm.askForFilename(`DocSet_${deal_id}`, files)
    const file_ids = compact(map(files, (item) => {
      if (item.extension === '.pdf') {
        return item.file_id
      } else if (['.doc', '.docx'].indexOf(item.extension) > -1) {
        return get(item, 'attributes.pdf_version_file_id')
      }
      this.toaster.warning(`There is no PDF file for ${item.name}, ignored.`)
      return false
    }))
    const {data: file} = await this.DocumentSetApi.mergePdf({ deal_id, name, file_ids })
    return file
  }

  /**
   * Preview all deal clones documents
   *
   * @see https://docs.google.com/drawings/d/13hvDEqHcR32imL1CZKbyc-yANaI4YHyNPIDQefhgqsw/edit
   *
   * @param {*} deal dealView instance
   * @param {*} to must be 'buyer' or 'supplier',
   */
  async previewAllClonesDocuments(deal: DealView, to: DealPartyE): Promise<FileObject[]> {
    const cloneIds = without(get(deal, 'attributes.clone_ids'), deal.deal_id)
    let clones = []
    if (cloneIds.length) {
      clones = await Promise.all(map(cloneIds, id => this.DealViewLoader.loadView(id, [])))
      this.DealValidation.ensureOneParty([deal, ...clones], to)
      // don't let send confirmations if buyer_id or supplier_id fields are different
      await this.DealViewLoader.fetchPartsFor(clones, [
        'costs',
        'credit-notes',
        'vendor-credits',
        'invoices',
        'segments:dynamic',
        'notes',
        'editable',
      ])
    }
    return this.previewMergedDocuments([deal, ...clones], to)
  }

  /**
   * Preview  (download) merged confirmation documents for the given set of deals
   *
   * @param {*} deals[]
   * @param {*} to must be 'buyer' or 'supplier'
   */
  async previewMergedDocuments(deals: DealView[], to: DealPartyE): Promise<FileObject[]> {
    if (!deals.length) {
      console.error('no deals provided')
      throw new Error('no deals provided')
    }

    this.DealValidation.ensureOneParty(deals, to)
    if (to !== DealPartyE.brokerage_customer) {
      this.expectSameFormulaFlag(deals, to)
    }

    for (const deal of deals) {
      const when = isDealConfirmedBy(deal, to)
      if (when) this.toaster.warning(`Deal ${deal.deal_id} confirmation was sent out ${dayjs.utc(when * 1000).fromNow()}`)
    }

    const documentsAvailable = await this.fetchAvailableDocs(deals)

    const instructions = flatten(deals.map(deal =>
      prepareInstructions(deal, to, documentsAvailable[deal.deal_id], { deals })))
    this.logInstructions(instructions)

    for (const deal of deals) {
      await this.runGenerateInstructions(deal, filter(instructions, { deal }), {extraData: {deals}})
    }

    // download requested documents
    const downloadInstructions = filter(instructions, 'download')
    const deal_ids = uniq(map(downloadInstructions, 'deal.deal_id'))
    const file_ids = map(downloadInstructions, 'download.file_id')
    const {data: mergedPdfs} = await this.DocumentSetApi.mergeDocuments({ file_ids, deal_ids })

    // TODO: research, what if user cancels multiple simultaneous downloads
    await Promise.all(mergedPdfs.map(file => this.Files.download(file)))
    await this.runRemoveInstructions(instructions)

    await Promise.all(deals.map(deal =>
      this.runReplaceInstructions(deal.deal_id, filter(instructions, { deal }))))
    // refresh `deal.files` for the Shipping Log Details page
    await this.DealViewLoader.fetchFilesFor(deals)

    return mergedPdfs
  }

  /**
   * Preview deal documents in docx or pdf format
   *
   * @see https://docs.google.com/drawings/d/13hvDEqHcR32imL1CZKbyc-yANaI4YHyNPIDQefhgqsw/edit
   *
   * @param {*} deal
   * @param {*} to must be 'buyer' or 'supplier'
   */
  async previewDealDocuments(deal: DealView, to: DealPartyE): Promise<void> {
    const when = isDealConfirmedBy(deal, to)
    if (when) this.toaster.warning(`Deal ${deal.deal_id} confirmation has been sent out ${dayjs.utc(when * 1000).fromNow()}`)

    const documentsAvailable = await this.fetchAvailableDocs([deal])
    const instructions = prepareInstructions(deal, to, documentsAvailable[deal.deal_id])
    this.logInstructions(instructions)

    await this.runGenerateInstructions(deal, instructions)

    // download requested documents
    const docsToDownload = compact(map(instructions, 'download'))
    // TODO: research, what if user cancels multiple simultaneous downloads
    await Promise.all(docsToDownload.map(file => this.Files.download(file)))

    await this.runRemoveInstructions(instructions)
    await this.runReplaceInstructions(deal.deal_id, instructions)
    // NOTE: we don't call previewDealDocuments from the shipping log details
    // // refresh `deal.files` for the Shipping Log Details page
    // await this.DealViewLoader.fetchFilesFor([deal])
  }

  /**
   * When they change buyer/supplier AND then confirmations were NOT sent out we
   *    remove related PO or SC and Proforma documents.
   * When they change buyer/supplier AND then confirmations were sent out, TC will
   *    warn the user to delete OR rename the old confirmation documents that would
   *    stored in the doc section.
   *
   * @param {*} deal Deal View
   * @param {*} party 'buyer' or 'supplier'
   */
  onPartyChanged(deal: DeepReadonly<(Deal | DealViewBase) & DealViewProducts & DealViewFiles>, party: DealPartyE): Observable<void> {
    if (isDealSubmitted(deal)) {
      this.toaster.warning(`You've changed ${party} in ${deal.deal_id}. Please make sure you remove ${party} confirmation documents. They were already sent out.`)
      return of<void>(undefined)
    } else {
      return this.fetchAvailableDocsObs([deal]).pipe(switchMap(documentsAvailable => {
        const instructions = prepareInstructions(deal, party, documentsAvailable[deal.deal_id])
        const existingDocs = [
          ...compact(map(instructions, 'download')),
          ...compact(map(instructions, 'replace')),
        ]
        return from(this.removeDocuments(existingDocs))
      }))
    }
  }

  /**
   * Display orange toaster and throw an exception if deals have different parties
   *
   * @private
   * @param {*} deals
   * @param {*} party
   */
  private expectSameFormulaFlag(deals: DeepReadonly<DealBase[]>, party: DealPartyE) {
    const aFormulaDeal = deals.find(deal => isDealFormulaDeal(deal))
    const aNonFormulaDeal = deals.find(deal => !isDealFormulaDeal(deal))
    if (aFormulaDeal) {
      if (aNonFormulaDeal) {
        this.toaster.warning(`All the deals should have ${party} formula defined`)
        throw new Error(`All the deals should have ${party} formula defined`)
      }
    }
    return !!aFormulaDeal
  }

  /**
   * Fetch available document templates for the given deals set
   *
   * @private
   * @param {*} deals
   * @returns document templates grouped by deal_id
   */
  private fetchAvailableDocsObs(
    deals: DeepReadonly<(Deal | DealViewBase) & DealViewProducts>[],
  ): Observable<Record<string, DocumentTemplate[]>> {
    return combineLatest(deals.map(deal =>
      this.getDocSetForDeal(deal)
      .pipe(_map(docs => ({ [deal.deal_id]: docs }))),
    )).pipe(_map(res => merge({}, ...res)))
  }

  /**
   * Fetch available document templates for the given deals set
   *
   * @private
   * @param {*} deals
   * @returns document templates grouped by deal_id
   */
  private async fetchAvailableDocs(deals: DealView[]): Promise<Record<string, DocumentTemplate[]>> {
    return merge({}, ...await Promise.all(deals.map(deal =>
      Promise.all([
        this.getDocSetForDeal(deal).toPromise(),
        this.DealViewLoader.fetchFilesFor([deal]),
      ]).then(([documentsAvailable]) =>
        ({ [deal.deal_id]: documentsAvailable})))))
  }

  /**
   * Generate deal documents with respect to given instructions
   *
   * @private
   * @param {*} deal
   * @param {*} instructions
   */
  private async runGenerateInstructions(deal: DealView, instructions, opts?): Promise<void> {
    const docsToGenerate = filter(instructions, 'generate')
    if (!docsToGenerate.length) return
    const docs = map(docsToGenerate, 'generate')
    const files = await this.DocumentsGenerator.generateDocuments(deal, docs, opts)
    docsToGenerate.forEach((instruction, i) =>
      set(instruction, 'download', files[i]))
  }

  /**
   * Remove just generated preview documents. There is no need to store them.
   *
   * @private
   * @param {*} instructions
   * @param {*} [extraFilesToRemove=[]]
   */
  private async runRemoveInstructions(instructions, extraFilesToRemove = []): Promise<void> {
    const docsToGenerate = filter(instructions, 'generate')
    if (!docsToGenerate.length && !extraFilesToRemove.length) return
    // remove documents
    const filesToRemove = map(filter(instructions, 'remove'), 'download')
    await this.removeDocuments([
      ...filesToRemove,
      ...extraFilesToRemove,
    ])
  }

  /**
   * Replace existing documents with just generated docs
   *
   * @private
   * @param {*} deal
   * @param {*} instructions
   */
  private async runReplaceInstructions(dealId: string, instructions): Promise<void> {
    // replace old documents with new versions
    const filesToReplace = map(filter(instructions, 'replace'), 'download')
    if (!filesToReplace.length) return
    await this.keepDealFilesUnique(dealId, filesToReplace)
  }

  /**
   * Log document preview instructions. For debug purpose only
   *
   * @param {*} instructions
   */
  private logInstructions(instructions): void {
    // tslint:disable-next-line: cyclomatic-complexity
    instructions.forEach((instruction) => {
      // tslint:disable-next-line: no-shadowed-variable
      const {deal, generate, replace, remove, download} = instruction
      const isFormula = instruction.buyer_formula && instruction.supplier_formula
        ? '(formula price)'
        : instruction.buyer_formula ? '(buyer formula price)'
        : instruction.supplier_formula ? '(supplier formula price)'
        : '(flat price)'
      const toString = doc => getType(doc) + isFormula
      if (download) {
        console.info(`download document ${toString(download)} for ${deal.deal_id}`)
      } else if (generate && replace) {
        console.info(`regenerate and save document ${toString(generate)} for ${deal.deal_id}`)
      } else if (generate && remove) {
        console.info(`generate and remove document ${toString(generate)} for ${deal.deal_id}`)
      } else if (generate) {
        console.info(`generate and save document ${toString(generate)} for ${deal.deal_id}`)
      } else {
        console.error('unknown instruction', instruction)
      }
    })
  }

  async toggleVisibility(file: FileObject, party: Pick<AccountObject, 'account'>): Promise<void> {
    let readers = file.attributes?.readers || []
    if (isFileVisibleTo(file, party)) {
      // tslint:disable-next-line: triple-equals
      readers = readers.filter(reader => reader != party.account)
    } else {
      readers.push(party.account)
    }
    set(file, 'attributes.readers', readers)
    await this.Files.patch(file, {
      visibility: readers.length ? 1 : 0,
      attributes: file.attributes,
    })

    const pdfId = get(file, 'attributes.pdf_version_file_id')
    if (pdfId) { // if there is a pdf version - update visibility there as well
      const pdfFile = await this.Files.getFileById(pdfId)
      await this.Files.patch(pdfFile, {
        visibility: readers.length ? 1 : 0,
        attributes: { ...pdfFile.attributes, readers },
      })
    }
  }

  toggleVisibilityImmutable(file: DeepReadonly<FileObject>, party: { account: number }): Promise<void> {
    return this.setVisibility(file, party.account, !isFileVisibleTo(file, party))
  }

  async setVisibility(file: string | DeepReadonly<FileObject>, partyId: number | string, visible: boolean): Promise<void> {
    if (typeof file === 'string') file = await this.Files.getFileById(file)
    if (typeof partyId === 'string') partyId = parseFloat(partyId)

    let readers = [...file.attributes?.readers || []]
    if (visible) {
      readers.push(partyId)
    } else {
      readers = readers.filter(reader => reader != partyId)
    }
    const pdfId = file.attributes?.pdf_version_file_id
    await Promise.all([
      this.Files.patchFileImmutable(file, {
        visibility: readers.length ? 1 : 0,
        attributes: { ...file.attributes, readers },
      }),
      // if there is a pdf version - update visibility there as well
      pdfId && this.Files.getFileById(pdfId).then(pdfFile => this.Files.patchFileImmutable(pdfFile, {
        visibility: readers.length ? 1 : 0,
        attributes: { ...pdfFile.attributes, readers },
      })),
    ])
  }
}
