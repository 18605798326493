import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { WeightTypesService } from 'src/pages/admin/settings/product-specifications/weight-types/weight-types.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadWeightTypes, loadWeightTypesFailure, loadWeightTypesSuccess } from './weight-type.actions'

@Injectable()
export class WeightTypeEffects {
  constructor(
    private actions$: Actions,
    private WeightTypes: WeightTypesService,
    private toaster: ToasterService,
  ) {}

  loadWeightTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadWeightTypes),
    switchMap(action =>
      from(this.WeightTypes.getWeightTypes()).pipe(
        map(weightTypes => loadWeightTypesSuccess({
          tableKey: action.tableKey,
          weightTypes: weightTypes.data,
        })),
        catchError(error => {
          console.error('Unable to fetch weightTypes', error)
          this.toaster.error('Unable to fetch weightTypes', error)
          return of(loadWeightTypesFailure({ error }))
        })))))
}
