import { Pipe, PipeTransform } from "@angular/core"
import { User } from "@tradecafe/types/core"
import { DeepReadonly } from "@tradecafe/types/utils"

@Pipe({
  name: 'fullname'
})
export class FullNamePipe implements PipeTransform {
  transform(userObj: DeepReadonly<Pick<User, 'firstname' | 'lastname'>>): string {
    return `${userObj?.firstname || ''} ${userObj?.lastname || ''}`.trim();
  }
}
