import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadMeasures, loadMeasuresFailure, loadMeasuresSuccess } from './measure.actions'

@Injectable()
export class MeasureEffects {
  constructor(
    private actions$: Actions,
    private measures: MeasuresService,
    private toaster: ToasterService,
  ) {}

  loadMeasures$ = createEffect(() => this.actions$.pipe(
    ofType(loadMeasures),
    switchMap(action =>
      from(this.measures.getMeasures()).pipe(
        map(measures => loadMeasuresSuccess({
          tableKey: action.tableKey,
          measures,
        })),
        catchError(error => {
          console.error('Unable to fetch measures', error)
          this.toaster.error('Unable to fetch measures', error)
          return of(loadMeasuresFailure({ error }))
        })))))
}
