export const routes = [
  {
    name: 'app',
    abstract: true,
    url: '',
    component: 'tcApp',
  }, {
    name: 'app.default',
    url: '/',
    redirectTo: 'auth.login',
  }, {
    name: 'auth',
    // url: '/auth',
  }, {
    name: 'auth.login',
    url: '/login',
    component: 'tcLogin',
    params: {
      returnTo: null,
    },
  },
  {
    name: 'auth.password-forgot',
    url: '/forgot-password',
    component: 'tcPasswordForgot',
    params: {
      returnTo: null,
    },
  },
  {
    name: 'auth.password-reset',
    url: '/reset-password',
    component: 'tcPasswordReset',
    params: {
      returnTo: null,
    },
  },
]
