import { createAction, props } from '@ngrx/store'
import { PaymentReference, TableKey } from '@tradecafe/types/core'


export const loadPaymentReferences = createAction(
  '[PaymentReference/API] Load Payment References',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadPaymentReferencesSuccess = createAction(
  '[PaymentReference/API] Load Payment References Success',
  props<{
    tableKey?: TableKey,
    paymentReferences: PaymentReference[],
  }>(),
)
export const loadPaymentReferencesFailure = createAction(
  '[PaymentReference/API] Load Payment References Failure',
  props<{ error: Error }>(),
)
