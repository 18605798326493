<tc-select-search placeholder="Product category" [readonly]="readonly"
    [ctrl]="categoryCtrl"
    [items]="filteredCategories$ | async" bindLabel="name" bindValue="category_id"
    ></tc-select-search>
<tc-select-search placeholder="Product type" [readonly]="readonly"
    [ctrl]="typeCtrl"
    [items]="filteredTypes$ | async" bindLabel="name" bindValue="type_id"
    ></tc-select-search>
<tc-select-search #productSelect placeholder="Product title" [readonly]="readonly"
    [group]="group" [ctrlName]="ctrlName" [ctrl]="ctrl"
    [items]="filteredProducts$ | async" bindLabel="name" bindValue="product_id"
    (change)="change.next($event)"
    ></tc-select-search>
