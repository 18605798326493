import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { loadAccounts } from 'src/app/store/accounts'
import { loadLocations } from 'src/app/store/locations'
import { loadMeasures } from 'src/app/store/measures'
import { loadPricingTerms } from 'src/app/store/pricing-terms'
import { loadProducts } from 'src/app/store/products'
import { loadUsers } from 'src/app/store/users'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { TradingDetailsOverlayComponent, TradingDetailsOverlayOptions } from './trading-details-overlay.component'


@Injectable()
export class TradingDetailsOverlayService {
  constructor(
    private modal: ModalService,
    private store: Store,
    private DealForm: DealFormService,
  ) { }

  showTradingDetails(dataOrDealId: TradingDetailsOverlayOptions|string) {
    if (typeof dataOrDealId === 'string') {
      this.store.dispatch(loadLocations({}))
      this.store.dispatch(loadMeasures({}))
      this.store.dispatch(loadProducts({}))
      this.store.dispatch(loadAccounts({}))
      this.store.dispatch(loadUsers({}))
      this.store.dispatch(loadPricingTerms({}))

      return this.DealForm.load(dataOrDealId).pipe(switchMap(({ dealForm, dealViewRaw }) =>
        this.showTradingDetails({
          dealId: dataOrDealId,
          dealForm,
          dealViewRaw$: of(dealViewRaw),
        })),
      )
    } else {
      return this.modal.openLargeSide(TradingDetailsOverlayComponent, dataOrDealId)
    }
  }
}
