import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { User } from '@tradecafe/types/core'
import { sortBy } from 'lodash-es'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const {apiUrl} = environment

export default module =>
  module.service('UserApi', downgradeInjectable(UserApiService))


@Injectable()
@AngularCopy()
export class UserApiService {
  constructor(private http: HttpClient) {}

  MaxRequestSize = 10000

  @HaveAngularParams()
  list(account: number | string, @AngularParams() params, archived = 1) {
    let url: string = `${apiUrl}/${account}/users?`
    if (typeof archived === 'undefined' || archived === 1) {
      url+= 'archived=1'
    }
    return this.http.get<{ data: User[] }>(url, { params }).toPromise()
    .then(({data = []}) => {
      return {
        data: sortBy(data.map((user) => {
          user.fullname = ((user.firstname || '') + ' ' + (user.lastname || '')).trim()
          return user
        }), 'fullname'),
      }
    })
  }

  create(account: number, data) {
    return this.http.post<{ data: User }>(`${apiUrl}/${account}/users`, data).toPromise()
  }

  get(account: number, id) {
    return this.http.get<{ data: User }>(`${apiUrl}/${account}/users/${id}`).toPromise()
  }

  update(account: number, id, data) {
    return this.http.put<{ data: User }>(`${apiUrl}/${account}/users/${id}`, data).toPromise()
  }

  delete(account: number, id) {
    return this.http.delete<{ data: User }>(`${apiUrl}/${account}/users/${id}`).toPromise()
  }

  // isActive(account: number, id) {
  //   return this.http.get(`${apiUrl}/${account}/users/${id}/is-active`).toPromise()
  // }

  setPasswordById(id, password) {
    return this.http.put<void>(`${apiUrl}/users/reset-password`, {password}, { params: {user_id: id} }).toPromise()
  }

  // setPasswordByEmail(email, password) {
  //   return this.http.put(`${apiUrl}/users/reset-password`, {password}, { params: {email} }).toPromise()
  // }

}
