import { Component } from '@angular/core'

@Component({
  selector: 'tc-empty',
  template: '<router-outlet></router-outlet>',
})
export class EmptyComponent {}

@Component({
  selector: 'tc-very-empty',
  template: '',
})
export class VeryEmptyComponent {}
