<div mat-dialog-title>
  <h2>Deal #{{dealId$ | async}} Finance</h2>
  <tc-locks [resourceId]="dealId$" resourceType="deal"></tc-locks>
  <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
</div>
<div mat-dialog-content *ngIf="ready$ | async">
  <table class="dates-and-users">
    <tr>
      <td colspan=2></td>
      <td><span class="text-gray">Deal Submission Date: </span><strong>{{ (dealRaw$ | async).attributes.submitted_at | epoch:'L'}}</strong></td>
      <td><span class="text-gray">Logistics Coordinator: </span><strong>{{ logisticsUser$ | async }}</strong></td>
    </tr>
    <tr>
      <td><span class="text-gray">Supplier: </span><strong>{{ supplier$ | async }}</strong></td>
      <td><span class="text-gray">Confirmation Date: </span><strong>{{ (dealRaw$ | async).seller_confirmed | epoch:'L' }}</strong></td>
      <td><span class="text-gray">Confirmed By: </span><strong>{{ supplierUser$ | async }}</strong></td>
      <td><span class="text-gray">Buying Trader: </span><strong>{{ supplierTrader$ | async }}</strong></td>
    </tr>
    <tr>
      <td><span class="text-gray">Buyer: </span><strong>{{ buyer$ | async }}</strong></td>
      <td><span class="text-gray">Confirmation Date: </span><strong>{{ (dealRaw$ | async).buyer_confirmed | epoch:'L' }}</strong></td>
      <td><span class="text-gray">Confirmed By: </span><strong>{{ buyerUser$ | async }}</strong></td>
      <td><span class="text-gray">Selling Trader: </span><strong>{{ buyerTrader$ | async }}</strong></td>
    </tr>
    <tr *ngIf="isBrokerageDeal$ | async">
      <td><span class="text-gray">Brokerage 3rd Party: </span><strong>{{ brokerageCustomer$ | async }}</strong></td>
      <td><span class="text-gray">Confirmation Date: </span><strong></strong></td>
      <td><span class="text-gray">Confirmed By: </span><strong></strong></td>
      <td><span class="text-gray">Selling Trader: </span><strong>{{ brokerageTrader$ | async }}</strong></td>
    </tr>
  </table>

  <div class="brokerage-deal" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="15px"
      *ngIf="isBrokerageDeal$ | async">
    <div class="brokerage-info">
      <table>
        <tr>
          <td></td>
          <td><small class="text-gray">Estimated Cost</small></td>
          <td><small class="text-gray">Actual Cost</small></td>
        </tr>
        <tr>
          <td style="vertical-align: top"><small class="text-gray">Brokerage Service</small></td>
          <td>
            <strong>{{ detailsForm.controls.brokerageEstAmount.value | currency:detailsForm.controls.brokerageCurrency.value:'symbol-narrow'}}</strong>
            <small class="text-gray">{{detailsForm.controls.brokerageCurrency.value}}</small>
          </td>
          <td>
            <strong>{{ detailsForm.controls.brokerageActAmount.value | currency:detailsForm.controls.brokerageCurrency.value:'symbol-narrow'}}</strong>
            <small class="text-gray">{{detailsForm.controls.brokerageCurrency.value}}</small>
          </td>
        </tr>
      </table>
      <mat-form-field>
        <mat-label>Payment Terms</mat-label>
        <input readonly matInput [value]="detailsForm.supplierPaymentTerms$ | async">
      </mat-form-field>
    </div>
    <tc-brokerage-dates-form
        [dealForm]="dealForm"
        [dealViewRaw$]="dealViewRaw$"
        ></tc-brokerage-dates-form>
  </div>

  <tc-deal-products-list
      *ngIf="dealForm"
      [dealViewRaw$]="dealViewRaw$"
      [readonly]="!!incomplete.incomplete"
      [invoices$]="invoices$"
      [dealForm]="dealForm"
      [displayColumns]="[
        'name', 'estSupplierWeight', 'estSupplierPrice', 'estCost', 'estBuyerWeight', 'estBuyerPrice', 'estRevenue',
        'actSupplierPrice', 'actSupplierWeight', 'partialCost', 'actCost', 'actBuyerPrice', 'actBuyerWeight', 'grossWeight',
        'actRevenue', 'margin', 'ellipsisMenu']"
      ></tc-deal-products-list>

  <table class="table tc-table-small tc-ui-grid-like">
    <thead>
      <tr>
        <td colspan=2></td>
        <td>Act. Amt.</td>
        <td>Est. Amt.</td>
        <td>FX rate</td>
        <td>Act. Amt. CAD</td>
        <td>Est. Amt. CAD</td>
        <td>Partial Amt. CAD</td>
        <td>Unit Price</td>
        <td>Invoice #</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Purchase</td>
        <td>Supplier</td>
        <td><div *ngIf="(detailsForm.actualTotals$ | async)?.buy_price">
            <div>{{(detailsForm.actualTotals$ | async)?.buy_price | currency:(detailsForm.actualTotals$ | async)?.buy_currency:'symbol-narrow'}}</div>
            <small class="text-gray">{{(detailsForm.actualTotals$ | async)?.buy_currency}}</small></div></td>
        <td><div>{{(detailsForm.estimatedTotals$ | async)?.buy_price | currency:(detailsForm.estimatedTotals$ | async)?.buy_currency:'symbol-narrow'}}</div>
            <small class="text-gray">{{(detailsForm.estimatedTotals$ | async)?.buy_currency}}</small></td>
        <td><div>{{askRate$ | async}}</div>
            <small class="text-gray">{{(detailsForm.estimatedTotals$ | async)?.buy_currency}}CAD/ask</small></td>
        <td>{{(detailsForm.actualTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{(detailsForm.estimatedTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{partialBuyPriceCAD$ | async | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{(detailsForm.actualTotals$ | async)?.partial_buy_price / (detailsForm.actualTotals$ | async)?.partial_weight.amount | currency:(detailsForm.estimatedTotals$ | async)?.buy_currency:'symbol-narrow' | measure:(detailsForm.actualTotals$ | async)?.partial_weight.measure_id}}</td>
        <td *ngIf="supplierInvoice$ | async as si"><a href="#" (click)="$event.preventDefault(); showInvoice(si.invoice)">{{si.invoiceId}}</a>
          <div class="text-ellipsis">
            <small class="label text-uppercase" [ngClass]="'label-' + si.statusColor">
              {{si.statusName}}
            </small>
          </div>
        </td>
      </tr>
      <tr>
        <td>Sale</td>
        <td>Buyer</td>
        <td><div *ngIf="(detailsForm.actualTotals$ | async)?.sell_price">
            <div>{{(detailsForm.actualTotals$ | async)?.sell_price | currency:(detailsForm.actualTotals$ | async)?.sell_currency:'symbol-narrow'}}</div>
            <small class="text-gray">{{(detailsForm.actualTotals$ | async)?.sell_currency}}</small></div></td>
        <td><div>{{(detailsForm.estimatedTotals$ | async)?.sell_price | currency:(detailsForm.estimatedTotals$ | async)?.sell_currency:'symbol-narrow'}}</div>
            <small class="text-gray">{{(detailsForm.estimatedTotals$ | async)?.sell_currency}}</small></td>
        <td><div>{{bidRate$ | async}}</div>
            <small class="text-gray">{{(detailsForm.estimatedTotals$ | async)?.sell_currency}}CAD/bid</small></td>
        <td>{{(detailsForm.actualTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{(detailsForm.estimatedTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{partialSellPriceCAD$ | async | currency:'CAD':'symbol-narrow'}}</td>
        <td>{{(detailsForm.actualTotals$ | async)?.partial_sell_price / (detailsForm.actualTotals$ | async)?.partial_weight.amount | currency:(detailsForm.estimatedTotals$ | async)?.sell_currency:'symbol-narrow' | measure:(detailsForm.actualTotals$ | async)?.partial_weight.measure_id}}</td>
        <td *ngIf="buyerInvoice$ | async as bi"><a href="#" (click)="$event.preventDefault(); showInvoice(bi.invoice)">{{bi.invoiceId}}</a>
          <div class="text-ellipsis">
            <small class="label text-uppercase" [ngClass]="'label-' + bi.statusColor">
              {{bi.statusName}}
            </small>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="dealForm">
    <tc-deal-totals-v2 mode="finance" [dealDetails]="dealForm.controls.details"></tc-deal-totals-v2>
    <tc-costs-list
        [dealViewRaw]="dealViewRaw$ | async"
        [oldDealView]="oldDealView$ | async"
        [costsForm]="dealForm.controls.costs"
        [fxRates]="fxRates$ | async"
        [fxRatesRange]="fxRatesAskRange$ | async"
        [vendorCredits]="vendorCredits$ | async"
        [creditNotes]="creditNotes$ | async"
        (updateCost)="updateCost($event)"
        (removeCost)="removeCost($event)"
        (creditNotesChanged)="creditNotesChanged($event)"
        (vendorCreditsChanged)="vendorCreditsChanged($event)"
        ></tc-costs-list>
    <tc-invoices-list
        [footer]="false"
        [formatCurrency]="true"
        [displayCurrency]="true"
        [dataSource]="invoicesDataSource"
        [tableIdentity]="invoicesTableKey"
        (uploadAttachment)="showUploadAttachment($event)"
        ></tc-invoices-list>
    <tc-trading-summary
        [dealForm]="dealForm"
        [dealViewRaw$]="dealViewRaw$"
        ></tc-trading-summary>
    <tc-shipping-summary-v2 class="summary" [formGroup]="dealForm.controls.details"></tc-shipping-summary-v2>
  </ng-container>
</div>
