<table mat-table fixedLayout [dataSource]="dataSource" matSort matSortActive="created" matSortDirection="desc" matSort matSortDisableClear>
  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource" [title]="element.created | epoch:'DD-MMM-YYYY hh:mma'">
      <div class="text-ellipsis">{{element.created | epoch:'DD-MMM-YYYY'}}</div>
      <small class="text-gray">{{element.created | epoch:"hh:mma" }}</small>
    </td>
  </ng-container>
  <ng-container matColumnDef="deal_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Deal #</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource">
      <span [title]="element.deal_id">{{element.deal_id}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="actionText">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource" style="pointer-events: initial; cursor: default; ">
      <span [title]="element.action">{{element.actionText}}</span></td>
  </ng-container>
  <ng-container matColumnDef="offer_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Supplier Offer #</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource" style="pointer-events: initial;">
      <button class="btn-link" type="button"
          [title]="element.offer_id | fakeId:'OFFER-'"
          (click)="openSupplierOffer(element.offer_id)"
          >{{element.offer_id | fakeId:'OFFER-'}}
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="matched_offer_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Matched Offer #</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource" style="pointer-events: initial;">
      <button class="btn-link" type="button"
          [title]="element.matched_offer_id | fakeId:'OFFER-'"
          (click)="openMatchedOffer(element.matched_offer_id)"
          >{{element.matched_offer_id | fakeId:'OFFER-'}}
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Field</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource">
      <span [title]="element.key">{{element.key}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="old">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Old Value</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource">{{element.old}}</td>
  </ng-container>
  <ng-container matColumnDef="new">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>New Value</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource">{{element.new}}</td>
  </ng-container>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource">
      <span [title]="element.username">{{element.username}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="matched_offer_price">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
    <td mat-cell *matCellDef="let element; dataSource:dataSource" style="pointer-events: initial; cursor: default; ">
      <span [title]="element.attributes?.price">
        {{element.attributes?.price | currency:element.attributes?.currency | measure:element.attributes?.measure_id}}
      </span></td>
  </ng-container>
</table>

<mat-paginator #paginator></mat-paginator>
