import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { map as _map, chunk, compact, flatten, isEqual, isObject, mergeWith, transform } from 'lodash-es'
import { Observable, ObservedValueOf, OperatorFunction, ReplaySubject, Subject } from 'rxjs'
import { concatAll, map, takeUntil } from 'rxjs/operators'

export * from 'src/shared/utils/select-options'
export * from 'src/shared/utils/wait-not-empty'


export const angular = window['angular']


/**
 * Run multiple fn in parallesl. provide chunked list as inputs
 *
 * @export
 * @param {*} list
 * @param {*} chunkSize
 * @param {*} fn
 * @returns
 */
export async function chunked<T, TResult>(list: T[], chunkSize: number, fn: (ids: T[]) => Promise<TResult[]>) {
  return flatten(await Promise.all(_map(chunk(list, chunkSize), fn)))
}

export async function allPagesBy<TResult>(limit: number, fn: (page: { limit: number, skip: number }) => Promise<TResult[]>) {
  const result = []
  for (let i = 0; true; i++) {
    const data = await fn({ limit, skip: i * limit })
    if (!data.length) break
    result.push(...data)
  }
  return result
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function diff(o, b) {
  return changes(o, b)
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key]))
        ? changes(value, base[key]) : value
      }
    })
  }
}

export function uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

/**
 * Get list of dirty fields or `undefined`
 *
 * @param {*} [form=$ctrl.matchedOfferForm || {}]
 * @returns `undefined` if there're no dirty fields
 * @returns array with dirty angularjs form controls (w/ at least one control)
 */
export function getDirtyFields(form) {
  if (!form.$dirty) return undefined
  // NOTE: we use internal $$controls as in most cases we don't set controls "name" attribute
  if (!form.$$controls) return form // form is just a form control
  const dirtyFields = flatten(compact(_map(form.$$controls, getDirtyFields))) // recursion
  return dirtyFields.length ? dirtyFields : undefined
}

export function runDigest<T>($scope: ng.IScope, fn: (...args) => Promise<T>) {
  return async function(...args) {
    await fn(...args)
    $scope.$digest()
  }
}

export function unsub<T>($scope, sub) {
  $scope.$on('$destroy', () => sub.unsubscribe())
}

// TODO: move to @tradecafe/types (copied by operationsservice)
export function mergeDeep<T>(obj: T, ...srcs: DeepReadonly<DeepPartial<T>>[]) {
  return mergeWith(obj, ...srcs, overrideArrays)
}

function overrideArrays<T>(objValue: T, srcValue: DeepPartial<T>) {
  if (Array.isArray(objValue) || Array.isArray(srcValue)) return srcValue
  return undefined
}

export class ToastedError extends Error {
  toasted = true
}

export function queueMap<T, R, O extends Observable<R>>(project: (value: T) => O): OperatorFunction<T, ObservedValueOf<O>> {
  return (source) => {
    return new Observable((subscriber) => {
      const teardown = new Subject();
      source.pipe(
        map((val) => {
          const buffer = new ReplaySubject();
          project(val).pipe(takeUntil(teardown)).subscribe(buffer);
          return buffer.asObservable();
        }),
        concatAll(),
      ).subscribe(subscriber);
      return () => {
        teardown.next();
      };
    });
  };
}
