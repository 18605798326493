import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DealStatusComponent } from './deal-status.component'

@NgModule({
  declarations: [DealStatusComponent],
  exports: [DealStatusComponent],
  imports: [CommonModule, MatTooltipModule],
})
export class DealStatusModule {}
