import { NgModule } from '@angular/core'
import { FreightRatesPageComponent } from './freight-rates-page.component'
import { CommonModule } from '@angular/common'
import { FreightRatesPageRoutingModule } from './freight-rates-page-routing.module'
import { BreadcrumbsModule } from 'src/components/breadcrumbs/v2/breadcrumbs.module'
import { TableViewsMenuModule } from 'src/components/table-view/table-views-menu/table-views-menu.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { RouterModule } from '@angular/router'
import { StoreModule } from '@ngrx/store'
import { CsvExporterModule } from 'src/components/csv-exporter/csv-exporter.module'
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { StickyHeaderModule } from 'src/directives/sticky-header/sticky-header.module'
import { TableUtilsModule } from 'src/services/table-utils'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { CopyFreightRatesFormModule } from './copy-freight-rates/copy-freight-rates-form.module'
import { FreightRatesListModule } from './freight-rates-list/freight-rates-list.module'
import { FreightRatesLogOverlayModule } from './log-overlay/freight-rates-log-overlay.module'


@NgModule({
  declarations: [FreightRatesPageComponent],
  providers: [
  ],
  imports: [
    CommonModule,
    FreightRatesPageRoutingModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SelectSearchModule,
    TableUtilsModule,
    CsvExporterModule,
    TableViewsMenuModule,
    StickyHeaderModule,
    BreadcrumbsModule,
    StoreModule,
    RouterModule,
    EpochRangeFieldModule,
    ToasterModule,
    CopyFreightRatesFormModule,
    FreightRatesListModule,
    FreightRatesLogOverlayModule
  ],
})
export class FreightRatesPageModule { }
