import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { MatEpochDateModule } from '../epoch.module'
import { EpochRangeFieldComponent } from './epoch-range-field.component'


@NgModule({
  declarations: [EpochRangeFieldComponent],
  exports: [EpochRangeFieldComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatEpochDateModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
  ],
})
export class EpochRangeFieldModule { }
