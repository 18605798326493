import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Cost, Deal, DealRow, DealView, DealViewRawCosts, DealViewRawDeal, DealViewRawStatus } from '@tradecafe/types/core'
import { DeepReadonly, isDealSubmitted } from '@tradecafe/types/utils'
import { compact, identity, uniq } from 'lodash-es'
import { filter, map, switchMap } from 'rxjs/operators'
import { loadAccounts } from 'src/app/store/accounts'
import { loadCurrencies } from 'src/app/store/currencies'
import { autosaveDealCost } from 'src/app/store/deal-view.actions'
import { loadProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes } from 'src/app/store/product-types'
import { loadProducts } from 'src/app/store/products'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { isDealInInventory } from 'src/services/data/deal-view.service'
import { DealsService } from 'src/services/data/deals.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CostFormComponent, CostFormOptions } from './cost-form.component'


type DealContext = {
  deal_id?: string,
  status: string,
  supplier_id: number | string,
  buyer_id: number | string,
} | Deal | DealView

@Injectable()
export class CostFormService {
  constructor(
    private modal: ModalService,
    private ConfirmModal: ConfirmModalService,
    private Deals: DealsService,
    private store: Store,
    private toaster: ToasterService,
  ) {}

  showCreateCost(deal: DeepReadonly<DealContext>, cost?: Partial<Cost>): Promise<Partial<Cost>[]> {
    return this.modal.openDialog<CostFormComponent, CostFormOptions, Partial<Cost>[]>(CostFormComponent, {
      title: 'New Cost',
      canDuplicate: true,
      canEditAmount: !deal.deal_id || isDealInInventory(deal) || !isDealSubmitted(deal),
      canEditCurrency: true,
      supplierIds: [deal.supplier_id],
      buyerIds: [deal.buyer_id],
      cost,
    }, { width: '500px' }).toPromise()
  }

  showCreateMultipleCosts(deals: DeepReadonly<Array<DealRow>>) {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadCurrencies({}))
    this.modal.openDialog<CostFormComponent, CostFormOptions, Partial<Cost>[]>(CostFormComponent, {
      title: 'New Cost',
      canEditAmount: deals.every(deal => isDealInInventory(deal) || !isDealSubmitted(deal)),
      canEditCurrency: true,
      supplierIds: uniq(compact(deals.map(deal => deal.supplier_id))),
      buyerIds: uniq(compact(deals.map(deal => deal.buyer_id))),
    }, { width: '500px' }).pipe(
      filter(identity),
      switchMap(([cost]) =>
        this.Deals.getDealViews(deals.map(d => d.deal_id), ['deal', 'costs'])
        .pipe(map(dealViewRaws => [dealViewRaws, cost] as [Array<DealViewRawDeal & DealViewRawCosts & DealViewRawStatus>, Partial<Cost>])))
    ).subscribe(([dealViewRaws, cost]) => {
      this.toaster.progress('Saving deals costs...')
      dealViewRaws.forEach(dv => {
        this.store.dispatch(autosaveDealCost({ dv, costs: [...dv.costs, cost], index: [dv.costs.length] }))
      })
    })
  }

  showUpdateCost(
    deal: DeepReadonly<DealContext>,
    cost: DeepReadonly<Partial<Cost>>,
    actualizedBy?: string,
  ): Promise<Partial<Cost>> {
    return this.modal.openDialog<CostFormComponent, CostFormOptions, Partial<Cost>[]>(CostFormComponent, {
      title: 'Edit Cost',
      cost,
      canEditAmount: !deal.deal_id || isDealInInventory(deal) || !isDealSubmitted(deal),
      canEditCurrency: !deal.deal_id || isDealInInventory(deal) || !isDealSubmitted(deal) || !cost.cost_id,
      isActualizedToZero: cost.status === 'actualized' && cost?.attributes?.actual_amount === 0 && !!cost.actualized_at && !!actualizedBy && {
        username: actualizedBy,
        timestamp: cost.actualized_at,
      },
      supplierIds: [deal.supplier_id],
      buyerIds: [deal.buyer_id],

    }, { width: '500px' }).toPromise().then(r => r?.[0])
  }

  removeCost() {
    return this.ConfirmModal.show({
      title: 'Delete this?',
      description: 'Are you sure you want to delete this?',
    })
  }
}
