import { Pipe, PipeTransform } from '@angular/core'
import { Dayjs } from 'dayjs'
import { fromEpoch, fromEpochUTC } from 'src/services/dayjs'

@Pipe({
  name: 'epoch',
  pure: true,
})
export class EpochPipe implements PipeTransform {

  transform(value: Dayjs | Date | number | string, format = 'LL | LT', utc?: 'utc'): string {
    value = utc ? fromEpochUTC(value) : fromEpoch(value)
    if (!value) return ''
    return value.format(format)
  }

}
