import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'src/shared/modal';
import { ProblematicFreightRatesComponent } from './problematic-freight-rates.component';
import { ProblematicFreightRatesService } from './problematic-freight-rates.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FreightRatesPageModule } from '../freight-rates-page.module';

@NgModule({
  declarations: [ProblematicFreightRatesComponent],
  providers: [ProblematicFreightRatesService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ModalModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    FreightRatesPageModule
  ],
})
export class ProblematicFreightRatesModule {}
