import { IEpochRange, TableKey, TableViewV1, TableViewV2, TableViewV3 } from '@tradecafe/types/core'
import { compact, filter, identity, isPlainObject, map, mapValues, pick, pickBy, reduce, sortBy } from 'lodash-es'
import { FilterDef } from 'src/components/deals-list/deals-list.filters'
import { epochFromUtc } from 'src/directives/epoch-range/epoch-range.utils'
import { dayjs } from 'src/services/dayjs'
import { ColumnDef, showInternalColumns } from 'src/services/table-utils/columns.utils'

export interface TableViewV1Mapping {
  [tableKey: string]: string
  default: string
  alt?: string // we had duplicate columns in logistics shipping log (etd+onboard dates and forwarder+freight_forwarder)
  alt2?: string // v1 can potentially have columns, which were deprecated or renamed between 2018 and 2021.
  alt3?: string
  alt4?: string
}

/**
 * Table Views migration strategy/config
 */
export interface TableViewUpdater {
  // safeguard. updater should only update necessary table views.
  readonly tables: TableKey[]
  // fresh column definitions by id ("field")
  columns: Dictionary<ColumnDef>
  // columns availability config
  available: Partial<Record<TableKey, string[]>>
  columnsV1: Dictionary<TableViewV1Mapping>
  filters: Dictionary<string|[string, string]>
  filterSettings: Dictionary<FilterDef>
  // filter for v1 columns stored in uiGridState
  ignoreColumn(v1name: string): boolean
  ignoreFilter(v1name: string): boolean
  convertFilters(result: Dictionary<number|string | number[] | string[] | Date>): Dictionary<string | number[] | string[] | Date>
}


export function tableViewsUpgrade(
  tableViews: Array<TableViewV1|TableViewV2 | TableViewV3>,
  config: TableViewUpdater,
): TableViewV2[] {
  // safeguard. don't mess tables views not explicitly specified in config
  tableViews = tableViews.filter(tv => config.tables.includes(tv.table))

  // v1 - tables made for ng1. approximately between 2017 and 2021.
  const v1 = filter(tableViews, { attributes: { version: 1 }}) as TableViewV1[]
  // v2 - the very first version supporting angular tables. major upgrade
  const v2 = filter(tableViews, { attributes: { version: 2 }}) as TableViewV2[]
  // v3 - fixing minor issues
  const v3 = filter(tableViews, { attributes: { version: 3 }}) as TableViewV2[]
  const v4 = filter(tableViews, { attributes: { version: 4 }}) as TableViewV3[]

  // v1 to v2 conversion does not override v1 table views. create copies and hide originals
  const skipIds = compact(v2.map(tv => tv.attributes.original?.table_view_id))
  // upgrade v1 tableviews to v2
  let upgraded = v1
    .filter(tv => !skipIds.includes(tv.table_view_id))
    .map(tv => convertToV2(tv, config))
  // upgrade v2 tableviews to v3
  upgraded = [...v2, ...upgraded].map(convertToV3)
  return [...v3, ...upgraded].map(convertInvoiceType)
}

const buyerInvoices = [
  TableKey.ManagementBuyerInvoices,
  TableKey.TradingBuyerInvoices,
  TableKey.LogisticsBuyerInvoices,
  TableKey.FinancialBuyerInvoices,
  TableKey.ManagementVendorInvoices,
  TableKey.TradingVendorInvoices,
  TableKey.LogisticsVendorInvoices,
  TableKey.FinancialPayables,
]

/**
 * Upgrade table views to support multiple invoice types
 *
 * @param {TableViewV3} tv
 * @returns {TableViewV3}
 */
function convertInvoiceType(tv) {
  return {
    ...tv,
    filters: mapValues(tv.filters, (value, key) => {
      if (key === 'type' && value && !Array.isArray(value) && buyerInvoices.includes(tv.table)) return [value]
      return value
    }),
  }
}

/**
 * Upgrade table views v2 to v3.
 * Similar to angular version v2 to v3 is not as majoror as v1 to v2.
 * (In v3 we entroduce epoch-date-adapter.)
 *   * Update date filters format - convert Date|string to unix epoch.
 *
 * @param {TableViewV2} tv
 * @returns {TableViewV2}
 */
function convertToV3(tv: TableViewV2): TableViewV2 {
  return {
    ...tv,
    filters: mapValues(tv.filters, (value, key) => {
      if (!value) return value
      if (key === 'type' && value && !Array.isArray(value) && buyerInvoices.includes(tv.table)) return [value]
      if (typeof value === 'number') return value
      if (!key.endsWith('_from') && !key.endsWith('_to')) return value
      return dayjs(value as string|Date).unix()
    }),
  }
}

/**
 * Upgrade table views v1 (from ng1, implemented in 2017-2018)
 *  * rename columns, skip deprecated columns
 *  * rename filters, skip deprecated filters
 *  * upgrade sorting config format
 *  * read "displayColumns" from uiGridState
 *  * backup: store reference to original/v1 table_view_id
 *  * backup: store original filters and uiGridState
 *
 * @param {TableViewV1} tv1
 * @returns {TableViewV2}
 */
function convertToV2(tv1: TableViewV1, config: TableViewUpdater): TableViewV2 {
  // TODO: check if table key is defined in v2
  // TODO: create unified columns index (by table identity)
  const by1 = mapColumns(tv1.table, config, 'by1') // v1 id => v2 ud
  let filters = mapFilters(tv1.table, tv1.filters, config)
  let columns: string[] = []
  const sorts: { id: string, sort: { index: string, direction: 'asc'|'desc' }}[] = []

  // read page size, visible columns and sorting info from stored ui-grid state (from v1)
  const uiGridState = tv1.attributes.uiGridState ? JSON.parse(tv1.attributes.uiGridState) : undefined
  const limit = Math.min(50, uiGridState?.pagination?.paginationPageSize || 0)
  uiGridState?.columns.forEach(c => {
    if (config.ignoreColumn(c.name)) return
    const id = by1[c.name]
    if (!id) {
      console.warn(`WA-7399, WA-7599: TableView upgrade warning: skipping unknown column "${c.name}" (${tv1.attributes.name}) in ${tv1.table}`)
      return
    }
    if (c.visible) columns.push(id)
    if (c.sort?.direction) sorts.push({ id, sort: c.sort})
  })

  // show "internal" columns (checkbox, ellipsis)
  const available = map(pick(config.columns, config.available[tv1.table]))
  columns = showInternalColumns(available, columns)

  // select sort
  const firstSort = sortBy(sorts, 'sort.index')[0]
  const sort: any = firstSort ? { id: firstSort.id, start: firstSort.sort.direction } : undefined

  // build table view v2
  const { attributes: { name }, ...tv } = tv1
  filters = { ...filters, columns }
  if (sort) filters = { ...filters, sort }
  if (limit) filters = { ...filters, limit }
  return {
    ...tv,
    filters,
    attributes: {
      version: 2,
      name: name || '',
      original: {
        table_view_id: tv1.table_view_id,
        filters: tv1.filters,
        uiGridState: tv1.attributes.uiGridState,
      },
    },
  }
}

function mapFilters(
  tableKey: TableKey,
  filters: Dictionary<string|{}|IEpochRange|number[]|string[]>|undefined,
  config: TableViewUpdater,
) {
  let result = reduce(filters, (res, value, v1key) => {
    if (config.ignoreFilter(v1key)) return res
    const v2 = config.filters[v1key]
    if (!v2) {
      console.warn(`WA-7399, WA-7599: TableView upgrade warning: skipping unknown filter ${v1key} in ${tableKey}`)

    } else if (Array.isArray(v2)) { // epoch range filter - [*_from, *_to]
      if (!isPlainObject(value)) { // not { from, to, tbd? }
        console.warn(`WA-7399, WA-7599: TableView upgrade warning: skip date range filter with unsupported value ${v1key} in ${tableKey} value =`, value)
      } else {
        const [fromKey, toKey] = v2
        const { from, to } = value as IEpochRange
        if (from && to) {
          const { useUtc } = config.filterSettings[fromKey.replace(/_from$/g, '')]
          res[fromKey] = epochFromUtc(from, useUtc)
          res[toKey] = epochFromUtc(to, useUtc)
        } else {
          res[toKey] = res[fromKey] = null
        }
      }
    } else {
      res[v2] = value as string|number[]|string[]
    }
    return res
  }, {} as Dictionary<number|string|Date|number[]|string[]>)

  result = config.convertFilters(result)
  return result
}

function mapColumns(tableKey: TableKey, config: TableViewUpdater, version: 'by1'|'by2'): Dictionary<string>
function mapColumns(tableKey: TableKey, config: TableViewUpdater, version: string): void
function mapColumns(tableKey: TableKey, config: TableViewUpdater, version: 'by1'|'by2') {
  const columns = pick(config.columns, config.available[tableKey])
  if (version === 'by1') {
    return reduce(columns, (by1, { field }) => {
      const v1 = config.columnsV1[field]
      if (v1) {
        if (v1[tableKey]) by1[v1[tableKey]] = field
        if (v1.alt) by1[v1.alt] = field
        if (v1.alt2) by1[v1.alt2] = field
        if (v1.alt3) by1[v1.alt3] = field
        if (v1.alt4) by1[v1.alt4] = field
        by1[v1.default] = field
      }
      return by1
    }, {} as Dictionary<string>)
  } else if (version === 'by2') {
    return pickBy(mapValues(columns, ({ field }) => {
      const v1 = config.columnsV1[field]
      return v1?.[tableKey] || v1?.default
    }), identity)
  } else return undefined
}
