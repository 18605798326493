import { TableKey } from '@tradecafe/types/core'

export const TableIdentities = {
  admin: {
    logisticsFreightRateList: TableKey.LogisticsFreightRatesList,
    logisticsShippingLogList: 'admin.logistics.shipping-logs',
    logisticsTransportationList: 'admin.logistics.transportation',
    logisticsVendorInvoiceList: TableKey.LogisticsVendorInvoices,
    logisticsBuyerInvoiceList: TableKey.LogisticsBuyerInvoices,
    logisticsCreditNoteList: 'admin.logistics.credit-notes',
    logisticsVendorCreditList: 'admin.logistics.vendor-credits',
    logisticsDocuments: TableKey.LogisticsDocuments,
    logisticsARReport: TableKey.LogisticsArReportPage,
    logisticsIntegrationsDeringer: TableKey.IntegrationsDeringerPage,

    settingCompanyBusinessTypeList: 'admin.setting.companies.business-types',
    settingCompanyDeportmentList: 'admin.setting.companies.departments',
    settingCompanyList: 'admin.setting.companies.companies',
    settingGeographic: 'admin.setting.geographic',
    settingLocationsList: 'admin.setting.locations',
    trackingProviders: 'admin.setting.tracking-providers',
    settingTriggerList: 'admin.setting.triggers',
    settingPaymentsCurrency: 'admin.setting.payments.currency',
    settingPricingTerms: 'admin.setting.payments.pricing-terms',
    settingPaymentReferences: 'admin.setting.payments.references',
    settingPaymentBanks: 'admin.setting.payments.banks',
    settingPaymentMethods: 'admin.setting.payments.methods',
    settingProductCategoryList: 'admin.setting.product-categories',
    settingProductList: 'admin.setting.products',
    settingProductSpecificationList: 'admin.setting.product-specifications',
    settingProductSpecsWeightTypes: 'admin.setting.weight-types',
    settingProductSpecsItemTypes: 'admin.setting.item-types',
    settingProductSpecsMeasures: 'admin.setting.measures',
    settingProductSpecsPackaging: 'admin.setting.packaging',
    settingProductSpecsWrapping: 'admin.setting.wrapping',
    settingProductTypeList: 'admin.setting.product-types',
    settingTransitTimesList: 'admin.setting.transit-times',
    settingCustomCostList: 'admin.setting.custom-costs',
    settingTemplateList: 'admin.setting.templates',
    settingCustomTagList: 'admin.setting.custom-tags',
    settingStatusNoteList: 'admin.setting.status-notes',
    settingDocLibDocumentList: TableKey.DocLibDocumentTemplates,
    settingDocLibDocumentOverrideList: TableKey.DocLibDocumentOverrides,
    settingDocLibDocumentSetList: TableKey.DocLibDocumentSets,

    auctionSupplierOffersList: 'admin.trading.supplier-offers',
    auctionMatchedOffersList: 'admin.trading.matched-offers',
    auctionFutureBids: 'admin.trading.future-bids',

    tradingDealDocuments: TableKey.DealDocuments, // unused
    tradingDealList: TableKey.TradingDealsListPage,
    tradingShippingLogList: TableKey.TradingShippingLogPage,
    tradingEstimateList: 'admin.trading.estimates',
    tradingVendorInvoiceList: TableKey.TradingVendorInvoices,
    tradingBuyerInvoiceList: TableKey.TradingBuyerInvoices,
    tradingCreditNoteList: 'admin.trading.credit-notes',
    tradingIntransitReportList: 'admin.trading.intransit-report',
    tradingFreightRateList: TableKey.TradingFreightRatesList,
    tradingVendorCreditList: 'admin.trading.vendor-credits',
    tradingARReport: TableKey.TradingArReportPage,

    financialFxRatesList: 'admin.financial.fx-rates', // unused
    financialReceiptList: 'admin.financial.receipts',
    financialPayableList: TableKey.FinancialPayables,
    financialBuyerInvoiceList: TableKey.FinancialBuyerInvoices,
    financialCreditNoteList: 'admin.financial.credit-notes',
    financialARReport: TableKey.FinancialArReportPage,

    managementAuditTrailList: 'admin.management.audit-trails',
    managementBuyerInvoiceList: TableKey.ManagementBuyerInvoices,
    managementCreditNoteList: 'admin.management.credit-notes',
    managementCreditOverrideList: 'admin.management.credit-overrides',
    managementVendorInvoiceList: TableKey.ManagementVendorInvoices,
    managementARReport: TableKey.ManagementArReportPage,

    notificationFeedList: 'admin.notification.feeds',

    // table name for client shipping log list
    clientShippingLogList: 'buyer.shipping-log-list',
    supplierShippingLogList: 'supplier.shipping-log-list',
    serviceProviderShippingLogList: 'service-provider.shipping-log-list',
  } as any as Dictionary<TableKey>, // TODO: remove "as any"; rewrite table identities to enum
  others: {
  },
}
