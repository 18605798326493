<mat-form-field *ngIf="ctrl && (readonly || ctrl.disabled)"
    [floatLabel]="(hasLabel$ | async) ? 'always' : 'auto'"
    (click)="$event.preventDefault();$event.stopImmediatePropagation()">
  <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
  <div class="tc-select-search-readonly-value">
    <ng-container *ngTemplateOutlet="optionDirective || renderTrigger; context: getTemplateContext()"></ng-container>
  </div>
  <input readonly matInput style="visibility:hidden">
</mat-form-field>
<mat-form-field *ngIf="ctrl && (!readonly && ctrl.enabled)">
  <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
  <mat-select #select
      [class.tc-select-empty]="!(multiple ? ctrl.value?.length && removable : (ctrl.value || ctrl.value === 0) && removable)"
      [aria-label]="realPlaceholder || placeholder"
      [formControl]="ctrl"
      [compareWith]="compareObjects"
      [multiple]="multiple"
      [disableRipple]="true"
      [required]="isRequired"
      (selectionChange)="onSelectionChange()"
      (openedChange)="openChange($event)">
    <mat-select-trigger>
      <ng-container *ngTemplateOutlet="optionDirective || renderTrigger; context: getTemplateContext()"></ng-container>
      <button *ngIf="multiple ? ctrl.value?.length && removable : (ctrl.value || ctrl.value === 0) && removable"
          [attr.aria-label]="'Clear ' + (realPlaceholder || placeholder)"
          [disabled]="ctrl.disabled"
          mat-icon-button class="tc-select-clear" (click)="clear($event)">
        <mat-icon class="fa fa-times"></mat-icon>
      </button>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
          [ngClass]="{ 'has-close-button': showCloseButton }"
          [showToggleAllCheckbox]="showSelectAll"
          (toggleAll)="toggleSelectAll($event)"
          noEntriesFoundLabel="No match"
          [formControl]="itemFilterCtrl"
          placeholderLabel="Search {{realPlaceholder || placeholder || ''}}"
          ariaLabel="Search {{realPlaceholder || placeholder || ''}}">
        <mat-icon ngxMatSelectSearchClear class="fa fa-times"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <button *ngIf="showCloseButton" class="btn-apply" mat-stroked-button (click)="select.close()">
      Close
    </button>
    <mat-option *ngIf="!multiple && hasBlank" [value]="null">
      None
    </mat-option>
    <cdk-virtual-scroll-viewport [itemSize]="40"
        [style.--items-count]="(filteredItems | async).length || 1"
        style="height: min(calc(50vh - 50px), calc(40px * var(--items-count)))">
      <mat-option *cdkVirtualFor="let item of filteredItems | async"
          id="tc-filtered-option-{{bindValue ? (item | fieldOrPath:bindValue) : item}}"
          [disabled]="item?.$hiddenOption"
          [value]="bindValue ? (item | fieldOrPath:bindValue) : item"
          [matTooltip]="showTooltips ? item?.tooltip : ''"
          [matTooltipPosition]="tooltipPosition">
        <ng-container *ngTemplateOutlet="optionDirective || renderOption; context:{item: item}"></ng-container>
      </mat-option>
    </cdk-virtual-scroll-viewport>

    <mat-option *ngFor="let item of selectedItems$ | async trackBy: trackBy"
        id="tc-selected-option-{{bindValue ? (item | fieldOrPath:bindValue) : item}}"
        class="fake-selected-item"
        [disabled]="item?.$hiddenOption"
        [value]="bindValue ? (item | fieldOrPath:bindValue) : item"
        [matTooltip]="showTooltips ? item?.tooltip : ''"
        [matTooltipPosition]="tooltipPosition">
      <ng-container *ngTemplateOutlet="optionDirective || renderOption; context:{item: item}"></ng-container>
    </mat-option>

    <mat-option *ngIf="hasButton"></mat-option>

    <button #innerSelectButton
            *ngIf="hasButton"
            (click)="buttonAction.emit()"
            [matTooltip]="buttonTooltip"
            matTooltipPosition="left" class="btn-action" type="button">
      <i class="far fa-plus"></i> {{buttonText}}
    </button>
  </mat-select>
  <ng-content></ng-content>
  <mat-error>
    <ng-content select=".error-container"></ng-content>
  </mat-error>
</mat-form-field>

<ng-template #renderTrigger let-text="text">
  <span [title]="text">{{text}}</span>
</ng-template>

<ng-template #renderOption let-item="item">
  <ng-container *ngIf="bindHint">
    <div [title]="bindLabel ? (item | fieldOrPath:bindLabel:item) : item">{{bindLabel ? (item | fieldOrPath:bindLabel:item) : item}}</div>
    <small class="text-gray">{{item | fieldOrPath:bindHint}}</small>
  </ng-container>
  <ng-container *ngIf="!bindHint">
    {{bindLabel ? (item | fieldOrPath:bindLabel:item) : item}}
  </ng-container>
</ng-template>
