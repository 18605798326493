import { Injectable } from '@angular/core'
import { ItemType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { ItemTypeApiService } from 'src/api/product/item-type'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['name', 'attributes']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr

/**
 * ItemTypes service
 */
@Injectable()
export class ItemTypesService {
  constructor(
    private AuthApi: AuthApiService,
    private ItemTypeApi: ItemTypeApiService,
    private Query: QueryService,
  ) {}


  private cacheExpiration = 0
  private cache = Promise.resolve<{ data: ItemType[] }>({ data: [] })


  /**
   * Get all available itemTypes
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getItemTypes(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.ItemTypeApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get itemTypes by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=itemType)
   */
  async getItemTypesByIds(ids?: string[]) {
    const { data } = await this.getItemTypes()
    const index = keyBy(data, 'item_type_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get possible values for given itemType field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const { data } = await this.getItemTypes()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new itemType
   *
   * @param {any} itemType
   * @returns
   */
  async create(itemType: DeepReadonly<Partial<ItemType>>) {
    const payload = pick(itemType, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    const { data } = await this.ItemTypeApi.create({ ...payload, user_id })
    this.invalidateCache()
    return data
  }

  /**
   * Update itemType
   *
   * @param {any} itemType
   * @returns
   */
  async update(itemType: DeepReadonly<Partial<ItemType>>) {
    const { item_type_id } = itemType
    const payload = pick(itemType, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    const { data } = await this.ItemTypeApi.update(item_type_id, { ...payload, user_id })
    this.invalidateCache()
    return data
  }

  /**
   * Update itemType
   *
   * @param {any} itemType
   * @returns
   */
  async remove(itemType: DeepReadonly<ItemType>) {
    const { data } = await this.ItemTypeApi.delete(itemType.item_type_id)
    this.invalidateCache()
    return data
  }


  /**
   * Invalidate itemTypes in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }
}
