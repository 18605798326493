import { Injectable } from '@angular/core'
import { ForexDirection, ForexRates } from '@tradecafe/types/core'
import { fromCADSync, toCADSync } from '@tradecafe/types/utils'
import { mapValues, memoize } from 'lodash-es'
import { ForexApiService } from 'src/api/forex'
import { dayjs } from 'src/services/dayjs'

/**
 * FX Rates service
 *
 * @export
 */
@Injectable()
export class FxRatesService {
  private spotCache: any = {}
  private fetchRatesCached = memoize(this.fetchRates)


  constructor(private ForexApi: ForexApiService) {}

  /**
   * Get spot rates
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getFxRates(timestamp?) {
    const date = dayjs(timestamp).unix()
    const fiveMinutes = Math.floor(date / 300) * 300
    return this.fetchRatesCached(fiveMinutes)

    // const serverTime = moment(timestamp).tz('America/Toronto')
    // const nowServerTime = moment().tz('America/Toronto')
    // const serverTime = moment(timestamp)
    // const nowServerTime = moment()
    // const date = serverTime.format('YYYYMMDD')
    // const hour = date < nowServerTime.format('YYYYMMDD') ? 23 : moment(serverTime).format('hh')
    // const { data: rates } = await ForexApi.get({ date, hour })
  }

  private async fetchRates(timestamp: number) {
    const { data } = await this.ForexApi.get({ date: timestamp })
    return data
  }

  /**
   * Convert amount from given currency (code) to CAD
   *
   * @param {any} amount
   * @param {any} code - currency code
   * @returns
   */
  async toCAD(amount, code, direction = 'bid') {
    const rates = await this.getSpotRates()
    return parseFloat(rates[code][direction]) * amount
  }

  /**
   * Convert amount from given currency (code) to CAD
   *
   * @param {any} amount
   * @param {any} code - currency code
   * @returns
   */
  toCADSync(amount: number, code, direction: ForexDirection = 'bid', rates?: ForexRates, range?) {
    return toCADSync(amount, code, direction, rates || this.spotCache, range)
  }

  /**
   * Convert amount from CAD to given currency (code)
   *
   * @param {any} amount
   * @param {any} code - currency code
   * @returns
   */
  fromCADSync(amount: number, code, direction: ForexDirection = 'bid', rates?: ForexRates, range?) {
    return fromCADSync(amount, code, direction, rates || this.spotCache, range)
  }

  /**
   * Fetch spot rates and cache for infinite time
   *
   * @returns
   */
  async getSpotRates() {
    if (Object.keys(this.spotCache).length) {
      // TODO: refresh rates from time to time
      return this.spotCache
    }
    const { rates } = await this.getFxRates()
    Object.assign(this.spotCache, { CAD: { bid: 1, ask: 1 } }, mapValues(rates, 'spot'))
    return this.spotCache
  }
}
