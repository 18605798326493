import { Cost, Deal, DealProduct, DealViewRaw, Invoice, Note, Product, Segment } from '@tradecafe/types/core'
import { DeepReadonly, isDealClone, isDealConfirmed, isDealLocked, isDealPrimaryClone, isDealSubmitted } from '@tradecafe/types/utils'
import { cloneDeep } from 'lodash-es'
import { Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { buildProducts, createDealView } from 'src/services/data/deal-view.service'

export function newDealViewProducts(): DealProduct[] {
  return [{
    bid: {} as DealProduct['bid'],
    offer: {} as DealProduct['offer'],
    buyer: {} as DealProduct['buyer'],
    supplier: {} as DealProduct['supplier'],
    attributes: {} as DealProduct['attributes'],
  }]
}

export function oldDealViewSel(deal$: Observable<DeepReadonly<DealViewRaw>>, products$: Observable<DeepReadonly<Dictionary<Product>>>) {
  // TODO: write conversion function for compatibility
  return combineLatest([deal$, products$]).pipe(map(([dealView, products]) => {
    const { deal, offers, bids, costs, segments, invoices, notes } = dealView
    const v1 = createDealView(deal)
    if (deal.deal_id) {
      v1.products = buildProducts(v1, offers, bids, products)
    } else {
      v1.products = newDealViewProducts()
    }
    v1.notes = cloneDeep(notes || []) as Note[]
    v1.costs = cloneDeep(costs || []) as Cost[]
    v1.segments = cloneDeep(segments || []) as Segment[]
    v1.invoices = cloneDeep(invoices || []) as Invoice[]
    return v1
  }))
}

export function isLockedSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => isDealLocked(deal)), distinctUntilChanged())
}

export function isSubmittedSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => isDealSubmitted(deal)), distinctUntilChanged())
}

export function isNotSubmittedSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => !isDealSubmitted(deal)), distinctUntilChanged())
}

export function isConfirmedSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => isDealConfirmed(deal)), distinctUntilChanged())
}

export function isNotConfirmedSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => !isDealConfirmed(deal)), distinctUntilChanged())
}

export function isNotInProgressSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => !deal.attributes.in_progress), distinctUntilChanged())
}

export function isCloneSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => isDealClone(deal)), distinctUntilChanged())
}

export function isCopySel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => !!deal.attributes.copy_of), distinctUntilChanged())
}

export function isPrimaryCloneSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => isDealPrimaryClone(deal)), distinctUntilChanged())
}

export function cloneFromSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => deal.attributes.clone_from), distinctUntilChanged())
}

export function copyFromSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => deal.attributes.copy_of), distinctUntilChanged())
}

export function statusSel(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => deal.status), distinctUntilChanged())
}

export function isBwiSold(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => !!(deal && deal.sold_deal_id)), distinctUntilChanged())
}

export function bwiSoldDealId(deal$: Observable<DeepReadonly<Deal>>) {
  return deal$.pipe(map(deal => deal.sold_deal_id || null), distinctUntilChanged())
}
