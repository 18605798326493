<small class="text-ellipsis text-gray" *ngIf="status">
  <span class="label text-uppercase {{DealStatus[status]?.labelClass || 'label-danger'}}" [title]="title">
    {{DealStatus[status]?.name || status}}
  </span>
  <i class="fa fa-star" *ngIf="types.includes('primary-clone')"
      matTooltip="Primary Clone"
      ></i>
  <i class="fa fa-clone" *ngIf="types.includes('clone')"
      matTooltip="Clone of {{deal?.attributes?.clone_from || deal?.attributes?.copy_of || 'some other deal'}}"
      ></i>
  <i class="fa fa-unlock" *ngIf="types.includes('unlocked')"
      matTooltip="Unlocked"
      ></i>
  <i class="fa fa-calculator" *ngIf="types.includes('formula')"
      matTooltip="Formula Deal"
      ></i>
  <i class="fa fa-gift" *ngIf="types.includes('matched')"
      matTooltip="Matched Offer"
      ></i>
  <i class="fa fa-location-dot" *ngIf="types.includes('segments')"
      matTooltip="{{deal?.segments?.length || 'few'}} Segment(s)"
      ></i>
  <i class="fa fa-info" *ngIf="types.includes('bwi')"
      matTooltip="BWI Inventory Deal has been converted to a Supplier Offer and Accepted"
      ></i>
  <i class="fa fa-briefcase" *ngIf="types.includes('tci')"
      matTooltip="TCI Deal"
      ></i>
  <i class="fa fa-superpowers" *ngIf="types.includes('super-offer')"
      matTooltip="Supper Offer Deal"
      ></i>
  <i class="fa fa-b" *ngIf="types.includes('brokerage')"
      matTooltip="Supper Offer Deal"
      ></i>
</small>
