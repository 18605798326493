<div mat-dialog-title>
  <h2>Deal #{{dealId$ | async}} Details</h2>
  <tc-locks [resourceId]="dealId$" resourceType="deal"></tc-locks>
  <tc-finance-issues [detailsForm]="detailsForm"></tc-finance-issues>
  <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
  <span fxFlex></span>
  <button mat-button type="button" (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button type="submit" color="primary" (click)="saveDeal()"
      [class.tc-dirty-badge]="isDirtyAndValid$ | async"
      [class.tc-invalid-badge]="isInvalid$ | async"
      [matTooltip]="fieldsReport$ | async"
      matTooltipClass="pre-tooltip"
      matTooltipShowDelay="10000"
      [disabled]="inProgress$ | async">
    <i class="fa fa-fw"
        [class.fa-save]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
        ></i> Save
  </button>
</div>
<div mat-dialog-content>
  <tc-deal-details-v2 *ngIf="ready$ | async" mode="products" options="$ctrl.resolve.options"
      [dealId]="dealId$ | async"
      [dealForm]="dealForm"
      [dealViewRaw$]="dealViewRaw$"
      [oldDealView$]="oldDealView$"
      [costs$]="costs$"
      (updateCost)="updateCost($event)"
      (removeCost)="removeCost($event)"
      ></tc-deal-details-v2>
</div>
