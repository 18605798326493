import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { Observable, of } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealDetailsFormGroup } from '../../deal-form.schema'

@Component({
  selector: 'tc-shipping-summary-v2',
  templateUrl: './shipping-summary.component.html',
  styleUrls: ['./shipping-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingSummaryComponent implements OnInit {
  @Input() formGroup: DealDetailsFormGroup
  protected isBrokerage$: Observable<boolean>
  protected brok$: Observable<{ estAmount: number, actAmount: number, currency: string }>

  ngOnInit() {
    this.isBrokerage$ = environment.enableBrokerageDeals
      ? replayForm(this.formGroup.controls.dealType).pipe(map(type => type === 'brokerage'))
      : of(false)
    this.brok$ = replayForm(this.formGroup).pipe(
      map(f => ({ estAmount: f.brokerageEstAmount, actAmount: f.brokerageActAmount, currency: f.brokerageCurrency })),
      distinctUntilChanged())
  }
}
