import { createAction, props } from '@ngrx/store'
import { ProductCategory, TableKey } from '@tradecafe/types/core'


export const loadProductCategories = createAction(
  '[ProductCategory/API] Load Product Categories',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadProductCategoriesSuccess = createAction(
  '[ProductCategory/API] Load Product Categories Success',
  props<{
    tableKey?: TableKey,
    productCategories: ProductCategory[],
  }>(),
)
export const loadProductCategoriesFailure = createAction(
  '[ProductCategory/API] Load Product Categories Failure',
  props<{ error: Error }>(),
)
