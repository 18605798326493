import { Injectable } from '@angular/core';
import { AuthApiService } from 'src/api/auth';

@Injectable()
export class ProductsServicesPermissionsService {
  constructor(private AuthApi: AuthApiService) {}

  canEdit() {
    return ['administrator', 'manager', 'superuser'].includes(
      this.AuthApi.currentUser.role
    );
  }
}
