import { Injectable } from '@angular/core'
import { AccountObject, DistributionListObject } from '@tradecafe/types/core'
import { ModalService } from 'src/shared/modal/modal.service'
import { DistributionListOverlayComponent } from './distribution-list-overlay.component'

@Injectable()
export class DistributionListOverlayService {
  constructor(private modal: ModalService) { }

  showCreateDistributionList(company: AccountObject) {
    return this.modal.openDialog(
      DistributionListOverlayComponent,
      {
        company,
      },
      {
        panelClass: ['tc-dialog', 'modal-lg'],
        width: null,
        maxWidth: null,
        autoFocus: false,
        disableClose: false,
      },
    )
  }

  showUpdateDistributionList(company: AccountObject, distributionList: DistributionListObject) {
    return this.modal.openDialog(
      DistributionListOverlayComponent,
      {
        company,
        distributionList,
      },
      {
        panelClass: ['tc-dialog', 'modal-lg'],
        width: null,
        maxWidth: null,
        autoFocus: false,
        disableClose: false,
      },
    )
  }
}
