import { HttpClient, HttpEventType } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { AccountType, FileObject, FileObjectDeringerStatus, IEpochRange, Note, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { isEmpty, omit, pick } from 'lodash-es'
import { Observable, combineLatest, from, of } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'
import { ALLOWED_FIELDS } from 'src/services/data/accounts.service'
import { ESPaginationParams, ESSortParams } from 'src/services/elastic-search'

const { apiUrl } = environment

export function registerNg1 (module: ng.IModule) {
  module.service('FileApi', downgradeInjectable(FileApiService))
}


// files table row
export interface FileRow extends DeepReadonly<FileObject> {
  readonly view: DeepReadonly<FileElasticSearchItem['view']>
  readonly viewEx: DeepReadonly<{
    deringerStatus: FileObjectDeringerStatus | 'sent'
    user: User // TODO: update backend; move to `file.view`
    statusAt: number
    statusBy: string
    fileName: string
    fileExt?: string
    format: { name: string, icon: string }
    note_warning?: Note
    readers: string
    // UI only
    size: string
    canAddNote?: boolean
    canReject?: boolean
    canApprove?: boolean
  }>
}
export interface FilesFilter {
  deal_ids?: string[]
  created_from?: number
  created_to?: number
  readers?: number[]
  supplier_id?: number[]
  buyer_id?: number[]
  providers?: number[]
}

export interface ElasticUserViewItem {
  firstname: string
  lastname: string
  user_id: string
}
export interface ElasticCompanyViewItem {
  account: string // "1522684505.6218",
  name: string // "Vital Foods DEV",
  type?: AccountType // "supplier"
}
export interface ElasticCountryViewItem {
  code: string // "CO",
  country_id: string // "be6291e1-bb60-42b9-b006-32ee1ca000a7",
  name: string // "Colombia",

  created?: string // 1516385307,
  oldId?: string // "140",
  updated?: number // 1516385307,
  user_id?: string // "system"
}

export interface ElasticFileView {
  buyer_confirmed: number,
  seller_confirmed: number,
  pickup_date: number,
  dropoff_date: number,
  onboard_date: number,
  eta: number,
  etd: number,
  company: ElasticCompanyViewItem
  buyer: ElasticCompanyViewItem
  supplier: ElasticCompanyViewItem
  parties: Required<ElasticCompanyViewItem>[]
  docs_country: ElasticCountryViewItem
  coordinator: ElasticUserViewItem
  trader: ElasticUserViewItem
  // note_warning: boolean - // not implemented?
}

export interface FileElasticSearchItem {
  file: FileObject
  view: ElasticFileView
}

export interface FilesFilterV2 {
  deal_id?: string[]
  created?: IEpochRange
  readers?: number[]
  supplier?: number[]
  buyer?: number[]
  providers?: number[]
  trader?: string[]
  creator?: string[]
  coordinator?: string[]
  docs_country?: string[]
  doc_type?: string[]
  pickup_date?: IEpochRange
  dropoff_date?: IEpochRange
  onboard_date?: IEpochRange
  eta?: IEpochRange
  etd?: IEpochRange
}

@Injectable()
@AngularCopy()
export class FileApiService {
  constructor(private http: HttpClient) {}


  // @HaveAngularParams()
  // list(@AngularParams() params?) {
  //   return this.http.get<{ data: FileObject[] }>(`${apiUrl}/files`, { params }).toPromise()
  // }

  @HaveAngularParams()
  search(query: FilesFilter, @AngularParams() params?/* : { limit: number, skip: number } */) {
    return this.http.post<{ data: FileObject[] }>(`${apiUrl}/files/filter`, query, { params}).toPromise()
  }

  esSearch(query: FilesFilterV2, pagination: ESPaginationParams, sorts: ESSortParams) {
    return this.http.post<{ total: number, hits: FileElasticSearchItem[] }>(`${apiUrl}/search/files`, { query, ...pagination, sort: sorts })
  }

  esSearchFilters(query) {
    return this.http.post<Dictionary<any>>(`${apiUrl}/search/files/filters`, query)
  }

  create(data: DeepReadonly<FileObject>) {
    return this.http.post<{ data: FileObject }>(`${apiUrl}/files`, data).toPromise()
  }

  // byDealIds(deal_ids: string[]) {
  //   return this.http.post<{ data: FileObject[] }>(`${apiUrl}/files/by-multiple-deals`, { deal_ids }).toPromise()
  // }

  get(id: string) {
    return this.http.get<{ data: FileObject }>(`${apiUrl}/files/${id}`).toPromise()
  }

  update(id: string, data: DeepReadonly<Partial<FileObject>>) {
    return this.http.put<{ data: FileObject }>(`${apiUrl}/files/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: FileObject }>(`${apiUrl}/files/${id}`).toPromise()
  }

  approve(id: string) {
    return this.http.put<{ data: FileObject }>(`${apiUrl}/files/${id}/approve`, undefined).toPromise()
  }

  reject(id: string) {
    return this.http.put<{ data: FileObject }>(`${apiUrl}/files/${id}/reject`, undefined).toPromise()
  }

  sendToDeringer(id: string, cc: string[], notificationRecipients: string[], dealId?: string) {
    return this.http.post<{ data: FileObject }>(`${apiUrl}/files/${id}/deringer/send`, { cc, notificationRecipients, deal_id: dealId }).toPromise()
  }

  downloadAsBlob(id: string): Promise<Blob> {
    return this.http.get(`${apiUrl}/files/download/${id}`, { responseType: 'blob' }).toPromise()
  }

  downloadAsText(id: string) {
    return this.http.get(`${apiUrl}/files/download/${id}`, { responseType: 'arraybuffer' }).toPromise()
  }

  endpoint() {
    return `${apiUrl}/files`
  }
}

@Injectable()
export class FileUploaderService {
  constructor(private http: HttpClient, private FileApi: FileApiService) { }

  /**
   * Complex operation of uploading ng-file-upload fileItem with some extra form data
   *
   * NOTE: backend API allows only few form fields to be set when uploading files
   *       other fields should be set using `PUT update` API endpoint
   *
   * @param {*} fileItem
   * @returns
   */
  uploadFile(fileItem: File, file: Partial<FileObject>, progress: true): Observable<{ event: 'progress' | 'done', progress: number, file?: FileObject }>
  uploadFile(fileItem: File, file: Partial<FileObject>, progress: undefined|false): Observable<FileObject>
  uploadFile(fileItem: File, file: Partial<FileObject>): Observable<FileObject>
  uploadFile(fileItem: File, file: Partial<FileObject>, progress?: boolean): Observable<FileObject | { event: 'progress' | 'done', progress: number, file?: FileObject }> {
    const UPLOAD_ALLOWED_FIELDS = ['deal_id', 'visibility']
    const formData = new FormData()
    formData.append('file', fileItem)
    UPLOAD_ALLOWED_FIELDS.forEach(name => {
      if (file?.[name]) formData.append(name, file[name])
    })
    return this.http.post<{ data: FileObject }>(`${apiUrl}/files`, formData, {
      reportProgress: true,
      observe: 'events'
    })
    .pipe(
      filter(event => event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.Response),
      switchMap((event): Observable<{ event: 'progress' | 'done', progress: number, file?: FileObject }> => {
        if (event.type === HttpEventType.DownloadProgress) {
          return of({ event: 'progress', progress: 100 * event.loaded / fileItem.size })
        }
        if (event.type === HttpEventType.Response) {
          const uploadedFile = event.body.data
          if (isEmpty(omit(pick(file, ALLOWED_FIELDS), UPLOAD_ALLOWED_FIELDS))) return of({ event: 'done', progress: 100, file: uploadedFile })
          file = pick(file, ALLOWED_FIELDS)
          file.attributes = { ...file.attributes, ...uploadedFile.attributes } // keep attributes
          const pdfId = uploadedFile.attributes.pdf_version_file_id
          const queue = [from(this.FileApi.update(uploadedFile.file_id, file))]
          if (pdfId) queue.push(from(this.FileApi.update(pdfId, { attributes: file.attributes })))
          return combineLatest(queue).pipe(map(([r]) => ({ event: 'done', progress: 100, file: r.data })))
        }
        return of(undefined) // never reached
      }),
      filter(r => progress || r.event === 'done'),
      map(r => progress ? r : r.file),
    )
  }
}
