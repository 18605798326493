import { createAction, props } from '@ngrx/store'
import { LocationObject, TableKey } from '@tradecafe/types/core'


export const loadLocations = createAction(
  '[Location/API] Load Locations',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadLocationsSuccess = createAction(
  '[Location/API] Load Locations Success',
  props<{
    tableKey?: TableKey,
    locations: LocationObject[],
  }>(),
)
export const loadLocationsFailure = createAction(
  '[Location/API] Load Locations Failure',
  props<{ error: Error }>(),
)
