import { Injectable } from '@angular/core'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { ProductTypeApiService } from 'src/api/product/type'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['name', 'description', 'attributes']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr


/**
 * ProductTypes service
 *
 * @export
 * @returns
 */
@Injectable()
export class ProductTypesService {
  constructor(
    private AuthApi: AuthApiService,
    private ProductTypeApi: ProductTypeApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) { }


  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  productTypeChanged$ = new Subject<void>()

  /**
   * Get all available product-types
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getProductTypes(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.ProductTypeApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (productTypes) => {
        const { account } = this.AuthApi.currentUser
        const userIds = uniq(compact(map(productTypes.data, 'user_id')))

        const users = await this.Users.getUsersByIds(account, userIds)

        productTypes.data.forEach((productType: any) => {
          productType.user = users[productType.user_id]
          productType.created = productType.created || productType.createdAt
          productType.updated = productType.updatedAt || productType.updated || productType.created
        })
        return productTypes
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get product-types by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=productType)
   */
  async getProductTypesByIds(ids?: string) {
    const { data } = await this.getProductTypes()
    const index = keyBy(data, 'type_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get possible values for given productType field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName) {
    const { data } = await this.getProductTypes()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new productType
   *
   * @param {any} productType
   * @returns
   */
  async create(productType) {
    const payload = pick(productType, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.ProductTypeApi.create(payload)
    this.invalidateCache()
    this.productTypeChanged$.next()
    return data
  }

  /**
   * Update productType
   *
   * @param {any} productType
   * @returns
   */
  async update(productType) {
    const { type_id } = productType
    const payload = pick(productType, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.ProductTypeApi.update(type_id, payload)
    this.invalidateCache()
    this.productTypeChanged$.next()
    return data
  }

  /**
   * Update productType
   *
   * @param {any} productType
   * @returns
   */
  async remove({ type_id }) {
    const { data } = await this.ProductTypeApi.delete(type_id)
    this.invalidateCache()
    this.productTypeChanged$.next()
    return data
  }


  /**
   * Invalidate product-types in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }
}
