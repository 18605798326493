import { Injectable } from '@angular/core'
import { ModalProxyService } from 'src/shared/modal'


@Injectable()
export class FileNameFormService {
  constructor(private modalHelper: ModalProxyService) {}

  async askForFilename(defaultName: string, documents) {
    const {name} = await this.modalHelper.showModal({
      component: 'tcFileNameForm',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        name: () => defaultName,
        documents: () => documents,
      },
    })
    return name
  }
}
