import { createAction, props } from '@ngrx/store'
import { ItemType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'


export const loadItemTypes = createAction('[ItemType/API] Load Item Types')
export const loadItemTypesSuccess = createAction('[ItemType/API] Load Item Types Success', props<{ itemTypes: ItemType[] }>())
export const loadItemTypesFailure = createAction('[ItemType/API] Load Item Types Failure', props<{ error: Error }>(), )

export const createItemType = createAction('[ItemType/API] Create Item Type', props<{ itemType: DeepReadonly<Partial<ItemType>> }>() )
export const createItemTypeSuccess = createAction('[ItemType/API] Create Item Type Success', props<{ itemType: ItemType }>() )
export const createItemTypeFailure = createAction('[ItemType/API] Create Item Type Failure', props<{ error: Error }>())

export const updateItemType = createAction('[ItemType/API] Update Item Type', props<{ itemType: DeepReadonly<Partial<ItemType>>; id: string }>())
export const updateItemTypeSuccess = createAction('[ItemType/API] Update Item Type Success', props<{ itemType: ItemType }>() )
export const updateItemTypeFailure = createAction('[ItemType/API] Update Item Type Failure', props<{ error: Error }>())

export const deleteItemType = createAction('[ItemType/API] Delete Item Type', props<{ itemType: DeepReadonly<ItemType>; id: string }>())
export const deleteItemTypeSuccess = createAction('[ItemType/API] Delete Item Type Success', props<{ id: string }>() )
export const deleteItemTypeFailure = createAction('[ItemType/API] Delete Item Type Failure', props<{ error: Error }>())
