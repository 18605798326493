import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SelectSearchModule } from '../select-search/select-search.module'
import { ProductFieldComponent } from './product-field.component'


@NgModule({
  declarations: [ProductFieldComponent],
  exports: [ProductFieldComponent],
  imports: [CommonModule, SelectSearchModule],
})
export class ProductFieldModule { }
