import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DocumentTemplate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { environment } from 'src/environments/environment'

const { apiUrl } = environment

@Injectable()
export class DocumentApiService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<{ data: DocumentTemplate[] }>(`${apiUrl}/document-library/document`).toPromise()
  }

  create(data: DeepReadonly<Partial<DocumentTemplate>>) {
    return this.http.post<{ data: DocumentTemplate }>(`${apiUrl}/document-library/document`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: DocumentTemplate }>(`${apiUrl}/document-library/document/${id}`).toPromise()
  }

  update(id: string, data: DeepReadonly<Partial<DocumentTemplate>>) {
    return this.http.put<{ data: DocumentTemplate }>(`${apiUrl}/document-library/document/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: DocumentTemplate }>(`${apiUrl}/document-library/document/${id}`).toPromise()
  }

  addSet(id: string, setId: string) {
    return this.http.put<{ data: DocumentTemplate }>(`${apiUrl}/document-library/document/${id}/doc-set/add`, {set_id: setId}).toPromise()
  }

  removeSet(id: string, setId: string) {
    return this.http.put<{ data: DocumentTemplate }>(`${apiUrl}/document-library/document/${id}/doc-set/remove`, {set_id: setId}).toPromise()
  }

  /**
   * Download given file
   *
   * @param {*} file
   * @returns
   */
  async downloadTags() {
    return this.http.get(`${environment.apiUrl}/document/tags`, { responseType: 'blob' }).toPromise().then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'document-tags.csv'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
    })
  }
}
