import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LockApiService } from './lock-api.service'
import { LocksComponent } from './locks.component'
import { LocksService } from './locks.service'
import { OnlineStatusService } from './online-status.service'

@NgModule({
  declarations: [LocksComponent],
  exports: [LocksComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class LocksModule {
  static forRoot(): ModuleWithProviders<LocksServiceModule> {
    return { ngModule: LocksServiceModule }
  }
}

@NgModule({
  providers: [LockApiService, LocksService, OnlineStatusService],
})
export class LocksServiceModule {
}
