import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Deal, DealBase, DealViewDto, DealViewRaw } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { Subject } from 'rxjs'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'
import { DealFormDto } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { ElasticSearchPayload } from 'src/services/elastic-search'
import { prepareBackendFilters, prepareDealsPayload } from 'src/app/store/deal-view-filters.utils'
import { ColumnDef } from 'src/services/table-utils'


export function registerNg1(module) {
    return module.service('ExportApi', downgradeInjectable(ExportApiService))
}


const { apiUrl } = environment


@Injectable()
export class ExportApiService {
    constructor(private http: HttpClient) { }

    @HaveAngularParams()
    exportCsv(data: ElasticSearchPayload, columns: ColumnDef[]) {
        const query = {
           query: prepareBackendFilters(data.query),
           columns: data.columns, 
        };
        return this.http.post(`${apiUrl}/export/csv`, { query, columns }, { responseType: 'blob' }).toPromise()
    }
}