import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Message } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('MessageApi', downgradeInjectable(MessageApiService))

export enum SegmentNotificationType {
  Updated = 'SEGMENT_UPDATED',
  Created = 'SEGMENT_CREATED',
}

interface SegmentNotificationField {
  field: string,
  highlight: boolean,
  value: string,
}

export interface SegmentNotification {
  type: SegmentNotificationType,
  deal_id: string,
  segment_type: string,
  fields: SegmentNotificationField[],
  buyers?: string[],
  suppliers?: string[],
  serviceProviders?: string[],
  docs_available: boolean | number,
  emails?: string[],
  notes?: string,
  users: string[]
}

@Injectable()
@AngularCopy()
export class MessageApiService {
  constructor(private http: HttpClient) {}

  READ_STATUSES = {
    read: {
      id: 'read', label: 'Read',
    },
    unread: {
      id: 'unread', label: 'Unread',
    },
  }

  @HaveAngularParams()
  list(account: number, @AngularParams() params) {
    return this.http.get<{ data: Message[] }>(`${apiUrl}/messages/${account}`, { params }).toPromise()
  }

  create(account: number, data: Partial<Message>) {
    return this.http.post<{ data: Message }>(`${apiUrl}/messages/${account}`, data).toPromise()
  }

  delete(account: number, id: string) {
    return this.http.delete<{ data: Message }>(`${apiUrl}/messages/${account}/${id}`).toPromise()
  }

  update(account: number, id: string, data: Partial<Message>) {
    return this.http.put<{ data: Message }>(`${apiUrl}/messages/${account}/${id}`, data).toPromise()
  }

  get(account: number, id: string) {
    return this.http.get<{ data: Message }>(`${apiUrl}/messages/${account}/${id}`).toPromise()
  }

  archive(account: number, id: string) {
    return this.http.put<{ data: Message }>(`${apiUrl}/messages/${account}/${id}/archive`, {}).toPromise()
  }

  getUnread(account: number) {
    return this.http.get<{ data: Message[] }>(`${apiUrl}/messages/${account}/list-unread`).toPromise()
  }

  markAsRead(account: number, ids: string[]) {
    return this.http.post<{ data: Message[] }>(`${apiUrl}/messages/${account}/mass-mark-as-read`, {message_ids: ids}).toPromise()
  }

  sendAuthRequest() {
    return this.http.post<void>(`${apiUrl}/messages/notification/invoices`, {}).toPromise()
  }

  validatePhone(phone: string) {
    return this.http.post<void>(`${apiUrl}/messages/validate/phone`, { phone }).toPromise()
  }

  sendSegmentNotification(data: SegmentNotification) {
    return this.http.post<void>(`${apiUrl}/messages/notification/segments`, data).toPromise()
  }

  query(account: number, query, limit = 100, skip = 0) {
    return this.http.post<{ data: Message[], total: number }>(
      `${apiUrl}/messages/${account}/filter?limit=${limit}&skip=${skip}`,
      query,
    ).toPromise()
  }
}
