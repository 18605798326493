import { Injectable } from '@angular/core'
import { DealParty, FileObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, groupBy, pick } from 'lodash-es'
import { FileApiService } from 'src/api/file'

export const ALLOWED_FIELDS = ['deal_id', 'name', 'attributes', 'visibility', 'archived']

export const KNOWN = {
  pdf: {name: 'Adobe PDF', icon: 'fa-file-pdf'},
  word: {name: 'Microsoft Word', icon: 'fa-file-word'},
  excel: {name: 'Microsoft Excel', icon: 'fa-file-excel'},
  powerpoint: {name: 'Microsoft PowerPoint', icon: 'fa-file-powerpoint'},
  archive: {name: 'Archive', icon: 'fa-file-zipper'},
  text: {name: 'Text', icon: 'fa-file-lines'},
  image: {name: 'Image', icon: 'fa-file-image'},
  audio: {name: 'Audio', icon: 'fa-file-audio'},
  video: {name: 'Video', icon: 'fa-file-video'},
  binary: {name: 'Binary', icon: 'fa-file'},
}

export const SUBTYPES = {
  'pdf': KNOWN.pdf,
  'msword': KNOWN.word,
  'vnd.openxmlformats-officedocument.wordprocessingml.document': KNOWN.word,
  'vnd.ms-excel': KNOWN.excel,
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': KNOWN.excel,
  'vnd.ms-powerpoint': KNOWN.powerpoint,
  'vnd.openxmlformats-officedocument.presentationml.presentation': KNOWN.powerpoint,
  'x-7z-compressed': KNOWN.archive,
  'x-rar-compressed': KNOWN.archive,
  'x-bzip': KNOWN.archive,
  'x-bzip2': KNOWN.archive,
  'zip': KNOWN.archive,
  'x-tar': KNOWN.archive,
}

export const TYPES = {
  text: KNOWN.text,
  image: KNOWN.image,
  audio: KNOWN.audio,
  video: KNOWN.video,
}

export const DEFAULT = KNOWN.binary

export interface FileRow extends FileObject {
  format: string;
  parties?: {
    type: DealParty;
    account: number;
    name: string;
  }[];
}

/**
 * Files service. Provides low/mid level access to files API
 */
@Injectable()
export class FilesService {
  constructor(private FileApi: FileApiService) {}

  async getByDealId(dealId: string): Promise<FileObject[]> {
    const data = await this.getByDealIds([dealId])
    return data[dealId] || []
  }

  async getFilesByIds(fileIds: DeepReadonly<string[]>): Promise<FileObject[]> {
    if (!fileIds?.length) return []
    const files = await Promise.all<FileObject>(fileIds.map(fileId =>
      this.getFileById(fileId).catch((err) => {
        if (err.status !== 404) throw err // swallow 404
        return undefined
      })))
    return compact(files)
  }

  async getFileById(fileId: string) {
    return this.FileApi.get(fileId).then(r => r.data)
  }

  async getByDealIds(deal_ids: string[]) {
    let {data} = await this.FileApi.search({ deal_ids })
    const fileRows = data.filter(file => !file.archived).map(file => ({ ...file, format: getFileFormat(file) }))
    return groupBy(fileRows, 'deal_id')
  }

  removeFile(file: DeepReadonly<FileObject>) {
    return this.FileApi.delete(file.file_id).then(r => r.data)
  }

  async patch(file: FileObject, payload: Partial<FileObject>) {
    const stored = await this.patchFileImmutable(file, payload)
    Object.assign(file, stored)
    return stored
  }

  patchFileImmutable(file: DeepReadonly<Pick<FileObject, 'file_id'>>, payload: DeepReadonly<Partial<FileObject>>) {
    return this.FileApi.update(file.file_id, pick(payload, ALLOWED_FIELDS)).then(r => r.data)
  }

  /**
   * Download given file
   *
   * @param {*} file
   * @returns
   */
  async download(file: DeepReadonly<Pick<FileObject, 'file_id'|'name'>>) {
    // NOTE: WA-2918: we temporary disable "PDF for traders" restrictions
    // const {role} = AuthApi.currentUser
    // const {pdf_version_file_id} = file.attributes || {}
    // if (role === 'trader' && pdf_version_file_id) {
    //   file = await FileApi.get(pdf_version_file_id)
    // }
    return this.FileApi.downloadAsBlob(file.file_id).then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
    })
  }

  /**
   * Download given file
   *
   * @param {*} file
   * @returns
   */
  async downloadAsPdf(file: DeepReadonly<Pick<FileObject, 'file_id'|'name'|'attributes'>>) {
    return this.FileApi.downloadAsBlob(file.attributes.pdf_version_file_id).then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = file.name.replace(/docx$/, 'pdf')
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
    })
  }


  sendToDeringer(file: DeepReadonly<FileObject>, cc: string[], notificationRecipients: string[], dealId?: string) {
    const file_id = file.attributes.pdf_version_file_id || file.file_id
    return this.FileApi.sendToDeringer(file_id, cc, notificationRecipients, dealId).then(r => r.data)
  }
}

/**
 * Read file format
 *
 * @param {*} file
 * @returns {KNOWN.*} - well described and supported document format
 */
export function getFileFormat(file: DeepReadonly<Pick<FileObject, 'type'>>): { name: string, icon: string } {
  // NOTE: WA-2918: we temporary disable "PDF for traders" restrictions
  // const {role} = AuthApi.currentUser
  // if (role === 'trader' && get(file, 'attributes.pdf_version_file_id')) {
  //   return KNOWN.pdf
  // }

  const [type, subtype] = (file.type || '').split('/')
  return SUBTYPES[subtype] || TYPES[type] || DEFAULT
}
