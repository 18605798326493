<h2 mat-dialog-title>
  <i class="fa fa-users" aria-hidden="true"></i>&nbsp;Select User(s)
</h2>
<form [formGroup]="confirmForm" (submit)="save()" class="tc-squeeze-grid">
  <mat-dialog-content>
    <div *ngIf="sentAlready" class="alert alert-warning" role="alert">
      <i class="fas fa-2x fa-fw fa-exclamation-triangle"
        style="color: orange; font-size: 1.5em; vertical-align: middle;"></i>
      <span>The confirmation has already been sent out.</span>
    </div>
    <div class="form-group">
      <p *ngIf="description" [innerHtml]="description"></p>
    </div>
    <tc-select-search placeholder="Users" [multiple]="true"
        [group]="confirmForm" ctrlName="selectedUsers"
        [items]="users$ | async"
        bindValue="user_id"
        bindLabel="fullname"
        bindHint="primaryemail"
        ></tc-select-search>
    <div formGroupName="confirmForClones" *ngIf="suggestClonesOf.length">
      <mat-checkbox *ngFor="let primaryDeal of suggestClonesOf" [formControlName]="primaryDeal.deal_id"
          [title]="primaryDeal.attributes.clone_ids.join(', ')">
        Confirm for all clones of {{primaryDeal.deal_id}}
      </mat-checkbox>
    </div>
    <div>{{description2}}</div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="confirmForm.invalid">
      <i class="fa fa-check"></i> {{confirmButtonText}}
    </button>
  </mat-dialog-actions>
</form>
