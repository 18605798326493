import { TableKey } from '@tradecafe/types/core'
import { dayjsUnix } from '@tradecafe/types/utils'
import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'

const DEAL_COLUMNS: ColumnDef[] = [
  { field: 'select',                                        displayName: '', internal: true, index: -1 },
  { field: 'tradersMenu',                                   displayName: '', internal: true, index: 1001 },
  { field: 'logisticsMenu',                                 displayName: '', internal: true, index: 1001 },
  { field: 'tradersShippingLogMenu',                        displayName: '', internal: true, index: 1001 },
  { field: 'clonesMenu',                                    displayName: '', internal: true, index: 1001 },
  { field: 'aesMenu',                                       displayName: '', internal: true, index: 1001 },
  { field: 'deringerMenu',                                  displayName: '', internal: true, index: 1001 },
  { field: 'montshipMenu',                                  displayName: '', internal: true, index: 1001 },
  { field: 'macropointMenu',                                displayName: '', internal: true, index: 1001 },

  { field: 'actual_cost',                                   displayName: 'Act. Cost',                  exportField: 'attributes.actual.buy_price',                                    exportPipe: 'currency:attributes.estimated.buy_currency:symbol-narrow' },
  { field: 'actual_margin_p',                               displayName: 'Act. Margin %',              exportField: 'attributes.actual.margin_p',                                     exportPipe: 'percent' },
  { field: 'actual_margin',                                 displayName: 'Act. Margin',                exportField: 'attributes.actual.margin',                                       exportPipe: 'currency:CAD:symbol-narrow' },
  { field: 'actual_revenue',                                displayName: 'Act. Revenue',               exportField: 'attributes.actual.sell_price',                                   exportPipe: 'currency:attributes.actual.sell_currency:symbol-narrow' },
  { field: 'actual_weight_buy',                             displayName: 'Act. Net Weight Supplier',   exportField: 'attributes.actual.weight',                                       exportPipe: 'measure' },
  { field: 'actual_weight_sell',                            displayName: 'Act. Net Weight Buyer',      exportField: 'actual_weight_sell',                                             exportPipe: 'measure' },
  { field: 'art_work',                                      displayName: 'Art Work',                   exportField: 'attributes.extra.art_work',                                      exportPipe: 'yesNo' },
  { field: 'bol_instructions',                              displayName: 'BOL Instructions',           exportField: 'attributes.extra.bol_instructions',                              exportPipe: 'yesNo' },
  { field: 'booking_id',                                    displayName: 'Booking #',                  exportField: 'booking_id',                                                     exportPipe: '' },
  { field: 'brand',                                         displayName: 'Brand',                      exportField: 'product.brand',                                                  exportPipe: '' },
  { field: 'buy_price',                                     displayName: 'Supplier Price',             exportField: 'product.buy_price',                                              exportPipe: 'price' },
  { field: 'buyer_incoterm',                                displayName: 'Buyer Incoterm',             exportField: 'product.buyer_incoterm.term',                                    exportPipe: '' },
  { field: 'buyer_incoterm_location',                       displayName: 'Buyer Incoterm Location',    exportField: 'product.buyer_incoterm_location.name',                           exportPipe: '' },
  { field: 'buyer_invoice_date',                            displayName: 'Buyer Invoice date',         exportField: 'buyer_invoice.issued',                                           exportPipe: 'epoch' },
  { field: 'buyer_invoice_id',                              displayName: 'Buyer invoice #',            exportField: 'buyer_invoice.invoice_id',                                       exportPipe: '' },
  { field: 'buyer_invoice_paid',                            displayName: 'Act. Buyer Paid Date',       exportField: 'buyer_invoice.attributes.paid',                                  exportPipe: 'epoch' },
  { field: 'buyer_invoice_due',                             displayName: 'Due Date',                   exportField: 'buyer_invoice.due',                                              exportPipe: 'epoch' },
  { field: 'buyer_ref',                                     displayName: 'Buyer ref. #',               exportField: 'attributes.buyer_ref',                                           exportPipe: '' },
  { field: 'buyer',                                         displayName: 'Buyer',                      exportField: 'buyer.name',                                                     exportPipe: '' },
  { field: 'buying_trader',                                 displayName: 'Buying Trader',              exportField: 'buying_trader.fullname',                                         exportPipe: '' },
  { field: 'carrier',                                       displayName: 'Carrier',                    exportField: 'carrier.name',                                                   exportPipe: '' },
  { field: 'collection_date',                               displayName: 'Collection Date',            exportField: 'collection_date',                                                exportPipe: 'epoch' },
  { field: 'color',                                         displayName: 'Color',                      exportField: 'attributes.extra.color',                                         exportPipe: '' },
  { field: 'confirmed_at',                                  displayName: 'Confirmed Date',             exportField: 'attributes.confirmed_at',                                        exportPipe: 'epoch' },
  { field: 'consignee',                                     displayName: 'Consignee',                  exportField: 'attributes.shipment.delivery.consignee.name',                    exportPipe: '' },
  { field: 'container_number',                              displayName: 'Container #',                exportField: 'container_number',                                               exportPipe: '' },
  { field: 'coordinator',                                   displayName: 'Coordinator',                exportField: 'coordinator.fullname',                                           exportPipe: '' },
  { field: 'courier_track_id',                              displayName: 'Courier Track. #',           exportField: 'courier_track_nums',                                             exportPipe: '' },
  { field: 'credit_balance',                                displayName: 'Credit Balance',             exportField: 'int_cred_avail',                                                 exportPipe: 'currency:CAD:symbol-narrow' },
  { field: 'date_invoiced',                                 displayName: 'Date Invoiced',              exportField: 'attributes.invoiced_at',                                         exportPipe: 'epoch' },
  { field: 'deal_date',                                     displayName: 'Date',                       exportField: 'attributes.deal_date',                                           exportPipe: 'epoch' },
  { field: 'deal_id',                                       displayName: 'Deal #',                     exportField: 'deal_id',                                                        exportPipe: '' },
  { field: 'delivered',                                     displayName: 'Delivered',                  exportField: 'attributes.extra.delivered',                                     exportPipe: 'epoch' },
  { field: 'delivery_date',                                 displayName: 'Est. Delivery Period',       exportField: 'attributes.delivery_dates',                                      exportPipe: 'epochRange:true', useUtc: true },
  { field: 'dest_country',                                  displayName: 'Destination Country',        exportField: 'dest_country.name',                                              exportPipe: '' },
  { field: 'dest_location',                                 displayName: 'Delivery',                   exportField: 'dest_location_name',                                             exportPipe: '' },
  { field: 'docs_country',                                  displayName: 'Docs Country',               exportField: 'docs_country.name',                                              exportPipe: '' },
  { field: 'documents',                                     displayName: 'Documents',                  exportField: 'attributes.extra.documents',                                     exportPipe: 'yesNo' },
  { field: 'documents_count',                               displayName: 'Docs Count',                 exportField: 'documents_count',                                                exportPipe: '' },
  { field: 'dropoff_date',                                  displayName: 'Dropoff Date',               exportField: 'dropoff_date',                                                   exportPipe: 'epoch' },
  { field: 'dropoff_location',                              displayName: 'Drop-off Loc.',              exportField: 'dropoff_location.name',                                          exportPipe: '' },
  { field: 'e_docs_to_customs_broker',                      displayName: 'e-Docs To Customs Broker',   exportField: 'attributes.extra.edocs_to_customs_broker',                       exportPipe: 'yesNo' },
  { field: 'epd',                                           displayName: 'EPD',                        exportField: 'attributes.shipment.epd_date',                                   exportPipe: 'epoch' },
  { field: 'erd',                                           displayName: 'ERD',                        exportField: 'attributes.shipment.erd_date',                                   exportPipe: 'epoch' },
  { field: 'est_delivery_date',                             displayName: 'Est. Delivery',              exportField: 'est_delivery_date',                                              exportPipe: 'epoch' },
  { field: 'est_id',                                        displayName: 'Est #',                      exportField: 'product.establishment_ids',                                      exportPipe: '' },
  { field: 'est_pickup_date',                               displayName: 'Est. Pickup',                exportField: 'est_pickup_date',                                                exportPipe: 'epoch' },
  { field: 'estimated_margin',                              displayName: 'Estimated Margin (CAD)',     exportField: 'attributes.estimated.margin',                                    exportPipe: '' },
  { field: 'eta',                                           displayName: 'ETA',                        exportField: 'latestVesselSegment.attributes.actual_delivery_date',            exportPipe: 'epoch' },
  { field: 'etd',                                           displayName: 'ETD',                        exportField: 'earliestVesselSegment.attributes.actual_pickup_date',            exportPipe: 'epoch' },
  { field: 'exact_dropoff_address',                         displayName: 'Exact Dropoff Address',      exportField: 'latestSegment.attributes.exact_dropoff.address.name',            exportPipe: '' },
  { field: 'exact_loading_address',                         displayName: 'Exact Loading Address',      exportField: 'earliestSegment.attributes.exact_loading.address.name',          exportPipe: '' },
  { field: 'forwarder',                                     displayName: 'Forwarder',                  exportField: 'forwarder.name',                                                 exportPipe: '' },
  { field: 'gross_weight',                                  displayName: 'Actual Gross Weight',        exportField: 'gross_weight',                                                   exportPipe: 'measure' },
  { field: 'hs_code',                                       displayName: 'HS Code',                    exportField: 'product.hs_code',                                                exportPipe: '' },
  { field: 'import_no',                                     displayName: 'Pedimento #',                exportField: 'attributes.shipment.import_no',                                  exportPipe: '' },
  { field: 'inalfresco',                                    displayName: 'Inalfresco',                 exportField: 'attributes.inalfresco',                                          exportPipe: 'yesNo' },
  { field: 'inspection',                                    displayName: 'Inspection',                 exportField: 'attributes.extra.inspection',                                    exportPipe: 'yesNo' },
  { field: 'inv',                                           displayName: 'Inv',                        exportField: 'inv',                                                            exportPipe: '' },
  { field: 'invoiced_amt',                                  displayName: 'Invoiced Amt. (Revenue)',    exportField: 'attributes.actual.revenue',                                      exportPipe: 'currency:CAD:symbol-narrow' },
  { field: 'int_cred_avail',                                displayName: 'Int Cred Avail',             exportField: 'int_cred_avail',                                                 exportPipe: 'currency:CAD:symbol-narrow' },
  { field: 'invoiced',                                      displayName: 'Invoiced',                   exportField: 'attributes.extra.invoiced',                                      exportPipe: 'yesNo' },
  { field: 'item_type',                                     displayName: 'Item Type',                  exportField: 'product.item_type.name',                                         exportPipe: '' },
  { field: 'load_ready',                                    displayName: 'Load Ready',                 exportField: 'attributes.extra.load_ready',                                    exportPipe: 'yesNo' },
  { field: 'loading_instructions',                          displayName: 'Loading Instructions',       exportField: 'attributes.extra.loading_instructions',                          exportPipe: 'yesNo' },
  { field: 'note_comments_public',                          displayName: 'Notes/Comments Public',      exportField: 'attributes.extra.comments',                                      exportPipe: '' },
  { field: 'note_warning',                                  displayName: 'Notes',                      exportField: 'note_warning',                                                   exportPipe: 'yesNo', index: 1000 },
  { field: 'origin_country',                                displayName: 'Country Of Origin',          exportField: 'origin_country',                                                 exportPipe: '' },
  { field: 'origin_location',                               displayName: 'Pickup',                     exportField: 'origin_location_name',                                           exportPipe: '' },
  { field: 'original_sent',                                 displayName: 'Original Sent',              exportField: 'attributes.extra.original_sent',                                 exportPipe: 'yesNo' },
  { field: 'packages_count',                                displayName: 'No of packages',             exportField: 'product.packages_count',                                         exportPipe: 'number' },
  { field: 'partial_margin_p',                              displayName: 'Partial Margin (%)',         exportField: 'attributes.actual.partial_margin_p',                             exportPipe: 'percent' },
  { field: 'partial_margin',                                displayName: 'Partial Margin (CAD)',       exportField: 'attributes.actual.partial_margin',                               exportPipe: 'currency:CAD:symbol-narrow' },
  { field: 'payment_terms_buyer',                           displayName: 'Payment Terms (Buyer)',      exportField: 'payment_terms_buyer',                                            exportPipe: '' },
  { field: 'payment_terms_supplier',                        displayName: 'Payment Terms (Supplier)',   exportField: 'payment_terms_supplier',                                         exportPipe: '' },
  { field: 'pickup_date',                                   displayName: 'Load Date',                  exportField: 'pickup_date',                                                    exportPipe: 'epoch' },
  { field: 'estimated_loading_date',                        displayName: 'Est. Loading Date',          exportField: 'estimated_loading_date',                                         exportPipe: 'epoch' },
  { field: 'pickup_location',                               displayName: 'Loading Loc.',               exportField: 'pickup_location.name',                                           exportPipe: '' },
  { field: 'port_of_discharge',                             displayName: 'Port Of Discharge',          exportField: 'port_of_discharge.name',                                         exportPipe: '' },
  { field: 'port_of_loading',                               displayName: 'Port Of Loading',            exportField: 'port_of_loading.name',                                           exportPipe: '' },
  { field: 'prepayment_received',                           displayName: 'Payment Received',           exportField: 'attributes.preprepayment_received',                              exportPipe: 'yesNo' },
  { field: 'prepayment_status',                             displayName: 'Prepayment Status',          exportField: 'prepayment_invoice.status',                                      exportPipe: 'invoiceStatus' },
  { field: 'product_category',                              displayName: 'Product Category',           exportField: 'product.category.name',                                          exportPipe: '' },
  { field: 'product',                                       displayName: 'Product',                    exportField: 'product.name',                                                   exportPipe: '' },
  { field: 'rate',                                          displayName: 'Rate',                       exportField: 'earliestSegment.attributes.amount',                              exportPipe: 'currency:earliestSegment.attributes.currency:symbol-narrow' },
  { field: 'sell_price',                                    displayName: 'Buyer Price',                exportField: 'product.sell_price',                                             exportPipe: 'price' },
  { field: 'selling_trader',                                displayName: 'Selling Trader',             exportField: 'selling_trader.fullname',                                        exportPipe: '' },
  { field: 'shipper',                                       displayName: 'Shipper',                    exportField: 'attributes.shipment.shipper.name',                               exportPipe: '' },
  { field: 'shipment_date',                                 displayName: 'Est. Shipping Period',       exportField: 'attributes.shipment_dates',                                      exportPipe: 'epochRange:true', useUtc: true },
  { field: 'status_a',                                      displayName: 'Status A',                   exportField: 'attributes.extra.customs_broker',                                exportPipe: '' },
  { field: 'status_b',                                      displayName: 'Status B',                   exportField: 'attributes.extra.description_of_goods',                          exportPipe: '' },
  { field: 'status_notes',                                  displayName: 'Status Notes',               exportField: 'statusNotes',                                                    exportPipe: '' },
  { field: 'status',                                        displayName: 'Status',                     exportField: 'status',                                                         exportPipe: '' },
  { field: 'shipment_status',                               displayName: 'Shipment Status',            exportField: 'shipment_status',                                                exportPipe: '' },

  { field: 'submitted_at',                                  displayName: 'Date Submitted',             exportField: 'attributes.submitted_at',                                        exportPipe: 'epoch' },
  { field: 'supplier_currency',                             displayName: 'Supplier Currency',          exportField: 'attributes.estimated.buy_currency',                              exportPipe: '' },
  { field: 'supplier_estimated_cost',                       displayName: 'Supplier Est. Cost',         exportField: 'attributes.estimated.buy_price',                                 exportPipe: 'currency:attributes.estimated.buy_currency:symbol-narrow' },
  { field: 'supplier_incoterm',                             displayName: 'Supplier Incoterm',          exportField: 'product.supplier_incoterm.term',                                 exportPipe: '' },
  { field: 'supplier_incoterm_location',                    displayName: 'Supplier Incoterm Location', exportField: 'product.supplier_incoterm_location.name',                        exportPipe: '' },
  { field: 'supplier_invoice_date',                         displayName: 'Supplier Invoice date',      exportField: 'supplier_invoice.issued',                                        exportPipe: 'epoch' },
  { field: 'supplier_invoice_id',                           displayName: 'Supplier invoice #',         exportField: 'supplier_invoice.vendor_invoice_id',                             exportPipe: '' },
  { field: 'supplier_invoice_paid',                         displayName: 'Act. Supplier Paid Date',    exportField: 'supplier_invoice.attributes.paid',                               exportPipe: 'epoch' },
  { field: 'supplier_invoice_status',                       displayName: 'Supplier Invoice Status',    exportField: 'supplier_invoice.status',                                        exportPipe: 'invoiceStatus' },
  { field: 'supplier_ref',                                  displayName: 'Supplier ref. #',            exportField: 'attributes.supplier_ref',                                        exportPipe: '' },
  { field: 'supplier_released',                             displayName: 'Supplier Released',          exportField: 'attributes.extra.supplier_released',                             exportPipe: 'epoch' },
  { field: 'supplier',                                      displayName: 'Supplier',                   exportField: 'supplier.name',                                                  exportPipe: '' },
  { field: 'total_weight',                                  displayName: 'Total Weight',               exportField: 'total_weight',                                                   exportPipe: 'measure' },
  { field: 'estimated_weight',                              displayName: 'Est. Weight',                exportField: 'attributes.estimated.weight',                                    exportPipe: 'measure' },
  { field: 'size_of_package',                               displayName: 'Weight of One Package',      exportField: 'weight_of_one_package',                                          exportPipe: 'measure' },
  { field: 'truck_inspection',                              displayName: 'Truck inspection',           exportField: 'attributes.extra.truck_inspection',                              exportPipe: 'yesNo' },
  { field: 'truck_refer_id',                                displayName: 'Refer #',                    exportField: 'truck_refer_id',                                                 exportPipe: '' },
  { field: 'type_of_packages',                              displayName: 'Package Type',               exportField: 'type_of_packages',                                               exportPipe: '' },
  { field: 'temperature',                                   displayName: 'Temperature',                exportField: 'product.temperature',                                            exportPipe: 'temperature:attributes.temperature_units' },
  { field: 'us_border',                                     displayName: 'US Border',                  exportField: 'attributes.shipment.border_cross',                               exportPipe: '' },
  { field: 'vessel_carrier',                                displayName: 'Shipping Line',              exportField: 'vessel_carrier.name',                                            exportPipe: '' },
  { field: 'vessel_cutoff_date',                            displayName: 'Vessel Cutoff Date',         exportField: 'earliestVesselSegment.attributes.cutoff_datetime',               exportPipe: 'epoch' },
  { field: 'vessel',                                        displayName: 'Vessel',                     exportField: 'earliestVesselSegment.attributes.vessel',                        exportPipe: '' },
  { field: 'voyage_no',                                     displayName: 'Voyage #',                   exportField: 'attributes.shipment.voyage_no',                                  exportPipe: '' },
  { field: 'freight_rates_amount',                          displayName: 'Freight Rate',               exportField: 'freight_rates_amount',                                           exportPipe: 'price' },
  { field: 'foreign_invoice_number_mx',                     displayName: 'Mex Inv #',                  exportField: 'foreign_invoice_number_mx',                                      exportPipe: '' },
  { field: 'wrappings',                                     displayName: 'Wrapping',                   exportField: 'wrappings',                                                      exportPipe: '' },
  { field: 'pickupTime',                                    displayName: 'Pickup Time',                exportField: 'pickupTime',                                                     exportPipe: '' },
  { field: 'deliveryTime',                                  displayName: 'Delivery Time',              exportField: 'deliveryTime',                                                   exportPipe: '' },
  { field: 'prepayment_percent',                            displayName: 'Prepayment %',               exportField: 'prepayment_percent',                                             exportPipe: '' },

  { field: 'closingDate',                                   displayName: 'Closing Date',               exportField: 'closingDate',                                                    exportPipe: 'epoch' },
  { field: 'seq',                                           displayName: 'Seq.',                       exportField: 'attributes.clone_sequence',                                      exportPipe: '' },
  { field: 'fx_bid_rate',                                   displayName: 'FX Bid Rates',               exportField: 'fx_bid_rate',                                                    exportPipe: '' },
  { field: 'fx_ask_rate',                                   displayName: 'FX Ask Rates',               exportField: 'fx_ask_rate',                                                    exportPipe: '' },
  { field: 'deringer_te_sent',                              displayName: 'T&E Sent',                   exportField: 'deringer.te_file.te_sent',                                       exportPipe: 'epoch' },
  { field: 'deringer_data_received',                        displayName: 'Data Received',              exportField: 'integration.deringer.data_received',                             exportPipe: 'epoch' },
  { field: 'deringer_pdf_received',                         displayName: 'PDF Received',               exportField: 'deringer.pdf_received',                                          exportPipe: 'epoch' },
  { field: 'deringer_pdf_bonds',                            displayName: 'No of Bonds',                exportField: 'deringer.bonds.total',                                           exportPipe: '' },
  { field: 'deringer_approved_at',                          displayName: 'Approved',                   exportField: 'deringer.approved_at',                                           exportPipe: 'epoch' },
  { field: 'deringer_approved_by',                          displayName: 'Approved by',                exportField: 'deringer.approved_by.full_name',                                 exportPipe: '' },
  { field: 'deringer_notes',                                displayName: 'Bond Notes',                 exportField: 'deringer_notes',                                                 exportPipe: 'yesNo', index: 1000 },
  { field: 'deringer_note_warning',                         displayName: 'Notes',                      exportField: 'deringer_note_warning',                                          exportPipe: 'yesNo', index: 1000 },

  { field: 'aes_number',                                    displayName: 'AES Number',                 exportField: 'attributes.shipment.aes',                                        exportPipe: '' },
  { field: 'aes_request_sent_at',                           displayName: 'Request Sent',               exportField: 'deal.export_reports[0]?.requests?.at(-1)?.created_at',           exportPipe: 'epoch' },
  { field: 'aes_responses_count',                           displayName: 'Responses Received',         exportField: 'aes_responses_count',                                            exportPipe: '' },

  { field: 'montship_pickup_date',                          displayName: 'Load Date',                  exportField: 'booking.montship_pickup_date',                                   exportPipe: 'epoch' },
  { field: 'montship_dropoff_date',                         displayName: 'Dropoff Date',               exportField: 'booking.montship_dropoff_date',                                  exportPipe: 'epoch' },
  { field: 'montship_etd',                                  displayName: 'ETD',                        exportField: 'booking.montship_etd',                                           exportPipe: 'epoch' },
  { field: 'montship_eta',                                  displayName: 'ETA',                        exportField: 'booking.montship_eta',                                           exportPipe: 'epoch' },
  { field: 'montship_sent_at',                              displayName: 'Sent',                       exportField: 'booking.montship_sent_at',                                       exportPipe: 'epoch' },
  { field: 'montship_confirmed_at',                         displayName: 'Confirmed',                  exportField: 'booking.montship_confirmed_at',                                  exportPipe: 'epoch' },
  { field: 'montship_status_at',                            displayName: 'Approved/Rejected',          exportField: 'booking.montship_status_at',                                     exportPipe: 'epoch' },
  { field: 'montship_count',                                displayName: 'Count',                      exportField: 'booking.montship_count',                                         exportPipe: '' },
  { field: 'montship_exact_loading',                        displayName: 'Exact Loading',              exportField: 'booking.montship_exact_loading.name',                            exportPipe: '' },

  { field: 'macropoint_tracking_request_id',                displayName: 'Macropoint Tracking #',      exportField: 'macropoint_order.tracking_request_id',                           exportPipe: '' },
  { field: 'macropoint_order_status_name',                  displayName: 'Macropoint Order Status',    exportField: 'macropoint_order.macropoint_order_status_name',                  exportPipe: '' },
  { field: 'macropoint_order_latest_location_full_address', displayName: 'Macropoint Current Address', exportField: 'macropoint_order.macropoint_order_latest_location_full_address', exportPipe: '' },
  { field: 'macropoint_request_count',                      displayName: 'No. of Macropoint Requests', exportField: 'macropoint_order.macropoint_request_count',                      exportPipe: '' },
  { field: 'last_macropoint_request_sent_at',               displayName: 'Last Request Sent',          exportField: 'macropoint_order.last_macropoint_request_sent_at',               exportPipe: 'epoch' },
]

export const DEAL_COLUMNS_BY_ID = keyBy(DEAL_COLUMNS, 'field')
export const DEAL_COLUMNS_INTERNAL = DEAL_COLUMNS.filter(col => col.internal)
export const DEAL_COLUMNS_BOOLEAN = DEAL_COLUMNS.filter(col => col.exportPipe === 'yesNo').map(col => col.field)

const DEAL_COLUMN_NAMES = mapValues(keyBy(DEAL_COLUMNS, 'field'), 'displayName')
const DEAL_COLUMN_OVERRIDES = {
  [TableKey.TradingShippingLogPage]: {
    buyer: 'Client',
    origin_location: 'Origin',
    origin_country: 'Origin Country',
    dest_location: 'Destination',
    collection_date: 'Due Date',
    total_weight: 'Net Weight',
    partial_margin: 'Margin Amt',
    partial_margin_p: 'Margin %',
    packages_count: 'Number Of Boxes',
  },
  [TableKey.LogisticsShippingLogPage]: {
    dest_location: 'Destination',
    origin_country: 'Origin Country',
    sell_price: 'Unit Sell Price',
    actual_weight_buy: 'Actual Weight',
  },
  [TableKey.DealClonesTab]: {
    sell_price: 'Sell Price',
    buy_price: 'Buy Price',
    delivery_date: 'Delivery Period',
    shipment_date: 'Shipment Period',
    partial_margin: 'Margin',
  },
  [TableKey.ClientShippingLogList]: {
    collection_date: 'Actual Payment Date',
    note_comments_public: 'Additional Info',
    buyer_invoice_date: 'Invoice Date',
    buyer_invoice_id: 'Invoice #',
    import_no: 'Import Permit',
    sell_price: 'Offer Price',
    origin_country: 'Origin',
    delivery_date: 'Delivery Period',
    est_delivery_date: 'Delivery Date',
    status_b: 'Description Of Goods',
    total_weight: 'Net Weight',
    buyer_ref: 'Selling Ref.#',
    dest_location: 'Destination',
    pickup_date: 'Pickup Date',
    etd: 'On Board Date',
    pickup_location: 'Pickup',
    prepayment_received: 'Prepayment amount',
    truck_refer_id: 'Truck',
    voyage_no: 'Voyage #',
    payment_terms_buyer: 'Payment Terms',
    supplier_invoice_paid: 'Supplier Paid Date',
    dest_country: 'Delivery',
    original_sent: 'Docs Status',
  },
  [TableKey.ClientSupplierShippingLogList]: {
    vessel_cutoff_date: 'Cut Off',
    pickup_date: 'Loading Date',
    exact_loading_address: 'Loading Address',
    courier_track_id: 'Courier Tracking #',
    dest_country: 'Destination',
    supplier_ref: 'Supplier Ref. Number',
    product: 'Product Name',
    actual_weight_buy: 'Actual Weight',
  },
  [TableKey.ClientServiceProviderShippingLogList]: {
    product: 'Product Name',
    origin_country: 'Origin',
  },
  [TableKey.IntegrationsAESPage]: {
    etd: 'Onboard Date',
  }
}



export function columnDefsById(tableKey: TableKey) {
  const displayNames = DEAL_COLUMN_OVERRIDES[tableKey] || {}
  const columnDefs = DEAL_COLUMNS.map(c =>
    displayNames[c.field]
      ? { ...c, displayName: displayNames[c.field] } as ColumnDef
      : c)
  return keyBy(columnDefs, c => c.field)
}

export function columnNames(tableKey: TableKey) {
  return {
    ...DEAL_COLUMN_NAMES,
    ...DEAL_COLUMN_OVERRIDES[tableKey],
  }
}

export const AVAILABLE_COLUMNS: Partial<Record<TableKey, string[]>> = {
  [TableKey.TradingDealsListPage]: [
    'select',
    'deal_date',
    'deal_id',
    'product',
    'buy_price',
    'sell_price',
    'status',
    'buyer',
    'dest_location',
    'supplier',
    'origin_location',
    'origin_country',
    'pickup_date', // hidden
    'dropoff_date', // hidden
    'buying_trader',
    'selling_trader',
    'supplier_ref', // hidden
    'buyer_ref', // hidden
    'delivery_date', // hidden
    'shipment_date', // hidden
    'note_warning',
    'macropoint_tracking_request_id', // hidden
    'tradersMenu',
  ],
  [TableKey.TradingShippingLogPage]: [
    'select',
    'deal_id',
    'product',
    'supplier',
    'buyer',
    'origin_location',
    'origin_country', // hidden
    'dest_location',
    'dest_country', // hidden
    'est_delivery_date', // hidden
    'est_pickup_date', // hidden
    'pickup_location', // hidden
    'est_delivery', // hidden
    'vessel_carrier', // hidden
    'container_number', // hidden
    'collection_date', // hidden
    'carrier',
    'submitted_at',
    'confirmed_at',
    'buy_price',
    'sell_price',
    'courier_track_id', // hidden
    'delivery_date', // hidden
    'shipment_date', // hidden
    'total_weight',
    'actual_weight_buy',
    'actual_weight_sell',
    'partial_margin_p',
    'partial_margin',
    'actual_margin_p', // hidden
    'actual_margin', // hidden
    'actual_cost', // hidden
    'actual_revenue', // hidden
    'gross_weight', // hidden
    'packages_count', // hidden
    'pickup_date', // hidden
    'dropoff_date', // hidden
    'etd', // hidden
    'eta', // hidden
    'vessel', // hidden
    'est_id', // hidden
    'booking_id', // hidden
    'payment_terms_buyer', // hidden
    'epd', // hidden
    'erd', // hidden
    'prepayment_status', // hidden
    'supplier_invoice_status', // hidden
    'supplier_invoice_paid', // hidden
    'buyer_invoice_id', // hidden
    'buyer_invoice_date', // hidden
    'buyer_invoice_paid', // hidden
    'product_category', // hidden
    'supplier_invoice_id', // hidden
    'supplier_ref', // hidden
    'buyer_ref', // hidden
    'note_warning', // hidden
    'tradersShippingLogMenu',
    'int_cred_avail',
    'buyer_incoterm', // hidden
    'buyer_incoterm_location', // hidden
    'supplier_incoterm', // hidden
    'supplier_incoterm_location', // hidden
  ],
  [TableKey.ManagersDealsPage]: [
    'select',
    'deal_date',
    'deal_id',
    'product',
    'buy_price',
    'sell_price',
    'status',
    'buyer',
    'dest_location',
    'supplier',
    'origin_location',
    'origin_country',
    'pickup_date', // hidden
    'dropoff_date', // hidden
    'buying_trader',
    'selling_trader',
    'supplier_ref', // hidden
    'buyer_ref', // hidden
    'delivery_date', // hidden
    'shipment_date', // hidden
    'partial_margin',
    'partial_margin_p',
    'estimated_margin',
    'note_warning',
    'tradersMenu',
  ],
  [TableKey.LogisticsShippingLogPage]: [
    'select',
    'buyer_invoice_paid', // hidden
    'buyer_invoice_due', // hidden
    'actual_cost', // hidden
    'actual_revenue', // hidden
    'art_work', // hidden
    'invoiced_amt', // hidden
    'buyer_ref',  // hidden
    'delivery_date', // hidden
    'supplier_estimated_cost', // hidden
    'supplier_currency', // hidden
    'color', // hidden
    'note_comments_public', // hidden
    'status_a', // hidden
    'delivered', // hidden
    'status_b', // hidden
    'documents', // hidden
    'documents_count', // hidden
    'import_no', // hidden
    'docs_country', // hidden
    'e_docs_to_customs_broker', // hidden
    'inspection', // hidden
    'invoiced', // hidden
    'load_ready', // hidden
    'loading_instructions', // hidden
    'original_sent', // hidden
    'supplier_released', // hidden
    'truck_inspection', // hidden
    'inalfresco', // hidden
    'date_invoiced', // hidden
    'item_type', // hidden
    'prepayment_received', // hidden
    'shipment_date', // hidden
    'us_border', // hidden
    'epd', // hidden
    'erd', // hidden
    'voyage_no', // hidden
    'supplier_ref', // hidden
    'bol_instructions', // hidden
    'booking_id',
    'brand', // hidden
    'credit_balance', // hidden
    'buyer',
    'buyer_invoice_date', // hidden
    'buyer_invoice_id', // hidden
    'payment_terms_buyer', // hidden
    'carrier',
    'collection_date', // hidden
    'container_number',
    'courier_track_id', // hidden
    'deal_date',
    'deal_id',
    'dest_location', // hidden
    'dropoff_date',
    'dropoff_location',
    'rate', // hidden
    'exact_loading_address', // hidden
    'vessel_cutoff_date', // hidden
    'vessel', // hidden
    'port_of_loading', // hidden
    'est_id', // hidden
    'eta',
    'etd',
    'forwarder', // hidden
    'hs_code', // hidden
    'exact_dropoff_address', // hidden
    'port_of_discharge', // hidden
    'coordinator', // hidden
    'origin_country',
    'origin_location',
    'partial_margin',
    'pickup_date',
    'pickup_location',
    'prepayment_status', // hidden
    'product_category', // hidden
    'product',
    'packages_count', // hidden
    'sell_price', // hidden
    'status', // hidden
    'status_notes', // hidden
    'supplier_invoice_status', // hidden
    'supplier_invoice_paid', // hidden
    'supplier_invoice_date', // hidden
    'supplier_invoice_id', // hidden
    'supplier',
    'payment_terms_supplier', // hidden
    'total_weight', // hidden
    'buying_trader', // hidden
    'selling_trader', // hidden
    'truck_refer_id',
    'partial_margin_p',
    'consignee', // hidden
    'shipper', // hidden
    'inv',
    'deringer_pdf_bonds',
    'deringer_te_sent', // hidden
    'note_warning',
    'logisticsMenu',
    'freight_rates_amount', // hidden
    'foreign_invoice_number_mx', // hidden
    'aes_number',
    'int_cred_avail',
    'buyer_incoterm', // hidden
    'buyer_incoterm_location', // hidden
    'supplier_incoterm', // hidden
    'supplier_incoterm_location', // hidden
    'estimated_weight',
    'wrappings',
    'shipment_status',
    'actual_weight_buy', // hidden
    'macropoint_tracking_request_id', // hidden
    'macropoint_order_status_name', // hidden
    'macropoint_order_latest_location_full_address', // hidden
    'type_of_packages', // hidden
    'temperature', // hidden
  ],
  [TableKey.IntegrationsDeringerPage]: [
    'select',
    'deal_id',
    'status',
    'deringer_te_sent',
    'deringer_data_received',
    'deringer_pdf_received',
    'deringer_pdf_bonds',
    'deringer_approved_at',
    'deringer_approved_by',
    'product', // hidden
    'item_type', // hidden
    'supplier', // hidden
    'buyer', // hidden
    'dropoff_date', // hidden
    'dropoff_location', // hidden
    'exact_loading_address', // hidden
    'exact_dropoff_address', // hidden
    'pickup_date', // hidden
    'delivery_date', // hidden
    'note_comments_public', // hidden
    'status_notes', // hidden
    'deringer_note_warning', // hidden
    'color', // hidden
    'status_a', // hidden
    'status_b', // hidden
    'selling_trader', // hidden
    'carrier', // hidden
    'pickup_location', // hidden
    'deringer_notes',
    'deringerMenu',
  ],
  [TableKey.IntegrationsMontshipPage]: [
    'deal_id',
    'color',
    'supplier', // hidden
    'buyer', // hidden
    'montship_pickup_date',
    'montship_dropoff_date',
    'montship_etd',
    'montship_eta',
    'montship_sent_at',
    'montship_confirmed_at',
    'montship_status_at',
    'montship_count',
    'montship_exact_loading',
    'montshipMenu',
  ],
  [TableKey.IntegrationsAESPage]: [
    'deal_id',
    'aes_number',
    'supplier', // hidden
    'buyer', // hidden
    'aes_request_sent_at',
    'aes_responses_count',
    'etd',
    'aesMenu',
  ],
  [TableKey.IntegrationsMacropointPage]: [
    'select',
    'deal_id',
    'macropoint_tracking_request_id',
    'macropoint_order_status_name',
    'macropoint_order_latest_location_full_address',
    'macropoint_request_count',
    'last_macropoint_request_sent_at',
    'macropointMenu',
  ],
}

export const DEFAULT_COLUMNS = {
  [TableKey.TradingDealsListPage]: [
    'select',
    'deal_date',
    'deal_id',
    'product',
    'buy_price',
    'sell_price',
    'status',
    'buyer',
    'dest_location',
    'supplier',
    'origin_location',
    'origin_country',
    'buying_trader',
    'selling_trader',
    'note_warning',
    'tradersMenu',
  ],
  [TableKey.TradingShippingLogPage]: [
    'select',
    'deal_id',
    'actual_margin',
    'actual_margin_p',
    'actual_weight_buy',
    'actual_weight_sell',
    'confirmed_at',
    'dest_country',
    'est_delivery_date',
    'est_pickup_date',
    'gross_weight',
    'submitted_at',
    'vessel_carrier',
    'int_cred_avail',
    'tradersShippingLogMenu',
  ],
  [TableKey.ManagersDealsPage]: [
    'select',
    'deal_date',
    'deal_id',
    'product',
    'buy_price',
    'sell_price',
    'status',
    'buyer',
    'dest_location',
    'supplier',
    'origin_location',
    'origin_country',
    'buying_trader',
    'selling_trader',
    'partial_margin',
    'partial_margin_p',
    'estimated_margin',
    'note_warning',
    'tradersMenu',
  ],
  [TableKey.LogisticsShippingLogPage]: [
    'select',
    'deal_id',
    'product',
    'supplier',
    'buyer',
    'origin_location',
    'origin_country',
    'pickup_location',
    'dropoff_location',
    'truck_refer_id',
    'pickup_date',
    'dropoff_date',
    'etd',
    'eta',
    'carrier',
    'booking_id',
    'container_number',
    'partial_margin',
    'note_warning',
    'aes_number',
    'int_cred_avail',
    'logisticsMenu',
  ],
  [TableKey.IntegrationsDeringerPage]: [
    'select',
    'deal_id',
    'status',
    'deringer_te_sent',
    'deringer_data_received',
    'deringer_pdf_received',
    'deringer_pdf_bonds',
    'deringer_approved_at',
    'deringer_approved_by',
    'deringer_notes',
    'deringerMenu',
  ],
  [TableKey.IntegrationsMontshipPage]: [
    'deal_id',
    'color',
    'montship_pickup_date',
    'montship_dropoff_date',
    'montship_etd',
    'montship_eta',
    'montship_sent_at',
    'montship_confirmed_at',
    'montship_status_at',
    'montship_count',
    'montship_exact_loading',
    'montshipMenu',
  ],
  [TableKey.IntegrationsAESPage]: [
    'deal_id',
    'aes_number',
    'aes_request_sent_at',
    'aes_responses_count',
    'etd',
    'aesMenu',
  ],
  [TableKey.IntegrationsMacropointPage]: [
    'deal_id',
    'macropoint_tracking_request_id',
    'macropoint_order_status_name',
    'macropoint_order_latest_location_full_address',
    'macropoint_request_count',
    'last_macropoint_request_sent_at',
    'macropointMenu',
  ],
  [TableKey.DealInventory]: [
    'select',
    'deal_id',
    'container_number',
    'shipment_date',
    'supplier',
    'buy_price',
    'origin_location',
    'origin_country',
    'buying_trader',
    'selling_trader',
    'product',
  ],
}

/* optimise cells rendering. reduce pipe calls */

// export interface EpochCell {
//   L: string
//   LT: string
// }

// interface DealRow {
//   buyer_invoice_date: EpochCell
//   buyer_invoice_paid: EpochCell
//   confirmed_at: EpochCell
//   date_invoiced: EpochCell
//   deal_date: EpochCell
//   delivered: EpochCell
//   dropoff_date: EpochCell
//   epd: EpochCell
//   erd: EpochCell
//   est_delivery_date: EpochCell
//   est_pickup_date: EpochCell
//   eta: EpochCell
//   etd: EpochCell
//   pickup_date: EpochCell
//   submitted_at: EpochCell
//   supplier_invoice_date: EpochCell
//   supplier_invoice_paid: EpochCell
//   supplier_released: EpochCell
//   vessel_cutoff_date: EpochCell
// }

const EMPTY_EPOCH_CELL = { L: '', T: '' }
function buildEpochCell(key, epoch) {
  if (!epoch) return EMPTY_EPOCH_CELL
  const d = dayjsUnix(epoch, DEAL_COLUMNS_BY_ID[key].useUtc)
  return {
    L: d.format('L'),
    LT: d.format('LT'),
  }
}

export function buildDealRow(deal: any) {
  return {
    ...deal,
    buyer_invoice_date: buildEpochCell('buyer_invoice_date', deal.buyer_invoice?.issued),
    buyer_invoice_paid: buildEpochCell('buyer_invoice_paid', deal.buyer_invoice?.attributes.paid),
    confirmed_at: buildEpochCell('confirmed_at', deal.attributes.confirmed_at),
    date_invoiced: buildEpochCell('date_invoiced', deal.attributes.invoiced_at),
    deal_date: buildEpochCell('deal_date', deal.attributes.deal_date || deal.created),
    delivered: buildEpochCell('delivered', deal.attributes.extra?.delivered),
    pickup_date: buildEpochCell('pickup_date', deal.earliestTruckSegment?.attributes.actual_pickup_date),
    dropoff_date: buildEpochCell('dropoff_date', deal.latestTruckSegment?.attributes.actual_delivery_date),
    epd: buildEpochCell('epd', deal.attributes.shipment?.epd_date),
    erd: buildEpochCell('erd', deal.attributes.shipment?.erd_date),
    // est_delivery_date: buildEpochCell('est_delivery_date', ),
    // est_pickup_date: buildEpochCell('est_pickup_date', ),
    eta: buildEpochCell('eta', deal.latestVesselSegment?.attributes.actual_delivery_date),
    etd: buildEpochCell('etd', deal.earliestVesselSegment?.attributes.actual_pickup_date),
    submitted_at: buildEpochCell('submitted_at', deal.submitted || deal.attributes.submitted_at),
    supplier_invoice_date: buildEpochCell('supplier_invoice_date', deal.supplier_invoice?.issued),
    supplier_invoice_paid: buildEpochCell('supplier_invoice_paid', deal.supplier_invoice?.attributes.paid),
    supplier_released: buildEpochCell('supplier_released', deal.attributes.extra?.supplier_released),
    vessel_cutoff_date: buildEpochCell('vessel_cutoff_date', deal.earliestVesselSegment?.attributes.cutoff_datetime),
  }
}
