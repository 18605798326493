import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { UsersService } from 'src/services/data/users.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadUsers, loadUsersFailure, loadUsersSuccess } from './user.actions'

const { tradecafeAccount } = environment

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private users: UsersService,
    private toaster: ToasterService,
  ) {}

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(loadUsers),
    switchMap(action =>
      from(this.users.getUsers(action.account || tradecafeAccount, typeof action.archived === 'undefined'? 1: action.archived)).pipe(
        map(r => loadUsersSuccess({
          tableKey: action.tableKey,
          users: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch users', error)
          this.toaster.error('Unable to fetch users', error)
          return of(loadUsersFailure({ error }))
        })))))
}
