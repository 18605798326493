import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Measure } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as MeasureActions from './measure.actions'

export const measuresFeatureKey = 'measures'
export interface MeasuresState extends EntityState<DeepReadonly<Measure>> {
}

export const selectId = (measure: Measure) => measure.measure_id
export const adapter = createEntityAdapter<DeepReadonly<Measure>>({ selectId })

const initialState: MeasuresState = adapter.getInitialState({
})

export const measuresReducer = createReducer(
  initialState,
  on(MeasureActions.loadMeasuresSuccess,
    (state, action) =>
      adapter.upsertMany(action.measures, state)),
)

