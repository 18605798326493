import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { range } from 'lodash-es'
import { map, switchMap } from 'rxjs/operators'
import { selectAllMeasures } from 'src/app/store/measures'
import { selectAllPackageTypes } from 'src/app/store/package-types'
import { selectProductsById } from 'src/app/store/products'
import { selectAllWeightTypes } from 'src/app/store/weight-types'
import { selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealProductFormGroup, DealProductsFormGroup } from '../deal-form.schema'


@Component({
  selector: 'tc-deal-specs',
  templateUrl: './deal-specs.component.html',
  styleUrls: ['./deal-specs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealSpecsComponent {
  constructor(private store: Store) { }

  // "weight" options
  readonly weights = range(5000, 26501, 500)

  @Input() productsForm: DealProductsFormGroup

  packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty())
  weightTypes$ = this.store.pipe(select(selectAllWeightTypes), waitNotEmpty())
  wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty())
  measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())

  productName$ = (productForm: DealProductFormGroup) =>
    replayForm(productForm.controls.productId).pipe(switchMap(productId =>
      this.store.select(selectProductsById, productId).pipe(map(p => p?.name))))
}
