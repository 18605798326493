import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AccountObject, TableKey, User } from '@tradecafe/types/core'
import { isFileVisibleTo, isProforma, isPurchaseOrder, isSalesConfirmation } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { FileRow } from 'src/api/file'
import { approveDeringerPdf, rejectDeringerPdf } from 'src/app/store/deal-view.actions'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { canBeVisible } from 'src/pages/admin/trading/deals/deal-documents/documents-acl.util'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { SegmentsService } from 'src/services/data/segments.service'
import { StatefulDataSource } from 'src/services/table-utils/data-sources/stateful-data-source'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { ModalProxyService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CarrierPickerService } from '../carrier-picker/carrier-picker.service'
import { InlineEditorComponent } from '../inline-editor/inline-editor.component'
import { NotesOverlayService } from '../notes/notes-overlay/notes-overlay.service'
import { AVAILABLE_COLUMNS, columnNames, DEFAULT_COLUMNS } from './files-list.columns'
import { DeringerRecipientsDialogService } from '../deringer-recipients-dialog/deringer-recipients-dialog.service'

@Component({
  selector: 'tc-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesListComponent extends OnDestroyMixin implements OnInit {

  readonly TableKey = TableKey

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private store: Store,
    private CarrierPicker: CarrierPickerService,
    private Files: FilesService,
    private Segments: SegmentsService,
    private DealDocuments: DealDocumentsService,
    private NotesOverlay: NotesOverlayService,
    private modalHelper: ModalProxyService,
    private DeringerRecipientsDialog: DeringerRecipientsDialogService
  ) { super() }


  @HostBinding('class')
  get hostClasses() {
    return `${this.class} tc-files-list tc-data-${this.dataSource.state$.value}`
  }

  @Input()
  class = ''


  @Input()
  tableIdentity?: TableKey

  @Input()
  dataSource: StatefulDataSource<FileRow>

  @Input()
  readonly = false

  private displayColumns$ = new ReplaySubject<string[]>(1)
  private _displayColumns: string[]
  get displayColumns() { return this._displayColumns }

  @Input()
  set displayColumns(value: string[]) {
    this._displayColumns = value
    this.displayColumns$.next(value)
  }

  @Input()
  availableColumns: string[]

  @Input()
  columnNames: Dictionary<string>

  @Input()
  selectAll = true

  @Input()
  back: string

  @Input()
  navigateTo: string

  @Output()
  rowClick = new EventEmitter<{file: FileRow, column?: string, tabName?: string}>()

  @Output()
  selectionChange = new EventEmitter<FileRow[]>()

  @Output()
  editColumns = new EventEmitter<void>()

  @Output()
  addNote = new EventEmitter<FileRow>()

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator) }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.setSort(sort) }

  @ViewChild(InlineEditorComponent)
  inlineEditor: InlineEditorComponent

  selection = new TableSelection<FileRow>('file_id')
  selectedRows$ = this.selection.selectedIds$.pipe(map(ids =>
    (this.dataSource.sort ? this.dataSource.sortData(this.dataSource.data, this.dataSource.sort) : this.dataSource.data || [])
    .filter(row => row && ids.includes(row.file_id))))

  isFileVisibleTo = isFileVisibleTo

  getRowId = (i: number, file: FileRow) =>
    // undefined file = fake. loading screen.
    file?.file_id || i
  getDealUrl = (dealId: string) =>
    this.navigateTo + '/' + dealId

  ngOnInit(): void {
    if (!this.rowClick.length) {
      this.rowClick.pipe(untilComponentDestroyed(this))
      .subscribe(({file}) => this.selection.toggleRow(file))
    }

    if (this.tableIdentity) {
      if (!this.displayColumns) this.displayColumns = DEFAULT_COLUMNS[this.tableIdentity]
      if (!this.availableColumns) this.availableColumns = AVAILABLE_COLUMNS[this.tableIdentity]
      if (!this.columnNames) this.columnNames = columnNames(this.tableIdentity)
    }

    this.selectedRows$.pipe(untilComponentDestroyed(this)).subscribe(selectedRows => {
      this.selectionChange.emit(selectedRows)
    })
  }

  openDealForm(file: FileRow) {
    const back = this.back ? `?back=${this.back}` : ''
    this.router.navigateByUrl(this.getDealUrl(file.deal_id) + back)
  }

  async toggleVisibility(file: FileRow, party) {
    try {
      await this.DealDocuments.toggleVisibilityImmutable(file, party)
      this.dataSource.refresh()
      this.toaster.success('Document visibility changed')
    } catch (err) {
      console.error('Unable to change Document visibility', err)
      this.toaster.error('Unable to change Document visibility', err)
    }
  }

  /**
   * Check if file could be visible to a party
   *
   * @param {*} file
   * @param {*} party
   * @returns
   */
  canDocBeVisible(file: FileRow, party: AccountObject) {
    return canBeVisible(file?.attributes?.document_type, party.type)
  }

  /**
   * Check if user can toggle file visibility at the vendor portal
   *
   * Traders can only change the visibility (check the VENDOR PROTAL checkbox)
   * for documents that they create. If a logistics person created a document,
   * the Trader on the deal CANNOT change the visibility set by logistics.
   *
   * @param {any} file
   * @returns
   */
  canToggleVisibility(file: FileRow) {
    if (this.readonly) return false
    const {user_id, role} = this.AuthApi.currentUser
    if (role === 'trader') return file.user_id === user_id
    return true
  }

  // tslint:disable-next-line: cyclomatic-complexity
  canDelete(file: FileRow) {
    if (this.readonly) return false
    // manager can delete everything
    if (this.AuthApi.currentUser.role === 'manager' || this.AuthApi.currentUser.role === 'superuser') return true
    // users can't delete buyer confirmation documents if the deal was confirmed by the buyer
    if (isSalesConfirmation(file, true) || isSalesConfirmation(file, false) || isProforma(file)) {
      return !file.view.buyer_confirmed
    }
    // users can't delete supplier confirmation documents if the deal was confirmed by the supplier
    if (isPurchaseOrder(file, true) || isPurchaseOrder(file, false)) {
      return !file.view.seller_confirmed
    }
    // users can delete all other documents
    return true
  }

  approveDeringer(file: FileRow) {
    this.store.dispatch(approveDeringerPdf({ deal_id: file.deal_id, file_id: file.file_id }))
  }

  rejectDeringer(file: FileRow) {
    this.store.dispatch(rejectDeringerPdf({ deal_id: file.deal_id, file_id: file.file_id }))
  }

  async sendToDeringer(file: FileRow) {
    const modalReturn = await this.DeringerRecipientsDialog.showDeringerRecipients(file.deal_id)
    const carrierRecipients: User[] = modalReturn?.carrierRecipients || []
    const notificationRecipientsIds: string[] = modalReturn?.notificationRecipients || []
    if (carrierRecipients.length > 0 || notificationRecipientsIds.length > 0) {
      const segments = await this.Segments.getByDealIds([file.deal_id])
      await this.CarrierPicker.sendToDeringer(segments, file, carrierRecipients, notificationRecipientsIds, file.deal_id)
      this.dataSource.refresh()
    }
  }

  showDeringerNotes(file: FileRow) {
    this.NotesOverlay.showDeringerNotes(file.deal_id, [file]).subscribe(() => {
      this.dataSource.refresh()
    })
  }

  async showDeleteItem(file: FileRow) {
    await this.modalHelper.showConfirm({
      title: 'Delete this document?',
      description: 'Are you sure you want to delete this document?',
    })
    try {
      await this.DealDocuments.removeDocuments([file])
      this.dataSource.refresh()
      this.toaster.success('Document deleted successfully.')
    } catch (err) {
      console.error('Unable to delete document.', err)
      this.toaster.error('Unable to delete document.', err)
    }
  }

  async editName(file: FileRow, el: HTMLElement) {
    if (!file || this.readonly) return
    const fileName = await this.inlineEditor.editText(() => file.viewEx.fileName, el)

    const name = file.viewEx.fileExt ? `${fileName}.${file.viewEx.fileExt}` : fileName
    try {
      const file_id = file.attributes?.pdf_version_file_id
      await Promise.all([
        this.Files.patchFileImmutable(file, {name}),
        // rename pdf, if there is one
        file_id && this.Files.patchFileImmutable({ file_id }, {name: `${fileName}.pdf`}),
      ])
      this.dataSource.refresh()
      this.toaster.success('File renamed successfully')
    } catch (err) {
      console.error(`Unable to rename file "${file.name}" (${file.file_id})`, err)
      this.toaster.error('Unable to rename file', err)
    }
  }

  download(event: MouseEvent, file: FileRow) {
    if (event.altKey) {
      this.Files.downloadAsPdf(file)
    } else {
      this.Files.download(file)
    }
  }
}
