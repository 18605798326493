import { CurrencyPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Cost, Country, CreditNote, CreditPool, DEAL_CONFIRMED, DEAL_DRAFT, DEAL_SUBMITTED, Deal, DealPartyE, DealStatusE, DealView, DealViewRaw, DealViewRawDeal, DealViewRawInvoices, ForexData, ForexRange, LocationObject, Measure, Note, User, VendorCredit } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, printFormula } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { pickBy, range, round, sortBy } from 'lodash-es'
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { GenericOption, loadAccounts, selectAccountEntities, selectBuyersOptions, selectSuppliersOptions } from 'src/app/store/accounts'
import { loadConsignees } from 'src/app/store/consignees'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { loadCurrencies, selectAllCurrencies } from 'src/app/store/currencies'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadMeasures, selectAllMeasures, selectMeasureEntities } from 'src/app/store/measures'
import { loadPackageTypes, selectAllPackageTypes } from 'src/app/store/package-types'
import { loadPaymentReferences } from 'src/app/store/payment-references'
import { loadPricingTerms, selectAllPricingTerms, selectPricingTermEntities } from 'src/app/store/pricing-terms'
import { loadProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes } from 'src/app/store/product-types'
import { loadProducts, selectProductEntities } from 'src/app/store/products'
import { loadUsers, selectCoordinatorsOptions, selectTradersOptions, selectUserEntities } from 'src/app/store/users'
import { loadWeightTypes, selectAllWeightTypes } from 'src/app/store/weight-types'
import { loadWrappingTypes, selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { NoteFormService } from 'src/components/notes/note-form/note-form.service'
import { environment } from 'src/environments/environment'
import { MeasurePipe } from 'src/filters/measure.pipe'
import { establishmentAddressOptions, invoiceAddressOptions } from 'src/pages/admin/logistics/shipping-details-page/deal-products-list/deal-product-form/deal-product-form.component'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { getPartyUsers } from 'src/services/data/accounts.service'
import { CreditPoolService } from 'src/services/data/credit-pool.service'
import { DealFormCalculatorService } from 'src/services/data/deal-form-calculator.service'
import { canEditDealFormField } from 'src/services/data/deal-view-permissions.service'
import { SPECIAL_INSTRUCTIONS } from 'src/services/data/notes.service'
import { selectOptions, waitNotEmpty } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealDocumentsService } from '../../../deals/deal-documents/deal-documents.service'
import { ChangePartyFormService } from '../change-party-form/change-party-form.service'
import { DealAutocopyFormService } from '../deal-autocopy-form/deal-autocopy-form.service'
import { isLockedSel } from '../deal-form-page.decorator'
import { CostsFormGroup, DealDetailsFormGroup, DealDetailsFormValue, DealFormGroup, DealProductFormGroup, DealProductFormValueBase, DealProductsFormGroup } from '../deal-form.schema'
import { DealFormService } from '../deal-form.service'
import { canAddProductSel, canRemoveProductSel, hasBuyerInstructionsSel, hasSupplierInstructionsSel, isBuyerPortalVisibleSel, isSupplierPortalVisibleSel } from './deal-details.decorator'


@Component({
  selector: 'tc-deal-details-v2',
  templateUrl: './deal-details.component.html',
  styleUrls: ['./deal-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealDetailsComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private Measures: MeasuresService,
    private NoteForm: NoteFormService,
    private DealFormCalculator: DealFormCalculatorService,
    private CreditPoolSvc: CreditPoolService,
    private AuthApi: AuthApiService,
    private ChangePartyForm: ChangePartyFormService,
    private DealAutocopyForm: DealAutocopyFormService,
    private DealForm: DealFormService,
    private measure: MeasurePipe,
    private currency: CurrencyPipe,
    private DealDocuments: DealDocumentsService,
    private store: Store,
  ) { super() }

  protected readonly DealPartyE = DealPartyE

  // "weight" options
  readonly weights = range(5000, 26501, 500)
  // minimum shipping date. allow users from GMT+N to create "yesterday" deals at their midnight till N:00 AM
  readonly TODAY = dayjs().utc().startOf('day').unix()
  protected readonly enableBrokerageDeals = environment.enableBrokerageDeals

  isManager$ = this.AuthApi.currentUser$.pipe(
    map(user => user?.role === 'manager' || user?.role === 'superuser'),
    distinctUntilChanged())

  @Input() mode: 'finance' | 'products'
  @Input() dealId: string
  isNew: boolean
  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
  dealRaw$: Observable<DeepReadonly<Deal>> // pristine deal. doesn't include any form changes
  creditNotes$: Observable<DeepReadonly<CreditNote[]>>
  vendorCredits$: Observable<DeepReadonly<VendorCredit[]>>

  @Input() oldDealView$: Observable<DealView>
  @Input() costs$: Observable<DeepReadonly<DeepPartial<Cost>[]>>

  isLocked$: Observable<boolean>
  canAddProduct$: Observable<boolean>
  canRemoveProduct$: Observable<boolean>
  isSupplierPortalVisible$: Observable<boolean>
  isBuyerPortalVisible$: Observable<boolean>
  canSetSupplierFormula$: Observable<boolean>
  canSetBuyerFormula$: Observable<boolean>
  hasSupplierFormula$: Observable<string>
  hasBuyerFormula$: Observable<string>
  hasSupplierInstructions$: Observable<DeepReadonly<Note>>
  hasBuyerInstructions$: Observable<DeepReadonly<Note>>
  fxRates$: Observable<ForexData>
  fxRatesAskRange$: Observable<ForexRange>
  primaryCostsVisible  = false

  @Input() dealForm: DealFormGroup
  detailsForm: DealDetailsFormGroup
  productsForm: DealProductsFormGroup
  costsForm: CostsFormGroup

  @Output() updateCost = new EventEmitter<DeepReadonly<Partial<Cost>>>()
  @Output() removeCost = new EventEmitter<DeepReadonly<Partial<Cost>>>()

  // ref data
  countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty())
  currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty())
  itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())
  private measuresById$ = this.store.pipe(select(selectMeasureEntities), waitNotEmpty())
  private locationsById$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  private pricingTermsById$ = this.store.pipe(select(selectPricingTermEntities), waitNotEmpty())
  packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty())
  pricingTerms$ = this.store.pipe(select(selectAllPricingTerms), waitNotEmpty())
  weightTypes$ = this.store.pipe(select(selectAllWeightTypes), waitNotEmpty())
  wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty())
  // user options
  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
  buyerTraders$: Observable<GenericOption[]>
  buyerUsers$: Observable<DeepReadonly<User[]>>
  coordinators$: Observable<GenericOption[]>
  supplierTraders$: Observable<GenericOption[]>
  supplierUsers$: Observable<DeepReadonly<User[]>>
  // account options
  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  buyers$: Observable<DeepReadonly<GenericOption[]>>
  suppliers$: Observable<DeepReadonly<GenericOption[]>>
  supplierChanged$ = new Subject<string>()
  buyerChanged$ = new Subject<string>()
  supplierTraderChanged$ = new Subject<string>()
  buyerTraderChanged$ = new Subject<string>()

  docsCountryIssues$: Observable<string>

  // dynamic
  balance$ = new ReplaySubject<any>(1)
  creditPool$ = new ReplaySubject<CreditPool>(1)

  ngOnInit() {
    this.isNew = !this.dealId
    this.detailsForm = this.dealForm.controls.details
    this.productsForm = this.dealForm.controls.products
    this.costsForm = this.dealForm.controls.costs
    this.docsCountryIssues$ = this.DealDocuments.hasDocsCountryIssues$(
      this.dealForm,
      this.store.pipe(select(selectProductEntities), waitNotEmpty()))

    this.buyers$ = selectOptions(this.store, selectBuyersOptions, this.detailsForm.controls.buyerId)
    this.suppliers$ = selectOptions(this.store, selectSuppliersOptions, this.detailsForm.controls.supplierId)
    this.coordinators$ = selectOptions(this.store, selectCoordinatorsOptions, this.detailsForm.controls.logisticsUserId)
    this.buyerTraders$ = selectOptions(this.store, selectTradersOptions, this.detailsForm.controls.buyerTraderId)
    this.supplierTraders$ = selectOptions(this.store, selectTradersOptions, this.detailsForm.controls.supplierTraderId)

    this.dealRaw$ = this.dealViewRaw$.pipe(map(dto => dto.deal))
    this.creditNotes$ = this.dealViewRaw$.pipe(map(dto => dto.credit_notes))
    this.vendorCredits$ = this.dealViewRaw$.pipe(map(dto => dto.vendor_credits))
    this.isLocked$ = isLockedSel(this.dealRaw$)
    this.canAddProduct$ = canAddProductSel(this.dealViewRaw$, this.AuthApi.currentUser)
    this.canRemoveProduct$ = canRemoveProductSel(this.dealViewRaw$, this.productsForm, this.AuthApi.currentUser)
    this.isSupplierPortalVisible$ = isSupplierPortalVisibleSel(this.dealRaw$)
    this.isBuyerPortalVisible$ = isBuyerPortalVisibleSel(this.dealRaw$)
    this.canSetSupplierFormula$ = canSetSupplierFormulaSel(this.dealViewRaw$, this.detailsForm, this.AuthApi.currentUser$)
    this.canSetBuyerFormula$ = canSetBuyerFormulaSel(this.dealViewRaw$, this.detailsForm, this.AuthApi.currentUser$)
    this.hasSupplierInstructions$ = hasSupplierInstructionsSel(this.dealViewRaw$)
    this.hasBuyerInstructions$ = hasBuyerInstructionsSel(this.dealViewRaw$)
    this.fxRates$ = replayForm(this.detailsForm.controls.fxRates)
    this.fxRatesAskRange$ = replayForm(this.detailsForm.controls.fxRatesAskRange)
    this.supplierUsers$ = combineLatest([this.users$, replayForm(this.detailsForm.controls.supplierId)]).pipe(
      map(([users, account]) => getPartyUsers(users, account)))
    this.buyerUsers$ = combineLatest([this.users$, replayForm(this.detailsForm.controls.buyerId)]).pipe(
      map(([users, account]) => getPartyUsers(users, account)))
    this.hasSupplierFormula$ = combineLatest([
      replayForm(this.detailsForm),
      replayForm(this.productsForm.at(0)),
      this.locationsById$, this.measuresById$, this.pricingTermsById$,
    ]).pipe(map(([detailsForm, productForm, locations, measures, pricingTerms]) => printFormula(
        detailsForm.supplierFormula,
        detailsForm.supplierCurrencyCode,
        productForm.supplierMeasureId,
        productForm.supplierIncotermId,
        {locations, measures, pricingTerms})),
      distinctUntilChanged())
    this.hasBuyerFormula$ = combineLatest([
      replayForm(this.detailsForm),
      replayForm(this.productsForm.at(0)),
      this.locationsById$, this.measuresById$, this.pricingTermsById$,
    ]).pipe(map(([detailsForm, productForm, locations, measures, pricingTerms]) => printFormula(
        detailsForm.buyerFormula,
        detailsForm.buyerCurrencyCode,
        productForm.buyerMeasureId,
        productForm.buyerIncotermId,
        {locations, measures, pricingTerms})),
      distinctUntilChanged())

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadConsignees())
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadMeasures({}))
    this.store.dispatch(loadPackageTypes({}))
    this.store.dispatch(loadPaymentReferences({}))
    this.store.dispatch(loadPricingTerms({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadUsers({archived: 0}))
    this.store.dispatch(loadWeightTypes({}))
    this.store.dispatch(loadWrappingTypes({}))

    this.refreshBuyerBalance(this.detailsForm.controls.buyerId.value)
    combineLatest([
      this.supplierChanged$.pipe(switchMap(accountId => this.onSupplierChange(accountId))),
      this.buyerChanged$.pipe(switchMap(accountId => this.onBuyerChange(accountId))),
      this.supplierTraderChanged$.pipe(switchMap(() => this.onSupplierTraderChange())),
      this.buyerTraderChanged$.pipe(switchMap(() => this.onBuyerTraderChange())),
    ]).pipe(untilComponentDestroyed(this)).subscribe()

    this.dealRaw$.pipe(take(1)).subscribe((d) => {
      if (!d.attributes.estimate_id) return

      if (this.detailsForm.controls.supplierId.value) {
        this.supplierChanged$.next(this.detailsForm.controls.supplierId.value)
      }
      if (this.detailsForm.controls.buyerId.value) {
        this.buyerChanged$.next(this.detailsForm.controls.buyerId.value)
      }
    })
  }

  invoiceAddressOptions$(productForm: DealProductFormGroup) {
    return invoiceAddressOptions(this.detailsForm, productForm)
  }

  establishmentAddressOptions$(productForm: DealProductFormGroup) {
    return establishmentAddressOptions(this.detailsForm, productForm)
  }

  supplierIndPrice$(productForm: DealProductFormGroup) {
    return combineLatest([
      replayForm(this.detailsForm),
      replayForm(productForm),
    ]).pipe(map(([deal, product]) =>
      product.supplierEstPrice && deal.supplierCurrencyCode && product.supplierMeasureId
        ? this.measure.transform(
            this.currency.transform(product.supplierEstPrice, deal.supplierCurrencyCode, 'symbol-narrow', '1.4'/* , 'no_TH' */),
            product.supplierMeasureId)
        : ''))
  }

  buyerIndPrice$(productForm: DealProductFormGroup) {
    return combineLatest([
      replayForm(this.detailsForm),
      replayForm(productForm),
    ]).pipe(map(([deal, product]) =>
      product.buyerEstPrice && deal.buyerCurrencyCode && product.buyerMeasureId
        ? this.measure.transform(
            this.currency.transform(product.buyerEstPrice, deal.buyerCurrencyCode, 'symbol-narrow', '1.4'/* , 'no_TH' */),
            product.buyerMeasureId)
        : ''))
  }

  supplierIndWeight$(productForm: DealProductFormGroup) {
    return replayForm(productForm).pipe(
      map(({ supplierEstWeight, supplierMeasureId }) =>
        supplierEstWeight || supplierEstWeight === 0 ? this.measure.transform(supplierEstWeight, supplierMeasureId) : ''),
      distinctUntilChanged())
  }

  buyerIndWeight$(productForm: DealProductFormGroup) {
    return replayForm(productForm).pipe(
      map(({ buyerEstWeight, buyerMeasureId }) =>
        buyerEstWeight || buyerEstWeight === 0 ? this.measure.transform(buyerEstWeight, buyerMeasureId) : ''),
      distinctUntilChanged())
  }

  changeFormula(party: DealPartyE.buyer | DealPartyE.supplier) {
    this.dealViewRaw$.pipe(take(1), switchMap(deal =>
      this.ChangePartyForm.showChangeFormula(deal, this.dealForm, party)),
    ).subscribe()
  }

  addProduct() {
    combineLatest([this.accounts$, this.dealViewRaw$]).pipe(take(1)).subscribe(([accounts, dv]) => {
      const firstProductForm = this.productsForm.at(0).getRawValue()
      const detailsForm = this.detailsForm.getRawValue()

      const supplier = accounts[detailsForm.supplierId].attributes.pricing
      const buyer = accounts[detailsForm.buyerId].attributes.pricing

      const productForm = this.DealForm.buildDealProductForm()
      productForm.patchValue({
        supplierMeasureId: firstProductForm.supplierMeasureId || undefined,
        supplierIncotermId: supplier?.incoterm || undefined,
        supplierIncotermLocationId: detailsForm.originLocationId || supplier?.tax_location || undefined,
        buyerMeasureId: firstProductForm.buyerMeasureId || undefined,
        buyerIncotermId: buyer?.incoterm || undefined,
        buyerIncotermLocationId: detailsForm.destLocationId || buyer?.tax_location || undefined,
      })
      this.DealForm.enableDisableProductForm(productForm, dv)
      this.productsForm.push(productForm)
    })
  }

  removeProduct(product_index: number) {
    this.productsForm.removeAt(product_index)
    // remove primary cost and adjust "product_index" in remaining costs
    const costsForm = this.dealForm.controls.costs
    costsForm.value.reduce((res, {cost}, index) => {
      if (cost.type === 'primary' && cost.attributes?.product_index === product_index) res.push(index)
      return res
    }, [] as number[]).sort().reverse().forEach(index => costsForm.removeAt(index))

    const primaryCosts = sortBy(costsForm.controls.filter(cf => cf.value.cost.type === 'primary'), 'value.cost.attributes.product_index')
    // here we expect product_indexes to be set and unique
    primaryCosts.slice(product_index).forEach((costForm) => {
      costForm.setValue({
        cost: {
          ...costForm.value.cost,
          attributes: {
            ...costForm.value.cost.attributes,
            product_index: costForm.value.cost.attributes.product_index - 1,
          },
        },
      })
    })
  }

  buyerConfirm() {
    this.detailsForm.patchValue({
      buyerConfirmedAt: this.detailsForm.getRawValue().buyerConfirmedAt
        ? 0 : dayjs().unix(),
      buyerConfirmedBy: this.AuthApi.currentUser.user_id,
    })
    // this.updateDealStatus()
  }

  sellerConfirm() {
    this.detailsForm.patchValue({
      supplierConfirmedAt: this.detailsForm.getRawValue().supplierConfirmedAt
        ? 0 : dayjs().unix(),
      supplierConfirmedBy: this.AuthApi.currentUser.user_id,
    })
    // this.updateDealStatus()
  }

  // NOTE: synthetic deal status was introduced in April'2018. currently unused.
  // https://bitbucket.org/tradecafe/webapp-staff/commits/665c8c2eee654f61cc1ef49eb7c2eeffd62340a1
  // TODO: WA-7397 (save): change deal status when clicking checkboxes
  private async updateDealStatus(): Promise<Pick<Deal, 'status'>> {
    const deal = await this.dealRaw$.pipe(take(1)).toPromise()
    const { supplierConfirmedAt, buyerConfirmedAt } = this.detailsForm.getRawValue()
    if ([DEAL_DRAFT, DEAL_SUBMITTED].includes(deal.status as DealStatusE) && supplierConfirmedAt && buyerConfirmedAt) {
      return { status: DEAL_CONFIRMED }
    } else if (deal.status === DEAL_CONFIRMED && !(supplierConfirmedAt && buyerConfirmedAt)) {
      return { status: DEAL_SUBMITTED }
    }
    return undefined
  }


  onSupplierTraderChange() {
    const supplierId = this.detailsForm.controls.supplierId.value
    if (!supplierId) return of(0)
    const traderId = this.detailsForm.controls.supplierTraderId.value
    return this.DealFormCalculator.refillPaymentTerms(supplierId, DealPartyE.supplier, traderId)
      .pipe(tap(details => this.dealForm.patchValue({ details })))
  }

  onBuyerTraderChange() {
    const buyerId = this.detailsForm.controls.buyerId.value
    if (!buyerId) return of(0)
    const traderId = this.detailsForm.controls.buyerTraderId.value
    return this.DealFormCalculator.refillPaymentTerms(buyerId, DealPartyE.buyer, traderId)
      .pipe(tap(details => this.dealForm.patchValue({ details })))
  }

  /**
   * Observe supplier change
   *
   * @param {*} newSupplierId
   */
  onSupplierChange(supplierId: string) {
    if (!supplierId) {
      this.DealForm.clearSupplierFields(this.detailsForm)
      return this.DealForm.refreshSecondaryCosts(this.dealForm)
    }

    const { buyerId } = this.detailsForm.getRawValue()
    this.suggestDealAutocopy({ buyerId, supplierId })

    return this.DealFormCalculator.prefillPartyFields(!this.dealId, DealPartyE.supplier, supplierId).pipe(
      switchMap(({ details, product, partyAcc }) => {
        const products = this.productsForm.controls.map(productForm =>
          pickBy(product, (_value, key) => productForm.get(key).pristine))
        // NOTE: we do not override measure and currency without user interaction
        if (!this.dealId && this.detailsForm.controls.supplierCurrencyCode.pristine) {
          const currency = partyAcc?.attributes.pricing?.currency
          details.supplierCurrencyCode = currency || null
        }
        this.dealForm.patchValue({ details, products })
        return this.DealForm.refreshSecondaryCosts(this.dealForm)
      }),
      switchMap(() => this.DealFormCalculator.refillPaymentTerms(supplierId, DealPartyE.supplier, this.detailsForm.controls.supplierTraderId.value)),
      tap(details => this.dealForm.patchValue({ details })),
      switchMap(() => this.doCalculations()))
  }

  /**
   * Observe buyer change
   *
   * @param {*} newBuyerId
   */
  onBuyerChange(buyerId: string) {
    const buyerIdWithInvoiceInReceipt = this.DealForm.handleBuyerInvoiceWithReceipt(this.dealViewRaw$, buyerId, this.detailsForm)

    if(buyerIdWithInvoiceInReceipt) {
      return buyerIdWithInvoiceInReceipt
    }

    if (!buyerId) {
      this.DealForm.cleanBuyerFields(this.detailsForm)
      return this.DealForm.refreshSecondaryCosts(this.dealForm)
    }

    const { supplierId } = this.detailsForm.getRawValue()
    this.suggestDealAutocopy({ buyerId, supplierId })

    this.refreshBuyerBalance(buyerId)

    return this.DealFormCalculator.prefillPartyFields(!this.dealId, DealPartyE.buyer, buyerId).pipe(
      switchMap(({ details, product, partyAcc }) => {
        const products = this.productsForm.controls.map(productForm =>
          pickBy(product, (_value, key) => productForm.get(key).pristine))
        // NOTE: do not override measure and currency without user interaction.
        if (!this.dealId && this.detailsForm.controls.buyerCurrencyCode.pristine) {
          const currency = partyAcc.attributes.pricing?.currency
          details.buyerCurrencyCode = currency || null
        }
        this.dealForm.patchValue({ details, products })
        return this.DealForm.refreshSecondaryCosts(this.dealForm)
      }),
      switchMap(() => this.DealFormCalculator.refillPaymentTerms(buyerId, DealPartyE.buyer, this.detailsForm.controls.buyerTraderId.value)),
      tap(details => this.dealForm.patchValue({ details })),
      switchMap(() => this.doCalculations()))
  }

  onOriginLocationChange(locationId: DeepReadonly<LocationObject>) {
    for (const productForm of this.productsForm.controls) {
      const ctrl = productForm.controls.supplierIncotermLocationId
      ctrl.setValue(locationId.location_id)
      ctrl.markAsDirty()
    }
    this.refreshSecondaryCosts()
  }

  onDestLocationChange(locationId: DeepReadonly<LocationObject>) {
    for (const productForm of this.productsForm.controls) {
      const ctrl = productForm.controls.buyerIncotermLocationId
      ctrl.setValue(locationId.location_id)
      ctrl.markAsDirty()
    }
    this.refreshSecondaryCosts()
  }

  onDocsCountryChange(country: Country) {
    const ctrl = this.detailsForm.controls.proformaNeeded
    ctrl.setValue(country.code === 'CO')
    ctrl.markAsDirty()
  }

  onProductChange() {
    this.refreshSecondaryCosts()
  }

  onSupplierMeasureChange(index: number, nextMeasure: Measure) {
    const productForm = this.productsForm.at(index)
    const nextValue = productForm.getRawValue()
    const { supplierMeasureId: prevMeasure } = this.productsForm.getRawValue()[index]
    const { supplierEstWeight: prevWeight, supplierActualWeight: prevActualWeight } = nextValue
    const nextWeight = round(this.Measures.convert(prevWeight, prevMeasure, nextMeasure), 2)
    productForm.patchValue({
      supplierEstWeight: nextWeight || prevWeight,
      supplierActualWeight: prevActualWeight ? nextWeight || prevWeight : prevActualWeight,
    })
    this.onSupplierWeightChange(index)
  }

  onBuyerMeasureChange(index: number, nextMeasure: Measure) {
    const productForm = this.productsForm.at(index)
    const nextValue = productForm.getRawValue()
    const { buyerMeasureId: prevMeasure, buyerActualWeight: prevActualWeight } = this.productsForm.getRawValue()[index]
    const { buyerEstWeight: prevWeight } = nextValue
    const nextWeight = round(this.Measures.convert(prevWeight, prevMeasure, nextMeasure), 2)
    productForm.patchValue({
      buyerEstWeight: nextWeight || prevWeight,
      supplierActualWeight: prevActualWeight ? nextWeight || prevWeight : prevActualWeight,
    })
    this.onBuyerWeightChange(index)
  }

  onSupplierWeightChange(index: number) {
    const productForm = this.productsForm.at(index)
    const { supplierEstWeight, supplierMeasureId, buyerMeasureId, supplierActualWeight, buyerActualWeight } = productForm.getRawValue()
    const buyerEstWeight = round(this.Measures.convert(supplierEstWeight, supplierMeasureId, buyerMeasureId), 2)
    const patch: Partial<DealProductFormValueBase> = { buyerEstWeight }
    if (supplierActualWeight) patch.supplierActualWeight = supplierEstWeight
    if (buyerActualWeight) patch.buyerActualWeight = buyerEstWeight
    productForm.patchValue(patch)
    Object.keys(patch).forEach((key: keyof DealProductFormValueBase) =>
      productForm.controls[key].markAsDirty())
    this.doCalculations().subscribe()
  }

  onBuyerWeightChange(index: number) {
    const productForm = this.productsForm.at(index)
    const { buyerEstWeight, buyerActualWeight } = productForm.getRawValue()
    if (buyerActualWeight) {
      productForm.patchValue({ buyerActualWeight: buyerEstWeight })
      productForm.controls.buyerActualWeight.markAsDirty()
    }
    this.doCalculations().subscribe()
  }

  onSupplierPriceChange(productForm: DealProductFormGroup) {
    const ctrl = productForm.controls.supplierActualPrice
    ctrl.setValue(productForm.getRawValue().supplierEstPrice)
    ctrl.markAsDirty()
    this.doCalculations().subscribe()
  }

  onBuyerPriceChange(productForm: DealProductFormGroup) {
    const ctrl = productForm.controls.buyerActualPrice
    ctrl.setValue(productForm.getRawValue().buyerEstPrice)
    ctrl.markAsDirty()
    this.doCalculations().subscribe()
  }

  onCurrencyChange() {
    this.doCalculations().subscribe()
  }

  addSupplierInstructions() {
    combineLatest([this.dealViewRaw$, this.hasSupplierInstructions$]).pipe(take(1)).subscribe(([dv, existing]) =>
      this.NoteForm.editSpecialInstructions(dv, existing, dv.deal.supplier_id, SPECIAL_INSTRUCTIONS))
  }

  addBuyerInstructions() {
    combineLatest([this.dealViewRaw$, this.hasBuyerInstructions$]).pipe(take(1)).subscribe(([dv, existing]) =>
      this.NoteForm.editSpecialInstructions(dv, existing, dv.deal.buyer_id, SPECIAL_INSTRUCTIONS))
  }
  setWoExportDocs({checked}) {
    this.detailsForm.controls.woExportDocs.setValue(!!checked)
  }

  /**
   * Start deal calculations
   *
   * @private
   */
  private refreshSecondaryCosts() {
    this.DealForm.refreshSecondaryCosts(this.dealForm)
      .pipe(switchMap(() => this.doCalculations()))
      .subscribe()
  }

  /**
   * Start deal calculations
   *
   * @private
   */
  private doCalculations() {
    return this.dealViewRaw$.pipe(take(1), switchMap(dto =>
      this.DealFormCalculator.doCalculationsForm(this.dealForm, dto.invoices)))
  }

  /**
   * When creating a deal open autocopy suggestion dialog when user changed supplier or buyer
   *
   * @private
   */
  private async suggestDealAutocopy({supplierId, buyerId}: Pick<DealDetailsFormValue, 'supplierId'|'buyerId'>) {
    const deal = await this.dealRaw$.pipe(take(1)).toPromise()
    if (this.dealId || !supplierId || !buyerId || deal.attributes.estimate_id) return // only new deals and only if supplier and buyer are set
    this.DealAutocopyForm.show({ supplierId, buyerId, dealDetails: this.detailsForm.getRawValue() }).subscribe()
  }

  private refreshBuyerBalance(buyerId: string) {
    if (!buyerId) return
    this.CreditPoolSvc.getBalance(buyerId).then(balance => this.balance$.next(balance))
    this.CreditPoolSvc.getFor(buyerId).then(creditPool => this.creditPool$.next(creditPool))
  }
}

function canSetSupplierFormulaSel(dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices>>, detailsForm: DealDetailsFormGroup, currentUser$: Observable<User>) {
  return combineLatest([dealViewRaw$, replayForm(detailsForm), currentUser$]).pipe(
    map(([dv, { supplierFormula }, currentUser]) =>
      canEditDealFormField(dv, 'supplierEstPrice', currentUser) && !supplierFormula),
    distinctUntilChanged())
}

function canSetBuyerFormulaSel(dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices>>, detailsForm: DealDetailsFormGroup, currentUser$: Observable<User>) {
  return combineLatest([dealViewRaw$, replayForm(detailsForm), currentUser$]).pipe(
    map(([dv, { buyerFormula }, currentUser]) =>
      canEditDealFormField(dv, 'buyerEstPrice', currentUser) && !buyerFormula),
    distinctUntilChanged())
}
