import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { DealViewRaw, INTERNAL, Note, NoteVisibilities, NoteVisibility, ONLY_ME, VENDORS } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { compact, find } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs'
import { catchError, distinctUntilChanged, map } from 'rxjs/operators'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { NotesService, SPECIAL_INSTRUCTIONS, getCategoryOptions2, getGeneralCategoryOptions2, getGeneralRecipientOptions2, getRecipientOptions2 } from 'src/services/data/notes.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { disableIf } from 'src/shared/utils/disable-if'
import { replayForm } from 'src/shared/utils/replay-form'
import { requiredIf } from 'src/shared/utils/required-if'


export interface NoteFormOptionsBase {
  title: string
  note?: DeepReadonly<Partial<Note>>
  onlyPayload?: boolean
  hide?: {
    category?: true
    visibility?: true
    company?: true
  }
}

export interface NoteFormOptionsGeneral extends NoteFormOptionsBase {
  type: 'general'
  deals?: DeepReadonly<DealViewRaw[]>
  isBulkAdd?: boolean
  categoryIds?: string[]
}

export interface NoteFormOptionsDealSpecific extends NoteFormOptionsBase {
  type: 'deal'
  dealViewRaw: DeepReadonly<Partial<DealViewRaw>>
}

export type NoteFormOptions = NoteFormOptionsGeneral | NoteFormOptionsDealSpecific

@Component({
  selector: 'tc-note-form-v2',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private store: Store,
    private DealDocuments: DealDocumentsService,
    private Notes: NotesService,
    private toaster: ToasterService,
    private dialogRef: MatDialogRef<NoteFormComponent, Partial<Note> | Partial<Note>[]>,
    @Inject(MAT_DIALOG_DATA) private dialogData: NoteFormOptions,
  ) { super() }

  readonly NoteVisibilities = NoteVisibilities

  // ref data
  categoriOptions = this.dialogData.type === 'general'
    ? getGeneralCategoryOptions2(this.dialogData.deals, this.dialogData.isBulkAdd)
    : getCategoryOptions2(this.dialogData.dealViewRaw)
  recipients$ = combineLatest([
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    this.store.pipe(select(selectCarrierEntities), waitNotEmpty()),
  ]).pipe(map(([accounts, carriers]) =>
    this.dialogData.type === 'general'
      ? getGeneralRecipientOptions2(this.dialogData.deals, { accounts, carriers })
      : getRecipientOptions2(this.dialogData.dealViewRaw, { accounts, carriers })))
  documentTypes$: Observable<{ id: string, name: string }[]> = this.dialogData.type === 'general'
    ? of([])
    : this.DealDocuments.getDocTypesForDeal(this.dialogData.dealViewRaw.deal).pipe(
      map(data => data.map(type => ({ id: type, name: type }))),
      catchError(() => of([])))

  // form
  noteForm = new FormGroup({
    categories: new FormControl<string[]>(
      this.dialogData.type === 'general' && this.dialogData.isBulkAdd
        ? this.dialogData.categoryIds
        : compact([this.dialogData.note?.attributes.category])
    ),
    company: new FormControl<string[]>(this.dialogData.note?.attributes.company as string[]/* look for requiredIf below */),
    document_type: new FormControl<string>(this.dialogData.note?.attributes.document_type),
    visibility: new FormControl<NoteVisibility>(this.dialogData.note?.visibility || ONLY_ME),
    body: new FormControl<string>(this.dialogData.note?.body || '', Validators.required),
  })

  // ui state
  title = this.dialogData.title
  isNew = !this.dialogData.note.note_id
  canSeeCategory = !this.dialogData.hide?.category
  canSeeVisibility = !this.dialogData.hide?.visibility
  canSeeCompaniesList$ = this.dialogData.hide?.company
    ? of(false)
    : of(this.dialogData.note?.attributes.category).pipe(
      map(category => category !== 'emergency' && !this.isCreditNote(category) && !this.dialogData.hide?.company),
      distinctUntilChanged())
  canSeeDocumentType$ = of(this.dialogData.note?.attributes.category).pipe(map(category => category === 'emergency'), distinctUntilChanged())
  canIgnore = this.dialogData.note.note_id && !this.dialogData.note.attributes.ignored
  canUnignore = this.dialogData.note.note_id && this.dialogData.note.attributes.ignored

  inProgress$ = new BehaviorSubject<'save' | 'ignore'>(undefined)


  ngOnInit(): void {
    of(this.dialogData.note?.attributes.category).subscribe(category =>
      disableIf(this.noteForm.controls.visibility, category === 'special_instructions'))
    replayForm(this.noteForm).subscribe(({ visibility }) =>
      requiredIf(this.noteForm.controls.company,
        visibility === VENDORS &&
        !this.isCreditNote(this.dialogData.note?.attributes.category) &&
        !(this.dialogData.hide?.company && this.dialogData.onlyPayload)))

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCarriers({}))
  }


  async saveMultipleNotes() {
    // check if valid
    if (this.inProgress$.value) return
    this.noteForm.markAllAsTouched()
    this.noteForm.updateValueAndValidity()
    if (!this.noteForm.valid) return
    //
    let notes = this.getNotesFromForm();
    let saveNotePromises = notes.map((n) => this.saveSingleNote({ note: n }))
    this.inProgress$.next('save')
    try {
      await Promise.all(saveNotePromises)
      this.toaster.success('Note(s) saved successfully.')
      this.dialogRef.close(notes)
    } catch (error) {
      console.error('Unable to save(s) note', error)
      this.toaster.error('Unable to save(s) note', error)
    }
    this.inProgress$.next(undefined)
  }

  // tslint:disable-next-line: cyclomatic-complexity
  async saveSingleNote({ note }) {
    // build json obj
    if (this.dialogData.type === 'deal') {
      note.deal_id = this.dialogData.dealViewRaw.deal.deal_id
    }
    if (this.dialogData.note.note_id) {
      note.attributes.original_note_id = this.dialogData.note.note_id
    }
    if (Array.isArray(note.attributes.company)) {
      note.attributes.company = note.attributes.company.filter(item => item)
    }
    delete note.note_id
    //
    // API call
    if (this.dialogData.type === 'deal') {
      const deal = this.dialogData.dealViewRaw.deal
      note = await this.Notes.saveDealNote(deal, note, this.dialogData.note)
    } else {
      note = await this.Notes.recreateNote(note, this.dialogData.note)
    }
    //
  }

  isSingleCategory(): boolean {
    if (this.noteForm.controls.categories.value.length !== 1) {
      const errorMsg = 'Ignore/Unignore can only support one category selection'
      console.error(errorMsg)
      this.toaster.warning(errorMsg)
      return false
    } else {
      return true
    }
  }

  async ignore() {
    if (!this.isSingleCategory()) {
      return
    }
    this.inProgress$.next('ignore')
    try {
      const deal = this.dialogData.type === 'deal' ? this.dialogData.dealViewRaw.deal : undefined
      const note = await this.Notes.ignoreImmutable(this.getNoteFromForm(), deal)
      this.toaster.success('Note saved successfully.')
      this.dialogRef.close(note)
    } catch (err) {
      console.error('Unable to save note', err)
      this.toaster.error('Unable to save note', err)
    }
    this.inProgress$.next(undefined)
  }

  async unignore() {
    if (!this.isSingleCategory()) {
      return
    }
    this.inProgress$.next('ignore')
    try {
      const note = await this.Notes.unignoreImmutable(this.getNoteFromForm())
      this.toaster.success('Note saved successfully.')
      this.dialogRef.close(note)
    } catch (err) {
      console.error('Unable to save note', err)
      this.toaster.error('Unable to save note', err)
    }
    this.inProgress$.next(undefined)
  }

  cancel() {
    this.dialogRef.close()
  }

  private isCreditNote(category: string) {
    return find(this.categoriOptions, { id: category })?.type === 'credit_note'
  }

  private getNoteFromForm(): Partial<Note> {
    const noteForm = this.noteForm.getRawValue()
    return {
      ...this.dialogData.note,
      body: noteForm.body,
      visibility: noteForm.visibility,
      attributes: {
        ...this.dialogData.note.attributes,
        category: this.noteForm.controls.categories.value[0],
        company: noteForm.company,
        document_type: noteForm.document_type,
        ignored: 0,
      },
    }
  }

  private setVisibility(category, currentVisibility) {
    return category === SPECIAL_INSTRUCTIONS || category === 'documents' ? INTERNAL : currentVisibility
  }

  private getNotesFromForm(): Partial<Note>[] {
    const noteForm = this.noteForm.getRawValue()
    return noteForm.categories.map((c) => ({
      ...this.dialogData.note,
      body: noteForm.body,
      visibility: this.setVisibility(c, noteForm.visibility),
      attributes: {
        ...this.dialogData.note.attributes,
        category: c,
        company: noteForm.company,
        document_type: noteForm.document_type,
        ignored: 0,
      },
    })
    )
  }
}
