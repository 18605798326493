/**
 * Decorator for upgraded API methods. Maps all arguments using `angular.copy`
 */
export function AngularCopy<T>() {
  return function (target: new (...params: any[]) => T) {
    for (const key of Object.getOwnPropertyNames(target.prototype)) {
      let descriptor = Object.getOwnPropertyDescriptor(target.prototype, key)
      if (descriptor && typeof descriptor.value === 'function') {
        descriptor = AngularCopyDecorator()(key, descriptor)
        Object.defineProperty(target.prototype, key, descriptor)
      }
    }
  }
}

function AngularCopyDecorator(): (methodName: string, descriptor: PropertyDescriptor) => PropertyDescriptor {
  return (methodName: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const originalMethod = descriptor.value
    descriptor.value = function (...args) {
      return originalMethod.apply(this, args.map(arg => arg ? angular.copy(arg) : arg))
    }
    return descriptor
  }
}


