<cdk-virtual-scroll-viewport [ngClass]="{ 'tc-scrollable-table': isScrollable }" [itemSize]="60" style="--mat-table-row-item-container-height: 60px">
  <table mat-table [trackBy]="getRowId"
      fixedLayout [dataSource]="dataSource"
      matSort matSortStart="desc" matSortDisableClear>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <ng-container *ngIf="filtersForm">
      <tr mat-header-row *matHeaderRowDef="filterColumns" class="tc-filters-row"></tr>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row"
        [class.clickable]="!!row && isClickable" [class.wrong-deal-status]="!!row && highlightWrongDealStatus(row)"
        [class.intransit-credit-negative-deal]="!!row && highlightIntransitCreditNegative(row)"></tr>
    <tr mat-footer-row *matFooterRowDef="displayColumns; sticky: true" [class.tc-show-footer]="totals$ | async"></tr>


    <ng-container *ngFor="let x of availableColumnFilters" [matColumnDef]="x.colName + '-filter'">
      <th mat-header-cell *matHeaderCellDef class="mat-header-cell-filter">
        <ng-container *ngIf="filtersData && filtersData[x.filterName] as filterData">
          <tc-select-search *ngIf="filterData.type === 'select'" [multiple]="true"
              [realPlaceholder]="columnNames[x.colName]"
              [group]="filtersForm" [ctrlName]="x.filterName"
              (openedChange)="!$event && applyFilters.next()"
              [items]="filterData.options"
              bindValue="id"
              bindLabel="name"
              ></tc-select-search>
          <tc-epoch-range-field *ngIf="filterData.type === 'epoch-range'" placeholder="" style="display: grid"
              [formGroup]="filtersForm" [startCtrlName]="x.colName + '_from'" [endCtrlName]="x.colName + '_to'"
              (closed)="applyFilters.next()" [useUtc]="!!DEALS_FILTER_SETTINGS[x.colName].useUtc"
              ></tc-epoch-range-field>
        </ng-container>
      </th>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox *ngIf="selectAll && !selectOne" matTooltip="Select All" (change)="selection.toggleRows($event.checked, dataSource.data)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <div *ngIf="!deal" class="skeleton-loader square"></div>
        <mat-checkbox *ngIf="deal && selection.canSelect(deal)"
            [checked]="selection.isSelected(deal)"
            [disabled]="selectOne && !selection.isSelected(deal) && !!selection.selectedIds.size"
            (click)="$event.stopPropagation()"
            (change)="selection.toggleRow(deal)"
            ></mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deal_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deal_date">{{columnNames.deal_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.deal_date || deal.created }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deal_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deal_id">{{columnNames.deal_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <a *ngIf="!isInventoryModal" class="text-ellipsis" [title]="deal.deal_id"
              [routerLink]="getDealUrl | call:deal.deal_id"
              target="_blank"
              (click)="$event.stopPropagation();$event.preventDefault();openDealForm(deal)">
            {{deal.deal_id}}
          </a>
          <a *ngIf="isInventoryModal" style="cursor: pointer;" class="text-ellipsis" [title]="deal.deal_id"
          (click)="$event.stopPropagation();$event.preventDefault();dealIdClick.emit(deal.deal_id)">
            {{deal.deal_id}}
          </a>
          <tc-deal-status [deal]="deal"></tc-deal-status>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.product">{{columnNames.product}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" [title]="deal.product.name">{{deal.product.name}}</div>
          <small class="text-ellipsis text-gray">{{deal.product.supplier.weight | measure:deal.product.supplier.measure_id}}</small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buy_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buy_price">{{columnNames.buy_price}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" title="{{deal.product.buy_price.amount | number:'1.4'}} {{deal.product.buy_price.currency | measure:deal.product.buy_price.measure_id}}">{{deal.product.buy_price.amount}}</div>
          <small class="text-gray text-ellipsis">
            {{deal.product.buy_price.currency | measure:deal.product.buy_price.measure_id}}
            <i *ngIf="deal.product.buy_price.formula" class="fa fa-info-circle"
                [matTooltip]="deal.product.buy_price.formula"></i>
          </small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="sell_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.sell_price">{{columnNames.sell_price}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" title="{{deal.product.sell_price.amount | number:'1.4'}} {{deal.product.sell_price.currency | measure:deal.product.sell_price.measure_id}}">{{deal.product.sell_price.amount}}</div>
          <small class="text-ellipsis text-gray">
            {{deal.product.sell_price.currency | measure:deal.product.sell_price.measure_id}}
            <i *ngIf="deal.product.sell_price.formula" class="fa fa-info-circle"
                [matTooltip]="deal.product.sell_price.formula"></i>
          </small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.status">{{columnNames.status}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.status">{{deal.status}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="shipment_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.shipment_status">{{columnNames.shipment_status}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.shipment_status">{{deal.shipment_status}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer">{{columnNames.buyer}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.buyer">
          <div [class.tc-portal-visible-contents]="isDealPortalVisible(deal, deal.buyer_id)">
            <div class="text-ellipsis" [title]="deal.buyer.name" *ngIf="deal.buyer.name">{{deal.buyer.name}}</div>
            <small *ngIf="deal.buyer.name" class="text-ellipsis text-gray" [class.tc-portal-visible]="isDealPortalVisible(deal, deal.buyer_id)">
              <span *ngIf="deal.status !== 'Rejected' && !deal.buyer_confirmed && deal.attributes.buyer_confirm_sent"
                    class="label label-success text-uppercase">Sent</span>
              <span *ngIf="deal.status !== 'Rejected' && deal.buyer_confirmed"
                    class="label label-success text-uppercase">Confirmed</span>
              <span *ngIf="deal.status === 'Rejected'"
                    class="label label-danger text-uppercase">Rejected</span>
            </small>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dest_location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.dest_location">{{columnNames.dest_location}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'dest_location')"
          (click)="inlineDestEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.dest_location">
          <div class="text-ellipsis" [title]="deal.dest_location_name">{{deal.dest_location_name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier">{{columnNames.supplier}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.supplier">
          <div [class.tc-portal-visible-contents]="isDealPortalVisible(deal, deal.supplier_id)">
            <div class="text-ellipsis" [title]="deal.supplier.name" *ngIf="deal.supplier.name">{{deal.supplier.name}}</div>
            <small class="text-ellipsis text-gray" *ngIf="deal.supplier.name" [class.tc-portal-visible]="isDealPortalVisible(deal, deal.supplier_id)">
              <span *ngIf="deal.status !== 'Rejected' && !deal.seller_confirmed && deal.attributes.seller_confirm_sent"
                    class="label label-success text-uppercase">Sent</span>
              <span *ngIf="deal.status !== 'Rejected' && deal.seller_confirmed"
                    class="label label-success text-uppercase">Confirmed</span>
              <span *ngIf="deal.status === 'Rejected'"
                    class="label label-danger text-uppercase">Rejected</span>
            </small>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="origin_location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.origin_location">{{columnNames.origin_location}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'origin_location')"
          (click)="inlineOriginEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.origin_location">
          <div class="text-ellipsis" [title]="deal.origin_location_name">{{deal.origin_location_name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dest_country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.dest_country">{{columnNames.dest_country}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.dest_country">
          <div class="text-ellipsis" [title]="deal.dest_country.name">{{deal.dest_country.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="docs_country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.docs_country">{{columnNames.docs_country}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.docs_country">
          <div class="text-ellipsis" [title]="deal.docs_country.name">{{deal.docs_country.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="origin_country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.origin_country">{{columnNames.origin_country}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.origin_country">{{deal.origin_country}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pickup_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.pickup_date">{{columnNames.pickup_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'earliestTruckSegment.attributes.actual_pickup_date')"
          (click)="inlineEditPickupDate(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="deal.pickup_date_time ? cellEpoch : cellEpochDate; context: { timestamp: deal.pickup_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dropoff_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.dropoff_date">{{columnNames.dropoff_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'latestTruckSegment.attributes.actual_delivery_date')"
          (click)="inlineEditDropoffDate(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="deal.dropoff_date_time ? cellEpoch : cellEpochDate; context: { timestamp: deal.dropoff_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buying_trader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buying_trader">{{columnNames.buying_trader}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.buying_trader">
          <div class="text-ellipsis" [title]="deal.buying_trader.fullname">{{deal.buying_trader.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="selling_trader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.selling_trader">{{columnNames.selling_trader}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.selling_trader">
          <div class="text-ellipsis" [title]="deal.selling_trader.fullname">{{deal.selling_trader.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_ref">{{columnNames.supplier_ref}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.supplier_ref')"
          (click)="inlineTextEdit(deal, 'attributes.supplier_ref', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.supplier_ref">{{deal.attributes.supplier_ref}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="inv">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.inv">{{columnNames.inv}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'inv')"
          (click)="inlineTextEdit(deal, 'inv', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.inv">{{deal.inv}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_ref">{{columnNames.buyer_ref}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.buyer_ref')"
          (click)="inlineTextEdit(deal, 'attributes.buyer_ref', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.buyer_ref">{{deal.attributes.buyer_ref}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="delivery_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.delivery_date">{{columnNames.delivery_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.delivery_dates')"
          (click)="inlineDeliveryDateRangeEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.delivery_dates | epochRange:true">
            {{deal.attributes.delivery_dates | epochRange:true}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="shipment_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.shipment_date">{{columnNames.shipment_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.shipment_dates')"
          (click)="inlineShipmentDateRangeEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.shipment_dates | epochRange:true">
            {{deal.attributes.shipment_dates | epochRange:true}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="partial_margin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.partial_margin">{{columnNames.partial_margin}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.partial_margin | zeroIfBwi:deal | currency:'CAD':'symbol-narrow'"
              [class.text-red]="(deal.attributes.actual.partial_margin|zeroIfBwi:deal) < 0">
            {{deal.attributes.actual.partial_margin | zeroIfBwi:deal | currency:'CAD':'symbol-narrow'}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <div class="text-ellipsis" [title]="totalPartialMargin$ | async | currency:'CAD':'symbol-narrow'">{{ totalPartialMargin$ | async | currency:'CAD':'symbol-narrow'}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="partial_margin_p">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.partial_margin_p">{{columnNames.partial_margin_p}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.partial_margin_p*100 | number:'1.2' | zeroIfBwi:deal + '%'"
              [class.text-red]="(deal.attributes.actual.partial_margin_p|zeroIfBwi:deal) < 0">
            {{deal.attributes.actual.partial_margin_p*100 | number:'1.2' | zeroIfBwi:deal}}%
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="estimated_margin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.estimated_margin">{{columnNames.estimated_margin}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.estimated.margin | currency:'CAD':'symbol-narrow'">
            {{deal.attributes.estimated.margin | currency:'CAD':'symbol-narrow'}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pickup_location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.pickup_location">{{columnNames.pickup_location}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.pickup_location">
          <div class="text-ellipsis" [title]="deal.pickup_location.name">{{deal.pickup_location.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dropoff_location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.dropoff_location">{{columnNames.dropoff_location}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.dropoff_location">
          <div class="text-ellipsis" [title]="deal.dropoff_location.name">{{deal.dropoff_location.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="product_category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.product_category">{{columnNames.product_category}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.product.category.name">{{deal.product.category.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="status_notes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.status_notes">{{columnNames.status_notes}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          class="tc-cell-small tc-cell-editable"
          (click)="deal && updateLogisticsNote($event, deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis"
              [title]="deal.statusNotes"
              (click)="updateLogisticsNote($event, deal)">
            {{deal.statusNotes}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="truck_refer_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.truck_refer_id">{{columnNames.truck_refer_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.truck_refer_id">{{deal.truck_refer_id}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="etd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.etd">{{columnNames.etd}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'earliestVesselSegment.attributes.actual_pickup_date')"
          (click)="inlineEditEtd(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestVesselSegment?.attributes.actual_pickup_date">
          <ng-container *ngTemplateOutlet="deal.earliestVesselSegment.attributes.actual_pickup_date_time ? cellEpoch : cellEpochDate; context: { timestamp: deal.earliestVesselSegment.attributes.actual_pickup_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="eta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.eta">{{columnNames.eta}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'latestVesselSegment.attributes.actual_delivery_date')"
          (click)="inlineEditEta(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.latestVesselSegment?.attributes.actual_delivery_date">
          <ng-container *ngTemplateOutlet="deal.latestVesselSegment.attributes.actual_delivery_date_time ? cellEpoch : cellEpochDate; context: { timestamp: deal.latestVesselSegment.attributes.actual_delivery_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="prepayment_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.prepayment_status">{{columnNames.prepayment_status}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.prepayment_invoice">
          <ng-container *ngTemplateOutlet="cellInvoiceStatus; context: { invoice: deal.prepayment_invoice }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_invoice_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_invoice_status">{{columnNames.supplier_invoice_status}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.supplier_invoice_status">
          <ng-container *ngTemplateOutlet="cellInvoiceStatus; context: { invoice: deal.supplier_invoice_status }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_invoice_paid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_invoice_paid">{{columnNames.supplier_invoice_paid}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.supplier_invoice">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.supplier_invoice.attributes.paid }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_invoice_due">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_invoice_due">{{columnNames.buyer_invoice_due}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.buyer_invoice">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.buyer_invoice.due, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_invoice_paid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_invoice_paid">{{columnNames.buyer_invoice_paid}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.buyer_invoice">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.buyer_invoice.attributes.paid }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="carrier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.carrier">{{columnNames.carrier}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.carrier">
          <div class="text-ellipsis" [title]="deal.carrier.name">{{deal.carrier.name}}</div>
          <small [class.tc-portal-visible]="isDealPortalVisible(deal, deal.carrier.account)"></small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vessel_carrier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.vessel_carrier">{{columnNames.vessel_carrier}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.vessel_carrier">
          <div class="text-ellipsis" [title]="deal.vessel_carrier.name">{{deal.vessel_carrier.name}}</div>
          <small [class.tc-portal-visible]="isDealPortalVisible(deal, deal.supplier_id)"></small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="booking_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.booking_id">{{columnNames.booking_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'earliestVesselOrTruckSegment.booking_id')"
          (click)="inlineBookingIdEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestVesselOrTruckSegment">
          <div class="text-ellipsis" [title]="deal.booking_id">
            {{deal.booking_id}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="container_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.container_number">{{columnNames.container_number}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'earliestVesselOrTruckSegment.attributes.container_number')"
          (click)="inlineContainerIdEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestVesselOrTruckSegment">
          <div class="text-ellipsis" [title]="deal.container_number">
            <a *ngIf="deal.container_number"
                [href]="composeContainerTrackingUrl(deal.container_number)" target="_blank">
              <small><i class="fa fa-fw fa-up-right-from-square"></i></small>
            </a>
            {{deal.container_number}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="credit_balance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.credit_balance">{{columnNames.credit_balance}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.int_cred_avail | currency">
            {{deal.int_cred_avail | currency}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_invoice_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_invoice_id">{{columnNames.supplier_invoice_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.supplier_invoice">
          <div class="text-ellipsis" [title]="deal.supplier_invoice.vendor_invoice_id">
            {{deal.supplier_invoice.vendor_invoice_id}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_invoice_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_invoice_date">{{columnNames.supplier_invoice_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.supplier_invoice">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.supplier_invoice.issued }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_invoice_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_invoice_id">{{columnNames.buyer_invoice_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.buyer_invoice">
          <div class="text-ellipsis" [title]="deal.buyer_invoice.invoice_id">
            {{deal.buyer_invoice.invoice_id}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_invoice_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_invoice_date">{{columnNames.buyer_invoice_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.buyer_invoice">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.buyer_invoice.issued }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="payment_terms_supplier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.payment_terms_supplier">{{columnNames.payment_terms_supplier}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.payment_terms_supplier">
            {{deal.payment_terms_supplier}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="payment_terms_buyer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.payment_terms_buyer">{{columnNames.payment_terms_buyer}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.payment_terms_buyer">
            {{deal.payment_terms_buyer}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="collection_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.collection_date">{{columnNames.collection_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'collection_date')"
          (click)="inlineDealDateEdit(deal, 'collection_date', td, true)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.collection_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="coordinator">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.coordinator">{{columnNames.coordinator}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.coordinator">
          <div class="text-ellipsis" [title]="deal.coordinator.fullname">{{deal.coordinator.fullname}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="invoiced">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.invoiced">{{columnNames.invoiced}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.invoiced')"
              [checked]="!!deal.attributes.extra?.invoiced"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.invoiced')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="inalfresco">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.inalfresco">{{columnNames.inalfresco}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.inalfresco')"
              [checked]="!!deal.attributes.inalfresco"
              (click)="toggleInalfrescoFlag($event, deal)"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="date_invoiced">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.date_invoiced">{{columnNames.date_invoiced}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.invoiced_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="forwarder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.forwarder">{{columnNames.forwarder}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.forwarder">
          <div class="text-ellipsis" [title]="deal.forwarder.name">{{deal.forwarder.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vessel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.vessel">{{columnNames.vessel}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'earliestVesselSegment.attributes.vessel')"
          (click)="inlineSegmentTextEdit(deal, 'earliestVesselSegment.attributes.vessel', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestVesselSegment?.attributes">
          <div class="text-ellipsis" [title]="deal.earliestVesselSegment.attributes.vessel">{{deal.earliestVesselSegment.attributes.vessel}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actual_cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actual_cost">{{columnNames.actual_cost}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.buy_price | currency:deal.attributes.estimated.buy_currency:'symbol-narrow'">
            {{deal.attributes.actual.buy_price | currency:deal.attributes.estimated.buy_currency:'symbol-narrow'}}
          </div>
          <small class="text-gray">{{deal.attributes.estimated.buy_currency}}</small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actual_revenue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actual_revenue">{{columnNames.actual_revenue}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.sell_price | currency:deal.attributes.estimated.sell_currency:'symbol-narrow'">
            {{deal.attributes.actual.sell_price | currency:deal.attributes.estimated.sell_currency:'symbol-narrow'}}
          </div>
          <small class="text-gray">{{deal.attributes.estimated.sell_currency}}</small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="invoiced_amt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.invoiced_amt">{{columnNames.invoiced_amt}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.revenue | currency:'CAD':'symbol-narrow'">
            {{deal.attributes.actual.revenue | currency:'CAD':'symbol-narrow'}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <div class="text-ellipsis" [title]="totalInvoicedAmt$ | async | number">{{ totalInvoicedAmt$ | async | number }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="courier_track_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.courier_track_id">{{columnNames.courier_track_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.courier_track_id?.length">
          <div class="text-ellipsis" [title]="deal.courier_track_nums">
            <ng-container *ngFor="let item of deal.courier_track_id; let index = index" [ngSwitch]="!!item.url">
              <span *ngIf="index > 0">, </span>
              <a *ngSwitchCase="true" [href]="item.url" target="_blank">{{item.number}}</a>
              <span *ngSwitchCase="false">{{item.number}}</span>
            </ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="prepayment_received">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.prepayment_received">{{columnNames.prepayment_received}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis">
            {{ deal.attributes.prepayment_received === true ? 'YES' : deal.attributes.prepayment_received === false ? 'NO' : '' }}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="total_weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.total_weight">{{columnNames.total_weight}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellWeight; context: { weight: deal.total_weight }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <div class="text-ellipsis" *ngIf="totalWeight$ | async as tw" [title]="tw.total | measure:tw.measure_id">{{ tw.total | measure:tw.measure_id }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="estimated_weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.estimated_weight">{{columnNames.estimated_weight}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellWeight; context: { weight: deal.attributes?.estimated?.weight }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="size_of_package">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.size_of_package">{{columnNames.size_of_package}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.packageSize">
          <ng-container *ngTemplateOutlet="cellWeight; context: { weight: deal.packageSize }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="type_of_packages">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.type_of_packages">{{columnNames.type_of_packages}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.packageTypes">
          <div class="text-ellipsis" [title]="deal.packageTypes">{{deal.packageTypes}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="temperature">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.temperature">{{columnNames.temperature}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis"
              title="{{deal.product.temperature}} °{{deal.attributes.temperature_units || 'C'}}">
            {{deal.product.temperature}} °{{deal.attributes.temperature_units || 'C'}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vessel_cutoff_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.vessel_cutoff_date">{{columnNames.vessel_cutoff_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'earliestVesselSegment.attributes.cutoff_datetime')"
          (click)="inlineEditVesselCutoffDate(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestVesselSegment">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.earliestVesselSegment.attributes.cutoff_datetime }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="art_work">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.art_work">{{columnNames.art_work}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.art_work')"
              [checked]="!!deal.attributes.extra?.art_work"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.art_work')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.color">{{columnNames.color}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" class="tc-cell-select-color"
          [matMenuTriggerFor]="colorMenu" [matMenuTriggerData]="{deal: deal}">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <span class="label text-uppercase" [ngStyle]="{ 'background-color': COLORS[deal.attributes.extra?.color] }">
            {{deal.attributes.extra?.color}}
          </span>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="inspection">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.inspection">{{columnNames.inspection}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.inspection')"
              [checked]="!!deal.attributes.extra?.inspection"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.inspection')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="delivered">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.delivered">{{columnNames.delivered}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.extra.delivered')"
          (click)="inlineDealDateEdit(deal, 'attributes.extra.delivered', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.extra?.delivered">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.extra.delivered }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="documents">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.documents">{{columnNames.documents}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.documents')"
              [checked]="!!deal.attributes.extra?.documents"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.documents')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="documents_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.documents_count">{{columnNames.documents_count}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && showDealDocuments(deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal?.documents_count">
          <div class="text-ellipsis" [title]="deal.documents_count">{{deal.documents_count}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.brand">{{columnNames.brand}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" [title]="deal.product.brand">{{deal.product.brand}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="item_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.item_type">{{columnNames.item_type}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'product.item_type')"
          (click)="inlineItemTypeEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product?.item_type">
          <div class="text-ellipsis" [title]="deal.product.item_type.name">{{deal.product.item_type.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_released">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_released">{{columnNames.supplier_released}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.extra.supplier_released')"
          (click)="inlineDealDateEdit(deal, 'attributes.extra.supplier_released', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.extra?.supplier_released">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.extra.supplier_released, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="truck_inspection">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.truck_inspection">{{columnNames.truck_inspection}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.truck_inspection')"
              [checked]="!!deal.attributes.extra?.truck_inspection"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.truck_inspection')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="note_comments_public">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.note_comments_public">{{columnNames.note_comments_public}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.extra.comments')"
          (click)="inlineTextEdit(deal, 'attributes.extra.comments', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.extra?.comments">
          <div class="text-ellipsis" [matTooltip]="deal.attributes.extra.comments">{{deal.attributes.extra.comments}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="rate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.rate">{{columnNames.rate}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestSegment">
          <div class="text-ellipsis" [title]="deal.earliestSegment.attributes.amount | currency:deal.earliestSegment.attributes.currency:'symbol-narrow'">
            {{deal.earliestSegment.attributes.amount | currency:deal.earliestSegment.attributes.currency:'symbol-narrow'}}
          </div>
          <small class="text-gray">{{deal.earliestSegment.attributes.currency}}</small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="status_b">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.status_b">{{columnNames.status_b}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.extra.description_of_goods')"
          (click)="inlineTextEdit(deal, 'attributes.extra.description_of_goods', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.extra?.description_of_goods">
          <div class="text-ellipsis" [matTooltip]="deal.attributes.extra.description_of_goods">{{deal.attributes.extra.description_of_goods}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="e_docs_to_customs_broker">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.e_docs_to_customs_broker">{{columnNames.e_docs_to_customs_broker}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.edocs_to_customs_broker')"
              [checked]="!!deal.attributes.extra?.edocs_to_customs_broker"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.edocs_to_customs_broker')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="original_sent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.original_sent">{{columnNames.original_sent}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.original_sent')"
              [checked]="!!deal.attributes.extra?.original_sent"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.original_sent')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="status_a">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.status_a">{{columnNames.status_a}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.extra.customs_broker')"
          (click)="inlineTextEdit(deal, 'attributes.extra.customs_broker', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.extra?.customs_broker">
          <div class="text-ellipsis" [matTooltip]="deal.attributes.extra.customs_broker">{{deal.attributes.extra.customs_broker}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="loading_instructions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.loading_instructions">{{columnNames.loading_instructions}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.loading_instructions')"
              [checked]="!!deal.attributes.extra?.loading_instructions"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.loading_instructions')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="load_ready">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.load_ready">{{columnNames.load_ready}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.load_ready')"
              [checked]="!!deal.attributes.extra?.load_ready"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.load_ready')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="packages_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.packages_count">{{columnNames.packages_count}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" [title]="deal.product.packages_count | number">{{deal.product.packages_count | number}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <div class="text-ellipsis" [title]="totalPackagesCount$ | async | number">{{ totalPackagesCount$ | async | number }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="voyage_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.voyage_no">{{columnNames.voyage_no}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.shipment">
          <div class="text-ellipsis" [title]="deal.attributes.shipment.voyage_no">{{deal.attributes.shipment.voyage_no}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="epd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.epd">{{columnNames.epd}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.shipment.epd_date')"
          (click)="inlineDealDateEdit(deal, 'attributes.shipment.epd_date', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.shipment">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.shipment.epd_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="erd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.erd">{{columnNames.erd}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.shipment.erd_date')"
          (click)="inlineDealDateEdit(deal, 'attributes.shipment.erd_date', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.shipment">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.shipment.erd_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="bol_instructions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.bol_instructions">{{columnNames.bol_instructions}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <mat-checkbox
              [disabled]="!canEdit(deal, 'attributes.extra.bol_instructions')"
              [checked]="!!deal.attributes.extra?.bol_instructions"
              (click)="toggleSimpleBoolean($event, deal, 'attributes.extra.bol_instructions')"
              ></mat-checkbox>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="port_of_loading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.port_of_loading">{{columnNames.port_of_loading}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.port_of_loading">
          <div class="text-ellipsis" [title]="deal.port_of_loading.name">{{deal.port_of_loading.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="port_of_discharge">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.port_of_discharge">{{columnNames.port_of_discharge}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.port_of_discharge">
          <div class="text-ellipsis" [title]="deal.port_of_discharge.name">{{deal.port_of_discharge.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="exact_loading_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.exact_loading_address">{{columnNames.exact_loading_address}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && pickExactLoadingAddress($event, deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.earliestSegment?.attributes.exact_loading?.address">
          <div class="text-ellipsis" [title]="deal.earliestSegment.attributes.exact_loading.address.name">{{deal.earliestSegment.attributes.exact_loading.address | address:['name', 'city', 'country']}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="exact_dropoff_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.exact_dropoff_address">{{columnNames.exact_dropoff_address}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && pickExactDropoffAddress($event, deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.latestSegment?.attributes.exact_dropoff?.address">
          <div class="text-ellipsis" [title]="deal.latestSegment.attributes.exact_dropoff.address.name">{{deal.latestSegment.attributes.exact_dropoff.address.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="consignee">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.consignee">{{columnNames.consignee}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && pickConsignee($event, deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes?.shipment?.delivery?.consignee?.name">
          <div class="text-ellipsis" [title]="deal.attributes.shipment.delivery.consignee.name">{{deal.attributes.shipment.delivery.consignee.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="us_border">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.us_border">{{columnNames.us_border}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.shipment">
          <div class="text-ellipsis" [title]="deal.attributes.shipment.border_cross">{{deal.attributes.shipment.border_cross}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="hs_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.hs_code">{{columnNames.hs_code}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" [title]="deal.product.hs_code">{{deal.product.hs_code}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="import_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.import_no">{{columnNames.import_no}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'attributes.shipment.import_no')"
          (click)="inlineTextEdit(deal, 'attributes.shipment.import_no', td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes.shipment?.import_no">
          <div class="text-ellipsis" [title]="deal.attributes.shipment.import_no">{{deal.attributes.shipment.import_no}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_estimated_cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_estimated_cost">{{columnNames.supplier_estimated_cost}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.estimated.buy_price | currency:deal.attributes.estimated.buy_currency:'symbol-narrow'">
            {{deal.attributes.estimated.buy_price | currency:deal.attributes.estimated.buy_currency:'symbol-narrow'}}
          </div>
          <small class="text-gray">{{deal.attributes.estimated.buy_currency}}</small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_currency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_currency">{{columnNames.supplier_currency}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.estimated.buy_currency">{{deal.attributes.estimated.buy_currency}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="est_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.est_id">{{columnNames.est_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product">
          <div class="text-ellipsis" [title]="deal.product.establishment_ids?.join(', ')">{{deal.product.establishment_ids?.join(', ')}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="est_pickup_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.est_pickup_date">{{columnNames.est_pickup_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="deal.est_pickup_date_time ? cellEpoch : cellEpochDate; context: { timestamp: deal.est_pickup_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="est_delivery_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.est_delivery_date">{{columnNames.est_delivery_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="deal.est_delivery_date_time ? cellEpoch : cellEpochDate; context: { timestamp: deal.est_delivery_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="submitted_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.submitted_at">{{columnNames.submitted_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.submitted || deal.attributes.submitted_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="confirmed_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.confirmed_at">{{columnNames.confirmed_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.attributes.confirmed_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actual_margin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actual_margin">{{columnNames.actual_margin}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.margin | zeroIfBwi:deal | currency:'CAD':'symbol-narrow'"
              [class.text-red]="(deal.attributes.actual.margin|zeroIfBwi:deal) < 0">
            {{deal.attributes.actual.margin | zeroIfBwi:deal | currency:'CAD':'symbol-narrow'}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actual_margin_p">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actual_margin_p">{{columnNames.actual_margin_p}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.attributes.actual.margin_p*100 | number:'1.2' | zeroIfBwi:deal + '%'"
              [class.text-red]="(deal.attributes.actual.margin_p|zeroIfBwi:deal) < 0">
            {{deal.attributes.actual.margin_p*100 | number:'1.2' | zeroIfBwi:deal}}%
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actual_weight_buy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actual_weight_buy">{{columnNames.actual_weight_buy}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellWeight; context: { weight: deal.attributes.actual.weight }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actual_weight_sell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.actual_weight_sell">{{columnNames.actual_weight_sell}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellWeight; context: { weight: deal.actual_weight_sell }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="gross_weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.gross_weight">{{columnNames.gross_weight}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <ng-container *ngTemplateOutlet="cellWeight; context: { weight: deal.gross_weight }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="seq">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.seq">{{columnNames.seq}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.seq === 0 ? 'original' : deal.seq">{{deal.seq === 0 ? 'original' : deal.seq}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="closingDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.closingDate">{{columnNames.closingDate}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.closingDate">
          <div class="text-ellipsis">
            <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: deal.closingDate }"></ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="estimated_loading_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.estimated_loading_date">{{columnNames.estimated_loading_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes?.shipment_dates.from">
          <div class="text-ellipsis">
            <ng-container *ngTemplateOutlet="cellEpochDate; context: { timestamp: deal.attributes.shipment_dates.from }"></ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deliveryTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deliveryTime">{{columnNames.deliveryTime}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deliveryTime">
          <div class="text-ellipsis">
            <ng-container *ngTemplateOutlet="cellEpochTime; context: { timestamp: deal.deliveryTime }"></ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pickupTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.pickupTime">{{columnNames.pickupTime}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.pickupTime">
          <div class="text-ellipsis">
            <ng-container *ngTemplateOutlet="cellEpochTime; context: { timestamp: deal.pickupTime }"></ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="wrappings">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.wrappings">{{columnNames.wrappings}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.wrappings">
          <div class="text-ellipsis" [title]="deal.wrappings">{{deal.wrappings}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="prepayment_percent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.prepayment_percent">{{columnNames.prepayment_percent}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes?.buyer_payment_terms?.prepayment_percent">
          <div class="text-ellipsis" [title]="deal.attributes.buyer_payment_terms.prepayment_percent + '%'">{{deal.attributes.buyer_payment_terms.prepayment_percent}} %</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="shipper">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.shipper">{{columnNames.shipper}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && pickShipper($event, deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.attributes?.shipment?.shipper?.name">
          <div class="text-ellipsis" [title]="deal.attributes.shipment.shipper.name">{{deal.attributes.shipment.shipper.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_te_sent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_te_sent">{{columnNames.deringer_te_sent}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deringer?.te_file">
          <div class="text-ellipsis tc-link-like" (click)="downloadFile($event, deal.deringer.te_file.file_id)">
            <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.deringer.te_file.te_sent }"></ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_data_received">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_data_received">{{columnNames.deringer_data_received}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.integration?.deringer">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.integration?.deringer.data_received }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_pdf_received">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_pdf_received">{{columnNames.deringer_pdf_received}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deringer?.pdf_received">
          <div class="text-ellipsis tc-link-like" (click)="downloadFile($event, deal.deringer.file_id)">
            <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.deringer.pdf_received }"></ng-container>
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_pdf_bonds">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_pdf_bonds">{{columnNames.deringer_pdf_bonds}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deringer?.bonds?.total">
          <div class="text-ellipsis tc-link-like" (click)="showDeringerDocuments(deal)">
            {{ deal.deringer.bonds.total }}
          </div>
          <small class="label text-uppercase"
              [class.label-danger]="deal.deringer.bonds.status === 'rejected'"
              [class.label-success]="deal.deringer.bonds.status === 'approved' && deal.deringer.bonds.total === 1"
              [class.label-success2]="deal.deringer.bonds.status === 'approved' && deal.deringer.bonds.total > 1"
              [class.label-default]="deal.deringer.bonds.status === 'received'"
            >{{ deal.deringer.bonds.status }}
          </small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <div class="text-ellipsis" [title]="totalDeringerBonds$ | async">{{ totalDeringerBonds$ | async}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deringer_approved_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_approved_at">{{columnNames.deringer_approved_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deringer">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.deringer.approved_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_approved_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_approved_by">{{columnNames.deringer_approved_by}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deringer?.approved_by">
          <div class="text-ellipsis" [title]="deal.deringer.approved_by.full_name">{{deal.deringer.approved_by.full_name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_notes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_notes">{{columnNames.deringer_notes}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.deringer?.notes_added">
          <button mat-icon-button title="Bond Notes" (click)="showDeringerNotes(deal)">
            <mat-icon class="fa fa-note-sticky"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="deringer_note_warning">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_note_warning">{{columnNames.deringer_note_warning}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container>
          <button mat-icon-button type="button" title="Notes"
              (click)="showDeringerWarningNotes(deal)"
              [matTooltip]="deal?.newest_deringer_warning_note?.body ? (deal?.newest_deringer_warning_note?.created | epoch) + ': ' + deal?.newest_deringer_warning_note?.body : ''"
              >
            <mat-icon class="fa"
              [class.fa-note-sticky]="!deal?.newest_deringer_warning_note"
              [class.fa-note]="deal?.newest_deringer_warning_note"
              [class.text-info]="deal?.newest_deringer_warning_note"
            ></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="note_warning">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.note_warning">{{columnNames.note_warning}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button type="button" [matTooltip]="noteTooltipContent(deal)" matTooltipClass="pre-tooltip huge-tooltip"
            (click)="showWarningNotes(deal)">
            <mat-icon class="fa" [class.fa-note-sticky]="!highlightNoteIcon(deal)" [class.fa-note]="highlightNoteIcon(deal)"
              [class.text-info]="highlightNoteIcon(deal)"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="aes_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.aes_number">{{columnNames.aes_number}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="deal">
          <mat-icon matTooltip="No AES Number" *ngIf="!deal.attributes.shipment?.aes" class="fa-light fa-location-crosshairs-slash no-aes"></mat-icon>
          <ng-container *ngIf="!deal?.export_reports?.[0]?.responses">
            <div class="text-ellipsis" [title]="deal.attributes.shipment?.aes">{{deal.attributes.shipment?.aes}}</div>
          </ng-container>
          <ng-container *ngIf="deal?.active_export_report_response">
            <a class="text-ellipsis modal-action"
              (click)="$event.stopPropagation();$event.preventDefault();openAESResponseModal(deal)"
              [title]="deal.attributes.shipment?.aes"
            >{{deal.attributes.shipment?.aes}}</a>
            <a class="modal-action"
              (click)="$event.stopPropagation();$event.preventDefault();openAESResponseModal(deal)">
              <small *ngIf="deal?.active_export_report_response?.status === 'not-read'" class="label label-danger text-uppercase">Unread</small>
              <small *ngIf="deal?.active_export_report_response?.status === 'read'" class="label label-warning text-uppercase">Read</small>
              <small *ngIf="deal?.active_export_report_response?.status === 'handled'" class="label label-default text-uppercase">Handled</small>
            </a>
          </ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="int_cred_avail">
      <th mat-header-cell *matHeaderCellDef [title]="columnNames.int_cred_avail">{{columnNames.int_cred_avail}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" [title]="deal.int_cred_avail | currency:'CAD':'symbol-narrow'">
            {{deal.int_cred_avail | currency:'CAD':'symbol-narrow'}}
          </div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="aes_responses_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.aes_responses_count">{{columnNames.aes_responses_count}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" (click)="deal && rowClick.emit({deal: deal})">
        <mat-icon class="fa-light fa-location-crosshairs-slash no-aes"
          *ngIf="deal?.export_reports?.[0]?.requests?.length && !deal?.export_reports?.[0]?.responses?.length"
          matTooltip="No AES Responses received">
        </mat-icon>
        <ng-container *ngIf="!deal"><div class="skeleton-loader thin-line"></div></ng-container>
        <ng-container *ngIf="deal?.export_reports?.[0]?.responses?.length">
          <a class="text-ellipsis modal-action"
            (click)="$event.stopPropagation();$event.preventDefault();openAESResponseModal(deal)"
            [title]="deal?.export_reports?.[0]?.responses?.length"
            > {{deal?.export_reports?.[0]?.responses?.length}}
          </a>
          <a class="modal-action"
            (click)="$event.stopPropagation();$event.preventDefault();openAESResponseModal(deal)">
            <small *ngIf="deal?.active_export_report_response?.status === 'not-read'" class="label label-danger text-uppercase">Unread</small>
            <small *ngIf="deal?.active_export_report_response?.status === 'read'" class="label label-warning text-uppercase">Read</small>
            <small *ngIf="deal?.active_export_report_response?.status === 'handled'" class="label label-default text-uppercase">Handled</small>
          </a>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="aes_request_sent_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.aes_request_sent_at">{{columnNames.aes_request_sent_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.export_reports">
          <a class="text-ellipsis" (click)="$event.stopPropagation();$event.preventDefault();openAESRequestModal(deal)">
            <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.active_export_report_request?.created_at }"></ng-container>
          </a>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_sent_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_sent_at">{{columnNames.montship_sent_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_sent_at">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_sent_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_confirmed_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_confirmed_at">{{columnNames.montship_confirmed_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_confirmed_at">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_confirmed_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_status_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_status_at">{{columnNames.montship_status_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_status_at">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_status_at }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_count">{{columnNames.montship_count}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_count">
          <div class="text-ellipsis tc-link-like" (click)="showBookingReview(deal)">
            {{ deal.booking.montship_count }}
          </div>
          <small class="label text-uppercase"
              [class.label-warning]="deal.booking.montship_status === 'created'"
              [class.label-danger]="deal.booking.montship_status === 'rejected'"
              [class.label-success]="deal.booking.montship_status === 'approved' && deal.booking.montship_count === 1"
              [class.label-success2]="deal.booking.montship_status === 'approved' && deal.booking.montship_count > 1"
              [class.label-default]="deal.booking.montship_status === 'sent'"
              [class.label-info]="deal.booking.montship_status === 'confirmed'"
            >{{ deal.booking.montship_status }}
          </small>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_etd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_etd">{{columnNames.montship_etd}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_etd">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_etd, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_eta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_eta">{{columnNames.montship_eta}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_eta">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_eta, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_pickup_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_pickup_date">{{columnNames.montship_pickup_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_pickup_date">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_pickup_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_dropoff_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_dropoff_date">{{columnNames.montship_dropoff_date}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_dropoff_date">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.booking.montship_dropoff_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montship_exact_loading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.montship_exact_loading">{{columnNames.montship_exact_loading}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.booking?.montship_exact_loading">
          <div class="text-ellipsis" [title]="deal.booking.montship_exact_loading.name">{{deal.booking.montship_exact_loading.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="macropoint_tracking_request_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.macropoint_tracking_request_id">{{columnNames.macropoint_tracking_request_id}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <mat-icon matTooltip="No Macropoint order" *ngIf="!deal.macropoint_order?.tracking_request_id" class="fa-light fa-location-crosshairs-slash"></mat-icon>
        <a class="text-ellipsis clickable"
          *ngIf="deal?.macropoint_order"
          (click)="showMacropointOrderDetails(deal)"
          [title]="deal.macropoint_order?.tracking_request_id"
        >{{deal.macropoint_order?.tracking_request_id}}</a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="macropoint_order_status_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.macropoint_order_status_name">{{columnNames.macropoint_order_status_name}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <mat-icon matTooltip="No Macropoint order status" *ngIf="!deal.macropoint_order?.macropoint_status_code" class="fa-light fa-location-crosshairs-slash"></mat-icon>
        <span *ngIf="deal.macropoint_order" [title]="MacropointStatusDescription[deal.macropoint_order?.macropoint_status_code]?.statusDescription">{{MacropointStatusDescription[deal.macropoint_order?.macropoint_status_code]?.statusMessage}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="macropoint_order_latest_location_full_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.macropoint_order_latest_location_full_address">{{columnNames.macropoint_order_latest_location_full_address}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <mat-icon matTooltip="No Macropoint order" *ngIf="!deal.macropoint_latest_address" class="fa-light fa-location-crosshairs-slash"></mat-icon>
        <span *ngIf="deal?.macropoint_order" [title]="deal.macropoint_latest_address">{{deal.macropoint_latest_address}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="macropoint_request_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.macropoint_request_count">{{columnNames.macropoint_request_count}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <mat-icon matTooltip="No Macropoint order requests logged" *ngIf="!deal.macropoint_order?.macropoint_request_count" class="fa-light fa-location-crosshairs-slash"></mat-icon>
        <span *ngIf="deal.macropoint_order?.macropoint_request_count" [title]="deal.macropoint_order?.macropoint_request_count">{{deal.macropoint_order?.macropoint_request_count}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="last_macropoint_request_sent_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.last_macropoint_request_sent_at">{{columnNames.last_macropoint_request_sent_at}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource">
        <mat-icon matTooltip="No Macropoint order request date" *ngIf="!deal.macropoint_order?.last_macropoint_request_sent_at" class="fa-light fa-location-crosshairs-slash"></mat-icon>
        <a class="text-ellipsis clickable" (click)="$event.stopPropagation();$event.preventDefault();showMacropointOrderAuditLog(deal)">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: deal.macropoint_order?.last_macropoint_request_sent_at }"></ng-container>
        </a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="buyer_incoterm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_incoterm">{{columnNames.buyer_incoterm}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product?.buyer_incoterm">
          <div class="text-ellipsis" [title]="deal.product.buyer_incoterm.term">{{deal.product.buyer_incoterm.term}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="buyer_incoterm_location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.buyer_incoterm_location">{{columnNames.buyer_incoterm_location}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product?.buyer_incoterm_location">
          <div class="text-ellipsis" [title]="deal.product.buyer_incoterm_location.name">{{deal.product.buyer_incoterm_location.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_incoterm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_incoterm">{{columnNames.supplier_incoterm}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product?.supplier_incoterm">
          <div class="text-ellipsis" [title]="deal.product.supplier_incoterm.term">{{deal.product.supplier_incoterm.term}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="supplier_incoterm_location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.supplier_incoterm_location">{{columnNames.supplier_incoterm_location}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && pickExactLoadingAddress($event, deal)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal?.product?.supplier_incoterm_location">
          <div class="text-ellipsis" [title]="deal.product.supplier_incoterm_location.name">{{deal.product.supplier_incoterm_location.name}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="tradersMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button type="button" (click)="editColumns.next()">
          <mat-icon class="fas fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button type="button"
              [matMenuTriggerFor]="tradersMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="montshipMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button type="button" (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button
              [matMenuTriggerFor]="montshipMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="aesMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button
              [matMenuTriggerFor]="aesMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="macropointMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button
              [matMenuTriggerFor]="macropointMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>



    <ng-container matColumnDef="logisticsMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button type="button" (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button type="button"
              [matMenuTriggerFor]="logisticsMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="tradersShippingLogMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button type="button" (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button type="button"
              [matMenuTriggerFor]="tradersShippingLogMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="clonesMenu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button type="button"
              [matMenuTriggerFor]="clonesMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="deringerMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <button mat-icon-button
              [matMenuTriggerFor]="deringerMenu"
              [matMenuTriggerData]="{deal: deal}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="freight_rates_amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.freight_rates_amount">{{columnNames.freight_rates_amount}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource"
          (click)="deal && rowClick.emit({deal: deal})">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" title="{{deal.freight_rates_amount?.amount | currency: deal.freight_rates_amount?.currency}}">{{deal.freight_rates_amount?.amount | currency: deal.freight_rates_amount?.currency}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="foreign_invoice_number_mx">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.foreign_invoice_number_mx">{{columnNames.foreign_invoice_number_mx}}</th>
      <td mat-cell *matCellDef="let deal; dataSource:dataSource" #td
          [class.tc-cell-editable]="deal && canEdit(deal, 'mexicanInvoiceNo')"
          (click)="inlineMexInvNoEdit(deal, td)">
        <ng-container *ngIf="!deal">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="deal">
          <div class="text-ellipsis" title="{{deal?.foreign_invoice_number_mx?.number}}">{{deal?.foreign_invoice_number_mx?.number}}</div>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>
<mat-paginator #paginator></mat-paginator>

<mat-menu #tradersMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item
        (click)="openDealForm(deal)">
      <i class="fa fa-fw fa-edit"></i>
      Edit
    </button>
    <button mat-menu-item
            *ngIf="canUnwindDeal(deal)"
            (click)="unwindDeal(deal)">
      <i class="fa fa-fw fa-undo"></i>
      Unwind Deal
    </button>
    <button mat-menu-item
        *ngIf="canCreateSupplierOffer(deal)"
        (click)="createSupplierOffer(deal)">
      <i class="fa fa-fw fa-gift" aria-hidden="true"></i>
      Create Supplier Offer
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="previewDealDocuments(deal, DealPartyE.supplier)">
      <i class="fa fa-fw fa-file-pdf"></i>
      Preview Supplier Documents
    </button>
    <button mat-menu-item
        *ngIf="deal.isClone()"
        [disabled]="!canPreviewAllClones(deal, DealPartyE.supplier)"
        (click)="previewAllClonesDocuments(deal, DealPartyE.supplier)">
      <i class="fa fa-fw fa-file-pdf"></i>
      Preview all Supplier Clone Documents
    </button>
    <button mat-menu-item
        (click)="previewDealDocuments(deal, DealPartyE.buyer)">
      <i class="fa fa-fw fa-file-pdf"></i>
      Preview Buyer Documents
    </button>
    <button mat-menu-item
        *ngIf="deal.isClone()"
        [disabled]="!canPreviewAllClones(deal, DealPartyE.buyer)"
        (click)="previewAllClonesDocuments(deal, DealPartyE.buyer)">
      <i class="fa fa-fw fa-file-pdf"></i>
      Preview all Buyer Clone Documents
    </button>
    <button mat-menu-item
        (click)="sendConfirmation(deal, DealPartyE.supplier)">
      <i class="fa fa-fw fa-paper-plane"></i>
      {{sendConfirmationLabel(deal, DealPartyE.supplier)}}
    </button>
    <button mat-menu-item
        (click)="sendConfirmation(deal, DealPartyE.buyer)">
      <i class="fa fa-fw fa-paper-plane"></i>
      {{sendConfirmationLabel(deal, DealPartyE.buyer)}}
    </button>
    <button mat-menu-item
        *ngIf="canConfirm(deal, DealPartyE.supplier)"
        (click)="confirmDealParty(deal, DealPartyE.supplier)">
      <i class="fa fa-fw fa-square-check"></i>
      Confirm Purchase
    </button>
    <button mat-menu-item
        *ngIf="canConfirm(deal, DealPartyE.buyer)"
        (click)="confirmDealParty(deal, DealPartyE.buyer)">
      <i class="fa fa-fw fa-square-check"></i>
      Confirm Sale
    </button>
    <button mat-menu-item
        *ngIf="deal.can_create_prepayment"
        (click)="requestPrepayment(deal)">
      <i class="fa fa-fw fa-square-check"></i>
      Request Prepayment
    </button>
    <button mat-menu-item
        (click)="showMessagesOf(deal)">
      <i class="fa fa-fw fa-comments"></i>
      Messages
    </button>
    <button mat-menu-item
        (click)="showDealNotes(deal)">
      <i class="fa fa-fw fa-pencil"></i>
      Notes
    </button>
    <button mat-menu-item
        (click)="showDealDocuments(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Documents
    </button>
    <button mat-menu-item
        (click)="openDocumentsTracking(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Track Documents
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="showDealDetails(deal)">
      <i class="fa fa-fw fa-chevron-circle-right"></i>
      Deal Details
    </button>
    <button mat-menu-item
        (click)="showShipmentDetails(deal)">
      <i class="fa fa-fw fa-truck"></i>
      Shipment Details
    </button>
    <button mat-menu-item
        (click)="showFinanceDetails(deal)">
      <i class="fa fa-fw fa-money-bill-1"></i>
      Finance Details
    </button>
    <button mat-menu-item
        (click)="showCompanyCredit(deal)">
      <i class="fa fa-fw fa-money-bill-1"></i>
      Check Credit
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item disabled>
      <i class="fa fa-fw fa-bell"></i>
      Notifications
    </button>
  </ng-template>
</mat-menu>

<mat-menu #logisticsMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item
        (click)="openDealForm(deal)">
      <i class="fa fa-fw fa-edit"></i>
      Edit
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        *ngFor="let party of deal.parties"
        (click)="toggleVendorPortal(deal, party.account)">
      <i class="fa fa-fw"
          [class.fa-square-check]="isDealPortalVisible(deal, party.account)"
          [class.fa-square]="!isDealPortalVisible(deal, party.account)"
          ></i>
      {{party.name}}
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        *ngIf="deal.status === 'Confirmed'"
        (click)="startWorkingOn(deal)">
      <i class="fa fa-fw fa-chevron-circle-right"></i>
      Start Working
    </button>
    <button mat-menu-item disabled>
      <i class="fa fa-fw fa-ship"></i>
      Track Container
    </button>
    <button mat-menu-item disabled>
      <i class="fa fa-fw fa-ship"></i>
      Track Vessel
    </button>
    <button mat-menu-item
        *ngIf="deal.can_create_prepayment"
        (click)="requestPrepayment(deal)">
      <i class="fa fa-fw fa-square-check"></i>
      Request Prepayment
    </button>
    <button mat-menu-item
            (click)="createAESExportReport(deal)">
      <i class="fa fa-square-a"></i> &nbsp;AES
    </button>
    <button mat-menu-item
         *ngIf="enableMacropoint && (!deal.macropoint_order?.tracking_request_id || deal.macropoint_order?.stopped)"
        (click)="createMacropointOrder(deal)">
    <i class="fa fa-route"></i> &nbsp;Request Macropoint Order
    </button>
    <button mat-menu-item
        *ngIf="enableMacropoint && deal.macropoint_order?.tracking_request_id && !deal.macropoint_order?.stopped"
      (click)="updateMacropointOrder(deal)">
    <i class="fa fa-refresh"></i> &nbsp;Send Updates to Macropoint
    </button>
    <button mat-menu-item
        *ngIf="enableMacropoint && deal.macropoint_order?.tracking_request_id && !deal.macropoint_order?.stopped"
      (click)="stopMacropointOrder(deal)">
    <i class="fa fa-square"></i> &nbsp;Stop Macropoint Order
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="showTradingDetails(deal)">
      <i class="fa fa-fw fa-chevron-circle-right"></i>
      Deal Details
    </button>
    <button mat-menu-item
        (click)="showProductsDetails(deal)">
      <i class="fa fa-fw fa-chevron-circle-right"></i>
      Product Details
    </button>
    <button mat-menu-item
        (click)="showShippingDetails(deal)">
      <i class="fa fa-fw fa-truck"></i>
      Shipment Details
    </button>
    <button mat-menu-item
        (click)="showFinanceDetails(deal)">
      <i class="fa fa-fw fa-money-bill-1"></i>
      Finance Details
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="showMessagesOf(deal)">
      <i class="fa fa-fw fa-comments"></i>
      Messages
    </button>
    <button mat-menu-item
        (click)="showDealNotes(deal)">
      <i class="fa fa-fw fa-pencil"></i>
      Notes
    </button>
    <button mat-menu-item
        (click)="showDealDocuments(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Documents
    </button>
    <button mat-menu-item
        (click)="openDocumentsTracking(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Track Documents
    </button>
    <button mat-menu-item
        (click)="uploadInvoice(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Upload Invoice
    </button>
    <button mat-menu-item
        (click)="showCompanyCredit(deal)">
      <i class="fa fa-fw fa-money-bill-1"></i>
      Check Credit
    </button>
    <button mat-menu-item disabled>
      <i class="fa fa-fw fa-bell"></i>
      Notifications
    </button>
  </ng-template>
</mat-menu>

<mat-menu #tradersShippingLogMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item
        (click)="showMessagesOf(deal)">
      <i class="fa fa-fw fa-comments"></i>
      Messages
    </button>
    <button mat-menu-item
        (click)="showDealNotes(deal)">
      <i class="fa fa-fw fa-pencil"></i>
      Notes
    </button>
    <button mat-menu-item
        (click)="showDealDocuments(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Documents
    </button>
    <button mat-menu-item
        (click)="openDocumentsTracking(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Track Documents
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="showTradingDetails(deal)">
      <i class="fa fa-fw fa-chevron-circle-right"></i>
      Deal Details
    </button>
    <button mat-menu-item
        (click)="showShipmentDetails(deal)">
      <i class="fa fa-fw fa-truck"></i>
      Shipment Details
    </button>
    <button mat-menu-item
        (click)="showFinanceDetails(deal)">
      <i class="fa fa-fw fa-money-bill-1"></i>
      Finance Details
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="openDealForm(deal)">
      <i class="fa fa-fw fa-edit"></i>
      Edit Deal
    </button>
    <button mat-menu-item
        (click)="showCompanyCredit(deal)">
      <i class="fa fa-fw fa-money-bill-1"></i>
      Check Credit
    </button>
    <button mat-menu-item disabled>
      <i class="fa fa-fw fa-ship"></i>
      Track Container
    </button>
    <button mat-menu-item disabled>
      <i class="fa fa-fw fa-ship"></i>
      Track Vessel
    </button>
  </ng-template>
</mat-menu>


<mat-menu #clonesMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item
        (click)="showCopyClone(deal)">
      <i class="fa fa-fw fa-clone"></i>
      Copy Clone
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        *ngFor="let party of deal.parties"
        (click)="toggleVendorPortal(deal, party.account)">
      <i class="fa fa-fw"
          [class.fa-square-check]="isDealPortalVisible(deal, party.account)"
          [class.fa-square]="!isDealPortalVisible(deal, party.account)"
          ></i>
      {{party.name}}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #deringerMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item (click)="openDealForm(deal)">
      <i class="fa fa-fw fa-edit"></i>
      Edit
    </button>
    <button mat-menu-item (click)="showDeringerDocuments(deal)">
      <i class="fa fa-fw fa-file-lines"></i>
      Documents
    </button>
    <mat-divider *ngIf="deal.deringer.bonds.total"></mat-divider>
    <button mat-menu-item *ngIf="deal.deringer.bonds.total && deal.deringer.bonds.status !== 'approved'" (click)="approveDeringer(deal)">
      <i class="fa fa-fw fa-check"></i>
      Approve
    </button>
    <button mat-menu-item *ngIf="deal.deringer.bonds.total && deal.deringer.bonds.status !== 'rejected'" (click)="rejectDeringer(deal)">
      <i class="fa fa-fw fa-times"></i>
      Reject
    </button>
  </ng-template>
</mat-menu>

<mat-menu #montshipMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item (click)="showMontshipRejectionNotes(deal)" [disabled]="deal.booking.status !== 'rejected'">
      <i class="fa fa-fw fa-note-sticky"></i>
      Show Montship rejection notes
    </button>
  </ng-template>
</mat-menu>

<mat-menu #aesMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item (click)="showAesCancellationNotes(deal)" [disabled]="!hasAesCancellation(deal)">
      <i class="fa fa-fw fa-note-sticky"></i>
      Show AES cancellation notes
    </button>
  </ng-template>
</mat-menu>

<mat-menu #macropointMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item
      *ngIf="enableMacropoint && deal.macropoint_order?.tracking_request_id && !deal.macropoint_order.stopped"
      (click)="stopMacropointOrder(deal)">
      <i class="fa fa-square"></i> &nbsp;Stop Macropoint Order
    </button>
    <button mat-menu-item
        *ngIf="enableMacropoint && deal.macropoint_order?.tracking_request_id && !deal.macropoint_order.stopped"
        (click)="updateMacropointOrder(deal)">
        <i class="fa fa-refresh"></i> &nbsp;Send Updates to Macropoint
    </button>
  </ng-template>
</mat-menu>

<ng-template #cellInvoiceStatus let-invoice="invoice">
  <div class="text-ellipsis">
    <span *ngIf="invoice.status === -1" class="label label-danger text-uppercase">Denied</span>
    <span *ngIf="invoice.status === 0" class="label label-default text-uppercase">In Review</span>
    <span *ngIf="invoice.status === 1" class="label label-success text-uppercase">Approved</span>
    <span *ngIf="invoice.status === 2" class="label label-warning text-uppercase">Needs Info</span>
    <span *ngIf="invoice.status === 3" class="label label-info text-uppercase">Actualized</span>
    <span *ngIf="invoice.status === 4" class="label label-success text-uppercase">Scheduled</span>
    <!-- <span *ngIf="invoice.status === 5 && invoice.attributes.prepayment_applied_to" class="label label-primary text-uppercase">Applied</span>
    <span *ngIf="invoice.status === 5 && !invoice.attributes.prepayment_applied_to" class="label label-primary text-uppercase">Paid</span> -->
    <span *ngIf="invoice.status === 5" class="label label-primary text-uppercase">{{invoice.name || 'Paid'}}</span>
  </div>
</ng-template>

<ng-template #cellEpochDate let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
</ng-template>

<ng-template #cellEpoch let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp">
    <div class="text-ellipsis" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
    <small class="text-ellipsis text-gray" [title]="timestamp | epoch:'LT':useUtc">{{timestamp | epoch:'LT':useUtc}}</small>
  </div>
</ng-template>

<ng-template #cellEpochTime let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp" [title]="timestamp | epoch:'hh:mma':useUtc">{{timestamp | epoch:'hh:mma':useUtc}}</div>
</ng-template>

<ng-template #cellWeight let-weight="weight">
  <ng-container *ngIf="weight?.amount">
    <div class="text-ellipsis" [title]="weight.amount | number">{{weight.amount | number}}</div>
    <small class="text-gray">{{weight.amount | measure:weight.measure_id}}</small>
  </ng-container>
</ng-template>

<mat-menu #colorMenu="matMenu" color="primary">
  <ng-template matMenuContent let-deal="deal">
    <button mat-menu-item
        *ngFor="let color of COLOR_OPTIONS"
        (click)="setColor(deal, color.id)">
      <i class="fa fa-fw fa-circle" [ngStyle]="{color: color.color}"></i> {{color.name}}
    </button>
  </ng-template>
</mat-menu>

<tc-inline-editor #inlineEditor></tc-inline-editor>
