import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AccountType, DealViewRaw, FileObject, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { ReplaySubject, from, merge } from 'rxjs'
import { filter, map, switchMap, take, tap } from 'rxjs/operators'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { approveDeringerPdfSuccess, rejectDeringerPdfSuccess } from 'src/app/store/deal-view.actions'
import { loadProducts } from 'src/app/store/products'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { FilesListComponent } from 'src/components/files-list/files-list.component'
import { NoteFormService } from 'src/components/notes/note-form/note-form.service'
import { UploaderDialogService } from 'src/components/uploader-dialog/uploader-dialog.service'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { DealsService } from 'src/services/data/deals.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { DealFilesDataSource } from 'src/services/table-utils/data-sources/deal-files-data-source'
import { TypedFormGroup } from 'src/shared/utils/typed-forms'
import { CreateDocumentFormService } from '../../create-document/create-document-form.service'
import { FilesService } from '../files.service'


export interface DocumentsOverlayOptions {
  dealId: string
  canCreate?: boolean
  canUpload?: boolean
  showDeringerCols?: boolean
  isReadonly?: boolean
}

@Component({
  selector: 'tc-documents-overlay',
  templateUrl: './documents-overlay.component.html',
  styleUrls: ['./documents-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsOverlayComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private DealDocuments: DealDocumentsService,
    private UploaderDialog: UploaderDialogService,
    private CreateDocumentForm: CreateDocumentFormService,
    private Files: FilesService,
    private store: Store,
    private actions$: Actions,
    private NoteForm: NoteFormService,
    private Deals: DealsService,
    @Inject(MAT_DIALOG_DATA) private dialogData: DocumentsOverlayOptions,
  ) { super() }

  readonly displayColumns = [
    'fileName',
    'created',
    'createdBy.fullname',
    'attributes.document_type',
    'company.name',
    'size',
    'format.name',
    'deringer.status',
    'hasNotes',
    ...(this.dialogData.showDeringerCols
      ? ['statusAt', 'statusBy']
      : []),
  ]
  readonly dealId = this.dialogData.dealId
  readonly isReadonly = this.dialogData.isReadonly
  readonly canCreate = this.dialogData.canCreate
  readonly canUpload = this.dialogData.canUpload

  readonly dealViewRaw$ = new ReplaySubject<DealViewRaw>(1)

  readonly documentsPartyType = [
    { id: 'supplier', name: 'Supplier' },
    { id: 'buyer', name: 'Buyer' },
    { id: 'service_provider', name: 'Service Provider' },
    { id: 'other', name: 'Other' },
  ]
  documentsFilterForm = new UntypedFormGroup({
    party: new UntypedFormControl(),
  }) as TypedFormGroup<{ party: Array<AccountType | 'other'> }>

  filesDataSource = new DealFilesDataSource(this.dealViewRaw$,
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    this.store.pipe(select(selectUserEntities), waitNotEmpty()),
    this.store.pipe(select(selectCarrierEntities), waitNotEmpty()),
    this.Files, this.documentsFilterForm)
  readonly filesTableKey = TableKey.DealDocuments

  // inner components
  @ViewChild(FilesListComponent) set filesList(v: FilesListComponent) { if (v) this.filesList$.next(v) }
  private filesList$ = new ReplaySubject<FilesListComponent>(1)

  canNotMergeDocuments$ = this.filesList$.pipe(switchMap(fl => fl.selection.selectedIds$), map(ids => !ids?.length))

  ngOnInit() {
    merge(
      this.actions$.pipe(ofType(approveDeringerPdfSuccess, rejectDeringerPdfSuccess)),
      this.filesDataSource.refresh$,
    ).pipe(
      switchMap(() => this.fetchDealFiles()),
      untilComponentDestroyed(this),
    ).subscribe()
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadUsers({}))

    this.Deals.getDealView(this.dealId).pipe(untilComponentDestroyed(this)).subscribe(dv => this.dealViewRaw$.next(dv))
  }

  showCreateDocument() {
    from(this.CreateDocumentForm.showCreateDocument(this.dealId)).pipe(
      switchMap(() => this.fetchDealFilesAndInvoices()),
    ).subscribe()
  }

  showUploadDocument() {
    this.store.dispatch(loadProducts({}))
    this.dealViewRaw$.pipe(
      take(1),
      switchMap(dv => this.UploaderDialog.showUploader({ dv, title: 'Upload Document' })),
      switchMap(() => this.fetchDealFilesAndInvoices()),
    ).subscribe()
  }

  showAddFileNote(file: DeepReadonly<FileObject>) {
    this.dealViewRaw$.pipe(
      take(1),
      switchMap(dealViewRaw =>
        this.NoteForm.createDealNote(dealViewRaw, {
          attributes: {
            category: file.file_id,
          },
          visibility: 1,
        })),
      tap(note => {
        if (note) this.filesDataSource.refresh()
      }),
    ).subscribe()
  }

  mergeToPDF() {
    this.filesList$.pipe(take(1)).subscribe(fl => {
      fl.selectedRows$.pipe(
        take(1),
        filter(files => !!files.length),
        switchMap(files => from(this.DealDocuments.mergePdf(this.dealId, files))),
        tap(file => {
          // download file
          this.Files.download(file)
          // clear selection
          fl.selection.selectedIds.clear()
          fl.selection.selectedIds$.next([])
        }),
        switchMap(() => this.fetchDealFiles()),
      ).subscribe()
    })
  }


  private fetchDealFiles() {
    return this.Deals.refetchDealParts(this.dealId, ['files'], this.dealViewRaw$)
  }

  private fetchDealFilesAndInvoices() {
    return this.Deals.refetchDealParts(this.dealId, ['files'], this.dealViewRaw$)
  }
}
