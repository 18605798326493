import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AccountObject, DealPaymentTerms, PaymentReference, PaymentTermsDate, PaymentTermsDates, User } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { identity } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { GenericOption, selectBrokerageCustomerOptions } from 'src/app/store/accounts';
import { selectCurrencyCodes } from 'src/app/store/currencies';
import { selectPaymentReferenceEntities, selectPaymentReferencesOptions } from 'src/app/store/payment-references';
import { selectFirstTraderOption, selectTradersOptions, selectUserEntities } from 'src/app/store/users';
import { getPartyUsers } from 'src/services/data/accounts.service';
import { CreditPoolService } from 'src/services/data/credit-pool.service';
import { getDesignatedContacts } from 'src/services/data/users.service';
import { replayForm } from 'src/shared/utils/replay-form';
import { selectOptions } from 'src/shared/utils/select-options';
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty';
import { DealDetailsFormGroup } from '../../deal-form/deal-form-page/deal-form.schema';

@Component({
  selector: 'tc-brokerage-info-form',
  templateUrl: './brokerage-info-form.component.html',
  styleUrls: ['./brokerage-info-form.component.scss'],
})
export class BrokerageInfoFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private store: Store,
    private CreditPool: CreditPoolService,
  ) { super() }

  @Input() detailsForm: DealDetailsFormGroup
  @Output() change = new EventEmitter<void>()

  protected PaymentTermsDates = PaymentTermsDates
  protected accounts$: Observable<DeepReadonly<AccountObject[]>>
  protected currencies$ = this.store.pipe(select(selectCurrencyCodes), waitNotEmpty())
  protected traders$: Observable<GenericOption[]>
  protected brokerageUsers$: Observable<DeepReadonly<User[]>>
  protected paymentReferences$: Observable<DeepReadonly<PaymentReference[]>>

  protected readonly termsForm = new FormGroup({
    days: new FormControl<number>(undefined, [Validators.required, Validators.min(0)]),
    from_date: new FormControl<PaymentTermsDate>(undefined, [Validators.required]),
    reference_id: new FormControl<string>(undefined),
  })

  ngOnInit(): void {
    this.accounts$ = selectOptions(this.store, selectBrokerageCustomerOptions, this.detailsForm.controls.brokerageCustomer)
    this.traders$ = selectOptions(this.store, selectTradersOptions, this.detailsForm.controls.brokerageTraderId)
    this.brokerageUsers$ = combineLatest([
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
      replayForm(this.detailsForm.controls.brokerageCustomer),
    ]).pipe(map(([users, account]) => getPartyUsers(users, account)))
    this.paymentReferences$ = selectOptions(this.store, selectPaymentReferencesOptions, this.termsForm.controls.reference_id)

    replayForm<DealPaymentTerms>(this.detailsForm.controls.brokeragePaymentTerms).pipe(untilComponentDestroyed(this)).subscribe((brokeragePaymentTerms) => {
      const termsForm = this.termsForm.getRawValue()
      if (termsForm.days !== brokeragePaymentTerms?.days) {
        this.termsForm.controls.days.setValue(brokeragePaymentTerms?.days)
      }
      if (termsForm.from_date !== brokeragePaymentTerms?.from_date) {
        this.termsForm.controls.from_date.setValue(brokeragePaymentTerms?.from_date)
      }
      if (termsForm.reference_id !== brokeragePaymentTerms?.reference_id) {
        this.termsForm.controls.reference_id.setValue(brokeragePaymentTerms?.reference_id)
      }
    })

    replayForm(this.detailsForm).pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.detailsForm.controls.brokeragePaymentTerms.enabled && !this.termsForm.enabled) this.termsForm.enable()
      else if (this.detailsForm.controls.brokeragePaymentTerms.disabled && !this.termsForm.disabled) this.termsForm.disable()
    })
  }

  async customerChanged(customer: DeepReadonly<AccountObject>) {
    const creditPool = await this.CreditPool.getFor(customer.account)
    const paymentTerms = await this.CreditPool.readPaymentTerms(creditPool)
    this.detailsForm.controls.brokeragePaymentTerms.setValue(paymentTerms)
    this.detailsForm.controls.brokerageCurrency.setValue(creditPool?.currency.code)
    this.termsForm.controls.from_date.setValue(paymentTerms.from_date)
    this.termsForm.controls.days.setValue(paymentTerms.days)
    this.termsForm.controls.reference_id.setValue(paymentTerms.reference_id)
    combineLatest([
      this.store.pipe(select(selectFirstTraderOption), filter(identity)),
      this.store.pipe(select(selectTradersOptions()), waitNotEmpty()),
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
    ]).pipe(take(1)).subscribe(([firstTraderId, traderOptions, users]) => {
      const primaryTrader = traderOptions.find(to => to.id === customer.manager)
        ? customer.manager
        : customer.managers?.find(managerId => traderOptions.find(to => to.id === managerId))
      const partyUsers = getPartyUsers(users, customer.account)
      const partyContacts = getDesignatedContacts(partyUsers).map(u => u.user_id)
      this.detailsForm.controls.brokerageTraderId.setValue(primaryTrader || firstTraderId)
      this.detailsForm.controls.brokerageContactUserIds.setValue(partyContacts)
    })
  }

  termsChanged() {
    const termsForm = this.termsForm.getRawValue()
    const paymentTerms = this.detailsForm.controls.brokeragePaymentTerms.getRawValue()
    this.store.pipe(select(selectPaymentReferenceEntities), waitNotEmpty(), take(1)).subscribe(prefs => {
      this.detailsForm.controls.brokeragePaymentTerms.setValue({
        ...paymentTerms,
        days: termsForm.days,
        from_date: termsForm.from_date,
        reference_id: termsForm.reference_id,
        reference_name: prefs[termsForm.reference_id]?.reference,
      })
      this.detailsForm.controls.brokeragePaymentTerms.markAsDirty()
      this.detailsForm.controls.brokeragePaymentTerms.markAsTouched()
    })
  }

  estAmountChanged() {
    const brokerageEstAmount = this.detailsForm.controls.brokerageEstAmount.value
    this.detailsForm.controls.brokerageActAmount.setValue(brokerageEstAmount)
    this.change.next()
  }
}
