<form [formGroup]="addressForm" (submit)="submit()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    {{isNew ? 'Add New Address' : 'Update Address'}}
  </h2>
  <div mat-dialog-content>
    <div gdColumns="repeat(5, minmax(0, 1fr))" gdGap="10px">
      <mat-form-field gdColumn="span 5">
        <mat-label>Address name</mat-label>
        <input matInput autocomplete="off" formControlName="name">
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="primary">
        Set as primary
      </mat-checkbox>
      <mat-checkbox color="primary" formControlName="consignee">
        Set as consignee
      </mat-checkbox>
      <mat-checkbox color="primary" formControlName="def_delivery">
        Set as default {{options.company.type === 'supplier' ? 'pickup' : 'delivery'}}
      </mat-checkbox>
      <mat-checkbox color="primary" formControlName="inalfresco"
          *ngIf="isTradecafe" (change)="onInalfrescoChanged($event)">
        INALFRESCO
      </mat-checkbox>
      <mat-checkbox color="primary" formControlName="aes_address"
          *ngIf="isTradecafe" (change)="onAESDesignationChanged($event)">
        Use for AES
      </mat-checkbox>
    </div>
    <div gdColumns="repeat(4, minmax(0, 1fr))" gdGap="10px">
      <tc-select-search gdColumn="span 2" placeholder="Department #"
          [group]="addressForm" ctrlName="department"
          [items]="departments$ | async" bindValue="department_id" bindLabel="name"
          clearable="true"
          ></tc-select-search>
      <mat-form-field gdColumn="span 2">
        <mat-label>Establishment #</mat-label>
        <input matInput autocomplete="off" formControlName="establishment">
      </mat-form-field>
      <mat-form-field gdColumn="span 2">
        <mat-label>Street address 1</mat-label>
        <input matInput autocomplete="off" formControlName="street1">
      </mat-form-field>
      <mat-form-field gdColumn="span 2">
        <mat-label>Street address 2</mat-label>
        <input matInput autocomplete="off" formControlName="street2">
      </mat-form-field>
      <mat-form-field gdColumn="span 2">
        <mat-label>City</mat-label>
        <input matInput autocomplete="off" formControlName="city"
            [matAutocomplete]="cities">
        <mat-autocomplete autoActiveFirstOption #cities="matAutocomplete">
          <mat-option *ngFor="let city of cities$ | async" [value]="city.name">{{city.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <tc-select-search gdColumn="span 2" placeholder="State/Province"
          [group]="addressForm" ctrlName="state"
          (change)="onStateChange()"
          [items]="states$ | async" bindValue="name" bindLabel="name" bindHint="shortCode"
          clearable="true"
          ></tc-select-search>
      <tc-select-search gdColumn="span 2" placeholder="Country"
          [group]="addressForm" ctrlName="cc"
          (change)="onCountryChange($event)"
          [items]="countries$ | async" bindValue="code" bindLabel="name" bindHint="code"
          clearable="true"
          ></tc-select-search>
      <mat-form-field gdColumn="span 2">
        <mat-label>Zip code</mat-label>
        <input matInput autocomplete="off" formControlName="postal">
      </mat-form-field>
      <div gdColumn="span 2" gdColumns="3fr 1fr" gdGap="10px">
        <mat-form-field>
          <mat-label>Telephone # 1</mat-label>
          <input matInput autocomplete="off" formControlName="telephone1">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ext</mat-label>
          <input matInput autocomplete="off" formControlName="telephone1_ext">
        </mat-form-field>
      </div>
      <mat-form-field gdColumn="span 2">
        <mat-label>Email</mat-label>
        <input matInput type="email" tc-email-pattern autocomplete="off" formControlName="email">
      </mat-form-field>
      <div gdColumn="span 2" gdColumns="minmax(0, 3fr) minmax(0, 1fr)">
        <mat-form-field>
          <mat-label>Telephone # 2</mat-label>
          <input matInput autocomplete="off" formControlName="telephone2">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ext</mat-label>
          <input matInput autocomplete="off" formControlName="telephone2_ext">
        </mat-form-field>
      </div>
      <div gdColumn="span 2" gdColumns="minmax(0, 3fr) minmax(0, 1fr)">
        <mat-form-field>
          <mat-label>Fax</mat-label>
          <input matInput autocomplete="off" formControlName="fax">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ext</mat-label>
          <input matInput autocomplete="off" formControlName="fax_ext">
        </mat-form-field>
      </div>
      <mat-form-field gdColumn="span 2">
        <mat-label>Tax ID #</mat-label>
        <input matInput autocomplete="off" formControlName="tax_id">
      </mat-form-field>
      <mat-form-field gdColumn="span 2">
        <mat-label>Note</mat-label>
        <textarea matInput class="form-control" formControlName="note" rows="1"></textarea>
      </mat-form-field>
      <mat-form-field gdColumn="span 2">
        <mat-label>Montship code</mat-label>
        <input matInput autocomplete="off" formControlName="montshipCode">
      </mat-form-field>
      <mat-form-field gdColumn="span 2">
        <mat-label>Contact name</mat-label>
        <input matInput autocomplete="off" formControlName="contact_name">
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">Save</button>
  </div>
</form>
