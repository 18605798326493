import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { combineLatest, Observable, Subject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { replayForm } from 'src/shared/utils/replay-form'
import { TypedFormGroup } from 'src/shared/utils/typed-forms'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from './api-table-data-source'
import { StatefulDataSource } from './stateful-data-source'


export class SimpleDataSource<T, F = any> extends StatefulDataSource<T> {

  constructor (
    private source$: Observable<T[]>,
    private filtersForm?: TypedFormGroup<F>,
    filterFn?: (rows: T[], filters: F) => T[],
  ) {
    super()
    const data$ = this.filtersForm && filterFn
      ? combineLatest([this.source$, replayForm(this.filtersForm)])
        .pipe(map(([data, filters]) => data && filterFn(data, filters)))
      : source$
    this.dataSubscription = data$.subscribe(data => {
      if (data) this.data = data
    })
  }

  dataSubscription: Subscription
  refresh$ = new Subject<void>()

  setPaginator(paginator: MatPaginator) {
    if (!paginator || paginator === this.paginator) return
    paginator.pageSizeOptions = PAGE_SIZE_OPTIONS
    paginator.showFirstLastButtons = true
    paginator.pageSize = DEFAULT_PAGE_SIZE
    this.paginator = paginator
  }

  setSort(sort: MatSort) {
    if (!sort || sort === this.sort) return
    if (this.filtersForm?.value['sort']) {
      sort.sort(this.filtersForm.value['sort'])
    }
    this.sort = sort
  }

  disconnect() {
    this.dataSubscription.unsubscribe()
  }

  refresh() {
    this.refresh$.next()
  }
}
