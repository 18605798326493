<h2 mat-dialog-title>
    <mat-icon class="fa fa-truck"></mat-icon>
    Inventory Deals
</h2>

<form class="tc-table-page-filters"
    [formGroup]="filtersForm" (submit)="applyFilters()"
    gdColumns="repeat(3, minmax(0, 1fr))" gdGap="10px">
  <mat-form-field [class.tc-text-empty]="!filtersForm.get('text').value">
    <mat-label>Search</mat-label>
    <button mat-icon-button matPrefix>
      <mat-icon class="fa fa-search"></mat-icon>
    </button>
    <input matInput formControlName="text" autocomplete="text">
    <button mat-icon-button class="tc-text-clear"
        [disabled]="filtersForm.get('text').disabled"
        (click)="filtersForm.get('text').reset()">
      <mat-icon class="fa fa-times"></mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field [class.tc-text-empty]="!filtersForm.get('deal_id').value">
    <mat-label>Deal #</mat-label>
    <button mat-icon-button matPrefix>
      <mat-icon class="fa fa-search"></mat-icon>
    </button>
    <input matInput formControlName="deal_id" autocomplete="deal_id">
    <button mat-icon-button class="tc-text-clear"
        [disabled]="filtersForm.get('deal_id').disabled"
        (click)="filtersForm.get('deal_id').reset()">
      <mat-icon class="fa fa-times"></mat-icon>
    </button>
  </mat-form-field>

  <tc-select-search placeholder="Trader" [multiple]="true"
      [group]="filtersForm" ctrlName="selling_trader"
      (openedChange)="!$event && applyFilters()"
      [items]="selling_trader$ | async"
      bindValue="id"
      bindLabel="name"
      ></tc-select-search>

  <tc-select-search placeholder="Supplier" [multiple]="true"
      [group]="filtersForm" ctrlName="supplier"
      (openedChange)="!$event && applyFilters()"
      [items]="supplier$ | async"
      bindValue="id"
      bindLabel="name"
      ></tc-select-search>
</form>

<tc-deals-list
    (dealIdClick)= "dealIdClick($event)"
    [selectAll]="false"
    [selectOne]="true"
    [dataSource]="dataSource"
    [filtersForm]="filtersForm"
    [filtersData]="filtersData$ | async"
    [totals$]="totals$"
    [availableColumns]="availableColumns"
    [columnNames]="columnNames"
    [displayColumns]="displayColumns$ | async"
    (applyFilters)="applyFilters()"
    (selectionChange)="dealSelectionChange($event)"
    [tableIdentity]="tableIdentity">
</tc-deals-list>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button (click)="openDeal()" [disabled]="!dealsList?.selection.selectedIds.size" color="primary">Create Supplier Offer</button>
</div>
