import { Injectable } from '@angular/core'
import { cloneDeep, filter, forEach, get, intersection, isEqual, map, omit, pick, pickBy, reduce, some } from 'lodash-es'
import { extendUnixRange } from 'src/directives/epoch-range/epoch-range.utils'

// TODO: refactor; there is no need to use DI for utility functions
@Injectable()
export class QueryService {

  /**
   * Emulating back end pagination, sort, filter and search
   *
   * @private
   * @param {any} data
   * @param {any} query
   * @returns and array with requested data
   */
  applyQuery<T extends object>(input: T[], query?, or?: any[]): { data: T[], total_rows: number } {
    query = cloneDeep(query)
    if (!query) return { data: input, total_rows: input.length }

    let { /* limit, skip, sorts, */filters } = query
    if (or) {
      const orKeys = reduce(or, (res, item) => res.concat(Object.keys(item)), [])
      filters = pickBy(filters, (_value, key) => orKeys.indexOf(key) < 0)
    }
    const search = filters.search?.toLowerCase() || ''
    delete filters.search

    // search
    let data = filter(input, (item) => {
      const cleanItem = omit(item, ['buyer.creditPool', 'clones'])
      return JSON.stringify(cleanItem).toLowerCase().includes(search)
      // const [yes] = ngFilter([item], { $: search })
      // return !!yes
    })


    // filter
    data = data.filter(doc =>
      Object.keys(filters)
        .filter(fieldName =>
          // filter should have value.
          (filters[fieldName] || filters[fieldName] === 0) &&
          // if it is an array, it should not be empty
          (!Array.isArray(filters[fieldName]) || filters[fieldName].length))
        .every((fieldName) => {
          let actual
          const expected = filters[fieldName]

          actual = get(doc, fieldName)

          if (fieldName === 'created') {
            const messageSentAt = get(doc, 'message_sent_at')
            if (messageSentAt) {
              actual = Number(messageSentAt)
            }
          }

          if (!Array.isArray(expected)) return this.match(expected, actual)
          if (Array.isArray(actual)) return intersection(expected, actual).length
          return expected.some(value => this.match(value, actual))
        }))

    // OR filter
    if (or) {
      data = data.filter(doc =>
        or.every(orItems => {
          const actualFilters = Object.keys(orItems)
            .filter(fieldName =>
              // filter should have value.
              (orItems[fieldName] || orItems[fieldName] === 0) &&
              // if it is an array, it should not be empty
              (!Array.isArray(orItems[fieldName]) || orItems[fieldName].length))

          return actualFilters.length === 0 || actualFilters.some((fieldName) => {
            const expected = orItems[fieldName]
            const actual = get(doc, fieldName)

            if (!Array.isArray(expected)) return this.match(expected, actual)
            if (Array.isArray(actual)) return intersection(expected, actual).length
            return expected.some(value => this.match(value, actual))
          })
        }),
      )
    }

    // NOTE: we let ui grid to sort and paginate data
    // // sort
    // data = orderBy(data,
    //   Object.keys(sorts),
    //   Object.keys(sorts).map(key => sorts[key]))
    // // paginate
    // data = data.slice(skip, skip + limit)
    return {
      data,
      // TODO: align field name with back-end API. should be the same
      total_rows: input.length,
    }
  }

  hasFilteringEnabled(filters, defaultFilters = {}) {
    return some(filters, (value, key) => {
      if (defaultFilters[key]) return !isEqual(value, defaultFilters[key])
      if (Array.isArray(value)) return value.length
      else if (this.isUnixDateRange(value)) return true
      else if (this.isMomentDateRange(value)) return true
      else if (typeof value === 'object') return false
      return !!value
    })
  }

  clearFilters(filters, defaultFilters = {}) {
    return forEach(filters, (value, key) => {
      if (defaultFilters[key]) filters[key] = cloneDeep(defaultFilters[key])
      else if (Array.isArray(value)) filters[key] = []
      else if (this.isUnixDateRange(value)) delete filters[key]
      else if (typeof value === 'string') filters[key] = ''
      else filters[key] = undefined
    })
  }

  hasGridFilteringEnabled(filters) {
    return some(filters, (value) => {
      if (Array.isArray(value.value)) return value.value.length
      else if (this.isUnixDateRange(value.value)) return true
      else if (this.isMomentDateRange(value.value)) return true
      else if (typeof value.value === 'object') return false
      return !!value.value
    })
  }

  clearGridFilters(filters) {
    return forEach(filters, (value) => {
      if (Array.isArray(value.value)) value.value = [] // .splice(0, value.value.length)
      else if (this.isUnixDateRange(value.value)) value.value = {}
      else if (this.isMomentDateRange(value.value)) value.value = {}
      else if (typeof value.value === 'string') value.value = ''
      else if (typeof value.value !== 'object') value.value = undefined
    })
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private match(a, b) {
    if (a && (this.isUnixDateRange(a) || typeof a.tbd !== 'undefined')) { // date range
      if (typeof a.from === 'undefined' && typeof a.from === 'undefined') return true
      if (!b) return false
      a = {...a}
      if (a.from === a.to) {
        [a.from, a.to] = extendUnixRange(a, a.useUtc)
      }
      if (a.from > b || a.to < b) return false
      return true
    }

    // NOTE: use '==' for safety sake
    if (typeof a === typeof b) return a == b // tslint:disable-line: triple-equals
    return `${a}` === `${b}`
  }

  private isUnixDateRange(dr) {
    return typeof dr === 'object' && (map(pick(dr, ['from', 'to'])).length)
  }

  private isMomentDateRange(dr) {
    return typeof dr === 'object' && (map(pick(dr, ['dateStart', 'dateEnd'])).length)
  }
}
