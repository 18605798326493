import { Injectable } from '@angular/core'
import { Bid, CreditNote, DealBase, DealPartyE, DealView, DealViewFiles, isCreditNoteApproved, isVendorCreditApproved, Offer, Shipment, VendorCredit } from '@tradecafe/types/core'
import { DealStatusEx, DeepReadonly, findBuyerInvoice, findSupplierInvoice, getCostByProduct, lookupSegments, partial, printFormulaLong, printPaymentTerms } from '@tradecafe/types/utils'
import { compact, difference, filter, find, first, flatten, get, isEqual, keyBy, map, pick, sumBy, uniq, uniqBy } from 'lodash-es'
import { ShipmentRateApiService } from 'src/api/shipment-routing/shipment-rate'
import { environment } from 'src/environments/environment'
import { PackageTrackingService } from 'src/pages/admin/logistics/shipping-logs/detail/overlays/package-tracking/package-tracking.service'
import { CountriesService } from 'src/pages/admin/settings/admin-setting-geographic/countries.service'
import { PricingTermsService } from 'src/pages/admin/settings/admin-setting-payments/pricing-terms/pricing-terms.service'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { TrackingProvidersService } from 'src/pages/admin/settings/tracking-providers/tracking-providers.service'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { DealCalculatorService } from 'src/services/data/deal-calculator.service'
import { DealViewService } from 'src/services/data/deal-view.service'
import { DealsService } from 'src/services/data/deals.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { AccountsService } from './accounts.service'
import { BidsService } from './bids.service'
import { CostsService } from './costs.service'
import { CreditNotesService } from './credit-notes.service'
import { CreditPoolService } from './credit-pool.service'
import { NotesService } from './notes.service'
import { OffersService } from './offers.service'
import { SegmentsService } from './segments.service'
import { ShipmentsService } from './shipments.service'
import { UsersService } from './users.service'
import { VendorCreditsService } from './vendor-credits.service'


/**
 * DealView Loader service
 *
 * We use this service for both Deals List and Shipping Log pages.
 * and we need more data for Shipping log, so we send more requests,
 * and it takes more time
 * TODO: optimize in GraphQL way, so "client" should specify response fields explicitly
 *
 * @export
 */
@Injectable()
export class DealViewLoaderService {
  constructor(
    private Products: ProductsService,
    private Offers: OffersService,
    private Bids: BidsService,
    private DealViewSrvc: DealViewService,
    private Users: UsersService,
    private Locations: LocationsService,
    private Invoices: InvoicesService,
    private Costs: CostsService,
    private Shipments: ShipmentsService,
    private Segments: SegmentsService,
    private Measures: MeasuresService,
    private Carriers: CarriersService,
    private CreditPool: CreditPoolService,
    private Accounts: AccountsService,
    private PackageTracking: PackageTrackingService,
    private CreditNotes: CreditNotesService,
    private DealCalculator: DealCalculatorService,
    private Deals: DealsService,
    private toaster: ToasterService,
    private PricingTerms: PricingTermsService,
    private Notes: NotesService,
    private Files: FilesService,
    private VendorCredits: VendorCreditsService,
    private TrackingProviders: TrackingProvidersService,
    private ShipmentRateApi: ShipmentRateApiService,
    private Countries: CountriesService,
  ) {}

  async loadView(deal_id, parts?, options?) {
    parts = parts || [
      'costs',
      'credit-notes',
      'vendor-credits',
      'invoices',
      'segments:dynamic',
      'editable',
    ]
    options = options || {
      filterVoidedCreditNotes: true,
    }
    try {
      const deal = await this.getDealWith(deal_id, parts, options)
      return deal
    } catch (err) {
      console.error('Unable to load deal data.', err)
      this.toaster.error('Unable to load deal data.', err)
      throw err
    }
  }

  async getDealWith(deal_id, parts?, options?) {
    const rawDeal = await this.Deals.getById(deal_id)
    const [dealView] = await this.fetchProductsFor([this.DealViewSrvc.createDealView(rawDeal)])
    if (!dealView) throw new Error('Deal is invalid')
    await this.fetchPartsFor([dealView], parts, options)
    return dealView
  }

  async searchByDealIdWith(deal_id, parts?, options?) {
    const [rawDeal] = await this.Deals.searchByDealId({deal_id})
    const [dealView] = await this.fetchProductsFor([this.DealViewSrvc.createDealView(rawDeal)])
    if (!dealView) throw new Error('Deal is invalid')
    await this.fetchPartsFor([dealView], parts, options)
    return dealView
  }

  async fetchDealsWith(deal_ids: string[], parts?) {
    let rawDeals = await this.Deals.searchByDealId({ deal_ids })
    const dealViews = await this.fetchProductsFor(rawDeals.map(this.DealViewSrvc.createDealView))
    await this.fetchPartsFor(dealViews, parts)
    return dealViews
  }

  async fetchPartsFor(dealViews: DealView[], parts = [], options: any = {}) {
    if (!dealViews.length || !parts.length) return
    const queue = []
    if (parts.includes('clones')) queue.push(this.fetchClonesFor(dealViews))
    if (parts.includes('costs')) queue.push(this.fetchCostsFor(dealViews))
    if (parts.includes('credit-notes')) queue.push(this.fetchCreditNotesFor(dealViews, options.filterVoidedCreditNotes))
    if (parts.includes('vendor-credits')) queue.push(this.fetchVendorCreditsFor(dealViews))
    if (parts.includes('invoices')) queue.push(this.fetchInvoicesFor(dealViews))
    if (parts.includes('locations')) queue.push(this.fetchLocationsFor(dealViews))
    if (parts.includes('segments:dynamic')) queue.push(this.fetchDynamicSegmentsFor(dealViews))
    if (parts.includes('segments')) queue.push(this.fetchStaticSegmentsFor(dealViews))
    if (parts.includes('users')) queue.push(this.fetchUsersFor(dealViews))
    if (parts.includes('notes')) queue.push(this.fetchNotesFor(dealViews))
    if (parts.includes('files')) queue.push(this.fetchFilesFor(dealViews))
    await Promise.all(queue)
    // if (parts.includes('rates')) queue.push(fetchFreightRatesFor(dealViews))
    if (parts.includes('row')) queue.push(this.fetchRowInfoFor(dealViews))
    if (parts.includes('editable')) queue.push(this.fetchEditableInfoFor(dealViews))
    await Promise.all(queue)
  }

  private async fetchClonesFor(dealViews) {
    const existingDealsById = keyBy(dealViews, 'deal_id')
    const cloned = filter(dealViews, deal => deal.isClone())
    const cloneIds = uniq(compact(flatten(map(cloned, 'attributes.clone_ids'))))
    const missingIds = difference(cloneIds, map(dealViews, 'deal_id'))
    if (missingIds.length) {
      const missing = await this.Deals.getDealsByIds(missingIds)
      Object.assign(existingDealsById, missing)
    }
    cloned.forEach((dealView) => {
      dealView.clones = compact(map(dealView.attributes.clone_ids, cloneId =>
        existingDealsById[cloneId]))
    })
  }

  /**
   * Create deal view with bids and offers combined in deal.products
   *
   * @private
   * @param {*} deals
   * @returns
   */
  private async fetchProductsFor(dealViews: DealView[]) {
    const dealIds = uniq(compact(map(dealViews, 'deal_id')))
    const [offersByDealId, bidsByDealId, products, measures, pricingTerms] = await Promise.all([
      this.Offers.getByDealIds(dealIds),
      this.Bids.getByDealIds(dealIds),
      this.Products.getByIds(),
      // NOTE: we load measures and pricing terms only for validation
      this.Measures.getMeasuresByIds(),
      this.PricingTerms.getPricingTerms().then(r => keyBy(r, 'pricing_terms_id')),
    ])

    return compact(map(dealViews, (dealView) => {
      // respect products order
      const offers: Offer[] = compact(dealView.raw.offers.map(offer_id =>
        find(offersByDealId[dealView.deal_id], {offer_id})))
      const bids: Bid[] = compact(dealView.raw.bids.map(bid_id =>
        find(bidsByDealId[dealView.deal_id], {bid_id})))

      // skip invalid deals
      if (!this.validateDeal(dealView.raw, offers, bids, {measures, pricingTerms, products})) {
        return undefined
      }

      // create deal view
      this.DealViewSrvc.populateProducts(dealView, offers, bids, products)
      dealView.numberOfPackages = sumBy(dealView.products, 'packages_count')

      return dealView
    }))
  }

  /**
   * Fetch information required specificaly for displaying inside a ui-grid
   * TODO: refactor, break onto pieces
   *
   * @param {*} deals
   */
  private async fetchRowInfoFor(deals: DealView[]) {
    const accountIds = uniq(compact(map([
      environment.tradecafeAccount,
      ...map(deals, 'supplier_id'),
      ...map(deals, 'buyer_id'),
    ], x => parseFloat(x as any as string))))
    const buyerIds = uniq(compact(map(map(deals, 'buyer_id'), id => parseFloat(id as any as string))))
    const dealIds = uniq(compact(map(deals, 'deal_id')))

    const [accounts, measures, creditPools, trackings, trackingProviders, pricingTerms, locations, countries] = await Promise.all([
      this.Accounts.getAccountsByIds(accountIds),
      this.Measures.getMeasuresByIds(),
      this.CreditPool.getForAccounts(buyerIds),
      this.PackageTracking.getPackagesByDealIds(dealIds),
      this.TrackingProviders.getTrackingProviders().then(r => keyBy(r, 'tracking_providers_id')),
      this.PricingTerms.getPricingTerms().then(r => keyBy(r, 'pricing_terms_id')),
      this.Locations.getLocationsByIds(),
      this.Countries.getCountriesByCode(),
    ])

    deals.forEach((deal) => {
      (deal as any).originCountry = countries[deal.attributes.origin_country]?.name
      deal.supplierPaymentTerms = printPaymentTerms(deal.attributes.supplier_payment_terms)
      deal.buyerPaymentTerms = printPaymentTerms(deal.attributes.buyer_payment_terms)
      deal.actualNetWeightBuy = deal.attributes.actual.weight
      deal.actualNetWeightSell = deal.attributes.actual.weight && {
        amount: this.Measures.convert(
          deal.attributes.actual.weight.amount,
          deal.attributes.actual.weight.measure_id,
          get(first(deal.products), 'buyer.measure_id')),
        measure_id: get(first(deal.products), 'buyer.measure_id'),
      }
      if (get(deal, 'attributes.actual.weight.amount')) {
        deal.totalWeight = deal.attributes.actual.weight
      } else if (get(deal, 'attributes.estimated.weight.amount')) {
        deal.totalWeight = deal.attributes.estimated.weight
      }
      deal.totalGrossWeight = this.totalDealWeight(deal, 'buyer', 'gross_weight')
      deal.hsCodes = uniq(compact(map(deal.products, 'hs_code'))).join()
      this.DealViewSrvc.setSupplierView(deal, accounts[deal.supplier_id])

      const buyerInvoices = filter(deal.invoices, {account: '' + deal.buyer_id/* , type: 'receivable' */})
      deal.buyer = {
        ...accounts[deal.buyer_id],
        invoices: buyerInvoices,
        invoice: findBuyerInvoice(deal.invoices, deal.buyer_id),
        creditPool: creditPools[deal.buyer_id],
      }
      deal.buyerInvoiceId = get(deal, 'buyer.invoice.vendor_invoice_id') || get(deal, 'buyer.invoice.invoice_id') || ''

      // we agreed to display top product by default (we also display csv in some columns)
      const [dealProduct] = deal.products // take first

      // grid specific, readonly fields
      deal.product = {
        product_id: dealProduct.product_id,
        product: dealProduct.name,
        quantity: dealProduct.packages_count,
        amount: dealProduct.supplier.weight,
        unit: measures[dealProduct.supplier.measure_id],
        brand: dealProduct.brand,
        buy_price: { // estimated
          amount: dealProduct.supplier.price,
          currency: dealProduct.supplier.currency_code,
          measure: measures[dealProduct.supplier.measure_id],
          measure_id: dealProduct.supplier.measure_id,
          formula: printFormulaLong(deal, DealPartyE.supplier, { measures, pricingTerms, locations}),
        },
        sell_price: { // estimated
          amount: dealProduct.buyer.price,
          currency: dealProduct.buyer.currency_code,
          measure: measures[dealProduct.buyer.measure_id],
          measure_id: dealProduct.buyer.measure_id,
          formula: printFormulaLong(deal, DealPartyE.buyer, { measures, pricingTerms, locations}),
        },
      }

      const dealTrackings = trackings[deal.deal_id]
      deal.courierTrackingNums = uniq(compact(map(dealTrackings, 'tracking_number'))).join(', ')
      deal.courierTrackings = dealTrackings && uniqBy(map(dealTrackings, (item) => {
        item.carrierInfo = trackingProviders[item.carrier]
        item.tracking_link = get(item, 'carrierInfo.url', '').replace('{track_number}', item.tracking_number)
        return item
      }), item => (item.carrier + ' ' + item.tracking_number))

      deal.establishments = uniq(compact(map(flatten(map(deal.products, 'supplier.establishments')), 'establishment'))).join('; ')

      if (deal.segments) {
        deal.statusEx = DealStatusEx.reduce((res, {id, check}) => {
          if (check(deal)) res.push(id)
          return res
        }, [])
      }
    })
  }

  /**
   * Fetch information required for Deal Form or Shipping Details page. So for editing
   *
   * - set `deal.supplier.invoices`
   * - set `deal.supplier.invoice` if available
   * - set `deal.buyer.invoices`
   * - set `deal.buyer.invoice` if available
   * - set `deal.costs[].invoice` if available
   * - refresh `deal.costs[].credit_notes_ex`
   * - DOES CALCULATIONS (this must be configurable)
   *
   * TODO: refactor, break onto pieces
   *
   * @param {*} deals
   */
  private async fetchEditableInfoFor(deals: DealView[]) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))

    const [accounts, measures, products] = await Promise.all([
      this.Accounts.getAccountsByIds(),
      this.Measures.getMeasuresByIds(),
      this.Products.getByIds(),
      Promise.all(dealIds.map(async dealId => ({
        [dealId]: await this.PackageTracking.getPackagesByDeal(dealId),
      }))).then(r => r.reduce(Object.assign, {})),
    ])

    deals.forEach((deal) => {
      deal.costs?.forEach((cost) => {
        Object.defineProperty(cost, 'invoice', {
          get: () => find(deal.invoices, {invoice_id: get(cost, 'attributes.associated.invoice_id')}),
        })
        // NOTE: data integrity fix. (also move to BE).
        // NOTE: sometimes *_ex fields are out of sync.
        const costCredit = (credit: DeepReadonly<CreditNote|VendorCredit>) => ({
          credit_note_id: credit.credit_note_id,
          amount: credit.amount,
          currency: credit.currency,
          date: credit.created,
        })
        cost.attributes.credit_notes_ex = deal.credit_notes
          ?.filter(c => c.cost_id === cost.cost_id && isCreditNoteApproved(c))
          ?.map(costCredit)
        cost.attributes.vendor_credits_ex = deal.vendor_credits
          ?.filter(vc => vc.cost_id === cost.cost_id && isVendorCreditApproved(vc) && !vc.attributes?.prepayment)
          ?.map(costCredit)
      })
      this.DealViewSrvc.setSupplierView(deal, accounts[deal.supplier_id])
      this.DealViewSrvc.setBuyerView(deal, accounts[deal.buyer_id])

      // invoices stuff. should be optional, not required for Deals List and Deal Form
      const supplierInvoices = filter(deal.invoices, {account: '' + deal.supplier_id /* , type: 'payable' */})
      deal.supplier = Object.assign({}, deal.supplier, {
        invoices: supplierInvoices,
        invoice: findSupplierInvoice(deal.invoices, deal.supplier_id),
      })
    })

    await Promise.all(map(filter(deals, 'costs'), deal =>
      this.DealCalculator.doCalculations(deal, {measures, products})))
  }

  private async fetchUsersFor(deals: DealView[]) {
    const users = await this.Users.getUsersByIds(environment.tradecafeAccount)

    deals.forEach((deal) => {
      deal.supplier_user = users[deal.supplier_user_id] // TODO: use deal.attributes.supplier_user_ids[]
      deal.buyer_user = users[deal.buyer_user_id] // TODO: use deal.attributes.buyer_user_ids[]
      deal.trader_user = users[deal.trader_user_id]
      deal.trader_user_supplier = users[deal.trader_user_id_supplier]
      deal.logistics_user = users[deal.logistics_user_id]
    })
  }

  private async fetchLocationsFor(deals: DealView[]) {
    const locations = await this.Locations.getLocationsByIds()

    deals.forEach((deal) => {
      if (!locations[deal.origin_location] || !locations[deal.dest_location]) {
        console.log(`deal ${deal.deal_id} invalid: deal refers to not existing location objects`)
        console.debug(`deal ${deal.deal_id} invalid: deal refers to not existing location objects`, deal)
      }
    })

    deals.forEach((deal) => {
      deal.origin = locations[deal.origin_location]
      deal.dest = locations[deal.dest_location]
    })
  }

  private async fetchCostsFor(deals: DealView[]) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))
    const [costs] = await Promise.all([
      this.Costs.getByDealIds(dealIds),
    ])

    deals.forEach((deal) => {
      deal.costs = costs[deal.deal_id] || []
      deal.products.forEach((dealProduct, product_index) => {
        const cost = getCostByProduct(deal, dealProduct)
        if (!cost) {
          console.debug(`WA-2889: ${deal.deal_id} product #${product_index} (${dealProduct.name}) has no primary cost`)
        } else {
          const {supplier, buyer} = dealProduct
          supplier.actual_weight = partial(supplier.actual_weight, cost.attributes.actual_buy_weight)
          buyer.actual_weight = partial(buyer.actual_weight, cost.attributes.actual_sell_weight)
        }
      })
    })
  }

  async fetchNotesFor(deals: Partial<DealView>[]) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))
    const [notes] = await Promise.all([
      this.Notes.getForDeals(dealIds),
    ])

    deals.forEach((deal) => {
      deal.notes = notes[deal.deal_id] || []
    })
  }

  private async fetchCreditNotesFor(deals: DealView[], filterVoidedCreditNotes: boolean) {
    const creditNotes = await this.CreditNotes.getCreditNotesForDeals(map(deals, 'deal_id'), filterVoidedCreditNotes)
    deals.forEach((deal) => {
      deal.credit_notes = creditNotes[deal.deal_id] || []
    })
  }

  private async fetchVendorCreditsFor(deals: DealView[]) {
    const vendorCredits = await this.VendorCredits.getVendorCreditsForDeals(map(deals, 'deal_id'))
    deals.forEach((deal) => {
      deal.vendor_credits = vendorCredits[deal.deal_id] || []
    })
  }

  private async fetchInvoicesFor(deals: DealView[]) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))
    const [invoices] = await Promise.all([
      this.Invoices.getForDeals(dealIds),
    ])

    deals.forEach((deal) => {
      deal.invoices = invoices[deal.deal_id] || []
    })
  }

  private async fetchStaticSegmentsFor(deals: DealView[]) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))
    const [carriers, locations, shipments, segments] = await Promise.all([
      this.Carriers.getCarriers().then(r => keyBy(r, 'carrier_id')),
      this.Locations.getLocationsByIds(),
      this.Shipments.getByDealIds(dealIds),
      this.Segments.getByDealIds(dealIds).then(r => r.reduce((res, segment) => {
        segment.deals.forEach((dealId) => {
          if (!res[dealId]) res[dealId] = []
          res[dealId].push(segment)
        })
        return res
      }, {})),
    ])

    // tslint:disable-next-line: cyclomatic-complexity
    deals.forEach((deal) => {
      deal.shipment = shipments[deal.deal_id] || {} as Shipment// TODO: this {} doesn't look right
      deal.segments = segments[deal.deal_id] || []
      deal.segments.forEach((segment) => {
        segment.origin = locations[segment.attributes.origin_id]
        segment.destination = locations[segment.attributes.destination_id]
      })

      const { trucks, vessels, earliest, latest, earliestTruck, latestTruck, earliestVessel, latestVessel } = lookupSegments(deal.segments)
      deal.earliestSegment = earliest
      deal.latestSegment = latest
      deal.earliestTruckSegment = earliestTruck
      deal.latestTruckSegment = latestTruck
      deal.earliestVesselSegment = earliestVessel
      deal.latestVesselSegment = latestVessel
      deal.earliestVesselOrTruckSegment = earliestVessel || earliestTruck

      deal.pickupLocation = locations[get(deal, 'earliestSegment.attributes.origin_id')]
      deal.dropoffLocation = locations[get(deal, 'latestSegment.attributes.destination_id')]
      deal.truckReferNumber = uniq(compact(map(trucks, 'attributes.refer_no'))).join(', ')

      // carriers
      const primarySegment = first(vessels) || first(trucks)
      const dealCarrier = carriers[primarySegment?.carrier_id]
      deal.carrierIds = dealCarrier && dealCarrier.carrier_id // we use this for filtering
      deal.carrier = dealCarrier
      deal.forwarder = carriers[find(map(deal.segments, 'attributes.freight_forwarder'))]
      const vesselCarrierId = get(deal, 'earliestVesselSegment.carrier_id')
      deal.vesselCarrierName = carriers[vesselCarrierId]?.name
      const truckCarrierId = get(deal, 'earliestTruckSegment.carrier_id')
      deal.truckCarrierName = carriers[truckCarrierId]?.name
      deal.referNo = find(map(trucks, 'attributes.refer_no'))
    })
  }

  private async fetchDynamicSegmentsFor(deals: DealView[]) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))
    const [shipments, segments] = await Promise.all([
      this.Shipments.getByDealIds(dealIds),
      this.Segments.getByDealIds(dealIds).then(r => r.reduce((res, segment) => {
        segment.deals.forEach((dealId) => {
          if (!res[dealId]) res[dealId] = []
          res[dealId].push(segment)
        })
        return res
      }, {})),
    ])

    deals.forEach((deal) => {
      deal.shipment = shipments[deal.deal_id] || {} as Shipment// TODO: this {} doesn't look right
      deal.segments = segments[deal.deal_id] || []

      Object.defineProperty(deal, 'trucks', {
        get: () => filter(deal.segments, {type: 'land'}),
      })
      Object.defineProperty(deal, 'vessels', {
        get: () => filter(deal.segments, {type: 'sea'}),
      })
      Object.defineProperty(deal, 'earliestSegment', {
        get: () => lookupSegments(deal.segments).earliest,
      })
      Object.defineProperty(deal, 'latestSegment', {
        get: () => lookupSegments(deal.segments).latest,
      })
      Object.defineProperty(deal, 'earliestTruckSegment', {
        get: () => lookupSegments(deal.segments).earliestTruck,
      })
      Object.defineProperty(deal, 'latestTruckSegment', {
        get: () => lookupSegments(deal.segments).latestTruck,
      })
      Object.defineProperty(deal, 'earliestVesselSegment', {
        get: () => lookupSegments(deal.segments).earliestVessel,
      })
      Object.defineProperty(deal, 'latestVesselSegment', {
        get: () => lookupSegments(deal.segments).latestVessel,
      })
      Object.defineProperty(deal, 'earliestVesselOrTruckSegment', {
        get: () => deal.earliestVesselSegment || deal.earliestTruckSegment,
      })
    })
  }

  async fetchFilesFor(deals: Array<DealBase & DealViewFiles>) {
    const dealIds = uniq(compact(map(deals, 'deal_id')))
    const files = await this.Files.getByDealIds(dealIds)

    deals.forEach((deal) => {
      deal.files = files[deal.deal_id] || []
    })
  }

  async fetchFreightRatesFor(dealViews) {
    const rateIds = uniq(compact([
      ...map(flatten(map(dealViews, 'segments')), 'attributes.rate_id'),
      ...map(flatten(map(dealViews, 'costs')), 'attributes.rate_id'),
    ]))
    if (!rateIds.length) return {}
    const rates = await Promise.all(rateIds.map(rateId =>
      this.ShipmentRateApi.get(rateId).then(r => r.data)
      .catch(err => console.error(`Unable to load freight rate id=${rateId}`, err))))
    // TODO: create API for fetching rates by ids
    return pick(keyBy(compact(rates), 'rate_id'), rateIds)
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private validateDeal(deal, offers, bids, {measures, pricingTerms, products}) {
    if (!get(deal, 'attributes.actual') || !get(deal, 'attributes.estimated')) {
      console.warn(`deal ${deal.deal_id} invalid: has no calculation results`)
      console.debug(`deal ${deal.deal_id} invalid: has no calculation results`, deal)
      return false
    }

    const N = deal.offers.length
    const O = offers
    const B = bids

    if (deal.status !== 'Draft' && !deal.attributes.seller_confirm_sent && !deal.attributes.buyer_confirm_sent) {
      console.warn(`deal ${deal.deal_id} invalid: '*_confirm_sent' flags are not set on the ${deal.status} deal`)
      console.debug(`deal ${deal.deal_id} invalid: '*_confirm_sent' flags are not set on the ${deal.status} deal`, deal, O, B)
      // return false // environment.env !== 'production' // let it crash on stage & dev
    }

    if (!N || !O || !B) {
      console.log(`deal ${deal.deal_id} invalid: has no bids or offers`)
      console.debug(`deal ${deal.deal_id} invalid: has no bids or offers`, deal, O, B)
      return false // environment.env !== 'production' // let it crash on stage & dev
    }
    if (N !== deal.bids.length || N !== O.length || N !== B.length) {
      console.log(`deal ${deal.deal_id} invalid: amount of bids and offers should match`)
      console.debug(`deal ${deal.deal_id} invalid: amount of bids and offers should match`, deal, O, B)
      return false // environment.env !== 'production' // let it crash on stage & dev
    }

    for (const o of O) {
      if (!products[o.product]) {
        console.log(`offer ${deal.deal_id}/${o.offer_id} invalid: referring to not existing product ${o.product}`)
        console.debug(`offer ${deal.deal_id}/${o.offer_id} invalid: referring to not existing product`, deal, o)
      }
    }
    const actualWeightUnits = get(deal, 'attributes.actual.weight.measure_id')
    if (actualWeightUnits && !measures[actualWeightUnits]) {
      console.warn(`deal ${deal.deal_id} invalid: referring to not existing actual weight measure ${actualWeightUnits}`)
      console.debug(`deal ${deal.deal_id} invalid: referring to not existing actual weight measure`, deal)
      return false
    }
    for (const o of O) {
      if (!measures[o.weight.unit]) {
        console.warn(`offer ${deal.deal_id}/${o.offer_id} invalid: referring to not existing measure ${o.weight.unit}`)
        console.debug(`offer ${deal.deal_id}/${o.offer_id} invalid: referring to not existing measure`, deal, o)
        return false
      }
    }
    for (const o of O) {
      if (!pricingTerms[o.incoterm]) {
        console.log(`offer ${deal.deal_id}/${o.offer_id} invalid: referring to not existing pricingTerm ${o.incoterm}`)
        console.debug(`offer ${deal.deal_id}/${o.offer_id} invalid: referring to not existing pricingTerm`, deal, o)
      }
    }
    for (const b of B) {
      if (!products[b.product]) {
        console.log(`bid ${deal.deal_id}/${b.bid_id} invalid: referring to not existing product ${b.product}`)
        console.debug(`bid ${deal.deal_id}/${b.bid_id} invalid: referring to not existing product`, deal, b)
      }
    }
    for (const b of B) {
      if (!measures[b.weight.unit]) {
        console.warn(`bid ${deal.deal_id}/${b.bid_id} invalid: referring to not existing measure ${b.weight.unit}`)
        console.debug(`bid ${deal.deal_id}/${b.bid_id} invalid: referring to not existing measure`, deal, b)
        return false
      }
    }
    for (const b of B) {
      if (!pricingTerms[b.incoterm]) {
        console.log(`bid ${deal.deal_id}/${b.bid_id} invalid: referring to not existing pricingTerm ${b.incoterm}`)
        console.debug(`bid ${deal.deal_id}/${b.bid_id} invalid: referring to not existing pricingTerm`, deal, b)
      }
    }

    const pairs = O.map(offer => ({ offer, bid: find(B, { offer: offer.offer_id}) }))
    pairs.forEach(({offer, bid}) => {
      mustMatch('product')
      mustMatch('attributes.gross_weight')
      mustMatch('wrapping')
      mustMatch('description')
      mustMatch('packing.package_id', 'packing.type')
      mustMatch('quantity')
      mustMatch('packing.packages_count', 'packing.unit')
      mustMatch('packing.package_size', 'attributes.package_size')
      mustMatch('packing.package_measure_id', 'attributes.package_measure_id')
      mustMatch('attributes.item_type_id')
      mustMatch('attributes.weight_type_id')
      mustMatch('attributes.additional_specs')
      mustMatch('attributes.comments')
      mustMatch('attributes.hs_code')
      mustMatch('lot', 'attributes.lot_no')
      mustMatch('attributes.shipping_marks')
      mustMatch('attributes.batches')
      mustMatch('attributes.pallets')
      mustMatch('attributes.expiry_date')
      mustMatch('attributes.expiry_in')
      mustMatch('attributes.production_date')
      mustMatch('attributes.brand')
      mustMatch('attributes.product_code')

      function mustMatch(offerField, bidField = offerField) {
        const [buy, sell] = [get(offer, offerField), get(bid, bidField || offerField)]
        if (!isEqual(buy, sell) && (buy || sell)) { // NOTE: allow difference between nulls&zeroes
          console.log(`${deal.deal_id}: offer.${offerField} must be equal to bid.${bidField}`)
          console.debug(`${deal.deal_id}: offer.${offerField} must be equal to bid.${bidField}`, [buy, sell])
        }
      }
    })
    return true
  }


  // NOTE: initially copied from DealProducts component
  private totalDealWeight(deal, party, fieldName) {
    const measure_id = deal.products[0][party].measure_id
    return {
      measure_id,
      // convert weights to 1st product units of measure
      amount: sumBy(deal.products, dealProduct => this.Measures.convert(
        dealProduct[party][fieldName],
        dealProduct[party].measure_id,
        measure_id,
      )),
    }
  }
}
