import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Measure } from '@tradecafe/types/core'
import { sortBy } from 'lodash-es'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


@Injectable()
@AngularCopy()
export class MeasureApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: Measure[] }>(`${apiUrl}/measures`, { params }).toPromise().then(({data = []}) => {
      return {
        data: sortBy(data, 'name'),
      }
    })
  }

  create(data: Partial<Measure>) {
    return this.http.post<{ data: Measure }>(`${apiUrl}/measures`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: Measure }>(`${apiUrl}/measures/${id}`).toPromise()
  }

  update(id: string, data: Partial<Measure>) {
    return this.http.put<{ data: Measure }>(`${apiUrl}/measures/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: Measure }>(`${apiUrl}/measures/${id}`).toPromise()
  }
}
