import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal/modal.service'
import { CsvExporterDialogComponent, CsvExporterOptions } from './csv-exporter.component'


@Injectable()
export class CsvExporterService<T = any> {
  constructor(private modal: ModalService) {}

  fromDataTable(data: CsvExporterOptions<T>) {
    this.modal.openDialog(CsvExporterDialogComponent, data)
  }

}
