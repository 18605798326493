import { Injectable } from '@angular/core'
import { DEAL_CANCELED, DEAL_CLOSED, DEAL_CONFIRMED, DEAL_DELIVERED, DEAL_DOCS_SENT, DEAL_INVOICED, DEAL_WITH_CARRIER, DEAL_WITH_RECEIVER, Deal, DealBase, DealParty, DealPartyE, DealRow, DealView, DealViewBase, DealViewClones, DealViewRawDeal, DealViewRawInvoices, INVOICE_ACTUALIZED, INVOICE_APPLIED, INVOICE_APPROVED, INVOICE_PAID, INVOICE_PARTIALLY_PAID, INVOICE_SCHEDULED, User } from '@tradecafe/types/core'
import { DeepReadonly, isDealBwiInventory, isDealConfirmed, isDealConfirmedBy, isDealFormulaDeal, isDealLocked, isDealSubmitted } from '@tradecafe/types/utils'
import { uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { isBwiInventory } from './accounts.service'
import { isDealInInventory } from './deal-view.service'

/**
 * Deal View Permissions service defines who can edit which deal fields
 *
 * @export
 * @returns
 */
@Injectable()
export class DealViewPermissionsService {
  constructor(private AuthApi: AuthApiService, private toaster: ToasterService) {}

  canEdit(dealView: DeepReadonly<DealRow>, fieldName: string, opts = { toast: false}) {
    return canEditDealViewField(dealView, fieldName, this.AuthApi.currentUser, opts.toast ? this.toaster : undefined)
  }

  // tslint:disable-next-line: cyclomatic-complexity
  canChangeStatusTo(dealView: DeepReadonly<Deal | DealViewBase>, nextStatus: string) {
    return canChangeDealStatusTo(dealView, nextStatus, this.AuthApi.currentUser)
  }

  canChangeParty(deal: DealView, partyType: DealParty) {
    return canChangeParty(deal.raw, partyType, this.AuthApi.currentUser)
  }

  /**
   * Check if curent user can confirm selected deals as a party type
   *
   * @param {DealView[]} dealViews
   * @param {DealParty} party
   * @returns
   */
  canConfirm(dealViews: DeepReadonly<DealBase[]>, party: DealPartyE) {
    if (!this.AuthApi.currentUser) return false
    if (this.AuthApi.currentUser.role !== 'manager' && this.AuthApi.currentUser.role !== 'superuser') return false
    if (!dealViews || !dealViews.length) return false
    // const allParties = uniq(map(dealViews, `${party}_id`))
    // if (allParties.length !== 1) return false
    if (party === DealPartyE.supplier) party = 'seller' as any
    return dealViews.every(deal =>
      !isDealConfirmed(deal) && !deal[`${party}_confirmed`])
  }

  /**
   * Check if curent user can preview all clones of a selected deal as a party type
   *
   * @param {DealView} dealView
   * @param {DealParty} party
   * @returns
   */
  canPreviewAllClones(dealView: DealViewClones, party: DealPartyE) {
    if (!dealView.clones) return false
    const partyIds = uniq(dealView.clones.map(clonedDeal =>
      party === DealPartyE.brokerage_customer ? clonedDeal.brokerage?.customer : parseFloat(clonedDeal[`${party}_id`])))
    return partyIds.length === 1
  }

  /**
   * Check if curent user can merge and preview documents for the selected deals as a party type
   *
   * @param {DealView[]} dealViews
   * @param {DealParty} party
   * @returns
   */
  canPreviewMerged(dealViews: DealViewBase[], party: DealParty) {
    if (!dealViews.length) return false
    const partyIds = uniq(dealViews.map(clonedDeal =>
      parseFloat(clonedDeal[`${party}_id`] as any as string)))
    return partyIds.length === 1
  }

  canCreateSupplierOffer(deal: DeepReadonly<Deal | DealViewBase>) {
    return canCreateSupplierOffer(deal, this.AuthApi.currentUser)
  }
}

export function canCreateSupplierOffer(deal: DeepReadonly<Deal | DealViewBase>, { role }: User) {
  return ['trader', 'manager', 'administrator', 'junior-administrator', 'superuser'].includes(role)
    && isBwiInventory(deal.buyer_id)
    && deal.status === DEAL_CONFIRMED
    && isDealConfirmedBy(deal, 'supplier')
    && !isDealBwiInventory(deal)
    && !deal.sold_deal_id
}

// tslint:disable-next-line: cyclomatic-complexity
export function canChangeDealStatusTo(deal: DeepReadonly <Deal | DealViewBase>, nextStatus: string, currentUser: User) {
  const { status } = deal
  if (status === nextStatus) return false

  switch (nextStatus) {
    case DEAL_CONFIRMED:
      return !isDealConfirmed(deal)
    case DEAL_INVOICED:
      return status === DEAL_CONFIRMED && !isDealFormulaDeal(deal)
    case DEAL_DOCS_SENT:
      return status === DEAL_INVOICED || status === DEAL_WITH_RECEIVER || status === DEAL_WITH_CARRIER
    case DEAL_WITH_CARRIER:
      return status === DEAL_DOCS_SENT || status === DEAL_WITH_RECEIVER
    case DEAL_WITH_RECEIVER:
      return status === DEAL_WITH_CARRIER || status === DEAL_DOCS_SENT
    case DEAL_DELIVERED:
      return status === DEAL_WITH_RECEIVER || status === DEAL_DOCS_SENT || status === DEAL_WITH_CARRIER
    case DEAL_CANCELED:
      return isAdmin(currentUser) && !isDealConfirmed(deal)
    case DEAL_CLOSED:
      return isManager(currentUser) && isDealInInventory(deal)
    default:
      return false // disable by default
  }
}

export function canChangeParty(deal: DeepReadonly<Deal>, partyType: string, currentUser: User) {
  // anyone can change BWI Inventory to some other buyer/supplier
  if (isBwiInventory(deal?.[`${partyType}_id`])) return true
  // locked deal is uneditable
  if (isDealLocked(deal)) return false
  // anyone can change buyer and supplier while deal was not submitted
  if (!isDealSubmitted(deal)) return true
  // managers are allowed to change party
  if (currentUser.role === 'manager' || currentUser.role === 'superuser') return true
  return false
}

export function canChangeBrokerageParty(deal: DeepReadonly<Deal>, currentUser: User) {
  // locked deal is uneditable
  if (isDealLocked(deal)) return false
  // anyone can change buyer and supplier while deal was not submitted
  if (!isDealSubmitted(deal)) return true
  // managers are allowed to change party
  if (currentUser.role === 'manager' || currentUser.role === 'superuser') return true
  return false
}

function canEditDealViewField(dealView: DeepReadonly<DealRow>, fieldName: string, currentUser: User, toaster?: ToasterService) {
  let result = canEditDealFormField({ deal: dealView.raw, invoices: [] }, fieldName, currentUser)
  if (result === undefined) {
    result = canEditDealsListCell(dealView, fieldName, currentUser, toaster)
  }
  if (result === undefined) { // literally undefined
    throw new Error(`Permissions are not defined for the deal view field "${fieldName}"`)
  }
  return result
}

export function canEditDealFormField(dv: DeepReadonly<DealViewRawDeal & DealViewRawInvoices>, fieldName: string, currentUser: User) {
  const { role } = currentUser

  switch (fieldName) {
    case 'brokerageSendSupplierConfirmation':
    case 'brokerageSendBuyerConfirmation':
    case 'brokerageEstAmount':
      return !isDealSubmitted(dv.deal)

    case 'productId':
    case 'brokeragePaymentTerms':
    case 'brokerageCustomer':
    case 'brokerageCurrency':
    case 'brokerageCollectionDate':
    case 'brokerageTermDate':
    case 'brokerageTraderId':
    case 'brokerageContactUserIds':
      if (!isDealSubmitted(dv.deal)) return true
      if (isDealLocked(dv.deal)) return false
      if (role === 'manager' || role === 'superuser') return true
      return false

    case 'supplierEstWeight':
    case 'supplierMeasureId':
    case 'supplierCurrencyCode':
    case 'supplierEstPrice':
      if (!isDealSubmitted(dv.deal)) return true
      if (isDealLocked(dv.deal)) return false
      if (role === 'manager' || role === 'superuser') return true
      if (role === 'trader' || role === 'logistics') return isBwiInventory(dv.deal.supplier_id)
      return false

    case 'buyerEstWeight':
    case 'buyerEstPrice':
      if (!isDealSubmitted(dv.deal)) return true
      if (isDealLocked(dv.deal)) return false
      if (role === 'manager' || role === 'superuser') return true
      if (role === 'trader' || role === 'logistics') return isBwiInventory(dv.deal.buyer_id)
      return false

    case 'buyerMeasureId':
    case 'buyerCurrencyCode':
      if (hasReceivableInvoiceWithReceipts(dv)) return false
      if (!isDealSubmitted(dv.deal)) return true
      if (isDealLocked(dv.deal)) return false
      if (role === 'manager' || role === 'superuser') return true
      if (role === 'trader' || role === 'logistics') return isBwiInventory(dv.deal.buyer_id)
      return false

    case 'supplierActualWeight':
      if (!hasActiveSupplierInvoice(dv)) return true
      if (isDealLocked(dv.deal)) return false
      if (role === 'manager' || role === 'superuser') return true
      return false

    case 'buyerActualWeight':
      if (!hasReceivableInvoiceWithReceipts(dv)) return true
      if (isDealLocked(dv.deal)) return false
      if (role === 'manager' || role === 'superuser') return true
      return false

    case 'collection_date':
      return true // everyone including traders can edit this date
    case 'attributes.buyer_ref':
    case 'attributes.delivery_dates':
    case 'attributes.extra.art_work':
    case 'attributes.extra.bol_instructions':
    case 'attributes.extra.comments':
    case 'attributes.extra.delivered':
    case 'attributes.extra.documents':
    case 'attributes.extra.edocs_to_customs_broker':
    case 'attributes.extra.description_of_goods':
    case 'attributes.extra.customs_broker':
    case 'attributes.extra.inspection':
    case 'attributes.extra.invoiced':
    case 'attributes.extra.load_ready':
    case 'attributes.extra.loading_instructions':
    case 'attributes.extra.original_sent':
    case 'attributes.extra.supplier_released':
    case 'attributes.extra.truck_inspection':
    case 'attributes.inalfresco':
    case 'attributes.shipment_dates':
    case 'attributes.shipment.epd_date':
    case 'attributes.shipment.erd_date':
    case 'attributes.shipment.import_no':
    case 'attributes.supplier_ref':
    case 'inv':
    case 'product.item_type':
    case 'mexicanInvoiceNo':
    case 'brokerageActAmount':
      return role !== 'trader'

    case 'origin_location':
    case 'dest_location':
      if (!isDealSubmitted(dv.deal)) return true
      if (isDealLocked(dv.deal)) return false
      return false

  }
  return undefined
}

function canEditDealsListCell(dealView: DeepReadonly<DealRow>, fieldName: string, currentUser: User, toaster?: ToasterService) {
  const { role } = currentUser

  switch (fieldName) {
    case 'earliestTruckSegment.attributes.actual_pickup_date':
      if (!dealView?.earliestTruckSegment) toaster?.warning('There are no "truck" segments in this deal')
      return role !== 'trader' && !!dealView?.earliestTruckSegment
    case 'latestTruckSegment.attributes.actual_delivery_date':
      if (!dealView?.latestTruckSegment) toaster?.warning('There are no "truck" segments in this deal')
      return role !== 'trader' && !!dealView?.latestTruckSegment
    case 'earliestVesselSegment.attributes.actual_pickup_date':
    case 'earliestVesselSegment.attributes.cutoff_datetime':
    case 'earliestVesselSegment.attributes.vessel':
      if (!dealView?.earliestVesselSegment) toaster?.warning('There are no "vessel" segments in this deal')
      return role !== 'trader' && !!dealView?.earliestVesselSegment
    case 'latestVesselSegment.attributes.actual_delivery_date':
      if (!dealView?.latestVesselSegment) toaster?.warning('There are no "vessel" segments in this deal')
      return role !== 'trader' && !!dealView?.latestVesselSegment
    case 'earliestVesselOrTruckSegment.booking_id':
    case 'earliestVesselOrTruckSegment.attributes.container_number':
      if (!dealView?.earliestVesselOrTruckSegment) toaster?.warning('There are no segments in this deal')
      return role !== 'trader' && !!dealView?.earliestVesselOrTruckSegment
  }
  return undefined

}

export function isAdmin({ role }: User) {
  return ['administrator', 'manager', 'superuser'].includes(role)
}

function isManager({ role }: User) {
  return ['manager', 'superuser'].includes(role)
}

function hasActiveSupplierInvoice(dealViewRaw: DeepReadonly<DealViewRawDeal & DealViewRawInvoices>) {
  return dealViewRaw.invoices?.some((invoice) =>
    invoice.type === 'payable'
      && invoice.account === dealViewRaw.deal.supplier_id
      && !invoice.attributes.prepayment
      && [
        INVOICE_APPROVED,
        INVOICE_ACTUALIZED,
        INVOICE_SCHEDULED,
        INVOICE_PAID,
        INVOICE_PARTIALLY_PAID,
        INVOICE_APPLIED
      ].includes(invoice.status)
  )
}

function hasReceivableInvoiceWithReceipts(dealViewRaw: DeepReadonly<DealViewRawDeal & DealViewRawInvoices>) {
  return dealViewRaw.invoices?.some(invoice =>invoice.type === 'receivable' && invoice.receipts?.length > 0)
}
