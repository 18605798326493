import { createSelector } from '@ngrx/store'
import { PricingTerm } from '@tradecafe/types/core'
import { sortBy } from 'lodash-es'
import { State } from '../reducers'
import { adapter, pricingTermsFeatureKey } from './pricing-term.reducer'

export const selectState = (state: State) => state[pricingTermsFeatureKey]

export const { selectEntities: selectPricingTermEntities } = adapter.getSelectors(selectState)
const { selectAll } = adapter.getSelectors(selectState)

export const selectAllPricingTerms = createSelector(
  selectAll,
  pricingTerms => sortBy(pricingTerms, 'ranking'))

export const selectPricingTerm = createSelector(
  selectPricingTermEntities,
  (pricingTerms: Dictionary<PricingTerm>, pricingTerm: string | number) =>
    pricingTerms[pricingTerm])

export const selectPricingTermsById = createSelector(
  selectPricingTermEntities,
  (pricingTerms: Dictionary<PricingTerm>, pricingTermId: string) =>
    pricingTerms[pricingTermId])

export const selectPricingTermsByIds = createSelector(
  selectPricingTermEntities,
  (pricingTerms: Dictionary<PricingTerm>, pricingTermIds: string[]) =>
    pricingTermIds.map(pricingTermId => pricingTerms[pricingTermId]))
