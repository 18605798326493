import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Segment } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const { apiUrl } = environment

export default module =>
  module.service('SegmentApi', downgradeInjectable(SegmentApiService))


@Injectable()
@AngularCopy()
export class SegmentApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: Segment[] }>(`${apiUrl}/segments`, { params }).toPromise()
  }

  listByDealIds(params) {
    return this.http.post<{ data: Segment[] }>(`${apiUrl}/segments/by-deal-ids`, params).toPromise()
  }

  create(data: Partial<Segment>) {
    return this.http.post<{ data: Segment }>(`${apiUrl}/segments`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: Segment }>(`${apiUrl}/segments/${id}`).toPromise()
  }

  update(id: string, data: Partial<Segment>) {
    return this.http.put<{ data: Segment }>(`${apiUrl}/segments/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: Segment }>(`${apiUrl}/segments/${id}`).toPromise()
  }
}
