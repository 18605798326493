import { Injectable } from '@angular/core'
import { DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawOffers, DealViewRawSegments } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalService } from 'src/shared/modal/modal.service'
import { ChangeCloneFormComponent, ChangeCloneFormOptions, ChangeCloneFormResult } from './change-clone-form.component'

type D = DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawSegments & DealViewRawInvoices

@Injectable()
export class ChangeCloneFormService {
  constructor(private modal: ModalService) {}

  showChangeClone(deals: DeepReadonly<D[]>) {
    return this.show(deals)
  }

  private show(deals: DeepReadonly<D[]>) {
    return this.modal.openDialog<ChangeCloneFormComponent, ChangeCloneFormOptions, ChangeCloneFormResult>(ChangeCloneFormComponent, {
      deals,
    }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
    })
  }
}
