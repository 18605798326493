import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ARReportInvoiceItem, InvoiceType } from '@tradecafe/types/core'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment

@Injectable()
export class ARReportApiService {
  constructor(private http: HttpClient) { }

  getBuyerInvoices(query: { buyer_ids: string[], status: number[], type: InvoiceType[] }) {
    return this.http.post<{ data: ARReportInvoiceItem[] }>(`${apiUrl}/reports/ar-report/invoices`, query)
  }
}
