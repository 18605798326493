import { Injectable } from '@angular/core'
import { Carrier, Segment, ShipmentRate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { filter, pick } from 'lodash-es'
import { SegmentApiService } from 'src/api/shipment-routing/segment'
import { SegmentFormValue } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { chunked } from './utils'

const ALLOWED_FIELDS = ['carrier_id', 'deals', 'booking_id', 'shipment_id', 'type', 'attributes']

export const SEGMENT_TYPES = {
  warehouse: 'Warehouse',
  rail: 'Rail',
  land: 'Land',
  sea: 'Sea',
  air: 'Air',
}

/**
 * Segments service
 *
 * @see https://docs.google.com/document/d/1k1M423yp-irtCGNem44Ct1rmMVHrmUgas0Bz8LGAiiA
 *
 * @export
 * @returns
 */
@Injectable()
export class SegmentsService {
  constructor(private SegmentApi: SegmentApiService) {}

  buildFromRate = buildSegmentFromRate
  getPopulatedValues = getSegmentPopulatedValues

  /**
   * Load segments associated with shipment
   *
   * @param {any} shipmentId
   * @returns {Promise} of a segment object or null
   */
  async getForShipment(shipment_id: string) {
    const { data } = await this.SegmentApi.list({ shipment_id, limit: Number.MAX_SAFE_INTEGER })
    return filter(data, { shipment_id })
  }

  async getByDealIds(dealIds: string[]) {
    if (!dealIds.length) return []
    const data = await chunked(dealIds, 400, deal_ids =>
      this.SegmentApi.listByDealIds({ deal_ids }).then(r => r.data))
    return data
  }

  /**
   * Create new deal segment document
   *
   * @private
   * @param {any} dealId
   * @param {any} segment
   * @returns
   */
  async create(segment: Partial<Segment>) {
    const payload = pick(segment, ALLOWED_FIELDS)
    const { data } = await this.SegmentApi.create(payload)
    Object.assign(segment, data)
    return data
  }

  /**
   * Update segment
   *
   * @private
   * @param {any} segment
   * @returns
   */
  async update(segment: Partial<Segment>) {
    const payload = pick(segment, ALLOWED_FIELDS)
    const { data } = await this.SegmentApi.update(segment.segment_id, payload)
    Object.assign(segment, data)
    return data
  }

  /**
   * Remove segment document
   *
   * @private
   * @param {any} dealId
   * @param {any} segment
   * @returns
   */
  async remove(segment: { readonly segment_id: string }) {
    const { data } = await this.SegmentApi.delete(segment.segment_id)
    return data
  }
}


/**
 * Build segment based on freight rate
 *
 * @param {any} rate
 * @param {any} {carriers} // all carriers by ids
 * @returns
 */
export function buildSegmentFromRate(
  rate: DeepReadonly<ShipmentRate>,
  {carriers}: { carriers: DeepReadonly<Dictionary<Carrier>>},
): Partial<Segment> {
  // NOTE: deals and shipment_id are required, but
  return {
    carrier_id: rate.carrier_id,
    type: rate.type,
    attributes: {
      carrier_account: carriers[rate.carrier_id]?.account,
      origin_id: rate.origin_id,
      destination_id: rate.destination_id,
      amount: rate.rate.amount,
      currency: rate.rate.currency,
      rate_id: rate.rate_id,
    },
  }
}

/**
 * Extract autopopulated segment fields
 *
 * @param {any} segment
 * @returns
 */
export function getSegmentPopulatedValues(segment: DeepReadonly<Segment>) {
  return pick(segment, [
    'booking_id',
    'attributes.bill_of_lading_no',
    'attributes.container_number',
    'attributes.freight_forwarder',
    'attributes.import_permit',
    'attributes.seal_number',
    'attributes.loading_ramp',
  ])
}

export function getSegmentFormPopulatedValues(segment: DeepReadonly<SegmentFormValue>) {
  return pick(segment, [
    'bookingId',
    'billOfLadingNo',
    'containerNumber',
    'freightForwarderId',
    'importPermit',
    'sealNumber',
    'loadingRamp',
  ])
}
