import { Injectable } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ComponentWithOnDestroyObservable, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, difference, isEqual, keys, mapValues, omit, pick, reject } from 'lodash-es'
import { map } from 'rxjs/operators'
import { replayForm } from 'src/shared/utils/replay-form'
import { DateFilter, FiltersFormGroup, TABLE_FILTERS_FIELDS } from './filters.interfaces'
import { UserStorageService } from './user-storage.service'

export interface TableViewFilter {
  columns: string[]
  // custom: any
  table_view_id: string
}

interface FormFactoryOptions<D extends TableViewFilter, M> {
  key: TableKey
  whitelist?: string[]
  cacheFilters?: boolean
  defaultValues: DeepReadonly<D>
  mapStoredValues?: (x: M) => M
}

/**
 * Filters Service makes it easier for components to build and store table fitlers
 *
 * @export
 * @class FiltersService
 */
@Injectable()
export class FiltersService {

  constructor(
    private fb: UntypedFormBuilder,
    private storage: UserStorageService,
  ) {}

  /**
   * Observe how many [whitelisted?] filters are active
   *
   * NOTE: FiltersFormGroup<T> has `filtersActive$` property with default whitelist
   *
   * @template T
   * @param {FiltersFormGroup<T>} form
   * @param {string[]} [whitelist]
   * @returns
   * @memberof FiltersService
   */
  filtersActive<T>(form: FiltersFormGroup<T>, whitelist?: string[]) {
    return filtersActive(form, whitelist)
  }

  /**
   * Build extended FormGroup. Keep it in sync with localStorate
   *
   * @template T
   * @param {OnDestroy} scope - subscribe until scope component destroyed
   * @param {FormFactoryOptions<T, DateFilter>} {
   *       key, - user storage (US) key
   *       defaultValues, - default filter values
   *       whitelist, - list of filter names to keep in sync with US
   *       mapStoredValues?, - optionally preprocess values read from US (default=`normalizeDateRange`)
   *     }
   * @returns {FiltersFormGroup<T>}
   * @memberof FiltersService
   */
  buildForm<T extends TableViewFilter>(
    scope: ComponentWithOnDestroyObservable,
    { key,
      defaultValues,
      cacheFilters,
      whitelist,
      mapStoredValues = normalizeDateRange,
    }: FormFactoryOptions<T, DateFilter>,
  ): FiltersFormGroup<T> {
    whitelist = whitelist || Object.keys(defaultValues)

    const initialFilters = cacheFilters
      ? this.readFilters(key, whitelist, defaultValues, mapStoredValues)
      : cloneDeep(defaultValues)

    // init form
    const form = this.fb.group(mapValues(initialFilters, x => [x])) as FiltersFormGroup<T>
    form.tableKey = key
    form.defaultValues = Object.freeze(defaultValues) as DeepReadonly<T>
    form.mapStoredValues = mapStoredValues
    form.filtersActive$ = this.filtersActive(form)

    if (cacheFilters) {
      form.valueChanges.pipe(untilComponentDestroyed(scope)).subscribe(filters =>
        this.storage.set(key, filters))
    }

    // override default "value" argument of `form.reset`
    const reset = form.reset
    form.reset = (value?, options = {}, emitEvent?) => {
      const tableSettings = pick(form.value, TABLE_FILTERS_FIELDS) // skip sort & pagination
      const defaultFilters = omit(defaultValues, TABLE_FILTERS_FIELDS) // skip sort & pagination
      value = { ...tableSettings, ...(value || defaultFilters) }
      reset.call(form, value, options, emitEvent)
    }
    return form
  }

  private readFilters<T, M>(
    key: TableKey,
    whitelist: string[],
    defaultValues: T,
    mapStoredValues: (x: M) => M,
  ) {
    const stored = this.storage.get(key, cloneDeep(defaultValues))

    // whiltelist stored filters - remove unsupported
    difference(keys(stored), whitelist).forEach(field =>
      delete stored[field])
    // add missing filters
    difference(keys(defaultValues), keys(stored)).forEach(field =>
      stored[field] = cloneDeep(defaultValues[field]))
    // // override with input default filters
    // keys(defaultValues).forEach(field =>
    //   stored[field] = cloneDeep(defaultValues[field]))
    // clean empty date-range filters
    return mapValues(stored, mapStoredValues)
  }
}

export function normalizeDateRange(value: DateFilter) {
  // detect date range
  if (value && value.hasOwnProperty('startDate') && value.hasOwnProperty('endDate')) {
    if (!value.startDate && !value.endDate) return null
  }
  return value
}

// NOTE: FiltersFormGroup<T> has `filtersActive$` property
export function filtersActive<T>(form: FiltersFormGroup<T>, whitelist?: string[], blacklist = ['limit', 'sort']) {
  if (!whitelist) whitelist = difference(Object.keys(form.value), blacklist)
  return replayForm(form).pipe(map(listFilters => {
    const visible = pick(listFilters, whitelist)
    const active = reject(visible, (filterValue, field) => isEqual(
      form.mapStoredValues(filterValue),
      form.defaultValues[field]))
    return active.length
  }))
}
