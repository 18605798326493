import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Invoice } from '@tradecafe/types/core'
import { DeepReadonly, getHsCode, getNameOverride } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { debounce, identity, range, round } from 'lodash-es'
import { Observable, combineLatest } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadConsignees } from 'src/app/store/consignees'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadLocations, selectAllLocations } from 'src/app/store/locations'
import { loadMeasures, selectAllMeasures } from 'src/app/store/measures'
import { loadPackageTypes, selectAllPackageTypes } from 'src/app/store/package-types'
import { loadPricingTerms, selectAllPricingTerms } from 'src/app/store/pricing-terms'
import { loadWeightTypes, selectAllWeightTypes } from 'src/app/store/weight-types'
import { loadWrappingTypes, selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { AddressFieldPickerOptions } from 'src/components/address-field/address-field.component'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { DealDetailsFormGroup, DealFormGroup, DealProductBatchFormGroup, DealProductFormGroup, DealProductFormValueBase } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { DealFormCalculatorService } from 'src/services/data/deal-form-calculator.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'

@Component({
  selector: 'tc-deal-product-form',
  templateUrl: './deal-product-form.component.html',
  styleUrls: ['./deal-product-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealProductFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private DealFormCalculator: DealFormCalculatorService,
    private DealForm: DealFormService,
    private Measures: MeasuresService,
    private store: Store,
  ) { super() }


  // @Input() mode?: 'finance'|'products' // we hide some content, when mode is specified
  @Input() dealForm: DealFormGroup
  @Input() invoices$: Observable<DeepReadonly<Invoice[]>>
  @Input() productForm: DealProductFormGroup
  detailsForm: DealDetailsFormGroup

  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())
  itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty())
  pricingTerms$ = this.store.pipe(select(selectAllPricingTerms), waitNotEmpty())
  weightTypes$ = this.store.pipe(select(selectAllWeightTypes), waitNotEmpty())
  wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty())
  hsCode$: Observable<string>
  productTranslation$: Observable<string>
  doCalculations = debounce(this.doCalculationsForReal, 300)

  // data
  weights = range(5000, 26501, 500)
  establishmentAddressOptions$: Observable<AddressFieldPickerOptions>
  invoiceAddressOptions$: Observable<AddressFieldPickerOptions>


  async ngOnInit() {
    this.store.dispatch(loadConsignees())
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadMeasures({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadPackageTypes({}))
    this.store.dispatch(loadPricingTerms({}))
    this.store.dispatch(loadWeightTypes({}))
    this.store.dispatch(loadWrappingTypes({}))

    this.detailsForm = this.dealForm.controls.details
    const buyerProductInfo$ = combineLatest([
      this.accounts$,
      replayForm(this.detailsForm.controls.buyerId),
      replayForm(this.productForm.controls.productId),
      replayForm(this.productForm.controls.itemTypeId),
    ]).pipe(map(([accounts, buyerId, product_id, item_type_id]) =>
      ({ buyer: accounts[buyerId], dealProduct: { product_id, item_type_id }})))
    this.productTranslation$ = buyerProductInfo$.pipe(map(({buyer, dealProduct}) => getNameOverride(buyer, dealProduct) || ''))
    this.hsCode$ = buyerProductInfo$.pipe(map(({buyer, dealProduct}) => getHsCode(buyer, dealProduct) || ''))

    this.productForm.controls.batches.controls.forEach(batchForm =>
      this.syncExpiryFields(batchForm))
    this.productForm.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.doCalculations()
    })

    this.establishmentAddressOptions$ = establishmentAddressOptions(this.detailsForm, this.productForm)
    this.invoiceAddressOptions$ = invoiceAddressOptions(this.detailsForm, this.productForm)
  }

  onSupplierPriceChange() {
    const ctrl = this.productForm.controls.supplierActualPrice
    ctrl.setValue(this.productForm.getRawValue().supplierEstPrice)
    ctrl.markAsDirty()
    this.doCalculations()
  }

  onBuyerPriceChange() {
    const ctrl = this.productForm.controls.buyerActualPrice
    ctrl.setValue(this.productForm.getRawValue().buyerEstPrice)
    ctrl.markAsDirty()
    this.doCalculations()
  }

  onSupplierWeightChange() {
    const { supplierEstWeight, supplierMeasureId, buyerMeasureId, supplierActualWeight, buyerActualWeight } = this.productForm.getRawValue()
    const buyerEstWeight = round(this.Measures.convert(supplierEstWeight, supplierMeasureId, buyerMeasureId), 2);
    const patch: Partial<DealProductFormValueBase> = { buyerEstWeight }
    if (supplierActualWeight) patch.supplierActualWeight = supplierEstWeight
    if (buyerActualWeight) patch.buyerActualWeight = buyerEstWeight
    this.productForm.patchValue(patch)
    Object.keys(patch).forEach((key: keyof DealProductFormValueBase) =>
      this.productForm.controls[key].markAsDirty())
    this.doCalculations()
  }

  onBuyerWeightChange() {
    const { buyerEstWeight, buyerActualWeight } = this.productForm.getRawValue()
    if (buyerActualWeight) {
      this.productForm.patchValue({ buyerActualWeight: buyerEstWeight })
      this.productForm.controls.buyerActualWeight.markAsDirty()
    }
    this.doCalculations()
  }

  addBatch() {
    const batchForm = this.DealForm.buildDealProductBatchForm()
    this.syncExpiryFields(batchForm)
    this.productForm.controls.batches.controls.push(batchForm)
    this.productForm.controls.batches.markAsDirty()
  }

  syncExpiryFields(batchForm: DealProductBatchFormGroup) {
    const { expiryDate, expiryIn } = batchForm.controls
    expiryDate.valueChanges.pipe(filter(identity), untilComponentDestroyed(this)).subscribe(() => {
      expiryIn.setValue(undefined)
      expiryIn.markAsDirty()
    })
    expiryIn.valueChanges.pipe(filter(identity), untilComponentDestroyed(this)).subscribe(() => {
      expiryDate.setValue(undefined)
      expiryDate.markAsDirty()
    })
  }

  removeBatch(index: number) {
    this.productForm.controls.batches.controls.splice(index, 1)
    this.productForm.controls.batches.markAsDirty()
  }

  /**
   * Start deal calculations
   */
  private doCalculationsForReal() {
    this.invoices$.pipe(take(1), switchMap(invoices =>
      this.DealFormCalculator.doCalculationsForm(this.dealForm, invoices)),
    ).subscribe()
  }

}

export function establishmentAddressOptions(
  detailsForm: DealDetailsFormGroup,
  productForm: DealProductFormGroup,
): Observable<AddressFieldPickerOptions> {
  return combineLatest([replayForm(detailsForm), replayForm(productForm)]).pipe(map(([deal, product]) => ({
    accountId: deal.supplierId,
    addresses: product.establishments,
    title: 'Select Establishment',
    showEstablishment: true,
    allowMultiple: true,
    limit: 3,
  })))
}

export function invoiceAddressOptions(
  detailsForm: DealDetailsFormGroup,
  productForm: DealProductFormGroup,
): Observable<AddressFieldPickerOptions> {
  return combineLatest([replayForm(detailsForm), replayForm(productForm)]).pipe(map(([deal, product]) => ({
    accountId: deal.buyerId,
    address: product.invoiceAddress,
    title: 'Select Invoice Address',
  })))
}
