import { TableKey } from '@tradecafe/types/core'
import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'

export type InvoicesListColumn =
| 'select'
| 'buyerInvoicesMenu'
| 'payablesMenu'
| 'vendorInvoicesMenu'
| 'ant_liability_date'
| 'act_liability_date'
| 'rowType'
| 'actl_amount_cad'
| 'actl_amount'
| 'actl_pmt_date'
| 'actualized'
| 'actualized_by'
| 'batch_id'
| 'buyer_acc'
| 'buyer'
| 'buying_trader'
| 'brokerage_trader'
| 'company_type'
| 'company'
| 'coordinator'
| 'cost_names'
| 'created'
| 'currency'
| 'deal_id'
| 'deal_status'
| 'due_date'
| 'escalated_date'
| 'est_amount'
| 'inv'
| 'invoice_id'
| 'issued'
| 'net_payable'
| 'note_warning'
| 'partial_margin_p'
| 'partial_margin'
| 'payment_type'
| 'product_category'
| 'product_type'
| 'product'
| 'rmng_amount'
| 'sched_pmt_date'
| 'selling_trader'
| 'status'
| 'sum_pmts_applied'
| 'supplier'
| 'user'
| 'variance'
| 'vendor_invoice_id'
| 'dealInvoiceMenu'


const INVOICE_COLUMNS: ColumnDef<InvoicesListColumn>[] = [
  { field: 'select',               displayName: '', internal: true, index: -1 },
  { field: 'buyerInvoicesMenu',    displayName: '', internal: true, index: 1001 },
  { field: 'payablesMenu',         displayName: '', internal: true, index: 1001 },
  { field: 'vendorInvoicesMenu',   displayName: '', internal: true, index: 1001 },
  { field: 'dealInvoiceMenu',      displayName: '', internal: true, index: 1001 },

  { field: 'ant_liability_date',   displayName: 'Ant. Liab. Date',          exportField: 'attributes.ant_liability_date',   exportPipe: 'epoch' },
  { field: 'act_liability_date',   displayName: 'Act. Liab. Date',          exportField: 'viewEx.act_liability_date',       exportPipe: 'epoch' },
  { field: 'rowType',              displayName: 'Type',                     exportField: 'viewEx.rowType',                  exportPipe: '' },

  { field: 'actl_amount_cad',      displayName: 'Actual Amt. CAD',          exportField: 'view.actl_amount_cad',            exportPipe: 'number' },
  { field: 'actl_amount',          displayName: 'Actual Amt.',              exportField: 'view.actl_amount',                exportPipe: 'number' },
  { field: 'actl_pmt_date',        displayName: 'Actual Pmt Date',          exportField: 'attributes.paid',                 exportPipe: 'epoch:L:utc' },
  { field: 'actualized',           displayName: 'Actualized',               exportField: 'attributes.actualized',           exportPipe: 'epoch:L:utc' },
  { field: 'actualized_by',        displayName: 'Actualized by',            exportField: 'actualized_by_name',              exportPipe: '' },
  { field: 'batch_id',             displayName: 'Batch #',                  exportField: 'batch_id',                        exportPipe: '' },
  { field: 'buyer_acc',            displayName: 'Acct #',                   exportField: 'view.company.account',            exportPipe: '' },
  { field: 'buyer',                displayName: 'Buyer',                    exportField: 'view.buyer.name',                 exportPipe: '' },
  { field: 'buying_trader',        displayName: 'Buying Trader',            exportField: 'view.buying_trader.fullname',     exportPipe: '' },
  { field: 'brokerage_trader',     displayName: 'Brokerage Trader',         exportField: 'view.brokerage_trader.fullname',  exportPipe: '' },
  { field: 'company_type',         displayName: 'Type',                     exportField: 'view.company.type',               exportPipe: '' },
  { field: 'company',              displayName: 'Company',                  exportField: 'view.company.name',               exportPipe: '' },
  { field: 'coordinator',          displayName: 'Logistics Coord',          exportField: 'view.coordinator.fullname',       exportPipe: '' },
  { field: 'cost_names',           displayName: 'Product/Service',          exportField: 'view.cost_names',                 exportPipe: '' },
  { field: 'created',              displayName: 'Created',                  exportField: 'created',                         exportPipe: 'epoch:L:utc' }, // we were exporting L LT on buyer invoice and traders vendor invoice pages
  { field: 'currency',             displayName: 'Currency',                 exportField: 'currency',                        exportPipe: '' },
  { field: 'deal_id',              displayName: 'Deal #',                   exportField: 'deal_id',                         exportPipe: '' },
  { field: 'deal_status',          displayName: 'Deal Status',              exportField: 'view.status',                     exportPipe: '' },
  { field: 'due_date',             displayName: 'Due',                      exportField: 'due',                             exportPipe: 'epoch:L:utc' },
  { field: 'escalated_date',       displayName: 'Escalation Date',          exportField: 'attributes.escalated',            exportPipe: 'epoch:L LT:utc' },
  { field: 'est_amount',           displayName: 'Estimated Amt.',           exportField: 'view.est_amount',                 exportPipe: 'number' },
  { field: 'inv',                  displayName: 'Inv',                      exportField: 'inv',                             exportPipe: '' },
  { field: 'invoice_id',           displayName: 'Invoice #',                exportField: 'invoice_id',                      exportPipe: '' },
  { field: 'issued',               displayName: 'Issued',                   exportField: 'issued',                          exportPipe: 'epoch:L:utc' },
  { field: 'net_payable',          displayName: 'Net Payable',              exportField: 'view.net_payable',                exportPipe: 'number' },
  { field: 'note_warning',         displayName: 'Notes',                    exportField: 'view.notes.body',                 exportPipe: '' },
  { field: 'partial_margin_p',     displayName: 'Partial Margin (%)',       exportField: 'view.partial_margin_p',           exportPipe: 'percent' },
  { field: 'partial_margin',       displayName: 'Partial Margin (CAD)',     exportField: 'view.partial_margin',             exportPipe: 'number' },
  { field: 'payment_type',         displayName: 'Payment Type',             exportField: 'attributes.payment_type',         exportPipe: '' },
  { field: 'product_category',     displayName: 'Product Category',         exportField: 'view.product.category.name',      exportPipe: '' },
  { field: 'product_type',         displayName: 'Product Type',             exportField: 'view.product.product_type.name',  exportPipe: '' },
  { field: 'product',              displayName: 'Product',                  exportField: 'view.product.name',               exportPipe: '' },
  { field: 'rmng_amount',          displayName: 'Remaining Amt.',           exportField: 'view.rmng_amount',                exportPipe: 'number' },
  { field: 'sched_pmt_date',       displayName: 'Sched. Pmt. Date',         exportField: 'attributes.scheduled_date',       exportPipe: 'epoch:L:utc' },
  { field: 'selling_trader',       displayName: 'Trader',                   exportField: 'view.selling_trader.fullname',    exportPipe: '' },
  { field: 'status',               displayName: 'Invoice Status',           exportField: 'status',                          exportPipe: 'invoiceStatus' },
  { field: 'sum_pmts_applied',     displayName: 'Sum Pmts Applied',         exportField: 'view.sum_pmts_applied',           exportPipe: '' },
  { field: 'supplier',             displayName: 'Supplier',                 exportField: 'view.supplier.name',              exportPipe: '' },
  { field: 'user',                 displayName: 'User',                     exportField: 'view.user.fullname',              exportPipe: '' },
  { field: 'variance',             displayName: 'Variance',                 exportField: 'view.variance',                   exportPipe: 'number' },
  { field: 'vendor_invoice_id',    displayName: 'Vendor Invoice #',         exportField: 'vendor_invoice_id',               exportPipe: '' },
]

export const INVOICE_COLUMNS_BY_ID = keyBy(INVOICE_COLUMNS, 'field')
export const INVOICE_COLUMNS_INTERNAL = INVOICE_COLUMNS.filter(col => col.internal).map(col => col.field)

const INVOICE_COLUMN_NAMES = mapValues(keyBy(INVOICE_COLUMNS, 'field'), 'displayName')
const INVOICE_COLUMN_OVERRIDES: Partial<Record<TableKey, Partial<Record<InvoicesListColumn, string>>>> = {
  [TableKey.ShippingDetailsInvoices]: {
    'company': 'Vendor',
    'status': 'Status',
    'actl_amount': 'Amt. Invoiced',
  },
  [TableKey.TradingVendorInvoices]: {
    'user': 'Creator',
    'buyer': 'Customer',
  },
  [TableKey.TradingBuyerInvoices]: {
    'created': 'Invoice Date',
    'status': 'Status',
  },
  [TableKey.LogisticsVendorInvoices]: {
    'user': 'Creator',
    'buyer': 'Customer',
  },
  [TableKey.LogisticsBuyerInvoices]: {
    'created': 'Invoice Date',
    'status': 'Status',
  },
  [TableKey.FinancialBuyerInvoices]: {
    'created': 'Invoice Date',
    'status': 'Status',
  },
  [TableKey.ManagementBuyerInvoices]: {
    'created': 'Invoice Date',
    'status': 'Status',
  },
}

export function columnDefsById(tableKey: TableKey) {
  const displayNames = INVOICE_COLUMN_OVERRIDES[tableKey] || {}
  const columnDefs = INVOICE_COLUMNS.map(c =>
    displayNames[c.field]
      ? { ...c, displayName: displayNames[c.field] }
      : c)
  return keyBy(columnDefs, c => c.field)
}

export function columnNames(tableKey?: TableKey) {
  return {
    ...INVOICE_COLUMN_NAMES,
    ...INVOICE_COLUMN_OVERRIDES[tableKey],
  }
}

export const AVAILABLE_COLUMNS: Partial<Record<TableKey, InvoicesListColumn[]>> = {
  [TableKey.ShippingDetailsInvoices]: [
    'rowType',
    'company',
    'vendor_invoice_id',
    'invoice_id',
    'actl_amount',
    'est_amount',
    'net_payable',
    'status',
    'act_liability_date',
    'ant_liability_date',
    'sched_pmt_date',
    'actl_pmt_date',
    'dealInvoiceMenu',
  ],
  [TableKey.LogisticsBuyerInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id', // hidden
    'buying_trader', // hidden
    'selling_trader',
    'brokerage_trader', // hidden
    'company',
    'deal_id',
    'deal_status',
    'buyer', // hidden
    'supplier', // hidden
    'product', // hidden
    'product_category', // hidden
    'product_type', // hidden
    'currency',
    'est_amount',
    'actl_amount', // hidden
    'actl_amount_cad', // hidden
    'rmng_amount',
    'variance', // hidden
    'created',
    'due_date',
    'issued',
    'sched_pmt_date', // hidden
    'status',
    'actl_pmt_date', // hidden
    'coordinator',
    'buyer_acc', // hidden
    'note_warning',
    'sum_pmts_applied', // hidden
    'user', // hidden
    'partial_margin', // hidden
    'partial_margin_p', // hidden
    'buyerInvoicesMenu',
  ],
  [TableKey.LogisticsVendorInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'buying_trader', // hidden
    'selling_trader',
    'buyer',
    'company',
    'deal_id',
    'deal_status',
    'cost_names', // hidden
    'currency',
    'est_amount',
    'actl_amount',
    'net_payable',
    'issued',
    'due_date',
    'status',
    'user',
    'created',
    'actualized', // hidden
    'actualized_by', // hidden
    'sched_pmt_date',
    'escalated_date', // hidden
    'actl_pmt_date',
    'coordinator',
    'buyer_acc', // hidden
    'partial_margin', // hidden
    'partial_margin_p', // hidden
    'inv', // hidden
    'note_warning',
    'vendorInvoicesMenu',
  ],
  [TableKey.TradingBuyerInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id', // hidden
    'buying_trader', // hidden
    'selling_trader',
    'brokerage_trader', // hidden
    'company',
    'deal_id',
    'deal_status',
    'buyer', // hidden
    'supplier', // hidden
    'product', // hidden
    'product_category', // hidden
    'product_type', // hidden
    'currency',
    'est_amount',
    'actl_amount', // hidden
    'actl_amount_cad', // hidden
    'rmng_amount',
    'variance', // hidden
    'created',
    'due_date',
    'issued',
    'sched_pmt_date', // hidden
    'status',
    'actl_pmt_date', // hidden
    'coordinator',
    'buyer_acc', // hidden
    'note_warning',
    'sum_pmts_applied', // hidden
    'user', // hidden
    'partial_margin', // hidden
    'partial_margin_p', // hidden
    'buyerInvoicesMenu',
  ],
  [TableKey.TradingVendorInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'buying_trader', // hidden
    'selling_trader',
    'buyer',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'actl_amount',
    'net_payable',
    'issued',
    'due_date',
    'status',
    'cost_names', // hidden
    'user',
    'created',
    'actualized', // hidden
    'actualized_by', // hidden
    'sched_pmt_date',
    'escalated_date', // hidden
    'actl_pmt_date',
    'coordinator',
    'buyer_acc', // hidden
    'note_warning',
    'partial_margin', // hidden
    'partial_margin_p', // hidden
    'vendorInvoicesMenu',
  ],
  [TableKey.FinancialBuyerInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id', // hidden
    // 'name', // deprecated
    'buying_trader', // hidden
    'selling_trader',
    'brokerage_trader', // hidden
    'company',
    'deal_id',
    'deal_status',
    'buyer', // hidden
    'supplier', // hidden
    'partial_margin_p', // hidden
    'partial_margin', // hidden
    'product', // hidden
    'product_category', // hidden
    'product_type', // hidden
    'currency',
    'est_amount',
    'actl_amount', // hidden
    'actl_amount_cad', // hidden
    'rmng_amount',
    'variance', // hidden
    // 'date', // deprecated
    'created',
    'due_date',
    'issued',
    'sched_pmt_date', // hidden
    'status',
    'actl_pmt_date', // hidden
    'coordinator',
    'buyer_acc', // hidden
    'note_warning',
    'sum_pmts_applied', // hidden
    'user', // hidden
    'buyerInvoicesMenu',
  ],
  [TableKey.FinancialPayables]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'batch_id',
    'selling_trader', // hidden
    'buying_trader', // hidden
    'company_type', // hidden
    'company',
    'deal_id', // hidden
    'deal_status',
    'buyer', // hidden
    'supplier', // hidden
    'partial_margin',
    'partial_margin_p', // hidden
    'product', // hidden
    'product_type', // hidden
    'product_category', // hidden
    'currency',
    'est_amount', // hidden
    'actl_amount',
    'actl_amount_cad',
    'net_payable',
    'variance',
    'issued',
    'due_date',
    'status',
    'payment_type',
    'cost_names', // hidden
    'user', // hidden
    'created',
    'actualized', // hidden
    'actualized_by',
    'sched_pmt_date',
    'escalated_date', // hidden
    'actl_pmt_date', // hidden
    'coordinator', // hidden
    'buyer_acc', // hidden
    'note_warning',
    'vendorInvoicesMenu',
  ],
  [TableKey.ManagementBuyerInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id', // hidden
    // 'name', // deprecated
    'buying_trader', // hidden
    'selling_trader',
    'brokerage_trader', // hidden
    'company',
    'deal_id',
    'deal_status',
    'buyer', // hidden
    'supplier', // hidden
    'partial_margin_p', // hidden
    'partial_margin', // hidden
    'product', // hidden
    'product_category', // hidden
    'product_type', // hidden
    'currency',
    'est_amount',
    'actl_amount', // hidden
    'actl_amount_cad', // hidden
    'rmng_amount',
    'variance', // hidden
    // 'date', // deprecated
    'created',
    'due_date',
    'issued',
    'sched_pmt_date', // hidden
    'status',
    'actl_pmt_date', // hidden
    'coordinator',
    'buyer_acc', // hidden
    'note_warning',
    'sum_pmts_applied', // hidden
    'user', // hidden
    'buyerInvoicesMenu',
  ],
  [TableKey.ManagementVendorInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'batch_id', // hidden
    'buying_trader', // hidden
    'selling_trader',
    'company_type',
    'company',
    'deal_id',
    'deal_status',
    'buyer', // hidden
    'supplier', // hidden
    'partial_margin', // hidden
    'partial_margin_p',
    'product', // hidden
    'cost_names', // hidden
    'product_type',  // hidden
    'product_category',  // hidden
    'currency',
    'est_amount',
    'actl_amount',
    'actl_amount_cad', // hidden
    'net_payable',
    'variance', // hidden
    'issued',
    'due_date',
    'status',
    'user', // hidden
    'created', // hidden
    'actualized', // hidden
    'actualized_by', // hidden
    'sched_pmt_date',
    'escalated_date', // hidden
    'payment_type', // hidden
    'actl_pmt_date',
    'coordinator',
    'buyer_acc', // hidden
    'note_warning',
    'vendorInvoicesMenu',
  ],
}

export const DEFAULT_COLUMNS: Partial<Record<TableKey, InvoicesListColumn[]>> = {
  [TableKey.ShippingDetailsInvoices]: [
    'rowType',
    'company',
    'vendor_invoice_id',
    'invoice_id',
    'actl_amount',
    'est_amount',
    'net_payable',
    'status',
    'act_liability_date',
    'ant_liability_date',
    'sched_pmt_date',
    'actl_pmt_date',
    'dealInvoiceMenu',
  ],
  [TableKey.LogisticsBuyerInvoices]: [
    'select',
    'invoice_id',
    'selling_trader',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'rmng_amount',
    'created',
    'due_date',
    'status',
    'coordinator',
    'note_warning',
    'buyerInvoicesMenu',
  ],
  [TableKey.LogisticsVendorInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'selling_trader',
    'buyer',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'actl_amount',
    'net_payable',
    'issued',
    'due_date',
    'status',
    'user',
    'created',
    'sched_pmt_date',
    'actl_pmt_date',
    'coordinator',
    'note_warning',
    'vendorInvoicesMenu',
  ],
  [TableKey.TradingBuyerInvoices]: [
    'select',
    'invoice_id',
    'selling_trader',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'rmng_amount',
    'created',
    'due_date',
    'status',
    'coordinator',
    'note_warning',
    'buyerInvoicesMenu',
  ],
  [TableKey.TradingVendorInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'selling_trader',
    'buyer',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'actl_amount',
    'net_payable',
    'issued',
    'due_date',
    'status',
    'user',
    'created',
    'sched_pmt_date',
    'actl_pmt_date',
    'coordinator',
    'note_warning',
    'vendorInvoicesMenu',
  ],
  [TableKey.FinancialBuyerInvoices]: [
    'select',
    'invoice_id',
    'selling_trader',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'rmng_amount',
    'created',
    'due_date',
    'status',
    'coordinator',
    'note_warning',
    'buyerInvoicesMenu',
  ],
  [TableKey.FinancialPayables]: [
    'select',
    'company',
    'vendor_invoice_id',
    'invoice_id',
    'currency',
    'actl_amount',
    'net_payable',
    'partial_margin',
    'variance',
    'due_date',
    'status',
    'deal_status',
    'sched_pmt_date',
    'actualized',
    'created',
    'issued',
    'payment_type',
    'batch_id',
    'note_warning',
    'vendorInvoicesMenu',
  ],
  [TableKey.ManagementBuyerInvoices]: [
    'select',
    'invoice_id',
    'selling_trader',
    'company',
    'deal_id',
    'deal_status',
    'currency',
    'est_amount',
    'rmng_amount',
    'created',
    'due_date',
    'status',
    'coordinator',
    'note_warning',
    'buyerInvoicesMenu',
  ],
  [TableKey.ManagementVendorInvoices]: [
    'select',
    'invoice_id',
    'vendor_invoice_id',
    'selling_trader',
    'company_type',
    'company',
    'deal_id',
    'deal_status',
    'partial_margin_p',
    'currency',
    'est_amount',
    'actl_amount',
    'net_payable',
    'issued',
    'due_date',
    'status',
    'sched_pmt_date',
    'actl_pmt_date',
    'coordinator',
    'note_warning',
    'vendorInvoicesMenu',
  ],
}
