import { DecimalPipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { AccountObject, Invoice, INVOICE_ACTUALIZED, INVOICE_APPROVED, INVOICE_PAID, INVOICE_SCHEDULED, PaymentTermsDate } from '@tradecafe/types/core'
import { calculateTermDate, DeepReadonly } from '@tradecafe/types/utils'
import * as isBetweenPlugin from 'dayjs/plugin/isBetween'
import { compact, filter, find, flatten, get, groupBy, has, keyBy, map, sumBy, uniq } from 'lodash-es'
import { AccountApiService } from 'src/api/account'
import { AuthApiService } from 'src/api/auth'
import { InvoiceApiService } from 'src/api/invoice'
import { ProductApiService } from 'src/api/product/product'
import { ProductTypeApiService } from 'src/api/product/type'
import { UserApiService } from 'src/api/user'
import { DateRangeSelectModalService } from 'src/components/date-range-select/date-range-select-modal.service'
import { extendUnixRange, toUnixRange } from 'src/directives/epoch-range/epoch-range.utils'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { DealCalculatorService } from 'src/services/data/deal-calculator.service'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import { dayjs } from '../dayjs'
import { ExcelService } from '../excel-download'
import { CostsService } from './costs.service'
import { allPagesBy } from './utils'

dayjs.extend(isBetweenPlugin)

/**
 * Invoices export service: WA-3075
 *
 * @see https://docs.google.com/document/d/1EaTyv23coC3G34JMh-foYsRXKy24EK9n3COdKKimVVs/edit
 *
 */
@Injectable()
export class InvoicesExportService {
  constructor(
    private decimalPipe: DecimalPipe,
    private AuthApi: AuthApiService,
    private InvoiceApi: InvoiceApiService,
    private Costs: CostsService,
    private AccountApi: AccountApiService,
    private UserApi: UserApiService,
    private ProductApi: ProductApiService,
    private ProductTypeApi: ProductTypeApiService,
    private Excel: ExcelService,
    private DateRangeSelectModal: DateRangeSelectModalService,
    private DealViewLoader: DealViewLoaderService,
    private DealCalculator: DealCalculatorService,
    private Measures: MeasuresService,
  ) { }

  private async exportActualizedVendorCosts(vendors: DeepReadonly<AccountObject[]>, { start, end, type, name }) {
    const statuses = [INVOICE_ACTUALIZED, INVOICE_APPROVED, INVOICE_SCHEDULED, INVOICE_PAID]
    const [invoices, relatedData] = await Promise.all([
      allPagesBy(1000, (({ skip, limit }) => this.InvoiceApi.elasticFetch({
        query: {
          type: 'payable',
          status: statuses,
          actualized: { from: start, to: end },
        },
        skip, limit,
      }).toPromise())),
      this.InvoiceApi.elasticFilters({
        query: {
          type: 'payable',
        },
        columns: [],
      }).toPromise(),
    ])
    const vendorsById = keyBy(vendors, 'account')

    const providerInvoices = invoices
    .map(dto => dto.invoice)
    .filter(invoice =>
      invoice.attributes?.actualized &&
      vendorsById[invoice.account] &&
      invoice.attributes?.costs?.length)

    const costIds = compact(uniq(flatten(map(providerInvoices, (item: Invoice) => {
      return map(item.attributes?.costs, 'cost_id')
    }))))
    const dealIds = compact(uniq(map(providerInvoices, (item: Invoice) => item.deal_id)))

    const [costsById, dealViews] = await Promise.all([
      this.Costs.getCostsByIds(costIds),
      this.DealViewLoader.fetchDealsWith(dealIds, []),
      this.Measures.getMeasures(),
    ])

    const headers = [
      'Document Type', 'Vendor Invoice No.', 'Vendor Name', 'Posting Date', 'Document Date', 'Customer No.',
      'Customer Dimension', 'Currency', 'Currency Exchange Rate', 'Payment Term', 'Payment Term Days', 'Deal Dimension',
      'Trader Dimension', 'Product Dimension', 'Country Dimension', 'Total Invoice Amount', 'Description', 'Quantity',
      'Amount', 'Customer Name Line', 'Deal Line', 'Trader Code Line', 'Product Code Line', 'Country Line', 'Estimated Amount',
      'Partial Margin', 'Document No.',
    ]

    const { buyer: buyers, product_type: productTypes, buying_trader, selling_trader } = relatedData
    const buyersById = keyBy(buyers, 'id')
    const productTypesById = keyBy(productTypes, 'id')
    const buyingTradersById = keyBy(buying_trader, 'id')
    const sellingTradersById = keyBy(selling_trader, 'id')
    const DealViewsById = keyBy(dealViews, 'deal_id')

    let dataToDownload = await Promise.all(map(providerInvoices, async (invoice) => {
      return this.invoiceCostsMapping(vendorsById, {
        buyersById,
        productTypesById,
        buyingTradersById,
        sellingTradersById,
        costsById,
        DealViewsById,
      }, invoice, type).catch((e) => {
        console.log(e)
        return []
      })
    }))
    dataToDownload = flatten(compact(dataToDownload))
    dataToDownload.unshift(headers)

    // export as csv
    // await downloadAsCSV(data, name)
    this.Excel.download(dataToDownload, name)
  }

  async exportActualizedServiceProviderCosts() {
    const range = await this.DateRangeSelectModal.show({ title: 'Select Actualized Date Range' })
    const [from, to] = extendUnixRange(toUnixRange(range, true), true)

    const providers = await this.AccountApi.listProviders({ limit: Number.MAX_SAFE_INTEGER })
      .then(({ data }) => data)
    await this.exportActualizedVendorCosts(providers, {
      start: from,
      end: to,
      type: 'provider',
      name: 'Actualized Service Provider Costs',
    })
  }

  async exportActualizedSupplierCosts() {
    const range = await this.DateRangeSelectModal.show({ title: 'Select Actualized Date Range' })
    const [from, to] = extendUnixRange(toUnixRange(range, true), true)

    const suppliers = await this.AccountApi.listSuppliers({ limit: Number.MAX_SAFE_INTEGER })
      .then(({ data }) => data)
    await this.exportActualizedVendorCosts(suppliers, {
      start: from,
      end: to,
      type: 'supplier',
      name: 'Actualized Supplier Costs',
    })
  }

  async exportApprovedBuyerInvoices() {

    let { dateStart: start, dateEnd: end } = await this.DateRangeSelectModal.show({ title: 'Select Approved Date Range' })

    const format = 'YYYYMMDD'
    start = dayjs.utc(dayjs(start).format(format), format)
    end = dayjs.utc(dayjs(end).format(format), format)

    const { account } = this.AuthApi.currentUser
    const statuses = [INVOICE_ACTUALIZED, INVOICE_APPROVED, INVOICE_SCHEDULED, INVOICE_PAID]
    const actualizedInvoices = await this.InvoiceApi.listByStatuses(account, statuses, {
      limit: Number.MAX_SAFE_INTEGER,
    }).then(({ data }) => data)

    const groupByType = groupBy(actualizedInvoices, 'type')
    const vendorInvoicesById = groupBy(groupByType['payable'], 'account')
    const approvedBuyerInvoices = filter(groupByType['receivable'], (item) => {
      return dayjs.utc(item.created * 1000).isBetween(start, end, 'day', '[]')
    })

    const headers = [
      'Document Type', 'Document No.', 'Posting Date', 'Document Date', 'Customer No.',
      'Customer Dimension', 'Currency', 'Currency Exchange Rate', 'Due Date', 'Payment Term Days', 'Deal Dimension',
      'Trader Dimension', 'Product Dimension', 'Country Dimension', 'Total Invoice Amount', 'Description', 'Quantity',
      'Amount', 'Customer Name Line', 'Deal Line', 'Trader Code Line', 'Product Code Line', 'Country Line',
    ]

    let dataToDownload = await Promise.all(map(approvedBuyerInvoices, async (invoice) => {
      return this.buyerInvoiceMapping(invoice, vendorInvoicesById).catch((e) => {
        console.log(e)
        return []
      })
    }))
    dataToDownload = flatten(compact(dataToDownload))
    dataToDownload.unshift(headers)

    // export as csv
    // await downloadAsCSV(data, 'Approved Buyer Invoices')
    this.Excel.download(dataToDownload, 'Approved Buyer Invoices')
  }

  private formatUTCEpoch(epoch) {
    if (!epoch) {
      return ''
    }
    return dayjs.utc(epoch * 1000).format('DD-MM-YYYY')
  }

  private formatNumber(num) {
    if (!num) return ''
    return this.decimalPipe.transform(num, '1.2-2')
  }

  private getExchangeRateForDeal(deal, currency, askBid = 'ask') {
    if (currency === 'CAD') return 1

    const fxRates = get(deal, 'attributes.fx_rates')
    const fxRatesRange = get(deal, `attributes.fx_rates_${askBid}_range`)

    const res = get(fxRates, `rates['${currency}']['${fxRatesRange}'][${askBid}]`)
    if (!res) return undefined

    return parseFloat(fxRates.rates[currency][fxRatesRange][`${askBid}`])
  }


  private async invoiceCostsMapping(vendorsById, relatedData, item, type) {
    const {
      costsById,
      buyersById,
      productTypesById,
      buyingTradersById,
      sellingTradersById,
      DealViewsById,
    } = relatedData

    let costs = map(get(item, 'attributes.costs', []), (cost) => costsById[cost.cost_id])
    costs = compact(costs)
    if (!costs.length) {
      return null
    }
    const vendor = vendorsById[item.account]

    const deal = DealViewsById[item.deal_id]
    if (!deal) {
      return null
    }

    const firstDealProduct = deal?.products[0]

    const [productType, trader, buyer] = await Promise.all([
      productTypesById[firstDealProduct.type_id],
      type === 'supplier' ? buyingTradersById[deal?.trader_user_id_supplier] : sellingTradersById[deal?.trader_user_id],
      buyersById[deal?.buyer_id],
      this.DealCalculator.updateFxRates(deal),
    ])
    const offer = firstDealProduct.offer
    const estimatedCostTotal = sumBy(costs, 'amount.total')
    // tslint:disable-next-line: cyclomatic-complexity
    return map(costs, (cost) => {

      // const actual_weight = get(cost, 'attributes.actual_buy_weight') || get(offer, 'weight.amount')
      // const actual_price = get(cost, 'attributes.actual_buy_price') || get(offer, 'price')

      return [
        'Invoice', // 'Document Type'
        item.vendor_invoice_id || '', // Vendor Invoice No.
        get(vendor, 'name', ''), // 'Vendor Name'
        has(item, 'attributes.actualized') ? this.formatUTCEpoch(get(item, 'attributes.actualized', '')) : '', // 'Posting Date'
        this.formatUTCEpoch(item.issued), // 'Document Date'
        type === 'provider' ? get(item, 'account', '') : get(deal, 'supplier_id', ''), // 'Customer No.'
        get(buyer, 'name', ''), // 'Customer Dimension'
        item.currency, // 'Currency'
        this.getExchangeRateForDeal(deal, item.currency) || '', // 'Currency Exchange Rate'
        this.formatUTCEpoch(item.due), // 'Payment Term'
        Math.ceil((item.due - item.issued) / 86400), // 'Payment Term Days'
        item.deal_id, // 'Deal Dimension'
        trader?.name, // 'Trader Dimension'
        get(productType, 'name', ''), // 'Product Dimension'
        get(deal, 'attributes.docs_country', ''), // 'Country Dimension'
        this.formatNumber(item.total), // 'Total Invoice Amount'
        get(cost, 'service', ''), // 'Description'
        cost.type !== 'primary' ? '1' : this.formatNumber(this.Measures.convert(get(cost, 'attributes.actual_buy_weight') || get(offer, 'weight.amount'), get(offer, 'weight.unit'), 'LB')), // 'Quantity'
        type === 'provider' ? this.formatNumber(item.total) : this.formatNumber(get(cost, 'attributes.actual_amount', '')), // 'Amount'
        get(buyer, 'name', ''), // 'Customer Name Line'
        item.deal_id, // 'Deal Line'
        trader?.name, // 'Trader Code Line'
        get(productType, 'name', ''), // 'Product Code Line'
        get(deal, 'attributes.docs_country', ''), // 'Country Line'
        this.formatNumber(estimatedCostTotal), // 'Estimated Amount'
        this.formatNumber(get(deal, 'attributes.actual.partial_margin', '') || 0), // 'Partial Margin',
        item.invoice_id,  // 'Document No.'
      ]
    })
  }

  private getDealPaymentTerm(deal) {
    // credit_type: "insurance"
    // days: 28
    // from_date: "bill_of_landing_date"
    // payment_method_open: "wire_transfer"
    // type: "open"
    const terms = get(deal, 'attributes.buyer_payment_terms')

    let date
    let days = terms.days
    if (terms.type === 'secure') { // secure_type: "prepayment"
      const fromDate: PaymentTermsDate = terms.secure_type === 'prepayment' ? PaymentTermsDate.days_from_pickup_date : PaymentTermsDate.delivery_date
      date = calculateTermDate(deal, fromDate) // pickup date
      days = 0
    } else {
      date = calculateTermDate(deal, terms.from_date)
    }

    return { days, date }
  }


  private async buyerInvoiceMapping(item, actualizedVendorInvoicesByVendorId) {
    const { account } = this.AuthApi.currentUser

    const [deal] = await Promise.all([
      this.DealViewLoader.loadView(item.deal_id, ['costs', 'segments']),
    ])

    // supplier invoice should be actualized
    if (!actualizedVendorInvoicesByVendorId[deal.supplier_id]) {
      return null
    }

    const { days: paymentTermDays, date: paymentTermDate } = this.getDealPaymentTerm(deal)

    // tslint:disable-next-line: prefer-const
    let [products, buyerTrader, buyer] = await Promise.all([
      Promise.all(map(deal.products, async (product) => {
        product.productType = await this.ProductTypeApi.get(product.type_id).then(({ data }) => data)
        product.product = await this.ProductApi.get(product.product_id).then(({ data }) => data)
        return product
        // }), () => angular.noop), // TODO: ng1 - was it "swallow exception" instruction?
      })),
      this.UserApi.get(account, deal.trader_user_id).then(({ data }) => data),
      this.AccountApi.get(deal.buyer_id).then(({ data }) => data),
      this.DealCalculator.updateFxRates(deal),
      this.Measures.getMeasures(),
    ])
    products = compact(products)

    const traderName = `${get(buyerTrader, 'firstname', '')} ${get(buyerTrader, 'lastname', '')}`.trim()

    return map(products, ({ bid, product, productType }) => {
      const primaryCost = find(deal.costs, { type: 'primary', product_id: get(bid, 'product') })
      const actual_weight = get(primaryCost, 'attributes.actual_sell_weight', 0) || get(bid, 'weight.amount', 0)
      const actual_price = get(primaryCost, 'attributes.actual_sell_price', 0) || get(bid, 'price', 0)

      return [
        'Invoice', // 'Document Type'
        item.invoice_id, // 'Document No.'
        this.formatUTCEpoch(item.created), // 'Posting Date'
        this.formatUTCEpoch(paymentTermDate), // 'Document Date'
        get(buyer, 'account', ''), // 'Customer No.'
        get(buyer, 'name', ''), // 'Customer Dimension'
        item.currency, // 'Currency'
        this.getExchangeRateForDeal(deal, item.currency, 'bid') || '', // 'Currency Exchange Rate'
        this.formatUTCEpoch(paymentTermDate + paymentTermDays * 86400), // 'Due Date' 'Payment Term'
        paymentTermDays, // 'Payment Term Days'
        item.deal_id, // 'Deal Dimension'
        traderName, // 'Trader Dimension'
        get(product, 'name', ''), // 'Product Dimension'
        get(deal, 'attributes.docs_country', ''), // 'Country Dimension'
        this.formatNumber(item.total), // 'Total Invoice Amount'
        get(product, 'name', ''), // 'Description'
        this.formatNumber(this.Measures.convert(actual_weight, get(bid, 'weight.unit'), 'LB')), // 'Quantity'
        this.formatNumber(actual_price * actual_weight), // 'Amount'
        get(buyer, 'name', ''), // 'Customer Name Line'
        item.deal_id, // 'Deal Line'
        traderName, // 'Trader Code Line'
        get(productType, 'name', ''), // 'Product Code Line'
        get(deal, 'attributes.docs_country', ''), // 'Country Line'
      ]
    })
  }
}
