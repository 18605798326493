import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'call',
  pure: true,
})
export class CallPipe implements PipeTransform {

  transform(fn: Function, ...args: any[]): any {
    return fn(...args)
  }

}
