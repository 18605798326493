<h2 mat-dialog-title>
  <mat-icon class="fa fa-ship"></mat-icon> {{ dealId }} Booking
  <span class="booking-status">{{ bookingStatusDisplayName$ | async }}</span>
  <button mat-icon-button class="note-icon"
          title="Rejection reason"
          (click)="showMontshipRejectionNotes()"
          *ngIf="(bookingStatus$ | async) === 'rejected'"
    ><mat-icon class="fa fa-2x fa-note-sticky"></mat-icon>
  </button>
</h2>
<div mat-dialog-content>
  <table class="table modal-table" *ngrxLet="displayedDiff$ as diff">
    <tr>
      <th class="col-head bd-bottom"></th>
      <th class="col-head bd-bottom">Booking Request</th>
      <th class="col-head bd-bottom">Current TC Value</th>
      <th class="col-head bd-bottom">Montship Confirmation</th>
    </tr>
    <tr>
      <th class="bd-bottom">Pickup date</th>
      <td class="bd-bottom">{{ diff?.request.pickupDate | epoch:'L':'utc' }}</td>
      <td class="bd-bottom">{{ diff?.current.pickupDate | epoch:'L':'utc' }}</td>
      <td class="bd-bottom">{{ diff?.response.pickupDate | epoch:'L':'utc' }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Pickup address</th>
      <td class="bd-bottom location">{{ diff?.request.pickupAddress }}</td>
      <td class="bd-bottom location">{{ diff?.current.pickupAddress }}</td>
      <td class="bd-bottom">—</td>
    </tr>
    <tr>
      <th class="bd-bottom">Pickup location</th>
      <td class="bd-bottom location">{{ diff?.request.pickupLocation }}</td>
      <td class="bd-bottom location">{{ diff?.current.pickupLocation }}</td>
      <td class="bd-bottom">{{ diff?.response.pickupLocation }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">ETD</th>
      <td class="bd-bottom">{{ diff?.request.etd | epoch:'L':'utc'}}</td>
      <td class="bd-bottom">{{ diff?.current.etd | epoch:'L':'utc'}}</td>
      <td class="bd-bottom">{{ diff?.response.etd | epoch:'L':'utc'}}</td>
    </tr>
    <tr>
      <th class="bd-bottom">ETA</th>
      <td class="bd-bottom">—</td>
      <td class="bd-bottom">{{ diff?.current.eta | epoch:'L':'utc'}}</td>
      <td class="bd-bottom">{{ diff?.response.eta | epoch:'L':'utc'}}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Origin</th>
      <td class="bd-bottom">{{ diff?.request.origin }}</td>
      <td class="bd-bottom">{{ diff?.current.origin }}</td>
      <td class="bd-bottom">{{ diff?.response.origin }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Port of Loading</th>
      <td class="bd-bottom">—</td>
      <td class="bd-bottom">{{ diff?.current.portOfLoading }}</td>
      <td class="bd-bottom">{{ diff?.response.portOfLoading }}</td>
    </tr>
    <tr>
    <th class="bd-bottom">Port of Discharge</th>
    <td class="bd-bottom">—</td>
    <td class="bd-bottom">{{ diff?.current.portOfDischarge }}</td>
    <td class="bd-bottom">{{ diff?.response.portOfDischarge }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Destination</th>
      <td class="bd-bottom">{{ diff?.request.destination }}</td>
      <td class="bd-bottom">{{ diff?.current.destination }}</td>
      <td class="bd-bottom">{{ diff?.response.destination }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Vessel Name</th>
      <td class="bd-bottom">—</td>
      <td class="bd-bottom">{{ diff?.current.vesselName }}</td>
      <td class="bd-bottom">{{ diff?.response.vesselName }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Booking Number</th>
      <td class="bd-bottom">—</td>
      <td class="bd-bottom">{{ diff?.current.bookingNumber }}</td>
      <td class="bd-bottom">{{ diff?.response.bookingNumber }}</td>
    </tr>
    <tr>
      <th class="bd-bottom">Final Destination</th>
      <td class="bd-bottom">{{ diff?.request.finalDestination }}</td>
      <td class="bd-bottom">{{ diff?.current.finalDestination }}</td>
      <td class="bd-bottom">—</td>
    </tr>
  </table>
  <div class="booking-scrolling-line">
    <button [disabled]="(isOldestResponse$ | async) || (inProgress$ | async)" mat-button (click)="seeOlder()">See older response</button>
    <button [disabled]="(isMostRecentResponse$ | async) || (inProgress$ | async)" mat-button (click)="seeNewer()">See newer response</button>
  </div>
</div>
<mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
  <button [disabled]="(cannotApproveReject$ | async) || (inProgress$ | async)" mat-raised-button (click)="reject()" color="warn">Reject</button>
  <button [disabled]="(cannotPutOnHold$ | async) || (inProgress$ | async)" mat-raised-button (click)="putOnHold()" color="primary">Put on hold</button>
  <button [disabled]="(cannotApproveReject$ | async) || (inProgress$ | async)" mat-raised-button (click)="approve()" color="primary">Approve</button>
</div>
