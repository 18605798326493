import { Injectable } from '@angular/core'
import { isDeliveryNoticeDocument, isExportInstruction, isLoadingInstruction, isTruckingInstruction } from '@tradecafe/types/utils'
import { compact, filter, flatten, get, keyBy, map, reduce, uniqBy } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { CustomTagsService } from 'src/pages/admin/settings/custom-tags/custom-tags.service'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import {
  accountsResolver, businessTypesResolver, carriersResolver, itemTypesResolver,
  locationsResolver, measuresResolver, pricingTermsResolver,
  productCategoriesResolver, productsResolver, usersResolver, wrappingsResolver
} from 'src/services/data/deal.resolvers'
import { ToastedError } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { ModalProxyService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'

export const LOADING_INSTRUCTION_TYPE = 'loading_instruction'
export const TRUCKING_INSTRUCTION_TYPE = 'trucking_instruction'
export const SUPPLIER_EXPORT_INSTRUCTION_TYPE = 'supplier_export_instruction'
export const BUYER_BATCHED_INSTRUCTION_TYPE = 'buyer_batched_instruction'

@Injectable()
export class CreateInstructionsFormService {
  constructor(
    private modalHelper: ModalProxyService,
    private toaster: ToasterService,
    private DealDocuments: DealDocumentsService,
    private CustomTags: CustomTagsService,
    private AuthApi: AuthApiService,
    private Carriers: CarriersService,
  ) {}

  private create(deals, options) {
    return this.modalHelper.showModal({
      component: 'tcCreateInstructionsForm',
      windowClass: 'modalShipping',
      size: 'xlg',
      backdrop: 'static',
      resolve: {
        deals: () => deals,
        ...reduce(options, (acc, value, key) => {
          acc[key] = () => value
          return acc
        }, {}),
        accounts: accountsResolver,
        businessTypes: businessTypesResolver,
        carriers: carriersResolver,
        categories: productCategoriesResolver,
        itemTypes: itemTypesResolver,
        locations: locationsResolver,
        measures: measuresResolver,
        pricingTerms: pricingTermsResolver,
        products: productsResolver,
        users: usersResolver,
        wrappings: wrappingsResolver,
        customTags: () => this.CustomTags.loadCustomTagsFor(deals[0]),
      },
    })
  }

  private loadInstructionTemplatesForDeals(deals) {
    return this.DealDocuments.getDocSetForDeal(deals[0]).toPromise().catch((err) => {
      if (!(err instanceof ToastedError)) {
        console.error('Unable to get documents set', err)
        this.toaster.error('Unable to get documents set', err)
      }
      throw err
    })
  }

  private async getCarrierAccountIds(deals) {
    const carriers = await this.Carriers.getCarriers()
    const carriersById = keyBy(carriers, 'carrier_id')
    const carrierIds = compact(map(flatten(map(deals, 'segments')), 'carrier_id'))

    return map(map(carrierIds, id => carriersById[id]), 'account')
  }

  async createLoadingInstruction(deals) {
    const docs = await this.loadInstructionTemplatesForDeals(deals)
    const carrierAccountIds = await this.getCarrierAccountIds(deals)
    const { account } = this.AuthApi.currentUser
    return this.create(deals, {
      isLoadingInstruction: true,
      type: LOADING_INSTRUCTION_TYPE,
      modalSetting: {title: 'Create Loading Instruction'},
      filename: `Loading_Instructions_${get(deals, '[0].carrier.name', '')}_${dayjs().format('YYYY-MM-DD')}`,
      availableColumns: [
        {id: 'deal_id', default: true},
        {id: 'booking_id', default: true},
        {id: 'vessel', default: true},
        {id: 'container_id'},
        {id: 'epd'},
        {id: 'load_date', default: true},
        {id: 'load_time', default: true},
        {id: 'erd'},
        {id: 'cutoff_date', default: true},
        {id: 'cutoff_time', default: true},
        {id: 'etd', default: true},
        {id: 'product'},
        {id: 'pickup_ref'},
        {id: 'supplier_ref'},
        {id: 'establishments'},
        {id: 'est_product_weight'},
        {id: 'delivery_date'},
        {id: 'delivery_time'},
        {id: 'eta'},
      ],
      documents: filter(docs, isLoadingInstruction),
      receivers: [...filter(uniqBy([...map(deals, 'supplier_id'), ...carrierAccountIds], i => `${i}`)), account],
    })
  }

  async createTruckingInstruction(deals) {
    const docs = await this.loadInstructionTemplatesForDeals(deals)
    const carrierAccountIds = await this.getCarrierAccountIds(deals)
    const { account } = this.AuthApi.currentUser
    return this.create(deals, {
      isTruckingInstruction: true,
      type: TRUCKING_INSTRUCTION_TYPE,
      modalSetting: {title: 'Create Trucking Instructions'},
      filename: `Trucking_Instructions_${get(deals, '[0].carrier.name', '')}_${dayjs().format('YYYY-MM-DD')}`,
      availableColumns: [
        {id: 'deal_id', default: true},
        {id: 'booking_id'},
        {id: 'container_id'},
        {id: 'refer_id'},
        {id: 'load_date', default: true},
        {id: 'load_time', default: true},
        {id: 'delivery'},
        {id: 'product'},
        {id: 'pick_up_address'},
        {id: 'delivery_address'},
        {id: 'pickup_ref'},
        {id: 'supplier_ref'},
        {id: 'establishments'},
        {id: 'est_product_weight'},
        {id: 'delivery_date'},
        {id: 'delivery_time'},
      ],
      documents: filter(docs, isTruckingInstruction),
      receivers: [...filter(uniqBy(carrierAccountIds, i => `${i}`)), account],
    })
  }

  async createSupplierExportInstruction(deals) {
    const docs = await this.loadInstructionTemplatesForDeals(deals)
    const { account } = this.AuthApi.currentUser
    return this.create(deals, {
      isSupplierExportInstruction: true,
      type: SUPPLIER_EXPORT_INSTRUCTION_TYPE,
      modalSetting: {title: 'Create Supplier Export Instructions'},
      filename: `Supplier_Export_Instruction_${dayjs().format('YYYY-MM-DD')}`,
      availableColumns: [
        {id: 'deal_id', default: true},
        {id: 'load_date', default: true},
        {id: 'load_time', default: true},
        {id: 'product'},
        {id: 'pick_up_address'},
        {id: 'carrier'},
        {id: 'pickup_ref'},
        {id: 'supplier_ref'},
        {id: 'establishments'},
        {id: 'est_product_weight'},
        {id: 'delivery_date'},
        {id: 'delivery_time'},
        {id: 'eta'},
      ],
      documents: filter(docs, isExportInstruction),
      receivers: [...filter(uniqBy([...map(deals, 'supplier_id')], i => `${i}`)), account],
    })
  }

  async createBuyerBatchedInstruction(deals) {
    const docs = await this.loadInstructionTemplatesForDeals(deals)
    const { account } = this.AuthApi.currentUser
    return this.create(deals, {
      isBuyerBatchedInstruction: true,
      type: BUYER_BATCHED_INSTRUCTION_TYPE,
      modalSetting: {title: 'Create Buyer Delivery Notification'},
      filename: `Buyer_Delivery_Notification_${dayjs().format('YYYY-MM-DD')}`,
      availableColumns: [
        {id: 'deal_id', default: true},
        {id: 'product_type_name', default: true},
        {id: 'delivery_date', default: true},
        {id: 'eta', default: true},
        {id: 'exact_dropoff_address', default: true},
        {id: 'last_segment_delivery_date'},
        {id: 'delivery_time'},
        {id: 'forwarder'},
      ],
      documents: filter(docs, isDeliveryNoticeDocument),
      receivers: [
        ...filter(uniqBy([...map(deals, 'buyer_id')], i => `${i}`)),
        ...filter(uniqBy(flatten(deals.map(d => d.segments.map(s => s?.attributes?.exact_dropoff?.account))), i => `${i}`)),
        account],
    })
  }
}
