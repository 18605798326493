import { AccountObject, AllInvoiceStatuses, CreditNote, DealViewRawCosts, DealViewRawCreditNotes, DealViewRawDeal, DealViewRawFiles, DealViewRawInvoices, DealViewRawVendorCredits, Invoice, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly, getAvgReceiptDate } from '@tradecafe/types/utils'
import { compact, flatten, last, sumBy, uniq } from 'lodash-es'
import { Observable, combineLatest, from } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { InvoiceRow } from 'src/api/invoice'
import { actualCollectionDate } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/trading-summary/trading-summary.component'
import { getAntDueDate } from 'src/services/data/deal-form-calculator.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { VendorCreditsService } from 'src/services/data/vendor-credits.service'
import { SimpleDataSource } from './simple-data-source'


const CREDIT_NOTE = 'Credit Note'
const VENDOR_CREDIT = 'Vendor Credit'
const PREPAYMENT_CREDIT = 'Prepayment Credit'

type DV = DealViewRawDeal & DealViewRawCosts & DealViewRawInvoices & Partial<DealViewRawCreditNotes & DealViewRawVendorCredits & DealViewRawFiles>

export class DealInvoicesDataSource extends SimpleDataSource<InvoiceRow> {

  constructor (
    dealViewRaw$: Observable<DeepReadonly<DV>>,
    accounts$: Observable<DeepReadonly<Dictionary<AccountObject>>>,
    VendorCredits: VendorCreditsService,
    Invoices: InvoicesService,
  ) {
    const appliedVendorCredits$ = dealViewRaw$.pipe(
      map(dv => dv.invoices?.map(i => i.invoice_id) || []),
      distinctUntilChanged(),
      switchMap(invoiceIds => VendorCredits.getVendorCreditsByInvoiceIds(invoiceIds)))

    const vendorCreditInvoices$ = dealViewRaw$.pipe(
      map(dv => uniq(compact(dv.vendor_credits?.map(i => i.invoice_id) || []))),
      distinctUntilChanged(),
      switchMap(invoiceIds => from(Invoices.getByIds(invoiceIds))))

    super(combineLatest([
      accounts$, dealViewRaw$, appliedVendorCredits$, vendorCreditInvoices$,
    ]).pipe(map(([accounts, dv, appliedVendorCredits, vendorCreditInvoices]) => flatten(compact([
      dv.invoices?.map(invoice =>
        buildInvoiceRow(dv, invoice, accounts, appliedVendorCredits)),
      dv.credit_notes?.map(creditNote =>
        buildCreditRow(dv, {creditNote}, vendorCreditInvoices, accounts)),
      dv.vendor_credits?.map(vendorCredit =>
        buildCreditRow(dv, {vendorCredit}, vendorCreditInvoices, accounts)),
    ])))))
  }
}

function buildInvoiceRow(
  dv: DeepReadonly<DV>,
  invoice: DeepReadonly<Invoice>,
  accounts: DeepReadonly<Dictionary<AccountObject>>,
  appliedVendorCredits: DeepReadonly<VendorCredit[]>,
) {
  appliedVendorCredits = appliedVendorCredits.filter(vc => vc.invoice_id === invoice.invoice_id)
  const vendorCreditTotal = sumBy(appliedVendorCredits, vc => vc.amount) || 0
  const creditNotes = compact(flatten(dv.costs?.map(c => c.attributes.credit_notes_ex)))

  const attachmentId = last(invoice.attributes.files)
  const fileUrl = dv.files?.find(f => f.file_id === attachmentId)?.link

  const row: InvoiceRow = {
    ...invoice,
    attributes: {
      ...invoice.attributes,
      scheduled_date: invoice.type === 'receivable'
        ? getAntDueDate(dv.deal.attributes.buyer_payment_terms, dv.deal.attributes.buyer_term_date)
        : invoice.attributes.scheduled_date,
      paid: invoice.type === 'receivable'
        ? actualCollectionDate(dv.deal, invoice, dv.costs)
        : invoice.attributes.paid,
    },
    view: {
      company: accounts[invoice.account],
      net_payable: invoice.total - vendorCreditTotal,
      est_amount: sumBy(invoice.attributes.costs, 'estimated'),
      actl_amount: invoice.total,
    },
    viewEx: {
      act_liability_date: invoice.type === 'receivable'
        ? getAvgReceiptDate(dv.deal, invoice, creditNotes)
        : invoice.attributes.paid || invoice.due,
      rowType: invoice.attributes.prepayment ? 'Prepayment' : 'Invoice',
      status: { status: invoice.status, name: AllInvoiceStatuses[invoice.status].value },
      fileUrl,
    },
  }
  return row
}

function buildCreditRow(
  dv: DeepReadonly<DV>,
  {creditNote, vendorCredit}: { creditNote?: DeepReadonly<CreditNote>, vendorCredit?: DeepReadonly<VendorCredit> },
  vendorCreditInvoices: DeepReadonly<Dictionary<Invoice>>,
  accounts: DeepReadonly<Dictionary<AccountObject>>,
) {
  const rowType = creditNote ? CREDIT_NOTE : (vendorCredit.attributes?.prepayment ? PREPAYMENT_CREDIT : VENDOR_CREDIT)
  const credit = creditNote || vendorCredit
  const invoice = vendorCreditInvoices[vendorCredit?.invoice_id]
  const row: InvoiceRow = {
    vendor_invoice_id: vendorCredit?.invoice_id,
    invoice_id: creditNote?.cn_id || vendorCredit?.credit_note_id,
    currency: credit.currency,
    status: credit.status,
    view: {
      company: accounts[credit.account],
      actl_amount: credit.amount,
    },
    viewEx: { dv, rowType, creditNote, vendorCredit },
    due: invoice?.due,
    attributes: {
      ant_liability_date: invoice?.attributes.ant_liability_date,
      scheduled_date: invoice?.attributes.scheduled_date,
      paid: invoice?.attributes.paid,
    },
  } as Partial<InvoiceRow> as InvoiceRow

  return row
}
