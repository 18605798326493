import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadProducts, loadProductsFailure, loadProductsSuccess } from './product.actions'

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private products: ProductsService,
    private toaster: ToasterService,
  ) {}

  loadProducts$ = createEffect(() => this.actions$.pipe(
    ofType(loadProducts),
    switchMap(action =>
      from(this.products.getAll()).pipe(
        map(products => loadProductsSuccess({
          tableKey: action.tableKey,
          products,
        })),
        catchError(error => {
          console.error('Unable to fetch products', error)
          this.toaster.error('Unable to fetch products', error)
          return of(loadProductsFailure({ error }))
        })))))
}
