import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit } from '@angular/core'
import { MatTooltip } from '@angular/material/tooltip'
import { DealViewRawStatus, DealViewStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'tc-deal-view-incomplete',
  standalone: true,
  template: `🛑`,
  styles: [`:host:not(.visible) { display: none }`],
  providers: [MatTooltip],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealViewIncompleteComponent extends OnDestroyMixin implements OnInit {
  constructor(private readonly tooltip: MatTooltip) { super() }

  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRawStatus>>

  @HostBinding('class.visible') incomplete: string;

  @HostListener('mouseenter') onMouseEnter(): void {
    this.tooltip.message = this.incomplete;
    this.tooltip.tooltipClass = 'pre-tooltip'
    this.tooltip.show();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.tooltip.hide();
  }

  ngOnInit() {
    this.dealViewRaw$.pipe(untilComponentDestroyed(this)).subscribe(dv => {
      this.incomplete = isDealViewIncomplete(dv)
    })
  }
}

export function isDealViewIncomplete(dv: DeepReadonly<DealViewRawStatus>) {
  if (!environment.enableIncompleteDeals) return ''
  const incomplete = dv.status === DealViewStatus.incomplete
    && dv.status_state?.some(s =>
      s.resource === 'costs' ||
      s.resource === 'segments' ||
      s.resource === 'invoices' ||
      s.resource === 'deal' ||
      s.resource === 'bids' ||
      s.resource === 'offers')
    || !!localStorage['WA-12222-all-deals-are-incomplete']
  return incomplete ? `This deal is missing backend data and so it is not editable at the moment. Please reload the page.` : ''
}
