import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { AccountType, DistributionListObject } from '@tradecafe/types/core'
import { map } from 'rxjs/operators'
import { waitNotEmpty } from 'src/services/data/utils'
import { selectAllAccounts } from 'src/app/store/accounts'
import { selectAllLocations } from 'src/app/store/locations'
import { selectAllProductCategories } from 'src/app/store/product-categories/product-category.selectors'
import { selectAllProductTypes } from 'src/app/store/product-types/product-type.selectors'
import { selectAllProducts } from 'src/app/store/products/product.selectors'
import { selectAllUsers } from 'src/app/store/users'
import { concat } from 'lodash-es'

export const createDistList = (): DistributionListObject => ({
  name: '',
  filters: {
    locations: [],
    suppliers: [],
    providers: [],
    products: [],
    product_categories: [],
    product_types: [],
  },
  notes: '',
  recipients: [],
  service_providers: [],
  contacts: {
    buyer: [],
    service_provider: [],
  },
  updated_at: 0,
  updated_by: '',
})

@Component({
  selector: 'tc-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DistributionListComponent implements OnInit {
  @Input() company
  @Input() distributionList

  constructor(
    private store: Store,
  ) { }

  // subscriptions
  protected locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  protected accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty())
  protected buyers$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(),
      map(account => account.filter((ac) => ac.type === AccountType.BUYER)))
  protected suppliers$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(),
      map(account => account.filter((ac) => ac.type === AccountType.SUPPLIER)))
  protected serviceProviders$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(),
      map(account => account.filter((ac) => ac.type === AccountType.SERVICE_PROVIDER)))
  protected productCategories$ = this.store.pipe(select(selectAllProductCategories), waitNotEmpty())
  protected productTypes$ = this.store.pipe(select(selectAllProductTypes), waitNotEmpty())
  protected products$ = this.store.pipe(select(selectAllProducts), waitNotEmpty())
  protected users$ = this.store.pipe(select(selectAllUsers), waitNotEmpty())
  //
  protected accounts = []
  protected recipients = []
  protected allProductCategories = []
  protected allProductTypes = []
  protected allProducts = []
  protected filteredProductCategories = []
  protected filteredProductTypes = []
  protected filteredProducts = []
  protected allUsers = []
  protected filteredBuyerContacts = []
  protected filteredServiceProviderContacts = []

  protected distributionListForm = new FormGroup(
    {
      name: new FormControl<string>(undefined, { validators: [Validators.required] }),
      filter_locationIds: new FormControl<string[]>([]),
      filter_supplierIds: new FormControl<string[]>([]),
      filter_serviceProviderIds: new FormControl<string[]>([]),
      filter_productCategoryIds: new FormControl<string[]>([]),
      filter_productTypeIds: new FormControl<string[]>([]),
      filter_productIds: new FormControl<string[]>([]),
      buyer_contacts: new FormControl<string[]>([]),
      service_providers: new FormControl<string[]>([]),
      service_provider_contacts: new FormControl<string[]>([]),
      notes: new FormControl<string>(undefined),
      emails: new FormControl<string[]>([]),
    },
  )

  // tslint:disable-next-line:cyclomatic-complexity
  ngOnInit(): void {
    if (!this.distributionList) {
      this.distributionList = createDistList()
    }
    // assign dropdown options for product-category / product-type / product
    this.productCategories$.subscribe((pcs) => {
      this.allProductCategories = pcs
      this.filteredProductCategories = this.allProductCategories
    })
    this.productTypes$.subscribe((pts) => {
      this.allProductTypes = pts
      this.filteredProductTypes = this.allProductTypes
    })
    this.products$.subscribe((ps) => {
      this.allProducts = ps
      this.filteredProducts = this.allProducts
    })
    this.users$.subscribe((us) => {
      this.allUsers = us
      this.filteredBuyerContacts = us.filter((u) => u.account === this.company.account)
    })
    //
    this.accounts$.subscribe((accs) => {
      this.accounts = accs
    })
    this.distributionListForm.patchValue(
      {
        name: this.distributionList?.name || '',
        filter_locationIds: this.distributionList?.filters.locations || [],
        filter_supplierIds: this.distributionList?.filters.suppliers.map((s) => parseFloat(s)) || [],
        filter_serviceProviderIds: this.distributionList?.filters.providers.map((s) => parseFloat(s)) || [],
        filter_productCategoryIds: this.distributionList?.filters.product_categories || [],
        filter_productTypeIds: this.distributionList?.filters.product_types || [],
        filter_productIds: this.distributionList?.filters.products || [],
        notes: this.distributionList?.notes || ' ',
      },
    )
    this.initRecipients()
  }

  initRecipients() {
    if (this.distributionList.recipients && Array.isArray(this.distributionList.recipients)) {
      this.recipients = this.distributionList.recipients
    } else {
      this.recipients = []
    }
    // separate emails / user_ids
    const mappedEmails = []
    this.recipients.forEach((r) => {
      const keys = Object.keys(r)
      if (keys[0] === 'email') {
        mappedEmails.push(r['email'])
      } else {
        console.error('Invalid recipient')
      }
    })
    // set emails
    this.distributionListForm.patchValue({ emails: mappedEmails })
    // set service_providers and contacts
    this.distributionListForm.patchValue({ service_providers: this.distributionList.service_providers })
    this.onServiceProviderChanged()
    this.distributionListForm.patchValue({ buyer_contacts: this.distributionList.contacts.buyer })
    this.distributionListForm.patchValue({ service_provider_contacts: this.distributionList.contacts.service_provider })
  }

  onProductCategoriesChanged() {
    let pts = []
    this.distributionListForm.controls.filter_productCategoryIds.value.forEach((category_id) => {
      pts = pts.concat(this.allProductTypes.filter((pt) => pt.category_id === category_id))

    })
    this.filteredProductTypes = pts
  }

  onProductTypesChanged() {
    let ps = []
    this.distributionListForm.controls.filter_productCategoryIds.value.forEach((category_id) => {
      ps = ps.concat(this.allProducts.filter((p) => p.category_id === category_id))
    })
    this.distributionListForm.controls.filter_productTypeIds.value.forEach((type_id) => {
      ps = ps.concat(this.allProducts.filter((p) => p.type_id === type_id))
    })
    this.filteredProducts = ps
  }

  onServiceProviderChanged() {
    this.filteredServiceProviderContacts = this.allUsers.filter(
      (u) => Array.isArray(this.distributionListForm.controls.service_providers.value) &&
          this.distributionListForm.controls.service_providers.value.includes(u.account),
    )
  }

  populateEmails(emails) {
    this.recipients = this.recipients.filter((r) => {
      const keys = Object.keys(r)
      if (keys[0] === 'user_id') {
        return true
      } else {
        return false
      }
    })
    let recipientEmails = []
    if (Array.isArray(emails)) {
      recipientEmails = emails.map((e) => ({ email: e }))
    }
    this.recipients = concat(this.recipients, recipientEmails)
  }

  getDistributionList() {
    return {
      name: this.distributionListForm.controls.name.value,
      filters: {
        locations: this.distributionListForm.controls.filter_locationIds.value,
        suppliers: this.distributionListForm.controls.filter_supplierIds.value.map((s) => `${s}`),
        providers: this.distributionListForm.controls.filter_serviceProviderIds.value.map((s) => `${s}`),
        products: this.distributionListForm.controls.filter_productIds.value,
        product_categories: this.distributionListForm.controls.filter_productCategoryIds.value,
        product_types: this.distributionListForm.controls.filter_productTypeIds.value,
      },
      notes: this.distributionListForm.controls.notes.value,
      recipients: this.recipients,
      service_providers: this.distributionListForm.controls.service_providers.value,
      contacts: {
        buyer: this.distributionListForm.controls.buyer_contacts.value,
        service_provider: this.distributionListForm.controls.service_provider_contacts.value,
      },
    }
  }
}
