const collator = new Intl.Collator(undefined, {sensitivity: 'base', numeric: true})
export function compare(a: string|any, b: string|any) {
  if (typeof a === 'string') a = a.trim()
  if (typeof b === 'string') b = b.trim()
  return collator.compare(a, b)
}
export function compareBy<T>(key: (keyof T) | ((x: T) => string)) {
  return function (a: T, b: T) {
    if (typeof key === 'function') return compare(key(a), key(b))
    return compare(a?.[key], b?.[key])
  }
}
