import { Injectable } from '@angular/core'
import { Cost, Deal, DealView } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep } from 'lodash-es'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { accountsResolver, currenciesResolver, productCategoriesResolver, productsResolver, productTypesResolver } from 'src/services/data/deal.resolvers'
import { ModalProxyService } from 'src/shared/modal'


type DealContext = { supplier_id?: string, buyer_id?: string} | Deal | DealView

@Injectable()
export class CostFormService {
  constructor(
    private modalHelper: ModalProxyService,
    private FxRates: FxRatesService,
  ) {}


  create(deal: DeepReadonly<DealContext>, options: any = {
    title: 'New Cost',
    type: 'tertiary',
    required: {
      service: true,
    },
  }) {
    return this.show(deal, undefined, options)
  }

  /** @deprecated use updateImmutable */
  update(deal: DeepReadonly<DealContext>, cost: Partial<Cost>, options?) {
    return this.show(deal, cost, options)
  }

  updateImmutable(deal: DeepReadonly<DealContext>, immutableCost: DeepReadonly<DeepPartial<Cost>>, options?) {
    const cost = cloneDeep(immutableCost) as Cost
    return this.show(deal, cost, options)
  }

  remove(cost: DeepReadonly<DeepPartial<Cost>>) {
    return this.modalHelper.showConfirm({
      title: 'Delete this?',
      description: 'Are you sure you want to delete this?',
    })
    .then(() => cost)
  }

  private show(deal: DeepReadonly<DealContext>, cost: Partial<Cost>, options?) {
    return this.modalHelper.showModal({
      component: 'tcCostForm',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        deal: () => deal,
        cost: () => cost,
        options: () => options,
        accounts: accountsResolver,
        categories: productCategoriesResolver,
        currencies: currenciesResolver,
        products: productsResolver,
        types: productTypesResolver,
        fxRates: () =>
          // TODO: use `deal.attributes.fx_rates`
          this.FxRates.getSpotRates(),
      },
    })
  }
}
