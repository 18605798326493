<div mat-dialog-title>
  <h2 fxFlex>{{ dialogData.title }}</h2>
  <button mat-raised-button type="button" color="primary" (click)="showAddNew()">Add New</button>
</div>
<div mat-dialog-content>
  <header class="filters-bar"
      gdColumns.sm="repeat(3, 1fr)"
      gdColumns.md="repeat(4, 1fr)"
      gdColumns.gt-md="repeat(6, 1fr)"
      gdGap="15px">
    <mat-form-field gdColumn="span 2"
        [class.tc-text-empty]="!filtersForm.controls.search.value">
      <mat-label>Search</mat-label>
      <button mat-icon-button matPrefix>
        <mat-icon class="fa fa-search"></mat-icon>
      </button>
      <input matInput [formControl]="filtersForm.controls.search" autocomplete="deal_id">
      <button mat-icon-button class="tc-text-clear"
          [disabled]="filtersForm.controls.search.disabled"
          (click)="filtersForm.controls.search.reset()">
        <mat-icon class="fa fa-times"></mat-icon>
      </button>
    </mat-form-field>
    <tc-select-search placeholder="Visibility"
        [ctrl]="filtersForm.controls.visibility"
        [items]="NoteVisibilities" bindValue="value" bindLabel="name"
        ></tc-select-search>
    <tc-select-search placeholder="Company" [multiple]="true"
        [ctrl]="filtersForm.controls.company"
        [items]="recipients$ | async" bindValue="id" bindLabel="name"
        ></tc-select-search>
    <tc-select-search placeholder="Category" [multiple]="true"
        [ctrl]="filtersForm.controls.category"
        [items]="categories$ | async" bindValue="id" bindLabel="name"
        ></tc-select-search>
    <tc-select-search placeholder="Created By" [multiple]="true"
        [ctrl]="filtersForm.controls.user_id"
        [items]="noteUsers$ | async" bindValue="user_id" bindLabel="fullname"
        ></tc-select-search>
  </header>

  <tc-notes-list-v2
      [dealViewRaw]="dialogData.dealViewRaw$ | async"
      [dataSource]="dataSource"
      [displayColumns]="displayColumns"
      ></tc-notes-list-v2>
</div>
