import { createSelector } from '@ngrx/store'
import { User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { environment } from 'src/environments/environment'
import { compareBy } from 'src/services/table-utils/compare'
import { GenericOption } from '../accounts/account.selectors'
import { State } from '../reducers'
import { adapter, usersFeatureKey } from './user.reducer'

export const selectState = (state: State) => state[usersFeatureKey]

export const { selectEntities: selectUserEntities, selectAll: selectAllUsers } = adapter.getSelectors(selectState)

// export const selectUser = createSelector(
//   selectUserEntities,
//   (users: Dictionary<User>, user: string | number) =>
//     users[user])

// export const selectUsersByIds = createSelector(
//   selectUserEntities,
//   (users: Dictionary<User>, userIds: string[]) =>
//     userIds.map(userId => users[userId]))

export const selectTradersOptions = (selected?: string) => createSelector(
  selectAllUsers,
  (users: DeepReadonly<User[]>): GenericOption[] => users
    .filter(user =>
      user.account === environment.tradecafeAccount && user.role === 'trader' && !user.archived ||
      selected === user.user_id)
    .sort(compareBy('fullname'))
    .map(user => ({ id: user.user_id, name: user.fullname, hint: user.primaryemail })))

export const selectFirstTraderOption = createSelector(
  selectTradersOptions(),
  traders => traders[0]?.id)

export const selectCoordinatorsOptions = (selected?: string) => createSelector(
  selectAllUsers,
  (users: DeepReadonly<User[]>): GenericOption[] => users
    .filter(user =>
      user.account === environment.tradecafeAccount && user.role === 'logistics' && !user.archived ||
      user.user_id === selected)
    .sort(compareBy('fullname'))
    .map(user => ({ id: user.user_id, name: user.fullname, hint: user.primaryemail })))

