import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableKey } from '@tradecafe/types/core';

export interface MacropointOrderLogOverlayOptions {
  title: string;
  order_id: string;
}

@Component({
  selector: 'tc-macropoint-order-log-overlay',
  templateUrl: './macropoint-order-log-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MacropointOrderLogOverlayComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: MacropointOrderLogOverlayOptions,
    private dialogRef: MatDialogRef<MacropointOrderLogOverlayComponent, void>
  ) {}

  tableIdentity = TableKey.MacropointOrderEventLogAudit;
  title = this.dialogData.title;
  auditLogFilter = { resource_id: this.dialogData.order_id };

  cancel() {
    this.dialogRef.close();
  }
}
