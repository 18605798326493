import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module'
import { NoteFormModule } from 'src/components/notes/note-form/note-form.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { FinanceIssuesModule } from 'src/shared/finance-issues/finance-issues.module'
import { LocksModule } from 'src/shared/lock/locks.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { TradingDetailsOverlayComponent } from './trading-details-overlay.component'
import { TradingDetailsOverlayService } from './trading-details-overlay.service'

@NgModule({
  providers: [TradingDetailsOverlayService],
  declarations: [TradingDetailsOverlayComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    FlexLayoutModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    PipesModule,
    EpochFieldModule,
    EpochRangeFieldModule,
    NoteFormModule,
    LocksModule,
    FinanceIssuesModule,
    DealViewIncompleteComponent,
  ],
})
export class TradingDetailsOverlayModule {}
