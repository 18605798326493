import { Injectable } from '@angular/core'
import { Deal, DealView, Message } from '@tradecafe/types/core'
import { differenceBy, filter, flatten, get, intersectionBy, map, pick } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { MessageApiService } from 'src/api/message'

const QUERY_ALL = { limit: Number.MAX_SAFE_INTEGER }
const ALLOWED_FIELDS = ['subject', 'body_txt', 'body_html', 'to', 'from', 'cc', 'direction', 'is_draft', 'attributes', 'type']


/**
 * Messages service
 *
 * @see https://docs.google.com/document/d/1dq-FsI0yh9Mj6hC_GHwuysSyKIxorNMfThWALezd0BQ
 *
 * @export
 * @returns
 */
@Injectable()
export class MessagesService {
  constructor(
    private AuthApi: AuthApiService,
    private MessageApi: MessageApiService,
  ) {}


  /**
   * Load deal messages
   *
   * @param {any} dealId
   * @returns {Promise} of an array with deal messages
   */
  async getForDeal(deal_id: string) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.MessageApi.list(account, Object.assign({ deal_id }, QUERY_ALL))
    // TODO: implement backend filtering
    return filter(data, (d) => get(d, 'data.deal_id') === deal_id)
  }

  /**
   * Store logistics messages related to a deal
   *
   * @param {any} deal
   * @param {any} messages
   * @returns {Promise}
   */
  async storeDealMessages(deal: Deal|DealView, messages: Message[]) {
    const existing = await this.getForDeal(deal.deal_id)

    const createMessage = differenceBy(messages, existing, 'message_id')
    const updateMessage = intersectionBy(messages, existing, 'message_id')
    const removeMessage = differenceBy(existing, messages, 'message_id')

    return Promise.all([
      Promise.all(createMessage.map(message => this.createFor(deal.deal_id, message))),
      Promise.all(updateMessage.map(this.update)),
      Promise.all(removeMessage.map(this.remove)),
    ]).then(res => map(flatten(res), 'data'))
  }

  /**
   * Create new deal messages document
   *
   * @private
   * @param {any} dealId
   * @param {any} message
   * @returns
   */
  async createFor(dealId: string, message: Partial<Message>) {
    const { account } = this.AuthApi.currentUser
    const payload = pick(message, ALLOWED_FIELDS)
    const { data } = await this.MessageApi.create(account, payload)
    return data
  }

  /**
   * Update messages
   *
   * @private
   * @param {any} message
   * @returns
   */
  async update(message: Partial<Message>) {
    const { account } = this.AuthApi.currentUser
    const payload = pick(message, ALLOWED_FIELDS)
    const { data } = await this.MessageApi.update(account, message.message_id, payload)
    return data
  }

  /**
   * Remove messages document
   *
   * @private
   * @param {any} dealId
   * @param {any} message
   * @returns
   */
  async remove(message: Message) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.MessageApi.delete(account, message.message_id)
    return data
  }
}
