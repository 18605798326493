import { Injectable } from '@angular/core'
import { Cost, DealBase, DealView } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, filter, find, forEach, get, map, mapValues, merge, pick, pickBy, remove, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { SecondaryCostApiService } from 'src/api/cost/secondary-cost'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'


const ALLOWED_FIELDS = ['order', 'type', 'costs', 'attributes']

const defaultSecondaryCostTypeCfg = {
  'Insurance & Interest': {
    'cargo-insurance':      { type: 'percent', name: 'Cargo Insurance' },
    'interest':             { type: 'percent', name: 'Interest' },
    'receivable-insurance': { type: 'percent', name: 'Receivable Insurance' },
    'document-delivery':    { type: 'money',   name: 'Document Delivery' },
    'bank-service-fee':     { type: 'money',   name: 'Bank Service Fee' },
    'Foreign Exchange':     { type: 'percent', name: 'Accural 1' },
    'Deal Fulfillment':      { type: 'percent', name: 'Accural 2' },
    'accrual-4':            { type: 'percent', name: 'Accural 4' },
    'accrual-5':            { type: 'percent', name: 'Accural 5' },
    'accrual-6':            { type: 'percent', name: 'Accural 6' },
    'accrual-7':            { type: 'percent', name: 'Accural 7' },
  },
  'Taxes & Margin': {
    'gst':                  { type: 'percent', name: 'GST' },
    'pst':                  { type: 'percent', name: 'PST' },
    'margin':               { type: 'percent', name: 'Margin' },
  },
  'Other': {
    'bonds':                { type: 'money',   name: 'Bonds' },
    'cert-1':               { type: 'money',   name: 'Certificate re-Issue' },
    'cert-2':               { type: 'money',   name: 'Certificate re-Issue 2' },
    'cert-3':               { type: 'money',   name: 'Certificate re-Issue 3' },
    'mecado-temp-recorder': { type: 'money',   name: 'Mecado temp Recorder' },
    'temp-recorder':        { type: 'money',   name: 'Temp Recorder' },
    'temp-recorder-2':      { type: 'money',   name: 'Temp Recorder 2' },
  },
}

/**
 * Custom Secondary Costs service
 *
 * @see https://docs.google.com/document/d/1rrFgEUwYQewpAEDVZe2cr2mhxYwrky0-c0JoJP47vek
 *
 * @export
 * @returns
 */
@Injectable()
export class CustomCostsService {
  constructor(
    private AuthApi: AuthApiService,
    private SecondaryCostApi: SecondaryCostApiService,
    private Products: ProductsService,
    private Locations: LocationsService,
    private toaster: ToasterService,
  ) {}

  /**
   * Query secondary costs, replace existing secondary costs with fresh costs set
   * NOTE: costs argument will be modified.
   *
   * @param {*} costs - list of existing costs of different type.
   * @param {*} secondary costs query
   */
  async refreshSecondaryCosts(costs, query) {
    remove(costs, validSecondaryCost)
    const secondaryCosts = await this.querySecondaryCosts(query)
    costs.push(...secondaryCosts)

    function validSecondaryCost(cost) {
      return cost.type === 'secondary' &&
        !!get(cost, 'attributes.default_cost.cost_type_id')
    }
  }

  /**
   * Get default (secondary) costs for the given deal
   *
   * @deprecated
   * @param {*} dealView
   * @returns list of costs (@see Costs service documentation for the schema)
   */
  async getDefaultCosts(dealView: DeepReadonly<DealView>) {
    return this.querySecondaryCosts({
      dealType: dealView.deal_type,
      providerIds: uniq(compact(map(dealView.costs, 'provider'))),
      supplierId: dealView.supplier_id,
      buyerId: dealView.buyer_id,
      originId: dealView.origin_location,
      destinationId: dealView.dest_location,
      productIds: compact(map(dealView.products, 'product_id')),
    })
  }

  /**
   * Query default (secondary) costs
   */
  async querySecondaryCosts({
    dealType, providerIds, supplierId, buyerId, originId, destinationId, productIds,
  }: {
    dealType?: DealBase['deal_type'],
    providerIds?: string[],
    supplierId?: number,
    buyerId?: number,
    originId?: string,
    destinationId?: string,
    productIds?: string[],
  }): Promise<Partial<Cost>[]> {
    const [products, ruleCosts] = await Promise.all([
      this.Products.getAll(),
      this.Locations.getLocationsByIds().then(locations =>
        this.fetchSecondaryCosts({
          dealType,
          providerIds,
          supplierId,
          buyerId,
          productIds,
          originCountry: get(locations[originId], 'country'),
          destinationCountry: get(locations[destinationId], 'country'),
        })),
    ])

    const rules = filter(ruleCosts, 'value')
    return rules
      .map(cost => this.fromDefaultCost(cost, { products }))
      .filter(cost => cost.attributes.default_cost?.cost_type_id)
  }

  /**
   * Prepare query and "resolve" default costs using Secondary Costs API
   *
   * @param {*} query
   * @returns
   */
  private async fetchSecondaryCosts({
    dealType, providerIds, supplierId, buyerId, originCountry, destinationCountry, productIds,
  }: {
    dealType?: DealBase['deal_type'],
    providerIds?: string[],
    supplierId?: number,
    buyerId?: number,
    originCountry?: string,
    destinationCountry?: string,
    productIds?: string[],
  }) {
    const { data: costRules } = await this.SecondaryCostApi.resolve({
      suppliers: supplierId || '',
      buyers: buyerId || '',
      provider_id: providerIds,
      org_countries: originCountry || '',
      dst_countries: destinationCountry || '',
      products: productIds,
    })

    if (dealType === 'brokerage') delete costRules.interest
    forEach(costRules, (item, key) => {
      item.cost_type_id = key
    })

    return costRules
  }

  /**
   * Build "Cost" object using values stored inside "rule" object
   *
   * @param {*} ruleCost
   * @param {*} { products }
   * @returns
   */
  private fromDefaultCost(ruleCost, { products }): Partial<Cost> {
    let service = find(products, { name: ruleCost.name })
    if (!service) {
      console.debug(`can't find TC Product for cost ${ruleCost.name}`)
      service = {}
    }
    return {
      type: 'secondary',
      amount: (ruleCost.type === 'money' ? {
        total: ruleCost.value as number,
        currency: ruleCost.currency as string,
      } : {
        total: 0,
        currency: 'CAD',
      }),
      product_id: service.product_id,
      service: service.name || ruleCost.name,
      status: 'pending',
      attributes: {
        default_cost: ruleCost,
      },
    }
  }

  /**
   * Create custom costs rule
   *
   * @param {*} payload
   * @returns
   */
  create(rule) {
    const {user_id} = this.AuthApi.currentUser
    const payload = this.extractPayload(rule)
    payload.creator_id = user_id // TODO: deprecate SER-286
    return this.SecondaryCostApi.create(payload).then(({data}) => {
      this.toaster.success('Custom cost saved successfully.')
      return data
    }).catch((err: any = {}) => {
      if (err.status === 409) {
        this.toaster.warning('There is a custom cost of this cost type already. You can only have one custom cost for each cost type.')
      } else {
        console.error('Unable to save custom cost', err)
        this.toaster.error('Unable to save custom cost', err)
      }
      throw err
    })
  }

  /**
   * Update custom costs rule
   *
   * @param {*} rule
   * @returns
   */
  update(rule) {
    const payload = this.extractPayload(rule)
    return this.SecondaryCostApi.update(rule.rule_id, payload).then(({data}) => {
      this.toaster.success('Custom cost updated successfully.')
      return data
    }).catch((err: any = {}) => {
      if (err.status === 409) {
        this.toaster.warning('There is a custom cost of this cost type already. You can only have one custom cost for each cost type.')
      } else {
        console.error('Unable to update custom cost', err)
        this.toaster.error('Unable to update custom cost', err)
      }
      throw err
    })
  }

  setOrder(rule, order) {
    return this.SecondaryCostApi.update(rule.rule_id, {order})
    .then(() => {
      rule.order = order
    })
  }

  /**
   * Prepare UI data to be uploaded to the BE
   *
   * @private
   * @param {*} data
   * @returns
   */
  private extractPayload(data) {
    // only allowed fields
    const payload = pick(data, ALLOWED_FIELDS)
    // merge in known cost types and names
    payload.costs = merge({}, payload.costs, defaultSecondaryCostTypeCfg[data.type])
    // store only defined costs. allow zero costs (let users override some defined costs)
    payload.costs = pickBy(payload.costs, cost =>
      (cost.value || cost.value === 0) && (cost.currency || cost.type === 'percent'))
    // convert data from old format to the new one (see SER-571)
    payload.attributes = mapValues(payload.attributes, value =>
      (Array.isArray(value) ? value[0] : value))
    return payload
  }
}
