<h2 mat-dialog-title>
  <i class="fa fa-cloud-arrow-up" aria-hidden="true"></i>&nbsp;{{title}}
</h2>
<div mat-dialog-content>
  <tc-drop-zone (changed)="onFilesChange($event)"></tc-drop-zone>
  <table class="table">
    <thead>
      <tr>
        <th width="*">Name</th>
        <th width="70px">Size</th>
        <th *ngIf="!dv" width="150px">Deal #</th>
        <th width="250px">Deal Party</th>
        <th width="250px">Type</th>
        <th width="40px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of filesQueue$ | async; let $index = index">
        <td>
          <strong>{{ item.file.name }}</strong>
          <i *ngIf="item.status === 'done'" class="fa fa-ok"></i>
          <i *ngIf="item.status === 'fail'" class="fa fa-ban"></i>
          <i *ngIf="item.status === 'cancel'" class="fa fa-xmark"></i>
          <mat-progress-bar [bufferValue]="item.progress$ | async"></mat-progress-bar>
        </td>
        <td nowrap>{{ item.file.size | bytes }}</td>
        <td *ngIf="!dv">
          <tc-select-search placeholder="Deal #" [multiple]="true"
              [ctrl]="item.form.controls.dealIds"
              [items]="dealIds$ | async"
              ></tc-select-search>
        </td>
        <td>
          <tc-select-search placeholder="Deal Party"
              [ctrl]="item.form.controls.dealParty"
              [items]="parties$ | async" bindLabel="name" bindValue="account" bindHint="type"
              ></tc-select-search>
        </td>
        <td>
          <tc-select-search placeholder="Document Type"
              [ctrl]="item.form.controls.documentType"
              [items]="item.documenTypes$ | async" bindLabel="name" bindValue="id"
              ></tc-select-search>
        </td>
        <td>
          <button mat-icon-button aria-label="Remove"
              *ngIf="item.status === 'queue' || item.status === 'fail'"
              (click)="removeFile($index)">
            <mat-icon class="fa fa-trash-can"></mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <p><strong>Note</strong>: You can upload {{limit}} file(s) at once.</p>
</div>
<mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="cancel()">Cancel</button>
  <button mat-raised-button type="submit" color="primary" [disabled]="inProgress$ | async" (click)="save()">
    <mat-icon class="fa fa-fw" aria-hidden="true"
      [class.fa-cloud-arrow-up]="(inProgress$ | async) !== 'save'"
      [class.fa-spin]="(inProgress$ | async) === 'save'"
      [class.fa-spinner]="(inProgress$ | async) === 'save'"
      ></mat-icon>
    Upload
  </button>
</div>
