import { Injectable } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { CreditNote, Deal, DealBase, DealView, DealViewInvoices, DealViewRaw, FileObject, Invoice, Segment, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact } from 'lodash-es'
import { Observable, ReplaySubject } from 'rxjs'
import { startWith, switchMap, tap } from 'rxjs/operators'
import { saveNoteSuccess } from 'src/app/store/deal-view.actions'
import { DealsService } from 'src/services/data/deals.service'
import { ModalProxyService, ModalService } from 'src/shared/modal'
import { NotesOverlayComponent, NotesOverlayOptions } from './notes-overlay.component'

@Injectable()
export class NotesOverlayService {
  constructor(
    private modal: ModalService,
    private modalHelper: ModalProxyService,
    private Deals: DealsService,
    private actions$: Actions,
  ) {}

  showDealNotes(deal_id: string, categories: string[] = []) {
    return this.show({
      deal_id,
      filters: {
        category: [],
      },
    })
  }

  showDeringerNotes(dealId: string, files: DeepReadonly<FileObject[]>) {
    return this.showDealNotes(dealId, files.map(f => f.file_id))
  }

  showWarningNotes(deal: DeepReadonly<DealBase & DealViewInvoices>) {
    return this.showDealNotes(
      deal.deal_id,
      [
        'general',
        'negative-margin',
        ...deal.invoices?.map(i => i.invoice_id) || [],
      ]
    )
  }

  showDeringerWarningNotes(deal: DeepReadonly<DealBase & DealViewInvoices>) {
    return this.showDealNotes(deal.deal_id, ['general', 'general_shipping'])
  }

  showSegmentNotes(deal_id: string, segment: DeepReadonly<Pick<Segment, 'carrier_id'|'segment_id'>>) {
    return this.show({
      deal_id,
      filters: {
        company: compact([segment.carrier_id]),
        category: compact([segment.segment_id]),
      },
    })
  }

  showProductNotes(dealId: string, productId: string) {
    return this.show({
      deal_id: dealId,
      filters: {
        category: compact([productId]),
      },
    })
  }

  showInvoiceNotes(deal: DeepReadonly<Deal | DealView>, invoice: DeepReadonly<Invoice>, categoryList?, noDefaultFilter?) {
    let categoryFilter = noDefaultFilter ? [] : [invoice.invoice_id, 'general', 'negative-margin']
    if (categoryList) {
      categoryFilter = categoryFilter.concat(categoryList)
    }

    const companyVal = invoice.type === 'payable' ? invoice.account : deal.buyer_id

    return this.show({
      deal_id: deal.deal_id,
      filters: {
        category: compact(categoryFilter),
      },
      defaults: { // TODO: not supported yet
        attributes: {invoice_id: invoice.invoice_id},
        visibility: 1,
      },
      newFilters: {attributes: {category: invoice.invoice_id, company: (companyVal ? [companyVal] : [])}},
    })

    // const {invoice_id} = invoice
    // return showNotes({
    //   settings: {
    //     title: `Invoice #${invoice_id} Notes`
    //   },
    //   filters: {
    //     invoice_id
    //   },
    //   defaults: {
    //     attributes: {invoice_id},
    //     visibility: 1
    //   }
    // })
  }

  showReceiptNotes(receiptId) {
    return this.showGeneralNotes({
      settings: {
        title: 'Receipt Notes',
      },
      filters: {
        receipt_id: receiptId,
      },
      defaults: {
        attributes: { receipt_id: receiptId },
        visibility: 1,
      },
    })

    // return show(deal, {
    //   'attributes.receipt_id': receipt.receipt_id,
    // })
  }

  showCreditNoteNotes(creditNote: DeepReadonly<CreditNote>) {
    const { credit_note_id, deal_id, cn_id } = creditNote
    return this.show({
      deal_id,
      filters: {
        category: [cn_id],
      },
      defaults: {
        deal_id,
        attributes: {
          category: cn_id,
          credit_note_id,
        },
      },
    })
  }

  showVendorCreditNotes(vendorCredit: DeepReadonly<VendorCredit>) {
    const { credit_note_id, deal_id } = vendorCredit
    return this.show({
      deal_id,
      filters: {
        category: [credit_note_id],
      },
      defaults: {
        deal_id,
        attributes: {
          category: credit_note_id,
          vendor_credit_id: credit_note_id,
        },
      },
    })
  }

  showFreightRateNotes(freightRate) {
    const {rate_id: freight_rate_id} = freightRate
    return this.showGeneralNotes({
      settings: {
        title: 'Freight Rate Notes',
      },
      filters: {
        freight_rate_id,
      },
      defaults: {
        attributes: {freight_rate_id},
      },
    })
  }


  /**
   * Show Deal Notes overlay
   *
   * @private
   * @param {*} deal
   * @param {*} filters
   * @returns
   */
  private show({deal_id, filters, newFilters}: {
    deal_id?: string,
    filters?: any,
    defaults?: any, // not supported yet
    newFilters?: any,
  }): Observable<void|unknown> {
    const dealViewRaw$ = new ReplaySubject<DealViewRaw>(1)

    const sub = this.actions$.pipe(
      ofType(saveNoteSuccess),
      startWith(0),
      switchMap(() => this.Deals.getDealView(deal_id,
        ['deal', 'costs', 'segments', 'invoices', 'files', 'credit_notes', 'vendor_credits', 'notes'],
        { keepVoidedCredits: true })),
    ).subscribe(dealViewRaw$)

    return this.modal.openLargeSide<NotesOverlayComponent, NotesOverlayOptions, void>(NotesOverlayComponent, {
      dealViewRaw$,
      filters,
      newFilters,
      general: false,
      title: `${deal_id} Notes`,
    }).pipe(tap(() => sub.unsubscribe()))
  }

  private showGeneralNotes({ settings = {}, filters = {}, defaults = {} }) {
    return this.modalHelper.showOverlay('tcNotesOverlay', {
      backdrop: true,
      resolve: {
        modalSetting: () => settings,
        filters: () => filters,
        defaults: () => defaults,
      },
    })
  }
}
