import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { StickyHeaderModule } from 'src/directives/sticky-header/sticky-header.module'
import { ModalModule } from 'src/shared/modal/modal.module'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { NotesListModule } from '../notes-list/notes-list.module'
import { NotesOverlayComponent } from './notes-overlay.component'
import { NotesOverlayService } from './notes-overlay.service'


@NgModule({
  declarations: [NotesOverlayComponent],
  providers: [NotesOverlayService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ModalModule,
    NotesListModule,
    ReactiveFormsModule,
    SelectSearchModule,
    StickyHeaderModule,
    ToasterModule,
  ],
})
export class NotesOverlayModule { }
