import { Injectable } from '@angular/core'
import { TableView } from '@tradecafe/types/core'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class SaveViewFormService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  saveTableView(view: Partial<TableView>): Promise<TableView> {
    return this.modalHelper.showModal({
      component: 'saveViewModal',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        view: () => view,
      },
    })
  }

  updateTableView(view: TableView): Promise<TableView> {
    return this.modalHelper.showModal({
      component: 'saveViewModal',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        view: () => view,
      },
    })
  }
}
