import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AccountObject, BUYER, Carrier, DealBase, DealView, DealViewRaw, INTERNAL, Note, NoteVisibility, SUPPLIER } from '@tradecafe/types/core'
import { DeepReadonly, isDealPrimaryClone } from '@tradecafe/types/utils'
import { filter, flatMap, flatten, groupBy, intersection, intersectionBy, isEqual, keyBy, map, mapValues, orderBy, pick, uniq, uniqBy, without } from 'lodash-es'
import { Observable, of } from 'rxjs'
import { map as _map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { NoteApiService } from 'src/api/note'
import { saveNoteSuccess } from 'src/app/store/deal-view.actions'
import { dayjs } from '../dayjs'

const QUERY_ALL = { limit: Number.MAX_SAFE_INTEGER }
const ALLOWED_FIELDS_CREATE = ['deal_id', 'matched_offer_id', 'body', 'visibility', 'attributes']
const ALLOWED_FIELDS_UPDATE = ['body', 'visibility', 'attributes']

export const SPECIAL_INSTRUCTIONS = 'special_instructions'
export const GENERAL_SHIPPING = 'general_shipping'
export const AES_CANCELLATION_REASON = 'aes_cancellation_reason'
export const MONTSHIP_BOOKING_REJECTION_REASON = 'montship_booking_rejection_reason'

/**
 * Notes service
 *
 * @see https://docs.google.com/document/d/1YLAaSlG64qLs5V5KKU4yhI9zRQyn2oTihfK9otrNkVM
 *
 * @export
 * @returns
 */
@Injectable()
export class NotesService {
  constructor(
    private AuthApi: AuthApiService,
    private NoteApi: NoteApiService,
    private store: Store, // TODO: reverse DI
  ) {}

  /** @deprecated use `getGeneralCategoryOptions` instead*/
  getGeneralCategoryOptions = getGeneralCategoryOptions
  /** @deprecated use `getCategoryOptions` instead*/
  getCategoryOptions = getCategoryOptions
  /** @deprecated use `getGeneralRecipientOptions` instead*/
  getGeneralRecipientOptions = getGeneralRecipientOptions
  /** @deprecated use `getRecipientOptions` instead*/
  getRecipientOptions = getRecipientOptions

  /**
   * Load notes notes for multiple deal ids
   *
   * @param {string[]} dealId
   * @returns {Promise} of arrays with deal notes grouped by deal ids
   */
  getForDealsObs(dealIds: string[]): Observable<Record<string, Note[]>> {
    return this.getByDealIdsObs(dealIds).pipe(_map(data => groupBy(data, 'deal_id')))
  }

  /**
   * Load notes notes for multiple deal ids
   *
   * @param {string[]} dealId
   * @returns {Promise} of an array with deal notes
   */
  getByDealIdsObs(dealIds: string[]): Observable<Note[]> {
    if (!dealIds.length) return of([])
    const { account } = this.AuthApi.currentUser
    return this.NoteApi.byDealIdsObs(account, dealIds).pipe(_map(({ data }) => data))
  }

  /**
   * Load notes notes for multiple deal ids
   *
   * @param {string[]} dealId
   * @returns {Promise} of arrays with deal notes grouped by deal ids
   */
  async getForDeals(dealIds: string[]) {
    const data = await this.getByDealIds(dealIds)
    return groupBy(data, 'deal_id')
  }

  /**
   * Load notes notes for multiple deal ids
   *
   * @param {string[]} dealId
   * @returns {Promise} of an array with deal notes
   */
  async getByDealIds(dealIds: string[]): Promise<Note[]> {
    if (!dealIds.length) return []
    const { account } = this.AuthApi.currentUser
    const { data } = await this.NoteApi.byDealIds(account, dealIds)
    return data
  }

  /**
   * Load deal notes
   *
   * @param {string} dealId
   * @returns {Promise} of an array with deal notes
   */
  async getForDeal(deal_id: string) {
    return this.queryNotes({ deal_id })
  }

  /**
   * Query notes
   *
   * @param {any} query
   * @returns {Promise} of an array with deal notes
   */
  async queryNotes(query) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.NoteApi.list(account, { ...QUERY_ALL, ...query })
    data.forEach((note) => {
      note.attributes = note.attributes || {}
      // WA-2840: on the fly migration: support multiple companies
      if (note.attributes.company && !Array.isArray(note.attributes.company)) {
        note.attributes.company = [(note.attributes.company as string|number).toString()]
      }
    })
    return data
  }

  /**
   * Get notes associated with invoice
   *
   * @param {*} invoiceIds
   * @returns
   */
  getForInvoiceIds(invoiceIds: string[]) {
    // TODO: introduce new API to query by multiple invoice ids; drop limit 1
    return Promise.all(map(invoiceIds, invoice_id =>
      this.queryNotes({limit: 1, invoice_id})
      .then(notes => ({ invoice_id, notes })),
    )).then(r => mapValues(keyBy(r, 'invoice_id'), 'notes'))
  }

  /**
   * Get notes associated with receipts
   *
   * @param {*} receiptIds
   * @returns
   */
  getByReceiptIds(receiptIds) {
    const { account } = this.AuthApi.currentUser
    return this.NoteApi.getByReceiptIds(account, receiptIds)
  }

  /**
   * Create new deal notes document
   *
   * @private
   * @param {any} dealId
   * @param {any} note
   * @returns
   */
  async createNote(note: DeepReadonly<Partial<Note>>) {
    const { account, user_id } = this.AuthApi.currentUser
    const payload = pick(note, ALLOWED_FIELDS_CREATE) as Partial<Note>
    // TODO: remove this when notes API will be ready
    payload.account = account
    payload.user_id = user_id
    const { data } = await this.NoteApi.create(account, payload)
    return data
  }

  /**
   * Update notes
   *
   * @private
   * @param {any} note
   * @returns
   */
  async update(note: DeepReadonly<Partial<Note>>) {
    const { account } = this.AuthApi.currentUser
    const payload = pick(note, ALLOWED_FIELDS_UPDATE)
    const { data } = await this.NoteApi.update(account, note.note_id, payload)
    return data
  }

  async ignore(note: Note) {
    note.attributes = note.attributes || {}
    note.attributes.ignored = dayjs().unix()
    return this.update(note)
  }

  async unignore(note: Note) {
    note.attributes = note.attributes || {}
    note.attributes.ignored = 0
    return this.update(note)
  }

  async ignoreImmutable(note: DeepReadonly<Partial<Note>>, deal?: DeepReadonly<DealBase>) {
    note = { ...note, attributes: { ...note.attributes, ignored: dayjs().unix() } }
    const stored = await this.update(note)
    if (deal) await this.updateClonedNotes(deal, stored)
    this.store.dispatch(saveNoteSuccess({note: stored}))
    return stored
  }

  async unignoreImmutable(note: DeepReadonly<Partial<Note>>) {
    note = { ...note, attributes: { ...note.attributes, ignored: 0 } }
    const stored = await this.update(note)
    this.store.dispatch(saveNoteSuccess({ note: stored }))
    return stored
  }

  /**
   * We don't update old notes. Instead we create new, but mark old as "ignored"
   */
  async saveDealNote(deal: DeepReadonly<DealBase>, note: DeepReadonly<Partial<Note>>, originalNote?: DeepReadonly<Partial<Note>>) {
    const stored = await this.recreateNote(note, originalNote)
    if (originalNote?.note_id) await this.updateClonedNotes(deal, stored)
    else await this.createClonedNotes(deal, note)
    return stored
  }

  /**
   * We don't update old notes. Instead we create new, but mark old as "ignored"
   */
  async recreateNote(note: DeepReadonly<Partial<Note>>, originalNote?: DeepReadonly<Partial<Note>>) {
    let stored = await this.createNote(note)
    if (originalNote?.note_id) {
      const updated = await this.update({
        ...originalNote,
        visibility: Math.min(originalNote.visibility, INTERNAL) as NoteVisibility,
        attributes: {
          ...originalNote.attributes,
          ignored: dayjs().unix(),
        },
      })
      stored = stored || updated
    }

    return stored
  }

  /**
   * In WA-2797 we decided we need to copy SI note to all clones
   * only if it was created in primary deal
   *
   * @private
   * @param {*} newNote - just saved note
   */
  private async createClonedNotes(deal: DeepReadonly<DealBase>, newNote: DeepReadonly<Partial<Note>>) {
    // check if current deal is primary and cloned
    if (!isDealPrimaryClone(deal) || newNote.attributes.category !== SPECIAL_INSTRUCTIONS) return
    await Promise.all(deal.attributes.clone_ids.map(deal_id =>
      this.createNote({
        ...newNote,
        deal_id,
        attributes: {
          ...newNote.attributes,
          original_note_id: newNote.note_id,
        },
      })))
  }

  /**
   * In WA-2797 we decided we need to override SI notes in cloned deals
   * NOTE: only if we edit note in the primary deal
   *
   * @private
   * @param {*} newNote - just saved note
   */
  private async updateClonedNotes(deal: DeepReadonly<DealBase>, newNote: DeepReadonly<Partial<Note>>) {
    // check if current deal is primary and cloned
    if (!isDealPrimaryClone(deal) || newNote.attributes.category !== SPECIAL_INSTRUCTIONS) return
    // find SI notes in deal clones clones
    const cloneIds = without(deal.attributes.clone_ids, deal.deal_id)
    const clonesNotes = await this.getByDealIds(cloneIds)
    const siNotes = clonesNotes.filter(note =>
      isEqual(note.attributes.company, newNote.attributes.company) &&
      note.attributes.category === SPECIAL_INSTRUCTIONS &&
      !note.attributes.ignored)

    await Promise.all(siNotes.map(note => {
      const updatedNote = {
        ...note,
        body: newNote.body,
        visibility: newNote.visibility,
        attributes: {
          ...note.attributes,
          ignored: newNote.attributes.ignored || 0,
        },
      }
      return this.recreateNote(updatedNote, note)
    }))
  }

}

function onlyCommon<T, TResult>(list: T[], getter: (value: T, index: number, collection: T[]) => TResult[], mapFn) {
  const common = intersection(...map(list, getter))
  return mapFn(common)
}

export function getGeneralCategoryOptions(deals: DealView[]) {
  if (!deals || !deals.length) {
    return [
      { id: 'general', type: 'general', name: 'General' },
      { id: GENERAL_SHIPPING, type: 'general_shipping', name: 'General Shipping' },
      { id: 'documents', type: 'document', name: 'Documents' },
      { id: SPECIAL_INSTRUCTIONS, type: 'special_instruction', name: 'Special Instructions' },
      { id: 'emergency', type: 'emergency', name: 'Emergency' },
    ]
  }

  const products = keyBy(flatten(map(deals, 'products')), 'product_id')
  return [
    { id: 'general', type: 'general', name: 'General' },
    ...orderBy(onlyCommon(deals,
      deal => uniq(map(deal.products, 'product_id')),
      common => map(pick(products, common), ({ name, product_id }) =>
        ({ name, type: 'product', id: product_id })),
    ), 'name'),
    // TODO: in theory deals might have common segments & documents
    { id: GENERAL_SHIPPING, type: 'general_shipping', name: 'General Shipping' },
    { id: 'documents', type: 'document', name: 'Documents' },
    { id: SPECIAL_INSTRUCTIONS, type: 'special_instruction', name: 'Special Instructions' },
    { id: 'emergency', type: 'emergency', name: 'Emergency' },
  ]
}

export function getGeneralCategoryOptions2(deals: DeepReadonly<DealViewRaw[]>, isBulkAdd?: boolean) {
  if (!deals?.length) {
    return [
      { id: 'general', type: 'general', name: 'General' },
      { id: GENERAL_SHIPPING, type: 'general_shipping', name: 'General Shipping' },
      { id: 'documents', type: 'document', name: 'Documents' },
      { id: SPECIAL_INSTRUCTIONS, type: 'special_instruction', name: 'Special Instructions' },
      { id: 'emergency', type: 'emergency', name: 'Emergency' }
    ]
  }

  if (isBulkAdd) {
    return uniq(flatMap(map(deals, (d) => getCategoryOptions2(d))))
  }

  const primaryCosts = deals.map(deal => deal.costs.filter(c => c.type === 'primary'))
  const commonProducts = uniqBy(intersectionBy(...primaryCosts, 'product_id'), 'product_id')
  return [
    { id: 'general', type: 'general', name: 'General' },
    ...commonProducts.map(cost =>
      ({ id: cost.product_id, type: 'product', name: cost.service })),
    { id: GENERAL_SHIPPING, type: 'general_shipping', name: 'General Shipping' },
    { id: 'documents', type: 'document', name: 'Documents' },
    { id: SPECIAL_INSTRUCTIONS, type: 'special_instruction', name: 'Special Instructions' },
    { id: 'emergency', type: 'emergency', name: 'Emergency' },
    { id: 'negative-margin', type: 'negative-margin', name: 'Negative Margin Notes' },
    { id: AES_CANCELLATION_REASON, type: AES_CANCELLATION_REASON, name: 'AES Cancellation Reason' },
    { id: MONTSHIP_BOOKING_REJECTION_REASON, type: MONTSHIP_BOOKING_REJECTION_REASON, name: 'Montship Rejection' },
  ]
}

export function getCategoryOptions(deal: DealView) {
  return [
    { id: 'general', type: 'general', name: 'General' },
    ...orderBy(map(deal.products, ({ name, product_id }) =>
      ({ name, id: product_id })), 'name'),
    { id: GENERAL_SHIPPING, type: 'general_shipping', name: 'General Shipping' },
    ...orderBy(map(deal.segments, ({ name, segment_id }: any, i) =>
      ({ name: name || `Segment ${i}`, type: 'segment', id: segment_id })), 'name'),
    ...orderBy(map(deal.invoices, ({ invoice_id }) =>
      ({ name: `Invoice #${invoice_id}`, type: 'invoice', id: invoice_id })), 'name'),
    ...orderBy(map(deal.credit_notes, ({ cn_id }) =>
      ({ name: `Credit Note #${cn_id}`, type: 'credit_note', id: cn_id })), 'name'),
    ...orderBy(map(deal.vendor_credits, ({ credit_note_id }) =>
      ({ name: `Vendor Credit #${credit_note_id}`, type: 'vendor_credit', id: credit_note_id })), 'name'),
    ...orderBy(map(deal.files, ({ name, file_id }) =>
      ({ name: `File "${name}"`, type: 'file', id: file_id })), 'name'),
    { id: 'documents', type: 'document', name: 'Documents' },
    { id: SPECIAL_INSTRUCTIONS, type: 'special_instruction', name: 'Special Instructions' },
    { id: 'emergency', type: 'emergency', name: 'Emergency' },
    { id: 'negative-margin', type: 'negative-margin', name: 'Negative Margin Notes' },
  ]
}

export function getCategoryOptions2(deal: DeepReadonly<Partial<DealViewRaw>>) {
  return [
    { id: 'general', type: 'general', name: 'General' },
    ...orderBy(map(filter(deal.costs, {type: 'primary'}), ({ service, product_id }) =>
      ({ name: service, type: 'product', id: product_id })), 'name'),
    { id: GENERAL_SHIPPING, type: 'general_shipping', name: 'General Shipping' },
    ...orderBy(map(deal.segments, ({ segment_id }, i) =>
      ({ name: `Segment ${i}`, type: 'segment', id: segment_id })), 'name'),
    ...orderBy(map(deal.invoices, ({ invoice_id }) =>
      ({ name: `Invoice #${invoice_id}`, type: 'invoice', id: invoice_id })), 'name'),
    ...orderBy(map(deal.credit_notes, ({ cn_id }) =>
      ({ name: `Credit Note #${cn_id}`, type: 'credit_note', id: cn_id })), 'name'),
    ...orderBy(map(deal.vendor_credits, ({ credit_note_id }) =>
      ({ name: `Vendor Credit #${credit_note_id}`, type: 'vendor_credit', id: credit_note_id })), 'name'),
    ...orderBy(map(deal.files, ({ name, file_id }) =>
      ({ name: `File "${name}"`, type: 'file', id: file_id })), 'name'),
    { id: 'documents', type: 'document', name: 'Documents' },
    { id: SPECIAL_INSTRUCTIONS, type: 'special_instruction', name: 'Special Instructions' },
    { id: 'emergency', type: 'emergency', name: 'Emergency' },
    { id: 'negative-margin', type: 'negative-margin', name: 'Negative Margin Notes' },
    { id: AES_CANCELLATION_REASON, type: AES_CANCELLATION_REASON, name: 'AES Cancellation Reason' },
    { id: MONTSHIP_BOOKING_REJECTION_REASON, type: MONTSHIP_BOOKING_REJECTION_REASON, name: 'Montship Rejection' },
  ]
}

export function getGeneralRecipientOptions(
  deals: DealView[],
  { accounts, carriers }: { accounts: DeepReadonly<Dictionary<AccountObject>>, carriers: DeepReadonly<Dictionary<Carrier>>},
) {
  if (!deals || !deals.length) {
    return [
      { name: 'Buyer', id: BUYER },
      { name: 'Supplier', id: SUPPLIER },
    ]
  }

  return uniqBy([
    uniqOrNothing(deals, 'supplier_id', ({ name, account }) =>
      ({ name, id: '' + account })) || { name: 'Supplier', id: SUPPLIER },
    uniqOrNothing(deals, 'buyer_id', ({ name, account }) =>
      ({ name, id: '' + account })) || { name: 'Buyer', id: BUYER },

    ...onlyCommon(deals,
      deal => uniq(map(deal.costs, 'provider')),
      common => map(pick(accounts, common), ({ name, account }) =>
        ({ name, id: '' + account }))),

    ...onlyCommon(deals,
      deal => uniq(map(deal.invoices, 'account')),
      common => map(pick(accounts, common), ({ name, account }) =>
        ({ name, id: '' + account }))),

    ...onlyCommon(deals,
      deal => uniq(map(deal.segments, 'carrier_id')),
      common => map(pick(carriers, common), ({ name, carrier_id }) =>
        ({ name, id: carrier_id }))),
  ], 'id')


  function uniqOrNothing<T, TResult>(list: T[], field: string, mapFn: (T) => TResult) {
    const all = uniq(map(list, field))
    if (all.length !== 1) return undefined
    return mapFn(accounts[all[0]])
  }
}

export function getGeneralRecipientOptions2(
  deals: DeepReadonly<DealViewRaw[]>,
  { accounts, carriers }: { accounts: DeepReadonly<Dictionary<AccountObject>>, carriers: DeepReadonly<Dictionary<Carrier>>},
) {
  if (!deals || !deals.length) {
    return [
      { name: 'Buyer', id: BUYER },
      { name: 'Supplier', id: SUPPLIER },
    ]
  }

  return uniqBy([
    uniqOrNothing(deals, 'deal.supplier_id', ({ name, account }) =>
      ({ name, id: '' + account })) || { name: 'Supplier', id: SUPPLIER },
    uniqOrNothing(deals, 'deal.buyer_id', ({ name, account }) =>
      ({ name, id: '' + account })) || { name: 'Buyer', id: BUYER },

    ...onlyCommon(deals as DealViewRaw[],
      deal => uniq(map(deal.costs, 'provider')),
      common => map(pick(accounts, common), ({ name, account }) =>
        ({ name, id: '' + account }))),

    ...onlyCommon(deals as DealViewRaw[],
      deal => uniq(map(deal.invoices, 'account')),
      common => map(pick(accounts, common), ({ name, account }) =>
        ({ name, id: '' + account }))),

    ...onlyCommon(deals as DealViewRaw[],
      deal => uniq(map(deal.segments, 'carrier_id')),
      common => map(pick(carriers, common), ({ name, carrier_id }) =>
        ({ name, id: carrier_id }))),
  ], 'id')


  function uniqOrNothing<T, TResult>(list: DeepReadonly<T[]>, field: 'deal.supplier_id' | 'deal.buyer_id', mapFn: (acc) => TResult) {
    const all = uniq(map(list, field))
    if (all.length !== 1) return undefined
    return mapFn(accounts[all[0]])
  }
}

export function getRecipientOptions(deal: DealView,
  { accounts, carriers }: { accounts: DeepReadonly<Dictionary<AccountObject>>, carriers: DeepReadonly<Dictionary<Carrier>> },
) {
  return uniqBy([
    ...map(pick(accounts, [deal.supplier_id, deal.buyer_id]), ({ name, account }) =>
      ({ name, id: '' + account })),
    ...map(pick(accounts, uniq(map(deal.costs, 'provider'))), ({ name, account }) =>
      ({ name, id: '' + account })),
    ...map(pick(carriers, uniq(map(deal.segments, 'carrier_id'))), ({ name, carrier_id }) =>
      ({ name, id: carrier_id })),
    ...map(pick(accounts, uniq(map(deal.invoices, 'account'))), ({ name, account }) =>
      ({ name, id: '' + account })),
  ], 'id')
}

export function getRecipientOptions2(dv: DeepReadonly<Partial<DealViewRaw>>,
  { accounts, carriers }: { accounts: DeepReadonly<Dictionary<AccountObject>>, carriers: DeepReadonly<Dictionary<Carrier>> },
) {
  return uniqBy([
    ...map(pick(accounts, [dv.deal.supplier_id, dv.deal.buyer_id]), ({ name, account }) =>
      ({ name, id: '' + account })),
    ...map(pick(accounts, uniq(map(dv.costs, 'provider'))), ({ name, account }) =>
      ({ name, id: '' + account })),
    ...map(pick(carriers, uniq(map(dv.segments, 'carrier_id'))), ({ name, carrier_id }) =>
      ({ name, id: carrier_id })),
    ...map(pick(accounts, uniq(map(dv.invoices, 'account'))), ({ name, account }) =>
      ({ name, id: '' + account })),
  ], 'id')
}
