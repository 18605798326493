import { Injectable } from '@angular/core'
import { AccountObject, Bid, Carrier, Cost, CostStatus, Deal, DealBase, DealProduct, DealProductBatch, DealView, DealViewBase, DealViewCosts, DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawOffers, DealViewRawSegments, DealViewShipment, Note, Offer, Product, Segment } from '@tradecafe/types/core'
import { DeepReadonly, findBuyerInvoice, findSupplierInvoice, getCostByProduct, isDealClone, isDealDelivered, isDealInvoiced, isDealPortalVisible, isDealSubmitted, pickupDate } from '@tradecafe/types/utils'
import * as weekdayPlugin from 'dayjs/plugin/weekday'
import { cloneDeep, compact, defaults, filter, find, first, get, isEqual, map, pick, set, uniq, uniqBy } from 'lodash-es'
import { dayjs } from '../dayjs'
import { isBwiInventory } from './accounts.service'

dayjs.extend(weekdayPlugin)

/**
 * Deal View service is about converting data between DealView (UI) and backend-schema
 *
 * @export
 * @returns
 */
@Injectable()
export class DealViewService {
  createDealView = createDealView
  populateProducts = populateProducts

  setSupplierView = setSupplierView
  setBuyerView = setBuyerView
  closingDate = closingDate
}


export function createDealView(deal: DeepReadonly<Deal>): DealView {
  const dealView: DealView = {
    ...pick(deal, [
      'deal_id',
      'deal_type',
      'created',
      'updated',
      'supplier_user_id',
      'buyer_user_id',
      'trader_user_id',
      'trader_user_id_supplier',
      'logistics_user_id',
      'documentation_type',
      'seller_terms',
      'buyer_terms',
      'supplier_anticipated_liability',
      'buyer_anticipated_liability',
      'collection_date',
      'buyer_confirmed',
      'seller_confirmed',
      'origin_location',
      'dest_location',
      'status',
      'locked',
      'attributes',
      'submitted',
      'bwi_inventory',
      'inv',
      'integration',
      'vendor_invoices',
      'foreign_invoices',
      'int_cred_avail',
      'wo_export_docs',
      'brokerage',
    ]),

    raw: deal,
    supplier_id: parseFloat(deal.supplier_id),
    buyer_id: parseFloat(deal.buyer_id),
  } as any as DealView

  // on the fly data migrations
  defaults(dealView.attributes, {
    supplier_user_ids: compact([dealView.supplier_user_id]),
    buyer_user_ids: compact([dealView.buyer_user_id]),
  })

  addViewMethods(dealView)
  return dealView
}

/**
 * Populate `dealView.products` field with "Deal Products" (bid&offer pairs)
 *
 * @param {any} dealView
 * @param {any} offers
 * @param {any} bids
 * @param {any} productsByIds
 * @returns
 */
export function buildProducts(
  deal: DeepReadonly<DealBase>,
  offers: DeepReadonly<Offer[]>, bids: DeepReadonly<Bid[]>, productsByIds: DeepReadonly<Dictionary<Product>>): DealProduct[] {
  if (!offers || !bids) {
    throw new Error(`Deal ${deal.deal_id} has no bids or no offers`)
  }
  if (offers.length !== bids.length) {
    throw new Error(`Deal ${deal.deal_id} offers.length should be equal to bids.length`)
  }

  return offers.map((offer) => {
    const bid = find(bids, { offer: offer.offer_id })
    return createDealProduct(bid, offer, productsByIds)
  })
}

export function buildDealProducts(
  { deal, offers, bids, costs }: DeepReadonly<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts>,
  productsByIds: DeepReadonly<Dictionary<Product>>,
) {
  const oldDealView = createDealView(deal)
  populateProducts(oldDealView, offers, bids, productsByIds)
  oldDealView.costs = costs as any
  oldDealView.products.forEach((dealProduct, product_index) => {
    const cost = getCostByProduct(oldDealView, dealProduct)
    if (cost) {
      dealProduct.supplier.actual_weight = cost.attributes.actual_buy_weight
      dealProduct.supplier.actual_price = cost.attributes.actual_buy_price
      dealProduct.buyer.actual_weight = cost.attributes.actual_sell_weight
      dealProduct.buyer.actual_price = cost.attributes.actual_sell_price
    } else {
      console.debug(`WA-2889: ${oldDealView.deal_id} product #${product_index} (${dealProduct.name}) has no primary cost`)
    }
  })
  return oldDealView.products
}

/**
 * Populate `dealView.products` field with "Deal Products" (bid&offer pairs)
 *
 * @param {any} dealView
 * @param {any} offers
 * @param {any} bids
 * @param {any} productsByIds
 * @returns
 */
function populateProducts(
  dealView: DealView,
  offers: DeepReadonly<Offer[]>,
  bids: DeepReadonly<Bid[]>,
  productsByIds: DeepReadonly<Dictionary<Product>>,
) {
  dealView.products = buildProducts(dealView, offers, bids, productsByIds)

  return dealView
}

/**
 * Create DealView product
 *
 * @private
 * @param {any} bid
 * @param {any} offer
 * @param {any} productsByIds
 * @returns
 */
// tslint:disable-next-line: cyclomatic-complexity
function createDealProduct(
  bid: DeepReadonly<Bid>,
  offer: DeepReadonly<Offer>,
  productsByIds: DeepReadonly<Dictionary<Product>>,
): DealProduct {
  const product = productsByIds[getFromBoth('product')]

  // deal product batches migration: production_date becomes production_date_range
  const batches = compact<DealProductBatch>(getFromBoth('attributes.batches') || []).map(batch => {
    if (batch.production_date_range) return batch
    const production_date_range = { from: batch['production_date'], to: batch['production_date'] }
    return { ...batch, production_date_range }
  })

  const dealProduct: DealProduct = {
    offer: cloneDeep(offer) as Offer,
    bid: cloneDeep(bid) as Bid,
    name: product.name,
    category_id: product.category_id,
    type_id: product.type_id,
    product_id: product.product_id,

    offer_id: offer.offer_id,
    supplier: {
      price: offer.price,
      currency_code: offer.currency,
      measure_id: offer.weight.unit,
      weight: offer.weight.amount,
      locked: get(offer, 'attributes.locked') as any,
      // actual_weight: get(offer, 'attributes.actual_weight'),
      // actual_price:  get(offer, 'attributes.actual_price'),
      incoterm: offer.incoterm,
      incoterm_location: get(offer, 'attributes.incoterm_location'),
      establishment: first(get(offer, 'attributes.establishments')) ||
                      get(offer, 'attributes.establishment'),
      // WA-2780: on the fly data migration. we store multiple establishments since 9 Oct'18
      establishments: get(offer, 'attributes.establishments') as any ||
                      compact([get(offer, 'attributes.establishment')]),
    },

    bid_id: bid.bid_id,
    buyer: {
      price: bid.price,
      currency_code: bid.currency,
      delivery: bid.delivery,
      weight: bid.weight.amount,
      measure_id: bid.weight.unit,
      locked: get(bid, 'attributes.locked') as any,
      // actual_weight: get(bid, 'attributes.actual_weight'),
      // actual_price:  get(bid, 'attributes.actual_price'),
      incoterm: bid.incoterm,
      incoterm_location: get(bid, 'attributes.incoterm_location'),
      invoice_address: get(bid, 'attributes.invoice_address') as any,
      // NOTE: next line is `product.buyer`, but we also store this value in offer
      // we have only one gross_weight and it is in buyer units of measure
      gross_weight: getFromBoth('attributes.gross_weight'),
    },
    attributes: {
      margin: get(bid, 'attributes.margin'), // calculated at the backend
      margin_p: get(bid, 'attributes.margin_p'), // calculated at the backend
    },
    wrapping_id: getFromBoth('wrapping'),
    description: getFromBoth('description'),
    package_id: getFromBoth('packing.package_id', 'packing.type'),
    packages_count: getFromBoth('quantity') &&
    getFromBoth('packing.packages_count', 'packing.unit'),
    package_size: getFromBoth('packing.package_size', 'attributes.package_size'),
    package_measure_id: getFromBoth('packing.package_measure_id', 'attributes.package_measure_id'),
    item_type_id: getFromBoth('attributes.item_type_id'),
    weight_type_id: getFromBoth('attributes.weight_type_id'),
    additional_specs: getFromBoth('attributes.additional_specs'),
    comments: getFromBoth('attributes.comments'),
    hs_code: getFromBoth('attributes.hs_code'),
    // lot_no: getFromBoth('lot', 'attributes.lot_no'),
    shipping_marks: getFromBoth('attributes.shipping_marks'),
    batches,
    // pallets: getFromBoth('attributes.pallets'),
    // expiry_date: getFromBoth('attributes.expiry_date'),
    // expiry_in: getFromBoth('attributes.expiry_in'),
    // production_date: getFromBoth('attributes.production_date'),
    brand: getFromBoth('attributes.brand'),
    product_code: getFromBoth('attributes.product_code'),
    /* eslint-enable key-spacing */
  }
  return dealProduct

  function getFromBoth(offerField: string, bidField = offerField) {
    const [buy, sell] = [get(offer, offerField), get(bid, bidField || offerField)]
    if (!isEqual(buy, sell) && (buy || sell)) {
      console.debug(`offer.${offerField} must be equal to bid.${bidField}`, {
        offer: buy,
        bid: sell,
      })
    }

    return buy && sell // :)
  }
}

/**
 * Add DealView getters methods
 *
 * @param {any} deal
 * @returns
 */
export function addViewMethods(deal: DealView) {
  return Object.assign(deal, {
    isInvoiced: () => isDealInvoiced(deal),
    isSubmitted: () => isDealSubmitted(deal),
    isClone: () => isDealClone(deal),
    isPortalVisible: (account: number|string) => isDealPortalVisible(deal, account),
  })
}

export function isDealInInventory(deal: DeepReadonly<Pick<Deal | DealViewBase, 'supplier_id'|'buyer_id'>>) {
  return isBwiInventory(deal.supplier_id) || isBwiInventory(deal.buyer_id)
}

export function getDealShipmentStatus(deal: DeepReadonly<DealViewRawDeal & DealViewRawCosts & DealViewRawSegments>) {
  let status = 'Unscheduled' // this is the default before an Estimated or Actual pickup date/Actual drop off date is added
  if (find(deal.segments, 'attributes.actual_pickup_date') ||
      find(deal.segments, 'attributes.actual_delivery_date')
  ) {
    status = 'Scheduled' // this is when an Actual Pickup or Drop off date is added for at least one segment
    if (isDealDelivered(deal.deal)) {
      // this is when the deal status is changed to delivered (or any stage after delivered)
      status = 'Delivered'
      if (find(deal.costs, 'attributes.actual_buy_weight')) {
        status = 'Picked Up' // this is when there is an Actual Weight entered in the product section of the shipping log details)
      }
    }
  }
  return status
}

export function hasDealStaleData(deal: DeepReadonly<DealBase & DealViewCosts>) {
  // NOTE: ensure deal.costs are ready
  if (!deal.attributes.deal_date) {
    console.warn('deal date is not defined')
    return true
  }
  // if the deal is older than 3 months
  if (dayjs().diff(dayjs.unix(deal.attributes.deal_date), 'month') >= 3) return true
  // check freight costs
  for (const cost of filter(deal.costs, 'attributes.freight_type')) {
    if (!cost.attributes.rate_until) {
      console.warn(`stale check ${deal.deal_id}: freigt rate expiration time is not defined`)
      return true
    }
    // If the date on that day is after the "valid until" date in the freight rate used
    if (dayjs().diff(dayjs.unix(cost.attributes.rate_until), 'month') >= 3) return true
    // if the freight rates are older than 3 months
    if (dayjs().diff(dayjs.unix(cost.attributes.rate_created), 'month') >= 3) return true
  }

  return false
}

export function getDealPartiesOld(
  deal: DeepReadonly<(Deal | DealViewBase) & DealViewShipment & DealViewCosts>,
  accounts: DeepReadonly<Dictionary<AccountObject>>,
  carriers: DeepReadonly<Dictionary<Carrier>>,
) {
  const carrierIds = uniq(map(deal.segments, 'attributes.freight_forwarder'))
  return uniqBy([
    ...map(pick(accounts, [deal.supplier_id, deal.buyer_id, deal?.brokerage?.customer])),
    ...map(pick(accounts, uniq(map(deal.costs, 'provider')))),
    ...map(pick(accounts, uniq(map(deal.segments, 'attributes.carrier_account')))),
    ...map(pick(accounts, uniq(map(pick(carriers, carrierIds), 'account')))),
  ], 'account')
}

export function getDealParties(
  dv: DeepReadonly<DealViewRawDeal & DealViewRawCosts & DealViewRawSegments>,
  accounts: DeepReadonly<Dictionary<AccountObject>>,
  carriers: DeepReadonly<Dictionary<Carrier>>,
): DeepReadonly<AccountObject[]> {
  const carrierIds = uniq(map(dv.segments, 'attributes.freight_forwarder'))
  return uniqBy([
    ...map(pick(accounts, [dv.deal.supplier_id, dv.deal.buyer_id, dv.deal?.brokerage?.customer])),
    ...map(pick(accounts, uniq(map(dv.costs, 'provider')))),
    ...map(pick(accounts, uniq(map(dv.segments, 'attributes.carrier_account')))),
    ...map(pick(accounts, uniq(map(pick(carriers, carrierIds), 'account')))),
  ], 'account')
}

/**
 * Copy deal (DealView instance or RAW)
 *
 * @param {any} deal - raw deal OR DealView
 * @returns
 */
export function copyDeal(originalDeal: DeepReadonly<Deal>, copyingNotCloning?: boolean): Deal
export function copyDeal(originalDeal: DeepReadonly<DealViewBase>, copyingNotCloning?: boolean): DealViewBase
export function copyDeal(originalDeal: DeepReadonly<Deal | DealViewBase>, copyingNotCloning?: boolean): Deal | DealViewBase {
  // TODO: WA-1922 use actual numbers as new estimates
  const copyFields = [
    'attributes.buyer_payment_terms',
    'attributes.buyer_user_ids',
    'attributes.docs_country',
    'attributes.estimate_id',
    'attributes.origin_country',
    'attributes.proforma_needed',
    'attributes.shipment.pickup',
    'attributes.shipment.type',
    'attributes.supplier_payment_terms',
    'attributes.supplier_user_ids',
    'attributes.temperature_units',
    'buyer_id',
    'buyer_terms', // TODO: this is `attributes.buyer_payment_terms`
    'buyer_user_id', // TODO: deprecated, use `attributes.buyer_user_ids`
    'dest_location',
    'documentation_type', // TODO: this is `attributes.docs_country`
    'logistics_user_id',
    'origin_location',
    'seller_terms', // TODO: this is `attributes.supplier_payment_terms`
    'supplier_id',
    'supplier_user_id', // TODO: deprecated. use `attributes.supplier_user_ids`
    'trader_user_id_supplier',
    'trader_user_id',
  ]

  if (!copyingNotCloning) {
    copyFields.push(
      'attributes.shipment.shipper',
      'attributes.shipment.use_corp_us_address',
      'attributes.shipment.delivery',
    )
  }
  const deal = pick(cloneDeep(originalDeal), copyFields) as Deal
  if (originalDeal.deal_id) {
    set(deal, 'attributes.copy_of', originalDeal.deal_id)
  }
  return deal
}

/**
 * Copy cost document
 *
 * @param {any} originalCost
 * @returns
 */
export function copyCost(originalCost: DeepReadonly<Partial<Cost>>) {
  const amount: Cost['amount'] = originalCost.status === 'pending'
    ? cloneDeep(originalCost.amount)
    : {
      currency: originalCost.attributes.actual_currency,
      total: originalCost.attributes.actual_amount,
      total_cad: originalCost.attributes.total_actual_cad,
      // cost_unit will be recalculated by the dealfinancials
      cost_unit: originalCost.attributes.actual_amount * originalCost.amount.cost_unit / originalCost.amount.total,
    }
  const cost = cloneDeep(pick(originalCost, [
    'account',
    'attributes.rate_id',
    'attributes.rate_created',
    'attributes.rate_until',
    'attributes.freight_type',
    'attributes.product_index',
    'attributes.default_cost',
    'cost_unit',
    'currency',
    'product_id',
    'provider',
    'service',
    'type',
  ]))
  return { ...cost, amount, status: 'pending' as CostStatus, attributes: cost.attributes || {} }
}

/**
 * Copy segment document
 *
 * @param {any} originalSegment
 * @returns
 */
export function copySegment(originalSegment: DeepReadonly<Partial<Segment>>, { mode = 'default'} = {}) {
  const SEGMENT_COPY_DEFAULT = [
    // WA-2803: default copy
    'attributes.amount',
    'attributes.carrier_account',
    'attributes.currency',
    'attributes.destination_id',
    'attributes.exact_dropoff.account',
    'attributes.exact_dropoff.address',
    'attributes.exact_loading.account',
    'attributes.exact_loading.address',
    'attributes.freight_forwarder',
    'attributes.origin_id',
    'attributes.rate_id',
    'carrier_id',
    'type',
  ]
  const SEGMENT_COPY_EXACT = [
    ...SEGMENT_COPY_DEFAULT,
    'attributes.facility_had_claims',
    'attributes.vessel',
    'attributes.actual_delivery_date_time',
    'attributes.actual_delivery_date',
    'attributes.actual_pickup_date_time',
    'attributes.actual_pickup_date',
    'attributes.eta_date',
    'attributes.eta_date_time',
    'attributes.etd_date',
    'attributes.etd_date_time',
    'attributes.cutoff_datetime',
  ]

  const segment = cloneDeep(pick(originalSegment, {
    default: SEGMENT_COPY_DEFAULT,
    exact: SEGMENT_COPY_EXACT,
  }[mode || 'default'] || SEGMENT_COPY_DEFAULT)) as Segment
  segment.attributes = segment.attributes || {} as Segment['attributes']
  return segment
}

/**
 * Copy note document
 *
 * @param {any} originalSegment
 * @returns
 */
export function copyNote(originalNote: Note): Partial<Note> {
  const note = cloneDeep(pick(originalNote, [
    'attributes.invoice_id',
    'attributes.segment_id',
    'attributes.ignored',
    'attributes.credit_note_id',
    'attributes.receipt_id',
    'attributes.category',
    'attributes.company',
    'attributes.freight_rate_id',
    'attributes.document_type',
    'visibility',
    'shipment_id',
    'type',
    'body',
  ]))
  note.attributes = note.attributes || {}
  note.attributes.original_note_id = originalNote.note_id
  return note
}

function setSupplierView(dealView: DealView, supplier: AccountObject) {
  const invoices = filter(dealView.invoices, {account: '' + dealView.supplier_id /* , type: 'payable' */})
  const invoice = findSupplierInvoice(dealView.invoices, dealView.supplier_id)
  dealView.supplier = {
    ...supplier,
    invoices,
    invoice,
  }
}

function setBuyerView(dealView: DealView, buyer: AccountObject) {
  const buyerInvoices = () => filter(dealView.invoices, {account: '' + dealView.buyer_id, type: 'receivable'})
  dealView.buyer = {
    ...buyer,
    get invoices() {
      return buyerInvoices()
    },
    get invoice() {
      return findBuyerInvoice(dealView.invoices, dealView.buyer_id)
    },
  }
}

export function closingDate(dealView: DeepReadonly<DealBase & Pick<DealViewShipment, 'earliestSegment'>>) {
  let closeDate = pickupDate(dealView)
  const formula = dealView.attributes.supplier_formula || dealView.attributes.buyer_formula
  if (formula && formula.days_before_pickup) {
    closeDate = closeDate.subtract(formula.days_before_pickup, 'day')
  } else {
    closeDate = closeDate.subtract(1, 'day')
  }
  const weekday = closeDate.weekday()
  if (weekday === 0) closeDate.subtract(2, 'day') // Sun => Fri
  if (weekday === 6) closeDate.subtract(1, 'day') // Sat => Fri
  return dayjs(closeDate).unix()
}
