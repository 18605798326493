<mat-form-field #formField (click)="!readonly && picker.open()" [formGroup]="internalForm">
  <mat-label>{{placeholder}}</mat-label>
  <span class="tc-date-range-text">{{dateRangeStr$ | async}}</span>
  <mat-date-range-input [rangePicker]="picker"
      [min]="min" [max]="max"
      [comparisonStart]="formGroup.get(comparisonStartCtrlName)?.value"
      [comparisonEnd]="formGroup.get(comparisonEndCtrlName)?.value">
    <input [readonly]="readonly" matStartDate formControlName="from" placeholder="Start date" (focus)="picker.open()">
    <input [readonly]="readonly" matEndDate formControlName="to" placeholder="End date" (focus)="picker.open()">
  </mat-date-range-input>
  <button mat-icon-button class="tc-epoch-clear" type="button"
      *ngIf="isClearVisible$ | async"
      [disabled]="formGroup.get(startCtrlName).disabled"
      (click)="clear($event)">
    <mat-icon class="fa fa-times"></mat-icon>
  </button>
  <mat-datepicker-toggle *ngIf="isToggleVisible$ | async" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker (closed)="closed.emit()">
    <mat-date-range-picker-actions *ngIf="useTemplates" #actions>
      <div class="tc-date-range-templates">
        <button mat-button *ngFor="let template of rangeTemplates" (click)="selectRange(template)">{{template.tbd}}</button>
      </div>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
