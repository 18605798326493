<div class="tc-deal-product" [formGroup]="product" gdColumns="repeat(6, minmax(0, 1fr))" gdGap="15px"
    *ngFor="let product of productsForm?.controls; let $index = index" >
  <header style="padding-top: 20px; font-weight: 500;" gdColumn="span 6">Product # {{$index + 1}} — {{ productName$ | call:product | async}}</header>
  <tc-select-search placeholder="Wrapping" gdColumn="span 3"
      [group]="product" ctrlName="wrappingId"
      [items]="wrappings$ | async" bindLabel="name" bindValue="wrapping_id"
      ></tc-select-search>
  <tc-select-search placeholder="Weight type" gdColumn="span 3"
      [group]="product" ctrlName="weightTypeId"
      [items]="weightTypes$ | async" bindLabel="name" bindValue="type_id"
      ></tc-select-search>
  <mat-form-field>
    <mat-label>No of packages</mat-label>
    <input matInput type="number" min="0" formControlName="packagesCount">
  </mat-form-field>
  <tc-select-search placeholder="Type of packages"
      [group]="product" ctrlName="packageId"
      [items]="packageTypes$ | async" bindLabel="name" bindValue="package_id"
      ></tc-select-search>
  <mat-form-field>
    <mat-label>Weight of one package</mat-label>
    <input matInput type="number" min="0"
        formControlName="packageSize"
        [matAutocomplete]="packageSize">
    <mat-autocomplete autoActiveFirstOption #packageSize="matAutocomplete">
      <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <tc-select-search placeholder="Unit of weight"
      [group]="product" ctrlName="packageMeasureId"
      [items]="measures$ | async" bindLabel="name" bindValue="measure_id"
      ></tc-select-search>
  <mat-form-field>
    <mat-label>Brand</mat-label>
    <input matInput formControlName="brand">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Product Code</mat-label>
    <input matInput formControlName="productCode">
  </mat-form-field>
  <mat-form-field gdColumn="span 6">
    <mat-label>Additional Specifications</mat-label>
    <textarea matInput rows="10" formControlName="additionalSpecs"></textarea>
  </mat-form-field>
</div>
