import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AccountObject, Cost, DealViewRaw, ForexData, Segment, SegmentType, ShipmentTypes } from '@tradecafe/types/core'
import { DeepReadonly, isDealConfirmed } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import * as dayjs from 'dayjs'
import { isEqual, sortBy } from 'lodash-es'
import { Observable, combineLatest, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators'
import { AesResponseReviewDialogService } from 'src/components/aes-response-review-dialog/aes-response-review-dialog.service'
import { BookingFormService } from 'src/components/booking-form/booking-form.service'
import { BookingReviewDialogService } from 'src/components/booking-review-dialog/booking-review-dialog.service'
import { COLOR_OPTIONS } from 'src/components/deals-list/deals-list.component'
import { SegmentFormService } from 'src/components/segment-form/segment-form.service'
import { SegmentNotificationFormService } from 'src/components/segment-form/segment-notification-form/segment-notification-form.service'
import { environment } from 'src/environments/environment'
import {
  DealDetailsFormGroup,
  DealFormGroup,
  SegmentFormGroup,
  SegmentFormValue,
  SegmentsFormGroup
} from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { buildCostFromSegment } from 'src/services/data/costs.service'
import { getDealShipmentStatus } from 'src/services/data/deal-view.service'
import { getSegmentFormPopulatedValues } from 'src/services/data/segments.service'
import { replayForm } from 'src/shared/utils/replay-form'


interface SegmentFormRow { segmentIndex: number, segmentForm: SegmentFormGroup }

@Component({
  selector: 'tc-shipping-details-form',
  templateUrl: './shipping-details-form.component.html',
  styleUrls: ['./shipping-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingDetailsFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private DealForm: DealFormService,
    private SegmentForm: SegmentFormService,
    private SegmentNotificationForm: SegmentNotificationFormService,
    private BookingForm: BookingFormService,
    private BookingReviewDialog: BookingReviewDialogService,
    private AesResponseReviewDialog: AesResponseReviewDialogService,
  ) { super() }

  // minimum shipping date. allow users from GMT+N to create "yesterday" deals at their midnight till N:00 AM
  readonly TODAY = dayjs().utc().startOf('day').unix()
  readonly ShipmentTypes = ShipmentTypes
  readonly COLOR_OPTIONS = COLOR_OPTIONS

  // input
  @Input() isReadonly: boolean
  @Input() dealId$: Observable<string>
  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
  @Input() dealForm: DealFormGroup

  @Output() costsActualizationCreation = new EventEmitter<{
    newCosts: Partial<Cost>[],
    costIds: string[],
    oldProvider: AccountObject,
    newProvider: AccountObject,
    newSegment: SegmentFormValue
  }>()
  @Output() createCostFromSegment = new EventEmitter<Partial<Cost>>()

  detailsForm: DealDetailsFormGroup
  segmentsForm: SegmentsFormGroup
  segmentForms$: Observable<SegmentFormGroup[]>
  fxRates$: Observable<ForexData>

  transportSegments$: Observable<SegmentFormRow[]>
  warehouseSegments$: Observable<SegmentFormRow[]>
  shipmentStatus$: Observable<string>
  canOpenBookingForm$: Observable<boolean>
  canOpenBookingReview$: Observable<boolean>
  hasAesResponses$: Observable<boolean>

  ngOnInit() {
    this.shipmentStatus$ = this.dealViewRaw$.pipe(map(getDealShipmentStatus))
    this.detailsForm = this.dealForm.controls.details
    this.segmentsForm = this.dealForm.controls.segments
    this.fxRates$ = replayForm(this.detailsForm.controls.fxRates)

    const segments$ = replayForm(this.segmentsForm).pipe(
      switchMap(() => this.segmentsForm.controls.length
        ? combineLatest(this.segmentsForm.controls.map(sf => replayForm(sf)))
        : of([] as SegmentFormValue[])),
      map(segments => sortBy(segments.map(({ type, order }, segmentIndex) => ({ segmentIndex, type, order })), s => s.order)),
      distinctUntilChanged<{ segmentIndex: number, type: SegmentType }[]>(isEqual))
    this.transportSegments$ = segments$.pipe(map(list => list
      .filter(({ type }) => type !== 'warehouse')
      .map(({ segmentIndex }) => ({ segmentIndex, segmentForm: this.segmentsForm.at(segmentIndex) }))))
    this.warehouseSegments$ = segments$.pipe(map(list => list
      .filter(({ type }) => type === 'warehouse')
      .map(({ segmentIndex }) => ({ segmentIndex, segmentForm: this.segmentsForm.at(segmentIndex) }))))
    this.canOpenBookingForm$ = this.dealViewRaw$.pipe(
      map(dealView => environment.enableMontshipBooking && this.isEligibleForMontshipBooking(dealView)),
    )
    this.canOpenBookingReview$ = this.dealViewRaw$.pipe(
      map(dealView => environment.enableMontshipBooking && dealView.bookings?.length > 0)
      ),
    this.hasAesResponses$ = this.dealViewRaw$.pipe(
      map(dealView => dealView?.export_reports?.[0]?.responses?.length > 0),
    )
  }

  addRegularSegment() {
    const form = this.DealForm.buildSegmentForm()
    const lastSegment = this.segmentsForm.getRawValue()[this.segmentsForm.value.length - 1]
    form.patchValue({
      type: 'land',
      // NOTE: start from 1 because Go doesn't like zeroes
      order: lastSegment ? lastSegment.order + 1 : 1,
      ...getSegmentFormPopulatedValues(lastSegment),
    })
    this.dealForm.controls.segments.push(form)
  }

  addWarehouseSegment() {
    const form = this.DealForm.buildSegmentForm()
    const lastSegment = this.segmentsForm.getRawValue()[this.segmentsForm.value.length - 1]
    form.patchValue({
      type: 'warehouse',
      // NOTE: start from 1 because Go doesn't like zeroes
      order: lastSegment ? lastSegment.order + 1 : 1,
      ...getSegmentFormPopulatedValues(lastSegment),
    })
    this.dealForm.controls.segments.push(form)
  }

  showUpdateSegment(segmentForm: SegmentFormGroup) {
    this.SegmentForm.showSegmentForm(this.dealViewRaw$, this.dealForm, segmentForm, this.isReadonly)
  }

  openSegmentNotificationDialog(segmentForm: SegmentFormGroup) {
    this.SegmentNotificationForm.showSegmentNotificationForm(this.dealForm.value.segments
      .map((s) => s.segment).filter((s) => s.type !== 'warehouse') as Segment[],
      segmentForm.value.segment.segment_id, this.dealViewRaw$)
  }

  openCreateCostDialog(segmentForm: SegmentFormGroup) {
    const { deal } = this.detailsForm.getRawValue()
    const cost = buildCostFromSegment(segmentForm, isDealConfirmed(deal))

    this.createCostFromSegment.next(cost)
  }

  showRemoveSegment(segmentForm: SegmentFormGroup) {
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.SegmentForm.removeSegmentForm(dv, this.dealForm, segmentForm)
    })
  }

  showBookingReview() {
    this.dealViewRaw$.pipe(
      take(1),
      switchMap(dv => {
          return this.BookingReviewDialog.showBookingReviewl({ dealId: dv.deal.deal_id })
      })).subscribe()
  }

  showBookingForm() {
    this.dealViewRaw$.pipe(
      take(1),
      switchMap(dv => {
        return this.BookingForm.showCreateBooking(dv)
      })).subscribe()
  }

  getSegmentFormId(_index: number, row: SegmentFormRow) {
    return row.segmentForm.controls.segment.value?.segment_id || row.segmentIndex
  }

  private isEligibleForMontshipBooking(dealView) {
    return !!dealView?.segments?.find(({ type, carrier_id }) => carrier_id === environment.montshipCarrierId && type === 'sea')
  }

  showAesResponseReviewModal() {
    this.dealViewRaw$.pipe(
      take(1),
      switchMap(dv => {
        return this.AesResponseReviewDialog.showAesResponseReviewl({ dealId: dv.deal.deal_id, deal: { export_reports: dv.export_reports } })
      })).subscribe()
  }
}
