import { Injectable } from '@angular/core'
import { Country } from '@tradecafe/types/core'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { CountryApiService } from 'src/api/metadata/country'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'

const ALLOWED_FIELDS = ['name', 'code', 'description', 'flag']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr

/**
 * Countries service
 *
 * @export
 * @returns
 */
@Injectable()
export class CountriesService {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private CountryApi: CountryApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) { }


  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  public countryChanged$ = new Subject<void>() // NOTE: unused


  /**
   * Get all available countries
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getCountries(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.CountryApi.list({ limit: Number.MAX_SAFE_INTEGER })
        .then(async (res) => {
          res.data = await this.populate(res.data)
          return res
        })
        .catch((err) => {
          this.invalidateCache()
          throw err
        })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get countries by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=country)
   */
  async getCountriesByIds(ids?: string[]) {
    const { data } = await this.getCountries()
    const index = keyBy(data, 'country_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get countries by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=country)
   */
  async getCountriesByCode() {
    const { data } = await this.getCountries()
    return keyBy(data, 'code')
  }

  /**
   * Get possible values for given country field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const { data } = await this.getCountries()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new country
   *
   * @param {any} country
   * @returns
   */
  async create(country: Country) {
    const payload = pick(country, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.CountryApi.create(payload)
    this.invalidateCache()
    this.countryChanged$.next()
    this.toaster.success('Country created successfully.')
    return data
  }

  /**
   * Update country
   *
   * @param {any} country
   * @returns
   */
  async update(country) {
    const { country_id } = country
    const payload = pick(country, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.CountryApi.update(country_id, payload)
    this.invalidateCache()
    // NOTE: in theory we should not do that, as we update objects in memory
    this.countryChanged$.next()
    this.toaster.success('Country updated successfully.')
    return data
  }

  /**
   * Update country
   *
   * @param {any} country
   * @returns
   */
  async remove(country) {
    const { data } = await this.CountryApi.delete(country.country_id)
    this.invalidateCache()
    this.countryChanged$.next()
    return data
  }


  /**
   * Invalidate countries in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }

  async populate(countries) {
    const { account } = this.AuthApi.currentUser
    const userIds = uniq(compact(map(countries, 'user_id')))
    const users = await this.Users.getUsersByIds(account, userIds)
    countries.forEach((country) => {
      country.user = users[country.user_id]
    })
    return countries
  }
}
