import { Component, OnDestroy, OnInit } from '@angular/core'
import { LazyLoaderService } from './angularjs-loader.service'

@Component({
  selector: 'tc-angularjs',
  // template: '<div ui-view ng-cloak></div>',
  template: '',
})
export class AngularJSComponent implements OnInit, OnDestroy {
  constructor(
    private lazyLoader: LazyLoaderService,
    // private elRef: ElementRef,
  ) {}

  ngOnInit() {
    // this.lazyLoader.load(this.elRef.nativeElement)
  }

  ngOnDestroy() {
    // this.lazyLoader.destroy()
  }
}
