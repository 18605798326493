import { Injectable } from '@angular/core'
import { cloneDeep, find, groupBy, map, sortBy } from 'lodash-es'
import { ModalProxyService } from 'src/shared/modal'


export interface ConfigureColumnsColumnDef<K = string> {
  displayName: string
  field: K
  index?: number // push ellipsis menu back
}

@Injectable()
export class ConfigureColumnsService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  async showColumnsConfig({available, selected}: {
    available: ConfigureColumnsColumnDef[],
    selected: string[],
  }) {
    // prepare data for old configure columns dialog
    available = cloneDeep(available)
    // tslint:disable-next-line: prefer-const
    let { false: availableItems, true: selectedItems } = groupBy(available, col => selected.includes(col.field))
    selectedItems = sortBy(selectedItems, col => selected.indexOf(col.field))

    const { selected: selectedColumns, customColumns } = await this.modalHelper.showModal({
      component: 'configureColumnsModal',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        availableItems: () => availableItems,
        selectedItems: () => selectedItems,
      },
    })

    selected = sortBy(map(selectedColumns, 'field'), field => find(available, { field }).index || 0)
    // TODO: update selected and custom columns
    return {selected, custom: customColumns}
  }
}
