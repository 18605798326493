import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { DocumentTemplate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as DocumentTemplateActions from './document-template.actions'

export const documentTemplatesFeatureKey = 'documentTemplates'
export interface DocumentTemplatesState extends EntityState<DeepReadonly<DocumentTemplate>> {
}

export const selectId = (documentTemplate: DocumentTemplate) => documentTemplate.document_id
export const adapter = createEntityAdapter<DeepReadonly<DocumentTemplate>>({ selectId })

const initialState: DocumentTemplatesState = adapter.getInitialState({
})

export const documentTemplatesReducer = createReducer(
  initialState,
  on(DocumentTemplateActions.loadDocumentTemplatesSuccess,
    (state, action) =>
      adapter.upsertMany(action.templates, state)),
  on(DocumentTemplateActions.createDocumentTemplateSuccess,
    (state, action) =>
      adapter.addOne(action.template, state)),
  on(DocumentTemplateActions.updateDocumentTemplateSuccess,
    (state, action) =>
      adapter.updateOne({ id: action.template.document_id, changes: action.template }, state)),
  on(DocumentTemplateActions.deleteDocumentTemplateSuccess,
    (state, action) =>
      adapter.removeOne(action.id, state)),
)

