import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Currency } from '@tradecafe/types/core'
import { get, set, sortBy } from 'lodash-es'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('CurrencyApi', downgradeInjectable(CurrencyApiService))


@Injectable()
@AngularCopy()
export class CurrencyApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: Currency[] }>(`${apiUrl}/currencies`, { params }).toPromise().then((res) => {
      return set(res, 'data', sortBy(get(res, 'data', []), 'code'))
    })
  }

  create(data: Partial<Currency>) {
    return this.http.post<{ data: Currency }>(`${apiUrl}/currencies`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: Currency }>(`${apiUrl}/currencies/${id}`).toPromise()
  }

  update(id: string, data: Partial<Currency>) {
    return this.http.put<{ data: Currency }>(`${apiUrl}/currencies/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: Currency }>(`${apiUrl}/currencies/${id}`).toPromise()
  }
}
