<h2 mat-dialog-title>
  <i class="fa fa-floppy-disk" aria-hidden="true"></i>&nbsp;{{title}}
</h2>
<form (submit)="save()" [formGroup]="costForm" class="tc-squeeze-grid">
  <mat-dialog-content>
    <tc-select-search placeholder="Service Provider"
        [group]="costForm" ctrlName="providerId"
        [items]="providers$ | async" bindValue="id" bindLabel="name" bindHint="hint"
        ></tc-select-search>
    <tc-select-search placeholder="Service"
        [group]="costForm" ctrlName="productId"
        [items]="filteredProducts$ | async" bindValue="product_id" bindLabel="name"
        ></tc-select-search>
    <tc-select-search placeholder="Service type"
        [ctrl]="typeCtrl"
        [items]="filteredTypes$ | async" bindValue="type_id" bindLabel="name"
        ></tc-select-search>
    <tc-select-search placeholder="Service category"
        *ngIf="!canEditServiceName"
        [ctrl]="categoryCtrl"
        [items]="filteredCategories$ | async" bindValue="category_id" bindLabel="name"
        ></tc-select-search>
    <mat-form-field *ngIf="canEditServiceName" class="mat-block">
      <mat-label>Service</mat-label>
      <input matInput type="text" autocomplete="off" formControlName="serviceName">
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <mat-form-field>
        <mat-label>Amount</mat-label>
        <input matInput type="number" autocomplete="off" formControlName="amount" step="0.0001"
            [readonly]="!canEditAmount">
      </mat-form-field>
      <tc-select-search placeholder="Currency"
          [readonly]="!canEditCurrency"
          [group]="costForm" ctrlName="currencyCode"
          [items]="currencies$ | async" bindValue="code" bindLabel="code"
          ></tc-select-search>
    </div>
    <div *ngIf="canDuplicate" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <mat-slide-toggle [formControl]="dupForm.controls.enabled" (change)="dupEnabled($event)" color="primary">
        Duplicate cost
      </mat-slide-toggle>
      <mat-form-field>
        <mat-label>Duplicates</mat-label>
        <input matInput type="number" autocomplete="off" [formControl]="dupForm.controls.duplicates" step="1" min="1">
      </mat-form-field>
    </div>
    <p *ngIf="isActualizedToZero">
      Actualized to $0 by {{isActualizedToZero.username}} on {{isActualizedToZero.timestamp| epoch:'l'}} at {{isActualizedToZero.timestamp| epoch:'LT'}}.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="!!freightRate" mat-button type="button" [matTooltip]="getProblemHistory()" matTooltipClass="pre-tooltip huge-tooltip"
      (click)="flagFreightRate()">
      <span *ngIf="isFlagged()"><i class="fa fa-flag" style="color: red;"></i></span>
      <span *ngIf="!isFlagged()"><i class="fa-regular fa-flag" style="color: red;"></i></span>
    </button>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="inProgress$ | async">
      <i class="fa fa-save"></i>&nbsp; Save
    </button>
  </mat-dialog-actions>
</form>
