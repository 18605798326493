/* eslint-disable no-multi-spaces */
export const locales = [
['Afrikaans',                     'af'],
['Albanian',                      'sq'],
['Amharic',                       'am'],
['Arabic',                        'ar'],
['Armenian',                      'hy'],
['Assamese',                      'as'],
['Azeri',                         'az'],
['Basque',                        'eu'],
['Belarusian',                    'be'],
['Bengali',                       'bn'],
['Bosnian',                       'bs'],
['Bulgarian',                     'bg'],
['Burmese',                       'my'],
['Catalan',                       'ca'],
['Chinese',                       'zh'],
['Croatian',                      'hr'],
['Czech',                         'cs'],
['Danish',                        'da'],
['Divehi Dhivehi Maldivian',      'dv'],
['Dutch',                         'nl'],
['English',                       'en'],
['Estonian',                      'et'],
['FYRO Macedonia',                'mk'],
['Faroese',                       'fo'],
['Farsi - Persian',               'fa'],
['Finnish',                       'fi'],
['French',                        'fr'],
['Gaelic',                        'gd'],
['Galician',                      'gl'],
['Georgian',                      'ka'],
['German',                        'de'],
['Greek',                         'el'],
['Guarani - Paraguay',            'gn'],
['Gujarati',                      'gu'],
['Hebrew',                        'he'],
['Hindi',                         'hi'],
['Hungarian',                     'hu'],
['Icelandic',                     'is'],
['Indonesian',                    'id'],
['Italian',                       'it'],
['Japanese',                      'ja'],
['Kannada',                       'kn'],
['Kashmiri',                      'ks'],
['Kazakh',                        'kk'],
['Khmer',                         'km'],
['Korean',                        'ko'],
['Lao',                           'lo'],
['Latin',                         'la'],
['Latvian',                       'lv'],
['Lithuanian',                    'lt'],
['Malay',                         'ms'],
['Malayalam',                     'ml'],
['Maltese',                       'mt'],
['Maori',                         'mi'],
['Marathi',                       'mr'],
['Mongolian',                     'mn'],
['Nepali',                        'ne'],
['Norwegian - Bokml',             'nb'],
['Norwegian - Nynorsk',           'nn'],
['Oriya',                         'or'],
['Polish',                        'pl'],
['Portuguese - Brazil',           'pt'],
['Portuguese - Portugal',         'pt'],
['Punjabi',                       'pa'],
['Raeto-Romance',                 'rm'],
['Romanian',                      'ro'],
['Russian',                       'ru'],
['Sanskrit',                      'sa'],
['Serbian',                       'sr'],
['Setsuana',                      'tn'],
['Sindhi',                        'sd'],
['Sinhala Sinhalese',             'si'],
['Slovak',                        'sk'],
['Slovenian',                     'sl'],
['Somali',                        'so'],
['Sorbian',                       'sb'],
['Spanish',                       'es'],
['Swahili',                       'sw'],
['Swedish',                       'sv'],
['Tajik',                         'tg'],
['Tamil',                         'ta'],
['Tatar',                         'tt'],
['Telugu',                        'te'],
['Thai',                          'th'],
['Tibetan',                       'bo'],
['Tsonga',                        'ts'],
['Turkish',                       'tr'],
['Turkmen',                       'tk'],
['Ukrainian',                     'uk'],
['Urdu',                          'ur'],
['Uzbek',                         'uz'],
['Vietnamese',                    'vi'],
['Welsh',                         'cy'],
['Xhosa',                         'xh'],
['Yiddish',                       'yi'],
['Zulu',                          'zu'],
]

export const shortCode = (lcid?: string) => (lcid || '').split('_')[0].toLowerCase()
