import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { PricingTerm } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as PricingTermActions from './pricing-term.actions'

export const pricingTermsFeatureKey = 'pricingTerms'
export interface PricingTermsState extends EntityState<DeepReadonly<PricingTerm>> {
}

export const selectId = (pricingTerm: PricingTerm) => pricingTerm.pricing_terms_id
export const adapter = createEntityAdapter<DeepReadonly<PricingTerm>>({ selectId })

const initialState: PricingTermsState = adapter.getInitialState({
})

export const pricingTermsReducer = createReducer(
  initialState,
  on(PricingTermActions.loadPricingTermsSuccess,
    (state, action) =>
      adapter.upsertMany(action.pricingTerms, state)),
)

