import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { DealViewRaw, Segment } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { last } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { selectCarrierEntities } from 'src/app/store/carriers'
import { autosaveDealSegment } from 'src/app/store/deal-view.actions'
import { loadUsers } from 'src/app/store/users'
import { SegmentFormService } from 'src/components/segment-form/segment-form.service'
import { DealFormGroup, SegmentFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { ShipmentRatePickerService } from 'src/pages/admin/trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.service'
import { getDealShipmentStatus } from 'src/services/data/deal-view.service'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'


export interface ShipmentDetailsOverlayOptions {
  dealId: string
  isReadonly?: boolean
  dealForm: DealFormGroup
  dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
}

@Component({
  selector: 'tc-shipment-details-overlay',
  templateUrl: './shipment-details-overlay.component.html',
  styleUrls: ['./shipment-details-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsOverlayComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private ShipmentRatePicker: ShipmentRatePickerService,
    private SegmentForm: SegmentFormService,
    private DealForm: DealFormService,
    private store: Store,
    private readonly toaster: ToasterService,
    @Inject(MAT_DIALOG_DATA) private dialogData: ShipmentDetailsOverlayOptions,
  ) { super() }

  // internal state
  readonly inProgress$ = new BehaviorSubject(false)
  readonly dealId = this.dialogData.dealId
  readonly isReadonly = this.dialogData.isReadonly
  readonly dealForm = this.dialogData.dealForm
  readonly detailsForm = this.dialogData.dealForm.controls.details
  readonly segmentsForm = this.dialogData.dealForm.controls.segments
  readonly dealViewRaw$ = this.dialogData.dealViewRaw$
  fxRates$ = replayForm(this.detailsForm.controls.fxRates)
  shipmentStatus$ = this.dealViewRaw$.pipe(map(getDealShipmentStatus))

  @ViewChild(DealViewIncompleteComponent) incomplete: DealViewIncompleteComponent

  ngOnInit() {
    this.store.dispatch(loadUsers({}))
  }

  showAddSegment() {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    combineLatest([
      this.dealViewRaw$.pipe(map(dto => last(dto.segments))),
      this.store.pipe(select(selectCarrierEntities)),
    ]).pipe(take(1)).subscribe(async ([lastSegment, carriers]) => {
      const { estimatedTotals: { weight } } = this.detailsForm.getRawValue()
      const segment = await this.ShipmentRatePicker.showAddSegment(lastSegment, carriers, weight)
      if (segment) this.createSegment(segment)
    })
  }

  showUpdateSegment(segmentForm: SegmentFormGroup) {
    this.SegmentForm.showSegmentForm(this.dealViewRaw$, this.dealForm, segmentForm, this.isReadonly || !!this.incomplete.incomplete)
  }

  showRemoveSegment(segmentForm: SegmentFormGroup) {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.SegmentForm.removeSegmentForm(dv, this.dealForm, segmentForm)
    })
  }

  protected createSegment(segment: Partial<DeepReadonly<Segment>>) {
    const form = this.DealForm.buildSegmentForm()
    const patch = this.DealForm.prepareDealSegmentPatch(segment, this.segmentsForm.length)
    form.patchValue(patch)
    this.segmentsForm.push(form)
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      if (this.dealId) {
        this.store.dispatch(autosaveDealSegment({
          dv,
          dealForm: this.dealForm.serialize(),
          index: this.segmentsForm.length - 1,
          patch,
        }))
      }
    })
  }
}
