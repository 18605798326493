
import { IEpochRange } from '@tradecafe/types/core'
import { identity, isEqual, isNull, isObject, mapValues, negate, pickBy, sortBy } from 'lodash-es'
import { dayjs } from './dayjs'

export interface FilterDataSelectOption<V = string> {
  id: V
  name: string
}
export interface FilterDataSelect<T = FilterDataSelectOption<any>> {
  type: 'select'
  options: T[]
}

export interface FilterDataEpoch {
  type: 'epoch-range'
  min: Date
  max: Date
}

export type FilterData = FilterDataSelect | FilterDataEpoch

export type ElasticSearchFilters = Dictionary<FilterData>


export interface ElasticSearchPayload {
  query: any,
  sort?: Dictionary<string>[],
  limit?: number,
  skip?: number,
  columns?: string[]
  calculate?: boolean
  populate_intransit_balance?: boolean
}

export interface ElasticSearchResponse<T> {
  data: T[]
  total: number
  totals: Dictionary<any>
}
export interface ESSortParamItem {
  [key: string]: 'desc' | 'asc'
}

export type ESSortParams = ESSortParamItem[]

export interface ESPaginationParams {
  limit: number
  skip: number
}

export class ElasticSearchService {
  // TODO: refactor fetchAll to observables
  protected async fetchAll<T>(
    limit: number,
    total: number | undefined,
    downloadChunk: (page: number) => Promise<ElasticSearchResponse<T>>,
  ) {
    const runStep = (page, step, resolve, reject) => {
      inProgress++
      return step().then(r => {
        result.splice(page * limit, limit, ...r)
        inProgress--
        const next = queue.shift()
        if (next) {
          const [nextPage, nextStep] = next
          runStep(nextPage, nextStep, resolve, reject)
        } else if (!inProgress) {
          resolve(result)
        }
      }).catch(reject)
    }

    const queue: Array<[number, () => Promise<T[]>]> = []
    let inProgress = 0

    for (let page = 0; !total || page < Math.ceil(total / limit); page++) {
      if (!total) {
        const r = await downloadChunk(page)
        if (!r.total) return []
        total = Math.min(10000, r.total) // NOTE: synthetic limit. backend fails when you read rows after 10k
        queue.push([page, async () => r.data])
      } else {
        queue.push([page, () => downloadChunk(page).then(r => r.data)])
      }
    }

    const result: T[] = Array.from({ length: total })
    return new Promise<T[]>((resolve, reject) => {
      queue.splice(0, 10).forEach(([page, step]) =>
        runStep(page, step, resolve, reject))
    })
  }

  protected getViewFilters(filtersData: Dictionary, booleanFilters?: string[]): ElasticSearchFilters {
    // tslint:disable-next-line: cyclomatic-complexity
    return pickBy(mapValues(filtersData, (v, key): FilterData => {
      if (booleanFilters?.includes(key)) {
        return { type: 'select', options: [{ id: false, name: 'NO' }, { id: true, name: 'YES' }] }
      } else if (Array.isArray(v)) {
        const hasNull = v.some(isNull)
        let notNulls = v.filter(negate(isNull))
        // convert to { id, name } if necessary
        if (!notNulls.every(isObject)) notNulls = notNulls.map(id => ({ id, name: id }))
        // sort if not sorted
        if (!v['sorted']) notNulls = sortBy(notNulls, 'name')
        // put "null" value on top
        const options = hasNull ? [{ id: null, name: 'NULL' }, ...notNulls] : notNulls
        return { type: 'select', options }

      } else if (v.hasOwnProperty('min') && v.hasOwnProperty('max')) {
        const min = dayjs.unix(v.min).toDate()
        const max = dayjs.unix(v.max).toDate()
        return { type: 'epoch-range', min, max }

      } else if (isEqual(v, {})) {
        const min = dayjs().add(50, 'y').toDate()
        const max = dayjs().subtract(50, 'y').toDate()
        return { type: 'epoch-range', min, max }
      }
      return undefined
    }), identity)
  }

}


export function elasticSearchDateRangeFilter(range: IEpochRange, utc = true): IEpochRange {
  if (!range) {
    return null
  }

  const dateFunc = utc ? dayjs.utc : dayjs

  return {
    from: dateFunc(range.from * 1000).startOf('date').unix(),
    to: dateFunc(range.to * 1000).endOf('date').unix(),
  }
}
