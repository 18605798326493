import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ChipsInputComponent } from './chips-input.component'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatChipsModule} from '@angular/material/chips'
import {MatIconModule} from '@angular/material/icon'

@NgModule({
  declarations: [ChipsInputComponent],
  exports: [ChipsInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
  ],
})
export class ChipsInputModule { }
