import { Injectable } from '@angular/core'
import { Deal, DealBase, DealViewCosts, DealViewRawCosts, DealViewRawDeal } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalProxyService } from 'src/shared/modal'


@Injectable()
export class CloneFormService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  show(deal: DeepReadonly<DealBase & DealViewCosts>): Promise<Deal[]> {
    return this.modalHelper.showModal({
      component: 'tcCloneForm',
      windowClass: 'modalclone',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        deal,
      },
    })
  }

  show2(dv: DeepReadonly<DealViewRawDeal & DealViewRawCosts>): Promise<Deal[]> {
    return this.show({
      ...dv.deal,
      costs: dv.costs,
    })
  }
}
