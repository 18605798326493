import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DeepReadonly } from '@tradecafe/types/utils'
import { difference } from 'lodash-es'
import { from, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { DocumentApiService } from 'src/api/document-lib/document'
import { DocumentSetApiService } from 'src/api/document-lib/document-set'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { createDocumentSet, createDocumentSetFailure, createDocumentSetSuccess, deleteDocumentSet, deleteDocumentSetFailure, deleteDocumentSetSuccess, loadDocumentSets, loadDocumentSetsFailure, loadDocumentSetsSuccess, updateDocumentSet, updateDocumentSetFailure, updateDocumentSetSuccess } from './document-set.actions'

@Injectable()
export class DocumentSetEffects {
  constructor(
    private actions$: Actions,
    private DocumentApi: DocumentApiService,
    private DocumentSetApi: DocumentSetApiService,
    private toaster: ToasterService,
  ) {}

  loadDocumentSets$ = createEffect(() => this.actions$.pipe(
    ofType(loadDocumentSets),
    switchMap(action =>
      from(this.DocumentSetApi.list()).pipe(
        map(r => loadDocumentSetsSuccess({
          tableKey: action.tableKey,
          documentSets: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch documentSets', error)
          this.toaster.error('Unable to fetch documentSets', error)
          return of(loadDocumentSetsFailure({ error }))
        })))))

  createDocumentSet$ = createEffect(() => this.actions$.pipe(
    ofType(createDocumentSet),
    switchMap(action =>
      from(this.DocumentSetApi.create(action.set).then(({ data }) =>
        this.updateDocumentsForDocumentSet(data.set_id, action.set.docs, []).then(() => data)
      )).pipe(
        map(r => createDocumentSetSuccess({ set: r })),
        tap(() => this.toaster.success('Document Set created successfully.')),
        catchError(error => {
          console.error('Unable to create document set', error)
          this.toaster.error('Unable to create document set', error)
          return of(createDocumentSetFailure({ error }))
        })))))

  updateDocumentSet$ = createEffect(() => this.actions$.pipe(
    ofType(updateDocumentSet),
    switchMap(action =>
      from(Promise.all([
        this.DocumentSetApi.update(action.id, action.set),
        this.updateDocumentsForDocumentSet(action.id, action.set.docs, action.original.docs),
      ])).pipe(
        map(([r]) => updateDocumentSetSuccess({ set: r.data })),
        tap(() => this.toaster.success('Document Set updated successfully.')),
        catchError(error => {
          console.error('Unable to update document set', error)
          this.toaster.error('Unable to update document set', error)
          return of(updateDocumentSetFailure({ error }))
        })))))

  deleteDocumentSet$ = createEffect(() => this.actions$.pipe(
    ofType(deleteDocumentSet),
    switchMap(action =>
      from(Promise.all([
        this.DocumentSetApi.delete(action.set.set_id),
        ...action.set.docs.map(docId => this.DocumentApi.removeSet(docId, action.set.set_id)),
      ])).pipe(
        map(() => deleteDocumentSetSuccess({ id: action.id })),
        tap(() => this.toaster.success('Document Set deleted successfully.')),
        catchError(error => {
          console.error('Unable to delete document set', error)
          this.toaster.error('Unable to delete document set', error)
          return of(deleteDocumentSetFailure({ error }))
        })))))

  private updateDocumentsForDocumentSet(setId: string, newDocs: DeepReadonly<string[]>, oldDocs: DeepReadonly<string[]>) {
    const added = difference(newDocs, oldDocs)
    const removed = difference(oldDocs, newDocs)

    return Promise.all([
      ...added.map(docId => this.DocumentApi.addSet(docId, setId)),
      ...removed.map(docId => this.DocumentApi.removeSet(docId, setId)),
    ])
  }
}
