import { Injectable } from '@angular/core'
import { PricingTerm } from '@tradecafe/types/core'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { PricingTermApiService } from 'src/api/metadata/pricing-term'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['term', 'insurance', 'selectable', 'ranking']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr


@Injectable()
export class PricingTermsService {
  constructor(
  private PricingTermApi: PricingTermApiService,
  private AuthApi: AuthApiService,
  private Users: UsersService,
  private Query: QueryService,
) {}

  pricingTermsChanged$ = new Subject<void>()

  cacheExpiration = 0
  cache = Promise.resolve({ data: [] })

  /**
   * Get pricingTerms by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=measure)
   */
  async getPricingTermsByIds(ids?: string[]) {
    const data = await this.getPricingTerms()
    const index = keyBy(data, 'pricing_terms_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get all available pricingTerms
   *
   * @param {any} query
   * @returns
   */
  async getPricingTerms(): Promise<PricingTerm[]>
  async getPricingTerms(query): Promise<{ data: PricingTerm[], total_rows: number }>
  async getPricingTerms(query?): Promise<{data: PricingTerm[], total_rows: number} | PricingTerm[]> {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.PricingTermApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (res) => {
        res.data = await this.populate(res.data)
        return res
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return query ? this.Query.applyQuery(data, query) : data
  }

  /**
   * Get possible values for given pricingTerm field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const pricingTerms = await this.getPricingTerms()
    const values = uniq(compact(map(pricingTerms, fieldName)))
    return values
  }

  /**
   * Get pricing term document by id
   *
   * @param {string} id
   * @returns
   */
  async getById(id: string) {
    const { data } = await this.PricingTermApi.get(id)
    return data
  }

  /**
     * Create new pricing term
     *
     * @param {any} pricingTerm
     * @returns
     */
  async create(pricingTerm) {
    const payload = pick(pricingTerm, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.PricingTermApi.create(payload)
    this.invalidateCache()
    this.pricingTermsChanged$.next()
    return data
  }

  /**
   * Update pricing term
   *
   * @param {any} pricingTerm
   * @returns
   */
  async update(pricingTerm) {
    const { pricing_terms_id } = pricingTerm
    const payload = pick(pricingTerm, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.PricingTermApi.update(pricing_terms_id, payload)
    this.invalidateCache()
    this.pricingTermsChanged$.next()
    return data
  }

  /**
   * Remove pricing term
   *
   * @param {any} pricingTerm
   * @returns
   */
  async remove(pricingTerm) {
    const { data } = await this.PricingTermApi.delete(pricingTerm.pricing_terms_id)
    this.invalidateCache()
    this.pricingTermsChanged$.next()
    return data
  }


  /**
   * Invalidate pricingTerms in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }

  private async populate(pricingTerms) {
    const { account } = this.AuthApi.currentUser
    const userIds = uniq(compact(map(pricingTerms, 'user_id')))
    const users = await this.Users.getUsersByIds(account, userIds)
    pricingTerms.forEach((pricingTerm) => {
      pricingTerm.user = users[pricingTerm.user_id]
    })
    return pricingTerms
  }
}
