import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Product } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as ProductActions from './product.actions'

export const productsFeatureKey = 'products'
export interface ProductsState extends EntityState<DeepReadonly<Product>> {
}

export const selectId = (product: Product) => product.product_id
export const adapter = createEntityAdapter<DeepReadonly<Product>>({ selectId })

const initialState: ProductsState = adapter.getInitialState({
})

export const productsReducer = createReducer(
  initialState,
  on(ProductActions.loadProductsSuccess,
    (state, action) =>
      adapter.upsertMany(action.products, state)),
)

