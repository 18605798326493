import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { EpochFieldComponent } from './epoch-field.component'


@NgModule({
  declarations: [EpochFieldComponent],
  exports: [EpochFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MtxDatetimepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveAsteriskModule,
  ],
})
export class EpochFieldModule { }
