import { Injectable } from '@angular/core'
import { TableView } from '@tradecafe/types/core'
import { cloneDeep, filter, mapKeys, memoize, pick } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { TableViewApiService } from 'src/api/table-view'
export const ALLOWED_FIELDS = ['attributes', 'columns', 'custom_columns', 'filters', 'table']
export const UPDATE_ALLOWED_FIELDS = ['attributes', 'columns', 'custom_columns', 'filters']


@Injectable()
export class TableViewsService {
  constructor(
    private AuthApi: AuthApiService,
    private TableViewApi: TableViewApiService,
  ) {}

  readonly getFor = memoize(this.getForNotCached.bind(this) as (tableIdentity: string) => Promise<TableView[]>)

  private async getForNotCached(tableIdentity: string) {
    const { account, user_id } = this.AuthApi.currentUser
    let { data } = await this.TableViewApi.list(account, {
      table: tableIdentity,
      user_id,
    })
    data = filter(data, 'attributes.version')
    data.forEach(this.postProcess)
    return data
  }

  async create(tableView: Partial<TableView>) {
    const { account, user_id } = this.AuthApi.currentUser
    tableView.attributes.version = tableView.attributes.version || 1
    const payload = pick(this.preProcess(tableView), ALLOWED_FIELDS)
    payload.account = '' + account
    payload.user_id = user_id
    const { data } = await this.TableViewApi.create(account, payload)
    this.getFor.cache.delete(tableView.table)
    return this.postProcess(data)
  }

  async update(tableView: Partial<TableView>) {
    if (tableView.attributes?.version === 2 && tableView.attributes.original?.table_view_id === tableView.table_view_id) {
      const { table_view_id, ...woId } = tableView
      return this.create(woId)
    }

    const { account } = this.AuthApi.currentUser
    const payload = pick(this.preProcess(tableView), UPDATE_ALLOWED_FIELDS)
    // TODO: research: we don't set account & user_id here
    const { data } = await this.TableViewApi.update(account, tableView.table_view_id, payload)
    this.getFor.cache.delete(tableView.table)
    return this.postProcess(data)
  }

  async remove(tableView: TableView) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.TableViewApi.delete(account, tableView.table_view_id)
    this.getFor.cache.delete(tableView.table)
    return data
  }

  async unshare(viewId: string, userId: string) {
    const { account } = this.AuthApi.currentUser
    return this.TableViewApi.unshare(account, viewId, userId)
  }

  async share(viewId: string, userId: string) {
    const { account } = this.AuthApi.currentUser
    return this.TableViewApi.share(account, viewId, userId)
  }

  async makePublic(viewId: string) {
    const { account } = this.AuthApi.currentUser
    return this.TableViewApi.public(account, viewId)
  }

  async makePrivate(viewId: string) {
    const { account } = this.AuthApi.currentUser
    return this.TableViewApi.private(account, viewId)
  }


  /**
   * Prepare document to be sent to the back-end
   *
   * @private
   * @param {any} tableView
   * @returns
   */
  private preProcess(tableView: Partial<TableView>) {
    const payload = cloneDeep(tableView)
    payload.filters = mapKeys(payload.filters, (_value, key) => key.replace(/\./g, '/'))
    return payload
  }

  /**
   * Prepare document to be used by UI
   *
   * @private
   * @param {any} tableView
   * @returns
   */
  private postProcess(tableView: Partial<TableView>) {
    tableView.filters = mapKeys(tableView.filters, (_value, key) => key.replace(/\//g, '.'))
    return tableView
  }
}

// NOTE: deprecated, use `tableViewsLoader` instead
tableViewsResolver.$inject = ['TableViewApi', 'AuthApi', 'tableIdentity']
export function tableViewsResolver(
  TableViewApi: TableViewApiService,
  AuthApi: AuthApiService,
  tableIdentity,
) {
  'ngInject'

  const user = AuthApi.currentUser
  return TableViewApi.list(user.account, {
    table: tableIdentity,
    user_id: user.user_id,
  }).then(res => filter(res.data, { attributes: { version: 1 } })).catch(() => [])
}

tableViewsLoader.$inject = ['tableViewManager', 'tableIdentity']
export function tableViewsLoader(tableViewManager, tableIdentity) {
  'ngInject'

  return tableViewManager.loadFor(tableIdentity)
}

