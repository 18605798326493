<form [formGroup]="form" (submit)="submit()">
  <h2 mat-dialog-title>
    Mark as Paid
  </h2>
  <div mat-dialog-content>
    <tc-epoch-field format="L"
                    placeholder="Actual Payment Date"
                    required
                    [group]="form"
                    ctrlName="paid"
                    [useUtc]="true"
    ></tc-epoch-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary"><i class="fa fa-calendar"></i> Confirm</button>
  </div>
</form>
