import { Injectable } from '@angular/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { statusNotesResolver } from 'src/services/data/deal.resolvers'
import { ModalProxyService } from 'src/shared/modal'


@Injectable()
export class SelectStatusNotesFormService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  select(selected: DeepReadonly<string[]>, statusNotes?) {
    return this.modalHelper.showModal({
      component: 'tcSelectStatusNotesForm',
      windowClass: 'modalShipping',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        selected: () => selected || [],
        statusNotes: statusNotes ? () => statusNotes : statusNotesResolver,
      },
    })
  }
}
