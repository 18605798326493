<form [formGroup]="itForm" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    {{title}}
  </h2>
  <div mat-dialog-content>
    <div gdColumns="minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px" style="padding-bottom: 30px">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Spanish Name</mat-label>
        <input matInput formControlName="spanishName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Temperature (°F)</mat-label>
        <input matInput type="number" formControlName="tempF">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Temperature (°C)</mat-label>
        <input matInput type="number" formControlName="tempC">
      </mat-form-field>
    </div>

    <h4 *ngIf="specsForm.controls.length">Registration Controls</h4>
    <div gdColumns="minmax(0, 4fr) minmax(0, 4fr) minmax(0, 8fr) minmax(0, 1fr)" gdGap="15px">
      <ng-container *ngFor="let item of specsForm.controls; let $index = index">
        <tc-select-search placeholder="Category"
            [ctrl]="item.controls.category"
            [items]="categories$ | call:item.controls.category.value | async" bindLabel="name" bindValue="category_id"
            ></tc-select-search>
        <tc-select-search placeholder="Select an item type" [multiple]="true"
            [ctrl]="item.controls.type"
            [items]="typesForCategory$ | call:item.controls.category | async" bindLabel="name" bindValue="type_id"
            ></tc-select-search>
        <tc-select-search placeholder="Products" [multiple]="true"
            [ctrl]="item.controls.products"
            [items]="filteredProducts$ | call:item | async" bindLabel="name" bindValue="product_id" bindHint="hint"
            ></tc-select-search>
        <button mat-icon-button (click)="removeSpec($index)">
          <mat-icon class="fa fa-trash-can"></mat-icon>
        </button>
      </ng-container>
    </div>
    <p class="text-right" style="padding-top: 15px">
      <button type="button" mat-button (click)="addSpec()">
        <i class="fa fa-plus"></i> Add Specification
      </button>
    </p>

    <h4 *ngIf="tempsForm.controls.length">Temperature Overrides</h4>
    <div cdkDropList (cdkDropListDropped)="onListDrop($event)" style="display: grid; gap: 15px">
      <div class="temp-override-row" *ngFor="let item of tempsForm.controls; let $index = index; let $last = last" [formGroup]="item" cdkDrag>
        <i class="fa fa-bars drag-row"></i>
        <tc-select-search placeholder="Companies" [multiple]="true"
            [ctrl]="item.controls.account"
            [items]="accounts$ | async" bindLabel="name" bindValue="account" bindHint="type"
            ></tc-select-search>
        <tc-select-search placeholder="Wrappings" [multiple]="true"
            [ctrl]="item.controls.wrapping_id"
            [items]="wrappings$ | async" bindLabel="name" bindValue="wrapping_id" bindHint="hint"
            ></tc-select-search>
        <tc-select-search placeholder="Packages" [multiple]="true"
            [ctrl]="item.controls.package_id"
            [items]="packageTypes$ | async" bindLabel="name" bindValue="package_id" bindHint="hint"
            ></tc-select-search>
        <mat-form-field>
          <mat-label>Temp (°F)</mat-label>
          <input matInput type="number" formControlName="F">
        </mat-form-field>
        <button class="remove-row" mat-icon-button (click)="removeTemp($index)">
          <mat-icon class="fa fa-trash-can"></mat-icon>
        </button>
        <tc-select-search placeholder="Product categories" [multiple]="true"
            [ctrl]="item.controls.category_id"
            [items]="categories$ | call:item.controls.category_id.value | async" bindLabel="name" bindValue="category_id" bindHint="hint"
            ></tc-select-search>
        <tc-select-search placeholder="Product types" [multiple]="true"
            [ctrl]="item.controls.type_id"
            [items]="types$ | async" bindLabel="name" bindValue="type_id" bindHint="hint"
            ></tc-select-search>
        <tc-select-search placeholder="Products" [multiple]="true"
            [ctrl]="item.controls.product_id"
            [items]="products$ | call:item.controls.product_id.value | async" bindLabel="name" bindValue="product_id" bindHint="hint"
            ></tc-select-search>
        <mat-form-field>
          <mat-label>Temp (°C)</mat-label>
          <input matInput type="number" formControlName="C">
        </mat-form-field>
      </div>
    </div>
    <p class="text-right" style="padding-top: 15px">
      <button type="button" mat-button (click)="addTemp()">
        <i class="fa fa-plus"></i> Add Temperature Override
      </button>
    </p>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="inProgress$ | async">
      <i class="fa fa-fw"
          [class.fa-save]="!(inProgress$ | async)"
          [class.fa-spin]="inProgress$ | async"
          [class.fa-spinner]="inProgress$ | async"
          ></i> Save
    </button>
  </div>
</form>
