import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { FilesListModule } from 'src/components/files-list/files-list.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ModalModule } from 'src/shared/modal'
import { CreateDocumentFormModule } from '../../create-document/create-document-form.module'
import { DocumentsOverlayComponent } from './documents-overlay.component'
import { DocumentsOverlayService } from './documents-overlay.service'

@NgModule({
  declarations: [DocumentsOverlayComponent],
  providers: [DocumentsOverlayService],
  imports: [
    CommonModule,
    FilesListModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ModalModule,
    SelectSearchModule,
    CreateDocumentFormModule,
  ],
})
export class DocumentsOverlayModule {}
