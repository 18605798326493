<div class="tc-table-page-table">
  <table mat-table fixedLayout [dataSource]="dataSource"
      matSort matSortActive="created" matSortDirection="desc" matSortStart="desc" matSortDisableClear>

    <tr mat-header-row *matHeaderRowDef="displayColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;" class="clickable"></tr>


    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox matTooltip="Select All" (change)="selection.toggleRows($event.checked, dataSource.data)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource">
        <div *ngIf="!element" class="skeleton-loader square"></div>
        <mat-checkbox *ngIf="element && selection.canSelect(element)"
            [checked]="selection.isSelected(element)"
            (click)="$event.stopPropagation()"
            (change)="selection.toggleRow(element)"
            ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element.created | epoch:'DD-MMM-YYYY'">{{ element.created | epoch:'DD-MMM-YYYY' }}</div></td>
    </ng-container>
    <ng-container matColumnDef="until">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Valid Until</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element.until">{{element.until | epoch:'DD-MMM-YYYY'}}</div></td>
    </ng-container>
    <ng-container matColumnDef="origin_city">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>City Of Origin</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.origin?.city">{{ element?.origin?.city }}</div></td>
    </ng-container>
    <ng-container matColumnDef="destination_city">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>City Of Destination</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.destination?.city">{{ element?.destination?.city }}</div></td>
    </ng-container>
    <ng-container matColumnDef="carrier_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Carrier</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.carrier?.name">{{ element?.carrier?.name }}</div></td>
    </ng-container>
    <ng-container matColumnDef="max_weight">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Max Weight</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.weight.max">{{ element?.weight.max }}</div></td>
    </ng-container>
    <ng-container matColumnDef="all_in_rate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>All-in Rate</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.rate?.amount | currency:element?.rate?.currency">{{ element?.rate?.amount | currency:element?.rate?.currency }}</div></td>
    </ng-container>
    <ng-container matColumnDef="rate_currency">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Currency</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.rate?.currency">{{ element?.rate?.currency }}</div></td>
    </ng-container>
    <ng-container matColumnDef="transit_time">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Transit Time</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.attributes?.transit_time">{{ element?.attributes?.transit_time }}</div></td>
    </ng-container>
    <ng-container matColumnDef="date_quoted">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Date Quoted</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.attributes?.date_quoted | epoch:'DD-MMM-YYYY'">{{ element?.attributes?.date_quoted | epoch:'DD-MMM-YYYY' }}</div></td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.statusLabel">{{ element?.statusLabel }}</div></td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.type">{{ element?.type }}</div></td>
    </ng-container>
    <ng-container matColumnDef="orgState">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>State Of Origin</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.originStateName">{{ element?.originStateName }}</div></td>
    </ng-container>
    <ng-container matColumnDef="orgCountry">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Country Of Origin</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.originCountry?.name">{{ element?.originCountry?.name }}</div></td>
    </ng-container>
    <ng-container matColumnDef="dstState">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>State Of Destination</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.destinationStateName">{{ element?.destinationStateName }}</div></td>
    </ng-container>
    <ng-container matColumnDef="dstCountry">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Country Of Destination</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.destinationCountry?.name">{{ element?.destinationCountry?.name }}</div></td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.description">{{ element?.description }}</div></td>
    </ng-container>
    <ng-container matColumnDef="port_loading">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Port Of Loading</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.port_loading">{{ element?.port_loading }}</div></td>
    </ng-container>
    <ng-container matColumnDef="port_discharge">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Port Of Discharge</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.port_discharge">{{ element?.port_discharge }}</div></td>
    </ng-container>
    <ng-container matColumnDef="commodity">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Commodity</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.commodity">{{ element?.commodity }}</div></td>
    </ng-container>
    <ng-container matColumnDef="container_size">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Container Size</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.container_size">{{ element?.container_size }}</div></td>
    </ng-container>
    <ng-container matColumnDef="problem">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Problem
      </th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)">
        <div [matTooltip]="element?.problem?.detail" matTooltipPosition='left' class="text-ellipsis">
          <mat-icon *ngIf="element?.problem && element?.problem.isFlagged" class="fa fa-flag" style="color: red;"></mat-icon>
          <mat-icon *ngIf="element?.problem && !element?.problem.isFlagged" class="fa-regular fa-flag"
            style="color: red;"></mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="creator">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Creator</th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource" (click)="showUpdateItem(element)"><div class="text-ellipsis" [title]="element?.creator?.fullname">{{ element?.creator?.fullname }}</div></td>
    </ng-container>

    <ng-container matColumnDef="ellipsisMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="editColumns.emit()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element; dataSource:dataSource">
        <button mat-icon-button type="button"
            [matMenuTriggerFor]="ellipsisMenu"
            [matMenuTriggerData]="{rate: element}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
  </table>
  <mat-paginator #paginator></mat-paginator>
</div>

<mat-menu #ellipsisMenu="matMenu" color="primary">
  <ng-template matMenuContent let-rate="rate">
    <button *ngIf="editable" mat-menu-item (click)="showUpdateItem(rate)">
      <i class="fa fa-fw fa-edit"></i> Edit
    </button>
    <button mat-menu-item (click)="showCopyItem(rate)">
      <i class="fa fa-fw fa-copy"></i> Copy
    </button>
    <button mat-menu-item (click)="showFreightRateNotes(rate)">
      <i class="fa fa-fw fa-note-sticky"></i> Note
    </button>
    <button mat-menu-item (click)="showArchiveItem(rate)">
      <i class="fa fa-fw fa-trash-can"></i> Archive
    </button>
    <button mat-menu-item (click)="showLogs(rate)">
      <i class="fa fa-fw fa-history"></i> Log
    </button>
  </ng-template>
</mat-menu>
