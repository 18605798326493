import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { CreditBalanceComponent } from './credit-balance.component'

@NgModule({
  declarations: [CreditBalanceComponent],
  exports: [CreditBalanceComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
  ],
})
export class CreditBalanceModule { }
