import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { DealViewRaw } from '@tradecafe/types/core'
import { DeepReadonly, printUnixRange } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import * as dayjs from 'dayjs'
import { compact, identity, pickBy } from 'lodash-es'
import { Observable, Subject, combineLatest } from 'rxjs'
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators'
import { selectAccountEntities } from 'src/app/store/accounts'
import { patchDealForm, saveDealFormFailure, saveDealFormSuccess } from 'src/app/store/deal-view.actions'
import { selectLocationEntities } from 'src/app/store/locations'
import { selectMeasureEntities } from 'src/app/store/measures'
import { selectPricingTermEntities } from 'src/app/store/pricing-terms'
import { selectUserEntities } from 'src/app/store/users'
import { NoteFormService } from 'src/components/notes/note-form/note-form.service'
import { hasBuyerInstructionsSel, hasSupplierInstructionsSel } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/deal-details.decorator'
import { DealFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { GENERAL_SHIPPING } from 'src/services/data/notes.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'


export interface TradingDetailsOverlayOptions {
  dealId: string
  isReadonly?: boolean
  dealForm: DealFormGroup
  dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
}

@Component({
  selector: 'tc-trading-details-overlay',
  templateUrl: './trading-details-overlay.component.html',
  styleUrls: ['./trading-details-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradingDetailsOverlayComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private NoteForm: NoteFormService,
    private DealForm: DealFormService,
    private store: Store,
    private dialog: MatDialogRef<TradingDetailsOverlayComponent>,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private dialogData: TradingDetailsOverlayOptions,
  ) { super() }

  // minimum shipping date. allow users from GMT+N to create "yesterday" deals at their midnight till N:00 AM
  readonly TODAY = dayjs().utc().startOf('day').unix()

  dealId = this.dialogData.dealId
  isReadonly = this.dialogData.isReadonly
  dealViewRaw$ = this.dialogData.dealViewRaw$
  detailsForm = this.dialogData.dealForm.controls.details
  productsForm = this.dialogData.dealForm.controls.products

  readonly inProgress$ = new Subject<boolean>()

  buyerAvgDaysToDueDate$: Observable<number>
  buyerContacts$: Observable<string>
  buyerInvoiceDate$: Observable<number>
  buyerInvoiceId$: Observable<string>
  buyerName$: Observable<string>
  buyerPricingTerms$: Observable<string>
  supplierTraderFullname$: Observable<string>
  buyerTraderFullname$: Observable<string>
  destLocationName$: Observable<string>
  hasSupplierInstructions$ = hasSupplierInstructionsSel(this.dealViewRaw$, GENERAL_SHIPPING)
  hasBuyerInstructions$ = hasBuyerInstructionsSel(this.dealViewRaw$, GENERAL_SHIPPING)

  originLocationName$: Observable<string>
  shipmentDatesRange$: Observable<string>
  supplierContacts$: Observable<string>
  supplierInvoiceDate$: Observable<number>
  supplierInvoiceId$: Observable<string>
  supplierName$: Observable<string>
  supplierPricingTerms$: Observable<string>

  buyerTermDateSnapshot$: Observable<number>
  collectionDateSnapshot$: Observable<number>
  invoiceDateSnapshot$: Observable<number>
  suplierAntLiabilitySnapshot$: Observable<number>

  ngOnInit() {
    const termsRefData$ = combineLatest([
      replayForm(this.detailsForm),
      replayForm(this.productsForm.controls[0]),
      this.store.pipe(select(selectMeasureEntities), waitNotEmpty()),
      this.store.pipe(select(selectPricingTermEntities), waitNotEmpty()),
    ])
    this.supplierPricingTerms$ = termsRefData$.pipe(
      map(([{supplierCurrencyCode}, {supplierIncotermId, supplierMeasureId}, measures, pricingTerms]) =>
        compact([supplierCurrencyCode, measures[supplierMeasureId].code, pricingTerms[supplierIncotermId].term]).join(' / ')),
      distinctUntilChanged())
    this.buyerPricingTerms$ = termsRefData$.pipe(
      map(([{buyerCurrencyCode}, {buyerIncotermId, buyerMeasureId}, measures, pricingTerms]) =>
        compact([buyerCurrencyCode, measures[buyerMeasureId].code, pricingTerms[buyerIncotermId].term]).join(' / ')),
      distinctUntilChanged())

    const dealSnapshot$ = this.dealViewRaw$.pipe(map(dv => dv.deal.attributes.snapshot), filter(identity))
    this.suplierAntLiabilitySnapshot$ = dealSnapshot$.pipe(
      map(snapshot => snapshot.supplier_anticipated_liability),
      distinctUntilChanged())
    this.invoiceDateSnapshot$ = dealSnapshot$.pipe(
      map(snapshot => snapshot.invoice_date),
      distinctUntilChanged())
    this.buyerTermDateSnapshot$ = dealSnapshot$.pipe(
      map(snapshot => snapshot.buyer_term_date),
      distinctUntilChanged())
    this.collectionDateSnapshot$ = dealSnapshot$.pipe(
      map(snapshot => snapshot.collection_date),
      distinctUntilChanged())

    const accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
    const supplier$ = combineLatest([accounts$, replayForm(this.detailsForm.controls.supplierId)])
    .pipe(map(([accounts, supplierId]) => accounts[supplierId]))
    this.supplierName$ = supplier$.pipe(map(supplier => supplier.name), distinctUntilChanged())
    const buyer$ = combineLatest([accounts$, replayForm(this.detailsForm.controls.buyerId)])
    .pipe(map(([accounts, buyerId]) => accounts[buyerId]))
    this.buyerAvgDaysToDueDate$ = buyer$.pipe(map(buyer => buyer.attributes.credit_info?.avg_days_to_due_date || 0), distinctUntilChanged())
    this.buyerName$ = buyer$.pipe(map(buyer => buyer.name), distinctUntilChanged())

    const users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
    this.supplierTraderFullname$ = combineLatest([users$, replayForm(this.detailsForm.controls.supplierTraderId)]).pipe(
      map(([users, supplierTraderId]) => users[supplierTraderId].fullname),
      distinctUntilChanged())
    this.buyerTraderFullname$ = combineLatest([users$, replayForm(this.detailsForm.controls.buyerTraderId)]).pipe(
      map(([users, buyerTraderId]) => users[buyerTraderId].fullname),
      distinctUntilChanged())
    this.buyerContacts$ = combineLatest([users$, replayForm(this.detailsForm.controls.buyerUserIds)]).pipe(
      map(([users, buyerUserIds]) => buyerUserIds.map(userId => users[userId].fullname).join(', ')),
      distinctUntilChanged())
    this.supplierContacts$ = combineLatest([users$, replayForm(this.detailsForm.controls.supplierUserIds)]).pipe(
      map(([users, supplierUserIds]) => supplierUserIds.map(userId => users[userId].fullname).join(', ')),
      distinctUntilChanged())

    const locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
    this.destLocationName$ = combineLatest([locations$, replayForm(this.detailsForm.controls.destLocationId)]).pipe(
      map(([locations, destLocationId]) => locations[destLocationId].name),
      distinctUntilChanged())
    this.originLocationName$ = combineLatest([locations$, replayForm(this.detailsForm.controls.originLocationId)]).pipe(
      map(([locations, originLocationId]) => locations[originLocationId].name),
      distinctUntilChanged())

    const supplierInvoice$ = combineLatest([this.dealViewRaw$, replayForm(this.detailsForm.controls.supplierId)]).pipe(
      map(([dv, supplierId]) => dv.invoices?.find(inv => inv.account === supplierId)),
      filter(identity))
    const buyerInvoice$ = combineLatest([this.dealViewRaw$, replayForm(this.detailsForm.controls.buyerId)]).pipe(
      map(([dv, buyerId]) => dv.invoices?.find(inv => inv.account === buyerId)),
      filter(identity))

    this.buyerInvoiceDate$ = buyerInvoice$.pipe(map(inv => inv.created), distinctUntilChanged())
    this.buyerInvoiceId$ = buyerInvoice$.pipe(map(inv => inv.invoice_id), distinctUntilChanged())
    this.supplierInvoiceDate$ = supplierInvoice$.pipe(map(inv => inv.created), distinctUntilChanged())
    this.supplierInvoiceId$ = supplierInvoice$.pipe(map(inv => inv.invoice_id), distinctUntilChanged())
    this.shipmentDatesRange$ = replayForm(this.detailsForm).pipe(map((detailsForm =>
      printUnixRange({
        from: detailsForm.shipmentDatesFrom,
        to: detailsForm.shipmentDatesTo,
        tbd: detailsForm.shipmentDatesTbd,
      }, { useUtc: true }) )))

    combineLatest([
      this.actions$.pipe(ofType(saveDealFormSuccess), tap(() => this.dialog.close())),
      this.actions$.pipe(ofType(saveDealFormSuccess, saveDealFormFailure), tap(() => this.inProgress$.next(false))),
    ]).subscribe()
  }

  addSupplierInstructions() {
    combineLatest([this.dealViewRaw$, this.hasSupplierInstructions$]).pipe(take(1)).subscribe(([dv, existing]) =>
      this.NoteForm.editSpecialInstructions(dv, existing, dv.deal.supplier_id, GENERAL_SHIPPING))
  }

  addBuyerInstructions() {
    combineLatest([this.dealViewRaw$, this.hasBuyerInstructions$]).pipe(take(1)).subscribe(([dv, existing]) =>
      this.NoteForm.editSpecialInstructions(dv, existing, dv.deal.buyer_id, GENERAL_SHIPPING))
  }

  submit() {
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.inProgress$.next(true)
      const form = this.DealForm.serializeDealDetails(this.detailsForm)
      const dirty = pickBy(form, (_value, key) => this.detailsForm.controls[key]?.dirty)
      this.store.dispatch(patchDealForm({
        dv,
        dealForm: {
          updated: dv.deal.updated,
          details: { deal_id: dv.deal.deal_id, ...dirty },
        },
      }))
    })
  }

  cancel() {
    this.dialog.close()
  }
}
