import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal'
import { BookingReviewDialogComponent, BookingReviewDialogOptions } from './booking-review-dialog.component'

@Injectable()
export class BookingReviewDialogService {

  constructor(private modal: ModalService) { }

  showBookingReviewl(opts: BookingReviewDialogOptions) {
    return this.modal.openDialog<BookingReviewDialogComponent, BookingReviewDialogOptions, boolean>(BookingReviewDialogComponent, opts, {
      panelClass: ['tc-dialog', 'modal-xlg'],
      width: null,
      maxWidth: null,
    })
  }
}
