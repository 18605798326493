import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadCarriers, loadCarriersFailure, loadCarriersSuccess } from './carrier.actions'

@Injectable()
export class CarrierEffects {
  constructor(
    private actions$: Actions,
    private carriers: CarriersService,
    private toaster: ToasterService,
  ) {}

  loadCarriers$ = createEffect(() => this.actions$.pipe(
    ofType(loadCarriers),
    switchMap(action =>
      from(this.carriers.getCarriers()).pipe(
        map(carriers => loadCarriersSuccess({
          tableKey: action.tableKey,
          carriers,
        })),
        catchError(error => {
          console.error('Unable to fetch carriers', error)
          this.toaster.error('Unable to fetch carriers', error)
          return of(loadCarriersFailure({ error }))
        })))))
}
