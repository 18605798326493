import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealDetailsOverlayComponent, DealDetailsOverlayOptions } from './deal-details-overlay.component'

@Injectable()
export class DealDetailsOverlayService {
  constructor(private modal: ModalService) {}

  showDealDetails(dealId: string) {
    this.modal.openLargeSide<DealDetailsOverlayComponent, DealDetailsOverlayOptions>(
      DealDetailsOverlayComponent,
      { dealId },
    )
  }
}
