import { Injectable } from '@angular/core'
import { AllInvoiceStatuses, Cost, Deal, DealView, DealViewBase, DealViewCosts, DealViewInvoices, INVOICE_ACTUALIZED, INVOICE_APPROVED, INVOICE_DENIED, INVOICE_PAID, INVOICE_SCHEDULED, Invoice, InvoiceCost, InvoiceLineItem, Note } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, lineItemDummy, lineItemFromCost, lineItemsFromPrepayment } from '@tradecafe/types/utils'
import { cloneDeep, compact, filter, first, flatten, get, groupBy, intersection, keyBy, map, merge, orderBy, pick, reject, set, sumBy, uniq } from 'lodash-es'
import { Observable } from 'rxjs'
import { map as _map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { CostApiService } from 'src/api/cost/cost'
import { InvoiceApiService, InvoiceRowOld } from 'src/api/invoice'
import { OperationsApiService } from 'src/api/operations'
import { extendUnixRange } from 'src/directives/epoch-range/epoch-range.utils'
import { environment } from 'src/environments/environment'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { dayjs } from '../dayjs'
import { NotesService } from './notes.service'
import { chunked, mergeDeep } from './utils'

const ALLOWED_FIELDS_UPDATE = ['batch_id', 'vendor_invoice_id', 'status', 'template', 'issued', 'due', 'currency', 'attributes', 'inv']

const { tradecafeAccount } = environment


/**
 * Invoices service
 *
 * @see https://docs.google.com/document/d/1EaTyv23coC3G34JMh-foYsRXKy24EK9n3COdKKimVVs/edit
 *
 * @export
 * @returns
 */
@Injectable()
export class InvoicesService {
  constructor(
    private AuthApi: AuthApiService,
    private InvoiceApi: InvoiceApiService,
    private toaster: ToasterService,
    private Notes: NotesService,
    private CostApi: CostApiService,
    private OperationsApi: OperationsApiService,
  ) {}

  // tslint:disable-next-line: cyclomatic-complexity
  async searchInvoices(filters) {
    const { type, status, created, due, issued, account, invoice_id, vendor_invoice_id, limit } = filters
    const query: any = {
      _limit: 1000,
      _sort_by: 'created',
      _sort_direction: -1,
    }
    if (invoice_id?.length) query.invoice_id = invoice_id
    if (type?.length) query.type = type
    if (status?.length) query.status = status
    if (account?.length) query.account = account
    if (created && created.from && created.to) {
      [query.creation_date_from, query.creation_date_to] = extendUnixRange(created, true)
    }
    if (due && due.from && due.to) {
      [query.due_date_from, query.due_date_to] = extendUnixRange(due, true)
    }
    if (issued && issued.from && issued.to) {
      [query.issue_date_from, query.issue_date_to] = extendUnixRange(issued, true)
    }

    if (vendor_invoice_id) {
      query.vendor_invoice_id = [vendor_invoice_id]
    }

    if (limit) {
      query._limit = limit
    }

    const {data} = await this.InvoiceApi.search(query)
    return data
  }

  async getByIds(invoiceIds: string[]) {
    const invoices = await Promise.all(invoiceIds.map(invoiceId => this.getById(invoiceId)))
    return keyBy(invoices, 'invoice_id')
  }

  async getById(invoiceId: string) {
    const {data} = await this.InvoiceApi.get(tradecafeAccount, invoiceId)
    return data
  }

  // only one buyer invoice for one deal
  async getBuyerInvoiceForDeal(deal_id: string) {
    const {data} = await this.InvoiceApi.list(tradecafeAccount, {deal_id, type: 'receivable', limit: 10})
    return data && data[0]
  }

  // only one brokerage invoice for one deal
  async getBrokerageInvoiceForDeal(deal_id: string) {
    const {data} = await this.InvoiceApi.list(tradecafeAccount, {deal_id, type: 'brokerage', limit: 10})
    return data && data[0]
  }

  /**
   * Load deals costs
   *
   * @param {string[]} dealIds
   * @returns {Promise} of an array with invoice
   */
  async getForDeals(dealIds: string[]) {
    const data = await chunked(dealIds, 400, deal_ids =>
      this.InvoiceApi.listByDealIds({deal_ids, limit: Number.MAX_SAFE_INTEGER}).then(r => r.data))
    return groupBy(filter(data, 'deal_id'), 'deal_id')
  }

  /**
   * Create buyer invoice if it doesn't exist
   *
   * @param {any} deal - Deal View object (with invoices)
   * @returns buyer invoice (new or existing)
   */
  async createBuyerInvoiceFor(deal: DealView) {
    if (environment.enableBrokerageDeals && deal.deal_type === 'brokerage') return
    const { data: invoice } = await this.OperationsApi.createBuyerInvoice(deal.deal_id)
    deal.invoices.push(invoice)
    // deal.buyer.invoice = invoice
  }

  /**
   * Create brokerage invoice if it doesn't exist
   *
   * @param {any} deal - Deal View object (with invoices)
   * @returns buyer invoice (new or existing)
   */
  async createBrokerageInvoiceFor(deal: DeepReadonly<DealView>) {
    if (deal.deal_type !== 'brokerage') return undefined
    const { data: invoice } = await this.OperationsApi.createBrokerageInvoice(deal.deal_id)
    return invoice
  }

  /**
   * Create prepayment invoice for the given deal
   *
   * @param {*} dealView
   * @param {*} { amount, due, issued, status }
   * @returns
   */
  async createPrepaymentFor(dealView: (Deal | DealViewBase) & DealViewCosts & DealViewInvoices, invoice: {
    amount: number,
    due: number,
    issued?: number,
  }) {
    return this.OperationsApi.createPrepaymentInvoice({ deal_id: dealView.deal_id, ...invoice })
  }

  /**
   * Update invoice document
   *
   * @param {any} invoice
   * @returns
   */
  async updateInvoice(invoice: DeepReadonly<Partial<Invoice>>) {
    const payload = pick(invoice, ALLOWED_FIELDS_UPDATE)
    const {data} = await this.InvoiceApi.update(tradecafeAccount, invoice.invoice_id, payload)
    return data
  }

  patch(invoice: DeepReadonly<Invoice>, payload: Partial<DeepReadonly<Invoice>>) {
    return this.InvoiceApi.update(tradecafeAccount, invoice.invoice_id, payload).then(r => r.data)
  }

  patchInvoice(invoice: DeepReadonly<Invoice>, payload: Partial<DeepReadonly<Invoice>>) {
    return this.patch(invoice, payload).then(() => {
      this.toaster.success('Invoice updated successfully')
    }, (err) => {
      console.error('Unable to update invoice', err)
      this.toaster.error('Unable to update invoice', err)
    })
  }

  patchImmutableInvoice(invoice: DeepReadonly<Invoice>, changes: DeepPartial<DeepReadonly<Invoice>>) {
    changes = pick(changes, ALLOWED_FIELDS_UPDATE)
    invoice = mergeDeep(cloneDeep(invoice), changes)
    const payload = pick(invoice, Object.keys(changes)) // keep other attributes in play
    return this.patch(invoice, payload)
  }

  // WA-2158
  checkNoteWarning(invoices: InvoiceRowOld[]) {
    const dealIds = compact(uniq(map(invoices, 'deal_id')))
    return this.Notes.getForDeals(dealIds).then((notesByDealId) => {
      invoices.forEach(invoice => {
        const dealNotes = orderBy(notesByDealId[invoice.deal_id] || [], ['created'], ['desc'])
        ; (invoice as any).dealNotes = dealNotes
        ; (invoice as any).noteWarning = dealNotes.find(note => { // set the first note, will be shown as tooltip in list
          if (note.attributes?.ignored) return false // ignore ignored note
          return ['general', invoice.invoice_id, 'negative-margin'].includes(note.attributes?.category)
        })
      })
    })
  }

  findNotesFor(invoices: DeepReadonly<Invoice[]>): Observable<Record<string, Note>> {
    const dealIds = compact(uniq(map(invoices, 'deal_id')))
    return this.Notes.getForDealsObs(dealIds).pipe(_map(notesByDealId =>
      invoices?.reduce((res, invoice) => {
      const dealNotes = orderBy(notesByDealId[invoice.deal_id] || [], ['created'], ['desc'])
      const note = dealNotes.find((note) => {
        if (note.attributes?.ignored) return false // ignore ignored note
        return invoice.invoice_id === note.attributes?.category
      })
      // set the first note, will be shown as tooltip in list
      if (note) res[invoice.invoice_id] = note
      return res
    }, {} as Record<string, Note>)))
  }

  /**
   * Approve invoice and update associated costs with actual amount
   *
   * @param {*} invoice
   * @returns
   */
  async approveInvoice(invoice: Invoice) {
    this.setInvoiceStatus(invoice, INVOICE_APPROVED)
    const {data} = await this.InvoiceApi.update(tradecafeAccount, invoice.invoice_id, {
      status: invoice.status,
      attributes: invoice.attributes,
    })
    Object.assign(invoice, data)
    await this.updateCostsActualAmount(invoice.invoice_id, get(invoice, 'attributes.costs'))
    return data
  }

  /**
   * Unapprove invoice
   *
   * @param {*} invoice
   * @returns
   */
  async unapproveInvoice(invoice: Invoice) {
    this.setInvoiceStatus(invoice, INVOICE_SCHEDULED)
    delete invoice.attributes.approved
    delete invoice.attributes.approved_user
    const {data} = await this.InvoiceApi.update(tradecafeAccount, invoice.invoice_id, {
      status: invoice.status,
      attributes: invoice.attributes,
    })
    Object.assign(invoice, data)
    return invoice
  }

  /**
   * Void invoice
   *
   * @param {*} invoice
   * @returns
   */
  voidInvoice(invoice: Invoice) {
    const {user_id} = this.AuthApi.currentUser
    const patchData = {
      void: dayjs.utc().unix(),
      status: INVOICE_DENIED,
      attributes: {
        ...invoice.attributes || {},
        voided_user: user_id,
      },
    }
    return this.InvoiceApi.update(invoice.account, invoice.invoice_id, patchData)
                     .then(() => this.cancelCostsActualAmount(invoice.invoice_id, get(invoice, 'attributes.costs')))
                     .then(() => {
                       merge(invoice, patchData)
                       const statusObj = AllInvoiceStatuses[invoice.status]
                       if (statusObj) {
                         set(invoice, 'statusObj', statusObj)
                       }
                     })
  }

  /**
   * Update costs with actual amount
   *
   * @private
   * @param {*} invoiceId
   * @param {*} costs
   * @returns
   */
  private updateCostsActualAmount(invoiceId: string, costs: DeepReadonly<InvoiceCost[]>) {
    return Promise.all(map(costs, cost =>
      this.CostApi.get(cost.cost_id).then(({data: original}) =>
        this.CostApi.update(tradecafeAccount, cost.cost_id, {
          attributes: merge(original.attributes, {
            associated: {
              invoice_id: invoiceId,
              amount: cost.actual,
              currency: cost.currency,
            },
          }),
        }).then(r => r.data),
      )))
  }

  /**
   * Cancel (deassociate) costs with actual amount
   *
   * @private
   * @param {string} invoiceId
   * @param {*} invoiceCosts
   * @returns
   */
  private cancelCostsActualAmount(invoiceId: string, invoiceCosts: DeepReadonly<InvoiceCost[]>) {
    return Promise.all(map(invoiceCosts, ({cost_id}) =>
      this.CostApi.get(cost_id).then(({data: original}) => {
          // don't cancel costs associated with another invoice
        if (get(original, 'attributes.associated.invoice_id') !== invoiceId) return undefined
        return this.CostApi.update(tradecafeAccount, cost_id, {
          status: 'pending',
          attributes: set(original.attributes, 'associated', null),
        }).then(r => r.data)
      },
      )))
  }

  /**
   * Build list with invoice line items using associated costs and provided prepayments
   *
   * @param {*} invoice
   * @param {*} {costs, prepayments = [], results}
   * @returns line_items
   */
  buildLineItems(
    { total, costs: invoiceCosts }: {
      total: number,
      costs: DeepReadonly<InvoiceCost[]>,
    },
    { costs, prepayments = [], results }: {
      costs: DeepReadonly<Dictionary<Cost>>,
      prepayments?: Invoice[],
      results?: {split, appliedPrepayments},
    },
  ) {
    const lineItems = []
    if (!invoiceCosts.length) {
      lineItems.push(lineItemDummy(total))
    } else {
      lineItems.push(...invoiceCosts.map((costItem) => {
        const cost = costs[costItem.cost_id]
        return lineItemFromCost(costItem.actual, cost)
      }))
    }
    if (prepayments.length) {
      const {split, appliedPrepayments} = this.calculatePrepayments(lineItems, prepayments)
      results.split = split
      results.appliedPrepayments = appliedPrepayments
      lineItems.push(...flatten(map(appliedPrepayments, lineItemsFromPrepayment)))
    }
    return lineItems
  }

  /**
   * Decide which prepayments to apply; detect if a prepayment needs to be splet up.
   *
   * @private
   * @param {*} lineItems
   * @param {*} prepayments
   * @returns
   */
  private calculatePrepayments(lineItems: InvoiceLineItem[], prepayments: Invoice[]) {
    const invoiceAmount = sumBy(lineItems, li => li.price * li.quantity)
    // const prepaymentsAmount = sumBy(prepayments, 'total')
    let split: any = false
    const appliedPrepayments = []
    const availablePrepayments = orderBy(prepayments, ['total'], ['asc'])

    for (let remainingAmount = invoiceAmount; remainingAmount > 0 && availablePrepayments.length > 0;) {
      const prepayment = first(availablePrepayments)
      if (prepayment.total <= remainingAmount) {
        // skip prepayment
        availablePrepayments.shift()
        remainingAmount -= prepayment.total
        appliedPrepayments.unshift(prepayment)
      } else {
        // split prepayment on to two parts. apply one; put remainings into the second
        appliedPrepayments.unshift(prepayment)
        prepayment.total -= remainingAmount
        prepayment.line_items[0].quantity -= remainingAmount / prepayment.line_items[0].quantity
        split = {
          oldPrepayment: prepayment,
          newPrepayment: {
            amount: remainingAmount,
            due: prepayment.due,
            issued: prepayment.issued,
            status: prepayment.status,
          },
        }
        break
      }
    }
    return {split, appliedPrepayments}
  }

  private setInvoiceStatus(invoice: Invoice, status: Invoice['status']) {
    invoice.status = status
    const {user_id} = this.AuthApi.currentUser
    if (status === INVOICE_ACTUALIZED) {
      set(invoice, 'attributes.actualized', dayjs.utc().unix())
      set(invoice, 'attributes.actualized_user', user_id)
    } else if (status === INVOICE_APPROVED) {
      invoice.attributes.approved = dayjs.utc().unix()
      invoice.attributes.approved_user = user_id
    }
  }
}

export function getAvailablePrepaymentsForInvoice(
  account: number | string,
  invoiceCosts: InvoiceCost[],
  invoices: Invoice[],
  dealCosts: Cost[],
  paidRequired = false,
) {
  const invoicedCostIds = invoiceCosts.map(c => c.cost_id)
  const vendorPrepayments = filter(reject(invoices, { status: INVOICE_DENIED }), {
    account: account.toString(),
    attributes: {
      prepayment: true,
    },
  })
  const primaryCostIds = map(filter(dealCosts, { type: 'primary' }), 'cost_id')

  // 1. not applied
  // 2. should be paid
  return filter(vendorPrepayments, (prepayment) => {
    let prepaymentCostIds = map(get(prepayment, 'attributes.costs', []), 'cost_id')
    if (!prepaymentCostIds.length) {
      prepaymentCostIds = primaryCostIds // default to primary costs
    }

    // current invoice should has the same costs with prepayment
    if (!intersection(invoicedCostIds, prepaymentCostIds).length) {
      return false
    }

    return !get(prepayment, 'attributes.prepayment_applied_to') && (paidRequired ? prepayment.status === INVOICE_PAID : true)
  })
}
