import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core'

@Directive({
  selector: '[tc-sticky-header]',
})
export class StickyHeaderDirective implements AfterViewInit, OnDestroy {
  constructor(private el: ElementRef) {}

  private readonly observer = new IntersectionObserver(
    ([e]) => {
      return e.target.classList.toggle('tc-sticky-header-pinned', !e.isIntersecting /* intersectionRatio < 1 */)
    },
    { threshold: [1], root: null })

  ngAfterViewInit() {
    this.observer.observe(this.el.nativeElement)
  }

  ngOnDestroy() {
    this.observer.unobserve(this.el.nativeElement)
    this.observer.disconnect()
  }
}
