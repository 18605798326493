import { CurrencyPipe, DecimalPipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { AllInvoiceStatuses } from '@tradecafe/types/core'
import { get } from 'lodash-es'
import { EpochRangePipe } from 'src/filters/epoch-range.pipe'
import { EpochPipe } from 'src/filters/epoch.pipe'
import { MeasurePipe } from 'src/filters/measure.pipe'
import { ColumnDef } from 'src/services/table-utils'


@Injectable()
export class CsvFormatterService {

  constructor(
    private decimalPipe: DecimalPipe,
    private measurePipe: MeasurePipe,
    private currencyPipe: CurrencyPipe,
    private epochPipe: EpochPipe,
    private epochRangePipe: EpochRangePipe,
  ) {}

  // tslint:disable-next-line: cyclomatic-complexity
  getCellValue<T>(row: T, col: ColumnDef): string {
    let value = get(row, col.exportField)
    if (!value && value !== 0) return ''

    const [pipeName, ...args] = col.exportPipe?.split(':') || [] as any[]

    switch (pipeName) {
      case 'epoch':
        value = value ? this.epochPipe.transform(value, ...args.length ? args : ['L']) : ''
        break

      case 'currency':
        value = (value || value === 0) ? this.currencyPipe.transform(value, ...args.map(a => get(row, a) || a)) : ''
        break

      case 'epochRange':
        value = value ? this.epochRangePipe.transform(value, ...args) : ''
        break

      case 'price':
        value = value ? `${this.decimalPipe.transform(value.amount, '1.4')} ${this.measurePipe.transform(value.currency, value.measure_id)}` : ''
        break

      case 'yesNo':
        value = value ? 'YES' : 'NO'
        break

      case 'measure':
        value = value ? this.measurePipe.transform(this.decimalPipe.transform(value.amount, '1.2'), value.measure_id) : ''
        break

      case 'invoiceStatus':
        value = AllInvoiceStatuses[value]?.value || ''
        break

      case 'percent':
        value = (value || value === 0) ? `${this.decimalPipe.transform(value * 100, '1.2-2')}%` : ''
        break

      case 'number':
        if (typeof value === 'number') {
          value = (value || value === 0) ? this.decimalPipe.transform(value, ...args) : ''
        }
        break

      case 'temperature':
        value = (value || value === 0) ? `${value} °${get(row, args[0]) || 'C'}` : ''
        break

      default:
        if (pipeName) throw Error(`Unknown pipeName "${pipeName}"`)
    }

    if (typeof value === 'number') {
      console.warn(`use 'number' and/or 'currency' pipe for column "${col.field}"`)
      value = value.toString()
    } else if (Array.isArray(value)) {
      value = value.toString()
    }

    return value
  }
}
