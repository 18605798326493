import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AccountObject, DealFormula, DealPartyE, DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawFiles, DealViewRawInvoices, DealViewRawOffers, DealViewRawSegments } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { map as _map, compact, get, isEqual, uniq, uniqWith } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { selectAccountEntities, selectBuyers, selectSuppliers } from 'src/app/store/accounts'
import { massDealForm, massDealFormFailure, massDealFormSuccess } from 'src/app/store/deal-view.actions'
import { selectAllLocations } from 'src/app/store/locations'
import { selectAllMeasures, selectMeasureEntities } from 'src/app/store/measures'
import { selectAllPricingTerms, selectPricingTermEntities } from 'src/app/store/pricing-terms'
import { selectProductEntities } from 'src/app/store/products'
import { selectAllUsers } from 'src/app/store/users'
import { DealFormCalculatorService } from 'src/services/data/deal-form-calculator.service'
import { DealViewCalculatorService } from 'src/services/data/deal-view-calculator.service'
import { canChangeParty } from 'src/services/data/deal-view-permissions.service'
import { buildDealProducts } from 'src/services/data/deal-view.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealDocumentsService } from '../../../deals/deal-documents/deal-documents.service'
import { DealFormDto } from '../deal-form.schema'

export interface ChangePartyFormOptions {
  deals: DeepReadonly<Array<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawFiles & DealViewRawSegments & DealViewRawInvoices>>
  party: undefined | DealPartyE.buyer | DealPartyE.supplier
  editableParty?: boolean
}

export type ChangePartyFormResult = DeepPartial<DealFormDto>[]

@Component({
  selector: 'tc-change-party-form',
  templateUrl: './change-party-form.component.html',
  styleUrls: ['./change-party-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePartyFormComponent implements OnInit {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private DealDocuments: DealDocumentsService,
    private DealViewCalculator: DealViewCalculatorService,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private dialogData: ChangePartyFormOptions,
    private dialogRef: MatDialogRef<ChangePartyFormComponent, ChangePartyFormResult>,
    private store: Store,
    private DealFormCalculator: DealFormCalculatorService,
  ) {}

  // static / config
  deals = this.dialogData.deals
  party = this.dialogData.party
  editableParty = this.dialogData.editableParty

  // internal state
  inProgress$ = new BehaviorSubject(false)
  allVisible$ = new BehaviorSubject<Record<string, boolean>>({})
  private initial: { account?: string, formula?: DealFormula } = {}

  // editable form
  linesForm = new FormArray<FormGroup<{
    price: FormControl<number>,
    location: FormControl<string>,
    conditions: FormControl<string>,
  }>>([], Validators.required)

  form = new FormGroup({
    account: new FormControl<number>(undefined, Validators.required), // this.account
    price: new FormControl<number>(undefined, [Validators.required, Validators.min(0)]), // this.price
    usdaProductName: new FormControl<string>(undefined, Validators.required), // this.formula.usda_product_name
    daysBeforePickup: new FormControl<number>(undefined, [Validators.required, Validators.min(0)]), // this.formula.days_before_pickup
    overridePrice: new FormControl<'flat' | 'formula' | undefined>(undefined),
    lines: this.linesForm,
  })

  // ref data
  accounts$ = this.party === 'buyer'
    ? this.store.pipe(select(selectBuyers()), waitNotEmpty())
    : this.store.pipe(select(selectSuppliers()), waitNotEmpty())
  private accountsByIds$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  private locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  pricingTerms$ = this.store.pipe(select(selectAllPricingTerms), waitNotEmpty())
  measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())
  users$ = this.store.pipe(select(selectAllUsers), waitNotEmpty())
  private products$ = this.store.pipe(select(selectProductEntities), waitNotEmpty())

  // calculated fields
  currency = this.printCurrency()
  priceSuffix$ = this.printPriceSuffix()
  isFlat$ = replayForm(this.form.controls.overridePrice).pipe(map(x => x === 'flat'), distinctUntilChanged())
  isFormula$ = replayForm(this.form.controls.overridePrice).pipe(map(x => x === 'formula'), distinctUntilChanged())


  ngOnInit() {
    // check all deals - can user change party?
    const denied = this.deals.filter(dv => !canChangeParty(dv.deal, this.party, this.AuthApi.currentUser))
    if (denied.length) {
      this.toaster.warning(`Deal${denied.length === 1 ? ` ${denied[0].deal.deal_id} is` : 's are'} submitted. Can't change ${this.party}.`)
      this.form.controls.account.disable()
    }

    const isB = this.party === DealPartyE.buyer
    // if all deals have the same party - preselect "account" form field
    const accountIds = uniq(compact(this.deals.map(dv => isB ? dv.deal.buyer_id : dv.deal.supplier_id)))
    if (accountIds.length === 1) {
      this.form.controls.account.setValue(parseFloat(accountIds[0]))
      this.initial.account = accountIds[0]
    }

    // if all deals have the same fixed price - preselect "price" form field
    const prices = uniq(compact(this.deals.map(dv => isB ? dv.bids[0].price : dv.offers[0].price)))
    if (prices.length === 1) {
      this.form.controls.price.setValue(prices[0])
    }

    // if all deals have the same formula - preselect "overridePrice" radio button
    const formulas: DealFormula[] = uniqWith(compact(this.deals.map(dv =>
      isB ? dv.deal.attributes.buyer_formula : dv.deal.attributes.supplier_formula)), isEqual)
    if (formulas.length === 1) {
      this.form.controls.overridePrice.setValue('formula')
      this.initial.formula = formulas[0]
    } else if (this.form.controls.price.value && !formulas.length) {
      this.form.controls.overridePrice.setValue('flat')
    }
    if (!accountIds.length && !this.form.controls.overridePrice.value) {
      this.form.controls.overridePrice.setValue('flat')
    }
    this.accountsByIds$.pipe(take(1)).subscribe(accounts => {
      if (formulas.length < 2) {
        this.patchFormWithFormula(formulas[0] || this.defaultFormula(accounts[this.form.controls.account.value]))
      }
    })
  }

  private readFormulaFromForm(): DealFormula {
    const form = this.form.value // skip disabled fields
    return {
      usda_product_name: form.usdaProductName,
      days_before_pickup: form.daysBeforePickup,
      lines: this.linesForm.getRawValue(),
    }
  }

  save() {
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()
    const { overridePrice, price, account } = this.form.value

    if (overridePrice === 'formula' && !this.form.valid) return

    this.inProgress$.next(true)
    const isB = this.party === DealPartyE.buyer
    combineLatest(this.deals.map((dv) => {
      if (!account || account.toString() === dv.deal[`${this.party}_id`]) {
        return of({ dv, patch: { details: { deal_id: dv.deal.deal_id }} as DeepPartial<DealFormDto>})
      } else {
        return this.setDealParty(dv, this.party, account?.toString())
          .pipe(map(patch => ({ dv, patch })))
      }
    })).pipe(
      map(pairs => pairs.map(({ dv, patch }) => {
        if (overridePrice === 'formula') {
          const formula = this.readFormulaFromForm()
          patch.details = { ...patch.details, ...(isB ? { buyerFormula: formula } : { supplierFormula: formula })}
          // return Deals.patchDeal(deal, { attributes: deal.attributes })
        } else if (overridePrice === 'flat') {
          if (isB) {
            patch.details = { ...patch.details, buyerFormula: null }
            patch.products = patch.products
              ? patch.products.map(p => ({ ...p, buyerEstPrice: price, buyerActualPrice: price }))
              : dv.bids.map(({bid_id, offer}) => ({ bid_id, offer_id: offer, buyerEstPrice: price, buyerActualPrice: price }))
          } else {
            patch.details = { ...patch.details, supplierFormula: null }
            patch.products = patch.products
              ? patch.products.map(p => ({ ...p, supplierEstPrice: price, supplierActualPrice: price }))
              : dv.bids.map(({bid_id, offer}) => ({ bid_id, offer_id: offer, supplierEstPrice: price, supplierActualPrice: price }))
          }
        }
        return {dv, patch}
      })),
      tap(changes => {
        const result = changes.map(c => c.patch)
        if (this.dialogData.deals[0].deal.deal_id) {
          this.store.dispatch(massDealForm({ party: this.party, changes }))
          this.actions$.pipe(ofType(massDealFormSuccess, massDealFormFailure), take(1)).subscribe((action) => {
            if (action.type === massDealFormSuccess.type) this.dialogRef.close(result)
            this.inProgress$.next(false)
          })
        } else {
          this.dialogRef.close(result)
        }
      }))
    .subscribe()
  }

  cancel() {
    this.dialogRef.close()
  }

  private printPriceSuffix() {
    return combineLatest([
      this.store.pipe(select(selectMeasureEntities), waitNotEmpty()),
      this.store.pipe(select(selectPricingTermEntities), waitNotEmpty()),
    ]).pipe(map(([measures, pricingTerms]) => {
      const isB = this.party === DealPartyE.buyer
      const incoterms = uniq(this.deals.map(dv => isB ? dv.bids[0].incoterm : dv.offers[0].incoterm))
      const units = uniq(this.deals.map(dv => isB ? dv.bids[0].weight.unit : dv.offers[0].weight.unit))
      let incoterm = '', measure = ''
      if (incoterms.length === 1) incoterm = pricingTerms[incoterms[0]]?.term
      if (units.length === 1) measure = measures[units[0]]?.symbol || units[0]
      return `${this.currency}/${measure} ${incoterm}`
    }))
  }

  private printCurrency() {
    const isB = this.party === DealPartyE.buyer
    const currencies = uniq(this.deals.map(dv => isB ? dv.bids[0].currency : dv.offers[0].currency))
    let currency = ''
    if (currencies.length === 1) [currency] = currencies
    return currency
  }


  private setDealParty(
    dv: DeepReadonly<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawSegments & DealViewRawInvoices & DealViewRawFiles>,
    party: undefined | DealPartyE.buyer | DealPartyE.supplier,
    accountId: string,
  ): Observable<DeepPartial<DealFormDto>> {
    return this.products$.pipe(
      take(1),
      switchMap(productsByIds =>
        this.DealDocuments.onPartyChanged({
          ...dv.deal,
          files: dv.files,
          products: buildDealProducts(dv, productsByIds),
        }, party)),
      switchMap(() => combineLatest([
        this.DealFormCalculator.prefillPartyFields(false, party, accountId),
        this.DealFormCalculator.refillPaymentTerms(accountId, party, party === 'buyer' ? dv.deal.trader_user_id : dv.deal.trader_user_id_supplier),
      ]).pipe(switchMap(([{ details, product }, details2]) => {
        if (details2) details = { ...details, ...details2 }
        let deal = dv.deal
        if (details.supplierPaymentTerms) deal = { ...deal, attributes: { ...deal.attributes, supplier_payment_terms: details.supplierPaymentTerms}}
        if (details.buyerPaymentTerms) deal = { ...deal, attributes: { ...deal.attributes, buyer_payment_terms: details.buyerPaymentTerms}}
        if (details.originLocationId) deal = { ...deal, origin_location: details.originLocationId }
        if (details.destLocationId) deal = { ...deal, dest_location: details.destLocationId }

        const r = this.DealViewCalculator.calculateTermDates({ ...dv, deal }, party)
        return this.DealViewCalculator.refreshSecondaryCosts({ ...dv, deal: r.deal })
        .pipe(map(costs => {
          const products = dv.bids.map(({ bid_id, offer: offer_id }) => ({ bid_id, offer_id, ...product }))
          return { details: { deal_id: dv.deal.deal_id, ...details, ...r.details }, products, costs }
        }))
      }))))
  }

  filteredLocations$(index: number) {
    return combineLatest([
      this.allVisible$, this.accountsByIds$, this.locations$,
      replayForm(this.linesForm).pipe(map(lines => lines[index])),
    ]).pipe(map(([allVisible, accounts, locations, lineForm]) => {
      if (allVisible[index]) return locations
      const account = accounts[this.form.controls.account.value]
      const visibleIds = account?.attributes?.locations || []
      return locations.filter(l => visibleIds.includes(l.location_id) || l.location_id === lineForm.location)
    }))
  }

  toggleLocations(index: number) {
    this.allVisible$.next({
      ...this.allVisible$.value,
      [index]: !this.allVisible$.value[index],
    })
  }

  addNewLine() {
    this.linesForm.push(new FormGroup({
      price: new FormControl<number>(undefined, Validators.required),
      location: new FormControl<string>(undefined, Validators.required),
      conditions: new FormControl<string>(undefined),
    }))
  }

  removeLine(index: number) {
    this.linesForm.removeAt(index)
  }

  onAccountChange(account: DeepReadonly<AccountObject>) {
    if (!this.initial.formula && this.initial.account !== account.account.toString()) {
      this.patchFormWithFormula(this.defaultFormula(account))
    }
  }

  private patchFormWithFormula(formula: DealFormula) {
    this.form.patchValue({
      usdaProductName: formula.usda_product_name,
      daysBeforePickup: formula.days_before_pickup,
    })
    formula.lines.forEach(line => this.linesForm.push(new UntypedFormGroup({
      price: new UntypedFormControl(line.price, Validators.required),
      location: new UntypedFormControl(line.location),
      conditions: new UntypedFormControl(line.conditions),
    })))
  }

  private defaultFormula(account: DeepReadonly<AccountObject>): DealFormula {
    const isB = this.party === DealPartyE.buyer
    const oppositeParty = isB ? 'supplier' : 'buyer'

    const [{deal, bids: [bid], offers: [offer]}] = this.deals
    let location
    if (this.initial.account === account?.account.toString()) {
      location = isB ? bid.attributes.incoterm_location : offer.attributes.incoterm_location
    }
    location = location || isB ? deal.dest_location : deal.origin_location
    location = location || account?.attributes?.pricing?.tax_location

    // take default name from any formula within selection
    const [anyFormula] = compact([
      ...this.deals.map(({deal: { attributes: { buyer_formula, supplier_formula }}}) => isB ? buyer_formula : supplier_formula),
      ...this.deals.map(({deal: { attributes: { buyer_formula, supplier_formula }}}) => !isB ? buyer_formula : supplier_formula),
    ])
    if (!anyFormula) return { lines: [{ location }] } as DealFormula

    const { usda_product_name } = anyFormula
    // take default values from the first selected deal with "opposite party" formula defined
    const [oppositeFormula] = compact(_map(this.deals, `attributes.${oppositeParty}_formula`))
    if (!oppositeFormula) return { usda_product_name, lines: [{ location }] } as DealFormula

    return {
      usda_product_name,
      days_before_pickup: get(oppositeFormula, 'days_before_pickup'),
      lines: [{ location, price: get(oppositeFormula, 'lines.0.price') }],
    }
  }
}
