import { Pipe, PipeTransform } from '@angular/core'
import { Deal, DealView } from '@tradecafe/types/core'
import { isDealInInventory } from 'src/services/data/deal-view.service'

@Pipe({
  name: 'zeroIfBwi',
  pure: true,
})
export class ZeroIfBwiPipe<T> implements PipeTransform {
  transform(value: T, deal: Deal | DealView): T|0 {
    return isDealInInventory(deal) ? 0 : value
  }
}
