import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'


export interface DealFormPageOverlayOptions {
  dealId: string
}

@Component({
  selector: 'tc-deal-form-page-overlay',
  templateUrl: './deal-form-page-overlay.component.html',
  styleUrls: ['./deal-form-page-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealFormPageOverlayComponent {
  public dealId:string
  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: DealFormPageOverlayOptions,
    private dialogRef: MatDialogRef<DealFormPageOverlayOptions>,
  ) {
    this.dialogRef.disableClose = true
    this.dealId = dialogData.dealId
  }

  @HostListener('document:keydown.escape', ['$event'])
  cancel() {
    this.dialogRef.close()
  }
}
