import { Injectable } from '@angular/core'
import { Measure, User } from '@tradecafe/types/core'
import { convertMeasures } from '@tradecafe/types/utils'
import { compact, keyBy, map, memoize, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { MeasureApiService } from 'src/api/product/measure'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['name', 'symbol', 'code', 'precision', 'conversions']


interface MeasureView extends Measure {
  user: User
}


/**
 * Measures service
 *
 * @export
 * @returns
 */
@Injectable()
export class MeasuresService {
  constructor(
    private AuthApi: AuthApiService,
    private MeasureApi: MeasureApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) {}


  private readonly syncCache: Dictionary<Measure> = {}
  private readonly fetchAllCached = cachedAsync(() => this.fetchAll())
  measureChanged$ = new Subject<void>()


  /**
   * Get all available measures
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getMeasures() {
    return this.fetchAllCached()
  }

  /**
   * Get measures by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=measure)
   */
  async getMeasuresByIds(ids?: string[]) {
    const data = await this.getMeasures()
    const index = keyBy(data, 'measure_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get measure by measure_id or code
   *
   * @param {string} measure_id
   * @returns {Object} { measure_id: measure_obj, .. }
   */
  getMeasureByIdOrCodeSync(idOrCode: string) {
    return this.syncCache?.[idOrCode]
  }

  /**
   * Get all available measures with users
   *
   * TODO: move closer to the grid
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getMeasuresForGrid(query) {
    const measures = await this.fetchAllCached()
    const { account } = this.AuthApi.currentUser
    const userIds = uniq(compact(map(measures, 'user_id')))
    const users = await this.Users.getUsersByIds(account, userIds)
    measures.forEach((measure: MeasureView) => {
      measure.user = users[measure.user_id]
    })
    return this.Query.applyQuery(measures, query)
  }

  private async fetchAll() {
    const { data: measures } = await this.MeasureApi.list({ limit: Number.MAX_SAFE_INTEGER })
    const byIdOrCode = {
      ...keyBy(measures, 'measure_id'),
      ...keyBy(measures, 'code'),
    }
    Object.keys(this.syncCache).forEach(idOrCode => {
      if (!byIdOrCode[idOrCode]) delete this.syncCache[idOrCode]
    })
    Object.assign(this.syncCache, byIdOrCode)
    return measures
  }


  /**
   * Get possible values for given measure field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const data = await this.getMeasures()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new measure
   *
   * @param {any} measure
   * @returns
   */
  async create(measure: Partial<Measure>) {
    const payload = pick(measure, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id
    payload.conversions = payload.conversions || {}

    const { data } = await this.MeasureApi.create(payload)
    this.invalidateCache()
    this.measureChanged$.next()
    return data
  }

  /**
   * Update measure
   *
   * @param {any} measure
   * @returns
   */
  async update(measure: Partial<Measure>) {
    const { measure_id } = measure
    const payload = pick(measure, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id
    payload.conversions = payload.conversions || {}

    const { data } = await this.MeasureApi.update(measure_id, payload)
    this.invalidateCache()
    this.measureChanged$.next()
    return data
  }

  /**
   * Update measure
   *
   * @param {any} measure
   * @returns
   */
  async remove(measure: Measure) {
    const { data } = await this.MeasureApi.delete(measure.measure_id)
    this.invalidateCache()
    this.measureChanged$.next()
    return data
  }

  convert(amount: number, from: string | Measure, to: string | Measure) {
    return convertMeasures(amount, from, to, this.syncCache)
  }

  /**
   * Invalidate measures in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.fetchAllCached.invalidate()
  }
}

// TODO: implement using ES7 decorators
// see https://raw.githubusercontent.com/developit/decko/master/src/decko.js
function cachedAsync<T>(fn: (...args) => Promise<T>) {
  const meme = memoize(fn)
  // NOTE: args[0] is `key` in lodash.memoize
  const result = (...args) => meme(...args).catch((err) => {
    result.invalidate()
    throw err
  })
  result.invalidate = () => meme.cache.clear()
  return result
}

