import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatChipInputEvent } from '@angular/material/chips'

@Component({
  selector: 'tc-chips-input',
  templateUrl: 'chips-input.component.html',
  styleUrls: ['chips-input.component.scss'],
})
export class ChipsInputComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const

  addOnBlur = true
  isRequired = false

  @Input() ctrl?: UntypedFormControl
  @Input() group?: UntypedFormGroup
  @Input() ctrlName?: string

  @Input() placeholder: string
  @Input() realPlaceholder: string

  @Output() change = new EventEmitter<string[]>()

  ngOnInit() {
    this.ctrl = this.group.controls[this.ctrlName] as UntypedFormControl
    if (!this.ctrl) throw new Error(`can not find control "${this.ctrlName}"`)
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim()
    const items = [...this.ctrl.value]

    if (value) {
      items.push(value)
      this.ctrl.setValue(items)
    }

    event.input.value = ''
    this.change?.emit(items)
  }

  remove(item): void {
    const index = this.ctrl.value.indexOf(item)
    const items = [...this.ctrl.value]

    if (index >= 0) {
      items.splice(index, 1)
      this.ctrl.setValue(items)
    }
    this.change?.emit(items)
  }
}
