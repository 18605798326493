import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ConsigneesApiService } from 'src/api/consignees'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import {
  createConsignee,
  createConsigneeFailure,
  createConsigneeSuccess,
  loadConsignees,
  loadConsigneesFailure,
  loadConsigneesSuccess,
  updateConsignee,
  updateConsigneeFailure,
  updateConsigneeSuccess
} from './consignees.actions'

@Injectable()
export class ConsigneesEffects {
  constructor(
    private actions$: Actions,
    private consignees: ConsigneesApiService,
    private toaster: ToasterService,
  ) {}

  loadConsignees$ = createEffect(() => this.actions$.pipe(
    ofType(loadConsignees),
    switchMap(() => {
      return this.consignees.list({limit: Number.MAX_SAFE_INTEGER}).pipe(
        map(r => loadConsigneesSuccess({
          consignees: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch consignees', error)
          this.toaster.error('Unable to fetch consignees', error)
          return of(loadConsigneesFailure({ error }))
        }))})))

  createConsignees$ = createEffect(() => this.actions$.pipe(
    ofType(createConsignee),
    switchMap(action => {
      return this.consignees.create(action.consignee).pipe(
        map(r => createConsigneeSuccess({
          consignee: r.data,
        })),
        catchError(error => {
          console.error('Unable to create consignees', error)
          this.toaster.error('Unable to create consignees', error)
          return of(createConsigneeFailure({ error }))
        }))})))

  updateConsignee$ = createEffect(() => this.actions$.pipe(
    ofType(updateConsignee),
    switchMap(action => {
      return this.consignees.update(action.id, action.consignee).pipe(
        map(r => updateConsigneeSuccess({
          consignee: r.data,
        })),
        catchError(error => {
          console.error('Unable to update consignees', error)
          this.toaster.error('Unable to update consignees', error)
          return of(updateConsigneeFailure({ error }))
        }))})))
}
