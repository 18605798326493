import { Injectable } from '@angular/core'
import { Cost, Offer, SupplierOffer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { get, omit, pick } from 'lodash-es'
import { SupplierOfferApiService } from 'src/api/supplier-offer'
import { ALLOWED_FIELDS } from './offers.service'


/**
 * Supplier Offers service
 *
 * @see https://docs.google.com/document/d/1EWIctYczd9xjHAyuFw6ZA8TGIZmZXuyX_eaT_f3iwTQ
 *
 * @export
 * @returns
 */
@Injectable()
export class SupplierOffersService {
  constructor(private SupplierOfferApi: SupplierOfferApiService) {}


  async create(offer: Partial<SupplierOffer>) {
    const payload = pick(offer, ALLOWED_FIELDS)
    const { data, error } = await this.SupplierOfferApi.create(payload)
    if (error) throw error
    return data
  }

  async generate(payload: {
    deal_id: string,
    expire: number,
    costs: string[],
    buyers: number[],
    buyers_copy_si: number[],
    countries: string[],
    locations: string[],
    copy_all_si: boolean,
    skip_cost_matching: boolean,
    skip_delivery_dates_calculation: boolean,
    biddable: boolean,
    mo_costs: DeepReadonly<Cost[]>,
  }) {
    const { data, error } = await this.SupplierOfferApi.generate(payload)
    if (error) throw error // don't trust 200
    return data
  }

  async clone(payload) {
    const offer_id = get(payload, 'offer.offer_id')
    const offer = omit(payload, ['offer.offer_id', 'offer.created'])
    const { data, error } = await this.SupplierOfferApi.clone(offer_id, offer)
    if (error) throw error
    return data
  }

  async reload(offer: Offer) {
    const data  = await this.SupplierOfferApi.reload(offer.offer_id)
    return data
  }

  async attachMO(payload) {
    const {data, error } = await this.SupplierOfferApi.attachMO(payload)
    if (error) throw error
    return data
  }

}
