import { Injectable } from '@angular/core'
import { DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawFiles, DealViewRawOffers } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalService } from 'src/shared/modal/modal.service'
import { ChangeBrokeragePartyFormComponent, ChangeBrokeragePartyFormOptions, ChangeBrokeragePartyFormResult } from './change-brokerage-party-form.component'

@Injectable()
export class ChangeBrokeragePartyFormService {
  constructor(private modal: ModalService) {}

  showChangeBrokerageParty(deals: DeepReadonly<Array<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawFiles>>) {
    return this.show(deals)
  }

  private show(deals: DeepReadonly<Array<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawFiles>>) {
    return this.modal.openDialog<ChangeBrokeragePartyFormComponent, ChangeBrokeragePartyFormOptions, ChangeBrokeragePartyFormResult>(ChangeBrokeragePartyFormComponent, {
      deals,
    }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
    })
  }
}
