import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FreightRatesPageComponent } from './freight-rates-page.component'


const routes: Routes = [{ path: '', component: FreightRatesPageComponent }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class FreightRatesPageRoutingModule { }
