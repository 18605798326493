import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { DeepReadonly } from '@tradecafe/types/utils'
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { uniq } from 'lodash-es'
import { asyncScheduler, combineLatest, Observable, of, scheduled } from 'rxjs'
import { concatAll, distinctUntilChanged, map, pairwise, switchMap, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { selectUserEntities } from 'src/app/store/users'
import { waitNotEmpty } from '../utils/wait-not-empty'
import { LockApiService, LockConnection, LockIdentity } from './lock-api.service'


@Injectable()
export class LocksService {
  constructor(
    private LockApi: LockApiService,
    private AuthApi: AuthApiService,
    private store: Store,
  ) { }

  private sockets: Record<string, LockConnection> = {}

  private lock(resourceId: string) {
    const connection = this.sockets[resourceId] || this.LockApi.lock(resourceId)
    if (!connection) return null
    this.sockets[resourceId] = connection
    connection.references++;
    return connection.lock$
  }

  private unlock(resourceId: string) {
    const connection = this.sockets[resourceId]
    if (!connection) return null
    connection.references--;
    if(connection.references === 0) {
      connection.unlock()
      delete this.sockets[resourceId]
    }
  }

  smartlock(resourceKind: string, resourceId$: Observable<string>, destroyable: OnDestroyMixin) {
    componentDestroyed(destroyable).pipe(withLatestFrom(resourceId$))
    .subscribe(([, resourceId]) => this.unlock(resourceId))

    return combineLatest([
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
      scheduled([
        [undefined], // start with undefined
        resourceId$.pipe(distinctUntilChanged()),
      ], asyncScheduler).pipe(concatAll(), pairwise(), switchMap(([prev, dealId]): Observable<DeepReadonly<LockIdentity[]>> => {
        if (prev) this.unlock(prev)
        return dealId ? this.lock(dealId) : of([])
      })),
    ]).pipe(map(([usersById, locks]) => {
      if (!locks || locks.length < 2) return '' // current tab is the only reader
      const users = locks.map(l => usersById[l.user_id])
      if (uniq(users).length === 1) return `This ${resourceKind} is open in another tab`
      const competitors = uniq(users)
        .filter(user => user.user_id !== this.AuthApi.currentUser.user_id)
        .map(user => user.fullname)
      if (competitors.length > 2) return `${competitors[0]} and others are also editing this ${resourceKind}`
      if (competitors.length === 2) return `${competitors.join(' and ')} are also editing this ${resourceKind}`
      return `${competitors[0]} is also editing this ${resourceKind}`
    }), distinctUntilChanged())
  }
}
