import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { AddressFieldModule } from 'src/components/address-field/address-field.module'
import { CostsListModule } from 'src/components/costs-list/costs-list.module'
import { CreditBalanceModule } from 'src/components/credit-balance/credit-balance.module'
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module'
import { NoteFormModule } from 'src/components/notes/note-form/note-form.module'
import { ProductFieldModule } from 'src/components/product-field/product-field.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { BrokerageDatesFormModule } from '../../../deals/brokerage-dates-form/brokerage-dates-form.module'
import { BrokerageInfoFormModule } from '../../../deals/brokerage-info-form/brokerage-info-form.module'
import { DealAutocopyFormModule } from '../deal-autocopy-form/deal-autocopy-form.module'
import { DealDetailsComponent } from './deal-details.component'
import { DealTotalsModule } from './deal-totals/deal-totals.module'
import { ShippingSummaryModule } from './shipping-summary/shipping-summary.module'
import { TradingSummaryModule } from './trading-summary/trading-summary.module'


@NgModule({
  declarations: [DealDetailsComponent],
  exports: [DealDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTooltipModule,
    FlexLayoutModule,
    CostsListModule,
    PipesModule,
    SelectSearchModule,
    EpochRangeFieldModule,
    ReactiveAsteriskModule,
    DealTotalsModule,
    TradingSummaryModule,
    ShippingSummaryModule,
    CreditBalanceModule,
    DealAutocopyFormModule,
    AddressFieldModule,
    ProductFieldModule,
    NoteFormModule,
    BrokerageInfoFormModule,
    BrokerageDatesFormModule,
  ],
})
export class DealDetailsModule { }
