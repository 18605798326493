import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Product } from '@tradecafe/types/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { map as _map, cloneDeep, compact, filter, findIndex, flatten, groupBy, identity, isEmpty, isEqual, omit, orderBy, pick, pickBy, reject, xorWith } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadAccounts, selectAccountEntities, selectAllAccounts } from 'src/app/store/accounts'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadProductCategories, selectAllProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes, selectAllProductTypes } from 'src/app/store/product-types'
import { environment } from 'src/environments/environment'
import { AccountsService } from 'src/services/data/accounts.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { ProductsService } from '../../products.service'

export interface ProductFormOptions {
  title?: string,
  product?: Product,
}

@Component({
  selector: 'tc-product-form',
  styleUrls: ['./product-form.component.scss'],
  templateUrl: './product-form.component.html',
})
export class ProductFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private toaster: ToasterService,
    private Products: ProductsService,
    private Accounts: AccountsService,
    private store: Store,
    private dialogRef: MatDialogRef<ProductFormComponent, Partial<Product>>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ProductFormOptions,
    private AuthApi: AuthApiService,
  ) {
    super()
  }

  protected productForm = new FormGroup({
    product_id: new FormControl<string>(undefined),
    name: new FormControl<string>(undefined, Validators.required),
    category_id: new FormControl<string>(undefined, Validators.required),
    type_id: new FormControl<string>(undefined, Validators.required),
    unique_cost: new FormControl<boolean>(false),
    schedule_b_codes: new FormArray([]),
    overrides: new FormArray([]),
    so_creation: new FormControl<boolean>(false),
  });

  get overrides() {
    const x = this.productForm.get("overrides") as FormArray;
    return x.controls as FormGroup[];
  }

  get scheduleBCodes() {
    const x = this.productForm.get("schedule_b_codes") as FormArray;
    return x.controls as FormGroup[];
  }

  freightProductCategoryId = environment.freightProductCategoryId
  title: string;

  inProgress$ = new BehaviorSubject<string>(undefined);
  overridesSnapshot$ = new BehaviorSubject(undefined);

  accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  productTypes$ = this.store.pipe(select(selectAllProductTypes), waitNotEmpty())
  categories$ = this.store.pipe(select(selectAllProductCategories), waitNotEmpty())

  selectableAccounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(), map((accounts) => orderBy(accounts, 'name')))
  selectableTypes$ = combineLatest([this.productTypes$, replayForm(this.productForm.controls.category_id).pipe(distinctUntilChanged())]).pipe(map(([productTypes, categoryId]) => productTypes?.filter(x => x.category_id === categoryId)))

  ngOnInit(): void {
    this.inProgress$.next('loading')
    this.title = this.dialogData?.title || 'Product type'
    this.productForm.patchValue(this.dialogData.product)
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProductTypes({}))

    this.dialogData.product?.schedule_b_codes?.forEach(code => this.productForm.controls.schedule_b_codes.push(this.generateScheduleBRowForm(code)))


    this.accounts$.pipe(take(1)).subscribe(async (companies) => {
      const overrides = await this.populateOverrideFields(companies, this.dialogData.product?.product_id);
      this.productForm.controls.overrides.clear();
      overrides?.forEach(override => this.productForm.controls.overrides.push(override));
      this.overridesSnapshot$.next(cloneDeep(this.productForm.controls.overrides.getRawValue()));
      this.inProgress$.next(undefined)
    });

    this.inProgress$.subscribe((inProgress) => {
      if (inProgress) this.productForm.disable();
      else this.productForm.enable();
      if (this.AuthApi.currentUser.role === 'logistics') {
        this.productForm.controls.name.disable()
        this.productForm.controls.category_id.disable()
        this.productForm.controls.type_id.disable()
        this.productForm.controls.so_creation.disable()
      }
    })
  }


  async populateOverrideFields(companies, product_id) {
    if (!product_id) return []
    // TODO: safeguard code/api. use companies after WA-5591 passed QA at prod
    companies = await this.Accounts.getAccountsByProduct(product_id)
    companies = await this.Accounts.getAccountsProducts(_map(companies, 'account'))
    return flatten(compact(companies?.map((company) => {
      const overrides = filter(company.products_spec, { product_id }).filter((override) => this.isOverrideVisible(override))
      if (!overrides.length) return false
      return overrides.map(override => this.generateOverrideRowForm(override, company.account))
    })))
  }

  protected categoryChanged() {
    this.productForm.controls.type_id.setValue(null);
    this.productForm.controls.unique_cost.setValue(false);
  }

  protected cancel() {
    this.dialogRef.close(null);
  }

  protected addOverrideRow(): void {
    this.productForm.controls.overrides.push(this.generateOverrideRowForm());
  }

  protected markOverrideAsDeleted(control: FormGroup): void {
    if(!this.inProgress$.value) {
      control.get('deleted').setValue(true);
    }
  }

  protected addScheduleBCodeRow(): void {
    this.productForm.controls.schedule_b_codes.push(this.generateScheduleBRowForm());
  }

  protected deleteScheduleBCode(index: number): void {
    if(!this.inProgress$.value) {
      this.productForm.controls.schedule_b_codes.removeAt(index);
    }
  }

  protected async save() {
    if (this.inProgress$.value) return

    this.productForm.markAllAsTouched()
    this.productForm.updateValueAndValidity()
    if (!this.productForm.valid) return;

    try {
      this.inProgress$.next('save');

      const productData = {
        ...(this.dialogData?.product || {}),
        ...omit(this.productForm?.getRawValue(), ['overrides']),
      }

      const product = productData.product_id
        ? await this.Products.update(productData)
        : await this.Products.create(productData)

      const productOverrides = this.productForm?.getRawValue()?.overrides;
      this.accounts$.pipe(take(1)).subscribe(async (companies) => {
        if (!this.isArrayEqual(productOverrides, this.overridesSnapshot$.value)) {
          const patches = this.generateOverridesPatchPayload(companies, product, productOverrides)
          await Promise.all(patches.map(({ company, products_spec }) => this.Accounts.updateAccountProducts(company, products_spec)))
        }
        this.toaster.success('Product saved successfully')
        this.dialogRef.close(product)
      })
    } catch (e) {
      console.error('Unable to save product', e)
      this.toaster.error('Unable to save product', e)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected childControlToFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  private generateOverridesPatchPayload(companies, product, overrides) {
    const product_id = product.product_id
    return compact(_map(groupBy(overrides, 'account'), (productOverrides, account) => {
      const company = companies[account]
      const previous = filter(company.products_spec, { product_id })
      productOverrides = productOverrides.map(x => ({
        ...omit(x, 'account'),
        item_type_id: x.item_type_id || undefined,
        product_id,
      }))

      if (this.isArrayEqual(previous, productOverrides)) return false
      const otherProductsProfiles = reject(company.products_spec, { product_id })
      const updated = reject(productOverrides, 'deleted')?.map(x => omit(x, 'deleted'))?.map(x => pickBy(x, identity))
      const invisible = reject(previous, (override => this.isOverrideVisible(override))) // they don't override
      // const deleted = filter(overrides, 'deleted')
      updated.forEach((override) => {
        const idx = findIndex(invisible, pick(override, ['product_id', 'item_type_id']))
        if (idx === -1) return
        invisible.splice(idx, 1)
        // // safeguard code. we don't want users to remove products from company profile. not here.
        // override.profile = override.profile || previouslyInvisible.profile
      })
      const products_spec = [
        ...otherProductsProfiles,
        ...invisible,
        ...updated,
      ]
      return { company, products_spec }
    }))
  }

  private generateOverrideRowForm(override?, account?: number) {
    return new FormGroup({
      item_type_id: new FormControl<string>(override?.item_type_id),
      account: new FormControl<number>(account, Validators.required),
      name: new FormControl<string>(override?.name),
      hs_code: new FormControl<string>(override?.hs_code),
      deleted: new FormControl<boolean>(false),
    })
  }

  private generateScheduleBRowForm(scheduleBCode?) {
    return new FormGroup({
      item_type_id: new FormControl<string>(scheduleBCode?.item_type_id),
      code: new FormControl<string>(scheduleBCode?.code, Validators.required),
    })
  }

  private isArrayEqual(x, y): boolean {
    return isEmpty(xorWith(x, y, isEqual));
  }

  private isOverrideVisible(x): boolean {
    return x.name || x.hs_code;
  }

  protected isLC(): boolean {
    return this.AuthApi.currentUser.role === 'logistics'
  }
}

