import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { isEqual } from 'lodash-es'
import { from, of } from 'rxjs'
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators'
import { TOP_LEVEL_FILTERS } from 'src/components/invoices/invoices-list/invoices-list.filters'
import { InvoiceElasticSearchService } from 'src/services/data/invoice-elastic.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { queueMap } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { prepareFiltersPayload, prepareInvoicesPayload } from './invoice-view-filters.utils'
import { loadInvoiceFilters, loadInvoiceFiltersFailure, loadInvoiceFiltersSuccess, loadInvoiceViews, loadInvoiceViewsFailure, loadInvoiceViewsSuccess, setInvoiceInv, setInvoiceInvFailure, setInvoiceInvSuccess, setInvoicePaidDate, setInvoicePaidDateFailure, setInvoicePaidDateSuccess, setInvoicePaymentType, setInvoicePaymentTypeFailure, setInvoicePaymentTypeSuccess } from './invoice-view.actions'


@Injectable()
export class InvoiceViewEffects {
  constructor(
    private actions$: Actions,
    private elastic: InvoiceElasticSearchService,
    private toaster: ToasterService,
    private invoices: InvoicesService,
  ) {}

  loadInvoiceViews$ = createEffect(() => this.actions$.pipe(
    ofType(loadInvoiceViews),
    map(action => ({
      action,
      payload: prepareInvoicesPayload(action.filters, action.page),
    })),
    queueMap(({action, payload}) =>
      this.elastic.fetchInvoices({ ...payload, calculate: action.calculate }).pipe(
        map(r => loadInvoiceViewsSuccess({
          tableKey: action.tableKey,
          total: r.total,
          totals: r.totals,
          invoiceViews: r.data,
          payload,
        })),
        catchError(error => {
          console.error('Unable to fetch invoices', error)
          this.toaster.error('Unable to fetch invoices', error)
          return of(loadInvoiceViewsFailure({ error }))
        })))))

  loadInvoiceFilters$ = createEffect(() => this.actions$.pipe(
    ofType(loadInvoiceFilters),
    map(action => ({
      tableKey: action.tableKey,
      payload: prepareFiltersPayload(action.filters, TOP_LEVEL_FILTERS[action.tableKey]),
    })),
    distinctUntilChanged(isEqual),
    switchMap(({tableKey, payload}) => this.elastic.fetchInvoiceFilters(payload).pipe(
      map(result => loadInvoiceFiltersSuccess({ tableKey, result })),
      catchError(error => of(loadInvoiceFiltersFailure({ error }))),
    ))))

  setInvoiceInv$ = createEffect(() => this.actions$.pipe(
    ofType(setInvoiceInv),
    switchMap(action =>
      from(this.invoices.patchImmutableInvoice(action.invoice, { inv: action.inv })).pipe(
        tap(() => this.toaster.success('Invoice "INV" value changed successfully')),
        map(() => setInvoiceInvSuccess()),
        catchError(error => {
          console.error('Unable to update invoice "INV" value', error)
          this.toaster.error('Unable to update invoice "INV" value', error)
          return of(setInvoiceInvFailure({ error }))
        })))))

  setInvoicePaymentType$ = createEffect(() => this.actions$.pipe(
    ofType(setInvoicePaymentType),
    switchMap(action =>
      from(this.invoices.patchImmutableInvoice(action.invoice, { attributes: { payment_type: action.paymentType } })).pipe(
        tap(() => this.toaster.success('Invoice "Payment Type" value changed successfully')),
        map(() => setInvoicePaymentTypeSuccess()),
        catchError(error => {
          console.error('Unable to update invoice "Payment Type" value', error)
          this.toaster.error('Unable to update invoice "Payment Type" value', error)
          return of(setInvoicePaymentTypeFailure({ error }))
        })))))

  setInvoicePaidDate$ = createEffect(() => this.actions$.pipe(
    ofType(setInvoicePaidDate),
    switchMap(action =>
      from(this.invoices.patchImmutableInvoice(action.invoice, { attributes: { paid: action.date } })).pipe(
        tap(() => this.toaster.success('Invoice "Paid" date changed successfully')),
        map(() => setInvoicePaidDateSuccess()),
        catchError(error => {
          console.error('Unable to update invoice "Paid" date', error)
          this.toaster.error('Unable to update invoice "Paid" date', error)
          return of(setInvoicePaidDateFailure({ error }))
        })))))
}
