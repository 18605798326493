import { createSelector } from '@ngrx/store'
import { DocumentTemplate } from '@tradecafe/types/core'
import { compact, uniq } from 'lodash-es'
import { State } from '../reducers'
import { adapter, documentTemplatesFeatureKey } from './document-template.reducer'

export const selectState = (state: State) => state[documentTemplatesFeatureKey]

export const { selectEntities: selectDocumentTemplateEntities, selectAll: selectAllDocumentTemplates } = adapter.getSelectors(selectState)

export const selectDocumentTemplate = createSelector(
  selectDocumentTemplateEntities,
  (documentTemplates: Dictionary<DocumentTemplate>, documentTemplate: string | number) =>
    documentTemplates[documentTemplate])

export const selectDocumentTemplatesByIds = createSelector(
  selectDocumentTemplateEntities,
  (documentTemplates: Dictionary<DocumentTemplate>, documentTemplateIds: string[]) =>
    documentTemplateIds.map(documentTemplateId => documentTemplates[documentTemplateId]))

export const selectAllDocumentTypes = createSelector(
  selectAllDocumentTemplates,
  templates => uniq(compact(templates.map(t => t.type)))
)
