import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { remove } from 'lodash-es'
import { UserApiService } from 'src/api/user'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CompanyContactFormService } from '../../../settings/companies/companies/form/sub-forms/company-contact-form.service'

@Component({
  selector: 'tc-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ContactsListComponent extends OnDestroyMixin implements OnInit {
  constructor(
      private clientShippingLogService: CompanyContactFormService,
      private toaster: ToasterService,
      private ConfirmModal: ConfirmModalService,
      private UserApi: UserApiService,
      ) {
    super()
  }

  @Input() users: DeepReadonly<User[]>
  @Input() account: number

  protected dataSource = new MatTableDataSource<User>()
  protected displayContactsColumns = ['fullname', 'role', 'address', 'lastupdate', 'actions']

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.sort = sort }

  @ViewChild(MatTable) contactsTable: MatTable<any>

  protected rowClick = new EventEmitter<{user: User, column?: string, tabName?: string}>()

  ngOnInit(): void {
    this.dataSource.data = Object.values(this.users)

    if (!this.rowClick.length) {
      this.rowClick.pipe(untilComponentDestroyed(this))
          .subscribe(({ user }) => this.updateContact(user))
    }
  }

  updateContact(user?: User) {
    return this.clientShippingLogService.showUpdateContact({ account: this.account, user })
  }

  addContact() {
    this.updateContact().then((user) => {
      user.fullname = ((user.firstname || '') + ' ' + (user.lastname || '')).trim()
      const newData = [ ...this.dataSource.data ]
      newData.push(user)
      this.dataSource.data = newData
    })
  }

  removeContact(user)  {
    this.ConfirmModal.show({
      title: 'Remove this contact?',
      description: 'Are you sure you want to remove this contact?',
    }).then(async () => {
      try {
        await this.UserApi.delete(user.account, user.user_id)
        const newData = [ ...this.dataSource.data ]
        remove( newData, {user_id: user.user_id})
        this.dataSource.data = newData
        this.toaster.success('Contact removed successfully.')
      } catch (e) {
        this.toaster.error('Unable to remove this contact.')
      }
    })
  }

  showUpdateItem(user) {
    this.updateContact(user).then((updates) => {
      const newData = [ ...this.dataSource.data ]
      updates.fullname = ((user.firstname || '') + ' ' + (user.lastname || '')).trim()
      const index = newData.findIndex((i) => i.user_id === updates.user_id)
      newData[index] = updates
      this.dataSource.data = newData
    })
  }

}
