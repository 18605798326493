import { Injectable } from '@angular/core'
import { AccountObject, GeneralAddress } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, identity } from 'lodash-es'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ModalService } from 'src/shared/modal/modal.service'
import { AddressFormComponent } from './address-form.component'

@Injectable()
export class AddressFormService {
  constructor(private modal: ModalService) { }

  showAddress(address: GeneralAddress, company: DeepReadonly<AccountObject>): Observable<GeneralAddress> {
    const mutable = cloneDeep(company) as AccountObject
    return this.modal.openDialog(AddressFormComponent, { address, company: mutable }, {
      // windowClass: 'modalShipping',
      // size: 'lg',
      // backdrop: 'static',
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
    }).pipe(filter(identity))
  }
}
