import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, Cost, DealViewRaw, SegmentType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, find, first } from 'lodash-es'
import { combineLatest, Observable, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators'
import { selectAccount, selectAccountEntities, selectAllAccounts } from 'src/app/store/accounts'
import { selectAllCarriers, selectCarrier, selectCarrierEntities } from 'src/app/store/carriers'
import { selectCurrencyCodes } from 'src/app/store/currencies'
import { selectAllLocations } from 'src/app/store/locations'
import { AddressFieldPickerOptions } from 'src/components/address-field/address-field.component'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { DealDetailsFormGroup, DealFormGroup, SegmentFormGroup, SegmentFormValue } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormCalculatorService } from 'src/services/data/deal-form-calculator.service'
import { getSegmentFormPopulatedValues } from 'src/services/data/segments.service'
import { compare } from 'src/services/table-utils/compare'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { SegmentFormBaseComponent } from '../segment-form-base.component'
import { SwapServiceProviderDialogService } from 'src/pages/admin/logistics/shipping-details-page/shipping-details-form/swap-service-provider-overlay/swap-service-provider-dialog.service'
import { environment } from 'src/environments/environment'
import { CostRow } from 'src/components/costs-list/costs-list.component'
import { prepareDealCostPatch } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service-factory'
import { ToasterService } from 'src/shared/toaster/toaster.service'

@Component({
  selector: 'tc-segment-form-regular',
  templateUrl: './segment-form-regular.component.html',
  styleUrls: ['./segment-form-regular.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentFormRegularComponent extends SegmentFormBaseComponent implements OnInit {
  constructor(
    NotesOverlay: NotesOverlayService,
    private SwapServiceProviderOverlay: SwapServiceProviderDialogService,
    private DealFormCalculator: DealFormCalculatorService,
    private Toaster: ToasterService,
    protected store: Store,
  ) {
    super(NotesOverlay, store)
  }

  // all @Input fields are mandatory
  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
  @Input() dealForm: DealFormGroup
  get dealId() { return this.dealForm.controls.details.value.deal.deal_id }
  get detailsForm() { return this.dealForm.controls.details }
  get segmentsForm() { return this.dealForm.controls.segments }
  @Input() segmentIndex: number
  @Input() segmentForm: SegmentFormGroup
  @Input() readonly = false
  isNew: boolean

  @Output() removeSegment = new EventEmitter()
  @Output() costsActualizationCreation = new EventEmitter<{
    newCosts: Partial<Cost>[],
    costIds: string[],
    oldProvider: AccountObject,
    newProvider: AccountObject,
    newSegment: SegmentFormValue
  }>()

  segmentTypeAir$: Observable<boolean>
  segmentTypeNotAir$: Observable<boolean>
  segmentTypeNotSea$: Observable<boolean>
  segmentTypeSea$: Observable<boolean>
  segmentTypeLand$: Observable<boolean>
  segmentTypeRail$: Observable<boolean>
  canRemoveSegment$: Observable<boolean>
  showAes$: Observable<boolean>
  showB13$: Observable<boolean>
  showPaps$: Observable<boolean>
  showPedimento$: Observable<boolean>
  showMexicanInvNo$: Observable<boolean>
  exactLoadingAddressOptions$: Observable<AddressFieldPickerOptions>
  exactDropoffAddressOptions$: Observable<AddressFieldPickerOptions>

  // ref data
  locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty())
  private accountsById$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  carriersById$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())
  carriers$ = this.store.pipe(select(selectAllCarriers), waitNotEmpty())
  freightForwarders$ = combineLatest([this.carriers$, this.accountsById$]).pipe(map(([carriers, accounts]) => [
    {name: 'Not Applicable', carrier_id: ''},
    // hide inactive carriers
    ...carriers?.filter(carrier => accounts[carrier.account].status === ACCOUNT_ACTIVE)
                .sort((a, b) => compare(a.name, b.name)),
  ]))
  currencyCodes$ = this.store.pipe(select(selectCurrencyCodes), waitNotEmpty())

  readonly enableSwapProvider = environment.enableSwapProvider

  ngOnInit(): void {
    super.ngOnInit()

    const deal$ = replayForm(this.detailsForm)
    const segment$ = replayForm(this.segmentForm)
    const segments$ = replayForm(this.segmentsForm)
    const segmentType$ = segment$.pipe(map(s => s.type), distinctUntilChanged())
    this.segmentTypeRail$ = segmentType$.pipe(map(type => type === 'rail'), distinctUntilChanged())
    this.segmentTypeAir$ = segmentType$.pipe(map(type => type === 'air'), distinctUntilChanged())
    this.segmentTypeLand$ = segmentType$.pipe(map(type => type === 'land'), distinctUntilChanged())
    this.segmentTypeSea$ = segmentType$.pipe(map(type => type === 'sea'), distinctUntilChanged())
    this.canRemoveSegment$ = segments$.pipe(
      map(segments => !this.readonly && segments.filter(s => s.type !== 'warehouse').length > 1),
      distinctUntilChanged())
    this.showB13$ = deal$.pipe(
      map(deal => deal.originCountryCode === 'CA'),
      distinctUntilChanged())
    this.showPedimento$ = this.showMexicanInvNo$ = deal$.pipe(
      map(deal => deal.docsCountryCode === 'MX'),
      distinctUntilChanged())
    this.showPaps$ = combineLatest([deal$, segmentType$]).pipe(
      map(([deal, segmentType]) => segmentType !== 'sea' && deal.originCountryCode === 'CA' && deal.docsCountryCode === 'MX'),
      distinctUntilChanged())
    this.showAes$ = combineLatest([deal$, segmentType$]).pipe(
      map(([deal, segmentType]) => (segmentType === 'land' || segmentType === 'sea') && deal.originCountryCode === 'US'),
      distinctUntilChanged())

    this.exactLoadingAddressOptions$ = exactLoadingAddressOptions(this.detailsForm, this.segmentForm)
    this.exactDropoffAddressOptions$ = exactDropoffAddressOptions(this.detailsForm, this.segmentForm)

    // only regular segments
    this.changedSegmentDate$.pipe(
      switchMap(changedField => this.DealFormCalculator.onSegmentDateChanged(
        this.dealViewRaw$,
        this.dealForm,
        this.segmentForm,
        changedField)),
      untilComponentDestroyed(this),
    ).subscribe()
  }

  setSegmentType(type: SegmentType) {
    this.segmentForm.patchValue({ type })
    this.segmentForm.controls.type.markAsDirty()
  }


  updateSegmentsBelow() {
    const patch = getSegmentFormPopulatedValues(this.segmentForm.value)
    this.segmentsForm.controls.slice(this.segmentIndex + 1).forEach((segmentForm: SegmentFormGroup) => {
      segmentForm.patchValue(patch)
      Object.keys(patch).forEach(ctrlName => segmentForm.controls[ctrlName].markAsDirty())
    })
  }

  onFreightForwarderChange() {
    this.updateSegmentsBelow()
    const ff = this.segmentForm.value.freightForwarderId
    if (!ff) return
    this.store.select(selectCarrier, ff).pipe(
      switchMap(carrier => this.store.select(selectAccount(carrier.account))),
      take(1))
      .subscribe(account => {
        this.segmentForm.patchValue({
          exactDropoffAccount: account.account,
          exactDropoffAddress: find(account.addresses, 'primary') || first(account.addresses),
        })
        this.segmentForm.controls.exactDropoffAddress.markAsDirty()
        this.segmentForm.controls.exactDropoffAccount.markAsDirty()
      })
  }

  async onCarrierEvent() {
    const oldProviderId = this.segmentForm.value.carrierAccountId
    this.onCarrierChange()
    const newProviderId = this.segmentForm.value.carrierAccountId
    if (this.enableSwapProvider) {
      combineLatest([
        this.store.select(selectAccount(oldProviderId)),
        this.store.select(selectAccount(newProviderId)),
        of(this.segmentForm.value)
      ])
        .pipe(
          switchMap(
            ([oldProvider, newProvider, newSegment]) => {
              const filteredCostFormValues = this.dealForm.value.costs.filter(
                (c) => c.type === 'tertiary' && c.status === 'pending' && c.serviceName === 'Freight Rate' && c.cost.provider !== newProviderId
              )
              const showModal = filteredCostFormValues.length > 0
              return combineLatest(
                showModal ?
                  this.SwapServiceProviderOverlay.showSwapServiceProvider(this.dealForm, this.dealViewRaw$, newProvider) :
                  of(null),
                of(newProvider),
                of(oldProvider),
                of(newSegment)
              )
            }
          ),
          take(1)
        )
        .subscribe(
          ([dialogResult, newProvider, oldProvider, newSegment]) => {
            const costsWithDiffCurrency: CostRow[] = dialogResult?.selectedCosts?.filter((cr: CostRow) => {
              if (!cr.originalCost.amount.currency) {
                this.Toaster.warning('Cost currency invalid')
              }
              if (!newProvider.attributes.pricing?.currency) {
                this.Toaster.warning('New provider currency invalid')
              }
              return cr.originalCost.amount.currency !== newProvider.attributes.pricing.currency
            })
            if (dialogResult?.onlyUpdatingProvider === false) {
              // update cost provider
              dialogResult?.selectedCosts?.forEach((cr: CostRow) => {
                const index = this.dealForm.value.costs.findIndex(c => c.cost.cost_id === cr.costId)
                this.dealForm.controls.costs.controls[index].reset(
                  prepareDealCostPatch({ ...cr.originalCost, provider: newProvider.account.toString() })
                )
              })
              // costs actualization and creation
              const newCosts: Partial<Cost>[] = costsWithDiffCurrency.map(() => {
                return {
                  type: 'tertiary',
                  status: 'pending',
                  attributes: {},
                  amount: {
                    total: 0,
                    currency: newProvider.attributes.pricing.currency
                  },
                  provider: newProvider.account.toString(),
                  service: "Freight Rate"
                }
              })
              this.costsActualizationCreation.next(
                {
                  newCosts: newCosts,
                  costIds: costsWithDiffCurrency.map(c => c.costId),
                  oldProvider,
                  newProvider,
                  newSegment
                }
              )
            }
          }
        )
    }
  }
}

function exactLoadingAddressOptions(
  detailsForm: DealDetailsFormGroup,
  segmentForm: SegmentFormGroup,
): Observable<AddressFieldPickerOptions> {
  return combineLatest([replayForm(detailsForm), replayForm(segmentForm)]).pipe(map(([deal, segment]) => ({
    title: 'Select Exact Loading Address',
    accountId: segment.exactLoadingAccount || segment.carrierAccountId,
    address: segment.exactLoadingAddress,
    accountIds: compact([
      deal.buyerId,
      deal.supplierId,
      segment.carrierAccountId,
      segment.freightForwarderId, // TODO: this field is carrier_id, not account_id
    ]),
  })))
}

function exactDropoffAddressOptions(
  detailsForm: DealDetailsFormGroup,
  segmentForm: SegmentFormGroup,
): Observable<AddressFieldPickerOptions> {
  return combineLatest([replayForm(detailsForm), replayForm(segmentForm)]).pipe(map(([deal, segment]) => ({
    title: 'Select Exact Drop-off Address',
    accountId: segment.exactDropoffAccount || segment.carrierAccountId,
    address: segment.exactDropoffAddress,
    accountIds: compact([deal.buyerId, deal.supplierId, segment.carrierAccountId]),
  })))
}
