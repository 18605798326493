import { Injectable } from '@angular/core'
import { Bid, DealPartyE, DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawFiles, DealViewRawInvoices, DealViewRawOffers, DealViewRawSegments, Offer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { tap } from 'rxjs/operators'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealFormGroup } from '../deal-form.schema'
import { ChangePartyFormComponent, ChangePartyFormOptions, ChangePartyFormResult } from './change-party-form.component'

type D = DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawFiles & DealViewRawSegments & DealViewRawInvoices

@Injectable()
export class ChangePartyFormService {
  constructor(private modal: ModalService) {}

  showChangeParty(deals: DeepReadonly<D[]>, party: undefined | DealPartyE.buyer | DealPartyE.supplier) {
    return this.show(deals, party, true)
  }

  showChangeFormula(dv: DeepReadonly<D>, dealFormGroup: DealFormGroup, party: undefined | DealPartyE.buyer | DealPartyE.supplier) {
    const dealForm = dealFormGroup.getRawValue()
    // flush form values
    const [bid = {} as Bid, ...bids] = dv.bids
    const [offer = {} as Offer, ...offers] = dv.offers
    dv = {
      ...dv,
      deal: {
        ...dv.deal,
        buyer_id: dealForm.details.buyerId,
        supplier_id: dealForm.details.supplierId,
        dest_location: dealForm.details.destLocationId,
        origin_location: dealForm.details.originLocationId,
        attributes: {
          ...dv.deal.attributes,
          supplier_formula: dealForm.details.supplierFormula,
          buyer_formula: dealForm.details.buyerFormula,
        },
      },
      bids: [{
        ...bid,
        currency: dealForm.details.buyerCurrencyCode,
        price: dealForm.products[0].buyerEstPrice,
        incoterm: dealForm.products[0].buyerIncotermId,
        weight: {
          ...bid?.weight,
          unit: dealForm.products[0].buyerMeasureId,
        },
        attributes: {
          ...bid.attributes || {},
          incoterm_location: dealForm.products[0].buyerIncotermLocationId,
        },
      }, ...bids],
      offers: [{
        ...offer,
        currency: dealForm.details.supplierCurrencyCode,
        price: dealForm.products[0].supplierEstPrice,
        incoterm: dealForm.products[0].supplierIncotermId,
        weight: {
          ...offer?.weight,
          unit: dealForm.products[0].supplierMeasureId,
        },
        attributes: {
          ...offer.attributes || {},
          incoterm_location: dealForm.products[0].supplierIncotermLocationId,
        },
      }, ...offers],
    }
    return this.show([dv], party, false).pipe(tap(changes => {
      const patch = changes?.[0]
      if (!patch) return
      const { details: { deal_id, ...details } } = patch
      dealFormGroup.patchValue({ details })
    }))
  }

  private show(deals: DeepReadonly<D[]>, party: undefined | DealPartyE.buyer | DealPartyE.supplier, editableParty: boolean) {
    return this.modal.openDialog<ChangePartyFormComponent, ChangePartyFormOptions, ChangePartyFormResult>(ChangePartyFormComponent, {
      deals,
      party,
      editableParty,
    }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
    })
  }
}
