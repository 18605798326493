import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { WrappingTypesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/wrapping-types.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadWrappingTypes, loadWrappingTypesFailure, loadWrappingTypesSuccess } from './wrapping-type.actions'

@Injectable()
export class WrappingTypeEffects {
  constructor(
    private actions$: Actions,
    private WrappingTypes: WrappingTypesService,
    private toaster: ToasterService,
  ) {}

  loadWrappingTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadWrappingTypes),
    switchMap(action =>
      from(this.WrappingTypes.getWrappingTypes()).pipe(
        map(wrappingTypes => loadWrappingTypesSuccess({
          tableKey: action.tableKey,
          wrappingTypes: wrappingTypes.data,
        })),
        catchError(error => {
          console.error('Unable to fetch wrappingTypes', error)
          this.toaster.error('Unable to fetch wrappingTypes', error)
          return of(loadWrappingTypesFailure({ error }))
        })))))
}
