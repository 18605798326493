import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { loadCarriers } from 'src/app/store/carriers'
import { loadLocations } from 'src/app/store/locations'
import { loadMeasures } from 'src/app/store/measures'
import { loadProducts } from 'src/app/store/products'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { ShipmentDetailsOverlayComponent } from './shipment-details-overlay.component'

@Injectable()
export class ShipmentDetailsOverlayService {
  constructor(
    private modal: ModalService,
    private store: Store,
    private DealForm: DealFormService,
  ) { }

  showShipmentDetails(dataOrDealId: string): Observable<any> {
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadMeasures({}))

    return this.DealForm.load(dataOrDealId)
      .pipe(
        take(1),
        switchMap(
          (
            { dealForm, dealViewRaw }
          ) => {
            return this.modal.openLargeSide(
              ShipmentDetailsOverlayComponent,
              {
                dealId: dataOrDealId,
                dealForm,
                dealViewRaw$: of(dealViewRaw),
              }
            )
          }
        ),
      )
  }
}
