import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

export interface BuyersGroup {
  group_id: string
  created: number
  name: string
  buyers: string[]
}
const { apiUrl } = environment

@Injectable()
export class BuyersGroupApiService {
  constructor(private http: HttpClient) {}

  async list() {
    const { data } = await this.http
      .get<{ data: BuyersGroup[] }>(`${apiUrl}/buyers_group`)
      .toPromise()

    return data
  }

  create(group: { name: string; buyers: string[] }) {
    return this.http
      .post<{ data: BuyersGroup }>(`${apiUrl}/buyers_group`, group)
      .toPromise()
  }

  update(group: BuyersGroup) {
    return this.http
      .put<{ data: BuyersGroup }>(
        `${apiUrl}/buyers_group/${group.group_id}`,
        group,
      )
      .toPromise()
  }

  delete(group_id: string) {
    return this.http
      .delete<{ data: BuyersGroup }>(`${apiUrl}/buyers_group/${group_id}`)
      .toPromise()
  }
}
