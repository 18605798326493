import { TableKey } from '@tradecafe/types/core'
import { mapValues, uniq } from 'lodash-es'

export const RATE_VALID = 'Valid'
export const RATE_EXPIRED = 'Expired'

export const FREIGHT_RATES_FILTER_SETTINGS = {}

export const DEFAULT_FREIGHT_RATES_FILTERS = {
  // full text search fields
  text: '',

  carrier: [],
  type: [],
  commodity: [],
  containerSize: [],
  orgCountry: [],
  orgState: [],
  orgCity: [],
  dstCountry: [],
  dstState: [],
  dstCity: [],
  status: [],
  maxWeight: [],
  creator: [],
  valid_until_from: null,
  valid_until_to: null,
  created_from: null,
  created_to: null,
  validity: RATE_VALID,

  // table internal filters
  sort: { id: 'created', start: 'desc' as 'asc'|'desc' },
  limit: 10,
  columns: [],

  // selected table view id
  table_view_id: null as string,
}

export type FreightRatesFilter = typeof DEFAULT_FREIGHT_RATES_FILTERS;


// filters placed at the top of the deals list page
export const TOP_LEVEL_FILTERS = {
  [TableKey.TradingFreightRatesList]: [
    'text',
    'carrier',
    'type',
    'commodity',
    'containerSize',
    'orgCountry',
    'orgState',
    'maxWeight',
    'creator',
  ],
  [TableKey.LogisticsFreightRatesList]: [
    'text',
    'carrier',
    'type',
    'commodity',
    'containerSize',
    'orgCountry',
    'orgState',
    'maxWeight',
    'creator',
  ],
}


export const TOP_LEVEL_FILTERS_COLUMNS = mapValues(TOP_LEVEL_FILTERS, (filters) =>
  uniq(filters.map(filter => filter.replace(/_to$/, '').replace(/_from$/, ''))))

