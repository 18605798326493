import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { PackageTypesService } from 'src/pages/admin/settings/product-specifications/package-types/package-types.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadPackageTypes, loadPackageTypesFailure, loadPackageTypesSuccess } from './package-type.actions'

@Injectable()
export class PackageTypeEffects {
  constructor(
    private actions$: Actions,
    private PackageTypes: PackageTypesService,
    private toaster: ToasterService,
  ) {}

  loadPackageTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadPackageTypes),
    switchMap(action =>
      from(this.PackageTypes.getPackageTypes()).pipe(
        map(packageTypes => loadPackageTypesSuccess({
          tableKey: action.tableKey,
          packageTypes: packageTypes.data,
        })),
        catchError(error => {
          console.error('Unable to fetch packageTypes', error)
          this.toaster.error('Unable to fetch packageTypes', error)
          return of(loadPackageTypesFailure({ error }))
        })))))
}
