import { TableKey } from '@tradecafe/types/core';

export const DEFAULT_COLUMNS: Partial<Record<TableKey, string[]>> = {
  [TableKey.SupplierOfferAudit]: [
    'created',
    'deal_id',
    'actionText',
    'offer_id',
    'matched_offer_id',
    'username',
  ],
  [TableKey.TradingFreightRatesAudit]: [
    'created',
    'key',
    'old',
    'new',
    'username',
  ],
  [TableKey.LogisticsFreightRatesAudit]: [
    'created',
    'key',
    'old',
    'new',
    'username',
  ],
  [TableKey.MatchedOfferViewLogAudit]: [
    'created',
    'username',
    'matched_offer_price',
  ],
  [TableKey.MatchedOfferAudit]: [
    'created',
    'deal_id',
    'actionText',
    'offer_id',
    'matched_offer_id',
    'key',
    'old',
    'new',
    'username',
  ],
  [TableKey.MacropointOrderEventLogAudit]: [
    'created',
    'actionText',
    'username',
  ],
};
