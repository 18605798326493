import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal'
import { MacropointOrderDetailsComponent, MacropointOrderDetailsDialogOptions } from './macropoint-order-details-dialog.component'

@Injectable()
export class MacropointOrderDetailsDialogService {

  constructor(private modal: ModalService) { }

  showMacropointOrderDetails(opts: MacropointOrderDetailsDialogOptions) {
    return this.modal.openDialog<MacropointOrderDetailsComponent, MacropointOrderDetailsDialogOptions, boolean>(MacropointOrderDetailsComponent, opts, {
      panelClass: ['tc-dialog', 'modal-xlg'],
      width: null,
      maxWidth: null,
    })
  }
}
