<div mat-dialog-title>
  <h2>{{dealId}} Shipment Details – {{shipmentStatus$ | async}}</h2>
  <tc-locks [resourceId]="dealId" resourceType="deal"></tc-locks>
  <tc-finance-issues [detailsForm]="detailsForm"></tc-finance-issues>
  <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
  <span fxFlex></span>
  <button mat-raised-button type="button" color="primary"
      [matTooltip]="incomplete.incomplete" matTooltipClass="pre-tooltip"
      [disabled]="!!incomplete.incomplete"
      (click)="showAddSegment()">
    <i class="fa fa-fw fa-save"></i> Add Segment
  </button>
</div>
<div mat-dialog-content>
  <tc-segments-list-v2
      [readonly]="isReadonly"
      [dealViewRaw$]="dealViewRaw$"
      [segmentsForm]="segmentsForm"
      [displayColumns]="['type', 'carrierName', 'cutoffDate', 'loadDate', 'originName', 'dropoffDate', 'destinationName', 'ellipsisMenu']"
      [fxRates]="fxRates$ | async"
      (updateSegment)="showUpdateSegment($event)"
      (removeSegment)="showRemoveSegment($event)"
      ></tc-segments-list-v2>
</div>
