import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeepReadonly } from '@tradecafe/types/utils';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { asyncScheduler, combineLatest, Observable, of, scheduled } from 'rxjs';
import { auditTime, concatAll, distinctUntilChanged, map, pairwise, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { loadUsers, selectUserEntities } from 'src/app/store/users';
import { waitNotEmpty } from '../utils/wait-not-empty';
import { LockApiService, LockConnection, LockIdentity } from './lock-api.service';
import { loadAccounts, selectAccountEntities, selectBuyers } from 'src/app/store/accounts';
import { uniq } from 'lodash-es';

@Injectable()
export class OnlineStatusService {
  constructor(
    private LockApi: LockApiService,
    private store: Store
  ) {}

  private sockets: Record<string, LockConnection> = {};

  private startTracking(resourceId: string) {
    const connection = this.sockets[resourceId] || this.LockApi.lock(resourceId, true);
    if (!connection) return null;
    this.sockets[resourceId] = connection;
    connection.references++;
    return connection.lock$;
  }

  stopTracking(resourceId: string) {
    const connection = this.sockets[resourceId];
    if (!connection) return null;
    connection.references--;
    if (connection.references === 0) {
      connection.unlock();
      delete this.sockets[resourceId];
    }
  }

  trackStatus(resourceId$: Observable<string>, destroyable?: OnDestroyMixin) {
    if (destroyable) {
      componentDestroyed(destroyable)
        .pipe(withLatestFrom(resourceId$))
        .subscribe(([, resourceId]) => this.stopTracking(resourceId));
    }

    this.store.dispatch(loadUsers({}));
    this.store.dispatch(loadAccounts({}));

    const activeList = combineLatest([
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
      this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
      scheduled(
        [
          [undefined], // start with undefined
          resourceId$.pipe(distinctUntilChanged()),
        ],
        asyncScheduler
      ).pipe(
        concatAll(),
        pairwise(),
        switchMap(([prev, resourceId]): Observable<DeepReadonly<LockIdentity[]>> => {
          if (prev) this.stopTracking(prev);
          return resourceId ? this.startTracking(resourceId) : of([]);
        })
      ),
    ]).pipe(
      map(([usersById, accounts, locks]) => {
        const onlineUsers = locks.map((l) => usersById[l.user_id]);
        const onlineAccounts = onlineUsers.map((u) => accounts[u.account]);

        return uniq(onlineAccounts.map((x) => x.account));
      }),
      distinctUntilChanged()
    );

    const firstValueObservable = activeList.pipe(take(1));
    return firstValueObservable.pipe(
      switchMap((firstValue) =>
        activeList.pipe(
          auditTime(10000), // introduce some delay so that short-lived status updates (e.g. full page reload) won't affect the screen
          startWith(firstValue)
        )
      )
    );
  }
}
