import { Injectable } from '@angular/core'
import { Currency } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { CurrencyApiService } from 'src/api/metadata/currency'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['code', 'description', 'symbol']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr

/**
 * Currencies service
 *
 * @export
 * @returns
 */
@Injectable()
export class CurrenciesService {
  constructor(
    private AuthApi: AuthApiService,
    private CurrencyApi: CurrencyApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) {}


  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  currencyUpdated$ = new Subject<void>()

  /**
   * Get all available currencies
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getCurrencies(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 hr
      // NOTE: we fetch everything
      this.cache = this.CurrencyApi.list({ limit: Number.MAX_SAFE_INTEGER })
        .catch((err) => {
          this.invalidateCache()
          throw err
        })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get currencies by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=currency)
   */
  async getCurrenciesByIds(ids?: string) {
    const { data } = await this.getCurrencies()
    const index = keyBy(data, 'currency_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get possible values for given currency field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const { data } = await this.getCurrencies()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new currency
   *
   * @param {any} currency
   * @returns
   */
  async create(currency: DeepReadonly<Partial<Currency>>) {
    const payload = pick(currency, ALLOWED_FIELDS) as Partial<Currency>
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id // TODO: move to backend

    const { data } = await this.CurrencyApi.create(payload)
    this.invalidateCache()
    this.currencyUpdated$.next()
    return data
  }

  /**
   * Update currency
   *
   * @param {any} currency
   * @returns
   */
  async update(currency: DeepReadonly<Partial<Currency>>) {
    const { currency_id } = currency
    const payload = pick(currency, ALLOWED_FIELDS) as Partial<Currency>
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.CurrencyApi.update(currency_id, payload)
    this.invalidateCache()
    // NOTE: in theory we should not do that, as we update objects in memory
    this.currencyUpdated$.next()
    return data
  }

  /**
   * Update currency
   *
   * @param {any} currency
   * @returns
   */
  async remove(currency: DeepReadonly<Currency>) {
    const { data } = await this.CurrencyApi.delete(currency.currency_id)
    this.invalidateCache()
    this.currencyUpdated$.next()
    return data
  }


  /**
   * Invalidate currencies in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }

  // TODO: move grid related code closer to UI. we don't want to fetch users when we need currencies
  async populate(currencies) {
    const { account } = this.AuthApi.currentUser
    const userIds = uniq(compact(map(currencies, 'user_id')))
    const users = await this.Users.getUsersByIds(account, userIds)
    currencies.forEach((currency) => {
      currency.user = users[currency.user_id]
    })
    return currencies
  }

  /**
   * Patch angular-currency-format config
   */
  async patchCurrencyFormat(currencyFormatService) {
    const currencies = await this.getCurrencies()
    currencies.data.forEach((currency) => {
      const cf = currencyFormatService.getByCode(currency.code)
      if (!cf) return
      if (cf.uniqSymbol) {
        cf.uniqSymbol.grapheme = currency.symbol
      } else {
        cf.uniqSymbol = {
          grapheme: currency.symbol,
          rtl: false,
          template: '$1',
        }
      }
    })
  }
}
