import { TableIdentities } from '../constants/table-identities'
import { accountsResolver, carriersResolver, countriesResolver, currenciesResolver, productCategoriesResolver, productSpecificationsResolver, productsResolver, productTypesResolver, usersResolver } from '../services/data/deal.resolvers'
import { tableViewsLoader, tableViewsResolver } from '../services/data/table-views.service'


export const routes = [
  {
    name: 'app.setting',
    // parent: 'app',
    url: '/setting',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['administrator', 'junior-administrator', 'manager', 'superuser'],
      title: 'Settings',
    },
  }, {
    name: 'app.setting.default',
    // parent: 'app.setting',
    url: '',
    redirectTo: 'app.setting.companies',
  }, {
    name: 'app.setting.companies',
    // parent: 'app.setting',
    url: '/companies',
    component: 'adminSettingCompanies',
    data: {
      roles: ['administrator', 'junior-administrator', 'manager', 'superuser', 'trader', 'logistics'],
      title: 'Companies',
      tabs: [
        {
          name: 'companies',
          state: 'app.setting.companies.companies',
          filters: ['search', 'user', 'account_status', 'account_state', 'account_type'],
          roles: ['administrator', 'junior-administrator', 'manager', 'superuser', 'trader', 'logistics'],
          advancedSearch: true,
        },
        {
          name: 'departments',
          state: 'app.setting.companies.departments',
          roles: ['administrator', 'junior-administrator', 'manager', 'superuser'],
          filters: ['search', 'user'],
        },
        {
          name: 'business types',
          state: 'app.setting.companies.business-types',
          roles: ['administrator', 'junior-administrator', 'manager', 'superuser'],
          filters: ['search', 'user'],
        },
      ],
    },
    resolve: {
      users: usersResolver,
    },
  }, {
    name: 'app.setting.companies.companies',
    // parent: 'app.setting',
    url: '/companies',
    component: 'adminSettingCompanyList',
    data: {
      title: 'Company List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingCompanyList,
      tableViews: tableViewsResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.companies.departments',
    // parent: 'app.setting',
    url: '/departments',
    component: 'adminSettingDepartmentList',
    data: {
      title: 'Department List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingCompanyDeportmentList,
      tableViews: tableViewsResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.companies.business-types',
    // parent: 'app.setting',
    url: '/business-types',
    component: 'adminSettingBusinessTypeList',
    data: {
      title: 'Business Type List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingCompanyBusinessTypeList,
      tableViews: tableViewsResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.products-services',
    // parent: 'app.setting',
    url: '/products-services',
    component: 'adminSettingProductsServices',
    data: {
      title: 'Products & Services',
      roles: ['logistics', 'administrator', 'junior-administrator', 'manager', 'superuser'],
    },
    resolve: {
      types: productTypesResolver,
      categories: productCategoriesResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.products-services.products',
    // parent: 'app.setting',
    url: '/products',
    component: 'adminSettingProductList',
    data: {
      title: 'Product List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductList,
      tableViews: tableViewsResolver,
      products: productsResolver,
      types: productTypesResolver,
      categories: productCategoriesResolver,
      users: usersResolver,
      accounts: accountsResolver,
    },
  }, {
    name: 'app.setting.products-services.product-specifications',
    // parent: 'app.setting',
    url: '/specifications',
    component: 'adminSettingProductSpecificationList',
    data: {
      title: 'Product Specification List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductSpecificationList,
      tableViews: tableViewsResolver,
      types: productTypesResolver,
      categories: productCategoriesResolver,
      users: usersResolver,
      specifications: productSpecificationsResolver,
    },
  }, {
    name: 'app.setting.products-services.product-types',
    // parent: 'app.setting',
    url: '/types',
    component: 'adminSettingProductTypeList',
    data: {
      title: 'Product Type List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductTypeList,
      tableViews: tableViewsResolver,
      types: productTypesResolver,
      categories: productCategoriesResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.products-services.product-categories',
    // parent: 'app.setting',
    url: '/categories',
    component: 'adminSettingProductCategoryList',
    data: {
      title: 'Product Category List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductCategoryList,
      tableViews: tableViewsResolver,
      categories: productCategoriesResolver,
      departments: departmentsResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.geographic',
    // parent: 'app.setting',
    url: '/geographic',
    component: 'adminSettingGeographic',
    data: {
      title: 'Geographic',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingGeographic,
      tableViews: tableViewsResolver,
      countries: countriesResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.setting.triggers',
    // parent: 'app.setting',
    url: '/triggers',
    component: 'adminSettingTriggerList',
    data: {
      title: 'Notification Triggers',
      roles: ['administrator', 'manager', 'superuser']
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingTriggerList,
      tableViews: tableViewsResolver,
    },
  },
  {
    name: 'app.setting.triggers.default',
    url: '',
    data: {
      roles: ['administrator', 'manager', 'superuser']
    }
  },
  {
    name: 'app.setting.locations',
    // parent: 'app.setting',
    url: '/locations',
    component: 'adminLocationsList',
    data: {
      title: 'Locations List',
      roles: ['administrator', 'junior-administrator', 'manager', 'superuser', 'logistics']
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingLocationsList,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.setting.trackingProviders',
    // parent: 'app.setting',
    url: '/tracking-providers',
    component: 'adminSettingTrackingProviders',
    data: {
      title: 'Tracking Providers',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.trackingProviders,
      tableViews: tableViewsResolver,
      carriers: carriersResolver,
      accounts: accountsResolver,
      users: usersResolver,
    },
  },
  {
    name: 'app.setting.transit-times',
    url: '/transit-times',
    component: 'adminTransitTimesList',
    data: {
      title: 'Transit Times List',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingTransitTimesList,
      tableViews: tableViewsResolver,
    },
  },
  {
    name: 'app.setting.payments',
    // parent: 'app.setting',
    url: '/payments',
    component: 'adminSettingPayments',
    data: {
      title: 'Payments',
      roles: ['administrator', 'manager', 'superuser']
    },
    resolve: {
      users: usersResolver,
    },
  },
  {
    name: 'app.setting.payments.default',
    url: '',
    data: {
      roles: ['administrator', 'manager', 'superuser'],
    }
  },
  {
    name: 'app.setting.payments.currency',
    // parent: 'app.setting',
    url: '/currency',
    component: 'adminSettingPaymentsCurrency',
    data: {
      title: 'Currency',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingPaymentsCurrency,
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.setting.payments.method',
    // parent: 'app.setting',
    url: '/payment-method',
    component: 'adminSettingPaymentsMethod',
    data: {
      title: 'Payment Method',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingPaymentMethods,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.setting.payments.reference',
    // parent: 'app.setting',
    url: '/payment-reference',
    component: 'adminSettingPaymentsReference',
    data: {
      title: 'Payment Reference',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingPaymentReferences,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.setting.payments.banks',
    // parent: 'app.setting',
    url: '/banks',
    component: 'adminSettingPaymentsBanks',
    data: {
      title: 'Banks',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingPaymentBanks,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.setting.payments.terms',
    // parent: 'app.setting',
    url: '/pricing-terms',
    component: 'adminSettingPaymentsTerms',
    data: {
      title: 'Pricing Terms',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingPricingTerms,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.setting.product-specs',
    // parent: 'app.setting',
    url: '/product-specs',
    component: 'adminSettingProductSpecs',
    data: {
      title: 'Product Specifications',
      tabs: [
        {
          name: 'Packaging',
          state: 'app.setting.product-specs.packaging',
        },
        {
          name: 'Wrapping',
          state: 'app.setting.product-specs.wrapping',
        },
        {
          name: 'Measures',
          state: 'app.setting.product-specs.measures',
        },
        {
          name: 'Weight Types',
          state: 'app.setting.product-specs.weight-types',
        },
        {
          name: 'Item Types',
          state: 'app.setting.product-specs.item-types',
        },
      ],
    },
    // TODO: redirect to some default explicitly
  }, {
    name: 'app.setting.product-specs.packaging',
    // parent: 'app.setting',
    url: '/packaging',
    component: 'tcPackageTypes',
    data: {
      title: 'Packaging',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductSpecsPackaging,
      tableViewsLoader,
    },
  }, {
    name: 'app.setting.product-specs.wrapping',
    // parent: 'app.setting',
    url: '/wrapping',
    component: 'tcWrappingTypes',
    data: {
      title: 'Wrapping',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductSpecsWrapping,
      tableViewsLoader,
    },
  }, {
    name: 'app.setting.product-specs.measures',
    // parent: 'app.setting',
    url: '/measures',
    component: 'tcMeasures',
    data: {
      title: 'Measures',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductSpecsMeasures,
      tableViewsLoader,
    },
  }, {
    name: 'app.setting.product-specs.weight-types',
    // parent: 'app.setting',
    url: '/weight-types',
    component: 'tcWeightTypes',
    data: {
      title: 'Weight Types',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductSpecsWeightTypes,
      tableViewsLoader,
    },
  }, {
    name: 'app.setting.product-specs.item-types',
    // parent: 'app.setting',
    url: '/item-types',
    component: 'tcItemTypes',
    data: {
      title: 'Item Types',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingProductSpecsItemTypes,
      tableViewsLoader,
      products: productsResolver,
    },
  }, {
    name: 'app.setting.custom-costs',
    // parent: 'app.setting',
    url: '/custom-costs',
    component: 'adminSettingCustomCostList',
    data: {
      title: 'Custom Costs',
      roles: ['administrator', 'manager', 'superuser']
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingCustomCostList,
      tableViewsLoader,
    },
  },
  {
    name: 'app.setting.custom-costs.default',
    url: '',
    data: {
      roles: ['administrator', 'manager', 'superuser'],
    }
  },
  {
    name: 'app.setting.templates',
    // parent: 'app.setting',
    url: '/templates',
    component: 'adminSettingTemplateList',
    data: {
      title: 'Message Templates',
      roles: ['administrator', 'manager', 'superuser'],
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingTemplateList,
      tableViewsLoader,
    },
  },
  {
    name: 'app.setting.templates.default',
    url: '',
    data: {
      roles: ['administrator', 'manager', 'superuser'],
    }
  },
  {
    name: 'app.setting.document-library',
    url: '/document-library',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Document Library',
      roles: ['administrator', 'manager', 'superuser']
    },
  },
  {
    name: 'app.setting.document-library.default',
    url: '',
    data: {
      roles: ['administrator', 'manager', 'superuser'],
    },
  },
  {
    name: 'app.setting.custom-tags',
    // parent: 'app.setting',
    url: '/custom-tags',
    component: 'adminSettingCustomTagList',
    data: {
      title: 'Custom Tags',
      roles: ['administrator', 'manager', 'superuser']
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingCustomTagList,
      tableViewsLoader,
    },
  },
  {
    name: 'app.setting.custom-tags.default',
    url: '',
    data: {
      roles: ['administrator', 'manager', 'superuser'],
    },
  },
  {
    name: 'app.setting.status-notes',
    // parent: 'app.setting',
    url: '/status-notes',
    component: 'adminSettingStatusNotesList',
    data: {
      title: 'Status Notes',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.settingStatusNoteList,
      tableViewsLoader,
    },
  },
]

// function templateResolver(TemplateApi, AuthApi: AuthApiService) {
//   'ngInject'

//   const {account} = AuthApi.currentUser
//   return TemplateApi.list(account, {limit: 10000, offset: 0}).then((res) => {
//     return res.data
//   })
// }

departmentsResolver.$inject = ['DepartmentApi']
function departmentsResolver(DepartmentApi) {
  'ngInject'

  return DepartmentApi.list({limit: Number.MAX_SAFE_INTEGER}).then(res => res.data)
}
