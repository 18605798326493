import { IEpochRange, INativeRange } from '@tradecafe/types/core'
import { fromUnix } from '@tradecafe/types/utils'
import { ConfigType } from 'dayjs'
import { dayjs } from 'src/services/dayjs'

export function extendUnixRange(range: IEpochRange, useUtc: boolean) {
  const from = useUtc ? dayjs.utc(fromUnix(range.from)).startOf('day').unix()
                      : dayjs(fromUnix(range.from)).startOf('day').unix()
  const to = useUtc ? dayjs.utc(fromUnix(range.to)).endOf('day').unix()
                    : dayjs(fromUnix(range.to)).endOf('day').unix()
  return [from, to]
}

export function toUnixRange(dateRange: INativeRange, useUtc: boolean) {
  dateRange = dateRange || {}
  return {
    from: toUnix(dateRange.dateStart, useUtc),
    to: toUnix(dateRange.dateEnd, useUtc),
    tbd: dateRange.selectedTemplateName ? dateRange.selectedTemplateName : null,
    useUtc,
  }
}

export function fromUnixRange(unixRange: IEpochRange, useUtc: boolean): INativeRange {
  if (!unixRange) return undefined
  return {
    dateStart: fromUnix(unixRange.from, useUtc),
    dateEnd: fromUnix(unixRange.to, useUtc),
    selectedTemplate: unixRange.tbd,
    selectedTemplateName: unixRange.tbd,
    localizationMap: {Cancel: 'close'},
    isSet() {
      return this.dateStart || this.dateEnd
    },
  }
}

export function toUnix(nativeDate: ConfigType, useUtc) {
  if (!nativeDate) return undefined
  if (!useUtc) return dayjs(nativeDate).unix()
  return dayjs.utc(dayjs(nativeDate).format('YYYY-MM-DD HH:mm:ss')).unix()
}

// convert 3:00 local time to 3:00 UTC
export function epochToUtc(epoch: number, useUtc = true) {
  return useUtc && epoch ? dayjs.utc(dayjs.unix(epoch).format('YYYY-MM-DD HH:mm:ss')).unix(): epoch
}

// convert 3:00 UTC to 3:00 local time
export function epochFromUtc(epoch: number, useUtc = true) {
  return useUtc && epoch ? dayjs(dayjs.utc(epoch * 1000).format('YYYY-MM-DD HH:mm:ss')).unix() : epoch
}

export function epochRangeFromUtc(epoch: IEpochRange, useUtc = true) {
  return useUtc && epoch ? { ...epoch, from: epochFromUtc(epoch.from, useUtc), to: epochFromUtc(epoch.to, useUtc) } : epoch
}

export function epochRangeToUtc(epoch: IEpochRange, useUtc = true) {
  return useUtc && epoch ? { ...epoch, from: epochToUtc(epoch.from, useUtc), to: epochToUtc(epoch.to, useUtc) } : epoch
}
