import { NgModule } from '@angular/core'
import { RequestPrepaymentFormService } from './request-prepayment.service'


@NgModule({
  providers: [
    RequestPrepaymentFormService,
  ],
})
export class RequestPrepaymentModule { }
