<section>
  <header class="mat-headline-5">Shipment Summary – {{shipmentStatus$ | async}}</header>
  <tc-segments-list-v2 *ngIf="segmentsForm.length"
      [readonly]="isReadonly"
      [dealViewRaw$]="dealViewRaw$"
      [segmentsForm]="segmentsForm"
      [displayColumns]="['type', 'carrierName', 'cutoffDate', 'loadDate', 'originName', 'dropoffDate', 'destinationName', 'ellipsisMenu']"
      [fxRates]="fxRates$ | async"
      (updateSegment)="showUpdateSegment($event)"
      (sendNotification)="openSegmentNotificationDialog($event)"
      (removeSegment)="showRemoveSegment($event)"
      (createCostFromSegment)="openCreateCostDialog($event)"
      ></tc-segments-list-v2>
</section>

<section class="segments-section" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="15px 40px">
  <header gdColumn="span 2">
    <div class="mat-headline-5">Segments</div>
    <span fxFlex></span>
    <tc-epoch-range-field placeholder="Supplier Pickup Range" [readonly]="isReadonly"
        [useUtc]="true" [useTemplates]="true"
        [min]="detailsForm.controls.date.value || TODAY"
        [max]="detailsForm.controls.deliveryDatesTo.value"
        [formGroup]="detailsForm"
        startCtrlName="shipmentDatesFrom"
        endCtrlName="shipmentDatesTo"
        tbdCtrlName="shipmentDatesTbd"
        comparisonStartCtrlName="deliveryDatesFrom"
        comparisonEndCtrlName="deliveryDatesTo"
        ></tc-epoch-range-field>

    <tc-epoch-range-field placeholder="Buyer Delivery Range" [readonly]="isReadonly"
        [useUtc]="true" [useTemplates]="true"
        [min]="detailsForm.controls.shipmentDatesFrom.value || TODAY"
        [formGroup]="detailsForm"
        startCtrlName="deliveryDatesFrom"
        endCtrlName="deliveryDatesTo"
        tbdCtrlName="deliveryDatesTbd"
        comparisonStartCtrlName="shipmentDatesFrom"
        comparisonEndCtrlName="shipmentDatesTo"
        ></tc-epoch-range-field>

    <tc-select-search placeholder="Transit Type" [readonly]="isReadonly"
        [group]="detailsForm" ctrlName="shipmentType"
        [items]="ShipmentTypes" bindLabel="name" bindValue="id"
        ></tc-select-search>
    <tc-select-search placeholder="Color" [readonly]="isReadonly"
        [group]="detailsForm" ctrlName="color"
        [items]="COLOR_OPTIONS" bindLabel="name" bindValue="id"
        ></tc-select-search>
    <span fxFlex></span>
    <button mat-raised-button color="primary" type="button"
    *ngIf="hasAesResponses$ | async"
    (click)="showAesResponseReviewModal()">
      AES responses
    </button>
    <button mat-raised-button color="add" type="button"
      *ngIf="!isReadonly && (canOpenBookingReview$ | async)"
      (click)="showBookingReview()">
      <i class="fa fa-ship"></i> See bookings
    </button>
    <button mat-raised-button color="add" type="button"
      *ngIf="!isReadonly && (canOpenBookingForm$ | async)"
      (click)="showBookingForm()">
      <i class="fa fa-ship"></i> Book
    </button>
    <button mat-raised-button color="primary" type="button" *ngIf="!isReadonly" (click)="addRegularSegment()">
      <mat-icon class="fa fa-plus"></mat-icon> Add Segment
    </button>
  </header>
  <tc-segment-form-regular
    *ngFor="let s of transportSegments$ | async; trackBy:getSegmentFormId"
    [dealViewRaw$]="dealViewRaw$"
    [dealForm]="dealForm"
    [segmentForm]="s.segmentForm"
    [segmentIndex]="s.segmentIndex"
    [readonly]="isReadonly"
    (removeSegment)="showRemoveSegment(s.segmentForm)"
    (costsActualizationCreation)="costsActualizationCreation.next($event)"
  ></tc-segment-form-regular>
</section>

<section class="segments-section">
  <header>
    <div class="mat-headline-5">Warehouse</div>
    <span fxFlex></span>
    <button mat-raised-button color="primary" type="button" *ngIf="!isReadonly" (click)="addWarehouseSegment()">
      <mat-icon class="fa fa-plus"></mat-icon> Add Warehouse
    </button>
  </header>
  <tc-segment-form-warehouse *ngFor="let s of warehouseSegments$ | async; trackBy:getSegmentFormId"
      [dealId]="dealId$ | async"
      [segmentIndex]="s.segmentIndex"
      [segmentForm]="s.segmentForm"
      [segmentsForm]="segmentsForm"
      [readonly]="isReadonly"
      (removeSegment)="showRemoveSegment(s.segmentForm)"
      ></tc-segment-form-warehouse>
</section>
