import { CurrencyPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { Cost, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawStatus, Invoice } from '@tradecafe/types/core'
import { DeepReadonly, partial } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { round, sumBy } from 'lodash-es'
import { Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { actualizeCostToZero, patchDealProduct, unactualizeCostToZero } from 'src/app/store/deal-view.actions'
import { selectMeasureEntities } from 'src/app/store/measures'
import { selectProductEntities } from 'src/app/store/products'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { InlineEditorComponent } from 'src/components/inline-editor/inline-editor.component'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { environment } from 'src/environments/environment'
import { MeasurePipe } from 'src/filters/measure.pipe'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { DealFormGroup, DealFormValue, DealProductFormGroup, DealProductFormValueBase } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { canActualizeCost, canUnactualizeCost } from 'src/services/data/costs.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { getCostByProductForm } from 'src/shared/utils/get-cost-by-product-form'
import { replayForm, replayFormStatus } from 'src/shared/utils/replay-form'
import { AdditionalCostDialogService } from './additional-cost-dialog/additional-cost-dialog.service'
import { DealProductOverlayService } from './deal-product-overlay/deal-product-overlay.service'

interface DealProductRow {
  productIndex: number
  productForm: DealProductFormGroup
  packagesCount: number
  name: string
  numbers: {
    estCost: number
    estRevenue: number
    partialCost: number
    actRevenue: number
    actCost: number
  }
  estSupplierWeight: string
  estSupplierPrice: string
  estCost: string
  estBuyerWeight: string
  estBuyerPrice: string
  estRevenue: string
  actSupplierPrice: string
  actSupplierWeight: string
  partialCost: string
  actCost: string
  actBuyerPrice: string
  actBuyerWeight: string
  grossWeight: string
  actRevenue: string
  margin: string
  // internal
  dealId: string
  productId: string
  supplierCurrencyCode: string
  buyerCurrencyCode: string
  supplierMeasureSymbol: string
  buyerMeasureSymbol: string

  canEditPackagesCount: boolean
  canEditEstSupplierWeight: boolean
  canEditEstBuyerWeight: boolean
  canEditSupplierPrice: boolean
  canEditBuyerPrice: boolean
  canEditActSupplierWeight: boolean
  canEditActBuyerWeight: boolean
  canEditActSupplierPrice: boolean
  canEditActBuyerPrice: boolean
  canEditGrossWeight: boolean
}

@Component({
  selector: 'tc-deal-products-list',
  templateUrl: './deal-products-list.component.html',
  styleUrls: ['./deal-products-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealProductsListComponent extends OnDestroyMixin implements OnInit {

  readonly enableAutomateAdditionalCharge = environment.enableAutomateAdditionalCharge

  constructor(
    private store: Store,
    private measurePipe: MeasurePipe,
    private currencyPipe: CurrencyPipe,
    private NotesOverlay: NotesOverlayService,
    private DealProductOverlay: DealProductOverlayService,
    private ConfirmModal: ConfirmModalService,
    private Measures: MeasuresService,
    private AuthApi: AuthApiService,
    private AdditionalCostDialog: AdditionalCostDialogService,
  ) { super() }

  @Input()
  dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices & DealViewRawCosts & DealViewRawStatus>>

  @Input() readonly = false

  @Input()
  dealForm: DealFormGroup

  @Input()
  invoices$: Observable<DeepReadonly<Invoice[]>>

  // dynamic data
  dataSource = new MatTableDataSource<DealProductRow>()

  @Input()
  displayColumns = [
    'packagesCount',
    'name',
    'estSupplierWeight',
    'estSupplierPrice',
    'estBuyerWeight',
    'estBuyerPrice',
    'actSupplierWeight',
    'actSupplierPrice',
    'grossWeight',
    'actBuyerWeight',
    'actBuyerPrice',
    'estCost',
    'partialCost',
    'actCost',
    'estRevenue',
    'actRevenue',
    'margin',
    'ellipsisMenu',
  ]

  @ViewChild(InlineEditorComponent)
  inlineEditor: InlineEditorComponent

  @Output()
  rowClick = new EventEmitter<{row: DealProductRow, column?: string, tabName?: string}>()
  isClickable: boolean

  @Output()
  dealProductsUpdate = new EventEmitter()

  private dealViewRaw: DeepReadonly<DealViewRawDeal & DealViewRawInvoices & DealViewRawCosts & DealViewRawStatus>
  totalPackagesCount$: Observable<number>
  totalEstSupplierWeight$: Observable<string>
  totalEstBuyerWeight$: Observable<string>
  totalEstCost$: Observable<string>
  totalEstRevenue$: Observable<string>
  totalActSupplierWeight$: Observable<string>
  totalGrossWeight$: Observable<string>
  totalActBuyerWeight$: Observable<string>
  totalActCost$: Observable<string>
  totalActRevenue$: Observable<string>
  totalPartialCost$: Observable<string>


  ngOnInit(): void {
    this.dealViewRaw$.pipe(untilComponentDestroyed(this)).subscribe((dv) => {
      this.dealViewRaw = dv
    })
    const data$ = combineLatest([
      replayForm<DealFormValue>(this.dealForm),
      this.store.pipe(select(selectProductEntities), waitNotEmpty()),
      this.store.pipe(select(selectMeasureEntities), waitNotEmpty()),
      replayFormStatus(this.dealForm),
    ]).pipe(map(([dealForm, products, measures]) =>
      dealForm.products.map((dealProduct, productIndex): DealProductRow => {
        const productForm = this.dealForm.controls.products.at(productIndex)
        const { supplierCurrencyCode, buyerCurrencyCode } = dealForm.details
        const { supplierMeasureId, buyerMeasureId } = dealProduct

        const numbers = {
          estCost: dealProduct.supplierEstPrice * dealProduct.supplierEstWeight,
          estRevenue: dealProduct.buyerEstPrice * dealProduct.buyerEstWeight,
          partialCost: partial(dealProduct.supplierActualPrice, dealProduct.supplierEstPrice) * partial(dealProduct.supplierActualWeight, dealProduct.supplierEstWeight),
          actRevenue: partial(dealProduct.buyerActualPrice, dealProduct.buyerEstPrice) * partial(dealProduct.buyerActualWeight, dealProduct.buyerEstWeight),
          actCost: dealProduct.supplierActualPrice * dealProduct.supplierActualWeight || 0,
        }

        return {
          numbers,
          productIndex,
          productForm,
          dealId: dealForm.details.deal.deal_id,
          productId: dealProduct.productId,
          supplierCurrencyCode,
          buyerCurrencyCode,
          supplierMeasureSymbol: measures[supplierMeasureId].symbol,
          buyerMeasureSymbol: measures[buyerMeasureId].symbol,
          packagesCount: dealProduct.packagesCount,
          name: products[dealProduct.productId]?.name,
          estSupplierWeight: this.measurePipe.transform(dealProduct.supplierEstWeight, supplierMeasureId, 2),
          estSupplierPrice: this.currencyPipe.transform(dealProduct.supplierEstPrice, supplierCurrencyCode, 'symbol-narrow', '1.4'),
          estBuyerWeight: this.measurePipe.transform(dealProduct.buyerEstWeight, buyerMeasureId, 2),
          estBuyerPrice: this.currencyPipe.transform(dealProduct.buyerEstPrice, buyerCurrencyCode, 'symbol-narrow', '1.4'),
          actSupplierWeight: this.measurePipe.transform(dealProduct.supplierActualWeight || 0, supplierMeasureId, 2),
          actSupplierPrice: this.currencyPipe.transform(dealProduct.supplierActualPrice || 0, supplierCurrencyCode, 'symbol-narrow', '1.4'),
          grossWeight: this.measurePipe.transform(dealProduct.buyerGrossWeight, buyerMeasureId, 2),
          actBuyerWeight: this.measurePipe.transform(dealProduct.buyerActualWeight || 0, buyerMeasureId, 2),
          actBuyerPrice: this.currencyPipe.transform(dealProduct.buyerActualPrice || 0, buyerCurrencyCode, 'symbol-narrow', '1.4'),

          estCost: this.currencyPipe.transform(numbers.estCost, supplierCurrencyCode, 'symbol-narrow', '1.2-2'),
          estRevenue: this.currencyPipe.transform(numbers.estRevenue, buyerCurrencyCode, 'symbol-narrow', '1.2-2'),
          partialCost: this.currencyPipe.transform(numbers.partialCost, supplierCurrencyCode, 'symbol-narrow', '1.2-2'),
          actRevenue: this.currencyPipe.transform(numbers.actRevenue, buyerCurrencyCode, 'symbol-narrow', '1.2-2'),
          actCost: this.currencyPipe.transform(numbers.actCost, supplierCurrencyCode, 'symbol-narrow', '1.2-2'),

          margin: `${round(dealProduct.marginP * 100, 2)}%`,

          canEditPackagesCount: productForm.controls.packagesCount.enabled,
          canEditEstSupplierWeight: productForm.controls.supplierEstWeight.enabled,
          canEditEstBuyerWeight: productForm.controls.buyerEstWeight.enabled,
          canEditSupplierPrice: productForm.controls.supplierEstPrice.enabled,
          canEditBuyerPrice: productForm.controls.buyerEstPrice.enabled,
          canEditActSupplierWeight: productForm.controls.supplierActualWeight.enabled,
          canEditActBuyerWeight: productForm.controls.buyerActualWeight.enabled,
          canEditActSupplierPrice: productForm.controls.supplierActualPrice.enabled,
          canEditActBuyerPrice: productForm.controls.buyerActualPrice.enabled,
          canEditGrossWeight: productForm.controls.buyerGrossWeight.enabled,
        }
      })))

    data$.pipe(untilComponentDestroyed(this)).subscribe(rows => {
      this.dataSource.data = rows
    })

    this.totalPackagesCount$ = data$.pipe(map(rows => sumBy(rows, row => row.packagesCount)))
    this.totalEstSupplierWeight$ = this.totalDealWeight$('supplierEstWeight', 'supplierMeasureId')
    this.totalEstBuyerWeight$ = this.totalDealWeight$('buyerEstWeight', 'buyerMeasureId')
    this.totalActSupplierWeight$ = this.totalDealWeight$('supplierActualWeight', 'supplierMeasureId')
    this.totalGrossWeight$ = this.totalDealWeight$('buyerGrossWeight', 'buyerMeasureId')
    this.totalActBuyerWeight$ = this.totalDealWeight$('buyerActualWeight', 'buyerMeasureId')
    this.totalEstCost$ = data$.pipe(map(this.totalAmount('estCost', 'supplierCurrencyCode')))
    this.totalEstRevenue$ = data$.pipe(map(this.totalAmount('estRevenue', 'buyerCurrencyCode')))
    this.totalActCost$ = data$.pipe(map(this.totalAmount('actCost', 'supplierCurrencyCode')))
    this.totalActRevenue$ = data$.pipe(map(this.totalAmount('actRevenue', 'buyerCurrencyCode')))
    this.totalPartialCost$ = data$.pipe(map(this.totalAmount('partialCost', 'supplierCurrencyCode')))
  }

  showProductDetails(row: DealProductRow) {
    this.DealProductOverlay.showProductDetails(this.dealViewRaw$, this.dealForm, row.productForm).toPromise()
  }

  showDealProductNotesOverlay(row: DealProductRow) {
    this.NotesOverlay.showProductNotes(row.dealId, row.productId).subscribe()
  }


  editPackagesCount(row: DealProductRow, cellEl: HTMLElement) {
    if (!row.canEditPackagesCount) return
    this.inlineEditor.editNumber(() => row.packagesCount, cellEl).then((packagesCount: number) =>
      this.patchProduct(row.productIndex, { packagesCount }))
  }

  editEstSupplierWeight(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() => row.productForm.getRawValue().supplierEstWeight, cellEl).then(supplierEstWeight => {
      const { supplierActualWeight, supplierMeasureId, buyerMeasureId } = row.productForm.getRawValue()
      this.patchProduct(row.productIndex, supplierActualWeight
        ? { supplierEstWeight,
            supplierActualWeight: supplierEstWeight,
            buyerActualWeight: this.Measures.convert(supplierEstWeight, supplierMeasureId, buyerMeasureId) }
        : { supplierEstWeight })
    })
  }

  editEstSupplierPrice(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() => row.productForm.getRawValue().supplierEstPrice, cellEl).then(supplierEstPrice =>
      this.patchProduct(row.productIndex, { supplierEstPrice, supplierActualPrice: supplierEstPrice }))
  }

  editActSupplierWeight(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(
      () => row.productForm.getRawValue().supplierActualWeight, cellEl
    ).then(supplierActualWeight => {
      const { supplierMeasureId, buyerMeasureId } = row.productForm.getRawValue()
      this.patchProduct(
        row.productIndex,
        row.productForm.controls.buyerActualWeight.enabled ? {
          supplierActualWeight,
          buyerActualWeight: this.Measures.convert(supplierActualWeight, supplierMeasureId, buyerMeasureId),
        } : {
          supplierActualWeight
        }
      )
    })
  }

  editActSupplierPrice(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() =>
      row.productForm.getRawValue().supplierActualPrice, cellEl).then(supplierActualPrice =>
      this.patchProduct(row.productIndex, { supplierActualPrice }))
  }

  editEstBuyerWeight(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() => row.productForm.getRawValue().buyerEstWeight, cellEl).then(buyerEstWeight => {
      const { buyerActualWeight } = row.productForm.getRawValue()
      this.patchProduct(row.productIndex, buyerActualWeight
        ? { buyerEstWeight, buyerActualWeight: buyerEstWeight }
        : { buyerEstWeight })
    })
  }

  editEstBuyerPrice(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() => row.productForm.getRawValue().buyerEstPrice, cellEl).then(buyerEstPrice =>
      this.patchProduct(row.productIndex, { buyerEstPrice, buyerActualPrice: buyerEstPrice }))
  }

  editActBuyerWeight(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() => row.productForm.getRawValue().buyerActualWeight, cellEl).then(buyerActualWeight =>
      this.patchProduct(row.productIndex, { buyerActualWeight }))
  }

  editActBuyerPrice(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() =>
      row.productForm.getRawValue().buyerActualPrice, cellEl).then(buyerActualPrice =>
      this.patchProduct(row.productIndex, { buyerActualPrice }))
  }

  editGrossWeight(row: DealProductRow, cellEl: HTMLElement) {
    this.inlineEditor.editNumber(() => row.productForm.getRawValue().buyerGrossWeight, cellEl).then(buyerGrossWeight =>
      this.patchProduct(row.productIndex, { buyerGrossWeight }))
  }

  private getCost(row: DealProductRow): Cost {
    return getCostByProductForm(this.dealForm, row.productForm)
  }

  canActualize(row: DealProductRow) {
    const { role } = this.AuthApi.currentUser
    return !this.readonly && role !== 'trader' && canActualizeCost(this.getCost(row), this.dealViewRaw.invoices)
  }
  canUnactualize(row: DealProductRow) {
    const { role } = this.AuthApi.currentUser
   return !this.readonly && role !== 'trader' && canUnactualizeCost(this.getCost(row))
  }

  async showActualizeToZero(row: DealProductRow) {
    const cost = this.getCost(row)
    await this.ConfirmModal.show({
      title: 'Actualize Cost to $0?',
      titleIcon: 'fas fa-exclamation-triangle',
      description: `Are you sure you want to actualize cost "${cost.service}" to $0?`,
      confirmButtonText: 'Actualize, Zero',
      confirmButtonClass: 'btn-danger',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn-link',
    })
    this.store.dispatch(actualizeCostToZero({ dv: this.dealViewRaw, cost_id: cost.cost_id }))
  }

  protected showUnactualizeToZero(row: DealProductRow) {
    const cost = this.getCost(row)
    this.store.dispatch(unactualizeCostToZero({ dv: this.dealViewRaw, cost_id: cost.cost_id }))
  }


  private patchProduct(index: number, patch: Partial<DealProductFormValueBase>) {
    const dealForm = this.dealForm.serialize()
    this.dealForm.controls.products.controls[index].patchValue(patch)
    this.store.dispatch(patchDealProduct({ dv: this.dealViewRaw, dealForm, index, patch }))
  }

  private totalDealWeight$(weightField: 'supplierEstWeight' | 'supplierActualWeight', measureField: 'supplierMeasureId'): Observable<string>
  private totalDealWeight$(weightField: 'buyerEstWeight' | 'buyerActualWeight' | 'buyerGrossWeight', measureField: 'buyerMeasureId'): Observable<string>
  private totalDealWeight$(
    weightField: 'supplierEstWeight' | 'buyerEstWeight' | 'supplierActualWeight' | 'buyerActualWeight' | 'buyerGrossWeight',
    measureField: 'supplierMeasureId' | 'buyerMeasureId',
  ): Observable<string> {
    return replayForm<DealFormValue>(this.dealForm).pipe(
      map(dealForm => {
        const measureId = dealForm.products[0][measureField]
        const sum = sumBy(dealForm.products, row =>
          this.Measures.convert(row[weightField], row[measureField], measureId))
        return this.measurePipe.transform(sum, measureId, 2)
      }),
      distinctUntilChanged())
  }

  private totalAmount(
    amountField: 'estCost' | 'estRevenue' | 'actCost' | 'actRevenue' | 'partialCost',
    currencyField: 'supplierCurrencyCode' | 'buyerCurrencyCode',
  ) {
    return (rows: DealProductRow[]) => {
      const sum = sumBy(rows, row => row.numbers[amountField])
      const currency = this.dealForm.getRawValue().details[currencyField]
      return this.currencyPipe.transform(sum, currency, 'symbol-narrow', '1.2-2')
    }
  }

  async showAdditionalCost(row: DealProductRow) {
    await this.AdditionalCostDialog.showAdditionalCost(row.dealId).toPromise()
    this.dealProductsUpdate.emit()
  }
}
