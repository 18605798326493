import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { ModalModule } from 'src/shared/modal'
import { AddressFieldModule } from '../address-field/address-field.module'
import { EpochFieldModule } from '../epoch/epoch-field/epoch-field.module'
import { ReactiveAsteriskModule } from '../reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from '../select-search/select-search.module'
import { BookingFormComponent } from './booking-form.component'
import { BookingFormService } from './booking-form.service'


@NgModule({
  declarations: [BookingFormComponent],
  providers: [BookingFormService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    ModalModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    SelectSearchModule,
    AddressFieldModule,
    EpochFieldModule,
  ],
})
export class BookingFormModule { }
