import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  AccountObject,
  CreditNote,
  DealView,
  DealViewRaw,
  ForexData,
  ForexRange,
  Product,
  VendorCredit,
} from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectProductEntities } from 'src/app/store/products';
import {
  CostRow,
  CostsListComponent,
} from 'src/components/costs-list/costs-list.component';
import {
  CostsFormGroup,
  DealDetailsFormGroup,
  DealFormGroup,
} from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema';
import { replayForm } from 'src/shared/utils/replay-form';
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty';
import { oldDealViewSel } from '../../../../trading/deal-form/deal-form-page/deal-form-page.decorator';

export interface SwapServiceProviderDialogOptions {
  title: string;
  dealForm: DealFormGroup;
  dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>;
  newProvider: AccountObject;
}

export interface SwapServiceProviderDialogResult {
  selectedCosts: CostRow[];
  onlyUpdatingProvider: boolean;
}

@Component({
  selector: 'tc-swap-service-provider-dialog',
  templateUrl: './swap-service-provider-dialog.component.html',
  styleUrls: ['./swap-service-provider-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwapServiceProviderDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private dialogData: SwapServiceProviderDialogOptions,
    private dialogRef: MatDialogRef<
      SwapServiceProviderDialogComponent,
      SwapServiceProviderDialogResult | null
    >,
    private store: Store
  ) { }

  @ViewChild(CostsListComponent)
  costsList: CostsListComponent;

  tmpForm = new FormGroup({});
  title = this.dialogData.title;
  fxRates$: Observable<ForexData>;
  fxRatesAskRange$: Observable<ForexRange>;
  creditNotes$: Observable<DeepReadonly<CreditNote[]>>;
  vendorCredits$: Observable<DeepReadonly<VendorCredit[]>>;
  costsForm: CostsFormGroup;
  dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>;
  detailsForm: DealDetailsFormGroup;
  products$: Observable<DeepReadonly<Dictionary<Product>>>;
  oldDealView$: Observable<DealView>;

  filter;

  ngOnInit(): void {
    // build cost list
    this.products$ = this.store.pipe(
      select(selectProductEntities),
      waitNotEmpty()
    );
    this.detailsForm = this.dialogData.dealForm.controls.details;
    this.costsForm = this.dialogData.dealForm.controls.costs;
    this.fxRates$ = replayForm(this.detailsForm.controls.fxRates);
    this.fxRatesAskRange$ = replayForm(
      this.detailsForm.controls.fxRatesAskRange
    );
    this.dealViewRaw$ = this.dialogData.dealViewRaw$;
    this.creditNotes$ = this.dealViewRaw$.pipe(
      map((dto) => dto.credit_notes || [])
    );
    this.vendorCredits$ = this.dealViewRaw$.pipe(
      map((dto) => dto.vendor_credits || [])
    );
    this.oldDealView$ = oldDealViewSel(this.dealViewRaw$, this.products$);
    //
    this.filter = (cr: CostRow) => {
      return cr.originalCost.status === 'pending'
        && cr.originalCost.service === 'Freight Rate'
        && cr.originalCost.type === 'tertiary'
        && cr.originalCost.provider !== this.dialogData.newProvider.account.toString()
    }
  }

  proceedWithout() {
    const selectedCosts: CostRow[] = this.costsList.selectedCosts();
    this.dialogRef.close({
      selectedCosts,
      onlyUpdatingProvider: true,
    });
  }

  async save() {
    const selectedCosts: CostRow[] = this.costsList.selectedCosts();
    this.dialogRef.close({
      selectedCosts,
      onlyUpdatingProvider: false,
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
