import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, delay, filter, map, switchMap } from 'rxjs/operators'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { MacropointService} from 'src/services/data/macropoint.service'
import { throwError } from 'rxjs'
import { createMacropointOrdersInBatch, createMacropointOrder, createMacropointOrderFailure, createMacropointOrderSuccess, createMacropointOrdersInBatchFailure, createMacropointOrdersInBatchSuccess, stopMacropointOrder, stopMacropointOrderSuccess, stopMacropointOrderFailure, updateMacropointOrdersInBatch, updateMacropointOrder, updateMacropointOrderFailure, updateMacropointOrderSuccess, updateMacropointOrdersInBatchFailure, updateMacropointOrdersInBatchSuccess } from './macropoint.actions'

@Injectable()
export class MacropointEffects {
  constructor(
    private actions$: Actions,
    private macropoint: MacropointService,
    private toaster: ToasterService,
  ) {}

  createMacropointOrder$ = createEffect(() => this.actions$.pipe(
    ofType(createMacropointOrder),
    switchMap(action =>
      from(this.macropoint.create(action.deal)).pipe(
        filter(order => Boolean(order)),
        map(order => createMacropointOrderSuccess({
            order,
        })),
        catchError(error => {
          if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
          console.error('Unable to create Macropoint order', error)
          this.toaster.error('Unable to create Macropoint order')
          return of(createMacropointOrderFailure({ error }))
        })))))

    createMacropointOrdersInBatch$ = createEffect(() => this.actions$.pipe(
        ofType(createMacropointOrdersInBatch),
        switchMap(action =>
            from(this.macropoint.createInBatch(action.deals )).pipe(
                filter(hasSubmitted => hasSubmitted),
                map(() => createMacropointOrdersInBatchSuccess()),
                catchError(error => {
                    if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
                    console.error('Unable to create Macropoint orders', error)
                    this.toaster.error('Unable to create Macropoint orders')
                    return of(createMacropointOrdersInBatchFailure({ error }))
                })))))
    
    updateMacropointOrder$ = createEffect(() => this.actions$.pipe(
      ofType(updateMacropointOrder),
      switchMap(action =>
        from(this.macropoint.update(action.dealId, action.segment)).pipe(
          filter(order => Boolean(order)),
          map(order => updateMacropointOrderSuccess({
              order,
          })),
          catchError(error => {
            if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
            console.error('Unable to update Macropoint order', error)
            this.toaster.error('Unable to update Macropoint order')
            return of(updateMacropointOrderFailure({ error }))
          })))))
  
      updateMacropointOrdersInBatch$ = createEffect(() => this.actions$.pipe(
          ofType(updateMacropointOrdersInBatch),
          switchMap(action =>
              from(this.macropoint.updateInBatch(action.deals )).pipe(
                  filter(hasSubmitted => hasSubmitted),
                  map(() => updateMacropointOrdersInBatchSuccess()),
                  catchError(error => {
                      if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
                      console.error('Unable to update Macropoint orders', error)
                      this.toaster.error('Unable to update Macropoint orders')
                      return of(updateMacropointOrdersInBatchFailure({ error }))
                  })))))

    stopMacropointOrder$ = createEffect(() => this.actions$.pipe(
      ofType(stopMacropointOrder),
      switchMap(action =>
        from(this.macropoint.stop(action.identifier)).pipe(
          delay(500), // there's a very small delay until elastic search processes the removal of the indexed value, not noticeable on the UI
          map(() => stopMacropointOrderSuccess({ identifier: action.identifier })),
          catchError(error => {
            if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') return throwError(error)
            console.error('Unable to stop Macropoint order', error)
            this.toaster.error('Unable to stop Macropoint order')
            return of(stopMacropointOrderFailure({ error }))
          })))))
}
