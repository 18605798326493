import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject } from 'rxjs'
import { cancelAESExportReport, cancelAESExportReportSuccess } from 'src/app/store/aes'

export interface AesCancellationDialogOptions {
  exportReportId: string,
  dealId: string,
}

@Component({
  selector: 'tc-aes-cancellation-reason-dialog',
  templateUrl: './aes-cancellation-dialog.component.html',
  styleUrls: ['./aes-cancellation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AesCancellationDialogComponent extends OnDestroyMixin implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: AesCancellationDialogOptions,
    private dialogRef: MatDialogRef<AesCancellationDialogComponent, boolean>,
    private actions$: Actions,
    private store: Store
  ) { super() }

  form = new FormGroup({
    reason: new FormControl(undefined, []),
  })

  protected inProgress$ = new BehaviorSubject<'loading'|'set-status'|undefined>('loading')
  protected reason: string

  ngOnInit(): void {
    this.actions$.pipe(ofType(cancelAESExportReportSuccess), untilComponentDestroyed(this)).subscribe(() => {
      this.dialogRef.close()
    })
  }

  protected close() {
    this.dialogRef.close()
  }

  protected submit() {
    this.store.dispatch(cancelAESExportReport({
      reportId: this.dialogData.exportReportId,
      dealId: this.dialogData.dealId,
      reason: this.form.controls.reason.value,
    }))
  }
}
