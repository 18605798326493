import { Pipe, PipeTransform } from '@angular/core'
import { isNumber, isString } from 'lodash-es'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'

/**
 * Measure filter
 *
 * digits = -1   means no rounding
 */
@Pipe({
  name: 'measure',
  pure: true,
})
export class MeasurePipe implements PipeTransform {
  constructor(private Measures: MeasuresService) {}

  transform(weight: number | string, idOrCode, digits = 2) {
    const measure = this.Measures.getMeasureByIdOrCodeSync(idOrCode)

    if (isNumber(weight)) {
      if (digits !== -1) weight = Math.round(10 ** digits * weight) / 10 ** digits
      return `${weight.toLocaleString()} ${measure?.symbol || ''}`.trim()

    } else if (isString(weight)) {
      return weight + (measure?.symbol ? '/' + measure.symbol : '')
    }

    return ''
  }
}

