import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { FinanceIssuesModule } from 'src/shared/finance-issues/finance-issues.module'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ShippingDetailsFormModule } from '../shipping-details-form/shipping-details-form.module'
import { ShippingDetailsOverlayComponent } from './shipping-details-overlay.component'
import { ShippingDetailsOverlayService } from './shipping-details-overlay.service'

@NgModule({
  providers: [ShippingDetailsOverlayService],
  declarations: [ShippingDetailsOverlayComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    FinanceIssuesModule,
    FlexLayoutModule,
    ShippingDetailsFormModule,
    LocksModule,
    DealViewIncompleteComponent,
  ],
})
export class ShippingDetailsOverlayModule {}
