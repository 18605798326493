<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;{{title}}
</h2>
<form [formGroup]="noteForm" (submit)="saveMultipleNotes()">
  <mat-dialog-content>
    <div fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <tc-select-search fxFlex placeholder="Category"
          [multiple]="true"
          *ngIf="canSeeCategory"
          [group]="noteForm" ctrlName="categories"
          [items]="categoriOptions" bindValue="id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search fxFlex placeholder="Company" [multiple]="true"
          *ngIf="canSeeCompaniesList$ | async"
          [group]="noteForm" ctrlName="company"
          [items]="recipients$ | async" bindValue="id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search fxFlex placeholder="Document Type"
          *ngIf="canSeeDocumentType$ | async"
          [group]="noteForm" ctrlName="document_type"
          [items]="documentTypes$ | async" bindValue="id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search fxFlex placeholder="Visibility" ng-required="true"
          *ngIf="canSeeVisibility"
          [group]="noteForm" ctrlName="visibility"
          [items]="NoteVisibilities" bindValue="value" bindLabel="name"
          ></tc-select-search>
    </div>
    <mat-form-field style="display: block">
      <mat-label>Enter text</mat-label>
      <textarea matInput rows="10" autofocus formControlName="body"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="space-between center" fxLayoutGap="15px">
    <button mat-raised-button type="button" color="warn"
        *ngIf="canIgnore"
        [disabled]="inProgress$ | async"
        (click)="ignore()">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-times]="(inProgress$ | async) !== 'ignore'"
          [class.fa-spin]="(inProgress$ | async) === 'ignore'"
          [class.fa-spinner]="(inProgress$ | async) === 'ignore'"
          ></i>
      IGNORE</button>
    <button mat-raised-button type="button" color="add"
        *ngIf="canUnignore"
        [disabled]="inProgress$ | async"
        (click)="unignore()">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-check]="(inProgress$ | async) !== 'ignore'"
          [class.fa-spin]="(inProgress$ | async) === 'ignore'"
          [class.fa-spinner]="(inProgress$ | async) === 'ignore'"
          ></i>
      UN-IGNORE</button>
    <span fxFlex></span>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      {{isNew ? 'Save' : 'Update'}}
    </button>
  </mat-dialog-actions>
</form>
