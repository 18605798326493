import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { CostsListModule } from 'src/components/costs-list/costs-list.module'
import { CsvExporterModule } from 'src/components/csv-exporter/csv-exporter.module'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { EpochDateModule } from 'src/components/epoch/epoch.module'
import { NotesOverlayModule } from 'src/components/notes/notes-overlay/notes-overlay.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SegmentFormModule } from 'src/components/segment-form/segment-form.module'
import { SegmentsListModule } from 'src/components/segments-list/segments-list.module'
import { StickyHeaderModule } from 'src/directives/sticky-header/sticky-header.module'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { FinanceIssuesModule } from 'src/shared/finance-issues/finance-issues.module'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ModalModule } from 'src/shared/modal'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { DocumentsOverlayModule } from '../../deals/deal-documents/documents-overlay/documents-overlay.module'
import { RequestPrepaymentModule } from '../../deals/request-prepayment/request-prepayment.module'
import { CreateOfferFormModule } from '../create-offer-form/create-offer-form.module'
import { CloneFormModule } from '../deal-clones/clone-form/clone-form.module'
import { ChangeBrokeragePartyFormModule } from './change-brokerage-party-form/change-brokerage-party-form.module'
import { ChangeCloneFormModule } from './change-clone-form/change-clone-form.module'
import { DealClonesModule } from './deal-clones/deal-clones.module'
import { DealDetailsModule } from './deal-details/deal-details.module'
import { DealFormPageComponent } from './deal-form-page.component'
import { DealFormService } from './deal-form.service'
import { DealSpecsModule } from './deal-specs/deal-specs.module'


@NgModule({
  declarations: [DealFormPageComponent],
  exports: [DealFormPageComponent],
  providers: [DealFormService],
  imports: [
    ModalModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule,
    FlexLayoutModule,
    CostsListModule,
    SegmentsListModule,
    DealDetailsModule,
    DealSpecsModule,
    PipesModule,
    EpochDateModule,
    EpochFieldModule,
    ReactiveAsteriskModule,
    StickyHeaderModule,
    DealClonesModule,
    SegmentFormModule,
    CsvExporterModule,
    DocumentsOverlayModule,
    NotesOverlayModule,
    CloneFormModule,
    RequestPrepaymentModule,
    LocksModule,
    ChangeCloneFormModule,
    FinanceIssuesModule,
    ChangeBrokeragePartyFormModule,
    DealViewIncompleteComponent,
    CreateOfferFormModule,
  ],
})
export class DealFormPageModule { }
