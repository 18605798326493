import {createAction, props} from '@ngrx/store'
import {DealRow, MacropointOrder, Segment} from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'

export const createMacropointOrder = createAction(
    '[Macropoint/API] Create Macropoint order',
    props<{ deal: DeepReadonly<DealRow> }>(),
)
export const createMacropointOrderSuccess = createAction(
    '[Macropoint/API] Create Macropoint order Success',
    props<{
        order: MacropointOrder,
    }>(),
)
export const createMacropointOrderFailure = createAction(
    '[Macropoint/API] Create Macropoint order Failure',
    props<{ error: Error }>(),
)

export const createMacropointOrdersInBatch = createAction(
    '[Macropoint/API] create Macropoint orders in batch',
    props<{ deals: DeepReadonly<DealRow>[] }>(),
)
export const createMacropointOrdersInBatchSuccess = createAction(
    '[Macropoint/API] Create Macropoint orders in batch Success',
)
export const createMacropointOrdersInBatchFailure = createAction(
    '[Macropoint/API] Create Macropoint orders in batch Failure',
    props<{ error: Error }>(),
)

export const updateMacropointOrder = createAction(
    '[Macropoint/API] Update Macropoint order',
    props<{ dealId: string, segment: DeepReadonly<Segment> }>(),
)
export const updateMacropointOrderSuccess = createAction(
    '[Macropoint/API] Update Macropoint order Success',
    props<{
        order: MacropointOrder,
    }>(),
)
export const updateMacropointOrderFailure = createAction(
    '[Macropoint/API] Update Macropoint order Failure',
    props<{ error: Error }>(),
)

export const updateMacropointOrdersInBatch = createAction(
    '[Macropoint/API] Update Macropoint orders in batch',
    props<{ deals: DeepReadonly<DealRow>[] }>(),
)
export const updateMacropointOrdersInBatchSuccess = createAction(
    '[Macropoint/API] Update Macropoint orders in batch Success',
)
export const updateMacropointOrdersInBatchFailure = createAction(
    '[Macropoint/API] Update Macropoint orders in batch Failure',
    props<{ error: Error }>(),
)

export const stopMacropointOrder = createAction(
    '[Macropoint/API] Stop Macropoint order',
    props<{ identifier: string }>(),
)
export const stopMacropointOrderSuccess = createAction(
    '[Macropoint/API] Stop Macropoint order Success',
    props<{
        identifier: string,
    }>(),
)
export const stopMacropointOrderFailure = createAction(
    '[Macropoint/API] Stop Macropoint order Failure',
    props<{ error: Error }>(),
)
