import { Injectable } from "@angular/core"
import { Store } from "@ngrx/store"
import { ItemType } from "@tradecafe/types/core"
import { DeepReadonly } from "@tradecafe/types/utils"
import { deleteItemType } from "src/app/store/item-types"
import { ConfirmModalService } from "src/components/confirm/confirm-modal.service"
import { ModalService } from "src/shared/modal"
import { ItemTypeFormComponent, ItemTypeFormOptions } from "./item-type-form.component"

@Injectable()
export class ItemTypeFormService {
  constructor(
    private modal: ModalService,
    private ConfirmModal: ConfirmModalService,
    private store: Store,
  ) {}

  showCreateItemType() {
    return this.modal.openDialog<ItemTypeFormComponent, ItemTypeFormOptions, ItemType>(ItemTypeFormComponent, {
      title: 'Create new Item Type'
    }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
    }).toPromise()
  }

  showUpdateItemType(itemType: DeepReadonly<ItemType>) {
    return this.modal.openDialog<ItemTypeFormComponent, ItemTypeFormOptions, ItemType>(ItemTypeFormComponent, {
      title: 'Update Item Type',
      itemType,
    }, {
      panelClass: ['tc-dialog', 'modal-xlg'],
      width: null,
      maxWidth: null,
    }).toPromise()
  }

  async showRemoveItemType(itemType: DeepReadonly<ItemType>) {
    await this.ConfirmModal.show({
      title: 'Delete this item type?',
      description: 'Are you sure you want to delete this item type?',
    })
    this.store.dispatch(deleteItemType({ id: itemType.item_type_id, itemType }))
  }
}
