import { TableKey } from '@tradecafe/types/core'
import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'

export const FREIGHT_RATES_COLUMNS: ColumnDef[] = [
  { field: 'select',                  displayName: '', internal: true, index: -1 },
  { field: 'ellipsisMenu',            displayName: '', internal: true, index: 1001 },

  { field: 'created',                 displayName: 'Created',                 exportField: 'created',                       exportPipe: 'epoch:DD-MMM-YYYY hh:mm a' },
  { field: 'until',                   displayName: 'Valid Until',             exportField: 'until',                         exportPipe: 'epoch:DD-MMM-YYYY' },
  { field: 'origin_city',             displayName: 'City Of Origin',          exportField: 'origin.city',                   exportPipe: '' },
  { field: 'destination_city',        displayName: 'City Of Destination',     exportField: 'destination.city',              exportPipe: '' },
  { field: 'carrier_name',            displayName: 'Carrier',                 exportField: 'carrier.name',                  exportPipe: '' },
  { field: 'max_weight',              displayName: 'Max Weight',              exportField: 'weight.max',                    exportPipe: '' },
  { field: 'all_in_rate',             displayName: 'All-in Rate',             exportField: 'rate.amount',                   exportPipe: 'currency:rate.currency:symbol-narrow' },
  { field: 'rate_currency',           displayName: 'Currency',                exportField: 'rate.currency',                 exportPipe: '' },
  { field: 'transit_time',            displayName: 'Transit Time',            exportField: 'attributes.transit_time',       exportPipe: '' },
  { field: 'date_quoted',             displayName: 'Date Quoted',             exportField: 'attributes.date_quoted',        exportPipe: 'epoch' },
  { field: 'status',                  displayName: 'Status',                  exportField: 'statusLabel',                   exportPipe: '' },
  { field: 'type',                    displayName: 'Type',                    exportField: 'type',                          exportPipe: '' },
  { field: 'orgState',                displayName: 'State Of Origin',         exportField: 'originStateName',               exportPipe: '' },
  { field: 'orgCountry',              displayName: 'Country Of Origin',       exportField: 'originCountry.name',            exportPipe: '' },
  { field: 'dstState',                displayName: 'State Of Destination',    exportField: 'destinationStateName',          exportPipe: '' },
  { field: 'dstCountry',              displayName: 'Country Of Destination',  exportField: 'destinationCountry.name',       exportPipe: '' },
  { field: 'description',             displayName: 'Description',             exportField: 'description',                   exportPipe: '' },
  { field: 'port_loading',            displayName: 'Port Of Loading',         exportField: 'port_loading',                  exportPipe: '' },
  { field: 'port_discharge',          displayName: 'Port Of Discharge',       exportField: 'port_discharge',                exportPipe: '' },
  { field: 'commodity',               displayName: 'Commodity',               exportField: 'commodity',                     exportPipe: '' },
  { field: 'container_size',          displayName: 'Container Size',          exportField: 'container_size',                exportPipe: '' },
  { field: 'problem',                 displayName: 'Problem',                 exportField: 'problem.detail',                exportPipe: '' },
  { field: 'creator',                 displayName: 'Creator',                 exportField: 'creator.fullname',              exportPipe: '' },
]

export const FREIGHT_RATES_COLUMNS_BY_ID = keyBy(FREIGHT_RATES_COLUMNS, 'field')
export const FREIGHT_RATES_COLUMNS_INTERNAL = FREIGHT_RATES_COLUMNS.filter(col => col.internal)
export const FREIGHT_RATES_COLUMNS_BOOLEAN = FREIGHT_RATES_COLUMNS.filter(col => col.exportPipe === 'yesNo').map(col => col.field)

const FREIGHT_RATES_COLUMN_NAMES = mapValues(keyBy(FREIGHT_RATES_COLUMNS, 'field'), 'displayName')
const FREIGHT_RATES_COLUMN_OVERRIDES = {
}

export function columnDefsById(tableKey: TableKey) {
  const displayNames = FREIGHT_RATES_COLUMN_OVERRIDES[tableKey] || {}
  const columnDefs = FREIGHT_RATES_COLUMNS.map(c =>
    displayNames[c.field]
      ? { ...c, displayName: displayNames[c.field] } as ColumnDef
      : c)
  return keyBy(columnDefs, c => c.field)
}

export function columnNames(tableKey: TableKey) {
  return {
    ...FREIGHT_RATES_COLUMN_NAMES,
    ...FREIGHT_RATES_COLUMN_OVERRIDES[tableKey],
  }
}

export const AVAILABLE_COLUMNS: Partial<Record<TableKey, string[]>> = {
  [TableKey.TradingFreightRatesList]: [
    'select',
    'created',
    'until',
    'origin_city',
    'destination_city',
    'carrier_name',
    'max_weight',
    'all_in_rate',
    'rate_currency',
    'transit_time',
    'date_quoted',
    'status',
    'type',
    'orgState',
    'orgCountry',
    'dstState',
    'dstCountry',
    'description',
    'port_loading',
    'port_discharge',
    'commodity',
    'container_size',
    'creator',
    'ellipsisMenu',
    'problem'
  ],
  [TableKey.LogisticsFreightRatesList]: [
    'select',
    'created',
    'until',
    'origin_city',
    'destination_city',
    'carrier_name',
    'max_weight',
    'all_in_rate',
    'rate_currency',
    'transit_time',
    'date_quoted',
    'status',
    'type',
    'orgState',
    'orgCountry',
    'dstState',
    'dstCountry',
    'description',
    'port_loading',
    'port_discharge',
    'commodity',
    'container_size',
    'creator',
    'ellipsisMenu',
    'problem'
  ],
}

export const DEFAULT_COLUMNS: Partial<Record<TableKey, string[]>> = {
  [TableKey.TradingFreightRatesList]: [
    'select',
    'created',
    'until',
    'origin_city',
    'destination_city',
    'carrier_name',
    'max_weight',
    'all_in_rate',
    'rate_currency',
    'transit_time',
    'description',
    'ellipsisMenu',
  ],
  [TableKey.LogisticsFreightRatesList]: [
    'select',
    'created',
    'until',
    'origin_city',
    'destination_city',
    'carrier_name',
    'max_weight',
    'all_in_rate',
    'rate_currency',
    'transit_time',
    'description',
    'ellipsisMenu',
  ],
}

