import { CurrencyPipe } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Cost, DEAL_CONFIRMED, DEAL_DELIVERED, DEAL_DOCS_SENT, DEAL_INVOICED, DEAL_PAID, DEAL_SUBMITTED, DEAL_WITH_CARRIER, DEAL_WITH_RECEIVER, DealView, FileObject, Invoice, InvoiceCost, InvoiceVendorCredit, PREPAYMENT_CREDIT_REFUNDED, VENDOR_CREDIT_PAID, VENDOR_CREDIT_VOIDED, VendorCredit } from '@tradecafe/types/core'
import { getProductByCost, lineItemFromCost } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { map as _map, compact, debounce, find, first, groupBy, identity, isEqual, keyBy, pick, reject, sortBy, sumBy, uniq } from 'lodash-es'
import { BehaviorSubject, ReplaySubject, combineLatest, from, of } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators'
import { FileUploaderService } from 'src/api/file'
import { OperationsApiService } from 'src/api/operations'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { loadCurrencies, selectCurrencyCodes } from 'src/app/store/currencies'
import { MeasurePipe } from 'src/filters/measure.pipe'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { CostsService, canActualizeCost } from 'src/services/data/costs.service'
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import { InvoicesService, getAvailablePrepaymentsForInvoice } from 'src/services/data/invoices.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { VendorCreditsService } from 'src/services/data/vendor-credits.service'
import { FilterDataSelect } from 'src/services/elastic-search'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { TypedFormArray, TypedFormGroup } from 'src/shared/utils/typed-forms'

interface VendorCreditView extends VendorCredit {
  cost: Cost
  name: string // = cost.service
  currency: string
}

export interface InvoiceFormOptions {
  mode: 'create' | 'actualize'
  title: string
  invoice: Partial<Invoice>
}

export interface InvoiceFormResult {
  invoice: Invoice
  costs?: Cost[]
}

export interface InvoiceFormValue {
  deal_id: string
  account: number
  vendor_invoice_id: string
  issued: number
  due: number
  total: number
  currency: string
}


@Component({
  selector: 'tc-invoice-form-v2',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private elastic: DealElasticSearchService,
    private Invoices: InvoicesService,
    private toaster: ToasterService,
    private Files: FilesService,
    private Costs: CostsService,
    private OperationsApi: OperationsApiService,
    private FileUploader: FileUploaderService,
    private DealViewLoader: DealViewLoaderService,
    private VendorCredits: VendorCreditsService,
    @Inject(MAT_DIALOG_DATA) private dialogData: InvoiceFormOptions,
    private dialogRef: MatDialogRef<InvoiceFormComponent, Invoice>,
    private store: Store,
    private measurePipe: MeasurePipe,
    public cd: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe
  ) { super() }

  public vendorInvoiceIdExist: boolean
  private existingVendorInvoices: Set<string> = new Set()
  // modal settings
  readonly title = this.dialogData.title
  readonly mode = this.dialogData.mode
  readonly isDealEditable = !this.dialogData.invoice.deal_id

  // ui state
  inProgress$ = new BehaviorSubject<'create'|'actualize'>(undefined)

  invoiceForm = new FormGroup({
    deal_id: new FormControl(this.dialogData.invoice.deal_id, Validators.required),
    account: new FormControl(parseFloat(this.dialogData.invoice.account) || undefined, Validators.required),
    vendor_invoice_id: new FormControl(this.dialogData.invoice.vendor_invoice_id, Validators.pattern(/^[^,.]+$/)),
    issued: new FormControl(this.dialogData.invoice.issued, Validators.required),
    due: new FormControl(this.dialogData.invoice.due, Validators.required),
    total: new FormControl(this.dialogData.invoice.total, Validators.required),
    currency: new FormControl(this.dialogData.invoice.currency, Validators.required),
  })
  costsForm = new FormArray([]) as TypedFormArray<InvoiceCost>
  creditsForm = new FormArray([]) as TypedFormArray<InvoiceVendorCredit>

  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  currencies$ = this.store.pipe(select(selectCurrencyCodes), waitNotEmpty())

  protected dealIds$ = new ReplaySubject<string[]>(1)
  invoiceDeal$ = new ReplaySubject<DealView>(1)

  partialMargin$ = this.invoiceDeal$.pipe(
    map(deal => deal?.attributes?.actual?.partial_margin),
    distinctUntilChanged())
  invoiceUnits$ = this.dialogData.invoice.attributes.unit
    ? of(this.dialogData.invoice.attributes.unit)
    : this.invoiceDeal$.pipe(
        map(deal => deal?.attributes?.estimated?.weight?.measure_id),
        distinctUntilChanged())

  vendors$ = combineLatest([this.invoiceDeal$, this.accounts$]).pipe(map(([deal, accounts]) => {
    if (!deal) return []
    // TODO: reuse `getDealParties`
    return _map(pick(accounts, compact([
      deal.supplier_id,
      deal.buyer_id,
      ..._map(deal.costs, 'provider'),
      ..._map(deal.invoices, 'account'),
    ]))).map((vendor) => {
      // tslint:disable-next-line: triple-equals
      if (vendor.account == deal.supplier_id) return { ...vendor, hint: 'supplier' }
      // tslint:disable-next-line: triple-equals
      if (vendor.account == deal.buyer_id) return { ...vendor, hint: 'buyer' }
      return { ...vendor, hint: 'service provider' }
    })
  }))
  vendorDealCosts$ = combineLatest([this.invoiceDeal$, replayForm(this.invoiceForm.controls.account)])
    .pipe(map(([{ costs, invoices }, invoiceAccount]) => {
      costs = reject(costs, { type: 'secondary' })
      // TODO: this.costsById = keyBy(costs, 'cost_id')
      const costGroupsByProvider = groupBy(costs, 'provider')
      const providerCosts = invoiceAccount && costGroupsByProvider[invoiceAccount] || []
      const globalCosts = costGroupsByProvider['undefined'] || []
      // WA-4093: filter out associated costs
      return [...globalCosts, ...providerCosts].filter(cost =>
        // always return costs associated with current invoice
        cost.attributes?.associated?.invoice_id === this.dialogData.invoice.invoice_id ||
        // return costs which can be actualized
        canActualizeCost(cost, invoices))
    }))
  private _vendorCredits$ = new ReplaySubject<VendorCreditView[]>(1)

  prepaymentsAvailable$ = combineLatest([
    this.invoiceDeal$,
    replayForm(this.invoiceForm.controls.account),
    replayForm(this.costsForm),
  ]).pipe(map(([deal, account, invoiceCosts]): Invoice[] | false => {
    const invoice = this.dialogData.invoice
    if (invoice.attributes.prepayment || !account || !deal) return false
    const paidPrepayments = getAvailablePrepaymentsForInvoice(account, invoiceCosts, deal.invoices, deal.costs, true)
    return paidPrepayments.length ? paidPrepayments : false
  }))
  prepaymentsAvailableAmount$ = this.prepaymentsAvailable$.pipe(map(prepayments =>
    // NOTE: in theory (not on practice) prepayments might be in different currencies
    prepayments ? sumBy(prepayments, 'total') : 0))

  readonly vendorWeight$ = combineLatest([replayForm(this.costsForm), this.invoiceDeal$])
    .pipe(map(([costsForm, deal]) => {
      const costs = costsForm
        .map(cf => deal.costs.find(c => c.cost_id === cf.cost_id))
        .filter(c => c?.type === 'primary')
      return sumBy(costs, cost => lineItemFromCost(0, cost).quantity)
    }))

  invoiceFiles: FileObject[]
  @ViewChild('inputFile', { static: false })
  inputFile: ElementRef<HTMLInputElement>

  vendorCredits$ = (index: number) =>
    combineLatest([replayForm(this.creditsForm), this._vendorCredits$]).pipe(map(([cf, vcs]) => {
      const alreadyOnTheForm = compact(cf.map((vc, i) => i === index ? undefined : vc.credit_note_id))
      return vcs
        .filter(vc => !alreadyOnTheForm.includes(vc.credit_note_id))
        .map(vc => ({ ...vc, hint: `${vc.credit_note_id} — ${this.currencyPipe.transform(vc.amount, vc.currency)}${vc.currency}` }))
    }))

  costWeight$ = (cost_id: string) =>
    this.invoiceDeal$.pipe(map(deal => {
      const cost = find(deal.costs, { cost_id })
      if (cost?.type !== 'primary') return undefined
      const product = getProductByCost(deal, cost)
      return this.measurePipe.transform(product.supplier.actual_weight || product.supplier.weight, product.supplier.measure_id)
    }), distinctUntilChanged())

  isPrimary$ = (costForm: TypedFormGroup<InvoiceCost>) =>
    combineLatest([this.invoiceDeal$, replayForm<string>(costForm.controls.cost_id)])
    .pipe(map(([deal, cost_id]) => {
      const cost = find(deal.costs, { cost_id })
      return cost?.type === 'primary'
    }), distinctUntilChanged())

  creditCurrency$ = (creditForm: TypedFormGroup<InvoiceVendorCredit>) =>
    combineLatest([replayForm(creditForm.controls.credit_note_id), this._vendorCredits$])
    .pipe(map(([credit_note_id, vendorCredits]) =>
      find(vendorCredits, { credit_note_id })?.currency))

  ngOnInit() {
    const { invoice } = this.dialogData

    invoice.attributes.costs.forEach(cost => this.addCost(cost))
    invoice.attributes.vendor_credits.forEach(credit => this.addVendorCredit(credit))

    if (!this.isDealEditable) {
      this.invoiceForm.controls.deal_id.disable()
    }
    if (invoice.invoice_id) {
      this.invoiceForm.controls.account.disable()
      this.invoiceForm.controls.issued.disable()
      this.invoiceForm.controls.total.disable()
      this.invoiceForm.controls.currency.disable()
    }
    this.costsForm.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(costs => {
      if (invoice.invoice_id || costs.length) {
        this.invoiceForm.controls.currency.disable()
      } else {
        this.invoiceForm.controls.currency.enable()
      }
    })

    combineLatest([ replayForm(this.costsForm), replayForm(this.creditsForm), this.invoiceDeal$ ])
    .pipe(untilComponentDestroyed(this)).subscribe(([costsForm, creditsForm, deal]) => {
      costsForm = costsForm.filter(cf => cf.cost_id)
      if (!costsForm.length) return
      const costs = keyBy(deal.costs, 'cost_id')
      const lineItems: any[] = this.Invoices.buildLineItems({ total: 0, costs: costsForm }, { costs })
      const creditsTotal = sumBy(creditsForm, cf => cf.applyAmount || 0)
      const invoiceTotal = sumBy(lineItems, lineItem => lineItem.quantity * lineItem.price) - creditsTotal
      const invoiceCurrency = first(compact(costsForm.map(cf => costs[cf.cost_id]?.amount?.currency)))
      this.invoiceForm.controls.total.setValue(invoiceTotal)
      this.invoiceForm.controls.currency.setValue(invoiceCurrency)
    })

    replayForm(this.invoiceForm.controls.account).pipe(
      filter(identity),
      switchMap((account: number) => from((async () => {
        const vendorCredits = await this.VendorCredits.getVendorCreditsForAccount(account).then(items =>
          items.filter(vc =>
            !vc.invoice_id &&
            ![VENDOR_CREDIT_PAID, VENDOR_CREDIT_VOIDED, PREPAYMENT_CREDIT_REFUNDED].includes(vc.status)))
        const vendorCreditsCostIds = uniq(compact(_map(vendorCredits, 'cost_id')))
        const costs = await this.Costs.getCostsByIds(vendorCreditsCostIds)
        return vendorCredits.filter(credit => !!costs[credit.cost_id]).map(credit => {
          const cost = costs[credit.cost_id]
          const view: VendorCreditView = {
            ...credit,
            cost: cost,
            name: cost.service,
            currency: cost.amount.currency,
          }
          return view
        })
      })())),
      untilComponentDestroyed(this)).subscribe(this._vendorCredits$)

    combineLatest([
      this._vendorCredits$,
      replayForm(this.invoiceForm.controls.currency),
      this.prepaymentsAvailableAmount$,
      combineLatest([replayForm(this.costsForm), this.invoiceDeal$]).pipe(map(([costsForm, deal]) => {
        costsForm = costsForm.filter(cf => cf.cost_id)
        if (!costsForm.length) return 0
        const costs = keyBy(deal.costs, 'cost_id')
        const lineItems: any[] = this.Invoices.buildLineItems({ total: 0, costs: costsForm }, { costs })
        return sumBy(lineItems, lineItem => lineItem.quantity * lineItem.price)
      })),
    ]).pipe(distinctUntilChanged(isEqual), untilComponentDestroyed(this)).subscribe(([vendorCredits, currency, prepaymentsAvailableAmount, costsTotal]) => {
      if (!costsTotal || this.creditsForm.dirty || invoice.attributes?.vendor_credits?.length) return
      const {selected} = sortBy(vendorCredits.filter(vc => vc.currency === currency), 'created').reduce((r, vc) => {
        if (vc.amount <= r.netPayable) {
          r.selected.push(vc)
          r.netPayable -= vc.amount
        }
        return r
      }, { selected: [], netPayable: costsTotal - prepaymentsAvailableAmount})
      this.creditsForm.clear()
      selected.forEach(vc => {
        this.addVendorCredit(vc)
        this.onVendorCreditChanged(vc, this.creditsForm.controls[this.creditsForm.controls.length - 1])
      })
    })

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCurrencies({}))

    this.Files.getFilesByIds(invoice.attributes.files).then((invoiceFiles) => {
      this.invoiceFiles = invoiceFiles
    }, (err) => {
      console.error('Unable to load files meta information.', err)
      this.toaster.error('Unable to load files meta information.', err)
    })

    if (this.isDealEditable) {
      combineLatest([
        this.elastic.fetchDealFilters({
          query: {
            status: [
              DEAL_SUBMITTED,
              DEAL_CONFIRMED,
              DEAL_INVOICED,
              DEAL_DOCS_SENT,
              DEAL_WITH_CARRIER,
              DEAL_WITH_RECEIVER,
              DEAL_DELIVERED,
              DEAL_PAID,
            ],
          }, columns: ['deal_ids']
        }).pipe(catchError(err => {
          console.error('Unable to load deal ids.')
          this.toaster.error('Unable to load deal ids.')
          throw err
        })).pipe(map(filters => {
          return (filters.deal_ids as FilterDataSelect).options.map(o => o.id as string)
        })),
      ]).pipe(untilComponentDestroyed(this)).subscribe(([deals]) => {
        this.dealIds$.next(deals)
      })
    } else {
      this.DealViewLoader.getDealWith(invoice.deal_id, ['costs', 'invoices']).then((deal) => {
        this.dealIds$.next([deal.deal_id])
        this.invoiceDeal$.next(deal)
      }, (err) => {
        console.error('Unable to load deal data.', err)
        this.toaster.error('Unable to load deal data.', err)
      })
    }

    const searchInvoice = debounce((vendor_invoice_id: string) => {
      if(this.existingVendorInvoices.has(vendor_invoice_id)) return
      this.Invoices.searchInvoices({ vendor_invoice_id, limit:1 }).then(invoices => {
        const [invoice] = invoices
        this.vendorInvoiceIdExist = invoice && invoice.vendor_invoice_id == vendor_invoice_id
        this.markAllFormsAsTouched()
      })
    }, 500)
    this.invoiceForm.controls.vendor_invoice_id.valueChanges
      .pipe(untilComponentDestroyed(this)).subscribe((value) => {
        this.vendorInvoiceIdExist = false
        searchInvoice(value)
      })

      this.invoiceForm.controls.deal_id.valueChanges
      .pipe(untilComponentDestroyed(this)).subscribe(debounce(async (value) => {
        if (!value) return
        const deal = await this.DealViewLoader.searchByDealIdWith(value, ['costs', 'invoices']);
        this.invoiceDeal$.next(deal)
    }, 100))
  }

  addCost(cost?: InvoiceCost) {
    this.costsForm.push(new FormGroup({
      cost_id: new FormControl(cost?.cost_id, Validators.required),
      actual: new FormControl(cost?.actual, [Validators.required, Validators.min(0)]),
      estimated: new FormControl(cost?.estimated),
      partial: new FormControl(cost?.partial),
      currency: new FormControl(cost?.currency),
    }))
  }

  removeCost(index: number) {
    this.costsForm.removeAt(index)
  }

  addVendorCredit(credit?: InvoiceVendorCredit) {
    this.creditsForm.push(new FormGroup({
      credit_note_id: new FormControl(credit?.credit_note_id, Validators.required),
      cost_id: new FormControl(credit?.cost_id),
      amount: new FormControl(credit?.amount),
      applyAmount: new FormControl(credit?.applyAmount),
    }))
  }

  removeVendorCredit(index: number) {
    this.creditsForm.removeAt(index)
    this.checkVendorCreditsTotal()
    this.creditsForm.markAsDirty()
  }

  onCostChanged(cost: Cost, costForm: TypedFormGroup<InvoiceCost>) {
    const partial = cost.type === 'primary' &&
      cost.attributes.actual_buy_weight * cost.attributes.actual_buy_price ||
      cost.amount.total || 0
    costForm.patchValue({
      currency: cost.amount.currency,
      estimated: cost.amount.total || 0,
      partial: partial,
      actual: Math.round(partial * 100) / 100,
    })
  }

  onVendorCreditChanged(credit: VendorCreditView, creditForm: TypedFormGroup<InvoiceVendorCredit>) {
    creditForm.patchValue({
      cost_id: credit?.cost_id,
      amount: credit?.amount,
      applyAmount: credit?.amount,
    })
    // this.selectedVendorCredits = uniqBy(this.selectedVendorCredits, 'credit_note_id')
    this.checkVendorCreditsTotal()
  }

  // fetchExtraDeals() {
  //   return this.DealViewLoader.getDealsWith(['costs', 'invoices']).then(({data}) => {
  //     this.deals.splice(0, this.deals.length, ...data)
  //     this.dealsById = keyBy(data, 'deal_id')
  //     return this.deals
  //   }, (err) => {
  //     console.error('Unable to load deals data.', err)
  //     this.toaster.error('Unable to load deals data.', err)
  //     throw err
  //   })
  // }

  private markAllFormsAsTouched() {
    this.costsForm.markAllAsTouched()
    this.invoiceForm.markAllAsTouched()
    this.creditsForm.markAllAsTouched()
  }

  async create() {
    if (this.inProgress$.value) return
    this.markAllFormsAsTouched()
    if (this.costsForm.invalid || this.invoiceForm.invalid || this.creditsForm.invalid) return

    this.inProgress$.next('create')
    try {
      let invoice: Invoice = await this.createInvoiceNew()
      invoice = await this.uploadInvoiceFile(invoice)

      this.toaster.success('Invoice created successfully.')
      // close dialog even if costs were not updated or file was not uploaded
      this.dialogRef.close()

    } finally {
      this.inProgress$.next(undefined)
    }
  }

  actualize() {
    if (this.inProgress$.value) return
    if (!this.costsForm.length) return
    if (!this.checkVendorCreditsTotal()) return
    this.markAllFormsAsTouched()
    if (this.costsForm.invalid || this.invoiceForm.invalid || this.creditsForm.invalid) return

    this.prepaymentsAvailable$.pipe(take(1)).subscribe(async (prepaymentsAvailable) => {
      this.inProgress$.next('actualize')

      try {
        let invoice: Invoice = undefined
        if (this.mode === 'create') invoice = await this.createInvoiceNew()
        else invoice = this.dialogData.invoice as Invoice
        invoice = await this.uploadInvoiceFile(invoice)

        const invoiceForm = this.invoiceForm.getRawValue()
        const costsForm = this.costsForm.getRawValue()
        const creditsForm = this.creditsForm.getRawValue()

        await this.OperationsApi.actualizeInvoice({
          invoiceId: invoice.invoice_id,
          due: invoiceForm.due,
          costs: costsForm.map(cf => ({ costId: cf.cost_id, amount: cf.actual })),
          vendorCredits: creditsForm.map(cf => ({ vendorCreditId: cf.credit_note_id, amount: cf.applyAmount })),
          prepaymentIds: prepaymentsAvailable ? prepaymentsAvailable.map(p => p.invoice_id) : [],
          vendorInvoiceId: invoiceForm.vendor_invoice_id
        })

        this.toaster.success('Invoice actualized successfully.')
        this.dialogRef.close()

      } catch (err) {
        console.error('Unable to actualize invoice', err)
        this.toaster.error('Unable to actualize invoice.', err)
        throw err

      } finally {
        this.inProgress$.next(undefined)
      }
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  agreeToUseExistingVendorInvoice() {
    this.existingVendorInvoices.add(this.invoiceForm.controls.vendor_invoice_id.value)
    this.vendorInvoiceIdExist = false
  }

  // vendorUnit() {
  //   return this.invoice.attributes.unit || this.invoiceDeal.attributes.estimated.weight.measure_id
  // }

  // wrongUnits(invoiceCost?): boolean {
  //   if (!invoiceCost) return this.invoice.attributes?.costs?.some(cost => this.wrongUnits(cost))
  //   const vendorUnit = this.vendorUnit()
  //   if (!vendorUnit) return false
  //   const costUnit = this.costUnit(invoiceCost)
  //   if (!costUnit) return false
  //   return 1 !== this.Measures.convert(1, vendorUnit, costUnit)
  // }


  private checkVendorCreditsTotal() {
    const creditsTotal = sumBy(this.creditsForm.value, 'amount')
    const invoiceTotal = this.invoiceForm.controls.total.value || 0
    let result = false
    this.prepaymentsAvailableAmount$.pipe(take(1)).subscribe((prepaymentsAvailableAmount) => {
      result = !creditsTotal || (invoiceTotal - prepaymentsAvailableAmount) >= 0
    })
    if (!result) this.toaster.warning('Vendor credits amount is greater than invoice amount!')
    return result
  }

  private async createInvoiceNew() {
    const invoiceForm = this.invoiceForm.getRawValue()
    const costsForm = this.costsForm.getRawValue()
    const creditsForm = this.creditsForm.getRawValue()

    const invoicePayload = {
      deal_id: invoiceForm.deal_id,
      account: invoiceForm.account.toString(),
      due: invoiceForm.due,
      issued: invoiceForm.issued,
      vendor_invoice_id: invoiceForm.vendor_invoice_id,
      vendorCredits: creditsForm.map(cf => ({ vendorCreditId: cf.credit_note_id, amount: cf.applyAmount })),
    }

    return this.OperationsApi.createPayableInvoice(costsForm.length
      ? { ... invoicePayload, costs: costsForm.map(cf => ({ costId: cf.cost_id, amount: cf.actual })) }
      : { ... invoicePayload, amount: invoiceForm.total, currency: invoiceForm.currency },
    ).then(r => r.data)
  }

  private async uploadInvoiceFile(invoice: Invoice) {
    const attachedFile = this.inputFile?.nativeElement.files[0]
    if (!attachedFile) return invoice

    try {
      const file = await this.FileUploader.uploadFile(attachedFile, {
        deal_id: invoice.deal_id,
        visibility: 0,
        attributes: {
          uploaded: true,
        },
      }).toPromise()
      return await this.Invoices.updateInvoice({
        invoice_id: invoice.invoice_id,
        attributes: {
          ...invoice.attributes,
          files: [file.file_id],
        },
      })
    } catch (err) {
      console.error('Unable to upload invoice file', err)
      this.toaster.error('Unable to upload invoice file.', err)
      throw err
    }
  }
}
