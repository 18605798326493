<cdk-virtual-scroll-viewport [itemSize]="55">
  <table mat-table [dataSource]="dataSource" [trackBy]="getRowId"
      matSort matSortStart="desc" matSortDisableClear>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row" [class.clickable]="!!row"></tr>


    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox *ngIf="selectAll"
            matTooltip="Select All"
            (change)="selection.toggleRows($event.checked, dataSource.data)"
            ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader square"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <mat-checkbox *ngIf="selection.canSelect(row)"
              [checked]="selection.isSelected(row)"
              (click)="$event.stopPropagation()"
              (change)="selection.toggleRow(row)"
              ></mat-checkbox>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="dealMenu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        <div *ngIf="!row" class="skeleton-loader circle"></div>
        <button *ngIf="row" mat-icon-button type="button" [matMenuTriggerFor]="dealMenu" [matMenuTriggerData]="{row: row}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="logisticsMenu">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button type="button" (click)="editColumns.next()">
          <mat-icon class="fa fa-bars"></mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        <div *ngIf="!row" class="skeleton-loader circle"></div>
        <button *ngIf="row" mat-icon-button type="button" [matMenuTriggerFor]="logisticsMenu" [matMenuTriggerData]="{row: row}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.company">{{columnNames.company}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.view?.company">
          <div class="text-ellipsis" [title]="row.view.company.name">{{row.view.company.name}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.created">{{columnNames.created}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.created }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="deal_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deal_id">{{columnNames.deal_id}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <!-- <div class="skeleton-loader tiny-line"></div> -->
        </ng-container>
        <ng-container *ngIf="row">
          <a class="text-ellipsis" [title]="row.deal_id"
              [routerLink]="getDealUrl | call:row.deal_id"
              (click)="openDealForm(row)">
            {{row.deal_id}}
          </a>
          <!-- <tc-deal-status [status]="row.view.status" [types]="row.view.deal_type"></tc-deal-status> -->
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="deringer_status_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_status_at">{{columnNames.deringer_status_at}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.viewEx.statusAt }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="deringer_status_by">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_status_by">{{columnNames.deringer_status_by}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <div class="text-ellipsis" [title]="row.viewEx.statusBy">{{row.viewEx.statusBy}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="deringer_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.deringer_status">{{columnNames.deringer_status}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <small class="label text-uppercase text-ellipsis"
              [class.label-danger]="row.viewEx.deringerStatus === 'rejected'"
              [class.label-success]="row.viewEx.deringerStatus === 'sent' || row.viewEx.deringerStatus === 'approved'"
              [class.label-default]="row.viewEx.deringerStatus === 'received'"
              [title]="row.viewEx.deringerStatus">{{ row.viewEx.deringerStatus }}
          </small>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="doc_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.doc_type">{{columnNames.doc_type}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <div class="text-ellipsis" [title]="row.attributes.document_type">{{row.attributes.document_type}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="dropoff_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.dropoff_date">{{columnNames.dropoff_date}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.view.dropoff_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="etd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.etd">{{columnNames.etd}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.view.etd">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.view.etd, useUtc: true }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="eta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.eta">{{columnNames.eta}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.view.eta">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.view.eta, useUtc: true }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="file_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.file_name">{{columnNames.file_name}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
          [class.tc-cell-editable]="!readonly"
          (click)="editName(row, td)">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row && !readonly">
          <div class="text-ellipsis" [title]="row.viewEx.fileName">{{row.viewEx.fileName}}</div>
        </ng-container>
        <ng-container *ngIf="row && readonly">
          <a href="{{row.link}}" target="_blank" class="text-ellipsis"
              style="text-decoration: none !important; color: inherit"
              title="Download {{row.name}}">
            {{row.viewEx.fileName}}
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="format_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.format_name">{{columnNames.format_name}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.viewEx.format">
          <div class="text-ellipsis" [title]="row.viewEx.format.name">
            <i class="fa" [ngClass]="row.viewEx.format.icon"></i> {{row.viewEx.format?.name}}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="note_warning">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.note_warning">{{columnNames.note_warning}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader circle"></div>
        </ng-container>
        <ng-container *ngIf="row?.viewEx?.note_warning">
          <button mat-icon-button type="button" title="Notes" (click)="showDeringerNotes(row)">
            <mat-icon class="fa fa-note-sticky"></mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="onboard_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.onboard_date">{{columnNames.onboard_date}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.view.onboard_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="pickup_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.pickup_date">{{columnNames.pickup_date}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.view.pickup_date, useUtc: true }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="readers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.readers">{{columnNames.readers}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.viewEx?.readers">
          <div class="text-ellipsis" [title]="row.viewEx.readers">{{row.viewEx.readers}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.size">{{columnNames.size}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.viewEx?.size">
          <div class="text-ellipsis" [title]="row.viewEx.size">{{row.viewEx.size}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.user">{{columnNames.user}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({file: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.viewEx.user">
          <div class="text-ellipsis" [title]="row.viewEx.user.fullname">{{row.viewEx.user.fullname}}</div>
        </ng-container>
      </td>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>

<mat-paginator #paginator></mat-paginator>

<mat-menu #dealMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-row="row">
    <button mat-menu-item (click)="download($event, row)">
      <i class="fa fa-fw fa-cloud-arrow-down"></i> Download
    </button>
    <button mat-menu-item *ngIf="!readonly" (click)="sendToDeringer(row)">
      <i class="fa fa-fw fa-cloud-arrow-up"></i> Send To Deringer
    </button>
    <mat-divider *ngIf="!readonly && (row.viewEx.canApprove || row.viewEx.canReject)"></mat-divider>
    <button mat-menu-item *ngIf="!readonly && row.viewEx.canAddNote" (click)="addNote.next(row)">
      <i class="fa fa-fw fa-cloud-arrow-up"></i> Add Note
    </button>
    <button mat-menu-item *ngIf="!readonly && row.viewEx.canApprove" (click)="approveDeringer(row)">
      <i class="fa fa-fw fa-check"></i> Approve
    </button>
    <button mat-menu-item *ngIf="!readonly && row.viewEx.canReject" (click)="rejectDeringer(row)">
      <i class="fa fa-fw fa-times"></i> Reject
    </button>
    <mat-divider *ngIf="row.view.parties.length"></mat-divider>
    <button mat-menu-item *ngFor="let party of row.view.parties"
        [disabled]="!canToggleVisibility(row)"
        (click)="toggleVisibility(row, party)">
      <i class="fa fa-fw {{isFileVisibleTo(row, party) ? 'fa-square-check' : 'fa-square'}}" ></i> {{party.name}}
    </button>
    <mat-divider *ngIf="!readonly"></mat-divider>
    <button mat-menu-item *ngIf="!readonly" [disabled]="!canDelete(row)" (click)="showDeleteItem(row)">
      <i class="fa fa-fw fa-trash-can"></i> Delete
    </button>
  </ng-template>
</mat-menu>

<mat-menu #logisticsMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-row="row">
    <button mat-menu-item (click)="download($event, row)">
      <i class="fa fa-fw fa-cloud-arrow-down"></i> Download
    </button>
    <mat-divider *ngIf="row.parties.length"></mat-divider>
      <button mat-menu-item *ngFor="let party of row.view.parties"
          [disabled]="!canToggleVisibility(row)"
          (click)="toggleVisibility(row, party)">
      <i class="fa fa-fw {{isFileVisibleTo(row, party) ? 'fa-square-check' : 'fa-square'}}"></i> {{party.name}}
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        [disabled]="!canDelete(row)"
        (click)="showDeleteItem(row)">
      <i class="fa fa-trash-can"></i> Delete
    </button>
  </ng-template>
</mat-menu>

<ng-template #cellEpoch let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp">
    <div class="text-ellipsis" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
    <small class="text-ellipsis text-gray" [title]="timestamp | epoch:'LT':useUtc">{{timestamp | epoch:'LT':useUtc}}</small>
  </div>
</ng-template>

<tc-inline-editor #inlineEditor></tc-inline-editor>
