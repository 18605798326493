import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ProductWrapping } from '@tradecafe/types/core'
import { sortBy } from 'lodash-es'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


@Injectable()
@AngularCopy()
export class ProductWrappingApiService {
  constructor(private http: HttpClient) {}

  MaxRequestSize = 10000

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: ProductWrapping[] }>(`${apiUrl}/wrappings`, { params }).toPromise().then(({data = []}) => {
      return {
        data: sortBy(data, 'name'),
      }
    })
  }

  create(data: Partial<ProductWrapping>) {
    return this.http.post<{ data: ProductWrapping }>(`${apiUrl}/wrappings`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: ProductWrapping }>(`${apiUrl}/wrappings/${id}`).toPromise()
  }

  update(id: string, data: Partial<ProductWrapping>) {
    return this.http.put<{ data: ProductWrapping }>(`${apiUrl}/wrappings/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: ProductWrapping }>(`${apiUrl}/wrappings/${id}`).toPromise()
  }
}
