import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal/modal.service'
import { DocumentsOverlayComponent, DocumentsOverlayOptions } from './documents-overlay.component'

@Injectable()
export class DocumentsOverlayService {
  constructor(private modal: ModalService) {}

  showDealDocuments(dealId: string, options?: Partial<DocumentsOverlayOptions>) {
    return this.modal.openLargeSide<DocumentsOverlayComponent, DocumentsOverlayOptions>(DocumentsOverlayComponent, {
      canCreate: false,
      canUpload: true,
      showDeringerCols: false,
      ...options,
      dealId,
    })
  }
}
