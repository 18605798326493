<div mat-dialog-title>
  <div class="tc-matched-offer-title">
    <h2>Matched Offer Details <mat-icon *ngIf="issues$ | async as issues"
        class="fas fa-fw fa-exclamation-triangle" style="color: red"
        [matTooltip]="issues" matTooltipPosition="after"
        ></mat-icon></h2>
    <div class="tc-mo-parties">
      Supplier: {{supplierName$ | async}} <br />
      Buyer: {{buyerName$ | async}}
    </div>
  </div>
  <button mat-raised-button color="add" type="button"
      (click)="sendOffer()"
      [disabled]="canNotSend$ | async">
    <i class="fa fa-fw fa-paper-plane" aria-hidden="true"></i> {{ sendOrResend$ | async }}
  </button>
  <button mat-raised-button color="add" type="button"
      *ngIf="canConfirm$ | async"
      (click)="confirmOffer()"
      [class.tc-invalid-badge]="isInvalid$ | async"
      [class.tc-dirty-badge]="isDirty$ | async"
      [matTooltip]="fieldsReport$ | async" matTooltipClass="pre-tooltip" matTooltipShowDelay="10000"
      [disabled]="(inProgress$ | async) || (isFinalized$ | async)">
    <i class="fa fa-fw" aria-hidden="true"
        [class.fa-check]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
        ></i> Confirm
  </button>
  <button mat-raised-button color="primary" type="button"
      (click)="saveOffer()"
      [class.tc-invalid-badge]="isInvalid$ | async"
      [class.tc-dirty-badge]="isDirty$ | async"
      [matTooltip]="fieldsReport$ | async" matTooltipClass="pre-tooltip" matTooltipShowDelay="10000"
      [disabled]="!(isBiddable$ | async) && ((inProgress$ | async) || (isFinalized$ | async))">
    <i class="fa fa-fw" aria-hidden="true"
        [class.fa-check]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
        ></i> Save
  </button>
  <button mat-button color="link" type="button" (click)="cancel()">
    Cancel
  </button>
</div>

<div class="tc-status-bar">
  <div [matTooltip]="offerTooltip$ | async" matTooltipClass="huge-tooltip pre-tooltip" matTooltipPosition="below">
    Supplier Offer #: <span [title]="supplierOfferId">{{supplierOfferId | fakeId:'OFFER-'}}</span><br>
    Buyer Offer #: <span [title]="matchedOfferId">{{matchedOfferId | fakeId:'OFFER-'}}</span><br>
  </div>
  <mat-form-field>
    <mat-label>Status</mat-label>
    <input matInput readonly [value]="status$ | async">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Margin (%)</mat-label>
    <input matInput readonly [class.tc-negative-margin]="(marginCad$ | async) < 0" [value]="margin$ | async">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Margin (CAD)</mat-label>
    <input matInput readonly [class.tc-negative-margin]="(marginCad$ | async) < 0"
        [value]="(marginCad$ | async) ? (marginCad$ | async | currency:'CAD') : '—'">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Revenue (CAD)</mat-label>
    <input matInput readonly [class.tc-negative-margin]="(revenueCad$ | async) < 0"
        [value]="(revenueCad$ | async) ? (revenueCad$ | async | currency:'CAD') : '—'">
  </mat-form-field>
</div>

<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <span [class.tc-invalid]="detailsTabInvalid$ | async">Offer Details</span>
      <tc-badge-invalid-2 [errors]="detailsTabInvalid$ | async"></tc-badge-invalid-2>
    </ng-template>
    <tc-matched-offer-details
        [matchedOffer]="dialogData.matchedOffer"
        [moForm]="moForm"
        [costsForm]="costsForm"
        [fxRates]="fxRates"
        [shipmentRates$]="shipmentRates$"
        (updateCost)="updateCost($event)"
        (removeCost)="removeCost($event)"
        ></tc-matched-offer-details>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span [class.tc-invalid]="specsTabInvalid$ | async">Product Specifications</span>
      <tc-badge-invalid-2 [errors]="specsTabInvalid$ | async"></tc-badge-invalid-2>
    </ng-template>
    <tc-matched-offer-specs [moForm]="moForm"></tc-matched-offer-specs>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span [class.tc-invalid]="noTertiaryCosts$ | async">Logistics Costs</span>
      <tc-badge-invalid-2 [errors]="noTertiaryCosts$ | async"></tc-badge-invalid-2>
    </ng-template>
    <header class="tc-logistics-costs-header" *ngIf="!(isFinalized$ | async)">
      <button mat-raised-button color="primary" type="button" (click)="showAddCost()">
        <i class="fa fa-plus-square"></i> Add New
      </button>
      <button mat-raised-button color="primary" type="button" (click)="showAddFreight()">
        <i class="fa fa-plus-square"></i> Add Freight
      </button>
    </header>
    <tc-costs-list [types]="['tertiary']"
        [readonly]="isFinalized$ | async"
        [units]="moForm.controls.supplierMeasureId.value"
        [matchedOffer]="dialogData.matchedOffer"
        [costsForm]="costsForm"
        [fxRates]="fxRates"
        fxRatesRange="spot"
        [shipmentRates$]="shipmentRates$"
        (updateCost)="updateCost($event)"
        (removeCost)="removeCost($event)"
        [displayColumns]="['service', 'provider', 'createdBy', 'estAmt', 'fxRate', 'estAmtCAD', 'origin', 'destination', 'portLoading', 'portDischarge', 'dealSource', 'matchedOfferSource', 'ellipsisMenu']"
        ></tc-costs-list>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>Shipping</ng-template>
    <header class="tc-segments-header">
      <button mat-raised-button color="primary" type="button" (click)="showAddSegment()">
        <i class="fa fa-plus-square" aria-hidden="true"></i> Add Segment
      </button>
    </header>
    <tc-segments-list-v2
        [segmentsForm]="segmentsForm"
        [fxRates]="fxRates"
        (updateSegment)="showUpdateSegment($event)"
        (removeSegment)="removeSegment($event)"
        ></tc-segments-list-v2>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>Default Costs</ng-template>
    <tc-costs-list [types]="['secondary']"
        [readonly]="isFinalized$ | async"
        [units]="moForm.controls.supplierMeasureId.value"
        [matchedOffer]="dialogData.matchedOffer"
        [costsForm]="costsForm"
        [fxRates]="fxRates"
        fxRatesRange="spot"
        (updateCost)="updateCost($event)"
        (removeCost)="removeCost($event)"
        [displayColumns]="['service', 'estAmt', 'fxRate', 'estAmtCAD']"
        ></tc-costs-list>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>Logs</ng-template>
    <tc-audit-log
        [filter]="{ matched_offer_id: matchedOfferId }"
        [logs]="dialogData.matchedOffer.logs"
        [tableIdentity]="TableKey.MatchedOfferAudit"
        ></tc-audit-log>
  </mat-tab>
</mat-tab-group>
