import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { CountriesService } from 'src/pages/admin/settings/admin-setting-geographic/countries.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadCountries, loadCountriesFailure, loadCountriesSuccess } from './country.actions'

@Injectable()
export class CountryEffects {
  constructor(
    private actions$: Actions,
    private countries: CountriesService,
    private toaster: ToasterService,
  ) {}

  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType(loadCountries),
    switchMap(() =>
      from(this.countries.getCountries()).pipe(
        map(countries => loadCountriesSuccess({
          countries: countries.data,
        })),
        catchError(error => {
          console.error('Unable to fetch countries', error)
          this.toaster.error('Unable to fetch countries', error)
          return of(loadCountriesFailure({ error }))
        })))))
}
