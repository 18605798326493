import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Carrier, Country, LocationObject, ShipmentRate, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { omit } from 'lodash-es'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { FiltersFormGroup } from 'src/services/table-utils'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { FreightRatesDataSource } from '../freight-rates-data-source'
import { FreightRatesService } from '../freight-rates.service'
import { FreightRatesLogOverlayService } from '../log-overlay/freight-rates-log-overlay.service'
import { AVAILABLE_COLUMNS } from './freight-rates-list.columns'

export interface FreightRateRow extends ShipmentRate {
  carrier?: DeepReadonly<Carrier>
  destination?: DeepReadonly<LocationObject>
  destinationCountry?: DeepReadonly<Country>
  destinationStateName?: string
  origin?: DeepReadonly<LocationObject>
  originCountry?: DeepReadonly<Country>
  originStateName?: string
  statusLabel?: string
  creator?: DeepReadonly<User>
}

@Component({
  selector: 'tc-freight-rates-list',
  templateUrl: './freight-rates-list.component.html',
  styleUrls: ['./freight-rates-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightRatesListComponent extends OnDestroyMixin {
  constructor(
    private FreightRates: FreightRatesService,
    private Toaster: ToasterService,
    private confirmModal: ConfirmModalService,
    private freightRatesLogOverlay: FreightRatesLogOverlayService,
    private readonly NotesOverlay: NotesOverlayService,
  ) { super() }

  @Input()
  filtersForm: FiltersFormGroup<any>

  @Input() dataSource: FreightRatesDataSource

  @Input() displayColumns = AVAILABLE_COLUMNS
  @Input() editable: boolean

  @Output() editColumns = new EventEmitter<void>()
  @Output() rowUpdated = new EventEmitter<void>()

  // table settings
  selection = new TableSelection<FreightRateRow>('rate_id')

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator)}

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.setSort(sort) }

  // row-level actions
  protected showUpdateItem = async (item: ShipmentRate) => {
    const updatedItem = await this.FreightRates.showFormModal(item, {
      title: 'Update Freight Rate',
      mode: 'update',
    })

    if(!updatedItem) return;
    this.rowUpdated.emit();
  }

  protected showCopyItem = async (item: ShipmentRate) => {
    const createdItem = await this.FreightRates.showFormModal(omit(item, ['rate_id']), {
      title: 'Copy Freight Rate',
      mode: 'create',
      isCopy: true,
      single: true,
    })

    if(!createdItem) return;
    this.rowUpdated.emit();
  }

  protected showFreightRateNotes(rate: ShipmentRate) {
    return this.NotesOverlay.showFreightRateNotes(rate)
  }

  protected showArchiveItem = async (item) => {
    await this.confirmModal.show({
      title: 'Archive this freight rate?',
      description: 'Are you sure you want to archive this freight rate?',
    })

    try {
      await this.FreightRates.update({ ...item, archived: true })
      this.Toaster.success('Freight Rate archived successfully.')
      this.rowUpdated.emit();
    } catch(err) {
      this.Toaster.error('Unable to archive freight rate.', err)
    }
  }

  protected showLogs(element) {
    this.freightRatesLogOverlay.showLogs(element.rate_id)
  }
}
