<mat-form-field>
  <mat-label>{{placeholder}}</mat-label>
  <mat-icon *ngIf="icon" matPrefix class="fa fa-calendar"></mat-icon>
  <mtx-datetimepicker #picker [type]="type" mode="portrait"></mtx-datetimepicker>
  <input matInput autocomplete="off" [min]="min" [max]="max"
      [mtxDatetimepicker]="picker"
      [formControl]="internalControl"
      (focus)="!readonly && picker.open()">
  <button mat-icon-button type="button" class="tc-epoch-clear"
      *ngIf="!readonly && ctrl.value"
      [disabled]="ctrl.disabled"
      (click)="ctrl.reset(); $event.stopPropagation()">
    <mat-icon class="fa fa-times"></mat-icon>
  </button>
  <mtx-datetimepicker-toggle *ngIf="!readonly && !ctrl.value" [for]="picker" matSuffix></mtx-datetimepicker-toggle>
</mat-form-field>
