import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Currency } from '@tradecafe/types/core'
import { ShipmentRateApiService } from 'src/api/shipment-routing/shipment-rate'
import { dayjs } from 'src/services/dayjs'
import { ToasterService } from 'src/shared/toaster/toaster.service'

@Component({
  selector: 'tc-copy-freight-rates-form',
  templateUrl: './copy-freight-rates-form.component.html',
  styleUrls: ['./copy-freight-rates-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyFreightRatesFormComponent {

  constructor(
    private toaster: ToasterService,
    private dialogRef: MatDialogRef<CopyFreightRatesFormComponent>,
    private ShipmentRateApi: ShipmentRateApiService,
    @Inject(MAT_DIALOG_DATA) private dialogData: {
      rateIds: string[]
      currency: Currency
    },
  ) {}

  batchChange = new UntypedFormGroup({
    sign: new UntypedFormControl(-1, Validators.required),
    amount: new UntypedFormControl(0, Validators.required),
    dateQuoted: new UntypedFormControl(dayjs().unix(), Validators.required),
    validUntil: new UntypedFormControl(dayjs().add(1, 'y').unix(), Validators.required),
    retireNow: new UntypedFormControl(false),
  })

  currencyCode = this.dialogData.currency.code

  async submit() {
    this.batchChange.markAllAsTouched()
    if (!this.batchChange.valid) return
    const form = this.batchChange.value
    try {
      const { data } = await this.ShipmentRateApi.clone({
        rate_ids: this.dialogData.rateIds,
        change: form.sign * form.amount,
        date_quoted: form.dateQuoted,
        until: form.validUntil,
        retire_now: form.retireNow,
      })
      this.dialogRef.close(data)
      this.toaster.success('Rates saved successfully')
    } catch (err) {
      this.toaster.error('Changing rates failed', err)
    }
  }
}
