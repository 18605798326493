import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { DealViewRaw } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject, Observable, merge, of } from 'rxjs'
import { distinctUntilChanged, mapTo, take } from 'rxjs/operators'
import { saveDealForm, saveDealFormFailure, saveDealFormSuccess } from 'src/app/store/deal-view.actions'
import { loadUsers } from 'src/app/store/users'
import { DealFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'


export interface ShippingDetailsOverlayOptions {
  dealId: string
  isReadonly?: boolean
  dealForm: DealFormGroup
  dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>
}

@Component({
  selector: 'tc-shipping-details-overlay',
  templateUrl: './shipping-details-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingDetailsOverlayComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ShippingDetailsOverlayComponent>,
    private actions$: Actions,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private dialogData: ShippingDetailsOverlayOptions,
  ) { super() }

  // internal state
  readonly inProgress$ = new BehaviorSubject(false)
  readonly dealId$ = of(this.dialogData.dealId)
  readonly isReadonly = this.dialogData.isReadonly
  readonly dealForm = this.dialogData.dealForm
  readonly dealViewRaw$ = this.dialogData.dealViewRaw$

  ngOnInit() {
    this.store.dispatch(loadUsers({}))
    merge(
      this.actions$.pipe(ofType(saveDealForm), mapTo('save')),
      this.actions$.pipe(ofType(saveDealFormSuccess, saveDealFormFailure), mapTo(undefined)),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(this.inProgress$)
  }

  saveDeal() {
    if (this.inProgress$.value) return
    this.dealForm.markAllAsTouched()
    this.dealForm.updateValueAndValidity()
    if (!this.dealForm.valid) return
    this.dealViewRaw$.pipe(take(1)).subscribe(dv =>
      this.store.dispatch(saveDealForm({ dv, dealForm: this.dealForm.serialize() })))
  }

  cancel() {
    this.dialogRef.close()
  }
}
