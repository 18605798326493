import { Injectable } from '@angular/core'
import { DEAL_CANCELED, DEAL_DELIVERED, DEAL_INVOICED, Deal, DealStatus, DealView, Invoice } from '@tradecafe/types/core'
import { isInvoicePaid } from '@tradecafe/types/utils'
import { merge } from 'lodash-es'
import { DealApiService } from 'src/api/deal'
import { OperationsApiService } from 'src/api/operations'
import { environment } from 'src/environments/environment'
import { DealViewService } from 'src/services/data/deal-view.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { DealValidationService } from '../actions/deal-validation.service'
import { DealViewPermissionsService } from './deal-view-permissions.service'


/**
 * Deals service
 *
 * @export
 */
@Injectable()
export class DealViewSaverService {

  constructor (
    private toaster: ToasterService,
    private DealApi: DealApiService,
    private OperationsApi: OperationsApiService,
    private DealViewSvc: DealViewService,
    private Invoices: InvoicesService,
    private DealValidation: DealValidationService,
    private DealViewPermissions: DealViewPermissionsService,
  ) { }

  /**
   * Update deal status
   *
   * @param {any} deal
   * @param {any} status
   */
  async updateDealStatus(deal: DealView, status: string) {
    const sameStatus = deal.status === status
    const previousStatus = DealStatus[deal.status].num > DealStatus[status].num && status !== DEAL_CANCELED
    const canChangeStatus = !sameStatus && (!previousStatus || this.DealViewPermissions.canChangeStatusTo(deal, status))
    // prevent setting status to the same value as it is right now
    // prevent status rollback (eg. delivered deal should never be set to "invoiced" again)
    if (canChangeStatus) {
      if (status === DEAL_INVOICED) {
        await this.DealValidation.checkBuyerCredit(deal, `Deal # ${deal.deal_id} is above Buyers credit limit. Get credit override from management.`, 'in_transit')
      }
      const {data: stored} = await this.DealApi.update(deal.deal_id, {status})
      // TODO: fetch bids and offers, BE might change their status
      // NOTE we don't use yet as of March'2019
      merge(deal, this.DealViewSvc.createDealView(stored))
    }
    if (status === DEAL_INVOICED) {
      if (environment.enableBrokerageDeals && deal.deal_type === 'brokerage') {
        const invoice = await this.Invoices.createBrokerageInvoiceFor(deal)
        deal.invoices.push(invoice as Invoice)
      } else {
        await this.Invoices.createBuyerInvoiceFor(deal)
      }
    }
    if (canChangeStatus) {
      await this.tryToCloseDeal(deal)
    }
  }

  // WA-2881: try to close this deal
  async tryToCloseDeal(deal: DealView | Deal) {
    if (deal.status !== DEAL_DELIVERED) return
    if (environment.enableBrokerageDeals && deal.deal_type === 'brokerage') {
      const brokerageInvoice = await this.Invoices.getBrokerageInvoiceForDeal(deal.deal_id)
      if (!isInvoicePaid(brokerageInvoice)) return
    } else {
      const buyerInvoice = await this.Invoices.getBuyerInvoiceForDeal(deal.deal_id)
      if (!isInvoicePaid(buyerInvoice)) return
    }
    await this.OperationsApi.closeDeal(deal.deal_id)
    this.toaster.success(`Deal ${deal.deal_id} is closed successfully!`)
  }
}
