import * as dayjs_ from 'dayjs' // NOTE: this must be the only place where we import dayjs
import * as durationPlugin from 'dayjs/plugin/duration'
import * as isBetweenPlugin from 'dayjs/plugin/isBetween'
import * as localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import * as relativeTimePlugin from 'dayjs/plugin/relativeTime'
import * as utcPlugin from 'dayjs/plugin/utc'
import { isDate, isNumber, isString } from 'lodash-es'

export const dayjs = dayjs_

dayjs.extend(durationPlugin)
dayjs.extend(utcPlugin)
dayjs.extend(relativeTimePlugin)
// TODO: reduce initial bundle. import some plugins with a component or service.
dayjs.extend(isBetweenPlugin)
dayjs.extend(localizedFormatPlugin)

dayjs['en'].formats = {
  l: 'MM/DD/YYYY',
  LTS: 'h:mm:ss A',
  LT: 'h:mm A',
  L: 'DD-MMM-YYYY',
  LL: 'DD – MMM – YYYY',
  LLL: 'MMMM D, YYYY',
  LLLL: 'ddd MMM D, YYYY',
}

export function fromEpoch(value: any): dayjs_.Dayjs {
  if (dayjs.isDayjs(value)) return     value
  if (isDate(value)) return            dayjs(value)
  if (isNumber(value) && value) return dayjs(value * 1000)
  if (isString(value)) return          dayjs(new Date(value))
  return undefined
}

export function fromEpochUTC(value: any): dayjs_.Dayjs {
  if (dayjs.isDayjs(value))     return value.utc()
  if (isDate(value))            return dayjs(value).utc()
  if (isNumber(value) && value) return dayjs.utc(value * 1000)
  if (isString(value))          return dayjs.utc(new Date(value))
  return undefined
}
