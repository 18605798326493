import { Injectable } from '@angular/core'
import { buildRequirement, buildValueGetter, ContextData, getDependencies, getValue } from '@tradecafe/documentlibrary-tags'
import { DealProduct, DealProductBatch, DealView } from '@tradecafe/types/core'
import { environment } from 'src/environments/environment'

type Targs = [ContextData] | [DealView, ContextData] | [DealView, DealProduct, ContextData] | [DealView, DealProduct, DealProductBatch, ContextData]

function addStaffIds<
T extends [ContextData]
    | [DealView, ContextData]
    | [DealView, DealProduct, ContextData]
    | [DealView, DealProduct, DealProductBatch, ContextData]
>(args: T): T {
  const data = args[args.length - 1] as ContextData
  args[args.length - 1] = <ContextData>{
    ...data,
    STAFF_ACCOUNT: environment.tradecafeAccount,
    STAFF_ACCOUNT_US: environment.tradecafeUSAccount,
  }
  return args
}

@Injectable()
export class DocumentRequirementsService {
  /**
   * Build identifier model for one exact identifier key
   *
   * @private
   * @param {*} identifier
   * @param {*} args
   * @returns
   */
  buildRequirement(identifier: string, ...args: Targs) {
    return buildRequirement(identifier, ...addStaffIds(args))
  }

  buildValueGetter(fields) {
    const getter = buildValueGetter(fields)
    return (identifier: string, ...args: Targs) => getter(identifier, ...addStaffIds(args))
  }

  getValue(identifierKey, ...args: Targs) { // <-- we provide it as getValue in *mappings.js
    return getValue(identifierKey, ...addStaffIds(args))
  }

  getDependencies(identifiers, deal, data) {
    return getDependencies(identifiers, deal, ...addStaffIds([data]))
  }
}
