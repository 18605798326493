<tc-epoch-field format="l" placeholder="Ant. term date"
    [ctrl]="dealForm.controls.details.controls.brokerageTermDate"
    ></tc-epoch-field>
<mat-form-field>
  <mat-label>Ant. due date</mat-label>
  <input readonly matInput [value]="dealForm.controls.details.brokerageAntDueDate$ | async | epoch:'l':'utc'">
</mat-form-field>
<tc-epoch-field format="l" placeholder="Ant. collection date"
    [ctrl]="dealForm.controls.details.controls.brokerageCollectionDate"
    ></tc-epoch-field>

<mat-form-field>
  <mat-label>Act. Term Date</mat-label>
  <input readonly matInput xx-value="actualTermDate$ | epoch:'l'">
</mat-form-field>
<mat-form-field>
  <mat-label>Average Days to Due Date</mat-label>
  <input readonly matInput [value]="avgToDueDate$ | async | number:'1.2-2'">
</mat-form-field>
<mat-form-field>
  <mat-label>Act. Collection Date</mat-label>
  <input readonly matInput [value]="actualCollectionDate$ | async | epoch:'l'">
</mat-form-field>
