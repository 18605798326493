import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { PaymentReference } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as PaymentReferenceActions from './payment-reference.actions'

export const paymentReferencesFeatureKey = 'paymentReferences'
export interface PaymentReferencesState extends EntityState<DeepReadonly<PaymentReference>> {
}

export const selectId = (paymentReference: PaymentReference) => paymentReference.payment_reference_id
export const adapter = createEntityAdapter<DeepReadonly<PaymentReference>>({ selectId })

const initialState: PaymentReferencesState = adapter.getInitialState({
})

export const paymentReferencesReducer = createReducer(
  initialState,
  on(PaymentReferenceActions.loadPaymentReferencesSuccess,
    (state, action) =>
      adapter.upsertMany(action.paymentReferences, state)),
)

