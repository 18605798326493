<div class="title header-row" fxLayout="row" fxLayoutAlign="space-between center">
  <h2 class="md-title" *ngIf="!offer.offer_id">{{options.title}}</h2>
  <div *ngIf="offer.offer_id" fxLayout="column">
    <h2 class="md-title" fxFill>Supplier Offer Details</h2>
    <div><span>Supplier Offer #:</span> <span [title]="offer.offer_id | fakeId:'OFFER-'">{{offer.offer_id | fakeId:'OFFER-'}}</span></div>
    <p *ngIf="offer.clones?.length">This is the parent offer of clone series</p>
  </div>

  <div class="tc-buttons">
    <button mat-button *ngIf="offer.offer_id" color="link" type="button" (click)="dismiss()">Close</button>
    <button mat-button *ngIf="!offer.offer_id" color="link" type="button" (click)="dismiss()">Cancel</button>
    <button mat-raised-button *ngIf="!offer.offer_id && !options.cloneOffer" color="primary" type="button"
        (click)="saveOffer(offer)" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"></i> Create
    </button>
    <button mat-raised-button *ngIf="!offer.offer_id && options.cloneOffer" color="primary" type="button"
        (click)="cloneOffer()" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"></i> Proceed
    </button>
    <button mat-raised-button *ngIf="options.addMOs" color="primary" type="button" (click)="attachMatchOffers()"
        [disabled]="canAttachMatchOffers$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"></i> Attach MOs
    </button>
  </div>
</div>
<div class="header-row buyer-filter" fxLayout="row" fxLayoutAlign="space-between center">
  <mat-form-field *ngIf="offer.offer_id">
    <mat-label>Status</mat-label>
    <input matInput autocomplete="off" [value]="OfferStatus[status].name || status" readonly="readonly">
  </mat-form-field>

  <tc-select-search placeholder="Dest. Country" [multiple]="true"
      *ngIf="canEditOffer"
      [group]="offerForm" ctrlName="countries"
      [items]="countries$ | async" bindValue="code" bindLabel="name"
      ></tc-select-search>
  <tc-select-search placeholder="Dest. Location" [multiple]="true"
      *ngIf="canEditOffer"
      [group]="offerForm" ctrlName="locations"
      [items]="locations$ | async" bindValue="location_id" bindLabel="name"
      ></tc-select-search>
  <tc-select-search placeholder="Buyers" [multiple]="true"
      *ngIf="canEditOffer"
      selectedTextFormat="count>1"
      [group]="offerForm" ctrlName="buyers"
      [items]="selectableBuyers$ | async" bindValue="account" bindLabel="name"
      ></tc-select-search>

  <mat-form-field *ngIf="offer.offer_id && !isFinalized(offer)">
    <mat-label>{{isExpired(offer) ? 'Expired' : 'Expires'}}</mat-label>
    <input matInput autocomplete="off" readonly="readonly" [value]="offer.expire | epochTimeAgo">
  </mat-form-field>

  <mat-form-field>
    <mat-label># of Loads</mat-label>
    <input matInput type="number" step="1" autocomplete="off" [formControl]="offerForm.controls.loads">
  </mat-form-field>

  <tc-select-search *ngIf="!offer.offer_id"
      placeholder="Valid For"
      [group]="offerForm" ctrlName="expirationTime"
      [readonly]="offer.offer_id"
      [items]="expirationTimes" bindValue="value" bindLabel="name"
      ></tc-select-search>

  <div class="input-separator"></div>

  <mat-checkbox class="tc-biddable" [formControl]="offerForm.controls.biddable">Allow bidding</mat-checkbox>
</div>

<mat-tab-group>
  <mat-tab label="Details">
    <div class="tc-deal-parties" gdColumns="repeat(4, minmax(0, 1fr))" gdGap="0 15px">
      <div gdColumn="span 2">
        <tc-select-search placeholder="Supplier"
            [group]="offerForm" ctrlName="account"
            [readonly]="offer.offer_id"
            [items]="suppliers$ | async" bindValue="account" bindLabel="name"
            ></tc-select-search>
      </div>
      <mat-form-field>
        <mat-label>Supplier ref. number</mat-label>
        <input matInput type="text" autocomplete="off" [readonly]="offer.offer_id" [formControl]="offerForm.controls.attributes.controls.supplier_ref">
      </mat-form-field>
      <tc-select-search placeholder="Country of origin"
          [group]="offerForm" ctrlName="country"
          [readonly]="offer.offer_id"
          [items]="countries$ | async" bindValue="code" bindLabel="name"
          ></tc-select-search>
    </div>
    <div class="tc-product-info" gdColumns="repeat(4, minmax(0, 1fr))" gdGap="0 15px">
      <tc-product-field gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)" gdGap="0 15px" gdColumn="span 3"
          [group]="offerForm" ctrlName="product"
          [readonly]="!!offer.offer_id"
          [protein]="true"
          ></tc-product-field>
      <tc-select-search placeholder="Item specification"
          [group]="offerForm.controls.attributes" ctrlName="item_type_id"
          [readonly]="offer.offer_id"
          [items]="itemTypes$ | async" bindValue="item_type_id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search placeholder="Unit"
          [group]="offerForm.controls.weight" ctrlName="unit"
          [readonly]="offer.offer_id"
          [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
          ></tc-select-search>
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" step="1" autocomplete="off" [formControl]="offerForm.controls.weight.controls.amount" [readonly]="offer.offer_id">
        <mat-error *ngIf="offerForm.controls.weight.controls.amount.touched && offerForm.controls.weight.controls.amount.errors?.pattern">
          Only two decimal places are allowed
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ind. weight</mat-label>
        <input matInput readonly [value]="(offerForm.getRawValue().weight.amount) | measure:offerForm.getRawValue().weight.unit">
      </mat-form-field>
      <div>
        <mat-checkbox [formControl]="offerForm.controls.wo_export_docs">W/O export docs</mat-checkbox>
        <button mat-button style="padding: 0;" color="link" class="btn-special-instructions" type="button" (click)="addSupplierInstructions()" *ngIf="!offer.offer_id">
          <i class="fa fa-20 fa-file-plus" alt="icon"></i>&nbsp;
          <span *ngIf="!hasSupplierInstructions()">ADD SUPPLIER SPECIAL INSTRUCTIONS</span>
          <span *ngIf="hasSupplierInstructions()">EDIT SUPPLIER SPECIAL INSTRUCTIONS</span>
        </button>
      </div>
      <mat-form-field>
        <mat-label>Price</mat-label>
        <input matInput type="number" step="0.0001" autocomplete="off" [formControl]="offerForm.controls.price" [readonly]="offer.offer_id">
      </mat-form-field>
      <tc-select-search placeholder="Currency"
          [group]="offerForm" ctrlName="currency"
          [readonly]="offer.offer_id"
          [items]="currencies$ | async" bindValue="code" bindLabel="code"
          ></tc-select-search>
      <mat-form-field>
        <mat-label>Default margin override (%)</mat-label>
        <input matInput type="number" min="0" [formControl]="offerForm.controls.grossMarginPercents" [readonly]="offer.offer_id">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ind. price</mat-label>
        <input matInput readonly [value]="(offerForm.getRawValue().price) | currency:offerForm.getRawValue().currency:'symbol-narrow':'1.4' | measure:offerForm.getRawValue().weight.unit">
      </mat-form-field>
    </div>
    <div class="tc-deal-delivery" *ngIf="enableRouteService" gdColumn="span 8" gdGap="0 15px">
        <div id="delivery-preference-label">Delivery Preference</div>
        <mat-radio-group
          aria-labelledby="delivery-preference-label"
          [formControl]="offerForm.controls.attributes.controls.delivery_preference"
        >
          <mat-radio-button value="time">Fastest</mat-radio-button>
          <mat-radio-button value="cost">Cheapest</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="tc-deal-parties" gdColumns="repeat(8, minmax(0, 1fr))" gdGap="0 15px">
      <tc-select-search placeholder="Pickup" gdColumn="span 4"
          [group]="offerForm" ctrlName="pickup"
          [readonly]="offer.offer_id"
          [items]="locations$ | async" bindValue="location_id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search placeholder="Contact" gdColumn="span 2"
          [multiple]="true"
          [group]="offerForm.controls.attributes" ctrlName="supplier_user_ids"
          [readonly]="offer.offer_id"
          [items]="supplierContacts$ | async" bindValue="user_id" bindLabel="fullname" bindHint="primaryemail"
          ></tc-select-search>
      <tc-select-search placeholder="Buying trader" gdColumn="span 2"
          [group]="offerForm.controls.attributes" ctrlName="trader_user_id_supplier"
          [readonly]="offer.offer_id"
          [items]="buyingTraders$ | async" bindValue="user_id" bindLabel="fullname" bindHint="primaryemail"
          ></tc-select-search>
      <tc-address-field placeholder="Establishment #" [multiple]="true" gdColumn="span 3" [readonly]="!!offer.offer_id"
          [group]="offerForm.controls.attributes" ctrlName="establishments"
          [pickerOptions]="establishmentAddressOptions$ | async">
        <div *tcAddressFieldValue="let address = address">
          <div>{{ address.establishment}}<span *ngIf="address.establishment"> – </span>{{ address.name }}</div>
          <div class="text-gray">{{ address | address:['street1', 'city']}}</div>
        </div>
      </tc-address-field>
      <tc-select-search placeholder="Supplier Incoterm" gdColumn="span 2"
          [readonly]="offer.offer_id"
          [group]="offerForm" ctrlName="incoterm"
          [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
          ></tc-select-search>
      <tc-select-search placeholder="Supplier incoterm location" gdColumn="span 3"
          [readonly]="offer.offer_id"
          [group]="offerForm.controls.attributes" ctrlName="incoterm_location"
          [items]="locations$ | async" bindValue="location_id" bindLabel="name"
          ></tc-select-search>
      <tc-epoch-range-field placeholder="Shipment date range" gdColumn="span 4" [min]="minDate" [useUtc]="true"
          [readonly]="!!offer.offer_id"
          [formGroup]="offerForm" startCtrlName="ship_start" endCtrlName="ship_end"
          ></tc-epoch-range-field>
      <mat-form-field gdColumn="span 4">
        <mat-label>Payment terms</mat-label>
        <input matInput readonly [value]="supplierPaymentTerms$ | async">
      </mat-form-field>
      <div gdColumn="span 8">
        <button mat-button *ngIf="!offer.offer_id" type="button" color="link"
        (click)="addTertiaryCost()">
          <mat-icon class="fa fa-plus"></mat-icon> ADD LOGISTICS COSTS
        </button>
      </div>
    </div>
    <table mat-table fixedLayout *ngIf="offerForm.controls.costs.value?.length"
        [dataSource]="offerForm.controls.costs.value"
        matSort matSortActive="name" matSortDisableClear>
      <ng-container matColumnDef="service">
        <th mat-header-cell *matHeaderCellDef>Service</th>
        <td mat-cell *matCellDef="let element">{{element.service}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef>Provider</th>
        <td mat-cell *matCellDef="let element">{{(accountsDictionary$ | async)?.[element.provider]?.name}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="est_amount">
        <th mat-header-cell *matHeaderCellDef>Est Amt</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.secondaryType === 'percent'">
            <span class="text-ellipsis amount {{element.type}}">{{element.secondaryValue | number:'2'}}</span>
          </ng-container>
          <ng-container *ngIf="element.secondaryType !== 'percent'">
            <span class="text-ellipsis amount {{element.type}}">{{element.amount.total | currency:element.amount.currency:'symbol-narrow':'1.2'}}</span>
            <small class="text-gray">{{element.amount.currency}}</small>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="est_amount_cad">
        <th mat-header-cell *matHeaderCellDef>Est Amt CAD</th>
        <td mat-cell *matCellDef="let element">
          <span class="text-ellipsis amount {{element.type}}">{{element.amount.total_cad | currency:'$':'symbol-narrow':'1.2'}}</span>
          <small class="text-ellipsis text-gray">CAD</small>
        </td>
        <td mat-footer-cell *matFooterCellDef>{{costsCadTotal | currency:'$':'symbol-narrow':'1.2'}}</td>
      </ng-container>
      <ng-container matColumnDef="action_menu">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element?.type && element?.type !== 'secondary'">
            <button mat-icon-button type="button"
                [matMenuTriggerFor]="ellipsisMenu"
                [matMenuTriggerData]="{cost: element}">
              <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
            </button>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="costTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: costTableDisplayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="costTableDisplayedColumns" class="tc-show-footer"></tr>
    </table>
  </mat-tab>
  <mat-tab label="Product specs">
    <div class="tc-deal-product" gdColumns="repeat(6, minmax(0, 1fr))" gdGap="0 15px">
      <tc-select-search placeholder="Wrapping" gdColumn="span 3"
          [group]="offerForm" ctrlName="wrapping"
          [readonly]="offer.offer_id"
          [items]="wrappings$ | async" bindValue="wrapping_id" bindLabel="name"
          ></tc-select-search>
      <tc-select-search placeholder="Weight type" gdColumn="span 3"
          [group]="offerForm.controls.attributes" ctrlName="weight_type_id"
          [readonly]="offer.offer_id"
          [items]="weightTypes$ | async" bindValue="type_id" bindLabel="name"
          ></tc-select-search>
      <mat-form-field>
        <mat-label>No of packages</mat-label>
        <input matInput type="number" min="0" step="1" autocomplete="off" [readonly]="offer.offer_id"
            [formControl]="offerForm.controls.packing.controls.packages_count">
      </mat-form-field>
      <tc-select-search placeholder="Type of Package"
          [group]="offerForm.controls.packing" ctrlName="package_id"
          [readonly]="offer.offer_id"
          [items]="packageTypes$ | async" bindValue="package_id" bindLabel="name"
          ></tc-select-search>
      <mat-form-field>
        <mat-label>Weight of one package</mat-label>
        <input matInput type="number"
                        step="1"
                        autocomplete="off"
                        [formControl]="offerForm.controls.packing.controls.package_size"
                        [readonly]="offer.offer_id"
                        min="0">
      </mat-form-field>
      <tc-select-search placeholder="Unit of weight"
          [group]="offerForm.controls.packing" ctrlName="package_measure_id"
          [readonly]="offer.offer_id"
          [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
          ></tc-select-search>
      <mat-form-field>
        <mat-label>Brand</mat-label>
        <input matInput type="text" [readonly]="offer.offer_id"
            [formControl]="offerForm.controls.attributes.controls.brand">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Product code</mat-label>
        <input matInput type="text" [readonly]="offer.offer_id"
            [formControl]="offerForm.controls.attributes.controls.product_code">
      </mat-form-field>
      <mat-form-field gdColumn="span 6">
        <mat-label>Additional specifications</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
            [readonly]="offer.offer_id"
            [formControl]="offerForm.controls.attributes.controls.additional_specs"
            ></textarea>
      </mat-form-field>
    </div>
  </mat-tab>
  <mat-tab label="Log" *ngIf="offer.offer_id">
    <div class="tc-log">
      <tc-audit-log [filter]="auditLogFilter"></tc-audit-log>
    </div>
  </mat-tab>
</mat-tab-group>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-cost="cost">
    <button mat-menu-item (click)="deleteTertiaryCost(cost)">
      <i class="fa fa-fw fa-trash-can"></i> Delete
    </button>
  </ng-template>
</mat-menu>
