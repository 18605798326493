import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ItemType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { sortBy } from 'lodash-es'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


@Injectable()
@AngularCopy()
export class ItemTypeApiService {
  constructor(private http: HttpClient) {}

  MaxRequestSize = 10000

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: ItemType[] }>(`${apiUrl}/item-type`, { params }).toPromise().then(({data = []}) => {
      return {
        data: sortBy(data, 'name'),
      }
    })
  }

  create(data: DeepReadonly<Partial<ItemType>>) {
    return this.http.post<{ data: ItemType }>(`${apiUrl}/item-type`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: ItemType }>(`${apiUrl}/item-type/${id}`).toPromise()
  }

  update(id: string, data: DeepReadonly<Partial<ItemType>>) {
    return this.http.put<{ data: ItemType }>(`${apiUrl}/item-type/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: ItemType }>(`${apiUrl}/item-type/${id}`).toPromise()
  }
}
