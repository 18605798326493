import { DragDropModule } from '@angular/cdk/drag-drop'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { NotesOverlayModule } from '../notes/notes-overlay/notes-overlay.module'
import { TypeSafeMatCellDefModule } from '../type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { SegmentsListComponent } from './segments-list.component'

@NgModule({
  declarations: [SegmentsListComponent],
  exports: [SegmentsListComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ScrollingModule,
    PipesModule,
    DragDropModule,
    TypeSafeMatCellDefModule,
    NotesOverlayModule,
  ],
})
export class SegmentsListModule { }
