import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { BookingFormModule } from 'src/components/booking-form/booking-form.module'
import { BookingReviewDialogModule } from 'src/components/booking-review-dialog/booking-review-dialog.module'
import { AesResponseReviewDialogModule } from 'src/components/aes-response-review-dialog/aes-response-review-dialog.module'
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SegmentFormModule } from 'src/components/segment-form/segment-form.module'
import { SegmentsListModule } from 'src/components/segments-list/segments-list.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ShippingDetailsFormComponent } from './shipping-details-form.component'
import { AesCancellationDialogModule } from 'src/components/aes-cancellation-dialog/aes-cancellation-dialog.module'

@NgModule({
  declarations: [ShippingDetailsFormComponent],
  exports: [ShippingDetailsFormComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    EpochRangeFieldModule,
    SegmentFormModule,
    SegmentsListModule,
    BookingFormModule,
    BookingReviewDialogModule,
    AesResponseReviewDialogModule,
    AesCancellationDialogModule,
  ],
})
export class ShippingDetailsFormModule {}
