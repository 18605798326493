import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { ApiModule } from 'src/api/api.module'
import { AddressFormModule } from 'src/components/address-form/address-form.module'
import { AddressPickerModule } from 'src/components/address-picker/address-picker.module'
import { CarrierPickerModule } from 'src/components/carrier-picker/carrier-picker.module'
import { CostsListDialogModule } from 'src/components/costs-list-dialog/costs-list-dialog.module'
import { CreditNoteFormModule } from 'src/components/credits/credit-note-form/credit-note-form.module'
import { VendorCreditFormModule } from 'src/components/credits/vendor-credit/vendor-credit-form.module'
import { CsvFormatterService } from 'src/components/csv-exporter/csv-formatter.service'
import { CsvService } from 'src/components/csv-exporter/csv.service'
import { DateRangeSelectModalService } from 'src/components/date-range-select/date-range-select-modal.service'
import { EmailSelectService } from 'src/components/email-picker/email-select.service'
import { InvoiceBatchFormService } from 'src/components/invoices/invoice-batch-form/invoice-batch-form.service'
import { InvoiceFormModule } from 'src/components/invoices/invoice-form/invoice-form.module'
import { InvoiceViewFormService } from 'src/components/invoices/invoice-view-form/invoice-view-form.service'
import { NoteFormModule } from 'src/components/notes/note-form/note-form.module'
import { NotesOverlayModule } from 'src/components/notes/notes-overlay/notes-overlay.module'
import { ConfigureColumnsService } from 'src/components/table-view/configure-columns/configure-columns.service'
import { SaveViewFormService } from 'src/components/table-view/save-view/save-view.service'
import { ShareViewFormService } from 'src/components/table-view/share-view/share-view.service'
import { UploaderDialogModule } from 'src/components/uploader-dialog/uploader-dialog.module'
import { FinanceService } from 'src/pages/admin/auction/matched-offer-overlay/finance.service'
import { MatchedOfferOverlayModule } from 'src/pages/admin/auction/matched-offer-overlay/matched-offer-overlay.module'
import { MatchedOffersService } from 'src/pages/admin/auction/matched-offer-overlay/matched-offers.service'
import { InventoryDealsFormModule } from 'src/pages/admin/auction/matched-offers/form/inventory-deals-form/inventory-deals-form.module'
import { InventoryDealsFormService } from 'src/pages/admin/auction/matched-offers/form/inventory-deals-form/inventory-deals-form.service'
import { MatchedOfferViewLogOverlayModule } from 'src/pages/admin/auction/matched-offers/views-overlay/matched-offer-view-log-overlay.module'
import { SupplierOfferOverlayModule } from 'src/pages/admin/auction/supplier-offer-overlay/supplier-offer-overlay.module'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { CopyFreightRatesFormModule } from 'src/pages/admin/logistics/freight-rates/copy-freight-rates/copy-freight-rates-form.module'
import { FreightRateFormModule } from 'src/pages/admin/logistics/freight-rates/form/freight-rate-form.module'
import { CreateInstructionsFormService } from 'src/pages/admin/logistics/shipping-logs/detail/create-instructions-form/create-instractions-form.service'
import { PackageTrackingService } from 'src/pages/admin/logistics/shipping-logs/detail/overlays/package-tracking/package-tracking.service'
import { SelectStatusNotesFormService } from 'src/pages/admin/logistics/shipping-logs/list/forms/select-status-notes-form/select-status-notes-form.service'
import { BuyerInvoiceReceiptsService } from 'src/pages/admin/management/buyer-invoices/receipts/buyer-invoice-receipts.service'
import { CountriesService } from 'src/pages/admin/settings/admin-setting-geographic/countries.service'
import { CurrenciesService } from 'src/pages/admin/settings/admin-setting-payments/currency/currencies.service'
import { PaymentReferencesService } from 'src/pages/admin/settings/admin-setting-payments/payment-references/payment-references.service'
import { PricingTermsService } from 'src/pages/admin/settings/admin-setting-payments/pricing-terms/pricing-terms.service'
import { ReassignAccountsFormModule } from 'src/pages/admin/settings/companies/companies/form/sub-forms/reassign-accounts-form/reassign-accounts-form.module'
import { CustomTagsService } from 'src/pages/admin/settings/custom-tags/custom-tags.service'
import { DocumentFormModule } from 'src/pages/admin/settings/document-library/documents/form/document-form.module'
import { DocumentTagsOverlayModule } from 'src/pages/admin/settings/document-library/tags/document-tags-overlay/document-tags-overlay.module'
import { GeoService } from 'src/pages/admin/settings/locations/location-form/geo.service'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { ItemTypeFormModule } from 'src/pages/admin/settings/product-specifications/item-types/item-type-form/item-type-form.module'
import { ItemTypesService } from 'src/pages/admin/settings/product-specifications/item-types/item-types.service'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { PackageTypesService } from 'src/pages/admin/settings/product-specifications/package-types/package-types.service'
import { WeightTypesService } from 'src/pages/admin/settings/product-specifications/weight-types/weight-types.service'
import { ProductCategoriesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/product-categories.service'
import { ProductTypesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/product-types.service'
import { WrappingTypesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/wrapping-types.service'
import { ProductsServicesPermissionsService } from 'src/pages/admin/settings/products-services/products-services-permissions.service'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { ProductFormModule } from 'src/pages/admin/settings/products-services/products/form/product-form.module'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { TrackingProvidersService } from 'src/pages/admin/settings/tracking-providers/tracking-providers.service'
import { TransitTimeFormModule } from 'src/pages/admin/settings/transit-times/transit-time-form/transit-time-form.module'
import { CostFormService } from 'src/pages/admin/trading/deal-form/costs-form/costs-form.service'
import { CreateOfferFormService } from 'src/pages/admin/trading/deal-form/create-offer-form/create-offer-form.service'
import { ChangePartyFormModule } from 'src/pages/admin/trading/deal-form/deal-form-page/change-party-form/change-party-form.module'
import { DealAutocopyFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-autocopy-form/deal-autocopy-form.service'
import { DealFormPageOverlayModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form-page-overlay/deal-form-page-overlay.component.module'
import { DealFormPageModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form-page.module'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { DealFormSnapshotsService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service-snapshots'
import { ShipmentRatePickerService } from 'src/pages/admin/trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.service'
import { NegativeMarginReasonFormService } from 'src/pages/admin/trading/deal-form/negative-margin-reason/negative-margin-reason.service'
import { CreateDocumentFormService } from 'src/pages/admin/trading/deals/create-document/create-document-form.service'
import { DocumentRequirementsService } from 'src/pages/admin/trading/deals/create-document/document-requirements.mapping'
import { DocumentsGeneratorService } from 'src/pages/admin/trading/deals/create-document/documents-generator.service'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { FileNameFormService } from 'src/pages/admin/trading/deals/deal-documents/form/file-name-form.service'
import { DealDetailsOverlayModule } from 'src/pages/admin/trading/deals/list/actions/deal-details-overlay/deal-details-overlay.module'
import { DealFinanceOverlayModule } from 'src/pages/admin/trading/deals/list/actions/deal-finance-overlay/deal-finance-overlay.module'
import { ShipmentDetailsOverlayModule } from 'src/pages/admin/trading/deals/list/actions/shipment-details-overlay/shipment-details-overlay.module'
import { SendConfirmationFormModule } from 'src/pages/admin/trading/deals/send-confirmation-form/send-confirmation-form.module'
import { EstimatesService } from 'src/pages/admin/trading/estimates/estimate/estimates.service'
import { LocksModule } from 'src/shared/lock/locks.module'
import { OnlineStatusService } from 'src/shared/lock/online-status.service'
import { MarkAsPaidFormService } from '../../components/invoices/mark-as-paid-form/mark-as-paid-form.service'
import { LogisticsClientShippingLogModule } from '../../pages/admin/logistics/client-shipping-log/client-shipping-log.module'
import { DistributionListOverlayModule } from '../../pages/admin/settings/companies/companies/actions/distribution-list-overlay/distribution-list-overlay.module'
import { ClientShippingLogModule } from '../../pages/admin/trading/client-shipping-log/client-shipping-log.module'
import { CompanyCreditFormService } from '../actions/company.service'
import { DealOverlaysService } from '../actions/deal-overlays.service'
import { DealSubmitService } from '../actions/deal-submit.service'
import { DealValidationService } from '../actions/deal-validation.service'
import { InvoiceFormsService } from '../actions/invoice-forms.service'
import { SegmentActionsService } from '../actions/segment-actions.service'
import { DealFinancialsListenerService } from '../deal-financials-listener'
import { ExcelService } from '../excel-download'
import { MatchedOfferNotifierService } from '../matched-offer-notifier'
import { QueryService } from '../query.service'
import { AccountsService } from './accounts.service'
import { AESService } from './aes.service'
import { BidsService } from './bids.service'
import { CostsService } from './costs.service'
import { CreditNotesService } from './credit-notes.service'
import { CreditPoolService } from './credit-pool.service'
import { CustomCostsService } from './custom-costs.service'
import { DealCalculatorService } from './deal-calculator.service'
import { DealElasticSearchService } from './deal-elastic.service'
import { DealFormCalculatorService } from './deal-form-calculator.service'
import { DealViewCalculatorService } from './deal-view-calculator.service'
import { DealViewLoaderService } from './deal-view-loader.service'
import { DealViewPermissionsService } from './deal-view-permissions.service'
import { DealViewSaverService } from './deal-view-saver.service'
import { DealViewSaver2Service } from './deal-view-saver2.service'
import { DealViewService } from './deal-view.service'
import { DealsService } from './deals.service'
import { FileElasticSearchService } from './file-elastic.service'
import { ImportLogsElasticSearchService } from './import-logs-elastic.service'
import { InvoiceElasticSearchService } from './invoice-elastic.service'
import { InvoicesExportService } from './invoices-export.service'
import { InvoicesHSBCExportService } from './invoices-hsbc-export.service'
import { InvoicesService } from './invoices.service'
import { MacropointService } from './macropoint.service'
import { MatchedOfferPermissionsService } from './matched-offer-permissions.service'
import { MessagesService } from './messages.service'
import { NotesService } from './notes.service'
import { OffersService } from './offers.service'
import { ReceiptsService } from './receipts.service'
import { SegmentsService } from './segments.service'
import { ShipmentsService } from './shipments.service'
import { SupplierOffersService } from './supplier-offers.service'
import { TableViewsService } from './table-views.service'
import { UsersService } from './users.service'
import { VendorCreditsService } from './vendor-credits.service'
import { MacropointOrderLogOverlayModule } from 'src/components/macropoint-log-overlay/macropoint-order-log-overlay.module'
import { CompanyLogOverlayModule } from 'src/pages/admin/settings/companies/companies/company-log-overlay/company-log-overlay.module'

@NgModule({
  // TODO: lazy load form modules after deprecating their downgraded versions
  imports: [
    ApiModule,
    DealFormPageModule,
    ReactiveFormsModule,
    CarrierPickerModule, // for the ng1 app. using it only in the deal-documents component
    CopyFreightRatesFormModule,
    AddressPickerModule,
    AddressFormModule,
    DealFormPageOverlayModule,
    InventoryDealsFormModule,
    DealFinanceOverlayModule,
    ChangePartyFormModule,
    SendConfirmationFormModule,
    InvoiceFormModule,
    DealDetailsOverlayModule,
    ShipmentDetailsOverlayModule,
    CreditNoteFormModule,
    VendorCreditFormModule,
    DistributionListOverlayModule,
    MatDialogModule, // remove after upgrading all ng1 modal dialogs and overlays
    NotesOverlayModule, // remove after upgrading all components and services which are using NotesOverlayService
    NoteFormModule, // remove after upgrading all components and services which are using NoteFormService
    ProductFormModule,
    LocksModule.forRoot(),
    ClientShippingLogModule,
    LogisticsClientShippingLogModule,
    DocumentFormModule,
    DocumentTagsOverlayModule,
    SupplierOfferOverlayModule,
    ReassignAccountsFormModule, // move to "company-form" module when ready
    FreightRateFormModule,
    TransitTimeFormModule,
    MatchedOfferViewLogOverlayModule,
    MatchedOfferOverlayModule,
    MacropointOrderLogOverlayModule,
    CostsListDialogModule,
    ItemTypeFormModule,
    UploaderDialogModule,
    CompanyLogOverlayModule
  ],
  providers: [
    AccountsService,
    BidsService,
    CarriersService,
    CostsService,
    CustomTagsService,
    DealsService,
    DealViewPermissionsService,
    DealViewService,
    FilesService,
    FxRatesService,
    GeoService,
    LocationsService,
    MatchedOfferNotifierService,
    MatchedOfferPermissionsService,
    MessagesService,
    NotesService,
    OffersService,
    PaymentReferencesService,
    ProductsService,
    ProductsServicesPermissionsService,
    QueryService,
    ReceiptsService,
    SegmentsService,
    ShipmentsService,
    SupplierOffersService,
    TableViewsService,
    TrackingProvidersService,
    UsersService,
    ItemTypesService,
    MeasuresService,
    PackageTypesService,
    FinanceService,
    EstimatesService,
    InvoicesService,
    CountriesService,
    MatchedOffersService,
    VendorCreditsService,
    DealFinancialsListenerService,
    CreditNotesService,
    CreditPoolService,
    CustomCostsService,
    PackageTrackingService,
    PricingTermsService,
    DealCalculatorService,
    DealFormService,
    DealFormSnapshotsService,
    DealFormCalculatorService,
    DealViewCalculatorService,
    DocumentRequirementsService,
    WrappingTypesService,
    WeightTypesService,
    ProductTypesService,
    ProductCategoriesService,
    CurrenciesService,

    // TODO: move to UI services module
    NegativeMarginReasonFormService,
    FileNameFormService,
    CompanyCreditFormService,
    InventoryDealsFormService,
    DealOverlaysService,
    SaveViewFormService,
    ShareViewFormService,
    ConfigureColumnsService,
    SelectStatusNotesFormService,
    CreateInstructionsFormService,
    SegmentActionsService,
    CostFormService,
    ShipmentRatePickerService,
    InvoiceViewFormService,
    EmailSelectService,
    CreateOfferFormService,
    InvoiceViewFormService,
    MarkAsPaidFormService,
    AESService,
    MacropointService,

    // TODO: move out to separate modules
    DealViewLoaderService,
    DealViewSaverService,
    DealValidationService,
    DealDocumentsService,
    DocumentsGeneratorService,
    DealSubmitService,
    CreateOfferFormService,
    DealAutocopyFormService,

    DealElasticSearchService,
    InvoiceElasticSearchService,
    DealViewSaver2Service,
    BuyerInvoiceReceiptsService,
    ImportLogsElasticSearchService,

    FileElasticSearchService,
    InvoiceFormsService,
    ExcelService,
    CsvService,
    CsvFormatterService,
    DateRangeSelectModalService,
    InvoicesHSBCExportService,
    InvoicesExportService,
    InvoiceBatchFormService,
    CreateDocumentFormService,
    OnlineStatusService,
  ],
})
export class DataModule { }
