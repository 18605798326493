import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthApiService } from 'src/api/auth';

@Injectable()
export class CanActivateAccount  {
  constructor(private AuthApi: AuthApiService, private router: Router) {}
  private readonly loginPageUrl = this.router.parseUrl('/login')

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree>{
    return this.AuthApi.realUser$.pipe(take(1), map(realUser => route.data.roles?.includes(realUser?.role) || this.loginPageUrl))
  }
}
