import { Injectable } from '@angular/core'
import { DealBase } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { isMatch, pick } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { DealApiService } from 'src/api/deal'
import { MessageApiService } from 'src/api/message'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'


const finacialFields = [
  // actual
  'attributes.actual.revenue',
  'attributes.actual.costs',
]

@Injectable()
export class DealFinancialsListenerService {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private MessageApi: MessageApiService,
    private Invoices: InvoicesService,
    private DealApi: DealApiService,
  ) {
    this.DealApi.dealUpdated$.subscribe(id => this.tryToNotifyChange(id))
  }

  private readonly changedDealIds = []
  dealFinancialsChanged$ = new Subject<{ dealId: string }>()

  // only compare financial fields
  tryToRecordChange(
    oldDeal: DeepReadonly<Pick<DealBase, 'deal_id'|'attributes'>>,
    newDeal: DeepReadonly<Pick<DealBase, 'deal_id'|'attributes'>>,
  ) {
    if (!isMatch(pick(oldDeal, finacialFields), pick(newDeal, finacialFields))) {
      this.changedDealIds.push(oldDeal.deal_id)
    }
  }


  // emit events
  notifyChange(dealId: string) {
    this.dealFinancialsChanged$.next({dealId})
  }

  getNotifyMessagePayload(dealId: string, message: string) {
    const {account, user_id} = this.AuthApi.currentUser
    return {
      type: 'in-app',
      account,
      deal_id: dealId,
      from: user_id,
      to: user_id,
      body_html: message,
      body_txt: message,
      is_draft: 'no',
      direction: 'outgoing',
    }
  }

  private async tryToNotifyChange(dealId: string) {
    const index = this.changedDealIds.indexOf(dealId)
    const {role, account} = this.AuthApi.currentUser
    if (index > -1 && role !== 'trader') {
      const invoicesByDealId = await this.Invoices.getForDeals([dealId])
      const message = `Deal financials are changed, please update the invoice(s) in deal <a style="color: white; font-weight: bold" href="/logistics/shipping-log/${dealId}">${dealId}</a>`
      const messagePayload = this.getNotifyMessagePayload(dealId, message)

      if (invoicesByDealId[dealId] && invoicesByDealId[dealId].length) {
        this.toaster.pop({
          type: 'warning',
          html: message,
          duration: 2000
        })
        this.MessageApi.create(account, messagePayload as any) // TODO: ng1 fix "to" field schema
      }
    }
    this.changedDealIds.splice(index, 1)
  }
}
