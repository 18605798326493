<div [innerHTML]="styles" style="display:none"></div>

<div class="tc-table-page-header" tc-sticky-header fxLayoutAlign="space-between center">
  <div fxFlex>
    <div class="mat-headline-6">TradeCafé - {{title}}</div>
    <tc-breadcrumbsv2 [activeView]="tableView.activeView$ | async"></tc-breadcrumbsv2>
  </div>
  <button mat-button (click)="clearFilters()" *ngIf="hasFilteringEnabled$ | async">
    <mat-icon class="fa fa-times"></mat-icon> Reset Filters
  </button>
  <tc-table-views-menu #tableView [columnDefs]="columnDefs" [availableColumns]="availableColumns"
    [tableIdentity]="tableIdentity" [filters]="filtersForm" (tableView)="applyTableView($event)"></tc-table-views-menu>
  <mat-divider [vertical]="true" style="height: 50px; margin: 0 30px 0 15px"></mat-divider>
  <button mat-raised-button color="action" [matMenuTriggerFor]="actionsMenu">
    <mat-icon class="fa fa-square-caret-down"></mat-icon> Action
  </button>
  <button *ngIf="editable" mat-raised-button color="add" (click)="showAddItem()">
    <mat-icon class="fa fa-plus"></mat-icon> Add New
  </button>
</div>

<form class="tc-table-page-filters"
    [formGroup]="filtersForm"
    gdColumns="repeat(2, minmax(0, 1fr))" gdGap="10px"
    gdColumns.sm="repeat(3, minmax(0, 1fr))"
    gdColumns.md="repeat(4, minmax(0, 1fr))"
    gdColumns.gt-md="repeat(6, minmax(0, 1fr))">
  <mat-form-field gdColumn.gt-md="span 2" [class.tc-text-empty]="!filtersForm.get('text').value">
    <mat-label>Search</mat-label>
    <button mat-icon-button matPrefix>
      <mat-icon class="fa fa-search"></mat-icon>
    </button>
    <input matInput formControlName="text" autocomplete="text">
    <button mat-icon-button class="tc-text-clear"
        [disabled]="filtersForm.get('text').disabled"
        (click)="filtersForm.get('text').reset()">
      <mat-icon class="fa fa-times"></mat-icon>
    </button>
  </mat-form-field>
  <tc-select-search placeholder="Carrier" [multiple]="true" [group]="filtersForm" ctrlName="carrier"
    [items]="carriers$ | async" bindValue="carrier_id"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Type" [multiple]="true" [group]="filtersForm" ctrlName="type"
    [items]="ShipmentRateTypes" bindValue="id"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Commodity" [multiple]="true" [group]="filtersForm" ctrlName="commodity"
    [items]="FreightCategories" bindValue="id"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Container Size" [multiple]="true" [group]="filtersForm" ctrlName="containerSize"
    [items]="containerSizesOptions$ | async"></tc-select-search>
  <tc-select-search placeholder="Origin Country" [multiple]="true" [group]="filtersForm" ctrlName="orgCountry"
    [items]="countries$ | async" bindValue="code"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Origin State" [multiple]="true" [group]="filtersForm" ctrlName="orgState"
    [items]="originStateOptions$ | async" bindValue="value"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Origin City" [multiple]="true" [group]="filtersForm" ctrlName="orgCity"
    [items]="originCityOptions$ | async" bindValue="value"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Dest Country" [multiple]="true" [group]="filtersForm" ctrlName="dstCountry"
    [items]="countries$ | async" bindValue="code"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Dest State" [multiple]="true" [group]="filtersForm" ctrlName="dstState"
    [items]="dstStateOptions$ | async" bindValue="value"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Dest City" [multiple]="true" [group]="filtersForm" ctrlName="dstCity"
    [items]="dstCityOptions$ | async" bindValue="value"
    bindLabel="name"></tc-select-search>
  <tc-select-search placeholder="Status" [multiple]="true" [group]="filtersForm" ctrlName="status"
    [items]="ShipmentRateStatuses" bindValue="id"
    bindLabel="label"></tc-select-search>
  <tc-epoch-range-field placeholder="Valid Until"
    [formGroup]="filtersForm" startCtrlName="valid_until_from" endCtrlName="valid_until_to"
    [useUtc]="true"
    ></tc-epoch-range-field>
  <tc-epoch-range-field placeholder="Creation Date"
    [formGroup]="filtersForm" startCtrlName="created_from" endCtrlName="created_to"
    [useUtc]="true"
    ></tc-epoch-range-field>
  <tc-select-search placeholder="Validity" [group]="filtersForm" ctrlName="validity" [items]="ValidityOptions"
    ></tc-select-search>
  <tc-select-search placeholder="Max. Weight" [multiple]="true" [group]="filtersForm" ctrlName="maxWeight"
    [items]="maxWeightOptions$ | async"></tc-select-search>
  <tc-select-search placeholder="Creator" [multiple]="true" [group]="filtersForm" ctrlName="creator"
    bindValue="user_id" bindLabel="fullname" [items]="creatorOptions$ | async"></tc-select-search>
</form>

<tc-freight-rates-list
  [filtersForm]="filtersForm"
  [dataSource]="dataSource"
  [displayColumns]="displayColumns$ | async"
  [editable]="editable"
  (editColumns)="tableView.configureColumns()"
  (rowUpdated)="reloadFreightRates()"
  ></tc-freight-rates-list>

<mat-menu #actionsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="exportGrid()">
      <i class="fa fa-fw fa-cloud-arrow-down"></i> Download to CSV
    </button>
    <a *ngIf="editable" mat-menu-item routerLink="/logistics/freight-rate-importer">
      <i class="fa fa-cloud-arrow-up" aria-hidden="true"></i> Upload a CSV
    </a>
    <mat-divider *ngIf="editable"></mat-divider>
      <button *ngIf="editable" mat-menu-item (click)="expireSelectedRates()" [disabled]="!freightRatesList.selection?.selectedIds?.size">
        <i class="fa fa-fw fa-circle-xmark"></i> Expire Rates
      </button>
      <button *ngIf="editable" mat-menu-item (click)="archiveSelectedRates()" [disabled]="!freightRatesList.selection?.selectedIds?.size">
        <i class="fa fa-fw fa-xmark"></i> Archive Rates
      </button>
      <button *ngIf="editable" mat-menu-item (click)="changeSelectedRates()" [disabled]="!freightRatesList.selection?.selectedIds?.size">
        <i class="fa fa-fw fa-edit"></i> Change Rates
      </button>
  </ng-template>
</mat-menu>
