import { createAction, props } from '@ngrx/store'
import { TableKey, WeightType } from '@tradecafe/types/core'


export const loadWeightTypes = createAction(
  '[WeightType/API] Load Weight Types',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadWeightTypesSuccess = createAction(
  '[WeightType/API] Load Weight Types Success',
  props<{
    tableKey?: TableKey,
    weightTypes: WeightType[],
  }>(),
)
export const loadWeightTypesFailure = createAction(
  '[WeightType/API] Load Weight Types Failure',
  props<{ error: Error }>(),
)
