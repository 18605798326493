import { createSelector } from '@ngrx/store'
import { ProductWrapping } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, wrappingTypesFeatureKey } from './wrapping-type.reducer'

export const selectState = (state: State) => state[wrappingTypesFeatureKey]

export const { selectEntities: selectWrappingTypeEntities, selectAll: selectAllWrappingTypes } = adapter.getSelectors(selectState)

export const selectWrappingType = createSelector(
  selectWrappingTypeEntities,
  (wrappingTypes: Dictionary<ProductWrapping>, wrappingType: string | number) =>
    wrappingTypes[wrappingType])

export const selectWrappingTypesById = createSelector(
  selectWrappingTypeEntities,
  (wrappingTypes: Dictionary<ProductWrapping>, wrappingTypeId: string) =>
    wrappingTypes[wrappingTypeId])

export const selectWrappingTypesByIds = createSelector(
  selectWrappingTypeEntities,
  (wrappingTypes: Dictionary<ProductWrapping>, wrappingTypeIds: string[]) =>
    wrappingTypeIds.map(wrappingTypeId => wrappingTypes[wrappingTypeId]))
