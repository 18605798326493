import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { DealProductsOverlayModule } from 'src/pages/admin/logistics/shipping-details-page/deal-products-list/deal-products-overlay/deal-products-overlay.module'
import { ShippingDetailsOverlayModule } from 'src/pages/admin/logistics/shipping-details-page/shipping-details-overlay/shipping-details-overlay.module'
import { TradingDetailsOverlayModule } from 'src/pages/admin/logistics/shipping-details-page/trading-details-overlay/trading-details-overlay.module'
import { CreateOfferFormModule } from 'src/pages/admin/trading/deal-form/create-offer-form/create-offer-form.module'
import { CopyCloneFormModule } from 'src/pages/admin/trading/deal-form/deal-clones/copy-clone-form/copy-clone-form.module'
import { DocumentsOverlayModule } from 'src/pages/admin/trading/deals/deal-documents/documents-overlay/documents-overlay.module'
import { RequestPrepaymentModule } from 'src/pages/admin/trading/deals/request-prepayment/request-prepayment.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { AesRequestReviewDialogModule } from '../aes-request-review-dialog/aes-request-review-dialog.module'
import { AesResponseReviewDialogModule } from '../aes-response-review-dialog/aes-response-review-dialog.module'
import { BookingReviewDialogModule } from '../booking-review-dialog/booking-review-dialog.module'
import { DealStatusModule } from '../deal-status/deal-status.module'
import { EpochRangeFieldModule } from '../epoch/epoch-range-field/epoch-range-field.module'
import { InlineEditorModule } from '../inline-editor/inline-editor.module'
import { MacropointOrderDetailsModule } from '../macropoint-order-details-dialog/macropoint-order-details-dialog.module'
import { NotesOverlayModule } from '../notes/notes-overlay/notes-overlay.module'
import { SelectSearchModule } from '../select-search/select-search.module'
import { TypeSafeMatCellDefModule } from '../type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { DealsListComponent } from './deals-list.component'

@NgModule({
  declarations: [DealsListComponent],
  exports: [DealsListComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    DealStatusModule,
    SelectSearchModule,
    ScrollingModule,
    PipesModule,
    InlineEditorModule,
    DealProductsOverlayModule,
    TradingDetailsOverlayModule,
    ShippingDetailsOverlayModule,
    DocumentsOverlayModule,
    TypeSafeMatCellDefModule,
    NotesOverlayModule,
    CopyCloneFormModule,
    RequestPrepaymentModule,
    EpochRangeFieldModule,
    BookingReviewDialogModule,
    AesRequestReviewDialogModule,
    AesResponseReviewDialogModule,
    MacropointOrderDetailsModule,
    CreateOfferFormModule,
  ],
})
export class DealsListModule { }
