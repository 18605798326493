import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { VendorCredit } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const {apiUrl} = environment

export function registerNg1(module: ng.IModule) {
  module.service('VendorCreditApi', downgradeInjectable(VendorCreditApiService))
}


@Injectable()
@AngularCopy()
export class VendorCreditApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account: number|string, @AngularParams() params?) {
    return this.http.get<{ data: VendorCredit[] }>(`${apiUrl}/vendor-credits/by-account/${account}`, { params }).toPromise()
  }

  @HaveAngularParams()
  listByDeal(dealId: string, @AngularParams() params?) {
    return this.http.get<{ data: VendorCredit[] }>(`${apiUrl}/vendor-credits/by-deal/${dealId}`, { params }).toPromise()
  }

  listByDeals(deal_ids: string[]) {
    return this.http.post<{ data: VendorCredit[] }>(`${apiUrl}/vendor-credits/by-deals/`, { deal_ids }).toPromise()
  }

  listByInvoices(invoice_ids: string[]) {
    return this.http.post<{ data: VendorCredit[] }>(`${apiUrl}/vendor-credits/by-invoices/`, { invoice_ids })
  }

  create(data: Partial<VendorCredit>) {
    return this.http.post<{ data: VendorCredit }>(`${apiUrl}/vendor-credits`, data).toPromise()
  }

  update(id: string, data: Partial<VendorCredit>) {
    return this.http.put<{ data: VendorCredit }>(`${apiUrl}/vendor-credits/${id}`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: VendorCredit }>(`${apiUrl}/vendor-credits/${id}`).toPromise()
  }

  void(id: string) {
    return this.http.put<{ data: VendorCredit }>(`${apiUrl}/vendor-credits/${id}/void`, {}).toPromise()
  }
}
