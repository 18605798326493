import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject } from 'rxjs'
import { rejectBooking, rejectBookingFailure, rejectBookingSuccess } from 'src/app/store/booking'
import { MONTSHIP_BOOKING_REJECTION_REASON, NotesService } from 'src/services/data/notes.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'

export interface BookingRejectionDialogOptions {
  bookingId: string,
  dealId: string,
}

@Component({
  selector: 'tc-booking-rejection-dialog',
  templateUrl: './booking-rejection-dialog.component.html',
  styleUrls: ['./booking-rejection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRejectionDialogComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private Notes: NotesService,
    @Inject(MAT_DIALOG_DATA) protected dialogData: BookingRejectionDialogOptions,
    private dialogRef: MatDialogRef<BookingRejectionDialogComponent, boolean>,
    private actions$: Actions,
    private store: Store,
    private Toaster: ToasterService,
  ) { super() }

  form = new FormGroup({
    reason: new FormControl(undefined, []),
  })

  protected inProgress$ = new BehaviorSubject<'saving'|undefined>(undefined)
  protected reason: string

  ngOnInit(): void {
    this.actions$.pipe(ofType(rejectBookingSuccess), untilComponentDestroyed(this)).subscribe(() => {
      this.dialogRef.close()
    })
    this.actions$.pipe(ofType(rejectBookingFailure), untilComponentDestroyed(this)).subscribe(() => {
      this.inProgress$.next(undefined)
    })
  }

  protected close() {
    this.dialogRef.close()
  }

  protected async submit(): Promise<void> {
    const reason = this.form.controls.reason.value;
    this.inProgress$.next('saving')

    if(reason) {
      try {
        await this.Notes.createNote({
          visibility: 1,
          body: reason,
          deal_id: this.dialogData.dealId,
          attributes: {
            category: MONTSHIP_BOOKING_REJECTION_REASON,
          },
        })
      } catch (err) {
        this.Toaster.error('Failed storing the rejection reason note.');
        this.inProgress$.next(undefined)
        return;
      }
    }

    this.store.dispatch(rejectBooking({
      bookingId: this.dialogData.bookingId,
      dealId: this.dialogData.dealId,
      reason: this.form.controls.reason.value,
    }))
  }
}
