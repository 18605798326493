<div class="table-responsive">
  <table class="table" style="white-space: nowrap" [ngSwitch]="isBrokerage$ | async">
    <thead>
      <tr>
        <th></th>
        <th colspan="2">Original Currency</th>
        <th colspan="3">Canadian</th>
        <th colspan="2">Margins</th>
      </tr>
      <tr>
        <th></th>
        <th>Product Costs</th>
        <th>Deal Revenue</th>

        <th>Product Costs</th>
        <th>Deal Costs</th>
        <th>Deal Revenue</th>

        <th>Deal Margin</th>
        <th>Margin Percentage</th>
      </tr>
    </thead>
    <tbody *ngSwitchCase="false">
      <tr>
        <td>Estimated</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.buy_price | currency:(formGroup.estimatedTotals$ | async)?.buy_currency:'symbol-narrow'}}</em> {{(formGroup.estimatedTotals$ | async)?.buy_currency}}</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.sell_price | currency:(formGroup.estimatedTotals$ | async)?.sell_currency:'symbol-narrow'}}</em> {{(formGroup.estimatedTotals$ | async)?.sell_currency}}</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.costs - (formGroup.estimatedTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.margin_p*100 | number:'1.2-2'}}</em> %</td>
      </tr>
      <tr>
        <td>Actual</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.buy_price | currency:(formGroup.actualTotals$ | async)?.buy_currency:'symbol-narrow'}}</em> {{(formGroup.actualTotals$ | async)?.buy_currency}}</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.sell_price | currency:(formGroup.actualTotals$ | async)?.sell_currency:'symbol-narrow'}}</em> {{(formGroup.actualTotals$ | async)?.sell_currency}}</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.costs - (formGroup.actualTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.margin_p*100 | number:'1.2-2'}}</em> %</td>
      </tr>
      <tr>
        <td>Partial</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_buy_price | currency:(formGroup.actualTotals$ | async)?.buy_currency:'symbol-narrow'}}</em> {{(formGroup.actualTotals$ | async)?.buy_currency}}</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_sell_price | currency:(formGroup.actualTotals$ | async)?.sell_currency:'symbol-narrow'}}</em> {{(formGroup.actualTotals$ | async)?.sell_currency}}</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_costs - (formGroup.actualTotals$ | async)?.partial_buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_sell_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_margin_p*100 | number:'1.2-2'}}</em> %</td>
      </tr>
      <tr>
        <td>Variance</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.buy_price - (formGroup.estimatedTotals$ | async)?.buy_price <= 0"
              >{{(formGroup.actualTotals$ | async)?.buy_price - (formGroup.estimatedTotals$ | async)?.buy_price | currency:(formGroup.actualTotals$ | async)?.buy_currency:'symbol-narrow'}}</em> {{(formGroup.actualTotals$ | async)?.buy_currency}}</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.sell_price - (formGroup.estimatedTotals$ | async)?.sell_price <= 0"
              >{{(formGroup.actualTotals$ | async)?.sell_price - (formGroup.estimatedTotals$ | async)?.sell_price | currency:(formGroup.actualTotals$ | async)?.sell_currency:'symbol-narrow'}}</em> {{(formGroup.actualTotals$ | async)?.sell_currency}}</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.buy_price_cad - (formGroup.estimatedTotals$ | async)?.buy_price_cad <= 0"
              >{{(formGroup.actualTotals$ | async)?.buy_price_cad - (formGroup.estimatedTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="((formGroup.actualTotals$ | async)?.costs - (formGroup.actualTotals$ | async)?.buy_price_cad) - ((formGroup.estimatedTotals$ | async)?.costs - (formGroup.estimatedTotals$ | async)?.buy_price_cad) <= 0"
              >{{((formGroup.actualTotals$ | async)?.costs - (formGroup.actualTotals$ | async)?.buy_price_cad) - ((formGroup.estimatedTotals$ | async)?.costs - (formGroup.estimatedTotals$ | async)?.buy_price_cad) | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.sell_price_cad - (formGroup.estimatedTotals$ | async)?.sell_price_cad <= 0"
              >{{(formGroup.actualTotals$ | async)?.sell_price_cad - (formGroup.estimatedTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.margin - (formGroup.estimatedTotals$ | async)?.margin <= 0"
              >{{(formGroup.actualTotals$ | async)?.margin - (formGroup.estimatedTotals$ | async)?.margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="((formGroup.actualTotals$ | async)?.margin_p - (formGroup.estimatedTotals$ | async)?.margin_p) * 100 <= 0">
          {{((formGroup.actualTotals$ | async)?.margin_p - (formGroup.estimatedTotals$ | async)?.margin_p) * 100 | number:'1.2-2'}}</em>%</td>
      </tr>
    </tbody>
    <tbody *ngSwitchCase="true">
      <tr>
        <td>Estimated</td>
        <td><em>—</em></td>
        <td><em>{{(brok$ | async)?.estAmount | currency:(brok$ | async)?.currency:'symbol-narrow'}}</em> {{(brok$ | async)?.currency}}</td>
        <td><em>—</em></td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.brokerage_costs | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.brokerage_revenue | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.estimatedTotals$ | async)?.margin_p*100 | number:'1.2-2'}}</em> %</td>
      </tr>
      <tr>
        <td>Actual</td>
        <td><em>—</em></td>
        <td><em>{{(brok$ | async)?.actAmount | currency:(brok$ | async)?.currency:'symbol-narrow'}}</em> {{(brok$ | async)?.currency}}</td>
        <td><em>—</em></td>
        <td><em>{{(formGroup.actualTotals$ | async)?.brokerage_costs | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.brokerage_revenue | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.margin_p*100 | number:'1.2-2'}}</em> %</td>
      </tr>
      <tr>
        <td>Partial</td>
        <td><em>—</em></td>
        <td><em>{{((brok$ | async)?.actAmount || (brok$ | async)?.estAmount) | currency:(brok$ | async)?.currency:'symbol-narrow'}}</em> {{(brok$ | async)?.currency}}</td>
        <td><em>—</em></td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_brokerage_costs | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_brokerage_revenue | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em>{{(formGroup.actualTotals$ | async)?.partial_margin_p*100 | number:'1.2-2'}}</em> %</td>
      </tr>
      <tr>
        <td>Variance</td>
        <td><em>—</em></td>
        <td><em>{{((brok$ | async)?.actAmount - (brok$ | async)?.estAmount) | currency:(brok$ | async)?.currency:'symbol-narrow'}}</em> {{(brok$ | async)?.currency}}</td>
        <td><em>—</em></td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.brokerage_costs - (formGroup.estimatedTotals$ | async)?.brokerage_costs <= 0"
              >{{(formGroup.actualTotals$ | async)?.brokerage_costs - (formGroup.estimatedTotals$ | async)?.brokerage_costs | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.brokerage_revenue - (formGroup.estimatedTotals$ | async)?.brokerage_revenue <= 0"
              >{{(formGroup.actualTotals$ | async)?.brokerage_revenue - (formGroup.estimatedTotals$ | async)?.brokerage_revenue | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="(formGroup.actualTotals$ | async)?.margin - (formGroup.estimatedTotals$ | async)?.margin <= 0"
              >{{(formGroup.actualTotals$ | async)?.margin - (formGroup.estimatedTotals$ | async)?.margin | currency:'CAD':'symbol-narrow'}}</em> CAD</td>
        <td><em [class.subValue]="((formGroup.actualTotals$ | async)?.margin_p - (formGroup.estimatedTotals$ | async)?.margin_p) * 100 <= 0">
          {{((formGroup.actualTotals$ | async)?.margin_p - (formGroup.estimatedTotals$ | async)?.margin_p) * 100 | number:'1.2-2'}}</em>%</td>
      </tr>
    </tbody>
  </table>
</div>
