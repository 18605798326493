import { createAction, props } from '@ngrx/store'
import { ProductPackaging, TableKey } from '@tradecafe/types/core'


export const loadPackageTypes = createAction(
  '[PackageType/API] Load Package Types',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadPackageTypesSuccess = createAction(
  '[PackageType/API] Load Package Types Success',
  props<{
    tableKey?: TableKey,
    packageTypes: ProductPackaging[],
  }>(),
)
export const loadPackageTypesFailure = createAction(
  '[PackageType/API] Load Package Types Failure',
  props<{ error: Error }>(),
)
