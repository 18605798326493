import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { AccountObject, AccountType, BusinessType, CreditNote, Deal, DealView, DealViewRawCosts, DealViewRawDeal, Invoice, InvoiceLineItem, Note, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { map } from 'rxjs/operators'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const { apiUrl } = environment

/** @deprecated for ng1 */
export interface InvoiceRowOld extends Invoice {
  creator?
  company?
  trader?
  coordinator?
  buyingTrader?
  customer?
  costs?
  costNames?
  deal?
  dealNotes?
  noteWarning?
  getEstimatedCostTotal?(): number
  getInvoiceTotal?(): number
}

export interface InvoiceViewUser {
  user_id: string,
  account: string,
  firstname: string,
  lastname: string,
  fullname?: string,
}

interface InvoiceViewView {
  actl_amount_cad: number
  deal: Deal
  actl_amount: number
  buyer: {
    account: number
    name: string
  }
  brokerage_trader: InvoiceViewUser
  buying_trader: InvoiceViewUser
  coordinator: InvoiceViewUser
  company: {
    type: AccountType
    name: string
    account: number
  }
  coordintator: InvoiceViewUser
  cost_names: string|string[]
  status: string // deal status
  est_amount: number
  net_payable: number
  note_warning: boolean
  notes: Note
  partial_margin_p: number
  partial_margin: number
  product: {
    category: { name: string }
    product_type: { name: string }
    name: string
  }
  rmng_amount: number
  selling_trader: InvoiceViewUser
  sum_pmts_applied: number
  supplier: {
    account: number
    name: string
  }
  user: InvoiceViewUser
  variance: number
}

export interface InvoiceViewDto {
  invoice: Invoice
  status: { status: number, name: string }
  view: Partial<InvoiceViewView>
}
export interface InvoiceRow extends DeepReadonly<Invoice> {
  readonly actualized_by_name?: string,
  readonly view: Partial<InvoiceViewView>
  readonly viewEx: DeepReadonly<{
    status?: { status: number, name: string }
    inventoryDeal?: Pick<Deal|DealView, 'buyer_id'|'supplier_id'>
    paymentType?: string
    companyType?: string
    rowType?: string // Prepayment, Invoice, Credit Note, Vendor Credit. UI only
    dv?: DealViewRawDeal & DealViewRawCosts
    creditNote?: CreditNote // for custom row type (deal invoices)
    vendorCredit?: VendorCredit // for custom row type (deal invoices)
    company?: AccountObject // for HSBC export
    businessType?: BusinessType // for HSBC export
    fileUrl?: string // attachment url
    act_liability_date?: number
  }>
}

export interface InvoiceTotalAmountDto {
  amount: number
  currency: string
}

export interface InvoiceTotalsDto {
  actl_amount: InvoiceTotalAmountDto[]
  actl_amount_cad: number
  est_amount: InvoiceTotalAmountDto[]
  net_payable: InvoiceTotalAmountDto[]
  partial_margin: number
  rmng_amount: InvoiceTotalAmountDto[]
  sum_pmts_applied: InvoiceTotalAmountDto[]
  variance: InvoiceTotalAmountDto[]
}

export function registerNg1(module: ng.IModule) {
  module.service('InvoiceApi', downgradeInjectable(InvoiceApiService))
}


@Injectable()
@AngularCopy()
export class InvoiceApiService {
  readonly InvoiceCategories = ['shipping', 'storage']

  constructor(private http: HttpClient) {}


  @HaveAngularParams()
  listCrossAccount(@AngularParams() params) {
    return this.http.get<{ data: Invoice[] }>(`${apiUrl}/invoices`, { params }).toPromise()
  }

  @HaveAngularParams()
  list(account: number | string, @AngularParams() params) {
    return this.http.get<{ data: Invoice[] }>(`${apiUrl}/invoices/${account}`, { params }).toPromise()
  }

  search(params) {
    return this.http.post<{ data: Invoice[] }>(`${apiUrl}/invoices/v2/search`, params).toPromise()
  }

  elasticSearch(params) {
    return this.http.post<{ total: number, totals: InvoiceTotalsDto, hits: InvoiceViewDto[] }>(`${apiUrl}/search/invoices`, params)
  }

  elasticFetch(params) {
    return this.elasticSearch(params).pipe(map(res => res.hits))
  }

  elasticFilters(params) {
    return this.http.post<Dictionary<any>>(`${apiUrl}/search/invoices/filters`, params)
  }

  @HaveAngularParams()
  listByStatuses(account: number | string, statuses, @AngularParams() params) {
    return this.http.post<{ data: Invoice[] }>(`${apiUrl}/invoices/get_by_status_values/${account}`, {status_values: statuses}, { params }).toPromise()
  }

  @HaveAngularParams()
  listByStatusForTrader(traderId: string, status, params) {
    return this.http.get<{ data: Invoice[] }>(`${apiUrl}/invoices/trader-status/${traderId}/${status}`, { params }).toPromise()
  }

  listByDealIds(query) {
    return this.http.post<{ data: Invoice[] }>(`${apiUrl}/invoices/by-multiple-deals?limit=${Number.MAX_SAFE_INTEGER}`, query).toPromise()
  }

  // create(data: DeepReadonly<Partial<Invoice>>) {
  //   return this.http.post<{ data: Invoice }>(`${apiUrl}/invoices`, data).toPromise()
  // }

  update(account: number | string, id: string, data: DeepReadonly<Partial<Invoice>>) {
    return this.http.put<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}`, data).toPromise()
  }

  get(account: number | string, id: string) {
    return this.http.get<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}`).toPromise()
  }

  delete(account: number | string, id: string) {
    return this.http.delete<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}`).toPromise()
  }

  void(account: number | string, id: string) {
    return this.http.put<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}/void`, {}).toPromise()
  }

  send(account: number | string, id: string) {
    return this.http.put<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}/sent`, {}).toPromise()
  }

  addReceipt(account: number | string, id: string, data) {
    return this.http.put<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}/receipt`, data).toPromise()
  }

  deleteReceipt(account: number | string, id: string, receiptId: string) {
    return this.http.delete<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}/receipt/${receiptId}`).toPromise()
  }

  addLineItem(account: number | string, id: string, data: DeepReadonly<Partial<InvoiceLineItem>>) {
    return this.http.put<{ data: { invoice: Invoice }}>(`${apiUrl}/invoices/${account}/${id}/item`, data).toPromise()
  }

  deleteLineItem(account: number | string, id: string, itemId: string) {
    return this.http.delete<{ data: Invoice }>(`${apiUrl}/invoices/${account}/${id}/item/${itemId}`).toPromise()
  }

  findBatchId(batch_id: string) {
    return this.http.get<any>(`${apiUrl}/invoices/has-batch-id/${batch_id}`).toPromise()
  }
}
