<h2 mat-dialog-title>
  Macropoint order details
</h2>
<div class="macropoint-order-details-modal-content" mat-dialog-content>
  <ng-container *ngIf="order">
    <div class="request-info">
      <div><span class="request-info-label">Requested by:</span>{{sender | fullname}}</div>
      <div class="request-status-wrapper"><span class="request-info-label">Status set:</span> <span class="request-info-name"> {{order.macropoint_status_set | epoch:'L LT'}}</span></div>
    </div>
    <div class="request-info">
      <div><span class="request-info-label">Request sent:</span> {{order.created | epoch:'L LT'}}</div>
      <div class="request-status-wrapper"><span class="request-info-label">Order status:</span> <span class="request-info-name" [title]="MacropointStatusDescription[order.macropoint_status_code]?.statusDescription">{{MacropointStatusDescription[order.macropoint_status_code]?.statusMessage}}</span></div>
    </div>
    <div class="request-info">
      <div><span class="request-info-label">Tracking request #:</span> {{order.tracking_request_id}}</div>
      <div class="request-status-wrapper"><span class="request-info-label">Deal Id:</span> <span class="request-info-name">{{order.deal_id}}</span></div>
    </div>
    <div class="request-info" *ngIf="order.macropoint_order_id">
      <div><span class="request-info-label">Macropoint order Id:</span> {{order.macropoint_order_id}}</div>
    </div>

    <ng-container *ngIf="order.latest_location || order.latest_trip_event">
      <mat-divider />

      <div class="request-info" *ngIf="order.latest_location">
        <span class="request-info-label">Latest reported location ({{order.latest_location?.approx_location_date_time_in_local_time | epoch:'L LT':'utc'}} local):</span> <span class="text-overflow" [title]="formatLocation(order.latest_location)">{{formatLocation(order.latest_location)}}</span>
      </div>

      <div class="request-info" *ngIf="order.latest_location">
        <span class="request-info-label">Locator Id:</span> <span class="text-overflow">{{order.latest_location?.locator}}</span>
      </div>

      <div class="request-info" *ngIf="order.latest_trip_event">
        <span class="request-info-label">Latest trip event ({{order.latest_trip_event?.approx_location_date_time_in_local_time | epoch:'L LT':'utc'}} local):</span> <span class="text-overflow" [title]="getTripEventDescription(order.latest_trip_event?.trip_event_code) + ' (' + formatLocation(order.latest_trip_event) + ')'">{{getTripEventDescription(order.latest_trip_event?.trip_event_code)}} ({{formatLocation(order.latest_trip_event)}})</span>
      </div>

      <div class="request-info" *ngIf="order.latest_trip_event">
        <span class="request-info-label">Trip event source:</span> <span class="text-overflow" [title]="order.latest_trip_event?.event_source">{{order.latest_trip_event?.event_source}}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="order.latest_schedule_alert">
      <mat-divider />
      <div class="request-info" *ngIf="order.latest_schedule_alert">
        <span class="request-info-label">Latest scheduled alert type ({{order.latest_schedule_alert?.received | epoch:'L LT'}}):</span> <span class="text-overflow" [title]="getScheduleAlertDescription(order.latest_schedule_alert?.schedule_alert_code)">{{getScheduleAlertDescription(order.latest_schedule_alert?.schedule_alert_code)}}</span>
      </div>
      <div class="request-info" *ngIf="order.latest_schedule_alert">
        <span class="request-info-label">Latest scheduled alert description:</span> <span class="text-overflow">{{order.latest_schedule_alert?.schedule_alert_text}}</span>
      </div>
      <div class="request-info" *ngIf="order.latest_schedule_alert">
        <span class="request-info-label">Upcoming stop stop name:</span> <span class="text-overflow" [title]="order.latest_schedule_alert?.stop_name + ' (' + order.latest_schedule_alert?.stop_type + ')'">{{order.latest_schedule_alert?.stop_name}} ({{order.latest_schedule_alert?.stop_type}})</span>
      </div>
      <div class="request-info" *ngIf="order.latest_schedule_alert">
        <span class="request-info-label">Upcoming stop location:</span> <span class="text-overflow" [title]="formatLocation(order.latest_schedule_alert)">{{formatLocation(order.latest_schedule_alert)}}</span>
      </div>
      <div class="request-info" *ngIf="order.latest_schedule_alert">
        <span class="request-info-label">Reported ETA to the upcoming stop:</span> <span class="text-overflow" [title]="order.latest_schedule_alert?.distance_to_stop_in_miles + ' mi, ' + (order.latest_schedule_alert?.eta_to_stop_utc | epoch:'L LT')">{{order.latest_schedule_alert?.distance_to_stop_in_miles}} mi, {{order.latest_schedule_alert?.eta_to_stop_utc | epoch:'L LT'}}</span>
      </div>
      <div class="request-info" *ngIf="order.latest_schedule_alert">
        <span class="request-info-label">Reported scheduled start and end times for the upcoming stop (local time):</span> <span class="text-overflow" [title]="(order.latest_schedule_alert?.scheduled_start_time_in_local_time_for_stop | epoch:'L LT':'utc') + ' - ' + (order.latest_schedule_alert?.scheduled_end_time_in_local_time_for_stop | epoch:'L LT':'utc')">{{order.latest_schedule_alert?.scheduled_start_time_in_local_time_for_stop | epoch:'L LT':'utc'}} - {{order.latest_schedule_alert?.scheduled_end_time_in_local_time_for_stop | epoch:'L LT':'utc'}}</span>
      </div>
    </ng-container>

    <mat-divider />
    <div class="macropoint-messages-container">
      <p class="empty-messages" *ngIf="!order?.messages?.length">No messages have been received yet.</p>
      <table class="table modal-table" *ngIf="displayedMessages.length">
        <tr>
          <th class="col-head bd-bottom timestamp-column">Received at</th>
          <th class="col-head bd-bottom">Message</th>
        </tr>
        <tr *ngFor="let message of displayedMessages">
          <td class="bd-bottom timestamp-column">{{message.received | epoch:'L LT'}}</td>
          <td class="bd-bottom">{{message.message}}</td>
        </tr>
      </table>
      <div class="message-navigation-buttons" *ngIf="order?.messages?.length">
        <button mat-button (click)="showMoreRecentMessages()" [disabled]="currentMessageIndex === 0">Show more recent messages</button>
        <button mat-button (click)="showOlderMessages()" [disabled]="currentMessageIndex + messagePageSize >= order.messages.length">Show older messages</button>
      </div>
    </div>
  </ng-container>
</div>
<mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
</div>
