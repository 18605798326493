import { NgModule } from '@angular/core'
import { FiltersService } from './filters.service'
import { UserStorageService } from './user-storage.service'

@NgModule({
  providers: [
    FiltersService,
    UserStorageService,
  ],
})
export class TableUtilsModule { }
