import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { LocationObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as LocationActions from './location.actions'

export const locationsFeatureKey = 'locations'
export interface LocationsState extends EntityState<DeepReadonly<LocationObject>> {
}

export const selectId = (location: LocationObject) => location.location_id
export const adapter = createEntityAdapter<DeepReadonly<LocationObject>>({ selectId })

const initialState: LocationsState = adapter.getInitialState({
})

export const locationsReducer = createReducer(
  initialState,
  on(LocationActions.loadLocationsSuccess,
    (state, action) =>
      adapter.upsertMany(action.locations, state)),
)

