import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { ModalProxyService } from 'src/shared/modal'
import { environment } from '../environments/environment'
import { AuthApiService } from './auth'

declare const location: any

export function buildAuthHeaders(token: string) {
  return { 'Authorization': `Bearer ${token}` }
}

export const DISABLE_GLOBAL_EXCEPTION_HANDLING = new HttpContextToken<boolean>(() => false)
export const DISABLE_GLOBAL_EXCEPTION_HANDLING_CONTEXT = new HttpContext().set(DISABLE_GLOBAL_EXCEPTION_HANDLING, true)

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  private isRedirecting = false
  private isIgnoreAuthErrorHandling: boolean

  constructor(private auth: AuthApiService, private router: Router, private modalHelper: ModalProxyService,
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.token$.value
    if (token && (request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.auctioneerApiUrl))) {
      request = request.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } })
    }

    this.isIgnoreAuthErrorHandling = !!request.context.get(DISABLE_GLOBAL_EXCEPTION_HANDLING)

    return next.handle(request).pipe(
      tap(response => this.handleResponse(response)),
      catchError(error => this.handleError(error)),
    )
  }

  private handleResponse(response: any): Observable<HttpEvent<any>> {
    this.handleAuthError(response)
    return <Observable<HttpEvent<any>>>of({})
  }

  private handleError(error: any): Observable<HttpEvent<any>> {
    if (this.handleAuthError(error)) return <Observable<HttpEvent<any>>>of({})
    // if (!window.navigator.onLine) {
    //   this.toaster.error('Please check your internet connection.')
    // }
    return throwError(error)
  }

  private handleAuthError(response: HttpResponse<any>) {
    if (this.isIgnoreAuthErrorHandling) {
      return false
    }
    if (![401, 403].includes(response.status)) return false
    this.modalHelper.hideLast()
    if (this.isRedirecting) return true
    if (!this.auth.isAuthlessUrl(response.url)) {
      this.isRedirecting = true
      this.router.navigateByUrl('/login', { state: { redirectTo: location.pathname + location.search + location.hash} })
    }
    return true
  }

}
