import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { ShipmentRate } from '@tradecafe/types/core'
import { identity, uniq } from 'lodash-es'
import { of } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'
import { loadCurrencies, selectCurrency } from 'src/app/store/currencies'
import { ModalService } from 'src/shared/modal/modal.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CopyFreightRatesFormComponent } from './copy-freight-rates-form.component'

@Injectable()
export class CopyFreightRatesFormService {

  constructor(
    private store: Store,
    private modal: ModalService,
    private toaster: ToasterService,
  ) {}

  showBatchChange(rates: ShipmentRate[]) {
    if (!rates.every(({rate}) => rate.currency && (rate.amount || rate.amount === 0))) {
      this.toaster.warning('Please select only rates with both "currency" and "amount" fields set.')
      return of(false)
    }
    const currencyCodes = uniq(rates.map(r => r.rate.currency))
    if (currencyCodes.length > 1) {
      this.toaster.warning('Please select rates with the same currency.')
      return of(false)
    }
    this.store.dispatch(loadCurrencies({}))
    const rateIds = rates.map(r => r.rate_id)
    return this.store.pipe(select(selectCurrency, currencyCodes[0]), filter(identity), take(1), switchMap(currency =>
      this.modal.openDialog(CopyFreightRatesFormComponent, { currency, rateIds }, {width: '300px'})))
  }
}
