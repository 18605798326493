import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { DealsListModule } from 'src/components/deals-list'
import { TableUtilsModule } from 'src/services/table-utils'
import { ModalModule } from 'src/shared/modal'
import { DealAutocopyFormComponent } from './deal-autocopy-form.component'
import { DealAutocopyFormService } from './deal-autocopy-form.service'

@NgModule({
  declarations: [DealAutocopyFormComponent],
  providers: [DealAutocopyFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DealsListModule,
    ModalModule,
    TableUtilsModule,
  ],
})
export class DealAutocopyFormModule {}
