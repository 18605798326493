import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { DealTotalsComponent } from './deal-totals.component'

@NgModule({
  declarations: [DealTotalsComponent],
  exports: [DealTotalsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    PipesModule,
  ],
})
export class DealTotalsModule {}
