import { Injectable } from '@angular/core'
import { Consignee } from '@tradecafe/types/core'
import { identity } from 'lodash-es'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ModalService } from 'src/shared/modal/modal.service'
import { ConsigneeListItem } from '../../pages/admin/settings/consignees/consignees-page.component'
import { ConsigneeFormComponent } from './consignee-form.component'

@Injectable()
export class ConsigneeFormService {
  constructor(private modal: ModalService) { }

  showConsignee(consignee?: Consignee, isSettingsPage?: boolean): Observable<ConsigneeListItem> {
    return this.modal.openDialog(ConsigneeFormComponent, { address: consignee, isSettingsPage }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
      disableClose: true,
    }).pipe(filter(identity))
  }
}
