import { Injectable } from '@angular/core'
import { DEAL_CONFIRMED, Deal, DealPartyE, DealViewBase } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, isDealConfirmed } from '@tradecafe/types/utils'
import { AuthApiService } from 'src/api/auth'
import { DocumentSetApiService } from 'src/api/document-lib/document-set'
import { OperationsApiService } from 'src/api/operations'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { canChangeDealStatusTo } from '../data/deal-view-permissions.service'
import { DealsService } from '../data/deals.service'
import { dayjs } from '../dayjs'
import { DealValidationService } from './deal-validation.service'

@Injectable()
export class DealSubmitService {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private DealValidation: DealValidationService,
    private Deals: DealsService,
    private OperationsApi: OperationsApiService,
    private DocumentSetApi: DocumentSetApiService,
  ) { }


  async submitDeals(deals: DeepReadonly<Array<Deal|DealViewBase>>) {
    if (!deals.length) return
    this.DealValidation.ensureOneParty(deals, DealPartyE.buyer)
    await this.DealValidation.checkBuyerCredit(deals, 'Selected deals are above Buyers credit limit. Get credit override from management or select less deals.')
    await this.DealValidation.askForNegativeMarginReason(deals)
    await this.internalSubmitDeals(deals)
  }

  async submitDeal(deal: DeepReadonly<Deal|DealViewBase>) {
    await this.DealValidation.checkBuyerCredit(deal, `Deal # ${deal.deal_id} is above Buyers credit limit. Get credit override from management.`)
    await this.DealValidation.askForNegativeMarginReason([deal])
    const [res] = await this.internalSubmitDeals([deal])
    return res
  }

  private async internalSubmitDeals(deals: DeepReadonly<Array<Deal | DealViewBase>>) {
    try {
      await this.DocumentSetApi.generateMissingDocument({ deal_ids: deals.map(d => d.deal_id) })
    } catch (err) {
      console.error(`Unable to create documents`, err)
      this.toaster.error(`Unable to create documents`, err)
      throw err
    }
    const res: Deal[] = []
    for (const deal of deals) {
      try {
        res.push(await this.OperationsApi.confirmDeal(deal.deal_id).then(r => r.data))
      } catch (err) {
        console.error(`Unable to submit Deal # ${deal.deal_id}`, err)
        this.toaster.error(`Unable to submit Deal # ${deal.deal_id}`, err)
        throw err
      }
      this.toaster.success(`Deal # ${deal.deal_id} submitted and locked`)
    }
    return res
  }

  /**
   * Confirm multiple dealViews as a partyType (buyer or supplier)
   *
   * @param {*} deals[]
   * @param {*} partyType 'buyer' or 'supplier'
   */
  async confirmDealParty(deals: DeepReadonly<Array<Deal|DealViewBase>>, partyType: DealPartyE) {
    Promise.all(deals.map(async deal => {
      if (await this.Deals.hasDataConflicts(deal)) {
        this.toaster.error('Data conflict detected. Please reload page.')
        throw new Error('Data conflict detected')
      }
    }))

    const { user_id } = this.AuthApi.currentUser
    const now = dayjs().utc().unix()

    const stored = await Promise.all(deals.map(async deal => {
      const patch: DeepPartial<Deal> = {}
      if (partyType === 'buyer') {
        patch.buyer_confirmed = deal.buyer_confirmed || now
        patch.attributes = { buyer_confirmed_by: deal.attributes.buyer_confirmed_by || user_id }
      } else {
        patch.seller_confirmed = deal.seller_confirmed || now
        patch.attributes = { seller_confirmed_by: deal.attributes.seller_confirmed_by || user_id }
      }
      if (!isDealConfirmed(deal) && canChangeDealStatusTo(deal, DEAL_CONFIRMED, this.AuthApi.currentUser)) {
        patch.status = DEAL_CONFIRMED
      }
      return this.Deals.patchImmutableDeal(deal, patch)
      .catch(err => {
        console.error(`Unable to confirm deal ${deal.deal_id}`, err)
        this.toaster.error(`Unable to confirm deal ${deal.deal_id}`, err)
        throw err
      })
    }))
    this.toaster.success(deals.length === 1
      ? `Deal ${deals[0].deal_id} Confirmed`
      : `Deals ${deals.map(d => d.deal_id)} Confirmed`)
    return stored
  }
}
