import { Injectable } from '@angular/core'
import { MatchedOffersService } from 'src/pages/admin/auction/matched-offer-overlay/matched-offers.service'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import {
  accountsResolver, businessTypesResolver, carriersResolver, countriesResolver, itemTypesResolver, locationsResolver,
  measuresResolver, packagingsResolver, pricingTermsResolver, productCategoriesResolver, productsResolver,
  productTypesResolver, usersResolver, weightTypesResolver, wrappingsResolver
} from 'src/services/data/deal.resolvers'
import { ToastedError } from 'src/services/data/utils'
import { ModalProxyService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { DealDocumentsService } from '../deal-documents/deal-documents.service'


@Injectable()
export class CreateDocumentFormService {
  constructor(
    private modalHelper: ModalProxyService,
    private toaster: ToasterService,
    private DealDocuments: DealDocumentsService,
    private DealViewLoader: DealViewLoaderService,
    private MatchedOffers: MatchedOffersService,
  ) {}

  async showCreateDocument(dealId: string) {
    const deal = await this.DealViewLoader.loadView(dealId)
    const documents = await this.DealDocuments.getDocSetForDeal(deal).toPromise()
    .catch((err) => {
      if (!(err instanceof ToastedError)) {
        console.error('Unable to get documents set', err)
        this.toaster.error('Unable to get documents set', err)
      }
      throw err
    })

    return this.modalHelper.showModal({
      component: 'tcCreateDocumentForm',
      windowClass: 'modalclone',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        deal: () => deal,
        documents: () => documents,
        accounts: accountsResolver,
        businessTypes: businessTypesResolver,
        carriers: carriersResolver,
        categories: productCategoriesResolver,
        countries: countriesResolver,
        itemTypes: itemTypesResolver,
        locations: locationsResolver,
        measures: measuresResolver,
        pricingTerms: pricingTermsResolver,
        products: productsResolver,
        productTypes: productTypesResolver,
        productPackages: packagingsResolver,
        users: usersResolver,
        weightTypes: weightTypesResolver,
        wrappings: wrappingsResolver,
        shipmentRates: () => this.DealViewLoader.fetchFreightRatesFor([deal]),
        matchedOffers: () => deal.attributes.matched_offer_id
          ? this.MatchedOffers.getById(deal.attributes.matched_offer_id).then(mo => [mo])
          : [],
      },
    })
  }
}

// // Debug query
// DocumentApi.list({ limit: Number.MAX_SAFE_INTEGER })
// .then(({data: documents}) => {
//   const input = lodash(documents)
//     .map('attributes.requirements')
//     .flatten()
//     .reject('is_row')
//     .map('tag')
//     .uniqBy(tag => tag.toLowerCase())
//     .value()
//     .sort()
//   const rows = lodash(documents)
//     .map('attributes.requirements')
//     .flatten()
//     .filter('is_row')
//     .map(req => req.tag + '.ROW')
//     .uniqBy(tag => tag.toLowerCase())
//     .value()
//     .sort()
//   console.warn('ALLTAGS document content\n', [
//     rows.join('\t'),
//     rows.map(tag => `{${tag}}`).join('\t'),
//     '',
//     input.map(tag => `${tag}\t{${tag}}`).join('\n')
//   ].join('\n'))
// })
