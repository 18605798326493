import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AllInvoiceStatuses, CreditNote, DEAL_CLOSED, DEAL_DELIVERED, Deal, DealStatus, INVOICE_APPROVED, INVOICE_DENIED, INVOICE_PAID, Invoice, TableKey, VendorCredit, VendorCreditStatus, accountBankingPaymentTypes } from '@tradecafe/types/core'
import { DeepReadonly, canActualizeInvoice, canApproveInvoice, canChangeToReviewInvoice, canEscalateInvoice, canPaidInvoice, canRejectInvoice, canScheduleInvoice, canUnapproveInvoice, canUnscheduleInvoice, canVoidInvoice, isInvoicePaid } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, find, keyBy, uniq } from 'lodash-es'
import { Observable, ReplaySubject, from } from 'rxjs'
import { map, mergeMap, toArray } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { InvoiceRow, InvoiceTotalsDto } from 'src/api/invoice'
import { OperationsApiService } from 'src/api/operations'
import { setInvoiceInv, setInvoicePaidDate, setInvoicePaymentType } from 'src/app/store/invoice-view/invoice-view.actions'
import { VendorCreditFormService } from 'src/components/credits/vendor-credit/vendor-credit-form.service'
import { InvoiceFormService } from 'src/components/invoices/invoice-form/invoice-form.service'
import { InvoiceViewFormService } from 'src/components/invoices/invoice-view-form/invoice-view-form.service'
import { environment } from 'src/environments/environment'
import { BuyerInvoiceReceiptsService } from 'src/pages/admin/management/buyer-invoices/receipts/buyer-invoice-receipts.service'
import { DealDetailsOverlayService } from 'src/pages/admin/trading/deals/list/actions/deal-details-overlay/deal-details-overlay.service'
import { DealFinanceOverlayService } from 'src/pages/admin/trading/deals/list/actions/deal-finance-overlay/deal-finance-overlay.service'
import { ShipmentDetailsOverlayService } from 'src/pages/admin/trading/deals/list/actions/shipment-details-overlay/shipment-details-overlay.service'
import { CompanyCreditFormService } from 'src/services/actions/company.service'
import { InvoiceFormsService } from 'src/services/actions/invoice-forms.service'
import { composeContainerTrackingUrl } from 'src/services/data/compose-container-tracking-url'
import { DealsService } from 'src/services/data/deals.service'
import { ElasticSearchFilters } from 'src/services/elastic-search'
import { FiltersFormGroup } from 'src/services/table-utils'
import { StatefulDataSource } from 'src/services/table-utils/data-sources/stateful-data-source'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CreditNoteFormService } from '../../credits/credit-note-form/credit-note-form.service'
import { InlineEditorComponent } from '../../inline-editor/inline-editor.component'
import { NoteFormService } from '../../notes/note-form/note-form.service'
import { NotesOverlayService } from '../../notes/notes-overlay/notes-overlay.service'
import { AVAILABLE_COLUMNS, DEFAULT_COLUMNS, InvoicesListColumn, columnNames } from './invoices-list.columns'
import { INVOICES_FILTER_SETTINGS } from './invoices-list.filters'

const { tradecafeAccount } = environment

@Component({
  selector: 'tc-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesListComponent extends OnDestroyMixin implements OnInit {

  readonly TableKey = TableKey
  readonly DealStatus = DealStatus
  readonly CreditNoteStatus = VendorCreditStatus
  readonly InvoiceStatus = AllInvoiceStatuses
  readonly INVOICES_FILTER_SETTINGS = INVOICES_FILTER_SETTINGS
  readonly tradecafeAccount = tradecafeAccount.toString()

  constructor(
    private router: Router,
    private toaster: ToasterService,
    private InvoiceViewForm: InvoiceViewFormService,
    private NotesOverlay: NotesOverlayService,
    private DealDetailsOverlay: DealDetailsOverlayService,
    private ShipmentDetailsOverlay: ShipmentDetailsOverlayService,
    private DealFinanceOverlay: DealFinanceOverlayService,
    private CompanyCreditForm: CompanyCreditFormService,
    private BuyerInvoiceReceipts: BuyerInvoiceReceiptsService,
    private AuthApi: AuthApiService,
    private OperationsApi: OperationsApiService,
    private InvoiceForm: InvoiceFormService,
    private InvoiceForms: InvoiceFormsService,
    private Deals: DealsService,
    private store: Store,
    private CreditNoteForm: CreditNoteFormService,
    private VendorCreditForm: VendorCreditFormService,
    private NoteForm: NoteFormService
  ) { super() }

  @HostBinding('class')
  get hostClasses() {
    return `${this.class} tc-invoices-list tc-data-${this.dataSource.state$.value}`
  }

  get displayColumns() { return this._displayColumns }

  @Input()
  set displayColumns(value: InvoicesListColumn[]) {
    this._displayColumns = value
    this.filterColumns = this._displayColumns.map(col => `${col}-filter`)
    this.totalColumns = value.map(colName => `${colName}_footer`)
    this.displayColumns$.next(value)
  }
  @Input()
  set availableColumns(value: InvoicesListColumn[]) {
    this.availableColumnFilters = value.map(colName => ({
      colName, filterName: colName === 'deal_id' ? 'deal_ids' : colName}))
    this.availableTotalColumns = value.map(colName => `${colName}_footer`)
  }


  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator) }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.setSort(sort) }


  @Input()
  class = ''

  @Input()
  footer = true

  private displayColumns$ = new ReplaySubject<string[]>(1)
  private _displayColumns: InvoicesListColumn[]

  @Input()
  tableIdentity: TableKey
  get isBuyerInvoicesPage() {
    return this.tableIdentity === TableKey.FinancialBuyerInvoices ||
      this.tableIdentity === TableKey.TradingBuyerInvoices ||
      this.tableIdentity === TableKey.LogisticsBuyerInvoices ||
      this.tableIdentity === TableKey.ManagementBuyerInvoices
  }

  @Input()
  filtersForm: FiltersFormGroup<any>

  @Input()
  dataSource: StatefulDataSource<InvoiceRow>

  @Input()
  readonly: boolean

  filterColumns: string[] = []

  availableColumnFilters: { colName: string, filterName: string }[]
  totalColumns: string[]
  availableTotalColumns: string[]

  @Input()
  columnNames: Dictionary<string>

  @Input()
  filtersData: ElasticSearchFilters

  @Input()
  selectAll = true

  @Input()
  displayCurrency = false

  @Input()
  formatCurrency = false

  @Input()
  back: string

  @Input()
  navigateTo: string

  @Output()
  rowClick = new EventEmitter<{ invoice: InvoiceRow, column?: string, tabName?: string }>()
  isClickable: boolean

  @Output()
  selectionChange = new EventEmitter<InvoiceRow[]>()

  @Output()
  applyFilters = new EventEmitter<void>()

  @Output()
  editColumns = new EventEmitter<void>()

  @ViewChild(InlineEditorComponent)
  inlineEditor: InlineEditorComponent

  selection = new TableSelection<InvoiceRow>('invoice_id')

  @Input()
  set totals$(value: Observable<InvoiceTotalsDto>) {
    this.totalsRows$ = value.pipe(map(totals => {
      const result = {
        currency: this.currencies,
        actl_amount_cad: this.currencies.map(currency => currency === 'CAD' ? totals?.actl_amount_cad : undefined),
        partial_margin: this.currencies.map(currency => currency === 'CAD' ? totals?.partial_margin : undefined),
        actl_amount: this.currencies.map(currency => find(totals?.actl_amount, { currency })?.amount),
        est_amount: this.currencies.map(currency => find(totals?.est_amount, { currency })?.amount),
        net_payable: this.currencies.map(currency => find(totals?.net_payable, { currency })?.amount),
        rmng_amount: this.currencies.map(currency => find(totals?.rmng_amount, { currency })?.amount),
        sum_pmts_applied: this.currencies.map(currency => find(totals?.sum_pmts_applied, { currency })?.amount),
        variance: this.currencies.map(currency => find(totals?.variance, { currency })?.amount),
      }
      const notEmpty = this.currencies
        .map((_, i) => Object.keys(result).some(key => result[key][i] && typeof result[key][i] === 'number') ? i : undefined)
        .filter(i => i !== undefined)
        .reverse()
      Object.keys(result).forEach(key => result[key] = result[key].filter((_, i) => notEmpty.includes(i)))
      return result
    }))
  }

  private readonly currencies = ['CAD', 'USD', 'AUD', 'MXN', 'EUR', 'GBP', 'INR']
  totalsRows$: Observable<{
    // displayColumns: string[]
    currency: string[]
    actl_amount_cad: number[]
    partial_margin: number[]
    actl_amount: number[]
    est_amount: number[]
    net_payable: number[]
    rmng_amount: number[]
    sum_pmts_applied: number[]
    variance: number[]
  }>


  composeContainerTrackingUrl = composeContainerTrackingUrl

  @Output()
  uploadAttachment = new EventEmitter<DeepReadonly<Invoice>>()

  @Output()
  creditChanged = new EventEmitter<void>()

  canChangeToReview = (invoice: DeepReadonly<Invoice>) => canChangeToReviewInvoice(invoice, this.AuthApi.currentUser)
  canActualize = (invoice: DeepReadonly<Invoice>) => canActualizeInvoice(invoice, this.AuthApi.currentUser)
  canReject = (invoice: DeepReadonly<Invoice>) => canRejectInvoice(invoice, this.AuthApi.currentUser)
  canEscalate = (invoice: DeepReadonly<Invoice>) => canEscalateInvoice(invoice, this.AuthApi.currentUser)
  canVoid = (invoice: DeepReadonly<Invoice>) => canVoidInvoice(invoice, this.AuthApi.currentUser)
  canApprove = (invoice: DeepReadonly<Invoice>) => canApproveInvoice(invoice, this.AuthApi.currentUser)
  canUnapprove = (invoice: DeepReadonly<Invoice>) => canUnapproveInvoice(invoice, this.AuthApi.currentUser)
  canPaid = (invoice: DeepReadonly<Invoice>) => canPaidInvoice(invoice, this.AuthApi.currentUser)
  canSchedule = (invoice: DeepReadonly<Invoice>) => canScheduleInvoice(invoice, this.AuthApi.currentUser)
  canUnschedule = (invoice: DeepReadonly<Invoice>) => canUnscheduleInvoice(invoice, this.AuthApi.currentUser)

  canCreateBankCharge = (invoice: DeepReadonly<InvoiceRow>) =>
    this.isInvoiceManager(invoice) && invoice.view.rmng_amount > 0 && invoice.view.rmng_amount <= environment.bankChargeMax
  canEditInv = (invoice: DeepReadonly<InvoiceRow>) =>
    invoice && this.tableIdentity === TableKey.LogisticsVendorInvoices
  canEditPaymentType = (invoice: InvoiceRow) =>
    invoice &&
    this.tableIdentity === TableKey.FinancialPayables &&
    ['accounting', 'manager', 'administrator', 'superuser'].includes(this.AuthApi.currentUser.role) &&
    invoice.status !== INVOICE_DENIED && invoice.status !== INVOICE_PAID
  canEditPaid = (invoice: InvoiceRow) =>
    invoice &&
    this.tableIdentity === TableKey.FinancialPayables &&
    ['accounting', 'manager', 'administrator', 'superuser'].includes(this.AuthApi.currentUser.role) &&
    [INVOICE_APPROVED, INVOICE_PAID].includes(invoice.status)

  getRowId = (i: number, invoice: DeepReadonly<Invoice>) =>
    // undefined invoice = fake. loading screen.
    invoice?.invoice_id || i
  getDealUrl = (dealId: string) =>
    this.navigateTo + '/' + dealId


  ngOnInit() {
    this.isClickable = !!this.rowClick.length

    if (this.tableIdentity) {
      if (!this.displayColumns) this.displayColumns = DEFAULT_COLUMNS[this.tableIdentity]
      if (!this.availableColumns) this.availableColumns = AVAILABLE_COLUMNS[this.tableIdentity]
      if (!this.columnNames) this.columnNames = columnNames(this.tableIdentity)
    }

    this.selection.selectedIds$.pipe(untilComponentDestroyed(this))
    .subscribe(ids => {
      const selectedRows = this.dataSource.data.filter(row => row && ids.includes(row.invoice_id))
      this.selectionChange.emit(selectedRows)
    })
  }

  onScroll(index: number) {
    if (this.dataSource['onScroll']) return this.dataSource['onScroll'](index)
  }

  openDealForm(invoice: InvoiceRow) {
    const back = this.back ? `?back=${this.back}` : ''
    this.router.navigateByUrl(this.getDealUrl(invoice.deal_id) + back)
  }

  async showInvoiceItem(invoice: InvoiceRow) {
    // // TODO: implement immutable version of InvoiceViewForm.showInvoice
    const { view, viewEx, ...copy } = cloneDeep(invoice) as InvoiceRow
    const invoiceView = { ...copy, deal: view.deal }

    const refresh = () => this.dataSource.refresh()
    await this.InvoiceViewForm.showInvoice(invoiceView as Invoice & { deal: Deal }).then(refresh, refresh)
  }

  showInvoiceNotes(invoice: InvoiceRow) {
    this.NotesOverlay.showInvoiceNotes({ deal_id: invoice.deal_id } as Deal, invoice)
      .subscribe(() => this.dataSource.refresh())
  }

  async showCreateBankCharge(invoice: InvoiceRow) {
    await this.InvoiceForms.createBankChargeForBuyerInvoice(invoice.invoice_id)
    this.dataSource.refresh()
  }

  async showUndoBankCharge(invoice: InvoiceRow) {
    await this.InvoiceForms.undoBankChargeForBuyerInvoice(invoice.invoice_id)
    this.dataSource.refresh()
  }

  showDealDetails({deal_id}: InvoiceRow) {
    this.DealDetailsOverlay.showDealDetails(deal_id)
  }

  showShipmentDetails(invoice: InvoiceRow) {
    this.ShipmentDetailsOverlay.showShipmentDetails(invoice.deal_id).subscribe()
  }

  showFinanceDetails({deal_id}: InvoiceRow) {
    this.DealFinanceOverlay.showFinanceDetails(deal_id)
  }

  showCompanyCredit(invoice: DeepReadonly<InvoiceRow>) {
    this.CompanyCreditForm.showCompanyCredit(invoice.view.buyer.account)
  }

  showReceipts(invoice: InvoiceRow) {
    this.BuyerInvoiceReceipts.showReceipts(invoice)
  }

  async showAddInfoToItem(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.showAddInfoToItem
    const { view, viewEx, ...copy } = cloneDeep(invoice) as InvoiceRow
    await this.InvoiceForms.showAddInfoToItem(copy as Invoice)
    this.dataSource.refresh()
  }

  async showActualizeInvoice(invoice: InvoiceRow) {
    await this.InvoiceForm.actualizeInvoice(invoice)
    this.dataSource.refresh()
  }

  async showUpdateVoid(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.void
    const { view, viewEx, ...copy } = cloneDeep(invoice) as InvoiceRow
    await this.InvoiceForms.void(copy as Invoice)
    this.dataSource.refresh()
  }

  async showApproveInvoice(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.approveSelectedInvoices
    const { view, viewEx, ...copy } = cloneDeep(invoice) as InvoiceRow
    await this.InvoiceForms.showApproveSelectedInvoices([copy as Invoice])
    this.dataSource.refresh()
  }

  async showUnapproveInvoice(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.unapproveSelectedInvoices
    const { view, viewEx, ...copy } = cloneDeep(invoice)
    await this.InvoiceForms.showUnapproveSelectedInvoices([copy as Invoice])
    this.dataSource.refresh()
  }

  async showRejectInvoice(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.updateStatus
    const { view, viewEx, ...copy } = cloneDeep(invoice)
    await this.InvoiceForms.updateStatus(copy as Invoice, INVOICE_DENIED, 'rejected')
    this.dataSource.refresh()
  }

  async showEscalateInvoice(invoice: InvoiceRow) {
    await this.InvoiceForms.showEscalateInvoice(invoice)
    this.dataSource.refresh()
  }

  async showPaid(invoice: InvoiceRow) {
    await this.InvoiceForm.updateInvoiceStatus(invoice, INVOICE_PAID, 'paid')
    this.dataSource.refresh()
  }

  async showPaidInvoice(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.updateStatus
    const { view, viewEx, ...copy } = cloneDeep(invoice)
    await this.InvoiceForms.updateStatus(copy as Invoice, INVOICE_PAID, 'paid')
    this.dataSource.refresh()
  }

  async showScheduleItem(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.schedule
    const { view, viewEx, ...copy } = cloneDeep(invoice) as InvoiceRow
    await this.InvoiceForms.schedule(copy as Invoice)
    this.dataSource.refresh()
  }

  async showUnscheduleItem(invoice: InvoiceRow) {
    // TODO: implement immutable version of InvoiceForms.unschedule
    const { view, viewEx, ...copy } = cloneDeep(invoice) as InvoiceRow
    await this.InvoiceForms.unschedule(copy as Invoice)
    this.dataSource.refresh()
  }

  async showCreditNoteItem(row: InvoiceRow) {
    const creditNote = await this.CreditNoteForm.showUpdateItemImmutable(
      creditWithDealCosts(row.viewEx.creditNote, row))
    if (creditNote) this.dataSource.refresh()
  }

  async showVendorCreditItem(row: InvoiceRow) {
    const vendorCredit = await this.VendorCreditForm.showUpdateItemImmutable(
      creditWithDealCosts(row.viewEx.vendorCredit, row))
    if (vendorCredit) this.dataSource.refresh()
  }

  async showVoidCreditNote(row: InvoiceRow) {
    await this.CreditNoteForm.showVoidCreditNoteImmutable(
      creditWithDealCosts(row.viewEx.creditNote, row))
    this.dataSource.refresh()
  }

  async showVoidVendorCredit(row: InvoiceRow) {
    await this.VendorCreditForm.showVoidVendorCreditImmutable(
      creditWithDealCosts(row.viewEx.vendorCredit, row))
    this.dataSource.refresh()
  }

  showCreditNoteNotes(credit: DeepReadonly<CreditNote>) {
    this.NotesOverlay.showCreditNoteNotes(credit).subscribe()
  }

  showVendorCreditNotes(credit: DeepReadonly<VendorCredit>) {
    this.NotesOverlay.showVendorCreditNotes(credit)
  }

  editInvoiceInv(invoice: InvoiceRow, cellEl: HTMLElement) {
    if (!this.canEditInv(invoice)) return
    this.inlineEditor.editText(() => invoice.inv, cellEl).then((inv: string) => {
      this.store.dispatch(setInvoiceInv({ invoice, inv }))
    })
  }

  editInvoicePaymentType(invoice: InvoiceRow, cellEl: HTMLElement) {
    if (!this.canEditPaymentType(invoice)) return
    this.inlineEditor.selectOption({
      items: accountBankingPaymentTypes,
      bindLabel: 'label',
      bindValue: 'id',
    }, () => invoice.attributes?.payment_type, cellEl).then((paymentType: string) => {
      this.store.dispatch(setInvoicePaymentType({ invoice, paymentType }))
    })
  }

  editInvoicePaid(invoice: InvoiceRow, el: HTMLElement) {
    if (!this.canEditPaid(invoice)) return
    this.inlineEditor.pickDate(el, () => ({
      date: invoice.attributes?.paid,
      useUtc: false,
    })).then(date => {
      this.store.dispatch(setInvoicePaidDate({ invoice, date }))
    })
  }

  async closeDeals(invoices: DeepReadonly<InvoiceRow[]>) {
    if (!invoices.length) return
    if (!this.dealsArentClosed(invoices)) {
      this.toaster.warning('This deal has already been closed.')
      return
    }
    if (!this.canCloseDeals(invoices)) {
      this.toaster.warning('Can not close deal before it is delivered and buyer invoice is paid.')
      return
    }
    try {
      const dealIds = uniq(invoices.map(invoice => invoice.deal_id))
      await Promise.all(dealIds.map(dealId => this.OperationsApi.closeDeal(dealId)))
      this.dataSource.refresh()
      this.toaster.success('Deals closed successfully!')
    } catch (err) {
      console.error('Unable to close deal!', err)
      this.toaster.error('Unable to close deal!', err)
    }
  }

  protected canCloseDeal(invoice: DeepReadonly<InvoiceRow>) {
    return isInvoicePaid(invoice) && invoice.view.status === DEAL_DELIVERED
  }

  private dealsArentClosed(invoices: DeepReadonly<InvoiceRow[]>) {
    return invoices.length && invoices.every(invoice => invoice.view.status !== DEAL_CLOSED)
  }

  protected canViewInvoice(invoice: DeepReadonly<InvoiceRow>) {
    return this.tableIdentity !== TableKey.TradingBuyerInvoices || this.isInvoiceManager(invoice);
  }

  protected isInvoiceManager(invoice: DeepReadonly<InvoiceRow>) {
    return this.AuthApi.currentUser.role !== 'trader' ||
            invoice.viewEx?.company?.manager === this.AuthApi.currentUser.user_id ||
            invoice.view?.selling_trader.user_id === this.AuthApi.currentUser.user_id;
  }

  canCloseDeals(invoices: DeepReadonly<InvoiceRow[]>) {
    return invoices.length && invoices.every(invoice => this.canCloseDeal(invoice))
  }

  addMultipleNotes() {
    // get invoices from the datasource
    const byId = keyBy(this.dataSource.data, 'invoice_id')
    let items = this.selection.selectedIds$.value.map(invoiceId => byId[invoiceId])
    let invoice_ids = Array.from(this.selection.selectedIds.keys())
    //
    // add multiple notes
    let dealIds = uniq(items.map((item) => item.deal_id))
    from(dealIds).pipe(
      mergeMap((dealId) => this.Deals.getDealView(
        dealId,
        ['deal', 'costs', 'segments', 'invoices', 'files', 'credit_notes', 'vendor_credits', 'notes'],
        { keepVoidedCredits: true }
      )),
      toArray()
    ).subscribe(async (dealViewRaws) => {
      try {
        await this.NoteForm.addMultipleNotes(dealViewRaws, null, { isBulkAdd: true, categoryIds: invoice_ids })
      } catch (error) {
        this.toaster.error(`Save multiple notes failed due to: ${error}`)
      }
    })
    //
  }
}

function creditWithDealCosts<T>(credit: T, row: InvoiceRow) {
  return { ...credit, deal: { ...row.viewEx.dv.deal, costs: row.viewEx.dv.costs } }
}
