import { TableIdentities } from '../constants/table-identities'
import {
  accountsResolver,
  countriesResolver,
  currenciesResolver,
  usersResolver
} from '../services/data/deal.resolvers'
import { tableViewsResolver } from '../services/data/table-views.service'


export const routes = [
  {
    name: 'app.logistics',
    // parent: 'app',
    url: '/logistics',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['logistics', 'administrator', 'junior-administrator', 'manager', 'superuser'],
      title: 'Logistics',
    },
  }, {
    name: 'app.logistics.default',
    // parent: 'app.logistics',
    url: '',
    redirectTo: 'app.logistics.shipping_logs',
  }, {
    name: 'app.logistics.client_shipping_log',
    // parent: 'app.logistics',
    url: '/client-shipping-log',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Client Shipping log',
    },
  },
  {
    name: 'app.logistics.freight_rates',
    // parent: 'app.logistics',
    url: '/freight-rates',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Freight Rates',
    },
  },
  {
    name: 'app.logistics.freight-rate-importer',
    // parent: 'app.logistics',
    url: '/freight-rate-importer',
    component: 'tcFreightRateImporterForm',
    data: {
      title: 'Freight Rates CSV Importer',
    },
    resolve: {
      countries: countriesResolver,
    },
  }, {
    name: 'app.logistics.shipping_logs',
    url: '/shipping-log',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Shipping Log',
    },
  }, {
    name: 'app.logistics.shipping_log-detail',
    url: '/shipping-log/:deal_id?back',
    component: 'tcMockUpgradedPage',
    data: {
      roles: ['logistics', 'administrator', 'junior-administrator', 'manager', 'trader', 'superuser'],
      title: 'Shipping Log Detail',
    },
  }, {
    name: 'app.logistics.transportations',
    // parent: 'app.logistics',
    url: '/transportations',
    component: 'adminLogisticsTransportationList',
    data: {
      title: 'Transportation Summary',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.logisticsTransportationList,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.logistics.vendor-invoices',
    url: '/vendor-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Vendor Invoices',
    },
  }, {
    name: 'app.logistics.buyer-invoices',
    url: '/buyer-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Buyer Invoices',
    },
  }, {
    name: 'app.logistics.credit-notes',
    // parent: 'app.logistics',
    url: '/credit-notes',
    component: 'adminLogisticsCreditNoteList',
    data: {
      title: 'Credit Notes',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.logisticsCreditNoteList,
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.logistics.vendor-credits-importer',
    // parent: 'app.logistics',
    url: '/vendor-credits-importer',
    component: 'tcVendorCreditsImporterForm',
    data: {
      title: 'Vendor Credits CSV Importer',
    },
    resolve: {
      back: () => 'app.logistics.vendor-credits'
    },
  }, {
    name: 'app.logistics.vendor-credits',
    // parent: 'app.logistics',
    url: '/vendor-credits',
    component: 'adminLogisticsVendorCreditList',
    data: {
      title: 'Vendor Credits',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.logisticsVendorCreditList,
      importCsvRoute: () => 'app.logistics.vendor-credits-importer',
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.logistics.documents',
    // parent: 'app.logistics',
    url: '/documents',
    component: 'tcDocumentsPage',
    data: {
      title: 'Documents',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.logisticsDocuments,
      tableViews: tableViewsResolver,
      accounts: accountsResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.logistics.ar-report',
    url: '/ar-report',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'AR Report',
    },
  },
]
