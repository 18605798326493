import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { DocumentOverrideApiService } from 'src/api/document-lib/document-override'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { createDocumentOverride, createDocumentOverrideFailure, createDocumentOverrideSuccess, deleteDocumentOverride, deleteDocumentOverrideFailure, deleteDocumentOverrideSuccess, loadDocumentOverrides, loadDocumentOverridesFailure, loadDocumentOverridesSuccess, updateDocumentOverride, updateDocumentOverrideFailure, updateDocumentOverrideSuccess } from './document-overrides.actions'

@Injectable()
export class DocumentOverrideEffects {
  constructor(
    private actions$: Actions,
    private DocumentOverrideApi: DocumentOverrideApiService,
    private toaster: ToasterService,
  ) {}

  loadDocumentOverrides$ = createEffect(() => this.actions$.pipe(
    ofType(loadDocumentOverrides),
    switchMap(action =>
      from(this.DocumentOverrideApi.list()).pipe(
        map(r => loadDocumentOverridesSuccess({
          tableKey: action.tableKey,
          documentOverrides: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch documentOverrides', error)
          this.toaster.error('Unable to fetch documentOverrides', error)
          return of(loadDocumentOverridesFailure({ error }))
        })))))

  createDocumentOverride$ = createEffect(() => this.actions$.pipe(
    ofType(createDocumentOverride),
    switchMap(action =>
      from(this.DocumentOverrideApi.create(action.override)).pipe(
        map(r => createDocumentOverrideSuccess({ override: r.data })),
        catchError(error => {
          console.error('Unable to create document override', error)
          this.toaster.error('Unable to create document override', error)
          return of(createDocumentOverrideFailure({ error }))
        })))))

  updateDocumentOverride$ = createEffect(() => this.actions$.pipe(
    ofType(updateDocumentOverride),
    switchMap(action =>
      from(this.DocumentOverrideApi.update(action.id, action.override)).pipe(
        map(r => updateDocumentOverrideSuccess({ override: r.data })),
        catchError(error => {
          console.error('Unable to update document override', error)
          this.toaster.error('Unable to update document override', error)
          return of(updateDocumentOverrideFailure({ error }))
        })))))

  deleteDocumentOverride$ = createEffect(() => this.actions$.pipe(
    ofType(deleteDocumentOverride),
    switchMap(action =>
      from(this.DocumentOverrideApi.delete(action.id)).pipe(
        map(r => deleteDocumentOverrideSuccess({ id: action.id })),
        catchError(error => {
          console.error('Unable to delete document override', error)
          this.toaster.error('Unable to delete document override', error)
          return of(deleteDocumentOverrideFailure({ error }))
        })))))
}
