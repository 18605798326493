import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal'
import { SupplierOfferOverlayComponent } from './supplier-offer-overlay.component'


@Injectable({ providedIn: 'root' })
export class SupplierOfferOverlayService {
  constructor(
    private modal: ModalService,
  ) {}


  public addOffer(offer?) {
    return this.show(offer, {
      title: 'Create Supplier Offer',
      isCreation: true,
    })
  }

  public duplicateOffer(offer) {
    return this.show(offer, {
      title: 'Duplicate Supplier Offer',
      redirect: 'app.trading.supplier-offers',
    })
  }

  public editOffer(offer) {
    return this.show(offer, {
      title: 'Supplier Offer Details',
    })
  }

  public cloneOffer(offer) {
    return this.show(offer, {
      title: 'Clone Supplier Offer',
      cloneOffer: true,
    })
  }

  public addMatchedOffers(offer, excludedBuyerIds?: string[]) {
    return this.show(offer, {
      title: 'Add Matched Offers',
      addMOs: true,
      excludedBuyerIds,
    })
  }

  private show(offer, options?) {
    return this.modal.openLargeSide(SupplierOfferOverlayComponent, {
      offer,
      options,
    })
  }
}
