<div mat-dialog-title>
  <h2 fxFlex>{{dealId}} Documents</h2>
  <tc-select-search placeholder="Party" [readonly]="isReadonly" [multiple]="true" style="width: 200px"
      [group]="documentsFilterForm" ctrlName="party"
      [items]="documentsPartyType" bindLabel="name" bindValue="id"
      ></tc-select-search>
  <button mat-raised-button color="primary" type="button" *ngIf="!isReadonly && this.canCreate" (click)="showCreateDocument()">
    <i class="fa fa-fw fa-cloud-arrow-up"></i> Create
  </button>
  <button mat-raised-button color="primary" type="button" *ngIf="!isReadonly && this.canUpload" (click)="showUploadDocument()">
    <i class="fa fa-fw fa-cloud-arrow-up"></i> Upload
  </button>
  <button mat-raised-button color="primary" type="button" *ngIf="!isReadonly" [disabled]="canNotMergeDocuments$ | async" (click)="mergeToPDF()">
    <i class="fa fa-fw fa-file-pdf"></i> Merge PDF
  </button>
</div>
<div mat-dialog-content>
  <tc-files-list #filesList [readonly]="isReadonly" [style.display]="filesDataSource.data?.length ? '' : 'none'"
      [tableIdentity]="filesTableKey"
      [dataSource]="filesDataSource"
      (addNote)="showAddFileNote($event)"
      ></tc-files-list>
</div>
