import { createSelector } from '@ngrx/store'
import { ProductPackaging } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, packageTypesFeatureKey } from './package-type.reducer'

export const selectState = (state: State) => state[packageTypesFeatureKey]

export const { selectEntities: selectPackageTypeEntities, selectAll: selectAllPackageTypes } = adapter.getSelectors(selectState)

export const selectPackageType = createSelector(
  selectPackageTypeEntities,
  (packageTypes: Dictionary<ProductPackaging>, packageType: string | number) =>
    packageTypes[packageType])

export const selectPackageTypesById = createSelector(
  selectPackageTypeEntities,
  (packageTypes: Dictionary<ProductPackaging>, packageTypeId: string) =>
    packageTypes[packageTypeId])

export const selectPackageTypesByIds = createSelector(
  selectPackageTypeEntities,
  (packageTypes: Dictionary<ProductPackaging>, packageTypeIds: string[]) =>
    packageTypeIds.map(packageTypeId => packageTypes[packageTypeId]))
