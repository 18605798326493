import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Booking, MontshipBookingRequest, Unlocode } from '@tradecafe/types/core'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export interface CreateBookingDto {
  deal_id: string
  request: MontshipBookingRequest
}


@Injectable()
export class BookingsApiService {
  constructor(private http: HttpClient) { }

  sendRequest(data: CreateBookingDto) {
    return this.http.post<{ data: Booking }>(`${apiUrl}/bookings/send-request`, data)
  }

  approve(bookingId: string) {
    return this.http.post<Booking>(`${apiUrl}/bookings/${bookingId}/approve`, undefined)
  }

  reject(bookingId: string) {
    return this.http.post<Booking>(`${apiUrl}/bookings/${bookingId}/reject`, undefined)
  }

  putOnHold(bookingId: string) {
    return this.http.post<Booking>(`${apiUrl}/bookings/${bookingId}/on-hold`, undefined)
  }

  // TODO: move to some other service
  getUnlocode(countryCode: string, stateCode: string, name: string) {
    let requestUrl = `${apiUrl}/unlocodes/${countryCode}`;
    if(stateCode) {
      requestUrl = `${requestUrl}/${stateCode}`;
    }
    return this.http.get<Unlocode[]>(requestUrl, { params: { name }})
  }
}
