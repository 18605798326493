import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Deal, DealBase, DealViewDto, DealViewRaw } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { Subject } from 'rxjs'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'
import { DealFormDto } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'


export function registerNg1(module) {
  return module.service('DealApi', downgradeInjectable(DealApiService))
}


const { apiUrl } = environment


@Injectable()
@AngularCopy()
export class DealApiService {
  dealUpdated$ = new Subject<string>()

  constructor(private http: HttpClient) {}

  list(query?) {
    if (query?.limit < Number.MAX_SAFE_INTEGER) {
      return this._list(query)
    }
    return this.massList(query)
  }

  @HaveAngularParams()
  private _list(@AngularParams() params?) {
    return this.http.get<{ data: Deal[] }>(`${apiUrl}/deals`, { params }).toPromise()
  }

  private async massList(query = {}) {
    const allDeals = []
    for (let i = 0; true; i++) {
      const { data: deals } = await this.list({ ...query, limit: 1000, skip: i * 1000 })
      if (!deals.length) break
      allDeals.push(...deals)
    }
    return { data: allDeals }
  }

  @HaveAngularParams()
  search(@AngularParams() params?) {
    return this.http.get<{ data: string[] }>(`${apiUrl}/deals/search_advanced`, { params }).toPromise()
  }

  searchByDealId(params?) {
    return this.http.post<{ data: Deal[] }>(`${apiUrl}/deals/search-by-deal-id`, {limit: Number.MAX_SAFE_INTEGER, ...params}).toPromise()
  }

  @HaveAngularParams()
  getForAccount(account: number|string, @AngularParams() params?) {
    return this.http.get<{ data: Deal[] }>(`${apiUrl}/deals/account/${account}`, { params }).toPromise()
  }

  // @HaveAngularParams()
  // create(data: Partial<Deal>, @AngularParams() params?) {
  //   return this.http.post<{ data: Deal }>(`${apiUrl}/deals`, data, { params }).toPromise()
  // }

  calculateMargin(data) {
    return this.http.post<{ data: any }>(`${apiUrl}/deals/calculate-margin`, data).toPromise()
  }

  get(dealId: string) {
    return this.http.get<{ data: Deal }>(`${apiUrl}/deals/${dealId}`).toPromise()
  }

  @HaveAngularParams()
  update(dealId: string, data: DeepReadonly<Partial<Deal|DealBase>>, @AngularParams() params?) {
    this.dealUpdated$.next(dealId)
    // TODO: drop account id parameter. BE is ready
    return this.http.put<{ data: Deal }>(`${apiUrl}/deals/${dealId}`, data, { params }).toPromise()
  }

  // @HaveAngularParams()
  // addBid(account: number|string, dealId: string, bidId: string, @AngularParams() params?) {
  //   return this.http.post<{ data: Deal }>(`${apiUrl}/deals/bid/${account}/${dealId}/${bidId}`, {}, { params }).toPromise()
  // }

  // @HaveAngularParams()
  // addOffer(account: number|string, dealId: string, offerId: string, @AngularParams() params?) {
  //   return this.http.post<{ data: Deal }>(`${apiUrl}/deals/offer/${account}/${dealId}/${offerId}`, {}, { params }).toPromise()
  // }

  // @HaveAngularParams()
  // removeBid(account: number|string, dealId: string, bidId: string, @AngularParams() params?) {
  //   return this.http.delete<{ data: Deal }>(`${apiUrl}/deals/bid/${account}/${dealId}/${bidId}`, { params }).toPromise()
  // }

  // @HaveAngularParams()
  // removeOffer(account: number|string, dealId: string, offerId: string, @AngularParams() params?) {
  //   return this.http.delete<{ data: Deal }>(`${apiUrl}/deals/offer/${account}/${dealId}/${offerId}`, { params }).toPromise()
  // }

  // delete(dealId: string) {
  //   return this.http.delete<{ data: Deal }>(`${apiUrl}/deals/${dealId}`).toPromise()
  // }

  lock(dealId: string) {
    return this.http.put<{ data: Deal }>(`${apiUrl}/deals/${dealId}/lock`, {})
  }

  unlock(dealId: string) {
    return this.http.put<{ data: Deal }>(`${apiUrl}/deals/${dealId}/unlock`, {})
  }

  unwind(dealId: string) {
    return this.http.post<{ data: Deal }>(`${apiUrl}/deals/${dealId}/unwind`, {}).toPromise()
  }

  sendInstruction(payload) {
    return this.http.post(`${apiUrl}/deals/send-instruction`, payload).toPromise()
  }

  byDealIds(deal_ids: DeepReadonly<string[]>) {
    return this.http.post<{ data: Deal[] }>(`${apiUrl}/deals/search-by-deal-id?limit=${Number.MAX_SAFE_INTEGER}`, {
      deal_ids,
      limit: Number.MAX_SAFE_INTEGER,
      _limit: Number.MAX_SAFE_INTEGER, // WTF?
    }).toPromise()
  }

  @HaveAngularParams()
  getDealIds(@AngularParams() params?) {
    return this.http.get<{ data: string[] }>(`${apiUrl}/deals/get-deal-ids`, { params }).toPromise()
  }

  // getCountryCodes(params?) {
  //   return this.http.get<{ data: string[] }>(`${apiUrl}/deals/origin-countries`, { params }).toPromise()
  // }

  cloneDeals(deal_id: string, clones) {
    return this.http.post<{ data: Deal[] }>(`${apiUrl}/deals/clone`, { deal_id, clones })
  }

  copyClone(deal_id: string, count: number) {
    return this.http.post<{ data: Deal[] }>(`${apiUrl}/deals/clones/${deal_id}/copy`, { count })
  }

  dealsSearch(query) {
    return this.http.post<{ total: number, totals: Dictionary<any>, hits: DealViewDto[] }>(`${apiUrl}/deals/search`, query)
  }

  dealsFilters(query) {
    return this.http.post<Dictionary<any>>(`${apiUrl}/deals/filters`, query)
  }

  // getByBuyerIds(buyer_ids: string[]) {
  //   return this.http.post<{ data: Deal[] }>(`${apiUrl}/deals/by-buyer-ids`, { buyer_ids }).toPromise()
  // }

  getDealView(query) {
    return this.http.post<DealViewRaw[]>(`${apiUrl}/deals/views`, query)
    // TEST: .pipe(map(dvs => dvs.map(dv => ({ ...dv, status: DealViewStatus.incomplete }))))
  }

  storeDeal(dealForm: DeepReadonly<DeepPartial<DealFormDto>>) {
    return this.http.post<Deal>(`${apiUrl}/deals/store`, dealForm)
  }
}
