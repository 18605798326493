import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { CreditPool } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const {apiUrl} = environment


export function registerNg1(module) {
  module.service('CreditPoolApi', downgradeInjectable(CreditPoolApiService))
}


@Injectable()
@AngularCopy()
export class CreditPoolApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: CreditPool[] }>(`${apiUrl}/credit-pool`, { params }).toPromise()
  }

  create(account, data) {
    return this.http.post<{ data: CreditPool }>(`${apiUrl}/credit-pool/${account}`, data).toPromise()
  }

  get(account: string | number) {
    return this.http.get<{ data: CreditPool }>(`${apiUrl}/credit-pool/${account}`).toPromise()
  }

  getByAccountIds(account_ids) {
    return this.http.post<{ data: CreditPool[] }>(`${apiUrl}/credit-pool/by-multiple-accounts`, {account_ids}).toPromise()
  }

  update(account, data) {
    return this.http.put<{ data: CreditPool }>(`${apiUrl}/credit-pool/${account}`, data).toPromise()
  }

  @HaveAngularParams()
  getOverrided(@AngularParams() params) {
    return this.http.get<{ data: any }>(`${apiUrl}/credit-pool/get_overrided`, { params }).toPromise()
  }

  override(account, dealId, userId) {
    return this.http.put<{ data: any }>(`${apiUrl}/credit-pool/${account}/${dealId}/${userId}/override`, {}).toPromise()
  }

  unoverride(account, dealId) {
    return this.http.put<{ data: any }>(`${apiUrl}/credit-pool/${account}/${dealId}/unoverride`, {}).toPromise()
  }

  getBalance(data) {
    return this.http.post<{ data: any }>(`${apiUrl}/credit-pool/get-all-balances`, data).toPromise()
  }
}
