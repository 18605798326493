import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { ModalModule } from 'src/shared/modal'
import { EpochRangePipe } from '../../filters/epoch-range.pipe'
import { EpochPipe } from '../../filters/epoch.pipe'
import { MeasurePipe } from '../../filters/measure.pipe'
import { ReactiveAsteriskModule } from '../reactive-asterisk/reactive-asterisk.module'
import { CsvExporterDialogComponent } from './csv-exporter.component'
import { CsvExporterService } from './csv-exporter.service'

@NgModule({
  declarations: [CsvExporterDialogComponent],
  exports: [CsvExporterDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    ReactiveAsteriskModule,
    FlexLayoutModule,
    ModalModule,
  ],
  providers: [
    CsvExporterService,
    CurrencyPipe,
    DecimalPipe,
    EpochPipe,
    EpochRangePipe,
    MeasurePipe,
  ],
})
export class CsvExporterModule {
}
