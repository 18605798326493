import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { TableView } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('TableViewApi', downgradeInjectable(TableViewApiService))


@Injectable()
@AngularCopy()
export class TableViewApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account, @AngularParams() params?) {
    return this.http.get<{ data: TableView[] }>(`${apiUrl}/${account}/table-views`, { params }).toPromise()
  }

  create(account, data: Partial<TableView>) {
    return this.http.post<{ data: TableView }>(`${apiUrl}/${account}/table-views`, data).toPromise()
  }

  get(account: number, id: string) {
    return this.http.get<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}`).toPromise()
  }

  update(account: number, id: string, data: Partial<TableView>) {
    return this.http.put<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}`, data).toPromise()
  }

  delete(account: number, id: string) {
    return this.http.delete<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}`).toPromise()
  }

  private(account: number, id: string, data: any = {}) {
    return this.http.put<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}/private`, data).toPromise()
  }

  public(account: number, id: string, data: any = {}) {
    return this.http.put<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}/public`, data).toPromise()
  }

  share(account: number, id: string, user_id: string) {
    return this.http.put<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}/share`, {user_id}).toPromise()
  }

  unshare(account: number, id: string, user_id: string) {
    return this.http.put<{ data: TableView }>(`${apiUrl}/${account}/table-views/${id}/unshare`, {user_id}).toPromise()
  }
}
