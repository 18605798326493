import { createAction, props } from '@ngrx/store'
import { DocumentSet, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'


export const loadDocumentSets = createAction('[DocumentSet/API] Load DocumentSets', props<{ tableKey?: TableKey }>())
export const loadDocumentSetsSuccess = createAction('[DocumentSet/API] Load DocumentSets Success', props<{ tableKey?: TableKey, documentSets: DocumentSet[] }>())
export const loadDocumentSetsFailure = createAction('[DocumentSet/API] Load DocumentSets Failure', props<{ error: Error }>())

export const createDocumentSet = createAction('[DocumentSet/API] Create Document Set', props<{ set: DeepReadonly<Partial<DocumentSet>> }>())
export const createDocumentSetSuccess = createAction('[DocumentSet/API] Create Document Set Success', props<{ set: DocumentSet }>())
export const createDocumentSetFailure = createAction('[DocumentSet/API] Create Document Set Failure', props<{ error: Error }>())

export const updateDocumentSet = createAction('[DocumentSet/API] Update Document Set', props<{ set: DeepReadonly<Partial<DocumentSet>>; id: string; original: DeepReadonly<DocumentSet>; }>())
export const updateDocumentSetSuccess = createAction('[DocumentSet/API] Update Document Set Success', props<{ set: DocumentSet }>())
export const updateDocumentSetFailure = createAction('[DocumentSet/API] Update Document Set Failure', props<{ error: Error }>())

export const deleteDocumentSet = createAction('[DocumentSet/API] Delete Document Set', props<{ set: DeepReadonly<Partial<DocumentSet>>; id: string }>())
export const deleteDocumentSetSuccess = createAction('[DocumentSet/API] Delete Document Set Success', props<{ id: string }>())
export const deleteDocumentSetFailure = createAction('[DocumentSet/API] Delete Document Set Failure', props<{ error: Error }>())
