import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Bid } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('BidApi', downgradeInjectable(BidApiService))

@Injectable()
@AngularCopy()
export class BidApiService {
  constructor(private http: HttpClient) { }

  @HaveAngularParams()
  list(account, @AngularParams() params) {
    return this.http.get<{ data: Bid }>(`${apiUrl}/bids/${account}`, { params }).toPromise()
  }

  byDeals(account, deal_ids) {
    return this.http.post<{ data: Bid[] }>(`${apiUrl}/bids/by_deals/${account}`, { deal_ids }).toPromise()
  }

  // create(data: Partial<Bid>) {
  //   return this.http.post<{ data: Bid }>(`${apiUrl}/bids`, data).toPromise()
  // }

  update(id: string, data: Partial<Bid>) {
    return this.http.put<{ data: Bid }>(`${apiUrl}/bids/${id}`, data).toPromise()
  }

  get(account: number, id: string) {
    return this.http.get<{ data: Bid }>(`${apiUrl}/bids/${account}/${id}`).toPromise()
  }

  // delete(id: string) {
  //   return this.http.delete<{ data: Bid }>(`${apiUrl}/bids/${id}`).toPromise()
  // }
}
