import { Injectable } from '@angular/core'
import { PaymentReference, User } from '@tradecafe/types/core'
import { compact, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { PaymentReferenceApiService } from 'src/api/metadata/payment-reference'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['reference']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr

interface PaymentReferenceView extends PaymentReference {
  user: User
}

@Injectable()
export class PaymentReferencesService {
  constructor(
    private PaymentReferenceApi: PaymentReferenceApiService,
    private AuthApi: AuthApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) {}

  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  paymentReferenceChanged$ = new Subject()


  /**
   * Get all available paymentReferences
   */
  async getPaymentReferences() {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.PaymentReferenceApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (res) => {
        res.data = await this.populate(res.data)
        return res
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return data
  }

  /**
   * Query available paymentReferences
   *
   * @param {any} query
   * @returns
   */
  async queryPaymentReferences(query) {
    const data = await this.getPaymentReferences()
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get possible values for given paymentReference field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: string) {
    const paymentReferences = await this.getPaymentReferences()
    const values = uniq(compact(map(paymentReferences, fieldName)))
    return values
  }

  /**
   * Get payment reference document by id
   *
   * @param {string} id
   * @returns
   */
  async getById(id: string) {
    const { data } = await this.PaymentReferenceApi.get(id)
    return data
  }

  /**
     * Create new payment reference
     *
     * @param {any} paymentReference
     * @returns
     */
  async create(paymentReference: Partial<PaymentReference>) {
    const payload = pick(paymentReference, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.PaymentReferenceApi.create(payload)
    this.invalidateCache()
    this.paymentReferenceChanged$.next()
    return data
  }

  /**
   * Update payment reference
   *
   * @param {any} paymentReference
   * @returns
   */
  async update(paymentReference: Partial<PaymentReference>) {
    const { payment_reference_id } = paymentReference
    const payload = pick(paymentReference, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.PaymentReferenceApi.update(payment_reference_id, payload)
    this.invalidateCache()
    this.paymentReferenceChanged$.next()
    return data
  }

  /**
   * Remove payment reference
   *
   * @param {any} paymentReference
   * @returns
   */
  async remove(paymentReference: PaymentReference) {
    const { data } = await this.PaymentReferenceApi.delete(paymentReference.payment_reference_id)
    this.invalidateCache()
    this.paymentReferenceChanged$.next()
    return data
  }

  /**
   * Invalidate paymentReferences in-memory cache
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }

  private async populate(paymentReferences: PaymentReference[]): Promise<PaymentReferenceView[]> {
    const { account } = this.AuthApi.currentUser
    const userIds = uniq(compact(map(paymentReferences, 'user_id')))
    const users = await this.Users.getUsersByIds(account, userIds)
    return paymentReferences.map((paymentReference: PaymentReferenceView) => {
      paymentReference.user = users[paymentReference.user_id]
      return paymentReference
    })
  }
}
