import { Injectable } from '@angular/core'
import { Cost, CreditNote, Deal, DealViewRaw } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, find, forEach, identity, map } from 'lodash-es'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { CostApiService } from 'src/api/cost/cost'
import { DealApiService } from 'src/api/deal'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { CreditNotesService } from 'src/services/data/credit-notes.service'
import { ModalProxyService, ModalService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { CreditNoteFormComponent, CreditNoteFormOptions } from './credit-note-form.component'

export interface CreditNoteWithDealCosts extends CreditNote {
  deal?: {
    costs: Cost[]
    deal_id: string
    buyer_id: string|number
  }
}

@Injectable()
export class CreditNoteFormService {
  constructor(
    private modalHelper: ModalProxyService,
    private ConfirmModal: ConfirmModalService,
    private CreditNotes: CreditNotesService,
    private DealApi: DealApiService,
    private CostApi: CostApiService,
    private toaster: ToasterService,
    private modal: ModalService,
  ) {}

  dealCreditNotesChange$ = new Subject<void>()

  showAddItem(dealViewRaw?: DeepReadonly<DealViewRaw>) {
    return this.modal.openDialog<CreditNoteFormComponent, CreditNoteFormOptions, CreditNote>(CreditNoteFormComponent, {
      creditNote: { deal_id: dealViewRaw?.deal.deal_id, attributes: {}},
      title: 'New Credit Note',
      isNew: true,
    }).pipe(filter(identity)).toPromise()
  }

  showUpdateItemImmutable(item: DeepReadonly<CreditNote>, readonly?: unknown) {
    return this.showUpdateItem(cloneDeep(item) as CreditNote, readonly)
  }

  showUpdateItem(creditNote: CreditNote, readonly?: unknown) {
    return this.modal.openDialog<CreditNoteFormComponent, CreditNoteFormOptions, CreditNote>(CreditNoteFormComponent, {
      creditNote,
      title: `${readonly ? 'Credit Note' : 'Update Credit Note'} #${creditNote.cn_id}`,
      isReadonly: !!readonly, // unused?!
    }).pipe(filter(identity)).toPromise()
    .then(stored => Object.assign(creditNote, stored))
  }

  showApproveItem(item: CreditNote) {
    return this.modalHelper.showModal({
      component: 'tcCreditNoteApproveForm',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        modalSetting: () => {
          return {title: 'Approve Credit Note'}
        },
        creditNote: () => angular.copy(item),
      },
    })
  }

  async showApproveCreditNotes(itemList: CreditNote[]) {
    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to approve selected credit notes?',
    })
    // const list = angular.copy(itemList)
    const list = itemList

    const dataRequests = []
    forEach(list, (item: any) => {
      dataRequests.push(this.DealApi.get(item.deal_id).then(({data}) => {
        item.deal = data
      }, (err) => {
        this.toaster.error('Unable to load deal data.', err)
      }))

      dataRequests.push(this.CostApi.get(item.cost_id).then(({data}) => {
        item.cost = data
      }, (err) => {
        this.toaster.error('Unable to load cost data.', err)
      }))
    })

    await Promise.all(dataRequests)

    return Promise.all(map(list, item => this.CreditNotes.approveCreditNote(item, item as any as {deal: Deal, cost: Cost})))
  }

  showVoidCreditNoteImmutable(creditNote: DeepReadonly<CreditNoteWithDealCosts>) {
    return this.showVoidCreditNote(cloneDeep(creditNote) as CreditNoteWithDealCosts)
  }

  async showVoidCreditNote(creditNote: CreditNoteWithDealCosts) {
    if (creditNote.status === 0 || creditNote.status === 3) {
      this.toaster.error(`Can not void this CN with status void and applied`)
      return
    }
    return this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to void this credit note?',
    }).then(() =>
      this.voidCreditNote(creditNote))
  }

  async showVoidCreditNotes(creditNotes: CreditNoteWithDealCosts[]) {
    return this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to void selected credit notes?',
    }).then(() =>
      Promise.all(creditNotes.map(creditNote =>
        this.voidCreditNote(creditNote))))
  }

  private voidCreditNote(creditNote: CreditNoteWithDealCosts) {
    const cost = find(creditNote.deal?.costs, { cost_id: creditNote.cost_id })
    return this.CreditNotes.voidCreditNote(creditNote, cost)
  }

  // async showVoidCreditNoteOf(deal, creditNote: CreditNoteWithDealCosts) {
  //   creditNote.deal = deal
  //   // TODO: ensure deal has costs loaded
  //   await this.showVoidCreditNote(creditNote)
  //   const cost = find(deal.costs, {cost_id: creditNote.cost_id})
  //   await this.DealViewSaver.updateCost(deal, cost)
  //   this.dealCreditNotesChange$.next()
  // }
}
