import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker'
import { EpochFieldModule } from '../epoch/epoch-field/epoch-field.module'
import { EpochRangeFieldModule } from '../epoch/epoch-range-field/epoch-range-field.module'
import { SelectSearchModule } from '../select-search/select-search.module'
import { InlineEditorComponent } from './inline-editor.component'

@NgModule({
  declarations: [InlineEditorComponent],
  exports: [InlineEditorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MtxDatetimepickerModule,
    MatFormFieldModule,
    MatInputModule,
    SelectSearchModule,
    EpochRangeFieldModule,
    EpochFieldModule,
  ],
})
export class InlineEditorModule {}
