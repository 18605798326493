import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { ProductType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as ProductTypeActions from './product-type.actions'

export const productTypesFeatureKey = 'productTypes'
export interface ProductTypesState extends EntityState<DeepReadonly<ProductType>> {
}

export const selectId = (productType: ProductType) => productType.type_id
export const adapter = createEntityAdapter<DeepReadonly<ProductType>>({ selectId })

const initialState: ProductTypesState = adapter.getInitialState({
})

export const productTypesReducer = createReducer(
  initialState,
  on(ProductTypeActions.loadProductTypesSuccess,
    (state, action) =>
      adapter.upsertMany(action.productTypes, state)),
)

