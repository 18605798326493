import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Cost } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export function registerNg1(module: ng.IModule) {
  module.service('CostApi', downgradeInjectable(CostApiService))
}


@Injectable()
@AngularCopy()
export class CostApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params?) {
    return this.http.get<{ data: Cost[] }>(`${apiUrl}/costs`, { params }).toPromise()
  }

  @HaveAngularParams()
  create(account: number, data: Partial<Cost>, @AngularParams() params?) {
    return this.http.post<{ data: Cost }>(`${apiUrl}/costs/${account}`, data, { params }).toPromise()
  }

  @HaveAngularParams()
  getForDeal(account: number, dealId: string, @AngularParams() params?) {
    return this.http.get<{ data: Cost[] }>(`${apiUrl}/costs/${account.toString()}/${dealId}`, { params }).toPromise()
  }

  getByDealIds(query) {
    return this.http.post<{ data: Cost[] }>(`${apiUrl}/costs/by-multiple-deals?limit=${Number.MAX_SAFE_INTEGER}`, query).toPromise()
  }

  getByIds(query) {
    return this.http.post<{ data: Cost[] }>(`${apiUrl}/costs/by-multiple-ids?limit=${Number.MAX_SAFE_INTEGER}`, {ids: query}).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: Cost }>(`${apiUrl}/costs/${id}`).toPromise()
  }

  update(account: number, id: string, data: DeepReadonly<Partial<Cost>>) {
    return this.http.put<{ data: Cost }>(`${apiUrl}/costs/${account.toString()}/${id}`, data).toPromise()
  }

  @HaveAngularParams()
  delete(id: string, @AngularParams() params?) {
    return this.http.delete<{ data: Cost }>(`${apiUrl}/costs/${id}`, { params }).toPromise()
  }
}
