import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, MatSortable } from '@angular/material/sort'
import { identity, isEqual } from 'lodash-es'
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll'
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import { delay, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { replayForm } from 'src/shared/utils/replay-form'
import { FiltersFormGroup, TableFilters } from '../filters.interfaces'
import { filtersActive } from '../filters.service'
import { IStatefulDataSource } from './stateful-data-source'
import { TableDataStatus } from './table-data-status'

export const PAGE_SIZE = 50
export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 75, 100] // these a fake

export class ScrollableDataSource<T, F extends TableFilters> extends TableVirtualScrollDataSource<T> implements IStatefulDataSource<T> {
  private readonly _sub = new Subscription
  state$ = new BehaviorSubject<TableDataStatus>('loading')

  private paginator$ = new ReplaySubject<MatPaginator>(1)
  private paginatorrr: MatPaginator  // NOTE don't use MatTableDataSource::paginator
  private sort$ = new ReplaySubject<MatSort>(1)
  private viewPort$ = new ReplaySubject<CdkVirtualScrollViewport>(1)

  private refresh$ = new BehaviorSubject<void>(undefined)
  fetch$ = new Subject<{ filters: F, page: number }>()

  constructor(
    source$: Observable<T[]>,
    private filtersForm?: FiltersFormGroup<F>,
    filterFn?: (rows: T[], filters: F) => T[],
  ) {
    super()

    if (this.filtersForm && filterFn) {
      source$ = combineLatest([source$, replayForm(this.filtersForm)])
        .pipe(map(([data, filters]) => data && filterFn(data, filters)))
    }

    const data$ = combineLatest([
      source$,
      this.paginator$.pipe(take(1)),
      this.sort$.pipe(take(1)),
    ]).pipe(map(([x]) => x), filter(identity))

    if (filtersForm) {
      this._sub.add(combineLatest([
        combineLatest([source$, filtersActive(filtersForm)]).pipe(
          map(([data, filters]): TableDataStatus => {
            if (!data) return 'loading'
            if (data.length) return 'has-data'
            if (filters) return 'no-match'
            return 'no-data'
          }),
          distinctUntilChanged(),
          tap(state => this.state$.next(state))),

        data$.pipe(tap(data => {
          this.data = data
          this.paginatorrr.length = data.length
        })),

        this.paginator$.pipe(
          switchMap(paginator => paginator.page.asObservable()),
          filter(e => this.filtersForm.value.limit !== e.pageSize),
          tap(e => this.filtersForm.patchValue({ limit: e.pageSize } as Partial<F>))),

        this.paginator$.pipe(
          switchMap(paginator => paginator.page.asObservable()),
          withLatestFrom(this.viewPort$),
          tap(([e, vp]) => vp.scrollToIndex(Math.min(this.filteredData.length, e.pageIndex * e.pageSize)))),

        this.sort$.pipe(
          switchMap(sort => sort.sortChange),
          map(({ active, direction }) => ({ id: active, start: direction })),
          filter(sort => !isEqual(this.filtersForm.value.sort, sort)),
          tap(sort =>  this.filtersForm.patchValue({ sort } as Partial<F>))),

        this.filtersForm.get('sort').valueChanges.pipe(tap(sortForm => {
          const { active, direction } = this.sort
          const sort = { id: active, start: direction }
          if (isEqual(sortForm, sort)) return
          this.sort.sort(sortForm)
        })),

        data$.pipe(delay(100), withLatestFrom(this.viewPort$), tap(([, viewPort]) => {
          viewPort.checkViewportSize()
        }))

        // this.filtersForm.get('limit').valueChanges.pipe(tap(limit => {
        //   if (limit !== this.paginatorrr.pageSize) this.paginatorrr.pageSize = limit
        // })),
      ]).subscribe())
    } else {
      this._sub.add(data$.subscribe(data => {
        this.data = data
        this.paginatorrr.length = data.length
      }))
    }
    this._sub.add(data$.pipe(delay(100), withLatestFrom(this.viewPort$)).subscribe(([, viewPort]) =>
      viewPort.checkViewportSize()))
  }

  disconnect() {
    this._sub.unsubscribe()
  }

  setPaginator(paginator: MatPaginator) {
    if (!paginator || paginator === this.paginatorrr) return
    paginator.pageSizeOptions = PAGE_SIZE_OPTIONS
    paginator.showFirstLastButtons = true
    // paginator.pageSize = this.filtersForm.value.limit || PAGE_SIZE
    paginator.pageSize = PAGE_SIZE
    this.paginatorrr = paginator
    this.paginator$.next(paginator)
  }

  setSort(sort: MatSort) {
    if (!sort || sort === this.sort) return
    // set sort
    const sortable = (this.filtersForm?.value.sort || { id: '', start: 'asc' }) as MatSortable
    sort.sort(sortable)

    // set sort
    this.sort = sort
    this.sort$.next(sort)
  }


  setViewPort(viewPort: CdkVirtualScrollViewport) {
    this.viewPort$.next(viewPort)
  }

  private checkViewportSize() {
    this.viewPort$.pipe(take(1)).subscribe(viewPort => viewPort.checkViewportSize())
  }

  onScroll(scrollIndex: number) {
    const page = Math.floor(scrollIndex / this.paginatorrr.pageSize)
    if (this.paginatorrr.pageIndex !== page) {
      this.paginatorrr.pageIndex = page
      this.paginatorrr.page.next(this.paginatorrr)
    }
    this.checkViewportSize()
  }

  refresh() {
    this.refresh$.next()
  }
}
