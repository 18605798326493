import { Injectable } from '@angular/core'
import { isFormulaDocument } from '@tradecafe/types/utils'
import { compact, filter, find, flatten, get, keyBy, map, merge, omit, reject, uniq, uniqBy } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { BusinessTypeApiService } from 'src/api/business-type'
import { DocumentSetApiService } from 'src/api/document-lib/document-set'
import { FileApiService } from 'src/api/file'
import { ProductCategoryApiService } from 'src/api/product/category'
import { PricingTermsService } from 'src/pages/admin/settings/admin-setting-payments/pricing-terms/pricing-terms.service'
import { CustomTagsService } from 'src/pages/admin/settings/custom-tags/custom-tags.service'
import { LocationsService } from 'src/pages/admin/settings/locations/locations.service'
import { ItemTypesService } from 'src/pages/admin/settings/product-specifications/item-types/item-types.service'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { WrappingTypesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/wrapping-types.service'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { AccountsService } from 'src/services/data/accounts.service'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import {
  accountsResolver, businessTypesResolver, carriersResolver, itemTypesResolver,
  locationsResolver, measuresResolver, pricingTermsResolver, productCategoriesResolver,
  productsResolver, usersResolver, wrappingsResolver
} from 'src/services/data/deal.resolvers'
import { UsersService } from 'src/services/data/users.service'
import { FilesService } from '../deal-documents/files.service'
import { DocumentRequirementsService } from './document-requirements.mapping'

@Injectable()
export class DocumentsGeneratorService {
  constructor(
    private CustomTags: CustomTagsService,
    private DocumentRequirements: DocumentRequirementsService,
    private DocumentSetApi: DocumentSetApiService,
    private DealViewLoader: DealViewLoaderService,
    private AuthApi: AuthApiService,
    private FileApi: FileApiService,
    private Files: FilesService,
    private Accounts: AccountsService,
    private Carriers: CarriersService,
    private Users: UsersService,
    private ProductCategories: ProductCategoryApiService,
    private ItemTypes: ItemTypesService,
    private Locations: LocationsService,
    private PricingTerms: PricingTermsService,
    private Products: ProductsService,
    private WrappingTypes: WrappingTypesService,
    private Measures: MeasuresService,
    private BusinessTypeApi: BusinessTypeApiService,
  ) {}

  private async getDocRequirementsForDeals(doc, deals, data) {
    await Promise.all(map(deals, async (deal) => {
      deal.customTags = await this.CustomTags.loadCustomTagsFor(deal)
    }))

    return map(deals, (deal) => {
      const {fields, rowsFields} = this.buildIdentifiers(deal, [doc], data)
      const {requirements, rows} = this.renderDocIdentifiers(doc, {fields, rowsFields}, deal.customTags)

      return {
        requirements,
        rows,
        deal_id: deal.deal_id,
        file_id: doc.file_id,
      }
    })
  }

  async generateMergedDocument(doc, deals) {
    const data = await this.fetchReferenceDataFor(deals)
    await this.DealViewLoader.fetchNotesFor(deals)

    const docRequiredData = await this.getDocRequirementsForDeals(doc, deals, data)
    const deal_id = map(deals, 'deal_id').join('-')
    const file = await this.generateDocument({deal_id}, doc, docRequiredData)
    return file
  }

  async generateMergedDocumentWithCustomRequirements(
    doc, deals, customRequirementValuesByDealId = {}, additionalData = {},
  ) {
    const data = await this.fetchReferenceDataFor(deals)
    await this.DealViewLoader.fetchNotesFor(deals)

    await Promise.all(map(deals, async (deal) => {
      deal.customTags = await this.CustomTags.loadCustomTagsFor(deal)
    }))

    const docRequiredData = map(deals, (deal) => {
      const {fields, rowsFields} = this.buildIdentifiers(deal, [doc], data)
      const {requirements, rows} = this.renderDocIdentifiers(doc, {fields, rowsFields}, deal.customTags)
      const customRequirementValuesByIdentifier = customRequirementValuesByDealId[deal.deal_id]

      return {
        requirements: map(requirements, (req) => {
          if (customRequirementValuesByIdentifier[req.identifier]) {
            req.identifier_value = customRequirementValuesByIdentifier[req.identifier]
          }
          return req
        }),
        rows,
        deal_id: deal.deal_id,
        file_id: doc.file_id,
      }
    })

    const deal_id = map(deals, 'deal_id').join('-')
    const file = await this.generateDocument({deal_id}, doc, docRequiredData, {}, additionalData)
    return file
  }

  /**
   * Build
   *
   * @param {*} deal
   * @param {*} docs
   * @param mergeClonesDocs, [true, false...] if generating docs for clones and merging them
   */
  async generateDocuments(deal, docs, {extraData = {}, fileBody}: any = {}) {
    const data = Object.assign(extraData, await this.fetchReferenceDataFor([deal]))
    const files = []
    for (const doc of docs) {
      const docRequiredData = await this.getDocRequirementsForDeals(doc, [deal], data)
      const deal_ids = uniq(compact(map([deal, ...(extraData.deals || [])], 'deal_id')))
      const name = isFormulaDocument(doc)
        ? `${doc.name || 'Document Name'}-${map(deal_ids, (dealId) => dealId.replace(/TCI$/g, 'TC')).join('-')}`
        : `${doc.name || 'Document Name'}-${deal.deal_id.replace(/TCI$/g, 'TC')}`

      const file = await this.generateDocument(deal, doc, docRequiredData, { ...fileBody, name })
      files.push(file)
    }

    return files
  }

  /**
   * Generate a document
   *
   * @param deal
   * @param doc: doc template
   * @param data: [{requirements, rows, deal_id, file_id}, ...]
   * @param filePatch: patch for created file after created
   * @param additionalData: addtional data when send request to generate doc
   * @returns {Promise<void>}
   */
  async generateDocument({deal_id}, doc, data, filePatch: any = {}, additionalData = {}) {
    const {data: docxFile} = await this.DocumentSetApi.generateDocumentMass({
      deal_id,
      name: filePatch.name || `${doc.name || 'Document Name'}-${deal_id.replace(/TCI$/g, 'TC')}`,
      data,
      ...additionalData,
    })
    const files = [[docxFile, filePatch]]
    const pdfId = get(docxFile, 'attributes.pdf_version_file_id')
    if (pdfId) { // if there is a pdf version - update visibility there as well
      const {data: pdfFile} = await this.FileApi.get(pdfId)
      files.push([pdfFile, omit(filePatch, 'deal_id')])
    }
    await Promise.all(files.map(([file, patch]) => {
      merge(file, {attributes: {document_type: doc.type}}, patch)
      return this.Files.patch(file, file)
    }))

    return docxFile
  }

  private async fetchReferenceDataFor(deals) {
    const [
      shipmentRates,
      accounts,
      carriers,
      users,
      categories,
      itemTypes,
      locations,
      pricingTerms,
      products,
      wrappings,
      measures,
      businessTypes,
    ] = await Promise.all([
      this.DealViewLoader.fetchFreightRatesFor(deals),
      accountsResolver(this.Accounts),
      carriersResolver(this.Carriers),
      usersResolver(this.AuthApi, this.Users),
      productCategoriesResolver(this.ProductCategories),
      itemTypesResolver(this.ItemTypes),
      locationsResolver(this.Locations),
      pricingTermsResolver(this.PricingTerms),
      productsResolver(this.Products),
      wrappingsResolver(this.WrappingTypes),
      measuresResolver(this.Measures),
      businessTypesResolver(this.BusinessTypeApi),
    ])
    return {
      shipmentRates,
      accounts: keyBy(accounts, 'account'),
      businessTypes: keyBy(businessTypes, 'business_type_id'),
      carriers: keyBy(carriers, 'carrier_id'),
      categories: keyBy(categories, 'category_id'),
      itemTypes: keyBy(itemTypes, 'item_type_id'),
      locations: keyBy(locations, 'location_id'),
      measures: keyBy(measures, 'measure_id'),
      pricingTerms: keyBy(pricingTerms, 'pricing_terms_id'),
      products: keyBy(products, 'product_id'),
      users: keyBy(users, 'user_id'),
      wrappings: keyBy(wrappings, 'wrapping_id'),
    }
  }

  private buildIdentifiers(deal, docs, data) {
    // sort requirements. keep unique tags
    const allReqs = uniqBy(flatten(map(docs, 'attributes.requirements')), req => req.tag + req.is_row)
    const inputReqs = reject(allReqs, 'is_row')
    const rowReqs = filter(allReqs, 'is_row')


    // is_row=false
    const identifierKeys = uniq(compact(flatten(map(inputReqs, 'identifier'))))
    const fields = compact(identifierKeys.map(identifierKey =>
      this.DocumentRequirements.buildRequirement(identifierKey, deal, {
        ...data,
        getValue: this.DocumentRequirements.getValue,
      })))

    // is_row=true
    // basic row requirements (DI.*) have 1:1 relation with deal.products[]
    // spec row requirements (SPEC.*) have 1:1 relation with deal.products[].batches[]
    const isSpec = req => /^spec_/.test(req.identifier)
    const rowIdentifiers = uniq(compact(flatten(map(reject(rowReqs, isSpec), 'identifier'))))
    const specIdentifiers = uniq(compact(flatten(map(filter(rowReqs, isSpec), 'identifier'))))
    let rowsFields
    if (specIdentifiers.length) {
      // we need to build next table
      // product 1 name | product 1 spec 1
      //                | product 1 spec 2
      // product 2 name | product 2 spec 1
      // product 3 name | product 3 spec 1
      //                | product 3 spec 2
      rowsFields = flatten(map(deal.products, (product) => { // NOTE: this flatten isn't deep
        const batches = get(product, 'batches.length') ? product.batches : [null]
        return map(batches, (batch, i) => compact([
          // render product info only in first batch row
          ...map(i ? [] : rowIdentifiers, identifier =>
            this.DocumentRequirements.buildRequirement(identifier, deal, product, data)),
          // render batch info only if there is a batch
          ...map(batch ? specIdentifiers : [], identifier =>
            this.DocumentRequirements.buildRequirement(identifier, deal, product, batch, data)),
        ]))
      }))
    } else {
      // we need to build simple table out of deal.products[]
      rowsFields = map(deal.products, product => compact(map(rowIdentifiers, identifier =>
        this.DocumentRequirements.buildRequirement(identifier, deal, product, data))))
    }

    return {fields, rowsFields}
  }

  /**
   * Returns `doc.attributes.requirements` copy with values put inside
   *
   * @private
   * @param {*} doc
   * @param {*} {fields, rowsFields}
   * @param {*} customTags
   * @returns
   */
  private renderDocIdentifiers(doc, {fields, rowsFields}, customTags) {
    return {
      // prepare "regular" requirements (not rows)
      requirements: doc.attributes.requirements.map((requirement) => {
        // apply calculated value if possible
        const calculatedField = find(fields, {identifier: requirement.identifier})
        if (calculatedField) {
          return {
            ...requirement,
            identifier_value: calculatedField.value,
          }
        }
        // if not found - look for a custom tag
        const customTag = find(customTags, {tag: requirement.tag.toUpperCase()})
        if (customTag) {
          return {
            ...requirement,
            identifier_value: customTag.description,
          }
        }
        // otherwise return original
        return requirement
      }),

      // prepare input rows (DI.* and SPEC.* tags)
      rows: map(rowsFields, rowRequirements =>
        rowRequirements.reduce((row, {identifier, value}) =>
          Object.assign(row, {
            [identifier]: value,
          }), {})),
    }
  }
}
