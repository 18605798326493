import { Injectable } from '@angular/core'
import { defaultsDeep, mapValues } from 'lodash-es'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class DateRangeSelectModalService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  show(options = {}) {
    defaultsDeep(options, {
      title: 'Select Date Range',
    })

    return this.modalHelper.showModal({
      component: 'tcDateRangeSelectModal',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: mapValues(options, value => () => value),
    })
  }
}
