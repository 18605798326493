import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Currency } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { keyBy } from 'lodash-es'
import * as CurrencyActions from './currency.actions'

export const currenciesFeatureKey = 'currencies'
export interface CurrenciesState extends EntityState<DeepReadonly<Currency>> {
  byCode: Dictionary<DeepReadonly<Currency>>
}

export const selectId = (currency: Currency) => currency.currency_id
export const adapter = createEntityAdapter<DeepReadonly<Currency>>({ selectId })

const initialState: CurrenciesState = adapter.getInitialState({
  byCode: {},
})

export const currenciesReducer = createReducer(
  initialState,
  on(CurrencyActions.loadCurrenciesSuccess,
    (state, action) => {
      state = adapter.upsertMany(action.currencies, state)
      return { ...state, byCode: keyBy(action.currencies, 'code') }
    }),
)

