import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Carrier } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('CarrierApi', downgradeInjectable(CarrierApiService))


@Injectable()
@AngularCopy()
export class CarrierApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: Carrier[] }>(`${apiUrl}/carriers`, { params }).toPromise()
  }

  create(data: Partial<Carrier>) {
    return this.http.post<{ data: Carrier }>(`${apiUrl}/carriers`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: Carrier }>(`${apiUrl}/carriers/${id}`).toPromise()
  }

  update(id: string, data: Partial<Carrier>) {
    return this.http.put<{ data: Carrier }>(`${apiUrl}/carriers/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: Carrier }>(`${apiUrl}/carriers/${id}`).toPromise()
  }
}
