import {createAction, props} from '@ngrx/store'
import {AesCommodityExportReport, AesResponseStatus} from '@tradecafe/types/core'

export interface ReportsRes {
    created_reports: AesCommodityExportReport[]
}


export const createAESExportReport = createAction(
    '[AES/API] Create AES export report',
    props<{ dealId: string, aesNumberAlreadyAssigned: boolean }>(),
)
export const createAESExportReportSuccess = createAction(
    '[AES/API] Create AES export report Success',
    props<{
        aes: AesCommodityExportReport,
    }>(),
)
export const createAESExportReportFailure = createAction(
    '[AES/API] Create AES export report Failure',
    props<{ error: Error }>(),
)

export const cancelAESExportReport = createAction(
    '[AES/API] Cancel AES export report',
    props<{ reportId: string, dealId: string, reason?: string }>(),
)
export const cancelAESExportReportSuccess = createAction(
    '[AES/API] Cancel AES export report Success',
    props<{
        aes: AesCommodityExportReport,
    }>(),
)
export const cancelAESExportReportFailure = createAction(
    '[AES/API] Cancel AES export report Failure',
    props<{ error: Error }>(),
)

export const createAESExportRequestsInBatch = createAction(
    '[AES/API] Create AES export requests in batch',
    props<{ dealIds: string[], aesNumberAlreadyAssigned: boolean }>(),
)
export const createAESInBatchSuccess = createAction(
    '[AES/API] Create AES export reports in batch Success',
    props<{
        items: AesCommodityExportReport[],
    }>(),
)
export const createAESInBatchFailure = createAction(
    '[AES/API] Create AES export reports in batch Failure',
    props<{ error: Error }>(),
)

export const setAesResponseStatus = createAction(
    '[AesExportReport/API] Set AES Response Status',
    props<{ reportId: string, status: AesResponseStatus }>()
)

export const setAesResponseStatusSuccess = createAction(
    '[AesExportReport/API] Set AES Response Status Success',
    props<{ reportId: string, status: AesResponseStatus }>()
)

export const setAesResponseStatusFailure = createAction(
    '[AesExportReport/API] Set AES Response Status Failure',
    props<{ error: Error }>()
)
