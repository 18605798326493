import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes, UrlSegment } from '@angular/router';
import { TableKey } from '@tradecafe/types/core';
import { EmptyComponent, VeryEmptyComponent } from 'src/components/empty.component';
import { environment } from 'src/environments/environment';
import { AngularJSComponent } from './angularjs/angularjs.component';
import { CanActivateAccount } from './can-activate-account.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('../pages/password-forgot/password-forgot.module').then(m => m.PasswordForgotModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('../pages/password-reset/password-reset.module').then(m => m.PasswordResetModule),
  },
  {
    path: 'trading',
    data: {
      roles: ['trader', 'administrator', 'junior-administrator', 'manager', 'superuser'],
      title: 'Trading',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'deals' },
      ... environment.enableMultipleBids ? [{
        path: 'future-bids',
        loadChildren: () => import('../pages/admin/auction/future-bidss/future-bids.module').then(m => m.FutureBidsModule),
        data: {
          tableIdentity: TableKey.TradingFutureBidsPage,
          title: 'Multiple Bids',
          back: '/trading/future-bids',
          navigateTo: '/trading/future-bids',
        },
      }] : [],
      {
        path: 'deals/new',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/trading/deal-form/deal-form.module').then(m => m.DealFormModule),
        data: {
          title: 'New Deal',
        },
      },
      {
        path: 'deals/:dealId',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/trading/deal-form/deal-form.module').then(m => m.DealFormModule),
        data: {
          title: 'Edit Deal',
          roles: ['logistics', 'trader', 'administrator', 'junior-administrator', 'manager', 'superuser'],
        },
      },
      {
        path: 'deals',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
        data: {
          title: 'Deals List',
          tableIdentity: TableKey.TradingDealsListPage,
          back: '/trading/deals',
          navigateTo: '/trading/deals',
        },
      },
      {
        path: 'shipping-log',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
        data: {
          title: 'Shipping Log',
          tableIdentity: TableKey.TradingShippingLogPage,
          back: '/trading/shipping-log',
          navigateTo: '/trading/deals',
        },
      },
      {
        path: 'client-shipping-log',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/trading/client-shipping-log/client-shipping-log.module').then(m => m.ClientShippingLogModule),
        data: {
          title: 'Client shipping Log',
          back: '/trading/client-shipping-log',
          navigateTo: '/trading/deals',
        },
      },
      {
        path: 'buyer-invoices',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Buyer Invoices',
          tableIdentity: TableKey.TradingBuyerInvoices,
          back: '/trading/buyer-invoices',
          navigateTo: '/trading/deals',
        },
      },
      {
        path: 'vendor-invoices',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Vendor Invoices',
          tableIdentity: TableKey.TradingVendorInvoices,
          back: '/trading/vendor-invoices',
          navigateTo: '/trading/deals',
        },
      },
      {
        path: 'freight-rates',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/logistics/freight-rates/freight-rates-page.module').then(m => m.FreightRatesPageModule),
        data: {
          title: 'Freight Rates',
          tableIdentity: TableKey.TradingFreightRatesList,
          back: '/trading/freight-rates',
          navigateTo: '/trading/freight-rates',
        },
      },
      {
        path: 'ar-report',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/ar-report-page/ar-report-page.module').then(m => m.ArReportPageModule),
        data: {
          title: 'AR Report',
          tableIdentity: TableKey.TradingArReportPage,
          back: '/trading/ar-report',
          navigateTo: '/trading/deals',
        },
      },
      {
        matcher: isAngularJSUrl('trading'),
        canActivate: [CanActivateAccount],
        component: AngularJSComponent,
      },
    ],
  },

  {
    path: 'financial',
    canActivate: [CanActivateAccount],
    data: {
      roles: ['accounting', 'manager', 'administrator', 'junior-administrator', 'superuser'],
      title: 'Financial',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'negative-deals'},
      {
        path: 'buyer-invoices',
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Buyer Invoices',
          tableIdentity: TableKey.FinancialBuyerInvoices,
          back: '/financial/buyer-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'payables',
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Payables',
          tableIdentity: TableKey.FinancialPayables,
          back: '/financial/payables',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'ar-report',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/ar-report-page/ar-report-page.module').then(m => m.ArReportPageModule),
        data: {
          title: 'AR Report',
          tableIdentity: TableKey.FinancialArReportPage,
          back: '/financial/ar-report',
          navigateTo: '/logistics/shipping-log',
        },
      },
      { matcher: isAngularJSUrl('management'), component: AngularJSComponent },
    ],
  },

  {
    path: 'reports',
    canActivate: [CanActivateAccount],
    data: {
      roles: ['accounting', 'manager', 'administrator', 'junior-administrator', 'superuser'],
      title: 'Reports',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'negative-deals'},
      environment.enableChartFeature ? {
        path: 'chart',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/reports/chart/chart.module').then(m => m.ChartModule),
        data: {
          title: 'Chart',
          back: '/reports/chart',
          navigateTo: '/reports/chart',
        },
      } :
        { path: 'chart', pathMatch: 'full', redirectTo: '/trading/deals'}
      ,
    ],
  },

  {
    path: 'management',
    canActivate: [CanActivateAccount],
    data: {
      roles: ['manager', 'superuser'],
      title: 'Management',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'negative-deals'},
      {
        path: 'negative-deals',
        loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
        data: {
          title: 'Negative Deals',
          tableIdentity: TableKey.ManagersDealsPage,
          back: '/management/negative-deals',
          navigateTo: '/trading/deals',
        },
      },
      {
        path: 'buyer-invoices',
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Buyer Invoices',
          tableIdentity: TableKey.ManagementBuyerInvoices,
          back: '/management/buyer-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'vendor-invoices',
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Vendor Invoices',
          tableIdentity: TableKey.ManagementVendorInvoices,
          back: '/management/vendor-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'ar-report',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/ar-report-page/ar-report-page.module').then(m => m.ArReportPageModule),
        data: {
          title: 'AR Report',
          tableIdentity: TableKey.ManagementArReportPage,
          back: '/management/ar-report',
          navigateTo: '/logistics/shipping-log',
        },
      },
      { matcher: isAngularJSUrl('management'), component: AngularJSComponent },
    ],
  },

  {
    path: 'logistics',
    data: {
      roles: ['logistics', 'administrator', 'junior-administrator', 'manager', 'superuser'],
      title: 'Logistics',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'shipping-log' },
      {
        path: 'shipping-log/:dealId',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/logistics/shipping-details/shipping-details.module').then(m => m.ShippingDetailsModule),
        data: {
          title: 'Shipping Log Detail',
          roles: ['logistics', 'trader', 'junior-administrator', 'administrator', 'manager', 'superuser'],
        },
      },
      {
        path: 'shipping-log',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
        data: {
          title: 'Shipping Log',
          tableIdentity: TableKey.LogisticsShippingLogPage,
          back: '/logistics/shipping-log',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'client-shipping-log',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/logistics/client-shipping-log/client-shipping-log.module').then(m => m.LogisticsClientShippingLogModule),
        data: {
          title: 'Client shipping Log',
          back: '/logistics/client-shipping-log',
          navigateTo: '/logistics/client-shipping-log',
        },
      },
      {
        path: 'indexing-report',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/logistics/corrupt-deal-report/corrupt-deal-report.module').then(m => m.CorruptDealReportModule),
        data: {
          title: 'Indexing Report',
          back: '/logistics/indexing-report',
          navigateTo: '/logistics/indexing-report',
        },
      },
      {
        path: 'integrations',
        canActivate: [CanActivateAccount],
        component: EmptyComponent,
        children: [
          {
            path: 'deringer',
            loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
            data: {
              title: 'Deringer',
              tableIdentity: TableKey.IntegrationsDeringerPage,
              back: '/logistics/integrations/deringer',
              navigateTo: '/logistics/shipping-log',
            },
          },
          {
            path: 'montship',
            loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
            data: {
              title: 'Montship',
              tableIdentity: TableKey.IntegrationsMontshipPage,
              back: '/logistics/integrations/montship',
              navigateTo: '/logistics/shipping-log',
            },
          },
          {
            path: 'aes',
            loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
            data: {
              title: 'AES',
              tableIdentity: TableKey.IntegrationsAESPage,
              back: '/logistics/integrations/aes',
              navigateTo: '/logistics/shipping-log',
            },
          },
          {
            path: 'macropoint',
            loadChildren: () => import('../pages/admin/trading/deals/deals-page/deals-page.module').then(m => m.DealsPageModule),
            data: {
              title: 'Macropoint',
              tableIdentity: TableKey.IntegrationsMacropointPage,
              back: '/logistics/integrations/macropoint',
              navigateTo: '/logistics/shipping-log',
            },
          },
        ],
      },
      {
        path: 'buyer-invoices',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Buyer Invoices',
          tableIdentity: TableKey.LogisticsBuyerInvoices,
          back: '/logistics/buyer-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'vendor-invoices',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Vendor Invoices',
          tableIdentity: TableKey.LogisticsVendorInvoices,
          back: '/logistics/vendor-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'financial-buyer-invoices',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Financial Buyer Invoices',
          tableIdentity: TableKey.FinancialBuyerInvoices,
          back: '/logistics/buyer-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'financial-vendor-invoices',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/invoices/invoices-page/invoices-page.module').then(m => m.InvoicesPageModule),
        data: {
          title: 'Financial Payables',
          tableIdentity: TableKey.FinancialPayables,
          back: '/logistics/vendor-invoices',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        path: 'freight-rates',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/logistics/freight-rates/freight-rates-page.module').then(m => m.FreightRatesPageModule),
        data: {
          title: 'Freight Rates',
          tableIdentity: TableKey.LogisticsFreightRatesList,
          back: '/logistics/freight-rates',
          navigateTo: '/logistics/freight-rates',
          editable: true,
        },
      },
      {
        path: 'upload-history',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../pages/admin/logistics/upload-history/upload-history-page.module').then(m => m.UploadHistoryPageModule),
        data: {
          title: 'Upload History',
          tableIdentity: TableKey.LogisticsUploadHistory,
          navigateTo: '/logistics/upload-history',
        },
      },
      {
        path: 'ar-report',
        canActivate: [CanActivateAccount],
        loadChildren: () => import('../components/ar-report-page/ar-report-page.module').then(m => m.ArReportPageModule),
        data: {
          title: 'AR Report',
          tableIdentity: TableKey.LogisticsArReportPage,
          back: '/logistics/ar-report',
          navigateTo: '/logistics/shipping-log',
        },
      },
      {
        matcher: isAngularJSUrl('logistics'),
        canActivate: [CanActivateAccount],
        component: AngularJSComponent,
      },
    ],
  },

  {
    path: 'setting',
    canActivate: [CanActivateAccount],
    data: {
      roles: ['accounting', 'manager', 'administrator', 'junior-administrator', 'superuser', 'trader', 'logistics'],
      title: 'Settings',
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/'},
      {
        path: 'consignees',
        data: {
          roles: ['accounting', 'manager', 'administrator', 'superuser'],
        },
        loadChildren: () => import('../pages/admin/settings/consignees/consignees-page.module').then(m => m.ConsigneesModule),
      },
      {
        path: 'document-library',
        data: {
          roles: ['accounting', 'manager', 'administrator', 'superuser'],
        },
        loadChildren: () => import('../pages/admin/settings/document-library/document-library-page.module').then(m => m.DocumentLibraryPageModule),
      },
      {
        path: 'buyers-groups',
        data: {
          roles: ['manager', 'administrator', 'superuser', 'trader'],
        },
        loadChildren: () => import('../pages/admin/settings/buyers-groups/buyers-groups-page.module').then(m => m.BuyersGroupsModule),
      },
      {
        matcher: isAngularJSUrl('settings'),
        component: AngularJSComponent,
        canActivate: [CanActivateAccount],
        data: {
          roles: ['administrator', 'junior-administrator', 'manager', 'superuser', 'trader', 'logistics'],
        },
      },
    ],
  },


  // AngularJS routes
  { matcher: isAngularJSUrl(''), component: AngularJSComponent },
  {
    path: '**', // unused
    component: EmptyComponent,
  },
]

@NgModule({
  declarations: [EmptyComponent, VeryEmptyComponent, AngularJSComponent],
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule],
  providers: [
    CanActivateAccount,
  ],
})
export class AppRoutingModule {
  constructor(router: Router) {
    // TODO: uncomment if experience issues with ng1/ng2/ng1 routes switching
    // router.routeReuseStrategy.shouldReuseRoute = () => false
  }
}

export function isAngularJSUrl(parent: string) {
  // tslint:disable-next-line: cyclomatic-complexity
  return (segments: UrlSegment[] | string) => {
    let url = serializeUrl(segments)
    if (parent) url = parent + '/' + url
    if (url.startsWith('trading/deals') ||
        url.startsWith('logistics/shipping-log') ||
        url.startsWith('setting/document-library') ||
        fullMatch(url, 'login') ||
        fullMatch(url, 'forgot-password') ||
        fullMatch(url, 'reset-password') ||
        fullMatch(url, 'management/negative-deals') ||
        fullMatch(url, 'trading/shipping-log') ||
        fullMatch(url, 'trading/client-shipping-log') ||
        fullMatch(url, 'logistics/client-shipping-log') ||
        fullMatch(url, 'logistics/buyer-invoices') ||
        fullMatch(url, 'logistics/vendor-invoices') ||
        fullMatch(url, 'trading/buyer-invoices') ||
        fullMatch(url, 'trading/vendor-invoices') ||
        fullMatch(url, 'financial/buyer-invoices') ||
        fullMatch(url, 'financial/payables') ||
        fullMatch(url, 'management/buyer-invoices') ||
        fullMatch(url, 'management/vendor-invoices') ||
        fullMatch(url, 'logistics/integrations/deringer') ||
        fullMatch(url, 'logistics/integrations/aes') ||
        fullMatch(url, 'logistics/integrations/montship') ||
        fullMatch(url, 'logistics/integrations/macropoint') ||
        fullMatch(url, 'setting/consignees') ||
        fullMatch(url, 'logistics/indexing-report') ||
        fullMatch(url, 'reports/chart') ||
        fullMatch(url, 'trading/freight-rates') ||
        fullMatch(url, 'logistics/freight-rates') ||
        fullMatch(url, 'trading/future-bids') ||
        fullMatch(url, 'setting/buyers-groups') ||
        fullMatch(url, 'logistics/upload-history') ||
        fullMatch(url, 'trading/ar-report') ||
        fullMatch(url, 'logistics/ar-report') ||
        fullMatch(url, 'financial/ar-report') ||
        fullMatch(url, 'management/ar-report') ||
        false // to keep git diffs clean
    ) {
      return null
    }
    return { consumed: segments as UrlSegment[] }
  }
}

function fullMatch(url: string, path: string) {
  return url.startsWith(path) && !url.startsWith(`${path}/`)
}

export function serializeUrl(segments: UrlSegment[] | string) {
  let url = segments
  if (Array.isArray(url)) url = url.map(u => u.path).join('/')
  if (url[0] === '/') url = url.slice(1)
  return url
}
