import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal'
import { AesRequestReviewDialogComponent, AesResponseReviewDialogOptions } from './aes-request-review-dialog.component'

@Injectable()
export class AesRequestReviewDialogService {

  constructor(private modal: ModalService) { }

  showAesResponseReviewl(opts: AesResponseReviewDialogOptions) {
    return this.modal.openDialog<AesRequestReviewDialogComponent, AesResponseReviewDialogOptions, boolean>(AesRequestReviewDialogComponent, opts, {
      panelClass: ['tc-dialog', 'modal-xlg'],
      width: null,
      maxWidth: null,
    })
  }
}
