import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { MacropointOrder, MacropointOrderLocation, MacropointOrderScheduleAlert, MacropointOrderTripEvent, MacropointOrderTripEventCode, MacropointOrderScheduleAlertCode, MacropointOrderMessage, MacropointOrderStatus, User } from '@tradecafe/types/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject } from 'rxjs'
import { waitNotEmpty } from '../../shared/utils/wait-not-empty'
import { compact } from 'lodash-es'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { MacropointService } from 'src/services/data/macropoint.service'
import { DeepReadonly } from '@tradecafe/types/utils'

export interface MacropointOrderDetailsDialogOptions {
  dealId: string
  orderId: string, 
}

@Component({
  selector: 'tc-macropoint-order-details-dialog',
  templateUrl: './macropoint-order-details-dialog.component.html',
  styleUrls: ['./macropoint-order-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MacropointOrderDetailsComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private MacropointService: MacropointService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private dialogRef: MatDialogRef<MacropointOrderDetailsComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private dialogData: MacropointOrderDetailsDialogOptions,
  ) { super() }

  readonly dealId = this.dialogData.dealId
  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())

  inProgress$ = new BehaviorSubject<'loading'|undefined>(undefined)

  protected order: MacropointOrder;
  protected sender: DeepReadonly<User>
  protected displayedMessages: MacropointOrderMessage[] = [];
  protected currentMessageIndex = 0;
  protected readonly messagePageSize = 3;

  protected MacropointStatusDescription = MacropointOrderStatus;

  async ngOnInit() {
    this.inProgress$.next('loading')
    this.store.dispatch(loadUsers({}))

    this.order = await this.MacropointService.get(this.dialogData.orderId);
    this.sortMessages();
    this.updateDisplayedMessages();

    this.users$.subscribe((users) => {
      this.sender = users[this.order.created_by]
      this.inProgress$.next(undefined)
      this.cd.detectChanges();
    })
  }


  protected close() {
    this.dialogRef.close()
  }

  protected formatLocation(location: MacropointOrderLocation | MacropointOrderTripEvent | MacropointOrderScheduleAlert): string {
    const addressSegments = [location.street1, (location as MacropointOrderLocation | MacropointOrderTripEvent).street2, (location as MacropointOrderLocation | MacropointOrderTripEvent).neighborhood, location.city, location.postal, location.state, location.country];
    let address = compact(addressSegments).join(', ');

    if(!this.isMacropointScheduleAlert(location) && location.longitude && location.latitude) {
      address += `; ${this.formatCoords(location.latitude, location.longitude)}`;
      const uncertainty = (location as MacropointOrderLocation)?.uncertainty;

      if(uncertainty) {
        address += ` ± ${uncertainty}mi`;
      }
    }

    return address;
  }

  private formatCoords(latitude, longitude) {
    let latDirection = latitude < 0 ? "S" : "N";
    let lonDirection = longitude < 0 ? "W" : "E";
  
    return Math.abs(latitude).toFixed(6) + "° " + latDirection + ", " + Math.abs(longitude).toFixed(6) + "° " + lonDirection;
  }

  protected getTripEventDescription(code: MacropointOrderTripEventCode): string {
    switch (code) {
      case MacropointOrderTripEventCode.ArrivedPickup:
        return 'Arrived at pickup';
      case MacropointOrderTripEventCode.DepartedPickup:
        return 'Departed from pickup';
      case MacropointOrderTripEventCode.ArrivedDropoff:
        return 'Arrived at dropoff';
      case MacropointOrderTripEventCode.DepartedDropoff:
        return 'Departed from dropoff';
    }
  }

  protected getScheduleAlertDescription(code: MacropointOrderScheduleAlertCode): string {
    switch (code) {
      case MacropointOrderScheduleAlertCode.CannotDetermine:
        return 'Cannot determine';
      case MacropointOrderScheduleAlertCode.OnTimeOrAheadOfSchedule:
        return 'On time or ahead of schedule';
      case MacropointOrderScheduleAlertCode.BehindSchedule:
        return 'Behind schedule';
      case MacropointOrderScheduleAlertCode.CannotMakeIt:
        return 'Cannot make it';
      case MacropointOrderScheduleAlertCode.PastAppointmentTime:
        return 'Past appointment date';
    }
  }

  protected showOlderMessages() {
    if (this.currentMessageIndex + this.messagePageSize < this.order.messages.length) {
      this.currentMessageIndex += this.messagePageSize;
      this.updateDisplayedMessages();
    }
  }

  protected showMoreRecentMessages() {
    if (this.currentMessageIndex - this.messagePageSize >= 0) {
      this.currentMessageIndex -= this.messagePageSize;
    } else {
      this.currentMessageIndex = 0; // Reset to show the most recent messages
    }
    this.updateDisplayedMessages();
  }

  private updateDisplayedMessages() {
    this.displayedMessages = this.order.messages.slice(this.currentMessageIndex, this.currentMessageIndex + this.messagePageSize);
  }

  private sortMessages() {
    this.order.messages?.sort((a, b) => b.received - a.received);
  }

  private isMacropointScheduleAlert(obj: MacropointOrderLocation | MacropointOrderTripEvent | MacropointOrderScheduleAlert): obj is MacropointOrderScheduleAlert {
    return !("longitude" in obj || "latitude" in obj);
  }
}
