import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { DealViewRaw, DealViewRawDeal, DealViewRawStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, isEmpty, isEqual, merge, pickBy } from 'lodash-es'
import { Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'
import { autosaveDealSegment } from 'src/app/store/deal-view.actions'
import { DealFormGroup, SegmentFormGroup, SegmentFormValue } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { SegmentFormRemoveService } from './segment-form-remove.service'
import { SegmentFormComponent, SegmentFormData } from './segment-form.component'

@Injectable()
export class SegmentFormService {
  constructor(
    private modal: ModalService,
    private SegmentFormRemove: SegmentFormRemoveService,
    private DealForm: DealFormService,
    private store: Store,
  ) { }

  async showSegmentForm(
    dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>,
    dealForm: DealFormGroup,
    segmentForm: SegmentFormGroup,
    readonly = false,
  ) {
    const detailsBackp = cloneDeep(dealForm.controls.details.getRawValue())
    const segmentBackup = cloneDeep(segmentForm.getRawValue())
    const segmentIndex = dealForm.controls.segments.controls.indexOf(segmentForm)
    return this.modal.openDialog<SegmentFormComponent, SegmentFormData>(SegmentFormComponent, {
      dealForm,
      dealViewRaw$,
      segmentForm,
      segmentIndex,
      readonly,
    }, {
      width: '900px',
    }).pipe(tap((result: SegmentFormValue) => {
      if (result) {
        const segmentPatch = pickBy(result, (value, key) => !isEqual(value, segmentBackup[key]))
        if (isEmpty(segmentPatch)) return
        const segment = merge(cloneDeep(segmentForm.value.segment), this.DealForm.readSegmentForm(segmentForm.value))
        segmentForm.patchValue({ segment })
        dealViewRaw$.pipe(take(1)).subscribe(dv => {
          this.store.dispatch(autosaveDealSegment({ dv, dealForm: dealForm.serialize(), index: segmentIndex, patch: segmentPatch }))
        })
      } else {
        dealForm.controls.details.setValue(detailsBackp)
        segmentForm.setValue(segmentBackup)
      }
    })).toPromise()
  }

  removeSegmentForm(dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>, dealForm: DealFormGroup, segmentForm: SegmentFormGroup) {
    this.SegmentFormRemove.removeSegmentForm(dv, dealForm, segmentForm)
  }
}
