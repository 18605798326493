import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { DocumentTemplate, FileObject, LANGUAGES } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { keyBy, map as _map, pick, without } from 'lodash-es'
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { FileApiService, FileUploaderService } from 'src/api/file'
import { loadAccounts, selectAccountsOptions } from 'src/app/store/accounts'
import { loadDocumentSets, selectAllDocumentSets } from 'src/app/store/document-sets'
import { createDocumentTemplate, createDocumentTemplateFailure, createDocumentTemplateSuccess, updateDocumentTemplate, updateDocumentTemplateFailure, updateDocumentTemplateSuccess } from 'src/app/store/document-templates'
import { loadProductCategories, selectAllProductCategories } from 'src/app/store/product-categories'
import { loadUsers, selectTradersOptions } from 'src/app/store/users'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


export interface DocumentFormOptions {
  title: string,
  document?: DeepReadonly<DocumentTemplate>,
  documents: DeepReadonly<DocumentTemplate[]>, // NOTE: very questionable. use ngrx.Store instead
}


@Component({
  selector: 'tc-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentFormComponent extends OnDestroyMixin {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private FileApi: FileApiService,
    private Files: FilesService,
    private FileUploader: FileUploaderService,
    private store: Store,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private dialogData: DocumentFormOptions,
    private dialogRef: MatDialogRef<DocumentFormComponent, DocumentTemplate>
  ) { super() }

  readonly title = this.dialogData.title
  readonly documents = keyBy(this.dialogData.documents, 'document_id')
  readonly LANGUAGES = _map(LANGUAGES)

  documentForm = new FormGroup({
    name: new FormControl(this.dialogData.document?.name, Validators.required),
    type: new FormControl(this.dialogData.document?.type, Validators.required),
    language: new FormControl(this.dialogData.document?.language || 'en', Validators.required),
    product_category_id: new FormControl(this.dialogData.document?.product_category_id),
    company: new FormControl(this.dialogData.document?.company),
    doc_set: new FormControl(this.dialogData.document?.doc_set),
    traders: new FormControl(this.dialogData.document?.traders),
    description: new FormControl(this.dialogData.document?.description),
  })
  inProgress$ = new BehaviorSubject<'save' | 'loading'>(undefined)

  documentSets$ = combineLatest([
    this.store.pipe(select(selectAllDocumentSets), waitNotEmpty()),
    replayForm(this.documentForm.controls.type),
  ]).pipe(map(([docSets, type]) => docSets.filter(docset => {
    const existingTypes = _map(pick(this.documents, without(docset.docs, this.dialogData.document?.document_id)), 'type')
    type = (type || '').toLowerCase()
    return !existingTypes.map(x => x.toLowerCase()).includes(type)
  })))
  categories$ = this.store.pipe(select(selectAllProductCategories), waitNotEmpty())
  traders$ = this.store.pipe(select(selectTradersOptions()), waitNotEmpty())
  companies$ = this.store.pipe(select(selectAccountsOptions(this.dialogData.document?.company)), waitNotEmpty())
  documentFile$ = new ReplaySubject<FileObject>(1)

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>


  ngOnInit() {
    this.store.dispatch(loadUsers({ /* only TC traders */ }))
    this.store.dispatch(loadDocumentSets({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadAccounts({}))
    if (this.dialogData.document?.file_id) {
      this.FileApi.get(this.dialogData.document?.file_id)
      .then(r => {
        this.documentFile$.next(r.data)
      })
      .catch(err => {
        console.error('Unable to load document file.', err)
        this.toaster.error('Unable to load document file.', err)
        this.documentFile$.next(undefined)
      })
    } else {
      this.documentFile$.next(undefined)
    }

    this.inProgress$.next('loading')
    combineLatest([
      this.documentSets$,
      this.categories$,
      this.traders$,
      this.companies$,
      this.documentFile$,
    ]).pipe(take(1), untilComponentDestroyed(this)).subscribe(() => {
      this.inProgress$.next(undefined)
    })

    combineLatest([
      this.actions$.pipe(ofType(updateDocumentTemplateSuccess, createDocumentTemplateSuccess), tap(() => this.dialogRef.close())),
      this.actions$.pipe(ofType(updateDocumentTemplateSuccess, createDocumentTemplateSuccess, updateDocumentTemplateFailure, createDocumentTemplateFailure), tap(() => this.inProgress$.next(undefined))),
    ]).pipe(untilComponentDestroyed(this)).subscribe()
  }

  cancel() {
    this.dialogRef.close()
  }

  async upload(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]
    if (!file) return
    const stored = await this.FileUploader.uploadFile(file, {
      visibility: 0
    }).toPromise()
    this.documentFile$.next(stored)
  }

  download() {
    this.documentFile$.pipe(take(1), untilComponentDestroyed(this)).subscribe(file => {
      this.Files.download(file)
    })
  }

  removeDocumentFile = () => {
    // this.formData.file_id = undefined
    // this.fileUploader.queue.splice(0)
    this.documentFile$.next(undefined)
  }

  save() {
    // const fields = ['file_id', 'name', 'type', 'doc_set', 'description', 'language', 'company', 'product_category_id', 'archived', 'attributes', 'traders']

    if (this.inProgress$.value) return
    this.documentForm.markAllAsTouched()
    this.documentForm.updateValueAndValidity()
    if (!this.documentForm.valid) return

    const documentForm = this.documentForm.value
    documentForm.company = documentForm.company ? documentForm.company.toString() : null
    documentForm.product_category_id = documentForm.product_category_id || null

    this.documentFile$.pipe(take(1), untilComponentDestroyed(this)).subscribe(async file => {
      if (!file) return

      this.inProgress$.next('save')

      // TODO: backend should set user_id;
      const {user_id} = this.AuthApi.currentUser
      const payload = { ...documentForm, user_id, file_id: file.file_id }
      if (this.dialogData.document?.document_id) {
        this.store.dispatch(updateDocumentTemplate({
          id: this.dialogData.document.document_id,
          template: payload,
          original: this.dialogData.document }))
      } else {
        this.store.dispatch(createDocumentTemplate({ template: payload }))
      }
    })
  }
}

