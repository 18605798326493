import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'tc-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToasterComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      head?: string,
      body?: string,
      html?: string|SafeHtml,
      progress?: boolean
    },
    private sanitizer: DomSanitizer,
  ) {
    if (data.head && !data.body && !data.html) {
      data.body = data.head
      data.head = ''
    } else if (data.html && typeof data.html === 'string') {
      data.html = this.sanitizer.bypassSecurityTrustHtml(data.html)
    }
  }
}
