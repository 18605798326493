import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'src/shared/modal';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SwapServiceProviderDialogComponent } from './swap-service-provider-dialog.component';
import { SwapServiceProviderDialogService } from './swap-service-provider-dialog.service';
import { CostsListModule } from 'src/components/costs-list/costs-list.module';

@NgModule({
  declarations: [SwapServiceProviderDialogComponent],
  providers: [SwapServiceProviderDialogService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ModalModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    CostsListModule,
  ],
})
export class SwapServiceProviderDialogModule {}
