import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


@Component({
  selector: 'tc-breadcrumbsv2', // TODO: rename after upgrade is done
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.html'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input()
  activeView: string

  crumbs: { url: string, title: string }[] = []

  constructor(route: ActivatedRoute) {
    for (let { pathFromRoot, parent, snapshot } = route.parent; !!parent; {pathFromRoot, snapshot, parent} = parent) {
      let url = '/' + pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(f => f.map(x => x.path)).join('/')
      if (url === '/trading') url = '/trading/deals'
      else if (url === '/logistics') url = '/logistics/shipping-log'
      this.crumbs.unshift({
        title: snapshot.data.title,
        url,
      })
    }
  }
}
