import { Injectable } from '@angular/core'
import { Cost, DEAL_DRAFT, Deal, DealParty, DealProduct, DealViewRaw, DealViewStatus, Estimate, ForexData, Product } from '@tradecafe/types/core'
import { find, get, keyBy, pick } from 'lodash-es'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { dayjs } from 'src/services/dayjs'

@Injectable()
export class EstimatesService {
  constructor(
    private Measures: MeasuresService,
  ) { }


  convertEstimateToDeal(
    estimate: Estimate & { product?: Product },
    side: DealParty,
    { measures, countries, latestRates }: { measures, countries, latestRates: ForexData },
  ): { dealViewRaw: DealViewRaw, dealProducts: DealProduct[] } {
    const {attributes = {}, cost_info = {}, product } = estimate

    const countriesByName = keyBy(countries, 'name')
    const now = dayjs.utc().unix()

    const {
      buyCurrency, buyUnitWeight, buyPrice, weight,
      sellCurrency, bidUnitWeight, sellPrice, weightRate,
      grossMargin,
    } = this.getSupplierPriceValues(estimate, { measures, latestRates })

    const activeSide = estimate.supplier_detail

    const dealProducts: DealProduct[] = [{
      product_id: product?.product_id,
      type_id: product?.type_id,
      category_id: product?.category_id,
      attributes: {},
      supplier: {
        currency_code: buyCurrency,
        measure_id: buyUnitWeight.measure_id,
        price: buyPrice,
        weight,
      },
      buyer: {
        currency_code: sellCurrency,
        measure_id: bidUnitWeight.measure_id,
        price: sellPrice,
        weight: weight * weightRate,
      },
    // TODO: prefill necessary fields from buyer and supplier, reuse code from deal-details component remove type casting
    } as DealProduct]
    const costs = this.processCosts(cost_info.costs) as Cost[]
    const deal = {
      deal_type: 'estimated',
      status: DEAL_DRAFT,
      created: now,
      supplier_id: attributes.supplier_id?.toString(),
      buyer_id: attributes.buyer_id?.toString(),
      attributes: {
        deal_date: now,
        estimate_id: estimate.estimate_id,
        finance_term: get(cost_info, 'est_finance_term_days', 30),
        // fx_rates: map(latestRates.rates, 'spot') as unknown as ForexData,
        total: {
          currency_code: sellCurrency,
          margin: get(activeSide, 'bottom.margin', 0),
          margin_p: grossMargin,
          raw_cost: get(activeSide, 'bottom.raw_cost', 0),
          target_cost: get(activeSide, 'bottom.target_price', 0),
          weight: {
            amount: weight,
            measure_id: buyUnitWeight.measure_id,
          },
        },
        // NOTE: for some unknown reason we store country name in `estimate.attributes`
        //       however `deal.attributes.origin_country` expects country code
        origin_country: get(countriesByName[attributes.origin_country], 'code'),
      },
    } as Deal
    return {
      dealViewRaw: {
        deal,
        costs,
        invoices: [],
        credit_notes: [],
        files: [],
        vendor_credits: [],
        offers: [],
        bids: [],
        segments: [],
        notes: [],
        bookings: [],
        export_reports: [],
        status: DealViewStatus.complete,
        status_state: [],
      },
      dealProducts,
    }
  }

  private processCosts(costs = []): Partial<Cost>[] {
    return costs.map((cost) => {
      // TODO: WA-2047 use CustomCosts service
      return {
        status: 'pending',
        type: cost.default ? 'secondary' : 'tertiary',
        provider: cost.provider,
        service: cost.name,
        product_id: cost.product_id,
        attributes: {
          default_cost: pick(cost, ['core', 'cost_type_id', 'name', 'type', 'value', 'currency']),
        },
        amount: {
          currency: cost.currency || 'CAD',
          cost_unit: cost.unitCostCAD || 0,
          total: cost.value || 0,
          total_cad: cost.amountCAD || 0,
        },
      }
    })
  }

  getSupplierPriceValues(estimate, { measures, latestRates }) {
    const {supplier_detail = {}} = estimate

    const buyPrice = get(supplier_detail, 'top.price', 0)
    const buyCurrency = get(supplier_detail, 'top.currency', 'USD')
    const buyCurrencyRate = latestRates.rates[buyCurrency].spot.ask
    const sellPrice = get(supplier_detail, 'bottom.sell_price', 0)
    const sellCurrency = get(supplier_detail, 'bottom.currency', 'USD')
    const sellCurrencyRate = latestRates.rates[sellCurrency].spot.bid
    const grossMargin = get(supplier_detail, 'bottom.desired_margin', 0)
    const weight = get(supplier_detail, 'top.weight', 0)

    const buyUnitWeight = find(measures, {code: get(supplier_detail, 'top.measure')})
    const bidUnitWeight = find(measures, {code: get(supplier_detail, 'bottom.measure')})
    const weightRate = this.Measures.convert(1, // to target weight unit
      get(supplier_detail, 'top.measure'),
      get(supplier_detail, 'bottom.measure'))

    return {
      buyPrice,
      buyCurrency,
      buyCurrencyRate,
      sellPrice,
      sellCurrency,
      sellCurrencyRate,
      grossMargin,
      weight,
      weightRate,
      buyUnitWeight,
      bidUnitWeight,
    }
  }
}
