import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { PaymentReferencesService } from 'src/pages/admin/settings/admin-setting-payments/payment-references/payment-references.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadPaymentReferences, loadPaymentReferencesFailure, loadPaymentReferencesSuccess } from './payment-reference.actions'

@Injectable()
export class PaymentReferenceEffects {
  constructor(
    private actions$: Actions,
    private PaymentReferences: PaymentReferencesService,
    private toaster: ToasterService,
  ) {}

  loadPaymentReferences$ = createEffect(() => this.actions$.pipe(
    ofType(loadPaymentReferences),
    switchMap(action =>
      from(this.PaymentReferences.getPaymentReferences()).pipe(
        map(paymentReferences => loadPaymentReferencesSuccess({
          tableKey: action.tableKey,
          paymentReferences: paymentReferences,
        })),
        catchError(error => {
          console.error('Unable to fetch paymentReferences', error)
          this.toaster.error('Unable to fetch paymentReferences', error)
          return of(loadPaymentReferencesFailure({ error }))
        })))))
}
