import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { ModalModule } from 'src/shared/modal'
import { BookingRejectionDialogComponent } from './booking-rejection-dialog.component'
import { BookingRejectionDialogService } from './booking-rejection-dialog.service'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { ToasterModule } from 'src/shared/toaster/toaster.module'


@NgModule({
  declarations: [
    BookingRejectionDialogComponent,
  ],
  providers: [BookingRejectionDialogService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    ModalModule,
    ToasterModule,
  ],
})
export class BookingRejectionDialogModule { }
