import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Offer, SupplierOffer } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { environment } from 'src/environments/environment'
import { DISABLE_GLOBAL_EXCEPTION_HANDLING_CONTEXT } from './auth-interceptor.service'


const { auctioneerApiUrl } = environment


export default module => module
.service('SupplierOfferApi', downgradeInjectable(SupplierOfferApiService))


@Injectable()
@AngularCopy()
export class SupplierOfferApiService {
  constructor(private http: HttpClient) {}

  create(data: Partial<SupplierOffer>) {
    return this.http.post<{ data: SupplierOffer, error?: any }>(`${auctioneerApiUrl}/supplier_offer/new`, data).toPromise()
  }

  generate(data) {
    return this.http.post<{ data: SupplierOffer, error?: any }>(`${auctioneerApiUrl}/supplier_offer/generate`, data).toPromise()
  }

  clone(id: string, data) {
    return this.http.post<{ data: SupplierOffer, error?: any }>(`${auctioneerApiUrl}/supplier_offer/${id}/clone`, data).toPromise()
  }

  reload(id: string) {
    return this.http.post<{ data: SupplierOffer, error?: any }>(`${auctioneerApiUrl}/supplier_offer/${id}/reload`, {} , {
      context: DISABLE_GLOBAL_EXCEPTION_HANDLING_CONTEXT,
    }).toPromise()
  }

  attachMO(data) {
    const { offer_id: id } = data
    return this.http.post<{ data: SupplierOffer, error?: any }>(`${auctioneerApiUrl}/supplier_offer/${id}/attach`, data).toPromise()
  }

  filter(params) {
    return this.http.post<{ data: Offer[] }>(`${auctioneerApiUrl}/supplier_offer/filter`, params).toPromise()
  }
}
