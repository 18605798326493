import { NgModule } from "@angular/core";
import { FreightRatesListComponent } from "./freight-rates-list.component";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from 'src/shared/pipes/pipes.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator';
import { ToasterModule } from 'src/shared/toaster/toaster.module';
import { TypeSafeMatCellDefModule } from 'src/components/type-safe-mat-cell-def/type-safe-mat-cell-def.module';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from "@angular/material/tooltip";


@NgModule({
  declarations: [FreightRatesListComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    PipesModule,
    ToasterModule,
    TypeSafeMatCellDefModule,
    MatTooltipModule
  ],
  exports: [FreightRatesListComponent]
})
export class FreightRatesListModule { }
