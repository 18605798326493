import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'src/shared/modal';
import { CompanyLogOverlayComponent } from './company-log-overlay.component';
import { CompanyLogOverlayService } from './company-log-overlay.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AuditLogModule } from 'src/pages/admin/auction/supplier-offer-overlay/audit-log/audit-log.module';

@NgModule({
  declarations: [CompanyLogOverlayComponent],
  providers: [CompanyLogOverlayService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ModalModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    AuditLogModule,
  ],
})
export class CompanyLogOverlayModule {}
