<table mat-table fixedLayout [dataSource]="dataSource" matSort matSortActive="name" matSortDisableClear>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource">
      <div *ngIf="!row" class="skeleton-loader square"></div>
      <mat-checkbox *ngIf="row && selection.canSelect(row)"
          [checked]="selection.isSelected(row)"
          (click)="$event.stopPropagation()"
          (change)="selection.toggleRow(row)"
          ></mat-checkbox>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource"
        (click)="row && rowClick.emit(row)">
      <ng-container *ngIf="!row">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="row">
        <div class="text-ellipsis" [title]="row.name">{{row.name}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell style="width: 150px;" *matHeaderCellDef mat-sort-header>Code</th>
    <td mat-cell *matCellDef="let row"
        (click)="row && rowClick.emit(row)">
      <ng-container *ngIf="!row">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="row">
        <div class="text-ellipsis" [title]="row.code">{{row.code}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container style="width: 200px;" matColumnDef="establishment">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Establishment</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource"
        (click)="row && rowClick.emit(row)">
      <ng-container *ngIf="!row">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="row">
        <div class="text-ellipsis" [title]="row.establishment">{{row.establishment}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource"
        (click)="row && rowClick.emit(row)">
      <ng-container *ngIf="!row">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="row">
        <div class="text-ellipsis" [title]="row | address">{{row | address}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="carrier">
    <th mat-header-cell style="width: 200px;" *matHeaderCellDef mat-sort-header>Carrier</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource"
        (click)="row && rowClick.emit(row)">
      <ng-container *ngIf="!row">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="row">
        <div class="text-ellipsis" [title]="row | address">{{row.carrierName}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="companiesTableField">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Companies</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource"
        (click)="row && rowClick.emit(row)">
      <ng-container *ngIf="!row">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="row">
        <div class="text-ellipsis" [title]="row | address">{{row.companiesTableField}}</div>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns" class="clickable" [class.tc-loading-row]="!row"></tr>
</table>

<mat-paginator #paginator></mat-paginator>
