import { Injectable } from '@angular/core'
import { SUBMITTED_STATUSES } from '@tradecafe/types/core'
import { of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealAutocopyFormComponent, DealAutocopyFormOptions } from './deal-autocopy-form.component'

@Injectable()
export class DealAutocopyFormService {
  constructor(
    private modal: ModalService,
    private elastic: DealElasticSearchService,
  ) {}

  show(opts: DealAutocopyFormOptions) {
    const query = { supplier: [opts.supplierId], buyer: [opts.buyerId], status: SUBMITTED_STATUSES }
    return this.elastic.fetchDeals({ query, limit: 1, columns: ['deal_id']})
    .pipe(switchMap(res => {
      if (!res.data.length) return of(false) // nothing to show
      return this.modal.openDialog(DealAutocopyFormComponent, opts, {
        panelClass: ['tc-dialog', 'modal-xlg'],
        width: null,
        maxWidth: null,
      })
    }))
  }
}
