import { ChangeDetectionStrategy, Component } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { ConsigneeListItem } from 'src/pages/admin/settings/consignees/consignees-page.component'
import { dayjs } from 'src/services/dayjs'

export interface ConsigneeFormOptions {
  address?: ConsigneeListItem,
  isSettingsPage?: boolean,
}

@Component({
  selector: 'tc-mark-as-paid-form',
  templateUrl: './mark-as-paid-form.component.html',
  styleUrls: ['./mark-as-paid-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MarkAsPaidFormComponent extends OnDestroyMixin {
  constructor(
    private dialog: MatDialogRef<MarkAsPaidFormComponent, number>,
    private fb: UntypedFormBuilder,
  ) {
    super()
  }

  form = this.fb.group({
    paid: [, Validators.required],
  })

  async submit() {
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()
    if (!this.form.valid) return
    this.dialog.close(dayjs.unix(this.form.value.paid).utc().set('hour', 9).unix())
  }

  cancel() {
    this.dialog.close()
  }
}
