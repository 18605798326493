import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { ModalModule } from 'src/shared/modal'
import { AesCancellationDialogComponent } from './aes-cancellation-dialog.component'
import { AesCancellationDialogService } from './aes-cancellation-dialog.service'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'


@NgModule({
  declarations: [
    AesCancellationDialogComponent,
  ],
  providers: [AesCancellationDialogService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    ModalModule,
  ],
})
export class AesCancellationDialogModule { }
