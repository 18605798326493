import { Injectable } from '@angular/core'
import { Offer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class SupplierOfferCloneFormService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  public show(offer: DeepReadonly<Offer>) {
    return this.modalHelper.showModal({
      component: 'tcSupplierOfferCloneForm',
      windowClass: 'modalclone',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        offer,
      },
    })
  }
}

