import { createSelector } from '@ngrx/store'
import { keyBy } from 'lodash-es'
import { State } from '../reducers'
import { adapter, measuresFeatureKey } from './measure.reducer'

export const selectState = (state: State) => state[measuresFeatureKey]

const { selectEntities, selectAll  } = adapter.getSelectors(selectState)

export const selectAllMeasures = selectAll
export const selectMeasureEntities = createSelector(
  selectEntities,
  byId => ({
    ...byId,
    ...keyBy(byId, 'code'),
  }))
