import { Injectable } from '@angular/core'
import { User } from '@tradecafe/types/core'
import { ModalProxyService } from 'src/shared/modal'


export interface ClientShippingLogOptions {
  account: number,
  user?: User,
}

@Injectable()
export class CompanyContactFormService {
  constructor(
      private modalHelper: ModalProxyService,
  ) {
  }

  async showUpdateContact(options: ClientShippingLogOptions) {
    return this.modalHelper.showModal({
      component: 'tcCompanyContactForm',
      windowClass: 'modalShipping',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        company: () => {
          return {
            account: options.account,
          }
        },
        contact: () => options.user,
      },
    })
  }
}
