<h2 mat-dialog-title>
  <mat-icon class="fa fa-truck"></mat-icon>
  Autocopy previously submitted deal
</h2>
<tc-deals-list
    [dataSource]="dataSource"
    [filtersForm]="filtersForm"
    [displayColumns]="displayColumns"
    [columnNames]="columnNames"
    navigateTo="/trading/deals"
    [tableIdentity]="tableIdentity"
    ></tc-deals-list>
<div mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button (click)="copyDeal()" color="primary">Copy Deal</button>
</div>
