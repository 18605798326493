import { Injectable } from '@angular/core'
import { find, forEach, get, keyBy, sortBy } from 'lodash-es'

export interface CountryFromNpm {
  name: string
  native: string
  phone: string
  continent: string
  capital: string
  currency: string
  languages: string[]
  emoji: string
  emojiU: string
  code: string // we add this field
}

export interface CountryRegions {
  countryName: string
  countryShortCode: string
  regions: {
    name: string
    shortCode: string
  }[]
}

const countries: Dictionary<CountryFromNpm> = require('countries-list/dist/countries.emoji.json')
const regions: CountryRegions[] = require('country-region-data/data.json')
forEach(countries, (country, code) => { country.code = code }) // populate country.code

// TODO: start using http://geobytes.com/free-ajax-cities-jsonp-api/
@Injectable()
export class GeoService {

  private statesByCountry = keyBy(regions, 'countryShortCode')

  getCountries() {
    return countries
    // "AD": {
    //   "name": "Andorra",
    //   "native": "Andorra",
    //   "phone": "376",
    //   "continent": "EU",
    //   "capital": "Andorra la Vella",
    //   "currency": "EUR",
    //   "languages": [
    //     "ca"
    //   ],
    //   "emoji": "🇦🇩",
    //   "emojiU": "U+1F1E6 U+1F1E9"
    // }
  }

  splitPhoneNumber(phone) {
    const trimmedPhone = `${phone || ''}`.trim().replace(/^\+/, '').trim()
    const country = find(sortBy(countries,
      ({ phone: code }) => -parseInt(code, 10)),
      ({ phone: code }) => trimmedPhone.startsWith(code))
    if (!country) return { number: phone }
    return {
      country,
      code: `+${parseInt(country.phone, 10)}`,
      number: trimmedPhone.replace(country.phone, ''),
    }
  }

  getStates(countryCode) {
    return get(this.statesByCountry[countryCode], 'regions') || []
    // {
    //   "name":"Badakhshan",
    //   "shortCode":"BDS"
    // }
  }

  getCities(_countryCode) {
    return []// filter(cities, { country: countryCode })
    // {
    //   "country": "AD",
    //   "name": "Sant Julià de Lòria",
    //   "lat": "42.46372",
    //   "lng": "1.49129"
    // }
  }

  getStateName(countryCode: string, stateCode: string) {
    if (!stateCode) return ''
    const country = find(regions, {countryShortCode: countryCode})
                 || find(regions, {countryName: countryCode})
    const state = find(country?.regions, { shortCode: stateCode })
    return state?.name || stateCode
  }

  getStateCode(countryCode: string, stateName: string) {
    if (!countryCode || !stateName) return ''
    const country = find(regions, {countryShortCode: countryCode})
                 || find(regions, {countryName: countryCode})
    return find(country.regions, { name: stateName })?.shortCode || undefined
  }

}
