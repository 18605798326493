import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ModalModule } from 'src/shared/modal'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { CostFormComponent } from './cost-form.component'
import { CostFormService } from './cost-form.service'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
  declarations: [CostFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    FlexLayoutModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    ModalModule,
    PipesModule,
  ],
  providers: [CostFormService],
})
export class CostFormModule {}
