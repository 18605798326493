import { createSelector } from '@ngrx/store'
import { Country } from '@tradecafe/types/core'
import { map } from 'lodash-es'
import { compare } from 'src/services/table-utils/compare'
import { State } from '../reducers'
import { adapter, countriesFeatureKey, CountriesState } from './country.reducer'


export const selectState = (state: State) => state[countriesFeatureKey]

export const { selectEntities: selectCountryEntities, selectAll: selectAllCountries } = adapter.getSelectors(selectState)
export const selectCountriesByCode = createSelector(selectState, state => state.byCode)

export const selectCountry = createSelector(
  selectState,
  ({byCode, entities}: CountriesState, countryCodeOrId: string) =>
    byCode[countryCodeOrId] || entities[countryCodeOrId])

export const selectCountriesByIds = createSelector(
  selectCountryEntities,
  (countries: Dictionary<Country>, countryIds: string[]) =>
    countryIds.map(countryId => countries[countryId]))

export const selectCountryCodes = createSelector(
  selectCountryEntities,
  (countries: Dictionary<Country>) =>
    map(countries, country => country.code).sort(compare))
