import { Injectable } from '@angular/core'
import { Cost, Deal, isVendorCreditApproved, PREPAYMENT_CREDIT_REFUNDED, VendorCredit, VENDOR_CREDIT_APPROVED, VENDOR_CREDIT_PAID, VENDOR_CREDIT_VOIDED } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { defaults, filter, find, get, groupBy, merge, pick, remove, uniq } from 'lodash-es'
import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { InvoiceApiService } from 'src/api/invoice'
import { VendorCreditApiService } from 'src/api/vendor-credit'
import { environment } from 'src/environments/environment'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { dayjs } from '../dayjs'
import { CostsService } from './costs.service'

const ALLOWED_FIELDS = ['account', 'amount', 'approval', 'attributes', 'cost_id', 'deal_id', 'invoice_id', 'status', 'vendor_number', 'currency']
const TRADER_CREDIT_LIMIT = 200 // CAD
const QUERY_ALL = {limit: Number.MAX_SAFE_INTEGER}
const { tradecafeAccount } = environment


interface UpdateContext {
  cost: Cost
  deal?: Deal
}


@Injectable()
export class VendorCreditsService {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private VendorCreditApi: VendorCreditApiService,
    private Costs: CostsService,
    private FxRates: FxRatesService,
    private InvoiceApi: InvoiceApiService,
  ) {}

  async getAllVendorCredits() {
    const { data: credits } = await this.VendorCreditApi.list(tradecafeAccount, QUERY_ALL)
    return credits.map(item => ({
      ...item,
      account: +item.account,
    }))
  }

  // TODO: check traders authorization and use getCreditNotesForDeals?
  getVendorCreditsByDealIds(dealIds: string[]) {
    if (!dealIds.length) return Promise.resolve([])
    return this.getAllVendorCredits().then(items =>
      items.filter(({deal_id}) => dealIds.includes(deal_id)))
  }

  getVendorCreditsByInvoiceIds(invoiceIds: string[]) {
    if (!invoiceIds.length) return of([])
    return this.VendorCreditApi.listByInvoices(invoiceIds).pipe(map(({ data: vendorCredits }) =>
      // WA-2231 voided credit notes should not be included
      filter(vendorCredits, 'status')))
  }

  async getVendorCreditsForDeals(dealIds: string[]) {
    let { data: vendorCredits } = await this.VendorCreditApi.listByDeals(dealIds)
    // WA-2231 voided credit notes should not be included
    vendorCredits = filter(vendorCredits, 'status')
    return groupBy(vendorCredits, 'deal_id')
  }

  getVendorCreditsForAccount(account: number|string) {
    return this.VendorCreditApi.list(account, QUERY_ALL).then(r => r.data)
  }

  getByIds(ids: string[] = []) {
    if (!ids.length) return []
    return Promise.all(ids.map((id) => {
      return this.VendorCreditApi.get(id).then(({data}) => data)
    }))
  }

  /**
   * Upsert credit note, update associated cost and refresh consignee if needed
   *
   * @param {*} creditNote
   * @param {*} context - {deal, cost}
   * @returns
   */
  saveVendorCredit(vendorCredit: VendorCredit, context: UpdateContext) {
    if (this.canAutoApprove(vendorCredit, context)) {
      this.setVendorCreditApproved(vendorCredit)
    }
    return this.save(vendorCredit, context, {
      success: 'Vendor credit saved successfully.',
      failure: 'Unable to save vendor credit',
    })
  }

  /**
   * savePaidVendorCredit
   *
   * @param {VendorCredit} vendorCredit
   * @returns VendorCredit
   */
  savePaidVendorCredit(vendorCredit: DeepReadonly<VendorCredit>): Promise<VendorCredit> {
    return this.VendorCreditApi.update(vendorCredit.credit_note_id, {
      status: VENDOR_CREDIT_PAID,
    }).then(r => r.data)
  }

  /**
   * saveRefundedVendorCredit
   *
   * @param {VendorCredit} vendorCredit
   * @returns VendorCredit
   */
  saveRefundedVendorCredit(vendorCredit: DeepReadonly<VendorCredit>): Promise<VendorCredit> {
    return this.VendorCreditApi.update(vendorCredit.credit_note_id, {
      status: PREPAYMENT_CREDIT_REFUNDED,
    }).then(r => r.data)
  }

  /**
   * Void credit note
   *
   * @param {*} vendorCredit
   * @returns
   */
  async voidVendorCredit(vendorCredit: VendorCredit, context: Pick<UpdateContext, 'cost'>) {
    await this.VendorCreditApi.void(vendorCredit.credit_note_id)
    vendorCredit.status = VENDOR_CREDIT_VOIDED
    if (vendorCredit.cost_id) {
      context.cost = context.cost || await this.Costs.getCostById(vendorCredit.cost_id)
      await this.updateAssociatedCost(vendorCredit, context)
    }

    if (vendorCredit.invoice_id) {
      await this.removeVendorCreditFromInvoice(vendorCredit.credit_note_id, vendorCredit.invoice_id)
    }

    return vendorCredit
  }

  private async removeVendorCreditFromInvoice(vendorCreditId: string, invoiceId: string) {
    const { data: invoice } = await this.InvoiceApi.get(tradecafeAccount, invoiceId)
    const vendor_credits = get(invoice, 'attributes.vendor_credits', [])
    remove(vendor_credits, id => id === vendorCreditId)
    await this.InvoiceApi.update(invoice.account, invoice.invoice_id, {
      attributes: { ...invoice.attributes, vendor_credits }})
  }

  /**
   * Upsert credit note, update associated cost, refresh deal consignee if needed
   *
   * @private
   * @param {*} vendorCredit
   * @param {*} deal
   * @param {*} msg
   * @returns
   */
  private async save(vendorCredit: VendorCredit, context: UpdateContext, msg: { failure: string, success: string }) {
    let stored
    try {
      stored = await this.patchVendorCredit(vendorCredit)
      this.toaster.success(msg.success)
    } catch (err) {
      console.error(msg.failure, err)
      this.toaster.error(msg.failure, err)
      throw err
    }

    try {
      await this.updateAssociatedCost(stored, context)
      this.toaster.success('Costs updated successfully.')
    } catch (err) {
      console.error('Unable to update cost for this credit note', err)
      this.toaster.error('Unable to update cost for this credit note', err)
      throw err
    }

    return stored
  }


  /**
   * Create/Update a credit note document
   *
   * @param {any} vendorCredit
   * @returns
   */
  async patchVendorCredit(vendorCredit: Partial<VendorCredit>) {
    const {user_id} = this.AuthApi.currentUser
    const payload = pick(vendorCredit, ALLOWED_FIELDS)
    if (!vendorCredit.credit_note_id) {
      payload.user_id = user_id
    }
    if (payload.account) {
      payload.account = payload.account.toString()
    }

    const {data: stored} = vendorCredit.credit_note_id
      ? await this.VendorCreditApi.update(vendorCredit.credit_note_id, payload)
      : await this.VendorCreditApi.create(payload)
    return stored
  }

  /**
   * Update associated cost
   *
   * @param {any} vendorCredit
   * @param {any} {cost}
   * @returns
   */
  private async updateAssociatedCost(vendorCredit: VendorCredit, {cost}: UpdateContext) {
    const {credit_note_id} = vendorCredit
    const attributes = defaults(cost.attributes, {
      vendor_credits: [],
      vendor_credits_ex: [],
    } as Partial<Cost['attributes']>)
    attributes.vendor_credits.push(credit_note_id)
    attributes.vendor_credits = uniq(attributes.vendor_credits)

    let costVendorCredit = find(attributes.vendor_credits_ex, {credit_note_id})
    if (isVendorCreditApproved(vendorCredit)) {
      if (!costVendorCredit) {
        attributes.vendor_credits_ex.push({
          credit_note_id,
          amount: vendorCredit.amount,
          date: vendorCredit.created,
          currency: vendorCredit.currency
        })
      } else {
        costVendorCredit.amount = vendorCredit.amount
        costVendorCredit.date = vendorCredit.created
        costVendorCredit.currency = vendorCredit.currency
      }
    } else {
      remove(attributes.vendor_credits_ex, {credit_note_id})
    }
    return this.Costs.update(cost)
  }


  /**
   * Check is current user can approve credit note
   *
   * @param {VendorCredit} vendorCredit
   * @param {UpdateContext} context
   * @returns
   */
  isApprovable(vendorCredit: VendorCredit, context: UpdateContext) {
    const {role, user_id} = this.AuthApi.currentUser
    const {trader_user_id} = context.deal || {}
    const vendorCredits = get(context.cost, 'attributes.vendor_credits', [])
    if (role !== 'trader') return true
    const amount = this.FxRates.toCADSync(
      vendorCredit.amount,
      vendorCredit.currency,
      'ask',
      context.deal.attributes.fx_rates.rates,
      context.deal.attributes.fx_rates_ask_range)
    return user_id === trader_user_id && amount < TRADER_CREDIT_LIMIT && vendorCredits.length < 2
  }

  /**
   * Check if a credit note can be automatically approved (WA-2154)
   *
   * @private
   * @param {*} vendorCredit
   * @param {*} context - {cost, deal}
   * @returns {boolean}
   */
  private canAutoApprove(vendorCredit: Pick<VendorCredit, 'credit_note_id'|'amount'|'currency'>, context: UpdateContext) {
    if (vendorCredit.credit_note_id) return false
    const {role, user_id} = this.AuthApi.currentUser

    const isBelowLimit = TRADER_CREDIT_LIMIT > this.FxRates.toCADSync(
        vendorCredit.amount,
        vendorCredit.currency,
        'ask',
        context.deal.attributes.fx_rates.rates,
        context.deal.attributes.fx_rates_ask_range)

    const isBuyerTrader = !vendorCredit.credit_note_id &&
        role === 'trader' &&
        user_id === context.deal.trader_user_id

    return isBelowLimit && isBuyerTrader
  }

  /**
   * Set credit note approved status
   *
   * @private
   * @param {*} vendorCredit
   */
  setVendorCreditApproved(vendorCredit: VendorCredit) {
    const {user_id} = this.AuthApi.currentUser
    return merge(vendorCredit, {
      status: VENDOR_CREDIT_APPROVED,
      approval: {
        user_id,
        date: dayjs.utc().unix(),
      },
    })
  }
}
