import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { PaymentMethod } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('PaymentMethodApi', downgradeInjectable(PaymentMethodApiService))


@Injectable()
@AngularCopy()
export class PaymentMethodApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: PaymentMethod[] }>(`${apiUrl}/payment-method`, { params }).toPromise()
  }

  create(data: Partial<PaymentMethod>) {
    return this.http.post<{ data: PaymentMethod }>(`${apiUrl}/payment-method`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: PaymentMethod }>(`${apiUrl}/payment-method/${id}`).toPromise()
  }

  update(id: string, data: Partial<PaymentMethod>) {
    return this.http.put<{ data: PaymentMethod }>(`${apiUrl}/payment-method/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: PaymentMethod }>(`${apiUrl}/payment-method/${id}`).toPromise()
  }
}
