<div *ngIf="isNew">
  <button mat-flat-button [color]="(segmentTypeLand$ | async) ? 'primary' : 'default'" type="button"
      (click)="setSegmentType('land')">Truck</button>
  <button mat-flat-button [color]="(segmentTypeSea$ | async) ? 'primary' : 'default'" type="button"
      (click)="setSegmentType('sea')">Vessel</button>
  <button mat-flat-button [color]="(segmentTypeAir$ | async) ? 'primary' : 'default'" type="button"
      (click)="setSegmentType('air')">Airplane</button>
  <button mat-flat-button [color]="(segmentTypeRail$ | async) ? 'primary' : 'default'" type="button"
      (click)="setSegmentType('rail')">Train</button>
</div>
<ng-container [formGroup]="segmentForm">
  <div gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <mat-icon class="fa fa-2x"
          [class.fa-train]="segmentTypeRail$ | async"
          [class.fa-plane]="segmentTypeAir$ | async"
          [class.fa-truck]="segmentTypeLand$ | async"
          [class.fa-ship]="segmentTypeSea$ | async"
          ></mat-icon>
      <span fxFlex></span>
      <button mat-icon-button type="button" matTooltip="Add/See Notes" (click)="showSegmentNotes()">
        <mat-icon class="fa fa-2x fa-pencil"></mat-icon>
      </button>
      <button mat-icon-button type="button" *ngIf="canRemoveSegment$ | async" matTooltip="Delete Segment" (click)="removeSegment.next()">
        <mat-icon class="fa fa-2x fa-trash-can"></mat-icon>
      </button>
    </div>
    <tc-select-search placeholder="Freight forwarder"
        [readonly]="readonly"
        [group]="segmentForm" ctrlName="freightForwarderId"
        (change)="onFreightForwarderChange()"
        [items]="freightForwarders$ | async"
        bindValue="carrier_id"
        bindLabel="name"
        ></tc-select-search>
    <tc-select-search [placeholder]="(segmentTypeSea$ | async) ? 'Port Of Loading' : 'Loading Location'"
        [readonly]="readonly"
        [group]="segmentForm" ctrlName="originId"
        [items]="locations$ | async"
        bindValue="location_id"
        bindLabel="name"
        bindHint="fullname"
        ></tc-select-search>
    <tc-select-search [placeholder]="(segmentTypeSea$ | async) ? 'Port Of Discharge' : 'Drop-off Location'"
        [readonly]="readonly"
        [group]="segmentForm" ctrlName="destinationId"
        [items]="locations$ | async"
        bindValue="location_id"
        bindLabel="name"
        bindHint="fullname"
        ></tc-select-search>
    <tc-select-search placeholder="Carrier"
        [readonly]="readonly"
        [group]="segmentForm" ctrlName="carrierId"
        [items]="carriers$ | async"
        bindValue="carrier_id"
        bindLabel="name"
        (change)="onCarrierEvent()"
        ></tc-select-search>
    <div gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
      <mat-form-field>
        <mat-label>Freight rate</mat-label>
        <input matInput [readonly]="readonly" type="number" step="0.01" min="0" formControlName="amount">
      </mat-form-field>
      <tc-select-search placeholder="Unit"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="currencyCode"
          [items]="currencyCodes$ | async"
          ></tc-select-search>
    </div>

    <tc-address-field placeholder="Exact Loading Address" [readonly]="readonly" expand="always"
        [ctrl]="segmentForm.controls.exactLoadingAddress"
        [accountCtrl]="segmentForm.controls.exactLoadingAccount"
        [addressFormat]="['name', 'street1', 'street2', 'city', 'state_code', 'country', 'postal']" [multiline]="true"
        [pickerOptions]="exactLoadingAddressOptions$ | async"
        ></tc-address-field>
    <tc-address-field placeholder="Exact Dropoff Address" [readonly]="readonly" expand="always"
        [ctrl]="segmentForm.controls.exactDropoffAddress"
        [accountCtrl]="segmentForm.controls.exactDropoffAccount"
        [addressFormat]="['name', 'street1', 'street2', 'city', 'state_code', 'country', 'postal']" [multiline]="true"
        [pickerOptions]="exactDropoffAddressOptions$ | async"
        ></tc-address-field>

    <ng-container *ngIf="segmentTypeSea$ | async">
      <div gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
        <mat-form-field>
          <mat-label>Container #</mat-label>
          <input matInput [readonly]="readonly" formControlName="containerNumber" (change)="updateSegmentsBelow()">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Seal #</mat-label>
          <input matInput [readonly]="readonly" formControlName="sealNumber" (change)="updateSegmentsBelow()">
        </mat-form-field>
      </div>
      <tc-epoch-field placeholder="Cut-off Date/time"
          type="datetime"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="cutoffDatetime"
          ></tc-epoch-field>
    </ng-container>

    <ng-container *ngIf="segmentTypeLand$ | async">
      <mat-form-field>
        <mat-label>Pickup Reference #</mat-label>
        <input matInput [readonly]="readonly" formControlName="pickupRefNo">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Delivery Reference #</mat-label>
        <input matInput [readonly]="readonly" formControlName="deliveryRefNo">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="segmentTypeAir$ | async">
      <mat-form-field>
        <mat-label>Flight Number #</mat-label>
        <input matInput [readonly]="readonly" formControlName="flightNo">
      </mat-form-field>
      <mat-form-field>
        <mat-label>AWB # (Tracking #)</mat-label>
        <input matInput [readonly]="readonly" formControlName="trackingNo">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!(segmentTypeSea$ | async)">
      <mat-form-field *ngIf="segmentTypeLand$ | async">
        <mat-label>Facility had claims</mat-label>
        <input matInput [readonly]="readonly" formControlName="facilityHadClaims">
      </mat-form-field>
      <tc-epoch-field format="L LT" placeholder="Cut-off Date/time" [gdColumn]="(segmentTypeLand$ | async) ? '' : 'span 2'"
          type="datetime"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="cutoffDatetime"
          ></tc-epoch-field>
    </ng-container>

    <div gdColumns="minmax(0, 7fr) minmax(0, 5fr)" gdGap="0 15px">
      <tc-epoch-field format="L" [hour]="8" [placeholder]="(segmentTypeSea$ | async) ? 'Est. ETD date' : 'Est. load date'"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="etdDate"
          (changed)="changedSegmentDate$.next('etdDate')"
          ></tc-epoch-field>
      <tc-epoch-field type="time" [placeholder]="(segmentTypeSea$ | async) ? 'Est. ETD time' : 'Est. load time'"
          [readonly]="readonly"
          [group]="internalForm" ctrlName="etdTime"
          ></tc-epoch-field>
    </div>
    <div gdColumns="minmax(0, 7fr) minmax(0, 5fr)" gdGap="0 15px">
      <tc-epoch-field format="L" [hour]="8" [placeholder]="(segmentTypeSea$ | async) ? 'Est. ETA date' : 'Est. drop-off date'"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="etaDate"
          (changed)="changedSegmentDate$.next('etaDate')"
          ></tc-epoch-field>
      <tc-epoch-field type="time" [placeholder]="(segmentTypeSea$ | async) ? 'Est. ETA time' : 'Est. drop-off time'"
          [readonly]="readonly"
          [group]="internalForm" ctrlName="etaTime"
          ></tc-epoch-field>
    </div>

    <div gdColumns="minmax(0, 7fr) minmax(0, 5fr)" gdGap="0 15px">
      <tc-epoch-field format="L" [hour]="8" [placeholder]="(segmentTypeSea$ | async) ? 'Act. ETD date' : 'Act. load date'"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="actualPickupDate"
          (changed)="changedSegmentDate$.next('actualPickupDate')"
          ></tc-epoch-field>
      <tc-epoch-field type="time" [placeholder]="(segmentTypeSea$ | async) ? 'Act. ETD time' : 'Act. load time'"
          [readonly]="readonly"
          [group]="internalForm" ctrlName="actualPickupTime"
          ></tc-epoch-field>
    </div>
    <div gdColumns="minmax(0, 7fr) minmax(0, 5fr)" gdGap="0 15px">
      <tc-epoch-field format="L" [hour]="8" [placeholder]="(segmentTypeSea$ | async) ? 'Act. ETA date' : 'Act. drop-off date'"
          [readonly]="readonly"
          [group]="segmentForm" ctrlName="actualDeliveryDate"
          (changed)="changedSegmentDate$.next('actualDeliveryDate')"
          ></tc-epoch-field>
      <tc-epoch-field type="time" [placeholder]="(segmentTypeSea$ | async) ? 'Act. ETA time' : 'Act. drop-off time'"
          [readonly]="readonly"
          [group]="internalForm" ctrlName="actualDeliveryTime"
          ></tc-epoch-field>
    </div>
  </div>
  <div gdColumns="repeat(4, minmax(0, 1fr))" gdGap="0 15px">
    <mat-form-field *ngIf="!(segmentTypeAir$ | async)">
      <mat-label>Booking #</mat-label>
      <input matInput [readonly]="readonly" formControlName="bookingId" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <mat-form-field *ngIf="segmentTypeSea$ | async">
      <mat-label>Bill of lading</mat-label>
      <input matInput [readonly]="readonly" formControlName="billOfLadingNo" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <tc-epoch-field format="L" placeholder="Bill of Lading Date" *ngIf="segmentTypeSea$ | async"
        [readonly]="readonly"
        [group]="detailsForm" ctrlName="shipmentBolDate"
        (changed)="changedSegmentDate$.next('shipmentBolDate')"
        ></tc-epoch-field>
    <mat-form-field *ngIf="!(segmentTypeSea$ | async)">
      <mat-label>Import permit</mat-label>
      <input matInput [readonly]="readonly" formControlName="importPermit" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="(segmentTypeLand$ | async) && (showAes$ | async)">
      <mat-label>AES #</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentAes">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="showB13$ | async">
      <mat-label>B13 #</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentB13">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="showPaps$ | async">
      <mat-label>PAPS #</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentPaps">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="showPedimento$ | async">
      <mat-label>Pedimento #</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentImportNo">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="showMexicanInvNo$ | async">
      <mat-label>Mexican Invoice #</mat-label>
      <input matInput [readonly]="readonly" formControlName="mexicanInvoiceNo">
  </mat-form-field>
  </div>
  <div gdColumns="repeat(4, minmax(0, 1fr))" *ngIf="!(segmentTypeSea$ | async)" gdGap="0 15px">
    <mat-form-field *ngIf="segmentTypeRail$ | async">
      <mat-label>Train</mat-label>
      <input matInput [readonly]="readonly" formControlName="train">
    </mat-form-field>
    <mat-form-field *ngIf="segmentTypeRail$ | async">
      <mat-label>Voyage #</mat-label>
      <input matInput [readonly]="readonly" formControlName="voyageNo">
    </mat-form-field>
    <mat-form-field *ngIf="!(segmentTypeAir$ | async)">
      <mat-label>Container #</mat-label>
      <input matInput [readonly]="readonly" formControlName="containerNumber" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <mat-form-field *ngIf="!(segmentTypeAir$ | async)">
      <mat-label>Seal #</mat-label>
      <input matInput [readonly]="readonly" formControlName="sealNumber" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <mat-form-field *ngIf="segmentTypeRail$ | async">
      <mat-label>Loading Ramp</mat-label>
      <input matInput [readonly]="readonly" formControlName="loadingRamp" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Bill of lading</mat-label>
      <input matInput [readonly]="readonly" formControlName="billOfLadingNo" (change)="updateSegmentsBelow()">
    </mat-form-field>
    <tc-epoch-field format="L" placeholder="Bill of Lading Date" *ngIf="(segmentTypeAir$ | async) || (segmentTypeRail$ | async)"
        [readonly]="readonly"
        [group]="detailsForm" ctrlName="shipmentBolDate"
        (changed)="changedSegmentDate$.next('shipmentBolDate')"
        ></tc-epoch-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="(segmentTypeSea$ | async) || (segmentTypeLand$ | async)">
      <mat-label>US/CAD border</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentBorderCross">
    </mat-form-field>
  </div>
  <div gdColumns="repeat(4, minmax(0, 1fr))" *ngIf="segmentTypeLand$ | async" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Refer #</mat-label>
      <input matInput [readonly]="readonly" formControlName="referNo">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Plate #</mat-label>
      <input matInput [readonly]="readonly" formControlName="plateNo">
    </mat-form-field>
    <tc-epoch-field format="L" placeholder="Bill of Lading Date"
        [readonly]="readonly"
        [group]="detailsForm" ctrlName="shipmentBolDate"
        (changed)="changedSegmentDate$.next('shipmentBolDate')"
        ></tc-epoch-field>
  </div>
  <div gdColumns="repeat(4, minmax(0, 1fr))" *ngIf="segmentTypeLand$ | async" gdGap="0 15px">
    <tc-epoch-field format="L" placeholder="EPD"
        [readonly]="readonly"
        [group]="detailsForm" ctrlName="shipmentEpd"
        ></tc-epoch-field>
    <tc-epoch-field format="L" placeholder="ERD"
        [readonly]="readonly"
        [group]="detailsForm" ctrlName="shipmentErd"
        ></tc-epoch-field>
  </div>
  <div gdColumns="repeat(4, minmax(0, 1fr))" *ngIf="segmentTypeSea$ | async" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Name of Vessel</mat-label>
      <input matInput [readonly]="readonly" formControlName="vessel">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm">
      <mat-label>Voyage #</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentVoyageNo">
    </mat-form-field>
    <mat-form-field [formGroup]="detailsForm" *ngIf="showAes$ | async">
      <mat-label>AES #</mat-label>
      <input matInput [readonly]="readonly" formControlName="shipmentAes">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tracking #</mat-label>
      <input matInput [readonly]="readonly" formControlName="trackingNo">
    </mat-form-field>
  </div>
  <div gdColumns="repeat(4, minmax(0, 1fr))" *ngIf="segmentTypeSea$ | async" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Import permit</mat-label>
      <input matInput [readonly]="readonly" formControlName="importPermit">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Loading Ramp</mat-label>
      <input matInput [readonly]="readonly" formControlName="loadingRamp" (change)="updateSegmentsBelow()">
    </mat-form-field>
  </div>
</ng-container>
