import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  SwapServiceProviderDialogComponent,
  SwapServiceProviderDialogOptions,
  SwapServiceProviderDialogResult,
} from './swap-service-provider-dialog.component';
import { Observable } from 'rxjs';
import { DeepReadonly } from '@tradecafe/types/utils';
import { AccountObject, DealViewRaw } from '@tradecafe/types/core';
import { DealFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema';

@Injectable()
export class SwapServiceProviderDialogService {
  constructor(private modal: ModalService) {}

  showSwapServiceProvider(
    dealForm: DealFormGroup,
    dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>,
    newProvider: AccountObject
  ) {
    return this.modal.openDialog<
      SwapServiceProviderDialogComponent,
      SwapServiceProviderDialogOptions,
      SwapServiceProviderDialogResult | null
    >(
      SwapServiceProviderDialogComponent,
      {
        title: `Swap Service Provider`,
        dealForm,
        dealViewRaw$,
        newProvider,
      },
      {
        panelClass: ['tc-dialog', 'modal-xlg'],
        width: null,
        maxWidth: null,
        autoFocus: false,
      }
    );
  }
}
