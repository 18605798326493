<div mat-dialog-title>
  <h2>Deal #{{dealId}} Details</h2>
  <span fxFlex></span>
  <button mat-button type="button" (click)="cancel()">
    Cancel
  </button>
</div>
<div mat-dialog-content>
  <tc-deal-form-page>
  </tc-deal-form-page>
</div>
