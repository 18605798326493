import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Consignee } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as ConsigneesActions from './consignees.actions'

export const consigneesFeatureKey = 'consignees'
export interface ConsigneesState extends EntityState<DeepReadonly<Consignee>> {
}

const sortComparer = (a, b) => b.created - a.created

export const selectId = (consignee: Consignee) => consignee.consignee_id
export const adapter = createEntityAdapter<DeepReadonly<Consignee>>({
  sortComparer,
  selectId,
})

const initialState: ConsigneesState = adapter.getInitialState({
})

export const consigneesReducer = createReducer(
  initialState,
  on(ConsigneesActions.loadConsigneesSuccess,
    (state, action) =>
      adapter.upsertMany(action.consignees, state)),
  on(ConsigneesActions.createConsigneeSuccess,
    (state, action) =>
      adapter.addOne(action.consignee, state)),
  on(ConsigneesActions.updateConsigneeSuccess,
    (state, action) =>
      adapter.updateOne({id: action.consignee.consignee_id, changes: action.consignee}, state)),
)

