import { Pipe, PipeTransform } from '@angular/core'
import { get } from 'lodash-es'

@Pipe({
  name: 'fieldOrPath',
})
export class FieldOrPathPipe implements PipeTransform {

  transform(value: {}, field: string, defaultValue?): any {
    return get(value, field, defaultValue || null)
  }
}
