export const routes = [
  {
    name: 'app.reports',
    // parent: 'app',
    url: '/reports',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['trader', 'administrator', 'manager', 'superuser'],
      title: 'Reports',
    },
  },
];
