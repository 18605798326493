import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, BUYER, Cost, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawOffers, SupplierOffer, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { keyBy, uniq, uniqBy } from 'lodash-es'
import { BehaviorSubject, ReplaySubject, combineLatest, from, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { loadAccounts, selectAccountEntities, selectAllAccounts } from 'src/app/store/accounts'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { loadLocations, selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadUsers } from 'src/app/store/users'
import { CostsListComponent } from 'src/components/costs-list/costs-list.component'
import { BuyersGroupApiService } from 'src/pages/admin/settings/buyers-groups/buyers-groups-item/buyers-groups.service'
import { sortForHaveTestAccountsAfterReal } from 'src/services/data/accounts.service'
import { isCostActualized } from 'src/services/data/costs.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { SupplierOffersService } from 'src/services/data/supplier-offers.service'
import { VendorCreditsService } from 'src/services/data/vendor-credits.service'
import { dayjs } from 'src/services/dayjs'
import { compareBy } from 'src/services/table-utils/compare'
import { DealInvoicesDataSource } from 'src/services/table-utils/data-sources/deal-invoices-data-source'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { disableIf } from 'src/shared/utils/disable-if'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CostsFormGroup } from '../deal-form-page/deal-form.schema'
import { buildDealCostForm, prepareDealCostPatch } from '../deal-form-page/deal-form.service-factory'


export interface CreateOfferFormOptions {
  dv: DeepReadonly<DealViewRawDeal & DealViewRawOffers & DealViewRawCosts & DealViewRawInvoices>
  defaultBuyers: number[]
  extraCosts: DeepReadonly<Cost[]>
}

interface BuyerGroup {
    group: boolean;
    name: string;
    account: string;
    group_id: string;
    created: number;
    buyers: string[];
  }

@Component({
  selector: 'tc-create-offer-form',
  templateUrl: './create-offer-form.component.html',
  styleUrl: './create-offer-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateOfferFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly Invoices: InvoicesService,
    private readonly VendorCredits: VendorCreditsService,
    private readonly SupplierOffers: SupplierOffersService,
    private readonly BuyersGroupApi: BuyersGroupApiService,
    private readonly toaster: ToasterService,
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<CreateOfferFormComponent, SupplierOffer>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: CreateOfferFormOptions,
  ) { super() }

  // const
  protected readonly TableKey = TableKey
  protected readonly expirationTimes = [
    dayjs.duration(5, 'minutes'),
    dayjs.duration(10, 'minutes'),
    dayjs.duration(15, 'minutes'),
    dayjs.duration(30, 'minutes'),
    dayjs.duration(1, 'hour'),
    dayjs.duration(2, 'hours'),
    dayjs.duration(3, 'hours'),
  ].map(value => ({ name: value.humanize(), value: value.asSeconds() }))

  // input
  protected readonly dealId = this.dialogData.dv.deal.deal_id
  private readonly dv = this.dialogData.dv
  protected readonly fxRates = this.dv.deal.attributes.fx_rates
  protected readonly units = this.dv.offers[0].weight.unit

  // forms
  protected readonly soForm = new FormGroup({
    buyersCountry: new FormControl<string[]>([]),
    buyersLocation: new FormControl<string[]>([]),
    selectedBuyers: new FormControl<number[]>(this.dialogData.defaultBuyers),
    expirationTime: new FormControl<number>(this.expirationTimes[4].value, Validators.required), // 1hr
    selectedBuyersCopySI: new FormControl<number[]>([]),
    copy_all_si: new FormControl<boolean>(false),
    skip_cost_matching: new FormControl<boolean>(false),
    skip_delivery_dates_calculation: new FormControl<boolean>(false),
    biddable: new FormControl<boolean>(false),
  })
  protected readonly costsForm = new FormArray([...this.dialogData.extraCosts, ...this.dv.costs]
    .filter(cost => cost.type === 'tertiary' && !isCostActualized(this.dv, cost))
    .map(cost => buildDealCostForm(prepareDealCostPatch(cost)))) as CostsFormGroup

  @ViewChild(CostsListComponent, { static: true }) costsList: CostsListComponent

  // ref data
  private readonly accountsByIds$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  private readonly accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty())
  protected readonly countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty(), map(list => list.sort(compareBy('name'))))
  private readonly locationsByIds$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  protected readonly locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty(), map(list => list.sort(compareBy('name'))))
  private readonly buyersGroups$ = new ReplaySubject<Dictionary<BuyerGroup>>(1)

  // filtered ref data
  protected readonly buyers$ = this.buyers()
  protected eligibleBuyers;
  protected readonly selectedBuyers$ = combineLatest([this.buyers$, replayForm(this.soForm.controls.selectedBuyers)])
    .pipe(map(([buyers, selectedBuyers]) =>
      {
        this.eligibleBuyers = buyers;
        return selectedBuyers?.length ? buyers.filter(account => selectedBuyers.includes(account.account)) : buyers
      }))

  // data sources
  protected readonly invoicesDataSource = new DealInvoicesDataSource(of(this.dv), this.accountsByIds$, this.VendorCredits, this.Invoices)

  // state
  protected readonly inProgress$ = new BehaviorSubject<'loading' | 'save' | undefined>('loading')

  ngOnInit(): void {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadUsers({}))

    const loadingBuyerGroups = this.BuyersGroupApi.list().then((groups) => {
      this.buyersGroups$.next(keyBy(groups.map((group) => ({
        ...group,
        group: true,
        name: `[Group]: ${group.name}`,
        account: group.group_id,
      })), 'account'))
    })

    combineLatest([this.accounts$, this.countries$, this.locations$, from(loadingBuyerGroups)]).pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.inProgress$.next(undefined)
    })
  }

  protected create() {
    if (this.inProgress$.value) return
    this.soForm.markAllAsTouched()
    if (!this.soForm.valid) return

    const soForm = this.soForm.getRawValue()
    this.buyersGroups$.pipe(take(1)).subscribe(async buyersGroups => {
      const groupsBuyers = soForm.selectedBuyers.filter(buyer=> buyersGroups[buyer])
      .flatMap(buyer=> buyersGroups[buyer].buyers.map(b => parseFloat(b)))
      const eligibleGroupBuyers = this.eligibleBuyers.filter(account => groupsBuyers.includes(account.account))
      const selectedBuyers = uniq([
        ...soForm.selectedBuyers.filter(buyer=> !buyersGroups[buyer]),
        ...eligibleGroupBuyers
      ])
      if(groupsBuyers.length && !selectedBuyers.length) {
        const errorMessage = `There are not eligible buyers to create supplier offers. Please update the product, country, location, and item type to get the eligible buyers`
        this.toaster.error(errorMessage)
        throw new Error(errorMessage)
      }
      const selectedCostIds = this.costsList.selectedCosts().map(c => c.originalCost.cost_id);
      try {
        const supplierOffer = await this.SupplierOffers.generate({
          deal_id: this.dealId,
          expire: dayjs().unix() + soForm.expirationTime,
          costs: selectedCostIds,
          buyers: selectedBuyers,
          buyers_copy_si: soForm.selectedBuyersCopySI,
          countries: soForm.buyersCountry,
          locations: soForm.buyersLocation,
          copy_all_si: soForm.copy_all_si,
          skip_cost_matching: soForm.skip_cost_matching,
          skip_delivery_dates_calculation: soForm.skip_delivery_dates_calculation,
          biddable: soForm.biddable,
          mo_costs: this.dialogData.extraCosts,
        });

        this.toaster.success('Supplier offer created successfully');
        this.dialogRef.close(supplierOffer);
      } catch (err) {
        if (typeof err.data?.error === 'string') {
          this.toaster.error(err.data.error, err);
        } else {
          this.toaster.error('Unable to create supplier offer', err);
        }
        throw err;
      }
    })
  }

  protected cancel() {
    this.dialogRef.close()
  }

  protected onCopyAllSpecialInstructiosnChange() {
    const ctrl = this.soForm.controls.selectedBuyersCopySI
    disableIf(ctrl, this.soForm.controls.copy_all_si.value)
    ctrl.setValue([])
  }

  private buyers() {
    return combineLatest([
      this.accounts$,
      this.locationsByIds$,
      this.buyersGroups$,
      replayForm(this.soForm),
    ]).pipe(map(([accounts, locations, buyersGroups, soForm]) => {
      const { buyersCountry, buyersLocation } = soForm
      let buyers = accounts.filter(a => a.type === BUYER)
      const product = this.dv.offers[0].product
      const itemTypeId = this.dv.offers[0].attributes.item_type_id

      const matchedBuyers = buyers.filter((buyer) => {
        let productMatch = buyer.products?.includes(product)
        if (buyer.products_spec && itemTypeId) {
          productMatch &&= !!buyer.products_spec.find(x => x.item_type_id
            ? x.item_type_id === itemTypeId && x.product_id === product
            : x.product_id === product)
        }
        const addressMatch = buyer.addresses?.some(addr => addr.primary && buyersCountry.includes(addr.cc))
        const locationMatch = buyersCountry.includes(locations[buyer.attributes.default_location]?.country)
        const locationsMatch = buyer.attributes.locations?.some(x => buyersLocation.includes(x))
        return productMatch && (!buyersCountry.length || addressMatch || locationMatch) && (!buyersLocation.length || locationsMatch)
      })

      return uniqBy(
        Object.values({
          ...buyersGroups,
          ...keyBy(sortForHaveTestAccountsAfterReal(matchedBuyers).map(a => ({ ...a, group: false })), 'account'),
        }).map((a: AccountObject & { group: boolean} , ranking) => ({ ...a, ranking })),
        'account')
        .filter(account => !account.archived && account.status === ACCOUNT_ACTIVE || account.group)
        .sort(compareBy('ranking'))
    }))
  }
}
