import { TableKey } from '@tradecafe/types/core'
import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'


const FILE_COLUMNS: ColumnDef[] = [
  { field: 'select',               displayName: '', internal: true, index: -1 },
  { field: 'dealMenu',             displayName: '', internal: true, index: 1001 },
  { field: 'logisticsMenu',        displayName: '', internal: true, index: 1001 },

  { field: 'company',              displayName: 'Company',                  exportField: 'view.company.name',               exportPipe: '' },
  { field: 'created',              displayName: 'Created',                  exportField: 'created',                         exportPipe: 'epoch:DD-MMM-YYYY hh:mma' },
  { field: 'deal_id',              displayName: 'Deal #',                   exportField: 'deal_id',                         exportPipe: '' },
  { field: 'deringer_status_at',   displayName: 'Status At',                exportField: 'viewEx.statusAt',                 exportPipe: 'epoch' },
  { field: 'deringer_status_by',   displayName: 'Status By',                exportField: 'viewEx.statusBy',                 exportPipe: '' },
  { field: 'deringer_status',      displayName: 'Status',                   exportField: 'deringer.status',                 exportPipe: '' },
  { field: 'doc_type',             displayName: 'Doc Type',                 exportField: 'attributes.document_type',        exportPipe: '' },
  { field: 'dropoff_date',         displayName: 'Dropoff Date',             exportField: 'view.dropoff_date',               exportPipe: 'epoch' },
  { field: 'eta',                  displayName: 'ETA',                      exportField: 'view.eta',                        exportPipe: 'epoch' },
  { field: 'etd',                  displayName: 'ETD',                      exportField: 'view.etd',                        exportPipe: 'epoch' },
  { field: 'file_name',            displayName: 'Name',                     exportField: 'viewEx.fileName',                 exportPipe: '' },
  { field: 'format_name',          displayName: 'Format',                   exportField: 'viewEx.format.name',              exportPipe: '' },
  { field: 'note_warning',         displayName: 'Bond Notes',               exportField: 'view.notes',                      exportPipe: 'yesNo' },
  { field: 'onboard_date',         displayName: 'Onboard Date',             exportField: 'view.onboard_date',               exportPipe: 'epoch' },
  { field: 'pickup_date',          displayName: 'Load Date',                exportField: 'view.pickup_date',                exportPipe: 'epoch' },
  { field: 'readers',              displayName: 'Visibility',               exportField: 'viewEx.readers',                  exportPipe: '' },
  { field: 'size',                 displayName: 'Size',                     exportField: 'viewEx.size',                     exportPipe: '' },
  { field: 'user',                 displayName: 'Created By',               exportField: 'view.user.fullname',              exportPipe: '' },
]

export const FILE_COLUMNS_BY_ID = keyBy(FILE_COLUMNS, 'field')
export const FILE_COLUMNS_INTERNAL = FILE_COLUMNS.filter(col => col.internal).map(col => col.field)

const FILE_COLUMN_NAMES = mapValues(keyBy(FILE_COLUMNS, 'field'), 'displayName')
const FILE_COLUMN_OVERRIDES: Partial<Record<TableKey, Dictionary<string>>> = {
  // [TableKey.***]: {
  //   'user': 'User',
  // },
}

export function columnDefsById(tableKey: TableKey) {
  const displayNames = FILE_COLUMN_OVERRIDES[tableKey] || {}
  const columnDefs = FILE_COLUMNS.map(c =>
    displayNames[c.field]
      ? { ...c, displayName: displayNames[c.field] } as ColumnDef
      : c)
  return keyBy(columnDefs, c => c.field)
}

export function columnNames(tableKey?: TableKey) {
  return {
    ...FILE_COLUMN_NAMES,
    ...FILE_COLUMN_OVERRIDES[tableKey],
  }
}

export const AVAILABLE_COLUMNS: Partial<Record<TableKey, string[]>> = {
  [TableKey.LogisticsDocuments]: [
    'select',
    'created',
    'user',
    'company',
    'deal_id',
    'doc_type', // hidden
    'readers',
    'file_name',
    'size',
    'format_name',
    'dropoff_date', // hidden
    'eta', // hidden
    'etd', // hidden
    'pickup_date', // hidden
    'onboard_date', // hidden
    'logisticsMenu',
  ],
  [TableKey.DealDocuments]: [
    'select',
    'created',
    'user',
    'doc_type', // hidden
    'file_name',
    'company',
    'size',
    'format_name',
    'deringer_status',
    'note_warning',
    'deringer_status_at', // optionally hidden
    'deringer_status_by', // optionally hidden
    'dealMenu',
  ],
}

export const DEFAULT_COLUMNS: Partial<Record<TableKey, string[]>> = {
  [TableKey.LogisticsDocuments]: [
    'select',
    'created',
    'user',
    'company',
    'deal_id',
    'doc_type',
    'readers',
    'file_name',
    'size',
    'format_name',
    'dropoff_date',
    'eta',
    'etd',
    'pickup_date',
    'onboard_date',
    'logisticsMenu',
  ],
  [TableKey.DealDocuments]: [
    'select',
    'created',
    'user',
    'doc_type',
    'file_name',
    'company',
    'size',
    'format_name',
    'deringer_status',
    'note_warning',
    'dealMenu',
  ],
}
