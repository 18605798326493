import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { AccountEffects } from './accounts'
import { AesEffects } from './aes'
import { CarrierEffects } from './carriers'
import { ConsigneesEffects } from './consignees'
import { BookingEffects } from './booking'
import { CountryEffects } from './countries'
import { CurrencyEffects } from './currencies'
import { DealViewEffects } from './deal-view-effects.effects'
import { DepartmentEffects } from './departments'
import { DocumentOverrideEffects } from './document-overrides'
import { DocumentSetEffects } from './document-sets'
import { DocumentTemplateEffects } from './document-templates'
import { InvoiceViewEffects } from './invoice-view/invoice-view.effects'
import { ItemTypeEffects } from './item-types'
import { LocationEffects } from './locations'
import { MeasureEffects } from './measures'
import { PackageTypeEffects } from './package-types'
import { PaymentReferenceEffects } from './payment-references'
import { PricingTermEffects } from './pricing-terms'
import { ProductCategoryEffects } from './product-categories'
import { ProductTypeEffects } from './product-types'
import { ProductEffects } from './products'
import { UserEffects } from './users'
import { WeightTypeEffects } from './weight-types'
import { WrappingTypeEffects } from './wrapping-types'
import { MacropointEffects } from './macropoint'

@NgModule({
  imports: [
    EffectsModule.forFeature([
      AccountEffects,
      AesEffects,
      CarrierEffects,
      ConsigneesEffects,
      BookingEffects,
      CountryEffects,
      CurrencyEffects,
      DealViewEffects,
      DepartmentEffects,
      DocumentOverrideEffects,
      DocumentSetEffects,
      DocumentTemplateEffects,
      InvoiceViewEffects,
      ItemTypeEffects,
      LocationEffects,
      MacropointEffects,
      MeasureEffects,
      PackageTypeEffects,
      PaymentReferenceEffects,
      PricingTermEffects,
      ProductCategoryEffects,
      ProductEffects,
      ProductTypeEffects,
      UserEffects,
      WeightTypeEffects,
      WrappingTypeEffects,
    ]),
  ],
})
export class StoreModule { }
