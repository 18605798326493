import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AccountObject, DealPartyE, DealPaymentTerms, DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawFiles, DealViewRawOffers, PaymentReference, PaymentTermsDate, PaymentTermsDates } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { identity, uniq } from 'lodash-es'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { DealApiService } from 'src/api/deal'
import { selectAccountEntities, selectBrokerageCustomerOptions } from 'src/app/store/accounts'
import { massDealForm, massDealFormFailure, massDealFormSuccess } from 'src/app/store/deal-view.actions'
import { selectPaymentReferenceEntities, selectPaymentReferencesOptions } from 'src/app/store/payment-references'
import { selectProductEntities } from 'src/app/store/products'
import { selectFirstTraderOption, selectTradersOptions, selectUserEntities } from 'src/app/store/users'
import { getPartyUsers } from 'src/services/data/accounts.service'
import { CreditPoolService } from 'src/services/data/credit-pool.service'
import { DealFormCalculatorService } from 'src/services/data/deal-form-calculator.service'
import { DealViewCalculatorService } from 'src/services/data/deal-view-calculator.service'
import { canChangeBrokerageParty } from 'src/services/data/deal-view-permissions.service'
import { buildDealProducts } from 'src/services/data/deal-view.service'
import { getDesignatedContacts } from 'src/services/data/users.service'
import { selectOptions, waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealDocumentsService } from '../../../deals/deal-documents/deal-documents.service'
import { DealFormDto } from '../deal-form.schema'

export interface ChangeBrokeragePartyFormOptions {
  deals: DeepReadonly<Array<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawFiles>>
}

export type ChangeBrokeragePartyFormResult = DeepPartial<DealFormDto>[]

@Component({
  selector: 'tc-change-brokerage-party-form',
  templateUrl: './change-brokerage-party-form.component.html',
  styleUrls: ['./change-brokerage-party-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeBrokeragePartyFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly toaster: ToasterService,
    private readonly AuthApi: AuthApiService,
    private readonly DealDocuments: DealDocumentsService,
    private readonly DealViewCalculator: DealViewCalculatorService,
    private readonly actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: ChangeBrokeragePartyFormOptions,
    private readonly dialogRef: MatDialogRef<ChangeBrokeragePartyFormComponent, ChangeBrokeragePartyFormResult>,
    private readonly store: Store,
    private readonly DealFormCalculator: DealFormCalculatorService,
    private readonly CreditPool: CreditPoolService,
    private readonly DealApi: DealApiService,
  ) { super() }

  // static / config
  protected readonly deals = this.dialogData.deals

  // internal state
  protected readonly inProgress$ = new BehaviorSubject(false)

  form = new FormGroup({
    brokerageCustomer: new FormControl<number>(getBrokerageCustomer(this.dialogData.deals), Validators.required),
    brokerageCurrency: new FormControl('', Validators.required),
    brokerageTraderId: new FormControl('', Validators.required),
    brokerageContactUserIds: new FormControl([], Validators.required),
    brokeragePaymentTerms: new FormControl(undefined, Validators.required),

    overridePrice: new FormControl(false),
    brokerageEstAmount: new FormControl<number>(getBrokerageAmount(this.dialogData.deals), [Validators.required, Validators.min(0)]),
  })

  protected PaymentTermsDates = PaymentTermsDates
  protected paymentReferences$: Observable<DeepReadonly<PaymentReference[]>>
  protected readonly termsForm = new FormGroup({
    days: new FormControl<number>(undefined, [Validators.required, Validators.min(0)]),
    from_date: new FormControl<PaymentTermsDate>(undefined, [Validators.required]),
    reference_id: new FormControl<string>(undefined),
  })

  // ref data
  protected accounts$ = selectOptions(this.store, selectBrokerageCustomerOptions, this.form.controls.brokerageCustomer)
  private readonly accountsByIds$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  protected readonly currency$ = new ReplaySubject<string>(1)
  private readonly products$ = this.store.pipe(select(selectProductEntities), waitNotEmpty())

  ngOnInit() {
    if (this.form.controls.brokerageCustomer.value) {
      this.accountsByIds$.pipe(take(1)).subscribe(accounts => {
        const brokerageCustomer = accounts[this.form.controls.brokerageCustomer.value]
        this.customerChanged(brokerageCustomer)
      })
    }

    this.paymentReferences$ = selectOptions(this.store, selectPaymentReferencesOptions, this.termsForm.controls.reference_id)

    // check all deals - can user change party?
    const denied = this.deals.filter(dv => !canChangeBrokerageParty(dv.deal, this.AuthApi.currentUser))
    if (denied.length) {
      this.toaster.warning(`Deal${denied.length === 1 ? ` ${denied[0].deal.deal_id} is` : 's are'} submitted. Can't change brokerage customer.`)
      this.form.controls.brokerageCustomer.disable()
    }

    replayForm<DealPaymentTerms>(this.form.controls.brokeragePaymentTerms).pipe(untilComponentDestroyed(this)).subscribe((brokeragePaymentTerms) => {
      const termsForm = this.termsForm.getRawValue()
      if (termsForm.days !== brokeragePaymentTerms?.days) {
        this.termsForm.controls.days.setValue(brokeragePaymentTerms?.days)
      }
      if (termsForm.from_date !== brokeragePaymentTerms?.from_date) {
        this.termsForm.controls.from_date.setValue(brokeragePaymentTerms?.from_date)
      }
      if (termsForm.reference_id !== brokeragePaymentTerms?.reference_id) {
        this.termsForm.controls.reference_id.setValue(brokeragePaymentTerms?.reference_id)
      }
    })

  }

  async save() {
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()
    if (!this.form.valid) return

    this.inProgress$.next(true)

    const productsByIds = await this.products$.pipe(take(1)).toPromise()

    try {
      await Promise.all(this.deals.map(dv =>
        this.DealDocuments.onPartyChanged({
          ...dv.deal,
          files: dv.files,
          products: buildDealProducts(dv, productsByIds),
        }, DealPartyE.brokerage_customer).toPromise()))
    } catch (err) {
      this.inProgress$.next(false)
      console.error('Unable to change brokerage confirmation documents', err)
      this.toaster.error('Unable to change brokerage confirmation documents', err)
    }

    const { overridePrice, brokerageEstAmount, brokerageCurrency, brokerageCustomer, brokeragePaymentTerms, brokerageTraderId, brokerageContactUserIds } = this.form.getRawValue()
    const patch = overridePrice
      ? { brokerageCustomer, brokeragePaymentTerms, brokerageTraderId, brokerageContactUserIds, brokerageEstAmount, brokerageCurrency }
      : { brokerageCustomer, brokeragePaymentTerms, brokerageTraderId, brokerageContactUserIds }

    this.store.dispatch(massDealForm({ changes: this.deals.map(dv => ({ dv, patch: { details: { ...patch, deal_id: dv.deal.deal_id } } })) }))
    this.actions$.pipe(ofType(massDealFormSuccess, massDealFormFailure), take(1)).subscribe((action) => {
      if (action.type === massDealFormSuccess.type) this.dialogRef.close()
      this.inProgress$.next(false)
    })
  }

  protected cancel() {
    this.dialogRef.close()
  }

  protected async customerChanged(customer: DeepReadonly<AccountObject>) {
    const creditPool = await this.CreditPool.getFor(customer.account)
    const paymentTerms = await this.CreditPool.readPaymentTerms(creditPool)
    this.form.controls.brokeragePaymentTerms.setValue(paymentTerms)
    this.form.controls.brokerageCurrency.setValue(creditPool?.currency.code)
    this.termsForm.controls.from_date.setValue(paymentTerms.from_date)
    this.termsForm.controls.days.setValue(paymentTerms.days)
    this.termsForm.controls.reference_id.setValue(paymentTerms.reference_id)
    combineLatest([
      this.store.pipe(select(selectFirstTraderOption), filter(identity)),
      this.store.pipe(select(selectTradersOptions()), waitNotEmpty()),
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
    ]).pipe(take(1)).subscribe(([firstTraderId, traderOptions, users]) => {
      const primaryTrader = traderOptions.find(to => to.id === customer.manager)
        ? customer.manager
        : customer.managers?.find(managerId => traderOptions.find(to => to.id === managerId))
      const partyUsers = getPartyUsers(users, customer.account)
      const partyContacts = getDesignatedContacts(partyUsers).map(u => u.user_id)
      this.form.controls.brokerageTraderId.setValue(primaryTrader || firstTraderId)
      this.form.controls.brokerageContactUserIds.setValue(partyContacts)
    })
  }

  protected termsChanged() {
    const termsForm = this.termsForm.getRawValue()
    const paymentTerms = this.form.controls.brokeragePaymentTerms.getRawValue()
    this.store.pipe(select(selectPaymentReferenceEntities), waitNotEmpty(), take(1)).subscribe(prefs => {
      this.form.controls.brokeragePaymentTerms.setValue({
        ...paymentTerms,
        days: termsForm.days,
        from_date: termsForm.from_date,
        reference_id: termsForm.reference_id,
        reference_name: prefs[termsForm.reference_id]?.reference,
      })
      this.form.controls.brokeragePaymentTerms.markAsDirty()
      this.form.controls.brokeragePaymentTerms.markAsTouched()
    })
  }
}

// if all deals have the same party - preselect "account" form field
function getBrokerageCustomer(deals: DeepReadonly<DealViewRawDeal[]>) {
  const brokerageCustomers = uniq(deals.map(d => d.deal.brokerage.customer?.toString()))
  return brokerageCustomers.length === 1 ? parseFloat(brokerageCustomers[0] as any) : undefined
}

// if all deals have the same fixed price - preselect "price" form field
function getBrokerageAmount(deals: DeepReadonly<DealViewRawDeal[]>) {
  const amounts: number[] = uniq(deals.map(d => d.deal.brokerage.amount))
  return amounts.length === 1 ? amounts[0] : undefined
}
