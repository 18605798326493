import { mapValues, orderBy } from 'lodash-es'
import { routes as appRoutes } from '../routes/app'
import { routes as auctionRoutes } from '../routes/auction'
import { routes as financialRoutes } from '../routes/financial'
import { routes as logisticsRoutes } from '../routes/logistics'
import { routes as managementRoutes } from '../routes/management'
import { routes as notificationRoutes } from '../routes/notification'
import { routes as reportsRoutes } from '../routes/reports'
import { routes as settingRoutes } from '../routes/setting'
import { routes as tradingRoutes } from '../routes/trading'


export const routes = [
  ...appRoutes,
  ...financialRoutes,
  ...logisticsRoutes,
  ...managementRoutes,
  ...reportsRoutes,
  ...settingRoutes,
  ...tradingRoutes,
  ...notificationRoutes,
  ...auctionRoutes,
]

const urlsByName = orderBy(routes, route => route.name.split('.').length)
.reduce((byName, route: any) => ({
  ...byName,
  [route.name]: {url: route.url, roles: route.data?.roles },
}), {} as Dictionary<{ url: string, roles: string[]}>)

export const routesByName = mapValues(urlsByName, ({url, roles}, name) => {
  const names = name.split('.')
  names.pop()
  const parentName = names.join('.')
  const parent = urlsByName[parentName]
  return {
    url: `${parent?.url || ''}${url || ''}`,
    roles: parent?.roles || roles,
  }
})
