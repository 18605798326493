import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { WeightType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as WeightTypeActions from './weight-type.actions'

export const weightTypesFeatureKey = 'weightTypes'
export interface WeightTypesState extends EntityState<DeepReadonly<WeightType>> {
}

export const selectId = (weightType: WeightType) => weightType.type_id
export const adapter = createEntityAdapter<DeepReadonly<WeightType>>({ selectId })

const initialState: WeightTypesState = adapter.getInitialState({
})

export const weightTypesReducer = createReducer(
  initialState,
  on(WeightTypeActions.loadWeightTypesSuccess,
    (state, action) =>
      adapter.upsertMany(action.weightTypes, state)),
)

