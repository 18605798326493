import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Cost, DealBase, DealViewRaw, Invoice, INVOICE_PAID } from '@tradecafe/types/core';
import { DeepReadonly, findBrokerageInvoice, getAvgReceiptDate } from '@tradecafe/types/utils';
import { compact, flatten } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { selectAccountEntities } from 'src/app/store/accounts';
import { replayForm } from 'src/shared/utils/replay-form';
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty';
import { DealFormGroup, DealFormValue } from '../../deal-form/deal-form-page/deal-form.schema';

@Component({
  selector: 'tc-brokerage-dates-form',
  templateUrl: './brokerage-dates-form.component.html',
  styleUrls: ['./brokerage-dates-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrokerageDatesFormComponent implements OnInit {
  constructor(private store: Store) {}

  @Input() dealForm: DealFormGroup
  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>

  protected avgToDueDate$: Observable<number>;
  protected actualCollectionDate$: Observable<number>;

  ngOnInit() {
    this.avgToDueDate$ = combineLatest([
      this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
      replayForm(this.dealForm.controls.details.controls.brokerageCustomer),
    ]).pipe(
      map(([accounts, brokerageCustomerId]) => accounts[brokerageCustomerId]?.attributes.credit_info?.avg_days_to_due_date || 0),
      distinctUntilChanged())

    this.actualCollectionDate$ = combineLatest([
      replayForm<DealFormValue>(this.dealForm),
      this.dealViewRaw$,
    ]).pipe(
      map(([df, dv]) => actualCollectionDate(df.details.deal, findBrokerageInvoice(dv.invoices), df.costs.map(cf => cf.cost))))
  }
}

function actualCollectionDate(
  deal: DeepReadonly<DealBase>,
  invoice: DeepReadonly<Invoice>,
  costs: DeepReadonly<Partial<Cost>[]>,
): number {
  if (invoice?.status !== INVOICE_PAID) return undefined
  const creditNotes = compact(flatten(costs.map((cost) => cost.attributes?.credit_notes_ex)));
  return getAvgReceiptDate(deal, invoice, creditNotes)
}
