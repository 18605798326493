<div class="tc-deal-dates-form" gdColumns="minmax(0, 2.5fr) minmax(0, 2.5fr) minmax(0, 5fr) minmax(0, 2fr) minmax(0, 4fr) minmax(0, 4fr) minmax(0, 4fr)" gdGap="15px">
  <mat-form-field gdColumn="span 2">
    <mat-label>Ant. Finance term</mat-label>
    <input readonly matInput [value]="estFinanceTerm$ | async | number:'1.2-2'">
  </mat-form-field>
  <tc-epoch-field format="l" placeholder="Ant. liability date (Supplier due date)"
      [group]="dealForm.controls.details" ctrlName="antLiabilityDate"
      ></tc-epoch-field>
  <span></span>
  <tc-epoch-field format="l" placeholder="Ant. term date"
      [group]="dealForm.controls.details" ctrlName="buyerTermDate"
      ></tc-epoch-field>
  <mat-form-field>
    <mat-label>Ant. due date</mat-label>
    <input readonly matInput [value]="dealForm.controls.details.antDueDate$ | async | epoch:'l':'utc'">
  </mat-form-field>
  <tc-epoch-field format="l" placeholder="Ant. collection date"
      [group]="dealForm.controls.details" ctrlName="collectionDate"
      ></tc-epoch-field>

  <mat-form-field>
    <mat-label>Act. Finance term</mat-label>
    <input readonly matInput [value]="financeTerm$ | async | number:'1.2-2'">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Average Days to Due Date</mat-label>
    <input readonly matInput [value]="supplierAvgToDueDate$ | async | number:'1.2-2'">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Act. Liability Date</mat-label>
    <input readonly matInput [value]="actualLiabilityDate$ | async | epoch:'l'">
  </mat-form-field>
  <span></span>
  <mat-form-field>
    <mat-label>Act. Term Date</mat-label>
    <input readonly matInput xx-value="actualTermDate$ | epoch:'l'">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Average Days to Due Date</mat-label>
    <input readonly matInput [value]="buyerAvgToDueDate$ | async | number:'1.2-2'">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Act. Collection Date</mat-label>
    <input readonly matInput [value]="actualCollectionDate$ | async | epoch:'l'">
  </mat-form-field>
</div>

<div class="tc-deal-terms-headers" gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 3fr)" gdGap="15px">
  <span>Payment terms</span>
  <span gdColumn="span 2">FX Rates</span>
  <div gdColumns="minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)" gdColumns.gt-sm="minmax(0, 5fr) minmax(0, 3fr) minmax(0, 3fr)" gdGap="15px">
    <span>Payment terms</span>
    <span>Raw cost</span>
    <span>Target cost (6% margin)</span>
  </div>
</div>
<div class="tc-deal-terms" gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 3fr)" gdGap="15px">
  <span>{{dealForm.controls.details.supplierPaymentTerms$ | async}}</span>
  <div [matTooltip]="dealForm.controls.details.usingAskRates$ | async"
      matTooltipPlacement="top"
      [matTooltipShowDelay]="3000"
      matTooltipClass="nya-bs-option-tooltip">
    {{ dealForm.controls.details.askRate$ | async }}
  </div>
  <div [matTooltip]="dealForm.controls.details.usingBidRates$ | async"
      matTooltipPlacement="top"
      [matTooltipShowDelay]="3000"
      matTooltipClass="nya-bs-option-tooltip">
    {{ dealForm.controls.details.bidRate$ | async }}
  </div>
  <div gdColumns="minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)" gdColumns.gt-sm="minmax(0, 5fr) minmax(0, 3fr) minmax(0, 3fr)" gdGap="15px">
    <span>{{dealForm.controls.details.buyerPaymentTerms$ | async}}</span>
    <span>
      <span *ngIf="!(estimatedTotals$ | async)?.raw_cost">—</span>
      <span *ngIf="(estimatedTotals$ | async)?.raw_cost">
        <span>{{(estimatedTotals$ | async)?.sell_currency}}</span>&nbsp;
        <span>{{(estimatedTotals$ | async)?.raw_cost
          | currency:(estimatedTotals$ | async)?.sell_currency:'symbol-narrow'
          | measure:(estimatedTotals$ | async)?.weight.measure_id}}</span>
      </span>
    </span>
    <span>
      <span *ngIf="!(estimatedTotals$ | async)?.target_cost">—</span>
      <span *ngIf="(estimatedTotals$ | async)?.target_cost">
        <span>{{(estimatedTotals$ | async)?.sell_currency}}</span>&nbsp;
        <span>{{(estimatedTotals$ | async)?.target_cost
          | currency:(estimatedTotals$ | async)?.sell_currency:'symbol-narrow'
          | measure:(estimatedTotals$ | async)?.weight.measure_id}}</span>
      </span>
    </span>
  </div>
</div>

<div gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
  <div class="tc-estimated-revenue" gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
    <div>
      <div>Partial Revenue (CAD)</div>
      <em>{{dealForm.controls.details.partialRevenueCad$ | async}}</em>
    </div>
    <div>
      <div>Partial Margin (CAD)</div>
      <em>{{dealForm.controls.details.partialMarginCad$ | async}}</em>
    </div>
    <div>
      <div>Partial Margin (%)</div>
      <em>{{dealForm.controls.details.partialMarginP$ | async}}</em>
    </div>
  </div>
</div>
