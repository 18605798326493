import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { ShippingSummaryComponent } from './shipping-summary.component'

@NgModule({
  declarations: [ShippingSummaryComponent],
  exports: [ShippingSummaryComponent],
  imports: [
    CommonModule,
    PipesModule,
  ],
})
export class ShippingSummaryModule {}
