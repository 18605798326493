import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { ProductWrapping } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as WrappingTypeActions from './wrapping-type.actions'

export const wrappingTypesFeatureKey = 'wrappingTypes'
export interface WrappingTypesState extends EntityState<DeepReadonly<ProductWrapping>> {
}

export const selectId = (wrappingType: ProductWrapping) => wrappingType.wrapping_id
export const adapter = createEntityAdapter<DeepReadonly<ProductWrapping>>({ selectId })

const initialState: WrappingTypesState = adapter.getInitialState({
})

export const wrappingTypesReducer = createReducer(
  initialState,
  on(WrappingTypeActions.loadWrappingTypesSuccess,
    (state, action) =>
      adapter.upsertMany(action.wrappingTypes, state)),
)

