<h2 mat-dialog-title>
  {{ dialogData.dealId }} AES export report cancellation
</h2>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field style="width: 100%;">
      <mat-label>Cancellation reason</mat-label>
      <textarea matInput formControlName="reason" rows="5"></textarea>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
  <button mat-raised-button color="primary" (click)="submit()">Confirm</button>
</div>
