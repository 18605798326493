import { formatNumber } from '@angular/common'
import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AccountObject, AllInvoiceStatuses, BuyerInvoiceStatuses, CompanyTypes, DealStatus, SupplierInvoiceStatuses, User, accountBankingPaymentTypes } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { concat, get, sortBy, uniq, without } from 'lodash-es'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { InvoiceApiService, InvoiceRow, InvoiceViewDto, InvoiceViewUser } from 'src/api/invoice'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { prepareInvoicesPayload } from 'src/app/store/invoice-view/invoice-view-filters.utils'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { INVOICE_COLUMNS_INTERNAL } from 'src/components/invoices/invoices-list/invoices-list.columns'
import { ElasticSearchFilters, ElasticSearchPayload, ElasticSearchService } from '../elastic-search'
import { waitNotEmpty } from './utils'

@Injectable()
export class InvoiceElasticSearchService extends ElasticSearchService {

  constructor(
    private InvoiceApi: InvoiceApiService,
    @Inject(LOCALE_ID) private locale: string,
    private store: Store,
  ) {
    super()
  }

  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())

  fetchInvoiceFilters({ query = {}, columns }: { query?: any, columns?: string[] }): Observable<ElasticSearchFilters> {
    columns = without(columns, ...INVOICE_COLUMNS_INTERNAL)
    let status
    if (query.type.includes('payable')) status = SupplierInvoiceStatuses
    else if (query.type.includes('receivable') || query.type.includes('brokerage')) status = BuyerInvoiceStatuses
    else status = Object.values(AllInvoiceStatuses)
    return this.InvoiceApi.elasticFilters({ query, columns })
      .pipe(map(res => this.getViewFilters({
        ...res,
        variance: res.variance.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        est_amount: res.est_amount.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        net_payable: res.net_payable.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        rmng_amount: res.rmng_amount.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        partial_margin: res.partial_margin.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        partial_margin_p: res.partial_margin_p.map((id: number) => ({ id, name: formatNumber(id * 100, this.locale, '1.2-2') + '%'})),
        actl_amount: res.actl_amount.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        actl_amount_cad: res.actl_amount_cad.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        sum_pmts_applied: res.sum_pmts_applied.map((id: number) => ({ id, name: formatNumber(id, this.locale, '1.2')})),
        payment_type: res.payment_type?.map(id => {
          if (id === null) return null
          const v = accountBankingPaymentTypes.find(x => x.id === id) || { id, label: id }
          return { id: v.id, name: v.label}
        }),
        company_type: res.company_type?.map(value => {
          if (value === null) return null
          const v = CompanyTypes.find(x => x.value === value) || { value, name: value }
          return { id: v.value, name: v.name }
        }),
        status: status.map(v => ({ id: v.id, name: v.value })),
        deal_status: res.deal_status
          ? Object.assign(sortBy(res.deal_status, name => DealStatus[name]?.num), { sorted: true })
          : res.deal_status,
        }, ['note_warning'])))
      }

  fetchInvoices(payload: ElasticSearchPayload) {
    this.store.dispatch(loadUsers({}))
    this.store.dispatch(loadAccounts({}))

    if (payload.columns?.length) {
      payload = {
        ...payload,
        columns: uniq(concat(without(payload.columns, ...INVOICE_COLUMNS_INTERNAL), ['buyer']))
      }
    }

    return this.InvoiceApi.elasticSearch(payload).pipe(
      withLatestFrom(this.users$, this.accounts$),
      map(([{ total, totals, hits }, usersById, accountsById]) =>
        ({ total, totals, data: this.buildInvoiceRows(hits, usersById, accountsById) })))
  }

  fetchInvoice(invoice_id: string, columns?: string[]) {
    return this.fetchInvoices({ query: { invoice_id }, columns, skip: 0, limit: 2 })
    .pipe(map(({ data: invoices }) => invoices.find(invoice => invoice.invoice_id === invoice_id)))
  }

  fetchAllInvoices(filters: any, total?: number) {
    const limit = 100
    filters = { ...filters, limit }
    return this.fetchAll(limit, total, (page: number) =>
      this.fetchInvoices(prepareInvoicesPayload(filters, page)).toPromise())
  }

  buildInvoiceRows(hits: InvoiceViewDto[], usersById: Dictionary<DeepReadonly<User>>, accountsById: Dictionary<DeepReadonly<AccountObject>>) {
    return hits.map(({ invoice, status, view }): InvoiceRow => {
      const actualizedUserId = invoice?.attributes?.actualized_user;
      const actualizedUser = actualizedUserId ? get(usersById, actualizedUserId) : null;
      return {
        ...invoice,
        actualized_by_name: actualizedUser?.fullname,
        view: {
          ...view,
          selling_trader: addFullname(view.selling_trader),
          buying_trader: addFullname(view.buying_trader),
          brokerage_trader: addFullname(view.brokerage_trader),
          coordinator: addFullname(view.coordinator),
          user: addFullname(view.user),
        },
        viewEx: {
          inventoryDeal: {
            buyer_id: view?.buyer?.account,
            supplier_id: view?.supplier?.account,
          },
          status,
          paymentType: accountBankingPaymentTypes.find(x => x.id === invoice.attributes?.payment_type)?.label
              || invoice.attributes?.payment_type || '',
          companyType: CompanyTypes.find(x => x.value === view?.company?.type)?.name || view?.company?.type || '',
          company: accountsById[invoice.account],
        },
      }
    })

    function addFullname(user?: InvoiceViewUser) {
      if (!user?.user_id) return user // NOTE: backend can respond with "coordinator: {}", which in "go" means undefined
      return {
        ...user,
        fullname: `${user.firstname} ${user.lastname}`.trim(),
      }
    }
  }
}
