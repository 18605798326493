import { createSelector } from '@ngrx/store'
import { WeightType } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, weightTypesFeatureKey } from './weight-type.reducer'

export const selectState = (state: State) => state[weightTypesFeatureKey]

export const { selectEntities: selectWeightTypeEntities, selectAll: selectAllWeightTypes } = adapter.getSelectors(selectState)

export const selectWeightType = createSelector(
  selectWeightTypeEntities,
  (weightTypes: Dictionary<WeightType>, weightType: string | number) =>
    weightTypes[weightType])

export const selectWeightTypesById = createSelector(
  selectWeightTypeEntities,
  (weightTypes: Dictionary<WeightType>, weightTypeId: string) =>
    weightTypes[weightTypeId])

export const selectWeightTypesByIds = createSelector(
  selectWeightTypeEntities,
  (weightTypes: Dictionary<WeightType>, weightTypeIds: string[]) =>
    weightTypeIds.map(weightTypeId => weightTypes[weightTypeId]))
