import { createSelector } from '@ngrx/store'
import { Currency } from '@tradecafe/types/core'
import { map } from 'lodash-es'
import { compare } from 'src/services/table-utils/compare'
import { State } from '../reducers'
import { adapter, currenciesFeatureKey, CurrenciesState } from './currency.reducer'


export const selectState = (state: State) => state[currenciesFeatureKey]

export const { selectEntities: selectCurrencyEntities, selectAll: selectAllCurrencies } = adapter.getSelectors(selectState)

export const selectCurrency = createSelector(
  selectState,
  ({byCode, entities}: CurrenciesState, currencyCodeOrId: string) =>
    byCode[currencyCodeOrId] || entities[currencyCodeOrId])

export const selectCurrenciesByIds = createSelector(
  selectCurrencyEntities,
  (currencies: Dictionary<Currency>, currencyIds: string[]) =>
    currencyIds.map(currencyId => currencies[currencyId]))

export const selectCurrencyCodes = createSelector(
  selectCurrencyEntities,
  (currencies: Dictionary<Currency>) =>
    map(currencies, currency => currency.code).sort(compare))
