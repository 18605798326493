import {createAction, props} from '@ngrx/store'
import { Booking } from '@tradecafe/types/core'
import { CreateBookingDto } from 'src/api/booking'

export const createBooking = createAction('[Booking/API] Create Booking', props<CreateBookingDto>())
export const createBookingSuccess = createAction('[Booking/API] Create Booking Success', props<{ booking: Booking }>())
export const createBookingFailure = createAction('[Booking/API] Create Booking Failure', props<{ error: Error }>())

export const approveBooking = createAction('[Booking/API] Approve Booking', props<{ bookingId: string }>())
export const approveBookingSuccess = createAction('[Booking/API] Approve Booking Success', props<{ booking: Booking }>())
export const approveBookingFailure = createAction('[Booking/API] Approve Booking Failure', props<{ error: Error }>())

export const rejectBooking = createAction('[Booking/API] Reject Booking', props<{ bookingId: string, dealId: string, reason?: string }>())
export const rejectBookingSuccess = createAction('[Booking/API] Reject Booking Success', props<{ booking: Booking }>())
export const rejectBookingFailure = createAction('[Booking/API] Reject Booking Failure', props<{ error: Error }>())

export const putBookingOnHold = createAction('[Booking/API] Put Booking On Hold', props<{ bookingId: string }>())
export const putBookingOnHoldSuccess = createAction('[Booking/API] Put Booking On Hold Success', props<{ booking: Booking }>())
export const putBookingOnHoldFailure = createAction('[Booking/API] Put Booking On Hold Failure', props<{ error: Error }>())
