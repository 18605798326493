import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { NotesOverlayModule } from 'src/components/notes/notes-overlay/notes-overlay.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { InvoiceFormComponent } from './invoice-form.component'
import { InvoiceFormService } from './invoice-form.service'

@NgModule({
  declarations: [InvoiceFormComponent],
  providers: [InvoiceFormService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    EpochFieldModule,
    PipesModule,
    NotesOverlayModule,
  ],
})
export class InvoiceFormModule {}
