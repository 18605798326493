import { Injectable } from '@angular/core'
import { Cost, CreditNote, CREDIT_NOTE_APPROVED, CREDIT_NOTE_VOIDED, DealBase, isCreditNoteApproved, Receipt } from '@tradecafe/types/core'
import { defaults, filter, find, get, groupBy, map, merge, pick, remove, set, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { CreditNoteApiService } from 'src/api/credit-note'
import { OperationsApiService } from 'src/api/operations'
import { environment } from 'src/environments/environment'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { dayjs } from '../dayjs'
import { CostsService } from './costs.service'
import { ReceiptsService } from './receipts.service'

const ALLOWED_FIELDS = ['account', 'amount', 'approval', 'attributes', 'cost_id', 'deal_id', 'invoice_id', 'status', 'currency']
const TRADER_CREDIT_LIMIT = 200 // CAD
const QUERY_ALL = {limit: Number.MAX_SAFE_INTEGER}
const { tradecafeAccount } = environment

@Injectable()
export class CreditNotesService {
  constructor (
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private CreditNoteApi: CreditNoteApiService,
    private Costs: CostsService,
    private FxRates: FxRatesService,
    private Receipts: ReceiptsService,
    private Invoices: InvoicesService,
    private OperationsApi: OperationsApiService,
  ) {}

  getAllCreditNotes() {
    return this.CreditNoteApi.list(tradecafeAccount, QUERY_ALL)
                        .then(({data}) => data)
  }

  // TODO: check traders authorization and use getCreditNotesForDeals?
  getCreditNotesByDealIds(dealIds: string[]) {
    if (!dealIds.length) return Promise.resolve([])
    // TODO: SER-514 introduce new API to get credit notes by multiple deal_ids
    return this.getAllCreditNotes().then(creditNotes =>
      creditNotes.filter(({deal_id}) => dealIds.includes(deal_id)))
  }

  getCreditNotesForDeal(dealId) {
    return this.CreditNoteApi.listByDeal(dealId, QUERY_ALL).then(r => r.data)
  }

  async getCreditNotesForDeals(dealIds: string[], filterVoidedCreditNotes = true) {
    let { data: creditNotes } = await this.CreditNoteApi.listByDeals(dealIds)
    // WA-2231 voided credit notes should not be included
    if (filterVoidedCreditNotes) creditNotes = filter(creditNotes, 'status')
    return groupBy(creditNotes, 'deal_id')
  }

  getByIds(dealId, ids = []) {
    if (!ids.length) return []
    return Promise.all(map(ids, (id) => {
      return this.CreditNoteApi.get(dealId, id).then(({data}) => data)
    }))
  }

  /**
   * Approve credit note and update associated cost
   *
   * @param {*} creditNote
   * @param {*} context
   * @returns
   */
  approveCreditNote(creditNote: CreditNote, context: { cost: Cost, deal: DealBase }) {
    this.setCreditNoteApproved(creditNote)
    return this.save(creditNote, context, {
      success: 'Credit note approved successfully.',
      failure: 'Unable to approve credit note',
    })
  }

  /**
   * Upsert credit note, update associated cost and refresh consignee if needed
   *
   * @param {*} creditNote
   * @param {*} context - {deal, cost}
   * @returns
   */
  saveCreditNote(creditNote: CreditNote, context: { cost: Cost, deal: DealBase }) {
    if (this.canAutoApprove(creditNote, context)) {
      this.setCreditNoteApproved(creditNote)
    }
    return this.save(creditNote, context, {
      success: 'Credit note saved successfully.',
      failure: 'Unable to save credit note',
    })
  }

  /**
   * Void credit note
   *
   * @param {*} creditNote
   * @returns
   */
  async voidCreditNote(creditNote: CreditNote, cost?: Cost) {
    try {
      await this.CreditNoteApi.void(creditNote.credit_note_id)
      creditNote.status = CREDIT_NOTE_VOIDED
      if (creditNote.cost_id) {
        cost = cost || await this.Costs.getCostById(creditNote.cost_id)
        await this.updateAssociatedCost(creditNote, cost)
      }
      // void associated receipt
      if (get(creditNote, 'attributes.receipt_id')) {
        const receipt = await this.Receipts.getById(get(creditNote, 'attributes.receipt_id'))
        await this.Receipts.voidReceipt(receipt)
      }

      // remove credit node from invoice
      // NOTE: we don't have creditNote.invoice_id at prod. and only 3 objects at stage. deprecating
      if (creditNote.invoice_id) {
        await this.removeCreditNoteFromInvoice(creditNote.credit_note_id, creditNote.invoice_id)
      }

      this.toaster.success('Credit note voided successfully.')
    } catch (err) {
      console.error('Unable to void credit note.', err)
      this.toaster.error('Unable to void credit note.', err)
      throw err
    }

  }

  private async removeCreditNoteFromInvoice(creditId: string, invoiceId: string) {
    const invoice = await this.Invoices.getById(invoiceId)
    const credits = get(invoice, 'credit_notes', []) as Cost['attributes']['credit_notes_ex']
    remove(credits, credit => credit.credit_note_id === creditId)
    await this.Invoices.patchInvoice(invoice, {credit_notes: credits})
  }

  /**
   * Upsert credit note, update associated cost, refresh deal consignee if needed
   *
   * @private
   * @param {*} creditNote
   * @param {*} deal
   * @param {*} msg
   * @returns
   */
  private async save(creditNote: CreditNote, context: { cost?: Cost, deal: DealBase }, msg) {
    let stored
    try {
      stored = await this.patchCreditNote(creditNote)
      this.toaster.success(msg.success)
    } catch (err) {
      console.error(msg.failure, err)
      this.toaster.error(msg.failure, err)
      throw err
    }

    try {
      await this.updateAssociatedCost(stored, context.cost)
      this.toaster.success('Costs updated successfully.')
    } catch (err) {
      console.error('Unable to update cost for this credit note', err)
      this.toaster.error('Unable to update cost for this credit note', err)
      throw err
    }

    return stored
  }


  private createReceiptForCreditNote(creditNote: CreditNote) {
    return this.OperationsApi.createReceipt({
      account: creditNote.account,
      amount: creditNote.amount,
      currency: creditNote.currency,
      received: dayjs.utc().unix(),
      description: `Generated from credit note for deal ${creditNote.deal_id}`,
      attributes: {
        credit_note_id: creditNote.credit_note_id,
        deal_id: creditNote.deal_id,
      },
    } as Receipt).toPromise().then(r => r.data, (e) => {
      this.toaster.error('Unable to create receipt for this credit note')
      throw e
    })
  }


  /**
   * Create/Update a credit note document
   *
   * @param {any} creditNote
   * @returns
   */
  async patchCreditNote(creditNote: Partial<CreditNote>) {
    const {user_id} = this.AuthApi.currentUser
    const payload = pick(creditNote, ALLOWED_FIELDS)
    if (!creditNote.credit_note_id) {
      payload.user_id = user_id
      set(payload, 'attributes.version', 2) // WA-2862: add flag for new created credit note.
    }
    if (payload.account) {
      payload.account = payload.account.toString()
    }

    const {data: stored} = creditNote.credit_note_id
      ? await this.CreditNoteApi.update(creditNote.deal_id, creditNote.credit_note_id, payload)
      : await this.CreditNoteApi.create(payload)

    // create receipt for approved credit note
    if (stored.status === CREDIT_NOTE_APPROVED && !get(stored, 'attributes.receipt_id')) {
      const receipt = await this.createReceiptForCreditNote(stored)
      set(stored, 'attributes.receipt_id', receipt.receipt_id)
      await this.CreditNoteApi.update(stored.deal_id, stored.credit_note_id, {attributes: stored.attributes})
    }

    return stored
  }

  /**
   * Update associated cost
   *
   * @private
   * @param {any} costId
   * @param {any} creditNoteId
   * @returns
   */
  private async updateAssociatedCost(creditNote: CreditNote, cost: Cost) {
    const {credit_note_id} = creditNote
    const attributes = defaults(cost.attributes, {
      credit_notes: [],
      credit_notes_ex: [],
    })
    attributes.credit_notes.push(credit_note_id)
    attributes.credit_notes = uniq(attributes.credit_notes)

    let costCreditNote = find(attributes.credit_notes_ex, {credit_note_id})
    if (isCreditNoteApproved(creditNote)) { // approved
      if (!costCreditNote) {
        costCreditNote = {credit_note_id}
        attributes.credit_notes_ex.push(costCreditNote)
      }
      Object.assign(costCreditNote, {
        amount: creditNote.amount,
        date: creditNote.created,
        currency: creditNote.currency,
      })
    } else if (creditNote.status === CREDIT_NOTE_VOIDED) {
      remove(attributes.credit_notes, id => id === credit_note_id)
      remove(attributes.credit_notes_ex, {credit_note_id})
    }
    return this.Costs.update(cost)
  }


  /**
   * Check is current user can approve credit note
   *
   * @param {*} creditNote
   * @param {*} context
   * @returns
   */
  isApprovable(creditNote: CreditNote, context: { cost: Cost, deal: DealBase }) {
    const {role, user_id} = this.AuthApi.currentUser
    const {trader_user_id} = context.deal || {}
    const creditNotes = context.cost.attributes?.credit_notes || []
    if (role !== 'trader') return true
    const amount = this.FxRates.toCADSync(
      creditNote.amount,
      // NOTE: in "dealfinancials" project we also use cost currency
      context.cost.amount.currency,
      'ask',
      context.deal.attributes.fx_rates.rates,
      context.deal.attributes.fx_rates_ask_range)
    return user_id === trader_user_id && amount < TRADER_CREDIT_LIMIT && creditNotes.length < 2
  }

  /**
   * Check if a credit note can be automatically approved (WA-2154)
   *
   * @private
   * @param {*} creditNote
   * @param {*} context - {cost, deal}
   * @returns {boolean}
   */
  private canAutoApprove(creditNote: CreditNote, context: { cost: Cost, deal: DealBase}) {
    if (creditNote.credit_note_id) return false



    const isBelowLimit = () => {
      return TRADER_CREDIT_LIMIT > this.FxRates.toCADSync(
        creditNote.amount,
        // NOTE: in "dealfinancials" project we also use cost currency
        // TODO: WA-2210 use credit note currency
        context.cost.amount.currency,
        'ask',
        context.deal.attributes.fx_rates.rates,
        context.deal.attributes.fx_rates_ask_range)
    }

    const isBuyerTrader = () => {
      const {role, user_id} = this.AuthApi.currentUser
      return !creditNote.credit_note_id &&
        role === 'trader' &&
        user_id === context.deal.trader_user_id
    }

    return isBelowLimit() // WA-3036 : All credit notes created by anyone under a specific amount should be approved automatically.
  }

  /**
   * Set credit note approved status
   *
   * @private
   * @param {*} creditNote
   */
  private setCreditNoteApproved(creditNote: CreditNote) {
    const {user_id} = this.AuthApi.currentUser
    return merge(creditNote, {
      status: CREDIT_NOTE_APPROVED,
      approval: {
        user_id,
        date: dayjs.utc().unix(),
      },
    })
  }

  //   /**
  //    * Update invoice
  //    *
  //    * @param {any} invoiceId
  //    * @param {any} creditNoteId
  //    * @returns
  //    */
  //   async function updateInvoice(creditNote: CreditNote) {
  //     const { credit_note_id, invoice_id } = creditNote
  //     if (!invoice_id) return false
  //     const { data: invoice } = await InvoiceApi.get(invoice_id)

  //     invoice.credit_notes = invoice.credit_notes || []
  //     let costCreditNote = find(invoice.credit_notes, { credit_note_id })
  //     if (creditNote.status === CREDIT_NOTE_APPROVED) { // approved
  //       if (!costCreditNote) {
  //         costCreditNote = { credit_note_id }
  //         invoice.credit_notes.push(costCreditNote)
  //       }
  //       Object.assign(costCreditNote, {
  //         amount: creditNote.amount,
  //         date: creditNote.created,
  //       })
  //     } else { // not approved
  //       remove(invoice.credit_notes, { credit_note_id })
  //     }

  //     const { data } = await InvoiceApi.update(invoice.account, invoice.invoice_id, {
  //       credit_notes: invoice.credit_notes
  //     })

  //     return data
  //   }
}
