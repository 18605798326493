<table mat-table [trackBy]="getRowId"
    fixedLayout [dataSource]="dataSource"
    cdkDropList (cdkDropListDropped)="onListDrop($event)">

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row cdkDrag *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row" class="clickable"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayColumns.length" class='no-shipments'>
      <div>
        <img src="assets/images/no-result.png">
        <h4>No Shipment Details</h4>
        <span>Please click the 'Add Segment' button to the top right to populate data.</span>
      </div>
    </td>
  </tr>


  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="selectAll" matTooltip="Select All" (change)="selection.toggleRows($event.checked, dataSource.data)" ></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let deal">
      <div *ngIf="!deal" class="skeleton-loader square"></div>
      <mat-checkbox *ngIf="deal && selection.canSelect(deal)"
          [checked]="selection.isSelected(deal)"
          (click)="$event.stopPropagation()"
          (change)="selection.toggleRow(deal)"
          ></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.type">{{columnNames.type}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader circle"></div>
      </ng-container>
      <ng-container *ngIf="segment" [ngSwitch]="segment.type">
        <i class="fa fa-plane" *ngSwitchCase="'air'"></i>
        <i class="fa fa-truck" *ngSwitchCase="'land'"></i>
        <i class="fa fa-train" *ngSwitchCase="'rail'"></i>
        <i class="fa fa-ship" *ngSwitchCase="'sea'"></i>
        <i class="fa fa-industry" *ngSwitchCase="'warehouse'"></i>
        <span *ngSwitchDefault>{{segment.type}}</span>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="carrierName">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.carrierName">{{columnNames.carrierName}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="segment">
        <div class="text-ellipsis" [class.tc-portal-visible]="segment.isPortalVisible" [title]="segment.carrierName">{{segment.carrierName}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="originName">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.originName">{{columnNames.originName}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="segment">
        <div class="text-ellipsis" [title]="segment.originName">{{segment.originName}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="destinationName">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.destinationName">{{columnNames.destinationName}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
      </ng-container>
      <ng-container *ngIf="segment">
        <div class="text-ellipsis" [title]="segment.destinationName">{{segment.destinationName}}</div>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="amountCAD">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.amountCAD">{{columnNames.amountCAD}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="segment?.amountCAD">
        <div class="text-ellipsis">{{segment.amountCAD | currency:'CAD':'symbol-narrow'}}</div>
        <small class="text-gray">CAD</small>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="cutoffDate">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.cutoffDate">{{columnNames.cutoffDate}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="segment?.cutoffDate">
        <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: segment.cutoffDate }"></ng-container>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="loadDate">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.loadDate">{{columnNames.loadDate}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="segment?.loadDate">
        <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: segment.loadDate, showTime: segment.loadTime }"></ng-container>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="dropoffDate">
    <th mat-header-cell *matHeaderCellDef xx-mat-sort-header [title]="columnNames.dropoffDate">{{columnNames.dropoffDate}}</th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource"
        (click)="segment && rowClick.emit({segment: segment})">
      <ng-container *ngIf="!segment">
        <div class="skeleton-loader thin-line"></div>
        <div class="skeleton-loader tiny-line"></div>
      </ng-container>
      <ng-container *ngIf="segment?.dropoffDate">
        <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: segment.dropoffDate, showTime: segment.dropoffTime }"></ng-container>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="ellipsisMenu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let segment; dataSource:dataSource">
      <ng-container *ngIf="!readonly && !segment">
        <div class="skeleton-loader circle"></div>
      </ng-container>
      <ng-container *ngIf="!readonly && segment">
        <button mat-icon-button type="button"
            [matMenuTriggerFor]="ellipsisMenu"
            [matMenuTriggerData]="{segment: segment}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      </ng-container>
    </td>
  </ng-container>
</table>

<mat-paginator #paginator></mat-paginator>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-segment="segment">
    <button mat-menu-item (click)="showUpdateItem(segment)">
      <i class="fa fa-fw fa-edit"></i> Edit
    </button>
    <button mat-menu-item (click)="showDeleteItem(segment)">
      <i class="fa fa-fw fa-trash-can"></i> Delete
    </button>
    <button mat-menu-item (click)="showSegmentNotes(segment)" *ngIf="!!dealViewRaw$ && segment.segmentForm.value.segment?.segment_id">
      <i class="fa fa-fw fa-comment"></i> Add/See Notes
    </button>
    <button mat-menu-item (click)="createCostFromSegment.next(segment.segmentForm)" >
      <i class="fa fa-plus"></i> Create cost
    </button>
    <button mat-menu-item (click)="sendSegmentNotification(segment)" *ngIf="isLogisticsView && !!dealViewRaw$
    && segment.segmentForm.value.segment?.segment_id && segment.segmentForm.value.type !== 'warehouse'">
      <i class="fa fa-fw fa-envelope"></i> Send Notification
    </button>
  </ng-template>
</mat-menu>

<ng-template #cellEpoch let-timestamp="timestamp" let-useUtc="useUtc" let-showTime="showTime">
  <div class="text-ellipsis" *ngIf="timestamp">
    <div class="text-ellipsis" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
    <small *ngIf="showTime" class="text-ellipsis text-gray" [title]="timestamp | epoch:'LT':useUtc">{{timestamp | epoch:'LT':useUtc}}</small>
  </div>
</ng-template>
