<mat-form-field [class.mat-form-field-should-float]="hasValue$ | async" (click)="click()">
  <mat-label *ngIf="placeholder">{{placeholder}} <mat-icon *ngIf="hasChanged$ | async"
      matTooltip="Address has been changed. Please re:select address."
      class="fas fa-exclamation-triangle"
      style="color: orange"
      ></mat-icon>
  </mat-label>
  <div class="tc-address-field-value">
    <ng-container *ngFor="let address of multiple ? ctrl.value : [ctrl.value]">
      <ng-container *ngTemplateOutlet="innerContent || displayValue; context: {address: address}"></ng-container></ng-container></div>
  <input matInput readonly [formControl]="ctrl" rows="1" autocomplete="off" style="display:none">
  <button *ngIf="!readonly && ctrl.enabled && hasValue$ | async"
      mat-icon-button type="button" class="tc-text-clear"
      (click)="clear($event)">
    <mat-icon class="fa fa-times"></mat-icon>
  </button>
</mat-form-field>
<div class="tc-address-field-spacer"></div>
<ng-content></ng-content>
<ng-template #displayValue let-address="address">
  <span [title]="address | address:addressFormat:multiline">{{(address | address:addressFormat:multiline)}}</span></ng-template>
