import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { AccountObject, Deal, DealParty, DealPartyE, DealView, SupplierOffer, TableKey } from '@tradecafe/types/core'
import { DeepReadonly, isDealClone, pickupDate } from '@tradecafe/types/utils'
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import * as dayjs from 'dayjs'
import { compact, identity, keyBy, last, sortBy } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, from, merge, of } from 'rxjs'
import { distinctUntilChanged, filter, map, mapTo, switchMap, take, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import { loadCarriers } from 'src/app/store/carriers'
import { loadCurrencies } from 'src/app/store/currencies'
import { actualizeCostToZeroSuccess, autosaveDealCostSuccess, autosaveDealSegmentSuccess, cloneDealSuccess, copyCloneDealSuccess, escalateInvoiceSuccess, escalateInvoicesSuccess, lockDeal, lockDealFailure, lockDealSuccess, massDealForm, massDealFormFailure, massDealFormSuccess, saveDealForm, saveDealFormFailure, saveDealFormSuccess, sendConfirmationSuccess, setDealDateRangeSuccess, specialInstructionsSuccess, submitDealForm, submitDealFormFailure, submitDealFormSuccess, unactualizeCostToZeroSuccess } from 'src/app/store/deal-view.actions'
import { selectRawDealsByIds } from 'src/app/store/deal-view.reducer'
import { loadLocations } from 'src/app/store/locations'
import { loadMeasures } from 'src/app/store/measures'
import { loadProducts } from 'src/app/store/products'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { CreditNoteFormService } from 'src/components/credits/credit-note-form/credit-note-form.service'
import { CsvExporterService } from 'src/components/csv-exporter/csv-exporter.service'
import { columnDefsById } from 'src/components/deals-list'
import { NoteFormService } from 'src/components/notes/note-form/note-form.service'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { SegmentFormService } from 'src/components/segment-form/segment-form.service'
import { UploaderDialogService } from 'src/components/uploader-dialog/uploader-dialog.service'
import { environment } from 'src/environments/environment'
import { SupplierOfferOverlayService } from 'src/pages/admin/auction/supplier-offer-overlay/supplier-offer-overlay.service'
import { DealOverlaysService } from 'src/services/actions/deal-overlays.service'
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service'
import { DealFormCalculatorService } from 'src/services/data/deal-form-calculator.service'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import { canCreateSupplierOffer } from 'src/services/data/deal-view-permissions.service'
import { DealsService } from 'src/services/data/deals.service'
import { OffersService } from 'src/services/data/offers.service'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { DealDocumentsService } from '../../deals/deal-documents/deal-documents.service'
import { DocumentsOverlayService } from '../../deals/deal-documents/documents-overlay/documents-overlay.service'
import { RequestPrepaymentFormService } from '../../deals/request-prepayment/request-prepayment.service'
import { SendConfirmationFormService } from '../../deals/send-confirmation-form/send-confirmation-form.service'
import { CreateOfferFormService } from '../create-offer-form/create-offer-form.service'
import { CloneFormService } from '../deal-clones/clone-form/clone-form.service'
import { ShipmentRatePickerService } from '../deal-shipping/shipment-rate-picker/shipment-rate-picker.service'
import { ChangeBrokeragePartyFormService } from './change-brokerage-party-form/change-brokerage-party-form.service'
import { ChangeCloneFormService } from './change-clone-form/change-clone-form.service'
import { ChangePartyFormService } from './change-party-form/change-party-form.service'
import { DealClonesComponent } from './deal-clones/deal-clones.component'
import { CostFormService } from './deal-details/cost-form/cost-form.service'
import { DealFormPageBaseController } from './deal-form-base.component'
import { DealFormPageOverlayService } from './deal-form-page-overlay/deal-form-page-overlay.component.service'
import {
  cloneFromSel,
  copyFromSel,
  isCloneSel, isCopySel,
  isLockedSel, isPrimaryCloneSel,
  isSubmittedSel
} from './deal-form-page.decorator'
import { DealFormService } from './deal-form.service'
import { DealFormSnapshotsService } from './deal-form.service-snapshots'


const TABS = ['details', 'product-specs', 'logistics-costs', 'shipping', 'default-costs', 'clones']
@Component({
  selector: 'tc-deal-form-page',
  templateUrl: './deal-form-page.component.html',
  styleUrls: ['./deal-form-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealFormPageComponent extends DealFormPageBaseController implements OnInit {
  constructor(
    private actions$: Actions,
    private activeRoute: ActivatedRoute,
    protected AuthApi: AuthApiService,
    private ChangePartyForm: ChangePartyFormService,
    private ChangeCloneForm: ChangeCloneFormService,
    private CloneForm: CloneFormService,
    private ConfirmModal: ConfirmModalService,
    CostForm: CostFormService,
    private CreateOfferForm: CreateOfferFormService,
    CreditNoteForm: CreditNoteFormService,
    protected DealDocuments: DealDocumentsService,
    protected DealForm: DealFormService,
    protected DealFormSnapshots: DealFormSnapshotsService,
    private DealFormCalculator: DealFormCalculatorService,
    private NoteForm: NoteFormService,
    NotesOverlay: NotesOverlayService,
    protected DealOverlays: DealOverlaysService,
    Deals: DealsService,
    private DealViewLoader: DealViewLoaderService,
    private Offers: OffersService,
    RequestPrepaymentForm: RequestPrepaymentFormService,
    protected router: Router,
    SegmentForm: SegmentFormService,
    protected SendConfirmationForm: SendConfirmationFormService,
    ShipmentRatePicker: ShipmentRatePickerService,
    protected store: Store,
    private SupplierOfferOverlayService: SupplierOfferOverlayService,
    toaster: ToasterService,
    private CsvExporter: CsvExporterService,
    private elastic: DealElasticSearchService,
    private DocumentsOverlay: DocumentsOverlayService,
    protected UploaderDialog: UploaderDialogService,
    protected dealFormPageOverlayService: DealFormPageOverlayService,
    private readonly ChangeBrokeragePartyForm: ChangeBrokeragePartyFormService,
  ) {
    super(
      '/trading/deals',
      dealFormPageOverlayService.getDealId() ? of(dealFormPageOverlayService.getDealId()) : activeRoute.params.pipe(map(pm => pm.dealId)),
      activeRoute.snapshot.queryParams.back || '/trading/deals',
      AuthApi,
      CostForm,
      CreditNoteForm,
      DealForm,
      NotesOverlay,
      DealOverlays,
      Deals,
      RequestPrepaymentForm,
      router,
      SegmentForm,
      ShipmentRatePicker,
      store,
      toaster,
      UploaderDialog,
      DealDocuments,
    )
  }

  protected readonly DealPartyE = DealPartyE

  // static options (read from route params and data)
  readonly isNew$ = this.dealId$.pipe(map(dealId => !dealId), distinctUntilChanged())
  readonly title$ = this.isNew$.pipe(map(isNew => isNew ? 'New Deal' : 'Edit Deal'), distinctUntilChanged())
  protected readonly isBrokerageDeal$ = environment.enableBrokerageDeals
    ? this.dealViewRaw$.pipe(map(dv => dv.deal.deal_type === 'brokerage'), distinctUntilChanged())
    : of(false)

  // active tab name and index
  private activeTab$ = new BehaviorSubject(location.hash.slice(1) || TABS[0])
  activeTabIndex$ = this.activeTab$.pipe(map(tabName => TABS.indexOf(tabName)), distinctUntilChanged())

  // clones component
  private dealClones$ = new BehaviorSubject<DealClonesComponent>(undefined)
  /* private */get dealClones() { return this.dealClones$.value }
  @ViewChild(DealClonesComponent) set dealClones(dealClones: DealClonesComponent) { this.dealClones$.next(dealClones) }
  @ViewChild(DealViewIncompleteComponent) incomplete: DealViewIncompleteComponent

  noSelectedClones$ = this.dealClones$.pipe(
    switchMap(dealClones => dealClones.selectedIds$),
    map(x => !x.length),
    distinctUntilChanged())

  private selectedDeals$ = this.dealClones$.pipe(
    switchMap(dc => dc.selectedIds$),
    take(1),
    switchMap(dealIds => this.Deals.getDealViews(dealIds)))

  private dealOrClones$: Observable<DeepReadonly<Deal[]>> = this.activeTab$.pipe(
    switchMap(activeTab => activeTab === 'clones'
      ? this.dealClones$.value.selectedIds$.pipe(
        switchMap(dealIds => this.store.select(selectRawDealsByIds(dealIds))),
        // TODO: WA-7397: sort by pickupDate - provide segments
        map(deals => sortBy(deals, deal => pickupDate(deal))))
      : this.dealRaw$.pipe(map(deal => [deal]))))
  private fetchDealViewOrClones$ = this.dealOrClones$.pipe(
    take(1),
    switchMap(deals => this.fetchOldDeals(deals.map(d => d.deal_id))))

  // ui state
  isLocked$ = isLockedSel(this.dealRaw$)
  protected canCreatePrepayment$ = this.dealViewRaw$.pipe(map(dv => dv.can_create_prepayment), distinctUntilChanged())
  isSubmitted$ = isSubmittedSel(this.dealRaw$)
  isClone$ = isCloneSel(this.dealRaw$)
  isCopy$ = isCopySel(this.dealRaw$)
  isPrimaryClone$ = isPrimaryCloneSel(this.dealRaw$)
  isClonesTabSelected$ = this.dealId$.pipe(switchMap(dealId => dealId
    ? this.activeTab$.pipe(map(activeTab => activeTab === 'clones'))
    : of(false)))
  isConfirmationDisabled$ = this.activeTab$.pipe(
    switchMap(activeTab => activeTab === 'clones'
      ? this.dealClones$.pipe(switchMap(dc => dc.selectedIds$), map(ids => !ids.length))
      : of(false)),
    distinctUntilChanged())
  canCreateSupplierOffer$ = this.dealRaw$.pipe(
    map(deal => {
      return canCreateSupplierOffer(deal, this.AuthApi.currentUser) && !this.hasActiveSupplierOffer(this.supplierOffer)
    }),
    distinctUntilChanged())

  hasActiveSupplierOffer = (offer) => {
    return offer && !this.Offers.isExpired(offer) && !this.Offers.isCancelled(offer)
  }

  canNotClone$ = this.dealRaw$.pipe(
    map(deal => !deal.deal_id || isDealClone(deal)),
    distinctUntilChanged())

  cloneFrom$ = cloneFromSel(this.dealRaw$)
  copyFrom$ = copyFromSel(this.dealRaw$)
  supplierOffer: SupplierOffer

  detailsTabInvalid$: Observable<number> = of(0) // this.fb.group({ details: this.detailsForm, products: this.productsForm })
  specsTabInvalid$: Observable<number> = of(0) // this.fb.group({ products: this.productsForm })

  modalDisplay: boolean = false
  accounts: DeepReadonly<AccountObject>[] = []

  ngOnInit(): void {
    this.modalDisplay = !!this.dealFormPageOverlayService.getDealId()
    merge(
      this.actions$.pipe(ofType(saveDealForm, massDealForm), mapTo('save')),
      this.actions$.pipe(ofType(submitDealForm), mapTo('submit')),
      this.actions$.pipe(ofType(lockDeal), mapTo('lock')),
      this.actions$.pipe(ofType(saveDealFormSuccess, saveDealFormFailure, massDealFormSuccess, massDealFormFailure, submitDealFormSuccess, submitDealFormFailure, lockDealSuccess, lockDealFailure), mapTo(undefined)),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(this.inProgress$)

    this.actions$.pipe(
      ofType(saveDealFormSuccess, submitDealFormSuccess),
      withLatestFrom(this.isNew$),
      untilComponentDestroyed(this),
    ).subscribe(([{ deal }, isNew]) => {
      if (isNew) this.goToDealId(deal.deal_id)
      else this.dealForm.markAsPristine()
    })

    this.isNew$.pipe(switchMap(isNew => {
      if (isNew) return of()
      return merge(
        this.actions$.pipe(
          ofType(specialInstructionsSuccess),
          switchMap(() => this.fetchDealNotes())),

        merge(
          this.actions$.pipe(
            ofType(
              actualizeCostToZeroSuccess,
              autosaveDealCostSuccess,
              autosaveDealSegmentSuccess,
              lockDealSuccess,
              saveDealFormSuccess,
              submitDealFormSuccess,
              unactualizeCostToZeroSuccess,
            )),
          this.actions$.pipe(
            ofType(sendConfirmationSuccess, escalateInvoicesSuccess),
            withLatestFrom(this.dealId$),
            filter(([action, dealId]) => action.dealIds.includes(dealId))),
          this.actions$.pipe(
            ofType(escalateInvoiceSuccess),
            withLatestFrom(this.dealId$),
            filter(([action, dealId]) => action.dealId === dealId)),
          this.actions$.pipe(
            ofType(setDealDateRangeSuccess),
            withLatestFrom(this.dealId$),
            filter(([action, dealId]) => action.deals[0].deal_id === dealId)),
          this.actions$.pipe(
            ofType(massDealFormSuccess),
            withLatestFrom(this.dealId$),
            filter(([action, dealId]) => !!action.deals.find(d => d.deal_id === dealId))),
        ).pipe(switchMap(() => this.fetchDeal())),
      )
    }), untilComponentDestroyed(this)).subscribe()

    this.actions$.pipe(
      ofType(copyCloneDealSuccess, cloneDealSuccess),
      map(a => a.deals),
      withLatestFrom(this.dealViewRaw$),
      untilComponentDestroyed(this),
    ).subscribe(([deals, dealViewRaw]) => {
      const deal = deals.find(d => d.deal_id === dealViewRaw.deal.deal_id)
      if (deal) this.dealViewRaw$.next({ ...dealViewRaw, deal })
    })

    this.dealViewRaw$.pipe(untilComponentDestroyed(this)).subscribe(dv =>
      this.DealForm.enableDisableDealForm(this.dealForm, dv))

    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadMeasures({}))

    const snapshotId$ = this.activeRoute.queryParams.pipe(
      map(q => q.snapshotId),
      distinctUntilChanged())

    this.dealId$.pipe(withLatestFrom(snapshotId$)).subscribe(([dealId, snapshotId]) => {
      if (dealId) {
        this.Offers.findSubOffer(dealId).then((supplierOffer) => this.supplierOffer = supplierOffer)
      } else if (!snapshotId) {
        this.newDeal()
      }
    })

    this.ready$.pipe(switchMap(() =>
      this.DealFormCalculator.keepTermDateInSync(this.dealViewRaw$, this.dealForm)),
      untilComponentDestroyed(this)).subscribe()

    this.activeRoute.queryParams.pipe(
      map(q => q.snapshotId),
      distinctUntilChanged(),
      filter(identity),
      switchMap(snapshotId => from(this.loadFromStorage(snapshotId))),
      untilComponentDestroyed(this)).subscribe()

    this.store.pipe(select(selectAllAccounts)).subscribe((accs) => {
      this.accounts = accs
    })
  }

  showAddSegment() {
    combineLatest([
      this.dealViewRaw$.pipe(map(dto => last(dto.segments))),
      this.carriers$,
    ]).pipe(take(1)).subscribe(async ([lastSegment, carriers]) => {
      const { estimatedTotals: { weight } } = this.detailsForm.getRawValue()
      const segment = await this.ShipmentRatePicker.showAddSegment(lastSegment, carriers, weight)
      if (segment) this.createSegment(segment)
    })
  }

  showDealDocuments() {
    this.dealId$.pipe(take(1), switchMap(dealId =>
      this.DocumentsOverlay.showDealDocuments(dealId, { canCreate: true }),
    )).subscribe()
  }

  setSpecialInstructions(party: DealParty) {
    combineLatest([
      this.dealId$.pipe(take(1)),
      this.selectedDeals$,
    ])
      .subscribe(async ([dealId, deals]) => {
        const added = await this.NoteForm.setSpecialInstructions(deals, party)
        if (added && deals.some(deal => deal.deal.deal_id === dealId)) {
          this.fetchDealNotes().subscribe()
        }
      })
  }

  showChangeCloneDetails() {
    this.selectedDeals$.subscribe(deals => this.ChangeCloneForm.showChangeClone(deals))
  }

  sendConfirmation(to: DealPartyE) {
    this.dealOrClones$.pipe(take(1)).subscribe(deals =>
      this.SendConfirmationForm.sendConfirmation(deals, to))
  }

  sendConfirmationLabel(to: DealPartyE) {
    return this.dealOrClones$.pipe(map(deals =>
      this.SendConfirmationForm.sendConfirmationLabel(deals, to)))
  }

  previewDocuments(to: DealPartyE) {
    this.fetchDealViewOrClones$.subscribe(deals =>
      this.DealDocuments.previewMergedDocuments(deals, to))
  }

  copyDeal() {
    this.dealViewRaw$
      .pipe(take(1))
      .subscribe((dv) => {
        let buyer = this.accounts.filter((acc) => acc.account.toString() === dv.deal.buyer_id)[0]
        let supplier = this.accounts.filter((acc) => acc.account.toString() === dv.deal.supplier_id)[0]
        let { notes } = dv
        if (buyer.status === -1 || supplier.status === -1) {
          this.toaster.error('Company in RESTRICTED status.')
          return null
        } else if (dv.deal.status === 'Draft' && ((dayjs.utc().unix() - dv.deal.created) > 3600 * 24 * 365)) {
          this.toaster.error("The deal you are trying to Copy has been in draft status for over a year. Please choose a more recent deal or create from scratch please.")
          return null
        }
        else {
          return this.DealFormSnapshots.copyDealForm(this.dealForm, { notes })
        }
      })
  }

  showCloneDeal() {
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => this.CloneForm.show2(dv))
  }

  submitDeal() {
    if (this.inProgress$.value) return
    this.dealForm.markAllAsTouched()
    this.dealForm.updateValueAndValidity()
    if (!this.dealForm.valid) return

    combineLatest([this.dealId$, this.isClone$, this.isClonesTabSelected$, this.dealViewRaw$]).pipe(take(1))
      .subscribe(async ([dealId, isClone, isClonesTabSelected, dv]) => {
        if (isClone && isClonesTabSelected) {
          await this.ConfirmModal.show({
            title: 'Deal Confirmation',
            titleIcon: 'fas fa-exclamation-triangle',
            description: `
            Please confirm that you only want to send out confirmations for the
            deal <strong>${dealId}</strong> (the one from which you are accessing the Clones tab).<br><br>
            If you wanted to send confirmataions for the deals you selected,
            please use links in the <strong>ACTION</strong> button.`,
            confirmButtonText: 'Send Confirmations',
            confirmButtonClass: 'btn-primary',
            cancelButtonText: 'Cancel',
            cancelButtonClass: 'btn-danger',
          })
        }
        this.store.dispatch(submitDealForm({
          dv,
          dealForm: this.dealForm.serialize(),
        }))
      })
  }

  async goToShippingView() {
    if (this.dealForm.dirty) await this.ConfirmModal.showUnsavedDataWarning()
    this.dealId$.pipe(take(1)).subscribe(dealId =>
      this.router.navigateByUrl(`/logistics/shipping-log/${dealId}?back=${this.back}`))
  }

  setActiveTab(index: number) {
    const tabName = TABS[index]
    this.activeTab$.next(tabName)
    if (!this.modalDisplay) {
      location.hash = tabName === 'details' ? '' : tabName
    }
  }

  showChangeParty(party: DealPartyE.buyer | DealPartyE.supplier) {
    this.selectedDeals$.subscribe(deals =>
      this.ChangePartyForm.showChangeParty(deals, party))
  }

  showChangeBrokerageParty() {
    this.selectedDeals$.subscribe(deals =>
      this.ChangeBrokeragePartyForm.showChangeBrokerageParty(deals))
  }

  showCreateOffer() {
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.CreateOfferForm.showCreateOffer(dv).then(so => {
        if (so) this.supplierOffer = so
      });
    });
  }

  async showSupplierOffer() {
    this.SupplierOfferOverlayService.editOffer(this.supplierOffer).subscribe()
  }

  showMatchedOffers() {
    this.router.navigateByUrl(`/trading/matched-offers?supplierOffersIds=${this.supplierOffer.offer_id}`)
  }

  showExportGrid() {
    const dataSource = this.dealClones.dataSource
    const byId = keyBy(dataSource.data, 'deal_id')
    const cloneIds = dataSource.data.map(d => d.deal_id)
    const selected = compact(this.dealClones.selectedIds$.value.map(dealId => byId[dealId]))

    return this.CsvExporter.fromDataTable({
      dataSource,
      fileName: `Deal-Clones-${new Date()}`,
      selected,
      visible: this.dealClones.exportColumns,
      available: this.dealClones.exportColumns,
      columnDefs: columnDefsById(TableKey.DealClonesTab),
      loadAll: (columns) =>
        this.elastic.fetchAllDeals({ deal_id: cloneIds, columns }).toPromise(),
      loadByIds: (rows, columns) =>
        this.elastic.fetchAllDeals({
          deal_ids: rows.map(r => r.deal_id),
          columns,
        }).toPromise()
    })
  }

  private async loadFromStorage(snapshotId: string) {
    const { dealViewRaw, snapshot } = await this.DealFormSnapshots.restoreSnapshot(snapshotId)
    snapshot.details.date = dayjs().unix()
    this.DealForm.setValue(this.dealForm, snapshot)
    this.DealFormCalculator.syncTermDate(dealViewRaw, this.dealForm).subscribe(() => {
      this.ready$.next(true)
      this.dealViewRaw$.next(dealViewRaw)
    })
  }

  private async newDeal() {
    const { dealViewRaw, dealProducts } = await this.DealFormSnapshots.newDeal()
    this.DealForm.patchForm(this.dealForm, dealViewRaw, dealProducts)
    this.ready$.next(true)
    this.dealViewRaw$.next(dealViewRaw)
  }


  private fetchOldDeals(dealIds: string[]): Observable<DealView[]> {
    return from(this.DealViewLoader.fetchDealsWith(
      dealIds,
      ['costs', 'credit-notes', 'vendor-credits', 'invoices', 'segments', 'notes', 'editable']))
  }
}
