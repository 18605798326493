import { ActionReducerMap, MetaReducer } from '@ngrx/store'
import { accountsFeatureKey, accountsReducer, AccountsState } from '../accounts'
import { carriersFeatureKey, carriersReducer, CarriersState } from '../carriers'
import { consigneesFeatureKey, consigneesReducer, ConsigneesState } from '../consignees'
import { countriesFeatureKey, countriesReducer, CountriesState } from '../countries'
import { currenciesFeatureKey, currenciesReducer, CurrenciesState } from '../currencies'
import { dealViewReducer, dealViewsFeatureKey, DealViewsState } from '../deal-view.reducer'
import { departmentsFeatureKey, departmentsReducer, DepartmentsState } from '../departments'
import { documentOverridesFeatureKey, documentOverridesReducer, DocumentOverridesState } from '../document-overrides'
import { documentSetsFeatureKey, documentSetsReducer, DocumentSetsState } from '../document-sets'
import { documentTemplatesFeatureKey, documentTemplatesReducer, DocumentTemplatesState } from '../document-templates'
import { invoiceViewReducer, invoiceViewsFeatureKey, InvoiceViewsState } from '../invoice-view/invoice-view.reducer'
import { itemTypesFeatureKey, itemTypesReducer, ItemTypesState } from '../item-types'
import { locationsFeatureKey, locationsReducer, LocationsState } from '../locations'
import { measuresFeatureKey, measuresReducer, MeasuresState } from '../measures'
import { packageTypesFeatureKey, packageTypesReducer, PackageTypesState } from '../package-types'
import { paymentReferencesFeatureKey, paymentReferencesReducer, PaymentReferencesState } from '../payment-references'
import { pricingTermsFeatureKey, pricingTermsReducer, PricingTermsState } from '../pricing-terms'
import { productCategoriesFeatureKey, productCategoriesReducer, ProductCategoriesState } from '../product-categories'
import { productTypesFeatureKey, productTypesReducer, ProductTypesState } from '../product-types'
import { productsFeatureKey, productsReducer, ProductsState } from '../products'
import { usersFeatureKey, usersReducer, UsersState } from '../users'
import { weightTypesFeatureKey, weightTypesReducer, WeightTypesState } from '../weight-types'
import { wrappingTypesFeatureKey, wrappingTypesReducer, WrappingTypesState } from '../wrapping-types'


export interface State {
  [accountsFeatureKey]: AccountsState
  [carriersFeatureKey]: CarriersState
  [consigneesFeatureKey]: ConsigneesState
  [countriesFeatureKey]: CountriesState
  [currenciesFeatureKey]: CurrenciesState
  [dealViewsFeatureKey]: DealViewsState
  [departmentsFeatureKey]: DepartmentsState
  [documentOverridesFeatureKey]: DocumentOverridesState
  [documentSetsFeatureKey]: DocumentSetsState
  [documentTemplatesFeatureKey]: DocumentTemplatesState
  [invoiceViewsFeatureKey]: InvoiceViewsState
  [itemTypesFeatureKey]: ItemTypesState
  [locationsFeatureKey]: LocationsState
  [measuresFeatureKey]: MeasuresState
  [measuresFeatureKey]: MeasuresState
  [packageTypesFeatureKey]: PackageTypesState
  [paymentReferencesFeatureKey]: PaymentReferencesState
  [pricingTermsFeatureKey]: PricingTermsState
  [productCategoriesFeatureKey]: ProductCategoriesState
  [productsFeatureKey]: ProductsState
  [productTypesFeatureKey]: ProductTypesState
  [usersFeatureKey]: UsersState
  [weightTypesFeatureKey]: WeightTypesState
  [wrappingTypesFeatureKey]: WrappingTypesState
}

export const reducers: ActionReducerMap<State> = {
  [accountsFeatureKey]: accountsReducer,
  [carriersFeatureKey]: carriersReducer,
  [consigneesFeatureKey]: consigneesReducer,
  [countriesFeatureKey]: countriesReducer,
  [currenciesFeatureKey]: currenciesReducer,
  [dealViewsFeatureKey]: dealViewReducer,
  [departmentsFeatureKey]: departmentsReducer,
  [documentOverridesFeatureKey]: documentOverridesReducer,
  [documentSetsFeatureKey]: documentSetsReducer,
  [documentTemplatesFeatureKey]: documentTemplatesReducer,
  [invoiceViewsFeatureKey]: invoiceViewReducer,
  [itemTypesFeatureKey]: itemTypesReducer,
  [locationsFeatureKey]: locationsReducer,
  [measuresFeatureKey]: measuresReducer,
  [packageTypesFeatureKey]: packageTypesReducer,
  [paymentReferencesFeatureKey]: paymentReferencesReducer,
  [pricingTermsFeatureKey]: pricingTermsReducer,
  [productCategoriesFeatureKey]: productCategoriesReducer,
  [productsFeatureKey]: productsReducer,
  [productTypesFeatureKey]: productTypesReducer,
  [usersFeatureKey]: usersReducer,
  [weightTypesFeatureKey]: weightTypesReducer,
  [wrappingTypesFeatureKey]: wrappingTypesReducer,
}


export const metaReducers: MetaReducer<State>[] = []
