<header *ngIf="showFilters$ | async" class="tc-clones-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
  <tc-select-search placeholder="Supplier" [multiple]="true" *ngIf="showSupplierFilter$ | async"
      [group]="filtersForm" ctrlName="supplierId"
      [items]="suppliers$ | async"
      bindValue="account"
      bindLabel="name"
      ></tc-select-search>
  <tc-select-search placeholder="Buyer" [multiple]="true" *ngIf="showBuyerFilter$ | async"
      [group]="filtersForm" ctrlName="buyerId"
      [items]="buyers$ | async"
      bindValue="account"
      bindLabel="name"
      ></tc-select-search>
  <span fxFlex></span>
</header>

<div *ngIf="canClone$ | async" class="tc-clones-empty">
  <button type="button" mat-raised-button color="primary" (click)="showCloneDeal()">Clone</button>
</div>

<tc-deals-list
    navigateTo="/trading/deals"
    [dataSource]="dataSource"
    [availableColumns]="displayColumns"
    [columnNames]="columnNames"
    [displayColumns]="displayColumns"
    [tableIdentity]="tableIdentity"
    ></tc-deals-list>
