import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fakeId' })
export class FakeIdPipe implements PipeTransform {
  transform(input: string, prefix = '', suffix = ''): string {
    return fakeId(input, prefix, suffix);
  }
}

export function fakeId(input: string, prefix = '', suffix = ''): string {
  if (!input) return '';
  return `${prefix}${input.slice(-5)}${suffix}`;
}
