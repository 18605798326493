import { TableIdentities } from '../constants/table-identities'
import { tableViewsResolver } from '../services/data/table-views.service'


export const routes = [
  {
    name: 'app.notification',
    // parent: 'app',
    url: '/notification',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['administrator', 'junior-administrator', 'accounting', 'manager', 'superuser', 'trader', 'logistics'],
      title: 'Notification',
    },
  }, {
    name: 'app.notification.default',
    url: '',
    redirectTo: 'app.notification.feeds',
  }, {
    name: 'app.notification.feeds',
    // parent: 'app.setting',
    url: '/feeds',
    component: 'adminNotificationFeedList',
    data: {
      title: 'Feeds',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.notificationFeedList,
      tableViews: tableViewsResolver,
    },
  },
]
