import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealFinanceOverlayComponent } from './deal-finance-overlay.component'

@Injectable()
export class DealFinanceOverlayService {
  constructor(private modal: ModalService) { }

  showFinanceDetails(dealId: string) {
    this.modal.openLargeSide(DealFinanceOverlayComponent, { dealId })
  }
}
