import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms'
import { MatDatepicker } from '@angular/material/datepicker'
import { IEpochRange } from '@tradecafe/types/core'
import { printUnixRange } from '@tradecafe/types/utils'
import { isEqual } from 'lodash-es'
import { Observable, Subscription } from 'rxjs'
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators'
import { epochFromUtc, epochRangeFromUtc, epochRangeToUtc, extendUnixRange } from 'src/directives/epoch-range/epoch-range.utils'
import { dayjs } from 'src/services/dayjs'
import { replayForm } from 'src/shared/utils/replay-form'


@Component({
  selector: 'tc-epoch-range-field',
  templateUrl: './epoch-range-field.component.html',
  styleUrls: ['./epoch-range-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EpochRangeFieldComponent implements OnInit, OnDestroy {
  @Input() placeholder = ''
  @Input() useUtc = false
  @Input() useTemplates = false
  @Input() min: number
  @Input() max: number
  @Input() formGroup: UntypedFormGroup
  @Input() startCtrlName: string
  @Input() endCtrlName: string
  @Input() tbdCtrlName: string
  @Input() comparisonStartCtrlName: string
  @Input() comparisonEndCtrlName: string
  @Input() readonly = false
  rangeTemplates: IEpochRange[]

  @ViewChild('picker', { static: true }) picker: MatDatepicker<unknown>
  protected dateRangeStr$: Observable<string>
  private backup: IEpochRange
  private sub = new Subscription()

  protected internalForm: FormGroup<{
    from: FormControl<number>
    to: FormControl<number>
    tbd: FormControl<any>
  }>

  protected isToggleVisible$: Observable<boolean>
  protected isClearVisible$: Observable<boolean>

  @Output() closed = new EventEmitter<void>()

  constructor(public _elementRef: ElementRef) { }

  ngOnInit() {
    this.rangeTemplates = rangeTemplates(this.useUtc)
    this.dateRangeStr$ = replayForm(this.formGroup).pipe(map(form => {
      const from = form[this.startCtrlName]
      const to = form[this.endCtrlName]
      const tbd = form[this.tbdCtrlName]
      return printUnixRange({ from, to, tbd }, { useUtc: this.useUtc })
    }), distinctUntilChanged())

    this.isToggleVisible$ = replayForm(this.formGroup).pipe(map(form => !this.readonly && !form[this.startCtrlName] && !form[this.endCtrlName]))
    this.isClearVisible$ = replayForm(this.formGroup).pipe(map(form => !this.readonly && (form[this.startCtrlName] || form[this.endCtrlName])))

    const [fromCtrl, toCtrl, tbdCtrl] = [this.startCtrlName, this.endCtrlName, this.tbdCtrlName]
      .map(ctrlName => this.formGroup.controls[ctrlName])
    this.internalForm = new FormGroup({
      from: new FormControl(epochFromUtc(fromCtrl.value, this.useUtc), fromCtrl.validator),
      to: new FormControl(epochFromUtc(toCtrl.value, this.useUtc), toCtrl.validator),
      tbd: tbdCtrl ? new FormControl(tbdCtrl.value, tbdCtrl.validator) : new FormControl(),
    })
    // oneway data replication external => internalForm
    this.sub.add(this.formGroup.valueChanges.pipe(map(formValue => ({
      from: formValue[this.startCtrlName],
      to: formValue[this.endCtrlName],
      tbd: formValue[this.tbdCtrlName],
    })), distinctUntilChanged(isEqual)).subscribe((v) =>
      this.internalForm.patchValue(epochRangeFromUtc(v, this.useUtc))))

    if (this.readonly) return
    this.sub.add(this.picker.openedStream.pipe(
      tap(() => {
        this.backup = this.readUnixRange(this.formGroup.value)
      }),
      switchMap(() => this.picker.closedStream),
      tap(() => {
        const output = this.readUnixRange(this.internalForm.getRawValue())
        if (output.from && !output.to) output.to = output.from
        const outputUtc = epochRangeToUtc(output, this.useUtc)
        if (this.backup.from === outputUtc.from && this.backup.to === outputUtc.to) return // nothing changed
        const [from, to] = extendUnixRange(outputUtc, this.useUtc)
        const tbd = this.rangeTemplates.some(tmpl => isEqual(tmpl, output)) ? output.tbd : ''
        this.formGroup.patchValue({
          [this.startCtrlName]: from,
          [this.endCtrlName]: to,
          [this.tbdCtrlName]: tbd,
        })
        this.formGroup.controls[this.startCtrlName]?.markAsDirty()
        this.formGroup.controls[this.startCtrlName]?.markAsTouched()
        this.formGroup.controls[this.endCtrlName]?.markAsDirty()
        this.formGroup.controls[this.endCtrlName]?.markAsTouched()
        this.formGroup.controls[this.tbdCtrlName]?.markAsDirty()
        this.formGroup.controls[this.tbdCtrlName]?.markAsTouched()
        this.closed.next()
      })).subscribe())
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  clear($event: MouseEvent) {
    $event.stopImmediatePropagation()
    this.formGroup.get(this.startCtrlName).reset()
    this.formGroup.get(this.endCtrlName).reset()
    this.formGroup.get(this.startCtrlName).updateValueAndValidity()
    this.formGroup.get(this.endCtrlName).updateValueAndValidity()
    this.closed.next()
  }

  selectRange(range: IEpochRange) {
    const patch: IEpochRange = {
      from: range.from,
      to: range.to,
    }
    if (this.tbdCtrlName) {
      patch.tbd = range.tbd
    }
    this.internalForm.patchValue(patch)
    this.picker.close()
  }

  private readUnixRange(formValue: IEpochRange): IEpochRange {
    const { from, to, tbd } = formValue
    return { from, to: to || from, tbd }
  }
}

function rangeTemplates(useUtc: boolean): IEpochRange[] {
  const customRangeTemplates = []
  const now = dayjs.utc().startOf('month')
  const end = dayjs.utc().startOf('month').add(11, 'month')
  for (
    let start = dayjs.utc('2018-05-01').startOf('month');
    end.isAfter(start) || end.isSame(start);
    start = start.add(1, 'month')
  ) {
    if (now.isAfter(start)) continue
    const tbd = `${start.format('MMMM\'YYYY')} - TBD`
    customRangeTemplates.push({
      tbd,
      from: dayjs(start).unix(),
      to: dayjs(start).add(1, 'month').subtract(1, 'second').unix(),
    })
  }
  if (useUtc) {
    customRangeTemplates.forEach(t => {
      t.from -= dayjs().utcOffset() * 60
      t.to -= dayjs().utcOffset() * 60
    })
  }
  return customRangeTemplates
}
