import { HttpParams } from '@angular/common/http'
import { stringify } from 'qs'

/**
 * Decorator for upgraded API methods. Use qs to encode params
 */
export function AngularParams() {
  return (
    target: any,
    propertyKey: string,
    parameterIndex: number,
  ) => {
    target[`__AngularParams_${propertyKey}`] ||= []
    target[`__AngularParams_${propertyKey}`].push(parameterIndex)
  }
}

export function HaveAngularParams() {
  return (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) => {
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, propertyKey)
    }

    const original = descriptor.value
    descriptor.value = function(...args) {
      const propertyIndex = target[`__AngularParams_${propertyKey}`]
      args = args.map((arg, i) => propertyIndex?.includes(i) ? qsParams(arg) : arg)
      return original.call(this, ...args)
    }
  }
}

function qsParams(params: Dictionary<any>) {
  return new HttpParams({
    fromString: stringify(params),
  })
}
