import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { ImportLogView, Receipt, ShipmentRate, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { environment } from 'src/environments/environment'

export default module =>
  module.service('ImportApi', downgradeInjectable(ImportApiService))

@Injectable()
export class ImportApiService {
  constructor(private http: HttpClient) { }

  importShipmentRates(rates: DeepReadonly<Partial<ShipmentRate>[]>, content: string, filename: string) {
    return this.http.post<void>(`${environment.apiUrl}/import-csv/shipment-rates`, { csv: { filename, content }, rows: rates.map((rate, row) => ({ row, rate })) })
  }

  importReceipts(receipts: DeepReadonly<Partial<Receipt>[]>, content: string, filename: string) {
    return this.http.post<void>(`${environment.apiUrl}/import-csv/receipts`, { csv: { filename, content }, rows: receipts.map((receipt, row) => ({ row, receipt })) })
  }

  importVendorCredits(vendorCredits: DeepReadonly<Partial<VendorCredit>[]>, content: string, filename: string) {
    return this.http.post<void>(`${environment.apiUrl}/import-csv/vendor-credits`, { csv: { filename, content }, rows: vendorCredits.map((vendorCredit, row) => ({ row, vendorCredit })) })
  }

  esSearch(payload) {
    return this.http.post<{ total: number, hits: ImportLogView[] }>(`${environment.apiUrl}/search/import-logs`, payload)
  }

  esSearchFilters(query) {
    return this.http.post<Dictionary<any>>(`${environment.apiUrl}/search/import-logs/filters`, query)
  }
}
