<mat-form-field>
  <mat-label>Deal Creation Limit</mat-label>
  <input readonly matInput [value]="creditPool ? (creditPool.deal_creation_max | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>Submitted Balance</mat-label>
  <input readonly matInput [value]="balance && creditPool ? (balance.creation.balance | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>Creation Credit Available</mat-label>
  <input readonly matInput [value]="balance && creditPool ? ((creditPool.deal_creation_max - balance.creation.balance) | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>Client Credit Limit</mat-label>
  <input readonly matInput [value]="creditPool ? (creditPool.max | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>Delivered Balance</mat-label>
  <input readonly matInput [value]="balance && creditPool  ? (balance.delivered.balance | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>Credit Available</mat-label>
  <input readonly matInput [value]="balance && creditPool ? ((creditPool.max - balance.delivered.balance) | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>In Intransit Credit Limit</mat-label>
  <input readonly matInput [value]="creditPool ? (creditPool.in_transit_max | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>In Transit Balance</mat-label>
  <input readonly matInput [value]="balance && creditPool  ? (balance.in_transit.balance | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field>
  <mat-label>In Transit Credit Available</mat-label>
  <input readonly matInput [value]="balance && creditPool ? ((creditPool.in_transit_max - balance.in_transit.balance) | currency:creditPool.currency.code:'symbol-narrow') : ''">
</mat-form-field>
<mat-form-field gdColumn="span 3">
  <mat-label>System Message</mat-label>
  <input readonly matInput>
</mat-form-field>
