import { keyBy } from 'lodash-es'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { Component, OnInit } from '@angular/core'
import {
  OnDestroyMixin,
  untilComponentDestroyed,
} from '@w11k/ngx-componentdestroyed'
import { ModalService } from 'src/shared/modal'
import { AddBuyersGroupComponent } from './add-buyers-group/add-buyers-group.component'
import {
  BuyersGroup,
  BuyersGroupApiService,
} from './buyers-groups-item/buyers-groups.service'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { sortBy } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { Store, select } from '@ngrx/store'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import {
  ACCOUNT_ACTIVE,
  ACCOUNT_RESTRICTED,
  AccountObject,
  AccountType,
} from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Roles } from 'src/constants/role'

@Component({
  selector: 'tc-buyers-groups-page',
  templateUrl: './buyers-groups-page.component.html',
  styleUrls: ['./buyers-groups-page.component.scss'],
})
export class BuyersGroupsPageComponent extends OnDestroyMixin
  implements OnInit {
  buyersMap: Dictionary<DeepReadonly<AccountObject>>
  buyers: DeepReadonly<AccountObject>[]
  buyers$ = this.store.pipe(
    select(selectAllAccounts),
    waitNotEmpty(),
    map((accounts) =>
      accounts
        .filter((ac) => !ac.archived && ac.status === ACCOUNT_ACTIVE && ac.type === AccountType.BUYER)
        .map((buyer) => ({
          id: String(buyer.account),
          ...buyer,
          status: [buyer.manager, ...(buyer.managers || [])].includes(
            this.AuthApi.currentUser.user_id,
          )
            ? ACCOUNT_ACTIVE
            : [Roles.manager.id, Roles.superuser.id, Roles.administrator.id].includes(this.AuthApi.currentUser.role) ? ACCOUNT_ACTIVE : ACCOUNT_RESTRICTED,
        })),
    ),
    map((accounts) => sortBy(accounts, (ac) => ac.name?.toLowerCase())),
    untilComponentDestroyed(this),
  )
  groups: BuyersGroup[] = []
  loading: boolean = true
  constructor(
    private AuthApi: AuthApiService,
    private store: Store,
    private toaster: ToasterService,
    private modal: ModalService,
    private buyersGroupService: BuyersGroupApiService,
  ) {
    super()
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(loadAccounts({}))
    combineLatest([
      this.buyers$,
      this.buyersGroupService.list().catch((err) => {
        this.toaster.error('Unable to load buyers groups.')
        throw err
      }),
    ])
      .pipe(untilComponentDestroyed(this))
      .subscribe(([buyers, groups]) => {
        this.buyersMap = keyBy(buyers, 'id')
        this.buyers = buyers.filter((buyer) => buyer.status === ACCOUNT_ACTIVE)
        this.groups = groups
        this.loading = false
      })
  }

  addNewGroup() {
    this.modal.openDialog(AddBuyersGroupComponent, {
      buyers: this.buyers,
      groups: this.groups,
    })
  }

  async deleteGroup(group: BuyersGroup) {
    try {
      await this.buyersGroupService.delete(group.group_id)
      this.groups = this.groups.filter((g) => group.group_id !== g.group_id)
      this.toaster.success(
        `Buyer Group "${group.name}" was removed successfully.`,
      )
    } catch {
      this.toaster.error(`Unable to remove Buyer Group "${group.name}"`)
    }
  }

  async updateGroup(event: { group: BuyersGroup; callback: Function }) {
    const { group, callback } = event
    try {
      await this.buyersGroupService.update(group)
      this.toaster.success(
        `Buyer Group "${group.name}" was updated successfully.`,
      )
      callback()
    } catch {
      this.toaster.error(`Unable to update Buyer Group: ${group.name}.`)
    }
  }
}
