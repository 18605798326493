import { createAction, props } from '@ngrx/store'
import { DocumentOverride, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'


export const loadDocumentOverrides = createAction('[DocumentOverride/API] Load DocumentOverrides', props<{ tableKey?: TableKey }>())
export const loadDocumentOverridesSuccess = createAction('[DocumentOverride/API] Load DocumentOverrides Success', props<{ tableKey?: TableKey, documentOverrides: DocumentOverride[] }>())
export const loadDocumentOverridesFailure = createAction('[DocumentOverride/API] Load DocumentOverrides Failure', props<{ error: Error }>())

export const createDocumentOverride = createAction('[DocumentOverride/API] Create Document Override', props<{ override: DeepReadonly<Partial<DocumentOverride>> }>())
export const createDocumentOverrideSuccess = createAction('[DocumentOverride/API] Create Document Override Success', props<{ override: DocumentOverride }>())
export const createDocumentOverrideFailure = createAction('[DocumentOverride/API] Create Document Override Failure', props<{ error: Error }>())

export const updateDocumentOverride = createAction('[DocumentOverride/API] Update Document Override', props<{ override: DeepReadonly<Partial<DocumentOverride>>; id: string }>())
export const updateDocumentOverrideSuccess = createAction('[DocumentOverride/API] Update Document Override Success', props<{ override: DocumentOverride }>())
export const updateDocumentOverrideFailure = createAction('[DocumentOverride/API] Update Document Override Failure', props<{ error: Error }>())

export const deleteDocumentOverride = createAction('[DocumentOverride/API] Delete Document Override', props<{ id: string }>())
export const deleteDocumentOverrideSuccess = createAction('[DocumentOverride/API] Delete Document Override Success', props<{ id: string }>())
export const deleteDocumentOverrideFailure = createAction('[DocumentOverride/API] Delete Document Override Failure', props<{ error: Error }>())
