import { MatSort } from '@angular/material/sort'
import { AccountObject, AccountType, BUYER, Carrier, DealViewRaw, FileObject, FileObjectDeringerStatus, SERVICE_PROVIDER, SUPPLIER, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { map as _map, compact, flatten, isEqual, keyBy, pick } from 'lodash-es'
import { Observable, combineLatest, from } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { FileRow } from 'src/api/file'
import { BytesFilter } from 'src/filters/bytes.filter'
import { canBeVisible, isUnknownType } from 'src/pages/admin/trading/deals/deal-documents/documents-acl.util'
import { FilesService, getFileFormat } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { getDealParties } from 'src/services/data/deal-view.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { TypedFormGroup } from 'src/shared/utils/typed-forms'
import { SimpleDataSource } from './simple-data-source'


export class DealFilesDataSource extends SimpleDataSource<FileRow> {
  constructor (
    dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>,
    accounts$: Observable<DeepReadonly<Dictionary<AccountObject>>>,
    users$: Observable<DeepReadonly<Dictionary<User>>>,
    carriers$: Observable<DeepReadonly<Dictionary<Carrier>>>,
    Files: FilesService,
    filtersForm: TypedFormGroup<{ party: Array<AccountType|'other'> }>,
  ) {
    const pdfs$ = dealViewRaw$.pipe(
      map(dv => compact(flatten(dv.files?.map(f => f.attributes.pdf_version_file_id))).sort()),
      distinctUntilChanged(isEqual),
      switchMap(pdfIds => from(Files.getFilesByIds(pdfIds).then(all => keyBy(all, 'file_id')))))
    super(combineLatest([
      dealViewRaw$, pdfs$, accounts$, carriers$, users$, replayForm(filtersForm),
    ]).pipe(map(([dv, pdfs, accounts, carriers, users, filters]) => {
      let files = dv.files || []
      files = files.filter(file => !file.archived)
      if (filters.party?.length) {
        // tslint:disable-next-line: cyclomatic-complexity
        files = files.filter((file: FileObject) => {
          if (!filters.party.length) return true // show all if no types selected
          const docType = file.attributes?.document_type
          if (isUnknownType(docType)) {
            return filters.party.includes('other')
          }
          if (filters.party.includes(SUPPLIER) && canBeVisible(docType, SUPPLIER)) {
            return true
          }
          if (filters.party.includes(BUYER) && canBeVisible(docType, BUYER)) {
            return true
          }
          if (filters.party.includes(SERVICE_PROVIDER) && canBeVisible(docType, SERVICE_PROVIDER)) {
            return true
          }
          return false
        })
      }
      return files.map(file => buildFileRow(file, dv, pdfs, accounts, users, carriers))
    })))
  }

  setSort(sort: MatSort) {
    if (!sort || sort === this.sort) return
    super.setSort(sort)
    sort.sort({ id: 'created', start: 'desc', disableClear: false })
  }
}

// tslint:disable-next-line: cyclomatic-complexity
function buildFileRow(
  file: DeepReadonly<FileObject>,
  dv: DeepReadonly<DealViewRaw>,
  pdfs: DeepReadonly<Dictionary<FileObject>>,
  accounts: DeepReadonly<Dictionary<AccountObject>>,
  users: DeepReadonly<Dictionary<User>>,
  carriers: DeepReadonly<Dictionary<Carrier>>,
) {
  const filename = file.name.split('.')
  const extension = filename.length > 1 ? filename.pop() : undefined
  const pdf = pdfs[file.attributes.pdf_version_file_id]

  let canAddNote = false
  let canReject = false
  let canApprove = false
  let statusAt: number
  let statusBy: string
  let deringerStatus: FileRow['viewEx']['deringerStatus'] = file.deringer?.status

  if (deringerStatus) {
    const status = file.deringer?.status
    canAddNote = true
    if (status === FileObjectDeringerStatus.approved) {
      statusAt = file.deringer.approved_at
      statusBy = users[file.deringer.approved_by]?.fullname
      canReject = true
    } else if (status === FileObjectDeringerStatus.rejected) {
      statusAt = file.deringer.rejected_at
      statusBy = users[file.deringer.rejected_by]?.fullname
      canApprove = true
    } else {
      canReject = true
      canApprove = true
    }
  }
  if (file.deringer?.te_sent) {
    deringerStatus = 'sent'
    statusAt = file.deringer.te_sent
  }
  if (pdf?.deringer?.te_sent) {
    deringerStatus = 'sent'
    statusAt = pdf.deringer.te_sent
  }

  const row: FileRow = {
    ...file,
    view: {
      parties: getDealParties(dv, accounts, carriers) as any,
      company: accounts[file.attributes.deal_party || file.account] as any,
    } as Partial<FileRow['view']> as FileRow['view'],
    viewEx: {
      canAddNote,
      canReject,
      canApprove,
      deringerStatus,
      user: users[file.user_id],
      statusAt,
      statusBy,
      fileName: filename.join('.'),
      fileExt: extension,
      format: getFileFormat(file),
      readers: _map(pick(accounts, file.attributes.readers), 'name').join(', '),
      size: BytesFilter()(file.size),
      note_warning: dv.notes?.find(f => f.attributes.category === file.file_id),
    },
  }
  return row
}
