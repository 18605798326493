import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { LetDirective } from '@ngrx/component'
import { ModalModule } from 'src/shared/modal'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { AesResponseReviewDialogComponent } from './aes-response-review-dialog.component'
import { AesResponseReviewDialogService } from './aes-response-review-dialog.service'


@NgModule({
  declarations: [
    AesResponseReviewDialogComponent,
  ],
  providers: [AesResponseReviewDialogService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    ModalModule,
    PipesModule,
    LetDirective,
  ],
})
export class AesResponseReviewDialogModule { }
