import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: 'bytes',
  pure: true
})
export class BytesPipe implements PipeTransform {
  transform(bytes: number, precision?: number) {
    return formatBytes(bytes, precision)
  }
}


// @see https://gist.github.com/thomseddon/3511330
export function formatBytes(bytes: number, precision?: number) {
  if (isNaN(parseFloat(bytes as any as string)) || !isFinite(bytes)) return '-'
  if (typeof precision === 'undefined') precision = 1
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']
  const number = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number]
}
