import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'tc-drop-zone',
  template: `
    <input #input type="file" class="drop-file" multiple (change)="onChange()">
    <button mat-button class="drop-button" type="button" (click)="input.click()">Drag & Drop files</button>
  `,
  styles: `
    :host {
      background-color: #f5f5f5;
      border: dotted 3px lightgray;
      border-radius: 5px;
      position: relative;
      display: grid;
      align-content: center;
      justify-items: center;

      &.file-over {
        border: dotted 3px green;
      }
    }

    .drop-file {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropzoneComponent {
  @Output() changed = new EventEmitter<File[]>()

  @HostBinding('class.file-over') protected fileover: boolean;
  @ViewChild('input') protected input: ElementRef<HTMLInputElement>

  @HostListener('dragover', ['$event'])
  protected onDragover(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.fileover = true
  }

  @HostListener('dragleave', ['$event'])
  protected onDragleave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.fileover = false;
  }

  @HostListener('drop', ['$event'])
  protected onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.fileover = false;
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.input.nativeElement.files = files
      this.onChange()
    }
  }

  protected onChange() {
    const files: File[] = []
    for (let i = 0; i < this.input.nativeElement.files.length; i++) {
      files.push(this.input.nativeElement.files.item(i))
    }
    this.changed.emit(files)
  }
}
