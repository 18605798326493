import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { DealSpecsComponent } from './deal-specs.component'


@NgModule({
  declarations: [DealSpecsComponent],
  exports: [DealSpecsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    PipesModule,
    SelectSearchModule,
  ],
})
export class DealSpecsModule { }
