import { Injectable } from '@angular/core'
import { AccountObject, DEAL_CANCELED, DEAL_DELIVERED, DEAL_INVOICED, Deal, DealBase, DealDateField, DealRateRangeField, DealRow, DealSimpleBooleanField, DealStatus, DealViewRaw, DealViewRawDeal, DealViewRawInvoices, DealViewRawSegments, DealViewShipment, IEpochRange, Segment } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, findBrokerageInvoice, findBuyerInvoice, isInvoicePaid } from '@tradecafe/types/utils'
import { cloneDeep, compact, get, isEqual, map, reject, set, sortBy, uniq, without } from 'lodash-es'
import { Observable, combineLatest, from } from 'rxjs'
import { map as _map, catchError, switchMap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { DealApiService } from 'src/api/deal'
import { OperationsApiService } from 'src/api/operations'
import { environment } from 'src/environments/environment'
import { DealFormDto, SegmentFormDto } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { DealFormService, prepareDealSegmentPatch } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { DealsService } from 'src/services/data/deals.service'
import { DealValidationService } from '../actions/deal-validation.service'
import { dayjs } from '../dayjs'
import { AccountsService } from './accounts.service'
import { BidsService } from './bids.service'
import { DealElasticSearchService } from './deal-elastic.service'
import { DealFormCalculatorService } from './deal-form-calculator.service'
import { DealViewPermissionsService } from './deal-view-permissions.service'
import { copySegment } from './deal-view.service'
import { InvoicesService } from './invoices.service'
import { OffersService } from './offers.service'
import { SegmentsService } from './segments.service'
import { ShipmentsService } from './shipments.service'

/**
 * Deals service
 *
 * @export
 */
@Injectable()
export class DealViewSaver2Service {

  constructor (
    private Deals: DealsService,
    private DealApi: DealApiService,
    private DealForm: DealFormService,
    private AuthApi: AuthApiService,
    private OperationsApi: OperationsApiService,
    private Accounts: AccountsService,
    private Bids: BidsService,
    private Offers: OffersService,
    private Segments: SegmentsService,
    private Shipments: ShipmentsService,
    private DealFormCalculator: DealFormCalculatorService,
    private elastic: DealElasticSearchService,
    private DealValidation: DealValidationService,
    private Invoices: InvoicesService,
    private DealViewPermissions: DealViewPermissionsService,
  ) { }

  startWorkingOn(deals: DeepReadonly<DealBase>[]): Observable<Deal[]> {
    deals = reject(deals, 'attributes.in_progress')
    return from(Promise.all(deals.map(deal =>
      this.Deals.patchImmutableDeal(deal,
        { attributes: { in_progress: dayjs().unix() }},
        { skip_calculations: 1 }))))
  }

  setDealsColor(deals: DeepReadonly<DealBase>[], color: string): Observable<Deal[]> {
    return from(Promise.all(deals.map(deal =>
      this.Deals.patchImmutableDeal(deal,
        { attributes: { extra: { color }}},
        { skip_calculations: 1 }))))
  }

  toggleDealFlag(deal: DeepReadonly<DealBase>, fieldName: DealSimpleBooleanField): Observable<Deal> {
    return from(this.Deals.patchImmutableDeal(deal,
      set({}, fieldName, !get(deal, fieldName, false)),
      { skip_calculations: 1 }))
  }

  togglePortalAccess(deal: DeepReadonly<DealBase>, account: number): Observable<Deal> {
    return from(this.Deals.hasDataConflicts(deal)).pipe(
      switchMap(freshDeal => {
        if (freshDeal) deal = freshDeal

        const attributes = cloneDeep((deal as DealBase).attributes)
        // TODO: remove safeguard after deal_parties[].account will become number
        if (typeof account === 'string') account = parseFloat(account) // safeguard code
        // tslint:disable-next-line: deprecation
        delete attributes.portal_visible
        attributes.portal_access = attributes.portal_access || []
        if (attributes.portal_access.includes(account)) attributes.portal_access = without(attributes.portal_access, account)
        else attributes.portal_access.push(account)
        // TODO: remove parseFloat mapping after /deals/search:buyer.account will become string
        attributes.portal_access = (attributes.portal_access as any as string[]).map(parseFloat)
        return from(this.Deals.updateDeal({ deal_id: deal.deal_id, attributes }, { skip_calculations: 1 }))
      }),
    )
  }

  setDealTextField(immutableDeal: DeepReadonly<DealBase>, fieldName: string, text: string): Observable<Deal> {
    const deal = cloneDeep(immutableDeal) as DealBase
    const patch: DeepPartial<DealBase> = {}
    set(patch, fieldName, text)
    return from(this.Deals.patchImmutableDeal(deal, patch, { skip_calculations: 1 }))
  }

  setDealConsignee(immutableDeal: DeepReadonly<DealBase>, address: any): Observable<Deal> {
    const deal = cloneDeep(immutableDeal) as DealBase
    const patch: DeepPartial<DealBase> = {}
    set(patch, 'attributes.shipment.delivery.consignee', address)
    set(patch, 'attributes.inalfresco', !!(address && address.inalfresco))
    return from(this.Deals.patchImmutableDeal(deal, patch, { skip_calculations: 1 }))
  }

  setDealShipper(immutableDeal: DeepReadonly<DealBase>, address: any): Observable<Deal> {
    const deal = cloneDeep(immutableDeal) as DealBase
    const patch: DeepPartial<DealBase> = {}
    set(patch, 'attributes.shipment.shipper', address)
    return from(this.Deals.patchImmutableDeal(deal, patch, { skip_calculations: 1 }))
  }

  setDealStatusNotes(immutableDeal: DeepReadonly<DealBase>, status_notes: string[]): Observable<DealRow> {
    const deal = cloneDeep(immutableDeal) as DealBase
    const patch: DeepPartial<DealBase> = { attributes: { status_notes }}
    return from(this.Deals.patchImmutableDeal(deal, patch, { skip_calculations: 1 }))
    .pipe(switchMap(() => this.elastic.fetchDeal(deal.deal_id)))
  }

  /**
   * Update deal status
   *
   * @param {DealViewRaw} deal
   * @param {any} status
   */
  async setDealStatus(dealViewRaw: DeepReadonly<DealViewRaw>, status: string): Promise<Deal> {
    let { deal } = dealViewRaw
    let result: Deal
    const sameStatus = deal.status === status
    const previousStatus = DealStatus[deal.status].num > DealStatus[status].num && status !== DEAL_CANCELED
    const canChangeStatus = !sameStatus && (!previousStatus || this.DealViewPermissions.canChangeStatusTo(deal, status))
    // prevent setting status to the same value as it is right now
    // prevent status rollback (eg. delivered deal should never be set to "invoiced" again)
    if (canChangeStatus) {
      if (status === DEAL_INVOICED) {
        await this.DealValidation.checkBuyerCredit(deal, `Deal # ${deal.deal_id} is above Buyers credit limit. Get credit override from management.`, 'in_transit')
      }
      deal = result = await this.Deals.patchImmutableDeal(deal, { status })
    }
    if (status === DEAL_INVOICED) {
      if (environment.enableBrokerageDeals && deal.deal_type === 'brokerage') {
        await this.createBrokerageInvoice(dealViewRaw)
      } else {
        await this.createBuyerInvoice({ ...dealViewRaw, deal })
      }
    }
    if (canChangeStatus) {
      const closed = await this.tryToCloseDeal({ ...dealViewRaw, deal })
      if (closed) result = closed
    }
    return result
  }

  // WA-2881: try to close this deal
  private tryToCloseDeal(dealViewRaw: DeepReadonly<DealViewRawDeal&DealViewRawInvoices>): Promise<Deal> {
    const { deal } = dealViewRaw
    if (deal.status !== DEAL_DELIVERED) return undefined
    if (environment.enableBrokerageDeals && deal.deal_type === 'brokerage') {
      const brokerageInvoice = findBrokerageInvoice(dealViewRaw.invoices)
      if (!isInvoicePaid(brokerageInvoice)) return undefined
    } else {
      const buyerInvoice = findBuyerInvoice(dealViewRaw.invoices, deal.buyer_id)
      if (!isInvoicePaid(buyerInvoice)) return undefined
    }
    return this.OperationsApi.closeDeal(deal.deal_id).then(r => r.data)
    // this.toaster.success(`Deal ${deal.deal_id} is closed successfully!`)
  }

  /**
   * Create buyer invoice if it doesn't exist
   *
   * @param {any} deal - Deal View object (with invoices)
   * @returns buyer invoice (new or existing)
   */
  private async createBuyerInvoice(dv: DeepReadonly<DealViewRaw>): Promise<void> {
    if (environment.enableBrokerageDeals && dv.deal.deal_type === 'brokerage') return
    await this.OperationsApi.createBuyerInvoice(dv.deal.deal_id)
  }

  /**
   * Create buyer invoice if it doesn't exist
   *
   * @param {any} deal - Deal View object (with invoices)
   * @returns buyer invoice (new or existing)
   */
  private async createBrokerageInvoice(dv: DeepReadonly<DealViewRaw>): Promise<void> {
    if (dv.deal.deal_type !== 'brokerage') return
    await this.OperationsApi.createBrokerageInvoice(dv.deal.deal_id)
  }

  unwindDeal(deal_id: string): Observable<any> {
    return from(this.Deals.unwindDeal(deal_id))
  }

  toggleInalfrescoFlag(deal: DeepReadonly<DealBase>): Observable<Deal> {
    return from(this.toggleInalfrescoFlagAsync(deal))
  }

  private async toggleInalfrescoFlagAsync(deal: DeepReadonly<DealBase>): Promise<Deal> {
    const patch: DeepPartial<DealBase> = { attributes: {
      inalfresco: !deal.attributes.inalfresco,
      shipment: { delivery: {} },
    } }
    if (!patch.attributes.inalfresco) {
      patch.attributes.shipment.delivery.consignee = undefined
    } else {
      const {account} = this.AuthApi.currentUser
      const bwi = await this.Accounts.getAccountById(account)
      const inalfresco = bwi.addresses.find(a => a.inalfresco)
      patch.attributes.shipment.delivery.consignee = inalfresco
    }
    return this.Deals.patchImmutableDeal(deal, patch, { skip_calculations: 1 })
  }

  setDealDateRange(immutableDeal: DeepReadonly<DealBase>, fieldName: DealRateRangeField, range: IEpochRange): Observable<Deal> {
    const deal = cloneDeep(immutableDeal) as DealBase
    const { deal_id } = deal
    const patch: DeepPartial<DealBase> = {}
    if (!range.tbd) range.tbd = ''
    set(patch, fieldName, range)
    // NOTE: can't skip calculations. deal shipment/delivery dates can affect interest and other costs
    return from(this.Deals.patchImmutableDeal(immutableDeal, patch/* , { skip_calculations: 1 } */))
  }

  setSegmentDate(
    deal: DeepReadonly<DealRow>,
    segment: DeepReadonly<Segment>,
    segmentPatch: DeepReadonly<Partial<SegmentFormDto>>,
    accounts: DeepReadonly<Dictionary<AccountObject>>,
  ): Observable<Deal> {
    // NOTE: this details object is incomplete. deal products and costs are empty as they are not needed here
    const details = this.DealForm.prepareDealTermsAndDatesPatch(deal.raw)
    const segments = deal.segments.map((s, i) => this.DealForm.prepareDealSegmentPatch(s, i))
    const segmentForm = prepareDealSegmentPatch(segment, deal.segments.indexOf(segment))
    const supplier = accounts[deal.supplier_id]
    const buyer = accounts[deal.buyer_id]
    const detailsPatch = this.DealFormCalculator.calculateTermDateFromSegmentFormImmutable(details, segments, deal.invoices, segmentForm, supplier, buyer, Object.keys(segmentPatch)[0] as any) || {}
    // TODO: WA-9473: move this.Deals.calculateShippingDates to the backend
    return this.Deals.storeDeal(deal, {
      updated: deal.updated,
      details: {
        deal_id: deal.deal_id,
        ...detailsPatch,
      },
      segments: deal.segments.map(({ segment_id }) =>
        segment_id === segment.segment_id
          ? { ...segmentPatch, segment_id} // patch segment
          : { segment_id }) // keep segment alive
    })
  }

  setDealDate(deal: DeepReadonly<DealBase>, fieldName: DealDateField, date: number, recalculate?: boolean): Observable<Deal> {
    return from(this.setDealDateAsync(deal, fieldName, date, recalculate))
  }

  private async setDealDateAsync(
    immutableDeal: DeepReadonly<DealBase>,
    fieldName: DealDateField,
    date: number,
    recalculate?: boolean,
  ): Promise<Deal> {
    const patch: DeepPartial<DealBase> = set({}, fieldName, date)
    // NOTE: can't skip calculations. deal shipment/delivery dates can affect interest and other costs
    return this.Deals.patchImmutableDeal(immutableDeal, patch, recalculate ? { skip_calculations: 1 } : undefined)
  }

  setBookingId(deal: DeepReadonly<DealRow>, bookingId: string) {
    return from(this.setBookingIdAsync(deal, bookingId))
  }

  private async setBookingIdAsync(deal: DeepReadonly<DealRow>, booking_id: string) {
    const {carrier_id} = deal.earliestVesselOrTruckSegment
    const segments = deal.segments.filter(s => s.carrier_id === carrier_id)
    await Promise.all(segments.map(({ segment_id }) =>
      this.Segments.update({ segment_id, booking_id })))
    return this.elastic.fetchDeal(deal.deal_id).toPromise()
  }

  setSegmentBasicField(deal: DeepReadonly<DealRow>, fieldName: string, text: string| any) {
    return from(this.setSegmentBasicFieldAsync(deal, fieldName, text))
  }

  private async setSegmentBasicFieldAsync(deal: DeepReadonly<DealRow>, fieldName: string, text: string|any) {
    const [segmentName, ...fieldPath] = fieldName.split('.')
    if (!deal[segmentName]) throw new Error(`setSegmentBasicField: segment "${fieldName}" not found`)
    fieldName = fieldPath.join('.')
    const segment = cloneDeep(deal[segmentName]) as Segment
    set(segment, fieldName, text)
    await this.Segments.update(segment)
    return this.elastic.fetchDeal(deal.deal_id).toPromise()
  }

  setItemTypeId(deal: DeepReadonly<DealBase>, itemTypeId: string) {
    return from(this.setItemTypeIdAsync(deal, itemTypeId))
  }

  setOriginLocation(deal: DeepReadonly<DealBase>, originLocationId: string) {
    return this.Deals.storeDeal(deal, {
      updated: deal.updated,
      details: { deal_id: deal.deal_id, originLocationId }
    })
  }

  setDestLocation(deal: DeepReadonly<DealBase>, destLocationId: string) {
    return this.Deals.storeDeal(deal, {
      updated: deal.updated,
      details: { deal_id: deal.deal_id, destLocationId }
    })
  }

  private async setItemTypeIdAsync(deal: DeepReadonly<DealBase>, itemTypeId: string) {
    const [offer, bid] = await Promise.all([
      this.Offers.getById(deal.offers[0]),
      this.Bids.getById(deal.bids[0]),
    ])
    offer.attributes.item_type_id = bid.attributes.item_type_id = itemTypeId

    await Promise.all([
      this.Offers.update({ offer_id: offer.offer_id, attributes: offer.attributes }),
      this.Bids.update({ bid_id: bid.bid_id, attributes: bid.attributes }),
    ])
    return this.elastic.fetchDeal(deal.deal_id).toPromise()
  }

  setContainerId(deal: DeepReadonly<DealRow>, containerId: string) {
    return from(this.setContainerIdAsync(deal, containerId))
  }

  private async setContainerIdAsync(deal: DeepReadonly<DealRow>, containerId: string) {
    const segment = deal.earliestVesselOrTruckSegment as Segment
    const { attributes, segment_id } = cloneDeep(segment)
    if (attributes.container_number !== containerId) {
      attributes.container_number = containerId
      await this.Segments.update({ attributes, segment_id })
      deal = await this.elastic.fetchDeal(deal.deal_id).toPromise()
      await this.storeShipmentContainers(deal)
    }
    return deal
  }

  setMexInvNo(deal: DeepReadonly<DealRow>, mexInvNo: string) {
    const foreignInvoices = cloneDeep(deal.foreign_invoices) as DealBase['foreign_invoices'] || []
    let mxInvoice = foreignInvoices.find(i => i.country_code === 'MX')
    if (!mxInvoice) foreignInvoices.push({ country_code: 'MX', number: mexInvNo })
    else mxInvoice.number = mexInvNo

    return this.Deals.storeDeal(deal, {
      updated: deal.updated,
      details: { deal_id: deal.deal_id, foreignInvoices }
    }).pipe(switchMap(() => this.elastic.fetchDeal(deal.deal_id)))
  }

  private async storeShipmentContainers(deal: DeepReadonly<DealRow>) {
    const segmentContainers = uniq(compact(map(deal.segments, 'attributes.container_number'))).sort()
    const shipment = await this.Shipments.getByDeal(deal.deal_id)
    const definedContainers = uniq(compact(map(shipment.containers, 'container_number'))).sort()
    if (isEqual(segmentContainers, definedContainers)) return
    shipment.containers = segmentContainers.map(container_number => ({
      container_number,
      size: 1,
      weight: 1,
    }))
    await this.Shipments.update(shipment)
  }

  copySegments(
    source: DeepReadonly<DealViewRawDeal & DealViewRawSegments>,
    destination: DeepReadonly<Array<DealViewRawDeal & DealViewRawSegments & DealViewShipment>>,
    deleteSegments: boolean,
    exactCopy: boolean,
  ): Observable<Deal[]> {
    const actions = destination.map(dv => {
      const dealForm: Partial<DealFormDto> = {
        updated: dv.deal.updated,
        details: { deal_id: dv.deal.deal_id },
        segments: []
      }
      // keep old segments
      if (!deleteSegments && dv.segments?.length) {
        sortBy(dv.segments, 'order').forEach(s => dealForm.segments.push({ segment_id: s.segment_id }))
      }

      // add new segments
      sortBy(source.segments, 'order').forEach(s => {
        const newSegment = copySegment(s, {
          mode: exactCopy ? 'exact' : 'default',
        })
        const { segment: { segment_id }, ...segmentForm } = prepareDealSegmentPatch(newSegment, dealForm.segments.length)
        dealForm.segments.push({ ...segmentForm, segment_id })
      })

      // update segments order
      dealForm.segments.forEach((segment, index) => {
        segment.order = index
      })
      // copy shipment infor stored in the "source" deal
      if (exactCopy) {
        dealForm.details.shipmentErd = source.deal.attributes.shipment?.erd_date
        dealForm.details.shipmentEpd = source.deal.attributes.shipment?.epd_date
        dealForm.details.shipmentVoyageNo = source.deal.attributes.shipment?.voyage_no
        // TODO: WA-7300: this.Deals.calculateShippingDates(deal)
      }

      return { deal: dv.deal, dealForm }
    })

    return combineLatest(actions.map(a => this.Deals.storeDeal(a.deal, a.dealForm)))
  }

  lockDeal(deal: DeepReadonly<DealBase>, lock: boolean): Observable<Deal> {
    if (lock) return this.Deals.lockDeal(deal)
    else return this.Deals.unlockDeal(deal)
  }

  copyClone(dealId: string, count: number): Observable<Deal[]> {
    return this.DealApi.copyClone(dealId, count).pipe(
      _map(({ data: deals }) => deals),
      catchError((err) => { throw err.error })
    )
  }
}
