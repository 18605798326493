import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTableModule } from '@angular/material/table'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ModalModule } from 'src/shared/modal'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { AddressPipe } from '../../filters/address.pipe'
import { EpochPipe } from '../../filters/epoch.pipe'
import { AddressFieldModule } from '../address-field/address-field.module'
import { ChipsInputModule } from '../chips-input/chips-input.module'
import { EpochFieldModule } from '../epoch/epoch-field/epoch-field.module'
import { NotesOverlayModule } from '../notes/notes-overlay/notes-overlay.module'
import { SelectSearchModule } from '../select-search/select-search.module'
import { TypeSafeMatCellDefModule } from '../type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { SegmentFormRegularComponent } from './segment-form-regular/segment-form-regular.component'
import { SegmentFormRemoveService } from './segment-form-remove.service'
import { SegmentFormWarehouseComponent } from './segment-form-warehouse/segment-form-warehouse.component'
import { SegmentFormComponent } from './segment-form.component'
import { SegmentFormService } from './segment-form.service'
import { SegmentNotificationFormService } from './segment-notification-form/segment-notification-form.service'
import { SegmentNotificationFormComponent } from './segment-notification-form/segment-notification-form.component'
import { SwapServiceProviderDialogModule } from 'src/pages/admin/logistics/shipping-details-page/shipping-details-form/swap-service-provider-overlay/swap-service-provider-dialog.module'


@NgModule({
  declarations: [SegmentFormRegularComponent, SegmentFormWarehouseComponent, SegmentFormComponent, SegmentNotificationFormComponent],
  exports: [SegmentFormRegularComponent, SegmentFormWarehouseComponent, SegmentFormComponent],
  providers: [SegmentFormService, SegmentFormRemoveService, SegmentNotificationFormService, EpochPipe, AddressPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FlexLayoutModule,
    EpochFieldModule,
    SelectSearchModule,
    PipesModule,
    ModalModule,
    AddressFieldModule,
    MatCheckboxModule,
    MatTableModule,
    TypeSafeMatCellDefModule,
    ChipsInputModule,
    NotesOverlayModule,
    LocksModule,
    SwapServiceProviderDialogModule
  ],
})
export class SegmentFormModule {
}
