import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CreditPool } from '@tradecafe/types/core'

@Component({
  selector: 'tc-credit-balance',
  templateUrl: './credit-balance.component.html',
  styleUrls: ['./credit-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tc-credit-balance',
  },
})
export class CreditBalanceComponent {
  @Input() creditPool: CreditPool
  @Input() balance: any
}
