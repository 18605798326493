import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { MatchedOffer } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { map } from 'rxjs/operators'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { auctioneerApiUrl } = environment


export default module => module
.service('MatchedOfferApi', downgradeInjectable(MatchedOfferApiService))


@Injectable()
@AngularCopy()
export class MatchedOfferApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: MatchedOffer[] }>(`${auctioneerApiUrl}/matched_offers`, { params }).toPromise()
  }

  filter(query) {
    return this.http.post<{ data: MatchedOffer[] }>(`${auctioneerApiUrl}/matched_offers/filter`, query).toPromise()
  }

  update(id: string, data: DeepReadonly<Partial<MatchedOffer>>) {
    return this.http.put<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/${id}`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/${id}`).toPromise()
  }

  @HaveAngularParams()
  getTotal(@AngularParams() params) {
    return this.http.get<{ total: number }>(`${auctioneerApiUrl}/matched_offers/total`, { params })
      .pipe(map(r => r.total)).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/${id}`).toPromise()
  }

  cancel(matched_offer_ids: string[]) {
    return this.http.post<{ modified: number, errors: unknown[] }>(`${auctioneerApiUrl}/matched_offers/cancel`, { matched_offer_ids }).toPromise()
  }

  accept(matched_offer_id: string) {
    return this.http.post<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/accept`, { matched_offer_id }).toPromise()
  }

  reopen(matched_offer_id: string) {
    return this.http.post<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/${matched_offer_id}/reopen`, {}).toPromise()
  }

  generateOfferNumber(matched_offer_id: string) {
    return this.http.post<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/${matched_offer_id}/generate/number`, {}).toPromise()
  }

  sendMessages(matched_offer_ids: string[]) {
    return this.http.post<{ error: unknown, errors: unknown[], data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/send_messages`, { matched_offer_ids }).toPromise()
  }

  getCountryCodes() {
    return this.http.get<{ data: string[] }>(`${auctioneerApiUrl}/matched_offers/countries`).toPromise()
  }

  calculate(data: DeepReadonly<Partial<MatchedOffer>>) {
    return this.http.post<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/calculate`, data).toPromise()
  }

  win(matched_offer_id: string) {
    return this.http.post<{ data: MatchedOffer }>(`${auctioneerApiUrl}/matched_offers/${matched_offer_id}/win`, {}).toPromise()
  }
}
