import { isDate, isEmpty, isNumber, pick, pickBy } from 'lodash-es'

export function prepareDealsPayload(filters: any, page: number, populate_intransit_balance?: boolean) {
  const forceQuery = filters?.forceQuery
  filters = prepareBackendFilters(filters)
  // tslint:disable-next-line: prefer-const
  let { limit, sort: sort_, table_view_id, columns, ...query } = filters
  // if deal_id filter is set - ignore other filters
  if (!forceQuery && query.deal_id) query = { deal_id: query.deal_id }
  const skip = limit * page
  const sort = !isEmpty(sort_) ? [{ [sort_.id]: sort_.start }] : []
  return { sort, limit, skip, query, columns, populate_intransit_balance }
}

export function prepareFiltersPayload(filters: any, topLevelFilters: string[]) {
  filters = prepareBackendFilters(filters)
  let { columns, ...query } = pick(filters, [...topLevelFilters, 'columns'])
  columns = columns.map(col => col === 'deal_id' ? 'deal_ids' : col)
  // if deal_id filter is set - ignore other filters
  if (!query.forceQuery && query.deal_id) query = { deal_id: query.deal_id }
  return { columns, query }
}

/**
 * Prepare FilterForm value for backend
 *
 * @private
 * @param {*} filters
 * @returns
 */
export function prepareBackendFilters(filters: any) {
  filters = { ...filters } // make mutable

  // delete empty values
  filters = pickBy(filters, v => isDate(v) || isNumber(v) || !isEmpty(v))

  // convert _to + _from to {to,from}
  Object.keys(filters).filter(k => k.endsWith('_to')).forEach(toKey => {
    const key = toKey.replace(/_to$/gi, '')
    const from = filters[`${key}_from`]
    const to = filters[`${key}_to`]
    delete filters[`${key}_from`]
    delete filters[`${key}_to`]
    if (filters.hasOwnProperty(key)) throw new Error(`Can not override existing filter ${key}`)
    filters[key] = { from, to }
  })

  return filters
}
