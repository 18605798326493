import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { Observable, of } from 'rxjs'
import { LocksService } from './locks.service'

@Component({
  selector: 'tc-locks',
  template: `
    <mat-icon *ngIf="lock$ | async as lock"
        [matTooltip]="lock"
        matTooltipPosition="right"
        class="fas fa-exclamation-triangle"
        ></mat-icon>
  `,
  styles: [`
    .fa-exclamation-triangle {
      color: orange;
      font-size: inherit;
      line-height: inherit;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocksComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private Locks: LocksService,
  ) { super() }

  @Input() resourceId: string | Observable<string>
  @Input() resourceType: string

  lock$: Observable<string>

  ngOnInit() {
    const resourceId$ = typeof this.resourceId === 'string' ? of(this.resourceId) : this.resourceId
    this.lock$ = this.Locks.smartlock(this.resourceType, resourceId$, this)
  }
}
