import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core'
import { DatetimeAdapter } from '@ng-matero/extensions/core'
import * as dayjs from 'dayjs'
import { ConfigType, Dayjs } from 'dayjs'
import * as localeDataPlugin from 'dayjs/plugin/localeData'

type UnixEpoch = number
declare const ngDevMode: boolean

dayjs.extend(localeDataPlugin)

/** Configurable options for @see DayjsEpochDateAdapter. */
export interface EpochDateAdapterOptions {

  /**
   * When enabled, the dates have to match the format exactly.
   * See https://momentjs.com/guides/#/parsing/strict-mode/.
   */
  strict?: boolean

  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * @default false
   */
  useUtc?: boolean
}

/** InjectionToken for moment date adapter to configure options. */
export const EPOCH_DATE_ADAPTER_OPTIONS = new InjectionToken<EpochDateAdapterOptions>(
  'EPOCH_DATE_ADAPTER_OPTIONS', {
    providedIn: 'root',
    factory: EPOCH_DATE_ADAPTER_OPTIONS_FACTORY,
})


/** @docs-private */
export function EPOCH_DATE_ADAPTER_OPTIONS_FACTORY(): EpochDateAdapterOptions {
  return {
    useUtc: false,
  }
}


/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length)
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i)
  }
  return valuesArray
}

/** The default hour names to use if Intl API is not available. */
const DEFAULT_HOUR_NAMES = range(24, i => i === 0 ? '00' : String(i))

/** The default minute names to use if Intl API is not available. */
const DEFAULT_MINUTE_NAMES = range(60, String)

/** Adapts Moment.js Dates for use with Angular Material. */
@Injectable()
export class EpochDateAdapter extends DateAdapter<number> {
  private _localeData: {
    firstDayOfWeek: number,
    longMonths: string[],
    shortMonths: string[],
    dates: string[],
    longDaysOfWeek: string[],
    shortDaysOfWeek: string[],
    narrowDaysOfWeek: string[]
  }

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(EPOCH_DATE_ADAPTER_OPTIONS) private _options?: EpochDateAdapterOptions,
  ) {
    super()
    this.setLocale(dateLocale || dayjs.locale())
  }

  setLocale(locale: string) {
    super.setLocale(locale)
    dayjs.locale(locale)
    const momentLocaleData = dayjs.localeData()
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, (i) => this.format(this.createDate(2017, 0, i + 1), 'D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
    }
  }

  getYear(date: UnixEpoch): number {
    return this._fromUnix(date).year()
  }

  getMonth(date: UnixEpoch): number {
    return this._fromUnix(date).month()
  }

  getDate(date: UnixEpoch): number {
    return this._fromUnix(date).date()
  }

  getDayOfWeek(date: UnixEpoch): number {
    return this._fromUnix(date).day()
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths
  }

  getDateNames(): string[] {
    return this._localeData.dates
  }

  getHourNames(): string[] {
    return DEFAULT_HOUR_NAMES
  }

  getMinuteNames(): string[] {
    return DEFAULT_MINUTE_NAMES
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek
    }
    return this._localeData.narrowDaysOfWeek
  }

  getYearName(date: UnixEpoch): string {
    return this._fromUnix(date).format('YYYY')
  }

  getFirstDayOfWeek(): number {
    return this._localeData.firstDayOfWeek
  }

  getNumDaysInMonth(date: UnixEpoch): number {
    return this._fromUnix(date).daysInMonth()
  }

  clone(date: UnixEpoch): UnixEpoch {
    return date
  }

  // tslint:disable-next-line: cyclomatic-complexity
  createDate(year: number, month: number, date: number): UnixEpoch {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      if (month < 0 || month > 11) {
        throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`)
      }

      if (date < 1) {
        throw Error(`Invalid date "${date}". Date has to be greater than 0.`)
      }
    }

    const d = dayjs().year(year).month(month).date(date) // TODO: make sure this line is ok
    const result = this._createMoment(d).locale(this.locale)

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid() && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`)
    }

    return result.unix()
  }

  today(): UnixEpoch {
    return this._createMoment().locale(this.locale).unix()
  }

  parse(value: any, parseFormat: string): UnixEpoch | null {
    if (value && typeof value === 'string') {
      return this._createMoment(value, parseFormat, this.locale).unix()
    }
    return value ? this._createMoment(value).locale(this.locale).unix() : null
  }

  format(date: UnixEpoch, displayFormat: string): string {
    const d = this._fromUnix(date)
    if (!this.isValid(date) && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error('DayjsEpochDateAdapter: Cannot format invalid date.')
    }
    return d.format(displayFormat)
  }

  addCalendarYears(date: UnixEpoch, years: number): UnixEpoch {
    return this._fromUnix(date).add(years, 'year').unix()
  }

  addCalendarMonths(date: UnixEpoch, months: number): UnixEpoch {
    return this._fromUnix(date).add(months, 'month').unix()
  }

  addCalendarDays(date: UnixEpoch, days: number): UnixEpoch {
    return this._fromUnix(date).add(days, 'day').unix()
  }

  addCalendarHours(date: UnixEpoch, calendarHours: number): UnixEpoch {
    return this._fromUnix(date).add(calendarHours, 'hour').unix()
  }

  toIso8601(date: UnixEpoch): string {
    return this._fromUnix(date).format()
  }

  /**
   * Returns the given value if given a valid Moment or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Moments and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value: any): UnixEpoch | null {
    let date
    if (value instanceof Date) {
      date = this._createMoment(value).locale(this.locale)
    } else if (this.isDateInstance(value)) {
      // Note: assumes that cloning also sets the correct locale.
      return this.clone(value)
    }
    if (typeof value === 'string') {
      if (!value) {
        return null
      }
      date = this._createMoment(value, 'YYYY-MM-DD[T]HH:mm:ssZZ' /* ISO_8601 */).locale(this.locale)
    }
    if (date && this.isValid(date)) {
      return this._createMoment(date).locale(this.locale).unix()
    }
    return super.deserialize(value)
  }

  isDateInstance(obj: any): boolean {
    return obj && typeof obj === 'number'
    // return dayjs.isDayjs(obj)
  }

  isValid(date: UnixEpoch): boolean {
    return this._fromUnix(date).isValid()
  }

  invalid(): UnixEpoch {
    return NaN
  }

  /** Creates a Moment instance while respecting the current UTC settings. */
  private _createMoment(
    date?: ConfigType,
    format?: string,
    locale?: string,
  ): Dayjs {
    const {strict, useUtc}: EpochDateAdapterOptions = this._options || {}

    return useUtc
      ? dayjs.utc(date, format).locale(locale)
      : dayjs(date, format, locale, strict)
  }

  private _fromUnix(epoch: number) {
    return dayjs.unix(epoch)
  }

  private _toUnix(date: Dayjs) {
    return date.unix()
  }

  // support time ops for @matheo/datepicker
  getHours(date: UnixEpoch): number {
    return this._fromUnix(date).hour()
  }

  getMinutes(date: UnixEpoch): number {
    return this._fromUnix(date).minute()
  }

  getSeconds(date: UnixEpoch): number {
    return this._fromUnix(date).second()
  }

  getMilliseconds(date: UnixEpoch): number {
    return this._fromUnix(date).millisecond()
  }

  setHours(date: UnixEpoch, hours: number) {
    return this._toUnix(this._fromUnix(date).hour(hours))
  }

  setMinutes(date: UnixEpoch, minutes: number) {
    return this._toUnix(this._fromUnix(date).minute(minutes))
  }

}


/** Adapts Moment.js Dates for use with Angular Material. */
@Injectable()
export class EpochDatetimeAdapter extends DatetimeAdapter<number> {
  private _localeData: {
    firstDayOfWeek: number,
    longMonths: string[],
    shortMonths: string[],
    dates: string[],
    longDaysOfWeek: string[],
    shortDaysOfWeek: string[],
    narrowDaysOfWeek: string[]
  }

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(EPOCH_DATE_ADAPTER_OPTIONS) private _options?: EpochDateAdapterOptions,
  ) {
    super(new EpochDateAdapter(dateLocale, _options))
    this.setLocale(dateLocale || dayjs.locale())
  }

  setLocale(locale: string) {
    super.setLocale(locale)
    dayjs.locale(locale)
    const momentLocaleData = dayjs.localeData()
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, (i) => this.format(this.createDate(2017, 0, i + 1), 'D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
    }
  }

  getYear(date: UnixEpoch): number {
    return this._fromUnix(date).year()
  }

  getMonth(date: UnixEpoch): number {
    return this._fromUnix(date).month()
  }

  getDate(date: UnixEpoch): number {
    return this._fromUnix(date).date()
  }

  getDayOfWeek(date: UnixEpoch): number {
    return this._fromUnix(date).day()
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths
  }

  getDateNames(): string[] {
    return this._localeData.dates
  }

  getHourNames(): string[] {
    return DEFAULT_HOUR_NAMES
  }

  getMinuteNames(): string[] {
    return DEFAULT_MINUTE_NAMES
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek
    }
    return this._localeData.narrowDaysOfWeek
  }

  getYearName(date: UnixEpoch): string {
    return this._fromUnix(date).format('YYYY')
  }

  getFirstDayOfWeek(): number {
    return this._localeData.firstDayOfWeek
  }

  getNumDaysInMonth(date: UnixEpoch): number {
    return this._fromUnix(date).daysInMonth()
  }

  clone(date: UnixEpoch): UnixEpoch {
    return date
  }

  // tslint:disable-next-line: cyclomatic-complexity
  createDate(year: number, month: number, date: number): UnixEpoch {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      if (month < 0 || month > 11) {
        throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`)
      }

      if (date < 1) {
        throw Error(`Invalid date "${date}". Date has to be greater than 0.`)
      }
    }

    const d = dayjs().year(year).month(month).date(date) // TODO: make sure this line is ok
    const result = this._createMoment(d).locale(this.locale)

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid() && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`)
    }

    return result.unix()
  }

  today(): UnixEpoch {
    return this._createMoment().locale(this.locale).unix()
  }

  parse(value: any, parseFormat: string): UnixEpoch | null {
    if (value && typeof value === 'string') {
      return this._createMoment(value, parseFormat, this.locale).unix()
    }
    return value ? this._createMoment(value).locale(this.locale).unix() : null
  }

  format(date: UnixEpoch, displayFormat: string): string {
    const d = this._fromUnix(date)
    if (!this.isValid(date) && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error('DayjsEpochDateAdapter: Cannot format invalid date.')
    }
    return d.format(displayFormat)
  }

  addCalendarYears(date: UnixEpoch, years: number): UnixEpoch {
    return this._fromUnix(date).add(years, 'year').unix()
  }

  addCalendarMonths(date: UnixEpoch, months: number): UnixEpoch {
    return this._fromUnix(date).add(months, 'month').unix()
  }

  addCalendarDays(date: UnixEpoch, days: number): UnixEpoch {
    return this._fromUnix(date).add(days, 'day').unix()
  }

  addCalendarHours(date: UnixEpoch, calendarHours: number): UnixEpoch {
    return this._fromUnix(date).add(calendarHours, 'hour').unix()
  }

  toIso8601(date: UnixEpoch): string {
    return this._fromUnix(date).format()
  }

  /**
   * Returns the given value if given a valid Moment or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Moments and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value: any): UnixEpoch | null {
    let date
    if (value instanceof Date) {
      date = this._createMoment(value).locale(this.locale)
    } else if (this.isDateInstance(value)) {
      // Note: assumes that cloning also sets the correct locale.
      return this.clone(value)
    }
    if (typeof value === 'string') {
      if (!value) {
        return null
      }
      date = this._createMoment(value, 'YYYY-MM-DD[T]HH:mm:ssZZ' /* ISO_8601 */).locale(this.locale)
    }
    if (date && this.isValid(date)) {
      return this._createMoment(date).locale(this.locale).unix()
    }
    return super.deserialize(value)
  }

  isDateInstance(obj: any): boolean {
    return obj && typeof obj === 'number'
    // return dayjs.isDayjs(obj)
  }

  isValid(date: UnixEpoch): boolean {
    return this._fromUnix(date).isValid()
  }

  invalid(): UnixEpoch {
    return NaN
  }

  /** Creates a Moment instance while respecting the current UTC settings. */
  private _createMoment(
    date?: ConfigType,
    format?: string,
    locale?: string,
  ): Dayjs {
    const {strict, useUtc}: EpochDateAdapterOptions = this._options || {}

    return useUtc
      ? dayjs.utc(date, format).locale(locale)
      : dayjs(date, format, locale, strict)
  }

  private _fromUnix(epoch: number) {
    return dayjs.unix(epoch)
  }

  private _toUnix(date: Dayjs) {
    return date.unix()
  }

  // support time ops for @matheo/datepicker
  getHours(date: UnixEpoch): number {
    return this._fromUnix(date).hour()
  }

  getMinutes(date: UnixEpoch): number {
    return this._fromUnix(date).minute()
  }

  getSeconds(date: UnixEpoch): number {
    return this._fromUnix(date).second()
  }

  getMilliseconds(date: UnixEpoch): number {
    return this._fromUnix(date).millisecond()
  }

  setHours(date: UnixEpoch, hours: number) {
    return this._toUnix(this._fromUnix(date).hour(hours))
  }

  setMinutes(date: UnixEpoch, minutes: number) {
    return this._toUnix(this._fromUnix(date).minute(minutes))
  }

  getHour(date: number): number {
    return this.getHours(date)
  }
  getMinute(date: number): number {
    return this.getMinutes(date)
  }
  getFirstDateOfMonth(date: number): number {
    return this._toUnix(this._fromUnix(date).startOf('month'))
  }
  isInNextMonth(startDate: number, endDate: number): boolean {
    return this.sameMonthAndYear(this._toUnix(this._fromUnix(startDate).add(1, 'month')), endDate)
  }
  addCalendarMinutes(date: number, minutes: number): number {
    return this._toUnix(this._fromUnix(date).add(minutes, 'minute'))
  }
  createDatetime(year: number, month: number, date: number, hour: number, minute: number): number {
    return this._toUnix(dayjs(new Date(year, month, date, hour, minute)))
  }

}
