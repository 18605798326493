import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { AccountObject, AuditObject, Carrier, Department, EmbeddedAuditLog, ItemType, LocationObject, MatchedOfferStatus, Measure, PricingTerm, Product, ProductCategory, ProductType, TableKey, User } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { compact, reject } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuditApiService } from 'src/api/audit';
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers';
import { loadItemTypes, selectItemTypeEntities } from 'src/app/store/item-types';
import { loadLocations, selectLocationEntities } from 'src/app/store/locations';
import { loadMeasures, selectMeasureEntities } from 'src/app/store/measures';
import { loadPricingTerms, selectPricingTermEntities } from 'src/app/store/pricing-terms';
import { loadProducts, selectProductEntities } from 'src/app/store/products';
import { loadUsers, selectUserEntities } from 'src/app/store/users';
import { SegmentTypes } from 'src/components/segment-form/segment-form.component';
import { EpochPipe } from 'src/filters/epoch.pipe';
import { fakeId } from 'src/filters/fake-id.pipe';
import { OffersService } from 'src/services/data/offers.service';
import { waitNotEmpty } from "src/services/data/utils";
import { ToasterService } from 'src/shared/toaster/toaster.service';
import { MatchedOfferOverlayService } from '../../matched-offer-overlay/matched-offer-overlay.service';
import { MatchedOffersService } from '../../matched-offer-overlay/matched-offers.service';
import { SupplierOfferOverlayService } from '../supplier-offer-overlay.service';
import { DEFAULT_COLUMNS } from './audit-log.columns';
import { loadDepartments, selectDepartmentEntities } from 'src/app/store/departments';
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts';
import { loadProductCategories, selectProductCategoryEntities } from 'src/app/store/product-categories';
import { loadProductTypes, selectProductTypeEntities } from 'src/app/store/product-types';


interface AuditLogRow {
  created: number
  user: User
  username: string
  action: string
  actionText: string
  key: string
  old: unknown
  new: unknown

  deal_id?: string
  offer_id?: string
  matched_offer_id?: string
  attributes?: AuditObject['attributes']
}

@Component({
  selector: 'tc-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditLogComponent implements OnInit {

  @Input()
  filter: {
    matched_offer_id?: string
    action?: string
    offer_id?: string
    rate_id?: string
    resource_id?: string
  }

  @Input()
  logs: DeepReadonly<EmbeddedAuditLog[]>

  @Input()
  tableIdentity = TableKey.SupplierOfferAudit

  @Input()
  displayColumns: string[]

  readonly dataSource = new MatTableDataSource<AuditLogRow>()

  private locations: DeepReadonly<Dictionary<LocationObject>>
  private measures: DeepReadonly<Dictionary<Measure>>
  private products: DeepReadonly<Dictionary<Product>>
  private productCategories: DeepReadonly<Dictionary<ProductCategory>>
  private productTypes: DeepReadonly<Dictionary<ProductType>>
  private carriers: DeepReadonly<Dictionary<Carrier>>
  private pricingTerms: DeepReadonly<Dictionary<PricingTerm>>
  private itemTypes: DeepReadonly<Dictionary<ItemType>>
  private users: DeepReadonly<Dictionary<User>>
  private departments: DeepReadonly<Dictionary<Department>>
  private accounts: DeepReadonly<Dictionary<AccountObject>>

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.sort = sort }

  constructor(
    private readonly AuditApi: AuditApiService,
    private readonly MatchedOffers: MatchedOffersService,
    private readonly MatchedOfferOverlay: MatchedOfferOverlayService,
    private readonly SupplierOfferOverlay: SupplierOfferOverlayService,
    private readonly Offers: OffersService,
    private readonly toaster: ToasterService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly epochPipe: EpochPipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly store: Store
  ) { }

  ngOnInit() {
    this.displayColumns = DEFAULT_COLUMNS[this.tableIdentity]
    combineLatest([
      this.store.pipe(select(selectCarrierEntities), waitNotEmpty(), tap(carriers => { this.carriers = carriers })),
      this.store.pipe(select(selectItemTypeEntities), waitNotEmpty(), tap(itemTypes => { this.itemTypes = itemTypes })),
      this.store.pipe(select(selectLocationEntities), waitNotEmpty(), tap(locations => { this.locations = locations })),
      this.store.pipe(select(selectMeasureEntities), waitNotEmpty(), tap(measures => { this.measures = measures })),
      this.store.pipe(select(selectPricingTermEntities), waitNotEmpty(), tap(pricingTerms => { this.pricingTerms = pricingTerms })),
      this.store.pipe(select(selectProductEntities), waitNotEmpty(), tap(products => { this.products = products })),
      this.store.pipe(select(selectProductCategoryEntities), waitNotEmpty(), tap(productCategories => { this.productCategories = productCategories })),
      this.store.pipe(select(selectProductTypeEntities), waitNotEmpty(), tap(productTypes => { this.productTypes = productTypes })),
      this.store.pipe(select(selectUserEntities), waitNotEmpty(), tap(users => { this.users = users })),
      this.store.pipe(select(selectDepartmentEntities), waitNotEmpty(), tap(departments => { this.departments = departments })),
      this.store.pipe(select(selectAccountEntities), waitNotEmpty(), tap(accounts => { this.accounts = accounts })),
    ]).pipe(take(1)).subscribe(() => {
      this.reloadData();
    })

    this.store.dispatch(loadMeasures({}))
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadUsers({}))
    this.store.dispatch(loadPricingTerms({}))
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadDepartments())
    this.store.dispatch(loadAccounts({}))
  }

  private async reloadData() {
    try {
      let { data: audits } = await this.AuditApi.list({ limit: 5000, ...this.filter })

      if (this.logs) {
        const logs = this.logs?.flatMap(l => this.populateLog(l))
        const rows = reject(audits, { action: 'MATCHED_OFFER_UPDATED' }).map(a => this.populateAudit(a));
        this.dataSource.data = [...logs, ...rows];
      } else {
        this.dataSource.data = audits.map(a => this.populateAudit(a));
      }
    } catch (err) {
      console.error('Unable to load audit data.', err);
      this.toaster.error('Unable to load audit data.', err);
    }
  }

  private translate({ keyValue, newValue, oldValue }) {
    let translatedNewValue = newValue
    let translatedOldValue = oldValue
    let keyDisplay = keyValue

    let lookups: { pattern: RegExp, keyDisplay: string, converter: (k: string) => string }[] = [
      {   pattern: /account.primaryphone/,                                                    keyDisplay: 'Phone',                                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.type/,                                                            keyDisplay: 'Type',                                                      converter: (k: string) => k                                                                  },
      {   pattern: /account.primaryemail/,                                                    keyDisplay: 'Email',                                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.language/,                                                        keyDisplay: 'Language',                                                  converter: (k: string) => k                                                                  },
      {   pattern: /account.website/,                                                         keyDisplay: 'Website',                                                   converter: (k: string) => k                                                                  },
      {   pattern: /account.name/,                                                            keyDisplay: 'Company Name',                                              converter: (k: string) => k                                                                  },
      {   pattern: /account.status/,                                                          keyDisplay: 'Status',                                                    converter: (k: string) => k                                                                  },
      {   pattern: /account.since/,                                                           keyDisplay: 'Since',                                                     converter: (k: string) => this.epochPipe.transform(k, 'DD-MMM-YYYY hh:mma', "utc")           },
      {   pattern: /account.manager/,                                                         keyDisplay: 'Manager',                                                   converter: (k: string) => this.users[k]?.fullname                                            },
      {   pattern: /account.managers.[0-9]*/,                                                 keyDisplay: 'Managers',                                                  converter: (k: string) => this.users[k]?.fullname                                            },
      {   pattern: /account.coordinator/,                                                     keyDisplay: 'Coordinator',                                               converter: (k: string) => this.users[k]?.fullname                                            },
      {   pattern: /account.coordinators.[0-9]*/,                                             keyDisplay: 'Coordinators',                                              converter: (k: string) => this.users[k]?.fullname                                            },
      {   pattern: /account.products.[0-9]*/,                                                 keyDisplay: 'Products',                                                  converter: (k: string) => this.products[k]?.name                                             },
      {   pattern: /account.parent/,                                                          keyDisplay: 'Parent',                                                    converter: (k: string) => this.accounts[k]?.name                                             },
      {   pattern: /account.preferred_carriers.[0-9]*/,                                       keyDisplay: 'Preferred Carriers',                                        converter: (k: string) => this.carriers[k]?.name                                             },
      {   pattern: /account.banking.street1/,                                                 keyDisplay: 'Banking Address',                                           converter: (k: string) => k                                                                  },
      {   pattern: /account.banking.name/,                                                    keyDisplay: 'Banking Name',                                              converter: (k: string) => k                                                                  },
      {   pattern: /account.banking.transit/,                                                 keyDisplay: 'Banking Transit #',                                         converter: (k: string) => k                                                                  },
      {   pattern: /account.banking.state/,                                                   keyDisplay: 'Banking Province/State',                                    converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.department/,                                     keyDisplay: 'Address Department #',                                      converter: (k: string) => this.departments[k]?.name                                          },
      {   pattern: /account.addresses.[0-9]*.contact_name/,                                   keyDisplay: 'Address Contact Name',                                      converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.postal/,                                         keyDisplay: 'Address Zip Code',                                          converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.integration_codes.montship/,                     keyDisplay: 'Address Monship Code',                                      converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.note/,                                           keyDisplay: 'Address Note',                                              converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.name/,                                           keyDisplay: 'Address Name',                                              converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.telephone1/,                                     keyDisplay: 'Address Telephone # 1',                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.telephone2/,                                     keyDisplay: 'Address Telephone # 2',                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.establishment/,                                  keyDisplay: 'Address Establishment',                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.street1/,                                        keyDisplay: 'Address Street 1',                                          converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.street2/,                                        keyDisplay: 'Address Street 2',                                          converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.city/,                                           keyDisplay: 'Address City',                                              converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.fax/,                                            keyDisplay: 'Address Fax',                                               converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.tax_id/,                                         keyDisplay: 'Address Tax Id',                                            converter: (k: string) => k                                                                  },
      {   pattern: /account.addresses.[0-9]*.email/,                                          keyDisplay: 'Address Email',                                             converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.pricing.other_tax/,                                    keyDisplay: 'Other Tax #',                                               converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.pricing.tax_gst/,                                      keyDisplay: 'GST #',                                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.pricing.tax_hst/,                                      keyDisplay: 'HST #',                                                     converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.pricing.tax_pst/,                                      keyDisplay: 'Provincial Tax #',                                          converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.pricing.currency/,                                     keyDisplay: 'Currency',                                                  converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.tracking_website/,                                     keyDisplay: 'Website',                                                   converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.scac_code/,                                            keyDisplay: 'SCAC code',                                                 converter: (k: string) => k                                                                  },
      {   pattern: /account.attributes.pricing.measure_id/,                                   keyDisplay: 'Measure',                                                   converter: (k: string) => this.measures[k]?.name                                             },
      {   pattern: /account.distribution_lists.[0-9]*.filters.locations.[0-9]*/,              keyDisplay: 'Distribution List Filter Locations',                        converter: (k: string) => (this.locations[k] as any)?.fullname                               },
      {   pattern: /account.distribution_lists.[0-9]*.filters.suppliers.[0-9]*/,              keyDisplay: 'Distribution List Filter Suppliers',                        converter: (k: string) => this.accounts[k]?.name                                             },
      {   pattern: /account.distribution_lists.[0-9]*.filters.providers.[0-9]*/,              keyDisplay: 'Distribution List Filter Providers',                        converter: (k: string) => this.accounts[k]?.name                                             },
      {   pattern: /account.distribution_lists.[0-9]*.filters.product_categories.[0-9]*/,     keyDisplay: 'Distribution List Filter Product Categories',               converter: (k: string) => this.productCategories[k]?.name                                    },
      {   pattern: /account.distribution_lists.[0-9]*.filters.product_types.[0-9]*/,          keyDisplay: 'Distribution List Filter Product Types',                    converter: (k: string) => this.productTypes[k]?.name                                         },
      {   pattern: /account.distribution_lists.[0-9]*.filters.products.[0-9]*/,               keyDisplay: 'Distribution List Filter Products',                         converter: (k: string) => this.products[k]?.name                                             },
      {   pattern: /account.distribution_lists.[0-9]*.service_providers.[0-9]*/,              keyDisplay: 'Distribution List Filter Service Providers',                converter: (k: string) => this.accounts[k]?.name                                             },
      {   pattern: /account.distribution_lists.[0-9]*.contacts.buyer.[0-9]*/,                 keyDisplay: 'Distribution List Filter Contacts Buyer',                   converter: (k: string) => this.users[k]?.fullname                                            },
      {   pattern: /account.distribution_lists.[0-9]*.contacts.service_provider.[0-9]*/,      keyDisplay: 'Distribution List Filter Contacts Service Provider',        converter: (k: string) => this.users[k]?.fullname                                            },
      {   pattern: /shipment-rate.carrier_id/,                                                keyDisplay: 'Carrier',                                                   converter: (k: string) => this.carriers[k]?.name                                             },
      {   pattern: /shipment-rate.origin_id/,                                                 keyDisplay: 'Origin',                                                    converter: (k: string) => this.locations[k]?.name                                            },
      {   pattern: /shipment-rate.destination_id/,                                            keyDisplay: 'Destination',                                               converter: (k: string) => this.locations[k]?.name                                            },
      {   pattern: /shipment-rate.until/,                                                     keyDisplay: 'Until',                                                     converter: (k: string) => this.epochPipe.transform(k, 'DD-MMM-YYYY hh:mma', "utc")           },
      {   pattern: /shipment-rate.attributes.date_quoted/,                                    keyDisplay: 'Date Quoted',                                               converter: (k: string) => this.epochPipe.transform(k, 'DD-MMM-YYYY hh:mma', "utc")           },
      {   pattern: /shipment-rate.description/,                                               keyDisplay: 'Description',                                               converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.commodity/,                                                 keyDisplay: 'Commodity',                                                 converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.weight.max/,                                                keyDisplay: 'Max Weight',                                                converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.weight.metric/,                                             keyDisplay: 'Unit',                                                      converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.container_size/,                                            keyDisplay: 'Container Size',                                            converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.port_loading/,                                              keyDisplay: 'Port Loading',                                              converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.rate.currency/,                                             keyDisplay: 'Currency',                                                  converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.attributes.transit_time/,                                   keyDisplay: 'Transit Time',                                              converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.rate.amount/,                                               keyDisplay: 'All-in rate',                                               converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.status/,                                                    keyDisplay: 'Status',                                                    converter: (k: string) => k                                                                  },
      {   pattern: /shipment-rate.port_discharge/,                                            keyDisplay: 'Port Discharge',                                            converter: (k: string) => k                                                                  }
    ]

    let found = lookups.find(l => keyValue?.match(l.pattern))
    if (found) {
      translatedNewValue = found?.converter(newValue)
      translatedOldValue = found?.converter(oldValue)
      keyDisplay = found?.keyDisplay
    }
    return { keyValue, keyDisplay, translatedNewValue, translatedOldValue }
  }

  private populateAudit(audit: AuditObject): AuditLogRow {
    let { action } = audit
    if (action === 'OFFER_CANCELED') action = 'SUPPLIER_OFFER_CANCELED'
    const keyValue = typeof audit.attributes?.field !== 'undefined' ? audit.attributes?.field : audit.attributes?.key
    const oldValue = typeof audit.attributes?.value?.old !== 'undefined' ? audit.attributes?.value?.old : audit.attributes?.old
    const newValue = typeof audit.attributes?.values?.new !== 'undefined' ? audit.attributes?.values?.new : audit.attributes?.new
    return {
      created: audit.created,
      user: this.users[audit.user_id],
      username: this.users[audit.user_id]?.fullname,
      action,
      actionText: (action === 'SUPPLIER_OFFER_CLONED')
        ? `supplier offer created by cloning ${fakeId(audit.attributes.clone.from, 'OFFER-')}`
        : action.toLowerCase().replace(/_/g, ' '),
      key: (this.translate({ keyValue, oldValue, newValue })).keyDisplay,
      new: (this.translate({ keyValue, oldValue, newValue })).translatedNewValue,
      old: (this.translate({ keyValue, oldValue, newValue })).translatedOldValue,
      deal_id: audit.deal_id,
      offer_id: audit.offer_id,
      matched_offer_id: audit.matched_offer_id,
      attributes: audit.attributes,
    }
  }

  private populateLog(log: DeepReadonly<EmbeddedAuditLog>): AuditLogRow[] {
    let {action} = log
    if (action === 'update') action = 'MATCHED_OFFER_UPDATED'

    return log?.values.map(l => ({
      user: this.users[log.user],
      username: this.users[log.user]?.fullname,
      action,
      actionText: action.toLowerCase().replace(/_/g, ' '),
      key: this.getUpdateActionKey(l.key).actionText,
      old: this.getUpdateActionKey(l.key, l.old).value,
      new: this.getUpdateActionKey(l.key, l.new).value,
      created: l.date,
    }))
  }

  private getUpdateActionKey(key, value?) {
    switch (key) {
      case 'segments':
        return {
          actionText: 'Segments',
          value: value?.map((i) => `${SegmentTypes[i.type]}-${this.carriers[i.carrier_id].name}`).join(', '),
        }
      case 'bid.notes':
        return {
          actionText: 'Bid Notes',
          value: value?.[0] ? value[value.length - 1].body : undefined,
        }
      case 'bid.attributes.logistics_user_id':
        return {
          actionText: 'Logistics User',
          value: this.users[value]?.fullname,
        }
      case 'bid.weight.unit':
        return {
          actionText: 'Bid Weight Unit',
          value: this.measures[value]?.name,
        }
      case 'bid.attributes.docs_country':
        return {
          actionText: 'Bid Docs Country',
          value,
        }
      case 'bid.product':
        return {
          actionText: 'Bid Product',
          value: this.products[value]?.name,
        }
      case 'attributes.revenue_cad':
        return {
          actionText: 'Revenue',
          value: this.currencyPipe.transform(value, 'CAD'),
        }
      case 'attributes.margin_cad':
        return {
          actionText: 'Margin',
          value: this.currencyPipe.transform(value, 'CAD'),
        }
      case 'attributes.supplier_liability_date':
        return {
          actionText: 'Ant. liability date (Supplier due date)',
          value: this.epochPipe.transform(value, 'DD-MMM-YYYY hh:mma', "utc"),
        }
      case 'attributes.finance_term':
        return {
          actionText: 'Finance Term',
          value,
        }
      case 'attributes.supplier_term_date':
        return {
          actionText: 'Supplier Term Date',
          value: this.epochPipe.transform(value, 'DD-MMM-YYYY hh:mma', "utc"),
        }
      case 'margin':
        return {
          actionText: 'Margin',
          value: this.decimalPipe.transform(value * 100, '1.2-2') + '%',
        }
      case 'offer.weight.amount':
        return {
          actionText: 'Offer Weight',
          value,
        }
      case 'bid.weight.amount':
        return {
          actionText: 'Bid Weight',
          value,
        }
      case 'bid.attributes.trader_user_id':
        return {
          actionText: 'Bid Trader User',
          value: this.users[value]?.fullname,
        }
      case 'bid.attributes.item_type_id':
        return {
          actionText: 'Bid Item Type',
          value: this.itemTypes[value]?.name,
        }
      case 'bid.ship_start':
        return {
          actionText: 'Bid Ship Start',
          value: this.epochPipe.transform(value, 'DD-MMM-YYYY hh:mma', "utc"),
        }
      case 'bid.ship_end':
        return {
          actionText: 'Bid Ship End',
          value: this.epochPipe.transform(value, 'DD-MMM-YYYY hh:mma', "utc"),
        }
      case 'bid.delivery':
        return {
          actionText: 'Bid Delivery',
          value: this.locations[value]?.name,
        }
      case 'bid.attributes.buyer_user_ids':
        return {
          actionText: 'Bid Buyers',
          value: compact(value?.map(i => this.users[i]?.fullname)).join(', '),
        }
      case 'offer.attributes.supplier_user_ids':
        return {
          actionText: 'Offer Suppliers',
          value: compact(value?.map(i => this.users[i]?.fullname)).join(', '),
        }
      case 'offer.price':
        return {
          actionText: 'Offer Price',
          value,
        }
      case 'offer.pickup':
        return {
          actionText: 'Offer Pickup',
          value: this.locations[value]?.name,
        }
      case 'offer.country':
        return {
          actionText: 'Offer Country',
          value,
        }
      case 'attributes.buyer_term_date':
        return {
          actionText: 'Buyer Term Date',
          value: this.epochPipe.transform(value, 'DD-MMM-YYYY hh:mma', "utc"),
        }
      case 'attributes.collection_date':
        return {
          actionText: 'Collection Date',
          value: this.epochPipe.transform(value, 'DD-MMM-YYYY hh:mma', "utc"),
        }
      case 'offer.incoterm':
        return {
          actionText: 'Offer Incoterm',
          value: this.pricingTerms[value]?.term,
        }
      case 'offer.attributes.item_type_id':
        return {
          actionText: 'Item Specification',
          value: this.itemTypes[value]?.name,
        }
      case 'offer.weight.unit':
        return {
          actionText: 'Offer Weight Unit',
          value: this.measures[value]?.name,
        }
      case 'offer.attributes.incoterm_location':
        return {
          actionText: 'Offer Incoterm',
          value: this.locations[value]?.name,
        }
      case 'bid.attributes.incoterm_location':
        return {
          actionText: 'Bid Incoterm',
          value: this.locations[value]?.name,
        }
      case 'bid.incoterm':
        return {
          actionText: 'Bid Incoterm',
          value: this.pricingTerms[value]?.term,
        }
      case 'costs':
        return {
          actionText: 'Costs',
          value: value?.map(cost =>
            `Service: ${cost.service}. Status: ${cost.status}. Type: ${cost.type}. Amount: ${cost.amount.total}\n\n`
          ).join(''),
        }
      case 'deal_id':
        return {
          actionText: 'Deal#',
          value,
        }
      case 'bids':
        return {
          actionText: 'Bid Amount',
          value: value?.[0] ? value[value.length - 1].amount : undefined,
        }
      case 'status':
        return {
          actionText: 'Status',
          value: value && MatchedOfferStatus[value].name,
        }

      default:
        console.warn(`UNKNOWN key=${key}`)
        return {
          actionText: key,
          value,
        }
    }
  }

  async openMatchedOffer(matchedOfferId: string) {
    const offer = await this.MatchedOffers.getById(matchedOfferId);
    this.MatchedOfferOverlay.showEditOffer(offer);
  }

  async openSupplierOffer(offerId: string) {
    const offer = await this.Offers.getById(offerId);
    this.SupplierOfferOverlay.editOffer(offer).subscribe();
  }
}
