import { Injectable } from '@angular/core'
import { FileObject, Segment, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, map, uniq } from 'lodash-es'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { CarrierPickerDialogComponent, CarrierPickerOptions } from './carrier-picker.component'

@Injectable()
export class CarrierPickerService {
  constructor(
    private modal: ModalService,
    private Carriers: CarriersService,
    private Files: FilesService,
  ) { }

  show(data: CarrierPickerOptions) {
    return this.modal.openDialog(CarrierPickerDialogComponent, data)
  }

  async sendToDeringer(
    segments: DeepReadonly<Pick<Segment, 'carrier_id'>[]>,
    file: DeepReadonly<FileObject>,
    carrierRecipients: User[],
    notificationRecipients: string[],
    dealId?: string,
  ) {
    let carrierIds = uniq(compact(map(segments, 'carrier_id')))
    const carriers = await this.Carriers.getCarriersByIds(carrierIds)
    if (carriers.length > 1) {
      carrierIds = await this.show({ carriers }).toPromise()
      if (!carrierIds) return
    }
    const carrierEmails = carrierRecipients.map(x => x.primaryemail)
    await this.Files.sendToDeringer(file, carrierEmails, notificationRecipients, dealId)
  }
}
