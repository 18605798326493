import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { AccountObject, Cost, CreditNote, DealViewField, DealViewRaw, Invoice, Segment, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { first, get, identity, isNumber } from 'lodash-es'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { selectAccountEntities } from 'src/app/store/accounts'
import { selectCarrierEntities } from 'src/app/store/carriers'
import { autosaveDealCost, autosaveDealSegment, lockDeal, saveDealForm } from 'src/app/store/deal-view.actions'
import { selectProductEntities } from 'src/app/store/products'
import { CreditNoteFormService } from 'src/components/credits/credit-note-form/credit-note-form.service'
import { NotesOverlayService } from 'src/components/notes/notes-overlay/notes-overlay.service'
import { SegmentFormService } from 'src/components/segment-form/segment-form.service'
import { UploaderDialogService } from 'src/components/uploader-dialog/uploader-dialog.service'
import { DealOverlaysService } from 'src/services/actions/deal-overlays.service'
import { actualizeToZeroPatch } from 'src/services/data/costs.service'
import { isAdmin } from 'src/services/data/deal-view-permissions.service'
import { DealsService } from 'src/services/data/deals.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { fieldsReport } from 'src/shared/utils/fields-report'
import { replayForm } from 'src/shared/utils/replay-form'
import { TypedFormControl } from 'src/shared/utils/typed-forms'
import { DealDocumentsService } from '../../deals/deal-documents/deal-documents.service'
import { RequestPrepaymentFormService } from '../../deals/request-prepayment/request-prepayment.service'
import { ShipmentRatePickerService } from '../deal-shipping/shipment-rate-picker/shipment-rate-picker.service'
import { CostFormService } from './deal-details/cost-form/cost-form.service'
import { oldDealViewSel, statusSel } from './deal-form-page.decorator'
import { CostFormValue, SegmentFormGroup, SegmentFormValue } from './deal-form.schema'
import { DealFormService } from './deal-form.service'
import { buildDealCostForm, prepareDealCostPatch } from './deal-form.service-factory'


export abstract class DealFormBaseController extends OnDestroyMixin {
  constructor(
    public dealId$: Observable<string>,
    protected DealForm: DealFormService,
    protected store: Store,
    protected UploaderDialog: UploaderDialogService,
    protected DealDocuments: DealDocumentsService,
    protected toaster: ToasterService,
    protected readonly CostForm: CostFormService
  ) {
    super()
  }

  protected incomplete: DealViewIncompleteComponent

  // ng-cloak. safeguard, should be removed
  ready$ = new ReplaySubject<true>(1)

  // ref data
  private products$ = this.store.pipe(select(selectProductEntities), waitNotEmpty())

  // pristine deal view (v2). raw. loaded from the backend. doesn't include form changes
  dealViewRaw$ = new ReplaySubject<DeepReadonly<DealViewRaw>>(1)
  dealRaw$ = this.dealViewRaw$.pipe(map(dto => dto.deal))
  invoices$ = this.dealViewRaw$.pipe(map(dto => dto.invoices || []))
  creditNotes$ = this.dealViewRaw$.pipe(map(dto => dto.credit_notes || []))
  vendorCredits$ = this.dealViewRaw$.pipe(map(dto => dto.vendor_credits || []))

  oldDealView$ = oldDealViewSel(this.dealViewRaw$, this.products$) // TODO: oldDealViewSel is not really implemented

  // forms
  readonly dealForm = this.DealForm.build()
  readonly detailsForm = this.dealForm.controls.details
  readonly productsForm = this.dealForm.controls.products
  readonly segmentsForm = this.dealForm.controls.segments
  readonly costsForm = this.dealForm.controls.costs
  readonly costs$ = replayForm(this.costsForm).pipe(map(costs => costs.map(cost => cost.cost)))

  // forms data observables
  fxRates$ = replayForm(this.detailsForm.controls.fxRates)
  fxRatesAskRange$ = replayForm(this.detailsForm.controls.fxRatesAskRange)

  // forms state observables
  inProgress$ = new BehaviorSubject<'save'|'submit'|'lock'>(undefined)
  isDirtyAndValid$ = this.dealForm.statusChanges.pipe(map(() => this.dealForm.valid && this.dealForm.dirty), distinctUntilChanged())
  isInvalid$ = this.dealForm.statusChanges.pipe(map(() => this.dealForm.invalid), distinctUntilChanged())
  fieldsReport$ = fieldsReport(this.dealForm)


  saveDeal() {
    if (this.inProgress$.value) return
    this.dealForm.markAllAsTouched()
    this.dealForm.updateValueAndValidity()
    if (!this.dealForm.valid) return
    this.dealViewRaw$.pipe(take(1)).subscribe(dv =>
      this.store.dispatch(saveDealForm({ dv, dealForm: this.dealForm.serialize() })))
  }

  updateCost(cost: DeepReadonly<Partial<Cost>>) {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    // NOTE: use cost.ID as a fallback measure for unsaved new costs
    const index = this.costsForm.value.findIndex(costForm =>
      cost.cost_id && cost.cost_id === costForm.cost.cost_id ||
      cost.ID && cost.ID === costForm.cost.ID)
    this.costsForm.controls[index].reset(prepareDealCostPatch(cost))
    this.autosaveOrRecalculateCost([index])
  }

  async removeCost(cost: DeepReadonly<Partial<Cost>>) {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    if (cost.attributes.associated?.invoice_id) {
      this.toaster.warning(`You cannot delete this cost because it is associated with invoice ${cost.attributes.associated?.invoice_id}. Please remove the association or void invoice before deleting the cost.`)
      return
    }
    await this.CostForm.removeCost()
    // NOTE: use cost.ID as a fallback measure for unsaved new costs
    const idx = this.costsForm.value.findIndex(costForm =>
      cost.cost_id === costForm?.cost.cost_id ||
      cost.ID && cost.ID === costForm?.cost.ID)
    this.costsForm.removeAt(idx)
    this.autosaveOrRecalculateCost()
  }

  /**
   * Start deal calculations
   *
   * @private
   */
  protected doCalculations() {
    return this.invoices$.pipe(take(1), switchMap(invoices =>
      this.DealForm.calculateDeal(this.dealForm, invoices)))
  }

  protected fetchDeal() {
    return this.dealId$.pipe(
      take(1),
      switchMap(dealId => this.DealForm.load(dealId, this.dealForm)),
      tap(({dealViewRaw}) => {
        this.ready$.next(true)
        this.dealViewRaw$.next(dealViewRaw)
      }))
  }

  protected autosaveOrRecalculateCost(index?: number[]) {
    const costs = this.costsForm.getRawValue().map(cf => cf.cost)
    this.dealId$.pipe(take(1)).subscribe(dealId => {
      if (dealId) {
        this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
          this.store.dispatch(autosaveDealCost({ dv, costs, index }))
        })
      } else {
        this.doCalculations().subscribe()
      }
    })
  }

  creditNotesChanged(_creditNote?: CreditNote) {
    this.fetchDeal().pipe(
      switchMap(() => this.doCalculations()),
    ).subscribe()
  }

  vendorCreditsChanged(_vendorCredit?: VendorCredit) {
    this.fetchDeal().pipe(
      switchMap(() => this.doCalculations()),
    ).subscribe()
  }

  showUploadAttachment(invoice: DeepReadonly<Invoice>) {
    this.dealViewRaw$.pipe(take(1)).subscribe(async (dv) => {
      const files = await this.UploaderDialog.showUploader({
        dv,
        title: `Upload Attachment for Invoice ${invoice.invoice_id}`,
        limit: 1,
      })
      if (!files) return
      try {
        await this.DealDocuments.attachInvoiceDocs(dv.deal.deal_id, invoice, files)
        this.fetchDeal().subscribe()
      } catch (error) {
        console.warn('Unable to upload invoice.', get(error, 'data.error.error.message'))
        this.toaster.error('Unable to upload invoice.', get(error, 'data.error.error.message'))
      }
    })
  }
}

export abstract class DealFormPageBaseController extends DealFormBaseController {

  constructor(
    private baseUrl: string,
    dealId$: Observable<string>,
    protected back: string,
    protected AuthApi: AuthApiService,
    protected readonly CostForm: CostFormService,
    protected CreditNoteForm: CreditNoteFormService,
    protected DealForm: DealFormService,
    protected NotesOverlay: NotesOverlayService,
    protected DealOverlays: DealOverlaysService,
    protected Deals: DealsService,
    protected RequestPrepaymentForm: RequestPrepaymentFormService,
    protected router: Router,
    protected SegmentForm: SegmentFormService,
    protected ShipmentRatePicker: ShipmentRatePickerService,
    protected store: Store,
    protected toaster: ToasterService,
    protected UploaderDialog: UploaderDialogService,
    protected DealDocuments: DealDocumentsService,
  ) {
    super(
      dealId$,
      DealForm,
      store,
      UploaderDialog,
      DealDocuments,
      toaster,
      CostForm,
    )
    this.dealId$.pipe(take(1)).subscribe(dealId =>
      this.dealIdForm = new UntypedFormControl(dealId))
    this.dealIdForm.valueChanges.pipe(
      untilComponentDestroyed(this),
      withLatestFrom(this.dealId$),
      filter(([dealId, currentDealId]) => dealId !== currentDealId),
      debounceTime(1000)).subscribe(([dealId]) => this.goToDealId(dealId))

    this.dealId$.pipe(
      filter(dealId => dealId !== this.dealIdForm.value),
      tap(dealId => this.dealIdForm.setValue(dealId, { emitEvent: false })),
      untilComponentDestroyed(this)).subscribe()
    this.dealId$.pipe(
      distinctUntilChanged(),
      filter(identity),
      switchMap(() => this.fetchDeal()),
      untilComponentDestroyed(this)).subscribe()
  }

  readonly isAdmin = isAdmin(this.AuthApi.currentUser)

  // ref data
  protected carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())
  protected accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())

  // forms
  dealIdForm: TypedFormControl<string>

  // ui state
  dealStatus$ = statusSel(this.dealRaw$)

  showDealNotes() {
    combineLatest(this.invoices$, this.dealId$).pipe(take(1)).subscribe(([invoices, dealId]) => {
      this.NotesOverlay.showDealNotes(dealId, invoices.map(i => i.invoice_id)).subscribe()
    })
  }

  showMessagesOf() {
    this.dealViewRaw$.pipe(take(1)).subscribe(({deal, costs}) =>
      this.DealOverlays.showMessagesOf({...deal, costs}))
  }

  showRequestPrepayment() {
    this.oldDealView$.pipe(take(1)).subscribe(deal =>
      this.RequestPrepaymentForm.show(deal).then((creditNote) => {
        this.creditNotesChanged(creditNote)
      }))
  }


  lockDeal(lock: boolean) {
    this.dealRaw$.pipe(take(1)).subscribe(deal =>
      this.store.dispatch(lockDeal({ deal, lock })))
/*
    if(!lock) {
      this.inProgress$.pipe(skip(1), take(1)).subscribe(() => {
        location.reload();
      })
    }
 */
  }


  goBack() {
    if (this.inProgress$.value) return
    // if (back.includes('/')) {
      this.router.navigateByUrl(this.back)
    // } else {
    //   $state.go(back)
    // }
  }

  goToDealId(dealId: string) {
    this.Deals.getById(dealId)
      .catch(() => this.toaster.warning(`Deal "${dealId}" not found`))
      .then(deal =>
        deal && this.router.navigateByUrl(`${this.baseUrl}/${dealId}`))
  }

  /**
   * Add new tertiary cost
   */
  async showAddCost(cost?: Partial<Cost>) {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    const { deal, supplierId, buyerId } = this.detailsForm.getRawValue()
    const costs = await this.CostForm.showCreateCost({
      deal_id: deal.deal_id,
      status: deal.status,
      supplier_id: supplierId,
      buyer_id: buyerId,
    }, cost)
    if (costs?.length) this.createCosts(costs)
  }

  /**
   * Pick a rate and post new cost document
   */
  async showAddFreight() {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    const { originLocationId, destLocationId, buyerId, estimatedTotals: { weight } } = this.detailsForm.getRawValue()
    const cost = await this.ShipmentRatePicker.addFreightCost({ originLocationId, destLocationId, buyerId, weight, dealId: this.dealIdForm.value })
    if (cost) this.createCosts([cost])
  }

  async costsActualizationCreation(
    input: {
      newCosts: Partial<Cost>[],
      costIds: string[],
      oldProvider: AccountObject,
      newProvider: AccountObject,
      newSegment: SegmentFormValue
    }) {
    // update segment
    const index = this.segmentsForm.value.findIndex((s) => s.segment?.segment_id === input.newSegment.segment?.segment_id)
    if (isNumber(index)) {
      this.segmentsForm.controls[index].patchValue({
        carrierAccountId: input.newProvider.account.toString(),
        currencyCode: input.newProvider.attributes.pricing?.currency
      })
    } else {
      const form = this.DealForm.buildSegmentForm()
      form.patchValue(input.newSegment)
      this.segmentsForm.push(form)
    }
    // for existing costs actualization
    let actualizationIndices = []
    for (let i = 0; i < input.costIds.length; i++) {
      const theCostForm = this.costsForm.controls.find(cf => cf.value.cost.cost_id === input.costIds[i])
      actualizationIndices.push(this.costsForm.controls.findIndex(cf => cf.value.cost.cost_id === input.costIds[i]))
      const originalCostFormValue: CostFormValue = first(this.costsForm.value.filter(cfv => cfv.cost.cost_id === input.costIds[i]))
      const itsCost = this.DealForm.readCostForm(originalCostFormValue)
      const updatedCost: DeepReadonly<Partial<Cost>> = { ...itsCost, ...actualizeToZeroPatch<Cost>({ amount: itsCost.amount, attributes: itsCost.attributes }) }
      theCostForm.patchValue(prepareDealCostPatch(updatedCost))
    }
    // for new costs creation
    let newCostsIndices = []
    newCostsIndices = input.newCosts.map((_, i) => this.costsForm.value.length + i)
    this.createCosts(input.newCosts, true)
    // save deal & calculate costs
    this.saveDeal()
  }

  /**
   * Show Add Credit Note dialog
   */
  showAddCredit() {
    this.dealViewRaw$.pipe(take(1)).subscribe(async dv => {
      const creditNote = await this.CreditNoteForm.showAddItem(dv)
      this.creditNotesChanged(creditNote)
    })
  }

  showUpdateSegment(segmentForm: SegmentFormGroup) {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
    }
    const readonly = this.AuthApi.currentUser.role === 'trader' || !!this.incomplete.incomplete
    this.SegmentForm.showSegmentForm(this.dealViewRaw$, this.dealForm, segmentForm, readonly)
  }

  showRemoveSegment(segmentForm: SegmentFormGroup) {
    if (this.incomplete.incomplete) {
      this.toaster.warning(this.incomplete.incomplete)
      return
    }
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.SegmentForm.removeSegmentForm(dv, this.dealForm, segmentForm)
    })
  }

  private createCosts(costs: DeepReadonly<Partial<Cost>[]>, skipCalculation: boolean = false) {
    const forms = costs.map(cost => buildDealCostForm(prepareDealCostPatch(cost)))
    forms.forEach(form => this.costsForm.push(form))
    !skipCalculation && this.autosaveOrRecalculateCost(forms.map((f, i) => this.costsForm.length - 1 - i))
  }

  protected createSegment(segment: Partial<DeepReadonly<Segment>>) {
    const form = this.DealForm.buildSegmentForm()
    const patch = this.DealForm.prepareDealSegmentPatch(segment, this.segmentsForm.length)
    form.patchValue(patch)
    this.segmentsForm.push(form)
    combineLatest([this.dealId$, this.dealViewRaw$]).pipe(take(1)).subscribe(([dealId, dv]) => {
      if (dealId) {
        this.store.dispatch(autosaveDealSegment({
          dv,
          dealForm: this.dealForm.serialize(),
          index: this.segmentsForm.length - 1,
          patch,
        }))
      }
    })
  }

  private refetchDealParts(parts: DealViewField[]) {
    return this.dealId$.pipe(take(1), switchMap(dealId =>
      this.Deals.refetchDealParts(dealId, parts, this.dealViewRaw$)))
  }

  protected fetchDealFiles = () => this.refetchDealParts(['files'])
  protected fetchDealNotes = () => this.refetchDealParts(['notes'])
  protected fetchDealFilesAndInvoices = () => this.fetchDeal()
  protected fetchDealCostsAndInvoices = () => this.fetchDeal()
  protected fetchDealCostsAndCreditNotes = () => this.fetchDeal()
  protected fetchDealCostsAndVendorCredits = () => this.fetchDeal()
  // protected fetchDealFilesAndInvoices = () => this.refetchDealParts(['files', 'invoices'])
  // protected fetchDealCostsAndInvoices = () => this.refetchDealParts(['costs', 'invoices'])
  // protected fetchDealCostsAndCreditNotes = () => this.refetchDealParts(['costs', 'credit_notes'])
  // protected fetchDealCostsAndVendorCredits = () => this.refetchDealParts(['costs', 'vendor_credits'])
}
