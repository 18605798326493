import { Injectable } from '@angular/core'
import { CreditPool, Deal, DealParty, DealPartyE, DealPaymentTerms, DealViewBase, PaymentTermsDate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { defaults, get, keyBy, map, uniq } from 'lodash-es'
import { CreditPoolApiService } from 'src/api/credit-pool'
import { PaymentReferencesService } from 'src/pages/admin/settings/admin-setting-payments/payment-references/payment-references.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'

/**
 * Credit Pool service
 *
 * @see https://docs.google.com/document/d/1SOQR8ZwjkbmHqq5xrFCH6Kh_CQaHSKVcb9Soinose6E/edit
 *
 * @export
 * @returns
 */
@Injectable()
export class CreditPoolService {
  constructor(
    private CreditPoolApi: CreditPoolApiService,
    private PaymentReferences: PaymentReferencesService,
    private toaster: ToasterService,
  ) {}

  getAllCreditPools() {
    return this.CreditPoolApi.list({limit: Number.MAX_SAFE_INTEGER}).then(r => r.data)
  }

  /**
   * Get company payment terms
   *
   * @param {any} account
   * @returns { days, from_date } where days is number, from_date - enum string
   */
  getPaymentTerms(account: number | string, party?: undefined | DealPartyE.buyer | DealPartyE.supplier, selectedTrader?: string) {
    return this.getFor(account)
      .then(creditPool => this.readPaymentTerms(creditPool, party, selectedTrader))
  }


  /**
   * Get company payment terms
   *
   * @param {number[]} accountIds
   * @returns creditPool documents keyed by account id
   */
  async getForAccounts(accountIds: number[]) {
    if (!accountIds.length) return {}
    const { data } = await this.CreditPoolApi.getByAccountIds(accountIds.map(x => x.toString()))
    return keyBy(data, 'account')
  }

  /**
   * Get company credit pool
   *
   * @param {any} account
   * @returns creditPool document
   */
  getFor(account: number | string): Promise<CreditPool> {
    return this.CreditPoolApi.get(account).then(r => r.data)
    .catch((err) => {
      if (err.status === 404) return undefined
      throw err
    })
  }

  // tslint:disable-next-line: cyclomatic-complexity
  async readPaymentTerms(creditPool: CreditPool, party?: DealParty, traderId?: string) {
    if (!creditPool) {
      if (party === 'buyer') {
        console.warn('Buyer doesn\'t have creditPool')
        this.toaster.error('Buyer Payment terms are not defined')
      }
    }
    let paymentTerms: DealPaymentTerms
    const customPaymentTerms = creditPool?.attributes?.custom_payment_terms?.find(pt => pt.traders?.includes(traderId))

    if (!customPaymentTerms) {
      const { days, from_date, na, payment_method_open, payment_method_secure, reference_id } = get(creditPool, 'attributes.payment_ref', {}) as CreditPool['attributes']['payment_ref']
      const { credit_type, secure_type, type, prepayment_percent } = get(creditPool, 'attributes.payment_term', {}) as CreditPool['attributes']['payment_term']
      // NOTE: we add reference asynchroniously on next tick (@see getPaymentTerms)
      paymentTerms = { credit_type, days, from_date, na, payment_method_open, payment_method_secure, prepayment_percent, reference_id, secure_type, type } as DealPaymentTerms
    } else {
      const { credit_type, days, from_date, na, payment_method_open, payment_method_secure, prepayment_percent, reference_id, secure_type, type } = customPaymentTerms
      paymentTerms = { credit_type, days, from_date, na, payment_method_open, payment_method_secure, prepayment_percent, reference_id, secure_type, type } as DealPaymentTerms
    }
    if (party && (!paymentTerms.days && paymentTerms.days !== 0 || !paymentTerms.from_date)) {
      if (party === 'buyer') {
        const { days, from_date } = paymentTerms
        console.warn(`Buyer Payment terms are missing for Account ${creditPool ? creditPool.account : party}`, { days, from_date })
        this.toaster.error('Buyer Payment Terms are missing')
        defaults(paymentTerms, {
          days: 30,
          from_date: PaymentTermsDate.delivery_date,
        })
      } else if (party === 'supplier') {
        defaults(paymentTerms, {
          days: 7,
          from_date: PaymentTermsDate.days_from_pickup_date,
        })
      }
    }

    if (!paymentTerms?.reference_id) return paymentTerms
    const paymentReference = await this.PaymentReferences.getById(paymentTerms.reference_id)
    paymentTerms.reference_name = paymentReference.reference
    return paymentTerms
  }

  /**
   * Check credit for the deal
   *
   * @param {any} deal raw deal or dealView
   * @returns {Promise<boolean>} true - credit check passed
   */
  async creditCheck(deals: DeepReadonly<Pick<Deal | DealViewBase, 'buyer_id' | 'deal_id'>> | DeepReadonly<Pick<Deal | DealViewBase, 'buyer_id' | 'deal_id'>[]>, type = 'creation') {
    if (!Array.isArray(deals)) deals = [deals as Pick<Deal | DealViewBase, 'buyer_id' | 'deal_id'>]
    if (uniq(map(deals, 'buyer_id')).length !== 1) {
      throw new Error('deals must have the same buyer')
    }
    const [{ buyer_id }] = deals
    const deal_id = map(deals, 'deal_id')
    const balance = await this.getBalance(buyer_id, deal_id)
    return balance[type].is_allowed
  }

  async getBalance(account_id: string|number, deal_id?: string[]) {
    const { data } = await this.CreditPoolApi.getBalance({ account_id: account_id.toString(), deal_id })
    return data
  }
}
