import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { ForexData } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('ForexApi', downgradeInjectable(ForexApiService))

@Injectable()
@AngularCopy()
export class ForexApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  get(@AngularParams() params?) {
    return this.http.get<{ data: ForexData }>(`${apiUrl}/forex`, { params }).toPromise()
  }
}
