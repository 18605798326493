<form [formGroup]="notificationForm" (submit)="submit()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    Notification for {{currentSegment?.deals[0]}}
  </h2>

  <div mat-dialog-content>
    <div gdColumns="repeat(2, minmax(0, 1fr))" gdGap="10px">
      <tc-select-search
        placeholder="Template"
        [group]="notificationForm"
        ctrlName="type"
        [items]="typeOptions"
        bindLabel="name"
        bindValue="id">
      </tc-select-search>

      <tc-select-search
        placeholder="Segment"
        [group]="notificationForm"
        ctrlName="segment_type"
        [items]="segmentTypeOptions"
        bindLabel="name"
        bindValue="id">
      </tc-select-search>
    </div>

    <div>
      <tc-select-search
              [group]="notificationForm"
              [items]="distribution_lists"
              placeholder="Emails list"
              ctrlName="emailsList"
              bindLabel="name"
              bindValue="name">
      </tc-select-search>
    </div>

    <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="10px">
      <tc-select-search
        [group]="notificationForm"
        [items]="buyers$ | async"
        [multiple]="true"
        placeholder="Buyers"
        ctrlName="buyers"
        bindLabel="fullname"
        bindValue="user_id"
        bindHint="primaryemail">
      </tc-select-search>

      <tc-select-search
        [group]="notificationForm"
        [items]="suppliers$ | async"
        [multiple]="true"
        placeholder="Suppliers"
        ctrlName="suppliers"
        bindLabel="fullname"
        bindValue="user_id"
        bindHint="primaryemail">
      </tc-select-search>

      <tc-select-search
        [group]="notificationForm"
        [items]="serviceProviders$ | async"
        [multiple]="true"
        placeholder="Service Providers"
        ctrlName="serviceProviders"
        bindLabel="fullname"
        bindValue="user_id"
        bindHint="primaryemail">
      </tc-select-search>
    </div>


    <table mat-table fixedLayout [dataSource]="dataSource">
      <ng-container matColumnDef="show">
        <th mat-header-cell *matHeaderCellDef>Show</th>
        <td mat-cell *matCellDef="let row; dataSource:dataSource">
          <mat-checkbox
            [checked]="row.show"
            (change)="toggleTableCheckbox(row, 'show')">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="highlight">
        <th mat-header-cell *matHeaderCellDef>Bold</th>
        <td mat-cell *matCellDef="let row; dataSource:dataSource">
          <mat-checkbox
            [checked]="row.highlight"
            (change)="toggleTableCheckbox(row, 'highlight')">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fieldName">
        <th mat-header-cell *matHeaderCellDef>Field</th>
        <td mat-cell *matCellDef="let row; dataSource:dataSource">
          <ng-container *ngIf="row">
            <div class="text-ellipsis" [title]="row.field">
              {{row.fieldName}}
            </div>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let row; dataSource:dataSource">
          <ng-container *ngIf="row">
            <div class="text-ellipsis" [title]="row.data">
              {{row.value}}
            </div>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>

    <div gdColumns="repeat(1, minmax(0, 1fr))" gdGap="10px">
      <mat-checkbox color="primary" formControlName="docs_available">
        Include "Documents available in TC"
      </mat-checkbox>

      <mat-form-field gdColumn="span 2">
        <mat-label>Note</mat-label>
        <textarea 
          matInput
          class="form-control"
          formControlName="notes"
          rows="1">
        </textarea>
      </mat-form-field>

      <tc-chips-input 
        gdColumn="span 2"
        [group]="notificationForm"
        placeholder="Emails"
        realPlaceholder="Add email..."
        ctrlName="emails">
      </tc-chips-input>

    </div>

  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-button type="button" (click)="saveDistributionList()">Save Distribution List</button>
    <button mat-raised-button type="submit" color="primary">
      <mat-icon class="fa fa-fw fa-envelope"></mat-icon> Send
    </button>
  </div>
</form>
