import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal'
import { BookingRejectionDialogComponent, BookingRejectionDialogOptions } from './booking-rejection-dialog.component'

@Injectable()
export class BookingRejectionDialogService {

  constructor(private modal: ModalService) { }

  showBookingRejectionDialog(opts: BookingRejectionDialogOptions) {
    return this.modal.openDialog<BookingRejectionDialogComponent, BookingRejectionDialogOptions, boolean>(BookingRejectionDialogComponent, opts, {
      panelClass: ['tc-dialog', 'modal-md'],
      width: null,
      maxWidth: null,
    })
  }
}
