import { DecimalPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormArray } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Cost, DEAL_DRAFT, ForexData, MATCHED_OFFER_EXPIRED, MATCHED_OFFER_INCOMPLETE, MATCHED_OFFER_REJECTED, MATCHED_OFFER_SENT, MATCHED_OFFER_UNSENT, MatchedOffer, MatchedOfferStatus, ShipmentRate, TableKey, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { Dayjs } from 'dayjs'
import { cloneDeep, filter, keyBy, omit, pick } from 'lodash-es'
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadAccounts, selectAccount, selectAccountEntities } from 'src/app/store/accounts'
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { selectUserEntities } from 'src/app/store/users'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { ShipmentRatePickerService } from 'src/pages/admin/trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.service'
import { NegativeMarginReasonFormService } from 'src/pages/admin/trading/deal-form/negative-margin-reason/negative-margin-reason.service'
import { isBwiInventory } from 'src/services/data/accounts.service'
import { NotesService } from 'src/services/data/notes.service'
import { SegmentsService, getSegmentFormPopulatedValues } from 'src/services/data/segments.service'
import { mergeDeep } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { fieldsReport } from 'src/shared/utils/fields-report'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { FreightRatesService } from '../../logistics/freight-rates/freight-rates.service'
import { MeasuresService } from '../../settings/product-specifications/measures/measures.service'
import { CostFormService } from '../../trading/deal-form/deal-form-page/deal-details/cost-form/cost-form.service'
import { CostsFormGroup, SegmentFormGroup, SegmentsFormGroup } from '../../trading/deal-form/deal-form-page/deal-form.schema'
import { prepareDealSegmentPatch, readCostForm } from '../../trading/deal-form/deal-form-page/deal-form.service'
import { buildDealCostForm, buildSegmentForm, prepareDealCostPatch } from '../../trading/deal-form/deal-form-page/deal-form.service-factory'
import { ShipmentRatePickerOptions } from '../../trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.component'
import { MatchedOfferFormService } from './matched-offer-form.service'
import { MatchedOffersService, isMatchedOfferBiddable, isMatchedOfferExpired, isMatchedOfferFinalized } from './matched-offers.service'

export interface MatchedOfferOverlayOptions {
  matchedOffer: DeepReadonly<MatchedOffer>
  fxRates: ForexData
}

@Component({
  selector: 'tc-matched-offer-overlay',
  templateUrl: './matched-offer-overlay.component.html',
  styleUrls: ['./matched-offer-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchedOfferOverlayComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly ConfirmModal: ConfirmModalService,
    private readonly decimalPipe: DecimalPipe,
    private readonly MatchedOfferForm: MatchedOfferFormService,
    private readonly MatchedOffers: MatchedOffersService,
    private readonly NegativeMarginReasonForm: NegativeMarginReasonFormService,
    private readonly Notes: NotesService,
    private readonly Segments: SegmentsService,
    private readonly Measures: MeasuresService,
    private readonly CostForm: CostFormService,
    private readonly ShipmentRatePicker: ShipmentRatePickerService,
    private readonly store: Store,
    private readonly toaster: ToasterService,
    private readonly FreightRates: FreightRatesService,
    private readonly dialogRef: MatDialogRef<MatchedOfferOverlayComponent, DeepReadonly<MatchedOffer>>,
    @Inject(MAT_DIALOG_DATA) protected readonly dialogData: MatchedOfferOverlayOptions,
  ) { super() }

  protected readonly MatchedOfferStatus = MatchedOfferStatus
  protected readonly TableKey = TableKey

  // primary state
  protected readonly supplierOfferId = this.dialogData.matchedOffer.supplier_offer_id
  protected readonly matchedOfferId = this.dialogData.matchedOffer.matched_offer_id
  protected readonly fxRates = this.dialogData.fxRates
  private readonly matchedOffer$ = new BehaviorSubject(this.dialogData.matchedOffer)
  protected readonly shipmentRates$ = new ReplaySubject<Dictionary<ShipmentRate>>(1)

  // form
  protected readonly moForm = this.MatchedOfferForm.buildMatchedOfferForm(this.dialogData.matchedOffer)
  protected readonly costsForm = new FormArray(this.dialogData.matchedOffer.costs.map(cost =>
    buildDealCostForm(prepareDealCostPatch(cost)))) as CostsFormGroup
  protected readonly segmentsForm = new FormArray<SegmentFormGroup>(this.dialogData.matchedOffer.segments.map((segment, i) =>
    buildSegmentForm(prepareDealSegmentPatch(segment, i)))) as SegmentsFormGroup

  // matched offer state
  protected readonly issues$ = this.matchedOffer$.pipe(map(mo => mo.issues.map(i => i.message).join('\n')))
  protected readonly supplierName$ = this.matchedOffer$.pipe(switchMap(mo => this.store.pipe(
    select(selectAccount(mo.offer.account)), map(acc => acc.name), distinctUntilChanged())))
  protected readonly buyerName$ = this.matchedOffer$.pipe(switchMap(mo => this.store.pipe(
    select(selectAccount(mo.bid.account)), map(acc => acc.name), distinctUntilChanged())))
  protected readonly isFinalized$ = this.matchedOffer$.pipe(map(isFinalized))
  protected readonly isBiddable$ = this.matchedOffer$.pipe(map(isMatchedOfferBiddable))
  protected readonly status$ = this.matchedOffer$.pipe(map(syntheticMatchedOfferStatus))

  // status bar
  protected readonly offerTooltip$ = combineLatest([this.matchedOffer$, this.store.pipe(select(selectUserEntities))])
    .pipe(map(([mo, users]) => interactionsTooltip(mo, users)))
  protected readonly margin$ = this.matchedOffer$.pipe(map(mo => `${this.decimalPipe.transform(mo.margin * 100, '1.2')}%`))
  protected readonly marginCad$ = this.matchedOffer$.pipe(map(mo => mo.attributes?.margin_cad))
  protected readonly revenueCad$ = this.matchedOffer$.pipe(map(mo => mo.attributes?.revenue_cad))

  // form
  protected readonly detailsTabInvalid$ = replayForm(this.moForm).pipe(map(() =>
    filter(omit(this.moForm.controls, ['wrappingId', 'weightTypeId', 'quantity', 'packageId', 'packageSize', 'packageMeasureId', 'brand', 'productCode', 'additionalSpecs']), 'invalid')))
  protected readonly specsTabInvalid$ = replayForm(this.moForm).pipe(map(() =>
    filter(pick(this.moForm.controls, ['wrappingId', 'weightTypeId', 'quantity', 'packageId', 'packageSize', 'packageMeasureId', 'brand', 'productCode', 'additionalSpecs']), 'invalid')))
  protected readonly noTertiaryCosts$ = replayForm(this.costsForm).pipe(map(costs => !costs.find(c => c.type === 'tertiary')))

  // form state
  protected readonly inProgress$ = new BehaviorSubject<'confirm'|'send'|'save'|undefined>(undefined)
  protected readonly isInvalid$ = replayForm(this.moForm).pipe(map(() => this.moForm.invalid))
  protected readonly isDirty$ = replayForm(this.moForm).pipe(map(() => this.moForm.dirty && this.moForm.valid))
  protected readonly fieldsReport$ = fieldsReport(this.moForm)
  protected readonly canNotSend$ = this.matchedOffer$.pipe(map(mo => !isMatchedOfferBiddable(mo) && !canSend(mo)))
  protected readonly canConfirm$ = this.matchedOffer$.pipe(map(mo => canConfirm(mo, this.AuthApi.currentUser)))
  protected readonly sendOrResend$ = this.matchedOffer$.pipe(map(mo =>
    (mo.status === MATCHED_OFFER_INCOMPLETE || mo.status === MATCHED_OFFER_UNSENT) ? 'Send' : 'Resend'))


  ngOnInit() {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadCarriers({}))

    this.FreightRates.listVisibleTo(this.dialogData.matchedOffer.matched_offer_id).pipe(untilComponentDestroyed(this)).subscribe(rates =>{
      this.shipmentRates$.next(keyBy(rates, 'rate_id'))
    })
  }

  protected async showAddCost(cost?: Partial<Cost>) {
    const supplier_id = this.dialogData.matchedOffer.offer.account
    const buyer_id = this.dialogData.matchedOffer.bid.account
    const costs = await this.CostForm.showCreateCost({ supplier_id, buyer_id, status: DEAL_DRAFT }, cost)
    if (costs?.length) this.createCosts(costs)
  }

  protected async showAddFreight() {
    const { originLocationId, destLocationId, supplierEstWeight, supplierMeasureId } = this.moForm.getRawValue()
    const weight = { amount: supplierEstWeight, measure_id: supplierMeasureId }
    const buyerId = this.dialogData.matchedOffer.bid.account.toString()
    const matchedOfferId = this.dialogData.matchedOffer.matched_offer_id
    const cost = await this.ShipmentRatePicker.addFreightCost({ originLocationId, destLocationId, buyerId, weight, matchedOfferId })
    if (cost) this.createCosts([cost])
  }

  private createCosts(costs: DeepReadonly<Partial<Cost>[]>) {
    const forms = costs.map(cost => buildDealCostForm(prepareDealCostPatch(cost)))
    forms.forEach(form => this.costsForm.push(form))
    this.toaster.warning('Costs are changed, please save the offer to recalculate delivery period.')
  }

  protected updateCost(cost: DeepReadonly<Partial<Cost>>) {
    const i = this.findCostIndex(cost)
    this.costsForm.controls[i].reset(prepareDealCostPatch(cost))
    this.toaster.warning('Costs are changed, please save the offer to recalculate delivery period.')
  }

  protected removeCost(cost: DeepReadonly<Partial<Cost>>) {
    const i = this.findCostIndex(cost)
    this.costsForm.removeAt(i)
    this.toaster.warning('Costs are changed, please save the offer to recalculate delivery period.')
  }

  private findCostIndex(cost: DeepReadonly<Partial<Cost>>) {
    // NOTE: use cost.ID as a fallback measure for unsaved new costs
    return this.costsForm.value.findIndex(costForm =>
      cost.cost_id && cost.cost_id === costForm?.cost.cost_id ||
      cost.ID && cost.ID === costForm?.cost.ID)
  }

  protected async showAddSegment() {
    const index = this.segmentsForm.length
    const segment = await this.showRatePicker(index, { title: 'New Segment' })
    this.segmentsForm.push(buildSegmentForm(segment))
  }

  protected async showUpdateSegment(segmentForm: SegmentFormGroup) {
    const index = this.segmentsForm.controls.indexOf(segmentForm)
    const segment = await this.showRatePicker(index, { title: 'Update Segment' })
    segmentForm.reset(segment)
  }

  protected removeSegment(segmentForm: SegmentFormGroup) {
    const index = this.segmentsForm.controls.findIndex(s => s === segmentForm)
    this.segmentsForm.removeAt(index)
  }

  private async showRatePicker(index: number, opts?: Partial<ShipmentRatePickerOptions>) {
    const { supplierEstWeight, supplierMeasureId } = this.moForm.getRawValue()
    const w = { amount: supplierEstWeight, unit: supplierMeasureId }
    const [accounts, carriers] = await combineLatest([
      this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
      this.store.pipe(select(selectCarrierEntities), waitNotEmpty()),
    ]).pipe(take(1)).toPromise()
    const preferred_carriers = accounts[this.dialogData.matchedOffer.bid.account].preferred_carriers || []
    const selectedRate = await this.ShipmentRatePicker.show({
      ...opts,
      buyWeightKg: this.Measures.convert(w.amount, w.unit, 'KG'),
      buyWeightLb: this.Measures.convert(w.amount, w.unit, 'LB'),
      filters: { preferred_carriers },
    })
    const segmentFromRate = this.Segments.buildFromRate(selectedRate, { carriers })
    const lastSegmentForm = getSegmentFormPopulatedValues(this.segmentsForm.controls[index - 1]?.value)
    return { ...lastSegmentForm, ...prepareDealSegmentPatch(segmentFromRate, index) }
  }

  protected async confirmOffer() {
    if (this.inProgress$.value) return

    const mo = await this.saveOffer()
    this.inProgress$.next('confirm')
    try {
      await this.MatchedOffers.confirmOffer(mo)
      this.toaster.success('Matched offer has been confirmed.')
    } catch (err) {
      console.error(`Unable to confirm Matched Offer ${mo.matched_offer_id}`, err)
      this.toaster.error(`Unable to confirm Matched Offer ${mo.matched_offer_id}`, err)
      throw err
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected async sendOffer() {
    if (this.inProgress$.value) return
    const isBiddable = await this.isBiddable$.pipe(take(1)).toPromise()

    let mo = this.readForm()
    if (isBiddable && this.dialogData.matchedOffer.offer.price !== mo.offer.price) {
      const isDirty = await this.isDirty$.pipe(take(1)).toPromise()
      if (isDirty) {
        await this.ConfirmModal.show({
          title: 'Unsaved Data Warning',
          titleIcon: 'fas fa-exclamation-triangle',
          description: 'Form has unsaved data. Please save data before resending',
          confirmButtonText: 'Close it',
          confirmButtonClass: 'btn-danger',
          cancelButtonText: '',
        })
      }
      return
    }

    if (mo.margin < 0) {
      const {user_id, account} = this.AuthApi.currentUser
      const {reason} = await this.NegativeMarginReasonForm.show({matchedOffer: mo})
      await this.Notes.createNote({
        body: reason.description,
        visibility: 1,
        matched_offer_id: mo.matched_offer_id,
        attributes: {
          category: 'negative-margin',
          account,
          user_id,
        },
      }).catch((err) => {
        console.error(`Unable to create negative margin note for ${mo.matched_offer_id}`, err)
        this.toaster.error(`Unable to create negative margin note for ${mo.matched_offer_id}`, err)
        throw err
      })
    }

    mo = await this.saveOffer(false)
    this.inProgress$.next('send')
    try {
      const { error, errors } = await this.MatchedOffers.sendOffers([mo])
      if (error || errors?.length) {
        console.error(`Unable to send Matched Offer ${mo.matched_offer_id}`, error || errors.join(' '))
        this.toaster.error('Unable to send Matched Offer', error || errors.join(' '))
        return
      }
      this.toaster.success('Matched offer has been sent.')
      this.dialogRef.close(mo)
    } catch (err) {
      console.error(`Unable to send Matched Offer ${mo.matched_offer_id}`, err)
      this.toaster.error('Unable to send Matched Offer', err)
      throw err
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected async saveOffer(closeModal = true) {
    const [isBiddable, isFinalized] = await Promise.all([
      this.isBiddable$.pipe(take(1)).toPromise(),
      this.isFinalized$.pipe(take(1)).toPromise(),
    ])
    if (!isBiddable && (this.inProgress$.value || isFinalized)) return undefined
    this.moForm.markAllAsTouched()
    this.moForm.updateValueAndValidity()
    if (!this.moForm.valid) return undefined

    let mo = this.readForm()
    this.inProgress$.next('save')
    try {
      mo = { ...mo, ...await this.MatchedOffers.calculateOfferImmutable(mo) }
      mo = { ...mo, ...await this.MatchedOffers.updateOfferImmutable(mo) }
      this.moForm.markAsPristine()
      this.toaster.success('Matched Offer saved')
      if (closeModal) this.dialogRef.close(mo)
      return mo
    } catch (err) {
      console.error(`Unable to save Matched Offer ${mo.matched_offer_id || ''}`, err)
      this.toaster.error(`Unable to save Matched Offer`, err)
      throw err
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected cancel() {
    this.dialogRef.close()
  }

  private readForm() {
    return mergeDeep(
      cloneDeep(this.dialogData.matchedOffer),
      this.MatchedOfferForm.readMatchedOfferForm(this.moForm),
      { costs: this.costsForm.getRawValue().map(cf => readCostForm(cf)) }
    )
  }
}

function canConfirm(mo: DeepReadonly<MatchedOffer>, currentUser: DeepReadonly<User>) {
  const { user_id, role } = currentUser
  return role === 'trader'
      && (mo.offer.creator_id || mo.offer.author.user_id) === user_id
      && isBwiInventory(mo.bid.account)
      && (mo.status === MATCHED_OFFER_SENT || mo.status === MATCHED_OFFER_UNSENT)
}

function canSend(mo: DeepReadonly<MatchedOffer>) {
  return mo.offer.bwi_inventory ?
    [MATCHED_OFFER_UNSENT, MATCHED_OFFER_SENT, MATCHED_OFFER_REJECTED, MATCHED_OFFER_EXPIRED].indexOf(mo.status) > -1 :
    [MATCHED_OFFER_UNSENT, MATCHED_OFFER_SENT, MATCHED_OFFER_REJECTED].indexOf(mo.status) > -1
}

function isFinalized(mo: DeepReadonly<MatchedOffer>) {
  return mo.offer.bwi_inventory && mo.status === MATCHED_OFFER_EXPIRED
    ? false
    : isMatchedOfferFinalized(mo) || mo.status === MATCHED_OFFER_SENT
}

function interactionsTooltip(mo: DeepReadonly<MatchedOffer>, users: DeepReadonly<Dictionary<User>>) {
  const creator = users[mo.offer.creator_id || mo.offer.author.user_id]
  const interactions: [string, string, Dayjs][] = [['Created', creator?.fullname, dayjs.unix(mo.offer.created)]]
  if (mo.interactions?.modified)
    interactions.push(['Modified', users[mo.interactions.modified.user_id]?.fullname, dayjs.unix(mo.interactions.modified.date)])
  if (mo.interactions?.message_sent_at)
    interactions.push(['Message sent', users[mo.interactions.message_sent_at.user_id]?.fullname, dayjs.unix(mo.interactions.message_sent_at.date)])
  if (mo.interactions?.confirmed)
    interactions.push(['Confirmed', users[mo.interactions.confirmed.user_id]?.fullname, dayjs.unix(mo.interactions.confirmed.date)])
  return interactions.map(([action, fullname, date]) =>
    `${action} by: ${fullname} on ${date.format('DD-MMM-YYYY')} at ${date.format('hh:mma')}`).join('\n')
}

function syntheticMatchedOfferStatus(mo: DeepReadonly<MatchedOffer>) {
  const status = isMatchedOfferExpired(mo) ? MATCHED_OFFER_EXPIRED : mo.status
  return MatchedOfferStatus[status]?.name || status
}
