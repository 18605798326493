<div gdColumns="minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr)" *ngIf="mode === 'finance'" gdGap="0 30px">
  <ng-container *ngTemplateOutlet="estimatedWeight"></ng-container>
  <ng-container *ngTemplateOutlet="estimatedCost"></ng-container>
  <ng-container *ngTemplateOutlet="estimatedRevenue"></ng-container>
  <span></span>
  <ng-container *ngTemplateOutlet="actualWeight"></ng-container>
  <ng-container *ngTemplateOutlet="actualCost"></ng-container>
  <ng-container *ngTemplateOutlet="actualRevenue"></ng-container>
</div>

<div gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 4fr)" *ngIf="mode === 'products'">
  <ng-container *ngTemplateOutlet="estimatedWeight"></ng-container>
  <ng-container *ngTemplateOutlet="actualWeight"></ng-container>
</div>
<div gdColumns="minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr)" *ngIf="!mode">
  <ng-container *ngTemplateOutlet="estimatedWeight"></ng-container>
</div>

<ng-template #estimatedWeight>
  <mat-form-field>
    <mat-label>Total Estimated Weight</mat-label>
    <input readonly matInput [value]="(dealDetails.estimatedTotals$ | async)?.weight.amount || ''">
    <!-- NOTE: we don't have any production deals with unweighted info atm.
    <span *ngIf="(dealDetails.estimatedTotals$ | async)?.unweighted.length">
      <span class="fieldTitle">Also</span>
      <span ng-repeat="i in (dealDetails.estimatedTotals$ | async)?.unweighted" style="line-height: 50px">
        {{deal.products[i].supplier.weight | measure:deal.products[i].supplier.measure_id}}
        of
        {{$ctrl.products[deal.products[i].product_id].name}}
        <span *ngIf="!$last">, </span>
      </span>
    </span> -->
  </mat-form-field>
</ng-template>

<ng-template #actualWeight>
  <mat-form-field>
    <mat-label>Total Actual Weight</mat-label>
    <input readonly matInput [value]="(dealDetails.actualTotals$ | async)?.weight.amount">
    <!-- NOTE: we don't have any production deals with unweighted info atm.
    <span *ngIf="(dealDetails.actualTotals$ | async)?.unweighted.length">
      <span class="fieldTitle">Also</span>
      <span ng-repeat="i in (dealDetails.actualTotals$ | async)?.unweighted" style="line-height: 50px">
        {{deal.products[i].supplier.weight | measure:deal.products[i].supplier.measure_id}}
        of
        {{$ctrl.products[deal.products[i].product_id].name}}
        <span *ngIf="!$last">, </span>
      </span>
    </span> -->
  </mat-form-field>
</ng-template>

<ng-template #estimatedCost>
  <mat-form-field>
    <mat-label>Total Estimated Product Cost</mat-label>
    <input readonly matInput
        value="{{(dealDetails.estimatedTotals$ | async)?.buy_currency}} {{(dealDetails.estimatedTotals$ | async)?.buy_price | currency:(dealDetails.estimatedTotals$ | async)?.buy_currency:'symbol-narrow':'1.0-0'}}">
    <div class="secondary-input">
      CAD {{(dealDetails.estimatedTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow':'1.0-0'}}</div>
  </mat-form-field>
</ng-template>

<ng-template #estimatedRevenue>
  <mat-form-field>
    <mat-label>Total Estimated Product Revenue</mat-label>
    <input readonly matInput
        value="{{(dealDetails.estimatedTotals$ | async)?.sell_currency}} {{(dealDetails.estimatedTotals$ | async)?.sell_price | currency:(dealDetails.estimatedTotals$ | async)?.sell_currency:'symbol-narrow':'1.0-0'}}">
    <div class="secondary-input">
      CAD {{(dealDetails.estimatedTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow':'1.0-0'}}</div>
  </mat-form-field>
</ng-template>

<ng-template #actualCost>
  <mat-form-field>
    <mat-label>Total Actual Product Cost</mat-label>
    <input readonly matInput
        value="{{(dealDetails.actualTotals$ | async)?.buy_currency}} {{(dealDetails.actualTotals$ | async)?.buy_price | currency:(dealDetails.actualTotals$ | async)?.buy_currency:'symbol-narrow':'1.0-0'}}">
    <div class="secondary-input">
      CAD {{(dealDetails.actualTotals$ | async)?.buy_price_cad | currency:'CAD':'symbol-narrow':'1.0-0'}}</div>
  </mat-form-field>
</ng-template>

<ng-template #actualRevenue>
  <mat-form-field>
    <mat-label>Total Actual Product Revenue</mat-label>
    <input readonly matInput
        value="{{(dealDetails.actualTotals$ | async)?.sell_currency}} {{(dealDetails.actualTotals$ | async)?.sell_price | currency:(dealDetails.actualTotals$ | async)?.sell_currency:'symbol-narrow':'1.0-0'}}">
    <div class="secondary-input">
      CAD {{(dealDetails.actualTotals$ | async)?.sell_price_cad | currency:'CAD':'symbol-narrow':'1.0-0'}}</div>
  </mat-form-field>
</ng-template>
