import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { AccountObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as AccountActions from './account.actions'

export const accountsFeatureKey = 'accounts'
export interface AccountsState extends EntityState<DeepReadonly<AccountObject>> {
}

export const selectId = (acc: AccountObject) => acc.account
export const adapter = createEntityAdapter<DeepReadonly<AccountObject>>({ selectId })

const initialState: AccountsState = adapter.getInitialState({
})

export const accountsReducer = createReducer(
  initialState,
  on(AccountActions.loadAccountsSuccess,
    (state, action) =>
      adapter.upsertMany(action.accounts, state)),
  on(AccountActions.patchAccountSuccess,
    (state, action) =>
      adapter.upsertOne(action.account, state)),
  on(AccountActions.reassignAccountsSuccess,
    (state, action) =>
      adapter.upsertMany(action.accounts, state)),
)

