import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { DealRow, DealViewDto } from '@tradecafe/types/core'
import { lookupSegments, printPaymentTerms } from '@tradecafe/types/utils'
import { map as _map, compact, find, last, maxBy, sortBy, uniq, uniqBy, without } from 'lodash-es'
import { from, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { DealApiService } from 'src/api/deal'
import { prepareDealsPayload } from 'src/app/store/deal-view-filters.utils'
import { loadWrappingTypes, selectWrappingTypeEntities } from 'src/app/store/wrapping-types'
import { DEAL_COLUMNS_BOOLEAN, DEAL_COLUMNS_INTERNAL } from 'src/components/deals-list/deals-list.columns'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { ElasticSearchFilters, ElasticSearchPayload, ElasticSearchService } from '../elastic-search'
import { closingDate, DealViewService } from './deal-view.service'

@Injectable()
export class DealElasticSearchService extends ElasticSearchService {

  constructor(
    private DealApi: DealApiService,
    private DealViewSrvc: DealViewService,
    private store: Store,
  ) {
    super()
  }

  readonly wrappingTypes$ = this.store.pipe(select(selectWrappingTypeEntities), waitNotEmpty())

  fetchDealFilters({ query = {}, columns }: { query?: any, columns?: string[] }): Observable<ElasticSearchFilters> {
    columns = without(columns, ...DEAL_COLUMNS_INTERNAL.map(x => x.field))
    return this.DealApi.dealsFilters({ query, columns })
      .pipe(map(res => this.getViewFilters(res, DEAL_COLUMNS_BOOLEAN)))
  }

  fetchDeals(payload: ElasticSearchPayload) {
    const columns = without(payload.columns, ...DEAL_COLUMNS_INTERNAL.map(x => x.field))
    if (columns?.includes('deringer_note_warning') || columns?.includes('note_warning')) {
      columns.push('notes');
    }
    if (columns?.includes('wrappings')) {
      this.store.dispatch(loadWrappingTypes({}))
    }
    if (columns.length) {
      payload.columns = uniq([
        ...columns,
        'parties',
        'export_reports',
        'macropoint_order',
        'can_create_prepayment',
        'int_cred_avail',
        'int_cred_max',
      ])
    }
    return this.DealApi.dealsSearch(payload).pipe(map(({ total, totals, hits }) =>
      ({ total, totals, data: this.buildDealRows(hits) })))
  }

  fetchDeal(deal_id: string, columns?: string[]) {
    return this.fetchDeals({ query: { deal_id }, columns, skip: 0, limit: 2 }).pipe(map(({ data }) =>
      data.find(deal => deal.deal_id.endsWith(deal_id))))
  }

  fetchDealsByIds(deal_ids: string[], columns?: string[]) {
    return this.fetchAllDeals({ deal_ids, columns }, deal_ids.length)
  }

  fetchAllDeals(filters: any, total?: number) {
    const limit = 100
    filters = { ...filters, limit }
    return from(this.fetchAll(limit, total, (page: number) =>
      this.fetchDeals(prepareDealsPayload(filters, page)).toPromise()))
  }


  private buildDealRows(hits: DealViewDto[]) {
    // tslint:disable-next-line: cyclomatic-complexity
    return hits.map(({
      deal: { int_cred_avail, ...deal }, product, segments = [], pickup_date, pickup_date_time, dropoff_date, dropoff_date_time, origin_country,
      note_warning, note_flags, notes, eta, eta_time, etd, etd_time, truck_refer_id, pickup_location, dropoff_location,
      booking_id, container_number, buyer_invoice, coordinator, total_weight,
      exact_loading_address, exact_dropoff_address, forwarder, carrier, buyer,
      buying_trader, selling_trader, supplier_invoice, supplier, dest_location,
      prepayment_invoice, port_of_discharge, port_of_loading, buyer_credit_pool,
      status_notes, courier_track_id, parties, actual_weight_sell,
      vessel_carrier, origin_location, dest_country, gross_weight,
      est_delivery_date, est_delivery_date_time, est_pickup_date, est_pickup_date_time, supplier_invoice_status,
      deringer, documents_count, docs_country, booking, freight_rates_amount, export_reports, macropoint_order, bids,
      shipment_status, can_create_prepayment, int_cred_max, packings, wrappings
    }) => {
      const {earliest, latest, earliestTruck, latestTruck, earliestVessel, latestVessel} = lookupSegments(segments)
      const earliestVesselOrTruck = earliestVessel || earliestTruck


      if (coordinator) {
        coordinator.fullname = `${coordinator.firstname || ''} ${coordinator.lastname || ''}`.trim()
      }
      if (buying_trader) {
        buying_trader.fullname = `${buying_trader.firstname || ''} ${buying_trader.lastname || ''}`.trim()
      }
      if (selling_trader) {
        selling_trader.fullname = `${selling_trader.firstname || ''} ${selling_trader.lastname || ''}`.trim()
      }

      if (product) {
        product = { ...product, hs_code: compact(product.hs_code) }
        if (product.sell_price.formula) {
          product.sell_price.formula = (product.sell_price.formula as any as string[]).join('\n')
        }
        if (product.buy_price.formula) {
          product.buy_price.formula = (product.buy_price.formula as any as string[]).join('\n')
        }
      }

      const deringerWarningNotes = notes?.filter(note => !note?.attributes?.ignored &&
        (note?.attributes?.category === 'general' || note?.attributes?.category === 'general_shipping'))

      const activeExportReportRequest = last(export_reports?.[0]?.requests)
      const activeExportReportResponse = last(export_reports?.[0]?.responses)

      const activeBid = bids && bids[0]
      const wrappingId = activeBid?.wrapping
      const packageSize = activeBid?.attributes.package_size
      const deliveryTime = est_delivery_date
      const pickupTime = pickup_date
      let wrapping

      if (wrappingId) {
        this.wrappingTypes$.pipe(take(1)).subscribe((wrappingTypes) => {
          wrapping = wrappingTypes[wrappingId].name
        })
      }

      return {
        ...this.DealViewSrvc.createDealView(deal), // add methods
        export_reports,
        active_export_report_request: activeExportReportRequest,
        active_export_report_response: activeExportReportResponse,
        product,
        pickup_date,
        pickup_date_time,
        dropoff_date,
        dropoff_date_time,
        eta,
        eta_time,
        etd,
        etd_time,
        booking_id,
        container_number,
        courier_track_id,
        courier_track_nums: uniq(compact(_map(courier_track_id, 'number'))),
        note_warning,
        deringer_note_warning: note_flags?.deringer_warning,
        newest_deringer_warning_note: maxBy(deringerWarningNotes, 'created'),
        buyer_invoice,
        supplier_invoice,
        coordinator,
        exact_loading_address,
        exact_dropoff_address,
        carrier,
        forwarder,
        total_weight,
        buying_trader,
        selling_trader,
        buyer,
        supplier,
        dest_location_name: dest_location?.name,
        origin_location_name: origin_location?.name,
        origin_country: origin_country?.name,
        segments,
        earliestSegment: earliest,
        latestSegment: latest,
        earliestTruckSegment: earliestTruck,
        latestTruckSegment: latestTruck,
        earliestVesselSegment: earliestVessel,
        latestVesselSegment: latestVessel,
        earliestVesselOrTruckSegment: earliestVesselOrTruck,
        truck_refer_id,
        pickup_location,
        dropoff_location,
        prepayment_invoice,
        port_of_discharge,
        port_of_loading,
        buyer_credit_pool,
        actual_weight_sell,
        vessel_carrier,
        dest_country,
        gross_weight,
        est_delivery_date,
        est_delivery_date_time,
        est_pickup_date,
        est_pickup_date_time,
        supplier_invoice_status,
        deringer,
        documents_count,
        docs_country,
        closingDate: closingDate({ ...deal, earliestSegment: earliest }),
        payment_terms_supplier: printPaymentTerms(deal.attributes.supplier_payment_terms),
        payment_terms_buyer: printPaymentTerms(deal.attributes.buyer_payment_terms),
        statusNotes: sortBy(status_notes, 'ranking').map(n => n.code).join(', '),
        parties: uniqBy(parties, 'account').map(x => ({ ...x, account: parseFloat(x.account as any as string) })),
        // we export rates with deal-clones.csv
        fx_bid_rate: deal.attributes.estimated?.sell_currency === 'CAD'
          ? '1 CAD/CAD'
          : `${deal.attributes?.fx_rates?.rates?.[deal.attributes.estimated?.sell_currency]?.[deal.attributes.fx_rates_bid_range || 'spot']?.bid || 'unknown'} ${deal.attributes.estimated?.sell_currency}/CAD`,
        fx_ask_rate: deal.attributes.estimated?.buy_currency === 'CAD'
          ? '1 CAD/CAD'
          : `${deal.attributes?.fx_rates?.rates?.[deal.attributes.estimated?.buy_currency]?.[deal.attributes.fx_rates_ask_range || 'spot']?.ask || 'unknown'} ${deal.attributes.estimated?.buy_currency}/CAD`,
        seq: deal.attributes.clone_sequence || 0,
        booking,
        freight_rates_amount,
        foreign_invoice_number_mx: find(deal?.foreign_invoices, {country_code: 'MX'}),
        status_notes,
        note_flags,
        int_cred_avail,
        int_cred_max,
        notes,
        wrapping,
        packageSize,
        deliveryTime,
        pickupTime,
        shipment_status: shipment_status?.[0],
        can_create_prepayment,
        macropoint_order,
        macropoint_latest_address: macropoint_order?.latest_location_full_address,
        packageTypes: packings?.map(p => p.name).join(', '),
        wrappings: wrappings?.map(w => w.name).join(', '),
      } as DealRow
    })
  }
}
