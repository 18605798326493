import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Cost, CreditNote, Deal, DealViewRaw, DealViewStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, identity } from 'lodash-es'
import { BehaviorSubject, ReplaySubject, combineLatest, from } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts'
import { environment } from 'src/environments/environment'
import { CreditNotesService } from 'src/services/data/credit-notes.service'
import { DealsService } from 'src/services/data/deals.service'
import { NotesService } from 'src/services/data/notes.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


export interface CreditNoteFormOptions {
  title: string
  creditNote: DeepReadonly<Partial<CreditNote>>
  dealViewRaw?: DeepReadonly<DealViewRaw>
  isNew?: boolean
  isReadonly?: boolean
}

@Component({
  selector: 'tc-credit-note-form',
  templateUrl: './credit-note-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditNoteFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private CreditNotes: CreditNotesService,
    private Deals: DealsService,
    private Notes: NotesService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private dialogData: CreditNoteFormOptions,
    private dialogRef: MatDialogRef<CreditNoteFormComponent, CreditNote>
  ) { super() }

  cnForm = new FormGroup({
    cost_id: new FormControl(this.dialogData.creditNote.cost_id, Validators.required),
    deal_id: new FormControl(this.dialogData.creditNote.deal_id, Validators.required),
    amount: new FormControl(this.dialogData.creditNote.amount, Validators.required),
    description: new FormControl(this.dialogData.creditNote.attributes.description),
    createNote: new FormControl(false),
  })

  title = this.dialogData.title
  isNew = this.dialogData.isNew
  isReadonly = this.dialogData.isReadonly
  predefinedDealId = this.dialogData.creditNote.deal_id
  dealViewRaw$ = new ReplaySubject<DeepReadonly<DealViewRaw>>(1)
  costs$ = this.dealViewRaw$.pipe(map(dv => dv.costs?.filter(c => c.type === 'primary')), waitNotEmpty())

  inProgress$ = new BehaviorSubject<'save'|'loading'>(undefined)

  dealIds$ = new ReplaySubject<string[]>(1)

  currency$ = this.dealViewRaw$.pipe(map(dv =>
    this.dialogData.creditNote.currency ||
    dv.deal.attributes.actual.sell_currency ||
    dv.deal.attributes.estimated.sell_currency))
  private company$ = combineLatest([
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    this.dealViewRaw$,
  ]).pipe(map(([accounts, dv]) =>
    accounts[environment.enableBrokerageDeals && dv.deal.deal_type === 'brokerage'
      ? dv.deal.brokerage.customer
      : dv.deal.buyer_id]))
  protected companyName$ = this.company$.pipe(map(company => company?.name))

  ngOnInit() {
    this.store.dispatch(loadAccounts({}))

    if (this.predefinedDealId) {
      this.dealIds$.next([this.predefinedDealId])
    } else {
      this.inProgress$.next('loading')
      const {role, user_id} = this.AuthApi.currentUser
      from(this.Deals.getDealIds(role === 'trader' ? { trader_user_id: user_id } : {}))
      .pipe(untilComponentDestroyed(this), catchError(err => {
        console.error('Unable to load deal ids.', err)
        this.toaster.error('Unable to load deal ids.', err)
        throw err
      })).subscribe((dealIds) => {
        this.dealIds$.next(dealIds)
      })
    }

    if (this.dialogData.dealViewRaw) {
      this.dealViewRaw$.next(this.dialogData.dealViewRaw)
    } else  {
      this.inProgress$.next('loading')
      const dealId$ = replayForm(this.cnForm.controls.deal_id).pipe(filter(identity), untilComponentDestroyed(this), distinctUntilChanged())
      dealId$.pipe(switchMap(dealId => this.Deals.getDealView(dealId, ['deal', 'costs']))).subscribe(dv => {
        if (dv.status === DealViewStatus.invalid) {
          this.toaster.warning(`Deal ${dv.deal.deal_id} is invalid`)
        } else {
          this.dealViewRaw$.next(dv)
        }
      })
      if (this.isNew && !this.predefinedDealId) {
        dealId$.subscribe(() => {
          this.cnForm.controls.cost_id.reset()
        })
      }
    }

    combineLatest(this.predefinedDealId ? [this.dealIds$, this.dealViewRaw$] : [this.dealIds$]).pipe(take(1), untilComponentDestroyed(this)).subscribe(() => {
      this.inProgress$.next(undefined)
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  async onCostChange(cost: DeepReadonly<Cost>) {
    if (!cost) return
    this.cnForm.controls.amount.setValue(cost.amount.total)
    this.cnForm.controls.amount.markAsDirty()
    this.cnForm.controls.amount.markAsTouched()
  }

  async save() {
    if (this.isReadonly || this.inProgress$.value) return
    this.cnForm.markAllAsTouched()
    this.cnForm.updateValueAndValidity()
    if (!this.cnForm.valid) return

    const creditForm = this.cnForm.value
    let creditNote = cloneDeep(this.dialogData.creditNote) as CreditNote

    this.inProgress$.next('save')
    combineLatest([this.dealViewRaw$, this.company$, this.currency$]).pipe(take(1)).subscribe(async ([dv, company, currency]) => {
      try {
        if (this.isNew) {
          creditNote.account = company.account.toString()
          creditNote.deal_id = creditForm.deal_id
        }
        creditNote.currency = currency
        if (creditForm.cost_id) creditNote.cost_id = creditForm.cost_id
        if (creditForm.amount !== undefined) creditNote.amount = creditForm.amount
        if (creditForm.description !== undefined) creditNote.attributes.description = creditForm.description

        const stored = await this.CreditNotes.saveCreditNote(creditNote, {
          deal: cloneDeep(dv.deal) as Deal,
          cost: cloneDeep(dv.costs.find(c => c.cost_id === creditNote.cost_id)) as Cost,
        })

        if (creditForm.createNote && creditForm.description) {
          this.Notes.createNote({
            visibility: 1,
            body: creditForm.description,
            attributes: {
              credit_note_id: stored.credit_note_id,
              category: stored.cn_id
            },
            deal_id: creditForm.deal_id
          })
        }

        this.dialogRef.close(stored)
      } finally {
        this.inProgress$.next(undefined)
      }
    })
  }
}
