import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { ItemType, ItemTypeSpec, ItemTypeTemp } from '@tradecafe/types/core'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { isEqual } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import { createItemType, createItemTypeFailure, createItemTypeSuccess, updateItemType, updateItemTypeFailure, updateItemTypeSuccess } from 'src/app/store/item-types'
import { loadPackageTypes, selectAllPackageTypes } from 'src/app/store/package-types'
import { loadProductCategories, selectProteinCategories } from 'src/app/store/product-categories'
import { loadProductTypes, selectAllProductTypes } from 'src/app/store/product-types'
import { loadProducts, selectProteinProductOptions } from 'src/app/store/products'
import { loadWrappingTypes, selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { DeepReadonly } from 'udsv'


export interface ItemTypeFormOptions {
  title: string
  itemType?: DeepReadonly<ItemType>
}

@Component({
  selector: 'tc-item-type-form',
  templateUrl: './item-type-form.component.html',
  styleUrl: './item-type-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemTypeFormComponent extends OnDestroyMixin implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA)
    private readonly dialogData: ItemTypeFormOptions,
    private readonly dialogRef: MatDialogRef<ItemTypeFormComponent, ItemType>,
    private readonly store: Store,
    private readonly actions$: Actions,
  ) { super() }

  protected readonly title = this.dialogData.title

  // form & state
  protected readonly itForm = new FormGroup({
    name: new FormControl<string>(this.dialogData.itemType?.name, [Validators.required]),
    spanishName: new FormControl<string>(this.dialogData.itemType?.attributes.spanish_name),
    tempF: new FormControl<number>(this.dialogData.itemType?.attributes.temperature.F, [Validators.required]),
    tempC: new FormControl<number>(this.dialogData.itemType?.attributes.temperature.C, [Validators.required]),
  })
  protected readonly specsForm = new FormArray(this.dialogData.itemType?.attributes.specifications?.map(spec => this.newSpecFormGroup(spec)) || [])
  protected readonly tempsForm = new FormArray(this.dialogData.itemType?.attributes.temp_overrides?.map(temp => this.newTempFormGroup(temp)) || [])
  protected readonly inProgress$ = new BehaviorSubject<'loading'|'saving'|undefined>('loading')

  // ref data
  protected readonly products$ = (selectedProductId?: string | string[]) =>
    this.store.pipe(select(selectProteinProductOptions(selectedProductId)), waitNotEmpty())
  protected readonly categories$ = (selectedCategoryId?: string | string[]) =>
    this.store.pipe(select(selectProteinCategories(selectedCategoryId)), waitNotEmpty())
  protected readonly types$ = this.store.pipe(select(selectAllProductTypes), waitNotEmpty())
  protected readonly wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty())
  protected readonly packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty())
  protected readonly accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty())

  ngOnInit(): void {
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadWrappingTypes({}))
    this.store.dispatch(loadPackageTypes({}))

    combineLatest([
      this.accounts$,
      this.products$(),
      this.categories$(),
      this.types$,
      this.wrappings$,
      this.packageTypes$,
    ]).pipe(take(1)).subscribe(() => {
      this.inProgress$.next(undefined)
    })

    combineLatest([
      this.actions$.pipe(ofType(updateItemTypeSuccess, createItemTypeSuccess), tap(a => this.dialogRef.close(a.itemType))),
      this.actions$.pipe(ofType(updateItemTypeSuccess, createItemTypeSuccess, updateItemTypeFailure, createItemTypeFailure), tap(() => this.inProgress$.next(undefined))),
    ]).pipe(untilComponentDestroyed(this)).subscribe()
  }

  private newSpecFormGroup(spec?: DeepReadonly<ItemTypeSpec>) {
    return new FormGroup({
      category: new FormControl(spec?.category, Validators.required),
      type: new FormControl(spec?.type as string[]),
      products: new FormControl(spec?.products as string[]),
    })
  }

  private newTempFormGroup(temp?: DeepReadonly<ItemTypeTemp>) {
    return new FormGroup({
      account: new FormControl(temp?.account, Validators.required),
      category_id: new FormControl(temp?.category_id),
      type_id: new FormControl(temp?.type_id),
      product_id: new FormControl(temp?.product_id),
      wrapping_id: new FormControl(temp?.wrapping_id),
      package_id: new FormControl(temp?.package_id),
      F: new FormControl(temp?.F, Validators.required),
      C: new FormControl(temp?.C, Validators.required),
    })
  }

  protected typesForCategory$ = (category: FormControl<string>) => {
    return replayForm(category).pipe(
      distinctUntilChanged(),
      switchMap(categoryId => this.types$.pipe(map(types => types.filter(t => t.category_id === categoryId)))))
  }

  protected filteredProducts$ = (spec: ReturnType<typeof this.newSpecFormGroup>) => {
    return replayForm(spec).pipe(
      distinctUntilChanged(isEqual),
      switchMap(({ type, products }) => this.products$(products).pipe(map(products => products.filter(t => type?.includes(t.type_id))))))
  }

  protected addSpec() {
    this.specsForm.push(this.newSpecFormGroup())
  }

  protected removeSpec(index: number) {
    this.specsForm.removeAt(index)
  }

  protected addTemp() {
    this.tempsForm.push(this.newTempFormGroup())
  }

  protected removeTemp(index: number) {
    this.tempsForm.removeAt(index)
  }

  private readForm(): Partial<DeepReadonly<ItemType>> {
    const itForm = this.itForm.getRawValue()
    const specsForm = this.specsForm.getRawValue()
    const tempsForm = this.tempsForm.getRawValue()

    return {
      name: itForm.name,
      attributes: {
        spanish_name: itForm.spanishName,
        temperature: {
          F: itForm.tempF,
          C: itForm.tempC,
        },
        temp_overrides: tempsForm,
        specifications: specsForm,
      }
    }
  }

  protected save() {
    if (this.inProgress$.value) return
    this.itForm.markAllAsTouched()
    this.itForm.updateValueAndValidity()
    this.specsForm.markAllAsTouched()
    this.specsForm.updateValueAndValidity()
    this.tempsForm.markAllAsTouched()
    this.tempsForm.updateValueAndValidity()
    if (!this.itForm.valid || !this.specsForm.valid || !this.tempsForm.valid) return

    const id = this.dialogData.itemType?.item_type_id
    const itemType = this.readForm()

    this.inProgress$.next('saving')
    this.store.dispatch(id ? updateItemType({ id, itemType }) : createItemType({ itemType }))
  }

  protected cancel() {
    this.dialogRef.close()
  }

  protected onListDrop(event: CdkDragDrop<any>) {
    moveItemInArray(this.tempsForm.controls, event.previousIndex, event.currentIndex)
  }
}
