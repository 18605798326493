import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { AccountObject, AllInvoiceStatuses, CREDIT_NOTE_VOIDED, Cost, CostType, CreditNote, DealBase, DealViewBase, DealViewCosts, DealViewInvoices, DealViewRaw, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawStatus, ForexData, ForexRange, INVOICE_ACTUALIZED, Invoice, LocationObject, MatchedOffer, ShipmentRate, User, VENDOR_CREDIT_VOIDED, VendorCredit } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, RatesContainer, amountOf, isDealConfirmed } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, filter, map, reject, sortBy, sumBy } from 'lodash-es'
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { ShipmentRateApiService } from 'src/api/shipment-routing/shipment-rate'
import { selectAccountEntities } from 'src/app/store/accounts'
import { actualizeCostToZero, unactualizeCostToZero } from 'src/app/store/deal-view.actions'
import { selectLocationEntities } from 'src/app/store/locations'
import { selectUserEntities } from 'src/app/store/users'
import { VendorCreditFormService } from 'src/components/credits/vendor-credit/vendor-credit-form.service'
import { InvoiceViewFormService } from 'src/components/invoices/invoice-view-form/invoice-view-form.service'
import { MatchedOfferOverlayService } from 'src/pages/admin/auction/matched-offer-overlay/matched-offer-overlay.service'
import { MatchedOffersService } from 'src/pages/admin/auction/matched-offer-overlay/matched-offers.service'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { FreightRatesService } from 'src/pages/admin/logistics/freight-rates/freight-rates.service'
import { CostFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/cost-form/cost-form.service'
import { CostFormGroup, CostsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { canActualizeCost, canUnactualizeCost } from 'src/services/data/costs.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { ConfirmModalService } from '../confirm/confirm-modal.service'
import { CreditNoteFormService } from '../credits/credit-note-form/credit-note-form.service'
import { COST_COLUMN_NAMES, DEFAULT_COLUMNS } from './costs-list.columns'

export interface CostRow {
  costForm?: CostFormGroup
  originalCost: DeepReadonly<Partial<Cost>>
  originalCredit?: DeepReadonly<(CreditNote | VendorCredit) & { deal?: DealViewBase & DealViewInvoices & DealViewCosts }>
  canUpdate?: boolean
  canDelete: boolean
  canActualize?: boolean
  canUnactualize?: boolean
  type: string
  created: number
  createdBy: string
  secondaryType?: string
  secondaryValue?: number
  actAmt: number
  service: string
  provider: string
  actCurrency: string
  estAmt?: number
  estCurrency?: string
  fxRate: { rate: number, code: string }
  actAmtCAD: number
  estAmtCAD?: number
  partialAmtCAD: number
  costUnit?: number
  invoice: DeepReadonly<Invoice>
  invoiceId: string
  invoiceStatus: number
  actualizedByUserName?: string
  costId: string

  origin?: string
  destination?: string
  portLoading?: string
  portDischarge?: string
  dealSource?: string
  matchedOfferSource?: string
  hasFreightRate?: boolean
}


@Component({
  selector: 'tc-costs-list',
  templateUrl: './costs-list.component.html',
  styleUrls: ['./costs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostsListComponent extends OnDestroyMixin implements OnInit, OnChanges {
  constructor(
    private CostForm: CostFormService,
    private FxRates: FxRatesService,
    private InvoiceViewForm: InvoiceViewFormService,
    private CreditNoteForm: CreditNoteFormService,
    private VendorCreditForm: VendorCreditFormService,
    private store: Store,
    private AuthApi: AuthApiService,
    private ConfirmModal: ConfirmModalService,
    private readonly MatchedOffers: MatchedOffersService,
    private readonly MatchedOfferOverlay: MatchedOfferOverlayService,
    private readonly ShipmentRateApi: ShipmentRateApiService,
    private readonly FreightRates: FreightRatesService,
    private readonly toaster: ToasterService,
  ) { super() }

  readonly InvoiceStatus = AllInvoiceStatuses

  @HostBinding('class')
  class = 'tc-costs-list'

  @Input() costsForm: CostsFormGroup

  // dynamic data
  dataSource = new MatTableDataSource<CostRow>()

  // @Input() dataSource: StatefulDataSource<CostRow>
  @Input() dealViewRaw?: DeepReadonly<DealViewRawDeal & DealViewRawInvoices & DealViewRawCosts & DealViewRawStatus>
  @Input() oldDealView?: DeepReadonly<DealViewBase & DealViewInvoices & DealViewCosts> // required for creditNotes and vendorCredits
  @Input() matchedOffer?: DeepReadonly<MatchedOffer> // very optional. used for Add Freight action
  @Input() supplierId: string
  @Input() buyerId: string
  @Input() fxRates: ForexData
  @Input() fxRatesRange: ForexRange
  @Input() creditNotes: DeepReadonly<CreditNote[]>
  @Input() vendorCredits: DeepReadonly<VendorCredit[]>
  @Input() units: string

  // settings
  @Input() types: CostType[] = ['secondary', 'tertiary']
  @Input() readonly = false
  @Input() displayColumns = DEFAULT_COLUMNS['default']
  @Input() columnNames: Dictionary<string> = COST_COLUMN_NAMES
  @Input() filter?
  @Input() footer = true

  // referenced data
  private locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
  @Input() shipmentRates$: Observable<Dictionary<ShipmentRate>>

  // output events
  @Output() updateCost = new EventEmitter<DeepReadonly<Partial<Cost>>>()
  @Output() removeCost = new EventEmitter<DeepReadonly<Partial<Cost>>>()
  @Output() creditNotesChanged = new EventEmitter<CreditNote>()
  @Output() vendorCreditsChanged = new EventEmitter<VendorCredit>()
  @Output() rowClick = new EventEmitter<{ cost: CostRow, column?: string, tabName?: string }>()

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator }
  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.sort = sort }

  totalActAmtCAD$ = new ReplaySubject<number>(1)
  totalEstAmtCAD$ = new ReplaySubject<number>(1)
  totalPartialAmtCAD$ = new ReplaySubject<number>(1)
  totalUnitCost$ = new ReplaySubject<number>(1)

  // table settings
  selection = new TableSelection<CostRow>('costId')

  ngOnChanges(change: SimpleChanges) {
    if (change.costs || change.creditNotes || change.vendorCredits || change.types) {
      this.reloadData()
    }
    if (change.dealViewRaw) {
      const dv = change.dealViewRaw.currentValue
      this.units = this.units || dv?.deal.attributes.estimated.weight.measure_id
    }
  }

  ngOnInit() {
    replayForm(this.costsForm).pipe(untilComponentDestroyed(this)).subscribe(() => this.reloadData())
    if (!this.rowClick.length) {
      this.rowClick.pipe(untilComponentDestroyed(this)).subscribe(({ cost }) => {
        if (this.readonly) {
          if (this.displayColumns.includes('select')) this.selection.toggleRow(cost)
        } else {
          this.showUpdateItem(cost)
        }
      })
    }
  }

  getRowId(_i: number, cost: CostRow) {
    // undefined cost = fake. loading screen.
    return cost?.originalCost?.cost_id || cost?.originalCredit?.credit_note_id
  }

  private reloadData() {
    combineLatest([this.accounts$, this.users$, this.locations$, this.shipmentRates$ || of({})])
    .pipe(take(1))
    .subscribe(([accounts, users, locations, shipmentRates]) => {
      const costs = this.costsForm.controls.filter(c => this.types.includes(c.value.cost.type))
      this.setGridData(costs, accounts, users, locations, shipmentRates)
    })
  }

  showInvoice(invoice) {
    return this.InvoiceViewForm.showInvoice(invoice)
  }

  async showUpdateItem(row: CostRow) {
    if (this.readonly) return
    if (row.type === 'secondary') return // ignore row clicks
    if (row.type === 'Credit Note') {
      const originalCredit = { ...row.originalCredit, deal: this.oldDealView }
      const creditNote = await this.CreditNoteForm.showUpdateItemImmutable(originalCredit as DeepReadonly<CreditNote>)
      if (creditNote) this.creditNotesChanged.next(creditNote)
    } else if (row.type === 'Vendor Credit' || row.type === 'Prepayment Credit') {
      const originalCredit: DeepReadonly<VendorCredit> = { ...row.originalCredit, deal: this.oldDealView } as any
      const vendorCredit = await this.VendorCreditForm.showUpdateItemImmutable(
        originalCredit, originalCredit.attributes?.prepayment)
      if (vendorCredit) this.vendorCreditsChanged.next(vendorCredit)
    } else {
      const cost = await this.CostForm.showUpdateCost({
        deal_id: this.oldDealView?.deal_id,
        status: this.oldDealView?.status,
        supplier_id: this.supplierId,
        buyer_id: this.buyerId,
      },
      row.originalCost, row.actualizedByUserName
      )
      if (cost) this.updateCost.next(cost)
    }
  }

  async showDeleteItem(row: CostRow) {
    if (row.type === 'Credit Note') {
      await this.CreditNoteForm.showVoidCreditNoteImmutable(row.originalCredit as CreditNote)
      this.creditNotesChanged.next(undefined)
    } else if (row.type === 'Vendor Credit' || row.type === 'Prepayment Credit') {
      await this.VendorCreditForm.showVoidVendorCreditImmutable(row.originalCredit as VendorCredit)
      this.vendorCreditsChanged.next(undefined)
    } else if (row.type !== 'secondary') {
      const cost = row.originalCost
      const idx = this.costsForm.controls.indexOf(row.costForm)
      if (idx === -1) return
      this.removeCost.next(cost)
    }
  }

  protected async showEditOffer(matchedOfferId: string) {
    const offer = await this.MatchedOffers.getById(matchedOfferId)
    this.MatchedOfferOverlay.showEditOffer(offer)
  }

  private buildCostRow(
    costForm: CostFormGroup,
    accounts: Dictionary<DeepReadonly<AccountObject>>,
    users: DeepReadonly<Dictionary<User>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>,
  ): CostRow {
    const { role } = this.AuthApi.currentUser
    const { cost } = costForm.value
    const invoice = getCostInvoice(this.oldDealView, cost)
    const rates: RatesContainer = this.dealViewRaw?.deal
      || { attributes: { fx_rates: this.fxRates, fx_rates_ask_range: this.fxRatesRange, fx_rates_bid_range: this.fxRatesRange }}
    const { partial_cad, estimated_cad, actual_cad } = amountOf(rates, cost as Cost)

    return {
      costForm,
      canUpdate: true,
      canDelete: !this.readonly && this.canDeleteCost(cost, this.dealViewRaw?.deal, invoice),
      originalCost: cost,
      canActualize: this.dealViewRaw?.deal && !this.readonly && role !== 'trader' && canActualizeCost(cost, this.dealViewRaw?.invoices),
      canUnactualize: this.dealViewRaw?.deal && !this.readonly && role !== 'trader' && canUnactualizeCost(cost),
      type: cost.type,
      created: cost.created,
      createdBy: users[cost.cost_id ? cost.creator_id : this.AuthApi.currentUser.user_id]?.fullname,
      secondaryType: cost.attributes.default_cost?.type,
      secondaryValue: cost.attributes.default_cost?.value,
      actAmt: cost.attributes.actual_amount,
      service: cost.service,
      provider: accounts[cost.provider]?.name,
      actCurrency: cost.attributes.actual_currency,
      estAmt: cost.amount.total,
      estCurrency: cost.amount.currency,
      fxRate: this.fx(cost.amount.currency),
      actAmtCAD: actual_cad,
      estAmtCAD: estimated_cad,
      partialAmtCAD: partial_cad,
      costUnit: cost.amount.cost_unit,
      invoice: invoice,
      invoiceId: invoice?.invoice_id,
      invoiceStatus: invoice?.status,
      actualizedByUserName: cost.actualized_by ? users[cost.actualized_by]?.fullname : null,
      costId: cost.cost_id,
      // matched offers costs columns
      origin: locations[shipmentRates[cost.attributes.rate_id]?.origin_id]?.name,
      destination: locations[shipmentRates[cost.attributes.rate_id]?.destination_id]?.name,
      portLoading: shipmentRates[cost.attributes.rate_id]?.port_loading,
      portDischarge: shipmentRates[cost.attributes.rate_id]?.port_discharge,
      dealSource: cost['deal_source'],
      matchedOfferSource: cost['source']?.type === 'matched_offer' ? cost['source'].source_id : undefined,
      hasFreightRate: cost['source']?.type === 'freight-rate'
    }
  }

  private canDeleteCost(_cost: unknown, deal?: DeepReadonly<DealBase>, invoice?: DeepReadonly<Invoice>) {
    const { role } = this.AuthApi.currentUser
    // super users can remove costs no matter what
    if (role === 'manager' || role === 'superuser') return true
    // other users can't remove costs from a confirmed deal
    if (deal && isDealConfirmed(deal)) return false
    // also other users can't remove cost if it has associated invoice in status actualized or later
    return !invoice || invoice.status < INVOICE_ACTUALIZED
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private buildCreditRow(
    { creditNote, vendorCredit }: { creditNote?: CreditNote, vendorCredit?: VendorCredit },
    costForm: CostFormGroup,
    accounts: Dictionary<DeepReadonly<AccountObject>>,
    users: DeepReadonly<Dictionary<User>>,
  ): CostRow {
    const { cost: originalCost } = costForm.value
    const type = creditNote ? 'Credit Note' : (vendorCredit.attributes?.prepayment ? 'Prepayment Credit' : 'Vendor Credit')
    const originalCredit = creditNote || vendorCredit
    const actAmt = creditNote ? creditNote.amount : - vendorCredit.amount
    const invoice = getCostInvoice(this.oldDealView, originalCost)
    const actCurrency = creditNote?.currency || vendorCredit?.currency || (creditNote
      ? this.dealViewRaw?.deal.attributes.estimated.sell_currency
      : originalCost.attributes.actual_currency || originalCost.amount.currency)
    const actAmtCAD = (actCurrency === 'CAD' ? actAmt : actAmt * this.fx(actCurrency)?.rate) || undefined
    return {
      type,
      costForm,
      originalCost,
      originalCredit,
      // 
      canDelete: creditNote?.status === 0 || creditNote?.status === 3 ? false : true,
      canUpdate: creditNote ? false : true,
      created: originalCredit.created,
      createdBy: users[originalCredit.user_id]?.fullname,
      service: originalCredit.attributes.description || originalCost.service,
      // NOTE: WA-7397, WA-5221: credit notes don't have `provider` field. replacing it with `account`
      // provider: accounts[credit.provider]?.name,
      provider: accounts[originalCredit.account]?.name,
      actAmt,
      actCurrency,
      fxRate: this.fx(actCurrency),
      partialAmtCAD: actAmtCAD,
      actAmtCAD,
      invoice,
      invoiceId: creditNote ? creditNote.cn_id : vendorCredit.credit_note_id,
      invoiceStatus: invoice?.status,
      costId: originalCost.cost_id
    }
  }

  private setGridData(
    costs: CostFormGroup[],
    accounts: Dictionary<DeepReadonly<AccountObject>>,
    users: DeepReadonly<Dictionary<User>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>,
  ) {
    let rows = compact(
      [
        ...map(costs, cost => this.buildCostRow(cost, accounts, users, locations, shipmentRates)),
        ...map(
          reject(this.creditNotes, { status: CREDIT_NOTE_VOIDED }),
          creditNote => {
            const costForm = this.costsForm.controls.find(c => c.value.cost.cost_id === creditNote.cost_id)
            return costForm && this.buildCreditRow({ creditNote }, costForm, accounts, users)
          }
        ),
        ...map(
          reject(this.vendorCredits, { status: VENDOR_CREDIT_VOIDED }),
          vendorCredit => {
            const costForm = this.costsForm.controls.find(c => c.value.cost.cost_id === vendorCredit.cost_id)
            return costForm && this.buildCreditRow({ vendorCredit }, costForm, accounts, users)
          }
        ),
      ]
    )

    if (this.filter && typeof this.filter === 'function') {
      rows = filter(rows, this.filter) as CostRow[]
    }

    rows = sortBy(rows, this.listItemSortValue)

    this.dataSource.data = rows
    this.totalActAmtCAD$.next(sumBy(rows, 'actAmtCAD'))
    this.totalEstAmtCAD$.next(sumBy(rows, 'estAmtCAD'))
    this.totalPartialAmtCAD$.next(sumBy(rows, 'partialAmtCAD'))
    this.totalUnitCost$.next(sumBy(rows, 'costUnit'))
  }

  private fx(currency: string) {
    const rate = 1 / this.FxRates.fromCADSync(
      1, currency, 'ask',
      this.fxRates.rates,
      this.fxRatesRange || 'spot')
    if (!rate || rate === 1) return undefined
    return {
      rate,
      code: `${currency}CAD (ask)`,
    }
  }

  private listItemSortValue(item: CostRow) {
    const reversedPriority = ['Vendor Credit', 'Prepayment Credit', 'primary', 'secondary', 'tertiary', 'Credit Note'].indexOf(item.type)
    return - parseInt(`${reversedPriority}${item.created}`, 10)
  }

  async showActualizeToZero(row: DeepReadonly<CostRow>) {
    await this.ConfirmModal.show({
      title: 'Actualize Cost to $0?',
      titleIcon: 'fas fa-exclamation-triangle',
      description: `Are you sure you want to actualize cost "${row.service}" to $0?`,
      confirmButtonText: 'Actualize, Zero',
      confirmButtonClass: 'btn-danger',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn-link',
    })
    this.store.dispatch(actualizeCostToZero({ dv: this.dealViewRaw, cost_id: row.originalCost.cost_id }))
  }

  showUnactualizeToZero(row: DeepReadonly<CostRow>) {
    this.store.dispatch(unactualizeCostToZero({ dv: this.dealViewRaw, cost_id: row.originalCost.cost_id }))
  }

  selectedCosts(): CostRow[] {
    return this.dataSource.data.filter((row) => this.selection.selectedIds?.has(row.costId))
  }

  protected async showFreightRate(row: DeepReadonly<CostRow>) {
    const freightRateId = row.originalCost['source'].source_id
    try {
      const response = await this.ShipmentRateApi.get(freightRateId);
      this.FreightRates.showFormModal(response?.data, {
        title: 'Freight Rate Details',
        mode: 'read',
        dealId: this.dealViewRaw?.deal?.deal_id,
        matchedOfferId: this.matchedOffer?.matched_offer_id,
      })
    } catch(err) {
      console.error('Unable to load freight rate', err)
      this.toaster.error('Unable to load freight rate', err)
    }
  }
}

function getCostInvoice(deal: DeepReadonly<DealViewInvoices | DealViewRaw>, cost: DeepReadonly<DeepPartial<Cost>>) {
  const invoiceId = cost.attributes.associated?.invoice_id
  return deal?.invoices?.find(invoice => invoice.invoice_id === invoiceId)
}
