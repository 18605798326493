import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { ProductCategoriesService } from 'src/pages/admin/settings/product-specifications/wrapping-types/product-categories.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadProductCategories, loadProductCategoriesFailure, loadProductCategoriesSuccess } from './product-category.actions'

@Injectable()
export class ProductCategoryEffects {
  constructor(
    private actions$: Actions,
    private productCategories: ProductCategoriesService,
    private toaster: ToasterService,
  ) {}

  loadProductCategories$ = createEffect(() => this.actions$.pipe(
    ofType(loadProductCategories),
    switchMap(action =>
      from(this.productCategories.getProductCategories()).pipe(
        map(productCategories => loadProductCategoriesSuccess({
          tableKey: action.tableKey,
          productCategories: productCategories.data,
        })),
        catchError(error => {
          console.error('Unable to fetch productCategories', error)
          this.toaster.error('Unable to fetch productCategories', error)
          return of(loadProductCategoriesFailure({ error }))
        })))))
}
