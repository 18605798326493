import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatIconModule } from '@angular/material/icon'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { TypeSafeMatCellDefModule } from 'src/components/type-safe-mat-cell-def/type-safe-mat-cell-def.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { AuditLogComponent } from './audit-log.component'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
  declarations: [AuditLogComponent],
  exports: [AuditLogComponent],
  imports: [
    CommonModule,
    PipesModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    ReactiveAsteriskModule,
    FlexLayoutModule,
    TypeSafeMatCellDefModule,
  ],
})
export class AuditLogModule {}
