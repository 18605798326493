import { map, without } from 'lodash-es'
import { ConfigureColumnsColumnDef } from 'src/components/table-view/configure-columns/configure-columns.service'

export interface ColumnDef<K = string> extends ConfigureColumnsColumnDef<K> {
  // v1 fields. compatible with angularjs/ui-grid. compatible with ng1 columns editor
  field: K,
  displayName: string,
  // v2 fields. only Angular (not angularjs)
  exportField?: string
  exportPipe?: string
  index?: number
  internal?: true
  useUtc?: true
}

export function showInternalColumns(available: ColumnDef[], selected: string[]) {
  const left = map(available.filter(def => def.internal && def.index < 0), 'field')
  const right = map(available.filter(def => def.internal && def.index > 0), 'field')
  return [...left, ...without(selected, ...left, ...right), ...right]
}
