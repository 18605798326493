import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  MacropointOrderLogOverlayComponent,
  MacropointOrderLogOverlayOptions,
} from './macropoint-order-log-overlay.component';

@Injectable()
export class MacropointOrderLogOverlayService {
  constructor(private modal: ModalService) {}

  showLogs(order_id: string) {
    return this.modal.openDialog<
      MacropointOrderLogOverlayComponent,
      MacropointOrderLogOverlayOptions,
      void
    >(
      MacropointOrderLogOverlayComponent,
      {
        title: `Log`,
        order_id,
      },
      {
        panelClass: ['tc-dialog', 'modal-xlg'],
        width: null,
        maxWidth: null,
      }
    );
  }
}
