import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ToasterComponent } from './toaster.component'

@Injectable()
export class ToasterService {
  constructor(private snackbar: MatSnackBar) {}

  error(head: string, body?: string|any) {

    //   eg. HTTP response with body = {
    //   "error": {
    //     "code": 404,
    //     "data": null,
    //     "error": {
    //         "code": 404,
    //         "message": "Response Error: Not Found..",
    //         "statusText": "Not Found",
    //         "details": []
    //     }
    //   }

    if (typeof body === 'object') {
      const res = body.data? body.data: body // handling http error
      const gatewayError = res?.error
      const gatewayMsg = gatewayError?.message || ''
      const serviceError = gatewayError?.error
      const serviceMsg = serviceError?.message || ''
      body = serviceMsg || gatewayMsg || undefined
      // TODO: get "error" message from NON http errors
      // body = serviceMsg || gatewayMsg || body.toString()
    }

    this.snackbar.openFromComponent(ToasterComponent, {
      data: { head, body },
      duration: 10000,
      panelClass: `tc-toaster-error`,
    })
  }

  warning(head: string, body?: string) {
    this.snackbar.openFromComponent(ToasterComponent, {
      data: { head, body },
      duration: 10000,
      panelClass: `tc-toaster-warning`,
    })
  }

  success(head: string, body?: string) {
    this.snackbar.openFromComponent(ToasterComponent, {
      data: { head, body },
      duration: 5000,
      panelClass: `tc-toaster-success`,
    })
  }

  info(head: string, body?: string) {
    this.snackbar.openFromComponent(ToasterComponent, {
      data: { head, body },
      duration: 10000,
      panelClass: `tc-toaster-info`,
    })
  }

  progress(head: string, body?: string) {
    this.snackbar.openFromComponent(ToasterComponent, {
      data: { head, body, progress: true },
      panelClass: `tc-toaster-progress`,
    })
  }

  pop(data: { type: string, head?: string, html?: string, duration?: number }) {
    this.snackbar.openFromComponent(ToasterComponent, {
      data,
      duration: data.duration || 10000,
      panelClass: `tc-toaster-${data.type}`,
    })
  }
}
