<form [formGroup]="form" (submit)="save()">
  <h2 mat-dialog-title style="text-transform: capitalize">Change clones</h2>
  
  <div mat-dialog-content gdGap="25px 10px" gdColumns="repeat(4, 1fr)">
    <tc-product-field gdColumn="1 / span 3" gdColumns="1fr 1fr 1fr" gdGap="0 10px"
    [group]="form" ctrlName="product_id" [protein]="true"
    ></tc-product-field>

    <tc-select-search placeholder="Item specification" gdColumn="4 / span 1"
    [group]="form" ctrlName="item_type_id"
    [items]="itemTypes$ | async" bindLabel="name" bindValue="item_type_id"
    ></tc-select-search>

    <tc-select-search placeholder="Pickup" gdColumn="1 / span 2"
      [group]="form" ctrlName="pickup_location_id"
      [items]="locations$ | async" bindValue="location_id" bindLabel="name"
    ></tc-select-search>

    <tc-select-search placeholder="Destination" gdColumn="3 / span 2"
      [group]="form" ctrlName="destination_location_id"
      [items]="locations$ | async" bindValue="location_id" bindLabel="name"
    ></tc-select-search>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-check"></i> Update
    </button>
  </div>
</form>