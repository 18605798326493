import { Injectable } from '@angular/core'
import { ModalProxyService } from 'src/shared/modal'
import { NegativeMarginReasonFormOptions } from './negative-margin-reason-form'

@Injectable()
export class NegativeMarginReasonFormService {
  constructor(private modalHelper: ModalProxyService) {}

  show(options: NegativeMarginReasonFormOptions) {
    return this.modalHelper.showModal({
      component: 'tcNegativeMarginReasonForm',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        options: () => options,
      },
    })
  }
}
