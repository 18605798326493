import { createSelector } from '@ngrx/store'
import { ProductType } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, productTypesFeatureKey } from './product-type.reducer'

export const selectState = (state: State) => state[productTypesFeatureKey]

export const { selectEntities: selectProductTypeEntities, selectAll: selectAllProductTypes } = adapter.getSelectors(selectState)

export const selectProductType = createSelector(
  selectProductTypeEntities,
  (productTypes: Dictionary<ProductType>, productType: string | number) =>
    productTypes[productType])

export const selectProductTypesById = createSelector(
  selectProductTypeEntities,
  (productTypes: Dictionary<ProductType>, productTypeId: string) =>
    productTypes[productTypeId])

export const selectProductTypesByIds = createSelector(
  selectProductTypeEntities,
  (productTypes: Dictionary<ProductType>, productTypeIds: string[]) =>
    productTypeIds.map(productTypeId => productTypes[productTypeId]))
