import { Injectable } from '@angular/core'
import { identity } from 'lodash-es'
import { ModalService } from 'src/shared/modal/modal.service'
import { Observable } from 'rxjs'
import { MarkAsPaidFormComponent } from './mark-as-paid-form.component'
import { filter } from 'rxjs/operators'


@Injectable()
export class MarkAsPaidFormService {
  constructor(private modal: ModalService) { }

  showMarkAsPaid(): Observable<number> {
    return this.modal.openDialog(MarkAsPaidFormComponent, {}, {
      panelClass: ['tc-dialog'],
      width: '450px',
      maxWidth: null,
      autoFocus: false,
      disableClose: true,
    }).pipe(filter(identity))
  }
}
