import { CurrencyPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { AddressPipe } from 'src/filters/address.pipe'
import { BytesPipe } from 'src/filters/bytes.pipe'
import { CallPipe } from 'src/filters/call.pipe'
import { EpochRangePipe } from 'src/filters/epoch-range.pipe'
import { EpochPipe } from 'src/filters/epoch.pipe'
import { FakeIdPipe } from 'src/filters/fake-id.pipe'
import { FullNamePipe } from 'src/filters/fullname.pipe'
import { MeasurePipe } from 'src/filters/measure.pipe'
import { ZeroIfBwiPipe } from 'src/filters/zero-if-bwi.pipe'
import { EpochTimeAgo } from './epoch-from-now.pipe'

@NgModule({
  exports: [EpochTimeAgo, EpochPipe, MeasurePipe, EpochRangePipe, ZeroIfBwiPipe, CallPipe, AddressPipe, FakeIdPipe, FullNamePipe, BytesPipe],
  providers: [CurrencyPipe, MeasurePipe, FakeIdPipe],
  declarations: [EpochTimeAgo, EpochPipe, MeasurePipe, EpochRangePipe, ZeroIfBwiPipe, CallPipe, AddressPipe, FakeIdPipe, FullNamePipe, BytesPipe],
})
export class PipesModule {}
