import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { MatEpochDateModule } from 'src/components/epoch/epoch.module'
import { ModalModule } from 'src/shared/modal'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { CopyFreightRatesFormComponent } from './copy-freight-rates-form.component'
import { CopyFreightRatesFormService } from './copy-freight-rates-form.service'

@NgModule({
  declarations: [CopyFreightRatesFormComponent],
  providers: [CopyFreightRatesFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    EpochFieldModule,
    MatRadioModule,
    ToasterModule,
    MatEpochDateModule,
    ModalModule,
  ],
})
export class CopyFreightRatesFormModule {
}
