import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Department } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as DepartmentActions from './department.actions'

export const departmentsFeatureKey = 'departments'
export interface DepartmentsState extends EntityState<DeepReadonly<Department>> {
}

export const selectId = (dep: Department) => dep.department_id
export const adapter = createEntityAdapter<DeepReadonly<Department>>({ selectId })

const initialState: DepartmentsState = adapter.getInitialState({
})

export const departmentsReducer = createReducer(
  initialState,
  on(DepartmentActions.loadDepartmentsSuccess,
    (state, action) =>
      adapter.upsertMany(action.departments, state)),
)

