import { ApplicationRef, ErrorHandler, NgModule } from '@angular/core'
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio'
import { MAT_TABS_CONFIG } from '@angular/material/tabs'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { UpgradeModule } from '@angular/upgrade/static'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule as NgrxStoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { LoadingBarModule } from '@ngx-loading-bar/core'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import * as Sentry from '@sentry/angular-ivy'
import * as LogRocket from 'logrocket'
import { ApiModule } from 'src/api/api.module'
import { HeaderModule } from 'src/components/header/header.module'
import { environment } from 'src/environments/environment'
import { DataModule } from 'src/services/data/data.module'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { LazyLoaderService } from './angularjs/angularjs-loader.service'
import { LocationShimModuleProviders } from './angularjs/location-shim.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { metaReducers, reducers } from './store/reducers'
import { StoreModule } from './store/store.module'

if (environment.logRocketAppId) {
  LogRocket.init(environment.logRocketAppId, {
    release: environment.version.version,
    network: {
      requestSanitizer: request => {
        if (request.url.toLowerCase().indexOf('login') !== -1) {
          return null
        }

        request.headers['authorization'] =  null

        return request
      },
    },
  })
}

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.env,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
      // new Integrations.BrowserTracing({
      //   tracingOrigins: [environment.apiUrl, environment.auctioneerApiUrl],
      //   routingInstrumentation: Sentry.routingInstrumentation,
      // }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

const StoreDevtoolsModules = environment.enableStoreDevtools ? [StoreDevtoolsModule.instrument({connectInZone: true})] : []
const SentryProviders = environment.sentryDsn ? [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
        logErrors: true,
      }),
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ] : []

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    AppRoutingModule,
    ApiModule,
    DataModule,
    ToasterModule,
    HeaderModule,
    BrowserAnimationsModule,
    NgrxStoreModule.forRoot(reducers, { metaReducers }),
    StoreModule,
    ...StoreDevtoolsModules,

    EffectsModule.forRoot(),
  ],
  providers: [
    ...SentryProviders,
    ...LocationShimModuleProviders,
   { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
   { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
   { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
   { provide: MAT_TABS_CONFIG, useValue: { stretchTabs: 'false' } },
  ],
})
export class AppModule {
  constructor(
    private lazyLoader: LazyLoaderService,
  ) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(AppComponent)
    // app()
    // this.upgrade.bootstrap(document.body, ['tradecafe.webapp'], { strictDi: true })
    console.log('loading ng1 app')
    this.lazyLoader.load(document.body)
  }
}
