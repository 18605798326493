import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { select, Store } from '@ngrx/store'
import { AccountObject, Consignee, GeneralAddress } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, identity } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { patchAccountSuccess, selectAccountEntities } from 'src/app/store/accounts'
import { updateConsignee } from 'src/app/store/consignees'
import { AccountsService } from 'src/services/data/accounts.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { AddressFormService } from '../address-form/address-form.service'
import { ConsigneeFormService } from '../consignee-form/consignee-form.service'

export interface AddressPickerOptions {
  // dialog title
  title?: string // Default - "Select Address"
  // account id to read addresses from
  accountId?: number
  // if set - allow user to select different accounts
  accountIds?: number[]
  // preselected address (if !allowMultiple)
  address?: GeneralAddress
  // preselected addresses (if allowMultiple)
  addresses?: GeneralAddress[]
  // users can select multiple addresses
  allowMultiple?: true
  // how many addresses users can select (if allowMultiple)
  limit?: number
  // show establishment column
  showEstablishment?: true
  // additional addresses to show (first items in addresses list)
  extraOptions?: GeneralAddress[]

  // data options
  accounts$?: Observable<DeepReadonly<AccountObject[]>>
  consignees$?: Observable<DeepReadonly<Consignee[]>>
  preselected?: GeneralAddress[]
}

export interface AddressPickerResult {
  account?: number
  address?: GeneralAddress
  addresses?: GeneralAddress[]
}

@Component({
  selector: 'tc-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressPickerComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private dialog: MatDialogRef<AddressPickerComponent, AddressPickerResult>,
    private AddressForm: AddressFormService,
    private Accounts: AccountsService,
    private toaster: ToasterService,
    private store: Store,
    private ConsigneeForm: ConsigneeFormService,
    @Inject(MAT_DIALOG_DATA) protected options: AddressPickerOptions,
  ) {
    super()
    if (!this.options.accountIds) this.companyCtrl.disable()
  }

  readonly preselected = this.options.preselected
  readonly accounts$ = this.options.accounts$
  readonly consignees$ = this.options.consignees$

  companyCtrl = new UntypedFormControl(this.options.accountId)
  searchCtrl = new UntypedFormControl('')

  account$ = combineLatest([
    this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
    replayForm(this.companyCtrl),
  ]).pipe(map(([accounts, accountId]) => accounts[accountId]))

  dataSource = new MatTableDataSource<GeneralAddress>()

  ngOnInit(): void {
    combineLatest([
      this.account$,
      replayForm<string>(this.searchCtrl).pipe(map(search => search.toLowerCase())),
      this.consignees$,
    ]).pipe(untilComponentDestroyed(this)).subscribe(([account, search, consignees]) => {
      this.dataSource.data = compact([
        ...this.options.extraOptions || [],
        ...account?.addresses || [],
        ...account?.consignees?.map((acId) => consignees.find((c) => c.consignee_id === acId)) || [],
      ]).filter(addr => JSON.stringify(addr).toLowerCase().includes(search))
    })
  }

  showAddAddress() {
    this.account$.pipe(take(1), filter(identity)).subscribe(account => {
      this.AddressForm.showAddress(undefined, account).subscribe(address => {
        const addresses = [...account.addresses, address]
        this.updateCompanyAddresses({ ...account, addresses })
      })
    })
  }

  showEditAddress(address: GeneralAddress) {
    if ((address as Consignee).consignee_id) {
      const consignee = address as Consignee
      this.ConsigneeForm.showConsignee(consignee).subscribe(updatedConsignee => {
        this.store.dispatch(updateConsignee({ id: consignee.consignee_id, consignee: updatedConsignee }))
      })
      return
    }

    this.account$.pipe(take(1), filter(identity)).subscribe(account => {
    this.AddressForm.showAddress(address, account).subscribe(updated => {
       const addresses = account.addresses.map(a => a === address ? updated : a)
       this.updateCompanyAddresses({...account, addresses })
      })
    })
  }

  select(selected: GeneralAddress[]) {
    if (selected.length > this.options.limit) {
      this.toaster.warning(`You can select maximum ${this.options.limit} entries`)
      return
    }
    if (!selected) {
      this.dialog.close({})
    } else {
      this.account$.pipe(take(1)).subscribe(account =>
        this.dialog.close({
          account: account?.account,
          address: selected[0], // compatibility code from WA-2780
          addresses: selected.map(address => Object.assign({}, address)),
        }))
    }
  }


  private async updateCompanyAddresses(account: DeepReadonly<AccountObject>) {
    if (!account) return
    try {
      const stored = await this.Accounts.patchAccountImmutable(account, {
        addresses: account.addresses,
      })
      this.store.dispatch(patchAccountSuccess({ account: stored }))
    } catch (err) {
      console.error('Unable to save address data.', err)
      this.toaster.error('Unable to save address data.', err)
    }
  }

  cancel() {
    this.dialog.close()
  }
}
