import { createAction, props } from '@ngrx/store'
import { Measure, TableKey } from '@tradecafe/types/core'


export const loadMeasures = createAction(
  '[Measure/API] Load Measures',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadMeasuresSuccess = createAction(
  '[Measure/API] Load Measures Success',
  props<{
    tableKey?: TableKey,
    measures: Measure[],
  }>(),
)
export const loadMeasuresFailure = createAction(
  '[Measure/API] Load Measures Failure',
  props<{ error: Error }>(),
)
