import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { ItemTypesService } from 'src/pages/admin/settings/product-specifications/item-types/item-types.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { createItemType, createItemTypeFailure, createItemTypeSuccess, deleteItemType, deleteItemTypeFailure, deleteItemTypeSuccess, loadItemTypes, loadItemTypesFailure, loadItemTypesSuccess, updateItemType, updateItemTypeFailure, updateItemTypeSuccess } from './item-type.actions'

@Injectable()
export class ItemTypeEffects {
  constructor(
    private actions$: Actions,
    private ItemTypes: ItemTypesService,
    private toaster: ToasterService,
  ) {}

  loadItemTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadItemTypes),
    switchMap(() =>
      from(this.ItemTypes.getItemTypes()).pipe(
        map(itemTypes => loadItemTypesSuccess({
          itemTypes: itemTypes.data,
        })),
        catchError(error => {
          console.error('Unable to fetch itemTypes', error)
          this.toaster.error('Unable to fetch itemTypes', error)
          return of(loadItemTypesFailure({ error }))
        })))))

  createItemType$ = createEffect(() => this.actions$.pipe(
    ofType(createItemType),
    switchMap(action =>
      from(this.ItemTypes.create(action.itemType)).pipe(
        map(itemType => createItemTypeSuccess({ itemType })),
        tap(() => this.toaster.success('Item Type created successfully.')),
        catchError(error => {
          console.error('Unable to create document itemType', error)
          this.toaster.error('Unable to create document itemType', error)
          return of(createItemTypeFailure({ error }))
        })))))

  updateItemType$ = createEffect(() => this.actions$.pipe(
    ofType(updateItemType),
    switchMap(action =>
      from(this.ItemTypes.update({ ...action.itemType, item_type_id: action.id })).pipe(
        map(itemType => updateItemTypeSuccess({ itemType })),
        tap(() => this.toaster.success('Item Type updated successfully.')),
        catchError(error => {
          console.error('Unable to update document itemType', error)
          this.toaster.error('Unable to update document itemType', error)
          return of(updateItemTypeFailure({ error }))
        })))))

  deleteItemType$ = createEffect(() => this.actions$.pipe(
    ofType(deleteItemType),
    switchMap(action =>
      from(this.ItemTypes.remove(action.itemType)).pipe(
        map(r => deleteItemTypeSuccess({ id: action.id })),
        tap(() => this.toaster.success('Item Type deleted successfully.')),
        catchError(error => {
          console.error('Unable to delete document itemType', error)
          this.toaster.error('Unable to delete document itemType', error)
          return of(deleteItemTypeFailure({ error }))
        })))))

}
