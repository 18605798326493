import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, defaults, flatten, identity, isEqual } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import { loadConsignees } from 'src/app/store/consignees'
import { selectAllConsignees } from 'src/app/store/consignees/consignees.selectors'
import { waitNotEmpty } from 'src/services/data/utils'
import { ModalService } from 'src/shared/modal/modal.service'
import { AddressPickerComponent, AddressPickerOptions, AddressPickerResult } from './address-picker.component'

export interface AddressPickerOptionsEx extends Omit<AddressPickerOptions, 'accountId' |'accountIds'> {
  accountId?: number | string
  accountIds?: Array<number | string>
}

@Injectable()
export class AddressPickerService {

  constructor(
    private modal: ModalService,
    private store: Store,
  ) { }

  show(options: DeepReadonly<AddressPickerOptionsEx>) {
    this.store.dispatch(loadConsignees())
    this.store.dispatch(loadAccounts({}))

    options = defaults(options, {
      title: 'Select Address',
      extraOptions: [],
      accountIds: undefined, // []
      accountId: undefined, // []
      showEstablishment: false,
      allowMultiple: false,
      limit: 3,
    })
    options = {
      ...options,
      ...this.buildDataOptions(options),
    }
    return this.modal.openDialog<AddressPickerComponent, AddressPickerOptions, AddressPickerResult>(
      AddressPickerComponent, options as AddressPickerOptions,
    ).pipe(filter(identity))
  }

  hasChanged(options: DeepReadonly<AddressPickerOptionsEx>): Observable<boolean> {
    const data = this.buildDataOptions(options)
    return combineLatest([data.accounts$, data.consignees$]).pipe(
      map(([accounts, consignees]) => [
        ...options.extraOptions || [],
        ...flatten(accounts.map(a => a.addresses)),
        ...consignees,
      ]),
      map(addresses => !!data.preselected?.length && data.preselected.some(a1 => !addresses.find(a2 => isEqual(a1, a2)))))
  }

  private buildDataOptions(options: DeepReadonly<AddressPickerOptionsEx>) {
    const accountId = options.accountId && parseFloat(options.accountId as string)
    const accountIds = options.accountIds?.map(x => typeof x === 'string' ? parseFloat(x) : x)
    const accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(), map(accounts => {
      if (!accountIds?.length) return accounts
      return accounts.filter(acc => accountIds.includes(acc.account))
    }))
    const consignees$ = this.store.pipe(select(selectAllConsignees), waitNotEmpty())
    const preselected = options.allowMultiple ? options.addresses : compact([options.address])
    return { accountId, accountIds, accounts$, consignees$, preselected }
  }
}
