import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { CurrenciesService } from 'src/pages/admin/settings/admin-setting-payments/currency/currencies.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadCurrencies, loadCurrenciesFailure, loadCurrenciesSuccess } from './currency.actions'

@Injectable()
export class CurrencyEffects {
  constructor(
    private actions$: Actions,
    private currencies: CurrenciesService,
    private toaster: ToasterService,
  ) {}

  loadCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(loadCurrencies),
    switchMap(action =>
      from(this.currencies.getCurrencies()).pipe(
        map(currencies => loadCurrenciesSuccess({
          tableKey: action.tableKey,
          currencies: currencies.data,
        })),
        catchError(error => {
          console.error('Unable to fetch currencies', error)
          this.toaster.error('Unable to fetch currencies', error)
          return of(loadCurrenciesFailure({ error }))
        })))))
}
