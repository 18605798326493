import { createAction, props } from '@ngrx/store'
import { Consignee } from '@tradecafe/types/core'


export const loadConsignees = createAction(
  '[Consignee/API] Load Consignees',
)
export const loadConsigneesSuccess = createAction(
  '[Consignee/API] Load Consignees Success',
  props<{
    consignees: Consignee[],
  }>(),
)
export const loadConsigneesFailure = createAction(
  '[Consignee/API] Load Consignees Failure',
  props<{ error: Error }>(),
)

export const createConsignee = createAction(
  '[Consignee/API] Add Consignees', props<{
    consignee: Consignee,
  }>(),
)
export const createConsigneeSuccess = createAction(
  '[Consignee/API] Add Consignees Success',
  props<{
    consignee: Consignee,
  }>(),
)
export const createConsigneeFailure = createAction(
  '[Consignee/API] Add Consignees Failure',
  props<{ error: Error }>(),
)

export const updateConsignee = createAction(
  '[Consignee/API] Change Consignees', props<{
    consignee: Consignee,
    id: string,
  }>(),
)
export const updateConsigneeSuccess = createAction(
  '[Consignee/API] Change Consignees Success',
  props<{
    consignee: Consignee,
  }>(),
)
export const updateConsigneeFailure = createAction(
  '[Consignee/API] Change Consignees Failure',
  props<{ error: Error }>(),
)
