import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ModalModule } from 'src/shared/modal'
import { NoteFormComponent } from './note-form.component'
import { NoteFormService } from './note-form.service'


@NgModule({
  declarations: [NoteFormComponent],
  providers: [NoteFormService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ModalModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    SelectSearchModule,
  ],
})
export class NoteFormModule { }
