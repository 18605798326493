import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealFormPageOverlayComponent, DealFormPageOverlayOptions } from './deal-form-page-overlay.component'

@Injectable()
export class DealFormPageOverlayService {
  private dealId:string
  constructor(private modal: ModalService) {}

  getDealId(): string{
    return this.dealId
  }

  setDealId(dealId:string): void{
     this.dealId = dealId
  }
  async showDealFormPageOverlay(dealId: string) {
    this.setDealId(dealId)
    await this.modal.openLargeSide<DealFormPageOverlayComponent, DealFormPageOverlayOptions>(
      DealFormPageOverlayComponent,
      { dealId },
    ).toPromise()
    this.setDealId(null)
  }
}
