import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'

export const SEGMENT_COLUMNS: ColumnDef[] = [
  { field: 'ellipsisMenu',    displayName: '', internal: true, index: 1001 },
  { field: 'type',            displayName: '' },
  { field: 'carrierName',     displayName: 'Provider' },
  { field: 'originName',      displayName: 'Pick-up' },
  { field: 'destinationName', displayName: 'Drop-off' },
  { field: 'amountCAD',       displayName: 'Amount in CAD' },
  { field: 'cutoffDate',      displayName: 'Cut-off' },
  { field: 'loadDate',        displayName: 'Loading date' },
  { field: 'dropoffDate',     displayName: 'Drop-off date' },
]

export const SEGMENT_COLUMN_NAMES = mapValues(keyBy(SEGMENT_COLUMNS, 'field'), 'displayName')
