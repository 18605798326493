import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShipmentRate } from '@tradecafe/types/core';
import { BehaviorSubject } from 'rxjs';
import { AuthApiService } from 'src/api/auth';
import { EpochPipe } from 'src/filters/epoch.pipe';
import { dayjs } from 'src/services/dayjs';
import { ToasterService } from 'src/shared/toaster/toaster.service';
import { FreightRatesService } from '../freight-rates.service';

export interface ProblematicFreightRatesOptions {
  freightRate?: ShipmentRate;
}

@Component({
  selector: 'tc-problematic-freight-rates',
  templateUrl: './problematic-freight-rates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProblematicFreightRatesComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: ProblematicFreightRatesOptions,
    private dialogRef: MatDialogRef<
      ProblematicFreightRatesComponent,
      ShipmentRate
    >,
    private toaster: ToasterService,
    private FreightRates: FreightRatesService,
    private AuthApi: AuthApiService,
    private epochPipe: EpochPipe
  ) { }

  pForm = new FormGroup({
    problem: new FormControl('', Validators.required),
  });
  title = '';
  inProgress$ = new BehaviorSubject<'save' | 'loading'>(undefined);
  shipmentRate: ShipmentRate;

  ngOnInit() {
    this.shipmentRate = this.dialogData.freightRate;
    if (!this.shipmentRate.problem) {
      this.title = 'Flag Freight Rate';
    } else if (this.shipmentRate.problem.isFlagged) {
      this.title = 'Unflag Freight Rate';
    } else {
      this.title = 'Flag Freight Rate';
    }
  }

  async save() {
    if (
      !this.pForm.controls.problem.value &&
      this.pForm.controls.problem.value === ''
    ) {
      this.toaster.warning('Problem invalid');
      return;
    }

    this.inProgress$.next('save');
    try {
      const stored = await this.FreightRates.update(
        {
          rate_id: this.dialogData.freightRate.rate_id,
          problem: {
            isFlagged: !this.shipmentRate.problem?.isFlagged,
            detail: this.pForm.controls.problem.value,
            history: [
              `- ${this.epochPipe.transform(dayjs.utc().unix(), 'MMM DD')}, ${this.AuthApi.currentUser.firstname} ${this.AuthApi.currentUser.lastname}, ${this.pForm.controls.problem.value}`,
              ...(this.shipmentRate.problem?.history || []),
            ],
          },
        }
      );
      this.dialogRef.close(stored);
      this.toaster.success('Freight rate problem saved successfully');
    } catch (error) {
      console.error(error);
      this.toaster.error(error.error.error.error.message);
    }
    this.inProgress$.next(undefined);
  }

  cancel() {
    this.dialogRef.close(this.dialogData.freightRate);
  }
}
