import { createSelector } from '@ngrx/store'
import { ItemType } from '@tradecafe/types/core'
import { State } from '../reducers'
import { adapter, itemTypesFeatureKey } from './item-type.reducer'

export const selectState = (state: State) => state[itemTypesFeatureKey]

export const { selectEntities: selectItemTypeEntities, selectAll: selectAllItemTypes } = adapter.getSelectors(selectState)

export const selectItemType = createSelector(
  selectItemTypeEntities,
  (itemTypes: Dictionary<ItemType>, itemType: string | number) =>
    itemTypes[itemType])

export const selectItemTypesById = createSelector(
  selectItemTypeEntities,
  (itemTypes: Dictionary<ItemType>, itemTypeId: string) =>
    itemTypes[itemTypeId])

export const selectItemTypesByIds = createSelector(
  selectItemTypeEntities,
  (itemTypes: Dictionary<ItemType>, itemTypeIds: string[]) =>
    itemTypeIds.map(itemTypeId => itemTypes[itemTypeId]))
