import { Pipe, PipeTransform } from '@angular/core'
import { dayjs } from 'src/services/dayjs'

@Pipe({
  name: 'epochTimeAgo',
  pure: true,
})
export class EpochTimeAgo implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return dayjs.unix(value).fromNow()
  }
}
