import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { switchMap } from 'rxjs/operators'
import { loadMeasures } from 'src/app/store/measures'
import { loadProducts } from 'src/app/store/products'
import { DealFormService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealProductsOverlayComponent, DealProductsOverlayOptions } from './deal-products-overlay.component'

@Injectable()
export class DealProductsOverlayService {
  constructor(
    private modal: ModalService,
    private DealForm: DealFormService,
    private store: Store,
  ) { }

  showProductsDetails(dealId: string) {
    this.store.dispatch(loadMeasures({}))
    this.store.dispatch(loadProducts({}))
    return this.DealForm.load(dealId).pipe(switchMap(({ dealForm, dealViewRaw }) =>
      this.modal.openLargeSide<DealProductsOverlayComponent, DealProductsOverlayOptions>(DealProductsOverlayComponent, {
        dealForm,
        dealViewRaw,
      })))
  }
}
