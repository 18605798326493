<form [formGroup]="form" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-users"></mat-icon> Change Brokerage Customer
  </h2>
  <div mat-dialog-content>
    <tc-select-search placeholder="Deals Brokerage Customer"
        [ctrl]="form.controls.brokerageCustomer"
        [items]="accounts$ | async" bindValue="account" bindLabel="name" bindHint="type"
        (change)="customerChanged($event)"
        ></tc-select-search>

    <mat-radio-group [formControl]="form.controls.overridePrice">
      <mat-radio-button [value]="false">Do not change price</mat-radio-button>
      <mat-radio-button [value]="true"
          [class.tc-active-radio-button]="form.controls.overridePrice.value"
          [ngSwitch]="form.controls.overridePrice.value">
        <ng-container *ngSwitchCase="false">Set Flat price</ng-container>
        <div gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr)" gdGap="10px" *ngSwitchCase="true">
          <mat-form-field>
            <mat-label>Flat Price {{(currency$ | async) ? ' (' + (currency$ | async) + ')' : ''}}</mat-label>
            <input matInput [formControl]="form.controls.brokerageEstAmount" type="number" autocomplete="off" step="0.0001" min="0" >
          </mat-form-field>
          <mat-form-field>
            <mat-label>No. of days</mat-label>
            <input matInput type="number" step="1" min="0"
                [formControl]="termsForm.controls.days"
                (change)="termsChanged()">
          </mat-form-field>
          <tc-select-search placeholder="From this date"
              [ctrl]="termsForm.controls.from_date"
              [items]="PaymentTermsDates" bindValue="id" bindLabel="name"
              (change)="termsChanged()"
              ></tc-select-search>
          <tc-select-search placeholder="Payment Reference"
              [ctrl]="termsForm.controls.reference_id"
              [items]="paymentReferences$ | async" bindValue="payment_reference_id" bindLabel="reference"
              [showTooltips]="true"
              (change)="termsChanged()"
              ></tc-select-search>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-check]="!(inProgress$ | async)"
          [class.fa-spin]="(inProgress$ | async)"
          [class.fa-spinner]="(inProgress$ | async)"
          ></i> Update
    </button>
  </div>
</form>
