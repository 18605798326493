import { Injectable } from '@angular/core'
import { AccountObject, Carrier, DealBase, Segment } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { defaults, merge } from 'lodash-es'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { MeasuresService } from 'src/pages/admin/settings/product-specifications/measures/measures.service'
import { ProductsService } from 'src/pages/admin/settings/products-services/products.service'
import { CarriersService } from 'src/pages/admin/settings/tracking-providers/carriers.service'
import { AccountsService } from 'src/services/data/accounts.service'
import { buildCostFromRate } from 'src/services/data/costs.service'
import { carriersResolver, countriesResolver, currenciesResolver, generateShipmentRatesResolver, locationsResolver } from 'src/services/data/deal.resolvers'
import { buildSegmentFromRate, getSegmentPopulatedValues } from 'src/services/data/segments.service'
import { ModalProxyService } from 'src/shared/modal'
import { ShipmentRatePickerOptions } from './shipment-rate-picker.component'

@Injectable()
export class ShipmentRatePickerService {
  constructor(
    private modalHelper: ModalProxyService,
    private FxRates: FxRatesService,
    private Products: ProductsService,
    private Carriers: CarriersService,
    private Accounts: AccountsService,
    private Measures: MeasuresService,
  ) {}

  /**
   * Show Shipment Rate picker
   *
   * @param {any} options
   * @returns {Promise} of {selectedRate: <shipping rate doc>}
   */
  async show(options?: DeepReadonly<ShipmentRatePickerOptions>) {
    const rate = await this.modalHelper.showModal({
      component: 'tcShipmentRatePicker',
      windowClass: 'modalclone tc-shipment-rate-picker',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        fx_rates: () => this.FxRates.getSpotRates(),
        options: () => defaults(options, { filters: { } }),
        rates: generateShipmentRatesResolver(options.dealId, options.matchedOfferId),
        carriers: carriersResolver,
        countries: countriesResolver,
        currencies: currenciesResolver,
        locations: locationsResolver,
      },
    })
    return angular.copy(rate)
  }

  async addFreightCost(opts: {
    originLocationId: string
    destLocationId: string
    buyer?: DeepReadonly<AccountObject>
    buyerId?: string
    weight: DeepReadonly<DealBase['attributes']['estimated']['weight']>
    dealId?: string,
    matchedOfferId?: string,
  }) {
    const buyer = opts.buyer || await this.Accounts.getAccountById(opts.buyerId)
    const w = opts.weight
    const selectedRate = await this.show({
      title: 'New Freight Rate',
      filters: {
        origin_id: opts.originLocationId,
        destination_id: opts.destLocationId,
        preferred_carriers: buyer.preferred_carriers || [],
      },
      buyWeightKg: w && this.Measures.convert(w.amount, w.measure_id, 'KG'),
      buyWeightLb: w && this.Measures.convert(w.amount, w.measure_id, 'LB'),
      dealId: opts.dealId,
      matchedOfferId: opts.matchedOfferId,
    })
    const [product, carrier] = await Promise.all([
      this.Products.getBySegmentType(selectedRate.type),
      this.Carriers.getById(selectedRate.carrier_id),
    ])
    return buildCostFromRate(selectedRate, { carrier, product })
  }

  async showAddSegment(
    lastSegment: DeepReadonly<Segment>,
    carriers: DeepReadonly<Dictionary<Carrier>>,
    w: DeepReadonly<DealBase['attributes']['estimated']['weight']>,
  ) {
    const selectedRate = await this.show({
      buyWeightKg: w && this.Measures.convert(w.amount, w.measure_id, 'KG'),
      buyWeightLb: w && this.Measures.convert(w.amount, w.measure_id, 'LB'),
    })
    const segment = buildSegmentFromRate(selectedRate, { carriers })
    if (lastSegment) {
      merge(segment, getSegmentPopulatedValues(lastSegment))
      segment.order = lastSegment.order + 1
    } else {
      segment.order = 0
    }
    return segment
  }
}
