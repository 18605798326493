import { Injectable } from '@angular/core'
import { FileObject } from '@tradecafe/types/core'
import { identity } from 'lodash-es'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ModalService } from 'src/shared/modal'
import { UploaderDialogComponent, UploaderDialogOptions } from './uploader-dialog.component'

@Injectable()
export class UploaderDialogService {
  constructor(private modal: ModalService) {}

  showUploader(data: UploaderDialogOptions): Promise<FileObject[]> {
    return this.showUploaderObs(data).toPromise()
  }

  showUploaderObs(data: UploaderDialogOptions): Observable<FileObject[]> {
    return this.modal.openDialog<UploaderDialogComponent>(UploaderDialogComponent, data, {
      panelClass: ['tc-dialog', 'modal-xlg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
    }).pipe(filter(identity))
  }
}
