import { createAction, props } from '@ngrx/store'
import { Cost, Deal, DealBase, DealDateField, DealPartyE, DealRow, DealViewRaw, DealViewRawCosts, DealViewRawDeal, DealViewRawSegments, DealViewRawStatus, Note, Segment, TableKey } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { DealDetailsFormDto, DealFormDto, DealProductFormValueBase, SegmentFormDto } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { ElasticSearchFilters, ElasticSearchPayload } from 'src/services/elastic-search'


export const loadDealViews = createAction(
  '[DealView/API] Load DealViews',
  props<{
    tableKey: TableKey,
    page: number,
    filters: any,
    calculate: boolean,
    populate_intransit_balance?: boolean,
  }>(),
)
export const loadDealViewsSuccess = createAction(
  '[DealView/API] Load DealViews Success',
  props<{
    tableKey: TableKey,
    total: number,
    totals: Dictionary<any>,
    dealViews: DealRow[],
    payload: ElasticSearchPayload
  }>(),
)
export const loadDealViewsFailure = createAction(
  '[DealView/API] Load DealViews Failure',
  props<{ error: Error }>(),
)

export const loadDealFilters = createAction(
  '[DealView/API] Load DealFilters',
  props<{
    tableKey: TableKey,
    filters: any,
  }>(),
)
export const loadDealFiltersSuccess = createAction(
  '[DealView/API] Load DealFilters Success',
  props<{
    tableKey: TableKey,
    result: ElasticSearchFilters,
  }>(),
)
export const loadDealFiltersFailure = createAction(
  '[DealView/API] Load DealFilters Failure',
  props<{ error: Error }>(),
)


export const startWorkingOn = createAction(
  '[DealView/API] Start Working On',
  props<{ deals: DeepReadonly<DealBase>[] }>(),
)
export const startWorkingOnSuccess = createAction(
  '[DealView/API] Start Working On Success',
  props<{ deals: Deal[] }>(),
)
export const startWorkingOnFailure = createAction(
  '[DealView/API] Start Working On Failure',
  props<{ error: Error }>(),
)

export const setDealsColor = createAction(
  '[DealView/API] Set Deal Color',
  props<{
    deals: DeepReadonly<DealBase>[],
    color: string,
  }>(),
)
export const setDealsColorSuccess = createAction(
  '[DealView/API] Set Deal Color Success',
  props<{ deals: Deal[] }>(),
)
export const setDealsColorFailure = createAction(
  '[DealView/API] Set Deal Color Failure',
  props<{ error: Error }>(),
)

export type DealSimpleBooleanField = 'attributes.extra.invoiced' |
  'attributes.extra.art_work' |
  'attributes.extra.inspection' |
  'attributes.extra.documents' |
  'attributes.extra.truck_inspection' |
  'attributes.extra.edocs_to_customs_broker' |
  'attributes.extra.original_sent' |
  'attributes.extra.loading_instructions' |
  'attributes.extra.load_ready' |
  'attributes.extra.bol_instructions'

export const toggleDealFlag = createAction(
  '[DealView/API] Toggle Deal Flag',
  props<{
    deal: DeepReadonly<DealBase>,
    fieldName: DealSimpleBooleanField,
  }>(),
)
export const toggleDealFlagSuccess = createAction(
  '[DealView/API] Toggle Deal Flag Success',
  props<{ deals: Deal[] }>(),
)
export const toggleDealFlagFailure = createAction(
  '[DealView/API] Toggle Deal Flag Failure',
  props<{ error: Error }>(),
)

export const toggleInalfrescoFlag = createAction(
  '[DealView/API] Toggle Inalfresco Flag',
  props<{ deal: DeepReadonly<DealBase> }>(),
)
export const toggleInalfrescoFlagSuccess = createAction(
  '[DealView/API] Toggle Inalfresco Flag Success',
  props<{ deals: Deal[] }>(),
)
export const toggleInalfrescoFlagFailure = createAction(
  '[DealView/API] Toggle Inalfresco Flag Failure',
  props<{ error: Error }>(),
)

export const togglePortalAccess = createAction(
  '[DealView/API] Toggle Portal Access',
  props<{
    deal: DeepReadonly<DealBase>,
    account: number,
  }>(),
)
export const togglePortalAccessSuccess = createAction(
  '[DealView/API] Toggle Portal Access Success',
  props<{ deals: Deal[] }>(),
)
export const togglePortalAccessFailure = createAction(
  '[DealView/API] Toggle Portal Access Failure',
  props<{ error: Error }>(),
)

export const setDealDateRange = createAction(
  '[DealView/API] Set Deal Date Range',
  props<{
    deal: DeepReadonly<DealBase>,
    details: Pick<DealDetailsFormDto, 'shipmentDatesFrom' | 'shipmentDatesTo' | 'shipmentDatesTbd'> |
             Pick<DealDetailsFormDto, 'deliveryDatesFrom' | 'deliveryDatesTo' | 'deliveryDatesTbd'>,
  }>(),
)
export const setDealDateRangeSuccess = createAction(
  '[DealView/API] Set Deal Date Range Success',
  props<{ deals: Deal[] }>(),
)
export const setDealDateRangeFailure = createAction(
  '[DealView/API] Set Deal Date Range Failure',
  props<{ error: Error }>(),
)

export const setDealDate = createAction(
  '[DealView/API] Set Deal Date',
  props<{
    deal: DeepReadonly<DealBase>,
    fieldName: DealDateField,
    date: number,
    recalculate?: boolean, // by default deal date doesn't trigger deal recalculation
  }>(),
)
export const setDealDateSuccess = createAction(
  '[DealView/API] Set Deal Date Success',
  props<{ deals: Deal[] }>(),
)
export const setDealDateFailure = createAction(
  '[DealView/API] Set Deal Date Failure',
  props<{ error: Error }>(),
)

export const setSegmentDate = createAction(
  '[DealView/API] Set Segment Date',
  props<{
    deal: DeepReadonly<DealRow>,
    segment: DeepReadonly<Segment>,
    patch: Partial<SegmentFormDto>,
  }>(),
)
export const setSegmentDateSuccess = createAction(
  '[DealView/API] Set Segment Date Success',
  props<{ deal: Deal }>(),
)
export const setSegmentDateFailure = createAction(
  '[DealView/API] Set Segment Date Failure',
  props<{ error: Error }>(),
)

export const copySegments = createAction(
  '[DealView/API] Copy Segments',
  props<{
    source: DealViewRawDeal & DealViewRawSegments,
    destination: Array<DealViewRawDeal & DealViewRawSegments>,
    deleteSegments: boolean,
    exactCopy: boolean,
  }>(),
)
export const copySegmentsSuccess = createAction(
  '[DealView/API] Copy Segments Success',
  props<{ deals: Deal[] }>(),
)
export const copySegmentsFailure = createAction(
  '[DealView/API] Copy Segments Failure',
  props<{ error: Error }>(),
)

export const setBookingId = createAction(
  '[DealView/API] Set Booking Id',
  props<{
    deal: DeepReadonly<DealRow>,
    bookingId: string,
  }>(),
)
export const setBookingIdSuccess = createAction(
  '[DealView/API] Set Booking Id Success',
  props<{ deal: DeepReadonly<DealRow> }>(),
)
export const setBookingIdFailure = createAction(
  '[DealView/API] Set Booking Id Failure',
  props<{ error: Error }>(),
)

export const setContainerId = createAction(
  '[DealView/API] Set Container Id',
  props<{
    deal: DeepReadonly<DealRow>,
    containerId: string,
  }>(),
)
export const setContainerIdSuccess = createAction(
  '[DealView/API] Set Container Id Success',
  props<{ deal: DeepReadonly<DealRow> }>(),
)
export const setContainerIdFailure = createAction(
  '[DealView/API] Set Container Id Failure',
  props<{ error: Error }>(),
)

export const setMexInvNo = createAction('[DealView/API] Set Mexican Invoice Number', props<{ deal: DeepReadonly<DealRow>, mexInvNo: string }>())
export const setMexInvNoSuccess = createAction('[DealView/API] Set Mexican Invoice Number Success', props<{ deal: DeepReadonly<DealRow> }>())
export const setMexInvNoFailure = createAction('[DealView/API] Set Mexican Invoice Number Failure', props<{ error: Error }>())

export const setDealTextField = createAction(
  '[DealView/API] Set Deal Text Field',
  props<{
    deal: DeepReadonly<DealRow>,
    fieldName: string,
    text: string,
  }>(),
)
export const setDealTextFieldSuccess = createAction(
  '[DealView/API] Set Deal Text Field Success',
  props<{ deals: Deal[] }>(),
)
export const setDealTextFieldFailure = createAction(
  '[DealView/API] Set Deal Text Field Failure',
  props<{ error: Error }>(),
)

export const setDealConsignee = createAction(
  '[DealView/API] Set Deal Consignee',
  props<{
    deal: DeepReadonly<DealRow>,
    address: any,
  }>(),
)
export const setDealConsigneeSuccess = createAction(
  '[DealView/API] Set Deal Consignee Success',
  props<{ deals: Deal[] }>(),
)
export const setDealConsigneeFailure = createAction(
  '[DealView/API] Set Deal Consignee Failure',
  props<{ error: Error }>(),
)

export const setDealShipper = createAction(
  '[DealView/API] Set Deal Shipper',
  props<{
    deal: DeepReadonly<DealRow>,
    address: any,
  }>(),
)
export const setDealShipperSuccess = createAction(
  '[DealView/API] Set Deal Shipper Success',
  props<{ deals: Deal[] }>(),
)
export const setDealShipperFailure = createAction(
  '[DealView/API] Set Deal Shipper Failure',
  props<{ error: Error }>(),
)


export const setSegmentTextField = createAction(
  '[DealView/API] Set Segment Text Field',
  props<{
    deal: DeepReadonly<DealRow>,
    fieldName: string,
    text: string,
  }>(),
)
export const setSegmentTextFieldSuccess = createAction(
  '[DealView/API] Set Segment Text Field Success',
  props<{ deal: DeepReadonly<DealRow> }>(),
)
export const setSegmentTextFieldFailure = createAction(
  '[DealView/API] Set Segment Text Field Failure',
  props<{ error: Error }>(),
)

export const setSegmentAddress = createAction(
  '[DealView/API] Set Segment Address',
  props<{
    deal: DeepReadonly<DealRow>,
    fieldName: string,
    value: { account?, address? },
  }>(),
)

export const setSegmentAddressSuccess = createAction(
  '[DealView/API] Set Segment Address Success',
  props<{ deal: DeepReadonly<DealRow> }>(),
)
export const setSegmentAddressFailure = createAction(
  '[DealView/API] Set Segment Address Failure',
  props<{ error: Error }>(),
)

export const setItemTypeId = createAction('[DealView/API] Set Item Type Field', props<{ deal: DeepReadonly<DealBase>, itemTypeId: string }>())
export const setItemTypeIdSuccess = createAction('[DealView/API] Set Item Type Field Success', props<{ deal: DeepReadonly<DealRow> }>())
export const setItemTypeIdFailure = createAction('[DealView/API] Set Item Type Field Failure', props<{ error: Error }>())

export const setOriginLocation = createAction('[DealView/API] Origin Location', props<{ deal: DeepReadonly<DealBase>, locationId: string }>())
export const setOriginLocationSuccess = createAction('[DealView/API] Origin Location Success', props<{ deal: Deal }>())
export const setOriginLocationFailure = createAction('[DealView/API] Origin Location Failure', props<{ error: Error }>())

export const setDestLocation = createAction('[DealView/API] Set Dest Location', props<{ deal: DeepReadonly<DealBase>, locationId: string }>())
export const setDestLocationSuccess = createAction('[DealView/API] Set Dest Location Success', props<{ deal: Deal }>())
export const setDestLocationFailure = createAction('[DealView/API] Set Dest Location Failure', props<{ error: Error }>())

export const setDealStatusNotes = createAction(
  '[DealView/API] Set Deal Status Notes',
  props<{
    deal: DeepReadonly<DealRow>,
    notes: string[],
  }>(),
)
export const setDealStatusNotesSuccess = createAction(
  '[DealView/API] Set Deal Status Notes Success',
  props<{ deal: DeepReadonly<DealRow> }>(),
)
export const setDealStatusNotesFailure = createAction(
  '[DealView/API] Set Deal Status Notes Failure',
  props<{ error: Error }>(),
)

export const setDealStatus = createAction(
  '[DealView/API] Set Deal Status',
  props<{
    deal: DeepReadonly<DealViewRaw>,
    status: string,
  }>(),
)
export const setDealStatusSuccess = createAction(
  '[DealView/API] Set Deal Status Success',
)
export const setDealStatusFailure = createAction(
  '[DealView/API] Set Deal Status Failure',
  props<{ error: Error }>(),
)

export const lockDeal = createAction('[DealView/API] Lock Deal', props<{ deal: DeepReadonly<DealBase>, lock: boolean }>())
export const lockDealSuccess = createAction('[DealView/API] Lock Deal Success', props<{ deal: DeepReadonly<Deal> }>())
export const lockDealFailure = createAction('[DealView/API] Lock Deal Failure', props<{ error: Error }>())

export const actualizeCostToZero = createAction('[DealView/API] Actualize Cost=0', props<{ dv: DeepReadonly<DealViewRawDeal & DealViewRawCosts & DealViewRawStatus>, cost_id: string }>())
export const actualizeCostToZeroSuccess = createAction('[DealView/API] Actualize Cost=0 Success', props<{ deal: DeepReadonly<Deal> }>())
export const actualizeCostToZeroFailure = createAction('[DealView/API] Actualize Cost=0 Failure', props<{ error: Error }>())

export const unactualizeCostToZero = createAction('[DealView/API] Unactualize Cost=0', props<{ dv: DeepReadonly<DealViewRawDeal & DealViewRawCosts & DealViewRawStatus>, cost_id: string }>())
export const unactualizeCostToZeroSuccess = createAction('[DealView/API] Unactualize Cost=0 Success', props<{ deal: DeepReadonly<Deal> }>())
export const unactualizeCostToZeroFailure = createAction('[DealView/API] Unactualize Cost=0 Failure', props<{ error: Error }>())

export const copyCloneDeal = createAction('[DealView/API] Copy Clone Deal', props<{ dealId: string, count: number }>())
export const copyCloneDealSuccess = createAction('[DealView/API] Copy Clone Deal Success', props<{ deals: Deal[] }>())
export const copyCloneDealFailure = createAction('[DealView/API] Copy Clone Deal Failure', props<{ error: Error }>())

export const cloneDeal = createAction('[DealView/API] Clone Deal', props<{ dealId: string, payload: any }>())
export const cloneDealSuccess = createAction('[DealView/API] Clone Deal Success', props<{ deals: Deal[] }>())
export const cloneDealFailure = createAction('[DealView/API] Clone Deal Failure', props<{ error: Error }>())

export const unwindDeal = createAction('[DealView/API] Unwind Deal', props<{ deal_id: string }>())
export const unwindDealSuccess = createAction('[DealView/API] Unwind Deal Success', props<{ deal_id: string }>())
export const unwindDealFailure = createAction('[DealView/API] Unwind Deal Failure', props<{ error: Error }>())

export const approveDeringerPdf = createAction('[File API] Approve Deringer PDF', props<{ deal_id: string, file_id: string }>())
export const approveDeringerPdfSuccess = createAction('[File API] Approve Deringer PDF Success', props<{ deal: DeepReadonly<DealRow> }>())
export const approveDeringerPdfFailure = createAction('[File API] Approve Deringer PDF Failure', props<{ error: Error }>())

export const rejectDeringerPdf = createAction('[File API] Reject Deringer PDF', props<{ deal_id: string, file_id: string }>())
export const rejectDeringerPdfSuccess = createAction('[File API] Reject Deringer PDF Success', props<{ deal: DeepReadonly<DealRow> }>())
export const rejectDeringerPdfFailure = createAction('[File API] Reject Deringer PDF Failure', props<{ error: Error }>())

export const saveDealForm = createAction('[Deal Form] Save Deal Form', props<{ dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>, dealForm: DeepReadonly<DealFormDto> }>())
export const patchDealForm = createAction('[Deal Form] Patch Deal Form', props<{ dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>, dealForm: DeepReadonly<DeepPartial<DealFormDto>> }>())
export const saveDealFormSuccess = createAction('[Deal Form] Save Deal Form Success', props<{ deal: Deal }>())
export const saveDealFormFailure = createAction('[Deal Form] Save Deal Form Failure', props<{ error: Error }>())

export const autosaveDealDetails = createAction('[Deal Form] Autosave Deal Details', props<{ deal: DeepReadonly<Deal>, patch: DeepReadonly<Partial<DealDetailsFormDto>>}>())

export const submitDealForm = createAction('[Deal Form] Submit Deal Form', props<{ dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>, dealForm: DeepReadonly<DealFormDto> }>())
export const submitDealFormSuccess = createAction('[Deal Form] Submit Deal Form Success', props<{ deal: Deal }>())
export const submitDealFormFailure = createAction('[Deal Form] Submit Deal Form Failure', props<{ error: Error }>())

export const autosaveDealCost = createAction('[Deal Form] Autosave Deal Cost', props<{
  dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>,
  costs: DeepReadonly<Partial<Cost>[]>
  index?: number[] // undefined/empty = send "cost removed" packet to API.
}>())
export const autosaveDealCostSuccess = createAction('[Deal Form] Autosave Deal Cost Success', props<{ deal: Deal }>())
export const autosaveDealCostFailure = createAction('[Deal Form] Autosave Deal Cost Failure', props<{ error: Error }>())

export const autosaveDealSegment = createAction('[Deal Form] Autosave Deal Segment', props<{
  dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>,
  dealForm: DeepReadonly<DealFormDto>
  index?: number // undefined = send "cost removed" packet to API.
  patch?: Partial<SegmentFormDto>
}>())
export const autosaveDealSegmentSuccess = createAction('[Deal Form] Autosave Deal Segment Success', props<{ deal: Deal }>())
export const autosaveDealSegmentFailure = createAction('[Deal Form] Autosave Deal Segment Failure', props<{ error: Error }>())
export const massDealForm = createAction('[Deal Form] Mass Save Deals Form', props<{
  party?: DealPartyE
  changes: {
    dv: DeepReadonly<DealViewRawDeal>
    patch: DeepPartial<DealFormDto>
  }[]
}>())
export const massDealFormSuccess = createAction('[Deal Form] Mass Save Deals Form Success', props<{ deals: Deal[] }>())
export const massDealFormFailure = createAction('[Deal Form] Mass Save Deals Form Failure', props<{ error: Error }>())

export const patchDealProduct = createAction('[Deal Form] Patch Deal Product', props<{
  index: number
  dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>,
  dealForm: DeepReadonly<DealFormDto>
  patch: DeepReadonly<Partial<DealProductFormValueBase>>
}>())
export const patchDealProductSuccess = createAction('[Deal Form] Patch Deal Product Success', props<{ deal: Deal }>())
export const patchDealProductFailure = createAction('[Deal Form] Patch Deal Product Failure', props<{ error: Error }>())

export const patchDealProducts = createAction('[Deal Form] Patch Deal Products', props<{
  dv: DeepReadonly<DealViewRawDeal & DealViewRawStatus>,
  dealForm: DeepReadonly<DealFormDto>
  patches: DeepReadonly<Partial<DealProductFormValueBase>>[]
}>())
export const patchDealProductsSuccess = createAction('[Deal Form] Patch Deal Products Success', props<{ deal: Deal }>())
export const patchDealProductsFailure = createAction('[Deal Form] Patch Deal Products Failure', props<{ error: Error }>())

// export const specialInstructions = createAction('[DealView/API] Special Instructions', props<{ deal_id: string }>())
export const specialInstructionsSuccess = createAction('[Notes/API] Special Instructions Success', props<{ note: Note }>())
// export const specialInstructionsFailure = createAction('[DealView/API] Special Instructions Failure', props<{ error: Error }>())

// export const saveNote = createAction('[Notes/API] Save Note', props<{ ... }>())
export const saveNoteSuccess = createAction('[Notes/API] Save Note Success', props<{ note: Note }>())
// export const saveNoteFailure = createAction('[Notes/API] Save Note Failure', props<{ error: Error }>())

// export const sendConfirmation = createAction('[DealView/API] Send Confirmation', props<{ dealIds: string, to: DealParty, recipientIds: string[] }>())
export const sendConfirmationSuccess = createAction('[DealView/API] Send Confirmation Success', props<{ dealIds: string[] }>())
// export const sendConfirmationFailure = createAction('[DealView/API] Send Confirmation Failure', props<{ error: Error }>())

// export const escalateInvoice = createAction('[Invoice/API] Escalate Invoice', props<{ invoiceId: string }>())
export const escalateInvoiceSuccess = createAction('[Invoice/API] Escalate Invoice Success', props<{ dealId: string, invoiceId: string }>())
// export const escalateInvoiceFailure = createAction('[Invoice/API] Escalate Invoice Failure', props<{ error: Error }>())

// export const escalateInvoices = createAction('[Invoice/API] Escalate Invoices', props<{ invoiceIds: string[] }>())
export const escalateInvoicesSuccess = createAction('[Invoice/API] Escalate Invoices Success', props<{ dealIds: string[], invoiceIds: string[] }>())
// export const escalateInvoicesFailure = createAction('[Invoice/API] Escalate Invoices Failure', props<{ error: Error }>())
