<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon class="fa fa-2x fa-industry"></mat-icon>
  <h5>Warehouse {{warehouseName$ | async}}</h5>
  <button mat-icon-button type="button" matTooltip="Add/See Notes" (click)="showSegmentNotes()">
    <mat-icon class="fa fa-2x fa-pencil"></mat-icon>
  </button>
  <button mat-icon-button type="button" *ngIf="canRemoveSegment" matTooltip="Delete Warehouse" (click)="removeSegment.next()">
    <mat-icon class="fa fa-2x fa-trash-can"></mat-icon>
  </button>
</div>
<ng-container [formGroup]="segmentForm">
  <div gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr)" gdGap="0 15px">
    <tc-select-search placeholder="Storage Company"
        [readonly]="readonly"
        [group]="segmentForm" ctrlName="carrierId"
        (change)="onCarrierChange()"
        [items]="carriers$ | async"
        bindValue="carrier_id"
        bindLabel="name"
        ></tc-select-search>
    <tc-select-search placeholder="Location"
        [readonly]="readonly"
        [group]="segmentForm" ctrlName="originId"
        (change)="onLocationChange()"
        [items]="locations$ | async"
        bindValue="location_id"
        bindLabel="name"
        bindHint="fullname"
        ></tc-select-search>
    <tc-address-field placeholder="Exact  Address" [readonly]="readonly || !segmentForm.controls.carrierAccountId.value" expand="always"
        [ctrl]="segmentForm.controls.exactLoadingAddress"
        [accountCtrl]="segmentForm.controls.exactLoadingAccount"
        [addressFormat]="['name', 'street1', 'street2', 'city', 'state_code', 'country', 'postal']"
        [pickerOptions]="warehouseAddressOptions$ | async"
        (addressChanged)="onAddressChange()"
        ></tc-address-field>
    <tc-epoch-field placeholder="Beginning Date"
        [readonly]="readonly" [group]="segmentForm" ctrlName="etdDate"
        ></tc-epoch-field>
    <tc-epoch-field placeholder="Ending Date"
        [readonly]="readonly" [group]="segmentForm" ctrlName="etaDate"
        ></tc-epoch-field>
    <mat-form-field>
      <mat-label>Storage Container</mat-label>
      <input matInput [readonly]="readonly" formControlName="containerNumber">
    </mat-form-field>
    <div gdColumns="minmax(0, 2fr) minmax(0, 1fr)" gdGap="15px">
      <tc-epoch-field placeholder="Pickup Date" title="Pickup Date"
          [readonly]="readonly" [group]="segmentForm" ctrlName="actualPickupDate"
          ></tc-epoch-field>
      <tc-epoch-field type="time" placeholder="Pickup Time" title="Pickup Time"
          [readonly]="readonly" [group]="internalForm" ctrlName="actualPickupTime"
          ></tc-epoch-field>
    </div>
    <div gdColumns="minmax(0, 2fr) minmax(0, 1fr)" gdGap="15px">
      <tc-epoch-field placeholder="Drop-off Date" title="Drop-off Date"
          [readonly]="readonly" [group]="segmentForm" ctrlName="actualDeliveryDate"
          ></tc-epoch-field>
      <tc-epoch-field type="time" placeholder="Drop-off Time" title="Drop-off Time"
          [readonly]="readonly" [group]="internalForm" ctrlName="actualDeliveryTime"
          ></tc-epoch-field>
    </div>
    <mat-form-field>
      <mat-label>Pickup Company</mat-label>
      <input matInput [readonly]="readonly" formControlName="pickupCompany">
    </mat-form-field>
  </div>
</ng-container>
