import { Injectable } from '@angular/core';
import { DEAL_CONFIRMED, DEAL_DRAFT } from '@tradecafe/types/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service';
import { ModalService } from 'src/shared/modal';
import { InventoryDealsFormComponent } from './inventory-deals-form.component';

export interface InventoryDealsFormOptions {}

@Injectable()
export class InventoryDealsFormService {
  constructor(
    private modal: ModalService,
    private elastic: DealElasticSearchService,
  ) {}

  show(opts: any) {
    const payload = {
      sort: [{ deal_id: 'desc' }],
      columns: [
        'deal_id',
        'container_number',
        'supplier',
        'origin_location',
        'buying_trader',
        'shipment_date',
      ],
      query: {
        product: [opts.product],
        status: [DEAL_DRAFT, DEAL_CONFIRMED],
        deal_type: "bwi"
      },
      skip: 0,
      limit: 10,
      calculate: false,
      populate_intransit_balance: false,
    }

    return this.elastic.fetchDeals(payload)
      .pipe(switchMap(res => {
        return this.modal.openDialog(InventoryDealsFormComponent, opts, {
          panelClass: ['tc-dialog', 'modal-xlg'],
          width: null,
          maxWidth: null,
        })
      }))
  }
}
