import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { CostsListModule } from "src/components/costs-list/costs-list.module";
import { InvoicesListModule } from "src/components/invoices/invoices-list";
import { ReactiveAsteriskModule } from "src/components/reactive-asterisk/reactive-asterisk.module";
import { SelectSearchModule } from "src/components/select-search/select-search.module";
import { ModalModule } from "src/shared/modal";
import { CreateOfferFormComponent } from "./create-offer-form.component";
import { CreateOfferFormService } from "./create-offer-form.service";

@NgModule({
  declarations: [CreateOfferFormComponent],
  providers: [CreateOfferFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,
    SelectSearchModule,
    CostsListModule,
    InvoicesListModule,
    ReactiveFormsModule,
    ModalModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ReactiveAsteriskModule,
  ]
})
export class CreateOfferFormModule {}
