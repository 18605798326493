import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { AccountObject, DealRow, DealViewRawCosts, DealViewRawDeal, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { identity, isEqual, map as _map, negate, pick, sortBy, uniq } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, mapTo, startWith, switchMap, take } from 'rxjs/operators'
import { selectAccountEntities } from 'src/app/store/accounts'
import { loadDealViews, massDealFormSuccess, saveDealFormSuccess, sendConfirmationSuccess, setDestLocationSuccess, setOriginLocationSuccess } from 'src/app/store/deal-view.actions'
import { selectDealsByIds } from 'src/app/store/deal-view.reducer'
import { CsvFormatterService } from 'src/components/csv-exporter/csv-formatter.service'
import { columnDefsById, columnNames, DealsListComponent } from 'src/components/deals-list'
import { waitNotEmpty } from 'src/services/data/utils'
import { SimpleDataSource } from 'src/services/table-utils/data-sources/simple-data-source'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { TypedFormGroup } from 'src/shared/utils/typed-forms'
import { CloneFormService } from '../../deal-clones/clone-form/clone-form.service'


interface CloneFiltersFormValue { buyerId: number[], supplierId: number[] }
@Component({
  selector: 'tc-deal-clones-v2',
  templateUrl: './deal-clones.component.html',
  styleUrls: ['./deal-clones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealClonesComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private store: Store,
    private actions$: Actions,
    private csvFormatter: CsvFormatterService,
    private CloneForm: CloneFormService,
    private toaster: ToasterService,
  ) { super() }

  // deals list configuration
  readonly exportColumns = ['seq', 'supplier', 'buyer', 'origin_location', 'shipment_date', 'delivery_date', 'buy_price', 'sell_price', 'partial_margin', 'deal_id', 'fx_bid_rate', 'fx_ask_rate']
  readonly displayColumns = ['select', 'seq', 'supplier', 'buyer', 'origin_location', 'shipment_date', 'closingDate', 'delivery_date', 'buy_price', 'sell_price', 'partial_margin', 'deal_id', 'clonesMenu']
  readonly tableIdentity = TableKey.DealClonesTab
  readonly columnNames = columnNames(this.tableIdentity)

  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawCosts>>
  @ViewChild(DealsListComponent) dealsList: DealsListComponent


  filtersForm = new UntypedFormGroup({
    buyerId: new UntypedFormControl([]),
    supplierId: new UntypedFormControl([]),
  }) as TypedFormGroup<CloneFiltersFormValue>
  private cloneIds$: Observable<DeepReadonly<string[]>>
  private clones$: Observable<DealRow[]>

  dataSource: SimpleDataSource<DealRow, CloneFiltersFormValue>

  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  suppliers$: Observable<DeepReadonly<AccountObject[]>>
  buyers$: Observable<DeepReadonly<AccountObject[]>>
  showSupplierFilter$: Observable<boolean>
  showBuyerFilter$: Observable<boolean>
  showFilters$: Observable<boolean>

  canClone$: Observable<boolean>

  ngOnInit() {
    this.cloneIds$ = this.dealViewRaw$.pipe(map(dv => dv.deal.attributes.clone_ids))
    this.clones$ = this.cloneIds$.pipe(
      waitNotEmpty(),
      switchMap(dealIds => this.store.select(selectDealsByIds(dealIds))),
      filter(deals => deals.some(identity)))
    this.canClone$ = this.dealViewRaw$.pipe(
      map(dv => dv.deal.deal_id && !dv.deal.attributes.clone_ids?.length),
      distinctUntilChanged())

    this.suppliers$ = combineLatest([this.accounts$, this.clones$]).pipe(map(([accounts, clones]) =>
      sortBy(_map(pick(accounts, uniq(clones.map(deal => deal?.supplier_id)))), 'name')))
    this.buyers$ = combineLatest([this.accounts$, this.clones$]).pipe(map(([accounts, clones]) =>
      sortBy(_map(pick(accounts, uniq(clones.map(deal => deal?.buyer_id)))), 'name')))
    this.showSupplierFilter$ = this.suppliers$.pipe(map(suppliers => suppliers.length > 1), distinctUntilChanged())
    this.showBuyerFilter$ = this.buyers$.pipe(map(buyers => buyers.length > 1), distinctUntilChanged())
    this.showFilters$ = combineLatest([this.showSupplierFilter$, this.showBuyerFilter$]).pipe(map(([a, b]) => a || b))

    this.dataSource = new SimpleDataSource<DealRow, CloneFiltersFormValue>(
      this.clones$,
      this.filtersForm,
      (deals, filters) => {
        if (filters.buyerId.length) {
          deals = deals.filter(deal => filters.buyerId.includes(deal.buyer_id))
        }
        if (filters.supplierId.length) {
          deals = deals.filter(deal => filters.supplierId.includes(deal.supplier_id))
        }
        return sortBy(deals, 'seq')
      })
    const columnDefs = columnDefsById(this.tableIdentity)
    this.dataSource.sortingDataAccessor = (row: DealRow, sortHeaderId: string) =>
      this.csvFormatter.getCellValue(row, columnDefs[sortHeaderId])

    combineLatest([
      this.cloneIds$.pipe(waitNotEmpty(), map(ids => ids.length)),
      this.clones$.pipe(waitNotEmpty(), map(clones => clones.filter(negate(identity)).length)),
    ]).pipe(
      distinctUntilChanged(isEqual),
      untilComponentDestroyed(this),
    ).subscribe(([total, fetched]) => {
      if (total < fetched) this.toaster.warning(`Unable to load ${total - fetched} out of ${total} clones`)
    })

    combineLatest([
      this.cloneIds$.pipe(waitNotEmpty(), distinctUntilChanged(isEqual)),
      this.actions$.pipe(ofType(setOriginLocationSuccess, setDestLocationSuccess, massDealFormSuccess, sendConfirmationSuccess, saveDealFormSuccess), mapTo(1), startWith(1)),
    ]).pipe(untilComponentDestroyed(this)).subscribe(([deal_ids]) => {
      this.store.dispatch(loadDealViews({
        tableKey: TableKey.DealClonesTab,
        filters: { deal_ids, pageSize: Number.MAX_SAFE_INTEGER, limit: 1000, columns: this.exportColumns },
        page: 0,
        calculate: false,
      }))
    })
  }

  showCloneDeal() {
    this.dealViewRaw$.pipe(take(1)).subscribe(dv =>
      this.CloneForm.show2(dv))
  }

  get selectedIds$() {
    return this.dealsList.selection.selectedIds$
  }
}
