import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, createSelector, on } from '@ngrx/store'
import { DealRow, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { isEqual, omit } from 'lodash-es'
import { createDealView } from 'src/services/data/deal-view.service'
import { ElasticSearchFilters } from 'src/services/elastic-search'
import { prepareDealsPayload } from './deal-view-filters.utils'
import * as DealViewActions from './deal-view.actions'
import { State } from './reducers'

export const dealViewsFeatureKey = 'dealViews'
export interface DealViewsState extends EntityState<DeepReadonly<DealRow>> {
  filters: Dictionary<ElasticSearchFilters>
  prevQuery: Dictionary<any>

  // deal ids lists grouped by table key
  tables: Dictionary<string[]>
  // total deals lists grouped by table key
  totals: Dictionary<Dictionary<any>>
}
const selectId = deal => deal.deal_id
const adapter = createEntityAdapter<DeepReadonly<DealRow>>({ selectId })

const initialState: DealViewsState = adapter.getInitialState({
  tables: {},
  totals: {},
  filters: {},
  prevQuery: {},
})


export const dealViewReducer = createReducer(
  initialState,
  on(DealViewActions.loadDealViews,
    (state, action) => {
      const key = action.tableKey
      const query = prepareDealsPayload(action.filters, action.page, action.populate_intransit_balance)

      const prev = state.prevQuery[key] || {}

      let { tables, totals, prevQuery } = state
      if (!isEqual(query.query, prev.query)) {
        tables = { ...tables, [key]: undefined } // drop table
        totals = { ...totals, [key]: undefined } // clear total
        prevQuery = { ...prevQuery, [key]: query } // save query
      } else if (!isEqual(query.sort, prev.sort)) {
        const table = [...tables[key] || []]
        tables = { ...tables, [key]: table.fill(undefined) } // clear table
        prevQuery = { ...prevQuery, [key]: query } // save query
      }
      return { ...state, tables, totals, prevQuery }
    }),
  on(DealViewActions.loadDealViewsSuccess,
    (state, action) => {
      const key = action.tableKey
      const { total, totals: totals_, dealViews, payload: { skip, limit } } = action
      const dealIds = dealViews.map(selectId)

      let table = state.tables[key] || []
      table = Array.from<string>({ length: total }).fill(undefined)

      table.splice(skip, dealIds.length, ...dealIds) // NOTE: dealIds.length or limit?
      const tables = { ...state.tables, [key]: table }
      const totals = { ...state.totals, [key]: totals_ }

      state = adapter.upsertMany(action.dealViews, state)
      return { ...state, tables, totals }
    }),
  on(DealViewActions.loadDealFiltersSuccess,
    (state, action) => {
      const key = action.tableKey
      const filters = { ...state.filters, [key]: action.result }
      return { ...state, filters }
    }),
  on(
    DealViewActions.setDealDateRangeSuccess,
    DealViewActions.setDealDateSuccess,
    DealViewActions.setDealsColorSuccess,
    DealViewActions.setDealTextFieldSuccess,
    DealViewActions.startWorkingOnSuccess,
    DealViewActions.toggleDealFlagSuccess,
    DealViewActions.toggleInalfrescoFlagSuccess,
    DealViewActions.togglePortalAccessSuccess,
    DealViewActions.setDealConsigneeSuccess,
    DealViewActions.setDealShipperSuccess,
    (state, action) =>
      action.deals.reduce((st, deal) =>
        adapter.setOne({
          ...st.entities[deal.deal_id],
          ...omit(createDealView(deal), 'product', 'origin_location', 'dest_location'),
        }, st), state)),
  on(
    DealViewActions.approveDeringerPdfSuccess,
    DealViewActions.rejectDeringerPdfSuccess,
    (state, { deal }) =>
        adapter.setOne({
          ...state.entities[deal.deal_id],
          ...deal,
        }, state)),
  on(
    DealViewActions.setBookingIdSuccess,
    DealViewActions.setContainerIdSuccess,
    DealViewActions.setMexInvNoSuccess,
    DealViewActions.setItemTypeIdSuccess,
    DealViewActions.setSegmentTextFieldSuccess,
    DealViewActions.setSegmentAddressSuccess,
    DealViewActions.setDealStatusNotesSuccess,
    (state, action) =>
      adapter.setOne(action.deal, state)),
  on(
    DealViewActions.unwindDealSuccess,
    (state, {deal_id}) =>
      adapter.removeOne(deal_id, state),
  ),
)

const selectState = (state: State) => state[dealViewsFeatureKey]
export const { selectEntities: selectDealViewEntities } = adapter.getSelectors(selectState)

export const selectDeal = (dealId: string) => createSelector(
  selectDealViewEntities,
  (dealViews: Dictionary<DealRow>) =>
    dealViews[dealId])

export const selectDealsByIds = (dealIds: DeepReadonly<string[]>) => createSelector(
  selectDealViewEntities,
  (dealViews: Dictionary<DealRow>) =>
    dealIds.map(dealId => dealViews[dealId]))

export const selectRawDealsByIds = (dealIds: DeepReadonly<string[]>) => createSelector(
  selectDealViewEntities,
  (dealViews: Dictionary<DealRow>) =>
    dealIds.map(dealId => dealViews[dealId].raw))

const selectTables = createSelector(selectState, state => state.tables)

export const selectDealsFilters = (key: TableKey) => createSelector(
  selectState,
  state => state.filters[key],
)

export const selectDealsTotals = (key: TableKey) => createSelector(
  selectState,
  state => state.totals[key],
)

export const selectDealsTable = (key: TableKey) => createSelector(
  selectDealViewEntities,
  selectTables,
  (dealViews, tables) =>
    tables[key] ? tables[key].map(id => dealViews[id]) : undefined)

