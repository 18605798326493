import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { ToasterComponent } from './toaster.component'
import { ToasterService } from './toaster.service'

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatSnackBarModule],
  declarations: [ToasterComponent],
  providers: [ToasterService],
})
export class ToasterModule { }
