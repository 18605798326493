import { AbstractControl } from '@angular/forms'
import { MemoizedSelector, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from './wait-not-empty'

export function selectOptions<S extends object, R, J, P>(
  store: Store,
  selector: (selected: string) => MemoizedSelector<S, R, J>,
  form: AbstractControl,
): Observable<R> {
  return replayForm(form).pipe(
    switchMap(value => store.select(selector(value))),
    waitNotEmpty())
}
