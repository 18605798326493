import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { replayForm } from 'src/shared/utils/replay-form'
import { AddressPickerOptionsEx, AddressPickerService } from '../address-picker/address-picker.service'
import { AddressFieldValueDirective } from './address-field-value.directive'

export type AddressFieldPickerOptions = Omit<AddressPickerOptionsEx, 'address'|'addresses'|'allowMultiple'>

@Component({
  selector: 'tc-address-field',
  templateUrl: './address-field.component.html',
  styleUrls: ['./address-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFieldComponent<
  F extends UntypedFormControl = UntypedFormControl,
  FAcc extends UntypedFormControl = UntypedFormControl,
> extends OnDestroyMixin implements OnInit {

  constructor(
    private AddressPicker: AddressPickerService,
  ) { super() }

  @Input() placeholder: string
  @Input() multiple = false
  @Input() readonly = false
  @Input() group: UntypedFormGroup
  @Input() ctrlName: string
  @Input() ctrl: F
  @Input() accountCtrl: FAcc
  @Input() addressFormat = ['name']
  @Input() multiline = false
  @ContentChild(AddressFieldValueDirective, { read: TemplateRef }) innerContent: TemplateRef<AddressFieldValueDirective>
  @Input() expand: 'never' | 'onhover' | 'always' = 'onhover'
  @HostBinding('class.tc-expand-always') get classExpandAlways() { return this.expand === 'always' }
  @HostBinding('class.tc-expand-onhover') get classExpandOnhover() { return this.expand === 'onhover' }

  @Output() pickAddress = new EventEmitter<F>()
  @Output() clearAddress = new EventEmitter<F>()
  @Output() addressChanged = new EventEmitter<F>()

  @Input() set pickerOptions(v: DeepReadonly<AddressFieldPickerOptions>) { this.pickerOptions$.next(v) }
  get pickerOptions(): DeepReadonly<AddressFieldPickerOptions> { return this.pickerOptions$.value }
  private pickerOptions$ = new BehaviorSubject<DeepReadonly<AddressFieldPickerOptions>>(undefined)

  hasValue$: Observable<boolean>

  private pickerOptionsWithValue$: Observable<DeepReadonly<AddressPickerOptionsEx>>
  hasChanged$: Observable<boolean>

  ngOnInit(): void {
    if (this.group && this.ctrlName) {
      this.ctrl = this.group.controls[this.ctrlName] as F
    }
    if (!this.ctrl) throw new Error(`can not find control "${this.ctrlName || '[undefined ctrlName]'}"`)

    this.hasValue$ = replayForm(this.ctrl).pipe(map(value => this.multiple ? value?.length : value), distinctUntilChanged())
    this.pickerOptionsWithValue$ = combineLatest([replayForm(this.ctrl), this.pickerOptions$]).pipe(map(([value, po]) =>
      this.multiple ? { ...po, addresses: value } : { ...po, address: value }))
    this.hasChanged$ = this.pickerOptionsWithValue$.pipe(switchMap(po => this.AddressPicker.hasChanged(po)))

    if (!this.pickAddress.observers.length) {
      this.pickAddress.pipe(untilComponentDestroyed(this)).subscribe(() => this.showAddressPicker())
    }
    if (!this.clearAddress.observers.length) {
      this.clearAddress.pipe(untilComponentDestroyed(this)).subscribe(() => this.doClearAddress())
    }
  }

  click() {
    if (this.readonly || this.ctrl.disabled) return
    this.pickAddress.next(this.ctrl)
  }

  clear($event: MouseEvent) {
    if (this.readonly || this.ctrl.disabled) return
    $event.stopPropagation()
    this.clearAddress.next(this.ctrl)
  }

  showAddressPicker() {
    const options = this.multiple
      ? { ...this.pickerOptions, addresses: this.ctrl.value }
      : { ...this.pickerOptions, address: this.ctrl.value }
    this.AddressPicker.show(options).subscribe(({ addresses, address, account }) => {
      this.ctrl.setValue(this.multiple ? addresses : address)
      this.ctrl.markAsDirty()
      this.ctrl.markAsTouched()
      this.accountCtrl?.setValue(account)
      this.accountCtrl?.markAsDirty()
      this.accountCtrl?.markAsTouched()
      this.addressChanged.next()
    })
  }

  doClearAddress() {
    this.ctrl.reset()
    this.ctrl.markAsDirty()
    this.ctrl.markAsTouched()
    this.accountCtrl?.reset()
    this.accountCtrl?.markAsDirty()
    this.accountCtrl?.markAsTouched()
    this.addressChanged.next()
  }

}
