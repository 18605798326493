import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Cost, DEAL_CLOSED, DEAL_CONFIRMED, DEAL_DRAFT, DealBase, DealDateField, DealPartyE, DealRow, DealSimpleBooleanField, DealViewClones, TableKey, MacropointOrderStatus } from '@tradecafe/types/core'
import { DeepReadonly, isDealPortalVisible, isDeringerDocument, lookupSegments } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { map as _map, get, identity, intersection, sortBy } from 'lodash-es'
import { Observable, combineLatest } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { createAESExportReport } from 'src/app/store/aes'
import { approveDeringerPdf, copyCloneDeal, rejectDeringerPdf, setBookingId, setContainerId, setDealConsignee, setDealDate, setDealDateRange, setDealShipper, setDealStatusNotes, setDealTextField, setDealsColor, setDestLocation, setItemTypeId, setMexInvNo, setOriginLocation, setSegmentAddress, setSegmentDate, setSegmentTextField, startWorkingOn, toggleDealFlag, toggleInalfrescoFlag, togglePortalAccess, unwindDeal } from 'src/app/store/deal-view.actions'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadLocations, selectAllLocations } from 'src/app/store/locations'
import { createMacropointOrder, stopMacropointOrder, updateMacropointOrder } from 'src/app/store/macropoint'
import { AesRequestReviewDialogService } from 'src/components/aes-request-review-dialog/aes-request-review-dialog.service'
import { AesResponseReviewDialogService } from 'src/components/aes-response-review-dialog/aes-response-review-dialog.service'
import { MacropointOrderLogOverlayService } from 'src/components/macropoint-log-overlay/macropoint-order-log-overlay.service'
import { InvoiceFormService } from 'src/components/invoices/invoice-form/invoice-form.service'
import { environment } from 'src/environments/environment'
import { EpochPipe } from 'src/filters/epoch.pipe'
import { DealProductsOverlayService } from 'src/pages/admin/logistics/shipping-details-page/deal-products-list/deal-products-overlay/deal-products-overlay.service'
import { ShippingDetailsOverlayService } from 'src/pages/admin/logistics/shipping-details-page/shipping-details-overlay/shipping-details-overlay.service'
import { TradingDetailsOverlayService } from 'src/pages/admin/logistics/shipping-details-page/trading-details-overlay/trading-details-overlay.service'
import { SelectStatusNotesFormService } from 'src/pages/admin/logistics/shipping-logs/list/forms/select-status-notes-form/select-status-notes-form.service'
import { CreateOfferFormService } from 'src/pages/admin/trading/deal-form/create-offer-form/create-offer-form.service'
import { CopyCloneFormService } from 'src/pages/admin/trading/deal-form/deal-clones/copy-clone-form/copy-clone-form.service'
import { DealDocumentsService } from 'src/pages/admin/trading/deals/deal-documents/deal-documents.service'
import { DocumentsOverlayService } from 'src/pages/admin/trading/deals/deal-documents/documents-overlay/documents-overlay.service'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { DealDetailsOverlayService } from 'src/pages/admin/trading/deals/list/actions/deal-details-overlay/deal-details-overlay.service'
import { DealFinanceOverlayService } from 'src/pages/admin/trading/deals/list/actions/deal-finance-overlay/deal-finance-overlay.service'
import { ShipmentDetailsOverlayService } from 'src/pages/admin/trading/deals/list/actions/shipment-details-overlay/shipment-details-overlay.service'
import { RequestPrepaymentFormService } from 'src/pages/admin/trading/deals/request-prepayment/request-prepayment.service'
import { SendConfirmationFormService } from 'src/pages/admin/trading/deals/send-confirmation-form/send-confirmation-form.service'
import { CompanyCreditFormService } from 'src/services/actions/company.service'
import { DealOverlaysService } from 'src/services/actions/deal-overlays.service'
import { DealSubmitService } from 'src/services/actions/deal-submit.service'
import { SegmentActionsService } from 'src/services/actions/segment-actions.service'
import { AccountsService } from 'src/services/data/accounts.service'
import { composeContainerTrackingUrl } from 'src/services/data/compose-container-tracking-url'
import { DealViewLoaderService } from 'src/services/data/deal-view-loader.service'
import { DealViewPermissionsService } from 'src/services/data/deal-view-permissions.service'
import { DealsService } from 'src/services/data/deals.service'
import { MacropointService } from 'src/services/data/macropoint.service'
import { AES_CANCELLATION_REASON, MONTSHIP_BOOKING_REJECTION_REASON } from 'src/services/data/notes.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { ElasticSearchFilters } from 'src/services/elastic-search'
import { ColumnDef, FiltersFormGroup } from 'src/services/table-utils'
import { StatefulDataSource } from 'src/services/table-utils/data-sources/stateful-data-source'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadCarriers, selectCarrierEntities } from '../../app/store/carriers'
import { BookingReviewDialogService } from '../booking-review-dialog/booking-review-dialog.service'
import { InlineEditorComponent } from '../inline-editor/inline-editor.component'
import { NotesOverlayService } from '../notes/notes-overlay/notes-overlay.service'
import { DEAL_COLUMNS_INTERNAL } from './deals-list.columns'
import { DEALS_FILTER_SETTINGS } from './deals-list.filters'
import { MacropointOrderDetailsDialogService } from '../macropoint-order-details-dialog/macropoint-order-details-dialog.service'

export const COLORS = {
  'Red': '#EA2F2F',
  'Green': '#2FEA2F',
  'Yellow': '#EACC2F',
  'Sky Blue': '#2FBDEA',
  'Lime Green': '#2FEA4D',
  'Orange': '#EA942F',
  'Purple': '#B92FEA',
  'Black': '#1D1221',
  'Blue': '#4899EE',
  'Bright Rose': '#FF33C5',
  'Slate Blue': '#605AE8',
  'Brown': '#654321',
  'Grey': '#8A8884',
  'Rose': '#EDDAE7',
}

export const COLOR_OPTIONS = [
  { name: 'No Color', color: 'transparent', id: '' },
  ...sortBy(_map(COLORS, (color, name) => ({ id: name, name, color })), 'name'),
]

@Component({
  selector: 'tc-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrls: ['./deals-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealsListComponent extends OnDestroyMixin implements OnInit {

  readonly COLORS = COLORS
  readonly COLOR_OPTIONS = COLOR_OPTIONS
  readonly DEALS_FILTER_SETTINGS = DEALS_FILTER_SETTINGS
  protected readonly DealPartyE = DealPartyE

  constructor(
    private CompanyCreditForm: CompanyCreditFormService,
    private DealDocuments: DealDocumentsService,
    private DealFinanceOverlay: DealFinanceOverlayService,
    private NotesOverlay: NotesOverlayService,
    private DealDetailsOverlay: DealDetailsOverlayService,
    private DealOverlays: DealOverlaysService,
    private DealSubmit: DealSubmitService,
    private DealViewLoader: DealViewLoaderService,
    private DealViewPermissions: DealViewPermissionsService,
    private TradingDetailsOverlay: TradingDetailsOverlayService,
    private Files: FilesService,
    private InvoiceForm: InvoiceFormService,
    private RequestPrepaymentForm: RequestPrepaymentFormService,
    private router: Router,
    private SelectStatusNotesForm: SelectStatusNotesFormService,
    private SendConfirmationForm: SendConfirmationFormService,
    private store: Store,
    private toaster: ToasterService,
    private SegmentActions: SegmentActionsService,
    private CopyCloneForm: CopyCloneFormService,
    private Deals: DealsService,
    private CreateOfferForm: CreateOfferFormService,
    private DealProductsOverlay: DealProductsOverlayService,
    private ShippingDetailsOverlay: ShippingDetailsOverlayService,
    private ShipmentDetailsOverlay: ShipmentDetailsOverlayService,
    private DocumentsOverlay: DocumentsOverlayService,
    private BookingReviewDialog: BookingReviewDialogService,
    private AesRequestReviewDialog: AesRequestReviewDialogService,
    private AesResponseReviewDialog: AesResponseReviewDialogService,
    private MacropointOrderDetailsDialog: MacropointOrderDetailsDialogService,
    private MacropointService: MacropointService,
    private MacropointOrderLogOverlayService: MacropointOrderLogOverlayService,
    private AuthApi: AuthApiService,
    private Account: AccountsService,
    private epochPipe: EpochPipe,
  ) { super() }

  @HostBinding('class')
  get hostClasses() {
    return `${this.class} tc-deals-list tc-data-${this.dataSource.state$.value}`
  }

  @HostBinding('style.--deals-list-header-height')
  readonly headerRowHeight = '56px'

  @HostBinding('style.--deals-list-rows-height')
  readonly visibleRowsHeight = '60px'

  @HostBinding('style.--deals-list-footer-height')
  readonly visibleFooterHeight = '48px'

  @HostBinding('style.--deals-list-filters-height')
  readonly visibleFiltersHeight = '63px'

  @HostBinding('style.--deals-list-rows-visible')
  get visibleRowsCount() {
    return Math.min(this.dataSource.paginator?.pageSize || Number.POSITIVE_INFINITY, this.dataSource.filteredData?.length || 0)
  }

  get displayColumns() { return this._displayColumns }

  @Input()
  set displayColumns(value: string[]) {
    this._displayColumns = value
    if(this._displayColumns?.length && this._availableColumns?.length) {
      this._displayColumns = this.getOrderedDisplayedColumns();
    }
    this.filterColumns = this._displayColumns.map(col => `${col}-filter`)
  }

  @Input()
  class = ''

  @Input()
  isScrollable = false

  @Input()
  filtersForm: FiltersFormGroup<any>

  @Input()
  dataSource: StatefulDataSource<DeepReadonly<DealRow>>

  private _displayColumns: string[] = []
  filterColumns: string[] = []

  private _availableColumns: string[];
  availableColumnFilters: { colName: string, filterName: string }[]
  @Input()
  set availableColumns(value: string[]) {
    this._availableColumns = value;
    if(this._displayColumns?.length && this._availableColumns?.length) {
      this._displayColumns = this.getOrderedDisplayedColumns();
    }
    this.availableColumnFilters = value.map(colName => ({
      colName, filterName: colName === 'deal_id' ? 'deal_ids' : colName}))
  }

  @Input()
  columnNames: Dictionary<string>

  @Input()
  filtersData: ElasticSearchFilters

  @Input()
  selectAll = true

  @Input()
  selectOne = false

  @Input()
  back: string

  @Input()
  navigateTo: string

  @Input()
  isEditBlocked: boolean

  @Input()
  tableIdentity: TableKey

  get isDeringerPage() {
    return this.tableIdentity === TableKey.IntegrationsDeringerPage
  }

  get isInventoryModal() {
    return this.tableIdentity === TableKey.DealInventory
  }

  @Output()
  rowClick = new EventEmitter<{ deal: DeepReadonly<DealRow>, column?: string, tabName?: string }>()

  @Output()
  dealIdClick = new EventEmitter<string>()

  isClickable: boolean

  @Output()
  selectionChange = new EventEmitter<DeepReadonly<DealRow[]>>()

  @Output()
  applyFilters = new EventEmitter<void>()

  @Output()
  editColumns = new EventEmitter<void>()


  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator) }
  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.setSort(sort) }

  @ViewChild(InlineEditorComponent)
  inlineEditor: InlineEditorComponent

  selection = new TableSelection<DeepReadonly<DealRow>>('deal_id')
  readonly itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  readonly locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())

  // feature flags
  readonly enableMacropoint = environment.enableMacropoint

  @Input()
  totals$: Observable<any>
  totalWeight$: Observable<{ total: number, measure_id: string}>
  totalPackagesCount$: Observable<number>
  totalInvoicedAmt$: Observable<number>
  totalPartialMargin$: Observable<number>
  totalDeringerBonds$: Observable<number>
  private carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())

  protected MacropointStatusDescription = MacropointOrderStatus;

  composeContainerTrackingUrl = composeContainerTrackingUrl

  canEdit = (deal: DeepReadonly<DealRow>, fieldName: string, opts?: { toast: boolean }) =>
      !this.isEditBlocked && this.DealViewPermissions.canEdit(deal, fieldName, opts)

  getRowId = (i: number, deal: DeepReadonly<DealBase>) =>
    // undefined deal = fake. loading screen.
    deal?.deal_id || i
  getDealUrl = (dealId: string) =>
    this.navigateTo + '/' + dealId

  ngOnInit() {
    if (!this.availableColumnFilters) this.availableColumns = this.displayColumns
    this.store.dispatch(loadItemTypes())
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadLocations({}))
    if (this.totals$) {
      this.totalWeight$ = this.totals$.pipe(filter(identity), map(t => t.total_weight))
      this.totalPackagesCount$ = this.totals$.pipe(filter(identity), map(t => t.packages_count))
      this.totalInvoicedAmt$ = this.totals$.pipe(filter(identity), map(t => t.invoiced_amt))
      this.totalPartialMargin$ = this.totals$.pipe(filter(identity), map(t => t.partial_margin))
      this.totalDeringerBonds$ = this.totals$.pipe(filter(identity), map(t => t.deringer_pdf_bonds))
    }
    this.isClickable = !!this.rowClick.length

    this.selection.selectedIds$.pipe(untilComponentDestroyed(this))
    .subscribe(ids => {
      const selectedRows = this.dataSource.data.filter(row => row && ids.includes(row.deal_id))
      this.selectionChange.emit(selectedRows)
    })
  }

  openDealForm(deal: DeepReadonly<DealBase>) {
    const back = this.back ? `?back=${this.back}` : ''
    this.router.navigateByUrl(this.getDealUrl(deal.deal_id) + back)
  }

  canPreviewAllClones(deal: DealViewClones, party: DealPartyE) {
    return this.DealViewPermissions.canPreviewAllClones(deal, party)
  }

  isDealPortalVisible = isDealPortalVisible

  async previewDealDocuments(deal: DeepReadonly<DealRow>, party: DealPartyE) {
    const dealView = await this.fetchOldDeal(deal)
    return this.DealDocuments.previewDealDocuments(dealView, party)
  }

  async previewAllClonesDocuments(deal: DeepReadonly<DealRow>, party: DealPartyE) {
    const dealView = await this.fetchOldDeal(deal)
    return this.DealDocuments.previewAllClonesDocuments(dealView, party)
  }

  sendConfirmation(deal: DeepReadonly<DealRow>, to: DealPartyE) {
    this.SendConfirmationForm.sendConfirmation(deal, to)
  }

  sendConfirmationLabel(deal: DeepReadonly<DealRow>, to: DealPartyE) {
    return this.SendConfirmationForm.sendConfirmationLabel([deal], to)
  }

  canConfirm(deal: DeepReadonly<DealBase>, party: DealPartyE) {
    return this.DealViewPermissions.canConfirm([deal], party)
  }

  confirmDealParty(deal: DeepReadonly<DealRow>, party: DealPartyE) {
    return this.DealSubmit.confirmDealParty([deal], party)
  }

  async requestPrepayment(deal: DeepReadonly<DealRow>) {
    const dealView = await this.fetchOldDeal(deal)
    return this.RequestPrepaymentForm.show(dealView)
  }

  showMessagesOf(deal: DeepReadonly<DealRow>) {
    return this.DealOverlays.showMessagesOf(deal)
  }

  showDealNotes(deal: DeepReadonly<DealRow>) {
    combineLatest(this.Deals.getDealView(deal.deal_id, ['invoices'])).subscribe(([dv]) => {
      this.NotesOverlay.showDealNotes(deal.deal_id, dv.invoices?.map(i => i.invoice_id)).subscribe()
    })
  }

  showWarningNotes(row: DeepReadonly<DealRow>) {
    this.Deals.getDealView(row.deal_id, ['deal', 'invoices']).subscribe(({ deal, invoices }) =>
      this.NotesOverlay.showWarningNotes({ ...deal, invoices }).subscribe(() => {
        this.dataSource.refresh()
      }))
  }

  showDeringerWarningNotes(row: DeepReadonly<DealRow>) {
    this.Deals.getDealView(row.deal_id, ['deal', 'invoices']).subscribe(({ deal, invoices }) =>
      this.NotesOverlay.showDeringerWarningNotes({ ...deal, invoices }).subscribe(() => {
        this.dataSource.refresh()
      }))
  }

  showDeringerNotes({ deal_id }: DeepReadonly<DealRow>) {
    this.Deals.getDealView(deal_id, ['deal', 'files']).subscribe(dv =>
      this.NotesOverlay.showDeringerNotes(deal_id, dv.files.filter(isDeringerDocument)).subscribe())
  }

  showMontshipRejectionNotes(deal: DeepReadonly<DealRow>) {
    this.NotesOverlay.showDealNotes(deal.deal_id, [MONTSHIP_BOOKING_REJECTION_REASON]).subscribe()
  }

  showAesCancellationNotes(deal: DeepReadonly<DealRow>) {
    this.NotesOverlay.showDealNotes(deal.deal_id, [AES_CANCELLATION_REASON]).subscribe()
  }

  showBookingReview(deal: DeepReadonly<DealRow>) {
    this.BookingReviewDialog.showBookingReviewl({ dealId: deal.deal_id }).subscribe(approved => {
      if (approved) this.dataSource.refresh()
    })
  }

  showDealDocuments(deal: DeepReadonly<DealRow>) {
    this.DocumentsOverlay.showDealDocuments(deal.deal_id).subscribe()
  }

  showDeringerDocuments(deal: DeepReadonly<DealRow>) {
    this.DocumentsOverlay.showDealDocuments(deal.deal_id, {
      showDeringerCols: true,
    }).subscribe()
  }

  openDocumentsTracking(deal: DeepReadonly<DealRow>) {
    this.carriers$.pipe(take(1)).subscribe((carriers) => {
      return this.DealOverlays.openDocumentsTracking(deal, carriers)
    })
  }

  showDealDetails({deal_id}: DeepReadonly<Pick<DealBase, 'deal_id'>>) {
    this.DealDetailsOverlay.showDealDetails(deal_id)
  }

  showShipmentDetails(deal: DeepReadonly<DealRow>) {
    this.ShipmentDetailsOverlay.showShipmentDetails(deal.deal_id).subscribe()
  }

  showShippingDetails(deal: DeepReadonly<DealRow>) {
    this.ShippingDetailsOverlay.showShippingDetails(deal.deal_id).subscribe()
  }

  showFinanceDetails({deal_id}) {
    this.DealFinanceOverlay.showFinanceDetails(deal_id)
  }

  showCompanyCredit(deal: DeepReadonly<DealRow>) {
    this.CompanyCreditForm.showCompanyCredit(deal.buyer_id)
  }

  toggleVendorPortal(deal: DeepReadonly<DealRow>, account: number) {
    this.store.dispatch(togglePortalAccess({deal, account}))
  }

  showCopyClone(deal: DeepReadonly<DealRow>) {
    this.CopyCloneForm.show(deal).then(count => {
      this.store.dispatch(copyCloneDeal({ dealId: deal.deal_id, count }))
    })
  }

  hasAesCancellation(deal: DeepReadonly<DealRow>) {
    return deal.export_reports?.[0]?.requests?.some(x => x.action === 'cancel');
  }

  // function getPortalAccessOptions(deals) {
  //   const accountsById = keyBy($ctrl.accounts, 'account')
  //   return deals.reduce((res, deal) => {
  //     res[deal.deal_id] = getPortalAccessOptionsForDeal(deal)
  //     return res
  //   }, {})

  //   function getPortalAccessOptionsForDeal(deal) {
  //     const parties = getDealParties(deal, accountsById, $ctrl.carriersByKey)
  //     return parties.map(({account, name}) => ({
  //       account,
  //       name,
  //       selected: deal.attributes.portal_visible ||
  //         includes(deal.attributes.portal_access, account),
  //     }))
  //   }
  // }


  startWorkingOn(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(startWorkingOn({ deals: [deal] }))
  }

  showTradingDetails(deal: DeepReadonly<DealRow>) {
    this.TradingDetailsOverlay.showTradingDetails(deal.deal_id).subscribe()
  }

  showProductsDetails(deal: DeepReadonly<DealRow>) {
    this.DealProductsOverlay.showProductsDetails(deal.deal_id).subscribe()
  }

  async uploadInvoice(deal: DeepReadonly<DealRow>) {
    await this.InvoiceForm.showCreateInvoice(deal.deal_id)
    // TODO: use ngrx action, refetch and update row
    this.dataSource.refresh()
  }

  async updateLogisticsNote($event: MouseEvent, deal: DeepReadonly<DealRow>) {
    $event.stopPropagation()
    const notes = await this.SelectStatusNotesForm.select(deal.attributes.status_notes)
    this.store.dispatch(setDealStatusNotes({deal, notes}))
  }

  toggleSimpleBoolean($event: MouseEvent, deal: DeepReadonly<DealRow>, fieldName: DealSimpleBooleanField) {
    $event.stopPropagation()
    this.store.dispatch(toggleDealFlag({ deal, fieldName }))
  }

  toggleInalfrescoFlag($event: MouseEvent, deal: DeepReadonly<DealRow>) {
    $event.stopPropagation()
    this.store.dispatch(toggleInalfrescoFlag({ deal }))
  }

  pickExactLoadingAddress($event: MouseEvent, deal: DeepReadonly<DealRow>) {
    $event.stopPropagation()
    if (!deal.segments.length) {
      this.toaster.warning(`Deal # ${deal.deal_id} has no segments`)
    } else {
      this.SegmentActions.pickExactLoadingAddress(deal, deal.earliestSegment)
      .subscribe(value =>
        this.store.dispatch(setSegmentAddress({ deal, fieldName: 'earliestSegment.attributes.exact_loading', value })))
    }
  }

  pickExactDropoffAddress($event: MouseEvent, deal: DeepReadonly<DealRow>) {
    $event.stopPropagation()
    if (!deal.segments.length) {
      this.toaster.warning(`Deal # ${deal.deal_id} has no segments`)
    } else {
      this.SegmentActions.pickExactDropoffAddress(deal, deal.latestSegment)
      .subscribe(value =>
        this.store.dispatch(setSegmentAddress({ deal, fieldName: 'latestSegment.attributes.exact_dropoff', value })))
    }
  }

  pickConsignee($event: MouseEvent, deal: DeepReadonly<DealRow>) {
    $event.stopPropagation()
    this.SegmentActions.pickConsignee(deal)
    .subscribe(({address}) =>
      this.store.dispatch(setDealConsignee({ deal, address })))
  }

  pickShipper($event: MouseEvent, deal: DeepReadonly<DealRow>) {
    $event.stopPropagation()
    this.SegmentActions.pickShipper(deal)
    .subscribe(({address}) =>
      this.store.dispatch(setDealShipper({ deal, address })))
  }

  setColor(deal: DeepReadonly<DealRow>, color: string) {
    this.store.dispatch(setDealsColor({ deals: [deal], color }))
  }

  approveDeringer(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(approveDeringerPdf({ deal_id: deal.deal_id, file_id: deal.deringer.file_id }))
  }

  rejectDeringer(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(rejectDeringerPdf({ deal_id: deal.deal_id, file_id: deal.deringer.file_id }))
  }

  async downloadFile($event: MouseEvent, fileId: string) {
    $event.preventDefault()
    const file = await this.Files.getFileById(fileId)
    this.Files.download(file)
  }

  async inlineEditPickupDate(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    if (!this.canEdit(deal, 'earliestTruckSegment.attributes.actual_pickup_date')) return
    const { earliestTruck: segment } = lookupSegments(deal.segments)
    const date = await this.inlineEditor.pickDate(el, () => ({
      date: segment?.attributes.actual_pickup_date,
      useUtc: true,
      showTimePicker: segment?.attributes.actual_pickup_date_time
    }))
    this.store.dispatch(setSegmentDate({ deal, segment, patch: { actualPickupDate: date } }))
  }

  async inlineEditDropoffDate(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    if (!this.canEdit(deal, 'latestTruckSegment.attributes.actual_delivery_date')) return
    const { latestTruck: segment } = lookupSegments(deal.segments)
    const date = await this.inlineEditor.pickDate(el, () => ({
      date: segment?.attributes.actual_delivery_date,
      useUtc: true,
      showTimePicker: segment.attributes.actual_delivery_date_time
    }))
    this.store.dispatch(setSegmentDate({ deal, segment, patch: { actualDeliveryDate: date } }))
  }

  async inlineEditEtd(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    if (!this.canEdit(deal, 'earliestVesselSegment.attributes.actual_pickup_date')) return
    const { earliestVessel: segment } = lookupSegments(deal.segments)
    const date = await this.inlineEditor.pickDate(el, () => ({
      date: segment?.attributes.actual_pickup_date,
      useUtc: true,
      showTimePicker: get(deal, 'earliestVesselSegment.attributes.actual_pickup_date_time'),
    }))
    this.store.dispatch(setSegmentDate({ deal, segment, patch: { actualPickupDate: date } }))
  }

  async inlineEditVesselCutoffDate(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    if (!this.canEdit(deal, 'earliestVesselSegment.attributes.cutoff_datetime')) return
    const { earliestVessel: segment } = lookupSegments(deal.segments)
    const date = await this.inlineEditor.pickDate(el, () => ({
      date: segment?.attributes.cutoff_datetime,
      useUtc: true,
    }))
    this.store.dispatch(setSegmentDate({ deal, segment, patch: { cutoffDatetime: date } }))
  }

  async inlineEditEta(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    if (!this.canEdit(deal, 'latestVesselSegment.attributes.actual_delivery_date')) return
    const { latestVessel: segment } = lookupSegments(deal.segments)
    const date = await this.inlineEditor.pickDate(el, () => ({
      date: segment?.attributes.actual_delivery_date,
      useUtc: true,
      showTimePicker: segment?.attributes.actual_delivery_date_time,
    }))
    this.store.dispatch(setSegmentDate({ deal, segment, patch: { actualDeliveryDate: date } }))
  }

  inlineDealDateEdit(deal: DeepReadonly<DealRow>, fieldName: DealDateField, el: HTMLElement, recalculate = false) {
    if (!this.canEdit(deal, fieldName)) return
    this.inlineEditor.pickDate(el, () => ({
      date: get(deal, fieldName),
      useUtc: true,
    })).then(date =>
      this.store.dispatch(setDealDate({ deal, fieldName, date, recalculate })))
  }

  inlineShipmentDateRangeEdit(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    // if (!this.canEdit(deal, fieldName)) return
    this.inlineEditor.pickDateRange(el, () => ({
      from: deal.attributes.shipment_dates.from,
      to: deal.attributes.shipment_dates.to,
      tbd: deal.attributes.shipment_dates.tbd,
      from2: deal.attributes.delivery_dates.from,
      to2: deal.attributes.delivery_dates.to,
      min: deal.attributes.deal_date || deal.created,
      max: deal.attributes.delivery_dates.to,
      useUtc: true,
    })).then(range =>
      this.store.dispatch(setDealDateRange({ deal, details: {
        shipmentDatesFrom: range.from,
        shipmentDatesTo: range.to,
        shipmentDatesTbd: range.tbd || '',
      }})))
  }

  inlineDeliveryDateRangeEdit(deal: DeepReadonly<DealRow>, el: HTMLElement) {
    // if (!this.canEdit(deal, fieldName)) return
    this.inlineEditor.pickDateRange(el, () => ({
      from: deal.attributes.delivery_dates.from,
      to: deal.attributes.delivery_dates.to,
      tbd: deal.attributes.delivery_dates.tbd,
      from2: deal.attributes.shipment_dates.from,
      to2: deal.attributes.shipment_dates.to,
      min: deal.attributes.shipment_dates.from || deal.created,
      useUtc: true,
    })).then(range =>
      this.store.dispatch(setDealDateRange({
        deal, details: {
          deliveryDatesFrom: range.from,
          deliveryDatesTo: range.to,
          deliveryDatesTbd: range.tbd,
        },
      })))
  }

  inlineBookingIdEdit(deal: DeepReadonly<DealRow>, cellEl: HTMLElement) {
    const fieldName = 'earliestVesselOrTruckSegment.booking_id'
    this.inlineTextEdit(deal, fieldName, cellEl, { editingBooking: true })
  }

  inlineContainerIdEdit(deal: DeepReadonly<DealRow>, cellEl: HTMLElement) {
    const fieldName = 'earliestVesselOrTruckSegment.attributes.container_number'
    this.inlineTextEdit(deal, fieldName, cellEl, { editingContainer: true })
  }

  inlineMexInvNoEdit(deal: DeepReadonly<DealRow>, cellEl: HTMLElement) {
    const fieldName = 'mexicanInvoiceNo'
    this.inlineTextEdit(deal, fieldName, cellEl, { editingMexInvNo: true })
  }

  inlineSegmentTextEdit(deal: DeepReadonly<DealRow>, fieldName: string, cellEl: HTMLElement) {
    this.inlineTextEdit(deal, fieldName, cellEl, { editingSegment: true })
  }

  inlineTextEdit(deal: DeepReadonly<DealRow>, fieldName: string, cellEl: HTMLElement, opts: any = {}) {
    if (!this.canEdit(deal, fieldName)) return
    this.inlineEditor.editText(() => get(deal, fieldName), cellEl).then((text: string) => {
      if (opts.editingBooking) this.store.dispatch(setBookingId({ deal, bookingId: text }))
      else if (opts.editingContainer) this.store.dispatch(setContainerId({ deal, containerId: text }))
      else if (opts.editingSegment) this.store.dispatch(setSegmentTextField({ deal, fieldName, text }))
      else if (opts.editingMexInvNo) this.store.dispatch(setMexInvNo({ deal, mexInvNo: text }))
      else this.store.dispatch(setDealTextField({ deal, fieldName, text }))
    })
  }

  inlineItemTypeEdit(deal: DeepReadonly<DealRow>, cellEl: HTMLElement) {
    if (!this.canEdit(deal, 'product.item_type')) return
    this.itemTypes$.pipe(take(1)).subscribe((itemTypes) => {
      this.inlineEditor.selectOption({
        items: itemTypes,
        bindLabel: 'name',
        bindValue: 'item_type_id',
      }, () => deal.product?.item_type?.item_type_id, cellEl)
      .then((itemTypeId: string) => {
        this.store.dispatch(setItemTypeId({ deal: deal.raw, itemTypeId }))
      })
    })
  }

  inlineOriginEdit(deal: DeepReadonly<DealRow>, cellEl: HTMLElement) {
    if (!this.canEdit(deal, 'origin_location')) return
    this.locations$.pipe(take(1)).subscribe((locations) => {
      this.inlineEditor.selectOption({
        items: locations,
        bindLabel: 'name',
        bindValue: 'location_id',
      }, () => deal.origin_location, cellEl)
      .then((locationId: string) => {
        this.store.dispatch(setOriginLocation({ deal, locationId }))
      })
    })
  }

  inlineDestEdit(deal: DeepReadonly<DealRow>, cellEl: HTMLElement) {
    if (!this.canEdit(deal, 'dest_location')) return
    this.locations$.pipe(take(1)).subscribe((locations) => {
      this.inlineEditor.selectOption({
        items: locations,
        bindLabel: 'name',
        bindValue: 'location_id',
      }, () => deal.dest_location, cellEl)
      .then((locationId: string) => {
        this.store.dispatch(setDestLocation({ deal, locationId }))
      })
    })
  }


  private fetchOldDeal(deal: DeepReadonly<DealRow>, parts = [ 'costs', 'credit-notes', 'vendor-credits', 'invoices', 'segments:dynamic', 'notes', 'files', 'editable' ]) {
    return this.DealViewLoader.getDealWith(deal.deal_id, parts)
  }

  canCreateSupplierOffer(deal: DeepReadonly<DealRow>) {
    return this.DealViewPermissions.canCreateSupplierOffer(deal)
  }

  async createSupplierOffer(deal: DeepReadonly<DealRow>, defaultData? : { defaultBuyers: string[], costs: DeepReadonly<Cost[]> }) {
    const dv = await this.Deals.getDealView(deal.deal_id, ['deal', 'bids', 'offers', 'costs', 'invoices']).toPromise();
    await this.CreateOfferForm.showCreateOffer(dv, defaultData);
  }

  canUnwindDeal(deal: DeepReadonly<DealRow>) {
    return this.AuthApi.currentUser.role === 'manager'
      && (
        (
          deal.deal_id.endsWith('TCI')
          && deal.status === DEAL_DRAFT
        )
        || (
          deal.deal_id.endsWith('TC')
          && (
            deal.status === DEAL_CLOSED || deal.status === DEAL_CONFIRMED
          )
          && this.Account.isBwiInventory(deal.buyer_id)
        )
      )
  }

  unwindDeal(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(unwindDeal({ deal_id: deal.deal_id.endsWith('TC') ? deal.deal_id + 'I' : deal.deal_id }))
  }

  createAESExportReport(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(createAESExportReport({
      dealId: deal.deal_id,
      aesNumberAlreadyAssigned: !!deal?.attributes?.shipment?.aes,
    }))
  }

  createMacropointOrder(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(createMacropointOrder({
      deal,
    }))
  }

  updateMacropointOrder(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(updateMacropointOrder({
      dealId: deal.deal_id,
      segment: deal.earliestTruckSegment,
    }))
  }

  stopMacropointOrder(deal: DeepReadonly<DealRow>) {
    this.store.dispatch(stopMacropointOrder({
      identifier: deal.macropoint_order?.tracking_request_id,
    }))
  }

  showMacropointOrderAuditLog(deal: DeepReadonly<DealRow>) {
    this.MacropointOrderLogOverlayService.showLogs(deal.macropoint_order?.order_id);
  }

  async openAESRequestModal(deal: DeepReadonly<DealRow>) {
    await this.AesRequestReviewDialog.showAesResponseReviewl({ dealId: deal.deal_id, deal }).toPromise()
    this.dataSource.refresh()
  }

  async openAESResponseModal(deal: DeepReadonly<DealRow>) {
    await this.AesResponseReviewDialog.showAesResponseReviewl({ dealId: deal.deal_id, deal: { export_reports: deal.export_reports } }).toPromise()
    this.dataSource.refresh()
  }

  async showMacropointOrderDetails(deal: DeepReadonly<DealRow>) {
    if(this.tableIdentity === TableKey.IntegrationsMacropointPage) {
      await this.MacropointOrderDetailsDialog.showMacropointOrderDetails({ dealId: deal.deal_id, orderId: deal.macropoint_order?.order_id })
    } else {
      await this.MacropointService.openMacropointOrderHyperlink(deal.macropoint_order.tracking_request_id)
    }
  }

  private getOrderedDisplayedColumns() {
    const orderedDisplayedColumns = sortBy([...DEAL_COLUMNS_INTERNAL, ...this._displayColumns], x => this.isColumnDefinition(x) ? x.index : 0).map(x => this.isColumnDefinition(x) ? x.field : x);
    return intersection(orderedDisplayedColumns, this._availableColumns);
  }

  private isColumnDefinition(value: ColumnDef | string): value is ColumnDef {
    return value.hasOwnProperty('index');
  }

  noteTooltipContent(deal: DeepReadonly<DealRow>) {
    if (!deal || !deal.notes || !Array.isArray(deal.notes)) {
      return null
    }
    let nts = deal.notes?.filter((n) => n.attributes.category === 'general' || n.attributes.category === 'general_shipping' || n.attributes.category === 'negative-margin')
    nts.sort((a, b) => a.created < b.created ? 1 : -1)
    return nts.map((n) => `${this.epochPipe.transform(n.created, 'MM/DD/YYYY')} ${n.body}`).join('\n')
  }

  highlightNoteIcon(deal: DeepReadonly<DealRow>) {
    if (Array.isArray(deal.notes)) {
      return deal.notes.some((n) => n.attributes.category === 'general' || n.attributes.category === 'general_shipping' || n.attributes.category === 'negative-margin')
    } else {
      return false
    }
  }

  highlightIntransitCreditNegative(row: DeepReadonly<DealRow>) {
    const now = dayjs.utc().unix()
    const times = row.segments.map((s) => {
      return Math.min(s.attributes.actual_pickup_date, s.attributes.etd_date)
    })
    const earliestTime = Math.min(...times)
    const timeDiff = earliestTime - now
    return this.tableIdentity === TableKey.LogisticsShippingLogPage
      && !this.Account.isBwiInventory(row.buyer_id)
      && (
        (row.int_cred_max - row.int_cred_avail < 0)
        || (
          timeDiff > 0
          && timeDiff < 3600 * 24 * 7
        )
      )
  }

  highlightWrongDealStatus(row: DeepReadonly<DealRow>) {
    return !!row
      && !this.Account.isBwiInventory(row.buyer_id)
      && (
        row.deal_id.endsWith('TCI')
        && row.status === 'Draft'
        || row.status === 'Submitted'
        || !row.attributes.estimated
      )
  }
}
