import { createAction, props } from '@ngrx/store'
import { Product, TableKey } from '@tradecafe/types/core'


export const loadProducts = createAction(
  '[Product/API] Load Products',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadProductsSuccess = createAction(
  '[Product/API] Load Products Success',
  props<{
    tableKey?: TableKey,
    products: Product[],
  }>(),
)
export const loadProductsFailure = createAction(
  '[Product/API] Load Products Failure',
  props<{ error: Error }>(),
)
