import { Injectable } from '@angular/core'
import { DealView } from '@tradecafe/types/core'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class RequestPrepaymentFormService {
  constructor(
    private modalHelper: ModalProxyService,
  ) {}

  show(deal: DealView) {
    return this.modalHelper.showModal({
      component: 'tcRequestPrepaymentForm',
      windowClass: 'modalShipping',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        deal: () => deal,
      },
    })
  }
}
