import { TableIdentities } from '../constants/table-identities'
import {
  buyersResolver, countriesResolver,
  currenciesResolver, locationsResolver,
  measuresResolver, pricingTermsResolver, productsResolver,
  suppliersResolver, usersResolver
} from '../services/data/deal.resolvers'
import { tableViewsLoader } from '../services/data/table-views.service'

export const routes = [
  {
    name: 'app.trading.supplier-offers',
    url: '/supplier-offers',
    component: 'tcSupplierOffers',
    data: {
      title: 'Supplier Offers',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.auctionSupplierOffersList,
      tableViewsLoader,
      countries: countriesResolver,
      currencies: currenciesResolver,
      locations: locationsResolver,
      measures: measuresResolver,
      pricingTerms: pricingTermsResolver,
      products: productsResolver,
      suppliers: suppliersResolver,
      users: usersResolver,
    },
  }, {
    name: 'app.trading.matched-offers',
    url: '/matched-offers?supplierOffersIds',
    component: 'tcMatchedOffers',
    data: {
      title: 'Matched Offers',
    },
    params: {
      supplierOffersIds: null,
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.auctionMatchedOffersList,
      tableViewsLoader,
      countries: countriesResolver,
      currencies: currenciesResolver,
      locations: locationsResolver,
      measures: measuresResolver,
      pricingTerms: pricingTermsResolver,
      products: productsResolver,
      suppliers: suppliersResolver,
      buyers: buyersResolver,
      users: usersResolver,
    },
  },
]
