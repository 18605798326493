import { Injectable } from '@angular/core'
import { AccountObject, Product, SegmentType } from '@tradecafe/types/core'
import { compact, filter, find, flatten, get, groupBy, keyBy, keys, map, memoize, omit, pick, reject, snakeCase, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { ProductApiService } from 'src/api/product/product'

export const ALLOWED_FIELDS = ['name', 'category_id', 'type_id', 'name_override', 'attributes', 'unique_cost', 'schedule_b_codes', 'so_creation']

@Injectable()
export class ProductsService {
  constructor(
    private AuthApi: AuthApiService,
    private ProductApi: ProductApiService,
  ) {}

  private fetchAllCached = memoize(this.fetchAll)
  getAll = this.fetchAllCached

  async getBySegmentType(type: SegmentType) {
    // WA-2809
    // "Freight" (category), "In Land Freight" (type), "Truck" (service) if the Freight Rate has a type = land
    // "Freight" (category), "Ocean Freight" (type), "Shipping Rate" (service) if the Freight Rate has a type = sea
    // TODO: move to the backend
    const products = await this.getAll()
    return find(products, {name: type === 'land' ? 'Truck' : 'Shipping Rate'})
  }

  async getByIds(ids?) {
    const all = await this.fetchAllCached()
    const index = keyBy(all, 'product_id')
    return ids ? pick(index, ids) : index
  }

  private async fetchAll() {
    const { data } = await this.ProductApi.list({limit: Number.MAX_SAFE_INTEGER})
    return data
  }


  create(product: Partial<Product>) {
    const payload: Partial<Product> = pick(product, ALLOWED_FIELDS)
    const {user_id} = this.AuthApi.currentUser
    payload.user_id = user_id // TODO: SER-286
    this.fetchAllCached.cache.clear()
    return this.ProductApi.create(payload).then((res) => {
      return res.data
    })
  }

  update(product: Partial<Product>) {
    const payload: Partial<Product> = pick(product, ALLOWED_FIELDS)
    const {user_id} = this.AuthApi.currentUser
    payload.user_id = user_id // TODO: SER-286
    this.fetchAllCached.cache.clear()
    return this.ProductApi.update(product.product_id, payload).then((res) => {
      return res.data
    })
  }

  archive(product: Product, archived: 0|1) {
    return this.ProductApi.update(product.product_id, {archived}).then(() => {
      product.archived = archived
      this.fetchAllCached.cache.clear()
    })
  }

  remove(product: Product) {
    this.ProductApi.delete(product.product_id).then(() => {
      this.fetchAllCached.cache.clear()
    })
  }

  // TODO: safeguard code/api. use companies after WA-5591 passed QA at prod
  /**
   * Upgrade companies and products
   *
   * @param {*} companies accounts keyed by id
   * @param {*} products products keyed by id
   */
  migrateProductOverrides(companies, products) {
    checkProductOverrides(companies, products)

    const companyProducts = {}
    const companyProductsSpec = {}
    filter(companies, (company) => {
      let changed = false
      companyProducts[company.account] = company.products.map(row =>
        typeof row === 'string' ? { product_id: row/* , profile: true */ } : row)
      companyProductsSpec[company.account] = company.products_spec || companyProducts[company.account]
      companyProducts[company.account].forEach(syncWithProducts)
      companyProductsSpec[company.account].forEach(syncWithProducts)
      if (!company.products_spec) {
        console.debug(`WA-5591: migration ${company.account}.products_spec is undefined`)
        changed = true
        company.products_spec = []
      }

      return changed
    })

    filter(products, (product) => {
      const productOverrides = getOldProductOverrides(companies, product)
      if (!productOverrides.length) return false

      productOverrides.forEach((productOverride) => {
        const company = companies[productOverride.account]
        if (!find(companyProductsSpec[company.account], { product_id: product.product_id })) {
          companyProductsSpec[company.account].push({...omit(productOverride, 'account')/* , profile: false */ })
        }
      })

      return true
    })

    map(companies, (company) => {
      if (company.products_spec.length !== companyProductsSpec[company.account].length) {
        console.debug(`WA-5591: migration specs mismatch ${company.products_spec.length} != ${companyProductsSpec[company.account].length}`, company, companyProductsSpec[company.account])
      }
      // company.products_spec = specFromProductsSpec[company.account]
    })
    // EOFn

    function syncWithProducts(company: AccountObject) {
      return (override) => {
        const product = products[override.product_id]
        const productOverride = find(getOldProductOverrides(companies, product), { account: company.account })
        const mismatch = productOverride && (
          // tslint:disable-next-line: triple-equals
          productOverride.hs_code != override.hs_code ||
          // tslint:disable-next-line: triple-equals
          productOverride.name != override.name)
        if (mismatch) {
          override.hs_code = override.hs_code || productOverride.hs_code
          override.name = override.name || productOverride.name
        }
      }
    }
  }
}

function checkProductOverrides(companies, products) {
  // read name & hs_code overrides from products db (grouped by account id)
  const productOverrides = groupBy(flatten(map(products, product =>
    getOldProductOverrides(companies, product))), 'account')

  filter(companies, (company) => {
    if (!company.products_spec && company.products.length) {
      const p = pick(products, company.products)
      console.debug(`WA-5591: ${company.name} (${company.account}) - "products_spec" is undefined, while old profile isn't empty"
        old profile = ${map(p, 'name')}`)
    }

    // make sure overrides from products service are in company.products_spec
    const missingProductOverrides = reject(productOverrides[company.account], ({ product_id }) =>
      find(company.products_spec, { product_id }))
    if (missingProductOverrides.length) {
      console.debug(`WA-5591: ${company.name} (${company.account}) - product name/hs_code overrides are missing
        missing products = ${map(pick(products, map(missingProductOverrides, 'product_id')), 'name')}
        company profile = ${map(pick(products, map(company.products_spec, 'product_id')), 'name')}
        missingProductOverrides =`, missingProductOverrides)
    }

    // make sure company.products are subset of company.products
    const missingCompanyProducts = reject(company.products, product_id =>
      find(company.products_spec, { product_id }))
    if (missingCompanyProducts.length) {
      console.debug(`WA-5591: ${company.name} (${company.account}) - products were in old profile
        old profile = ${map(pick(products, missingCompanyProducts), 'name')}
        company profile = ${map(pick(products, map(company.products_spec, 'product_id')), 'name')}
        missingCompanyProducts =`, missingCompanyProducts)
    }
  })
}


// TODO: safeguard code/api. use companies after WA-5591 passed QA at prod
/**
 * Read overrides from a product
 *
 * @param {*} companies accounts keyed by id
 * @param {*} product a product
 * @returns Array<{
 *            account: number,
 *            item_type_id?: string,
 *            name?: string,
 *            hs_code?: string,
 *          }>
 */
function getOldProductOverrides(companies: AccountObject[], product: Product) {
  const nameOverride = get(product, 'name_override', {})
  const hsCodeOverride = get(product, 'attributes.hs_code_override', {})

  const accounts = compact(map(uniq([...keys(nameOverride), ...keys(hsCodeOverride)]), (nameId) => {
    const company = companies[nameId.replace('_', '.')] ||
        find(companies, ({name}) => nameId === snakeCase(name))
    if (company) return { nameId, account: company.account }
    console.debug(`WA-5591: can't find find company with nameId=${nameId} for product product${product.name}`)
    return undefined
  }))

  return accounts.map(({account, nameId}) => ({
    account,
    product_id: product.product_id,
    name: nameOverride[nameId],
    hs_code: hsCodeOverride[nameId],
  }))
}
