import { BUYER, SERVICE_PROVIDER, SUPPLIER } from '@tradecafe/types/core'

const ACL = [
  /* eslint-disable no-multi-spaces */
  { type: 'Arrival_Notice',                  parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Bill_of_Lading',                  parties: [SERVICE_PROVIDER] },
  { type: 'CAFTA',                           parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Certificado_Origen_US_ Colombia', parties: [BUYER] },
  { type: 'Certificate_Origin',              parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Free_Sale_Certificate',           parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Invoice',                         parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Invoice_Lb',                      parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Invoice',                         parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Inv_Customs',                     parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Invoice',                         parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Inv_Inalfresco',                  parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Invoice',                         parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Loading Instructions',            parties: [SUPPLIER, SERVICE_PROVIDER] },
  { type: 'Loading_instructions',            parties: [SUPPLIER, SERVICE_PROVIDER] },
  { type: 'NAFTA _US_Dairy',                 parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Nafta_Ca_Dairy',                  parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Nafta_CA_Meat',                   parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Nafta_US_Meat',                   parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Packing_list',                    parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Packing_list',                    parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Packing_List_Lot_Number.',        parties: [BUYER, SERVICE_PROVIDER] },
  { type: 'Proforma',                        parties: [BUYER] },
  { type: 'Proforma_Colombia',               parties: [BUYER] },
  { type: 'Purchase_Order',                  parties: [SUPPLIER] },
  { type: 'Purchase_Confirmation_Colombia',  parties: [SUPPLIER] },
  { type: 'Sales_Confirmation',              parties: [BUYER] },
  { type: 'Supplier_Export_Instructions',    parties: [SUPPLIER, SERVICE_PROVIDER] },
  { type: 'Supplier_Instructions _Dairy',    parties: [SUPPLIER, SERVICE_PROVIDER] },
  { type: 'Trucking_Custody_Intructions',    parties: [SERVICE_PROVIDER] },
  { type: 'Trucking_Instructions',           parties: [SERVICE_PROVIDER] },
  { type: 'Trucking_Instructions_Dairy',     parties: [SERVICE_PROVIDER] },
  /* eslint-enable no-multi-spaces */
]

/**
 * Check if file could be visible to a party
 *
 * @param {string} docType - document type
 * @param {string} accountType - account type
 * @returns
 */
export function canBeVisible(docType: string, accountType) {
  const aclRecord = findAclRecordFor(docType)
  return !aclRecord || aclRecord.parties.includes(accountType)
}

export function isUnknownType(docType: string) {
  return !findAclRecordFor(docType)
}

function findAclRecordFor(docType: string) {
  return ACL.find(rule =>
    (docType || '').toLowerCase().startsWith(rule.type.toLowerCase()))
}
