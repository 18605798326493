import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ChangeBrokeragePartyFormComponent } from './change-brokerage-party-form.component'
import { ChangeBrokeragePartyFormService } from './change-brokerage-party-form.service'

@NgModule({
  providers: [ChangeBrokeragePartyFormService],
  declarations: [ChangeBrokeragePartyFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
    MatProgressBarModule,
    FlexLayoutModule,
  ],
})
export class ChangeBrokeragePartyFormModule {}
