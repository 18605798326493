<button mat-button [matMenuTriggerFor]="viewsMenu">
  <mat-icon class="fa fa-fw fa-cog"></mat-icon> Views
</button>

<mat-menu #viewsMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item
        (click)="saveNewTableView()">
      <i class="fa fa-fw fa-save"></i> Save New View
    </button>
    <button mat-menu-item
        (click)="updateTableView()"
        [disabled]="sharedOrNotSelected()">
      <i class="fa fa-fw fa-arrows-rotate"></i> Update View
    </button>
    <button mat-menu-item
        (click)="shareTableView()"
        [disabled]="sharedOrNotSelected()">
      <i class="fa fa-fw fa-share"></i> Share View
    </button>

    <button mat-menu-item
        (click)="deleteTableView()"
        [disabled]="sharedOrNotSelected()">
      <i class="fa fa-fw fa-trash-can"></i> Delete View
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item class="tc-table-view"
        [class.active]="isSelected(null)"
        [class.modified]="isModified(null)"
        (click)="restoreTableView(null)">
      <i class="fa fa-fw fa-bookmark"></i>
      <span flex> Default View</span>
      <i class="fa fa-fw fa-star" matTooltip="Modified" *ngIf="isSelected(null)"></i>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item class="tc-table-view"
        *ngFor="let view of myTableViews$ | async"
        [class.active]="isSelected(view)"
        [class.modified]="isModified(view)"
        (click)="restoreTableView(view)">
      <i class="fa fa-fw fa-bookmark"></i>
      <span flex> {{view.attributes.name}}</span>
      <i class="fa fa-fw fa-star" matTooltip="Modified" *ngIf="isSelected(view)"></i>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="sharedMenu"
        *ngIf="hasSharedTableViews$ | async">
      <i class="fa fa-fw fa-share-alt"></i> Shared Views
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        (click)="configureColumns()">
      <i class="fa fa-fw fa-columns"></i> Configure Columns</button>
  </ng-template>
</mat-menu>

<mat-menu #sharedMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item class="tc-table-view"
        *ngFor="let view of sharedTableViews$ | async; trackBy:getItemId"
        [class.active]="isSelected(view)"
        [class.modified]="isModified(view)"
        (click)="restoreTableView(view)">
      <i class="fa fa-fw fa-bookmark"></i>
      <span flex>{{ view.attributes.name}}</span>
      <i class="fa fa-fw fa-star" matTooltip="Modified" *ngIf="isSelected(view)"></i>
    </button>
  </ng-template>
</mat-menu>
