import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, TrackByFunction, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FloatLabelType } from '@angular/material/form-field'
import { MatSelect } from '@angular/material/select'
import { DeepReadonly } from '@tradecafe/types/utils'
import { map as _map, difference, get, isArray, isEqual, isNil, keyBy, pick, set } from 'lodash-es'
import { ReplaySubject, Subject } from 'rxjs'
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators'
import { SelectSearchOptionDirective } from './select-search-option.directive'


declare const ngDevMode
interface ItemEx {
  $hiddenOption?: boolean
  tooltip?: string
}

@Component({
  selector: 'tc-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent<T/*  extends ItemEx */> implements OnInit, OnDestroy, OnChanges {

  constructor(public _elementRef: ElementRef) {}

  @Input() items: DeepReadonly<T[]>
  private itemsByValue: Dictionary<T>
  @Input() placeholder: string
  @Input() realPlaceholder: string
  @Input() multiple = false
  @Input() hasBlank = false

  @Input() ctrl?: UntypedFormControl
  @Input() group?: UntypedFormGroup
  @Input() ctrlName?: string

  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' = 'left'
  @Input() showTooltips = false
  @Input() readonly: string | boolean = false
  @Input() removable = true

  @Input() hasButton = false
  @Input() buttonTooltip: string = undefined
  @Input() buttonText = false
  @Output() buttonAction = new EventEmitter()


  @Input() hiddenLabel: boolean
  @Input() bindLabel?: string
  @Input() bindValue?: string
  @Input() bindHint?: string

  @Input() floatLabel: FloatLabelType = 'auto'

  @Input() showCloseButton = false
  @Input() showSelectAll = true
  @Input() selectedTextFormat = 'count>2'

  @Input() set tabIndex(tabIndex: number) { this._matSelect.tabIndex = tabIndex }

  @Input()
  @HostBinding('class.inline')
  inline = false

  @ViewChild('innerSelectButton', { read: ElementRef }) innerSelectButton: ElementRef
  private _matSelect: MatSelect
  get matSelect() { return this._matSelect }
  @ViewChild('select', { static: false }) set matSelect(matSelect: MatSelect) {
    this._matSelect = matSelect
    if (matSelect && this.multiple) {
      this._matSelect['_selectOptionByValue'] = this._selectOptionByValue.bind(this._matSelect)
    }
  }
  @ContentChild(SelectSearchOptionDirective, { read: TemplateRef }) optionDirective: TemplateRef<SelectSearchOptionDirective>

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport

  @ViewChild(MatSelect, { static: false })
  select: MatSelect

  @Output() change = new EventEmitter<T>()
  @Output() search = new EventEmitter<string>()

  @Output() openedChange = new EventEmitter<boolean>()


  hasCtrl = false
  isRequired = false

  public itemFilterCtrl: UntypedFormControl = new UntypedFormControl()
  public filteredItems: ReplaySubject<DeepReadonly<T[]>> = new ReplaySubject<DeepReadonly<T[]>>(1)
  private _onDestroy = new Subject<void>()
  public selectedItems$ = this.filteredItems.pipe(switchMap(items =>
    this.ctrl.valueChanges.pipe(
      startWith(this.ctrl.value),
      map(value => {
      if (this.multiple ? !value?.length : !value) return []
      return items.filter(item => {
        const itemValue = this.bindValue ? get(item, this.bindValue) : item
        return this.multiple ? value.includes(itemValue) : value === itemValue
      }) as any[]
    }))))

  public hasLabel$ = this.selectedItems$.pipe(
    map(() => {
      const { text } = this.getTemplateContext();
      return Boolean(text);
  }));

  compareObjects(o1: DeepReadonly<T>, o2: DeepReadonly<T>): boolean {
    return isEqual(o1, o2)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.bindValue) {
      this.itemsByValue = keyBy(changes.items.currentValue, this.bindValue)
    }
    this.filterItems() // async search
  }

  ngOnInit() {

    this.items = this.items || []

    // safeguard. we don't support "Hidden" entries in simple lists
    if (this.hiddenLabel && (!this.bindValue || !this.bindLabel)) {
      console.error('tc-select-search[hiddenLabel] requires both [bindLabel] and [bindValue] to be defined')
    }

    if (this.ctrl) {
      this.hasCtrl = true
    } else if (this.group) {
      this.ctrl = this.group.controls[this.ctrlName] as UntypedFormControl
      if (!this.ctrl) throw new Error(`can not find control "${this.ctrlName}"`)
      // this.ctrl = this.group.get(this.ctrlName) as FormControl
    } else {
      this.ctrl = new UntypedFormControl()
    }

    this.checkIfRequired()
    this.filterItems()

    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((term) => {
        this.search.emit(term)
        this.filterItems()
      })
  }

  onSelectionChange() {
    let value = this.ctrl.value
    if (this.multiple) {
      if (isNil(value)) {
        value = []
      } else if (!isArray(value)) {
        value = [value]
      }
      value = value.map(item => this.bindValue ? this.itemsByValue[item] : item)
    } else {
      value = this.bindValue ? this.itemsByValue[value] : value
    }
    this.change.next(value)
  }

  ngOnDestroy() {
    this._onDestroy.next()
    this._onDestroy.complete()
  }

  open() {
    if (this._matSelect) this._matSelect.open()
  }

  checkIfRequired() {
    const errors = this.ctrl?.validator && this.ctrl.validator(new UntypedFormControl())
    this.isRequired = !isNil(errors) && errors.required
  }

  private filterItems() {
    if (!this.items) {
      return
    }

    let search = this.itemFilterCtrl.value
    if (!search) {
      let hiddenItems = []
      if (this.hiddenLabel) {
        const selectedValues = this.ctrl.value ? (Array.isArray(this.ctrl.value) ? this.ctrl.value : [this.ctrl.value]) : []
        const availableValues = _map(this.items, this.bindValue)
        hiddenItems = difference(selectedValues, availableValues).map(value => {
          const item = { $hiddenOption: true }
          set(item, this.bindValue, value)
          set(item, this.bindLabel, this.hiddenLabel)
          return item
        })
      }

      this.filteredItems.next([
        ...this.items,
        ...hiddenItems,
      ])
      return
    } else {
      search = search.toLowerCase()
    }

    this.filteredItems.next(
      this.items.filter(item => {
        const label: string = this.bindLabel ? get(item, this.bindLabel) : item
        const hint: string = this.bindHint ? get(item, this.bindHint) : item
        return (label ? ('' + label).toLowerCase().includes(search) : false) || (hint ? ('' + hint).toLowerCase().includes(search) : false)
      }),
    )
  }

  setWidth() {
    if (!this.hasButton) {
      return
    }
    setTimeout(() => {
      if (!this.innerSelectButton || !this.innerSelectButton.nativeElement) {
        return
      }
      let element: HTMLElement = this.innerSelectButton.nativeElement
      let panelElement: HTMLElement
      while (element = element.parentElement) {
        if (element.classList.contains('mat-select-panel')) {
          panelElement = element
          break
        }
      }
      if (panelElement) {
        this.innerSelectButton.nativeElement.style.width = panelElement.clientWidth + 'px'
      }
    }, 1)
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredItems.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          if (this.bindValue) val = val.map(v => get(v, this.bindValue))
          this._matSelect.options.forEach(opt => opt.select())
          this.ctrl.setValue(val)
        } else {
          this._matSelect.options.forEach(opt => opt.deselect())
          this.ctrl.setValue([])
        }
        this.ctrl.updateValueAndValidity()
      })
  }

  // tslint:disable-next-line: cyclomatic-complexity
  getTemplateContext() {
    const value = this.ctrl.value

    if (!this.multiple) {
      const item = this.bindValue ? this.itemsByValue[value] : value
      const text = this.bindLabel ? get(item, this.bindLabel) : item
      return { text, item }

    } else {
      if (!value?.length) return {}

      const items = this.bindValue ? _map(pick(this.itemsByValue, value)) : value
      const text = (this.bindLabel ? _map(items, this.bindLabel) : items).join(', ')
      if (this.selectedTextFormat.includes('count')) {
        // NOTE: @see https://github.com/silviomoreto/bootstrap-select/blob/389be9242798c59a17dd4a07bb940fa00035ae28/js/bootstrap-select.js
        // If this is multi select, and the selectText type is count, the show 1 of 2 selected etc..
        const [, max = 2] = this.selectedTextFormat.split('>')
        if (value.length > max) {
          return { items, text: `${value.length} items selected` }
        }
      }

      return { items, text }
    }
  }

  openChange($event: boolean) {
    if ($event) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0)
      this.cdkVirtualScrollViewPort.checkViewportSize()
      this.ctrl.updateValueAndValidity()
    }
    this.openedChange.emit($event)
    this.setWidth() // wtf?
  }

  clear($event: MouseEvent) {
    $event.stopPropagation()
    if (this.multiple) this.ctrl.setValue([])
    else this.ctrl.reset()
    this.ctrl.markAsDirty()
    this.ctrl.markAsTouched()
    this.ctrl.updateValueAndValidity()
    this.openedChange.emit(false)
  }

  trackBy: TrackByFunction<T> = (i, item) =>
    this.bindValue ? get(item, this.bindValue) : item

  // TODO: patch material files if works ok
  private _selectOptionByValue = function(value) {
    const correspondingOption = this.options.find((option) => {
      // Skip options that are already in the model. This allows us to handle cases
      // where the same primitive value is selected multiple times.
      if (this._selectionModel.isSelected(option)) {
        return false
      }
      try {
        // CHANGE: DO NOT "Treat null as a special reset value."
        return /* option.value != null &&  */this._compareWith(option.value, value)
      } catch (error) {
        if (typeof ngDevMode === 'undefined' || ngDevMode) {
          // Notify developers of errors in their comparator.
          console.warn(error)
        }
        return false
      }
    })
    if (correspondingOption) {
      this._selectionModel.select(correspondingOption)
    }
    return correspondingOption
  }
}
