import { NgModule } from '@angular/core'
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter as MatDateAdapter } from '@angular/material/core'
import { DatetimeAdapter, MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core'
import { EPOCH_DATE_ADAPTER_OPTIONS, EpochDateAdapter, EpochDatetimeAdapter } from './epoch-date-adapter'
import { MAT_EPOCH_DATE_FORMATS, MTX_EPOCH_DATETIME_FORMATS } from './epoch-date-formats'

@NgModule({
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_EPOCH_DATE_FORMATS },
    { provide: MTX_DATETIME_FORMATS, useValue: MTX_EPOCH_DATETIME_FORMATS },
    { provide: MatDateAdapter, useClass: EpochDateAdapter, deps: [MAT_DATE_LOCALE, EPOCH_DATE_ADAPTER_OPTIONS] },
    { provide: DatetimeAdapter, useClass: EpochDatetimeAdapter, deps: [MAT_DATE_LOCALE, EPOCH_DATE_ADAPTER_OPTIONS] },
  ],
})
export class EpochDateModule {}

@NgModule({
  imports: [EpochDateModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_EPOCH_DATE_FORMATS },
    { provide: MTX_DATETIME_FORMATS, useValue: MTX_EPOCH_DATETIME_FORMATS },
  ],
})
export class MatEpochDateModule {}
