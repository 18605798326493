<form [formGroup]="data.dealForm" (submit)="submit()">
  <div mat-dialog-title>
    <h2>Deal #{{dealId}} Details</h2>
    <tc-locks [resourceId]="dealId" resourceType="deal"></tc-locks>
    <tc-finance-issues [detailsForm]="data.dealForm.controls.details"></tc-finance-issues>
    <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete>
    <span fxFlex></span>
    <button mat-button type="button" (click)="cancel()">
      Cancel
    </button>
    <button mat-raised-button type="submit" color="primary"
        [matTooltip]="incomplete.incomplete" matTooltipClass="pre-tooltip"
        [disabled]="!!incomplete.incomplete">
      <i class="fa fa-fw"
          [class.fa-save]="!(inProgress$ | async)"
          [class.fa-spin]="inProgress$ | async"
          [class.fa-spinner]="inProgress$ | async"
          ></i> Save
    </button>
  </div>
  <div mat-dialog-content>
    <ng-container *ngFor="let productForm of data.dealForm.controls.products.controls; let index = index">
      <h5>Product {{index + 1}}</h5>
      <tc-deal-product-form
          [invoices$]="invoices$"
          [dealForm]="data.dealForm"
          [productForm]="productForm"
          ></tc-deal-product-form>
    </ng-container>
  </div>
</form>
