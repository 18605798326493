import { Injectable } from '@angular/core'
import {
  Offer, OFFER_CANCELED,
  OFFER_CLOSED,
  OFFER_CONFIRMED,
  OFFER_DRAFT,
  OFFER_MATCHED,
  OFFER_OPEN,
  OFFER_PARTIALLY_CONFIRMED,
  OFFER_SENT,
  OFFER_SUBMITTED
} from '@tradecafe/types/core'
import { groupBy, map, maxBy, memoize, pick } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { OfferApiService } from 'src/api/offer'
import { SupplierOfferApiService } from 'src/api/supplier-offer'
import { dayjs } from '../dayjs'

export const ALLOWED_FIELDS = ['account', 'country', 'deal_id', 'product', 'price', 'currency', 'wo_export_docs',
  'quantity', 'containers', 'ship_start', 'ship_end', 'type', 'note', 'pickup', 'expire',
  'establishment', 'status', 'wrapping', 'description', 'weight', 'incoterm', 'packing',
  'attributes', 'lot', 'gross_margin', 'costs', 'notes', 'buyers', 'locations', 'countries', 'loads', 'biddable']

/**
 * Offers service
 *
 * @see https://docs.google.com/document/d/1EWIctYczd9xjHAyuFw6ZA8TGIZmZXuyX_eaT_f3iwTQ
 *
 * @export
 * @returns
 */
@Injectable()
export class OffersService {
  constructor(
    private AuthApi: AuthApiService,
    private OfferApi: OfferApiService,
    private SupplierOfferApi: SupplierOfferApiService,
  ) {}

  private readonly getByDealIdsCached = memoize(this.getByDealIds)


  isFinalized = isOfferFinalized
  isExpired = isOfferExpired
  isCancelled = isOfferCancelled

  async getById(offerId: string) {
    const { data } = await this.OfferApi.get(this.AuthApi.currentUser.account, offerId)
    return data
  }

  async queryOffers(query) {
    const { data } = await this.SupplierOfferApi.filter(query)
    return data
  }

  /**
   * Get all offers
   *
   */
  async getAll(query: any = {}) {
    const { account } = this.AuthApi.currentUser
    const { data: offers } = await this.OfferApi.list(account, {
      limit: Number.MAX_SAFE_INTEGER,
      ...query,
    })
    return offers
  }
  /**
   * Get by deal_id
   *
   * @param {any} deal_id
   * @returns
   */
  async getByDealId(dealId: string) {
    const data = await this.getByDealIds([dealId])
    return data[dealId] || []
  }

  /**
   * Get by deal_ids
   *
   * @param {any} deal_ids
   * @returns
   */
  async getByDealIds(dealIds: string[]) {
    if (!dealIds.length) return {}
    const { account } = this.AuthApi.currentUser
    const { data } = await this.OfferApi.byDealIds(account, dealIds)
    return groupBy(data, 'deal_id')
  }

  /**
   * Update offer
   *
   * @param {any} offer
   * @returns
   */
  async update(offer: Partial<Offer>) {
    const { account } = this.AuthApi.currentUser
    const { offer_id } = offer
    const payload = pick(offer, ALLOWED_FIELDS)
    const { data, error } = await this.OfferApi.update(account, offer_id, payload)
    if (error) throw error
    if (offer.deal_id) this.getByDealIdsCached.cache.delete(offer.deal_id)
    else this.getByDealIdsCached.cache.clear()
    return data
  }

  async cancelOffers(offers: Offer[]) {
    const data = await this.OfferApi.cancel(map(offers, 'offer_id'))
    offers.forEach((offer) => {
      if (offer.deal_id) this.getByDealIdsCached.cache.delete(offer.deal_id)
      else this.getByDealIdsCached.cache.clear()
    })
    return data
  }

  async getCountryCodes() {
    const { data } = await this.OfferApi.getCountryCodes()
    return data
  }

  async findSubOffer(origin_deal_id: string, status?) {
    status = status || [OFFER_DRAFT, OFFER_OPEN, OFFER_CLOSED, OFFER_MATCHED, OFFER_SUBMITTED, OFFER_SENT, OFFER_CONFIRMED]
    const { data } = await this.OfferApi.filter(this.AuthApi.currentUser.account, { filters: { origin_deal_id, status }})
    return maxBy(data, 'created')
  }
}

export function isOfferFinalized(offer: Offer) {
  return offer.status === OFFER_CLOSED ||
    offer.status === OFFER_CANCELED ||
    offer.status === OFFER_CONFIRMED ||
    offer.status === OFFER_PARTIALLY_CONFIRMED
}

export function isOfferCancelled(offer: Offer) {
  return offer.status === OFFER_CANCELED;
}

export function isOfferExpired(offer: Offer) {
  return !isOfferFinalized(offer) && offer.expire < dayjs().unix()
}
