import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Deal, DealBase, DealPartyE, DealViewBase } from '@tradecafe/types/core'
import { DeepReadonly, isDealConfirmedBy, isDealPrimaryClone } from '@tradecafe/types/utils'
import { capitalize, flatten, get, map, some, uniq } from 'lodash-es'
import { DocumentSetApiService } from 'src/api/document-lib/document-set'
import { OperationsApiService } from 'src/api/operations'
import { sendConfirmationSuccess } from 'src/app/store/deal-view.actions'
import { loadMeasures } from 'src/app/store/measures'
import { loadProducts } from 'src/app/store/products'
import { DealValidationService } from 'src/services/actions/deal-validation.service'
import { ModalService } from 'src/shared/modal/modal.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { DealDocumentsService } from '../deal-documents/deal-documents.service'
import { SendConfirmationFormComponent, SendConfirmationFormOptions, SendConfirmationFormResult } from './send-confirmation-form.component'

@Injectable()
export class SendConfirmationFormService {
  constructor (
    private toaster: ToasterService,
    private OperationsApi: OperationsApiService,
    private DocumentSetApi: DocumentSetApiService,
    private DealValidation: DealValidationService,
    private DealDocuments: DealDocumentsService,
    private modal: ModalService,
    private store: Store,
  ) {}

  /**
   * Explicitly send confirmation email
   *
   * @param {DealView | DealView[]} deals, all deals should have same buyers or suppliers
   * @param {*} opts { to: 'buyer' | 'supplier' }
   */
  async sendConfirmation(deals: DeepReadonly<Array<Deal | DealViewBase> | Deal | DealViewBase>, to: DealPartyE) {
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadMeasures({}))

    // accept single deals
    deals = Array.isArray(deals) ? deals : [deals]

    // don't let send confirmations if buyer_id or supplier_id fields are different
    const account = this.DealValidation.ensureOneParty(deals, to)

    await this.DealValidation.checkBuyerCredit(deals, 'Selected deals are above Buyers credit limit. Get credit override from management or select less deals.')
    await this.DealValidation.askForNegativeMarginReason(deals)
    await this.DealDocuments.checkDocumentsIssues(deals)

    // ask for additional recipients (userIds) and if we should respect clones
    const omitUserIds = get(deals[0], `attributes.${to}_user_ids`,
      // NOTE: support old deal with single contact fields
      [get(deals[0], `${to}_user_id`)])
    // allow users to send clones only when the only selected deal is primary clone
    const suggestClonesOf = deals.filter(isDealPrimaryClone)
    const prefix = to === 'supplier' ? 'seller' : to
    const sentAlready = some(deals, `attributes.${prefix}_confirm_sent`)
    const res = await this.modal.openDialog<
      SendConfirmationFormComponent, SendConfirmationFormOptions, SendConfirmationFormResult
    >(SendConfirmationFormComponent, {
      sentAlready,
      account, // NOTE: this MUST be float or nothing
      // skip deal contacts
      omitUserIds,
      suggestClonesOf,
      confirmButtonText: 'OK',
      description: `Please select the users you want to send this confirmation request to.
                    Deal contact(s) for ${to} will always receive the confirmation request.`,
      description2: `Selected Deals: ${map(deals, 'deal_id').join(', ')}`,
    }).toPromise()

    if (!res) return
    const { userIds, confirmForClones = {} } = res

    const dealsToConfirm = uniq(flatten(deals.map(deal =>
      isDealPrimaryClone(deal) && confirmForClones[deal.deal_id]
        ? deal.attributes.clone_ids
        : [deal.deal_id])))

    // try to create confirmation documents for the deal (sequentially!)
    const filesToAttach = await this.DocumentSetApi.generateConfirmationDocuments({ deal_ids: deals.map(d => d.deal_id), party: to })
      .then(r => r.data)


    // send confirmation
    try {
      await this.OperationsApi.sendLink({
        to,
        recipients: userIds,
        deal_ids: dealsToConfirm,
        attachments: map(filesToAttach, 'file_id'),
      })
      this.store.dispatch(sendConfirmationSuccess({ dealIds: deals.map(d => d.deal_id) }))
      this.toaster.success('Confirmation request has been sent.')
    } catch (err) {
      console.error('Unable to send confirmation', err)
      this.toaster.error('Unable to send confirmation request.', err)
      throw err
    }
  }

  sendConfirmationLabel(deals: DeepReadonly<DealBase[]>, to: DealPartyE) {
    const send = deals.some(deal => isDealConfirmedBy(deal, to)) ? 'Resend' : 'Send'
    if (to === DealPartyE.brokerage_customer) return `${send} Brokerage Confirmation`
    return `${send} ${capitalize(to)} Confirmation`
  }
}
