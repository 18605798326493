import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { LocationObject, ShipmentRate, ShipmentRateStatuses, ShipmentRateTypes, TableKey, TableView } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { map as _map, compact, filter, find, keyBy, memoize, orderBy, pick, uniq, uniqBy } from 'lodash-es'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, tap } from 'rxjs/operators'
import { loadCarriers, selectAllCarriers, selectCarrierEntities } from 'src/app/store/carriers'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { loadLocations, selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { CsvExporterService } from 'src/components/csv-exporter/csv-exporter.service'
import { chunked, waitNotEmpty } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { FiltersService } from 'src/services/table-utils'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { GeoService } from '../../settings/locations/location-form/geo.service'
import { CopyFreightRatesFormService } from './copy-freight-rates/copy-freight-rates-form.service'
import { FreightRatesDataSource, isRateExpired } from './freight-rates-data-source'
import { AVAILABLE_COLUMNS, DEFAULT_COLUMNS, FREIGHT_RATES_COLUMNS, columnDefsById, columnNames } from './freight-rates-list/freight-rates-list.columns'
import { FreightRateRow, FreightRatesListComponent } from './freight-rates-list/freight-rates-list.component'
import { DEFAULT_FREIGHT_RATES_FILTERS, RATE_EXPIRED, RATE_VALID } from './freight-rates-list/freight-rates-list.filters'
import { FreightCategories, FreightRatesService } from './freight-rates.service'

@Component({
  selector: 'tc-freight-rates-page',
  templateUrl: './freight-rates-page.component.html',
  styleUrls: ['./freight-rates-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightRatesPageComponent extends OnDestroyMixin  {
  styles = this.sanitizer.bypassSecurityTrustHtml(`<style>body::-webkit-scrollbar { display: none; }</DeepReadonly<style>>`)

  constructor(
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private Filters: FiltersService,
    private FreightRates: FreightRatesService,
    private CopyFreightRatesForm: CopyFreightRatesFormService,
    private Geo: GeoService,
    private CsvExporter: CsvExporterService<DeepReadonly<FreightRateRow>>,
    private Toaster: ToasterService,
    private store: Store,
    private confirmModal: ConfirmModalService,
  ) { super() }

  // page configuration from route
  readonly tableIdentity = this.activeRoute.snapshot.data.tableIdentity as TableKey
  readonly title = this.activeRoute.snapshot.data.title as string
  readonly editable = this.activeRoute.snapshot.data.editable as boolean

  @ViewChild(FreightRatesListComponent, { static: true })
  protected freightRatesList: FreightRatesListComponent;


  // table configuration
  readonly columnDefs = columnDefsById(this.tableIdentity)
  readonly availableColumns = AVAILABLE_COLUMNS[this.tableIdentity]
  readonly columnNames = columnNames(this.tableIdentity)

  filtersForm = this.Filters.buildForm(this, {
    key: this.tableIdentity,
    cacheFilters: true,
    defaultValues: {
      ...DEFAULT_FREIGHT_RATES_FILTERS,
      columns: DEFAULT_COLUMNS[this.tableIdentity],
    },
  })

  hasFilteringEnabled$ = this.filtersForm.filtersActive$.pipe(map(n => !!n), distinctUntilChanged())
  freightRates$ = new BehaviorSubject<ShipmentRate[]>([])

  // Lookup values

  carriers$ = this.store.pipe(select(selectAllCarriers), waitNotEmpty(), map((carriers) => orderBy(carriers, 'name')))
  countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty(), map((carriers) => orderBy(carriers, 'name')))
  locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())

  locationsDictionary$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  carriersDictionary$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())
  countriesDictionary$ = this.countries$.pipe(map((countries) => keyBy(countries, 'code')))
  users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())

  private stateOptions(stateFilterControl: FormControl, countryFilterControl: FormControl) {
    return combineLatest([this.locations$, replayForm(countryFilterControl)]).pipe(map(([locations, countryCodes]) => {
      return this.getStates({locations, countryCodes});
    }), tap((stateOptions) => {
      const selectedStates = stateFilterControl.value;
      stateFilterControl.setValue(filter(selectedStates, value => find(stateOptions, { value })))
    }))
  }

  private cityOptions(cityFilterControl: FormControl, stateFilterControl: FormControl, countryFilterControl: FormControl) {
    return combineLatest([this.locations$, replayForm(stateFilterControl)]).pipe(map(([locations, stateCodes]) => {
      const countryCodes = countryFilterControl.value;
      return this.getCities({locations, countryCodes, stateCodes});
    }), tap((cityOptions) => {
      const selectedCities = cityFilterControl;
      cityFilterControl.setValue(filter(selectedCities, value => find(cityOptions, { value })))
    }))
  }

  originStateOptions$ = this.stateOptions(this.filtersForm.controls.orgState, this.filtersForm.controls.orgCountry)
  dstStateOptions$ = this.stateOptions(this.filtersForm.controls.dstState, this.filtersForm.controls.dstCountry)
  originCityOptions$ = this.cityOptions(this.filtersForm.controls.orgCity, this.filtersForm.controls.orgState, this.filtersForm.controls.orgCountry)
  dstCityOptions$ = this.cityOptions(this.filtersForm.controls.dstCity, this.filtersForm.controls.dstState, this.filtersForm.controls.dstCountry)

  containerSizesOptions$ = this.freightRates$.pipe(map((freightRates) => orderBy(uniq((compact(freightRates?.map((r) => r.container_size)))))))
  maxWeightOptions$ = this.freightRates$.pipe(map((freightRates) => orderBy(uniq((compact(freightRates?.map((r) => r.weight.max)))))))
  creatorOptions$ = combineLatest([this.freightRates$, this.users$]).pipe(map(([freightRates, users]) =>{
    let creators = uniqBy(freightRates, 'user_id')?.filter(fr => users[fr.user_id])?.map(fr => ({
      user_id: fr.user_id,
      fullname: users[fr.user_id]?.fullname
    }));
    return orderBy(creators, 'fullname');
  }))

  ShipmentRateTypes = orderBy(ShipmentRateTypes, 'name')
  ShipmentRateStatuses = orderBy(Object.keys(ShipmentRateStatuses).map(i => ShipmentRateStatuses[i]), 'label')
  FreightCategories = orderBy(FreightCategories, 'name')
  ValidityOptions = [RATE_VALID, RATE_EXPIRED]

  // table settings

  protected dataSource = new FreightRatesDataSource(
    this.freightRates$,
    this.filtersForm,
    this.locationsDictionary$,
    this.carriersDictionary$,
    this.countriesDictionary$,
    this.users$,
    this.Geo
  )

  displayColumns$ = replayForm(this.filtersForm.controls.columns)

  async ngOnInit() {
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadUsers({}))

    await this.reloadFreightRates();
  }


  protected clearFilters(): void {
    this.filtersForm.reset()
  }

  protected applyTableView(view: TableView) {
    const controls = Object.keys(this.filtersForm.controls)
    const filters = view ? {
      ...pick(DEFAULT_FREIGHT_RATES_FILTERS, controls),
      ...pick(view.filters, controls),
      table_view_id: view.table_view_id as TableKey,
    } : this.filtersForm.defaultValues
    this.filtersForm.patchValue(filters)
  }

  // top-level and batch actions

  protected showAddItem = async () => {
    const createdItem = await this.FreightRates.showFormModal({
      attributes: {
        date_quoted: dayjs.utc().unix(),
      },
    }, {
      title: 'New Freight Rate',
      mode: 'create',
      single: true,
    })

    if(!createdItem) return;
    await this.reloadFreightRates();
  }

  protected exportGrid() {
    this.CsvExporter.fromDataTable({
      dataSource: this.dataSource,
      fileName: `Freight Rates-${new Date()}`,
      selected: this.selectedFreightRates(),
      visible: this.filtersForm.get('columns').value,
      available: AVAILABLE_COLUMNS[this.tableIdentity],
      columnDefs: keyBy(FREIGHT_RATES_COLUMNS, 'field'),
      loadByIds: (rows, _columns) => Promise.resolve(rows)
    })
  }

  protected expireSelectedRates = async () => {
    const selectedItems = this.selectedFreightRates()
    if (selectedItems.some(item => isRateExpired(item))) {
      this.Toaster.warning('You can not expire "Expired" freight rates')
      return
    }
    await this.confirmModal.show({
      title: 'Expire selected freight rate(s)?',
      description: 'Are you sure you want to expire selected freight rate(s)?',
    })

    const expireAt = dayjs.utc().unix()
    try {
      await Promise.all(_map(selectedItems, (rate) => this.FreightRates.update({ ...rate, until: expireAt })))
      const currentTime = dayjs(expireAt).format('hh:mma')
      this.Toaster.success(`The expiry date of all selected rate(s) have been changed to today at ${currentTime}.`)
    } catch(e) {
      this.Toaster.error('Unable to expire selected rate(s).')
      console.log(e)
    }
    await this.reloadFreightRates();
  }

  protected archiveSelectedRates = async () => {
    const selectedItems = this.selectedFreightRates()
    await this.confirmModal.show({
      title: 'Archive selected freight rate(s)?',
      description: 'Are you sure you want to archive selected freight rate(s)?',
    })

    try {
      await chunked(selectedItems, 500, rates =>
        Promise.all(rates.map((rate) => this.FreightRates.update({ ...rate, archived: true }))))
      this.Toaster.success('All selected rate(s) archived successfully.')
    } catch(e) {
      this.Toaster.error('Unable to archive selected rate(s).')
      console.log(e)
    }

    await this.reloadFreightRates();
  }

  protected changeSelectedRates = () => {
    const selectedItems = this.selectedFreightRates()
    this.CopyFreightRatesForm.showBatchChange(selectedItems as ShipmentRate[]).subscribe(async clones => {
      if (!clones || !clones.length) return
      await this.reloadFreightRates();
    })
  }

  protected async reloadFreightRates(): Promise<void> {
    const response = await this.FreightRates.listAll();
    const rates = response?.data?.filter(x => !x.visible_to_matched_offer_id && !x.visible_to_deal_id)
    this.freightRates$.next(rates)
  }

  /**
   * Retrieves an array of currently selected rates
   *
   * @private
   * @returns {FreightRateRow[]}
   */
  private selectedFreightRates(): FreightRateRow[] {
    return this.dataSource.data.filter((row) => this.freightRatesList?.selection?.selectedIds?.has(row.rate_id))
  }

  /**
   * Retrieves all in-use states for the provided country codes
   */
  private getStates = memoize(({locations, countryCodes = [] }: {locations: DeepReadonly<LocationObject[]>, countryCodes: string[]}) => {
    const filtered = filter(locations, location =>
      location.state &&
      !countryCodes.length || countryCodes.includes(location.country))

      const stateOptions = _map(uniqBy(filtered, 'state'), (location: LocationObject) => ({
      value: location.state,
      name: this.Geo.getStateName(location.country, location.state) || location.state,
    }))

    return orderBy(stateOptions, 'name')
  })


  /**
   * Retrieves all in-use cities for the provided country and state codes
   */
  private getCities = memoize(({ locations, countryCodes = [], stateCodes = [] }: {locations: DeepReadonly<LocationObject[]>, countryCodes: string[], stateCodes: string[]}) => {
    const filtered = filter(locations, location =>
      location.city &&
      (!countryCodes.length || countryCodes.includes(location.country)) &&
      (!stateCodes.length || stateCodes.includes(location.state)))
    return orderBy(uniq(_map(filtered, 'city')).map(name => ({ value: name, name })), 'name')
  })
}
