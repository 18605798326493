import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { LetDirective } from '@ngrx/component'
import { ModalModule } from 'src/shared/modal'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { BookingReviewDialogComponent } from './booking-review-dialog.component'
import { BookingReviewDialogService } from './booking-review-dialog.service'
import { BookingRejectionDialogModule } from '../booking-rejection-dialog/booking-rejection-dialog.module'
import { NotesOverlayModule } from '../notes/notes-overlay/notes-overlay.module'


@NgModule({
  declarations: [
    BookingReviewDialogComponent,
  ],
  providers: [BookingReviewDialogService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ModalModule,
    PipesModule,
    LetDirective,
    BookingRejectionDialogModule,
    NotesOverlayModule,
  ],
})
export class BookingReviewDialogModule { }
