<cdk-virtual-scroll-viewport [itemSize]="55">
  <table mat-table [trackBy]="getRowId"
      fixedLayout [dataSource]="dataSource"
      matSort matSortStart="desc" matSortDisableClear>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"
        [class.tc-loading-row]="!row"
        [class.tc-ignored-note]="row?.note.attributes.ignored"
        [class.clickable]="canEdit(row)"
        ></tr>

    <ng-container matColumnDef="ellipsisMenu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        <div *ngIf="!row" class="skeleton-loader circle"></div>
        <button *ngIf="row" mat-icon-button type="button" [matMenuTriggerFor]="ellipsisMenu" [matMenuTriggerData]="{row: row}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.created">{{columnNames.created}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        </ng-container>
        <ng-container *ngIf="row">
          <ng-container *ngTemplateOutlet="cellEpoch; context: { timestamp: row.created }"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="relatesTo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.relatesTo">{{columnNames.relatesTo}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.relatesTo">
          <div class="text-ellipsis" [title]="row.relatesTo">{{row.relatesTo}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.category">{{columnNames.category}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.category">
          <div class="text-ellipsis" [title]="row.category">{{row.category}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="visibility">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.visibility">{{columnNames.visibility}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.visibility">
          <div class="text-ellipsis" [title]="row.visibility">{{row.visibility}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.user">{{columnNames.user}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.user">
          <div class="text-ellipsis" [title]="row.user">{{row.user}}</div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="body">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [title]="columnNames.body">{{columnNames.body}}</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
        <ng-container *ngIf="!row">
          <div class="skeleton-loader thin-line"></div>
        </ng-container>
        <ng-container *ngIf="row?.body">
          <div class="text-ellipsis" [title]="row.body">{{row.body}}</div>
        </ng-container>
      </td>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>
<mat-paginator #paginator></mat-paginator>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-row="row">
    <button mat-menu-item *ngIf="!row.note.attributes.ignored" (click)="showUpdateItem(row)">
      <i class="fa fa-fw fa-edit"></i> Edit
    </button>
    <button mat-menu-item *ngIf="!row.note.attributes.ignored" (click)="ignoreNote(row)">
      <i class="fa fa-fw fa-times"></i> Ignore
    </button>
    <button mat-menu-item *ngIf="!!row.note.attributes.ignored" (click)="unignoreNote(row)">
      <i class="fa fa-fw fa-times"></i> UnIgnore
    </button>
  </ng-template>
</mat-menu>

<ng-template #cellEpoch let-timestamp="timestamp" let-useUtc="useUtc">
  <div class="text-ellipsis" *ngIf="timestamp">
    <div class="text-ellipsis" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
    <small class="text-ellipsis text-gray" [title]="timestamp | epoch:'LT':useUtc">{{timestamp | epoch:'LT':useUtc}}</small>
  </div>
</ng-template>
