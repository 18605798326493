import { Injectable } from '@angular/core'
import { DealRow, Segment } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, get } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { AddressPickerService } from 'src/components/address-picker/address-picker.service'

/**
 * Segment Actions service
 *
 * @export
 */
@Injectable()
export class SegmentActionsService {
  constructor(private AddressPicker: AddressPickerService, private AuthApi: AuthApiService) {}

  pickExactLoadingAddress(deal: DeepReadonly<DealRow>, segment: DeepReadonly<Segment>) {
    return this.AddressPicker.show({
      title: 'Select Exact Loading Address',
      accountId: get(segment, 'attributes.exact_loading.account') ||
                 get(segment, 'attributes.carrier_account'),
      address: get(segment, 'attributes.exact_loading.address'),
      accountIds: compact([
        deal.buyer_id.toString(),
        deal.supplier_id.toString(),
        get(segment, 'attributes.carrier_account'),
        get(segment, 'attributes.freight_forwarder'),
      ]),
    })
  }

  pickExactDropoffAddress(deal: DeepReadonly<DealRow>, segment: DeepReadonly<Segment>) {
    return this.AddressPicker.show({
      title: 'Select Exact Drop-off Address',
      accountId: get(segment, 'attributes.exact_dropoff.account') ||
                 get(segment, 'attributes.carrier_account'),
      address: get(segment, 'attributes.exact_dropoff.address'),
      accountIds: compact([
        deal.buyer_id.toString(),
        deal.supplier_id.toString(),
        get(segment, 'attributes.carrier_account'),
        get(segment, 'attributes.freight_forwarder'),
      ]),
    })
  }

  pickConsignee(deal: DeepReadonly<DealRow>) {
    return this.AddressPicker.show({
      accountId: deal.buyer_id,
      address: get(deal, 'attributes.shipment.delivery.consignee'),
      title: 'Select Consignee Address',
      accountIds: compact([
        this.AuthApi.currentUser.account,
        deal.buyer_id,
      ]),
    })
      // .then(({address}) => {
      //   set(deal, 'attributes.shipment.delivery.consignee', address)
      //   set(deal, 'attributes.inalfresco', !!(address && address.inalfresco))
      // })
  }

  pickShipper(deal: DeepReadonly<DealRow>) {
    return this.AddressPicker.show({
      accountId: deal.supplier_id,
      address: get(deal, 'attributes.shipment.shipper'),
      title: 'Select Shipper Address',
      accountIds: compact([
        this.AuthApi.currentUser.account,
        deal.supplier_id,
      ]),
    })
  }
}
