import { Injectable } from '@angular/core'
import { isNil } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { AngularStorage } from './angular-storage.service'

@Injectable()
export class UserStorageService extends AngularStorage {
  constructor(private authService: AuthApiService) {
    super(localStorage, 'tc')
  }

  get(key: string, defaultValue: any = null) {
    const userId = this.authService.currentUser.user_id
    if (isNil(userId)) return defaultValue // 0?
    const userKey = `${key}::${userId}`
    return super.get(userKey, defaultValue)
  }

  has(key: string) {
    const userId = this.authService.currentUser.user_id
    if (isNil(userId)) return false // 0?
    const userKey = `${key}::${userId}`
    return super.has(userKey)
  }

  set(key: string, value: any = null) {
    const userId = this.authService.currentUser.user_id
    if (!isNil(userId)) {
      const userKey = `${key}::${userId}`
      super.set(userKey, value)
    }
  }

  remove(key: string) {
    const userId = this.authService.currentUser.user_id
    const userKey = `${key}::${userId}`
    super.remove(userKey)
  }
}
