import { Injectable } from '@angular/core'
import { Carrier } from '@tradecafe/types/core'
import { compact, filter, find, forEach, keyBy, map, memoize, pick } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { CarrierApiService } from 'src/api/shipment-routing/carrier'
import { assert } from 'src/services/assert'
import { AccountsService } from 'src/services/data/accounts.service'

const ALLOWED_FIELDS = ['name', 'type', 'description', 'attributes', 'archived']
const QUERY_ALL = {limit: Number.MAX_SAFE_INTEGER}


/**
 * Carriers service
 */
@Injectable()
export class CarriersService {
  constructor(
    private AuthApi: AuthApiService,
    private CarrierApi: CarrierApiService,
    private Accounts: AccountsService,
  ) {}

  private readonly fetchCarriersCached = memoize(this.fetchAll)
  private readonly getCarriersCached = memoize(this.getCarriersFresh)

  readonly getCarriers = this.getCarriersCached

  /**
   * Get carrier by id (not cached in JS)
   *
   * @param {any} carrier_id
   * @returns
   */
  async getById(carrier_id: string) {
    const { data } = await this.CarrierApi.get(carrier_id)
    return data
  }

  async getCachedById(carrier_id: string) {
    const carriers = await this.getCarriersCached()
    return find(carriers, {carrier_id})
  }

  async getCarriersByIds(carrierIds: string[]) {
    const carriers = await this.getCarriersCached()
    return carriers.filter(c => carrierIds.includes(c.carrier_id))
  }

  /**
   * Get carriers list (not cached in JS)
   *
   * @returns array with carriers
   */
  private async getCarriersFresh() {
    const [all, accounts] = await Promise.all([
      this.fetchCarriersCached().then(carriers => keyBy(carriers, 'carrier_id')),
      this.Accounts.getAccountsByIds(),
    ])

    // WA-1802, WA-1048: integrity check
    forEach(all, (carrier) => {
      const company = accounts[carrier.account]
      // assert(`WA-1802, WA-1048: found orphan carrier ${carrier.name} (${carrier.carrier_id})`, company && !company.archived)
      if (!company || company.archived) {
        // // This is an orphan carrier. We should remove it.
        // remove(carrier.carrier_id)
        return
      }
      assert(`WA-1802: company.attributes.carrier_id is not set ${carrier.name} (${carrier.carrier_id})`, company.attributes.carrier_id)
      if (!company.attributes.carrier_id) {
        // The company does not refer to it's carrier. We should fix this.
        // company.attributes.carrier_id = carrier.carrier_id
        return
      }
      // assert(`WA-1802: found orphan carrier ${carrier.name} (${carrier.carrier_id} vs ${company.attributes.carrier_id})`, company.attributes.carrier_id === carrier.carrier_id)
      // if (company.attributes.carrier_id !== carrier.carrier_id) {
      //   // const assigned = all[company.attributes.carrier_id] || {}
      //   // This is an orphan carrier. We should remove it.
      //   remove(carrier.carrier_id)
      // }

      // @ts-ignore
      carrier.status = company.status
    })

    // valid carriers = carriers, with carrier_id === company.attributes.carrier_id
    // (and the associated account is not archived. Additional filter needed as carrier has
    // a separate archived field from account)
    const valid = filter(pick(all, compact(map(accounts, 'attributes.carrier_id'))), carrier => !!(accounts[carrier.account] && !accounts[carrier.account].archived))

    // forEach(valid, (carrier) => {
    //   const company = accounts[carrier.account]
    //   console.debug(`carrier ${carrier.name} is OK and assigned to ${company.name || carrier.account}`)
    // })

    // return map(all)
    return map(valid) // return only valid carriers
  }

  async getCompanyCarrierId(account: number | string) {
    account = '' + account
    const carriers = await this.getCarriersCached()
    return find(carriers, {account})
  }

  private async fetchAll() {
    const { data } = await this.CarrierApi.list(QUERY_ALL)
    return data
  }

  async create(carrier: Partial<Carrier>) {
    const {user_id} = this.AuthApi.currentUser
    const account = '' + carrier.account // :facepalm:
    const payload = pick(carrier, ALLOWED_FIELDS)
    payload.user_id = user_id
    payload.account = '' + account
    const { data } = await this.CarrierApi.create(payload)
    await this.invalidateCache()
    return data
  }

  async update(carrier: Partial<Carrier>) {
    const {user_id} = this.AuthApi.currentUser
    const payload = pick(carrier, ALLOWED_FIELDS)
    payload.user_id = user_id
    const { data } = await this.CarrierApi.update(carrier.carrier_id, payload)
    await this.invalidateCache()
    return data
  }

  // async remove(carrierId) {
  //   const { data } = await CarrierApi.delete(carrierId)
  //   return data
  // }

  private async invalidateCache() {
    this.fetchCarriersCached.cache.clear()
    this.getCarriersCached.cache.clear()
    // NOTE: from UX perspective, it is better to slow down create/update action
    // rather than slow down view rendering next time UI will ask for a carrier
    await this.getCarriersCached() // ignore response, we roughly ~resync~ reload cache here
  }
}
