<form [formGroup]="form">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    CSV Exporter
  </h2>
  <div mat-dialog-content>
    <p>Which rows to export?</p>
    <mat-radio-group aria-label="Which rows to export?" formControlName="rowType">
      <mat-radio-button value="all">All</mat-radio-button>
      <mat-radio-button value="visible">Visible</mat-radio-button>
      <mat-radio-button *ngIf="selected" value="selected">Selected ({{ selected }})</mat-radio-button>
    </mat-radio-group>
    <ng-container *ngIf="canSelectColumns">
      <p>Which columns to export?</p>
      <mat-radio-group aria-label="Which columns to export?" class="mat-radio-group" formControlName="columnType">
        <mat-radio-button value="all">All</mat-radio-button>
        <mat-radio-button value="visible">Visible</mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <div *ngIf="overrideBatch" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox formControlName="overrideBatch">Override Batch #</mat-checkbox>
      <mat-form-field [ngStyle]="{ opacity: form.controls.overrideBatch.value ? 1 : 0 }">
        <mat-label>Batch #</mat-label>
        <input matInput #batchIdInput formControlName="batchId" autocomplete="off">
      </mat-form-field>
    </div>
    <mat-progress-bar *ngIf="inProgress$ | async as progress" mode="determinate" [value]="progress"></mat-progress-bar>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-raised-button type="submit" color="primary" (click)="save()">Save</button>
  </div>
</form>
