import { Injectable } from '@angular/core'
import { ProductCategory } from '@tradecafe/types/core'
import { compact, keyBy, map, pick, uniq } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { ProductCategoryApiService } from 'src/api/product/category'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'

const ALLOWED_FIELDS = ['name', 'description', 'attributes']
const CACHE_MAX_AGE = 3600 * 1000 // 1hr


/**
 * ProductCategories service
 *
 * @export
 * @returns
 */
@Injectable()
export class ProductCategoriesService {
  constructor(
    private AuthApi: AuthApiService,
    private ProductCategoryApi: ProductCategoryApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) { }


  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  productCategoryChanged$ = new Subject<void>()

  /**
   * Get all available product-types
   *
   * @param {any} query
   * @returns {Object} { total_rows:number, data: []}
   */
  async getProductCategories(query?) {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.ProductCategoryApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (productCategories) => {
        const { account } = this.AuthApi.currentUser
        const userIds = uniq(compact(map(productCategories.data, 'user_id')))

        const users = await this.Users.getUsersByIds(account, userIds)

        productCategories.data.forEach((productCategory: any) => {
          productCategory.user = users[productCategory.user_id]
          productCategory.created = productCategory.created || productCategory.createdAt
          productCategory.updated = productCategory.updatedAt || productCategory.updated || productCategory.created
        })
        return productCategories
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return this.Query.applyQuery(data, query)
  }

  /**
   * Get product-types by ids
   *
   * @param {Array} ids
   * @returns hash (key=id, value=productCategory)
   */
  async getProductCategoriesByIds(ids?: string) {
    const { data } = await this.getProductCategories()
    const index = keyBy(data, 'category_id')
    return ids ? pick(index, ids) : index
  }

  /**
   * Get possible values for given productCategory field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName) {
    const { data } = await this.getProductCategories()
    return uniq(compact(map(data, fieldName)))
  }


  /**
   * Create new productCategory
   *
   * @param {any} productCategory
   * @returns
   */
  async create(productCategory: Partial<ProductCategory>) {
    const payload = pick(productCategory, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.ProductCategoryApi.create(payload)
    this.invalidateCache()
    this.productCategoryChanged$.next()
    return data
  }

  /**
   * Update productCategory
   *
   * @param {any} productCategory
   * @returns
   */
  async update(productCategory: Partial<ProductCategory>) {
    const { category_id } = productCategory
    const payload = pick(productCategory, ALLOWED_FIELDS)
    const { user_id } = this.AuthApi.currentUser
    payload.user_id = user_id

    const { data } = await this.ProductCategoryApi.update(category_id, payload)
    this.invalidateCache()
    this.productCategoryChanged$.next()
    return data
  }

  /**
   * Update productCategory
   *
   * @param {any} productCategory
   * @returns
   */
  async remove({ category_id }) {
    const { data } = await this.ProductCategoryApi.delete(category_id)
    this.invalidateCache()
    this.productCategoryChanged$.next()
    return data
  }


  /**
   * Invalidate product-types in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }
}
