import { Injectable } from '@angular/core'
import { forEach, isFunction, merge } from 'lodash-es'

@Injectable()
export class ExcelService {

  private async lazyloadExcel() {
    if (typeof window['ExcelJS'] === 'undefined') {
      await addScriptLib('https://cdnjs.cloudflare.com/ajax/libs/exceljs/1.5.1/exceljs.min.js')
    }
  }

  private setStyles(ws, data) {
    const defaultCellStyles = data.defaultCellStyles || {}
    forEach(data, (line, index) => {
      const row = ws.getRow(index + 1)
      merge(row, line.rowStyles || {})
      row.eachCell((cell, colNumber) => {
        if (isFunction(defaultCellStyles)) {
          defaultCellStyles(cell, colNumber)
          merge(cell, line.cellStyles || {})
        } else {
          merge(cell, defaultCellStyles, line.cellStyles || {})
        }
      })
    })
  }


  async download(data, fileName: string) {
    await this.lazyloadExcel()

    const wb = new window['ExcelJS'].Workbook()
    const ws = wb.addWorksheet(fileName.substring(0, 30))
    ws.addRows(data)
    ws.columns.forEach(column => {
      column.width = 18
    })
    this.setStyles(ws, data)

    const xls64 = await wb.xlsx.writeBuffer({base64: true})

    // build anchor tag and attach file (works in chrome)
    const a = document.createElement('a')
    const blobData = new Blob([xls64], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const url = URL.createObjectURL(blobData)
    a.href = url
    a.download = `${fileName}.xlsx`
    document.body.appendChild(a)
    a.click()
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}

function addScriptLib(url) {
  return new Promise<void>((resolve, reject) =>
    document.head.appendChild(Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      src: url,
      onload: () => resolve(),
      onerror: err => reject(err),
    })))
}
