import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AccountObject, Cost, DealPartyE, DealPaymentTerms, DealProduct, DealViewBase, DealViewRawCosts, DealViewRawInvoices, Invoice, Product } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, findBrokerageInvoice, findBuyerInvoice, isDealSubmitted, lookupSegments } from '@tradecafe/types/utils'
import { cloneDeep, find, identity, isEqual, mapValues, merge, pick, pickBy, remove } from 'lodash-es'
import { asapScheduler, combineLatest, from, Observable, of } from 'rxjs'
import { distinctUntilChanged, filter, map, observeOn, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { selectAccountEntities } from 'src/app/store/accounts'
import { selectMeasureEntities } from 'src/app/store/measures'
import { selectProductEntities } from 'src/app/store/products'
import { selectFirstTraderOption, selectUserEntities } from 'src/app/store/users'
import { environment } from 'src/environments/environment'
import { FinanceService } from 'src/pages/admin/auction/matched-offer-overlay/finance.service'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { CostsFormGroup, DealDetailsFormGroup, DealDetailsFormValue, DealFormGroup, DealProductFormValue, DealProductsFormGroup, SegmentFormGroup, SegmentFormValue } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { buildDealCostForm, prepareDealCostPatch } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.service-factory'
import { getCostByProductForm } from 'src/shared/utils/get-cost-by-product-form'
import { replayForm } from 'src/shared/utils/replay-form'
import { dayjs } from '../dayjs'
import { DealFinancialsListenerService } from '../deal-financials-listener'
import { getPartyUsers, isBwiInventory } from './accounts.service'
import { CreditPoolService } from './credit-pool.service'
import { DealCalculatorService } from './deal-calculator.service'
import { getDefaultContacts, getDefaultGeo, getDesignatedContacts } from './users.service'
import { uuid, waitNotEmpty } from './utils'

const etd = (segmnt: DeepReadonly<SegmentFormValue>) => segmnt?.actualPickupDate || segmnt?.etdDate || NaN
const eta = (segmnt: DeepReadonly<SegmentFormValue>) => segmnt?.actualDeliveryDate || segmnt?.etaDate || NaN
const eq = (s1: DeepReadonly<SegmentFormValue>, s2: DeepReadonly<SegmentFormValue>) =>
  s1.segment?.segment_id && s2.segment?.segment_id && s1.segment.segment_id === s2.segment.segment_id ||
  isEqual(s1, s2)


/**
 * Deal Calculator service
 *
 *    * prepare input for /deals/calculate-margin
 *    * parsing output
 *    * update Deal
 *
 * @export
 * @returns
 */
@Injectable()
export class DealFormCalculatorService {
  constructor (
    private FxRates: FxRatesService,
    private Finance: FinanceService,
    private DealCalculator: DealCalculatorService,
    private store: Store,
    private AuthApi: AuthApiService,
    private CreditPoolSvc: CreditPoolService,
    private DealFinancialsListener: DealFinancialsListenerService,
  ) { }

  // state
  readonly running = {} // promise
  readonly scheduled = {}// input data for scheduled calculation

  /**
   * Calculate Deal
   */
  doCalculationsForm(
    dealForm: DealFormGroup,
    invoices: DeepReadonly<Invoice[]>,
  ) {
    const detailsForm = dealForm.controls.details

    return combineLatest([
      this.store.pipe(select(selectProductEntities), waitNotEmpty()),
      this.store.pipe(select(selectMeasureEntities), waitNotEmpty()),
    ]).pipe(
      take(1),
      // use fresh fx_rates deal is not yet confirmed
      switchMap(x =>
        this.updateFxRatesForm(dealForm.controls.details)
        .pipe(map(() => x))),
      // make sure deal.products are in sync with primary costs
      tap(([products]) => {
        this.syncPrimaryCostsForm(detailsForm.getRawValue(), dealForm.controls.products, dealForm.controls.costs, { products })
      }),
      switchMap(([, measures]) => {
        const details = detailsForm.getRawValue()
        const deal: DeepPartial<DealViewBase> = {
          deal_id: details.deal.deal_id, // optional. we've been using it for logging
          deal_type: details.deal.deal_type, // we use deal type to decide if we need to calculate brokerage financials
          brokerage: {
            amount: details.brokerageEstAmount,
            actual_amount: details.brokerageActAmount,
            currency: details.brokerageCurrency,
            customer: details.brokerageCustomer,
            paymentTerms: details.brokeragePaymentTerms,
            sendBuyerConfirmation: details.brokerageSendBuyerConfirmation,
            sendSupplierConfirmation: details.brokerageSendSupplierConfirmation,
            collectionDate: details.brokerageCollectionDate,
            termDate: details.brokerageTermDate,
            traderId: details.brokerageTraderId,
            contactUserIds: details.brokerageContactUserIds,
          },
          status: details.deal.status, // we use deal status to decide if we need to consider prepayments when calculating interest
          supplier_id: parseFloat(details.supplierId),
          buyer_id: parseFloat(details.buyerId),
          collection_date: details.collectionDate,
          supplier_anticipated_liability: details.antLiabilityDate,
          attributes: {
            fx_rates: details.fxRates,
            fx_rates_timestamp: details.fxRatesTimestamp,
            supplier_payment_terms: details.supplierPaymentTerms,
            buyer_payment_terms: details.buyerPaymentTerms,
          },
        }

        // aggregated offers (supplier) and bids (buyer)
        const bids_and_offers: DeepPartial<DealProduct>[] = dealForm.controls.products.getRawValue().map((product, i) => ({
          product_id: product.productId,
          supplier: {
            price: product.supplierEstPrice,
            currency_code: details.supplierCurrencyCode,
            weight: product.supplierEstWeight,
            measure_id: product.supplierMeasureId,
            measure: pick(measures[product.supplierMeasureId], ['measure_id', 'conversions']),
            actual_price: product.supplierActualPrice,
            actual_weight: product.supplierActualWeight || 0,
          },
          buyer: {
            price: product.buyerEstPrice,
            currency_code: details.buyerCurrencyCode,
            weight: product.buyerEstWeight,
            measure_id: product.buyerMeasureId,
            measure: pick(measures[product.buyerMeasureId], ['measure_id', 'conversions']),
            actual_price: product.buyerActualPrice,
            actual_weight: product.buyerActualWeight || 0,
          },
          cost_id: getCostByProductForm(dealForm, dealForm.controls.products.controls[i])?.cost_id
        }))
        // NOTE: in order to match input & output costs we introduce temporary ID.
        //       it should not be stored
        dealForm.controls.costs.controls.forEach(cf => {
          if (!cf.value.cost.ID) {
            cf.patchValue({ cost: { ...cf.value.cost, ID: uuid() } })
          }
        })

        // array with all costs associated with the deal (we rely on costs order)
        const costs = dealForm.controls.costs.value.map(cost => ({
          // cost_id might be undefined, for cost identification we rely on costs order
          // output costs order MUST be the same as input costs order
          ID: cost.cost.ID,
          cost_id: cost.cost.cost_id,
          type: cost.cost.type,
          status: cost.cost.status,
          provider: cost.cost.provider,
          service: cost.cost.service,
          product_id: cost.cost.product_id,
          amount: cost.cost.amount,
          attributes: cost.cost.attributes,
        }))

        return from(this.DealCalculator.scheduleCalculations({ deal, bids_and_offers, costs, invoices }))
      }),
      map(({ data: res }) => {
        // compare deals to determine if deal financials are changed
        this.DealFinancialsListener.tryToRecordChange(detailsForm.controls.deal.value, res.deal)

        detailsForm.patchValue(pickBy({
          estFinanceTerm: res.deal.attributes.est_finance_term,
          financeTerm: res.deal.attributes.finance_term,
          fxRatesAskRange: res.deal.attributes.fx_rates_ask_range,
          fxRatesBidRange: res.deal.attributes.fx_rates_bid_range,
          actualTotals: res.deal.attributes.actual,
          estimatedTotals: res.deal.attributes.estimated,
        }, (x) => x || x === 0))

        // we expect no new or missing offers/bids in response. we rely on array elements order
        res.bids_and_offers.forEach(({ attributes }, i) => {
          if (attributes) {
            dealForm.controls.products.controls[i].patchValue({
              margin: attributes.margin,
              marginP: attributes.margin_p,
            })
          }
        })

        res.costs.forEach(cost => {
          const costForm = dealForm.controls.costs.controls.find(c => c.value.cost.ID === cost.ID)
          costForm?.patchValue(prepareDealCostPatch({ ...costForm.value.cost, ...cost }))
        })
      }),
    )
  }

  /**
   * Use fresh fx_rates deal is not yet confirmed
   *
   * @private
   * @param {any} deal
   */
  private updateFxRatesForm(deal: DealDetailsFormGroup) {
    if (deal.value.fxRates?.rates && isDealSubmitted(deal.value.deal)) return of(false)
    return from(this.FxRates.getFxRates()).pipe(
      tap(fxRates => {
        deal.patchValue({ fxRates, fxRatesTimestamp: dayjs().unix() })
      }),
      map(() => true))
  }

  /**
   * Create new, update existing, remove irrelevant primary costs
   *
   * @private
   * @param {any} deal
   */
  private syncPrimaryCostsForm(
    deal: DealDetailsFormValue,
    productsForm: DealProductsFormGroup,
    costsForm: CostsFormGroup,
    { products }: { products: DeepReadonly<Dictionary<Product>> },
  ) {
    // delete irrelevant costs
    remove(costsForm.controls, (costForm) =>
      costForm.value.cost.type === 'primary' &&
      !find(productsForm.getRawValue(), { productId: costForm.value.cost.product_id }))

    // create or update other primary costs
    // tslint:disable-next-line: cyclomatic-complexity
    productsForm.controls.forEach((productForm, product_index) => {
      const { productId, bid, offer,
        supplierEstPrice, supplierEstWeight, buyerEstPrice, buyerEstWeight,
        supplierActualWeight, supplierActualPrice, buyerActualWeight, buyerActualPrice,
      } = productForm.getRawValue()
      const offer_id = offer?.offer_id
      const bid_id = bid?.bid_id
      // ignore invalid product entries
      if (!productId) return
      // find existing primary cost
      let costForm = find(costsForm.controls, { value: { cost: { type: 'primary', attributes: { product_index } }}})
      // prepare form patch
      let costPatch: Partial<Cost> = {
        service: products[productId].name,
        product_id: productId,
        provider: deal.supplierId,
        amount: {
          total: supplierEstPrice * supplierEstWeight,
          currency: deal.supplierCurrencyCode,
        },
        attributes: {
          buy_weight: supplierEstWeight,
          buy_price: supplierEstPrice,
          actual_buy_weight: supplierActualWeight,
          actual_buy_price: supplierActualPrice,
          sell_weight: buyerEstWeight,
          sell_price: buyerEstPrice,
          actual_sell_weight: buyerActualWeight,
          actual_sell_price: buyerActualPrice,
          actual_date: dayjs().unix(), // unused?
        },
      }

      const newCost = !costForm
      if (newCost) { // create new if there is no primary cost
        costForm = buildDealCostForm()
        costPatch = merge(costPatch, {
          type: 'primary',
          status: 'pending',
          attributes: { product_index, bid_id, offer_id }, // this field is supposed to be constant
        })
      } else {
        costPatch = merge(cloneDeep(costForm.value.cost), costPatch)
      }

      // update primary cost form
      costForm.patchValue(prepareDealCostPatch(costPatch))
      if (newCost) costsForm.push(costForm)
    })
  }

  /**
   * Calculate Term Date
   *
   * @param {any} dealView
   * @param {string} from_date - from_date string as it comes (from paymentTerms.from_date)
   * @returns epoch date or NaN if input date is undefined
   * @throws {Error} when from_date is unknown
   */
  private calculateTermDateForm(
    dealView: DeepReadonly<Pick<DealDetailsFormValue, 'shipmentBolDate' | 'buyerId' | 'shipmentDatesTo' | 'deliveryDatesTo' | 'date'>>,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    from_date: string,
  ) {
    return this.calculateActTermDateForm(dealView, segments, invoices, from_date) || this.calculateEstTermDateForm(dealView, from_date)
  }

  /**
   * Estimated Term Date (from traders)
   *
   * @private
   * @param {any} dealView
   * @param {string} from_date - from_date string as it comes (from paymentTerms.from_date)
   * @returns epoch date or NaN if input date is undefined
   * @throws {Error} when from_date is unknown
   */
  private calculateEstTermDateForm(
    dealView: DeepReadonly<Pick<DealDetailsFormValue, 'shipmentDatesTo' | 'deliveryDatesTo' | 'date'>>,
    from_date: string,
  ) {
    const { shipmentDatesTo, deliveryDatesTo, date } = dealView
    return this.Finance.getTermDate({
      created: date,
      shipmentDate: shipmentDatesTo,
      deliveryDate: deliveryDatesTo,
      paymentTerms: { from_date }, // TODO: refactor to pass in whole paymentTerms object
    })
  }

  /**
   * Actual Term Date (from logistics)
   *
   * @private
   * @param {any} dealView
   * @param {string} from_date - paymentTerms.from_date
   * @returns epoch date or NaN if input date is undefined
   * @throws {Error} when from_date is unknown
   */
  // tslint:disable-next-line: cyclomatic-complexity
  private calculateActTermDateForm(
    dealView: DeepReadonly<Pick<DealDetailsFormValue, 'shipmentBolDate' | 'date' | 'buyerId' | 'dealType'>>,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    from_date: string,
  ) {
    const {earliest, latest, latestVessel} = lookupSegments(segments, etd, eta)

    if (from_date === 'bill_of_landing_date') {
      // Bill of Lading Date == Bill of Lading Date from Shipping Log (View)
      return dealView.shipmentBolDate
    } else if (from_date === 'days_from_pickup_date') {
      // Days from Pickup Date == actual Pickup Date from earliest segment
      return etd(earliest)
    } else if (from_date === 'ship_date') {
      // Days from Ship Date == actual Ship Date from earliest segment
      return etd(earliest)
    } else if (from_date === 'deal_date_date') {
      // Deal Date == the date of deal creation (whichever date it is on the deal)
      return dealView.date
    } else if (from_date === 'delivery_date') {
      // Delivery Date == actual Delivery date from latest segment
      return eta(latest)
    } else if (from_date === 'invoice_date') {
      // Invoice Date == the day Trade Cafe sends out invoice to client
      const invoice = environment.enableBrokerageDeals && dealView.dealType === 'brokerage'
        ? findBrokerageInvoice(invoices)
        : findBuyerInvoice(invoices, dealView.buyerId)
      return invoice?.issued || NaN
    } else if (from_date === 'vessel_arrival_date') {
      // Vessel Arrival Date == Vessel Arrival Date from Vessel Segment in Shipping Log.
      return eta(latestVessel)
    }

    throw new Error(`Unknown payment_term.from_date value "${from_date}"`)
  }

  /**
   * Check if given deal field can update given payment term date
   *
   * @param {any} dealView
   * @param {string} dealField deal field name
   * @param {string} from_date paymentTerms.from_date
   * @returns
   */
  private isFinanceDateForm(
    dealView: DeepReadonly<DealDetailsFormValue>,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    dealField: keyof SegmentFormValue | keyof DealDetailsFormValue,
    from_date: string,
  ) {
    if (!from_date) return false

    if (dealField === 'date') {
      return from_date === 'deal_date_date'
    }

    if (dealField === 'shipmentDatesTo') {
      return !this.calculateActTermDateForm(dealView, segments, invoices, from_date) &&
        ['bill_of_landing_date', 'days_from_pickup_date', 'ship_date', 'invoice_date'].includes(from_date)
    }

    if (dealField === 'deliveryDatesTo') {
      return !this.calculateActTermDateForm(dealView, segments, invoices, from_date) &&
        ['delivery_date', 'vessel_arrival_date'].includes(from_date)
    }

    throw new Error(`Unknown deal field name "${dealField}"`)
  }

  /**
   * Check if given segment field name can update given payment term date
   *
   * @param {any} dealView
   * @param {any} segment
   * @param {string} segmentField segment field name
   * @param {string} from_date paymentTerms.from_date
   * @returns
   */
  // tslint:disable-next-line: cyclomatic-complexity
  private isFinanceDateFromSegmentForm(
    dealView: DeepReadonly<Pick<DealDetailsFormValue, 'buyerId' | 'dealType'>>,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    segment: DeepReadonly<SegmentFormValue>,
    segmentField: keyof SegmentFormValue | keyof DealDetailsFormValue,
    from_date: string,
  ) {
    if (!from_date) return false

    const {earliest, latest, latestVessel} = lookupSegments(segments, etd, eta)
    if (from_date === 'bill_of_landing_date') {
      return segmentField === 'shipmentBolDate'

    } else if (from_date === 'days_from_pickup_date' || from_date === 'ship_date') {
      if (segmentField === 'actualPickupDate') return eq(segment, earliest)
      if (segmentField === 'etdDate') return eq(segment, earliest) && !segment.actualPickupDate
      return false
    } else if (from_date === 'deal_date_date') {
      return segmentField === 'date' // not possible?

    } else if (from_date === 'delivery_date') {
      if (segmentField === 'actualDeliveryDate') return eq(segment, latest)
      if (segmentField === 'etaDate') return eq(segment, latest) && !segment.actualDeliveryDate
      return false

    } else if (from_date === 'invoice_date') {
      const invoice = environment.enableBrokerageDeals && dealView.dealType === 'brokerage'
        ? findBrokerageInvoice(invoices)
        : findBuyerInvoice(invoices, dealView.buyerId)
      return invoice && segmentField === 'shipmentBolDate'

    } else if (from_date === 'vessel_arrival_date') {
      if (segmentField === 'actualDeliveryDate') return eq(segment, latestVessel)
      if (segmentField === 'etaDate') return eq(segment, latestVessel) && !segment.actualDeliveryDate
      return false
    }

    throw new Error(`Unknown payment_term.from_date value "${from_date}"`)
  }

  /**
   * Calculate deal dates
   *
   * Ant Liab. Date (Supplier Side) = last date of the Supplier date range + Supplier payment Terms
   * Ant Liab. Date (Buyer Side) = Ant Lib Date (Supplier Side)
   * Ant Term Date = last date of Buyer date range
   * Ant Collection Date = Ant Term Date + Buyer Payment Terms
   */
  private calculateTermDateFromDealForm(
    dealView: DealDetailsFormGroup,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    supplier: DeepReadonly<AccountObject>,
    buyer: DeepReadonly<AccountObject>,
    brokerageCustomer: DeepReadonly<AccountObject>,
    changedField: keyof SegmentFormValue | keyof DealDetailsFormValue,
  ) {
    const patch = this.calculateTermDateFromDealFormImmutable(
      dealView.getRawValue(), segments, invoices, supplier, buyer, brokerageCustomer, changedField)
    if (patch) dealView.patchValue(patch)
    return !!patch
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private calculateTermDateFromDealFormImmutable(
    deal: DeepReadonly<DealDetailsFormValue>,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    supplier: DeepReadonly<AccountObject>,
    buyer: DeepReadonly<AccountObject>,
    brokerageCustomer: DeepReadonly<AccountObject>,
    changedField: keyof SegmentFormValue | keyof DealDetailsFormValue,
  ) {
    const epochDay = 86400
    let days = 0
    let from_date = ''
    let changed = false

    const patch: Partial<Pick<DealDetailsFormValue, 'supplierTermDate'|'antLiabilityDate'|'buyerTermDate'|'collectionDate'|'brokerageCollectionDate'|'brokerageTermDate'>> = { }

    if (deal.supplierPaymentTerms) {
      ({ days, from_date } = deal.supplierPaymentTerms)
      if (changedField === 'supplierPaymentTerms' ||
          changedField !== 'buyerPaymentTerms' && changedField !== 'brokerageTermDate' &&
          this.isFinanceDateForm(deal, segments, invoices, changedField, from_date)
      ) {
        const avgDelay = supplier?.attributes?.credit_info?.avg_days_to_due_date || 0
        const termDate = this.calculateTermDateForm(deal, segments, invoices, from_date) || undefined
        patch.supplierTermDate = termDate
        patch.antLiabilityDate = termDate + (days + avgDelay) * epochDay || undefined
        changed = true
      }
    }

    if (deal.buyerPaymentTerms) {
      ({ days, from_date } = deal.buyerPaymentTerms)
      if (changedField === 'buyerPaymentTerms' ||
          changedField !== 'supplierPaymentTerms' && changedField !== 'brokerageTermDate' &&
          this.isFinanceDateForm(deal, segments, invoices, changedField, from_date)
      ) {
        const avgDelay = buyer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const termDate = this.calculateTermDateForm(deal, segments, invoices, from_date) || undefined
        patch.buyerTermDate = termDate
        patch.collectionDate = termDate + (days + avgDelay) * epochDay || undefined
        changed = true
      }
    }

    if (deal.brokeragePaymentTerms) {
      ({ days, from_date } = deal.brokeragePaymentTerms)
      if (changedField === 'brokeragePaymentTerms' ||
          changedField !== 'supplierPaymentTerms' && changedField !== 'buyerPaymentTerms' &&
          this.isFinanceDateForm(deal, segments, invoices, changedField, from_date)
      ) {
        const avgDelay = brokerageCustomer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const termDate = this.calculateTermDateForm(deal, segments, invoices, from_date) || undefined
        patch.brokerageTermDate = termDate
        patch.brokerageCollectionDate = termDate + (days + avgDelay) * epochDay || undefined
        changed = true
      }
    }
    return changed ? patch : false
  }

  private calculateTermDateFromSegmentForm(
    dealView: DealDetailsFormGroup,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    segment: SegmentFormGroup,
    supplier: DeepReadonly<AccountObject>,
    buyer: DeepReadonly<AccountObject>,
    changedField: keyof SegmentFormValue | keyof DealDetailsFormValue,
  ) {
    const patch = this.calculateTermDateFromSegmentFormImmutable(
      dealView.getRawValue(), segments, invoices, segment.getRawValue(), supplier, buyer, changedField)
    if (patch) dealView.patchValue(patch)
    return !!patch
  }

  // tslint:disable-next-line: cyclomatic-complexity
  calculateTermDateFromSegmentFormImmutable(
    deal: DeepReadonly<Pick<DealDetailsFormValue, 'buyerId' | 'buyerPaymentTerms' | 'date' | 'deliveryDatesTo' | 'shipmentBolDate' | 'shipmentDatesTo' | 'supplierPaymentTerms'>>,
    segments: DeepReadonly<SegmentFormValue[]>,
    invoices: DeepReadonly<Invoice[]>,
    segment: SegmentFormValue,
    supplier: DeepReadonly<AccountObject>,
    buyer: DeepReadonly<AccountObject>,
    changedField: keyof SegmentFormValue | keyof DealDetailsFormValue,
  ) {
    const epochDay = 86400
    let days = 0
    let from_date = ''
    let changed = false

    const patch: Partial<Pick<DealDetailsFormValue, 'antLiabilityDate' | 'buyerTermDate' | 'collectionDate' | 'date'| 'deliveryDatesTo'| 'shipmentDatesTo' | 'supplierTermDate'>> = { }

    if (deal.supplierPaymentTerms) {
      ({ days, from_date } = deal.supplierPaymentTerms)
      if (this.isFinanceDateFromSegmentForm(deal, segments, invoices, segment, changedField, from_date)) {
        const avgDelay = supplier?.attributes?.credit_info?.avg_days_to_due_date || 0
        const termDate = this.calculateTermDateForm(deal, segments, invoices, from_date) || undefined
        patch.supplierTermDate = termDate
        patch.antLiabilityDate = termDate + days * epochDay + avgDelay * epochDay || undefined
        changed = true
      }
    }

    if (deal.buyerPaymentTerms) {
      ({ days, from_date } = deal.buyerPaymentTerms)
      if (this.isFinanceDateFromSegmentForm(deal, segments, invoices, segment, changedField, from_date)) {
        const avgDelay = buyer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const termDate = this.calculateTermDateForm(deal, segments, invoices, from_date) || undefined
        patch.buyerTermDate = termDate
        patch.collectionDate = termDate + days * epochDay + avgDelay * epochDay || undefined
        changed = true
      }
    }
    return changed ? patch : false
  }

  syncTermDate(
    dv: DeepReadonly<DealViewRawCosts & DealViewRawInvoices>,
    dealForm: DealFormGroup,
  ) {
    const { details: detailsForm } = dealForm.controls
    const { supplierId, buyerId, buyerPaymentTerms, buyerTermDate, brokerageCustomer: brokerageCustomerId } = detailsForm.getRawValue()
    const segments = dealForm.getRawValue().segments
    return this.store.pipe(select(selectAccountEntities), waitNotEmpty(), take(1), switchMap(accounts => {
      const supplier = accounts[supplierId]
      const buyer = accounts[buyerId]
      const brokerageCustomer = accounts[brokerageCustomerId]
      this.calculateTermDateFromDealForm(detailsForm, segments, dv.invoices, supplier, buyer, brokerageCustomer, 'date')
      // update collection date on buyer term date change
      if (buyerTermDate && buyerPaymentTerms) {
        const epochDay = 86400
        const avgDelay = buyer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const days = buyerPaymentTerms.days
        detailsForm.patchValue({
          collectionDate: buyerTermDate + (days + avgDelay) * epochDay,
        })
      }
      // Calculate Finance Term; recalculate deal and costs if needed
      return this.doCalculationsForm(dealForm, dv.invoices)
    }))

  }

  keepTermDateInSync(
    dealViewRaw$: Observable<DeepReadonly<DealViewRawCosts & DealViewRawInvoices>>,
    dealForm: DealFormGroup,
  ) {
    return this.store.pipe(select(selectAccountEntities), waitNotEmpty(), take(1), switchMap(accounts => {
      const calculateTerms = (
        changedField: keyof SegmentFormValue | keyof DealDetailsFormValue,
        dv: DeepReadonly<DealViewRawCosts & DealViewRawInvoices>,
      ) => {
        const { details, segments } = dealForm.controls
        const supplier = accounts[details.getRawValue().supplierId]
        const buyer = accounts[details.getRawValue().buyerId]
        const brokerageCustomer = accounts[details.getRawValue().brokerageCustomer]
        const changed = this.calculateTermDateFromDealForm(details, segments.getRawValue(), dv.invoices, supplier, buyer, brokerageCustomer, changedField)
        return changed ? this.doCalculationsForm(dealForm, dv.invoices) : of(false)
      }
      // update collection date on buyer term date change
      const calculateCollectionDate = (buyerTermDate: number) => {
        const { buyerId, buyerPaymentTerms } = dealForm.controls.details.getRawValue()
        if (!buyerTermDate && !buyerPaymentTerms) return
        const buyer = accounts[buyerId]
        const epochDay = 86400
        const avgDelay = buyer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const days = buyerPaymentTerms.days
        dealForm.controls.details.patchValue({
          collectionDate: buyerTermDate + (days + avgDelay) * epochDay,
        })
      }
      const calculateBrokerageCollectionDate = (brokerageTermDate: number) => {
        const { brokerageCustomer: brokerageCustomerId, brokeragePaymentTerms } = dealForm.controls.details.getRawValue()
        if (!brokerageTermDate && !brokeragePaymentTerms) return
        const brokerageCustomer = accounts[brokerageCustomerId]
        const epochDay = 86400
        const avgDelay = brokerageCustomer?.attributes?.credit_info?.avg_days_to_due_date || 0
        const days = brokeragePaymentTerms.days
        dealForm.controls.details.patchValue({
          collectionDate: brokerageTermDate + (days + avgDelay) * epochDay,
        })
      }
      // Calculate Finance Term; recalculate deal and costs if needed
      const calculateDeal = (dv: DeepReadonly<DealViewRawCosts & DealViewRawInvoices>) =>
        this.doCalculationsForm(dealForm, dv.invoices)

      return combineLatest([
        replayForm(dealForm.controls.details.controls.date).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateTerms('date', dv))),
        replayForm(dealForm.controls.details.controls.shipmentDatesTo).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateTerms('shipmentDatesTo', dv))),
        replayForm(dealForm.controls.details.controls.deliveryDatesTo).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateTerms('deliveryDatesTo', dv))),
        replayForm(dealForm.controls.details.controls.supplierPaymentTerms).pipe(
          distinctUntilChanged(isEqual), skip(0), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateTerms('supplierPaymentTerms', dv))),
        replayForm(dealForm.controls.details.controls.brokeragePaymentTerms).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateTerms('brokeragePaymentTerms', dv))),
        replayForm(dealForm.controls.details.controls.buyerPaymentTerms).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateTerms('buyerPaymentTerms', dv))),
        replayForm(dealForm.controls.details.controls.buyerTermDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          tap(buyerTermDate => calculateCollectionDate(buyerTermDate))),
        replayForm(dealForm.controls.details.controls.brokerageTermDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          tap(brokerageTermDate => calculateBrokerageCollectionDate(brokerageTermDate))),
        replayForm(dealForm.controls.details.controls.antLiabilityDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateDeal(dv))),
        replayForm(dealForm.controls.details.controls.collectionDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateDeal(dv))),
        replayForm(dealForm.controls.details.controls.brokerageCollectionDate).pipe(
          distinctUntilChanged(isEqual), skip(1), observeOn(asapScheduler),
          withLatestFrom(dealViewRaw$), switchMap(([, dv]) => calculateDeal(dv))),
      ])
    }))
  }

  /**
   * Keep termdate in sync with segment form
   *
   * @param dealForm
   * @param invoices$
   * @param segmentForm
   * @param changedField
   */
  onSegmentDateChanged(
    dealViewRaw$: Observable<DeepReadonly<DealViewRawCosts & DealViewRawInvoices>>,
    dealForm: DealFormGroup,
    segmentForm: SegmentFormGroup,
    changedField: 'actualPickupDate' | 'etdDate' | 'actualDeliveryDate' | 'etaDate' | 'shipmentBolDate',
  ) {
    return combineLatest([
      this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
      dealViewRaw$,
    ]).pipe(take(1), switchMap(([accounts, dv]) => {
      const { details, segments } = dealForm.controls
      const actualPickupDate = segmentForm.controls.actualPickupDate.value
      if (changedField === 'actualPickupDate' && actualPickupDate && segmentForm.controls.type.value === 'sea') {
        details.controls.shipmentBolDate.setValue(actualPickupDate)
        details.controls.shipmentBolDate.markAsDirty()
      }
      const supplier = accounts[details.getRawValue().supplierId]
      const buyer = accounts[details.getRawValue().buyerId]
      const changed = this.calculateTermDateFromSegmentForm(details, segments.getRawValue(), dv.invoices, segmentForm, supplier, buyer, changedField)
      if (changed) {
        return this.doCalculationsForm(dealForm, dv.invoices)
      }
      return of(false)
    }))
  }

  refillPaymentTerms(partyId: string, party: DealPartyE.buyer | DealPartyE.supplier, traderId: string) {
    return from(this.CreditPoolSvc.getPaymentTerms(partyId, party, traderId)).pipe(map(paymentTerms => {
      if (party === 'supplier') {
        return { supplierPaymentTerms: paymentTerms }
      } else /* if (party === 'buyer') */ {
        return { buyerPaymentTerms: paymentTerms }
      }
    }))
  }

  prefillPartyFields(
    newDeal: boolean,
    party: undefined | DealPartyE.buyer | DealPartyE.supplier,
    partyId: string,
  ): Observable<{
    details: Partial<DealDetailsFormValue>,
    product: Partial<DealProductFormValue>,
    partyAcc: DeepReadonly<AccountObject>,
  }> {
    return combineLatest([
      this.store.pipe(select(selectFirstTraderOption), filter(identity)),
      this.store.pipe(select(selectAccountEntities), waitNotEmpty()),
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
      // tslint:disable-next-line: cyclomatic-complexity
    ]).pipe(take(1), map(([firstTraderId, accounts, users]) => {
      const partyAcc = accounts[partyId]
      const partyUsers = getPartyUsers(users, partyAcc.account)

      const partyTrader = users[partyAcc.manager]?.role === 'trader'
        ? partyAcc.manager
        : partyAcc.managers?.find(managerId => users[managerId]?.role === 'trader')

      const { country, location } = getDefaultGeo(partyAcc)
      const partyContacts = isBwiInventory(partyAcc)
        ? [getDefaultContacts(partyUsers, users).bwiManager.user_id]
        : getDesignatedContacts(partyUsers).map(u => u.user_id)

      const details: Partial<DealDetailsFormValue> = {}
      if (party === 'supplier') {
        const iAmTraderManager = this.AuthApi.currentUser.role === 'trader' &&
          (partyAcc.manager === this.AuthApi.currentUser.user_id ||
          partyAcc.managers?.find(managerId => managerId === this.AuthApi.currentUser.user_id)) &&
          this.AuthApi.currentUser.user_id

        details.supplierId = partyId
        details.supplierTraderId = iAmTraderManager || partyTrader || firstTraderId
        details.supplierUserIds = partyContacts
        details.originCountryCode = country
        details.originLocationId = location
        details.supplierConfirmedAt = 0
        details.supplierConfirmedBy = ''
      } else if (party === 'buyer') {
        details.buyerId = partyId
        const iAmTrader = this.AuthApi.currentUser.role === 'trader' && this.AuthApi.currentUser.user_id
        details.buyerTraderId = iAmTrader || partyTrader || firstTraderId
        details.buyerUserIds = partyContacts
        details.docsCountryCode = country
        details.destLocationId = location
        details.buyerConfirmedAt = 0
        details.buyerConfirmedBy = ''
        details.proformaNeeded = country === 'CO'
        // NOTE: don't set default LC if he is not role=logistics and not
        const coordinators = mapValues(pickBy(users, { role: 'logistics' }), () => true)
        if (coordinators[partyAcc.coordinator]) {
          details.logisticsUserId = partyAcc.coordinator
        }
      }

      const product = this.prefillProductFields(newDeal, party, partyAcc)
      return { details, product, partyAcc }
    }))
  }

  /**
   * We prefill/autopopulate some product fields using information from supplier/buyer account
   *
   * @private
   * @param {*} initPhase - just opened the deal page?
   * @param {*} party - mandatory string 'supplier' or 'buyer'
   */
  prefillProductFields(
    newDeal: boolean,
    party: DealPartyE.buyer | DealPartyE.supplier,
    partyAcc: DeepReadonly<AccountObject>,
  ) {
    // NOTE: most product product fields will not be prefillled on the "New Deal" form
    const { measure_id, incoterm, tax_location } = partyAcc?.attributes.pricing || {}
    const productPatch: Partial<DealProductFormValue> = {}
    if (newDeal) productPatch[`${party}MeasureId`] = measure_id || null
    productPatch[`${party}IncotermId`] = incoterm || null
    productPatch[`${party}IncotermLocationId`] = tax_location || null
    if(party === 'buyer') {
      productPatch['invoiceAddress'] = partyAcc.addresses.find((i) => i.primary === 1)
    }
    return productPatch
  }
}

export function getAntDueDate(paymentTerms: DeepReadonly<DealPaymentTerms>, termDate: number) {
  return termDate ? termDate + 24 * 60 * 60 * (paymentTerms?.days || 0) : undefined
}
