import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { DepartmentApiService } from 'src/api/department'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { loadDepartments, loadDepartmentsFailure, loadDepartmentsSuccess } from './department.actions'


@Injectable()
export class DepartmentEffects {
  constructor(
    private actions$: Actions,
    private DepartmentApi: DepartmentApiService,
    private toaster: ToasterService,
  ) {}

  loadDepartments$ = createEffect(() => this.actions$.pipe(
    ofType(loadDepartments),
    switchMap(action =>
      from(this.DepartmentApi.list()).pipe(
        map(r => loadDepartmentsSuccess({
          departments: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch departments', error)
          this.toaster.error('Unable to fetch departments', error)
          return of(loadDepartmentsFailure({ error }))
        })))))
}
