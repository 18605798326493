<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;{{title}}
</h2>
<form [formGroup]="vcForm" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
    <tc-select-search placeholder="Deal #"
        [ctrl]="vcForm.controls.deal_id"
        [readonly]="(isReadonly$ | async) || !isNew || !!predefinedDealId"
        [items]="dealIds$ | async"
        ></tc-select-search>
    <tc-select-search placeholder="Company"
        [ctrl]="vcForm.controls.account"
        [readonly]="(isReadonly$ | async) || !isNew || !vcForm.controls.deal_id.value"
        [items]="vendors$ | async" bindValue="account" bindLabel="name" bindHint="type"
        ></tc-select-search>
    <tc-select-search placeholder="Cost"
        [ctrl]="vcForm.controls.cost_id"
        (change)="onCostChange($event)"
        [readonly]="(isReadonly$ | async) || !vcForm.controls.deal_id.value"
        [items]="costs$ | async" bindValue="cost_id" bindLabel="service" bindHint="type"
        ></tc-select-search>
    <mat-form-field>
      <mat-label>Amount ({{vcForm.controls.currency.value}})</mat-label>
      <input matInput type="number" step="0.01" min="0" [readonly]="(isReadonly$ | async)" [formControl]="vcForm.controls.amount">
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Vendor Number</mat-label>
      <textarea matInput [formControl]="vcForm.controls.vendor_number" [readonly]="(isReadonly$ | async)"></textarea>
    </mat-form-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Description</mat-label>
      <textarea matInput [formControl]="vcForm.controls.description" [readonly]="(isReadonly$ | async)"></textarea>
    </mat-form-field>
    <div gdColumn="span 2" *ngIf="isNew && vcForm.controls.description.value">
      <mat-checkbox color="primary" [formControl]="vcForm.controls.createNote" [disabled]="(isReadonly$ | async)">Create internal note</mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">{{(isReadonly$ | async) ? 'Close' : 'Cancel'}}</button>
    <button mat-raised-button type="submit" color="primary" *ngIf="!(isReadonly$ | async)" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
