export const Roles = {
  administrator: {
    id: 'administrator',
    defaultUrl: '/trading/deals',
  },
  "junior-administrator": {
    id: 'junior-administrator',
    defaultUrl: '/trading/deals',
  },
  manager: {
    id: 'manager',
    defaultUrl: '/trading/deals',
  },
  superuser: {
    id: 'superuser',
    defaultUrl: '/trading/deals',
  },
  accounting: {
    id: 'accounting',
    defaultUrl: '/financial/payables',
  },
  trader: {
    id: 'trader',
    defaultUrl: '/trading/deals',
  },
  logistics: {
    id: 'logistics',
    defaultUrl: '/logistics/shipping-log',
  },
}
