import { ChangeDetectorRef, Component, Input, OnChanges, ViewChildren } from '@angular/core'
import { MatTooltip } from '@angular/material/tooltip'
import { DealRow, DealStatus } from '@tradecafe/types/core'
import { DealStatusEx, DeepReadonly } from '@tradecafe/types/utils'

@Component({
  selector: 'tc-deal-status',
  templateUrl: './deal-status.component.html',
  styleUrls: ['./deal-status.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealStatusComponent implements OnChanges {
  readonly DealStatus = DealStatus

  @Input()
  deal: DeepReadonly<DealRow>
  @Input()
  status: string
  @Input()
  types: string[]
  @Input()
  title: string

  @Input()
  downgraded: false

  ngOnChanges() {
    if (this.deal) {
      this.status = this.deal.status
      this.types = DealStatusEx.filter(x => x.check(this.deal)).map(x => x.id)
    } else {
      this.status = this.status || ''
      this.types = this.types || []
    }
  }

  @ViewChildren(MatTooltip)
  set tooltips(tooltips: MatTooltip[]) {
    if (!this.downgraded) return
    tooltips.forEach(tooltip => {
      if (tooltip['_patched']) return
      tooltip['_patched'] = true
      const _originalShow = tooltip.show
      tooltip.show = (delay?: number) => {
        const r = _originalShow.call(tooltip, delay)
        const cd = tooltip._tooltipInstance['_changeDetectorRef'] as ChangeDetectorRef
        setTimeout(() => cd.detectChanges(), delay || 0)
        return r
      }
      const _originalHide = tooltip.hide
      tooltip.hide = (delay?: number) => {
        const r = _originalHide.call(tooltip, delay)
        const cd = tooltip._tooltipInstance['_changeDetectorRef'] as ChangeDetectorRef
        setTimeout(() => cd.detectChanges(), delay || 0)
        return r
      }
    })
  }
}
