import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ModalModule } from 'src/shared/modal'
import { AddressListModule } from '../address-list/address-list.module'
import { ConsigneeFormService } from '../consignee-form/consignee-form.service'
import { SelectSearchModule } from '../select-search/select-search.module'
import { AddressPickerComponent } from './address-picker.component'
import { AddressPickerService } from './address-picker.service'

@NgModule({
  declarations: [AddressPickerComponent],
  providers: [AddressPickerService, ConsigneeFormService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FlexLayoutModule,
    AddressListModule,
    ModalModule,
    SelectSearchModule,
  ],
})
export class AddressPickerModule { }
