import { formatBytes } from "./bytes.pipe"

export default module => module
  .filter('bytes', BytesFilter)


BytesFilter.$inject = []
export function BytesFilter() {
  'ngInject'

  return function (bytes, precision?) {
    return formatBytes(bytes, precision)
  }
}
