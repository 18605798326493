import { Injectable } from '@angular/core'
import { Deal, DealView } from '@tradecafe/types/core'
import { get, reject } from 'lodash-es'
import { CustomTagApiService } from 'src/api/metadata/custom-tag'

const QUERY_ALL = {limit: Number.MAX_SAFE_INTEGER}


@Injectable()
export class CustomTagsService {
  constructor(private CustomTagApi: CustomTagApiService) {}

  getAllTags() {
    return this.CustomTagApi.list(QUERY_ALL).then(({data}) => {
      data.forEach((tag) => {
        if (tag.company === 'undefined' || tag.company === null) delete tag.company
      })

      return data
    })
  }

  async loadCustomTagsFor(deal: Deal | DealView) {
    const data = await this.getAllTags()
    const country = get(deal, 'attributes.docs_country')
    const company = deal.buyer_id

    data.forEach((tag) => {
      tag.tag = tag.tag.toUpperCase() // for better indexing
    })

    return reject(data, tag =>
      tag.country && tag.country !== country ||
      // tslint:disable-next-line: triple-equals
      tag.company && tag.company != company)
  }

}
