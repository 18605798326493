import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as UserActions from './user.actions'

export const usersFeatureKey = 'users'
export interface UsersState extends EntityState<DeepReadonly<User>> {
}

export const selectId = (user: User) => user.user_id
export const adapter = createEntityAdapter<DeepReadonly<User>>({ selectId })

const initialState: UsersState = adapter.getInitialState({
})

export const usersReducer = createReducer(
  initialState,
  on(UserActions.loadUsersSuccess,
    (state, action) =>
      adapter.upsertMany(action.users, state)),
)

