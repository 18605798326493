import { Injectable } from '@angular/core'
import { mapValues } from 'lodash-es'
import { ModalProxyService } from 'src/shared/modal'

@Injectable({ providedIn: 'root' })
export class ConfirmModalService {
  constructor (
    private modalHelper: ModalProxyService,
  ) { }

  show(options, ignoreStack = false) {
    options = {
      title: 'Are you sure?',
      titleIcon: 'fa-circle-question',
      description: '',
      confirmButtonText: 'Confirm',
      confirmButtonClass: 'btn-primary',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn-link',
      ...options,
    }

    return this.modalHelper.showModal(
      {
        component: 'tcConfirmModal',
        windowClass: 'modalclone confirm-modal',
        resolve: mapValues(options, value => () => value),
      },
      ignoreStack
    )
  }

  showStaleDataWarning() {
    return this.show({
      title: 'Stale Data Warning',
      titleIcon: 'fas fa-exclamation-triangle',
      description: 'Please note that there is a possibility that the shipping costs may be out of date. Double check with the Freight Rates table and/or Damaris for latest rates.',
      confirmButtonText: 'Proceed Anyway',
      confirmButtonClass: 'btn-primary',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn-danger',
    })
  }

  showUnsavedDataWarning() {
    return this.show({
      title: 'Unsaved Data Warning',
      titleIcon: 'fas fa-exclamation-triangle',
      description: 'Form has unsaved data. Are you sure you want to leave this page?',
      confirmButtonText: 'Leave Page',
      confirmButtonClass: 'btn-danger',
      cancelButtonText: 'Stay on Page',
      cancelButtonClass: 'btn-link',
    })
  }
}
