import { Injectable } from '@angular/core'
import { DealViewRaw, Segment, DistributionListObject, Product } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable } from 'rxjs'
import { ModalService } from 'src/shared/modal/modal.service'
import { SegmentNotificationFormComponent } from './segment-notification-form.component'


export interface SegmentNotificationModalOptions {
  deal$: Observable<DeepReadonly<DealViewRaw>>,
  segments: Segment  [],
  currentSegmentId?: string,
}

@Injectable()
export class SegmentNotificationFormService {
  constructor(
    private modal: ModalService,
  ) { }

  async showSegmentNotificationForm(
    segments: SegmentNotificationModalOptions['segments'],
    currentSegmentId: SegmentNotificationModalOptions['currentSegmentId'],
    deal$: SegmentNotificationModalOptions['deal$'],
  ) {
    return this.modal.openDialog<SegmentNotificationFormComponent, SegmentNotificationModalOptions, void>(SegmentNotificationFormComponent,
      {
      segments,
      currentSegmentId,
      deal$,
    }, {
      width: '900px',
    })
  }

  checkFilters = (list: DistributionListObject, deal: DeepReadonly<DealViewRaw>, allProducts: DeepReadonly<Product[]>, carrier_account?) => {
    const { filters } = list
    const r = []

    if (filters.locations[0]) {
      if (filters.locations.find((i) => i === deal.deal.dest_location)) {
        r.push(true)
      } else {
        r.push(false)
      }
    }
    if (filters.suppliers[0]) {
      if (filters.suppliers.find((i) => i === deal.deal.supplier_id)) {
        r.push(true)
      } else {
        r.push(false)
      }
    }

    if (filters.providers[0]) {
      if (filters.providers.find((i) => i === carrier_account)) {
        r.push(true)
      } else {
        r.push(false)
      }
    }

    if (filters.products[0] || filters.product_categories[0] || filters.product_types[0]) {
      const product = allProducts.find((i) => i.product_id === deal.offers[0].product)
      if (filters.products[0]) {
        if (filters.products.find((i) => i === product.product_id)) {
          r.push(true)
        } else {
          r.push(false)
        }
      }
      if (filters.product_categories[0]) {
        if (filters.product_categories.find((i) => i === product.category_id)) {
          r.push(true)
        } else {
          r.push(false)
        }
      }
      if (filters.product_types[0]) {
        if (filters.product_types.find((i) => i === product.type_id)) {
          r.push(true)
        } else {
          r.push(false)
        }
      }
    }

    return r[0] && r.indexOf(false) === -1
  }
}
