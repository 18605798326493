import { createAction, props } from '@ngrx/store'
import { Currency, TableKey } from '@tradecafe/types/core'


export const loadCurrencies = createAction(
  '[Currency/API] Load Currencies',
  props<{
    tableKey?: TableKey,
  }>(),
)
export const loadCurrenciesSuccess = createAction(
  '[Currency/API] Load Currencies Success',
  props<{
    tableKey?: TableKey,
    currencies: Currency[],
  }>(),
)
export const loadCurrenciesFailure = createAction(
  '[Currency/API] Load Currencies Failure',
  props<{ error: Error }>(),
)
