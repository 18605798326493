import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Cost, DEAL_DRAFT, Deal, DealParty, DealViewRaw, DealViewStatus, Estimate, Note, Product } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly } from '@tradecafe/types/utils'
import { cloneDeep, merge } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { CustomCostsService } from 'src/services/data/custom-costs.service'
import { copyCost, copyDeal, copyNote } from 'src/services/data/deal-view.service'
import { dayjs } from 'src/services/dayjs'
import { EstimatesService } from '../../estimates/estimate/estimates.service'
import { newDealViewProducts } from './deal-form-page.decorator'
import { DealDetailsFormValue, DealFormGroup, DealFormValue, DealProductFormValue } from './deal-form.schema'
import { DealFormService } from './deal-form.service'


interface SnapshotOptions {
  noDates?: boolean
  details?: Partial<DealDetailsFormValue>
}

@Injectable()
export class DealFormSnapshotsService {

  constructor(
    private router: Router,
    private ConfirmModal: ConfirmModalService,
    private DealForm: DealFormService,
    private Estimates: EstimatesService,
    private CustomCosts: CustomCostsService,
    private FxRates: FxRatesService,
    private AuthApi: AuthApiService,
  ) { }

  async newDeal() {
    const { role, user_id } = this.AuthApi.currentUser
    const now = dayjs().unix()
    const [costs, fxRates] = await Promise.all([
      this.CustomCosts.querySecondaryCosts({}),
      this.FxRates.getFxRates(),
    ])
    const deal: Deal = {
      created: now,
      status: DEAL_DRAFT,
      trader_user_id: role === 'trader' ? user_id : undefined,
      trader_user_id_supplier: role === 'trader' ? user_id : undefined,
      attributes: {
        deal_date: now,
        shipment: {},
        estimated: { weight: {} },
        actual: { weight: {} },
        delivery_dates: {},
        shipment_dates: {},
        fx_rates: fxRates,
        fx_rates_timestamp: now,
      },
    } as Deal
    const dealProducts = newDealViewProducts()
    const dealViewRaw: DealViewRaw = {
      deal,
      costs: costs as Cost[],
      segments: [],
      invoices: [],
      notes: [],
      bids: [],
      offers: [],
      credit_notes: [],
      vendor_credits: [],
      files: [],
      bookings: [],
      export_reports: [],
      status: DealViewStatus.complete,
      status_state: []
    }

    return { dealViewRaw, dealProducts }
  }

  async copyDealId(dealId: string, form: DeepReadonly<DealDetailsFormValue>) {
    const { dealViewRaw, dealProducts } = await this.DealForm.load(dealId).toPromise()
    const dealForm = this.DealForm.build()
    this.DealForm.patchForm(dealForm, dealViewRaw, dealProducts)
    if (form.dealType === 'brokerage') {
      dealForm.controls.details.controls.dealType.setValue('brokerage')
      dealForm.controls.details.controls.brokeragePaymentTerms.setValue(form.brokeragePaymentTerms)
      dealForm.controls.details.controls.brokerageCustomer.setValue(form.brokerageCustomer)
      dealForm.controls.details.controls.brokerageEstAmount.setValue(form.brokerageEstAmount)
      dealForm.controls.details.controls.brokerageActAmount.setValue(form.brokerageActAmount)
      dealForm.controls.details.controls.brokerageCurrency.setValue(form.brokerageCurrency)
      dealForm.controls.details.controls.brokerageSendSupplierConfirmation.setValue(form.brokerageSendSupplierConfirmation)
      dealForm.controls.details.controls.brokerageSendBuyerConfirmation.setValue(form.brokerageSendBuyerConfirmation)
      dealForm.controls.details.controls.brokerageTraderId.setValue(form.brokerageTraderId)
      dealForm.controls.details.controls.brokerageContactUserIds.setValue([...form.brokerageContactUserIds])
    }
    return this.copyAndNavigate(dealForm, dealViewRaw)
  }

  async convertEstimate(estimate: Estimate & { product?: Product }, side: DealParty, { measures, countries, latestRates }) {
    const { dealProducts, dealViewRaw } = this.Estimates.convertEstimateToDeal(estimate, side, { measures, countries, latestRates })
    const dealForm = this.DealForm.build()
    this.DealForm.patchForm(dealForm, dealViewRaw, dealProducts)
    dealForm.controls.details.controls.buyerCurrencyCode.setValue(dealProducts[0].buyer.currency_code)
    dealForm.controls.details.controls.supplierCurrencyCode.setValue(dealProducts[0].supplier.currency_code)
    dealForm.controls.details.controls.estimateId.setValue(estimate.estimate_id)
    return this.copyAndNavigate(dealForm, dealViewRaw)
  }

  copyDealForm(dealForm: DealFormGroup, dealViewRaw: DeepReadonly<Pick<DealViewRaw, 'notes'>>) {
    return this.copyAndNavigate(dealForm, dealViewRaw, { noDates: true })
  }

  async createBrokerageDeal(form: DeepReadonly<DealDetailsFormValue>) {
    const { dealViewRaw, dealProducts } = await this.newDeal()
    const dealForm = this.DealForm.build()
    this.DealForm.patchForm(dealForm, dealViewRaw, dealProducts)
    dealForm.controls.details.controls.dealType.setValue('brokerage')
    dealForm.controls.details.controls.brokeragePaymentTerms.setValue(form.brokeragePaymentTerms)
    dealForm.controls.details.controls.brokerageCustomer.setValue(form.brokerageCustomer)
    dealForm.controls.details.controls.brokerageEstAmount.setValue(form.brokerageEstAmount)
    dealForm.controls.details.controls.brokerageActAmount.setValue(form.brokerageActAmount)
    dealForm.controls.details.controls.brokerageCurrency.setValue(form.brokerageCurrency)
    dealForm.controls.details.controls.brokerageSendSupplierConfirmation.setValue(form.brokerageSendSupplierConfirmation)
    dealForm.controls.details.controls.brokerageSendBuyerConfirmation.setValue(form.brokerageSendBuyerConfirmation)
    dealForm.controls.details.controls.brokerageTraderId.setValue(form.brokerageTraderId)
    dealForm.controls.details.controls.brokerageContactUserIds.setValue([...form.brokerageContactUserIds])
    return this.copyAndNavigate(dealForm, dealViewRaw)
  }

  async createDealFromFutureBid(
    products: DeepReadonly<Partial<DealProductFormValue>[]>,
    details: DeepReadonly<Partial<DealDetailsFormValue>>,
  ) {
    const { dealViewRaw, dealProducts } = await this.newDeal()
    const dealForm = this.DealForm.build()
    this.DealForm.patchForm(dealForm, dealViewRaw, dealProducts)
    dealForm.controls.details.patchValue(details)
    dealForm.controls.products.patchValue(products)
    dealForm.controls.details.controls.deal.patchValue({buyer_id: details.buyerId, supplier_id: details.supplierId})
    return this.copyAndNavigate(dealForm, dealViewRaw, {
       details: {
        dealType: 'limit_order',
        shipmentDatesFrom: details.shipmentDatesFrom,
        shipmentDatesTo: details.shipmentDatesTo,
        shipmentDatesTbd: '',
        deliveryDatesFrom: details.deliveryDatesFrom,
        deliveryDatesTo: details.deliveryDatesTo,
        deliveryDatesTbd: '',
        supplierPaymentTerms: details.supplierPaymentTerms,
        buyerPaymentTerms: details.buyerPaymentTerms,
        supplierTermDate: details.supplierTermDate,
        buyerTermDate: details.buyerTermDate,
      }
    })
  }

  private async copyAndNavigate(
    dealForm: DealFormGroup,
    dealViewRaw: DeepReadonly<Pick<DealViewRaw, 'notes'>>,
    options: SnapshotOptions = {},
  ) {
    if (hasDealFormStaleData(dealForm.getRawValue())) {
      await this.ConfirmModal.showStaleDataWarning()
    }
    const snapshotId = this.saveSnapshot(dealForm, dealViewRaw, options)
    return this.router.navigateByUrl(`/trading/deals/new?snapshotId=${snapshotId}`)
  }

  private saveSnapshot(
    dealForm: DealFormGroup,
    dealViewRaw: DeepReadonly<Pick<DealViewRaw, 'notes'>>,
    options: SnapshotOptions = {},
  ): string {
    const { products, costs } = dealForm.getRawValue()
    const patches: Partial<DealDetailsFormValue>[] = []
    if (options.noDates) {
      patches.push({
        shipmentDatesFrom: null,
        shipmentDatesTo: null,
        deliveryDatesFrom: null,
        deliveryDatesTo: null,
        // merge(deal, {
        //   status: DEAL_DRAFT,
        //   attributes: {
        //     shipment_dates: { from: null, to: null},
        //     delivery_dates: { from: null, to: null},
        //   },
        // })
      })
    }
    const details: DealDetailsFormValue = merge(cloneDeep(dealForm.getRawValue().details), ...patches)
    const snapshot: DeepPartial<DealFormValue> = {
      details: {
        deal: copyDeal(details.deal, true),
        copyOf: details.deal.deal_id,
        estimateId: details.estimateId,
        buyerId: details.buyerId,
        buyerCurrencyCode: details.buyerCurrencyCode,
        buyerPaymentTerms: details.buyerPaymentTerms,
        buyerTraderId: details.buyerTraderId,
        buyerUserIds: details.buyerUserIds,
        destLocationId: details.destLocationId,
        docsCountryCode: details.docsCountryCode,
        logisticsUserId: details.logisticsUserId,
        originCountryCode: details.originCountryCode,
        woExportDocs: details.woExportDocs,
        originLocationId: details.originLocationId,
        proformaNeeded: details.proformaNeeded,
        supplierId: details.supplierId,
        supplierCurrencyCode: details.supplierCurrencyCode,
        supplierPaymentTerms: details.supplierPaymentTerms,
        supplierTraderId: details.supplierTraderId,
        supplierUserIds: details.supplierUserIds,
        temperatureUnits: details.temperatureUnits,
        actualTotals: { weight: {} },
        estimatedTotals: { weight: {} },
        dealType: details.dealType,
        brokeragePaymentTerms: details.brokeragePaymentTerms,
        brokerageCustomer: details.brokerageCustomer,
        brokerageEstAmount: details.brokerageEstAmount,
        brokerageActAmount: details.brokerageActAmount,
        brokerageCurrency: details.brokerageCurrency,
        brokerageSendSupplierConfirmation: details.brokerageSendSupplierConfirmation,
        brokerageSendBuyerConfirmation: details.brokerageSendBuyerConfirmation,
        brokerageTraderId: details.brokerageTraderId,
        brokerageContactUserIds: details.brokerageContactUserIds,
        ...(options.details || {})
      },
      products: products.map(product => ({
        bid: {},
        offer: {},
        // 'comments',
        // 'description',
        // 'shipping_marks',
        batches: product.batches,
        additionalSpecs: product.additionalSpecs,
        brand: product.brand,
        buyerIncotermId: product.buyerIncotermId,
        buyerIncotermLocationId: product.buyerIncotermLocationId,
        buyerLocked: product.buyerLocked,
        buyerMeasureId: product.buyerMeasureId,
        buyerEstPrice: product.buyerEstPrice,
        buyerActualPrice: product.buyerActualPrice,
        buyerEstWeight: product.buyerEstWeight,
        establishments: product.establishments,
        invoiceAddress: product.invoiceAddress,
        itemTypeId: product.itemTypeId,
        packageId: product.packageId,
        packageMeasureId: product.packageMeasureId,
        packagesCount: product.packagesCount,
        packageSize: product.packageSize,
        productCode: product.productCode,
        productId: product.productId,
        supplierIncotermId: product.supplierIncotermId,
        supplierIncotermLocationId: product.supplierIncotermLocationId,
        supplierLocked: product.supplierLocked,
        supplierMeasureId: product.supplierMeasureId,
        supplierEstPrice: product.supplierEstPrice,
        supplierActualPrice: product.supplierActualPrice,
        supplierEstWeight: product.supplierEstWeight,
        weightTypeId: product.weightTypeId,
        wrappingId: product.wrappingId,
      })),
      costs: costs.map(cost => ({
        cost: copyCost(cost.cost),
        amount: cost.amount,
        currencyCode: cost.currencyCode,
        providerId: cost.providerId,
        status: cost.status,
        type: cost.type,
        productId: cost.productId,
        serviceName: cost.serviceName,
      })).filter(c => !!c.cost.amount.total),
    }
    const notes = (dealViewRaw.notes || [])
      .filter(note => note.attributes?.category === 'special_instructions')
      .map(copyNote)
    const snapshotId = '' + Date.now()
    sessionStorage.setItem(snapshotId, JSON.stringify({ snapshot, notes }))
    return snapshotId
  }

  async restoreSnapshot(snapshotId: string) {
    const { snapshot, notes } = JSON.parse(sessionStorage.getItem(snapshotId)) as { snapshot: DealFormValue, notes: Note[] }
    let { dealViewRaw } = await this.newDeal()
    dealViewRaw = {
      ...dealViewRaw,
      deal: merge(dealViewRaw.deal, snapshot.details.deal),
      costs: snapshot.costs.map(cf => cf.cost as Cost),
      notes,
    }
    snapshot.details.deal = dealViewRaw.deal
    return { dealViewRaw, snapshot }
  }
}


function hasDealFormStaleData(deal: DeepReadonly<DealFormValue>) {
  if (!deal.details.date) {
    console.warn('deal date is not defined')
    return true
  }
  // if the deal is older than 3 months
  if (dayjs().diff(dayjs.unix(deal.details.date), 'month') >= 3) return true
  // check freight costs
  for (const cost of deal.costs.filter(c => c.cost.attributes.freight_type)) {
    const { rate_until, rate_created } = cost.cost.attributes
    if (!rate_until) {
      console.warn(`stale check ${deal.details.deal.deal_id}: freigt rate expiration time is not defined`)
      return true
    }
    // If the date on that day is after the "valid until" date in the freight rate used
    if (dayjs().diff(dayjs.unix(rate_until), 'month') >= 3) return true
    // if the freight rates are older than 3 months
    if (dayjs().diff(dayjs.unix(rate_created), 'month') >= 3) return true
  }

  return false
}
