import { EventEmitter, Injectable } from '@angular/core'
import { TransitTime } from '@tradecafe/types/core'
import { compact, flatten, map, pick, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { TransitTimeApiService } from 'src/api/shipment-routing/transit-time'
import { UsersService } from 'src/services/data/users.service'
import { QueryService } from 'src/services/query.service'
import { LocationsService } from '../locations/locations.service'

const ALLOWED_FIELDS = ['name', 'pickup', 'delivery', 'time', 'type']
const LIST_UPDATE_EVENT = 'transit-times:update'
const CACHE_MAX_AGE = 3600 * 1000 // 1hr


@Injectable()
export class TransitTimesService {
  constructor(
    private Locations: LocationsService,
    private TransitTimeApi: TransitTimeApiService,
    private AuthApi: AuthApiService,
    private Users: UsersService,
    private Query: QueryService,
  ) {}


  private cacheExpiration = 0
  private cache = Promise.resolve({ data: [] })

  listUpdateEvent$ = new EventEmitter<void>()


  /**
   * Get all available transitTimes
   *
   * @param {any} query
   * @returns
   */
  async getTransitTimes(): Promise<TransitTime[]>
  async getTransitTimes(query): Promise<{ data: TransitTime[], total_rows: number }>
  async getTransitTimes(query?): Promise<TransitTime[] | { data: TransitTime[], total_rows: number }> {
    if (!this.cache || this.cacheExpiration < Date.now()) {
      this.cacheExpiration = Date.now() + CACHE_MAX_AGE // 1 min
      // NOTE: we fetch everything
      this.cache = this.TransitTimeApi.list({ limit: Number.MAX_SAFE_INTEGER })
      .then(async (transitTimes) => {
        const { account } = this.AuthApi.currentUser
        const userIds = uniq(compact(map(transitTimes.data, 'user_id')))
        const locationIds = uniq(compact(flatten(transitTimes.data.map(transitTime => [
          transitTime.pickup,
          transitTime.delivery,
        ]))))
        const [users, locations] = await Promise.all([
          this.Users.getUsersByIds(account, userIds),
          this.Locations.getLocationsByIds(locationIds),
        ])

        transitTimes.data.forEach((transitTime) => {
          transitTime['pickupLocation'] = locations[transitTime.pickup]?.name || ''
          transitTime['deliveryLocation'] = locations[transitTime.delivery]?.name || ''
          ; (transitTime as any).user = users[transitTime.user_id] // TODO: define ui-only TrasitTimeRow interface
          transitTime.created = transitTime.created
          transitTime.updated = transitTime.updated || transitTime.created
        })
        return transitTimes
      })
      .catch((err) => {
        this.invalidateCache()
        throw err
      })
    }
    const { data } = await this.cache
    return query ? this.Query.applyQuery(data, query) : data
  }

  /**
   * Get possible values for given transitTime field
   *
   * @param {any} fieldName
   * @returns
   */
  async getFilterData(fieldName: 'pickup'|'delivery'|'type') {
    const transitTimes = await this.getTransitTimes()
    const values = uniq(compact(map(transitTimes, fieldName)))
    return values
  }

  /**
   * Get transit time document by id
   *
   * @param {string} id
   * @returns
   */
  async getById(id: string) {
    const { data } = await this.TransitTimeApi.get(id)
    return data
  }

  /**
     * Create new transit time
     *
     * @param {any} transitTime
     * @returns
     */
  async create(transitTime) {
    const { user_id } = this.AuthApi.currentUser
    const payload = pick(transitTime, ALLOWED_FIELDS)
    payload.user_id = user_id

    const { data } = await this.TransitTimeApi.create(payload)
    this.invalidateCache()
    this.listUpdateEvent$.next()
    return data
  }

  /**
   * Update transit time
   *
   * @param {any} transitTime
   * @returns
   */
  async update(transitTime) {
    const { user_id } = this.AuthApi.currentUser
    const { transit_time_id } = transitTime
    const payload = pick(transitTime, ALLOWED_FIELDS)
    payload.user_id = user_id
    const { data } = await this.TransitTimeApi.update(transit_time_id, payload)
    this.invalidateCache()
    this.listUpdateEvent$.next()
    return data
  }

  /**
   * Remove transit time
   *
   * @param {any} transitTime
   * @returns
   */
  async remove(transitTime) {
    const { data } = await this.TransitTimeApi.delete(transitTime.transit_time_id)
    this.invalidateCache()
    this.listUpdateEvent$.next()
    return data
  }


  /**
   * Invalidate transitTimes in-memory cache
   *
   * @private
   */
  private invalidateCache() {
    this.cacheExpiration = 0
  }
}
