import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { ModalService } from './modal.service'

@NgModule({
  providers: [ModalService],
  imports: [MatDialogModule],
  exports: [MatDialogModule],
})
export class ModalModule { }
