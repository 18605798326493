import { TableIdentities } from '../constants/table-identities'
import { currenciesResolver } from '../services/data/deal.resolvers'
import { tableViewsResolver } from '../services/data/table-views.service'


export const routes = [
  {
    name: 'app.management',
    // parent: 'app',
    url: '/management',
    abstract: true,
    template: '<div ui-view></div>',
    data: {
      roles: ['manager', 'superuser'],
      title: 'Management',
    },
  }, {
    name: 'app.management.default',
    // parent: 'app.management',
    url: '',
    redirectTo: 'app.management.audit-trails',
  }, {
    name: 'app.management.audit-trails',
    // parent: 'app.management',
    url: '/audits',
    component: 'adminManagementAuditTrailList',
    data: {
      title: 'Audit Trail',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.managementAuditTrailList,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.management.credit-notes',
    // parent: 'app.management',
    url: '/credit-notes',
    component: 'adminManagementCreditNoteList',
    data: {
      title: 'Credit Notes',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.managementCreditNoteList,
      tableViews: tableViewsResolver,
      currencies: currenciesResolver,
    },
  }, {
    name: 'app.management.credit-overrides',
    // parent: 'app.management',
    url: '/credit-overrides',
    component: 'adminManagementCreditOverrideList',
    data: {
      title: 'Credit Overrides',
    },
    resolve: {
      tableIdentity: () => TableIdentities.admin.managementCreditOverrideList,
      tableViews: tableViewsResolver,
    },
  }, {
    name: 'app.management.vendor-invoices',
    url: '/vendor-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Vendor Invoices',
    },
  },
  {
    name: 'app.management.buyer-invoices',
    url: '/buyer-invoices',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Buyer Invoices',
    },
  },
  {
    name: 'app.management.ar-report',
    url: '/ar-report',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'AR Report',
    },
  }, {
    name: 'app.management.negative-deals',
    url: '/negative-deals',
    component: 'tcMockUpgradedPage',
    data: {
      title: 'Negative Deals',
    },
  },
]

tableViewsLoader.$inject = ['tableViewManager', 'tableIdentity']
function tableViewsLoader(tableViewManager, tableIdentity) {
  'ngInject'

  return tableViewManager.loadFor(tableIdentity)
}
