import { createAction, props } from '@ngrx/store'
import { Country } from '@tradecafe/types/core'


export const loadCountries = createAction(
  '[Country/API] Load Countries',
)
export const loadCountriesSuccess = createAction(
  '[Country/API] Load Countries Success',
  props<{
    countries: Country[],
  }>(),
)
export const loadCountriesFailure = createAction(
  '[Country/API] Load Countries Failure',
  props<{ error: Error }>(),
)
