import { createSelector } from '@ngrx/store'
import { LocationObject } from '@tradecafe/types/core'
import { compareBy } from 'src/services/table-utils/compare'
import { State } from '../reducers'
import { adapter, locationsFeatureKey } from './location.reducer'

export const selectState = (state: State) => state[locationsFeatureKey]

export const { selectEntities: selectLocationEntities, selectAll } = adapter.getSelectors(selectState)

export const selectAllLocations = createSelector(selectAll, locations => locations.sort(compareBy('name')))

export const selectLocation = createSelector(
  selectLocationEntities,
  (locations: Dictionary<LocationObject>, locationId: string) =>
    locations[locationId])

export const selectLocationsByIds = createSelector(
  selectLocationEntities,
  (locations: Dictionary<LocationObject>, locationIds: string[]) =>
    locationIds.map(locationId => locations[locationId]))
