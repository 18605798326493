import { Injectable } from '@angular/core';
import { ShipmentRate } from '@tradecafe/types/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  ProblematicFreightRatesComponent,
  ProblematicFreightRatesOptions,
} from './problematic-freight-rates.component';

@Injectable()
export class ProblematicFreightRatesService {
  constructor(private modal: ModalService) {}

  showProblematicFreightRates(freightRate: ShipmentRate) {
    return this.modal
      .openDialog<
        ProblematicFreightRatesComponent,
        ProblematicFreightRatesOptions,
        ShipmentRate
      >(ProblematicFreightRatesComponent, {
        freightRate,
      })
      .toPromise();
  }
}
