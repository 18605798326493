import { createSelector } from '@ngrx/store'
import { PaymentReference } from '@tradecafe/types/core'
import { compareBy } from 'src/services/table-utils/compare'
import { State } from '../reducers'
import { adapter, paymentReferencesFeatureKey } from './payment-reference.reducer'

export const selectState = (state: State) => state[paymentReferencesFeatureKey]

export const { selectAll: selectAllPaymentReferences, selectEntities: selectPaymentReferenceEntities } = adapter.getSelectors(selectState)

export const selectPaymentReference = createSelector(
  selectPaymentReferenceEntities,
  (pricingTerms: Dictionary<PaymentReference>, pricingTerm: string | number) =>
    pricingTerms[pricingTerm])

export const selectPaymentReferencesById = createSelector(
  selectPaymentReferenceEntities,
  (pricingTerms: Dictionary<PaymentReference>, pricingTermId: string) =>
    pricingTerms[pricingTermId])

export const selectPaymentReferencesByIds = createSelector(
  selectPaymentReferenceEntities,
  (pricingTerms: Dictionary<PaymentReference>, pricingTermIds: string[]) =>
    pricingTermIds.map(pricingTermId => pricingTerms[pricingTermId]))

export const selectPaymentReferencesOptions = (_selected?: string | string[]) => createSelector(
  selectAllPaymentReferences,
  (paymentReferences) => paymentReferences.sort(compareBy('reference')).map(pt => ({ ...pt, tooltip: pt.reference })))
