import { suppliersResolver } from 'src/services/data/deal.resolvers'
import { Injectable } from '@angular/core'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class SupplierOfferNoteFormService {
  constructor(
    private modal: ModalProxyService,
  ) {}


  public createSupplierOfferNote(note) {
    return this.show({
      note,
      options: {
        title: 'Add Note',
      },
    })
  }

  public updateSupplierOfferNote(note) {
    return this.show({
      note,
      options: {
        title: 'Update Note',
      },
    })
  }

  private async show({note, options}) {
    const resolve = {
      note: () => note,
      suppliers: suppliersResolver,
      options: () => options,
    }

    note = await this.modal.showModal({
      component: 'tcSupplierOfferNoteForm',
      size: 'lg',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve,
    })

    return note
  }
}
