import { createAction, props } from '@ngrx/store'
import { AccountObject, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'


export const loadAccounts = createAction('[Account/API] Load Accounts', props<{ tableKey?: TableKey }>())
export const loadAccountsSuccess = createAction('[Account/API] Load Accounts Success', props<{ tableKey?: TableKey, accounts: AccountObject[] }>())
export const loadAccountsFailure = createAction('[Account/API] Load Accounts Failure', props<{ error: Error }>())

export const patchAccount = createAction('[Account/API] Patch Account', props<{ account: string|number; patch: DeepReadonly<Partial<AccountObject>> }>())
export const patchAccountSuccess = createAction('[Account/API] Patch Account Success', props<{ account: AccountObject }>())
export const patchAccountFailure = createAction('[Account/API] Patch Account Failure', props<{ error: Error }>())

export const reassignAccounts = createAction('[Account/API] Reassign Accounts', props<{ coordinator_reassignments: DeepReadonly<AccountObject['coordinator_reassignments']> }>())
export const reassignAccountsSuccess = createAction('[Account/API] Reassign Accounts Success', props<{ accounts: AccountObject[] }>())
export const reassignAccountsFailure = createAction('[Account/API] Reassign Accounts Failure', props<{ error: Error }>())
