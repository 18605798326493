import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AddressFieldModule } from 'src/components/address-field/address-field.module';
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module';
import { ProductFieldModule } from 'src/components/product-field/product-field.module';
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module';
import { SelectSearchModule } from 'src/components/select-search/select-search.module';
import { BuyersGroupsModule } from 'src/pages/admin/settings/buyers-groups/buyers-groups-page.module';
import { PipesModule } from 'src/shared/pipes/pipes.module';
import { ToasterModule } from 'src/shared/toaster/toaster.module';
import { SupplierOfferCloneFormModule } from '../supplier-offer-overlay/supplier-offer-clone-form/supplier-offer-clone-form.module';
import { SupplierOfferNoteFormService } from '../supplier-offer-overlay/supplier-offer-note-form/supplier-offer-note-form.service';
import { AuditLogModule } from './audit-log/audit-log.module';
import { SupplierOfferOverlayComponent } from './supplier-offer-overlay.component';
import { SupplierOfferOverlayService } from './supplier-offer-overlay.service';

@NgModule({
  declarations: [SupplierOfferOverlayComponent],
  exports: [SupplierOfferOverlayComponent],
  imports: [
    CommonModule,
    PipesModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    MatTabsModule,
    ReactiveAsteriskModule,
    FlexLayoutModule,
    SelectSearchModule,
    EpochRangeFieldModule,
    AddressFieldModule,
    AuditLogModule,
    ToasterModule,
    SupplierOfferCloneFormModule,
    BuyersGroupsModule,
    ProductFieldModule,
  ],
  providers: [SupplierOfferOverlayService, SupplierOfferNoteFormService]
})
export class SupplierOfferOverlayModule {}
