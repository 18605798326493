import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Deal, DealPartyE, Invoice, Receipt } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { environment } from 'src/environments/environment'

export default module =>
  module.service('OperationsApi', downgradeInjectable(OperationsApiService))

@AngularCopy()
@Injectable()
export class OperationsApiService {
  constructor(private http: HttpClient) { }

  escalateInvoice(invoiceId: string) {
    return this.http.post<void>(`${environment.apiUrl}/operations/escalate-invoice`, { invoiceId })
  }

  confirmDeal(deal_id: string) {
    return this.http.post<{ data: Deal }>(`${environment.apiUrl}/operations/confirm-deal`, { deal_id }).toPromise()
  }

  closeDeal(deal_id: string) {
    return this.http.post<{ data: Deal }>(`${environment.apiUrl}/operations/close-deal`, { deal_id }).toPromise()
  }

  sendLink(payload: { deal_ids: string[], recipients: string[], to: DealPartyE, attachments: string[] }) {
    return this.http.post<{ data: Deal }>(`${environment.apiUrl}/operations/send-link`, payload).toPromise()
  }

  createReceipt(receipt: DeepReadonly<Omit<Receipt, 'receipt_id'|'created'>>) {
    return this.http.post<{ data: Receipt }>(`${environment.apiUrl}/operations/create-receipt`, receipt)
  }

  voidReceipt(receipt_id: string) {
    return this.http.post<void>(`${environment.apiUrl}/operations/void-receipt`, { receipt_id })
  }

  applyReceipt(payload: Pick<Receipt, 'receipt_id'|'invoices'>) {
    return this.http.post<void>(`${environment.apiUrl}/operations/apply-receipt`, payload)
  }

  createBankCharge(invoice_id: string) {
    return this.http.post<void>(`${environment.apiUrl}/operations/create-bank-charge`, { invoice_id })
  }

  undoBankCharge(invoice_id: string) {
    return this.http.post<void>(`${environment.apiUrl}/operations/undo-bank-charge`, { invoice_id })
  }

  actualizeInvoice(payload: {
    invoiceId: string,
    costs: { costId: string, amount: number }[],
    due: number
    prepaymentIds: string[],
    vendorCredits: { vendorCreditId: string, amount: number }[],
    vendorInvoiceId: string
  }) {
    return this.http.post<void>(`${environment.apiUrl}/operations/actualize-invoice`, payload).toPromise()
  }

  createPrepaymentInvoice(payload: {
    deal_id: string,
    amount: number,
    due: number,
    issued?: number,
  }): Promise<{ data: Invoice }> {
    return this.http.post<{ data: Invoice }>(`${environment.apiUrl}/operations/create-prepayment-invoice`, payload).toPromise()
  }

  createBuyerInvoice(dealId: string): Promise<{ data: Invoice }> {
    return this.http.post<{ data: Invoice }>(`${environment.apiUrl}/operations/create-buyer-invoice`, { dealId }).toPromise()
  }

  createBrokerageInvoice(dealId: string): Promise<{ data: Invoice }> {
    return this.http.post<{ data: Invoice }>(`${environment.apiUrl}/operations/create-brokerage-invoice`, { dealId }).toPromise()
  }

  createPayableInvoice(payload: {
    deal_id: string;
    account: string;
    due: number;
    issued: number;
    vendor_invoice_id?: string;
    amount?: number;
    currency?: string;
    costs?: { costId: string, amount: number }[];
    vendorCredits: { vendorCreditId: string, amount: number }[];
  }): Promise<{ data: Invoice }> {
    return this.http.post<{ data: Invoice }>(`${environment.apiUrl}/operations/create-payable-invoice`, payload).toPromise()
  }

  additionalCost(dealId: string, userPrice: number) {
    return this.http.post<void>(`${environment.apiUrl}/operations/additional-charge`, { dealId, userPrice })
  }
}
