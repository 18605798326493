import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Cost, DealBase, DealViewRaw, Invoice, INVOICE_PAID } from '@tradecafe/types/core'
import { DeepReadonly, findBuyerInvoice, findSupplierInvoice, getAvgReceiptDate } from '@tradecafe/types/utils'
import { compact, flatten } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { selectAccountEntities } from 'src/app/store/accounts'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'
import { DealFormGroup, DealFormValue } from '../../deal-form.schema'


@Component({
  selector: 'tc-trading-summary',
  templateUrl: './trading-summary.component.html',
  styleUrls: ['./trading-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradingSummaryComponent implements OnInit {
  constructor(private store: Store) { }

  @Input() dealForm: DealFormGroup
  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>

  protected estFinanceTerm$: Observable<number>
  protected financeTerm$: Observable<number>

  protected supplierAvgToDueDate$: Observable<number>
  protected buyerAvgToDueDate$: Observable<number>
  protected actualLiabilityDate$: Observable<number>
  protected actualCollectionDate$: Observable<number>

  protected estimatedTotals$: Observable<DealBase['attributes']['estimated']>
  protected actualTotals$: Observable<DealBase['attributes']['actual']>


  ngOnInit() {
    const detailsForm = this.dealForm.controls.details
    const form$ = replayForm(detailsForm)
    this.estimatedTotals$ = form$.pipe(map(({ estimatedTotals }) => estimatedTotals), distinctUntilChanged())
    this.actualTotals$ = form$.pipe(map(({ actualTotals }) => actualTotals), distinctUntilChanged())
    this.estFinanceTerm$ = form$.pipe(map(({ estFinanceTerm }) => estFinanceTerm), distinctUntilChanged())
    this.financeTerm$ = form$.pipe(map(({ financeTerm }) => financeTerm), distinctUntilChanged())

    const accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
    this.supplierAvgToDueDate$ = combineLatest([accounts$, replayForm(detailsForm.controls.supplierId)]).pipe(
      map(([accounts, supplierId]) => accounts[supplierId]?.attributes.credit_info?.avg_days_to_due_date || 0),
      distinctUntilChanged())
    this.buyerAvgToDueDate$ = combineLatest([accounts$, replayForm(detailsForm.controls.buyerId)]).pipe(
      map(([accounts, buyerId]) => accounts[buyerId]?.attributes.credit_info?.avg_days_to_due_date || 0),
      distinctUntilChanged())
    const buyerInvoice$ = combineLatest([this.dealViewRaw$, replayForm(detailsForm.controls.buyerId)])
      .pipe(map(([dv, buyerId]) => findBuyerInvoice(dv.invoices, buyerId)))
    const supplierInvoice$ = combineLatest([this.dealViewRaw$, replayForm(detailsForm.controls.supplierId)])
      .pipe(map(([dv, supplierId]) => findSupplierInvoice(dv.invoices, supplierId)))
    this.actualLiabilityDate$ = supplierInvoice$.pipe(map(supplierInvoice =>
      supplierInvoice?.status === INVOICE_PAID ? supplierInvoice?.attributes?.paid : supplierInvoice?.due))
    // this.actualCollectionDate$ = this.dealViewRaw$.pipe(map(dv => getInterestDates(dv).avgReceiptDate))
    this.actualCollectionDate$ = combineLatest([replayForm<DealFormValue>(this.dealForm), buyerInvoice$]).pipe(
      map(([df, buyerInvoice]) => actualCollectionDate(df.details.deal, buyerInvoice, df.costs.map(cf => cf.cost))))
  }
}

export function actualCollectionDate(
  deal: DeepReadonly<DealBase>,
  buyerInvoice: DeepReadonly<Invoice>,
  costs: DeepReadonly<Partial<Cost>[]>,
): number {
  if (buyerInvoice?.status !== INVOICE_PAID) return undefined
  const creditNotes = compact(flatten(costs.map((cost) => cost.attributes?.credit_notes_ex)));
  return getAvgReceiptDate(deal, buyerInvoice, creditNotes)
}
