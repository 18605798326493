import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { AddressFieldModule } from 'src/components/address-field/address-field.module'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { EpochRangeFieldModule } from 'src/components/epoch/epoch-range-field/epoch-range-field.module'
import { ProductFieldModule } from 'src/components/product-field/product-field.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { DealProductFormComponent } from './deal-product-form.component'

@NgModule({
  declarations: [DealProductFormComponent],
  exports: [DealProductFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    FlexLayoutModule,
    SelectSearchModule,
    PipesModule,
    AddressFieldModule,
    EpochRangeFieldModule,
    EpochFieldModule,
    ProductFieldModule,
  ],
})
export class DealProductFormModule {}
