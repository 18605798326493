import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AllInvoiceStatuses, INTERNAL, Invoice, INVOICE_ACTUALIZED, INVOICE_APPROVED, INVOICE_IN_REVIEW, INVOICE_NEEDS_INFO, INVOICE_PAID, INVOICE_SCHEDULED } from '@tradecafe/types/core'
import { canEscalateInvoice, DeepReadonly } from '@tradecafe/types/utils'
import { extend, filter, get, map, merge, omit, set } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { InvoiceApiService } from 'src/api/invoice'
import { OperationsApiService } from 'src/api/operations'
import { escalateInvoicesSuccess, escalateInvoiceSuccess } from 'src/app/store/deal-view.actions'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { MarkAsPaidFormService } from 'src/components/invoices/mark-as-paid-form/mark-as-paid-form.service'
import { InvoicesService } from 'src/services/data/invoices.service'
import { ModalProxyService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { NotesService } from '../data/notes.service'
import { dayjs } from '../dayjs'

@Injectable()
export class InvoiceFormsService {
  constructor(
    private toaster: ToasterService,
    private modalHelper: ModalProxyService,
    private AuthApi: AuthApiService,
    private InvoiceApi: InvoiceApiService,
    private Invoices: InvoicesService,
    private ConfirmModal: ConfirmModalService,
    private Notes: NotesService,
    private OperationsApi: OperationsApiService,
    private store: Store,
    private MarkAsPaidForm: MarkAsPaidFormService,
  ) {}

  private async updateItemsStatusImmutable(
    invoices: DeepReadonly<Invoice[]>,
    status: Invoice['status'],
    dateKey: string,
    paid?: number,
  ): Promise<void|Invoice[]> {
    if (!invoices.length) return

    if (dateKey !== 'paid') {
      await this.ConfirmModal.show({
        title: 'Warning',
        description: `Are you sure you want to update these invoices status to ${dateKey}?`,
      })
    }

    // TODO: create ngrx actions and effect
    const updated = await Promise.all(invoices.map((invoice) =>
      this.Invoices.patchImmutableInvoice(invoice, {
        status,
        attributes: {
          [dateKey]: paid || dayjs.utc().unix(),
          [`${dateKey}_user`]: this.AuthApi.currentUser.user_id,
        },
      })))
    .catch((err) => {
      console.error('Unable to update invoices status.', err)
      this.toaster.error('Unable to update invoices status.', err)
      throw err
    })

    this.toaster.success('Invoices status updated successfully.')
    return updated
  }

  async updateInvoicesStatus(
      selectedInvoices: DeepReadonly<Invoice[]>,
      status: Invoice['status'],
      dateKey: string,
  ): Promise<void|Invoice[]> {
    if (!selectedInvoices.length) return
    const items = selectedInvoices.filter(invoice => statusChangeTestGeneric(invoice, status))

    if (!items.length) {
      this.toaster.warning('No invoice able to update to this status')
      return
    }
    if (selectedInvoices.length !== items.length) {
      this.toaster.warning(`${items.length} invoice(s) will be updated to this status`)
    }

    return this.updateItemsStatusImmutable(items, status, dateKey)
  }

  async updateInvoicesStatusToPaid(
    selectedInvoices: DeepReadonly<Invoice[]>,
  ): Promise<void|Invoice[]> {
    if (!selectedInvoices.length) return

    const items = selectedInvoices.filter(invoice => statusChangeTestGeneric(invoice, INVOICE_PAID))

    if (selectedInvoices.length !== items.length) {
      this.toaster.error(`Some of the invoices you have chosen are not in Approved status.
      Please check the list to remove any invoice that is not in Approved status.`)
      return
    }

    const paid = await this.MarkAsPaidForm.showMarkAsPaid().toPromise()

    if (!paid) return

    return this.updateItemsStatusImmutable(selectedInvoices, INVOICE_PAID, 'paid', paid)
  }

  async voidSelectItems(selectedInvoices: Invoice[], success?: () => void) {
    if (!selectedInvoices.length) return
    const items = selectedInvoices.filter(item =>
      [INVOICE_IN_REVIEW, INVOICE_NEEDS_INFO].includes(item.status))

    if (!items.length) {
      this.toaster.warning('Only invoice in "In Review" and "Needs Info" status able to void.')
      return
    }
    if (selectedInvoices.length !== items.length) {
      this.toaster.warning(`${items.length} invoice(s) will be voided`)
    }

    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to void selected invoices?',
    })

    await Promise.all(items.map(x => this.Invoices.voidInvoice(x)))
    .catch(err => {
      console.error('Unable to void invoices.', err)
      this.toaster.error('Unable to void invoices.', err)
      throw err
    })
    if (angular.isFunction(success)) success()
    this.toaster.success('Invoices voided successfully.')
  }

  async showScheduleSelectedInvoices(selectedInvoices: DeepReadonly<Invoice[]>): Promise<void|Invoice[]> {
    if (!selectedInvoices.length) return
    const items = selectedInvoices.filter(invoice =>
      statusChangeTestGeneric(invoice, INVOICE_SCHEDULED))

    if (!items.length) {
      this.toaster.warning('No invoice need to be scheduled.')
      return
    }
    if (selectedInvoices.length !== items.length) {
      this.toaster.warning(`${items.length} invoice(s) will be scheduled.`)
    }

    const changes = await this.modalHelper.showModal({
      component: 'tcInvoiceScheduleForm',
      windowClass: 'modalclone',
    })

    const updated = await Promise.all(items.map(item =>
      this.Invoices.patchImmutableInvoice(item, changes)))
    .catch(err => {
      console.error('Unable to schedule invoices.', err)
      this.toaster.error('Unable to schedule invoices.', err)
      throw err
    })

    this.toaster.success('Invoices scheduled successfully.')
    return updated
  }

  async showRescheduleSelectInvoices(selectedInvoices: DeepReadonly<Invoice[]>): Promise<void|Invoice[]> {
    if (!selectedInvoices.length) return
    const items = selectedInvoices.filter(item => {
      const statusDef = AllInvoiceStatuses[item.status]
      return (statusDef && item.status === INVOICE_SCHEDULED)
    })
    if (!items.length) {
      this.toaster.warning('No invoice need to be re-scheduled.')
      return
    }
    if (selectedInvoices.length !== items.length) {
      this.toaster.warning(`${items.length} invoice(s) will be re-scheduled.`)
    }

    const changes = await this.modalHelper.showModal({
      component: 'tcInvoiceScheduleForm',
      windowClass: 'modalclone',
    })

    const updated = await Promise.all(items.map(item =>
      this.Invoices.patchImmutableInvoice(item, changes)))
    .catch(err => {
      console.error('Unable to re-scheduled invoices.', err)
      this.toaster.error('Unable to re-scheduled invoices.', err)
      throw err
    })

    this.toaster.success('Invoices re-scheduled successfully.')
    return updated
  }

  async unscheduleSelectInvoices(selectedInvoices: DeepReadonly<Invoice[]>): Promise<void|Invoice[]> {
    if (!selectedInvoices.length) return
    const items = selectedInvoices.filter(item => {
      const statusDef = AllInvoiceStatuses[item.status]
      return (statusDef && item.status === INVOICE_SCHEDULED)
    })

    if (!items.length) {
      this.toaster.warning('No invoice need to be unscheduled.')
      return
    }
    if (selectedInvoices.length !== items.length) {
      this.toaster.warning(`${items.length} invoice(s) will be unscheduled.`)
    }

    const updated = await Promise.all(items.map(item => {
      const {account, invoice_id, attributes} = item
      const payload = {
        status: INVOICE_ACTUALIZED,
        attributes: omit(attributes, ['scheduled', 'scheduled_user', 'scheduled_date']),
      }
      return this.InvoiceApi.update(account, invoice_id, payload).then(r => r.data)
    }))
    .catch(err => {
      console.error('Unable to unscheduled invoices.', err)
      this.toaster.error('Unable to unscheduled invoices.', err)
      throw err
    })
    this.toaster.success('Invoices unscheduled successfully.')
    return updated
  }

  async showApproveSelectedInvoices(selectedInvoices: Invoice[]) {
    const scheduled = filter(selectedInvoices, {status: INVOICE_SCHEDULED})
    if (!scheduled.length) {
      this.toaster.warning('No invoice can be approved.')
      return
    }
    if (selectedInvoices.length !== scheduled.length) {
      this.toaster.warning(`${scheduled.length} invoice(s) will be approved.`)
    }

    await this.ConfirmModal.show({
      title: 'Approve Invoices',
      titleIcon: 'fas fa-exclamation-triangle',
      description: `You're about to approve ${selectedInvoices.length === 1 ? `invoice ${selectedInvoices[0].invoice_id}` : 'multiple invoices'}. Are you sure you want to do that?`,
      confirmButtonText: 'Approve',
      confirmButtonClass: 'btn-primary',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn-danger',
    })

    let approved = 0
    let failed = 0
    await Promise.all(scheduled.map(invoice =>
      this.Invoices.approveInvoice(invoice)
      .then(() => approved++ )
      .catch(() => failed++ )))

    if (!approved && failed) this.toaster.error(`Unable to approve ${failed} invoices`)
    else if (approved && failed) this.toaster.warning(`${scheduled.length} scheduled invoices were selected, ${approved} were approved and ${failed} failed to approve. Please wait (or check back later) to see all the invoices with updated status of Approved`)
    else if (approved && !failed) this.toaster.success(`${scheduled.length} scheduled invoices were selected, ${approved} were approved. Please wait (or check back later) to see all the invoices with updated status of Approved`)
  }

  async showUnapproveSelectedInvoices(selectedInvoices: Invoice[]) {
    const approved = filter(selectedInvoices, {status: INVOICE_APPROVED})
    if (!approved.length) {
      this.toaster.warning('Paid invoice can\'t be unapproved!')
      return
    }
    if (selectedInvoices.length !== approved.length) {
      this.toaster.warning(`${approved.length} invoice(s) will be unapproved.`)
    }

    await this.ConfirmModal.show({
      title: 'Unapprove Invoices',
      titleIcon: 'fas fa-exclamation-triangle',
      description: `You're about to unapprove ${selectedInvoices.length === 1 ? `invoice ${selectedInvoices[0].invoice_id}` : 'multiple invoices'}. Are you sure you want to do that?`,
      confirmButtonText: 'Unapprove',
      confirmButtonClass: 'btn-primary',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn-danger',
    })
    await Promise.all(approved.map(invoice => this.Invoices.unapproveInvoice(invoice)))
  }

  updateStatus = async (invoice: Invoice, status: Invoice['status'], dateKey: string, noConfirm?: boolean) => {
    if (!noConfirm) {
      await this.ConfirmModal.show({
        title: 'Warning',
        description: `Are you sure you want to update this invoice status to ${dateKey}?`,
      })
    }
    const {user_id} = this.AuthApi.currentUser
    const attributes = get(invoice, 'attributes', {})
    attributes[dateKey] = dayjs.utc().unix()
    attributes[`${dateKey}_user`] = user_id
    await this.InvoiceApi.update(invoice.account, invoice.invoice_id, {status, attributes})
    .catch((err) => {
      console.error('Unable to update invoice status.', err)
      this.toaster.error('Unable to update invoice status.', err)
      throw err
    })
    invoice.status = status
    this.toaster.success('Invoice Status updated successfully.')
  }

  showEscalateInvoice = async (invoice: DeepReadonly<Invoice>) => {
    await this.ConfirmModal.show({
      title: 'Warning',
      description: `Are you sure you want to escalate invoice #${invoice.invoice_id}?`,
    })
    await this.OperationsApi.escalateInvoice(invoice.invoice_id).toPromise()
    .catch((err) => {
      console.error(`Unable to escalate invoice ${invoice.invoice_id}`, err)
      this.toaster.error(`Unable to escalate invoice ${invoice.invoice_id}`, err)
      throw err
    })
    this.store.dispatch(escalateInvoiceSuccess({ dealId: invoice.deal_id, invoiceId: invoice.invoice_id }))
    this.toaster.success('Invoice escalated successfully.')
  }

  showEscalateInvoices = async (selected: DeepReadonly<Invoice[]>) => {
    if (!selected.length) return
    const invoices = selected.filter(invoice => canEscalateInvoice(invoice, this.AuthApi.currentUser))

    if (!invoices.length) {
      this.toaster.warning('Can not escalate selected invoices')
      return
    }
    if (selected.length !== invoices.length) {
      this.toaster.warning(`${invoices.length} invoice(s) will be escalated`)
    }

    await this.ConfirmModal.show({
      title: 'Warning',
      description: `Are you sure you want to escalate selected invoices?`,
    })
    await Promise.all(invoices.map(async invoice =>
      await this.OperationsApi.escalateInvoice(invoice.invoice_id).toPromise()
      .catch((err) => {
        console.error(`Unable to escalate invoice ${invoice.invoice_id}`, err)
        this.toaster.error(`Unable to escalate invoice ${invoice.invoice_id}`, err)
        throw err
      })))
    this.store.dispatch(escalateInvoicesSuccess({
      dealIds: invoices.map(i => i.deal_id),
      invoiceIds: invoices.map(i => i.invoice_id),
    }))
    this.toaster.success('Invoices escalated successfully.')
  }

  schedule = (invoice: Invoice) => {
    return this.modalHelper.showModal({
      component: 'tcInvoiceScheduleForm',
      windowClass: 'modalclone',
      resolve: {
        invoice: () => angular.copy(invoice),
      },
    }).then(async (payload = {}) => {
      const {account, invoice_id, attributes} = invoice
      return this.InvoiceApi.update(account, invoice_id, merge({}, {attributes}, payload))
                       .then(({data}) => {
                         merge(invoice, payload)
                         this.toaster.success('Invoice scheduled successfully')
                         return data
                       }, (err) => {
                         console.error('Unable to schedule invoice.', err)
                         this.toaster.error('Unable to schedule invoice.', err)
                         throw err
                       })
    })
  }

  unschedule = (invoice: Invoice) => {
    const {account, invoice_id, attributes} = invoice
    const payload = {
      status: INVOICE_ACTUALIZED,
      attributes: omit(attributes, ['scheduled', 'scheduled_user', 'scheduled_date']),
    }
    return this.InvoiceApi.update(account, invoice_id, payload).then(() => {
      extend(invoice, payload)
      this.toaster.success('Invoice unscheduled successfully')
    }, (err) => {
      console.error('Unable to unscheduled invoice.', err)
      this.toaster.error('Unable to unscheduled invoice.', err)
      throw err
    })
  }

  void = (item: Invoice) => {
    return this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to void this invoice',
    }).then(() => {
      return this.Invoices.voidInvoice(item).then(() => {
        this.toaster.success('Invoice voided successfully.')
        return item
      }, (err) => {
        console.error('Unable to void invoice.', err)
        this.toaster.error('Unable to void invoice.', err)
        throw err
      })
    })
  }

  createBankChargeForBuyerInvoice = (buyerInvoiceId: string) => {
    return this.ConfirmModal.show({
      title: `Bank Charge For ${buyerInvoiceId}`,
      description: 'Are you sure you want to create bank charge cost for this invoice?',
    }).then(() => {
      return this.OperationsApi.createBankCharge(buyerInvoiceId).toPromise().then(() => {
        this.toaster.success('Bank Charge created successfully.')
      }, () => {
        this.toaster.error('Unable to create Bank Charge, please try again later.')
      })
    })
  }

  undoBankChargeForBuyerInvoice = (buyerInvoiceId: string) => {
    return this.ConfirmModal.show({
      title: 'Undo Bank Charge',
      description: 'Are you sure you want to undo bank charge cost for this invoice?',
    }).then(() => {
      return this.OperationsApi.undoBankCharge(buyerInvoiceId).toPromise().then(() => {
        this.toaster.success('Bank Charge undo successfully.')
      }, () => {
        this.toaster.error('Unable to undo Bank Charge, please do it manually.')
      })
    })
  }

  showAddNoteForMultipleInvoices = async (invoices: Invoice[]) => {
    const { body, visibility } = await this.modalHelper.showModal({
      component: 'tcNoteForm',
      size: 'lg',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        onlyPayload: true,
        modalSetting: () => ({title: 'Add New Note'}),
        note: () => ({visibility: 1}),
      },
    })

    await Promise.all(map(invoices, invoice => this.Notes.createNote({
      body,
      visibility,
      deal_id: invoice.deal_id,
      attributes: {
        category: invoice.invoice_id,
        company: [invoice.account],
      },
    })))
    .catch((err) => {
      console.error('Unable to add note.', err)
      this.toaster.error('Unable to add note.', err)
      throw err
    })

    this.toaster.success('Note added successfully.')
  }

  showAddInfoToItem = (item: Invoice) => {
    return this.modalHelper.showModal({
      component: 'tcNoteForm',
      size: 'lg',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        modalSetting: () => {
          return {title: 'Leave a note for this invoice'}
        },
        note: () => set({ visibility: INTERNAL }, 'attributes.invoice_id', item.invoice_id),
      },
    }).then(() => {
      this.updateStatus(item, INVOICE_IN_REVIEW, 'escalated', true) // TODO: escalated?
    })
  }
}

function statusChangeTestGeneric(invoice: DeepReadonly<Invoice>, status: Invoice['status']) {
  const statusDef = AllInvoiceStatuses[invoice.status]
  return (statusDef && invoice.status !== status && statusDef.next.indexOf(status) > -1)
}
