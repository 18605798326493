import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { environment } from 'src/environments/environment'
import { MacropointOrder } from '@tradecafe/types/core'

const { apiUrl } = environment

@Injectable()
@AngularCopy()
export class MacropointApiService {
  constructor(private http: HttpClient) {}
  create(dealId) {
    return this.http.post<MacropointOrder>(`${apiUrl}/macropoint/orders`, {deal_id: dealId}).toPromise()
  }

  createInBatch(dealIds: string[]) {
    return this.http.post<void>(`${apiUrl}/macropoint/orders/batch`, {deal_ids: dealIds}).toPromise()
  }

  get(orderId: string): Promise<MacropointOrder> {
    return this.http.get<MacropointOrder>(`${apiUrl}/macropoint/orders/${orderId}`).toPromise()
  }

  update(resourceId: string) {
    return this.http.post<MacropointOrder>(`${apiUrl}/macropoint/orders/${resourceId}/refresh`, null).toPromise()
  }

  updateInBatch(identifiers: string[]) {
    return this.http.post<void>(`${apiUrl}/macropoint/orders/batch/refresh`, {identifiers}).toPromise()
  }

  generateHyperlink(resourceId: string) {
    return this.http.post<{ url: string }>(`${apiUrl}/macropoint/orders/${resourceId}/hyperlink`, null).toPromise()
  }

  stopOrder(resourceId: string) {
    return this.http.post<{ url: string }>(`${apiUrl}/macropoint/orders/${resourceId}/stop`, null).toPromise()
  }
}
