import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DocumentSet, DocumentTemplate, FileObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, uniq } from 'lodash-es'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

const { apiUrl } = environment

@Injectable()
export class DocumentSetApiService {
  constructor (private http: HttpClient) {}

  list() {
    return this.http.get<{ data: DocumentSet[] }>(`${apiUrl}/document-set`).toPromise()
  }

  create(data: DeepReadonly<Partial<DocumentSet>>) {
    return this.http.post<{ data: DocumentSet }>(`${apiUrl}/document-set`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: DocumentSet }>(`${apiUrl}/document-set/${id}`).toPromise()
  }

  update(id: string, data: DeepReadonly<Partial<DocumentSet>>) {
    return this.http.put<{ data: DocumentSet }>(`${apiUrl}/document-set/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: DocumentSet }>(`${apiUrl}/document-set/${id}`).toPromise()
  }

  addDoc(id: string, docId: string) {
    return this.http.put<{ data: DocumentSet }>(`${apiUrl}/document-set/${id}/docs/add`, {doc: docId}).toPromise()
  }

  removeDoc(id: string, docId: string) {
    return this.http.put<{ data: DocumentSet }>(`${apiUrl}/document-set/${id}/docs/remove`, {doc: docId}).toPromise()
  }

  getDocSet(query: {
    buyer_id: string,
    country_code: string,
    supplier_formula?: boolean,
    buyer_formula?: boolean,
    product_category_id?: string[],
  }) {
    return this.http.post<{ data: DocumentTemplate[] }>(`${apiUrl}/document-set/getDocSet`, query)
  }

  getDocTypesForDeal(query: { buyer_id: string, country_code: string }) {
    return this.getDocSet(query).pipe(map(r => uniq(compact(r.data.map(ds => ds.type)))))
  }

  generateDocumentMass(payload) {
    return this.http.post<{ data: FileObject }>(`${apiUrl}/document-generate-mass`, payload).toPromise()
  }

  generateMissingDocument(payload) {
    return this.http.post<void>(`${apiUrl}/document-generate-missing`, payload).toPromise()
  }

  generateConfirmationDocuments(payload) {
    return this.http.post<{ data: FileObject[] }>(`${apiUrl}/document-generate-confirmation`, payload).toPromise()
  }

  mergePdf(payload) {
    return this.http.post<{ data: FileObject }>(`${apiUrl}/document-merge-pdf`, payload).toPromise()
  }

  mergeDocuments({ file_ids, deal_ids }) {
    return this.http.post<{ data: FileObject[] }>(`${apiUrl}/merge-documents`, { file_ids, deal_ids }).toPromise()
  }
}
