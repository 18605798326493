import { Injectable } from '@angular/core'
import { Product } from '@tradecafe/types/core'
import { identity } from 'lodash-es'
import { filter } from 'rxjs/operators'
import { ModalService } from 'src/shared/modal/modal.service'
import { ProductFormComponent, ProductFormOptions } from './product-form.component'

@Injectable()
export class ProductFormService {

  constructor(
    private modal: ModalService,
  ) { }

  show(product: Product, options: ProductFormOptions) {
    return this.modal.openDialog<ProductFormComponent, ProductFormOptions, Partial<Product>>(ProductFormComponent, { product, ...options }, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
    }).pipe(filter(identity))
  }
}
