<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;{{ title }}
</h2>

<form [formGroup]="pForm" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
    <mat-form-field gdColumn="span 2">
      <mat-label>Problem</mat-label>
      <textarea
        matInput
        [formControl]="pForm.controls.problem"
        rows="5"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="inProgress$ | async"
    >
      <i
        class="fa fa-fw"
        aria-hidden="true"
        [class.fa-save]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
      ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
