import { MatDateFormats } from "@angular/material/core"
import { MtxDatetimeFormats } from "@ng-matero/extensions/core"

export const MAT_EPOCH_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'l',
  },
  display: {
    dateA11yLabel: 'LL',
    dateInput: 'l',
    monthYearA11yLabel: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
  },
}

export const MTX_EPOCH_DATETIME_FORMATS: MtxDatetimeFormats = {
  parse: {
    dateInput: 'l',
    datetimeInput: 'l LT',
    timeInput: 'LT',
    monthInput: 'MM',
    yearInput: 'YYYY',
  },
  display: {
    dateA11yLabel: 'LL',
    dateInput: 'l',
    datetimeInput: 'l LT',
    monthInput: 'MM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    timeInput: 'LT',
    yearInput: 'YYYY',
    popupHeaderDateLabel: 'MMM DD, ddd'
  },
}
