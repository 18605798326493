import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { DealViewRawBids, DealViewRawCosts, DealViewRawDeal, DealViewRawInvoices, DealViewRawOffers, DealViewRawSegments } from '@tradecafe/types/core'
import { DeepPartial, DeepReadonly, isDealSubmitted } from '@tradecafe/types/utils'
import { BehaviorSubject } from 'rxjs'
import { take } from 'rxjs/operators'
import { massDealForm, massDealFormFailure, massDealFormSuccess } from 'src/app/store/deal-view.actions'
import { selectAllItemTypes } from 'src/app/store/item-types'
import { selectAllLocations } from 'src/app/store/locations'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { DealFormDto } from '../deal-form.schema'

export interface ChangeCloneFormOptions {
    deals: DeepReadonly<Array<DealViewRawDeal & DealViewRawOffers & DealViewRawBids & DealViewRawCosts & DealViewRawSegments & DealViewRawInvoices>>
}

export type ChangeCloneFormResult = DeepPartial<DealFormDto>[]

@Component({
  selector: 'tc-change-clone-form',
  templateUrl: './change-clone-form.component.html',
  styleUrls: ['./change-clone-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeCloneFormComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: ChangeCloneFormOptions,
    private dialogRef: MatDialogRef<ChangeCloneFormComponent, ChangeCloneFormResult>,
    private store: Store,
    private actions$: Actions,
  ) {}

  // static / config
  deals = this.dialogData.deals

  // internal state
  inProgress$ = new BehaviorSubject(false)
  allVisible$ = new BehaviorSubject<Record<string, boolean>>({})

  // editable form
  form = new FormGroup({
    category_id: new FormControl<string>(undefined),
    type_id: new FormControl<string>(undefined),
    product_id: new FormControl<string>(undefined, Validators.required),
    item_type_id: new FormControl<string>(undefined, Validators.required),
    pickup_location_id: new FormControl<string>(undefined, Validators.required),
    destination_location_id: new FormControl<string>(undefined, Validators.required),
  })

  // ref data
  locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())

  // tslint:disable-next-line: cyclomatic-complexity
  ngOnInit() {
    const productId = this.getCommonValue(this.deals.map(dv => dv.bids?.[0]?.product));
    const itemTypeId = this.getCommonValue(this.deals.map(dv => dv.bids?.[0]?.attributes?.item_type_id));
    const destinationLocationId = this.getCommonValue(this.deals.map(dv => dv.deal.dest_location));
    const originLocationId = this.getCommonValue(this.deals.map(dv => dv.deal.origin_location));

    this.form.controls.product_id.setValue(productId);
    this.form.controls.item_type_id.setValue(itemTypeId);
    this.form.controls.pickup_location_id.setValue(originLocationId)
    this.form.controls.destination_location_id.setValue(destinationLocationId);

    if(this.deals.find(dv => dv.bids?.length > 1)) {
      this.form.disable();
    } else if(this.deals.find(dv => isDealSubmitted(dv.deal))) {
      this.form.controls.category_id.disable();
      this.form.controls.type_id.disable();
      this.form.controls.product_id.disable();
      this.form.controls.item_type_id.disable();
    }
  }

  save() {
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()

    if (!this.form.valid) {
      return;
    }

    this.inProgress$.next(true)

    const changes = this.deals.map(dv => {
      const patch: DeepPartial<DealFormDto> = {
        details: {
          deal_id: dv.deal.deal_id,
          originLocationId: this.form.controls.pickup_location_id.value,
          destLocationId: this.form.controls.destination_location_id.value,
        }
      };

      const bid = dv.bids[0];
      patch.products = [
        {
          bid_id: bid.bid_id,
          offer_id: bid.offer,
          productId: this.form.controls.product_id.value,
          itemTypeId: this.form.controls.item_type_id.value,
          buyerIncotermLocationId: this.form.controls.destination_location_id.value,
          supplierIncotermLocationId: this.form.controls.pickup_location_id.value,
        }
      ]

      return { dv, patch };
    });

    this.store.dispatch(massDealForm({ changes }))

    const result = changes.map(c => c.patch)
    this.actions$.pipe(ofType(massDealFormSuccess, massDealFormFailure), take(1)).subscribe((action) => {
      if (action.type === massDealFormSuccess.type) this.dialogRef.close(result)
      this.inProgress$.next(false)
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  private getCommonValue(arr: any[]) {
    if(!arr?.length || !this.allEqual(arr)) {
      return null;
    }

    return arr[0];
  }

  private allEqual(arr: any[]) {
    return arr?.every( v => v === arr[0]);
  }
}
