import { INVOICE_ACTUALIZED, INVOICE_APPROVED, INVOICE_IN_REVIEW, INVOICE_NEEDS_INFO, INVOICE_SCHEDULED, TableKey } from '@tradecafe/types/core'
import { mapValues, uniq } from 'lodash-es'
import { FilterDef } from '../../deals-list/deals-list.filters'

export const INVOICES_FILTER_SETTINGS: Dictionary<FilterDef> = {
  issued: { useUtc: true },
  created: { useUtc: false },
  actl_pmt_date: { useUtc: true },
  sched_pmt_date: { useUtc: true },
  due_date: { useUtc: true },
  actualized: { useUtc: true }, // TODO: WA-8189: not editable, should not be utc
}

const DEFAULT_FILTERS = {
  // full text search fields
  text: '',
  deal_id: '',
  type: [],

  // table internal filters
  sort: { id: 'created', start: 'desc' as 'asc'|'desc' },
  limit: 10,
  columns: [],

  // selected table view id
  table_view_id: null,

  // deal view filters (GET /deals/search)
  invoice_id: [],
  due_date_from: null,
  due_date_to: null,
  status: [],
  currency: [],
  issued_from: null,
  issued_to: null,
  created_from: null,
  created_to: null,
  deal_status: [],
  deal_type: [],
  brokerage_trader: [],
  buying_trader: [],
  selling_trader: [],
  coordinator: [],
  company: [],
  note_warning: [],
  supplier: [],
  buyer: [],
  variance: [],
  est_amount: [],
  net_payable: [],
  rmng_amount: [],
  vendor_invoice_id: [],
  user: [],
  deal_ids: [],
  // sent: [],
  // received: [],
  partial_margin: [],
  partial_margin_p: [],
  actl_amount: [],
  actl_amount_cad: [],
  // name: [],
  product_category: [],
  product_type: [],
  actl_pmt_date_from: null,
  actl_pmt_date_to: null,
  sched_pmt_date_from: null,
  sched_pmt_date_to: null,
  sum_pmts_applied: [],
  payment_type: [],
  company_type: [],
  batch_id: [],
  product: [],
  cost_names: [],
  actualized_from: null,
  actualized_to: null,
  approved_by: [],
  buyer_acc: [],
  inv: [],
  internal: [],
}

const buyerInvoicesFilters = {
  ...DEFAULT_FILTERS,
  type: ['receivable', 'brokerage'],
}
const vendorInvoicesFilters = {
  ...DEFAULT_FILTERS,
  type: ['payable'],
  status: [
    INVOICE_IN_REVIEW,
    INVOICE_APPROVED,
    INVOICE_NEEDS_INFO,
    INVOICE_ACTUALIZED,
    INVOICE_SCHEDULED,
  ],
}
export const DEFAULT_INVOICES_FILTERS: Partial<Record<TableKey, typeof DEFAULT_FILTERS>> = {
  [TableKey.TradingBuyerInvoices]: buyerInvoicesFilters,
  [TableKey.LogisticsBuyerInvoices]: buyerInvoicesFilters,
  [TableKey.FinancialBuyerInvoices]: buyerInvoicesFilters,
  [TableKey.ManagementBuyerInvoices]: buyerInvoicesFilters,
  [TableKey.TradingVendorInvoices]: vendorInvoicesFilters,
  [TableKey.LogisticsVendorInvoices]: vendorInvoicesFilters,
  [TableKey.FinancialPayables]: vendorInvoicesFilters,
  [TableKey.ManagementVendorInvoices]: {
    ...vendorInvoicesFilters,
    status: [INVOICE_SCHEDULED],
  },
}

// filters placed at the top of the deals list page
export const TOP_LEVEL_FILTERS = {
  [TableKey.TradingBuyerInvoices]: [
    'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'status',
    'currency',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'brokerage_trader',
    'issued',
    'created',
    'deal_status',
  ],
  [TableKey.TradingVendorInvoices]: [
    // 'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'cost_names',
    'status',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'created',
    'company_type',
    'user',
  ],
  [TableKey.LogisticsBuyerInvoices]: [
    'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'status',
    'currency',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'brokerage_trader',
    'issued',
    'created',
    'deal_status',
  ],
  [TableKey.LogisticsVendorInvoices]: [
    // 'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'cost_names',
    'status',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'created',
    'company_type',
    'user',
  ],
  [TableKey.FinancialBuyerInvoices]: [
    'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'status',
    'currency',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'brokerage_trader',
    'issued',
    'created',
    'deal_status',
    'internal'
  ],
  [TableKey.FinancialPayables]: [
    // 'type',
    'text',
    'company',
    'status',
    'deal_id',
    'internal',
    'currency',
    'selling_trader',
    'buying_trader',
    'coordinator',
    'company_type',
    'issued',
    'due_date',
    'cost_names',
    'created',
    'actualized',
    'payment_type',
    'batch_id',
    'sched_pmt_date',
  ],
  [TableKey.ManagementBuyerInvoices]: [
    'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'status',
    'currency',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'brokerage_trader',
    'issued',
    'created',
    'deal_status',
    'internal'
  ],
  [TableKey.ManagementVendorInvoices]: [
    // 'type',
    'text',
    'deal_id',
    'due_date',
    'company',
    'status',
    'currency',
    'coordinator',
    'selling_trader',
    'buying_trader',
    'approved_by',
    'created',
    'company_type',
    'payment_type',
    'batch_id',
    'internal',
  ],
}


export const TOP_LEVEL_FILTERS_COLUMNS = mapValues(TOP_LEVEL_FILTERS, (filters, tableKey) =>
  uniq(filters.map(filter => filter.replace(/_to$/, '').replace(/_from$/, ''))))
