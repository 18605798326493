import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { Carrier, Deal, DealViewRawDeal, ForexData, LocationObject, SegmentType } from '@tradecafe/types/core'
import { DeepReadonly, isDealPortalVisible } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { Observable, combineLatest, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { selectCarrierEntities } from 'src/app/store/carriers'
import { selectLocationEntities } from 'src/app/store/locations'
import { FxRatesService } from 'src/pages/admin/financial/fx-rates/fx-rates.service'
import { SegmentFormGroup, SegmentsFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { waitNotEmpty } from 'src/services/data/utils'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { replayForm } from 'src/shared/utils/replay-form'
import { NotesOverlayService } from '../notes/notes-overlay/notes-overlay.service'
import { SEGMENT_COLUMN_NAMES } from './segments-list.columns'


interface SegmentRow {
  segmentForm: SegmentFormGroup
  type: SegmentType
  carrierName: string
  isPortalVisible: boolean
  originName: string
  destinationName: string
  amountCAD: number
  cutoffDate: number
  loadDate: number
  loadTime: boolean
  dropoffDate: number
  dropoffTime: boolean
}


@Component({
  selector: 'tc-segments-list-v2',
  templateUrl: './segments-list.component.html',
  styleUrls: ['./segments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentsListComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private FxRates: FxRatesService,
    private NotesOverlay: NotesOverlayService,
    private store: Store,
  ) { super() }

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator }

  @ViewChild(MatSort)
  set sort(sort: MatSort) { this.dataSource.sort = sort }

  @HostBinding('class')
  class = 'tc-segments-list'

  // table config
  @Input() columnNames: Dictionary<string> = SEGMENT_COLUMN_NAMES
  @Input() displayColumns = [
    'type',
    'carrierName',
    'originName',
    'destinationName',
    'amountCAD',
    'ellipsisMenu',
  ]

  @Input() dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal>>
  // segments and rates (dirty, from main form)
  @Input() segmentsForm: SegmentsFormGroup
  @Input() fxRates: ForexData
  @Input() readonly = false
  @Input() selectAll = true

  // dynamic data
  dataSource = new MatTableDataSource<SegmentRow>()
  isLogisticsView: boolean = window.location.pathname.indexOf('shipping-log/') !== -1

  // referenced data
  private locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty())
  private carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty())

  // output events
  @Output() updateSegment = new EventEmitter<SegmentFormGroup>()
  @Output() sendNotification = new EventEmitter<SegmentFormGroup>()
  @Output() removeSegment = new EventEmitter<SegmentFormGroup>()
  @Output() rowClick = new EventEmitter<{segment: SegmentRow, column?: string, tabName?: string}>()
  @Output() selectionChange = new EventEmitter<SegmentRow[]>()
  @Output() createCostFromSegment = new EventEmitter<SegmentFormGroup>()

  public selection = new TableSelection<SegmentRow>('segment_id')


  ngOnInit() {
    // TODO: move subscription to deal-form
    // TODO: use scroll & highlight strategy at the shipping log details page
    this.rowClick.pipe(untilComponentDestroyed(this)).subscribe(({ segment }) => {
      this.showUpdateItem(segment)
    })
    combineLatest([
      replayForm(this.segmentsForm),
      this.carriers$,
      this.locations$,
      (this.dealViewRaw$ || of(undefined)).pipe(map(dv => dv?.deal)),
    ]).pipe(untilComponentDestroyed(this)).subscribe(([_segments, carriers, locations, deal]) => {
      this.setGridData(deal, carriers, locations)
    })

    this.selection.selectedIds$.pipe(untilComponentDestroyed(this)).subscribe(ids => {
      const selectedRows = this.dataSource.data.filter(row => row
        && row.segmentForm.controls.segment.value?.segment_id
        && ids.includes(row.segmentForm.controls.segment.value.segment_id))
      this.selectionChange.emit(selectedRows)
    })
  }

  getRowId(_i: number, segment: SegmentRow) {
    // undefined segment = fake. loading screen.
    return segment?.segmentForm?.value.segment?.segment_id || _i
  }

  showUpdateItem(row: SegmentRow) {
    this.updateSegment.next(row.segmentForm)
  }

  sendSegmentNotification(row: SegmentRow) {
    this.sendNotification.next(row.segmentForm)
  }

  showDeleteItem(row: SegmentRow) {
    this.removeSegment.next(row.segmentForm)
  }

  showSegmentNotes(row: SegmentRow) {
    const { carrierId: carrier_id, segment: { segment_id }} = row.segmentForm.getRawValue()
    this.dealViewRaw$?.pipe(take(1), map(dv => dv?.deal?.deal_id)).subscribe(dealId =>
      this.NotesOverlay.showSegmentNotes(dealId, { carrier_id, segment_id }).subscribe())
  }

  onListDrop(event: CdkDragDrop<any>) {
    moveItemInArray(this.segmentsForm.controls, event.previousIndex, event.currentIndex)
    this.segmentsForm.controls.forEach((segmentForm, i) => {
      // NOTE: +1 because Go doesn't like zeroes
      if (segmentForm.controls.order.value !== i + 1) {
        segmentForm.controls.order.setValue(i + 1)
        segmentForm.controls.order.markAsDirty()
      }
    })
  }

  private setGridData(
    deal: DeepReadonly<Deal>,
    carriers: DeepReadonly<Dictionary<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
  ) {
    const rows: SegmentRow[] = this.segmentsForm.controls.map((segmentForm: SegmentFormGroup) => ({
      segmentForm,
      segment_id: segmentForm.value.segment?.segment_id,
      type: segmentForm.value.type,
      carrierName: carriers[segmentForm.value.carrierId]?.name,
      isPortalVisible: isDealPortalVisible(deal, carriers[segmentForm.value.carrierId]?.account),
      originName: locations[segmentForm.value.originId]?.name,
      destinationName: locations[segmentForm.value.destinationId]?.name,
      amountCAD: (segmentForm.value.amount && this.fxRates)
        ? this.FxRates.toCADSync(segmentForm.value.amount, segmentForm.value.currencyCode, 'ask', this.fxRates.rates, 'spot')
        : undefined,
      cutoffDate: segmentForm.value.cutoffDatetime,
      loadDate: segmentForm.value.actualPickupDate,
      loadTime: segmentForm.value.actualPickupDateTime,
      dropoffDate: segmentForm.value.actualDeliveryDate,
      dropoffTime: segmentForm.value.actualDeliveryDateTime,
    }))

    this.dataSource.data = rows
  }
}
