import { createSelector } from '@ngrx/store'
import { Product } from '@tradecafe/types/core'
import { compact } from 'lodash-es'
import { selectProductCategoryEntities } from '../product-categories'
import { selectProductTypeEntities } from '../product-types'
import { State } from '../reducers'
import { adapter, productsFeatureKey } from './product.reducer'

export const selectState = (state: State) => state[productsFeatureKey]

export const { selectEntities: selectProductEntities, selectAll: selectAllProducts } = adapter.getSelectors(selectState)

export const selectProduct = createSelector(
  selectProductEntities,
  (products: Dictionary<Product>, product: string | number) =>
    products[product])

export const selectProductsById = createSelector(
  selectProductEntities,
  (products: Dictionary<Product>, productId: string) =>
    products[productId])

export const selectProductsByIds = createSelector(
  selectProductEntities,
  (products: Dictionary<Product>, productIds: string[]) =>
    productIds.map(productId => products[productId]))

export const selectProteinProductOptions = (selectedProductId?: string | string[]) =>
  createSelector(
    selectProductCategoryEntities,
    selectProductTypeEntities,
    selectAllProducts,
    (categories, types, products) =>
      products?.filter(p =>
        categories?.[p.category_id]?.type === 'protein' ||
        (Array.isArray(selectedProductId)
          ? selectedProductId.includes(p.product_id)
          : selectedProductId === p.product_id)
      ).map(product => ({
        ...product,
        hint: `${compact([categories?.[product.category_id]?.name, types?.[product.type_id]?.name]).join(' / ')}`,
      }))
  )

export const selectNonProteinProducts = createSelector(
  selectProductCategoryEntities,
  selectAllProducts,
  (categories, products) => products.filter(p => categories[p.category_id]?.type !== 'protein')
)
