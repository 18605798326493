<h2 mat-dialog-title>
  <mat-icon class="fa fa-address-book"></mat-icon>
  {{options.title}}
</h2>
<div mat-dialog-content>
  <div gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
    <tc-select-search placeholder="Company" [ctrl]="companyCtrl"
        [items]="accounts$ | async"
        bindLabel="name"
        bindValue="account"
        ></tc-select-search>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput [formControl]="searchCtrl">
      <button *ngIf="searchCtrl.value"
          mat-icon-button type="button" class="tc-text-clear"
          (click)="searchCtrl.setValue('')">
        <mat-icon class="fa fa-times"></mat-icon>
      </button>
    </mat-form-field>
  </div>
  <tc-address-list #addressList
      [dataSource]="dataSource"
      [selected]="preselected"
      [multiple]="options.allowMultiple"
      [showEstablishment]="options.showEstablishment"
      (rowClick)="showEditAddress($event)"
      ></tc-address-list>
  <p class="text-right">
    <button mat-button type="button"
            [disabled]="!(account$ | async)"
            (click)="showAddAddress()">+ add new</button>
  </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="cancel()">Cancel</button>
  <button mat-raised-button type="button" color="primary" (click)="select(addressList.selection.selected$.value)">Select</button>
</div>
