import { Injectable } from '@angular/core'
import { Deal, DealBase, DealPartyE, DealViewBase, DEAL_DRAFT, INTERNAL, Note } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, filter, find, map, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { NegativeMarginReasonFormService } from 'src/pages/admin/trading/deal-form/negative-margin-reason/negative-margin-reason.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { AccountsService } from '../data/accounts.service'
import { CreditPoolService } from '../data/credit-pool.service'
import { NotesService } from '../data/notes.service'
import { ToastedError } from '../data/utils'

@Injectable()
export class DealValidationService {
  constructor (
    private toaster: ToasterService,
    private AuthApi: AuthApiService,
    private CreditPool: CreditPoolService,
    private NegativeMarginReasonForm: NegativeMarginReasonFormService,
    private Notes: NotesService,
    private Accounts: AccountsService,
  ) { }

  /**
   * Make sure all deals have the same party (buyer or supplier). Return party id
   *
   * @param {*} deals
   * @param {*} party 'buyer' or 'supplier'
   * @returns {number} party account id
   */
  ensureOneParty(deals: DeepReadonly<Array<Deal|DealViewBase>>, party: DealPartyE) {
    const accountIds = compact(uniq(map(deals, party === DealPartyE.brokerage_customer ? 'brokerage.customer' : `${party}_id`)))
    if (accountIds.length !== 1) {
      this.toaster.warning(`All the deals should have the same ${party}`)
      throw new ToastedError(`All the deals should have the same ${party}`)
    }
    return parseFloat(accountIds[0] as string)
  }

  /**
   * Check if buyer credit limit is below deals total amount
   *
   * @param {*} dealOrDeals
   * @param {*} notApprovedMessage
   */
  async checkBuyerCredit(
    dealOrDeals: DeepReadonly<Pick<Deal | DealViewBase, 'buyer_id' | 'deal_id'>> | DeepReadonly<Pick<Deal | DealViewBase, 'buyer_id' | 'deal_id'>[]>,
    notApprovedMessage: string,
    limitType = 'creation',
  ) {
    const approved = await this.CreditPool.creditCheck(dealOrDeals, limitType)
      .catch((err) => {
        console.error('Unable to check credit', err)
        this.toaster.error('Unable to check credit', err)
        throw err
      })
    if (!approved) {
      this.toaster.error(notApprovedMessage)
      throw new ToastedError(notApprovedMessage)
    }
  }

  /**
   * Check deals for negative margin. Ask user for the reason and create a note.
   * User might select checkbox "Same reason for all deals"
   *
   * @private
   * @param {*} deals
   * @throws an exception if user clcked cancel or something wrong happened while uploading a note
   */
  async askForNegativeMarginReason(deals: DeepReadonly<DealBase[]>) {
    // @ts-ignore
    deals = filter(deals, (deal) =>  deal.status === DEAL_DRAFT && !this.Accounts.isBwiInventory(deal.buyer_id) )
    if (!deals.length) return // no draft deals without BWI inventory buyer, no need to ask

    // check if there are deals with negative margin
    deals = deals.filter(deal => deal.attributes.estimated.margin < 0)
    if (!deals.length) return // no negative deals, everything is ok

    // check if someone left a "negative margin" note
    const notes = await this.Notes.getForDeals(deals.map(deal => deal.deal_id))
    deals = deals.filter(deal => !find(notes[deal.deal_id], { attributes: { category: 'negative-margin' }}))
    if (!deals.length) return // all negative notes are in place

    // const {user_id, account} = this.AuthApi.currentUser
    const dealsList = [...deals]
    for (let deal; deal = dealsList.shift();) {
      const otherDealIds = map(deals, 'deal_id')
      // ask user what's the reason for negative margin and if he wants to reuse reason
      const {reason, applyToAll} = await this.NegativeMarginReasonForm.show({ otherDealIds, deal })
      const { account, user_id } = this.AuthApi.currentUser
      const note: Partial<Note> = {
        body: reason.description,
        visibility: INTERNAL,
        attributes: {
          category: 'negative-margin',
          account, // ?
          user_id, // ?
        },
      }
      // send note(s) to the backend
      const dealIds = applyToAll ? [deal.deal_id, ...otherDealIds] : [deal.deal_id]
      await Promise.all(dealIds.map(deal_id =>
        this.Notes.createNote({...note, deal_id})
        .catch((err) => {
          this.toaster.error(`Unable to create negative margin note for ${deal_id}`, err)
          console.error(`Unable to create negative margin note for ${deal_id}`, err)
          throw err
        })))
      if (applyToAll) break
    }
  }
}
