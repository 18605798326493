import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Carrier } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as CarrierActions from './carrier.actions'

export const carriersFeatureKey = 'carriers'
export interface CarriersState extends EntityState<DeepReadonly<Carrier>> {
}

export const selectId = (carrier: Carrier) => carrier.carrier_id
export const adapter = createEntityAdapter<DeepReadonly<Carrier>>({ selectId })

const initialState: CarriersState = adapter.getInitialState({
})

export const carriersReducer = createReducer(
  initialState,
  on(CarrierActions.loadCarriersSuccess,
    (state, action) =>
      adapter.upsertMany(action.carriers, state)),
)

