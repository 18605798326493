import { CurrencyPipe, formatNumber } from '@angular/common'
import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms'
import { Store, select } from '@ngrx/store'
import { Cost, Deal, DealProduct, DealProductBatch, DealViewField, DealViewRaw, DealViewRawDeal, DealViewRawInvoices, DealViewRawStatus, DealViewStatus, IEpochRange, Invoice, Segment } from '@tradecafe/types/core'
import { DeepReadonly, findBuyerInvoice, isCostActualizedToZero, isDealConfirmed, isDealLocked, isDealSubmitted, printPaymentTerms } from '@tradecafe/types/utils'
import { cloneDeep, compact, forEach, isEmpty, isEqual, pick, uniq } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs'
import { distinctUntilChanged, map, mapTo, switchMap, take, tap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadProducts, selectProductEntities } from 'src/app/store/products'
import { environment } from 'src/environments/environment'
import { isBwiInventory } from 'src/services/data/accounts.service'
import { actualizeToZeroPatch, unactualizeToZeroPatch } from 'src/services/data/costs.service'
import { CustomCostsService } from 'src/services/data/custom-costs.service'
import { DealFormCalculatorService, getAntDueDate } from 'src/services/data/deal-form-calculator.service'
import { canChangeParty, canEditDealFormField } from 'src/services/data/deal-view-permissions.service'
import { buildDealProducts } from 'src/services/data/deal-view.service'
import { DealsService } from 'src/services/data/deals.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { dayjs } from 'src/services/dayjs'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { disableIf } from 'src/shared/utils/disable-if'
import { replayForm } from 'src/shared/utils/replay-form'
import { CostFormValue, CostsFormGroup, DealDetailsFormDto, DealDetailsFormGroup, DealDetailsFormValue, DealFormDto, DealFormGroup, DealFormValue, DealProductBatchFormGroup, DealProductBatchFormValue, DealProductFormDto, DealProductFormGroup, DealProductFormValue, DealProductsFormGroup, SegmentFormDto, SegmentFormGroup, SegmentFormValue, SegmentsFormGroup } from './deal-form.schema'
import { buildDealCostForm, buildSegmentForm, prepareDealCostPatch, prepareDealSegmentPatch } from './deal-form.service-factory'

const dealDetailsFormConfig: Record<keyof DealDetailsFormValue, [] | [unknown, ValidatorFn | ValidatorFn[]]> = {
  actualInvoiceDate: [],
  actualTotals: [, Validators.required],
  antLiabilityDate: [, Validators.required],
  brokerageEstAmount: [, [Validators.required, Validators.min(0)]],
  brokerageActAmount: [, [Validators.required, Validators.min(0)]],
  brokerageCollectionDate: [, Validators.required],
  brokerageCurrency: [, Validators.required],
  brokerageCustomer: [, Validators.required],
  brokeragePaymentTerms: [, Validators.required],
  brokerageSendBuyerConfirmation: [],
  brokerageSendSupplierConfirmation: [],
  brokerageTermDate: [, Validators.required],
  brokerageTraderId: [, Validators.required],
  brokerageContactUserIds: [, Validators.required],
  buyerConfirmedAt: [], // disabled = !deal.deal_id
  buyerConfirmedBy: [], // hidden
  buyerCurrencyCode: [, Validators.required], // disabled = $index !== 0 || !canEdit(deal, 'buyerCurrencyCode')
  buyerFormula: [],
  buyerId: [, Validators.required], // disabled = !canChangeParty('buyer')
  buyerPaymentTerms: [, Validators.required],
  buyerRef: [],
  buyerTermDate: [, Validators.required],
  buyerTraderId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
  buyerUserIds: [[], Validators.required], // disabled = !tradersCantEditLockedDeal()
  collectionDate: [, Validators.required],
  color: [],
  copyOf: [], // always disabled
  date: [, Validators.required],
  deal: [, Validators.required],
  dealType: [],
  deliveryDatesFrom: [, Validators.required],
  deliveryDatesTbd: [],
  deliveryDatesTo: [, Validators.required],
  destLocationId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
  docsCountryCode: [], // disabled = !tradersCantEditLockedDeal()
  estFinanceTerm: [/* , Validators.required */], // TODO: required for deals created with 1.21+
  estimatedTotals: [, Validators.required],
  estimateId: [], // always disabled
  financeTerm: [, Validators.required],
  fxRates: [, Validators.required], // hidden
  fxRatesAskRange: [, Validators.required], // hidden
  fxRatesBidRange: [, Validators.required], // hidden
  fxRatesTimestamp: [/* , Validators.required */], // hidden // TODO: fxRatesTimestamp must be mandatory, but we have lots of deals w/o this field in production
  inalfresco: [],
  letterOfCreditNo: [],
  logisticsUserId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
  mexicanInvoiceNo: [],
  originCountryCode: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
  originLocationId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
  prepaymentMade: [],
  prepaymentReceived: [],
  proformaNeeded: [], // disabled = deal.attributes.docs_country === 'CO'
  scheduledDeliveryDate: [],
  scheduledPickupDate: [],
  shipmentAes: [],
  shipmentB13: [],
  shipmentBolDate: [],
  shipmentBorderCross: [],
  shipmentConsignee: [],
  shipmentDatesFrom: [, Validators.required],
  shipmentDatesTbd: [],
  shipmentDatesTo: [, Validators.required],
  shipmentEpd: [],
  shipmentErd: [],
  shipmentImportNo: [],
  shipmentNotifyParty: [],
  shipmentPaps: [],
  shipmentShipper: [],
  shipmentType: [],
  shipmentVoyageNo: [],
  supplierConfirmedAt: [], // disabled = !deal.deal_id
  supplierConfirmedBy: [], // hidden
  supplierCurrencyCode: [, Validators.required], // disabled = $index !== 0 || !canEdit(deal, 'supplierCurrencyCode')
  supplierFormula: [],
  supplierId: [, Validators.required], // disabled = !canChangeParty('supplier')
  supplierPaymentTerms: [, Validators.required],
  supplierRef: [],
  supplierTermDate: [, Validators.required],
  supplierTraderId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
  supplierUserIds: [[], Validators.required], // disabled = !tradersCantEditLockedDeal()
  temperatureUnits: [, Validators.required],
  useCorpUsAddress: [],
  woExportDocs: [],
}


@Injectable()
export class DealFormService {
  constructor(
    private fb: UntypedFormBuilder,
    private AuthApi: AuthApiService,
    private CustomCosts: CustomCostsService,
    @Inject(LOCALE_ID) private locale: string,
    private currencyPipe: CurrencyPipe,
    private Deals: DealsService,
    private DealFormCalculator: DealFormCalculatorService,
    private toaster: ToasterService,
    private store: Store,
  ) { }

  load(dealId: string, dealForm = this.build(), fields?: DealViewField[]) {
    this.store.dispatch(loadProducts({}))
    return combineLatest([
      this.Deals.getDealView(dealId, fields),
      this.store.pipe(select(selectProductEntities), waitNotEmpty(), take(1)),
    ]).pipe(switchMap(([dealViewRaw, products]) => {
      const dealProducts = buildDealProducts(dealViewRaw, products)
      const result = { dealForm, dealViewRaw, dealProducts, products }
      this.patchForm(dealForm, dealViewRaw, dealProducts)
      this.enableDisableDealForm(dealForm, dealViewRaw)
      return this.calculateDeal(dealForm, dealViewRaw.invoices).pipe(mapTo(result))
    }))
  }

  calculateDeal(dealForm: DealFormGroup, invoices: DeepReadonly<Invoice[]>) {
    const snapshot = cloneDeep(pick(dealForm.controls.details.getRawValue(), ['actualTotals', 'estimatedTotals']))
    return this.DealFormCalculator.doCalculationsForm(dealForm, invoices).pipe(tap(() => {
      const postupdate = cloneDeep(pick(dealForm.controls.details.getRawValue(), ['actualTotals', 'estimatedTotals']))
      const financeIssues = detectFinanceIssues(snapshot, postupdate)
      if (financeIssues) {
        dealForm.controls.details.financeIssues$.next(financeIssues)
      }
    }))
  }

  build() {
    const details = this.buildDetailsForm()
    const products = this.fb.array([this.buildDealProductForm()], [Validators.required]) as DealProductsFormGroup
    const costs = this.fb.array([], [Validators.required]) as CostsFormGroup
    const segments = this.fb.array([]) as SegmentsFormGroup
    const groupConfig: Record<keyof DealFormValue, AbstractControl> = { details, products, costs, segments }
    const group = this.fb.group(groupConfig) as DealFormGroup
    const self = this
    group.serialize = function() { return self.serializeDealForm(this) }
    return group
  }

  buildDetailsForm(fields?: Array<keyof DealDetailsFormValue>) {
    const detailsForm = this.fb.group(fields ? pick(dealDetailsFormConfig, fields) : dealDetailsFormConfig) as DealDetailsFormGroup
    const detailsForm$ = replayForm(detailsForm)
    detailsForm.partialMarginCad$ = detailsForm$.pipe(
      map(({ actualTotals }) => isDealInInventory() ? 0 : actualTotals.partial_margin),
      map(v => v ? this.currencyPipe.transform(v, 'CAD', 'symbol-narrow') : '—'),
      distinctUntilChanged())
    detailsForm.partialMarginP$ = detailsForm$.pipe(
      map(({ actualTotals }) => isDealInInventory() ? 0 : actualTotals.partial_margin_p),
      map(v => v ? formatNumber(v * 100, this.locale, '1.2-2') + '%' : '—'),
      distinctUntilChanged())
    detailsForm.partialMarginPN$ = detailsForm$.pipe(
      map(({ actualTotals }) => isDealInInventory() ? 0 : actualTotals.partial_margin_p),
      map(v => v ? formatNumber(v * 100, this.locale, '1.2-2') : '—'),
      distinctUntilChanged())
    detailsForm.partialRevenueCad$ = detailsForm$.pipe(
      map(({ dealType, actualTotals, estimatedTotals }) =>
        environment.enableBrokerageDeals && dealType === 'brokerage'
          ? actualTotals.brokerage_revenue || estimatedTotals.brokerage_revenue
          : (isDealInInventory() ? 0 : actualTotals.revenue || estimatedTotals.revenue)),
      map(v => v ? this.currencyPipe.transform(v, 'CAD', 'symbol-narrow') : '—'),
      distinctUntilChanged())

    detailsForm.usingBidRates$ = detailsForm$.pipe(
      map(({ fxRatesBidRange, fxRatesTimestamp }) =>
        `Using ${fxRatesBidRange} rates from\n${dayjs.unix(fxRatesTimestamp).format('L LT')}`),
      distinctUntilChanged())
    detailsForm.usingAskRates$ = detailsForm$.pipe(
      map(({ fxRatesAskRange, fxRatesTimestamp }) =>
        `Using ${fxRatesAskRange} rates from\n${dayjs.unix(fxRatesTimestamp).format('L LT')}`),
      distinctUntilChanged())
    detailsForm.bidRate$ = detailsForm$.pipe(
      map(({ buyerCurrencyCode, fxRates, fxRatesBidRange }) =>
        `[${buyerCurrencyCode}/CAD] ${fxRates.rates[buyerCurrencyCode]?.[fxRatesBidRange].bid || ''} BID`),
      distinctUntilChanged())
    detailsForm.askRate$ = detailsForm$.pipe(
      map(({ supplierCurrencyCode, fxRates, fxRatesAskRange }) =>
        `[${supplierCurrencyCode}/CAD] ${fxRates.rates[supplierCurrencyCode]?.[fxRatesAskRange].ask || ''} ASK`),
      distinctUntilChanged())

    detailsForm.supplierPaymentTerms$ = detailsForm$.pipe(
      map(({supplierPaymentTerms}) => printPaymentTerms(supplierPaymentTerms)),
      distinctUntilChanged())
    detailsForm.buyerPaymentTerms$ = detailsForm$.pipe(
      map(({buyerPaymentTerms}) => printPaymentTerms(buyerPaymentTerms)),
      distinctUntilChanged())
    detailsForm.brokeragePaymentTerms$ = detailsForm$.pipe(
      map(({brokeragePaymentTerms}) => printPaymentTerms(brokeragePaymentTerms, true)),
      distinctUntilChanged())
    detailsForm.antDueDate$ = detailsForm$.pipe(
      map(({ buyerPaymentTerms, buyerTermDate }) => getAntDueDate(buyerPaymentTerms, buyerTermDate)),
      distinctUntilChanged())
    detailsForm.brokerageAntDueDate$ = detailsForm$.pipe(
      map(({ brokeragePaymentTerms, brokerageTermDate }) => getAntDueDate(brokeragePaymentTerms, brokerageTermDate)),
      distinctUntilChanged())
    detailsForm.estimatedTotals$ = detailsForm$.pipe(map(({ estimatedTotals }) => estimatedTotals), distinctUntilChanged())
    detailsForm.actualTotals$ = detailsForm$.pipe(map(({ actualTotals }) => actualTotals), distinctUntilChanged())
    detailsForm.financeIssues$ = new BehaviorSubject(undefined)

    return detailsForm

    function isDealInInventory() {
      const { supplierId, buyerId } = detailsForm.getRawValue()
      return isBwiInventory(supplierId) || isBwiInventory(buyerId)
    }
  }

  buildDealProductForm(value?: DeepReadonly<Partial<DealProductFormValue>>) {
    const groupConfig: Record<keyof DealProductFormValue, AbstractControl|unknown[]> = {
      bid: [],
      offer: [],
      productId: [, Validators.required], // disabled = deal.isLocked()
      itemTypeId: [, Validators.required], // disabled = deal.isLocked()
      wrappingId: [], // disabled = !tradersCantEditLockedDeal()
      weightTypeId: [], // disabled = !tradersCantEditLockedDeal()
      brand: [], // disabled = !tradersCantEditLockedDeal()
      productCode: [], // disabled = !tradersCantEditLockedDeal()
      packagesCount: [, Validators.min(0)],
      packageId: [], // disabled = !tradersCantEditLockedDeal()
      packageSize: [, Validators.min(0)],
      packageMeasureId: [], // disabled = !tradersCantEditLockedDeal()
      additionalSpecs: [], // disabled = !tradersCantEditLockedDeal()
      buyerGrossWeight: [],
      supplierLocked: [], // disabled = !tradersCantEditLockedDeal()
      buyerLocked: [], // disabled = !tradersCantEditLockedDeal()
      establishments: [[]],
      supplierIncotermId: [, Validators.required],
      supplierIncotermLocationId: [],  // disabled = !tradersCantEditLockedDeal()
      supplierEstWeight: [, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]{1,2})?$/)]], // disabled = !tradersCantEditLockedDeal()
      supplierActualWeight: [], // disabled = !tradersCantEditLockedDeal()
      supplierMeasureId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
      supplierEstPrice: [, Validators.required], // disabled = !canEdit(deal, 'supplierEstPrice')
      invoiceAddress: [],
      buyerIncotermId: [, Validators.required],
      buyerIncotermLocationId: [],  // disabled = !tradersCantEditLockedDeal()
      buyerEstWeight: [, [Validators.required, Validators.pattern(/^[0-9]*(\.[0-9]{1,2})?$/)]], // disabled = !tradersCantEditLockedDeal()
      buyerActualWeight: [], // disabled = !tradersCantEditLockedDeal()
      buyerMeasureId: [, Validators.required], // disabled = !tradersCantEditLockedDeal()
      buyerEstPrice: [, Validators.required], // disabled = !canEdit(deal, 'buyerEstPrice')
      supplierActualPrice: [],
      buyerActualPrice: [],
      comments: [],
      description: [],
      shippingMarks: [],
      margin: [],
      marginP: [],
      batches: new UntypedFormArray([]),
    }
    const group = this.fb.group(groupConfig) as DealProductFormGroup
    const self = this
    group.serialize = function () { return self.serializeDealProduct(this) }
    if (value) group.patchValue(value)
    return group
  }

  buildDealProductBatchForm(value?: DeepReadonly<DealProductBatchFormValue>) {
    const groupConfig: Record<keyof DealProductBatchFormValue, unknown[]> = {
      cases: [],
      lotNo: [],
      pallets: [],
      productionDateRangeFrom: [, Validators.required],
      productionDateRangeTo: [, Validators.required],
      productionDateRangeTbd: [],
      expiryDate: [],
      expiryIn: [],
    }
    const group = this.fb.group(groupConfig) as DealProductBatchFormGroup
    if (value) group.patchValue(value)
    return group
  }

  buildSegmentForm(value?: DeepReadonly<SegmentFormValue>) {
    return buildSegmentForm(value)
  }

  prepareDealDetailsPatch(deal: DeepReadonly<Deal>, dealProducts: DeepReadonly<Partial<DealProduct>[]>): DeepReadonly<DealDetailsFormValue> {
    return {
      deal: deal,
      actualTotals: deal.attributes.actual,
      antLiabilityDate: deal.supplier_anticipated_liability,
      buyerConfirmedAt: deal.buyer_confirmed,
      buyerConfirmedBy: deal.attributes.buyer_confirmed_by,
      buyerCurrencyCode: dealProducts[0].buyer.currency_code,
      buyerFormula: deal.attributes.buyer_formula,
      buyerId: deal.buyer_id,
      buyerPaymentTerms: deal.attributes.buyer_payment_terms,
      buyerRef: deal.attributes.buyer_ref,
      buyerTermDate: deal.attributes.buyer_term_date,
      buyerTraderId: deal.trader_user_id,
      buyerUserIds: deal.attributes.buyer_user_ids,
      collectionDate: deal.collection_date,
      color: deal.attributes.extra?.color,
      date: deal.attributes.deal_date || deal.created,
      deliveryDatesFrom: deal.attributes.delivery_dates?.from || undefined,
      deliveryDatesTbd: deal.attributes.delivery_dates?.tbd,
      deliveryDatesTo: deal.attributes.delivery_dates?.to || undefined,
      destLocationId: deal.dest_location,
      docsCountryCode: deal.attributes.docs_country,
      estimatedTotals: deal.attributes.estimated,
      fxRates: deal.attributes.fx_rates,
      fxRatesAskRange: deal.attributes.fx_rates_ask_range || 'spot',
      fxRatesBidRange: deal.attributes.fx_rates_bid_range || 'spot',
      fxRatesTimestamp: deal.attributes.fx_rates_timestamp,
      inalfresco: deal.attributes.inalfresco,
      logisticsUserId: deal.logistics_user_id,
      originCountryCode: deal.attributes.origin_country,
      originLocationId: deal.origin_location,
      prepaymentMade: deal.attributes.prepayment_made,
      prepaymentReceived: deal.attributes.prepayment_received,
      proformaNeeded: deal.attributes.proforma_needed,
      supplierConfirmedAt: deal.seller_confirmed,
      supplierConfirmedBy: deal.attributes.seller_confirmed_by,
      scheduledPickupDate: deal.attributes.scheduled_pickup_date,
      scheduledDeliveryDate: deal.attributes.scheduled_delivery_date,
      actualInvoiceDate: deal.attributes.actual_invoice_date,
      shipmentAes: deal.attributes.shipment?.aes,
      shipmentB13: deal.attributes.shipment?.b13,
      shipmentBolDate: deal.attributes.shipment?.bill_of_lading_date,
      shipmentBorderCross: deal.attributes.shipment?.border_cross,
      shipmentConsignee: deal.attributes.shipment?.delivery?.consignee,
      shipmentDatesFrom: deal.attributes.shipment_dates?.from || undefined,
      shipmentDatesTbd: deal.attributes.shipment_dates?.tbd,
      shipmentDatesTo: deal.attributes.shipment_dates?.to || undefined,
      shipmentEpd: deal.attributes.shipment?.epd_date,
      shipmentErd: deal.attributes.shipment?.erd_date,
      shipmentImportNo: deal.attributes.shipment?.import_no,
      shipmentNotifyParty: deal.attributes.shipment?.delivery?.notify_party,
      shipmentPaps: deal.attributes.shipment?.paps,
      shipmentShipper: deal.attributes.shipment?.shipper,
      shipmentType: deal.attributes.shipment?.type,
      shipmentVoyageNo: deal.attributes.shipment?.voyage_no,
      supplierCurrencyCode: dealProducts[0].supplier.currency_code,
      supplierFormula: deal.attributes.supplier_formula,
      supplierId: deal.supplier_id,
      supplierPaymentTerms: deal.attributes.supplier_payment_terms,
      supplierRef: deal.attributes.supplier_ref,
      supplierTermDate: deal.attributes.supplier_term_date,
      supplierTraderId: deal.trader_user_id_supplier,
      supplierUserIds: deal.attributes.supplier_user_ids,
      useCorpUsAddress: deal.attributes.shipment?.use_corp_us_address,
      estFinanceTerm: deal.attributes.est_finance_term,
      financeTerm: deal.attributes.finance_term,
      temperatureUnits: deal.attributes.temperature_units || 'C',
      letterOfCreditNo: deal.attributes.letter_of_credit_no,
      copyOf: deal.attributes.copy_of,
      estimateId: deal.attributes.estimate_id,
      mexicanInvoiceNo: deal.foreign_invoices?.find(invoice => invoice.country_code === 'MX')?.number,
      woExportDocs: deal.wo_export_docs,

      dealType: deal.deal_type,
      brokerageEstAmount: deal.brokerage?.amount,
      brokerageActAmount: deal.brokerage?.actual_amount,
      brokerageCollectionDate: deal.brokerage?.collectionDate,
      brokerageCurrency: deal.brokerage?.currency,
      brokerageCustomer: parseFloat(deal.brokerage?.customer as any) || undefined,
      brokeragePaymentTerms: deal.brokerage?.paymentTerms,
      brokerageSendBuyerConfirmation: deal.brokerage?.sendBuyerConfirmation,
      brokerageSendSupplierConfirmation: deal.brokerage?.sendSupplierConfirmation,
      brokerageTermDate: deal.brokerage?.termDate,
      brokerageTraderId: deal.brokerage?.traderId,
      brokerageContactUserIds: deal.brokerage?.contactUserIds || [],
    }
  }

  prepareDealTermsAndDatesPatch(deal: DeepReadonly<Deal>): DeepReadonly<Pick<DealDetailsFormValue, 'buyerId' | 'buyerPaymentTerms' | 'date' | 'deliveryDatesTo' | 'shipmentBolDate' | 'shipmentDatesTo' | 'supplierPaymentTerms'>> {
    return {
      buyerId: deal.buyer_id,
      buyerPaymentTerms: deal.attributes.buyer_payment_terms,
      date: deal.attributes.deal_date || deal.created,
      deliveryDatesTo: deal.attributes.delivery_dates?.to || undefined,
      shipmentBolDate: deal.attributes.shipment?.bill_of_lading_date,
      shipmentDatesTo: deal.attributes.shipment_dates?.to || undefined,
      supplierPaymentTerms: deal.attributes.supplier_payment_terms,
    }
  }

  private prepareDealProductBatchesPatch(dealProduct: Partial<DealProduct>): DealProductBatchFormValue[] {
    return dealProduct.batches?.map(batch => ({
      lotNo: batch.lot_no,
      cases: batch.cases,
      pallets: batch.pallets,
      productionDateRangeFrom: batch.production_date_range?.from,
      productionDateRangeTo: batch.production_date_range?.to,
      productionDateRangeTbd: batch.production_date_range?.tbd,
      expiryDate: batch.expiry_date,
      expiryIn: batch.expiry_in,
    })) || []
  }

  private serializeDealProductBatch(batch: DealProductBatchFormValue): DealProductBatch {
    const production_date_range: IEpochRange = batch.productionDateRangeFrom && batch.productionDateRangeTo
      ? { from: batch.productionDateRangeFrom,
          to: batch.productionDateRangeTo,
          tbd: batch.productionDateRangeTbd || undefined,
        }
      : undefined
    return {
      lot_no: batch.lotNo,
      cases: batch.cases,
      pallets: batch.pallets,
      production_date_range,
      expiry_date: batch.expiryDate,
      expiry_in: batch.expiryIn,
    }
  }

  private prepareDealProductsPatch(dealProducts: Partial<DealProduct>[]): DealProductFormValue[] {
    return dealProducts.map(product => ({
      additionalSpecs: product.additional_specs,
      bid: product.bid,
      brand: product.brand,
      buyerActualPrice: product.buyer.actual_price || product.buyer.price,
      buyerActualWeight: product.buyer.actual_weight,
      buyerGrossWeight: product.buyer.gross_weight,
      buyerIncotermId: product.buyer.incoterm,
      buyerIncotermLocationId: product.buyer.incoterm_location,
      buyerLocked: product.buyer.locked,
      buyerMeasureId: product.buyer.measure_id,
      buyerEstPrice: product.buyer.price,
      buyerEstWeight: Math.round(product.buyer.weight * 100) / 100,
      categoryId: product.category_id,
      comments: product.comments,
      description: product.description,
      shippingMarks: product.shipping_marks,
      establishments: product.supplier.establishments,
      // NOTE: due to data corruption some deals have invoice_address={}
      invoiceAddress: product.buyer.invoice_address && !isEmpty(product.buyer.invoice_address) ? product.buyer.invoice_address : undefined,
      itemTypeId: product.item_type_id,
      offer: product.offer,
      packageId: product.package_id,
      packageMeasureId: product.package_measure_id,
      packagesCount: product.packages_count,
      packageSize: product.package_size,
      productCode: product.product_code,
      productId: product.product_id,
      supplierActualPrice: product.supplier.actual_price || product.supplier.price,
      supplierActualWeight: product.supplier.actual_weight,
      supplierIncotermId: product.supplier.incoterm,
      supplierIncotermLocationId: product.supplier.incoterm_location,
      supplierLocked: product.supplier.locked,
      supplierMeasureId: product.supplier.measure_id,
      supplierEstPrice: product.supplier.price,
      supplierEstWeight: product.supplier.weight,
      typeId: product.type_id,
      weightTypeId: product.weight_type_id,
      wrappingId: product.wrapping_id,
      margin: product.attributes.margin,
      marginP: product.attributes.margin_p,
      batches: this.prepareDealProductBatchesPatch(product),
    }))
  }

  private prepareDealCostsPatch(costs: Partial<DeepReadonly<Cost[]>>): CostFormValue[] {
    return costs.map(cost => prepareDealCostPatch(cost))
  }

  prepareDealSegmentPatch(segment: Partial<DeepReadonly<Segment>>, index: number): SegmentFormValue {
    return prepareDealSegmentPatch(segment, index)
  }

  private prepareDealSegmentsPatch(segments: DeepReadonly<Segment[]>): SegmentFormValue[] {
    return segments.map((segment, i) => prepareDealSegmentPatch(segment, i))
  }

  private serializeDealForm(fg: DealFormGroup): DeepReadonly<DealFormDto> {
    // NOTE: UI should not send raw objects and internal values to the backend
    // NOTE: UI should send ids when available (deal_id, bid_id, offer_id, cost_id, segment_id)
    const dealForm = fg.getRawValue()
    return {
      updated: dealForm.details.deal?.updated,
      details: this.serializeDealDetails(fg.controls.details),
      products: fg.controls.products.controls.map(productForm => this.serializeDealProduct(productForm)),
      costs: fg.controls.costs.controls.map(costForm => costForm.value.cost),
      segments: fg.controls.segments.controls.map(segmentForm => this.serializeSegment(segmentForm)),
    }
  }

  // replace "deal" with "deal_id"
  serializeDealDetails(detailsForm: DealDetailsFormGroup): DealDetailsFormDto {
    const { deal, estimatedTotals, actualTotals, estFinanceTerm, financeTerm, mexicanInvoiceNo, ...details } = detailsForm.getRawValue()
    return {
      ...this.serializeUndefinedAsNull(details),
      deal_id: deal?.deal_id,
      foreignInvoices: mexicanInvoiceNo ? [{ country_code: 'MX', number: mexicanInvoiceNo }] : [],
    };
  }

  // replace "offer" and "bid" with their ids
  private serializeDealProduct(productForm: DealProductFormGroup): DealProductFormDto {
    const { offer, bid, batches, ...product} = productForm.getRawValue()
    return {
      ...this.serializeUndefinedAsNull(product),
      offer_id: offer?.offer_id,
      bid_id: bid?.bid_id,
      batches: batches?.map(batch => this.serializeDealProductBatch(batch)),
    }
  }

  // replace "segent" with "segment_id"
  private serializeSegment(segmentForm: SegmentFormGroup): SegmentFormDto {
    const { segment, ...segmentValue} = segmentForm.getRawValue()
    return {
      ...this.serializeUndefinedAsNull(segmentValue),
      segment_id: segment?.segment_id,
    }
  }

  private serializeUndefinedAsNull<T extends Record<string, any>>(form: T) {
    forEach(form, (_value, key) => {
      if (form[key] === undefined) {
        form = { ...form, [key]: null }
      }
    })
    return form
  }

  readSegmentForm(segment: SegmentFormValue): Partial<Segment> {
    return {
      type: segment.type,
      attributes: {
        actual_delivery_date_time: segment.actualDeliveryDateTime,
        actual_delivery_date: segment.actualDeliveryDate,
        actual_pickup_date_time: segment.actualPickupDateTime,
        actual_pickup_date: segment.actualPickupDate,
        amount: segment.amount,
        bill_of_lading_no: segment.billOfLadingNo,
        carrier_account: segment.carrierAccountId,
        container_number: segment.containerNumber,
        currency: segment.currencyCode,
        cutoff_datetime: segment.cutoffDatetime,
        delivery_ref_no: segment.deliveryRefNo,
        destination_id: segment.destinationId,
        eta_date_time: segment.etaDateTime,
        eta_date: segment.etaDate,
        etd_date_time: segment.etdDateTime,
        etd_date: segment.etdDate,
        exact_dropoff: {
          account: segment.exactDropoffAccount?.toString(),
          address: segment.exactDropoffAddress,
        },
        exact_loading: {
          account: segment.exactLoadingAccount?.toString(),
          address: segment.exactLoadingAddress,
        },
        facility_had_claims: segment.facilityHadClaims,
        flight_no: segment.flightNo,
        freight_forwarder: segment.freightForwarderId,
        import_permit: segment.importPermit,
        loading_ramp: segment.loadingRamp,
        origin_id: segment.originId,
        pickup_company: segment.pickupCompany,
        pickup_ref_no: segment.pickupRefNo,
        plate_no: segment.plateNo,
        refer_no: segment.referNo,
        seal_number: segment.sealNumber,
        tracking_no: segment.trackingNo,
        train: segment.train,
        vessel: segment.vessel,
        voyage_no: segment.voyageNo,
      },
      order: segment.order,
      booking_id: segment.bookingId,
      carrier_id: segment.carrierId,
    }
  }

  readCostForm(cost: CostFormValue): Partial<Cost> {
    return readCostForm(cost)
  }

  enableDisableDealForm(dealForm: DealFormGroup, dv: DeepReadonly<DealViewRawStatus & DealViewRawDeal & DealViewRawInvoices>) {
    if (!dv.status || dv.status === DealViewStatus.incomplete) {
      dealForm.disable()
    } else {
      this.enableDisableDetailsForm(dealForm.controls.details, dv)
      dealForm.controls.products.controls.forEach(productForm =>
        this.enableDisableProductForm(productForm, dv))
    }
  }

  private enableDisableDetailsForm(form: DealDetailsFormGroup, dv: DeepReadonly<DealViewRawDeal & DealViewRawInvoices>) {
    const { currentUser } = this.AuthApi
    const lockedAndTrader = isDealLocked(dv.deal) && currentUser.role === 'trader'
    // NOTE: canChangeParty relies on "pristine" form values. we should disable party change STRICTLY AFTER change hits backend (after save)
    disableIf(form.controls.supplierId, !canChangeParty(dv.deal, 'supplier', currentUser))
    disableIf(form.controls.buyerId, !canChangeParty(dv.deal, 'buyer', currentUser))
    disableIf(form.controls.originCountryCode, lockedAndTrader)
    disableIf(form.controls.originLocationId, lockedAndTrader)
    disableIf(form.controls.supplierUserIds, lockedAndTrader)
    disableIf(form.controls.supplierTraderId, lockedAndTrader)
    disableIf(form.controls.docsCountryCode, lockedAndTrader)
    disableIf(form.controls.destLocationId, lockedAndTrader)
    disableIf(form.controls.logisticsUserId, lockedAndTrader)
    disableIf(form.controls.buyerUserIds, lockedAndTrader)
    disableIf(form.controls.buyerTraderId, lockedAndTrader)
    disableIf(form.controls.supplierConfirmedAt, !dv.deal.deal_id)
    disableIf(form.controls.buyerConfirmedAt, !dv.deal.deal_id)
    disableIf(form.controls.dealType, !dv.deal.deal_id)
    // NOTE: implemented dynamically. relies on dirty form value
    const { dealType, docsCountryCode } = form.getRawValue()
    disableIf(form.controls.proformaNeeded, docsCountryCode === 'CO')
    disableIf(form.controls.supplierCurrencyCode, !canEditDealFormField(dv, 'supplierCurrencyCode', currentUser))
    disableIf(form.controls.buyerCurrencyCode, !canEditDealFormField(dv, 'buyerCurrencyCode', currentUser))
    disableIf(form.controls.brokeragePaymentTerms, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokeragePaymentTerms', currentUser))
    disableIf(form.controls.brokerageCustomer, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageCustomer', currentUser))
    disableIf(form.controls.brokerageEstAmount, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageEstAmount', currentUser))
    disableIf(form.controls.brokerageActAmount, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageActAmount', currentUser))
    disableIf(form.controls.brokerageCurrency, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageCurrency', currentUser))
    disableIf(form.controls.brokerageSendSupplierConfirmation, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageSendSupplierConfirmation', currentUser))
    disableIf(form.controls.brokerageSendBuyerConfirmation, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageSendBuyerConfirmation', currentUser))
    disableIf(form.controls.brokerageCollectionDate, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageCollectionDate', currentUser))
    disableIf(form.controls.brokerageTermDate, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageTermDate', currentUser))
    disableIf(form.controls.brokerageTraderId, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageTraderId', currentUser))
    disableIf(form.controls.brokerageContactUserIds, dealType !== 'brokerage' || !canEditDealFormField(dv, 'brokerageContactUserIds', currentUser))

    if (form.controls.dealType.value === 'brokerage') {
      form.controls.brokerageEstAmount.setValidators(dealDetailsFormConfig.brokerageEstAmount[1])
      form.controls.brokerageActAmount.setValidators(dealDetailsFormConfig.brokerageActAmount[1])
      form.controls.brokerageCurrency.setValidators(dealDetailsFormConfig.brokerageCurrency[1])
      form.controls.brokerageCustomer.setValidators(dealDetailsFormConfig.brokerageCustomer[1])
      form.controls.brokeragePaymentTerms.setValidators(dealDetailsFormConfig.brokeragePaymentTerms[1])
      form.controls.brokerageSendBuyerConfirmation.setValidators(dealDetailsFormConfig.brokerageSendBuyerConfirmation[1])
      form.controls.brokerageSendSupplierConfirmation.setValidators(dealDetailsFormConfig.brokerageSendSupplierConfirmation[1])
      form.controls.brokerageCollectionDate.setValidators(dealDetailsFormConfig.brokerageCollectionDate[1])
      form.controls.brokerageTermDate.setValidators(dealDetailsFormConfig.brokerageTermDate[1])
      form.controls.brokerageTraderId.setValidators(dealDetailsFormConfig.brokerageTraderId[1])
      form.controls.brokerageContactUserIds.setValidators(dealDetailsFormConfig.brokerageContactUserIds[1])
    } else {
      form.controls.brokerageEstAmount.clearValidators()
      form.controls.brokerageActAmount.clearValidators()
      form.controls.brokerageCurrency.clearValidators()
      form.controls.brokerageCustomer.clearValidators()
      form.controls.brokeragePaymentTerms.clearValidators()
      form.controls.brokerageSendBuyerConfirmation.clearValidators()
      form.controls.brokerageSendSupplierConfirmation.clearValidators()
      form.controls.brokerageCollectionDate.clearValidators()
      form.controls.brokerageTermDate.clearValidators()
      form.controls.brokerageTraderId.clearValidators()
      form.controls.brokerageContactUserIds.clearValidators()
    }
  }

  enableDisableProductForm(
    productForm: DealProductFormGroup,
    dv: DeepReadonly<DealViewRawDeal & DealViewRawInvoices>
  ) {
    const { currentUser } = this.AuthApi
    const isLocked = isDealLocked(dv.deal)
    const isTrader = currentUser.role === 'trader'
    const isLockedAndTrader = isLocked && isTrader // ex `tradersCantEditLockedDeal`
    const isSubmittedAndTrader = isDealSubmitted(dv.deal) && isTrader // ex `tradersCantEditSubmittedDeal`

    disableIf(productForm.controls.productId, !canEditDealFormField(dv, 'productId', currentUser))
    disableIf(productForm.controls.supplierLocked, isLockedAndTrader)
    disableIf(productForm.controls.buyerLocked, isLockedAndTrader)
    disableIf(productForm.controls.supplierEstWeight, !canEditDealFormField(dv, 'supplierEstWeight', currentUser))
    disableIf(productForm.controls.supplierMeasureId, !canEditDealFormField(dv, 'supplierMeasureId', currentUser))
    disableIf(productForm.controls.buyerEstWeight, !canEditDealFormField(dv, 'buyerEstWeight', currentUser))
    disableIf(productForm.controls.buyerMeasureId, !canEditDealFormField(dv, 'buyerMeasureId', currentUser))
    disableIf(productForm.controls.wrappingId, isTrader ? false : isLocked)
    disableIf(productForm.controls.itemTypeId, isLocked)
    disableIf(productForm.controls.supplierIncotermLocationId, isLocked)
    disableIf(productForm.controls.buyerIncotermLocationId, isLocked)
    disableIf(productForm.controls.supplierEstPrice, !canEditDealFormField(dv, 'supplierEstPrice', currentUser))
    disableIf(productForm.controls.buyerEstPrice, !canEditDealFormField(dv, 'buyerEstPrice', currentUser))
    disableIf(productForm.controls.supplierActualPrice, isSubmittedAndTrader)
    disableIf(productForm.controls.buyerActualPrice, isSubmittedAndTrader)
    disableIf(productForm.controls.buyerGrossWeight, isSubmittedAndTrader)
    disableIf(productForm.controls.supplierActualWeight, !canEditDealFormField(dv, 'supplierActualWeight', currentUser))
    disableIf(productForm.controls.buyerActualWeight, !canEditDealFormField(dv, 'buyerActualWeight', currentUser))
  }

  setValue(dealForm: DealFormGroup, value: DeepReadonly<DealFormValue>) {
    // change collections size if needed
    const productsForm = dealForm.controls.products as DealProductsFormGroup
    const costsForm = dealForm.controls.costs as CostsFormGroup
    const segmentsForm = dealForm.controls.segments as SegmentsFormGroup
    while (productsForm.length > value.products?.length) productsForm.removeAt(0)
    value.products.forEach((product, i) => {
      i < productsForm.length
        ? productsForm.at(i).reset(product, { emitEvent: false })
        : productsForm.push(this.buildDealProductForm(product))

      const batchesForm = productsForm.controls[i].controls.batches
      while (batchesForm.length > product.batches?.length) batchesForm.removeAt(0)
      product.batches?.forEach((batch, j) =>
        j < batchesForm.length
          ? batchesForm.at(j).reset(batch, { emitEvent: false })
          : batchesForm.push(this.buildDealProductBatchForm(batch)))
    })
    while (costsForm.length > value.costs?.length) costsForm.removeAt(0)
    value.costs.forEach((cost, i) =>
      i < costsForm.length
        ? costsForm.at(i).reset(cost, { emitEvent: false })
        : costsForm.push(buildDealCostForm(cost)))
    while (segmentsForm.length > value.segments?.length) segmentsForm.removeAt(0)
    value.segments?.forEach((segment, i) =>
      i < segmentsForm.length
        ? segmentsForm.at(i).reset(segment, { emitEvent: false })
        : segmentsForm.push(this.buildSegmentForm(segment)))
    dealForm.patchValue(value)
  }

  patchForm(
    dealForm: DealFormGroup,
    { deal, costs = [], segments = [] }: DealViewRaw,
    dealProducts: Partial<DealProduct>[],
  ) {
    this.setValue(dealForm, {
      details: this.prepareDealDetailsPatch(deal, dealProducts),
      products: this.prepareDealProductsPatch(dealProducts),
      costs: this.prepareDealCostsPatch(costs),
      segments: this.prepareDealSegmentsPatch(segments),
    })
  }

  refreshSecondaryCosts(dealForm: DealFormGroup) {
    const detailsForm = dealForm.controls.details.getRawValue()
    return from(this.CustomCosts.querySecondaryCosts({
      dealType: detailsForm.dealType,
      providerIds: compact(uniq(dealForm.getRawValue().costs.map(cost => cost.providerId?.toString()))),
      supplierId: parseFloat(detailsForm.supplierId) || undefined,
      buyerId: parseFloat(detailsForm.buyerId) || undefined,
      originId: detailsForm.originLocationId,
      destinationId: detailsForm.destLocationId,
      productIds: compact(dealForm.getRawValue().products.map(x => x.productId)),
    })).pipe(tap(secondaryCosts => {
      const existing = compact(dealForm.controls.costs.controls.map((costForm, index) =>
        isProperSecondaryCost(costForm.value.cost) && {costForm, index}))
      const toRemove = existing.filter(({costForm: {value: {cost: c1}}}) =>
        !secondaryCosts.find(c2 => isEqualSecondaryCost(c1, c2)))
      const toAdd = secondaryCosts.filter(c1 =>
        !existing.find(({ costForm: { value: { cost: c2 } } }) => isEqualSecondaryCost(c1, c2)))

      // keep updated costs; keep estimated values; update attributes.default_cost reference
      existing
      .filter(({costForm: {value: {cost: c1}}}) => secondaryCosts.find(c2 => isEqualSecondaryCost(c1, c2)))
      .forEach(({costForm}) => {
        const newCost = secondaryCosts.find(c2 => isEqualSecondaryCost(costForm.value.cost, c2))
        let oldCost = cloneDeep(costForm.value.cost) as Cost
        if (isCostActualizedToZero(oldCost as Cost)) {
          oldCost = { ...oldCost, ...unactualizeToZeroPatch<Cost>(oldCost) }
        }
        costForm.patchValue(prepareDealCostPatch({
          ...oldCost,
          attributes: {
            ...oldCost.attributes,
            default_cost: newCost.attributes.default_cost
          }
        }))
      })

      // actualize stored costs to $0
      toRemove.reverse().forEach(({ costForm, index }) => {
        const cost = costForm.controls.cost.value
        if (cost.cost_id) {
          let updatedCost = cloneDeep(costForm.controls.cost.value) as Cost
          updatedCost = { ...updatedCost, ...actualizeToZeroPatch<Cost>(updatedCost) }
          costForm.patchValue(prepareDealCostPatch(updatedCost))
        } else {
          dealForm.controls.costs.removeAt(index)
        }
      })

      // in confirmed deals keep estimated values = $0
      const zeroEstimated = isDealConfirmed(dealForm.controls.details.controls.deal.value) ? { total: 0, total_cad: 0 } : {}
      toAdd.forEach((cost, i) => {
        const costForm = buildDealCostForm(prepareDealCostPatch({ ...cost, amount: { ...cost.amount, ...zeroEstimated }}))
        dealForm.controls.costs.push(costForm)
      })
    }))

    function isProperSecondaryCost(cost: DeepReadonly<Partial<Cost>>) {
      return cost.type === 'secondary' && cost.attributes?.default_cost?.cost_type_id
    }

    function isEqualSecondaryCost(c1: DeepReadonly<Partial<Cost>>, c2: DeepReadonly<Partial<Cost>>) {
      return isEqual(c1.attributes.default_cost.cost_type_id, c2.attributes.default_cost.cost_type_id)
    }
  }

  /**
   * Clean deal & form fields related to supplier
   */
  clearSupplierFields(detailsForm: DealDetailsFormGroup) {
    detailsForm.patchValue({
      supplierUserIds: [],
      supplierTraderId: null,
      supplierPaymentTerms: null,
    })
  }

  /**
   * Clean deal & form fields related to buyer
   */
  cleanBuyerFields(detailsForm: DealDetailsFormGroup) {
    detailsForm.patchValue({
      buyerUserIds: [],
      buyerTraderId: null,
      destLocationId: null,
      logisticsUserId: null,
      docsCountryCode: null,
      buyerPaymentTerms: null,
    })
  }

  handleBuyerInvoiceWithReceipt(dealViewRaw$: Observable<DeepReadonly<DealViewRaw>>, buyerId: string, detailsForm: DealDetailsFormGroup) {
    let dealViewRaw

    dealViewRaw$.pipe(take(1)).subscribe((dv) => dealViewRaw = dv)

    if(!dealViewRaw.deal.buyer_id) return false

    const buyerInvoice = findBuyerInvoice(dealViewRaw.invoices, dealViewRaw.deal.buyer_id)

    if(buyerInvoice?.receipts && buyerInvoice.receipts.length > 0) {
      if(buyerId === dealViewRaw.deal.buyer_id) {
        return true
      }
      this.toaster.error('There is a receipt associated with the buyer invoice. Please unapply receipt before trying to change the buyer.')
      detailsForm.patchValue({
        buyerId: dealViewRaw.deal.buyer_id,
      })
      return dealViewRaw.deal.buyer_id
    }

    return false
  }

}

/** @deprecated import from service-factory file */
export { prepareDealSegmentPatch } from './deal-form.service-factory'

function detectFinanceIssues(
  prev: DeepReadonly<Pick<DealDetailsFormValue, 'actualTotals'|'estimatedTotals'>>,
  next: DeepReadonly<Pick<DealDetailsFormValue, 'actualTotals'|'estimatedTotals'>>,
) {
  const threshold = 10
  const financeIssues: string[] = []

  if (Math.abs(prev.actualTotals?.margin - next.actualTotals?.margin) > threshold) {
    financeIssues.push(`actual margin:\n${prev.actualTotals.margin} / ${next.actualTotals.margin}`)
  }
  if (Math.abs(prev.actualTotals?.costs - next.actualTotals?.costs) > threshold) {
    financeIssues.push(`actual costs:\n${prev.actualTotals.costs} / ${next.actualTotals.costs}`)
  }
  if (Math.abs(prev.actualTotals?.revenue - next.actualTotals?.revenue) > threshold) {
    financeIssues.push(`actual revenue:\n${prev.actualTotals.revenue} / ${next.actualTotals.revenue}`)
  }
  if (Math.abs(prev.actualTotals?.partial_margin - next.actualTotals?.partial_margin) > threshold) {
    financeIssues.push(`actual partial_margin:\n${prev.actualTotals.partial_margin} / ${next.actualTotals.partial_margin}`)
  }
  if (Math.abs(prev.actualTotals?.partial_costs - next.actualTotals?.partial_costs) > threshold) {
    financeIssues.push(`actual partial_costs:\n${prev.actualTotals.partial_costs} / ${next.actualTotals.partial_costs}`)
  }
  if (Math.abs(prev.actualTotals?.brokerage_costs - next.actualTotals?.brokerage_costs) > threshold) {
    financeIssues.push(`actual brokerage_costs:\n${prev.actualTotals.brokerage_costs} / ${next.actualTotals.brokerage_costs}`)
  }
  if (Math.abs(prev.actualTotals?.partial_brokerage_costs - next.actualTotals?.partial_brokerage_costs) > threshold) {
    financeIssues.push(`actual partial_brokerage_costs:\n${prev.actualTotals.partial_brokerage_costs} / ${next.actualTotals.partial_brokerage_costs}`)
  }
  if (Math.abs(prev.actualTotals?.partial_brokerage_revenue - next.actualTotals?.partial_brokerage_revenue) > threshold) {
    financeIssues.push(`actual partial_brokerage_revenue:\n${prev.actualTotals.partial_brokerage_revenue} / ${next.actualTotals.partial_brokerage_revenue}`)
  }
  if (Math.abs(prev.actualTotals?.brokerage_revenue - next.actualTotals?.brokerage_revenue) > threshold) {
    financeIssues.push(`actual brokerage_revenue:\n${prev.actualTotals.brokerage_revenue} / ${next.actualTotals.brokerage_revenue}`)
  }
  if (Math.abs(prev.estimatedTotals?.margin - next.estimatedTotals?.margin) > threshold) {
    financeIssues.push(`estimated margin:\n${prev.estimatedTotals.margin} / ${next.estimatedTotals.margin}`)
  }
  if (Math.abs(prev.estimatedTotals?.costs - next.estimatedTotals?.costs) > threshold) {
    financeIssues.push(`estimated costs:\n${prev.estimatedTotals.costs} / ${next.estimatedTotals.costs}`)
  }
  if (Math.abs(prev.estimatedTotals?.revenue - next.estimatedTotals?.revenue) > threshold) {
    financeIssues.push(`estimated revenue:\n${prev.estimatedTotals.revenue} / ${next.estimatedTotals.revenue}`)
  }
  if (Math.abs(prev.estimatedTotals?.brokerage_costs - next.estimatedTotals?.brokerage_costs) > threshold) {
    financeIssues.push(`estimated brokerage_costs:\n${prev.estimatedTotals.brokerage_costs} / ${next.estimatedTotals.brokerage_costs}`)
  }
  if (Math.abs(prev.estimatedTotals?.brokerage_revenue - next.estimatedTotals?.brokerage_revenue) > threshold) {
    financeIssues.push(`estimated brokerage_revenue:\n${prev.estimatedTotals.brokerage_revenue} / ${next.estimatedTotals.brokerage_revenue}`)
  }
  return financeIssues.length ? `Desynced Financials!\n\n${financeIssues.join('\n\n')}` : ''
}

export function readCostForm(cost: CostFormValue): Partial<Cost> {
  return {
    ...cloneDeep(cost.cost) as Partial<Cost>,
    provider: cost.providerId ? cost.providerId : undefined,
    type: cost.type,
    status: cost.status,
    product_id: cost.productId || null,
    service: cost.serviceName || '',
    amount: {
      total: cost.amount,
      currency: cost.currencyCode,
      // NOTE: total_cad and cost_unit will be calculated using dealfinancials or new deal saving API
    },
  }
}
