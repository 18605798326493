<div class="brokerage-deal" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="30px"
    *ngIf="enableBrokerageDeals && detailsForm.controls.dealType.value === 'brokerage'">
  <tc-brokerage-info-form
      [detailsForm]="detailsForm"
      (change)="onCurrencyChange()"
      ></tc-brokerage-info-form>
  <tc-brokerage-dates-form
      [dealForm]="dealForm"
      [dealViewRaw$]="dealViewRaw$"
      ></tc-brokerage-dates-form>
</div>
<h4 class="tc-confirmation-status" *ngIf="!mode" [formGroup]="detailsForm" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="50px">
  <div class="tc-parties-left" gdColumns="repeat(2, minmax(0, 1fr))">
    <div>
      <ng-container *ngIf="!detailsForm.controls.supplierConfirmedAt.value">Supplier has not confirmed sale</ng-container>
      <ng-container *ngIf="detailsForm.controls.supplierConfirmedAt.value">Supplier confirmed sale <i class="fa fa-check"></i></ng-container>
    </div>
    <div>
    <mat-checkbox style="margin-right: 20px;" *ngIf="isManager$ | async" [checked]="!!detailsForm.controls.supplierConfirmedAt.value" [disabled]="!dealId" (change)="sellerConfirm()">
      Supplier contract
    </mat-checkbox>
      <mat-checkbox [checked]="!!detailsForm.controls.woExportDocs.value" (change)="setWoExportDocs($event)">
        W/O export docs
      </mat-checkbox>
    </div>
  </div>
  <div class="tc-parties-right" gdColumns="repeat(2, minmax(0, 1fr))">
    <div>
      <ng-container *ngIf="!detailsForm.controls.buyerConfirmedAt.value">Buyer has not confirmed purchase</ng-container>
      <ng-container *ngIf="detailsForm.controls.buyerConfirmedAt.value">Buyer confirmed purchase <i class="fa fa-check"></i></ng-container>
    </div>
    <div>
      <mat-checkbox *ngIf="isManager$ | async" [checked]="!!detailsForm.controls.buyerConfirmedAt.value" [disabled]="!dealId" (change)="buyerConfirm()">
        Buyer contract
      </mat-checkbox>
    </div>
    <mat-checkbox formControlName="proformaNeeded">
      Proforma needed
    </mat-checkbox>
    <mat-checkbox formControlName="useCorpUsAddress">
      Use corporation US address
    </mat-checkbox>
  </div>
</h4>

<div class="tc-deal-parties" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="50px" [formGroup]="detailsForm">
  <div class="tc-parties-left" gdColumns="minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
    <tc-select-search placeholder="Supplier"
        [class.tc-portal-visible]="isSupplierPortalVisible$ | async"
        [group]="detailsForm" ctrlName="supplierId"
        [items]="suppliers$ | async" bindValue="id" bindLabel="name"
        (change)="supplierChanged$.next($event?.id)"
        ></tc-select-search>
    <mat-form-field [formGroup]="detailsForm">
      <mat-label>Supplier ref. number</mat-label>
      <input matInput formControlName="supplierRef" autocomplete="off">
    </mat-form-field>
    <tc-select-search placeholder="Country Of Origin"
        [group]="detailsForm" ctrlName="originCountryCode"
        [items]="countries$ | async" bindValue="code" bindLabel="name"
        ></tc-select-search>

    <tc-select-search placeholder="Pickup"
        [group]="detailsForm" ctrlName="originLocationId"
        [items]="locations$ | async" bindValue="location_id" bindLabel="name"
        (change)="onOriginLocationChange($event)"
        ></tc-select-search>
    <tc-select-search placeholder="Contact" [multiple]="true" selectedTextFormat=""
        [group]="detailsForm" ctrlName="supplierUserIds"
        [items]="supplierUsers$ | async" bindValue="user_id" bindLabel="fullname" bindHint="primaryemail"
        ></tc-select-search>
    <tc-select-search placeholder="Buying trader"
        [group]="detailsForm" ctrlName="supplierTraderId"
        [items]="supplierTraders$ | async" bindValue="id" bindLabel="name"
        (change)="supplierTraderChanged$.next($event?.id)"
        ></tc-select-search>
    <tc-epoch-range-field placeholder="Shipment date range"
        [useUtc]="true"
        [useTemplates]="true"
        [min]="detailsForm.controls.date.value || TODAY"
        [max]="detailsForm.controls.deliveryDatesTo.value"
        [formGroup]="detailsForm"
        startCtrlName="shipmentDatesFrom"
        endCtrlName="shipmentDatesTo"
        tbdCtrlName="shipmentDatesTbd"
        comparisonStartCtrlName="deliveryDatesFrom"
        comparisonEndCtrlName="deliveryDatesTo"
        ></tc-epoch-range-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Payment Terms</mat-label>
      <input readonly matInput [value]="detailsForm.supplierPaymentTerms$ | async">
    </mat-form-field>
  </div>

  <div class="tc-parties-right" gdColumns="repeat(4, minmax(0, 1fr))" gdGap="15px">
    <tc-select-search placeholder="Buyer" gdColumn="span 2"
        [class.tc-portal-visible]="isBuyerPortalVisible$ | async"
        [group]="detailsForm" ctrlName="buyerId"
        [items]="buyers$ | async" bindValue="id" bindLabel="name"
        (change)="buyerChanged$.next($event?.id)"
        ></tc-select-search>
    <mat-form-field [formGroup]="detailsForm">
      <mat-label>Buyer ref. number</mat-label>
      <input matInput formControlName="buyerRef" autocomplete="off">
    </mat-form-field>
    <tc-select-search placeholder="Docs Country"
        [group]="detailsForm" ctrlName="docsCountryCode"
        [items]="countries$ | async" bindValue="code" bindLabel="name"
        (change)="onDocsCountryChange($event)">
      <!-- TODO: update API, make deal-id optional
        <i class="fa fa-exclamation-triangle tc-validation-warning"
          *ngIf="docsCountryIssues$ | async as issues"
          [matTooltip]="issues"></i> -->
    </tc-select-search>

    <tc-select-search placeholder="Destination"
        [group]="detailsForm" ctrlName="destLocationId"
        [items]="locations$ | async" bindValue="location_id" bindLabel="name"
        (change)="onDestLocationChange($event)"
        ></tc-select-search>
    <tc-select-search placeholder="Logistics coordinator"
        [group]="detailsForm" ctrlName="logisticsUserId"
        [items]="coordinators$ | async" bindValue="id" bindLabel="name" bindHint="hint"
        ></tc-select-search>
    <tc-select-search placeholder="Contact" [multiple]="true" selectedTextFormat=""
        [group]="detailsForm" ctrlName="buyerUserIds"
        [items]="buyerUsers$ | async" bindValue="user_id" bindLabel="fullname" bindHint="primaryemail"
        ></tc-select-search>
    <tc-select-search placeholder="Selling trader"
        [group]="detailsForm" ctrlName="buyerTraderId"
        [items]="buyerTraders$ | async" bindValue="id" bindLabel="name"
        (change)="buyerTraderChanged$.next($event?.id)"
        ></tc-select-search>
    <tc-epoch-range-field placeholder="Delivery date range" gdColumn="span 2"
        [useUtc]="true"
        [useTemplates]="true"
        [min]="detailsForm.controls.shipmentDatesFrom.value || TODAY"
        [formGroup]="detailsForm"
        startCtrlName="deliveryDatesFrom"
        endCtrlName="deliveryDatesTo"
        tbdCtrlName="deliveryDatesTbd"
        comparisonStartCtrlName="shipmentDatesFrom"
        comparisonEndCtrlName="shipmentDatesTo"
        ></tc-epoch-range-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Payment Terms</mat-label>
      <input readonly matInput [value]="detailsForm.buyerPaymentTerms$ | async">
    </mat-form-field>
  </div>
</div>

<tc-credit-balance gdColumns="repeat(6, minmax(0, 1fr))" gdGap="15px"
    [creditPool]="creditPool$ | async"
    [balance]="balance$ | async"
    ></tc-credit-balance>

<hr class="fillHR100">

<div class="tc-products">
  <div class="tc-deal-product" *ngFor="let product of productsForm?.controls; let $index = index" [formGroup]="product">
    <h5>
      <span>Product {{$index + 1}}</span>
      <button *ngIf="canRemoveProduct$ | async"
          mat-icon-button (click)="removeProduct($index)">
        <mat-icon class="fa fa-trash-can tc-remove-product"></mat-icon>
      </button>
    </h5>

    <div class="tc-product-info" gdColumns="minmax(0, 3fr) minmax(0, 1fr)" gdGap="15px">
      <tc-product-field gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)" gdGap="0 15px"
          [group]="product" ctrlName="productId"
          [protein]="true" (change)="onProductChange()"
          ></tc-product-field>
      <tc-select-search placeholder="Item specification"
          [group]="product" ctrlName="itemTypeId"
          [items]="itemTypes$ | async" bindLabel="name" bindValue="item_type_id"
          ></tc-select-search>
    </div>

    <div class="tc-product-params" *ngIf="mode === 'products'">
      <div gdColumns="minmax(0, 4fr) minmax(0, 2fr) minmax(0, 3fr) minmax(0, 3fr)" gdGap="15px">
        <tc-select-search placeholder="Wrapping"
            [group]="product" ctrlName="wrappingId"
            [items]="wrappings$ | async" bindValue="wrapping_id" bindLabel="name">
        </tc-select-search>
        <tc-select-search placeholder="Weight type"
            [group]="product" ctrlName="weightTypeId"
            [items]="weightTypes$ | async" bindValue="type_id" bindLabel="name">
        </tc-select-search>
        <mat-form-field>
          <mat-label>Brand</mat-label>
          <input matInput formControlName="brand" autocomplete="off">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Product Code</mat-label>
          <input matInput formControlName="productCode" autocomplete="off">
        </mat-form-field>
      </div>
      <div gdColumns="minmax(0, 4fr) minmax(0, 2fr) minmax(0, 3fr) minmax(0, 3fr)" gdGap="15px">
        <mat-form-field>
          <mat-label>No of packages</mat-label>
          <input matInput type="number" min="0" formControlName="packagesCount" autocomplete="off">
        </mat-form-field>
        <tc-select-search placeholder="Type of packages"
            [group]="product" ctrlName="packageId"
            [items]="packageTypes$ | async" bindValue="package_id" bindLabel="name"
            ></tc-select-search>
        <mat-form-field>
          <mat-label>Weight of one package</mat-label>
          <input matInput type="number" min="0" autocomplete="off"
              formControlName="packageSize"
              [matAutocomplete]="packageSize">
          <mat-autocomplete autoActiveFirstOption #packageSize="matAutocomplete">
            <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <tc-select-search placeholder="Unit of weight"
            [group]="product" ctrlName="packageMeasureId"
            [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
            ></tc-select-search>
      </div>
      <div gdColumns="minmax(0, 5fr) minmax(0, 1fr)" gdGap="15px">
        <mat-form-field>
          <mat-label>Additional Specifications</mat-label>
          <input matInput formControlName="additionalSpecs" autocomplete="off">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Gross Weight</mat-label>
          <input matInput formControlName="buyerGrossWeight" autocomplete="off" type="number" min="0" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="tc-product-params" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="50px">
      <div class="tc-parties-left">
        <h6 fxLayoutAlign="flex-start center" fxLayoutGap="15px">
          <span>Supplier</span>
          <mat-slide-toggle formControlName="supplierLocked" color="primary">LOCK PRICE</mat-slide-toggle>
          <!-- <span class="text-gray" *ngIf="product.supplier.lastPrice">
            Last price: <span>{{product.supplier.lastPrice.price
              | currency:product.supplier.lastPrice.currency_code:'symbol-narrow':'1.4':'no_TH'
              | measure:product.supplier.lastPrice.measure_id}}</span>
          </span> -->
        </h6>
        <div gdColumns="minmax(0, 5fr) minmax(0, 2fr) minmax(0, 5fr)" gdGap="15px">
          <tc-address-field placeholder="Establishment #" [multiple]="true"
              [group]="product" ctrlName="establishments"
              [pickerOptions]="establishmentAddressOptions$(product) | async">
            <div *tcAddressFieldValue="let address = address">
              <div>{{ address.establishment}}<span *ngIf="address.establishment"> – </span>{{ address.name || 'No Name'}}</div>
              <div class="text-gray">{{ address | address:['street1', 'city']}}</div>
            </div>
          </tc-address-field>
          <tc-select-search placeholder="Supplier Incoterm"
              [group]="product" ctrlName="supplierIncotermId"
              [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
              ></tc-select-search>
          <tc-select-search placeholder="Supplier Incoterm Location"
              [group]="product" ctrlName="supplierIncotermLocationId"
              [items]="locations$ | async" bindValue="location_id" bindLabel="name"
              ></tc-select-search>
        </div>
        <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
          <mat-form-field>
            <mat-label>Weight</mat-label>
            <input matInput type="number" min="0.01" step="0.01" autocomplete="off"
                formControlName="supplierEstWeight"
                (change)="onSupplierWeightChange($index)"
                [matAutocomplete]="supplierEstWeight">
            <mat-autocomplete autoActiveFirstOption #supplierEstWeight="matAutocomplete" (optionSelected)="onSupplierWeightChange($index)" (optionSelected)="onSupplierWeightChange($index)">
              <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <tc-select-search placeholder="Unit"
              [group]="product" ctrlName="supplierMeasureId"
              (change)="onSupplierMeasureChange($index, $event)"
              [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
              ></tc-select-search>
          <mat-form-field>
            <mat-label>Ind. weight</mat-label>
            <input readonly matInput [value]="supplierIndWeight$(product) | async">
          </mat-form-field>
        </div>

        <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
          <mat-form-field>
            <mat-label>Price</mat-label>
            <input matInput type="number" step="0.0001" autocomplete="off"
                formControlName="supplierEstPrice"
                (change)="onSupplierPriceChange(product)">
          </mat-form-field>
          <tc-select-search placeholder="Currency"
              [group]="detailsForm" ctrlName="supplierCurrencyCode"
              (change)="onCurrencyChange()"
              [items]="currencies$ | async" bindValue="code" bindLabel="code"
              ></tc-select-search>
          <mat-form-field>
            <mat-label>Ind. price</mat-label>
            <input readonly matInput [value]="supplierIndPrice$(product) | async">
          </mat-form-field>
        </div>
        <div *ngIf="$index === 0">
          <a *ngIf="canSetSupplierFormula$ | async"
              href="#" class="form-group" (click)="$event.preventDefault(); changeFormula(DealPartyE.supplier)">
            Set Formula Price
          </a>
          <a *ngIf="hasSupplierFormula$ | async as formula"
              href="#" class="form-group" (click)="$event.preventDefault(); changeFormula(DealPartyE.supplier)"
              style="white-space: pre;">
            {{ formula }}
          </a>
        </div>
      </div>

      <div class="tc-parties-right tc-parties-right-reduced">
        <h6 fxLayoutAlign="flex-start center" fxLayoutGap="15px">
          <span>Buyer</span>
          <mat-slide-toggle formControlName="buyerLocked" color="primary">LOCK PRICE</mat-slide-toggle>
          <!-- <span class="text-gray" *ngIf="product.buyer.lastPrice">
            Last price: <span>{{product.buyer.lastPrice.price
              | currency:product.buyer.lastPrice.currency_code:'symbol-narrow':'1.4':'no_TH'
              | measure:product.buyer.lastPrice.measure_id">
            </span>
          </span> -->
        </h6>
        <div gdColumns="minmax(0, 5fr) minmax(0, 2fr) minmax(0, 5fr)" gdGap="15px">
          <tc-address-field placeholder="Invoice Address"
              [group]="product" ctrlName="invoiceAddress"
              [pickerOptions]="invoiceAddressOptions$(product) | async"
              ></tc-address-field>
          <tc-select-search placeholder="Buyer Incoterm"
              [group]="product" ctrlName="buyerIncotermId"
              [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
              ></tc-select-search>
          <tc-select-search placeholder="Buyer Incoterm Location"
              [group]="product" ctrlName="buyerIncotermLocationId"
              [items]="locations$ | async" bindValue="location_id" bindLabel="name"
              ></tc-select-search>
        </div>
        <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
          <mat-form-field>
            <mat-label>Weight</mat-label>
            <input matInput type="number" min="0.01" step="0.01" autocomplete="off"
                formControlName="buyerEstWeight"
                (change)="onBuyerWeightChange($index)"
                [matAutocomplete]="buyerEstWeight">
            <mat-autocomplete autoActiveFirstOption #buyerEstWeight="matAutocomplete" (optionSelected)="onBuyerWeightChange($index)">
              <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <tc-select-search placeholder="Unit"
              [group]="product" ctrlName="buyerMeasureId"
              (change)="onBuyerMeasureChange($index, $event)"
              [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
              ></tc-select-search>
          <mat-form-field>
            <mat-label>Ind. weight</mat-label>
            <input readonly matInput [value]="buyerIndWeight$(product) | async">
          </mat-form-field>
        </div>
        <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
          <mat-form-field>
            <mat-label>Price</mat-label>
            <input matInput type="number" step="0.0001" autocomplete="off"
                formControlName="buyerEstPrice"
                (change)="onBuyerPriceChange(product)">
          </mat-form-field>
          <tc-select-search placeholder="Currency"
              [group]="detailsForm" ctrlName="buyerCurrencyCode"
              (change)="onCurrencyChange()"
              [items]="currencies$ | async" bindValue="code" bindLabel="code"
              ></tc-select-search>
          <mat-form-field>
            <mat-label>Ind. price</mat-label>
            <input readonly matInput [value]="buyerIndPrice$(product) | async">
          </mat-form-field>
        </div>
        <div *ngIf="$index === 0 && ((canSetBuyerFormula$ | async) || (hasBuyerFormula$ | async))" class="form-group">
          <a *ngIf="canSetBuyerFormula$ | async" href="#" (click)="$event.preventDefault(); changeFormula(DealPartyE.buyer)">
            Set Formula Price
          </a>
          <a *ngIf="hasBuyerFormula$ | async as formula" href="#" (click)="$event.preventDefault(); changeFormula(DealPartyE.buyer)">
            {{ formula }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <tc-deal-totals-v2 [mode]="mode" [dealDetails]="detailsForm"></tc-deal-totals-v2>

  <div *ngIf="!mode" gdColumns="minmax(0, 1fr) minmax(0, 2fr) minmax(0, 3fr)">
    <div>
      <button mat-button type="button" *ngIf="canAddProduct$ | async" (click)="addProduct()">
        <i class="fa fa-plus" aria-hidden="true"></i> ADD PRODUCT
      </button>
    </div>
    <div style="text-align: right">
      <button mat-button type="button" [disabled]="!dealId" (click)="addSupplierInstructions()">
        <i class="fa fa-20 fa-file-plus" alt="icon"></i>&nbsp;
        <span *ngIf="!(hasSupplierInstructions$ | async)">ADD SUPPLIER SPECIAL INSTRUCTIONS</span>
        <span *ngIf="hasSupplierInstructions$ | async">EDIT SUPPLIER SPECIAL INSTRUCTIONS</span>
      </button>
      <p *ngIf="hasSupplierInstructions$ | async as supplierInstructions" style="padding-right: 10px">{{supplierInstructions.body}}</p>
    </div>
    <div>
      <button mat-button type="button" [disabled]="!dealId" (click)="addBuyerInstructions()">
        <i class="fa fa-20 fa-file-plus" alt="icon"></i>&nbsp;
        <span *ngIf="!(hasBuyerInstructions$ | async)">ADD BUYER SPECIAL INSTRUCTIONS</span>
        <span *ngIf="hasBuyerInstructions$ | async">EDIT BUYER SPECIAL INSTRUCTIONS</span>
      </button>
      <p *ngIf="hasBuyerInstructions$ | async as buyerInstructions" style="padding-left: 10px">{{buyerInstructions.body}}</p>
    </div>
  </div>
</div>

<div class="tc-deal-costs" *ngIf="!mode">
  <header (click)="$event.detail === 3 ? primaryCostsVisible = !primaryCostsVisible : ''">Deal costs</header>
  <tc-costs-list [types]="primaryCostsVisible ? ['primary', 'secondary', 'tertiary'] : ['secondary', 'tertiary']"
      [units]="productsForm.controls[0].controls.supplierMeasureId.value"
      [dealViewRaw]="dealViewRaw$ | async"
      [oldDealView]="oldDealView$ | async"
      [costsForm]="costsForm"
      [creditNotes]="creditNotes$ | async"
      [vendorCredits]="vendorCredits$ | async"
      [fxRates]="fxRates$ | async"
      [fxRatesRange]="fxRatesAskRange$ | async"
      (updateCost)="updateCost.next($event)"
      (removeCost)="removeCost.next($event)"
      ></tc-costs-list>
</div>

<div class="tc-deal-footer" *ngIf="mode !== 'products'">
  <tc-trading-summary
      [dealForm]="dealForm"
      [dealViewRaw$]="dealViewRaw$"
      ></tc-trading-summary>
  <tc-shipping-summary-v2 class="summary" [formGroup]="detailsForm"></tc-shipping-summary-v2>
</div>
