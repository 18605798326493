import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { ShipmentRate } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('ShipmentRateApi', downgradeInjectable(ShipmentRateApiService))


@Injectable()
@AngularCopy()
export class ShipmentRateApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: ShipmentRate[] }>(`${apiUrl}/shipment-rates`, { params }).toPromise()
  }

  listObs(params: { limit: number }) {
    return this.http.get<{ data: ShipmentRate[] }>(`${apiUrl}/shipment-rates`, { params })
  }

  create(data: Partial<ShipmentRate>) {
    return this.http.post<{ data: ShipmentRate }>(`${apiUrl}/shipment-rates`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: ShipmentRate }>(`${apiUrl}/shipment-rates/${id}`).toPromise()
  }

  update(id: string, data: Partial<ShipmentRate>) {
    return this.http.put<{ data: ShipmentRate }>(`${apiUrl}/shipment-rates/${id}`, data).toPromise()
  }

  clone(data: any) {
    // @see https://tradecafe.postman.co/workspace/TradeCafe~eb673c1f-d8b3-48e9-8841-29ba6c8a2e38/request/2834091-58beda1b-fa8a-4a4f-ad87-b9060c5b68ea
    return this.http.post<{ data: ShipmentRate[] }>(`${apiUrl}/shipment-rates/clone`, data).toPromise()
  }
}
