import { Injectable } from '@angular/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable, ReplaySubject } from 'rxjs'
import { io, Socket } from 'socket.io-client'
import { AuthApiService } from 'src/api/auth'
import { environment } from 'src/environments/environment'

const { lockApiUrl } = environment

export interface LockIdentity {
  user_id: string
  lock_id: string
}

export interface Lock extends LockIdentity {
  expires_at: number
  created_at: number
}

export interface LockAcquiredEvent extends Partial<Lock> {
  resource_locks: LockIdentity[]
}


export interface LockConnection {
  unlock: () => void,
  lock$: Observable<DeepReadonly<LockIdentity[]>>,
  references: number,
}

@Injectable()
export class LockApiService {
  constructor(
    private AuthApi: AuthApiService,
  ) { }

  lock(resourceId: string, observer: boolean = false): LockConnection {
    const socket = io(`${lockApiUrl}/?resource_id=${resourceId}&observer=${observer}`, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${this.AuthApi.token$.value}`,
      },
    })
    const lock = new ReplaySubject<LockIdentity[]>(1)
    let keepaliveInterval
    socket.on('lock:acquired', (e: LockAcquiredEvent) => {
      if(!observer) {
        if (keepaliveInterval) clearTimeout(keepaliveInterval)
        const ttl = (e.expires_at - e.created_at) * 1000 - 3000
        keepaliveInterval = setInterval(() => this.keepalive(socket), ttl)
      }
      lock.next(e.resource_locks)
    })
    socket.on('lock:changed', (changes: LockIdentity[]) => lock.next(changes))
    return {
      lock$: lock.asObservable(),
      unlock: () => {
        if (keepaliveInterval) clearTimeout(keepaliveInterval)
        socket.disconnect()
        lock.next([])
      },
      references: 0,
    }
  }

  private keepalive(socket: Socket) {
    socket.emit('keepalive')
  }
}
