import { isEqual } from 'lodash-es'
import { BehaviorSubject } from 'rxjs'

export class PickerSelection<T> {
  constructor(private selected: T[], private multiple = false) { }
  readonly selected$ = new BehaviorSubject<T[]>(this.selected)

  canSelect = (row: T) => !!row
  isSelected = (row: T) => !!this.selected$.value.find(x => isEqual(x, row))

  toggleRow(row: T) {
    let selected = this.selected$.value
    if (this.multiple) {
      if (this.isSelected(row)) {
        selected = selected.filter(x => !isEqual(x, row))
      } else {
        selected = [...selected, row]
      }
    } else {
      selected = this.isSelected(row) ? [] : [row]
    }
    this.selected$.next(selected)
  }
}
