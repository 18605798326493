import { TableKey } from '@tradecafe/types/core'
import { mapValues, uniq } from 'lodash-es'

export interface FilterDef {
  useUtc?: boolean
}

export const DEALS_FILTER_SETTINGS = {
  buyer_invoice_date: { },
  buyer_invoice_paid: { },
  buyer_invoice_due: { useUtc: true },
  collection_date: { useUtc: true },
  confirmed_at: { },
  date_invoiced: { },
  deal_date: { },
  delivered: { },
  actual_delivery_date: { useUtc: true },
  delivery_date: { useUtc: true },
  dropoff_date: { useUtc: true },
  epd: { useUtc: true },
  erd: { useUtc: true },
  est_delivery_date: { useUtc: true },
  est_pickup_date: { useUtc: true },
  eta: { useUtc: true },
  etd: { useUtc: true },
  load: { },
  onboard: { },
  pickup_date: { useUtc: true },
  shipment_date: { useUtc: true },
  submitted_at: { },
  supplier_invoice_date: { },
  supplier_invoice_paid: { },
  supplier_released: { useUtc: true },
  vessel_cutoff_date: { useUtc: true },
  deringer_te_sent: { },
  deringer_data_received: { },
  deringer_pdf_received: { },
  deringer_approved_at: { },
  montship_pickup_date: { useUtc: true },
  montship_dropoff_date: { useUtc: true },
  montship_etd: { useUtc: true },
  montship_eta: { useUtc: true },
  montship_sent_at: { },
  montship_confirmed_at: { },
  montship_status_at: { },
  aes_request_sent_at: {  },
  aes_approved_at: { useUtc: true },
  aes_responses_count: { },
  aes_has_export_segment: { },
  last_macropoint_request_sent_at: {  },
}

export const DEFAULT_DEALS_FILTERS = {
  // full text search fields
  text: '',
  deal_id: '',

  // table internal filters
  sort: { id: 'deal_date', start: 'desc' as 'asc'|'desc' },
  limit: 10,
  columns: [],

  // selected table view id
  table_view_id: null as string,

  // deal view filters (GET /deals/search)
  actual_cost: [],
  actual_revenue: [],
  actual_delivery_date_from: null,
  actual_delivery_date_to: null,
  art_work: [],
  bol_instructions: [],
  booking_id: [],
  brand: [],
  buy_price: [],
  buyer_id: [],
  buyer_invoice_date_from: null,
  buyer_invoice_date_to: null,
  buyer_invoice_id: [],
  buyer_invoice_paid_from: null,
  buyer_invoice_paid_to: null,
  buyer_invoice_due_from: null,
  buyer_invoice_due_to: null,
  buyer_ref: [],
  buyer: [],
  buying_trader: [],
  carrier: [],
  collection_date_from: null,
  collection_date_to: null,
  color: [],
  consignee: [],
  shipper: [],
  container_number: [],
  coordinator: [],
  courier_track_id: [],
  credit_balance: [],
  date_invoiced_from: null,
  date_invoiced_to: null,
  deal_date_from: null,
  deal_date_to: null,
  deal_ids: [],
  deal_type: [],
  delivered_from: null,
  delivered_to: null,
  delivery_date_from: null,
  delivery_date_to: null,
  dest_location: [],
  docs_country: [],
  documents: [],
  documents_count: [],
  dropoff_date_from: null,
  dropoff_date_to: null,
  dropoff_location: [],
  e_docs_to_customs_broker: [],
  epd_from: null,
  epd_to: null,
  erd_from: null,
  erd_to: null,
  est_id: [],
  estimated_margin: [],
  eta_from: null,
  eta_to: null,
  etd_from: null,
  etd_to: null,
  exact_dropoff_address: [],
  exact_loading_address: [],
  forwarder: [],
  hs_code: [],
  import_no: [],
  inalfresco: [],
  inspection: [],
  invoiced_amt: [],
  invoiced: [],
  item_type: [],
  load_ready: [],
  loading_instructions: [],
  note_comments_public: [],
  note_warning: [],
  onboard_from: null,
  onboard_to: null,
  origin_country: [],
  origin_location: [],
  original_sent: [],
  packages_count: [],
  partial_margin_p: [],
  partial_margin: [],
  payment_terms_buyer: [],
  payment_terms_supplier: [],
  pickup_date_from: null,
  pickup_date_to: null,
  pickup_location: [],
  port_of_discharge: [],
  port_of_loading: [],
  prepayment_received: [],
  prepayment_status: [],
  product_category: [],
  product_type: [],
  product: [],
  rate: [],
  sell_price: [],
  selling_trader: [],
  shipment_date_from: null,
  shipment_date_to: null,
  status: [],
  status_a: [],
  status_b: [],
  status_notes: [],
  supplier_currency: [],
  supplier_estimated_cost: [],
  supplier_invoice_date_from: null,
  supplier_invoice_date_to: null,
  supplier_invoice_id: [],
  supplier_invoice_paid_from: null,
  supplier_invoice_paid_to: null,
  supplier_invoice_status: [],
  secondary_cost_invoice_id: [],
  supplier_ref: [],
  supplier_released_from: null,
  supplier_released_to: null,
  supplier: [],
  temperature: [],
  total_weight: [],
  truck_inspection: [],
  truck_refer_id: [],
  type_of_packages: [],
  us_border: [],
  vessel_cutoff_date_from: null,
  vessel_cutoff_date_to: null,
  vessel: [],
  voyage_no: [],
  wrappings: [],
  freight_rates_amount: [],
  foreign_invoice_number_mx: [],

  inv: [],
  dest_country: [],
  est_pickup_date_from: null,
  est_pickup_date_to: null,
  est_delivery_date_from: null,
  est_delivery_date_to: null,
  vessel_carrier: [],
  submitted_at_from: null,
  submitted_at_to: null,
  confirmed_at_from: null,
  confirmed_at_to: null,
  actual_weight_buy: [],
  actual_weight_sell: [],
  actual_margin_p: [],
  actual_margin: [],
  gross_weight: [],

  deringer_te_sent_from: null,
  deringer_te_sent_to: null,
  deringer_data_received_from: null,
  deringer_data_received_to: null,
  deringer_pdf_received_from: null,
  deringer_pdf_received_to: null,
  deringer_pdf_bonds: [],
  deringer_approved_at_from: null,
  deringer_approved_at_to: null,
  deringer_approved_by: [],
  deringer_notes: [],
  deringer_note_warning: [],

  macropoint_tracking_request_id: [],
  macropoint_order_status_name: [],
  macropoint_order_latest_location_full_address: [],
  macropoint_request_count: null,
  last_macropoint_request_sent_at_from: null,
  last_macropoint_request_sent_at_to: null,

  montship_pickup_date_from: null,
  montship_pickup_date_to: null,
  montship_dropoff_date_from: null,
  montship_dropoff_date_to: null,
  montship_etd_from: null,
  montship_etd_to: null,
  montship_eta_from: null,
  montship_eta_to: null,
  montship_sent_at_from: null,
  montship_sent_at_to: null,
  montship_confirmed_at_from: null,
  montship_confirmed_at_to: null,
  montship_status_at_from: null,
  montship_status_at_to: null,
  montship_count: [],
  montship_exact_loading: [],

  aes_number: null,
  aes_responses_count: null,
  aes_request_sent_at_from: null,
  aes_request_sent_at_to: null,
  aes_approved_at_from: null,
  aes_approved_at_to: null,
  aes_status: null,
  aes_has_export_segment: [],

  buyer_incoterm: [],
  buyer_incoterm_location: [],
  supplier_incoterm: [],
  supplier_incoterm_location: [],
}


// filters placed at the top of the deals list page
export const TOP_LEVEL_FILTERS = {
  [TableKey.TradingDealsListPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'status',
    'deal_date_from',
    'deal_date_to',
    'product',
    'product_type',
    'product_category',
    'origin_country',
    'deal_type',
  ],
  [TableKey.ManagersDealsPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'status',
    'deal_date_from',
    'deal_date_to',
    'product',
    'product_type',
    'product_category',
    'origin_country',
    'deal_type',
  ],
  [TableKey.TradingShippingLogPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'product',
    'carrier',
    'origin_country',
    'status',
    'product_category',
    'product_type',
    'deal_type',
    'dest_country',
    'origin_country',
    'product_category',
    'origin_country',
    'status',
    'deal_type',
    'deal_date_from',
    'deal_date_to',
    'submitted_at_from',
    'submitted_at_to',
  ],
  [TableKey.LogisticsShippingLogPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'product',
    'coordinator',
    'selling_trader',
    'carrier',
    'deal_date_from',
    'deal_date_to',
    'pickup_date_from',
    'pickup_date_to',
    'actual_delivery_date_from',
    'actual_delivery_date_to',
    'collection_date_from',
    'collection_date_to',
    'onboard_from',
    'onboard_to',
    'vessel_cutoff_date_from',
    'vessel_cutoff_date_to',
    'origin_country',
    'docs_country',
    'status',
    'product_category',
    'status_notes',
    'deal_type',
    'booking_id',
    'container_number',
    'supplier_invoice_id',
    'secondary_cost_invoice_id',
    'macropoint_tracking_request_id',
    'macropoint_order_status_name',
    'macropoint_order_latest_location_full_address',
  ],
  [TableKey.IntegrationsDeringerPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'product',
    'coordinator',
    'selling_trader',
    'carrier',
    'deal_date_from',
    'deal_date_to',
    'pickup_date_from',
    'pickup_date_to',
    'actual_delivery_date_from',
    'actual_delivery_date_to',
    'collection_date_from',
    'collection_date_to',
    'onboard_from',
    'onboard_to',
    'vessel_cutoff_date_from',
    'vessel_cutoff_date_to',
    'origin_country',
    'docs_country',
    'status',
    'product_category',
    'status_notes',
    'deal_type',
    'booking_id',
    'container_number',
    'supplier_invoice_id',
    'secondary_cost_invoice_id',

    // same as shipping log + new deringer specific filters
    'deringer_te_sent_from',
    'deringer_te_sent_to',
    'deringer_data_received_from',
    'deringer_data_receved_to',
    'deringer_pdf_received_from',
    'deringer_pdf_received_to',
  ],
  [TableKey.IntegrationsMontshipPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'product',
    'coordinator',
    'selling_trader',
    'carrier',
    'deal_date_from',
    'deal_date_to',
    'pickup_date_from',
    'pickup_date_to',
    'actual_delivery_date_from',
    'actual_delivery_date_to',
    'collection_date_from',
    'collection_date_to',
    'onboard_from',
    'onboard_to',
    'vessel_cutoff_date_from',
    'vessel_cutoff_date_to',
    'origin_country',
    'docs_country',
    'status',
    'product_category',
    'status_notes',
    'deal_type',
    'booking_id',
    'container_number',
    'supplier_invoice_id',
    'secondary_cost_invoice_id',
  ],
  [TableKey.IntegrationsAESPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'product',
    'coordinator',
    'selling_trader',
    'carrier',
    'deal_date_from',
    'deal_date_to',
    'pickup_date_from',
    'pickup_date_to',
    'actual_delivery_date_from',
    'actual_delivery_date_to',
    'collection_date_from',
    'collection_date_to',
    'onboard_from',
    'onboard_to',
    'vessel_cutoff_date_from',
    'vessel_cutoff_date_to',
    'origin_country',
    'docs_country',
    'status',
    'product_category',
    'status_notes',
    'deal_type',
    'booking_id',
    'container_number',
    'supplier_invoice_id',
    'secondary_cost_invoice_id',
    'aes_request_sent_at_from',
    'aes_request_sent_at_to',
    'aes_approved_at_from',
    'aes_approved_at_to',
    'aes_number',
    'aes_has_export_segment'
  ],
  [TableKey.IntegrationsMacropointPage]: [
    'text',
    'deal_id',
    'buyer',
    'supplier',
    'product',
    'macropoint_tracking_request_id',
    'macropoint_order_status_name',
    'macropoint_order_latest_location_full_address',
    'macropoint_request_count',
    'last_macropoint_request_sent_at_from',
    'last_macropoint_request_sent_at_to',
    'deal_type',
  ],
  [TableKey.DealInventory]: [
    'deal_id',
    'container_number',
    'shipment_date',
    'supplier',
    'buy_price',
    'origin_location',
    'origin_country',
    'buying_trader',
    'selling_trader',
    'product',
    'deal_type',
    'status'
  ]
}


export const TOP_LEVEL_FILTERS_COLUMNS = mapValues(TOP_LEVEL_FILTERS, (filters, tableKey) =>
  uniq(filters.map(filter => filter.replace(/_to$/, '').replace(/_from$/, ''))))
