import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { ProductCategory } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as ProductCategoryActions from './product-category.actions'

export const productCategoriesFeatureKey = 'productCategories'
export interface ProductCategoriesState extends EntityState<DeepReadonly<ProductCategory>> {
}

export const selectId = (productCategory: ProductCategory) => productCategory.category_id
export const adapter = createEntityAdapter<DeepReadonly<ProductCategory>>({ selectId })

const initialState: ProductCategoriesState = adapter.getInitialState({
})

export const productCategoriesReducer = createReducer(
  initialState,
  on(ProductCategoryActions.loadProductCategoriesSuccess,
    (state, action) =>
      adapter.upsertMany(action.productCategories, state)),
)

