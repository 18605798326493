import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Cost, DEAL_CONFIRMED, DEAL_DRAFT, DealRow, MatchedOffer, TableKey } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { forEach, identity, isEqual, pick } from 'lodash-es'
import { Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadDealFilters, loadDealViews, setDestLocationSuccess, setOriginLocationSuccess, setSegmentDateSuccess } from 'src/app/store/deal-view.actions'
import { selectDealsFilters, selectDealsTable, selectDealsTotals } from 'src/app/store/deal-view.reducer'
import { AVAILABLE_COLUMNS, DEFAULT_COLUMNS, DealsListComponent, columnDefsById, columnNames } from 'src/components/deals-list'
import { DEFAULT_DEALS_FILTERS, TOP_LEVEL_FILTERS_COLUMNS } from 'src/components/deals-list/deals-list.filters'
import { DealFormPageOverlayService } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form-page-overlay/deal-form-page-overlay.component.service'
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service'
import { FilterDataSelect, FilterDataSelectOption } from 'src/services/elastic-search'
import { FiltersService, TABLE_FILTERS_FIELDS } from 'src/services/table-utils'
import { ApiTableDataSource } from 'src/services/table-utils/data-sources/api-table-data-source'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'

export interface InventoryDealsFormOptions {}

@Component({
  selector: 'tc-inventory-deals',
  templateUrl: './inventory-deals-form.component.html',
  styleUrls: ['./inventory-deals-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryDealsFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private elastic: DealElasticSearchService,
    private toaster: ToasterService,
    private dealFormPageOverlayService: DealFormPageOverlayService,
    private AuthApi: AuthApiService,
    private Filters: FiltersService,
    private store: Store,
    private actions$: Actions,
    private dialogRef: MatDialogRef<InventoryDealsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { super() }

  // page configuration
  tableIdentity = TableKey.DealInventory

  selectedDeals: DeepReadonly<DealRow[]> = []
  // table configurations
  readonly columnDefs = columnDefsById(this.tableIdentity)
  readonly availableColumns = AVAILABLE_COLUMNS[this.tableIdentity]
  readonly columnNames = columnNames(this.tableIdentity)

  filtersForm = this.Filters.buildForm(this, {
    key: this.tableIdentity,
    cacheFilters: false,
    defaultValues: {
      ...DEFAULT_DEALS_FILTERS,
      columns: DEFAULT_COLUMNS[this.tableIdentity],
      product: [this.data.product],
      status: [DEAL_DRAFT, DEAL_CONFIRMED],
      selling_trader: this.AuthApi.currentUser.role === 'trader' ? [this.AuthApi.currentUser.user_id] : [],
      deal_type: "bwi"
    },
  })
  // dropdown options (filters)
  supplier$ = new Subject<FilterDataSelectOption[]>()
  selling_trader$ = new Subject<FilterDataSelectOption[]>()

  displayColumns$ = replayForm(this.filtersForm.controls.columns)

  // data, ros and totals
  totals$ = this.store.select(selectDealsTotals(this.tableIdentity))
  filtersData$ = this.store.select(selectDealsFilters(this.tableIdentity))
  dataSource: ApiTableDataSource<DeepReadonly<DealRow>, any>

  @ViewChild(DealsListComponent)
  dealsList: DealsListComponent

  // NOTE: we don't apply backend filters "on change"
  private flushBackendFilter$ = new Subject<{ force: boolean }>()
  private filtersForm$ = replayForm(this.filtersForm)

  // data, rows and totals
  private deals$ = this.store.select(selectDealsTable(this.tableIdentity))

  ngOnInit() {
    // if user is searching by deal_id - disable all other filters
    this.filtersForm$
      .pipe(
        map((form) => !!form.deal_id),
        distinctUntilChanged(),
        untilComponentDestroyed(this)
      )
      .subscribe((searchDealId) => {
        forEach(this.filtersForm.controls, (control, name) => {
          if (control === this.filtersForm.controls.deal_id) return
          if (TABLE_FILTERS_FIELDS.includes(name)) return
          if (name === 'table_view_id') return
          if (searchDealId) control.disable()
          else control.enable()
        })
      })

    // load "top" filters data
    this.elastic
      .fetchDealFilters({ columns: TOP_LEVEL_FILTERS_COLUMNS[this.tableIdentity] })
      .pipe(untilComponentDestroyed(this))
      .subscribe((filtersData: Dictionary<FilterDataSelect>) => {
        this.supplier$.next(filtersData.supplier.options)
        this.selling_trader$.next(filtersData.selling_trader.options)
      })

    // trigger data reload
    const trigger$ = this.flushBackendFilter$.pipe(
      withLatestFrom(this.filtersForm$),
      distinctUntilChanged(([, a], [{force} = {force: false}, b]) =>
        // skip not forced triggers if filter form is pristine
        !force && isEqual(a, b)),
      map(([, filters]) => filters))

    // setup data source
    this.dataSource = new ApiTableDataSource<DeepReadonly<DealRow>, any>(
      this.deals$.pipe(filter(identity)),
      this.filtersForm,
      trigger$
    )

    // listen for fetch commands - fetch data
    this.dataSource.fetch$
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ filters, page }) => {
        this.store.dispatch(
          loadDealViews({
            tableKey: this.tableIdentity,
            page,
            filters: {
              ...filters,
              product: [this.data.product],
              status: [DEAL_DRAFT, DEAL_CONFIRMED],
              deal_type: "bwi",
              forceQuery: true
            },
            calculate: false,
            populate_intransit_balance: false,
          })
        )
      })

    // reload filters
    trigger$.pipe(untilComponentDestroyed(this)).subscribe((filters) =>
      this.store.dispatch(
        loadDealFilters({
          tableKey: this.tableIdentity,
          filters,
        })
      )
    )

    // listen for columns, sort and pagination changes - apply backend filters
    this.filtersForm.valueChanges
      .pipe(
        distinctUntilChanged((a, b) =>
          isEqual(pick(a, TABLE_FILTERS_FIELDS), pick(b, TABLE_FILTERS_FIELDS))
        ),
        untilComponentDestroyed(this)
      )
      .subscribe(() => this.applyFilters())

      this.actions$.pipe(ofType(
        setOriginLocationSuccess,
        setDestLocationSuccess,
        setSegmentDateSuccess,
      ), untilComponentDestroyed(this)).subscribe(() => {
        this.reloadPage()
      })

    // NOTE: dispatch query action
    this.reloadPage()
  }

  applyFilters() {
    this.flushBackendFilter$.next({ force: false })
  }

  openDeal() {
    if (this.selectedDeals?.length) {
      const [deal] = this.selectedDeals
      const {user_id: dealSellingTraderUserId, firstname, lastname} = deal["selling_trader"]
      const { user_id: currentUserId } = this.AuthApi.currentUser
      if (dealSellingTraderUserId != currentUserId) {
        this.toaster.error(`You are not the selling trader on this BWI - Inventory deal. Please contact ${firstname} ${lastname}.`)
        return
      }
      const { offer: { buyers: defaultBuyers }, costs } = this.data.matchedOffer as MatchedOffer;
      this.dealsList.createSupplierOffer(this.selectedDeals[0], { defaultBuyers, costs: costs as Cost[] })
    }
  }

  dealIdClick(deal_id: string) {
    this.dealFormPageOverlayService.showDealFormPageOverlay(deal_id)
  }

  dealSelectionChange(deals: DeepReadonly<DealRow[]>) {
    this.selectedDeals = deals
  }



  cancel() {
    this.dialogRef.close()
  }

  private reloadPage() {
    this.flushBackendFilter$.next({ force: true })
  }
}
