<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;{{ title }}
</h2>

<form [formGroup]="tmpForm" (submit)="save()">
  <tc-costs-list
    [dealViewRaw]="dealViewRaw$ | async"
    [vendorCredits]="vendorCredits$ | async"
    [creditNotes]="creditNotes$ | async"
    [costsForm]="costsForm"
    [fxRates]="fxRates$ | async"
    [fxRatesRange]="fxRatesAskRange$ | async"
    [oldDealView]="oldDealView$ | async"
    [filter]="filter"
    [displayColumns]="[
      'select',
      'type',
      'service',
      'provider',
      'createdBy',
      'actAmt',
      'estAmt',
      'fxRate',
      'actAmtCAD',
      'estAmtCAD',
      'partialAmtCAD',
      'costUnit',
      'invoiceStatus',
      'ellipsisMenu'
    ]"
  ></tc-costs-list>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="button" (click)="proceedWithout()">
      <i class="fa fa-fw" aria-hidden="true" [class.fa-save]="true"></i>
      Proceed Without
    </button>
    <button mat-raised-button type="button" color="primary" (click)="save()">
      <i class="fa fa-fw" aria-hidden="true" [class.fa-save]="true"></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
