import { Injectable } from '@angular/core'
import { AccountObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalProxyService } from 'src/shared/modal'
import { AccountsService } from '../data/accounts.service'

@Injectable()
export class CompanyCreditFormService {
  constructor(
    private modalHelper: ModalProxyService,
    private Accounts: AccountsService,
  ) {}

  async showCompanyCredit(company: DeepReadonly<AccountObject>|number|string, editable = true) {
    if (typeof company === 'number' || typeof company === 'string') {
      company = await this.Accounts.getAccountById(company)
    }
    return this.modalHelper.showModal({
      component: 'tcCompanyCreditForm',
      windowClass: 'modalShipping modal-company-info',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        modalSetting: () => {
          return {
            title: 'Check Credit',
          }
        },
        editable: () => editable,
        company: () => angular.copy(company),
      },
    })
  }
}
