import { MatPaginator } from '@angular/material/paginator'
import { MatSort, MatSortable } from '@angular/material/sort'
import { identity, isEqual } from 'lodash-es'
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators'
import { FiltersFormGroup, TableFilters } from '../filters.interfaces'
import { filtersActive } from '../filters.service'
import { StatefulDataSource } from './stateful-data-source'
import { TableDataStatus } from './table-data-status'

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 75, 100]
export const DEFAULT_PAGE_SIZE = 10

export class ApiTableDataSource<T, F extends TableFilters> extends StatefulDataSource<T> {

  fetchSubscription: Subscription
  dataSubscription: Subscription
  state$ = new BehaviorSubject<TableDataStatus>('loading')

  private paginator$ = new ReplaySubject<MatPaginator>(1)
  private sort$ = new ReplaySubject<MatSort>(1)
  sorttt: MatSort // NOTE don't override MatTableDataSource::sort

  private refresh$ = new BehaviorSubject<void>(undefined)
  fetch$ = new Subject<{ filters: F, page: number }>()

  constructor(
    source$: Observable<T[]>,
    private filtersForm: FiltersFormGroup<F>,
    trigger: Observable<{}>,
  ) {
    super()

    const pageIndex$ = this.paginator$.pipe(
      switchMap(paginator => paginator.page.asObservable()),
      map(e => e.pageIndex),
      startWith(0),
      distinctUntilChanged())

    const data$ = combineLatest([
      source$,
      this.paginator$.pipe(take(1)),
      this.sort$.pipe(take(1)),
    ]).pipe(map(([x]) => x), filter(identity))

    this.fetchSubscription = combineLatest([trigger, this.refresh$]).pipe(
      map(() => {
        if (this.paginator) this.paginator.pageIndex = 0
        // NOTE: multiply by 2 = preload next page
        const filters = { ...filtersForm.value, limit: filtersForm.value.limit /* * 2 */ }
        this.fetch$.next({ filters, page: 0 })
        return filtersForm.value
      }),
      switchMap(filters => pageIndex$.pipe(
        filter(identity),
        distinctUntilChanged(),
        tap(page =>
          // NOTE: add 1 = preload next page
          this.fetch$.next({ filters, page: page /* + 1 */ })))),
    ).subscribe()

    this.dataSubscription = combineLatest([
      combineLatest([source$, filtersActive(filtersForm)]).pipe(
        map(([data, filters]): TableDataStatus => {
          if (!data) return 'loading'
          if (data.length) return 'has-data'
          if (filters) return 'no-match'
          return 'no-data'
        }),
        distinctUntilChanged(),
        tap(state => this.state$.next(state))),

      data$.pipe(tap(data => {
        this.data = data
      })),

      this.paginator$.pipe(
        switchMap(paginator => paginator.page.asObservable()),
        filter(e => this.filtersForm.value.limit !== e.pageSize),
        tap(e => {
          this.filtersForm.patchValue({ limit: e.pageSize } as Partial<F>)
        })),

      this.sort$.pipe(
        switchMap(sort => sort.sortChange),
        tap(({ active, direction }) => {
          const sort = { id: active, start: direction }
          if (isEqual(this.filtersForm.value.sort, sort)) return
          this.filtersForm.patchValue({ sort } as Partial<F>)
        })),

      this.filtersForm.get('sort').valueChanges.pipe(tap(sortForm => {
        const { active, direction } = this.sorttt
        const sort = { id: active, start: direction }
        if (isEqual(sortForm, sort)) return
        this.sorttt.sort(sortForm)
      })),

      this.filtersForm.get('limit').valueChanges.pipe(tap(limit => {
        const { pageSize } = this.paginator
        if (limit !== pageSize) this.paginator.pageSize = limit
      })),
    ]).subscribe()
  }

  disconnect() {
    this.dataSubscription.unsubscribe()
    this.fetchSubscription.unsubscribe()
  }

  setPaginator(paginator: MatPaginator) {
    if (!paginator || paginator === this.paginator) return
    paginator.pageSizeOptions = PAGE_SIZE_OPTIONS
    paginator.showFirstLastButtons = true
    paginator.pageSize = this.filtersForm.value.limit || DEFAULT_PAGE_SIZE
    this.paginator = paginator
    this.paginator$.next(paginator)
  }

  setSort(sort: MatSort) {
    if (!sort || sort === this.sorttt) return
    // set sort
    const sortable = (this.filtersForm.value.sort || { id: '', start: 'asc' }) as MatSortable
    sort.sort(sortable)

    // set sort
    this.sorttt = sort
    this.sort$.next(sort)
  }

  refresh() {
    this.refresh$.next()
  }
}
