import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Deal, DealViewBase } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { filter, keyBy, mapValues, omit, values } from 'lodash-es'
import { map } from 'rxjs/operators'
import { selectAllUsers } from 'src/app/store/users'
import { environment } from 'src/environments/environment'
import { waitNotEmpty } from 'src/services/data/utils'
import { ToasterService } from '../../../../../shared/toaster/toaster.service'

const { tradecafeAccount } = environment
export interface SendConfirmationFormOptions {
  description: string
  description2: string
  confirmButtonText: string
  suggestClonesOf: DeepReadonly<Array<Deal|DealViewBase>>,
  sentAlready: boolean

  omitUserIds: DeepReadonly<string[]>
  account: number
}

export interface SendConfirmationFormResult {
  userIds: string[]
  confirmForClones: Record<string, boolean>
}

@Component({
  selector: 'tc-send-confirmation-form',
  templateUrl: './send-confirmation-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendConfirmationFormComponent {
  constructor(
    private dialogRef: MatDialogRef<SendConfirmationFormComponent, SendConfirmationFormResult>,
    private store: Store,
    private toaster: ToasterService,
    @Inject(MAT_DIALOG_DATA) private options: SendConfirmationFormOptions,
  ) { }

  confirmForm = new UntypedFormGroup({
    selectedUsers: new UntypedFormControl([]),
    confirmForClones: new UntypedFormGroup(mapValues(keyBy(this.options.suggestClonesOf, 'deal_id'), () =>
      new UntypedFormControl())),
  })
  // options
  description = this.options.description
  description2 = this.options.description2
  confirmButtonText = this.options.confirmButtonText
  suggestClonesOf = this.options.suggestClonesOf
  sentAlready = this.options.sentAlready

  users$ = this.store.pipe(
    select(selectAllUsers), waitNotEmpty(),
    map(users => {
      const omitUserIds = this.options.omitUserIds || []
      const account = this.options.account || tradecafeAccount
      return values(omit(keyBy(filter(users, { account }), 'user_id'), omitUserIds))
    }))

  cancel() {
    this.dialogRef.close()
  }

  save() {
    this.confirmForm.markAllAsTouched()
    if (!this.confirmForm.valid) return
    this.dialogRef.close({
      userIds: this.confirmForm.value.selectedUsers,
      confirmForClones: this.confirmForm.value.confirmForClones,
    })
    this.toaster.success('The confirmation is in progress. It takes some time.')
  }
}
