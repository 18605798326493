import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { ToasterService } from 'src/shared/toaster/toaster.service'

import { loadAccounts } from 'src/app/store/accounts'
import { loadLocations } from 'src/app/store/locations'
import { loadProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes } from 'src/app/store/product-types'
import { loadProducts } from 'src/app/store/products'
import { AccountObject, DistributionListObject } from '@tradecafe/types/core'
import { loadUsers } from 'src/app/store/users'

export interface DistributionListOverlayOptions {
  company: AccountObject,
  distributionList: DistributionListObject
}

@Component({
  selector: 'tc-distribution-list-overlay',
  templateUrl: './distribution-list-overlay.component.html',
  styleUrls: ['./distribution-list-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DistributionListOverlayComponent extends OnDestroyMixin implements OnInit {
  @ViewChild('tcDistributionList') tcDistributionList

  protected company: AccountObject
  protected distributionList: DistributionListObject

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private dialogData: DistributionListOverlayOptions,
    private dialogRef: MatDialogRef<DistributionListOverlayOptions>,
    protected toaster: ToasterService,
  ) {
    super()

    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadProducts({}))
    this.store.dispatch(loadProductCategories({}))
    this.store.dispatch(loadProductTypes({}))
    this.store.dispatch(loadUsers({}))
  }

  ngOnInit() {
    this.company = this.dialogData?.company
    this.distributionList = this.dialogData?.distributionList
  }

  save() {
    let distributionList = this.tcDistributionList.getDistributionList()
    if (distributionList.name === '') {
      this.toaster.warning('Please fill distribution list name')
    } else {
      this.dialogRef.close(distributionList)
    }
  }

  cancel() {
    this.dialogRef.close()
  }
}
