import { Injectable } from '@angular/core'
import { Cost, DealViewInvoices, Invoice, INVOICE_DENIED, INVOICE_IN_REVIEW, INVOICE_NEEDS_INFO } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, find, get, groupBy, keyBy, pick, uniq } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { CostApiService } from 'src/api/cost/cost'
import { environment } from 'src/environments/environment'
import { SegmentFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { ShipmentRateRow } from 'src/pages/admin/trading/deal-form/deal-shipping/shipment-rate-picker/shipment-rate-picker.component'
import { assert } from '../assert'
import { chunked } from './utils'

const { tradecafeAccount } = environment
const QUERY_ALL = { limit: Number.MAX_SAFE_INTEGER }
const ALLOWED_FIELDS = ['type', 'amount', 'service', 'provider', 'status', 'attributes', 'product_id']


/**
 * canActualize - if cost is not associated with an invoice or if invoice is voided or in progress
 *
 * @export
 * @param {DeepReadonly<Cost>} cost
 * @param {DeepReadonly<Invoice[]>} dealInvoices
 * @return {boolean}
 */
export function canActualizeCost(cost: DeepReadonly<Partial<Cost>>, dealInvoices?: DeepReadonly<Invoice[]>): boolean {
  // no, if actualized to zero
  if (cost.status === 'actualized' && cost.attributes.associated) return false
  const invoiceId = cost.attributes?.associated?.invoice_id
  // yes, if no invoice
  if (!invoiceId) return true
  const invoice = dealInvoices?.find(i => i.invoice_id === invoiceId)
  if (!invoice) return true
  // yes, if invoice is not active
  return [INVOICE_DENIED, INVOICE_IN_REVIEW, INVOICE_NEEDS_INFO].includes(invoice.status)
}

export function canUnactualizeCost(cost: DeepReadonly<Partial<Cost>>) {
  return cost.status === 'actualized' && cost.attributes.associated && !cost.attributes.associated.invoice_id
}

/**
 * Costs service
 *
 * @see https://docs.google.com/document/d/1rrFgEUwYQewpAEDVZe2cr2mhxYwrky0-c0JoJP47vek
 *
 * @export
 * @returns
 */
@Injectable()
export class CostsService {
  constructor(
    private AuthApi: AuthApiService,
    private CostApi: CostApiService,
  ) {}

  buildFromRate = buildCostFromRate
  /** @deprecated use exported function `isActualized` */
  isActualized = isCostActualized

  /**
   * Get multiple costs by cost_ids
   *
   * @param {*} costIds
   * @returns
   */
  async getCostsByIds(costIds) {
    if (!costIds.length) return {}
    return this.CostApi.getByIds(uniq(costIds))
    .then(r => keyBy(compact(r.data), 'cost_id'))
  }

  /**
   * Get one exact cost by id
   *
   * @param {*} costId
   * @returns
   */
  getCostById(costId) {
    return this.CostApi.get(costId).then(r => r.data)
  }

  /**
   * Get costs by multiple deal ids
   *
   * @param {*} deal_ids
   * @returns
   */
  async getByDealIds(dealIds) {
    if (!dealIds.length) return {}
    const data = await chunked(dealIds, 400, deal_ids =>
      this.CostApi.getByDealIds({ deal_ids, limit: Number.MAX_SAFE_INTEGER }).then(r => r.data))
    return groupBy(data, 'deal_id')
  }

  /**
   * Load deal costs
   *
   * @param {any} dealId
   * @returns {Promise} of an array with deal costs
   */
  async getForDeal(dealId: string) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.CostApi.getForDeal(account, dealId, QUERY_ALL)
    // TODO: handle 404 explicitly or remove when costs API will be ready
    .catch(() => ({data: []}))
    data.forEach((cost) => {
      assert('cost.provider = "undefined"', cost.provider !== 'undefined')
    })
    return data
  }

  /**
   * Create new deal costs document
   *
   * @param {any} dealId
   * @param {any} cost
   * @param {any} opts { skip_calculations }
   * @returns
   */
  async createFor(dealId: string, cost: Partial<Cost>, opts?) {
    cost.provider = cost.provider ? '' + cost.provider : undefined // :facepalm:
    const { account, user_id } = this.AuthApi.currentUser
    const payload = pick(cost, ALLOWED_FIELDS)
    payload.account = '' + account // TODO: WA-286
    payload.deal_id = dealId
    // TODO: remove this when costs API will be ready
    payload.creator_id = user_id
    const { data } = await this.CostApi.create(account, payload, opts)
    return Object.assign(cost, data)
  }

  /**
   * Update costs
   *
   * @param {any} cost
   * @param {any} opts { skip_calculations }
   * @returns
   */
  async update(cost: Partial<Cost>) {
    cost.provider = cost.provider ? '' + cost.provider : undefined // :facepalm:
    const { account } = this.AuthApi.currentUser // TODO: WA-286
    const payload = pick(cost, ALLOWED_FIELDS)
    const { data } = await this.CostApi.update(account, cost.cost_id, payload)
    return Object.assign(cost, data)
  }

  /**
   * Remove costs document
   *
   * @param {any} dealId
   * @param {any} cost
   * @returns
   */
  async remove({cost_id}: {cost_id: string}, opts?) {
    const { data } = await this.CostApi.delete(cost_id, opts)
    return data
  }

  async actualizeToZero(cost: DeepReadonly<Cost>) {
    const { data } = await this.CostApi.update(tradecafeAccount, cost.cost_id, actualizeToZeroPatch(cost))
    return data
  }

  async unactualizeToZero(cost: DeepReadonly<Cost>) {
    const { associated, ...attributes } = cost.attributes
    const { data } = await this.CostApi.update(tradecafeAccount, cost.cost_id, {
      status: 'pending',
      attributes,
    })
    return data
  }
}

export function isCostActualized(dealView: DeepReadonly<DealViewInvoices>, cost: DeepReadonly<Pick<Cost, 'cost_id'>>) {
  return dealView.invoices?.find(invoice =>
    invoice.attributes.actualized && invoice.status !== INVOICE_DENIED &&
    find(invoice.attributes.costs, { cost_id: cost.cost_id }))
}

/**
 * Build cost based on freight rate
 *
 * @param {any} rate
 * @param {any} {carrier, product}
 * @returns
 */
export function buildCostFromRate(rate: ShipmentRateRow, {carrier, product}): Partial<Cost> {
  const service = {
    rail: 'Rail',
    land: 'Land',
    air: 'Air',
    sea: 'Sea',
  }[rate.type] || 'Freight'
  return {
    status: 'pending',
    type: 'tertiary',
    amount: {
      total: rate.rate.amount,
      currency: rate.rate.currency,
      total_cad: rate.amountCAD,
    },
    provider: get(carrier, 'account'),
    service,
    product_id: get(product, 'product_id'),
    attributes: {
      rate_id: rate.rate_id,
      rate_created: rate.created,
      rate_until: rate.until,
      freight_type: rate.type,
    },
  }
}

export function buildCostFromSegment(segmentForm: SegmentFormGroup, isDealConfirmed: boolean): Partial<Cost> {
  const productMap = {
    land: environment.freightTruckProductId,
    sea: environment.freightVesselProductId,
    air: environment.freightAirplaneProductId,
    rail: environment.freightTrainProductId,
  }

  return {
    attributes: {},
    type: 'tertiary',
    status: 'pending',
    amount: {
      currency: segmentForm.value.currencyCode,
      total: isDealConfirmed ? 0  : segmentForm.value.amount,
    },
    provider: segmentForm.value.carrierAccountId,
    product_id: productMap[segmentForm.value.type],
  }
}

// export function actualizeToZeroPatch(cost: Pick<Cost, 'amount'|'attributes'>): Pick<Cost, 'status'|'attributes'>
export function actualizeToZeroPatch<T extends DeepReadonly<Cost> = DeepReadonly<Cost>>(cost: Pick<T, 'amount'|'attributes'>): Pick<T, 'status'|'attributes'> {
  return {
    status: 'actualized',
    attributes: {
      ...cost.attributes,
      associated: {
        invoice_id: '',
        amount: 0,
        currency: cost.amount.currency,
      },
    },
  }
}

export function unactualizeToZeroPatch<T extends DeepReadonly<Cost> = DeepReadonly<Cost>>(cost: Pick<T, 'attributes'>): Pick<T, 'status'|'attributes'> {
  const { associated, ...attributes } = cost.attributes
  return { status: 'pending', attributes: { ...attributes } }
}
