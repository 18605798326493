import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Country } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { keyBy } from 'lodash-es'
import * as CountryActions from './country.actions'

export const countriesFeatureKey = 'countries'
export interface CountriesState extends EntityState<DeepReadonly<Country>> {
  byCode: Dictionary<DeepReadonly<Country>>
}

export const selectId = (country: Country) => country.country_id
export const adapter = createEntityAdapter<DeepReadonly<Country>>({ selectId })

const initialState: CountriesState = adapter.getInitialState({
  byCode: {},
})

export const countriesReducer = createReducer(
  initialState,
  on(CountryActions.loadCountriesSuccess,
    (state, action) => {
      state = adapter.upsertMany(action.countries, state)
      return { ...state, byCode: keyBy(action.countries, 'code') }
    }),
)

