import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { AddressPickerModule } from '../address-picker/address-picker.module'
import { ReactiveAsteriskModule } from '../reactive-asterisk/reactive-asterisk.module'
import { AddressFieldValueDirective } from './address-field-value.directive'
import { AddressFieldComponent } from './address-field.component'

@NgModule({
  declarations: [AddressFieldComponent, AddressFieldValueDirective],
  exports: [AddressFieldComponent, AddressFieldValueDirective],
  imports: [
    AddressPickerModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    PipesModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
  ],
})
export class AddressFieldModule { }
