import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { CustomTag } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'

const { apiUrl } = environment

export function registerNg1(module) {
  return module.service('CustomTagApi', downgradeInjectable(CustomTagApiService))
}

@Injectable()
@AngularCopy()
export class CustomTagApiService {
  constructor(private http: HttpClient) { }

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: CustomTag[] }>(`${apiUrl}/custom-tag`, { params }).toPromise()
  }

  create(data: Partial<CustomTag>) {
    return this.http.post<{ data: CustomTag }>(`${apiUrl}/custom-tag`, data).toPromise()
  }

  get(id: string) {
    return this.http.get<{ data: CustomTag }>(`${apiUrl}/custom-tag/${id}`).toPromise()
  }

  update(id: string, data: Partial<CustomTag>) {
    return this.http.put<{ data: CustomTag }>(`${apiUrl}/custom-tag/${id}`, data).toPromise()
  }

  delete(id: string) {
    return this.http.delete<{ data: CustomTag }>(`${apiUrl}/custom-tag/${id}`).toPromise()
  }
}
