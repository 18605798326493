import { AbstractControl, FormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { TypedFormArray, TypedFormControl, TypedFormGroup } from './typed-forms'

export function replayForm<T>(form: FormControl<T>): Observable<T>
export function replayForm<T, F extends AbstractControl = TypedFormGroup<T>>(form: TypedFormArray<T, F>): Observable<T[]>
export function replayForm<T>(form: TypedFormGroup<T> | TypedFormControl<T>): Observable<T>
// tslint:disable-next-line: unified-signatures
export function replayForm<T = any>(form: AbstractControl): Observable<T>
export function replayForm<T = any, F extends AbstractControl = TypedFormGroup<T>>(form: F | UntypedFormGroup | AbstractControl): Observable<T> {
  // NOTE: can't `startWith(form.value)` as it will start from the very first form value. we want it to fire modified value on subscription
  return form.valueChanges.pipe(startWith(undefined as T), map(() =>
    form instanceof UntypedFormGroup || form instanceof UntypedFormArray
      ? form.getRawValue() as T
      : form.value as T))
}

export function replayFormStatus(form: AbstractControl): Observable<string> {
  return form.statusChanges.pipe(startWith(''), map(() => form.status))
}
