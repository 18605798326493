import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { from, of } from 'rxjs'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { AccountsService } from 'src/services/data/accounts.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { loadAccounts, loadAccountsFailure, loadAccountsSuccess, reassignAccounts, reassignAccountsFailure, reassignAccountsSuccess } from './account.actions'
import { selectAccountEntities } from './account.selectors'


@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions,
    private accounts: AccountsService,
    private store: Store,
    private toaster: ToasterService,
  ) {}

  loadAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(loadAccounts),
    switchMap(action =>
      from(this.accounts.getAccounts()).pipe(
        map(r => loadAccountsSuccess({
          tableKey: action.tableKey,
          accounts: r.data,
        })),
        catchError(error => {
          console.error('Unable to fetch accounts', error)
          this.toaster.error('Unable to fetch accounts', error)
          return of(loadAccountsFailure({ error }))
        })))))

  reassignAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(reassignAccounts),
    withLatestFrom(
      this.store.pipe(select(selectAccountEntities), waitNotEmpty())
    ),
    tap(() => this.toaster.progress('Reassigning accounts...')),
    switchMap(([action, accounts]) =>
      this.accounts.reassignAccounts(accounts, action.coordinator_reassignments).pipe(
        map(accounts => reassignAccountsSuccess({ accounts })),
        tap(() => this.toaster.success('Reassigned accounts successfully')),
        catchError(error => {
          console.error('Unable to reassign accounts', error)
          this.toaster.error('Unable to reassign accounts', error)
          return of(reassignAccountsFailure({ error }))
        })))))
}
