import { Injectable } from '@angular/core'
import { Invoice } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class BuyerInvoiceReceiptsService {
  constructor(private modalHelper: ModalProxyService) {}

  showReceipts(invoice: DeepReadonly<Pick<Invoice, 'invoice_id' | 'receipts'>>) {
    return this.modalHelper.showModal({
      component: 'tcBuyerInvoiceReceipts',
      size: 'md',
      backdrop: 'static',
      resolve: {
        invoice: () => invoice,
      },
    })
  }
}
