import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Country, GeneralAddress } from '@tradecafe/types/core'
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed'
import { mapValues, sortBy } from 'lodash-es'
import { map } from 'rxjs/operators'
import { selectAllAccounts } from 'src/app/store/accounts'
import { selectAllCarriers } from 'src/app/store/carriers'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { selectAllDepartments } from 'src/app/store/departments'
import { loadDepartments } from 'src/app/store/departments/department.actions'
import { ConsigneeListItem } from 'src/pages/admin/settings/consignees/consignees-page.component'
import { GeoService } from 'src/pages/admin/settings/locations/location-form/geo.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { replayForm } from 'src/shared/utils/replay-form'

export interface ConsigneeFormOptions {
  address?: ConsigneeListItem,
  isSettingsPage?: boolean,
}

@Component({
  selector: 'tc-consignee-form',
  templateUrl: './consignee-form.component.html',
  styleUrls: ['./consignee-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsigneeFormComponent extends OnDestroyMixin implements OnInit {

  constructor(
    private dialog: MatDialogRef<ConsigneeFormComponent, GeneralAddress>,
    private store: Store,
    private geo: GeoService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public options: ConsigneeFormOptions,
  ) {
    super()
  }

  isNew = !this.options.address

  consigneeForm = this.fb.group({
    name: [, Validators.required],
    companies: [],
    inalfresco: [], // boolean<=>0|1
    department: [],
    establishment: [],
    street1: [],
    street2: [],
    city: [, Validators.required],
    state: [],
    state_code: [],
    country: [], // hidden
    cc: [, Validators.required],
    postal: [],
    telephone1: [],
    telephone1_ext: [],
    email: [],
    telephone2: [],
    telephone2_ext: [],
    fax: [],
    fax_ext: [],
    tax_id: [],
    note: [],
    carrier_id: [, Validators.required],
    code: [],
    montshipCode: [],
    contact_name: [],
  })

  // Only allow non-archived accounts/companies for the form
  accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(), map(allAccounts => allAccounts.filter(a => !a.archived)));
  countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty(), map(c => sortBy(c, 'name')))
  carriers$ = this.store.pipe(select(selectAllCarriers))
  departments$ = this.store.pipe(select(selectAllDepartments), waitNotEmpty(), map(x => sortBy(x, 'name')))
  states$ = replayForm(this.consigneeForm.controls.cc).pipe(map(cc => this.geo.getStates(cc)), map(x => sortBy(x, 'name')))
  cities$ = replayForm(this.consigneeForm.controls.cc).pipe(map(cc => this.geo.getCities(cc)), map(x => sortBy(x, 'name')))

  ngOnInit(): void {
    if (this.options.address) {
      this.consigneeForm.patchValue({ ...this.options.address, montshipCode: this.options.address?.integration_codes?.montship })
    }
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadDepartments())
  }

  onStateChange() {
    const { cc, state } = this.consigneeForm.getRawValue()
    this.consigneeForm.controls.state_code.setValue(this.geo.getStateCode(cc, state))
  }

  onCountryChange(country: Country) {
    this.consigneeForm.controls.country.setValue(country.name)
    this.consigneeForm.controls.city.setValue(undefined)
    this.consigneeForm.controls.state.setValue(undefined)
    this.consigneeForm.controls.state_code.setValue(undefined)
  }

  onCarrierChange() {
    this.consigneeForm.controls.carrier_id.markAsDirty()
  }

  async submit() {
    this.consigneeForm.markAllAsTouched()
    this.consigneeForm.updateValueAndValidity()
    if (!this.consigneeForm.valid) return
    let form = {...this.consigneeForm.value}
    // NOTE: backend doesn't allow empty strings
    form = mapValues(form, (v, k) => k === 'code' ? (v || ' ') : (v || undefined))
    form.inalfresco = form.inalfresco ? 1 : 0
    form.integration_codes = {
      montship: form.montshipCode,
    }
    delete form.montshipCode
    this.dialog.close(form)
  }

  cancel() {
    this.dialog.close()
  }
}
