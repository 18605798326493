import { Pipe, PipeTransform } from '@angular/core'
import { GeneralAddress } from '@tradecafe/types/core'
import { compact, map, pick } from 'lodash-es'

@Pipe({
  name: 'address',
  pure: true,
})
export class AddressPipe implements PipeTransform {
  // tslint:disable-next-line: cyclomatic-complexity
  transform(address: Partial<GeneralAddress>, fields = ['name', 'street1', 'city', 'state_code', 'country'], multiline = false) {
    if (!address) return ''

    if (!multiline) {
      return compact(map(pick(address, fields))).join(', ')
    }

    return compact([
      address.name,
      address.name && '\n',
      address.street1,
      address.street1 && '\n',
      address.street2,
      address.street2 && '\n',
      address.city,
      address.city && ' ',
      (address.state_code) && `(${(address.state_code)})  `,
      address.postal,
      address.country && (address.city || address.state_code || address.postal) && '\n',
      address.country,
    ]).join('').trim()
  }
}
