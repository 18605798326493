import { createAction, props } from '@ngrx/store';
import { DocumentTemplate, TableKey } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';


export const loadDocumentTemplates = createAction('[DocumentTemplate/API] Load DocumentTemplates', props<{ tableKey?: TableKey }>() )
export const loadDocumentTemplatesSuccess = createAction('[DocumentTemplate/API] Load DocumentTemplates Success', props<{ tableKey?: TableKey; templates: DocumentTemplate[] }>())
export const loadDocumentTemplatesFailure = createAction('[DocumentTemplate/API] Load DocumentTemplates Failure', props<{ error: Error }>())

export const createDocumentTemplate = createAction('[DocumentTemplate/API] Create Document Template', props<{ template: DeepReadonly<Partial<DocumentTemplate>> }>() )
export const createDocumentTemplateSuccess = createAction('[DocumentTemplate/API] Create Document Template Success', props<{ template: DocumentTemplate }>() )
export const createDocumentTemplateFailure = createAction('[DocumentTemplate/API] Create Document Template Failure', props<{ error: Error }>())

export const updateDocumentTemplate = createAction('[DocumentTemplate/API] Update Document Template', props<{ template: DeepReadonly<Partial<DocumentTemplate>>; id: string, original: DeepReadonly<DocumentTemplate> }>())
export const updateDocumentTemplateSuccess = createAction('[DocumentTemplate/API] Update Document Template Success', props<{ template: DocumentTemplate }>() )
export const updateDocumentTemplateFailure = createAction('[DocumentTemplate/API] Update Document Template Failure', props<{ error: Error }>())

export const deleteDocumentTemplate = createAction('[DocumentTemplate/API] Delete Document Template', props<{ template: DeepReadonly<Partial<DocumentTemplate>>; id: string }>())
export const deleteDocumentTemplateSuccess = createAction('[DocumentTemplate/API] Delete Document Template Success', props<{ id: string }>() )
export const deleteDocumentTemplateFailure = createAction('[DocumentTemplate/API] Delete Document Template Failure', props<{ error: Error }>())
