import { Injectable } from '@angular/core'
import {ToasterService} from '../../shared/toaster/toaster.service'
import { MacropointApiService } from 'src/api/macropoint'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { difference, uniq } from 'lodash-es';
import { Store } from '@ngrx/store';
import { loadCarriers, selectCarrierEntities } from 'src/app/store/carriers';
import { loadLocations, selectLocationEntities } from 'src/app/store/locations';
import { DealRow, GeneralAddress, MacropointOrder, Segment } from '@tradecafe/types/core';
import { Observable, combineLatest, forkJoin, from } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DeepReadonly } from '@tradecafe/types/utils';
import { loadAccounts, selectAccountEntities } from 'src/app/store/accounts';
import { waitNotEmpty } from './utils';

/**
 * Macropoint service
 */
@Injectable()
export class MacropointService {
  constructor(
    private MacropointApi: MacropointApiService,
    private ConfirmModal: ConfirmModalService,
    private toaster: ToasterService,
    private store: Store,
  ) {
  }

  locations$ = this.store.select(selectLocationEntities).pipe(waitNotEmpty())
  carriers$ = this.store.select(selectCarrierEntities).pipe(waitNotEmpty())
  accounts$ = this.store.select(selectAccountEntities).pipe(waitNotEmpty())

/**
 * Creates a Macropoint order for the specified deal.
 */
  create(deal: DeepReadonly<DealRow>) {
    return from(this.ConfirmModal.show({
      title: 'Create Macropoint order?',
      description: 'Are you sure you want to create a Macropoint order for this deal?',
    })).pipe(
      switchMap(() => this.getMissingMandatoryMacropointData(deal.deal_id, deal.earliestTruckSegment)),
      switchMap(async ({ errors }) => {
        const messages = Object.values(errors);
        if (messages?.length) {
          this.toaster.warning(messages[0]);
          return null;
        }

        const data = await this.MacropointApi.create(deal.deal_id);
        if(data) {
          this.toaster.success('Macropoint order has been successfully created');
        }
        return data;
      }),
    )
  }

  /**
   * Schedules one or more Macropoint orders for creation.
   */
  createInBatch(deals: DeepReadonly<DealRow[]>) {
    return from(this.ConfirmModal.show({
      title: 'Create Macropoint orders?',
      description: 'Are you sure you want to create Macropoint orders for these deals?',
    })).pipe(
      switchMap(() => this.aggregateErrorsForDeals(deals)),
      switchMap(async (errorMap) => {
        if(!deals.length) {
          return true;
        }

        for (const error in errorMap) {
          if (errorMap[error].length > 0) {
            const errorMessage = `${error} in deal(s): ${errorMap[error].join(', ')}.`;
            this.toaster.warning(errorMessage);
            return false;
          } 
        }
          
        const dealIds = deals.map(x => x.deal_id);
        if(dealIds?.length) {
          await this.MacropointApi.createInBatch(dealIds);
          this.toaster.success('Macropoint orders have been scheduled for creation. If a deal does not get its tracking number shortly, please try submitting it individually.')
          return true;
        }
  
        return false;
      }),
    )
  }

  /**
   * Retrieves a Macropoint order.
   */
    async get(order_id: string): Promise<MacropointOrder> {
      return await this.MacropointApi.get(order_id)
    }

  /**
   * Updates a Macropoint order for the specified deal.
   */
  update(dealId: string, segment: DeepReadonly<Segment>) {
    return from(this.ConfirmModal.show({
      title: 'Update Macropoint order?',
      description: 'Are you sure you want to update the Macropoint order data for this deal?',
    })).pipe(
      switchMap(() => this.getMissingMandatoryMacropointData(dealId, segment)),
      switchMap(async ({ errors }) => {
        const messages = Object.values(errors);
        if (messages?.length) {
          this.toaster.warning(messages[0]);
          return null;
        }

        const data = await this.MacropointApi.update(dealId);
        if(data) {
          this.toaster.success('Macropoint order has been successfully updated');
        }
        return data;
      }),
    )
  }

  /**
   * Schedules one or more Macropoint orders for data refresh.
   */
  updateInBatch(deals: DeepReadonly<DealRow[]>) {
    return from(this.ConfirmModal.show({
      title: 'Update Macropoint orders?',
      description: 'Are you sure you want to update Macropoint orders for these deals?',
    })).pipe(
      switchMap(() => this.aggregateErrorsForDeals(deals)),
      switchMap(async (errorMap) => {
        if(!deals?.length) {
          return true;
        }

        for (const error in errorMap) {
          if (errorMap[error].length > 0) {
            const errorMessage = `${error} in deal(s): ${errorMap[error].join(', ')}.`;
            this.toaster.warning(errorMessage);
            return false;
          } 
        }

        const orderIds = deals.map(d => d.macropoint_order?.order_id);
        await this.MacropointApi.updateInBatch(orderIds);
        this.toaster.success('Macropoint orders have been scheduled for updating. If the changes are not reflected in the order soon, please try submitting it individually.')
        return true;
      }),
    )
  }

  /**
   * Opens a tab with the Macropoint order.
   */
  async openMacropointOrderHyperlink(identifier: string) {
    const { url } = await this.MacropointApi.generateHyperlink(identifier)
    if(url) {
      window.open(url, '_blank');
    }
  }

  /**
   * Stops tracking a Macropoint order.
   */
    async stop(identifier: string) {
      await this.MacropointApi.stopOrder(identifier)
      this.toaster.success('Macropoint order has been successfully stopped');
    }

  /**
 * Identifies any missing data mandatory for creating a Macropoint order.
 */
  private getMissingMandatoryMacropointData(dealId: string, segment: DeepReadonly<Segment>) {
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadAccounts({}))

    return combineLatest([this.locations$, this.carriers$, this.accounts$])
      .pipe(
        take(1),
        map(([locations, carriers, accounts]) => {
          let errors: string[] = [];
  
          if (!segment) {
            errors.push('Missing truck segment');
          } else {
            if (!accounts[carriers[segment?.carrier_id]?.account]?.attributes?.scac_code) {
              errors.push('Missing carrier SCAC code');
            }
  
            if (!segment.attributes.actual_delivery_date ||
                !segment.attributes.actual_pickup_date ||
                !segment.attributes.actual_delivery_date_time ||
                !segment.attributes.actual_pickup_date_time ||
                !locations[segment.attributes.origin_id].timezone ||
                !locations[segment.attributes.destination_id].timezone) {
              errors.push('Missing stop (pickup/dropoff) date, time or time zone');
            }
  
            const validAddress = (address: GeneralAddress) => address &&
                (address.cc || address.country) &&
                (address.state_code || address.state) &&
                address.city && address.street1 && address.postal;
  
            if (!validAddress(segment.attributes.exact_loading?.address) ||
                !validAddress(segment.attributes.exact_dropoff?.address)) {
              errors.push('Missing exact stop address (street, postal, city, country, state)');
            }
          }
  
          return { dealId, errors: uniq(errors) };
        })
      );
  }

/**
 * Validates deals in bulk, returning a dictionary of error messages mapped to the deals it applies to. 
 */
  private aggregateErrorsForDeals(deals: DeepReadonly<DealRow[]>): Observable<{ [key: string]: string[] }> {
    const validationObservables = deals.map(deal => this.getMissingMandatoryMacropointData(deal.deal_id, deal.earliestTruckSegment));
    
    return forkJoin(validationObservables).pipe(
      map(results => {
        const errorMap = {};
        results.forEach(result => {
          result.errors.forEach(error => {
            if (!errorMap[error]) {
              errorMap[error] = [];
            }
            errorMap[error].push(result.dealId);
          });
        });
        return errorMap;
      }),
      take(1),
    );
  }
}
