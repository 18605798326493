import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormControl } from '@angular/forms'
import { MtxDatetimepicker, MtxDatetimepickerType } from '@ng-matero/extensions/datetimepicker'
import { Subscription } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { epochFromUtc, epochToUtc } from 'src/directives/epoch-range/epoch-range.utils'
import { dayjs } from 'src/services/dayjs'
import { replayFormStatus } from 'src/shared/utils/replay-form'


@Component({
  selector: 'tc-epoch-field',
  templateUrl: './epoch-field.component.html',
  styleUrls: ['./epoch-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EpochFieldComponent implements OnInit, OnDestroy {

  constructor(private cd: ChangeDetectorRef, public _elementRef: ElementRef) { }

  @Input() placeholder = ''
  @Input() icon = false
  @Input() useUtc = true
  // @Input() useTemplates = false
  @Input() min: number
  @Input() max: number
  @Input() hour: number
  @Input() group: AbstractControl
  @Input() ctrlName: string
  @Input() type: MtxDatetimepickerType = 'date'
  @Input() readonly = false
  @Input() ctrl: UntypedFormControl
  @ViewChild('picker', { static: true }) picker: MtxDatetimepicker<unknown>
  @Output() changed = new EventEmitter<number>()

  readonly sub = new Subscription
  internalControl: UntypedFormControl

  private _markAllAsTouched: AbstractControl['markAllAsTouched']

  ngOnInit() {
    this.ctrl = this.ctrl || this.group.get(this.ctrlName) as UntypedFormControl
    if (!this.ctrl) throw new Error(`can not find control "${this.ctrlName}"`)
    this.internalControl = new UntypedFormControl(epochFromUtc(this.ctrl.value, this.useUtc), this.ctrl.validator)

    // this component needs to hijack markAllAsTouched in order to sync '.touched' fields between `ctrl` and `internalControl`
    this._markAllAsTouched = this.ctrl.markAllAsTouched
    this.ctrl.markAllAsTouched = () => {
      this.internalControl.markAllAsTouched()
      this._markAllAsTouched.call(this.ctrl)
      this.cd.markForCheck()
    }

    // oneway data replication external => internalForm
    this.sub.add(this.ctrl.valueChanges.subscribe(value =>
      this.internalControl.setValue(epochFromUtc(value, this.useUtc))))

    this.sub.add(replayFormStatus(this.ctrl).subscribe(() =>
      this.ctrl.enabled
        ? this.internalControl.enable()
        : this.internalControl.disable()))

    if (this.readonly) return
    let backup: number
    this.sub.add(this.picker.openedStream.pipe(
      tap(() => {
        backup = this.ctrl.value
      }),
      switchMap(() => this.picker.closedStream),
      tap(() => {
        const output = this.internalControl.value
        let outputUtc = epochToUtc(output, this.useUtc)
        if (this.type === 'date' && outputUtc) { // if hour is not provided set time to 0:00
          outputUtc = this.hour ? dayjs.utc(outputUtc * 1000).hour(this.hour).minute(0).unix() : dayjs.utc(outputUtc * 1000).startOf('day').unix()
        }
        if (backup === outputUtc) return // nothing changed
        this.ctrl.setValue(outputUtc)
        this.ctrl.markAsTouched()
        this.ctrl.markAsDirty()
        this.changed.next(outputUtc)
      })).subscribe())
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
    this.ctrl.markAllAsTouched = this._markAllAsTouched
  }

}
