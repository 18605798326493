import { VERSION } from './version'

export const environment = {
  version: VERSION,
  env: 'staging',
  apiUrl: 'https://dev.sapi.tradecafe.ca',
  auctioneerApiUrl: 'https://dev.auctioneer.tradecafe.ca',
  lockApiUrl: 'wss://dev.lock.tradecafe.ca',
  registrationUrl: 'https://stage.register.tradecafe.ca',
  webappUrl: 'https://stage.vendor.tradecafe.ca',
  tradecafeAccount: parseFloat('1510188624.5357'),
  tradecafeUSAccount: parseFloat('1525314877.9101'),
  googleMapsApiKey: 'AIzaSyApYcVgdU3inIWbUkwtoYPgpKThuNT9K1o',
  bwiInventoryAccounts: '1526595143.8113,1526595143.9949,1524774037.6374'.split(',').map(x => parseFloat(x.trim())),
  bwiInventoryEmail: 'nwalker@bassettwalkerinc.com',
  bwiInventoryUsername: 'Nicholas Walker',
  montshipAccount: parseFloat('1520792915.4761'),
  montshipCarrierId: '72099d26-b561-4913-b214-062f114a3480',
  bankChargeMax: parseFloat('150'),
  bankChargeProductId: '2532254e-3267-4230-b163-abf9c7d4d397',
  bankChargeProviderId: '1518606314.6461',
  freightProductCategoryId: '9db7a86c-53da-41c3-8f79-8123f40a456e',
  freightTruckProductId: '4ba33c4c-5598-406f-a287-d3705ffd788e',
  freightVesselProductId: 'a82c0cda-0eca-454d-a16b-a22cd7323182',
  freightAirplaneProductId: '0cae6878-154c-4e68-947e-177fc4ad6981',
  freightTrainProductId: '0f146e48-297f-4693-b31a-64010a84cf13',
  sentryDsn: 'https://9b03b036b393440a8832a1d768182a5b@o457369.ingest.sentry.io/5535479',
  logRocketAppId: '0zfmwa/dev-staff-portal-tradecafe',
  // NOT feature flags!!!
  enableProdMode: true,
  enableStoreDevtools: false,
  // feature flags
  enableMontshipBooking: 'true'.toLowerCase() === 'true',
  enableBrokerageDeals: 'true'.toLowerCase() === 'true',
  enableChartFeature: 'false'.toLowerCase() === 'true',
  enableMultipleBids: 'false'.toLowerCase() === 'true',
  enableAutomateAdditionalCharge: 'true'.toLowerCase() === 'true',
  enableExportService: 'true'.toLowerCase() === 'true',
  enableMacropoint: 'true'.toLowerCase() === 'true',
  enableSwapProvider: 'true'.toLowerCase() === 'true',
  enableIncompleteDeals: '${WEBAPP_ENABLEINCOMPLETEDEALS}'.toLowerCase() === 'true',
  enableRouteService: '${WEBAPP_ENABLEROUTESERVICE}'.toLowerCase() === 'true',
}
