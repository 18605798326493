import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed'
import {DealElasticSearchService} from 'src/services/data/deal-elastic.service'
import {FiltersService} from 'src/services/table-utils'
import {Store} from '@ngrx/store'
import {TableViewApiService} from 'src/api/table-view'
import {DomSanitizer} from '@angular/platform-browser'
import {DEFAULT_COLUMNS, columnNames} from 'src/components/deals-list'
import {selectDealsTable, selectDealsTotals} from 'src/app/store/deal-view.reducer'
import {DEFAULT_DEALS_FILTERS} from 'src/components/deals-list/deals-list.filters'
import {ApiTableDataSource} from 'src/services/table-utils/data-sources/api-table-data-source'
import { DealRow, DealStatusE, TableKey } from '@tradecafe/types/core'
import {replayForm} from 'src/shared/utils/replay-form'
import {Subject} from 'rxjs'
import {distinctUntilChanged, filter} from 'rxjs/operators'
import {identity, isEqual} from 'lodash-es'
import {loadDealViews} from 'src/app/store/deal-view.actions'
import { DeepReadonly } from '@tradecafe/types/utils'
import { loadMeasures } from 'src/app/store/measures'

export interface PreviewClientShippingLogOptions {
  displayColumns: string[],
  selectedAccountType: string,
  account: number,
  tableIdentity: TableKey,
}

type Sort = 'asc'|'desc'

@Component({
  selector: 'tc-preview-client-shipping-log',
  templateUrl: './preview-client-shipping-log.component.html',
  styleUrls: ['./preview-client-shipping-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewClientShippingLogComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PreviewClientShippingLogComponent, void>,
    @Inject(MAT_DIALOG_DATA) private dialogData: PreviewClientShippingLogOptions,
    private elastic: DealElasticSearchService,
    private Filters: FiltersService,
    private store: Store,
    private TableViewApi: TableViewApiService,
    private sanitizer: DomSanitizer) { super() }

  styles = this.sanitizer.bypassSecurityTrustHtml(`<style>body::-webkit-scrollbar { display: none; }</style>`)

  protected tableIdentity = this.dialogData.tableIdentity

  columnNames = columnNames(this.tableIdentity)
  displayColumns = this.dialogData.displayColumns
  availableColumns = DEFAULT_COLUMNS[this.tableIdentity]
  totals$ = this.store.select(selectDealsTotals(this.tableIdentity))

  protected readonly filtersForm = this.Filters.buildForm(this, {
    key: this.tableIdentity,
    cacheFilters: false,
    defaultValues: {
      ...DEFAULT_DEALS_FILTERS,
      sort: this.getDefaultSorting(),
      columns: this.availableColumns,
      [this.dialogData.selectedAccountType]: [this.dialogData.account],
      parties: [this.dialogData.account],
      status: Object.values(DealStatusE).filter(value => value !== DealStatusE.DEAL_DRAFT),
    },
  })

  protected dataSource: ApiTableDataSource<DeepReadonly<DealRow>, any>

  private filtersForm$ = replayForm(this.filtersForm)
  private deals$ = this.store.select(selectDealsTable(this.tableIdentity))


  ngOnInit(): void {
    const trigger$ = new Subject()
    this.store.dispatch(loadMeasures({}))

    this.dataSource = new ApiTableDataSource<DeepReadonly<DealRow>, any>(
        this.deals$.pipe(filter(identity)),
        this.filtersForm,
        trigger$)

    this.dataSource.fetch$.pipe(untilComponentDestroyed(this), distinctUntilChanged(isEqual)).subscribe(({filters, page}) => {
      this.store.dispatch(loadDealViews({
        tableKey: this.tableIdentity,
        page,
        filters: {...filters, columns: this.displayColumns},
        calculate: false,
      }))
    })

    replayForm(this.filtersForm).pipe(untilComponentDestroyed(this)).subscribe(() => trigger$.next())
  }

  getDefaultSorting () {
     if (this.tableIdentity === TableKey.ClientServiceProviderShippingLogList) {
       return { id: 'deal_id', start: 'desc' as Sort }
     } else if (this.tableIdentity === TableKey.ClientSupplierShippingLogList) {
       return { id: 'pickup_date', start: 'desc' as Sort }
     }
     return { id: 'est_delivery_date', start: 'desc' as Sort }
  }

  cancel (): void {
    this.dialogRef.close()
  }

}
